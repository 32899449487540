import { AnyAction, Dispatch, Middleware } from "redux";
import { Action, getType } from "typesafe-actions";

type IHandlerFunction<TAction extends Action, TStore> = (
	action: TAction,
	middlewareStuff: {
		dispatch: Dispatch<AnyAction>;
		/**
		 * next is used to transfer current action to next middleware and also to transfer same action to reducers once all middlewares are executed.
		 * @description
		 * If a reducers is not updating the state for current action, then for current action you may want to add next(action)
		 */
		next: Dispatch<AnyAction>;
		getState: () => TStore;
	}
) => void;

function createMiddleware<TStore, TActions extends Action>() {
	const chainApi: {
		handlers: {
			[key: string]: IHandlerFunction<any, any>;
		};
		handleAction<
			TInputAction extends Action,
			TActionCreator extends (...args: any[]) => TInputAction
		>(
			actionCreator: TActionCreator,
			handler: IHandlerFunction<ReturnType<TActionCreator>, TStore>
		): typeof chainApi;
		getMiddleware: () => Middleware<{}, TStore>;
	} = {
		handlers: {},
		handleAction(actionCreator, handler) {
			(
				chainApi.handlers as {
					[key: string]: IHandlerFunction<any, any>;
				}
			)[getType(actionCreator)] = handler;
			return chainApi;
		},
		getMiddleware() {
			const middleware: Middleware<{}, TStore> =
				({ dispatch, getState }) =>
				(next) =>
				(action: TActions) => {
					if (
						(
							chainApi.handlers as {
								[key: string]: IHandlerFunction<any, any>;
							}
						)[action.type]
					)
						return (
							chainApi.handlers as {
								[key: string]: IHandlerFunction<typeof action, TStore>;
							}
						)[action.type](action, { dispatch, next, getState });
					else return next(action);
				};
			return middleware;
		},
	};
	return chainApi;
}

export default createMiddleware;
