import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import {
	TAnnex6GroupA,
	TAnnex6GroupA2,
} from "../../../../../redux/app/annex6/groups/group-a";
import { marpolUtils } from "../../../../../redux/app/marpol";
import CommaSeparator from "../../../../_common/CommaSeparator";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupA2PreviewProps = OwnProps;
function Annex6GroupA2Preview(props: Annex6GroupA2PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupA2;

    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'A', 2.1, renderItem2P1()],
                    [null, null, 2.2, renderItem2P2()],
                    [null, null, 2.3, renderItem2P3()],
                    [null, null, 2.4, renderItem2P4()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem2P1() {
        if (!record.item2P1.place) return <Annex6OldVersionPreview />;
        if (record.item2P1.place === 'IN_PORT') return record.item2P1.portName;
        if (record.item2P1.place === 'AT_SEA')
            return (
                <>
                    {record.item2P1?.latitude && record.item2P1?.latitude} {''}
                    {record.item2P1?.longitude && record.item2P1?.longitude}
                </>
            );
        return record.item2P1.receptionFacilityName;
    }
    function renderItem2P2() {
        if (!record.item2P2.start || !record.item2P2.end)
            return <Annex6OldVersionPreview />;
        return `STARTED  ${record.item2P2.start.date} at ${record.item2P2.start.time} | FINISHED  ${record.item2P2.end.date} at ${record.item2P2.end.time}`;
    }

    function renderItem2P3() {
        return (
            <h4 className="font-medium uppercase">
                ORIGINAL SUPPLIED FUEL OIL BDN REFERENCE NO:{' '}
                {record.item2P3?.BDN}
            </h4>
        );
    }

    function renderItem2P4() {
        if (!record.item2P4.tanks) {
            console.error(
                record.item2P4,
                'Item2P4 is invalid or is an older version.'
            );
            return <Annex6OldVersionPreview />;
        }
        const totalQuantityInMT = record.item2P4.tanks
            .reduce(
                (result, currentTank) => result + currentTank.quantityOfFuel,
                0
            )
            .toFixed(3);
        return (
            <>
                {totalQuantityInMT} MT OF {record.item2P3.fuelType} de-bunkered
                to tanks. <br />
                <CommaSeparator
                    items={record.item2P4.tanks.map((tank) => {
                        const quantityOfFuel = tank.quantityOfFuel.toFixed(3);
                        const totalContent = tank.totalContent.toFixed(3);
                        return `${quantityOfFuel} MT REMOVED FROM ${tank.selectedTank.tankName} NOW CONTAINING ${totalContent} MT`;
                    })}
                    customSeperator={<br />}
                />
            </>
        );
    }

    return renderMain();
}

export default Annex6GroupA2Preview;
