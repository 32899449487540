import React, { FC } from 'react';
import { IData } from '.';
import WayshipTextArea from './WayshipTextArea';

interface IRemarksProps {
    data: IData;
    setData: React.Dispatch<React.SetStateAction<IData>>;
    remarksErrorMsg?: string;
}

const Remarks: FC<IRemarksProps> = ({ data, setData, remarksErrorMsg }) => {
    const isError = remarksErrorMsg && remarksErrorMsg?.trim().length > 0;
    return (
        <div>
            <WayshipTextArea
                title="Remarks"
                value={data.remarks}
                isSubmit={data.metric.value === ''}
                dataKey=""
                onChange={(v) => {
                    setData((prev) => ({
                        ...prev,
                        remarks: v,
                    }));
                }}
                error={isError && {
                    isValid: false,
					message: remarksErrorMsg
                }}
            />
        </div>
    );
};

export default Remarks;
