import React from "react";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";

type DashboardViewProps = PropsFromRedux;
function DashboardView(props: DashboardViewProps) {
	return (
		<div
			className="h-[calc(100vh-100px)] min-h-full overflow-y-auto bg-white rounded-md md:overflow-hidden m-2"
			// style={{ height: "calc(100vh - 100px)" }}
		>
			<h1>hello dashboard view</h1>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(DashboardView);
