import React from "react";

import { BallastTableColumns } from "./BallastTableRows";
import { EBallastRecordCode, TBallastBackendRecord, TBallastRecordUser } from "redux/app/ballast/@types";
import { marpolUtils } from "redux/app/marpol";

function getSignTableRows(
	record: TBallastBackendRecord,
	is2ndUserSection: boolean = false
): BallastTableColumns[] {
	function getItemNumber() {
		switch (record.recordCode) {
			case EBallastRecordCode.BALLAST:
				return is2ndUserSection ? "3.2.4" : "3.1.3";
			case EBallastRecordCode.DEBALLAST:
				return "3.3.4";
			case EBallastRecordCode.EXCHANGE:
				return null;
			case EBallastRecordCode.ADDITIONAL:
				return null;
			case EBallastRecordCode.INTERNAL:
				return "3.2.4";
			case EBallastRecordCode.EXCEPTIONAL:
				return "3.5.6";
		}
	}

	function getFormattedDate(dateWithTime: string) {
		return dateWithTime;
		return marpolUtils.getDate(dateWithTime);
	}

	function getVerificationUser() {
		if (!record.verifiedBy) return undefined;
		return {
			...record.verifiedBy,
			date: record.submittedBy.date,
		};
	}

	function renderSignatures() {
		if (!record.submittedBy) return null;
		const verifiedUser = getVerificationUser();
		return (
			<h4 className="font-medium leading-9 uppercase">
				RECORDED BY: {renderSignatureItem(record.submittedBy)}
				<br />
				VERIFIED BY: {renderSignatureItem(verifiedUser, !record.verifiedBy)}
				<br />
				APPROVED BY: {renderSignatureItem(record.approvedBy)}
			</h4>
		);
	}

	function renderStrikeOffSign() {
		if (!record.strikedBy) return null;
		return (
			<h4 className="font-medium leading-9 uppercase">
				STRUCK OFF BY: {renderSignatureItem(record.strikedBy)}
				<div className="w-full">
					STRUCK OFF REASON:
					<span className="ml-1 font-bold text-red-700">
						{record.strikeOffReason}
					</span>
				</div>
			</h4>
		);
	}

	function renderSignatureItem(
		user?: TBallastRecordUser,
		isPending: boolean = false
	) {
		if (!user || isPending) return "PENDING";
		const { date, designation, name, staffId } = user;
		return `${name} [${designation}] - ${staffId}
		 on ${getFormattedDate(date)}`;
	}

	return [
		[null, getItemNumber(), renderSignatures()],
		[null, null, renderStrikeOffSign()],
	];
}

export default getSignTableRows;
