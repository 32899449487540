import React from "react";
type SVGFromProps = { className?: string };
const SVGFrom: React.FC<SVGFromProps> = (props) => (
	<svg
		width="20"
		height="26"
		viewBox="0 0 20 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<g clipPath="url(#clip0_1478_20142)">
			<path
				d="M1.42097 19.2747C1.4137 18.1457 1.85522 17.06 2.6484 16.2566C3.44158 15.4531 4.52144 14.9976 5.65044 14.9904L14.1642 14.9355C15.2932 14.9283 16.3788 15.3698 17.1823 16.163C17.9858 16.9561 18.4412 18.036 18.4485 19.165C18.4521 19.7295 18.2314 20.2723 17.8348 20.6741C17.4382 21.0758 16.8983 21.3035 16.3338 21.3072L3.56312 21.3894C2.99862 21.393 2.4558 21.1723 2.05407 20.7757C1.65234 20.3791 1.42461 19.8392 1.42097 19.2747Z"
				stroke="#B7AEAE"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M9.88027 10.7061C11.6435 10.6947 13.0637 9.25611 13.0524 7.49285C13.041 5.72959 11.6024 4.30939 9.83915 4.32075C8.07589 4.3321 6.65569 5.77071 6.66705 7.53397C6.6784 9.29723 8.11701 10.7174 9.88027 10.7061Z"
				stroke="#B7AEAE"
				strokeWidth="1.5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1478_20142">
				<rect
					width="19.7244"
					height="25.5418"
					fill="white"
					transform="translate(0 0.127045) rotate(-0.369011)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default SVGFrom;
