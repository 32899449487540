import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupF1 } from "../../../../../redux/app/orb1/groups/group-f";
import ORB1TableRows from "../ORB1TableRows";
import { marpolUtils } from "redux/app/marpol";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupF1PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupF1Preview(props: ORB1GroupF1PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupF1;
	const { item19, item20, item21, selectedDate, remarks } = orb1Record;
	const operationDate = moment(selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const systemFailureDate = (() => {
		const theDate = marpolUtils.getDate(item19.systemFailureDate);
		return moment(theDate, "YYYY-MM-DD").format("DD-MM-YYYY");
	})();
	const systemRestorationDate = (() => {
		if (item20.isSystemRestored !== "YES") return "";
		const theDate = marpolUtils.getDate(item20.systemRestoration.date);
		return moment(theDate, "YYYY-MM-DD").format("DD-MM-YYYY");
	})();

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "F", "19", renderItem19()],
					[null, null, "20", renderItem20()],
					[null, null, "21", renderItem21()],
					[null, null, "NOTE", remarks],
				]}
			/>
		);
	}

	function renderItem19() {
		return (
			<h4 className="font-medium uppercase">
				AT {systemFailureDate} {item19.systemFailureTime} HRS TIME{" "}
				{item19.whatFailed} FAILED
			</h4>
		);
	}

	function renderItem20() {
		return (
			<h4 className="font-medium uppercase">
				{item20.isSystemRestored === "YES"
					? `AT ${systemRestorationDate} ${item20.systemRestoration.time} HRS TIME ${item20.anyActionTaken}`
					: `UNKNOWN - ${item20.anyActionTaken}`}
			</h4>
		);
	}

	function renderItem21() {
		return (
			<h4 className="font-medium uppercase">
				REASON OF FAILURE: {item21.reasonsForFailure}
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupF1Preview;
