import React from 'react';
import classNames from 'classnames';

type OwnProps = {
    value: boolean | undefined;
    onChange(newValue: boolean): void;
    onReset?(): void;
    labels?: {
        trueLabel: string;
        falseLabel: string;
    };
    className?: string;
    disabled?: boolean;
    colorScheme?: 'indigo' | 'yellow' | 'orange' | 'red';
};
type TrueFalseButtonInputProps = React.PropsWithChildren<OwnProps>;
function TrueFalseButtonInput(props: TrueFalseButtonInputProps) {
    const {
        className = '',
        value,
        labels = {
            falseLabel: 'No',
            trueLabel: 'Yes',
        },
        disabled = false,
        colorScheme = 'indigo',
    } = props;

    const selectedClasses = classNames({
        'text-white bg-indigo-700 border-indigo-700': colorScheme === 'indigo',
        'text-white bg-orange-700 border-orange-700': colorScheme === 'orange',
        'text-white bg-red-700 border-red-700': colorScheme === 'red',
        'text-white bg-yellow-700 border-yellow-700': colorScheme === 'yellow',
    });
    const unSelectedClasses = classNames({
        'text-indigo-700 border-indigo-700': colorScheme === 'indigo',
        'text-orange-700 border-orange-700': colorScheme === 'orange',
        'text-red-700 border-red-700': colorScheme === 'red',
        'text-yellow-700 border-yellow-700': colorScheme === 'yellow',
    });

    return (
        <div
            className={classNames('w-full ws-input', className, {
                'ws-input--disabled ws-input--read-only outline-none focus-within:outline-none focus:outline-none':
                    disabled,
            })}
        >
            <div className="flex flex-row flex-wrap justify-start w-full -mx-2">
                <button
                    className={classNames(
                        'border-2 outline-none rounded-md p-1 mx-2 px-8 text-sm font-medium ',
                        !disabled
                            ? {
                                  [unSelectedClasses]:
                                      value === false && value !== undefined,
                                  [selectedClasses]: value === true,
                                  [unSelectedClasses]: value === undefined,
                              }
                            : {
                                  ' cursor-not-allowed text-gray-500 border-gray-500':
                                      value === false && value !== undefined,
                                  ' cursor-not-allowed text-white bg-gray-500 border-gray-500':
                                      value === true,
                              }
                    )}
                    onClick={() => {
                        if (disabled) return;
                        if (value === true && props.onReset) props.onReset();
                        else props.onChange(true);
                    }}
                >
                    {labels.trueLabel}
                </button>
                <button
                    className={classNames(
                        'border-2 outline-none rounded-md p-1 mx-2 px-8 text-sm font-medium  ',
                        !disabled
                            ? {
                                  [unSelectedClasses]: value === true,
                                  [selectedClasses]:
                                      value === false && value !== undefined,
                                  [unSelectedClasses]: value === undefined,
                              }
                            : {
                                  ' cursor-not-allowed text-gray-500 border-gray-500':
                                      value === true,
                                  ' cursor-not-allowed text-white bg-gray-500 border-gray-500':
                                      value === false && value !== undefined,
                              }
                    )}
                    onClick={() => {
                        if (disabled) return;
                        if (value === false && props.onReset) props.onReset();
                        else props.onChange(false);
                    }}
                >
                    {labels.falseLabel}
                </button>
				<div className="flex flex-row flex-wrap justify-start w-full px-2 pt-3">
					{props.children}
				</div>
            </div>
        </div>
    );
}

export default TrueFalseButtonInput;
