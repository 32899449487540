import {
	IDashboardStatusType,
	TPossibleOperations,
	TVesselCardOperation,
} from "./types";

const vesselUtils = {
	operationType: (operations: TVesselCardOperation[]): TPossibleOperations => {
		if (operations.length === 2) return "fav/unfav";
		if (operations.length === 1 && operations[0] === "fav") return "fav";
		return "unfav";
	},
	activeMessage: (operationType: TPossibleOperations, isPlural: boolean) => {
		const article = isPlural ? "the" : "a";
		switch (operationType) {
			case "fav":
				return `Favoriting ${article} ${isPlural ? "vessels" : "vessel"}`;
			case "unfav":
				return `Unfavoriting ${article} ${isPlural ? "vessels" : "vessel"}`;
			case "fav/unfav":
				return `Favoriting/Unfavoriting ${article} ${
					isPlural ? "vessels" : "vessel"
				}`;
			default:
				return `Favoriting ${article} ${isPlural ? "vessels" : "vessel"}`;
		}
	},
	getColorMapper: (statuses: IDashboardStatusType[]) => {
		const colorMapper = {} as any;
		statuses.forEach((statusObj) => {
			colorMapper[statusObj.status] = statusObj.color;
		});
		return colorMapper;
	},
};

export default vesselUtils;
