import React from "react";
import VesselOnboardCrew from "../../../../../components/app/vessel/onboard/crew/Crew";

type ScreenVesselOnboardCrewType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
		};
	};
}>;

const ScreenVesselOnboardCrew: React.FC<ScreenVesselOnboardCrewType> = (
	props: ScreenVesselOnboardCrewType
) => {
	return <VesselOnboardCrew vesselId={props.match.params.vesselId} />;
};

export default ScreenVesselOnboardCrew;
