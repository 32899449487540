import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	Bar,
} from "recharts";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import {
	isBilgeDataEmpty,
	quarterWiseDataTransformationForGraph,
	transformDataForAllVesselsToGraph,
} from "redux/app/report/utils";
import { IStore } from "redux/store";
import CustomTooltip from "./CustomTooltip";
import classNames from "classnames";

const categoryLegends = [
	{
		title: "OWS overboard",
		color: "#00318c",
	},
	{
		title: "Transfer to slop",
		color: "#00a6e6",
	},
];

const xAxisScale = "Quarter";
const yAxisScale = "m³";

type OwnProps = {
	reportContainerWidth: number;
};
type GraphProps = PropsFromRedux & OwnProps;
function Graph(props: GraphProps) {
	const {
		reportContainerWidth,
		vesselId,
		year,
		rawBilgeWaterData,
		rawBilgeWaterAllVesselsData,
		jobToGetVesselSpecificBilgeReport,
		jobToLoadVesselTenantBilgeReport,
	} = props;

	const finalData = useMemo(() => {
		if (!vesselId) {
			return transformDataForAllVesselsToGraph(rawBilgeWaterAllVesselsData);
		} else return quarterWiseDataTransformationForGraph(rawBilgeWaterData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, rawBilgeWaterData, year, rawBilgeWaterAllVesselsData]);

	if (
		(!vesselId && jobToLoadVesselTenantBilgeReport.isLoading) ||
		(vesselId && jobToGetVesselSpecificBilgeReport.isLoading)
	) {
		return <div className="flex justify-center mt-20">Loading...</div>;
	}

	if (isBilgeDataEmpty(finalData)) {
		return (
			<div className="flex justify-center items-center mt-20">
				No data found{vesselId ? " for this vessel" : ""}.{" "}
				{vesselId
					? "Choose another vessel."
					: "Please select a vessel or change the year."}
			</div>
		);
	}

	return (
		<div className="flex justify-center m-2">
			<div className="p-4 px-6 shadow-md max-w-4xl rounded-md border border-gray-300">
				<h3 className="font-medium text-center py-1.5">Bilge water (m³)</h3>
				<div className="flex justify-center">
					{categoryLegends.map((cat) => {
						return (
							<div key={cat.title} className="flex items-center">
								<span
									style={{ background: cat.color }}
									className={classNames(
										"ml-2 xl:ml-4 text-sm  xl:text-sm w-3 h-3 lg:w-[16px] lg:h-[16px] xl:w-[22px] xl:h-[22px] rounded-full  "
									)}
								/>
								<span className="ml-1 mr-1 xl:mr-1 xl:ml-2 text-sm lg:text-[12px] xl:text-sm ">
									{cat.title}
								</span>
							</div>
						);
					})}
				</div>
				<div className="axis flex justify-end text-[#7E7E7E] mt-4">
					<p className="m-0 text-[12px] font-medium px-3.5 border-r-2 border-[#7E7E7E]">
						X-axis - {xAxisScale}
					</p>
					<p className="m-0 text-[12px] font-medium px-3.5">
						Y-axis - {yAxisScale}
					</p>
				</div>
				<div className="flex justify-center">
					<ResponsiveContainer
						width={
							reportContainerWidth > 1000
								? reportContainerWidth / 2
								: 0.75 * reportContainerWidth
						}
						height={375}
					>
						<BarChart
							width={reportContainerWidth}
							data={finalData}
							margin={{
								top: 25,
								right: 10,
								left: -20,
								bottom: 5,
							}}
							barGap={0}
						>
							<CartesianGrid vertical={false} fill="" />
							<XAxis tickLine={false} dataKey="quarter" fontSize={14} />
							<YAxis tickLine={false} fontSize={14} />
							<Tooltip content={<CustomTooltip />} />
							<Bar
								barSize={45}
								dataKey="owsOverboard"
								stackId="a"
								fill="#00318c"
							/>
							<Bar
								barSize={45}
								dataKey="transferToSlop"
								stackId="a"
								fill="#00a6e6"
							/>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getBilgeWaterVesselId(reportStore);
	const year = reportSelectors.getBilgeWaterYear(reportStore);
	const rawBilgeWaterData = reportSelectors.getRawBilgeWaterData(reportStore);

	const rawBilgeWaterAllVesselsData =
		reportSelectors.getBilgeWaterAllVesselsData(reportStore);
	const jobToGetVesselSpecificBilgeReport =
		reportSelectors.jobToLoadGarbageReport(reportStore);
	const jobToLoadVesselTenantBilgeReport =
		reportSelectors.jobToLoadVesselTenantBilgeReport(reportStore);
	return {
		vesselId,
		year,
		rawBilgeWaterData,
		jobToLoadVesselTenantBilgeReport,
		rawBilgeWaterAllVesselsData,
		jobToGetVesselSpecificBilgeReport,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Graph);
