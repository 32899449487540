import classNames from 'classnames';
import React, { FC } from 'react'
import { IListObjectStore } from 'redux/_common/type';
import { IVessel, TVesselCardOperation } from 'redux/app/vessel/types';
import moment from "moment";
import { TAB_IDS } from 'redux/app/vessel/constants';

interface IVesselsTableProps {
	vesselsToShow: IListObjectStore<IVessel>;
	isSelectionEnabled: boolean;
	selectedVesselsOnSelectionEnabled: string[];

	selectVesselAndAdd: (vesselId: string) => void;
	onOnboardClick: (vesselId: string) => void;
	updateMyVesselList: (updatedVessels: IListObjectStore<IVessel>) => void;
	vesselCardOperationAdd: (operation: TVesselCardOperation) => void;
	favoriteAVessel: (favoritedVesselIds: string[]) => void;
	saveFavorite: (favoritedVesselIds: string[]) => void;

	favoritedVesselIds: string[];
	currentSelectedTab: string;
};

const VesselsTable: FC<IVesselsTableProps> = (props) => {
	const { vesselsToShow, isSelectionEnabled } = props;

	const classes = {
		th: "p-3 m-px text-xs font-medium leading-4 tracking-wide text-left text-gray-600 bg-gray-200 border border-gray-300 whitespace-nowrap",
		thNumber:
			"p-3 m-px text-xs font-medium leading-4 tracking-wide text-gray-600 bg-gray-200 border border-gray-300 whitespace-nowrap",
		td: "p-3 m-px text-sm leading-7 text-left  border whitespace-nowrap",
		tdNumber:
			"p-3 m-px text-sm leading-7 border whitespace-nowrap text-gray-700",
		tdVesselName:
			"p-3 m-px text-sm leading-6 text-left  border whitespace-nowrap text-indigo-800",
	};

	return (
		<div
			className='w-full'
		>
			<table
				className={classNames("w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive")}
			>
				<thead>
					<tr>
						<th className="text-sm text-center">#</th>
						<th className="text-sm">Name</th>
						<th className="text-sm">IMO</th>
						<th className="text-sm">From - To</th>
						<th className="text-sm">At</th>
						<th className="text-sm">Version</th>
						<th className="text-sm">Last Synced</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(vesselsToShow?.ids) &&
						vesselsToShow?.ids?.map((id, index) => {
							const vessel = vesselsToShow.byIds[id];
							if (!vessel) {
								return null;
							}
							const { vesselName, imoNumber, liveInfo } = vessel;

							return (
								<tr
									key={vessel.id}
									className="font-medium hover:bg-gray-100"
									onClick={() => {
										if (isSelectionEnabled) {
											props.selectVesselAndAdd(id);
										} else {
											props.onOnboardClick(vessel.id);
										}
									}}
								>
									<td
										className={classNames(
											classes.td,
											"text-gray-500 text-xs leading-6 text-center"
										)}
									>
										{index + 1}
									</td>
									<td className={classes.tdVesselName}>
										{vesselName}
									</td>
									<td className={classes.tdNumber}>
										{imoNumber}
									</td>
									<td
										className={classNames(classes.tdNumber)}
									>
										{`${liveInfo?.from?.local_reading || ''} ${liveInfo?.to?.local_reading? ` - ${liveInfo?.to?.local_reading}`: ''}`}
									</td>
									<td className={classes.tdNumber}>
										{liveInfo?.state_of_ship?.local_reading
											? `${liveInfo?.state_of_ship?.local_reading}`
											: ""}
									</td>
									<td className={classes.tdNumber}>
										{liveInfo?.versionInfo
											? `${liveInfo?.versionInfo?.FE} | ${liveInfo?.versionInfo?.BE} `
											: ""}
									</td>
									<td className={classes.tdNumber}>
										{liveInfo?.last_synced ? moment(liveInfo.last_synced).fromNow() : ""}
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

export default VesselsTable