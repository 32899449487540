const vesselOnboardConstants = {
	feature: "@vessel/onboard",
	forms: {
		CREW: "CREW",
		DEVICE: "DEVICE",
		VESSEL: "VESSEL",
	},
};

export const localRangeManagementHeaderList = [
	{ name: "S.no" },
	{ name: "Metric" },
	{ name: "Range" },
	{ name: "Date Modified" },
	{ name: "Remarks" },
	{ name: "Actions" },
];

export const configRangeManagementHeaderList = [
	{ name: "S.no" },
	{ name: "Metric" },
	{ name: "Range" },
	{ name: "Remarks" },
	{ name: "Actions" },
];

export const RANGE_MANAGEMENT_PER_PAGE = 15;

export default vesselOnboardConstants;
