import { formUtils } from "redux/_core/form";
import { IUserStore, IVesselUser } from "./@types";
import userConstants from "./constants";
const { USERS_PER_PAGE } = userConstants;

const userDefaults: IVesselUser = {
	email: "",
	name: "",
	password: "",
	role: "",
	roleCategory: "",
	staffId: "",
	tenantId: "",
	username: "",
};

const userFormDefaults = formUtils.getDefaults({
	fields: userDefaults,
	mode: "CREATE",
});

const userReducerState: IUserStore = {
	_jobs: {},
	users: {
		byIds: {},
		ids: [],
	},
	_forms: {
		USER: {
			...userFormDefaults,
		},
	},
	selectedUserId: "",
	userProfile: undefined,
	_pagination: {
		currentPage: 1,
		itemsPerPage: USERS_PER_PAGE,
		totalItems: 0,
	},
};

export default userReducerState;
export { userDefaults as userFormDefaults };
