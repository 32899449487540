import classNames from "classnames";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { historyActions } from "redux/_core/history";
import { TTableAlert, TTableHeader } from "redux/app/dashboard/@types";
import { tableColorMapper } from "redux/app/dashboard/utils";
import { IStore } from "redux/store";
import { rootRoutes } from "routes";

type OwnProps = {
	value: any;
	columnDescriptor: TTableHeader;
	isRowForColumnExpanded?: boolean;
	id: string;
	index: number;
	setRowExpander?: (rowId: string) => void;
};
type CellRendererProps = PropsFromRedux & OwnProps;
function CellRenderer(props: CellRendererProps) {
	const {
		value,
		columnDescriptor,
		isRowForColumnExpanded,
		id,
		index,
		setRowExpander,
	} = props;
	let cell: any = "";

	switch (columnDescriptor.key) {
		case "name": {
			const name = value;
			cell = (
				<div
					onClick={(e) => {
						e.stopPropagation();
						props.goToDashboard(
							rootRoutes.children.app.children.vessel.children.onboard.children.dashboard.url(
								id
							)
						);
					}}
				>
					{name}
				</div>
			);
			break;
		}

		case "fromTo":
			const fromTo = value;
			cell = <div>{fromTo}</div>;
			break;

		case "alerts": {
			const alerts = value as TTableAlert;
			const isAlertsThere = alerts ? !!Object.keys(alerts)?.length : false;
			if (isAlertsThere) {
				cell = (
					<div className="flex items-center space-x-4 ">
						{Object.keys(alerts).map((alert, index) => {
							return (
								<div key={index} className="flex items-center md:min-w-min">
									<div
										style={{ backgroundColor: tableColorMapper(alert) }}
										className="w-2 h-2 md:w-[13px] md:h-[13px] rounded-full"
									/>
									<p className="text-xs md:text-sm my-0 ml-2 min-w-[44px] md:min-w-min">
										{alerts[alert].length} issues
									</p>
								</div>
							);
						})}
					</div>
				);
			} else {
				cell = "All good :)";
			}

			break;
		}

		case "usage": {
			const usage = value as TTableAlert;
			const isUsageThere = usage ? !!Object.keys(usage)?.length : false;
			if (isUsageThere) {
				cell = (
					<div className="flex justify-between items-center">
						<div className="flex items-center space-x-4 ">
							{Object.keys(usage).map((usageKey) => {
								return (
									<div
										key={usageKey}
										className="flex items-center md:min-w-min"
									>
										<div
											style={{ backgroundColor: tableColorMapper(usageKey) }}
											className="w-2 h-2 md:w-[13px] md:h-[13px] rounded-full"
										/>
										<p className="text-xs md:text-sm m-0 ml-2 min-w-[44px] md:min-w-min">
											{usage[usageKey].length} issues
										</p>
									</div>
								);
							})}
						</div>
						<div
							onClick={() => setRowExpander && setRowExpander(id)}
							className=" flex justify-center items-center  cursor-pointer pr-4"
						>
							<span
								className={classNames("text-sm md:text-lg bp3-icon ", {
									"bp3-icon-chevron-down": isRowForColumnExpanded,
									"bp3-icon-chevron-right": !isRowForColumnExpanded,
								})}
							/>
						</div>
					</div>
				);
			} else {
				cell = "All good :)";
			}

			break;
		}

		default:
			cell = "";
	}

	return (
		<td
			key={"cell" + id + index}
			className={classNames(
				"px-3 py-3 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D] min-w-32"
			)}
		>
			{cell}
		</td>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToDashboard(route: string) {
			dispatch(historyActions.push("Dashboard", route));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(CellRenderer);
