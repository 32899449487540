import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";

type OwnProps = {
	value: string;
	autoFocus: boolean;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onDrop: (files: any) => void;
	onPaste: (files: any) => void;
	isChatExpanded: boolean;
	toggleChatExpander: () => void;
	noOfChatAttachments?: number;
};
type ChatMessageEditorProps = PropsFromRedux & OwnProps;
function ChatMessageEditor(props: ChatMessageEditorProps) {
	const {
		autoFocus,
		value,
		onChange,
		onDrop,
		onPaste,
		isChatExpanded,
		toggleChatExpander,
		noOfChatAttachments = 0,
	} = props;

	const [drag, setDrag] = useState(false);
	let dropRef = useRef<HTMLDivElement>(null);
	const handleDrag = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragIn = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
	};
	const handleDragOut = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setDrag(false);
	};

	const handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setDrag(false);
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			onDrop(e.dataTransfer.files);
			e.dataTransfer.clearData();
		}
	};

	useEffect(() => {
		let div = dropRef.current;
		div?.addEventListener("dragenter", handleDragIn);
		div?.addEventListener("dragleave", handleDragOut);
		div?.addEventListener("dragover", handleDrag);
		div?.addEventListener("drop", handleDrop);
		return () => {
			div?.removeEventListener("dragenter", handleDragIn);
			div?.removeEventListener("dragleave", handleDragOut);
			div?.removeEventListener("dragover", handleDrag);
			div?.removeEventListener("drop", handleDrop);
		};
	});

	const handlePaste = async (e: any) => {
		if (!e?.clipboardData?.files?.length) {
			return true;
		} else {
			e.preventDefault();
		}

		if (e?.clipboardData?.files?.length) {
			onPaste(e?.clipboardData?.files);
			navigator?.clipboard?.writeText("");
		}
	};

	useEffect(() => {
		let div = dropRef.current;
		div?.addEventListener("paste", handlePaste);
		return () => {
			div?.removeEventListener("paste", handlePaste);
		};
	});

	return (
		<div className="w-full relative" ref={dropRef}>
			{drag && (
				<div className="absolute w-full flex justify-center mt-12 font-medium">
					Drop the files here
				</div>
			)}
			<span
				className="absolute top-3 right-3 bp3-icon bp3-icon-fullscreen text-gray-600 cursor-pointer"
				onClick={() => toggleChatExpander()}
			/>
			<textarea
				// rows={4}
				className={classNames(
					"shadow appearance-none  w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-md resize-none transition hover:ease-in-out duration-300",
					{
						"border-dashed border-2 border-gray-800": drag,
						border: !drag,
						// "h-[calc(100vh-95px)]": isChatExpanded,
						// "h-[114px]": !isChatExpanded,
					}
				)}
				style={{
					height: isChatExpanded
						? `calc(100vh - ${
								(noOfChatAttachments ? 24 + noOfChatAttachments * 22 : 0) + 93.5
						  }px)`
						: 114,
				}}
				id="user-value"
				placeholder="Drop or paste your images/attachments here."
				tabIndex={0}
				value={value}
				autoFocus={autoFocus}
				onChange={onChange}
			/>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ChatMessageEditor);
