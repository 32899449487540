import { IListObjectStore } from "../../_common/type";
import { IJobHOCState } from "../../_core/job/types";
import { IForm, IFormStore } from "../../_core/form/types";
import { TAnnex6BackendRecord } from "../annex6/@types";
import { TBallastBackendRecord } from "../ballast/@types";
import { IGRB1BackendRecord, IGarbageRecord } from "../garbage-record/@types";
import { IGRB2, TGRB2BackendRecord } from "../grb2/@types";
import {
	TORB1ObservedQuantity,
	TORB1BackendRecord,
	TORB1Record,
} from "../orb1/@types";
import { TORB4BackendRecord } from "../orb4/@types";
import { IPagination } from "./helper.types";
import MARPOLModule from "./operation.types";

export enum EMARPOLModelName {
	MARPOL = "marpol",
	BALLAST = "BallastWaterRecordOperations",
}

export enum EMARPOLDocuments {
	ORB1 = "ORB1",
	ORB2 = "ORB2",
	GRB1 = "GARBAGE",
	GRB2 = "GRB2",
	BALLAST = "BALLAST",
	ANNEX6 = "ANNEX6",
	SOUNDING_LOG = "SOUNDING_LOG",
	BDN = "BDN",
	UNKNOWN = "UNKNOWN",
	BALLAST_WATER_RECORD_OPERATION = "BallastWaterRecordOperation",
	BALLAST_WATER_RECORD = "BallastWaterRecord",
}

export type TMARPOLRecord =
	| TORB1BackendRecord
	| TORB4BackendRecord
	| TBallastBackendRecord
	| TAnnex6BackendRecord
	| IGRB1BackendRecord
	| TGRB2BackendRecord
	| TSoundingRecordBackEnd;

export enum EMARPOLTankCategory {
	IOPP3P1 = "IOPP3.1",
	IOPP3P3 = "IOPP3.3",
	CARGO = "CARGO",
	SLOP_TANKS = "SLOP_TANKS",
	BALLAST = "BALLAST",
	OTHER = "OTHER",
}

export enum EMARPOLTankType {
	/**
	 * The water and oil leakage in the engine room is collected in bilge wells and this oily water mixture is then transferred to primary bilge tank or bilge holding tank, where the mixture is settled down and then is transferred to secondary bilge tank.
	 */
	BILGE_TANK = "BILGE_TANK",

	/**
	 * Ship generated sludge are the residue from any oil separation process which is usually a mixture of water, solid material and high viscosity oil. Sludge tanks Located in engine room, this tank is used to store sludge produced after treating fuel or lube oil through purifiers.
	 */
	SLUDGE_TANK = "SLUDGE_TANK",

	/**
	 * When oily water separator operates, it separates oil from water and that oil is collected and discharged into a separate tank known as O.W.S sludge tank.
	 */
	OWS_SLUDGE_TANK = "OWS_SLUDGE_TANK",

	/**
	 * Slop tanks are present onboard tanker to store oily water mixture from cargo tank washing.
	 */
	SLOP_TANK = "SLOP_TANK",

	/**
	 * Ballasting or de-ballasting is a process by which sea water is taken in and out of the ship when the ship is at the port or at the sea. The sea water carried by the ship is known as ballast water.To ensure its trim, stability and structural integrity.
	 */
	BALLAST_TANK = "BALLAST_TANK",

	/** Stores fuel oil in it. */
	FUEL_OIL_TANK = "FUEL_OIL_TANK",
	LUBE_OIL_TANK = "LUBE_OIL_TANK",
	DIESEL_OIL_TANK = "DIESEL_OIL_TANK",

	/**
	 * The drain tank is located in the engine room. All drip trays and other drains are connected to this tank.
	 */
	DRAIN_TANK = "DRAIN_TANK",

	/**
	 * The waste oil tank is a separate tank used to collect waste and impure oil produced onboard ship.
	 */
	WASTE_OIL_TANK = "WASTE_OIL_TANK",

	CARGO_TANK = "CARGO_TANK",
	WATER_BALLAST_TANK = "WATER_BALLAST_TANK",
	OTHER_TANK = "OTHER_TANK",
}

export enum EMARPOLTankPosition {
	PORT_SIDE = "PORT_SIDE",
	STARBOARD_SIDE = "STARBOARD_SIDE",
	CENTER = "CENTER",
	UNKNOWN = "UNKNOWN",
}

export type TMARPOLTank = {
	id: string;
	tankName: string;
	tankCode: string;
	type: EMARPOLTankType;
	lateralPosition: EMARPOLTankPosition;
	totalCapacity: number;
	valveStatus: "OPEN" | "CLOSED";
	percent: number;
	frames: string;
	category: EMARPOLTankCategory;
	/**
	 * @description Calculated quantity by wayship software
	 */
	expectedQuantity: number;
	/**
	 * @description Real time sounding quantity captured by user before and after any MARPOL operation.
	 */
	observedQuantity: TORB1ObservedQuantity;
	/**
	 * @description Sounding quantity on Week start used for finding descripancies in ORB1-C1 and I3/I2 and also in Automatic drainage case.
	 */
	soundingQuantity: number;

	/**
	 * @description Will be used for generating alerts and triggers whenever tank reaches certain quantity.
	 */
	quantitiesLimits: {
		minimum: number;
		maximum: number;
	};

	lastMarpolRecordedOperationDate?: string;
	lastSoundingRecordedOperationDate?: string;
	latestQuantitySource?: EMARPOLDocuments;
};

export type TMARPOLProcessedTank = {
	id: string;
	oldQuantity: number;
	newQuantity: number;
	observedQuantity: TORB1ObservedQuantity;
};

export type TMARPOLTankFromBackend = TMARPOLTank &
	Omit<TMARPOLTank, "expectedQuantity"> & {
		observedQuantityAsPerSounding: string;
		observedQuantityAsPerOps: number;
		fullname: string;
		soundingQuantityInCM: any;
	};

export type TMARPOLTankWithObservedQuantity = TMARPOLTank;

export enum ETankGroupCodes {
	ORB1 = "ORB1",
	ORB1_WITH_SLOP = "ORB1_WITH_SLOP",
	ORB1_WITH_OTHERS = "ORB1_WITH_OTHERS",
	ORB2 = "ORB2",
	ORB2_WITH_SLOP = "ORB2_WITH_SLOP",
	ORB2_WITH_OTHERS = "ORB2_WITH_OTHERS",
	BALLAST = "BALLAST",
	OTHERS = "OTHERS",
	SLOP = "SLOP_TANKS",
	ALL_TANKS = "ALL_TANKS",
}

export type TSoundingRecord = {
	recordCode: ETankGroupCodes;
	selectedDate: string;
	remarks: string;
	tanks: TSoundingRecordTank[];
};

export type TSoundingRecordBackEnd = TSoundingRecord & {
	id: string;
	submittedBy: MARPOLModule.RecordUser;
	marpolType: EMARPOLDocuments.SOUNDING_LOG;
	state: MARPOLModule.RecordState;
};

export type TSoundingRecordTank = {
	tankId: string;
	tankName: string;
	/**
	 * @description Sounding quantity in M³
	 */
	soundingQuantity?: number;
	soundingQuantityInCM?: number;
	lastSoundingQuantity?: number;
};

export type TMARPOLOperation =
	| TORB1Record
	| IGarbageRecord
	| IGRB2
	| TORB4BackendRecord
	| TBallastBackendRecord
	| TAnnex6BackendRecord;

export type TMARPOLFilters = {
	vesselId: string;
	documentType: EMARPOLDocuments;
	weekStart: string;
	weekEnd: string;
};

export interface IMARPOLStore extends IJobHOCState {
	docs: IListObjectStore<TMARPOLOperation>;
	selectedVesselId: string;
	marpolReport: TMARPOLReport;
	filters: TMARPOLFilters;
	_pagination: IPagination;
}

export type TAuditLog = {
	id: string;
	title: string;
	createdAt: string;
	_rev: string;
	localTime: string;
	resource: string;
	user: string;
	action: string;
	params: any;
};

export type TMARPOLReport = {
	SOUNDING_LOG: {
		lastSoundingLog?: TSoundingRecord;
	};
	ORB1: {
		lastInventoryRecords: {
			// [EORB1RecordCode.ORB1C1]?: TORB1BackendRecord;
			// [EORB1RecordCode.ORB1I3]?: TORB1BackendRecord;
		};
		lastSubmittedRecord?: TORB1BackendRecord;
		lastValidRecord?: TORB1BackendRecord;
		currentOperations: TORB1BackendRecord[];
	};
	ORB2: {
		lastSubmittedRecord?: TORB4BackendRecord;
		lastValidRecord?: TORB4BackendRecord;
		pendingRecords: TORB4BackendRecord[];
	};
	BALLAST: {
		lastSubmittedRecord?: TBallastBackendRecord;
		lastValidRecord?: TBallastBackendRecord;
		pendingRecords: TBallastBackendRecord[];
	};
	GRB1: {
		pendingRecords: IGRB1BackendRecord[];
	};
	GRB2: {
		pendingRecords: TGRB2BackendRecord[];
	};
	ANNEX6: {
		lastSubmittedRecord?: TAnnex6BackendRecord;
		lastValidRecord?: TAnnex6BackendRecord;
		pendingRecords: TAnnex6BackendRecord[];
	};
	MISC: {
		tenantId: string;
		tenantName: string;
	};
};

export type TMARPOLTankUpdateSource = {
	source: EMARPOLDocuments | "TANK_ONBOARD" | "SOUNDING_LOG";
	latestQuantity: number;
	updateDate: string;
	operationName: "Operational Entry" | "Sounding record" | "Tank onboard";
};
