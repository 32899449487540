import { IAPIRequestMetaData } from "../../_core/api/types";
import vesselActionTypes from "./action-types";

const {
	APPROVAL_LIST_LOAD,
	APPROVAL_BY_ID
} = vesselActionTypes;

const approvalAPIs = {
	listAPI(pageNumber = 1, itemsPerPage = 30): IAPIRequestMetaData<any> {
		return {
			feature: APPROVAL_LIST_LOAD,
			method: "GET",
			url: "api/permitLogs/approve",
			queryParams: {
				filter: {
					limit: itemsPerPage,
					skip: (pageNumber - 1) * itemsPerPage,
				},
			},
		};
	},
	updateVesselAPI(
		approval: any
	): IAPIRequestMetaData<any> {
		return {
			feature: APPROVAL_BY_ID,
			method: "PATCH",
			url: "api/permitLogs/" + approval.id,
			body: approval,
		};
	}
};

export default approvalAPIs;
