import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import VesselInfo from "./_elements/VesselInfo";
import Usage from "./_elements/Usage";
import Alerts from "./_elements/Alerts";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "redux/app/vessel/onboard";
import moment from "moment";
import classNames from "classnames";
import { commonUtils } from "redux/_common";
import TimeLogsWrapper from "./_elements/TimeLogsWrapper";

type OwnProps = { vesselId: string };
type VesselOnboardDashboardProps = PropsFromRedux & OwnProps;
function VesselOnboardDashboard(props: VesselOnboardDashboardProps) {
	const { vesselId, tenantId, jobToLoadUsageAndAlerts, jobToLoadTimeLogs, timeLogs } = props;

	const [dateRange, setDateRange] = useState({
		fromDate: moment().subtract(6, "d").toISOString(),
		toDate: moment().toISOString(),
	});
	const [isToDateDisabled, setIsToDateDisabled] = useState(false);

	const { fromDate, toDate } = dateRange;

	useEffect(() => {
		const { fromDate, toDate } = dateRange;
		props.loadUsageAndAlertsData(vesselId, fromDate, toDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, dateRange]);

	useEffect(() => {
		const isToDateSameAsCurrentDate = moment(dateRange.toDate).isSame(
			moment(),
			"day"
		);

		if (isToDateSameAsCurrentDate) setIsToDateDisabled(true);
		else setIsToDateDisabled(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange.toDate]);

	const handleLeftArrowClick = useCallback(
		commonUtils.debounce((dateRange) => {
			const newToDate = moment(dateRange.fromDate)
				.subtract(1, "d")
				.toISOString();
			setDateRange({
				fromDate: moment(newToDate).subtract(6, "d").toISOString(),
				toDate: newToDate,
			});
		}, 500),
		[]
	);

	const handleRightArrowClick = useCallback(
		commonUtils.debounce((dateRange) => {
			const newFromDate = moment(dateRange.toDate).add(1, "d").toISOString();
			setDateRange({
				fromDate: newFromDate,
				toDate: moment(newFromDate).add(6, "d").toISOString(),
			});
		}, 500),
		[]
	);

	return (
		<>
			<VesselInfo vesselId={props.vesselId} />
			<div className="my-2 mt-5 flex justify-center items-center vessel-dashboard relative">
				{/* <VesselDateRangePicker
					dateRange={dateRange}
					setDateRange={setDateRange}
				/> */}
				<div
					className="flex items-center justify-center"
				>
					<span
						className={classNames(
							"text-2xl bp3-icon bp3-icon-chevron-left font-semibold",
							{
								"cursor-pointer": !jobToLoadUsageAndAlerts.isLoading,
								"cursor-not-allowed": jobToLoadUsageAndAlerts.isLoading,
							}
						)}
						onClick={() => {
							if (jobToLoadUsageAndAlerts.isLoading) return;
							handleLeftArrowClick(dateRange);
						}}
					/>
					<div className="text-base px-2 ">
						{moment(fromDate).format("DD/MM/YYYY")} -{" "}
						{moment(toDate).format("DD/MM/YYYY")}
					</div>
					<span
						className={classNames(
							"text-2xl bp3-icon bp3-icon-chevron-right font-semibold",
							{
								"cursor-not-allowed text-gray-400":
									isToDateDisabled || jobToLoadUsageAndAlerts.isLoading,
								"cursor-pointer": !isToDateDisabled,
							}
						)}
						onClick={() => {
							if (isToDateDisabled || jobToLoadUsageAndAlerts.isLoading) return;
							handleRightArrowClick(dateRange);
						}}
					/>
				</div>
				<TimeLogsWrapper
					jobToLoadTimeLogs={jobToLoadTimeLogs}
					timeLogs={timeLogs}
					getTimeLogs={props.getTimeLogs}
					vesselId={vesselId}
				/>
			</div>
			<div className="flex flex-col lg:flex-row">
				<div className="py-4 mx-1 lg:w-1/2 md:mx-4 lg:mx-0 lg:pr-1">
					<Usage uiFromDate={fromDate} uiToDate={toDate} tenantId={tenantId} />
				</div>
				<div className="py-4 mx-1 lg:w-1/2 md:mx-4 lg:mx-0 lg:pl-1">
					<Alerts uiFromDate={fromDate} uiToDate={toDate} tenantId={tenantId} />
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const onboardStore = vesselOnboardSelectors._getStore(store);
	const jobToLoadUsageAndAlerts = vesselOnboardSelectors.getJobToLoadUsageAndAlertsData(onboardStore);
	const jobToLoadTimeLogs = vesselOnboardSelectors.jobToLoadTimeLogs(onboardStore);
	const timeLogs = vesselOnboardSelectors.getTimeLogs(onboardStore);
	return {
		tenantId: store.app.auth.user?.tenantId ?? "",
		jobToLoadUsageAndAlerts,
		jobToLoadTimeLogs,
		timeLogs,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadUsageAndAlertsData(
			vesselId: string,
			fromDate?: string,
			toDate?: string
		) {
			dispatch(
				vesselOnboardActions.command.dashboardUsabilityAlertsLoad(
					vesselId,
					fromDate,
					toDate
				)
			);
		},
		getTimeLogs(vesselId: string, page: number) {
			dispatch(
				vesselOnboardActions.command.getTimeLogs(vesselId, page)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselOnboardDashboard);
