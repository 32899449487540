import { Action } from "typesafe-actions";
import { IAction } from "./type";

export function invokerAction<T extends IAction<any, any>>(
	invoker: string,
	action: T,
	invokerType:
		| "FEATURE"
		| "MIDDLEWARE"
		| "UI-COMPONENT"
		| "OTHER"
		| "NOT-SPECIFIED" = "NOT-SPECIFIED"
): T {
	return {
		...action,
		type: `${invoker} [${action.type}]`,
		meta: {
			...(action.meta ? action.meta : {}),
			trueType: action.type,
			invokerType,
		},
	};
}
