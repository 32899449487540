import moment from "moment";
import { createReducer } from "typesafe-actions";
import withJobs from "../../_core/job/reducers";
import { EMARPOLDocuments, IMARPOLStore } from "./@types";
import { EORB1RecordCode } from "../orb1/@types";
import marpolActions, { EMARPOLConstants, TMARPOLActions } from "./actions";

const initialState: IMARPOLStore = {
	docs: {
		byIds: {},
		ids: [],
	},
	selectedVesselId: "",
	filters: {
		documentType: EMARPOLDocuments.ORB1,
		vesselId: "",
		weekStart: moment().subtract(14, "day").format("YYYY-MM-DD"),
		weekEnd: moment().format("YYYY-MM-DD"),
	},
	_jobs: {},
	_pagination: {
		currentPage: 1,
		itemsPerPage: 20,
		pageWiseListIds: {},
		totalItems: 100,
	},
	marpolReport: {
		SOUNDING_LOG: {
			lastSoundingLog: undefined,
		},
		ORB1: {
			currentOperations: [],
			lastInventoryRecords: {
				[EORB1RecordCode.ORB1C1]: undefined,
				[EORB1RecordCode.ORB1I3]: undefined,
			},
		},
		ORB2: {
			lastSubmittedRecord: undefined,
			lastValidRecord: undefined,
			pendingRecords: [],
		},
		ANNEX6: {
			pendingRecords: [],
		},
		BALLAST: {
			pendingRecords: [],
		},
		GRB1: {
			pendingRecords: [],
		},
		GRB2: {
			pendingRecords: [],
		},
		MISC: {
			tenantId: "",
			tenantName: "",
		},
	},
};

const marpolReducers = createReducer<IMARPOLStore, TMARPOLActions>(initialState)
	.handleAction(
		marpolActions.document.marpolDocsSet,
		function marpolDocsSet(state, action) {
			return {
				...state,
				docs: action.payload.docs,
			};
		}
	)
	.handleAction(
		marpolActions.document.marpolFiltersSet,
		function marpolFiltersSet(state, action) {
			return {
				...state,
				filters: action.payload.filters,
			};
		}
	);

export default withJobs(EMARPOLConstants.FEATURE, initialState, marpolReducers);
