export const toolConstants = {
	FEATURE: "@tool",
};

export enum CONFIG_TABS {
	TAB_LOGS = "logs",
	TAB_CHECKLISTS = "checklists",
	TAB_PERMITS = "permits",
}

export enum PRINT_TABS {
	TAB_LOGS = "logs",
	TAB_CHECKLISTS = "checklists",
	TAB_PERMITS = "permits",
}

const headerList = [
	{ name: "Select", className: "md:w-[65px] text-center" },
	{ name: "S.no" },
	{ name: "Name" },
	{ name: "Version" },
	{ name: "Last updated" },
	{ name: "Action" },
];

export const logHeaderList = headerList;
export const checklistHeaderList = headerList;
export const permitHeaderList = headerList;
export const printLogHeaderList = headerList;
export const printPermitHeaderList = headerList;
export const configPrintActivityLogHeaderList = [
	{ name: "S.no" },
	{ name: "Vessel Name" },
	{ name: "Module" },
	{ name: "Code" },
	{ name: "Action" },
	{ name: "Change" },
	{ name: "Version" },
	{ name: "Updated by" },
	{
		name: "Updated at",
	},
];
export const trainingCertificateHeaderList = [
	{ name: "S.no" },
	{ name: "Rank" },
	{ name: "Full name" },
	{ name: "Crew ID" },
	{ name: "Vessel Name" },
	{ name: "Is Relieved" },
	{ name: "Is Endorsed" },
	{ name: "Actions" },
];

export const CONFIGS_PER_PAGE = 10;
export const PRINTS_PER_PAGE = 10;
export const CP_ACTIVITY_LOGS_PER_PAGE = 20;
export const TRAINING_CERTIFICATE_PER_PAGE = 10;
