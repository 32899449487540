import React, { FC, useMemo, useState } from "react";
import Table from "../configandprint/_elements/Table";
import {
	TRAINING_CERTIFICATE_PER_PAGE,
	trainingCertificateHeaderList,
} from "redux/app/tool/constants";
import Td from "../configandprint/_elements/Td";
import ModalPortal from "components/app/_common/ModalPortal";
import CertificateView from "./CertificateView";
import { IVesselUser } from "redux/app/feedback/@types";
import { IListObjectStore } from "redux/_common/type";
import { IJobGetStatus } from "redux/_core/job/types";
import NumberedPagination from "components/_common/NumberedPagination";
import { IPagination } from "redux/app/tool/@types";
import ConfirmBox from "../configandprint/_elements/ConfirmBox";

interface ITrainingCertificateListProps {
	vesselSpecificUsers: IListObjectStore<IVesselUser>;
	jobToLoadTenants: IJobGetStatus;
	jobToLoadVesselSpecificUsers: IJobGetStatus;
	jobToLoadVesselSpecificToTenant: IJobGetStatus;
	editVesselUserTrainingDate: (userId: string, date: string) => void;
	toolUserTrainingCertificateApprove: (userId: string) => void;
	approveCertificate: boolean;
}

const TrainingCertificateList: FC<ITrainingCertificateListProps> = (props) => {
	const {
		jobToLoadTenants,
		jobToLoadVesselSpecificUsers,
		jobToLoadVesselSpecificToTenant,
		approveCertificate,
	} = props;
	const [currentUser, setCurrentUser] = useState<IVesselUser | null>(null);
	const [isUserCertificateModalVisible, setIsUserCertificateModalVisible] =
		useState(false);
	const [pagination, setPagination] = useState<IPagination>({
		totalItems: 0,
		itemsPerPage: TRAINING_CERTIFICATE_PER_PAGE,
		currentPage: 1,
	});
	const [isApproveCertificateModalOpen, setIsApproveCertificateModalOpen] =
		useState(false);

	const onCertificateRequest = (user: IVesselUser) => {
		setCurrentUser(user);
		setIsUserCertificateModalVisible(true);
	};

	const vesselUsers = useMemo(() => {
		const users = props.vesselSpecificUsers?.ids?.map((id) => {
			return props.vesselSpecificUsers?.byIds[id];
		});
		setPagination((prev) => {
			return {
				...prev,
				totalItems: users?.length || 0,
			};
		});
		return users;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.vesselSpecificUsers?.ids]);

	const isLoading = useMemo(() => {
		return (
			jobToLoadTenants.isLoading ||
			jobToLoadVesselSpecificUsers.isLoading ||
			jobToLoadVesselSpecificToTenant.isLoading
		);
	}, [
		jobToLoadTenants.isLoading,
		jobToLoadVesselSpecificUsers.isLoading,
		jobToLoadVesselSpecificToTenant.isLoading,
	]);

	return (
		<>
			{isUserCertificateModalVisible && (
				<ModalPortal
					isActive={isUserCertificateModalVisible}
					onClose={() => {
						// props.toggleCopyModal();
					}}
					// width={650}
					isOverlayClickCloseable={false}
					isFullWidth
				>
					{currentUser ? (
						<CertificateView
							user={currentUser}
							editVesselUserTrainingDate={
								props.editVesselUserTrainingDate
							}
							isWayshipSupportAccount={true}
							onClose={() => {
								setIsUserCertificateModalVisible(false);
							}}
							onUserChange={(user) => {
								setCurrentUser(user);
							}}
						/>
					) : null}
				</ModalPortal>
			)}
			<div className="px-4 border-t border-[#D8D8D8] mt-4">
				{/* <div className="flex items-center w-full mt-4">
					<input
						type="text"
						placeholder="Search"
						className=" p-2 border bg-[#F7F7F7] border-[#F7F7F7] h-full w-full rounded-md"
						// value={searchInputVal}
						onChange={(e) => {
							// props.setSearch(e.target.value);
						}}
					/>
				</div> */}
				<div className="my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<Table
						isEmpty={!isLoading && !vesselUsers.length}
						headers={trainingCertificateHeaderList}
						isLoading={isLoading}
					>
						{vesselUsers
							?.slice(
								(pagination.currentPage - 1) *
									(pagination.itemsPerPage ||
										TRAINING_CERTIFICATE_PER_PAGE),
								pagination.currentPage *
									(pagination.itemsPerPage ||
										TRAINING_CERTIFICATE_PER_PAGE)
							)
							.map((crew, index) => {
								return (
									<tr key={index} id={`${index}`}>
										<Td>
											{(pagination.currentPage - 1) *
												(pagination.itemsPerPage ||
													TRAINING_CERTIFICATE_PER_PAGE) +
												index +
												1}
										</Td>
										<Td>{crew.designation?.name}</Td>
										<Td>{crew.name}</Td>
										<Td>{crew.staffId}</Td>
										<Td>{crew.vesselName || ""}</Td>
										<Td>
											{crew.isRelieved ? "Yes" : "No"}
										</Td>
										<Td className="w-28">
											{crew.isEndorsed
												? `Yes by - ${
														crew?.endorsedBy &&
														crew?.endorsedBy
															?.length > 0
															? crew?.endorsedBy?.map(
																	(e) =>
																		e.designation
															  )
															: "NA"
												  }`
												: "No"}
										</Td>
										<Td className="py-1">
											<div className="relative cursor-pointer">
												<div
													className="inline-flex items-center justify-center h-4 text-blue-500 underline cursor-pointer"
													onClick={() => {
														if (
															approveCertificate
														) {
															setCurrentUser(
																crew
															);
															setIsApproveCertificateModalOpen(
																true
															);
														} else {
															onCertificateRequest(
																crew
															);
														}
													}}
												>
													{approveCertificate
														? "Approve Certificate"
														: crew?.trainingDate
														? "View Certificate"
														: "Generate Certificate"}
												</div>
											</div>
										</Td>
									</tr>
								);
							})}
					</Table>
					<NumberedPagination
						{...{
							...pagination,
							totalItems: pagination?.totalItems || 0,
						}}
						itemIds={[]}
						onPageChange={function onPageChange(
							pageNumberFromPagination
						) {
							setPagination((prev) => {
								return {
									...prev,
									currentPage: pageNumberFromPagination,
								};
							});
						}}
						onItemsPerPageChange={(itemsPerPage) => {
							setPagination((prev) => {
								return {
									...prev,
									itemsPerPage,
								};
							});
						}}
						isChangeItemsPerPageVisible
						isPageLoading={isLoading}
					/>

					<ConfirmBox
						title={`Do you want to issue a training certificate to ${currentUser?.name} with staffId ${currentUser?.staffId}?`}
						closeBtnLabel="No"
						confirmBtnLabel="Yes"
						isActive={isApproveCertificateModalOpen}
						onClose={() => {
							setIsApproveCertificateModalOpen(false);
							setCurrentUser(null);
						}}
						modalSize={500}
						onConfirm={() => {
							props.toolUserTrainingCertificateApprove(
								(currentUser as any)._id
							);
							setIsApproveCertificateModalOpen(false);
							setCurrentUser(null);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default TrainingCertificateList;
