import { ILog } from "../../../redux/app/logs/@types";
import { IDiscrepanciesArray, IDiscrepancy, ISidebarObj } from "./types"

function getDefaultSidebarObj(): ISidebarObj {
	return {
		discrepanciesArray: [],
		onClickEvent: false,
		selectedDate: '',
		title: '',
	}
}

function getWatchHour(logData: ILog) {
	if (logData.data?.watchHour && typeof logData.data?.watchHour === 'string' && logData.data?.watchHour?.length === 8) {
		return logData.data?.watchHour;
	}
	const startWatchHour = logData.data?.startWatchHour;
	const endWatchHour = logData.data?.endWatchHour;
	const watchHour = ((startWatchHour? startWatchHour: "") + (endWatchHour? endWatchHour: ""));
	return watchHour;
}

function getDiscrepanciesArray(logData: ILog | undefined, logDataArray?: ILog[] | ILog) {
	const newLogDataArray = (logDataArray && Array.isArray(logDataArray) && logDataArray.length > 0)? logDataArray: (logData? [ logData ]: []);
	const set: {
		[key: string]: IDiscrepancy[]
	} = {
		
	};
	let count = 0;
	let discrepanciesArray: IDiscrepanciesArray = [];
	if (newLogDataArray && newLogDataArray?.length > 0) {
		newLogDataArray.forEach((logData) => {
			const discrepancies = logData?.discrepancies;
			if (Array.isArray(discrepancies)) {
				const watchHour = getWatchHour(logData);
				if (watchHour && typeof watchHour === 'string' && watchHour.trim().length > 0) {
					count += discrepancies.length;
					if (!Array.isArray(set[watchHour])) {
						set[watchHour] = [];
					}
					set[watchHour].push(...discrepancies);
				}
			}
		});
		discrepanciesArray = Object.entries(set).map(([watchHour, discrepancies]) => ({watchHour, discrepancies}));
	}
	return {
		count,
		discrepanciesArray
	}
}

export {
	getDiscrepanciesArray,
	getDefaultSidebarObj,
	getWatchHour,
}