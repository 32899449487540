import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupG1 } from "../../../../../redux/app/orb1/groups/group-g";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupG1PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupG1Preview(props: ORB1GroupG1PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupG1;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "G", 22, renderItem22()],
					[null, null, 23, renderItem23()],
					[null, null, 24, renderItem24()],
					[null, null, 25, renderItem25()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem22() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item22.timeOfOccurence} LT
			</h4>
		);
	}

	function renderItem23() {
		return (
			<h4 className="font-medium uppercase">
				POSITION LAT: {orb1Record.item23.position.latitude} LON:{" "}
				{orb1Record.item23.position.longitude}
			</h4>
		);
	}

	function renderItem24() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item24.typeOfOil} QUANTITY OF OIL{" "}
				{orb1Record.item24.approxQuantity.toFixed(2)} M³
			</h4>
		);
	}

	function renderItem25() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item25.circumstancesOfDischargeRemarks}
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupG1Preview;
