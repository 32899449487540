import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupE2 } from "../../../../../redux/app/orb1/groups/group-e";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupE2PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupE2Preview(props: ORB1GroupE2PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupE2;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "E", 16, renderItem16()],
					[null, null, null, renderSource()],
					[null, null, 18, renderItem18()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem16() {
		return (
			<h4 className="font-medium uppercase">
				PUMP START AT {orb1Record.item16.start.time}HRS,{" "}
				{orb1Record.item16.position.latitude},{" "}
				{orb1Record.item16.position.longitude}
			</h4>
		);
	}

	function renderSource() {
		return orb1Record.item16.bilgeSource;
	}

	function renderItem18() {
		return (
			<h4 className="font-medium uppercase">
				STOP: {orb1Record.item16.stop.time}HRS
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupE2Preview;
