import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupE3 } from "../../../../../redux/app/orb1/groups/group-e";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupE3PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupE3Preview(props: ORB1GroupE3PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupE3;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "E", 17, renderItem17()],
					[null, null, 18, renderItem18()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem17() {
		return (
			<h4 className="font-medium uppercase">
				TRANSFER START: {orb1Record.item17.transferTime.time}
				HRS, TO {orb1Record.item17.destinationTank.tankName}
			</h4>
		);
	}

	function renderItem18() {
		return (
			<h4 className="font-medium uppercase">
				STOP: {orb1Record.item18.manualOperationSetOn.time}HRS
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupE3Preview;
