import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4B1 } from "../../../../../redux/app/orb4/groups/orb4-b";
import { commonUtils } from "redux/_common";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4B1PreviewProps = OwnProps;
function ORB4B1Preview(props: ORB4B1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4B1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "B", 4.1, renderItem4P1()],
					[null, null, 4.2, renderItem4P2()],
					[null, null, 5, renderItem5()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem4P1() {
		return (
			<h4 className="font-medium uppercase">
				FROM {orb4Record.item4P1.sourceTank.tankName}
			</h4>
		);
	}
	function renderItem4P2() {
		return (
			<h4 className="font-medium uppercase">
				TO {orb4Record.item4P2.destinationTank.tankName}, QUANTITY TRANSFERRED :
				{orb4Record.item4P1.transferQuantity}M³, <br />
				TOTAL QUANTITY OF TANK: {orb4Record.item4P2.destinationTank.tankName} =
				{commonUtils.addUp(
					orb4Record.item4P2.destinationTank.expectedQuantity,
					orb4Record.item4P1.transferQuantity
				)}
				M³
			</h4>
		);
	}
	function renderItem5() {
		return (
			<h4 className="font-medium uppercase">
				TANK EMPTIED -{" "}
				{orb4Record.item5.isTankEmptied ? (
					"YES"
				) : (
					<>
						NO <br />
						RETAINED {orb4Record.item5.quantityRetained}
						M³
					</>
				)}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4B1Preview;
