import React, { FC } from "react";
import { SwitchUI } from "./SwitchUI";

const Table: FC<{ table: any,isEnableAllForExternal: boolean }> = ({ table,isEnableAllForExternal }) => {
	return (
		<section className="w-full flex flex-col gap-5">
			<h1 className="text-3xl font-extrabold">{table.title}</h1>
			<div data-key={table.key} className="flex flex-col gap-2 border border-gray-500 p-2">
				{table.columns.map((c: any) => {
					const newField = {
						...c,
						key: `${c.key}_${0}`,
						data: {
							key: "table",
							value: table.key,
						},
					};
					return <SwitchUI key={c.key} field={newField} isEnableAllForExternal={isEnableAllForExternal} />;
				})}
			</div>
			<div>
				<button data-key="table" id={table.key} className="bg-blue-500 px-5 py-2 rounded-md text-white">
					Create New
				</button>
			</div>
		</section>
	);
};

export default Table;
