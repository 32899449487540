import classNames from "classnames";
import React from "react";
import moment from "moment";

type OwnProps = {};
type Annex6OldVersionPreviewProps = OwnProps;
function Annex6OldVersionPreview(props: Annex6OldVersionPreviewProps) {
	return (
		<div className="m-px text-sm font-medium leading-7 text-left text-gray-500 bg-white">
			<h4 className="font-medium uppercase">
				<div className="flex flex-row items-center justify-center p-3 px-6 font-medium text-left bg-gray-100 border-2 border-gray-400 border-dashed">
					<div>
						<div>
							<div>
								Annex VI upgrade caused issues in preview of older records.{" "}
								<br />
								Data is safe. A fix will be delivered shortly.
							</div>
						</div>
					</div>
					<span className="ml-6 text-gray-400 bp3-icon bp3-icon-time"></span>
				</div>
			</h4>
		</div>
	);
}

export default Annex6OldVersionPreview;
