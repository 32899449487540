import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import insightSelectors from "redux/app/insight/selectors";
import { IStore } from "redux/store";
import plusIcon from "assets/icons/plus.svg";
import { EParameterTabType, IMetricListItem } from "redux/app/insight/@types";
import insightActions from "redux/app/insight/actions";
import { IListObjectStore } from "redux/_common/type";
import { storeMetricsToLocalStorage } from "redux/app/insight/utils";
import ArrowedString from "../../ArrowedString";

type OwnProps = {};
type EngineMetricParameterListProps = PropsFromRedux & OwnProps;
function EngineMetricParameterList(props: EngineMetricParameterListProps) {
	const { engineLogMetricsList, searchText, selectedMetrics, stateOfShip } =
		props;
	const [tempSelectedMetrics, setTempSelectedMetrics] = useState<string[]>([]);

	useEffect(() => {
		setTempSelectedMetrics(selectedMetrics);
	}, [selectedMetrics]);

	const engineLogMetricsListWithSearch = engineLogMetricsList.ids.filter(
		(idKey) => {
			const item = engineLogMetricsList.byIds[idKey];
			const { section, subSection, metric } = item;
			const textBeingSearched = searchText.toLowerCase();
			return (
				metric?.toLowerCase().includes(textBeingSearched) ||
				subSection?.toLowerCase().includes(textBeingSearched) ||
				section?.toLowerCase().includes(textBeingSearched)
			);
		}
	);

	return (
		<div>
			<div className={classNames("px-4 overflow-y-auto h-[calc(100vh-365px)]")}>
				{engineLogMetricsListWithSearch.map((metricKey) => {
					const listItem = engineLogMetricsList.byIds[metricKey];
					const { section, subSection, metric, key } = listItem;
					return (
						<div
							key={key}
							className="flex justify-between items-center py-2.5 pb-1.5 border-b border-gray-300 cursor-pointer"
							onClick={() => {
								let updatedTempSelectedMetrics = [...tempSelectedMetrics];

								updatedTempSelectedMetrics = tempSelectedMetrics.includes(key)
									? updatedTempSelectedMetrics.filter((m) => m !== key)
									: [...updatedTempSelectedMetrics, key];

								setTempSelectedMetrics(updatedTempSelectedMetrics);
							}}
						>
							<div className="w-[calc(100%-20px)]">
								<div className="text-gray-500 text-[12px]">
									<ArrowedString
										strArr={[section ?? "", subSection ?? ""]}
										isSingle={false}
									/>
								</div>
								<h4 className="text-sm">{metric} </h4>
							</div>
							<div>
								{tempSelectedMetrics.includes(key) ? (
									<span className="bp3-icon bp3-icon-tick text-lg text-green-500" />
								) : (
									<img
										className="cursor-pointer"
										src={plusIcon}
										alt="plus icon"
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
			<div
				onClick={() => {
					if (!stateOfShip) {
						props.setStateOfShipError(true);
						return;
					}

					storeMetricsToLocalStorage<IMetricListItem>(
						tempSelectedMetrics,
						engineLogMetricsList,
						EParameterTabType.ENGINE_LOG_TAB
					);
					props.updateSelectedMetrics(tempSelectedMetrics);
					props.toggleParameterMetricsList(false);
					props.setSearchText("");
				}}
				className="border-t flex justify-center items-center h-[46px] text-sm cursor-pointer "
			>
				Apply
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const selectedMetrics =
		insightSelectors.getEngineLogSelectedMetrics(insightStore);
	const engineLogMetricsList =
		insightSelectors.getEngineLogMetricsList(insightStore);
	const searchText = insightSelectors.getSearchInputText(insightStore);
	const engineLogConfig = insightSelectors.getEngineLogConfig(insightStore);
	const stateOfShip = insightSelectors.getStateOfShip(insightStore);
	return {
		engineLogConfig,
		engineLogMetricsList,
		searchText,
		selectedMetrics,
		stateOfShip,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleParameterMetricsList(value?: boolean) {
			dispatch(insightActions.document.toggleParameterMetricList(value));
		},
		updateSelectedMetrics(metrics: string[]) {
			dispatch(
				insightActions.document.recentSelectedMetricToSelectedMetricsAdd(
					metrics
				)
			);
		},
		setEngineLogMetricsList(metricsList: IListObjectStore<IMetricListItem>) {
			dispatch(insightActions.document.engineLogMetricsListSet(metricsList));
		},
		setStateOfShipDropdownOptions(options: { label: string; value: string }[]) {
			dispatch(insightActions.document.stateOfShipDropdownOptionsSet(options));
		},
		setStateOfShipError(stateOfShipError: boolean) {
			dispatch(
				insightActions.document.insightStateOfShipError(stateOfShipError)
			);
		},
		setSearchText(searchText: string) {
			dispatch(insightActions.document.searchInputSet(searchText));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(EngineMetricParameterList);
