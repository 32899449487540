import extActionTypes from "./action-types";
import { apiActions } from "../../_core/api";
import { Middleware } from "redux";
import { IStore } from "../../store";
import vesselActions from "./actions";
import extAPIs from "./apis";
import logActions, { logJobActions } from "../logs/actions";
import extActions from "./actions";
import { debug } from "console";
import { historyActions } from "../../_core/history";
import { getType } from "typesafe-actions";
import { rootRoutes } from "../../../routes";

const { apiRequest } = apiActions;
const { LOAD_TASK, FORM_UPDATE } = extActionTypes;

const extMiddleware: Middleware<IStore> = ({ dispatch, getState }) => (
	next
) => (action) => {
	switch (action.type) {
		case LOAD_TASK: {
			const job = vesselActions._jobs.vesselListLoad;
			dispatch(
				apiRequest<{
					task: any;
				}>({
					...extAPIs.getTask(action.payload),
					preExecute: () =>
						dispatch(job.active({ message: "Logging in!!!" })),
					postExecute: {
						onSuccess: (response) => {
							if (response.status === 200) {
								dispatch(
									job.success({
										notification: { hideAtState: "SUCCESS", timeout: 500 },
									})
								);

								dispatch(extActions.extUserAuthenticated(true));
								const action = extActions.setExtForm(response.data);
								dispatch(action);
								let url;
								if (action.payload.result[0].code === "CL_TEC_01") {
									url =
										rootRoutes.children.ext.children.form.children.tec01.url;
								} else if (action.payload.result[0].code === "CL_TNK_07") {
									url =
										rootRoutes.children.ext.children.form.children.tnk07.url;
								} else {
									url = rootRoutes.children.ext.children.login.url;
									dispatch(
										job.error({ message: "No exts found!!", notification: {} })
									);
								}
								dispatch(historyActions.push("@ext", url()));
							} else
								dispatch(
									job.error({ message: "No exts found!!", notification: {} })
								);
						},
						onError: (error) => {
							dispatch(
								job.error({
									message: "Email or Password is Invalid!"
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case FORM_UPDATE: {
			next(action);
			const store = getState();
			const extStore: any = store.app.extStore;
			const job = vesselActions._jobs.formUpdate;
			dispatch(
				apiRequest({
					...extAPIs.updatePermit(
						action.payload,
						extStore.extForm.id,
						extStore.extForm
					),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess() {
							const url = rootRoutes.children.ext.children.login.url;
							if (extStore.extForm.externalCollaborators[0].isSigned) {
								dispatch(historyActions.replace("@ext", url()));
							} else {
								dispatch(
									job.success({ message: "Data saved successfully", notification: {} })
								);
							}
						},
						onError() {
							dispatch(
								job.error({ message: "Data save failed", notification: {} })
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
		}
		default: {
			next(action);
		}
	}
};
export default extMiddleware;
