import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { marpolSelectors } from "../../../../redux/app/marpol";
import { EMARPOLDocuments } from "../../../../redux/app/marpol/@types";
import { IStore } from "../../../../redux/store";
import Annex6Docs from "./Annex6Docs";
import BallastDocs from "./BallastDocs";
import GarbageRecordDocs from "./grb1/GarbageRecordDocs";
import GRB2Docs from "./GRB2/GRB2Docs";
import ORB1Docs from "./ORB1Docs";
import ORB2Docs from "./ORB2Docs";

type OwnProps = {};
type MARPOLDocumentManagerProps = PropsFromRedux & OwnProps;
function MARPOLDocumentManager(props: MARPOLDocumentManagerProps) {
	const { isVesselSelected, filters } = props;
	const { documentType } = filters;

	switch (documentType) {
		case EMARPOLDocuments.GRB1:
			return <GarbageRecordDocs disabled={!isVesselSelected} />;
		case EMARPOLDocuments.GRB2:
			return <GRB2Docs disabled={!isVesselSelected} />;
		case EMARPOLDocuments.ORB1:
			return <ORB1Docs disabled={!isVesselSelected} />;
		case EMARPOLDocuments.ORB2:
			return <ORB2Docs disabled={!isVesselSelected} />;
		case EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION:
			return <BallastDocs disabled={!isVesselSelected} />;
		case EMARPOLDocuments.ANNEX6:
			return <Annex6Docs disabled={!isVesselSelected} />;
		default:
			return null;
	}
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const marpolStore = marpolSelectors._getStore(store);
	return {
		isVesselSelected: marpolSelectors.isVesselSelected(marpolStore),
		filters: marpolSelectors.getFilters(marpolStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(MARPOLDocumentManager);
