import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4C1 } from "../../../../../redux/app/orb4/groups/orb4-c";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4C1PreviewProps = OwnProps;

function ORB4C1Preview(props: ORB4C1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4C1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "C", 6, renderItem6()],
					[null, null, 7, renderItem7()],
					[null, null, 8, renderItem8()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem6() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item6.placeOfUnloading}
			</h4>
		);
	}
	function renderItem7() {
		return (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={orb4Record.item7.unloadedTanks.map(
						(tank) => tank.selectedTank.tankName
					)}
				/>
			</h4>
		);
	}
	function renderItem8() {
		return (
			<h4 className="font-medium uppercase">
				TANK EMPTIED -{" "}
				{orb4Record.item8.areTanksEmptied ? (
					"YES"
				) : (
					<>
						NO, <br />
						<CommaSeparator
							items={orb4Record.item7.unloadedTanks.map(
								(tank) =>
									`${tank.selectedTank.tankName} ${tank.item8QuantityRetained}M³ @15°C`
							)}
						/>
					</>
				)}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4C1Preview;
