import { Action } from "redux";
import actionTypes from "./action-types";

const { MULTI_BATCH_ACTION, MULTI_DISPATCH_ACTION } = actionTypes;

const multiDispatchActions = (actions: Action[], meta = {}) => ({
	type: MULTI_DISPATCH_ACTION,
	payload: { actions },
	meta,
});
const dispatchInBatch = (actions: Action[], meta = {}) => ({
	type: MULTI_BATCH_ACTION,
	payload: { actions },
	meta,
});
const multiActions = {
	batchActions: dispatchInBatch,
	dispatchIndividually: multiDispatchActions,
};

export default multiActions;
