import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupB1 } from "../../../../../redux/app/annex6/groups/group-b";
import { marpolUtils } from "redux/app/marpol";
import CommaSeparator from "components/_common/CommaSeparator";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupA1PreBiewProps = OwnProps;
function Annex6GroupB1Preview(props: Annex6GroupA1PreBiewProps) {
    const record = props.annex6Record as TAnnex6GroupB1;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'B', 3.0, renderItem3()],
                    [null, null, 4.1, renderItem4P1()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }
    function renderItem3() {
        const attachmentId = record.isAutoCreated
            ? record.attachmentSourceId
            : record.id;
        const attachmentRev = record.isAutoCreated
            ? record.attachmentSourceRev
            : record._rev;
        // const link = record.item3.bdnAttachmentLink;
        return (
            <>
                BDN REFERENCE NO: {record.item3.BDN}
                {/* {link ? (
                    <>
                        <br />
                        <a
                            href={marpolUtils.getAttachmentURL(
                                link,
                                attachmentId,
                                attachmentRev
                            )}
                            target="_blank"
                        >
                            {link}
                        </a>
                    </>
                ) : null} */}
            </>
        );
    }
    function renderItem4P1(): React.ReactNode {
        const sealNumbers = `${record.item4P1.marpolSampleSealNo}`.split(',');
        return (
            <>
                RECEIVED MARPOL SAMPLE SEAL NUMBERS:{' '}
                <CommaSeparator items={sealNumbers} customSeperator={' | '} />
            </>
        );
    }

    return renderMain();
}

export default Annex6GroupB1Preview;
