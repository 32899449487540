import moment from 'moment';
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import Button from "../../../../@set-product/core/button/Button";
import { checklistActions } from "../../../../redux/app/checklist";
import { logSelectors } from "../../../../redux/app/logs";
import { IStore } from "../../../../redux/store";
import { historyActions } from '../../../../redux/_core/history';
import { rootRoutes } from '../../../../routes';
import PermitSignModal from "../../../../screens/ext-forms/permitSignModal";
import Modal from "../../_common/Modal";

let tableJson = [
	{
		index: 1,
		qTxt: `Detailed entries recorded in Official Log Book and signed by Master  and Chief Engineer or Chief Officer`,
		id: "de_en_re_le_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 2,
		qTxt: `Detailed entries recorded in Cargo / Oil Record Book (deck) and signed by Master and Chief Officer`,
		id: "de_en_re__ch_in_ca",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 3,
		qTxt: `Detailed entries recorded in Oil Record Book (E/R) and signed by Master and Chief Engineer`,
		id: "de_en_re_oi_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 4,
		qTxt: `Detailed entries recorded in Engine Log Book  and signed by Master and Chief Engineer`,
		id: "de_re_en_lo_la_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 5,
		qTxt: `Detailed entries recorded in Deck Log Book  and signed by Master and Chief Officer`,
		id: "de_en_re_de_lo_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 6,
		qTxt: `Detailed entries recorded in GMDSS  Log  and signed by Master and GMDSS delegate officer`,
		id: "de_en_gmdss_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 7,
		qTxt: `Record of  cargo, ballast and bunker Tank and other void spaces or cofferdam soundings / Ullages (as applicable)`,
		id: "re_ca_ba_bu_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 8,
		qTxt: `Record of seabed soundings around the vessel, incase of grounding`,
		id: "re_se_so_ar_ve_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 9,
		qTxt: `Charterers Informed and hard copy filed onboard`,
		id: "ch_in_ha_co_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 10,
		qTxt: `USCG Informed and hard copy filed onboard`,
		id: "us_in_ha_fi_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 11,
		qTxt: `Qualified Individual informed and hard copy filed onboard`,
		id: "qa_in_in_ha_co_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 12,
		qTxt: `Local Pollution Control Office informed and hard copy filed onboard`,
		id: "lo_po_co_of_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 13,
		qTxt: `Local agent informed and hard copy filed onboard`,
		id: "lo_ag_in_ha_co_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 14,
		qTxt: `Attach scanned copies of all Logs, Statement Of  Facts, Printer Records, Cargo plans, Log Book entries, Bell Book entries, E/R logger entries ,PMS printouts ,weather reports, Check Lists, Forms, Navigation warnings, etc. as applicable.`,
		id: "at_sc_co_lo_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 15,
		qTxt: `VDR (Voyage Data Recorder) hard drive sent to office for recovering/analyzing data`,
		id: "vdr_ha_dr_da",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 16,
		qTxt: `Owner informed`,
		id: "ow_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 17,
		qTxt: `P & I  office informed`,
		id: "pi_of_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 18,
		qTxt: `Classification Society informed `,
		id: "cl_sc_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 19,
		qTxt: `H & M Underwriter informed`,
		id: "hm_un_in",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 20,
		qTxt: `Flag State informed`,
		id: "fa_st_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 21,
		qTxt: `Next of Kin informed`,
		id: "ne_ki_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 22,
		qTxt: `Manning Office informed`,
		id: "ma_of_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 23,
		qTxt: `Charterers Informed`,
		id: "ch_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 24,
		qTxt: `Flag State Informed`,
		id: "fl_st_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 25,
		qTxt: `Damage Stability Provider informed`,
		id: "da_st_pr_in_ra",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 25,
		qTxt: `Damage Stability Provider informed`,
		id: "da_st_pr_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 26,
		qTxt: `Media Consultant informed`,
		id: "ma_co_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 27,
		qTxt: `Master advised to direct media enquiries to shore office.`,
		id: "ma_ad_di_ma_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 28,
		qTxt: `Office Reception /switchboard personnel instructed NOT to answer any media questions & re-direct calls to emergency response team`,
		id: "of_re_sw_pe_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 29,
		qTxt: `Situation reports (sitreps) prepared for Media and other Third parties as applicable`,
		id: "si_re_pr_me_ot_th_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 30,
		qTxt: `Legal advisor informed`,
		id: "le_ad_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 31,
		qTxt: `Salvage authorities informed`,
		id: "sa_au_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 32,
		qTxt: `Hospital informed`,
		id: "ho_in_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 33,
		qTxt: `Documentation relating to repairs and correspondence with repair  facility`,
		id: "do_re_re_co_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 34,
		qTxt: `Drug and Alcohol test carried on board using Saliva test kit and Alcoholmeter or D&A test carried out by external agency`,
		id: "dr_al_te_ca_bo",
		actionby: "Ship",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 34,
		qTxt: `Drug and Alcohol test carried on board using Saliva test kit and Alcoholmeter or D&A test carried out by external agency`,
		id: "dr_al_te_ca_bo",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 35,
		qTxt: `Record / log of all emergency response related communications `,
		id: "re_lo_al_ra",
		actionby: "Office",
		options: ["Yes", "No", "NA"],
	},
	{
		index: 36,
		qTxt: `Conduct an Extraordinary Safety meeting to discuss this incident/accident and create MIPS/SCM reports`,
		id: "co_ex_sa_me_di_ra",
		actionby: "Vessel",
		options: ["Yes", "No", "NA"],
	},
];

const RadioGroup = (props: { extForm: any; updateData: any; d: any }) => {
	const { extForm, updateData, d } = props;
	//console.log("Id ->" + d.id + " Ques -> " + d.qTxt)
	return (
		<fieldset id={d.id}>
			{d.options.map((item: string, index: number) => (
				<>
					<input
					    className="m-2"
						key={item}
						type="radio"
						name={d.id + "::remote"}
						checked={extForm?.data?.[d.id+ "::remote"] === item ? true : false}
						onChange={function onChange(e) {
							updateData(d.id+"::remote", item);
						}}
					/>
					<label
						onClick={function onClick(e) {
							updateData(d.id+"::remote", item);
						}}
					>
						{item}
					</label>
				</>
			))}
		</fieldset>
	);
};

const RowElement = (props: {
	extForm: any;
	updateData: any;
	updateTable: any;
}) => {
	const { extForm, updateData, updateTable } = props;

	const _ui = tableJson.map((d, index) => {
		return (
			<tr>
				<td className="text-center border border-black">{d.index}</td>
				<td className="border border-black">{d.qTxt}</td>
				<td className="text-center border border-black">{d.actionby}</td>
				<td className="text-center border border-black">
					{d.actionby == "Ship" || d.actionby == "Vessel" ? (
						(extForm?.data?.[d.id] ? extForm?.data?.[d.id] : "") + " " + 
						(extForm?.data?.[d.id + "_remark"]
							? "Remark: " + extForm?.data?.[d.id + "_remark"]
							: "") + " " + 
						(extForm?.data?.[d.id + "_date"]
							? "Date: " + extForm?.data?.[d.id + "_date"]
							: "")
					) : (
						<div>
							<RadioGroup extForm={extForm} updateData={updateData} d={d} />
							{
								extForm?.data?.[d.id + "::remote"] === "NA" ? 
									(
									<div className="flex"> {"Remarks:"}
									<textarea
									className="border"
									value={
										extForm?.data?.[d.id + "_remark::remote"]
											? extForm?.data?.[d.id + "_remark::remote"]
											: ""
									}
									onChange={function onChange(e) {
										updateData(d.id + "_remark::remote", e.target.value);
									}}></textarea></div>
									)
								: ""
							}
							
							<input
								type="date"
								className="w-full"
								value={
									extForm?.data?.[d.id + "_date::remote"]
										? extForm?.data?.[d.id + "_date::remote"]
										: ""
								}
								onChange={function onChange(e) {
									updateData(d.id + "_date::remote", e.target.value);
								}}
							></input>
						</div>
					)}
				</td>
			</tr>
		);
	});
	return <tbody>{_ui}</tbody>;
};

const ChecklistSaf01: React.FC<any> = (props: any) => {
	const logData = props;
	// console.log("Vessel name" , logData?.vesselName ? logData?.vesselName : "")

	const [isModalOpen, setModalOpen] = useState(false);

	function triggerSubmitOperation() {
		setModalOpen(true);
	}

	function resetOperations() {
		setModalOpen(false);
	}

	useEffect(() => {
		if(logData.id === undefined) {
			props.formRedirect()
		}
	}, []);

	/**
	 *This is to update store with key and value
	 * @param key key of the attributes
	 * @param val value to be set
	 */
	function updateData(key: string, val: string) {
		if (key) {
			props.formFieldEdit({
				...logData,
				data: { ...logData.data, [key]: val },
			});
		}
	}

	/**
	 * This is the update table value in store
	 * @param key which key should be updated
	 * @param val value of the paramter
	 * @param parentKey parent element key
	 * @param index index in table
	 */
	function updateTable(
		key: string,
		val: string,
		parentKey: string,
		index: number
	) {
		const data = { ...logData };
		if (key) {
			if (data?.data?.[parentKey]?.[index]?.[key]) {
				data.data[parentKey][index][key] = val;
			} else {
				if (logData.data[parentKey] === undefined) {
					logData.data[parentKey] = [];
				}
				if (logData.data[parentKey].length === 0) {
					logData.data[parentKey].push({});
					data.data[parentKey][index][key] = val;
				} else if (data.data[parentKey][index]) {
					data.data[parentKey][index][key] = val;
				} else {
					logData.data[parentKey].push({});
					data.data[parentKey][index][key] = val;
				}
			}
			props.formFieldEdit({ ...data });
		}
	}

	return (
		<div className="w-full h-full bg-white ws-scroll">
			<div className="m-20 text-lg">
				<div className="sm:mx-auto">
					<h5 className="p-2 text-center">
						CL SAF 01 POST INCIDENT / ACCIDENT CHECKLIST
					</h5>

					<form
						action=""
						onSubmit={function onSubmit(e) {
							e.preventDefault();
							props.setFormSubmit(true);	
							props.formSubmit();
						}}
					>
						<div className="grid grid-cols-2 m-5">
							<div className="pl-5 text-center border border-black item-center">
								Ship Name:
							</div>
							<div className="border border-black">
								<textarea
									className="w-full"
									disabled
									value={logData?.vesselName ? logData?.vesselName : ""}
								/>
							</div>
						</div>

						<table className="w-full border border-collapse border-black">
							<thead className="border border-black">
								<th className="">S.No</th>
								<th className="border border-black">
									<div className="pl-1 text-2xl font-bold">ITEM</div>
								</th>
								<th className="border border-black">ACTION BY Ship / Office</th>
								<th className="border border-black">
									YES / NO / NA (If Yes completed on)
								</th>
							</thead>
							<RowElement
								extForm={logData}
								updateData={updateData}
								updateTable={updateTable}
							/>
						</table>

						<div className="justify-end p-0 my-3 bp3-dialog-footer-actions">
							<Button
								text="Sign"
								view="filled"
								color="primary"
								onClick={() => {
									triggerSubmitOperation();
								}}
								className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
							/>
							<Button
								text="Submit"
								view="filled"
								type="submit"
								color="primary"
								className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
							/>
						</div>
					</form>
				</div>
			</div>
			<Modal isActive={!!isModalOpen} onClose={() => resetOperations()}>
				{isModalOpen ? (
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							<PermitSignModal
								onClose={function onModelClose() {
									resetOperations();
								}}
								onSign={function onModelSign() {
									const data = { ...logData };
									const index = data.collaborators.findIndex((item:any) => item.rank === "TS");
									if(index > -1) {
										data.collaborators[index].isSigned = true;
										data.collaborators[index].signedOn = new Date();
										props.formFieldEdit({ ...data });
									}
									props.setFormSubmit(true);
									props.formSubmit();
									resetOperations();
								}}
							></PermitSignModal>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
};
function mapStateToProps(store: IStore) {
	const checklistStore: any = store.app.checklistStore;
	const selectedChecklist = checklistStore.checklist;
	return selectedChecklist;
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		formFieldEdit(value: any) {
			this.setFormSubmit(false)
			dispatch(checklistActions.checklistSelect(value));
		},
		formSubmit(value: any) {
			dispatch(checklistActions.updateChecklist(value));
		},
		setFormSubmit(value: boolean) {
			dispatch(checklistActions.checklistSubmit(value));
		},
		formRedirect() {
			const url = rootRoutes.children.app.children.checklist.children.list.url;
			dispatch(historyActions.push("@checklist", url()));
			dispatch(checklistActions.listLoad(true));
		}
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
export default reduxConnector(ChecklistSaf01);
