import actionTypes from "./action-types";
import { IPromiseAction } from "./types";
const { PROMISE_ACTION } = actionTypes;

const promiseActionInvokerCreator = (invoker: string) => ({
	PROMISE_ACTION: `${invoker} [${PROMISE_ACTION}]`,
});
const promiseActions = <ISuccess, IError = any>(
	promise: Promise<ISuccess>,
	{
		preExecute,
		onSuccess,
		onError,
		finally: finalAction,
		invoker,
	}: IPromiseAction<ISuccess, IError>
) => ({
	type: promiseActionInvokerCreator(invoker).PROMISE_ACTION,
	payload: promise,
	meta: {
		preExecute,
		onSuccess,
		onError,
		finally: finalAction,
		trueType: PROMISE_ACTION,
	},
});

export default promiseActions;
