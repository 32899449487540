import React from "react";
import classNames from "classnames";
import DuplicateHTML from "../../_elements/DuplicateHTML";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../../redux/store";
import { marpolSelectors } from "../../../../../redux/app/marpol";

type OwnProps = {};
type GRB1TableProps = React.PropsWithChildren<OwnProps>;
function GRB1Table(props: GRB1TableProps & PropsFromRedux) {
	const { isEPS } = props;

	return (
		<table className="w-full mt-1 bg-gray-100 border rounded-md">
			<thead>
				<tr className="bg-gray-100">
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2="w-24"
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Date and Time {isEPS ? <>(UTC)</> : null}
								</div>
							)}
						/>
					</th>
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2="w-36"
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Position of the ship (latitude/longitude) or port if disposed
									of ashore or name of ship if discharged to another ship
								</div>
							)}
						/>
					</th>
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2="w-20"
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Category
								</div>
							)}
						/>
					</th>
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2="w-24"
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Estimated amount discharged into sea (M³)
								</div>
							)}
						/>
					</th>
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2="w-24"
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Estimated amount discharged to reception facilities or another
									ship (M³)
								</div>
							)}
						/>
					</th>
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2="w-24"
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Estimated amount incinerated (M³)
								</div>
							)}
						/>
					</th>
					<th className="relative">
						<DuplicateHTML
							classNamesForCopy1="absolute top-px bottom-px left-px right-px"
							classNamesForCopy2=""
							duplicateHTML={(className) => (
								<div
									className={classNames(
										"p-3 m-px text-sm font-medium leading-4 tracking-wide text-left text-gray-600 align-text-top bg-gray-200 border-b border-gray-300",
										className
									)}
								>
									Remarks (eg start/stop time and position of inceneration),{" "}
									<br />
									Reason for discharge or accidental loss and general remarks.
								</div>
							)}
						/>
					</th>
				</tr>
			</thead>
			<tbody>{props.children}</tbody>
		</table>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const marpolStore = marpolSelectors._getStore(store);
	const marpolReport = marpolSelectors.getMARPOLReport(marpolStore);
	const tenantName = marpolReport.MISC.tenantName;
	const isEPS = tenantName === "EPS";
	return {
		isEPS,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GRB1Table);
