import React from "react";
import VesselOnboardTanks from "../../../../../components/app/vessel/onboard/tanks/Tanks";

type ScreenVesselOnboardTanksType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
		};
	};
}>;
const ScreenVesselOnboardTanks: React.FC<ScreenVesselOnboardTanksType> = (
	props: ScreenVesselOnboardTanksType
) => {
	return <VesselOnboardTanks vesselId={props.match.params.vesselId} />;
};

export default ScreenVesselOnboardTanks;
