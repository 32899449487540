import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Select, {
	ActionMeta,
	GroupBase,
	OptionsOrGroups,
	PropsValue,
	SingleValue,
} from "react-select";
import { IOption } from "redux/app/feedback/@types";
import classNames from "classnames";
import clsx from "clsx";
import {
	clearIndicatorStyles,
	controlStyles,
	dropdownIndicatorStyles,
	menuStyles,
	optionStyles,
	placeholderStyles,
	selectInputStyles,
} from "redux/app/feedback/utils";

type OwnProps = {
	id?: string;
	placeholder?: string;
	isDisabled?: boolean;
	value?: PropsValue<IOption> | undefined;
	options?: OptionsOrGroups<IOption, GroupBase<IOption>> | undefined;
	handleChange?:
		| ((
				newValue: SingleValue<IOption>,
				actionMeta: ActionMeta<IOption>
		  ) => void)
		| undefined;
	isLoading?: boolean;
	key?: string;
};
type FeedbackFilterDropdownProps = PropsFromRedux & OwnProps;
function FeedbackFilterDropdown(props: FeedbackFilterDropdownProps) {
	const {
		placeholder,
		isDisabled,
		value,
		options,
		handleChange,
		id,
		isLoading,
		key,
	} = props;
	return (
		<Select
			key={key}
			isClearable
			isSearchable
			id={id}
			placeholder={placeholder}
			isDisabled={isDisabled}
			unstyled
			value={value}
			options={options}
			isLoading={isLoading}
			onChange={(newValue, actionMeta) =>
				handleChange && handleChange(newValue, actionMeta)
			}
			className={classNames("w-full text-sm", {
				"cursor-not-allowed": isDisabled,
			})}
			menuPosition="fixed"
			classNames={{
				option: ({ isFocused, isSelected }) =>
					clsx(
						isFocused && optionStyles.focus,
						isSelected && optionStyles.selected,
						optionStyles.base
					),

				dropdownIndicator: () => dropdownIndicatorStyles,
				menu: () => menuStyles,
				clearIndicator: () => clearIndicatorStyles,
				placeholder: () => placeholderStyles,
				control: ({ isFocused, isDisabled }) =>
					clsx(
						isFocused ? controlStyles.focus : controlStyles.nonFocus,
						isDisabled ? controlStyles.disabled : controlStyles.base
					),

				valueContainer: ({ isDisabled }) =>
					clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
			}}
		/>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackFilterDropdown);
