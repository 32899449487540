import React, { useEffect } from "react";
import classNames from "classnames";
import InfiniteProgress from "../../../../../_common/InfiniteProgress";
import { Dispatch } from "redux";
import { IStore } from "../../../../../../redux/store";
import { connect, ConnectedProps } from "react-redux";
import {
	vesselOnboardSelectors,
	vesselOnboardActions,
} from "../../../../../../redux/app/vessel/onboard";
import InputWrapper from "../../../../../_common/InputWrapper";
import Button from "../../../../../../@set-product/core/button";
import WSDropdown from "../../../../../_common/Dropdown";
import ReactJson from "react-json-view";
import { IVesselCrew } from "../../../../../../redux/app/vessel/types";
import { crewFormDefaults } from '../../../../../../redux/app/vessel/onboard/reducers.state';

const uiStaticData = vesselOnboardSelectors.getCrewFormStaticData();

type CrewFormType = PropsFromRedux & {
	title: string;
	subtitle: string;
	onCancel(): void;
	onClose(): void;
};
function CrewForm(props: CrewFormType) {
	const { form, jobs, subtitle, title, crewDesignations } = props;
	const { hasErrors, helpMessages, fields, errors, warnings } = form;
	useEffect(
		function onLoad() {
			if (!jobs.crewDesignationLoad.isExecuted)
				props.loadCrewDesignations();
			if (jobs.crewCreate.isSuccess || jobs.crewUpdate.isSuccess)
				props.onClose();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[jobs.crewCreate.isSuccess, jobs.crewUpdate.isSuccess]
	);
	return (
		<div
			className={classNames(
				"w-full h-full my-6 bg-white bp3-dialog py-3",
				{}
			)}
		>
			<div className="justify-between p-4 px-8 pt-6 bp3-dialog-header">
				<div className="flex flex-col px-2">
					<h4 className="text-xl font-medium text-gray-700 font-redhat-display">
						{title}
					</h4>
					<h5
						className={classNames("text-sm text-gray-600", {
							"text-red-500 font-medium": hasErrors,
						})}
					>
						{hasErrors ? "Form has few errors" : subtitle}
					</h5>
				</div>
				<button
					aria-label="Close"
					onClick={() => {
						props.resetForm();
						props.onClose();
					}}
					className="p-4 text-2xl bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
				/>
			</div>
			<InfiniteProgress
				isLoading={
					jobs.crewLoad.isLoading ||
					jobs.crewCreate.isLoading ||
					jobs.crewUpdate.isLoading
				}
				isSpacedOut={false}
			/>

			<div
				className="p-8 py-0 mx-0 overflow-y-auto bg-white rounded-sm bp3-dialog-body ws-scroll"
				style={{ maxHeight: 460 }}
			>
				<form
					className="flex flex-row flex-wrap items-baseline justify-start p-2 -mx-2 -my-2"
					onSubmit={function onSubmit(e) {
						e.preventDefault();
						props.formSubmit();
					}}
					autoComplete="off"
				>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.name.isValid}
								hasWarning={!warnings.name.isValid}
								inputValue={fields.name}
							>
								<label
									htmlFor="field-id"
									className="ws-input__label"
								>
									{uiStaticData.name.label}
								</label>
								<input
									type="text"
									className="ws-input"
									placeholder={uiStaticData.name.placeholder}
									value={fields.name}
									onChange={function onNameChange(e) {
										props.formFieldEdit({
											name: e.target.value,
										});
									}}
								/>
								<span className="ws-input__error">
									{errors.name.message}
								</span>
								<span className="ws-input__warning">
									{warnings.name.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.name}
								</span>
							</InputWrapper>
						</div>
					</div>
					{/* <div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.email.isValid}
								hasWarning={!warnings.email.isValid}
								inputValue={fields.email}
							>
								<label htmlFor="field-id" className="ws-input__label">
									{uiStaticData.email.label}
								</label>
								<input
									type="text"
									className="ws-input"
									placeholder={uiStaticData.email.placeholder}
									value={fields.email}
									onChange={function onEmailChange(e) {
										props.formFieldEdit({ email: e.target.value });
									}}
								/>
								<span className="ws-input__error">{errors.email.message}</span>
								<span className="ws-input__warning">
									{warnings.email.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.email}
								</span>
							</InputWrapper>
						</div>
					</div> */}
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.designation.isValid}
								hasWarning={!warnings.designation.isValid}
								inputValue={fields.designation}
							>
								<label
									htmlFor="field-id"
									className="ws-input__label"
								>
									{uiStaticData.designation.label}
								</label>
								<WSDropdown
									id="crew-designation-id"
									inputWrapperProps={{
										hasError: !errors.designation.isValid,
										hasWarning:
											!warnings.designation.isValid,
										inputValue: fields.designation,
									}}
									value={fields.designation}
									placeholder={
										uiStaticData.designation.placeholder
									}
									options={(() => {
										return crewDesignations.map(
											(designation) => {
												return {
													value: designation,
													label: designation,
												};
											}
										);
									})()}
									onSelect={function onDesignationChange(e) {
										props.formFieldEdit({
											designation: e?.value,
										});
									}}
									noFilter={true}
								/>
								<span className="ws-input__error">
									{errors.designation.message}
								</span>
								<span className="ws-input__warning">
									{warnings.designation.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.designation}
								</span>
								<InfiniteProgress
									className="ws-input__progress"
									isLoading={
										jobs.crewDesignationLoad.isLoading
									}
									isSpacedOut={false}
									isSmall={true}
								/>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.staffId.isValid}
								hasWarning={!warnings.staffId.isValid}
								inputValue={fields.staffId}
							>
								<label
									htmlFor="field-id"
									className="ws-input__label"
								>
									{uiStaticData.staffId.label}
								</label>
								<input
									type="text"
									className="ws-input"
									placeholder={
										uiStaticData.staffId.placeholder
									}
									value={fields.staffId}
									onChange={function onStaffIdChange(e) {
										props.formFieldEdit({
											staffId: e.target.value,
										});
									}}
								/>
								<span className="ws-input__error">
									{errors.staffId.message}
								</span>
								<span className="ws-input__warning">
									{warnings.staffId.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.staffId}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.fromDate.isValid}
								hasWarning={!warnings.fromDate.isValid}
								inputValue={fields.fromDate}
							>
								<label
									htmlFor="field-id"
									className="ws-input__label"
								>
									{uiStaticData.fromDate.label}
								</label>
								<input
									type="date"
									className="ws-input"
									placeholder={
										uiStaticData.fromDate.placeholder
									}
									value={fields.fromDate}
									onChange={function onFromDateChange(e) {
										props.formFieldEdit({
											fromDate: e.target.value,
										});
									}}
								/>
								<span className="ws-input__error">
									{errors.fromDate.message}
								</span>
								<span className="ws-input__warning">
									{warnings.fromDate.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.fromDate}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.toDate.isValid}
								hasWarning={!warnings.toDate.isValid}
								inputValue={fields.toDate}
							>
								<label
									htmlFor="field-id"
									className="ws-input__label"
								>
									{uiStaticData.toDate.label}
								</label>
								<input
									type="date"
									className="ws-input"
									placeholder={
										uiStaticData.toDate.placeholder
									}
									value={fields.toDate}
									onChange={function onFromDateChange(e) {
										props.formFieldEdit({
											toDate: e.target.value,
										});
									}}
								/>
								<span className="ws-input__error">
									{errors.toDate.message}
								</span>
								<span className="ws-input__warning">
									{warnings.toDate.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.toDate}
								</span>
							</InputWrapper>
						</div>
					</div>
					{/* <div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.image.isValid}
								hasWarning={!warnings.image.isValid}
								inputValue={fields.image}
							>
								<label htmlFor="field-id" className="ws-input__label">
									{uiStaticData.image.label}{" "}
									<span className="text-gray-400">(Optional)</span>
								</label>
								{jobs.crewImageUpload.isLoading ? (
									<label
										htmlFor="crew-image-id"
										className="flex flex-col w-1/3 p-2 text-center border cursor-pointer ws-input hover:bg-gray-200 animate-pulse"
									>
										<span className="mx-2 my-2 font-medium text-gray-500">
											Uploading
										</span>
										<span className="mx-2 my-2 text-xl text-gray-500 bp3-icon-upload bp3-icon" />
									</label>
								) : fields.image ? (
									<label
										htmlFor="crew-image-id"
										tabIndex={0}
										id="crew-image"
										className="flex flex-col w-1/3 p-2 text-center border cursor-pointer ws-input hover:bg-gray-200"
									>
										<img
											src={fields.image}
											alt="Profile"
											className="h-20"
											style={{ minHeight: "3.5rem" }}
										/>
									</label>
								) : (
									<label
										htmlFor="crew-image-id"
										tabIndex={0}
										id="crew-image"
										className="flex flex-col w-1/3 p-2 text-center border cursor-pointer ws-input hover:bg-gray-200"
									>
										<span className="mx-2 mt-2 text-xl text-gray-500 bp3-icon-mugshot bp3-icon" />
										<span className="mx-2 my-2 font-medium text-gray-500">
											{uiStaticData.image.placeholder}
										</span>
									</label>
								)}
								<input
									type="file"
									name="crew-image-id"
									id="crew-image-id"
									className="invisible h-0 p-0 m-0 border-0"
									placeholder={uiStaticData.image.placeholder}
									onChange={function onImageChange(e) {
										const files = (e.target as HTMLInputElement).files;
										if (files) props.imageUpload(files[0]);
									}}
								/>
								<span className="ws-input__error">{errors.image.message}</span>
								<span className="ws-input__warning">
									{warnings.image.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.image}
								</span>
							</InputWrapper>
						</div>
					</div> */}
					<div className="justify-start w-full p-0 my-3 bp3-dialog-footer-actions">
						<Button
							text="Submit"
							loading={
								jobs.crewLoad.isLoading ||
								jobs.crewCreate.isLoading ||
								jobs.crewUpdate.isLoading
							}
							view="filled"
							type="submit"
							color="primary"
							className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
						/>
						<Button
							text="Cancel"
							view="smooth"
							className="mx-2 font-medium "
							onClick={() => {
								props.resetForm();
								props.onCancel();
							}}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	return vesselOnboardSelectors.getCrewFormData(store.app.vesselStore.onboard);
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		load(crewId: string) {
			dispatch(vesselOnboardActions.command.crewLoad(crewId));
		},
		loadCrewDesignations() {
			dispatch(vesselOnboardActions.command.crewDesignationsLoad());
		},
		formModeSet(mode: "CREATE" | "EDIT" | "NONE") {
			dispatch(vesselOnboardActions._forms.crew.formModeSet(mode));
		},
		formFieldEdit(fields: Partial<IVesselCrew>) {
			dispatch(vesselOnboardActions._forms.crew.formFieldsEdit(fields));
		},
		formSubmit() {
			dispatch(vesselOnboardActions._forms.crew.formSubmit());
		},
		imageUpload(file: File) {
			dispatch(vesselOnboardActions.command.crewImageUpload(file));
		},
		resetForm() {
			dispatch(
				vesselOnboardActions._forms.crew.formFieldsSet(crewFormDefaults)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(CrewForm);
