import classNames from 'classnames';
import React, { FC } from 'react'
import { IData } from '.';
import { ERANGE_MGMT_TYPES, ESIGNAL_REPORT_TYPE_VAL, ILocalRange } from 'redux/app/vessel/onboard/types';
import { ModalType } from '../Ranges';
import Button from "@set-product/core/button/Button";
import { formatSRTypeValues } from 'redux/app/vessel/onboard/utils';

interface IButtonsProps {
    className?: string;
    btn1: {
        isVisible: boolean;
        className?: string;
        text: string;
    }
    btn2: {
        isVisible: boolean;
        className?: string;
        text: string;
    }
    modalType: ModalType;
    data: IData;
    onCancel: () => void
    onSubmit: (localRange: ILocalRange) => void;
    setRangeErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
	isTabActive?: boolean;
    dropdownType?: ERANGE_MGMT_TYPES;
    signalReportType?: ESIGNAL_REPORT_TYPE_VAL
}

const getTitle = (title?: string, isLast?: boolean) => {
    if (title && title?.trim()?.length > 0) {
        if (isLast) return title
        return title + " > ";
    }
    return '';
}

const getMetric = (data: IData) => {
    return getTitle(data.metric.label) + getTitle(data.subModules.label) + getTitle(data.modules.label) + getTitle(data.section.label) + getTitle(data.category.label, true);
}

const Buttons: FC<IButtonsProps> = ({ btn1, btn2, className, data , modalType, onCancel, onSubmit, setRangeErrorMsg,isTabActive,
    dropdownType,
    signalReportType}) => {

  return (
    <div className={classNames("flex flex-row items-center justify-end gap-x-4 mt-4", className)}>
        {btn1.isVisible && <Button
            className={classNames("bg-red-500")}
            onClick={(e) => {
                e.preventDefault();
                let metric = data.metric.label || '';
                if (modalType === ModalType.Add) {
                    metric = getMetric(data);
                }
                let minRange = data.range[0];
                let maxRange = data.range[1];

                const isMinRangeEmpty = minRange === undefined || minRange === null || `${minRange}`.trim().length === 0;
                const isMaxRangeEmpty = maxRange === undefined || maxRange === null || `${maxRange}`.trim().length === 0;
                if (setRangeErrorMsg) {
                    if (isMinRangeEmpty && isMaxRangeEmpty) {
                        setRangeErrorMsg("Both range can't be empty");
                        return;
                    } else if (!isMinRangeEmpty && !isMaxRangeEmpty && Number(minRange) >= Number(maxRange)) {
                        setRangeErrorMsg("Min range can't be equal to or greater than Max range");
                        return;
                    }
                }
                if (isMinRangeEmpty) {
                    minRange = undefined;
                } else {
                    const v = Number(minRange);
                    if (isNaN(v)) {
                        minRange = undefined
                    } else {
                        minRange = v;
                    }
                }
                if (isMaxRangeEmpty) {
                    maxRange = undefined
                } else {
                    const v = Number(maxRange);
                    if (isNaN(v)) {
                        maxRange = undefined
                    } else {
                        maxRange = v;
                    }
                }

				let metricVal: string = metric;
                if (isTabActive) {
                    //INFO: Takes into account the tab
                    const splittedMetric = metric
                        ?.split('>')
                        .map((s) => s.trim());
                    splittedMetric?.splice(
                        splittedMetric?.length - 1,
                        0,
                        data?.tab?.label ?? ''
                    );
                    metricVal = splittedMetric?.join(' > ');
                }




                onSubmit({
                    key: isTabActive
                        ? `${data.key}${
                              dropdownType ===
                              ERANGE_MGMT_TYPES.ENGINE_LOG
                                  ? '_cur_eng_'
                                  : '_cur_'
                          }${data?.tab?.label}`
                        : data.key,
                    range: [minRange, maxRange] as [number, number],
                    metric: metricVal,
                    remarks: data?.remarks,
                    type: dropdownType === ERANGE_MGMT_TYPES.ENGINE_LOG
                                    ? undefined
                                    : formatSRTypeValues(signalReportType),
                });
            }}
        >
            {btn1.text}
        </Button>}
        <Button
            className={classNames("bg-blue-500", btn2.className)}
            onClick={(e) => {
                e.preventDefault();
                onCancel();
            }}
        >
            {btn2.text}
        </Button>
    </div>
  )
}

export default Buttons