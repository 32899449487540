import React from "react";
import { marpolUtils } from "../../../../redux/app/marpol";

type AttachmentPreviewProps = {
	id: string;
	_rev: string;
	receiptNumber: string;
	receiptAttachment: string;
};

const AttachmentsPreview = (props: AttachmentPreviewProps) => {
	const { receiptNumber, receiptAttachment, id, _rev } = props;
	return (
		<>
			<br />
			RECEIPT NO: {receiptNumber}
			<br />
			ATTACHMENT:{" "}
			<a
				href={marpolUtils.getAttachmentURL(receiptAttachment, id, _rev)}
				download={receiptAttachment}
			>
				{" "}
				{receiptAttachment}
			</a>
		</>
	);
};

export default AttachmentsPreview;
