import { createReducer } from "typesafe-actions";
import { IReportStore } from "./@types";
import reportActions, { TReportActions } from "./actions";
import withJobs from "redux/_core/job/reducers";
import reportConstants, { quarterLabels } from "./constants";
import moment from "moment";

const defaultState: IReportStore = {
	_jobs: {},
	garbage: {
		rawGarbageData: [],
		vesselId: "",
		year: moment().year().toString(),
		allVesselsGarbageDischargeData: [],
		allVesselsGarbageCategoryData: [],
	},
	bilgeWater: {
		quarterLabels: [...quarterLabels],
		rawBilgeWaterData: [],
		vesselId: "",
		year: moment().year().toString(),
		allVesselBilgeWaterData: [],
	},
	ors: {
		vesselId: "",
		year: moment().year().toString(),
		rawOrsData: [],
		rawOrsVesselSpecificData: [],
	},
	cargoSlop: {
		rawCargoSlopData: [],
		rawCargoSlopVesselSpecificData: [],
	},
	oilSpillsInWater: {
		rawOilSpillsInWaterData: [],
		rawOilSpillsInWaterVesselSpecificData: [],
	},
	vesselId: "",
	year: moment().year().toString(),
};

const reportReducer = createReducer<IReportStore, TReportActions>(defaultState)
	.handleAction(
		reportActions.document.garbageRawDataSet,
		function garbageRawDataSet(state, action) {
			const { garbageRawData } = action.payload;
			return {
				...state,
				garbage: {
					...state.garbage,
					rawGarbageData: garbageRawData,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.reportVesselIdSet,
		function reportVesselIdSet(state, action) {
			const { vesselId } = action.payload;
			return {
				...state,
				vesselId,
			};
		}
	)
	.handleAction(
		reportActions.document.reportYearSet,
		function reportYearSet(state, action) {
			const { year } = action.payload;
			return {
				...state,
				year,
			};
		}
	)
	.handleAction(
		reportActions.document.garbageReportVesselIdSet,
		function garbageReportVesselIdSet(state, action) {
			const { vesselId } = action.payload;
			return {
				...state,
				garbage: {
					...state.garbage,
					vesselId,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.garbageReportYearSet,
		function garbageReportYearSet(state, action) {
			const { year } = action.payload;
			return {
				...state,
				garbage: {
					...state.garbage,
					year,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.bilgeWaterRawDataSet,
		function bilgeWaterRawDataSet(state, action) {
			const { bilgeWaterRawData } = action.payload;
			return {
				...state,
				bilgeWater: {
					...state.bilgeWater,
					rawBilgeWaterData: bilgeWaterRawData ?? null,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.bilgeWaterReportVesselIdSet,
		function bilgeWaterReportVesselIdSet(state, action) {
			const { vesselId } = action.payload;
			return {
				...state,
				bilgeWater: {
					...state.bilgeWater,
					vesselId,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.bilgeWaterReportYearSet,
		function bilgeWaterReportYearSet(state, action) {
			const { year } = action.payload;
			return {
				...state,
				bilgeWater: {
					...state.bilgeWater,
					year,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.bilgeWaterAllVesselDataSet,
		function bilgeWaterAllVesselDataSet(state, action) {
			const { vesselBilgeWaterRecords } = action.payload;
			return {
				...state,
				bilgeWater: {
					...state.bilgeWater,
					allVesselBilgeWaterData: vesselBilgeWaterRecords,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.garbageDischargeAllVesselDataSet,
		function garbageDischargeAllVesselDataSet(state, action) {
			const { garbageDischargeRecords } = action.payload;
			return {
				...state,
				garbage: {
					...state.garbage,
					allVesselsGarbageDischargeData: garbageDischargeRecords,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.garbageCategoryAllVesselDataSet,
		function garbageCategoryAllVesselDataSet(state, action) {
			const { garbageCategoryRecords } = action.payload;
			return {
				...state,
				garbage: {
					...state.garbage,
					allVesselsGarbageCategoryData: garbageCategoryRecords,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.orsReportVesselIdSet,
		function orsReportVesselIdSet(state, action) {
			const { vesselId } = action.payload;
			return {
				...state,
				ors: {
					...state.ors,
					vesselId,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.orsReportYearSet,
		function orsReportYearSet(state, action) {
			const { year } = action.payload;
			return {
				...state,
				ors: {
					...state.ors,
					year,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.orsRawDataSet,
		function orsRawDataSet(state, action) {
			const { rawOrsData } = action.payload;
			return {
				...state,
				ors: {
					...state.ors,
					rawOrsData,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.orsVesselSpecificRawDataSet,
		function orsVesselSpecificRawDataSet(state, action) {
			const { rawVesselSpecificOrsData } = action.payload;
			return {
				...state,
				ors: {
					...state.ors,
					rawOrsVesselSpecificData: rawVesselSpecificOrsData,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.cargoSlopRawDataSet,
		function cargoSlopRawDataSet(state, action) {
			const { rawCargoSlopData } = action.payload;
			return {
				...state,
				cargoSlop: {
					...state.cargoSlop,
					rawCargoSlopData,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.cargoSlopVesselSpecificRawDataSet,
		function cargoSlopVesselSpecificRawDataSet(state, action) {
			const { rawVesselSpecificCargoSlopData } = action.payload;
			return {
				...state,
				cargoSlop: {
					...state.cargoSlop,
					rawCargoSlopVesselSpecificData: rawVesselSpecificCargoSlopData,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.oilSpillsInWaterRawDataSet,
		function oilSpillsInWaterRawDataSet(state, action) {
			const { rawOilSpillsInWaterData } = action.payload;
			return {
				...state,
				oilSpillsInWater: {
					...state.oilSpillsInWater,
					rawOilSpillsInWaterData,
				},
			};
		}
	)
	.handleAction(
		reportActions.document.oilSpillsInWaterVesselSpecificRawDataSet,
		function oilSpillsInWaterVesselSpecificRawDataSet(state, action) {
			const { rawVesselSpecificOilSpillsInWaterData } = action.payload;
			return {
				...state,
				oilSpillsInWater: {
					...state.oilSpillsInWater,
					rawOilSpillsInWaterVesselSpecificData:
						rawVesselSpecificOilSpillsInWaterData,
				},
			};
		}
	);

export default withJobs<IReportStore>(
	reportConstants.FEATURE,
	defaultState,
	reportReducer
);
