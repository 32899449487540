import actionTypes from "../action-types";
import { apiActions, apiTypesCreator } from "../../../../_core/api";
import { Middleware } from "redux";
import {
	IVesselOnboard_vesselLoad__Action,
	IVesselOnboard_vesselImageUpload__Action,
	IVesselOnboard_crewListLoad__Action,
	IVesselOnboard_crewLoad__Action,
	IVesselOnboard_crewImageUpload__Action,
	IVesselOnboard_crewListImport__Action,
	IVesselOnboard_crewCreate__Action,
	IVesselOnboard_crewUpdate__Action,
	IVesselOnboard_crewDelete__Action,
} from "../types";
import actions from "./../actions";
import vesselOnboardAPIs from "../apis";
import { vesselOnboardUtils } from "..";
import { commonUtils } from "../../../../_common";
import { object, ValidationError, string, date, ref } from "yup";
import { formActionTypes } from "../../../../_core/form";
import {
	IForm_Submit__Action,
	IForm_FieldsSet__Action,
	IFormErrorFormat,
} from "../../../../_core/form/types";
import { IVesselCrew } from "../../types";
import { IStore } from "../../../../store";
import { promiseActions } from "../../../../_core/promise-action";
import vesselOnboardConstants from "../constants";
import { batch } from "react-redux";
import { crewFormDefaults } from "../reducers.state";
import { invokerAction } from "../../../../_common/actions";

const { apiRequest } = apiActions;
const {
	WIZARD_CREW_LIST_LOAD,
	WIZARD_CREW_LIST_IMPORT,
	WIZARD_CREW_LOAD,
	WIZARD_CREW_IMAGE_UPLOAD,
	WIZARD_CREW_CREATE,
	WIZARD_CREW_UPDATE,
	WIZARD_CREW_DELETE,
	WIZARD_CREW_DESIGNATIONS_LOAD,
} = actionTypes.command;
const { FORM_SUBMIT, FORM_FIELDS_SET } = formActionTypes;

const createValidationSchema = object().shape({
	name: string().required("Name is required"),
	fromDate: date().required("From date is required"),
	toDate: date().required("To date is required"),
	designation: string().required("Designation is required"),
	staffId: string().required("Crew ID is required"),
	image: string(),
	vesselId: string().required("No VesselId present"),
	id: string().notRequired(),
});

const updateValidationSchema = object().shape({
	name: string().required("Name is required"),
	fromDate: date().required("From date is required"),
	toDate: date().required("To date is required"),
	designation: string().required("Designation is required"),
	userId: string().required("UserID is required"),
	staffId: string().required("Crew ID is required"),
	image: string(),
	vesselId: string().required("No VesselId present"),
	id: string().required(),
});

const onboardCrewMiddleware: Middleware<{}, IStore> = ({
	dispatch,
	getState,
}) => (next) => (
	action:
		| IVesselOnboard_vesselLoad__Action
		| IVesselOnboard_vesselImageUpload__Action
		| IVesselOnboard_crewListLoad__Action
		| IVesselOnboard_crewListImport__Action
		| IVesselOnboard_crewLoad__Action
		| IVesselOnboard_crewImageUpload__Action
		| IVesselOnboard_crewCreate__Action
		| IVesselOnboard_crewUpdate__Action
		| IVesselOnboard_crewDelete__Action
		| IForm_Submit__Action
		| IForm_FieldsSet__Action<IVesselCrew>
) => {
	switch (action.type) {
		case WIZARD_CREW_LIST_LOAD: {
			next(action);
			const {
				vesselId,
			} = (action as IVesselOnboard_crewListLoad__Action).payload;
			const job = actions._jobs.WIZARD_CREW_LIST_LOAD;
			dispatch(
				apiRequest<
					{
						// Dumb Fixes
						user: IVesselCrew;
						image: string;
						id: string;
						vesselId: string;
						fromDate: string;
						toDate: string;
						userId: string;
					}[]
				>({
					...vesselOnboardAPIs.loadCrewsAPI(vesselId),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading Crews",
								notification: {
									hideAtState: "SUCCESS",
									timeout: 100,
								},
							})
						);
					},
					postExecute: {
						onSuccess(response) {
							if (response.data && response.data.length > 0) {
								// const crewsFromServer = response.data.filter((crewData) =>
								// 	crewData.user ? true : false
								// );
								const crewsFromServer = response.data;

								const crews = crewsFromServer.map(
									(crewFromServer) =>
										vesselOnboardUtils.getCrewFromServerCrewData(
											crewFromServer
											// {
											// 	...crewFromServer.user,
											// 	// userId: crewFromServer.id,
											// 	// id: crewFromServer.id,
											// 	// // image: crewFromServer.image,
											// 	// vesselId:
											// 	// 	crewFromServer?.vesselId,
											// 	// fromDate:
											// 	// crewFromServer.fromDate,
											// 	// toDate: crewFromServer.toDate,
											// }
										)
								);

								dispatch(
									actions.document.crewListSet(
										commonUtils.arrayToObject(crews)
									)
								);
								dispatch(
									job.success({
										message: "Successfully loaded crew",
										notification: { className: "text-xs" },
									})
								);
							} else
								dispatch(
									job.error({
										message: "No Crews found",
										notification: { timeout: 10000 },
									})
								);
						},
						onError() {
							dispatch(
								job.error({
									message: "Server error while loading crew",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_CREW_LIST_IMPORT: {
			next(action);
			const {
				file,
				vesselId,
			} = (action as IVesselOnboard_crewListImport__Action).payload;
			const job = actions._jobs.WIZARD_CREW_LIST_IMPORT;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.importCrewsAPI(file, vesselId),
					preExecute() {
						dispatch(
							job.active({
								message: "Importing Crew",
								notification: { timeout: 10000 },
							})
						);
					},
					postExecute: {
						onSuccess() {
							dispatch(actions.command.crewListLoad(vesselId));
							dispatch(job.success({ message: "Imported crew successfully" }));
						},
						onError() {
							dispatch(job.error({ message: "Failed to import crew" }));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_CREW_LOAD: {
			next(action);
			const { crewId } = (action as IVesselOnboard_crewLoad__Action).payload;
			const job = actions._jobs.WIZARD_CREW_LOAD;
			dispatch(
				apiRequest<{
					// Dumb Fixes
					user: IVesselCrew;
					id: string;
					image: string;
					vesselId: string;
					fromDate: string;
					toDate: string;
					userId: string;
				}>({
					...vesselOnboardAPIs.loadCrewAPI(crewId),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading crew",
								notification: { timeout: 1000, hideAtState: "SUCCESS" },
							})
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(
								invokerAction(
									"$crew-middleware",
									actions._forms.crew.formFieldsSet(
										vesselOnboardUtils.getCrewFromServerCrewData({
											...response.data.user,
											id: response.data.id,
											image: response.data.image,
											vesselId: response.data.vesselId,
											fromDate: response.data.fromDate,
											toDate: response.data.toDate,
											userId: response.data.userId,
										})
									)
								)
							);
							dispatch(job.success({}));
						},
						onError() {
							dispatch(job.error({}));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_CREW_CREATE: {
			next(action);
			const { crew } = (action as IVesselOnboard_crewCreate__Action).payload;
			const job = actions._jobs.WIZARD_CREW_CREATE;
			dispatch(
				apiRequest<Partial<IVesselCrew>>({
					...vesselOnboardAPIs.createCrewAPI(crew),
					preExecute() {
						dispatch(job.active({ message: "Adding Crew", notification: {} }));
					},
					postExecute: {
						onSuccess(response) {
							dispatch(actions.document.crewNew({ ...crew, ...response.data }));
							dispatch(job.success({ message: "Crew is added" }));
						},
						onError(response) {
							dispatch(
								job.error({
									message: response?.response?.data?.error?.message ? response?.response?.data?.error?.message : "Crew is not added due to an error",
									notification: { className: "text-xs" },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_CREW_UPDATE: {
			next(action);
			const {
				crew,
				crewId,
			} = (action as IVesselOnboard_crewUpdate__Action).payload;
			const job = actions._jobs.WIZARD_CREW_UPDATE;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.updateCrewAPI(crewId, crew),
					preExecute() {
						dispatch(
							job.active({
								message: "Updating crew",
								notification: {},
							})
						);
					},
					postExecute: {
						onSuccess() {
							dispatch(actions.document.crewEdit({ ...crew, id: crewId }));
							dispatch(job.success({ message: "Crew updated" }));
						},
						onError(response) {
							dispatch(job.error({ message: response?.response?.data?.error?.message ? response?.response?.data?.error?.message : "Crew update failed" }));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_CREW_DELETE: {
			next(action);
			const { crewId } = (action as IVesselOnboard_crewDelete__Action).payload;
			const job = actions._jobs.WIZARD_CREW_DELETE;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.deleteCrewAPI(crewId),
					preExecute() {
						dispatch(
							job.active({ message: "Deleting crew", notification: {} })
						);
					},
					postExecute: {
						onSuccess() {
							dispatch(actions.document.crewErase(crewId));
							dispatch(job.success({ message: "Deleted Crew Successfully" }));
						},
						onError(response) {
							dispatch(job.error({ 
								message: response?.response?.data?.error?.message ? response?.response?.data?.error?.message : "Failed to Delete Crew" 
							}));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_CREW_IMAGE_UPLOAD: {
			next(action);
			const {
				file,
			} = (action as IVesselOnboard_crewImageUpload__Action).payload;
			const job = actions._jobs.WIZARD_CREW_IMAGE_UPLOAD;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.uploadCrewImageAPI(file),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess(response) {
							const fileName = response.data.result.files.file[0].name;
							const BASE_URL = `${process.env.REACT_APP_DOMAIN}/api`;
							const url = `${BASE_URL}/storages/wayshipprivate/download/${fileName}`;
							setTimeout(function withDelay() {
								batch(function batchDispatch() {
									dispatch(job.success({}));
									// dispatch(
									// 	invokerAction(
									// 		"$crew-middleware",
									// 		actions._forms.crew.formFieldsEdit({ image: url })
									// 	)
									// );
								});
							}, 1000);
						},
						onError() {
							dispatch(job.error({}));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case FORM_SUBMIT: {
			next(action);
			const { feature, formName } = (action as IForm_Submit__Action).payload;
			if (
				feature === vesselOnboardConstants.feature &&
				formName === vesselOnboardConstants.forms.CREW
			) {
				const { fields, mode } = getState().app.vesselStore.onboard._forms.CREW;
				if (mode === "CREATE") {
					dispatch(
						promiseActions(
							createValidationSchema.validate(fields, { abortEarly: false }),
							{
								invoker: "$vessel/onboard/crew-middleware",
								onSuccess: (validatedData) => {
									if (validatedData)
										dispatch(actions.command.crewCreate(fields));
								},
								onError: (error: ValidationError) => {
									const errors: IFormErrorFormat = {
										GLOBAL: {
											isValid: false,
											message: "Found few errors",
										},
									};
									error.inner.map((errorItem) => {
										errors[errorItem.path] = {
											isValid: false,
											message: errorItem.message,
										};
									});
									dispatch(
										invokerAction(
											"$crew-middleware",
											actions._forms.crew.formErrorsSet(errors)
										)
									);
								},
							}
						)
					);
				} else if (mode === "EDIT") {
					dispatch(
						promiseActions(
							updateValidationSchema.validate(fields, { abortEarly: false }),
							{
								invoker: "$vessel/onboard/crew-middleware",
								onSuccess: (validatedData) => {
									if (validatedData)
										dispatch(actions.command.crewUpdate(fields.id, fields));
								},
								onError: (error: ValidationError) => {
									const errors: IFormErrorFormat = {
										GLOBAL: {
											isValid: false,
											message: "Found few errors",
										},
									};
									error.inner.map((errorItem) => {
										errors[errorItem.path] = {
											isValid: false,
											message: errorItem.message,
										};
									});
									dispatch(
										invokerAction(
											"$crew-middleware",
											actions._forms.crew.formErrorsSet(errors)
										)
									);
								},
							}
						)
					);
				} else
					dispatch(
						invokerAction(
							"$crew-middleware",
							actions._forms.crew.formErrorsSet({
								GLOBAL: {
									isValid: false,
									message: "No form mode provided",
								},
							})
						)
					);
			}
			break;
		}
		case FORM_FIELDS_SET: {
			next(action);
			const { formName, feature, fields } = (action as IForm_FieldsSet__Action<
				any
			>).payload;
			if (
				feature === vesselOnboardConstants.feature &&
				formName === vesselOnboardConstants.forms.CREW
			) {
				// To reset the errors on Change //FIXME: Add below code in Form Middleware
				dispatch(
					invokerAction(
						"$vessel/onboard/crew-middleware",
						actions._forms.crew.formErrorsReset([
							"GLOBAL",
							...Object.keys(fields),
						])
					)
				);
			}
			break;
		}

		case WIZARD_CREW_DESIGNATIONS_LOAD: {
			next(action);
			const job = actions._jobs.WIZARD_CREW_DESIGNATIONS_LOAD;
			dispatch(
				apiRequest<{ designations: string[] }>({
					...vesselOnboardAPIs.loadDesignationsForCrewAPI(),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess(response) {
							dispatch(job.success({}));
							dispatch(
								actions.document.crewDesignationsSet(response.data.designations)
							);
						},
						onError() {
							dispatch(
								job.error({
									message: "Designations failed to load",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
		}
		default: {
			next(action);
		}
	}
};
export default onboardCrewMiddleware;
