const JOB_IDLE = "job/IDLE";
const JOB_ACTIVE = "job/ACTIVE";
const JOB_SUCCESS = "job/SUCCESS";
const JOB_ERROR = "job/ERROR";
const JOB_PROMISE = "job/PROMISE";

export default {
	JOB_IDLE,
	JOB_ACTIVE,
	JOB_SUCCESS,
	JOB_ERROR,
	JOB_PROMISE,
};
