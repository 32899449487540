import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupJ1 } from "../../../../../redux/app/annex6/groups/group-j";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupJ1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupJ1Preview(props: Annex6GroupJ1PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupJ1;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[[operationDate, "J", "-", record.remarks]]}
			/>
		);
	}

	return renderMain();
}

export default Annex6GroupJ1Preview;
