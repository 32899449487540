import { apiActions } from "redux/_core/api";
import userAPIs from "./api";
import { Middleware } from "redux";
import { IStore } from "redux/store";
import { IUser, IUserActions, IVesselUser, TCount } from "./@types";
import userActions, { userJobActions } from "./actions";
import { formActionTypes } from "../../_core/form";
import { IFormErrorFormat, IForm_Submit__Action } from "redux/_core/form/types";
import userConstants from "./constants";
import { promiseActions } from "redux/_core/promise-action";
import { ValidationError, object, string } from "yup";
import { invokerAction } from "redux/_common/actions";
import { commonUtils } from "redux/_common";
import { userFormDefaults } from "./reducers.state";
import { IUserDetail } from "../vessel/types";
import vesselAPIs from "../vessel/apis";
import { authActions, authSelectors } from "../auth";

const { apiRequest } = apiActions;
const { FORM_SUBMIT } = formActionTypes;

// Both schema should possibly be same -  will refactor/remove
const createUserValidationSchema = object().shape({
	name: string().required("Name is required"),
	email: string().email("Enter valid email id").required("Email is required"),
	roleCategory: string().required("Role is required"),
});

const updateUserValidationSchema = object().shape({
	name: string().required("Name is required"),
	email: string().email().required("Email is required"),
	roleCategory: string().required("Role is required"),
	staffId: string()
		.min(6, "Should be minimum 6 character long")
		.required("Staffid is required"),
});

const userMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action: IUserActions) => {
		switch (action.type) {
			case "@user/list/LOAD": {
				next(action);
				const { pageNumber } = action.payload;
				const userStore = getState().app.user;
				const job = userJobActions.userListLoad;
				const pagination = userStore._pagination;

				const authStore = getState().app.auth;
				const token = authStore.authToken;

				if (!token) {
					console.log("No token found");
					dispatch(authActions.logout());
					return;
				}

				dispatch(
					apiRequest<IUser[]>({
						...userAPIs.loadUserList(
							pagination.itemsPerPage,
							pageNumber
						),
						preExecute() {
							dispatch(
								userActions.document.paginationCurrentPageSet(
									pageNumber
								)
							);
							dispatch(
								job.active({
									message: `Loading Users`,
								})
							);
						},
						postExecute: {
							onSuccess({ data: users }) {
								if (users && users.length) {
									dispatch(
										userActions.document.userListSet(
											commonUtils.arrayToObject(users)
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load users",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case "@user/CREATE": {
				next(action);

				const authStore = getState().app.auth;
				const job = userJobActions.userCreate;

				const { user: newUser } = action.payload;
				const user = {
					...newUser,
					role: "tenantAdmin",
					username: newUser.email,
					password: "wayship",
					tenantId: authStore?.user?.tenantId ?? "",
					staffId:
						newUser.name.slice(0, 4).toUpperCase().padEnd(4, "A") +
						Math.random().toString().slice(2, 12),
				};

				dispatch(
					apiRequest<IUser>({
						...userAPIs.createUser(user),
						preExecute() {
							dispatch(
								job.active({
									message: `Creating a user`,
									notification: {
										hideAtState: "SUCCESS",
										timeout: 100,
									},
								})
							);
						},
						postExecute: {
							onSuccess(response) {
								if (response?.data?.id) {
									// dispatch(userActions.commands.userListLoad());
									dispatch(
										userActions.document.userNew(
											response.data
										)
									);
									dispatch(
										job.success({
											message: "User added",
											notification: {
												hideAtState: "IDLE",
												timeout: 350,
											},
										})
									);
								}
							},
							onError(response) {
								if (
									response?.response?.data?.error
										?.statusCode === 422
								) {
									dispatch(
										job.error({
											message:
												"User email already exists",
										})
									);
								} else {
									dispatch(
										job.error({
											message: "Failed to add Device",
										})
									);
								}
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@user/UPDATE": {
				next(action);
				const job = userJobActions.userUpdate;
				const { user: formUserFields, userId } = action.payload;
				const userStore = getState().app.user;
				const { selectedUserId, users } = userStore;
				const originalUserData = users.byIds[selectedUserId];
				console.log({ userId, formUserFields });
				dispatch(
					apiRequest({
						...userAPIs.updateUser(userId, formUserFields),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess() {
								dispatch(
									userActions.document.userEdit({
										...originalUserData,
										...formUserFields,
									})
								);
								dispatch(
									job.success({
										message: "User updated",
										notification: {},
									})
								);
							},
							onError() {
								dispatch(
									job.error({
										message: "User Update failed",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
								dispatch(
									userActions.document.selectedUserIdSet("")
								);
							},
						},
					})
				);
				break;
			}
			case "@user/DELETE": {
				next(action);
				const authToken = getState().app.auth.authToken;
				const { userId } = action.payload;
				const job = userJobActions.userDelete;
				if (authToken && userId) {
					dispatch(
						apiRequest({
							...userAPIs.deleteUser(userId, authToken ?? ""),
							preExecute() {
								dispatch(
									job.active({
										message: "Deleting User",
										notification: {},
									})
								);
							},
							postExecute: {
								onSuccess(response) {
									if (response.status === 200) {
										dispatch(
											job.success({
												message: "Deleted user",
												notification: {
													hideAtState: "IDLE",
													timeout: 350,
												},
											})
										);
										dispatch(
											userActions.document.userErase(
												userId
											)
										);
									}
								},
								onError() {
									dispatch(
										job.error({
											message: "Error deleting user",
										})
									);
								},
								finally() {
									dispatch(job.idle({}));
									dispatch(
										userActions.document.selectedUserIdSet(
											""
										)
									);
								},
							},
						})
					);
				}
				break;
			}
			case FORM_SUBMIT: {
				next(action);
				const { feature, formName } = (action as IForm_Submit__Action)
					.payload;

				if (
					feature === userConstants.FEATURE &&
					formName === userConstants.forms.USER
				) {
					const { mode, fields } = getState().app.user._forms.USER;
					if (mode === "CREATE") {
						dispatch(
							promiseActions(
								createUserValidationSchema.validate(fields, {
									abortEarly: false,
								}),
								{
									invoker: "$user/middleware",
									onSuccess: (validatedData) => {
										if (validatedData)
											dispatch(
												userActions.commands.userCreate(
													fields
												)
											);
									},
									onError: (error: ValidationError) => {
										const errors: IFormErrorFormat = {
											GLOBAL: {
												isValid: false,
												message: "Found few errors",
											},
										};
										error.inner.map((errorItem) => {
											errors[errorItem.path] = {
												isValid: false,
												message: errorItem.message,
											};
										});
										dispatch(
											invokerAction(
												"$user-middleware",
												userActions._forms.user.formErrorsSet(
													errors
												)
											)
										);
										dispatch(
											userActions.commands.formReset(
												fields,
												errors
											)
										);
									},
								}
							)
						);
					} else if (mode === "EDIT") {
						const selectedUserId =
							getState().app.user.selectedUserId;
						dispatch(
							promiseActions(
								updateUserValidationSchema.validate(fields, {
									abortEarly: false,
								}),
								{
									invoker: "$user/middleware",
									onSuccess: (validatedData) => {
										if (validatedData)
											dispatch(
												userActions.commands.userUpdate(
													selectedUserId,
													fields
												)
											);
									},
									onError: (error: ValidationError) => {
										const errors: IFormErrorFormat = {
											GLOBAL: {
												isValid: false,
												message: "Found few errors",
											},
										};
										error.inner.map((errorItem) => {
											errors[errorItem.path] = {
												isValid: false,
												message: errorItem.message,
											};
										});
										dispatch(
											invokerAction(
												"$user-middleware",
												userActions._forms.user.formErrorsSet(
													errors
												)
											)
										);
										dispatch(
											userActions.commands.formReset(
												fields,
												errors
											)
										);
									},
								}
							)
						);
					}
				}
				break;
			}

			case "@user/LOAD": {
				next(action);
				const userStore = getState().app.user;
				const users = userStore.users;
				const { userId } = action.payload;
				const user =
					userId in users?.byIds ? users?.byIds[userId] : null;

				const loadedUser = {} as IVesselUser;
				Object.keys(userFormDefaults).forEach((key) => {
					//@ts-ignore
					loadedUser[key] = users.byIds[userId][key];
				});

				if (user)
					dispatch(
						invokerAction(
							"$user-middleware",
							userActions._forms.user.formFieldsSet(loadedUser)
						)
					);

				break;
			}

			case "@user/form/RESET": {
				next(action);
				const { errors, fields } = action.payload;
				// INFO: this will ensure that on error during, it will populate the other fields except the one with errors
				const updatedFields = {} as any;
				Object.keys(fields).forEach((field) => {
					if (field in errors) {
						updatedFields[field] = "";
					} else {
						updatedFields[field] =
							fields[field as keyof IVesselUser];
					}
				});

				dispatch(
					invokerAction(
						"$user-middleware",
						userActions._forms.user.formFieldsSet(updatedFields)
					)
				);
				break;
			}

			case "@user/details/LOAD": {
				next(action);
				// @ts-ignore
				const userId = getState().app.auth.user?.userId;
				const job = userJobActions.userGetDetails;

				dispatch(
					apiRequest<IUserDetail>({
						...vesselAPIs.getUserDetails(userId),
						preExecute: () => dispatch(job.active({})),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const data = response.data;
									dispatch(
										userActions.document.userDetailsSet(
											data
										)
									);
								}
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			case "@user/count": {
				next(action);
				const job = userJobActions.userCount;
				const authStore = getState().app.auth;
				const user = authSelectors(authStore).getLoggedInUser();
				dispatch(
					apiRequest<TCount>({
						...userAPIs.loadTotalUsersCount(user?.tenantId ?? ""),
						preExecute() {},
						postExecute: {
							onSuccess({ data }) {
								if (typeof data?.count === "number") {
									dispatch(
										userActions.document.paginationTotalItemsSet(
											data.count
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load count",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			default: {
				next(action);
			}
		}
	};

export default userMiddleware;
