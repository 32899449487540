import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { IStore } from "../../../../../redux/store";
import { logSelectors } from "../../../../../redux/app/logs";
import { connect, ConnectedProps } from "react-redux";
import ReactToPrint from "react-to-print";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import moment from "moment";
import { IDiscrepancy, IDiscrepanciesArray, ISidebarObj } from "../../types";
import { getDiscrepanciesArray } from "../../utils";
type LogTemplateRendererProps = PropsFromRedux & {
	onClose(): void;
	openSidebarModal?: (data: ISidebarObj) => void;
	discrepancy?: IDiscrepancy;
	setIsDragRef?: React.MutableRefObject<(value: boolean) => void>;
};

function findDiscrepancyValueElm(
	elms: HTMLCollection,
	id: string
): HTMLElement | null {
	for (let i = 0; i < elms.length; i++) {
		const elm = elms.item(i) as HTMLElement;
		const elmId = elm.id;
		if (!elmId.endsWith("_discrepancy") && elmId.includes(id)) {
			return elm;
		}
		const elmClassName = elm.className;
		if (
			elmClassName.includes(id) &&
			elm.textContent &&
			elm.textContent.trim().length > 0
		) {
			return elm;
		}
		const discrepancyValueElm = findDiscrepancyValueElm(elm.children, id);
		if (discrepancyValueElm) {
			return discrepancyValueElm;
		}
	}
	return null;
}

function changeBorder(discrepancy: IDiscrepancy, revert?: boolean) {
	const { metric, watchHour } = discrepancy;

	if (!metric) {
		return;
	}

	const id = (watchHour ? watchHour + "_" : "") + metric;
	drawBorder(findDiscrepancyElm(metric), revert);
	drawBorder(findDiscrepancyElm(id, id + "_discrepancy", true), revert);
}

function drawBorder(elm: HTMLElement | null, revert?: boolean) {
	if (elm) {
		let border = revert
			? elm.nodeName === "SPAN"
				? "none"
				: "1px solid black"
			: "2px solid #f56565";
		elm.style.border = border;
	}
}

function findDiscrepancyElm(
	id: string,
	className?: string,
	isValue?: boolean
): HTMLElement | null {
	let elm = document.getElementById(id);
	if (!elm) {
		const indx = id.indexOf("_cur_eng");
		if (indx > -1) {
			const newId = id.slice(0, indx);
			elm = document.getElementById(newId);
		}
	}
	if (!elm) {
		const elms = document.getElementsByClassName(className ? className : id);
		if (isValue) {
			elm = findDiscrepancyValueElm(elms, id);
		} else if (elms.length > 0) {
			elm = elms.item(0) as HTMLElement;
		}
	}
	return elm;
}

function LogTemplateRenderer(props: LogTemplateRendererProps) {
	const {
		logTemplate,
		logData,
		logDataArray,
		logType,
		jobToLoadPrintTemplates,
		jobToLoadLogData,
		openSidebarModal,
		discrepancy,
		setIsDragRef,
	} = props;
	const [isDrag, setIsDrag] = useState(false);
	useEffect(() => {
		if (setIsDragRef) {
			setIsDragRef.current = setIsDrag;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const finalData =
		logDataArray && logDataArray?.length > 0 ? logDataArray : logData;
	function b64_to_utf8(data: string) {
		return decodeURIComponent(escape(window.atob(data)));
	}
	let dynamicJsScript: any;
	if (logTemplate && logTemplate.js) {
		dynamicJsScript = b64_to_utf8(logTemplate.js);
	}
	let htmlcontent: any;
	if (logTemplate && logTemplate.html) {
		htmlcontent = b64_to_utf8(logTemplate.html);
	}

	window.onerror = (error) => {
		// tslint:disable-next-line: no-console
		console.error("Print Template Window Error:", error);
	};
	useEffect(
		function onMount() {
			if (logTemplate && logTemplate.js && logTemplate.html) {
				const script = document.createElement("script");

				script.innerHTML = dynamicJsScript;
				script.classList.add("printscript");

				try {
					/* Injecting the printTemplate script into DOM */
					document.body.appendChild(script);

					/* Checking if the getData(data) function is available for invocation */
					if (!!(window as any).getData) {
						/* invoking getData to start the printing Process */
						(window as any).getData(finalData);
					}
				} catch (err) {
					// tslint:disable-next-line: no-console
					console.error(
						"catched error while inserting print template script",
						err
					);
				}
			}
			return function onUnmount() {
				/* Removing the dynamically generated scripts to clean up */
				const printScripts = document.getElementsByClassName("printscript");
				// tslint:disable-next-line: prefer-for-of
				for (let i = 0; i < printScripts.length; i++) {
					if (!!printScripts && printScripts[i]) printScripts[i].remove();
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[logTemplate, logType, finalData]
	);
	const zoom = useRef({
		setDefaultState: () => {},
		setTransform: (x: number, y: number, scale: number) => {},
	});
	useEffect(() => {
		if (discrepancy) {
			const discrepancyTitleElm = findDiscrepancyElm(discrepancy.metric);
			const transformElmWrapper = document.getElementById(
				"transformElementWrapper"
			);
			const transformElm = document
				.getElementsByClassName("react-transform-element")
				?.item(0);

			changeBorder(discrepancy);

			const discrepancyTitleElmRect =
				discrepancyTitleElm?.getBoundingClientRect();
			const transformElmRect = transformElm?.getBoundingClientRect();
			const transformElmWrapperRect =
				transformElmWrapper?.getBoundingClientRect();

			if (
				discrepancyTitleElmRect &&
				transformElmRect &&
				transformElmWrapperRect
			) {
				const { x: discrepancyElmX, y: discrepancyElmY } =
					discrepancyTitleElmRect;
				const { x: transformElmX, y: transformElmY } = transformElmRect;
				const { height, width } = transformElmWrapperRect;
				zoom.current.setTransform(
					(transformElmX - discrepancyElmX + width / (2 * 2) - 100) * 2,
					(transformElmY - discrepancyElmY + (height / (2 * 2) - 50)) * 2,
					2
				);
			}
		}
	}, [discrepancy]);

	const [discrepanciesArray, setDiscrepanciesArray] =
		useState<IDiscrepanciesArray>([]);
	const discrepanciesCount = useRef(0);

	useEffect(() => {
		const { count, discrepanciesArray } = getDiscrepanciesArray(
			logData,
			logDataArray
		);
		if (count > 0) {
			discrepanciesCount.current = count;
			setDiscrepanciesArray(discrepanciesArray);
		}
	}, [logData, logDataArray]);

	const componentRef = useRef<any>();

	return (
		<div>
			<TransformWrapper
				wheel={{ wheelEnabled: false, touchPadEnabled: false }}
				options={{
					// minScale: -10,
					limitToBounds: false,
					centerContent: true,
				}}
				pan={{
					disabled: !isDrag,
				}}
			>
				{({
					zoomIn,
					zoomOut,
					resetTransform,
					setDefaultState,
					setTransform,
					setPositionX,
					setPositionY,
					...rest
				}: any) => {
					zoom.current.setDefaultState = setDefaultState;
					zoom.current.setTransform = setTransform;
					return (
						<>
							<div className="w-11/12 h-[calc(100vh-48px)] my-6 px-0 mx-auto bg-white overflow-hidden rounded-lg shadow-2xl">
								<div
									className={classNames(
										"flex flex-row items-end justify-between px-6 py-6 border-b"
									)}
								>
									<div>
										<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
											{jobToLoadLogData.isLoading ||
											jobToLoadPrintTemplates.isLoading
												? "Loading..."
												: logData?.title}
										</h3>
										<h6 className="text-sm text-gray-600">
											{jobToLoadPrintTemplates.isLoading
												? "Loading Print format"
												: jobToLoadLogData.isLoading
												? "Loading log"
												: "Print format"}
										</h6>
									</div>
									<div>
										<div>
											<div className="flex flex-row justify-between ">
												<div className="flex items-center mx-12 bg-blue-100 border border-blue-200 rounded-full">
													<button
														className={`flex items-center h-full p-6 py-3 text-gray-700 rounded-sm rounded-l-full outline-none hover:bg-blue-200 ${
															isDrag ? "bg-blue-300" : ""
														}`}
														onClick={() => {
															setIsDrag((prev) => !prev);
															if (zoom.current.setDefaultState) {
																(zoom.current.setDefaultState as any)();
															}
														}}
													>
														<span className="p-0 m-0 bp3-icon-move bp3-icon" />
													</button>
													<button
														className="flex items-center h-full p-6 py-3 text-gray-700 rounded-sm outline-none hover:bg-blue-200 focus:bg-blue-300"
														onClick={zoomIn}
													>
														<span className="p-0 m-0 bp3-icon-zoom-in bp3-icon" />
													</button>
													<button
														className="flex items-center h-full p-6 py-3 text-gray-700 rounded-sm outline-none hover:bg-blue-200 focus:bg-blue-300"
														onClick={zoomOut}
													>
														<span className="p-0 m-0 bp3-icon-zoom-out bp3-icon" />
													</button>
													<button
														className="flex items-center h-full p-6 py-3 text-gray-700 rounded-sm outline-none hover:bg-blue-200 focus:bg-blue-300"
														onClick={resetTransform}
													>
														<span className="p-0 m-0 bp3-icon-zoom-to-fit bp3-icon" />
													</button>

													<ReactToPrint
														trigger={() => (
															<button
																className={classNames(
																	"p-6 py-3 text-gray-700 rounded-sm outline-none hover:bg-blue-200 focus:bg-blue-300 flex items-center h-full",
																	{
																		"rounded-r-full": !(
																			logData?.code === "engineLogConfig" &&
																			discrepanciesCount.current > 0
																		),
																	}
																)}
															>
																<span className="bp3-icon bp3-icon-print" />
															</button>
														)}
														content={() => componentRef.current}
													/>
													<button
														disabled={
															logData?.code !== "engineLogConfig" ||
															!(discrepanciesCount.current > 0)
														}
														className="relative flex items-center h-full p-6 py-3 text-gray-700 rounded-sm rounded-r-full outline-none hover:bg-blue-200 focus:bg-blue-300"
														onClick={() => {
															if (logData) {
																zoom.current.setDefaultState();
																if (discrepancy) {
																	changeBorder(discrepancy, true);
																}
																openSidebarModal &&
																	openSidebarModal({
																		discrepanciesArray: discrepanciesArray,
																		selectedDate: moment(
																			logData?.selectedDate
																		).format("DD-MM-YYYY"),
																		title: logData?.title || "",
																		onClickEvent: true,
																	});
															}
														}}
													>
														<div className="absolute flex items-center justify-center w-4 h-4 px-2 py-2 text-xs text-gray-700 bg-blue-300 rounded-full top-2 right-3 ">
															{discrepanciesCount.current > 0
																? discrepanciesCount.current
																: 0}
														</div>
														<span className="bp3-icon bp3-icon-warning-sign" />
													</button>
												</div>
												<button
													aria-label="Close"
													onClick={() => {
														props.onClose();
													}}
													className="p-4 py-3 mx-2 mr-0 text-gray-600 bg-gray-100 rounded-md outline-none hover:bg-gray-200 focus:bg-gray-300 "
												>
													<span className="text-lg bp3-icon bp3-minimal bp3-icon-cross" />
												</button>
											</div>
										</div>
									</div>
								</div>

								<div
									id="transformElementWrapper"
									className={classNames(
										"relative m-0 p-6 h-[calc(100vh-140px)]",
										{
											"bg-gray-200 animate-pulse":
												jobToLoadLogData.isLoading ||
												jobToLoadPrintTemplates.isLoading,
											"bg-white":
												!jobToLoadLogData.isLoading &&
												!jobToLoadPrintTemplates.isLoading,
											"overflow-hidden": isDrag,
											"overflow-scroll": !isDrag,
										}
									)}
								>
									<TransformComponent>
										<div
											ref={componentRef}
											className={classNames("ws-print-templates web-only", {
												"cursor-default": !isDrag,
												"cursor-move": isDrag,
											})}
										>
											{htmlcontent ? (
												<div
													dangerouslySetInnerHTML={{
														__html: `${htmlcontent}`,
													}}
												/>
											) : null}
										</div>
									</TransformComponent>
									{jobToLoadPrintTemplates.isError &&
									!jobToLoadPrintTemplates.isLoading &&
									!jobToLoadLogData.isLoading ? (
										<div className="relative h-screen p-32 overflow-hidden text-center bg-gray-200">
											<div>
												<span className="text-6xl text-gray-500 bp3-icon bp3-icon-error" />
											</div>
											<h3 className="mt-6 mb-1 text-2xl font-medium text-gray-700">
												Failed to load Print format
											</h3>
											<p className="text-base text-gray-600">
												Try reopening this view again.
											</p>
										</div>
									) : null}
								</div>
							</div>
						</>
					);
				}}
			</TransformWrapper>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const logStore = logSelectors._getStore(store);
	const selectedLog = logSelectors.getSelectedLog(logStore);
	if (Array.isArray(selectedLog?.log)) {
		return {
			logTemplate: selectedLog?.logPrintTemplate,
			logDataArray: selectedLog?.log,
			logData: selectedLog?.log[0],
			logType: selectedLog?.log[0]?.code,
			selectedDate: selectedLog?.log[0]?.selectedDate,
			jobToLoadPrintTemplates: logSelectors.jobToLoadPrintTemplates(logStore),
			jobToLoadLogData: logSelectors.jobToLoadSelectedLog(logStore),
		};
	} else
		return {
			logTemplate: selectedLog?.logPrintTemplate,
			logData: selectedLog?.log,
			logDataArray: [],
			logType: selectedLog?.log?.code,
			selectedDate: selectedLog?.log?.selectedDate,
			jobToLoadPrintTemplates: logSelectors.jobToLoadPrintTemplates(logStore),
			jobToLoadLogData: logSelectors.jobToLoadSelectedLog(logStore),
		};
}
const reduxConnector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(LogTemplateRenderer);
