import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupI5 } from "../../../../../redux/app/orb1/groups/group-i";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { marpolUtils } from "redux/app/marpol";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupI5PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupI5Preview(props: ORB1GroupI5PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI5;

	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					...renderItemI5Rows(),
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItemI5Rows(): ORB1TableColumns[] {
		const row1Content = (
			<h4 className="font-medium uppercase">
				{orb1Record.itemI5.tanks
					.reduce((result, currentTank) => {
						result = result + currentTank.removedQuantityInMT;
						return result;
					}, 0)
					.toFixed(3)}
				MT of {orb1Record.itemI5.iso} (OR EQUIVALENT),{" "}
				{orb1Record.itemI5.sulphurPercent}% SULPHUR DEBUNKERED IN TANK(S)
			</h4>
		);

		const row2Content = (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={orb1Record.itemI5.tanks.map(
						(tank) =>
							`${tank.removedQuantityInMT.toFixed(3)} MT REMOVED FROM ${
								tank.selectedTank.tankName
							}, NOW CONTAINING ${tank.totalRetainedQuantityInMT.toFixed(
								3
							)} MT.`
					)}
				/>
			</h4>
		);

		const row3Content = (
			<h4 className="font-medium uppercase">
				DEBUNKERED TO {""}
				{orb1Record.itemI5.placeOfDeBunkering.place === "AT_SEA" ? (
					<>
						{orb1Record.itemI5.placeOfDeBunkering?.latitude &&
							orb1Record.itemI5.placeOfDeBunkering?.latitude}{" "}
						{""}
						{orb1Record.itemI5.placeOfDeBunkering?.longitude &&
							orb1Record.itemI5.placeOfDeBunkering?.longitude}
					</>
				) : null}
				{orb1Record.itemI5.placeOfDeBunkering.place === "IN_PORT" ? (
					<>{orb1Record.itemI5.placeOfDeBunkering.portName}</>
				) : null}
			</h4>
		);

		const row4Content = (
			<h4 className="font-medium uppercase">
				START:{" "}
				{moment(orb1Record.itemI5.start.date, "YYYY-MM-DD").format(
					"DD-MMM-YYYY"
				)}{" "}
				{orb1Record.itemI5.start.time}HRS, STOP:{" "}
				{moment(orb1Record.itemI5.end.date, "YYYY-MM-DD").format("DD-MMM-YYYY")}{" "}
				{orb1Record.itemI5.end.time}HRS
			</h4>
		);

		const row5Content = (
			<h4 className="font-medium uppercase">
				ATTACHMENT:
				<a
					href={marpolUtils.getAttachmentURL(
						orb1Record.itemI5.receiptAttachment,
						orb1Record.id,
						orb1Record._rev
					)}
					download={orb1Record.itemI5.receiptAttachment}
				>
					{orb1Record.itemI5.receiptAttachment}
				</a>{" "}
				, RECEIPT NO: <b>{orb1Record.itemI5.receiptNumber}</b>
			</h4>
		);

		return [
			[operationDate, "I", "-", row1Content],
			[null, null, "-", row2Content],
			[null, null, "-", row3Content],
			[null, null, "-", row4Content],
			[null, null, "-", row5Content],
		];
	}

	return renderMain();
}

export default ORB1GroupI5Preview;
