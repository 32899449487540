import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import toolActions from "redux/app/tool/actions";
import PrintForm from "../../_elements/PrintForm";

type OwnProps = {};
type PrintCreateProps = PropsFromRedux & OwnProps;
function PrintCreate(props: PrintCreateProps) {
	useEffect(() => {
		return () => {
			props.setPrintInfo(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<PrintForm
				action="Create"
				actionLabel="Add Print"
				onClose={() => props.togglePrintAddModal()}
				type="create"
				isDisabled={false}
				handleActionClick={(config, changeLog?: string) => {
					props.printCreate(config, changeLog ?? "");
				}}
			/>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		togglePrintAddModal() {
			dispatch(toolActions.document.printAddModalToggle());
		},
		//TODO: any needs to be replaced by IPrintInfo
		setPrintInfo(print?: any) {
			dispatch(toolActions.document.toolPrintInfoSet(print));
		},
		printCreate(print: any, changeLog: string) {
			dispatch(toolActions.commands.toolPrintCreate(print, changeLog));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PrintCreate);
