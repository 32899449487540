import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import FeedbackTicketTab from "../_common/FeedbackTicketTab";
import FeedbackTicketStatusCount from "../_common/FeedbackTicketStatusCount";
import { Range } from "react-date-range";
import DateRangePickerVerbose from "components/app/feedback/dashboard/_elements/DateRangePickerVerbose";
import FeedbackCategory from "./_elements/FeedbackCategory";
import {
	ETicketTabType,
	IDashboardStatsAndFeedbackPayload,
} from "redux/app/feedback/@types";
import feedbackSelectors from "redux/app/feedback/selectors";
import feedbackActions from "redux/app/feedback/actions";
import {
	defaultStaticRanges,
	filters,
	getCreatedAt,
} from "redux/app/feedback/utils";
import FeedbackTicketTableTabs from "./_elements/FeedbackTicketTableTabs";

type OwnProps = {};
type FeedbackDashboardProps = PropsFromRedux & OwnProps;
function FeedbackDashboard(props: FeedbackDashboardProps) {
	const { tab, assignedTo, dateRange, jobToGetStatsAndFeedback } = props;
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [selectedDateLabel, setSelectedDateLabel] = useState<
		string | undefined
	>("All time");

	const handleOnChange = (ranges: any) => {
		const { selection } = ranges;
		props.setDateRange([selection]);
		const selectedLabel = defaultStaticRanges.find((range) =>
			range.isSelected(selection)
		)?.label;
		setSelectedDateLabel(selectedLabel);
	};

	return (
		<div className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto">
			<div className="flex flex-col items-center justify-center md:flex-row">
				<div className="md:basis-1/3"></div>
				<div className="flex items-center justify-center md:basis-1/3">
					<FeedbackTicketTab />
				</div>
				<div className="flex items-center justify-end md:basis-1/3 md:pr-4">
					<DateRangePickerVerbose
						handleOnChange={(ranges) => {
							handleOnChange(ranges);
						}}
						disabled={jobToGetStatsAndFeedback.isLoading}
						dateRange={dateRange}
						onClose={() => setIsDatePickerOpen(false)}
						handleOpen={() => setIsDatePickerOpen(true)}
						isOpen={isDatePickerOpen}
						overlayClickClose={true}
						handleOnClose={(range, selectedLabel) => {
							filters(
								range,
								tab,
								assignedTo,
								selectedLabel
							).forEach((filter) => {
								props.loadDashboardFeedbacks(filter);
							});

							props.loadDashboardStats({
								stats: true,
								assignedTo:
									tab === ETicketTabType.MY_TICKETS
										? assignedTo
										: undefined,
								selector:
									range?.startDate && range?.endDate
										? {
												createdAt: getCreatedAt(
													range,
													selectedLabel
												),
										  }
										: undefined,
							});
						}}
					/>
				</div>
			</div>
			<FeedbackTicketStatusCount selectedDateLabel={selectedDateLabel} />
			<FeedbackTicketTableTabs />
			<div className="my-12">
				<FeedbackCategory
					title="Tickets with high priority"
					filter={
						filters(
							dateRange[0],
							tab,
							assignedTo,
							selectedDateLabel
						)[0]
					}
				/>
			</div>
			<div className="mb-12">
				<FeedbackCategory
					title="Tickets waiting for reply"
					filter={
						filters(
							dateRange[0],
							tab,
							assignedTo,
							selectedDateLabel
						)[1]
					}
				/>
			</div>
			<div className="mb-12">
				<FeedbackCategory
					title="Tickets due this week"
					filter={
						filters(
							dateRange[0],
							tab,
							assignedTo,
							selectedDateLabel
						)[2]
					}
				/>
			</div>
			<div className="mb-12">
				<FeedbackCategory
					title="Tickets overdue"
					filter={
						filters(
							dateRange[0],
							tab,
							assignedTo,
							selectedDateLabel
						)[3]
					}
				/>
			</div>
			<div className="mb-12">
				<FeedbackCategory
					title="Tickets to be assigned"
					filter={
						filters(
							dateRange[0],
							tab,
							assignedTo,
							selectedDateLabel
						)[4]
					}
				/>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const authStore = store.app.auth;
	const feedbackStore = feedbackSelectors._getStore(store);
	const tab = feedbackSelectors.getFeedbackTicketTabType(feedbackStore);
	const assignedTo = authStore?.user?.email ?? "";
	const dateRange = feedbackSelectors.getDateRange(feedbackStore);
	const jobToGetStatsAndFeedback =
		feedbackSelectors.jobToLoadDashboardFeedbacksAndStats(feedbackStore);

	return {
		tab,
		assignedTo,
		dateRange,
		jobToGetStatsAndFeedback,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setDateRange(dateRange: Range[]) {
			dispatch(
				feedbackActions.document.feedbackDashboardDateRangeSet(dateRange)
			);
		},
		loadDashboardStats(payload: IDashboardStatsAndFeedbackPayload) {
			dispatch(
				feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad(payload)
			);
		},
		loadDashboardFeedbacks(payload: IDashboardStatsAndFeedbackPayload) {
			dispatch(
				feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad(payload)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackDashboard);
