import moment from "moment";
import { marpolUtils } from "../../marpol";
import {
	EBallastOperationCodes,
	EBallastOperationMethods,
	EBallastRecordCode,
	EBallastRecordState,
} from "../@types";
import {
	TBallast_Additional,
	TBallast_Additional_MissedEntry,
} from "./additional";
import { TBallast_Ballast } from "./ballast";
import { TBallast_Deballast } from "./deballast";
import { TBallast_Exceptional } from "./exceptional";
import { TBallast_Exchange } from "./exchange";
import { TBallast_Internal } from "./internal";
import { EMARPOLDocuments } from "../../marpol/@types";

const ballastDefaults: {
	[EBallastOperationCodes.BALLAST_WITHOUT_TREATMENT]: TBallast_Ballast;
	[EBallastOperationCodes.BALLAST_WITH_TREATMENT]: TBallast_Ballast;
	[EBallastOperationCodes.DEBALLAST_IN_PORT]: TBallast_Deballast;
	[EBallastOperationCodes.DEBALLAST_IN_SEA]: TBallast_Deballast;
	[EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY]: TBallast_Deballast;
	[EBallastOperationCodes.EXCHANGE_BALLAST_USING_SEQUENTIAL]: TBallast_Exchange;
	[EBallastOperationCodes.EXCHANGE_BALLAST_USING_FLOW_THROUGH]: TBallast_Exchange;
	[EBallastOperationCodes.INTERNAL_TRANSFER_BETWEEN_BALLAST]: TBallast_Internal;
	[EBallastOperationCodes.ACCIDENTAL_DISCHARGE_OF_BALLAST_WATER]: TBallast_Exceptional;
	[EBallastOperationCodes.EXCEPTIONAL_DEBALLAST_FROM_CARGO]: TBallast_Exceptional;
	[EBallastOperationCodes.ADDITIONAL_PROCEDURES]: TBallast_Additional;
	[EBallastOperationCodes.ADDITIONAL_PROCEDURES_MISSED_ENTRY]: TBallast_Additional_MissedEntry;
} = {
	[EBallastOperationCodes.BALLAST_WITHOUT_TREATMENT]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.BALLAST,
		operationCode: EBallastOperationCodes.BALLAST_WITHOUT_TREATMENT,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: false,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EMPTY_PUMP,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.BALLAST_WITH_TREATMENT]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.BALLAST,
		operationCode: EBallastOperationCodes.BALLAST_WITH_TREATMENT,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EMPTY_PUMP,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.DEBALLAST_IN_PORT]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.DEBALLAST,
		operationCode: EBallastOperationCodes.DEBALLAST_IN_PORT,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EMPTY_PUMP,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.DEBALLAST_IN_SEA]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.DEBALLAST,
		operationCode: EBallastOperationCodes.DEBALLAST_IN_SEA,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		originOfBallast: "",
		placeOfBallast: {
			place: "AT_SEA",
			startPosition: {
				latitude: "",
				longitude: "",
			},
			endPosition: {
				latitude: "",
				longitude: "",
			},
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EMPTY_PUMP,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.DEBALLAST,
		operationCode: EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "AT_RECEPTION_FACILITY",
			receiptAttachment: "",
			receiptNumber: "",
			startDate: "",
			startTime: "",
			originOfBallast: "",
			receptionFacilityName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: false,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EMPTY_PUMP,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.EXCHANGE_BALLAST_USING_SEQUENTIAL]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.EXCHANGE,
		operationCode: EBallastOperationCodes.EXCHANGE_BALLAST_USING_SEQUENTIAL,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EXCHANGE_EMPTY_REFILL,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.EXCHANGE_BALLAST_USING_FLOW_THROUGH]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.EXCHANGE,
		operationCode: EBallastOperationCodes.EXCHANGE_BALLAST_USING_FLOW_THROUGH,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.EXCHANGE_FLOW_THROUGH,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.INTERNAL_TRANSFER_BETWEEN_BALLAST]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.INTERNAL,
		operationCode: EBallastOperationCodes.INTERNAL_TRANSFER_BETWEEN_BALLAST,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		sourceTank: {
			selectedTank: marpolUtils.getDefaultsForNewTank(),
			startDate: moment().format("YYYY-MM-DD"),
			startTime: moment().format("HH:mm"),
			endDate: moment().format("YYYY-MM-DD"),
			endTime: moment().add(5, "minutes").format("HH:mm"),
			transferQuantity: 0,
			differenceInQuantity: 0,
			newRetainedQuantity: 0,
			isDifferenceInQtyAcknowledged: false,
		},
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		destinationTanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		operationMethod: EBallastOperationMethods.INTERNAL_TRANSFER,
		pumpsUsed: "",
		temperature: 0,
		salinity: 0,
		depth: 0,
		density: 1.025,
	},
	[EBallastOperationCodes.ACCIDENTAL_DISCHARGE_OF_BALLAST_WATER]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.EXCEPTIONAL,
		operationCode: EBallastOperationCodes.ACCIDENTAL_DISCHARGE_OF_BALLAST_WATER,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		circumstantialRemarks: "",
	},
	[EBallastOperationCodes.EXCEPTIONAL_DEBALLAST_FROM_CARGO]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.EXCEPTIONAL,
		operationCode: EBallastOperationCodes.EXCEPTIONAL_DEBALLAST_FROM_CARGO,
		selectedDate: moment().format("YYYY-MM-DD"),
		originOfBallast: "",
		remarks: "",
		placeOfBallast: {
			place: "AT_SEA",
			startPosition: {
				latitude: "",
				longitude: "",
			},
			endPosition: {
				latitude: "",
				longitude: "",
			},
		},
		tanks: [],
		totalOperatedWaterVolume: 0,
		ballastROB: 0,
		isBallastWaterTreatmentPlanUsed: true,
		isBallastWaterTreatmentSystemUsed: true,
		circumstantialRemarks: "",
	},
	[EBallastOperationCodes.ADDITIONAL_PROCEDURES]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.ADDITIONAL,
		operationCode: EBallastOperationCodes.ADDITIONAL_PROCEDURES,
		placeOfBallast: {
			place: "IN_PORT",
			portName: "",
		},
		startDate: moment().format("YYYY-MM-DD"),
		startTime: moment().format("HH:mm"),
		endDate: moment().format("YYYY-MM-DD"),
		endTime: moment().add(5, "minutes").format("HH:mm"),
		isIntendToUpdateTanks: false,
		isVesselStateInDryDocking: false,
		tanks: [],
		ballastROB: 0,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
	},
	[EBallastOperationCodes.ADDITIONAL_PROCEDURES_MISSED_ENTRY]: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		state: EBallastRecordState.IDLE,
		recordCode: EBallastRecordCode.ADDITIONAL,
		operationCode: EBallastOperationCodes.ADDITIONAL_PROCEDURES_MISSED_ENTRY,
		placeOfBallast: {
			place: "AT_SEA",
			startPosition: {
				latitude: "",
				longitude: "",
			},
			endPosition: {
				latitude: "",
				longitude: "",
			},
		},
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		itemMissedEntry: {
			dateOfOriginalOperation: "",
			isAnMissedEntryRecord: true,
			missedRecord: undefined,
			receiptAttachment: "",
		},
	},
};

export default ballastDefaults;
