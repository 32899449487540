import classNames from 'classnames';
import InputWrapper from 'components/_common/InputWrapper';
import React from 'react';

type WayshipNumberProps = {
    title: string;
    dataKey?: string;
    step?: number;
    value: any;
    onChangeHandler?: (value: any, dataKey: string) => void;
    type?: string;
    isSubmit?: boolean;
    onFocus?: (title: string) => void;
    onBlurHandler?: (value: any, dataKey: string) => void;
    onKeyDown?: (e: any) => void;
    required?: boolean;
    error?: any;
    placeholder?: string;
    isWholeNumber?: boolean;
    restrictNo?: {
        max?: number;
        decimalPlaces?: number;
    }
};

function getNumber(total?: number) {
    if (!total) return 0;
    let num = 9;
    for (let i = 1; i < total; i++) {
        num = num * 10 + 9;
    }
    return num;
}

function WayshipNumber(props: WayshipNumberProps) {
    const {
        title,
        dataKey,
        step,
        value,
        onChangeHandler,
        isSubmit,
        placeholder,
        onFocus,
        error,
        required,
        onBlurHandler,
        isWholeNumber = true,
        restrictNo
    } = props;

    return (
        <InputWrapper
            className="w-full"
            hasError={
                error && error.hasOwnProperty('isValid')
                    ? !error.isValid
                    : false
            }
            hasWarning={false}
            inputValue={value}
        >
            <label htmlFor="number-input-random" className="ws-input__label">
                {title}
            </label>
            <input
                type="number"
                name="number-input-random"
                step={step}
                min={isWholeNumber ? 0 : undefined}
                id={dataKey}
                className={classNames('ws-input', {
                    'ws-input--read-only': isSubmit,
                })}
                placeholder={placeholder || ''}
                value={value || ''}
                onChange={(e) => {
                    let value = e.target.value;
                    if (restrictNo && value) {
                        if (restrictNo.max !== undefined && Number(value) > restrictNo.max) {
                            e?.preventDefault();
                            return;
                        }
                        const valueArr = value.split('.');
                        if (restrictNo.decimalPlaces !== undefined && valueArr.length >= 2 && valueArr[1].length > restrictNo.decimalPlaces) {
                            e?.preventDefault();
                            return;
                        }
                    }
                    
                    if (onChangeHandler && dataKey) {
                        onChangeHandler(value, dataKey);
                    }
                }}
                onKeyPress={(event) => {
                    if (isWholeNumber) {
                        if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }
                }}
                disabled={isSubmit}
                onFocus={() => {
                    if (onFocus) {
                        onFocus(title);
                    } else {
                        console.log('onFocus not provided!');
                    }
                }}
                onBlur={(e) => {
                    if (onBlurHandler && dataKey) {
                        onBlurHandler(e.target.value, dataKey);
                    }
                }}
                required={required ?? false}
            />
            {/* <ReactJson src={{ value }} /> */}
            <span className="ws-input__error">{error?.message || ''}</span>
            <span className="ws-input__warning">Hello Warning</span>
        </InputWrapper>
    );
}

export default WayshipNumber;
