import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import { RouteProps, withRouter } from "react-router-dom";
import {
	generatePlaceholder,
	getTopNavHeaderTitle,
} from "redux/app/topnavbar/utils";
import topNavbarActions from "redux/app/topnavbar/actions";
import topNavbarSelectors from "redux/app/topnavbar/selectors";
import VesselName from "components/app/vessel/onboard/_elements/VesselName";
import classNames from "classnames";
import Notification from "./Notification";
import { isNotificationPopoverVisible } from "./util";

type OwnProps = {};
type TopNavbarProps = PropsFromRedux & RouteProps & OwnProps;
function TopNavbar(props: TopNavbarProps) {
	const { location, headerTitle, searchText, searchPlaceholder } = props;

	useEffect(() => {
		if (location?.pathname) {
			const headerTitle = getTopNavHeaderTitle(location);
			props.setNavTitle(headerTitle);
			props.setSearchText("");
			const generatedPlaceholder = generatePlaceholder(location.pathname);
			props.setPlaceholder(generatedPlaceholder);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const homePageKeys = [
		// "dashboard",
		// "crew",
		// "device",
		// "tanks",
		// "logs",
		// "marpol",
		"onboard",
	];

	if (
		location?.pathname.includes("app/dashboard/list/") ||
		location?.pathname.includes("app/feedback/view") ||
		location?.pathname.includes("app/tools/list/config-print") ||
		location?.pathname.includes("app/tools/list/training-certificate")
	) {
		return <></>;
	}

	const headerRenderer = homePageKeys.some((key) =>
		location?.pathname.includes(key)
	) ? (
		<div className="ml-4">
			<VesselName
				goBack={() => {
					props.goBackToHome();
				}}
			/>
		</div>
	) : (
		<p className="text-[26px] ">{headerTitle}</p>
	);

	// do not disable search input for feedback and vessels page
	const isDisabled =
		!location?.pathname.includes("/app/feedback") &&
		!location?.pathname.includes("/app/vessel");

	return (
		<div>
			<div className="hidden m-2 mb-0 bg-white rounded-lg md:block">
				<div className="flex items-center justify-between p-3">
					<div>{headerRenderer}</div>
					<div className="flex items-center space-x-4">
						<form>
							<label
								htmlFor="default-search"
								className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
							>
								Search
							</label>
							<div className="relative">
								<div className="absolute inset-y-0 left-0 flex items-center pl-3 transform -translate-y-1/2 pointer-events-none top-1/2">
									<span className="text-sm text-gray-600 bp3-icon bp3-icon-search" />
								</div>
								<input
									type="text"
									id="default-search"
									className={classNames(
										"block w-[300px] p-2 py-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-[#F0F0F1] focus:ring-gray-600 focus:border-gray-600 font-redhat-text placeholder:text-gray-500",
										{
											"cursor-not-allowed": isDisabled,
										}
									)}
									placeholder={searchPlaceholder}
									value={searchText}
									disabled={isDisabled}
									onChange={(e) =>
										props.setSearchText(e.target.value)
									}
								/>
							</div>
						</form>

						<Notification
							popoverVisible={
								isNotificationPopoverVisible(
									location?.pathname || ''
								)
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const topNavbarStore = topNavbarSelectors._getStore(store);
	return {
		headerTitle: topNavbarSelectors.getNavbarTitle(topNavbarStore),
		searchText: topNavbarSelectors.getSearchInputText(topNavbarStore),
		searchPlaceholder:
			topNavbarSelectors.getSearchPlaceholderText(topNavbarStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setNavTitle(title: string) {
			dispatch(topNavbarActions.document.navbarTitleSet(title));
		},
		setSearchText(inputText: string) {
			dispatch(topNavbarActions.document.searchTextSet(inputText));
		},
		setPlaceholder(placeholder: string) {
			dispatch(
				topNavbarActions.document.navbarSearchPlaceholderSet(placeholder)
			);
		},
		goBackToHome() {
			window.history.back();
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default withRouter(reduxConnector(TopNavbar));
