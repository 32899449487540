import React from "react";
import {
	IGarbageAccidentalLoss,
	IGarbageDischargedIntoSea,
	IGarbageDischargedToReceptionAshoreOrOtherShips,
	IGarbageIncinerated,
	IGarbageRecord,
} from "../../../../../redux/app/garbage-record/@types";
import moment from "moment";

type OwnProps = {
	garbageRecord: IGarbageRecord;
};
type GarbageRecordEntryPositionProps = OwnProps;
function GarbageRecordEntryPosition(props: GarbageRecordEntryPositionProps) {
	const { garbageRecord } = props;
	const { entry } = garbageRecord;
	const isRecordOlder = moment(garbageRecord.selectedDate).isBefore(
		moment("2023-08-25")
	);
	switch (entry) {
		case "GARBAGE_INCINERATED": {
			const grb1Record = garbageRecord as IGarbageIncinerated;
			if (isRecordOlder)
				return (
					<>
						<div className="leading-5 font-redhat-text">
							<span
								className="block mb-px text-xs font-medium text-gray-600"
								id="position"
							>
								START AT
							</span>
							{grb1Record.locationOfStartOfIncineration.latitude ? (
								<div className="block mb-1 font-medium whitespace-nowrap ">
									<span className="block text-xs text-gray-700">Latitude</span>
									{grb1Record.locationOfStartOfIncineration.latitude}
								</div>
							) : null}
							{grb1Record.locationOfStartOfIncineration.longitude ? (
								<div className="block font-medium whitespace-nowrap">
									<span className="block text-xs text-gray-700">Longitude</span>
									{grb1Record.locationOfStartOfIncineration.longitude}
								</div>
							) : null}
						</div>
						<div className="mt-6 leading-5 font-redhat-text">
							<span
								className="block mb-px text-xs font-medium text-gray-600"
								id="position"
							>
								STOP AT
							</span>
							{grb1Record.locationOfCompletionOfIncineration.latitude ? (
								<div className="block mb-1 font-medium whitespace-nowrap ">
									<span className="block text-xs text-gray-700">Latitude</span>
									{grb1Record.locationOfCompletionOfIncineration.latitude}
								</div>
							) : null}
							{grb1Record.locationOfCompletionOfIncineration.longitude ? (
								<div className="block font-medium whitespace-nowrap">
									<span className="block text-xs text-gray-700">Longitude</span>
									{grb1Record.locationOfCompletionOfIncineration.longitude}
								</div>
							) : null}
						</div>
					</>
				);
			return (
				<div className="leading-5 font-redhat-text">
					{grb1Record.locationOfCompletionOfIncineration.latitude ? (
						<div className="block mb-1 font-medium whitespace-nowrap ">
							{grb1Record.locationOfCompletionOfIncineration.latitude}
						</div>
					) : null}
					{grb1Record.locationOfCompletionOfIncineration.longitude ? (
						<div className="block font-medium whitespace-nowrap">
							{grb1Record.locationOfCompletionOfIncineration.longitude}
						</div>
					) : null}
				</div>
			);
		}
		case "GARBAGE_DISCHARGED_INTO_SEA":
			return (
				<div className="h-full leading-5 font-redhat-text">
					{(garbageRecord as IGarbageDischargedIntoSea)
						.locationOfStartOfDischarge.latitude ? (
						<div className="block mb-1 font-medium whitespace-nowrap">
							<span className="block text-xs text-gray-700">Latitude</span>
							{
								(garbageRecord as IGarbageDischargedIntoSea)
									.locationOfStartOfDischarge.latitude
							}
						</div>
					) : null}
					{(garbageRecord as IGarbageDischargedIntoSea)
						.locationOfStartOfDischarge.longitude ? (
						<div className="block font-medium whitespace-nowrap">
							<span className="block text-xs text-gray-700">Longitude</span>
							{
								(garbageRecord as IGarbageDischargedIntoSea)
									.locationOfStartOfDischarge.longitude
							}
						</div>
					) : null}
				</div>
			);
		case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
			return (
				<div className="h-full leading-5 font-redhat-text">
					{(garbageRecord as IGarbageDischargedToReceptionAshoreOrOtherShips)
						.placeOfDischarge ? (
						<span className="block mb-1 font-medium whitespace-nowrap">
							<span className="block text-xs text-gray-700">
								Place of Discharge
							</span>
							{
								(
									garbageRecord as IGarbageDischargedToReceptionAshoreOrOtherShips
								).placeOfDischarge
							}
						</span>
					) : null}
				</div>
			);
		case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
			return (
				<div className="h-full leading-5 font-redhat-text">
					{(garbageRecord as IGarbageAccidentalLoss).locationOfOccurance ? (
						<span className="block mb-1 font-medium whitespace-nowrap">
							<span className="block text-xs text-gray-700">Occured At:</span>

							{(garbageRecord as IGarbageAccidentalLoss).locationOfOccurance}
						</span>
					) : null}
				</div>
			);
	}
}

export default GarbageRecordEntryPosition;
