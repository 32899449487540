import React, { FC } from 'react'
import { IListObjectStore } from 'redux/_common/type';
import { TAB_IDS } from 'redux/app/vessel/constants';
import { IVessel, TVesselCardOperation } from 'redux/app/vessel/types';
import VesselCard from './VesselCard';

interface IVesselsListProps {
	vesselsToShow: IListObjectStore<IVessel>;
	isSelectionEnabled: boolean;
	selectedVesselsOnSelectionEnabled: string[];

	selectVesselAndAdd: (vesselId: string) => void;
	onOnboardClick: (vesselId: string) => void;
	updateMyVesselList: (updatedVessels: IListObjectStore<IVessel>) => void;
	vesselCardOperationAdd: (operation: TVesselCardOperation) => void;
	favoriteAVessel: (favoritedVesselIds: string[]) => void;
	saveFavorite: (favoritedVesselIds: string[]) => void;

	favoritedVesselIds: string[];
	currentSelectedTab: string;
}

const VesselsCard: FC<IVesselsListProps> = (props) => {
	const { vesselsToShow, isSelectionEnabled, selectedVesselsOnSelectionEnabled, favoritedVesselIds, currentSelectedTab, saveFavorite } = props;
	return (
		<div className="flex flex-wrap mb-4 justify-center items-stretch">
			{Array.isArray(vesselsToShow?.ids) &&
				vesselsToShow?.ids?.map((id) => {
					const vessel = vesselsToShow.byIds[id];
					return (
						<div
							onClick={() => {
								if (isSelectionEnabled) {
									props.selectVesselAndAdd(id);
								} else {
									props.onOnboardClick(vessel.id);
								}
							}}
							className="mb-4 mx-2 cursor-pointer"
							key={vessel.id}
						>
							<VesselCard
								isSelected={selectedVesselsOnSelectionEnabled?.includes(
									id
								)}
								handleFavorite={(e) => {
									if (!isSelectionEnabled) {
										e.stopPropagation();
										const vesselIdToFavorite = id;
										let updatedFavoritedVesselIds: string[] = [];
										let operation: TVesselCardOperation;
										if (favoritedVesselIds.includes(vesselIdToFavorite)) {
											updatedFavoritedVesselIds =
												favoritedVesselIds.filter(
													(vesselId) => vesselId !== vesselIdToFavorite
												);
											operation = "unfav";
										} else {
											updatedFavoritedVesselIds = [
												...favoritedVesselIds,
												vesselIdToFavorite,
											];
											operation = "fav";
										}

										if (
											favoritedVesselIds.includes(vesselIdToFavorite) &&
											currentSelectedTab === TAB_IDS.myVessels
										) {
											let updatedVessels =
												vesselsToShow as IListObjectStore<IVessel>;
											updatedVessels = {
												ids: updatedVessels.ids.filter(
													(id) => id !== vesselIdToFavorite
												),
												byIds: Object.keys(updatedVessels.byIds)
													.filter((id) => id !== vesselIdToFavorite)
													.reduce((acc, curr) => {
														acc[curr] = updatedVessels.byIds[curr];
														return acc;
													}, {} as typeof updatedVessels.byIds),
											};
											props.updateMyVesselList(updatedVessels);
										}

										props.vesselCardOperationAdd(operation);
										props.favoriteAVessel(updatedFavoritedVesselIds);
										saveFavorite(updatedFavoritedVesselIds);
									}
								}}
								isFavorited={favoritedVesselIds?.includes(vessel.id)}
								vessel={vessel}
							/>
						</div>
					);
				})}
		</div>
	);
}

export default VesselsCard