import { IAPIRequestMetaData } from "redux/_core/api/types";
import { IUser, IVesselUser } from "./@types";
import { getType } from "typesafe-actions";
import userActions from "./actions";
import { commonUtils } from "redux/_common";

const userAPIs = {
	createUser(user: IVesselUser): IAPIRequestMetaData<any> {
		return {
			url: "/api/users",
			method: "POST",
			feature: getType(userActions.commands.userCreate),
			body: user,
		};
	},
	updateUser(
		userId: string,
		user: Partial<IUser>
	): IAPIRequestMetaData<IUser> {
		return {
			url: `/api/users/${userId}`,
			feature: getType(userActions.commands.userUpdate),
			method: "PATCH",
			body: user,
		};
	},
	deleteUser(userId: string, token: string): IAPIRequestMetaData<any> {
		return {
			url: `/api/users/${userId}`,
			feature: getType(userActions.commands.userDelete),
			method: "DELETE",
			headers: {
				// @ts-ignore
				"x-access-token": token,
			},
		};
	},
	loadUserList(
		itemsPerPage: number = 10,
		pageNumber: number = 1,
		isPaginated?: boolean
	): IAPIRequestMetaData<IUser[]> {
		isPaginated = true;
		return {
			url: "/api/users",
			method: "GET",
			feature: getType(userActions.commands.userListLoad),
			queryParams: {
				filter: {
					where: {
						role: "tenantAdmin",
					},
					limit: isPaginated ? itemsPerPage : 200,
					skip: isPaginated ? (pageNumber - 1) * itemsPerPage : 0,
				},
			},
		};
	},
	loadTotalUsersCount(
		tenantId: string
	): IAPIRequestMetaData<{ count: number }> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/count`,
			method: "GET",
			feature: getType(userActions.commands.userCount),
			queryParams: {
				modelName: "user",
				filter: {
					selector: {
						tenantId: tenantId,
						role: "tenantAdmin",
					},
				},
			},
		};
	},
};

export default userAPIs;
