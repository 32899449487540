import React, { useEffect } from "react";
import DeviceForm from "./Form";
import { Dispatch } from "redux";
import { vesselOnboardActions } from "../../../../../../redux/app/vessel/onboard";
import { connect, ConnectedProps } from "react-redux";
import { deviceFormDefaults } from "../../../../../../redux/app/vessel/onboard/reducers.state";
type DeviceCreateType = PropsFromRedux & {
	onClose(): void;
	vesselId: string;
};
function DeviceCreate(props: DeviceCreateType) {
	useEffect(function onLoad() {
		props.setFormModeCreate();
		props.setVesselId(props.vesselId);
	}, []);
	return (
		<DeviceForm
			onCancel={() => {
				props.onClose();
			}}
			onClose={() => {
				props.onClose();
			}}
			title="Add new Device"
			subtitle="Please make sure that you have installed Wayship app in device before proceeding"
		/>
	);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		setVesselId(vesselId: string) {
			dispatch(vesselOnboardActions._forms.device.formFieldsEdit({ vesselId }));
		},
		setFormModeCreate() {
			dispatch(vesselOnboardActions._forms.device.formModeSet("CREATE"));
		},
	};
}
const reduxConnector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(DeviceCreate);
