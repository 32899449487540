import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4O1 } from "../../../../../redux/app/orb4/groups/orb4-o";
import AttachmentsPreview from "../../_elements/AttachmentsPreview";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4O1PreviewProps = OwnProps;
function ORB4O1Preview(props: ORB4O1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4O1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "0", "-", renderItemO1()],
					[null, null, "-", renderItemO2()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItemO1() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.itemO1.generalRemarks}
			</h4>
		);
	}
	function renderItemO2() {
		if (!(orb4Record.itemO2 && orb4Record.itemO2.receiptAttachment !== ""))
			return null;
		return (
			<h4 className="font-medium uppercase">
				<AttachmentsPreview
					{...orb4Record.itemO2}
					_rev={orb4Record._rev}
					id={orb4Record.id}
				/>
			</h4>
		);
	}

	return renderMain();
}

export default ORB4O1Preview;
