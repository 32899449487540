import classNames from 'classnames';
import InfiniteProgress from 'components/_common/InfiniteProgress';
import Pagination from "components/_common/NumberedPagination";
import React, { FC, useEffect, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { historyActions } from 'redux/_core/history';
import { IStore } from 'redux/store';
import { rootRoutes } from 'routes';
import { ISealLog, ISealLogsFilters } from 'redux/app/logs/@types';
import { logActions, logSelectors } from 'redux/app/logs';
import SealLogRow from './SealLogRow';
import InputWrapper from 'components/_common/InputWrapper';
import { useReactToPrint } from 'react-to-print';
import SealLogsPrintTemplate from './SealLogsPrintTemplate';

type SealLogsOwnProps = {
	pageNumber: string;
	vesselId?: string;
};
type SealLogsProps = PropsFromRedux & SealLogsOwnProps;

const SealLogs: FC<SealLogsProps> = (props) => {
	const {
		seallogs,
		paginationOfSeallogs,
		pageNumber,
		jobToLoadSeallogs,
		vesselId,
		sealLogsFilter,
		seallogsPrint,
	} = props;
	const printElementRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => printElementRef.current,
	});

	useEffect(() => {
		props.loadSeallogsPrint(vesselId || "");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId]);

	useEffect(() => {
		// INFO: when user selects a new vessel in the list will run again
		props.loadSeallogsCount(vesselId || "");
		props.loadSeallogs(+pageNumber, vesselId || "");

		return () => {
			props.resetPageNumber();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[vesselId, pageNumber, sealLogsFilter.startDate, sealLogsFilter.endDate]);

	return (
		<div className="min-h-full  py-6 pt-0 mb-6 bg-white rounded-md">
			<div
				className={classNames(
					"flex flex-row items-end justify-between  my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Seal Logs
					</h3>
					<h6 className="text-sm text-gray-600">
						{jobToLoadSeallogs.isLoading
							? "Loading seallogs please wait"
							: ""}
					</h6>
				</div>
				<div
					className='flex items-center gap-x-4'
				>
					<InputWrapper className="w-full py-0 my-0">
						<label className="ws-input__label">Start date</label>
						<input
							type="date"
							name="year-build-date"
							id="year-build-date"
							className="font-bold text-gray-700 ws-input"
							value={sealLogsFilter.startDate}
							onChange={function onStartDateChange(e) {
								props.filtersEdit({
									...sealLogsFilter,
									vesselId,
									startDate: e.target.value,
								});
							}}
							placeholder="From"
						/>
					</InputWrapper>
					<InputWrapper className="w-full py-0 my-0">
						<label className="ws-input__label">End date</label>
						<input
							type="date"
							name="year-build-date"
							id="year-build-date"
							className="font-bold text-gray-700 ws-input"
							value={sealLogsFilter.endDate}
							onChange={function onEndDateChange(e) {
								props.filtersEdit({
									...sealLogsFilter,
									vesselId,
									endDate: e.target.value,
								});
							}}
							placeholder="To"
						/>
					</InputWrapper>
					<div className="flex flex-row items-center justify-center">
						<button className="px-6 py-1.5 mt-6 text-base text-indigo-700 border border-indigo-600 rounded-sm outline-none ws-button-og">
							<span
								className="mr-3 font-medium"
								onClick={() => {
									handlePrint && handlePrint();
								}}
							>
								Print
							</span>
							<span className="mb-0.5 text-sm bp3-icon bp3-icon-print"></span>
						</button>
					</div>
				</div>
			</div>
			<div className="mb-6 overflow-x-auto">
				<InfiniteProgress
					isLoading={jobToLoadSeallogs.isLoading || jobToLoadSeallogs.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full relative mb-6  border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{ "animate-pulse": jobToLoadSeallogs.isLoading }
					)}
				>
					<thead className="relative">
						<tr>
							<th className="text-sm text-gray-500">
								No.
							</th>
							<th className="text-sm text-gray-500">
								Location
							</th>
							<th className="text-sm text-center text-gray-500">
								Seal Fitted
							</th>
							<th className="text-sm text-center text-gray-500">
								Seal No.
							</th>
							<th className="text-sm text-center text-gray-500">
								Seal Removed
							</th>
							<th className="text-sm text-center text-gray-500">
								Reason for removal
							</th>
							<th className="text-sm text-center text-gray-500">
								Recorded By
							</th>
							<th className="text-sm text-center text-gray-500">
								C/E Initials
							</th>
						</tr>
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
							jobToLoadSeallogs.isLoading && seallogs?.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!jobToLoadSeallogs.isLoading,
						})}
					>
						{seallogs?.map((seallog, index) => {
							return (
								<SealLogRow
									seallog={seallog}
									key={index}
									onClick={(seallog: ISealLog) => {
										// selectSeallog(seallog);
									}}
									indexNumber={
										(paginationOfSeallogs.currentPage - 1) *
										paginationOfSeallogs.itemsPerPage +
										(index + 1)
									}
								/>
							);
						})}
					</tbody>
				</table>
				{!jobToLoadSeallogs.isLoading && seallogs?.length === 0 ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
							No Seal Logs found..
						</h5>
					</div>
				) : null}
				<div></div>
				<Pagination
					{...paginationOfSeallogs}
					onPageChange={function onPageChange(
						pageNumberFromPagination
					) {
						props.goToNewPage(vesselId || "", pageNumberFromPagination);
					}}
					isPageLoading={
						jobToLoadSeallogs.isLoading
					}
				/>
			</div>
			<div
				className='invisible h-0 overflow-hidden'
			>
				<div
					ref={printElementRef}
					className=''
				>
					<SealLogsPrintTemplate seallogs={seallogsPrint} />
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const logsStore = store.app.logs;
	const seallogs = logSelectors.getSealLogs(logsStore);
	const seallogsPrint = logSelectors.getSealLogsPrint(logsStore);
	const sealLogsFilter = logSelectors.getSealLogsFilter(logsStore);

	return {
		seallogs,
		seallogsPrint,
		sealLogsFilter,
		paginationOfSeallogs: logSelectors.getSeallogsPagination(logsStore),
		jobToLoadSeallogsPrint: logSelectors.jobToLoadSeallogsPrint(logsStore),
		jobToLoadSeallogs: logSelectors.jobToLoadSeallogs(logsStore),
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		filtersEdit(fields: Partial<ISealLogsFilters>) {
			dispatch(logActions._forms.sealLogsFilter.formFieldsEdit(fields));
		},
		loadSeallogsCount(
			vesselId: string
		) {
			dispatch(
				logActions.commands.seallogsCountLoad(
					vesselId
				)
			);
		},
		loadSeallogs(
			pageNumber: number,
			vesselId: string
		) {
			dispatch(
				logActions.commands.seallogsLoad({
					pageNumber,
					vesselId
				})
			);
		},
		loadSeallogsPrint(
			vesselId: string
		) {
			dispatch(
				logActions.commands.seallogsLoadPrint({
					vesselId
				})
			);
		},
		goToNewPage(vesselId: string, pageNumber: number) {
			dispatch(
				historyActions.push(
					"#Seallog",
					rootRoutes.children.app.children.vessel.children.onboard.children.seallogs.url(
						vesselId, pageNumber
					)
				)
			);
		},
		resetPageNumber() {
			dispatch(logActions.document.seallogsPaginationCurrentPageSet(1));
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(SealLogs);