import moment from "moment";
import React from "react";
import { IGarbageRecord } from "../../../../../redux/app/garbage-record/@types";
type OwnProps = {
	grb1Record: IGarbageRecord;
};
type GRB1ParticipantsProps = OwnProps;
function GRB1Participants(props: GRB1ParticipantsProps) {
	const { grb1Record } = props;
	return (
		<>
			<br />
			<br />
			SUBMITTED BY: <br />
			{grb1Record.submittedBy
				? `${grb1Record.submittedBy.name} [${
						grb1Record.submittedBy.designation
				  }] - ${grb1Record.submittedBy.staffId} ${
						grb1Record.submittedBy.date
							? `on ${moment(grb1Record.submittedBy.date).toISOString()}`
							: ""
				  }`
				: "PENDING"}
			,
			<br />
			<br />
			APPROVED BY: <br />
			{grb1Record.approvedBy
				? `${grb1Record.approvedBy.name} [${
						grb1Record.approvedBy.designation
				  }] - ${grb1Record.approvedBy.staffId} ${
						grb1Record.approvedBy.date
							? `on ${moment(grb1Record.approvedBy.date).toISOString()}`
							: ""
				  }`
				: "PENDING"}
		</>
	);
}

export default GRB1Participants;
