import classNames from "classnames";
import moment from "moment";
import React from "react";
import ORB4Previewer from "../ORB4Previewer";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4O6 } from "../../../../../redux/app/orb4/groups/orb4-o";
import AttachmentsPreview from "../../_elements/AttachmentsPreview";
import { EORB4RecordState, TORB4Record } from "redux/app/orb4/@types";
import ORB4TableRows, { ORB4TableColumns } from "../ORB4TableRows";
type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4GroupO6PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB4GroupO6Preview(props: ORB4GroupO6PreviewProps) {
	const orb4Record = props.orb4Record as TORB4O6;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const dateOfOriginalOperation = moment(
		orb4Record.itemO6.dateOfOriginalOperation,
		"YYYY-MM-DD"
	).format("DD-MMM-YYYY");

	function renderMain() {
		return (
			<>
				<ORB4TableRows
					record={orb4Record}
					tableRows={[
						[dateOfOriginalOperation, "O", "-", renderItemMain()],
						[...renderMissedPlaceholder()],
						[null, null, "NOTE", orb4Record.remarks],
					]}
				/>
				{renderMissedRecord()}
			</>
		);
	}
	function renderItemMain() {
		return (
			<h4 className="font-medium uppercase">
				{/*  This entry key/value validation is a quickfix and stopgap solution for vessel arctic, this validation will be
			  refactored and types will be also be corrected */}
				ENTRY PERTAINING TO AN EARLIER MISSED OPERATIONAL ENTRY
				{orb4Record.itemO6.receiptAttachment &&
				orb4Record.itemO6.receiptAttachment !== " " ? (
					<>
						<br />
						CORRECTION NOTE:{" "}
						<a
							href={marpolUtils.getAttachmentURL(
								orb4Record.itemO6.receiptAttachment,
								orb4Record.id,
								orb4Record._rev
							)}
							download={orb4Record.itemO6.receiptAttachment}
						>
							{" "}
							{orb4Record.itemO6.receiptAttachment}
						</a>
					</>
				) : null}
			</h4>
		);
	}

	function renderMissedPlaceholder(): ORB4TableColumns {
		const canShowPlaceholder =
			orb4Record.itemO6.isAnMissedEntryRecord &&
			!orb4Record.itemO6.missedRecord;
		if (!canShowPlaceholder) return [null, null, null, null];

		const content = (
			<h4 className="font-medium text-gray-500 uppercase">
				<div className="flex flex-row items-center justify-center p-6 px-12 font-medium text-center bg-gray-100 border-2 border-gray-400 border-dashed">
					<div>MISSED ENTRY WILL APPEAR HERE</div>
					<span className="ml-6 text-gray-400 bp3-icon bp3-icon-time"></span>
				</div>
			</h4>
		);

		return [
			operationDate,
			<div className="text-gray-500">?</div>,
			<div className="text-gray-500">?</div>,
			content,
		];
	}

	function renderMissedRecord() {
		if (!orb4Record.itemO6.missedRecord) return null;
		return (
			<ORB4Previewer
				orbRecord={{
					...orb4Record.itemO6.missedRecord,
					selectedDate: orb4Record.selectedDate,
					state: props.isStrikedOff
						? EORB4RecordState.STRIKED_OFF
						: orb4Record.itemO6.missedRecord.state,
				}}
			/>
		);
	}

	return renderMain();
}

export default ORB4GroupO6Preview;
