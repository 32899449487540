import { ActionType, createAction, getType } from "typesafe-actions";
import {
	IVessel_ListRemove__Action,
	IVessel_ListLoad__Action,
	IVessel_ListFilter__Action,
	IVessel_ListSet__Action,
	IVessel_ListReset__Action,
	IVessel_ListPush__Action,
	IVessel,
	IVesselFilters,
	IVessel_listEdit__Action,
	IFleet,
	TVesselCardOperation,
} from "./types";
import actionTypes from "./action-types";
import { IListObjectStore } from "../../_common/type";
import { jobActions } from "../../_core/job";
import { formActions } from "../../_core/form";
import { TAB_IDS } from "./constants";

const {
	VESSEL_LIST_REMOVE,
	VESSEL_LIST_LOAD,
	VESSEL_LIST_FILTER,
	VESSEL_LIST_INFO_SET,
	VESSEL_LIST_SET,
	VESSEL_LIST_RESET,
	VESSEL_LIST_PUSH,
	VESSEL_LIST_EDIT,
	VESSEL_LIST_INFO_LOAD,
	VESSEL_INFO_LOAD,
	VESSEL_LIST_LOAD_NEW,
	TAB_SELECT,
	MY_VESSELS_LIST_SET,
	MY_VESSELS_LOAD,
	FAVORITED_VESSELS_SET,
	FAVORITE_A_VESSEL,
	FLEET_LIST_LOAD,
	FLEET_VESSEL_LIST_LOAD,
	FLEET_LIST_SET,
	FLEET_ID_SET,
	VESSEL_SELECTION_ENABLE,
	VESSEL_SELECTED_ADD,
	VESSEL_SELECTED_RESET,
	FLEET_SELECTED_VESSEL_ASSIGN,
	ASSIGN_VESSELS_FLEET_ID_SET,
	FLEET_NAME_ADD,
	FLEET_CREATE_MODAL_TOGGLE,
	FLEET_CREATE,
	FLEET_LIST_DROPDOWN_TOGGLE,
	ASSIGN_VESSEL_TO_FLEET_DROPDOWN_TOGGLE,
	VESSEL_CARD_OPERATION_ADD,
	VESSEL_CARD_OPERATIONS_RESET,
} = actionTypes;

const customActions = {
	check: createAction(VESSEL_LIST_REMOVE, (vesselId: string) => {
		return { vesselId };
	}),
	hmm: createAction(VESSEL_LIST_LOAD, (vesselId: string) => {
		return { vesselId };
	}),
};
const vesselActions = {
	listRemove: (vesselId: string) => ({
		type: VESSEL_LIST_REMOVE,
		payload: { vesselId },
	}),
	listLoad: (notify: boolean = false) => ({
		type: VESSEL_LIST_LOAD,
		payload: { notify },
	}),
	listLoadNew: (favoritedVesselIds?: string[]) => ({
		type: VESSEL_LIST_LOAD_NEW,
		payload: { favoritedVesselIds },
	}),
	listInfoLoad: () => ({
		type: VESSEL_LIST_INFO_LOAD,
		payload: {},
	}),
	infoLoad: (vesselId: string) => ({
		type: VESSEL_INFO_LOAD,
		payload: { vesselId },
	}),
	fleetListLoad: () => ({ type: FLEET_LIST_LOAD }),
	fleetListSet: (fleets: IFleet[]) => ({
		type: FLEET_LIST_SET,
		payload: { fleets },
	}),
	selectedFleetIdSet: (fleetId: string) => ({
		type: FLEET_ID_SET,
		payload: { fleetId },
	}),
	fleetSpecificVesselListLoad: (fleetId: string) => ({
		type: FLEET_VESSEL_LIST_LOAD,
		payload: { fleetId },
	}),
	assignSelectedVesselsToFleet: () => ({
		type: FLEET_SELECTED_VESSEL_ASSIGN,
	}),
	listMyVessels: () => ({ type: MY_VESSELS_LOAD }),
	favoritedVessels: (vesselIds: string[]) => ({
		type: FAVORITED_VESSELS_SET,
		payload: { vesselIds },
	}),
	favoriteAVessel: (favoritedVesselIds: string[]) => ({
		type: FAVORITE_A_VESSEL,
		payload: { favoritedVesselIds },
	}),
	listFilter: () => ({
		type: VESSEL_LIST_FILTER,
		payload: {},
	}),

	listInfoSet: (vessels: IListObjectStore<IVessel>) => ({
		type: VESSEL_LIST_INFO_SET,
		payload: vessels,
	}),
	listSet: (vessels: IListObjectStore<IVessel>) => ({
		type: VESSEL_LIST_SET,
		payload: vessels,
	}),
	myVesselsListSet: (vessels: IListObjectStore<IVessel>) => ({
		type: MY_VESSELS_LIST_SET,
		payload: vessels,
	}),
	listReset: () => ({
		type: VESSEL_LIST_RESET,
		payload: {},
	}),
	listPush: (vessel: IVessel) => ({
		type: VESSEL_LIST_PUSH,
		payload: vessel,
	}),
	listEdit(vesselId: string, vessel: IVessel) {
		return {
			type: VESSEL_LIST_EDIT,
			payload: {
				vesselId,
				vessel,
			},
		};
	},
	_jobs: {
		vesselListRemove: jobActions("@vessel", VESSEL_LIST_REMOVE),
		vesselListLoad: jobActions("@vessel", VESSEL_LIST_LOAD),
		vesselListLoadNew: jobActions("@vessel", VESSEL_LIST_LOAD_NEW),
		vesselListInfoLoad: jobActions("@vessel", VESSEL_LIST_INFO_LOAD),
		vesselInfoLoad: jobActions("@vessel", VESSEL_INFO_LOAD),
		vesselListFilter: jobActions("@vessel", VESSEL_LIST_FILTER),
		vesselMyListLoad: jobActions("@vessel", MY_VESSELS_LOAD),
		favoriteAVessel: jobActions("@vessel", FAVORITE_A_VESSEL),
		fleetListLoad: jobActions("@vessel", FLEET_LIST_LOAD),
		assignSelectedVesselsToFleet: jobActions(
			"@vessel",
			FLEET_SELECTED_VESSEL_ASSIGN
		),
		createFleet: jobActions("@vessel", FLEET_CREATE),
	},
	_forms: {
		filters: formActions<IVesselFilters>("FILTERS", "@vessel"),
	},
	tabSelect: (tabSelected: TAB_IDS) => ({
		type: TAB_SELECT,
		payload: { tabSelected },
	}),
	enableVesselSelection: (isSelected: boolean) => ({
		type: VESSEL_SELECTION_ENABLE,
		payload: {
			isSelected,
		},
	}),
	addVesselToSelectedListOnEnable: (vesselId: string) => ({
		type: VESSEL_SELECTED_ADD,
		payload: {
			vesselId,
		},
	}),
	selectedVesselsReset: () => ({
		type: VESSEL_SELECTED_RESET,
	}),
	assignToFleetDropdownIdSet: (fleetId: string) => ({
		type: ASSIGN_VESSELS_FLEET_ID_SET,
		payload: {
			fleetId,
		},
	}),
	fleetNameSet: (fleetName: string) => ({
		type: FLEET_NAME_ADD,
		payload: {
			fleetName,
		},
	}),
	createFleetModalToggle: () => ({
		type: FLEET_CREATE_MODAL_TOGGLE,
	}),
	createFleet: () => ({
		type: FLEET_CREATE,
	}),
	fleetListDropdownToggle: (isOpen: boolean) => ({
		type: FLEET_LIST_DROPDOWN_TOGGLE,
		payload: {
			isOpen,
		},
	}),
	assignVesselToFleetDropdownToggle: (isOpen: boolean) => ({
		type: ASSIGN_VESSEL_TO_FLEET_DROPDOWN_TOGGLE,
		payload: {
			isOpen,
		},
	}),
	priorToDebounceOperationAdd: (operation: TVesselCardOperation) => ({
		type: VESSEL_CARD_OPERATION_ADD,
		payload: {
			operation,
		},
	}),
	// After debounced save - we need to reset all fav/unfav operations stored in operationsPriorToDebounce key
	vesselOperationsReset: () => ({
		type: VESSEL_CARD_OPERATIONS_RESET,
	}),
};

getType(customActions.check());
export type RootAction = ActionType<typeof customActions>;

export default vesselActions;
