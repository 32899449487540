import React from "react";
type Props = {
	color: string;
};

function RectangularStatusBar(props: Props) {
	const { color } = props;
	return (
		<div
			className="border border-[#D0D0D0] rounded-md w-full h-full py-4"
			style={{ background: color }}
		></div>
	);
}

export default RectangularStatusBar;
