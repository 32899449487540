import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupI3 } from "../../../../../redux/app/annex6/groups/group-i";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupI3PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupI3Preview(props: Annex6GroupI3PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupI3;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "I", 13.1, renderItem13P1()],
					[null, null, 13.2, renderItem13P2()],
					[null, null, 13.3, renderItem13P3()],
					[null, null, 13.4, renderItem13P4()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem13P1() {
		return <h4 className="font-medium uppercase">{record.item13P1.time}</h4>;
	}

	function renderItem13P2() {
		return (
			<h4 className="font-medium uppercase">
				{record.item13P2.descriptionOfRepair}
			</h4>
		);
	}

	function renderItem13P3() {
		return (
			<h4 className="font-medium uppercase">
				{record.item13P3.nameOfSystemCharged}
				<br />
				{record.item13P3.totalCapacityOfODS} KG
			</h4>
		);
	}

	function renderItem13P4() {
		return (
			<h4 className="font-medium uppercase">
				{record.item13P4.odsName}
				<br />
				{record.item13P4.quantityCharged} KG
			</h4>
		);
	}

	return renderMain();
}

export default Annex6GroupI3Preview;
