import React from 'react'
import classnames from 'classnames'

type ModalType = React.PropsWithChildren<{
    isActive: boolean
    onClose: () => void
    className?: string
    isFullWidth?: boolean
}>
const Modal: React.SFC<ModalType> = ({
    isActive,
    onClose,
    children,
    className,
    isFullWidth = false,
}: ModalType) => {
    return (
        <div
            className={classnames({
                'ws-modal--active': isActive,
                'h-0 z-0 pointer-events-none ': !isActive,
            })}
        >
            <div
                className={classnames(
                    'ws-modal',
                    {
                        'opacity-0': !isActive,
                        'opacity-1': isActive,
                    },
                    className
                )}
            >
                <div className="ws-modal__overlay" onClick={onClose} />
                <div
                    className={classnames('ws-modal__container ', {
                        ' pointer-events-none ': !isActive,
                        'md:w-4/5 sm:w-full lg:w-3/5': !isFullWidth,
                        'w-full max-h-full overflow-y-auto ws-scroll': isFullWidth,
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal
