import React from "react";
import DownloadButton from "../Utils/DownloadButton";
import { SectionUI } from "../Utils/SectionUI";
import config from './vdf03.json';
// import '../app.css';

const VDF03 = () => {
	return (
		<div className="antialiased px-6 bg-white">
			<div className="py-12 px-40 flex flex-col">
				{config.checklist.map((checklist) => {
					return <SectionUI key={checklist.key} checklist={checklist} />;
				})}
				<DownloadButton />
			</div>
		</div>
	);
}

export default VDF03;