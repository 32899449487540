import { IAPIRequestMetaData } from "../../_core/api/types";
import extActionTypes from "./action-types";

const {
	LOAD_TASK,
	FORM_UPDATE
} = extActionTypes;

const extAPIs = {
	getTask(authToken: string): IAPIRequestMetaData<any> {
		return {
			feature: LOAD_TASK,
			method: "POST",
			url: "api/extUsers/authenticate",
			headers: {
				// @ts-ignore
				Authorization: authToken,
				"x-access-token": undefined
			},
		};
	},
	updatePermit(
		authToken: string,
		permitId: string,
		permit: Partial<any>) :  IAPIRequestMetaData<any> {
		return {
			feature: FORM_UPDATE,
			method: "PATCH",
			url: "api/permitLogs/" + permitId,
			body: permit,
			headers: {
				// @ts-ignore
				Authorization: authToken,
				"x-access-token": undefined
			},
		};
	}
};

export default extAPIs;
