export const API_REQUEST = "api/REQUEST";
export const API_SUCCESS = "api/SUCCESS";
export const API_ERROR = "api/ERROR";
export const API_DEFAULT_HEADER_SET = "api/default-header/SET";

export const typeCreator = (feature: string) => ({
	API_REQUEST: `${feature} [${API_REQUEST}]`,
	API_SUCCESS: `${feature} [${API_SUCCESS}]`,
	API_ERROR: `${feature} [${API_ERROR}]`,
	API_DEFAULT_HEADER_SET: `${feature} [${API_DEFAULT_HEADER_SET}]`,
});
