import React from "react";
import UserList from "components/app/user/list/List";

type ScreenUserListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreenUserList(props: ScreenUserListProps) {
	return <UserList pageNumber={props.match.params.pageNumber} />;
}
export default ScreenUserList;
