import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Log from "../../../components/app/log/Log";
import { rootRoutes } from "../../../routes";
import ScreenLogList from "./list";
import ScreenLogView from "./view";

const logRoutes = rootRoutes.children.app.children.log.children;
type ScreenLogProps = React.PropsWithChildren<{}>;
function ScreenLog(props: ScreenLogProps) {
	return (
		<Log>
			<Switch>
				<Route path={logRoutes.list.path} component={ScreenLogList} />
				<Route path={logRoutes.view.path} component={ScreenLogView} />
				<Route render={RedirectComponent} />
			</Switch>
		</Log>
	);
}

function RedirectComponent() {
	return <Redirect to={logRoutes.list.url()} />;
}
export default ScreenLog;
