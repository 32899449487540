import classNames from 'classnames';
import InfiniteProgress from 'components/_common/InfiniteProgress';
import Pagination from "components/_common/NumberedPagination";
import SidebarModal from 'components/app/_common/SidebarModal';
import Discrepancies from 'components/app/log/list/_elements/Discrepancy';
import { IDiscrepancy } from 'components/app/log/types';
import { getDefaultSidebarObj } from 'components/app/log/utils';
import React, { FC, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { historyActions } from 'redux/_core/history';
import { IStore } from 'redux/store';
import { rootRoutes } from 'routes';
import ChecklistRow from './ChecklistRow';
import { ITabChecklist } from 'redux/app/checklist/types';
import checklistSelectors from 'redux/app/checklist/selectors';
import { checklistActions } from 'redux/app/checklist';
import ModalPortal from 'components/app/_common/ModalPortal';
import TemplateRenderer from './TemplateRenderer';
import ChecklistFilterHeader from './ChecklistFilterHeader';

type ChecklistOwnProps = {
	pageNumber: string;
	vesselId?: string;
};

type ChecklistProps = PropsFromRedux & ChecklistOwnProps;

const Checklist: FC<ChecklistProps> = (props) => {
	const {
		checklists,
		paginationOfChecklists,
		pageNumber,
		jobToLoadChecklists,
		jobToFilterChecklists,
		vesselId,
	} = props;
	const setIsDragRef = useRef((value: boolean) => {});
	
	useEffect(() => {
		// INFO: when user selects a new vessel in the list will run again
		props.loadChecklistUniqueList(vesselId || "");
		props.loadChecklistCount(vesselId || "");
		props.loadChecklists(paginationOfChecklists.currentPage !== +pageNumber ? +pageNumber : paginationOfChecklists.currentPage, true, vesselId || "")

		return () => {
			props.resetPageNumber();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[vesselId,paginationOfChecklists.currentPage,pageNumber])

	useEffect(() => {
		return () => {
			// INFO: On change of vessel, logType remains set, that needs to be resettled
			// props.resetLogType();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const [isActive, setIsActive] = useState(false);
	const [sidebarObj, setSidebarObj] = useState(getDefaultSidebarObj());
	const [discrepancy, setDiscrepancy] = useState<IDiscrepancy>();

	const [logOperation, setLogOperation] = useState<{
		isModalOpen: boolean;
		logId: string;
		watchHour?: string;
	}>({
		isModalOpen: false,
		logId: "",
		watchHour: "",
	});

	function resetOperations() {
		setLogOperation({
			...logOperation,
			isModalOpen: false,
			logId: "",
			watchHour: "",
		});
	}

	function selectChecklist(checklist: ITabChecklist | null) {
		if (checklist) {
			const startWatchHour = "";
			const endWatchHour = "";
			setLogOperation({
				isModalOpen: true,
				logId: checklist._id,
				watchHour:
					(startWatchHour ? startWatchHour : "") +
					(endWatchHour ? endWatchHour : ""),
			});
			props.loadChecklist(checklist, vesselId || "");
		}
	}

	return (
		<div className="min-h-full  py-6 pt-0 mb-6 bg-white rounded-md">
			<SidebarModal
				isActive={isActive}
				onClose={() => {
					setIsActive(false);
					setSidebarObj(getDefaultSidebarObj());
					setDiscrepancy(undefined);
				}}
				title={
					<h2 className="text-lg font-semibold font-redhat-display">
						Discrepancies
					</h2>
				}
			>
				<p className="flex items-center justify-between px-5 gap-x-2">
					<span className="font-medium text-gray-800">
						{sidebarObj.title}
					</span>
					<span className="font-mono text-right text-gray-700">
						{sidebarObj.selectedDate}
					</span>
				</p>
				<Discrepancies
					onClick={
						sidebarObj.onClickEvent
							? (discrepancy) => {
									setIsDragRef && setIsDragRef.current(true);
									setDiscrepancy({ ...discrepancy });
									setIsActive(false);
									setSidebarObj(getDefaultSidebarObj());
							  }
							: undefined
					}
					discrepanciesArray={sidebarObj.discrepanciesArray}
				/>
			</SidebarModal>
			<div
				className={classNames(
					"flex flex-row items-end justify-between  my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Checklists
					</h3>
					<h6 className="text-sm text-gray-600">
						{jobToLoadChecklists.isLoading
							? "Loading checklists please wait"
							: ""}
					</h6>
				</div>
			</div>
			<div className="mb-6 overflow-x-auto">
				<InfiniteProgress
					isLoading={jobToLoadChecklists.isLoading || jobToFilterChecklists.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full relative mb-6  border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{ "animate-pulse": jobToLoadChecklists.isLoading }
					)}
				>
					<thead className="relative">
						<tr>
							<th className="text-sm text-gray-500">No.</th>
							<th className="text-sm text-gray-500">Log type</th>
							{/* <th className="text-sm text-gray-500">Vessel</th> */}
							<th className="text-sm text-center text-gray-500">
								Created Date
							</th>
							<th className="text-sm text-center text-gray-500">
								Selected Date
							</th>
							<th className="text-sm text-center text-gray-500">
								Actions
							</th>
						</tr>
						<ChecklistFilterHeader />
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
								jobToLoadChecklists.isLoading && checklists?.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!jobToLoadChecklists.isLoading,
						})}
					>
						{checklists?.map((checklist, index) => {
							return (
								<ChecklistRow
									openSidebarModal={(data) => {
										setSidebarObj(data);
										setIsActive(true);
									}}
									checklistId=""
									checklist={checklist}
									key={index}
									onClick={(checklist: ITabChecklist) => {
										selectChecklist(checklist);
									}}
									indexNumber={
										(paginationOfChecklists.currentPage - 1) *
											paginationOfChecklists.itemsPerPage +
										(index + 1)
									}
								/>
							);
						})}
					</tbody>
				</table>
				{!jobToLoadChecklists.isLoading && checklists?.length === 0 ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
							No Checklists found..
						</h5>
					</div>
				) : null}
				<div></div>
					<Pagination
						{...paginationOfChecklists}
						onPageChange={function onPageChange(
							pageNumberFromPagination
						) {
							props.goToNewPage(vesselId || "", pageNumberFromPagination);
						}}
						isPageLoading={
							jobToLoadChecklists.isLoading || jobToFilterChecklists.isLoading
						}
					/>
				
			</div>
				<ModalPortal
							isActive={logOperation.isModalOpen}
							onClose={() => {
								resetOperations();
							}}
							isFullWidth
						>
					{logOperation.isModalOpen ? (
						<TemplateRenderer
							setIsDragRef={setIsDragRef}
							openSidebarModal={(data) => {
								setSidebarObj(data);
								setIsActive(true);
							}}
							onClose={function onClose() {
								setSidebarObj(getDefaultSidebarObj());
								props.clearSelectedChecklist();
								resetOperations();
							}}
							discrepancy={discrepancy}
						/>
					) : null}
					</ModalPortal>
			</div>
			
	);
}

function mapStateToProps(store: IStore) {
	const checklistStore = store.app.checklistStore;
	const checklists = checklistSelectors.getChecklists(checklistStore);

	return {
		checklists: checklists,
		paginationOfChecklists: checklistSelectors.getPagination(checklistStore),
		jobToLoadChecklists: checklistSelectors.jobToLoadChecklists(checklistStore),
		jobToFilterChecklists: checklistSelectors.jobToFilterChecklists(checklistStore),
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadChecklistCount(
			vesselId: string
		) {
			dispatch(
				checklistActions.checklistCountLoad(
					vesselId
				)
			);
		},
		loadChecklistUniqueList(
			vesselId: string
		) {
			dispatch(
				checklistActions.loadChecklistUniqueList(
					vesselId
				)
			);
		},
		loadChecklists(
			pageNumber: number,
			showPageNumberInNotification: boolean = false,
			vesselId: string
		) {
			dispatch(
				checklistActions.checklistLoad({
					pageNumber,
					showPageNumberInNotification,
					vesselId
				})
			);
		},
		loadChecklist(checklist: ITabChecklist, vesselId: string) {
			dispatch(checklistActions.singleChecklistLoad(checklist, vesselId));
		},

		clearSelectedChecklist() {
			dispatch(checklistActions.clearSelectedChecklist());
		},
		goToNewPage(vesselId: string,pageNumber: number) {
			dispatch(
				historyActions.push(
					"#Checklist",
					rootRoutes.children.app.children.vessel.children.onboard.children.checklist.url(
						vesselId,pageNumber
					)
				)
			);
		},
		resetPageNumber() {
			dispatch(checklistActions.paginationCurrentPageSet(1));
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Checklist);