import { action, ActionType, createAction, getType } from 'typesafe-actions';
import actionTypes from './action-types';
import {
    IFormFieldsFormat,
    IFormErrorFormat,
    IFormHelpMessagesFormat,
    IForm_FieldsSet__Action,
    IForm_FieldsClear__Action,
    IForm_ErrorsSet__Action,
    IForm_ErrorsReset__Action,
    IForm_WarningsSet__Action,
    IForm_WarningsReset__Action,
    IForm_HelpmessagesSet__Action,
    IForm_HelpmessagesReset__Action,
    IForm_Reset__Action,
    IForm_Submit__Action,
    IFormErrorFormatTemp,
    IForm_modeSet__Action,
} from './types';

// const {
// 	FORM_FIELDS_SET,
// 	FORM_FIELDS_CLEAR,
// 	FORM_ERRORS_SET,
// 	FORM_ERRORS_RESET,
// 	FORM_WARNINGS_SET,
// 	FORM_WARNINGS_RESET,
// 	FORM_HELPMESSAGES_SET,
// 	FORM_HELPMESSAGES_RESET,
// 	FORM_RESET,
// 	FORM_SUBMIT,
// 	FORM_MODE_SET,
// 	FORM_FIELDS_EDIT, // TODO: Add this method in free time
// } = actionTypes;

const FORM_SUBMIT = 'form/SUBMIT'; // Command and Event action - Form Submitted
const FORM_FIELDS_SET = 'form/fields/SET'; // Document and Event action - Set Complete form Object/Field
const FORM_FIELDS_EDIT = 'form/fields/EDIT'; // Document and Event action - Edit only given fields of form.
const FORM_FIELDS_CLEAR = 'form/fields/CLEAR'; // Document and Event action - Clear only given fields of form.
const FORM_ERRORS_SET = 'form/errors/SET'; // Document and Event action - Set Errors for given fields in form.
const FORM_ERRORS_RESET = 'form/errors/RESET'; // Document and Event action - Reset Errors for given fields of form.
const FORM_WARNINGS_SET = 'form/warnings/SET'; // Document and Event action - Set Warnings for given fields in form.
const FORM_WARNINGS_RESET = 'form/warnings/RESET'; // Document and Event action - Reset Warnings for given fields of form.
const FORM_HELPMESSAGES_SET = 'form/help-messages/SET'; // Document and Event action - Set HelperMessages for all the fields in Form.
const FORM_HELPMESSAGES_RESET = 'form/help-messages/RESET'; // Document and Event action - Reset HelperMessage for all the fields in form.
const FORM_RESET = 'form/RESET'; // Document and Event action - Reset complete form including [Fields, Errors, Warning, HelperMessages].
const FORM_MODE_SET = 'form/mode/SET'; // Document and Event action - Set the mode which tells if form being is used to create or edit an entity.

const formInvokerActionCreator = (invoker: string) => ({
    FORM_FIELDS_SET: `${invoker} [${FORM_FIELDS_SET}]`,
    FORM_FIELDS_CLEAR: `${invoker} [${FORM_FIELDS_CLEAR}]`,
    FORM_ERRORS_SET: `${invoker} [${FORM_ERRORS_SET}]`,
    FORM_ERRORS_RESET: `${invoker} [${FORM_ERRORS_RESET}]`,
    FORM_WARNINGS_SET: `${invoker} [${FORM_WARNINGS_SET}]`,
    FORM_WARNINGS_RESET: `${invoker} [${FORM_WARNINGS_RESET}]`,
    FORM_HELPMESSAGES_SET: `${invoker} [${FORM_HELPMESSAGES_SET}]`,
    FORM_HELPMESSAGES_RESET: `${invoker} [${FORM_HELPMESSAGES_RESET}]`,
    FORM_RESET: `${invoker} [${FORM_RESET}]`,
    FORM_SUBMIT: `${invoker} [${FORM_SUBMIT}]`,
    FORM_MODE_SET: `${invoker} [${FORM_MODE_SET}]`,
});

const formActions = <
    IFormFields extends IFormFieldsFormat,
    IFieldErrors extends IFormErrorFormat = IFormErrorFormatTemp<IFormFields>,
    IFormHelpMessages extends IFormHelpMessagesFormat = any,
    IFormWarnings extends IFormErrorFormat = any
>(
    formName: string,
    feature: string
) => ({
    formFieldsSet: createAction(FORM_FIELDS_SET, (fields: IFormFields) => ({
        fields,
        formName,
        feature,
    }))(),
    formFieldsEdit: createAction(
        FORM_FIELDS_EDIT,
        (fields: Partial<IFormFields>) => ({
            fields,
            formName,
            feature,
        })
    )(),
    formFieldsClear: createAction(FORM_FIELDS_CLEAR, (fieldKeys: string[]) => ({
        fieldKeys,
        formName,
        feature,
    }))(),
    formErrorsSet: createAction(
        FORM_ERRORS_SET,
        (errors: Partial<IFieldErrors>) => ({
            errors,
            formName,
            feature,
        })
    )(),
    formErrorsReset: createAction(FORM_ERRORS_RESET, (fieldKeys: string[]) => ({
        fieldKeys,
        formName,
        feature,
    }))(),
    formWarningsSet: createAction(
        FORM_WARNINGS_SET,
        (warnings: Partial<IFormWarnings>) => ({
            warnings,
            formName,
            feature,
        })
    )(),
    formWarningsReset: createAction(
        FORM_WARNINGS_RESET,
        (fieldKeys: string[]) => ({
            fieldKeys,
            formName,
            feature,
        })
    )(),
    formHelpMessagesSet: createAction(
        FORM_HELPMESSAGES_SET,
        (helpMessages: IFormHelpMessages) => ({
            helpMessages,
            formName,
            feature,
        })
    )(),
    formHelpMessagesReset: createAction(
        FORM_HELPMESSAGES_RESET,
        (fieldKeys: string[]) => ({
            fieldKeys,
            formName,
            feature,
        })
    )(),
    formReset: createAction(FORM_RESET, (fieldKeys: string[]) => ({
        fieldKeys,
        formName,
        feature,
    }))(),
    formSubmit: createAction(FORM_SUBMIT, (meta?: any) => ({
        formName,
        feature,
        meta,
    }))(),
    formModeSet: createAction(
        FORM_MODE_SET,
        (mode: 'CREATE' | 'EDIT' | 'NONE') => ({
            mode,
            formName,
            feature,
        })
    )(),
});

const noFormActions = formActions('DUMMY-FORM', 'DUMMY-FEATURE');
export type IFormActions = ActionType<typeof noFormActions>;

export default formActions;
