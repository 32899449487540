import moment from "moment";
import { Middleware } from "redux";
import { marpolActions, marpolSelectors } from ".";
import { commonUtils } from "../../_common";
import { apiActions } from "../../_core/api";
import { jobActions } from "../../_core/job";
import {
	EORB1RecordCode,
	EORB1RecordState,
	TORB1BackendRecord,
	TORB1ProcessedTank,
} from "../orb1/@types";
import {
	EMARPOLDocuments,
	EMARPOLModelName,
	EMARPOLTankCategory,
	EMARPOLTankPosition,
	EMARPOLTankType,
	TMARPOLOperation,
} from "./@types";
import { EMARPOLActions, EMARPOLConstants, TMARPOLActions } from "./actions";

const marpolMiddleware: Middleware =
	({ dispatch, getState }) =>
	(next) =>
	(action: TMARPOLActions) => {
		next(action);
		switch (action.type) {
			case EMARPOLActions.MARPOL_DOCS_LOAD: {
				next(action);
				const job = jobActions(EMARPOLConstants.FEATURE, action.type);
				const marpolFilters = marpolSelectors.getFilters(
					marpolSelectors._getStore(getState())
				);

				const weekStart = moment(marpolFilters.weekStart)
					.set({ hour: moment().get("hour"), minute: moment().get("minute") })
					.toISOString();
				const weekEnd = moment(marpolFilters.weekEnd)
					.set({ hour: moment().get("hour"), minute: moment().get("minute") })
					.toISOString();

				dispatch(
					apiActions.apiRequest<{ logs: { docs: TORB1BackendRecord[] } }>({
						method: "GET",
						feature: action.type,
						url: "api/marpol/all",
						queryParams: {
							vesselId: marpolFilters.vesselId,
							marpolType: marpolFilters.documentType,
							weekStart,
							weekEnd,
							modelName: EMARPOLModelName.MARPOL,
							state: {
								$ne: "DRAFT",
							},
						},
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								const orb1Records = data.logs.docs.filter(
									(doc) => doc.marpolType === marpolFilters.documentType
								);
								dispatch(
									marpolActions.document.marpolDocsSet(
										commonUtils.arrayToObject(orb1Records)
									)
								);
								dispatch(job.success({}));
							},
							onError() {
								dispatch(job.error({}));
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
		}
	};
export default marpolMiddleware;

const fakeDocuments: any[] = [];
