import vesselOnboardReducers from "./reducers";
import crewMiddleware from "./middlewares/crew-middleware";
import deviceMiddleware from "./middlewares/device-middleware";
// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now
import vesselFormMiddleware from "./middlewares/vessel-middleware";
import tanksMiddleware from "./middlewares/tanks-middleware";
import dashboardMiddleware from "./middlewares/dashboard-middleware";

export { default as vesselOnboardActions } from "./actions";
export { default as vesselOnboardActionTypes } from "./action-types";
export { default as vesselOnboardSelectors } from "./selectors";
export { default as vesselOnboardUtils } from "./utils";
export { default as vesselOnboardCrewMiddleware } from "./middlewares/crew-middleware";
export { default as vesseLOnboardDeviceMiddleware } from "./middlewares/device-middleware";
// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now
export { default as vesselOnboardFormMiddleware } from "./middlewares/vessel-middleware";
export { default as vesselOnboardDashboardMiddleware } from "./middlewares/dashboard-middleware";

const vesselOnboardMiddlewares = [
	crewMiddleware,
	deviceMiddleware,
	// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now
	vesselFormMiddleware,
	tanksMiddleware,
	dashboardMiddleware,
];
export { vesselOnboardMiddlewares };
// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now
export default vesselOnboardReducers;
