import classNames from "classnames";
import ModalPortal from "components/app/_common/ModalPortal";
import React, { useEffect, useState } from "react";
import { DateRangePicker, RangeKeyDict, Range } from "react-date-range";
import {
	defaultStaticRanges,
	getFormattedDate,
} from "redux/app/feedback/utils";

type OwnProps = {
	handleOpen: () => void;
	onClose: () => void;
	isOpen: boolean;
	handleOnChange: (rangesByKey: RangeKeyDict) => void;
	dateRange: Range[];
	overlayClickClose: boolean;
	handleOnClose: (range: Range, selectedLabel?: string) => void; // it doesn't update state, rather is to be used for api call
	disabled: boolean;
};
type DateRangePickerVerboseProps = OwnProps;
function DateRangePickerVerbose(props: DateRangePickerVerboseProps) {
	const {
		dateRange,
		handleOnChange,
		handleOpen,
		isOpen,
		onClose,
		overlayClickClose,
		handleOnClose,
		disabled,
	} = props;
	const [focusRange, setFocusRange] = useState<any>([0, 0]);
	const formattedDate = getFormattedDate(dateRange[0]);

	// to ensure the focus comes back to start date on closing or opening of modal.
	useEffect(() => {
		setFocusRange([0, 0]);
	}, [isOpen]);

	return (
		<>
			<div className="relative">
				<div
					onClick={() => !disabled && handleOpen()}
					className={classNames(
						"  p-1.5 py-2 shadow mt-2.5 rounded-md text-sm text-gray-700 flex items-center justify-between min-w-48",
						{
							"cursor-pointer": !disabled,
							"cursor-not-allowed": disabled,
						}
					)}
				>
					<div className="flex items-center">
						<span className="bp3-icon bp3-icon-calendar text-gray-700 mr-2 " />
						<span className="mr-6 font-medium">{formattedDate}</span>
					</div>
					<span className="bp3-icon bp3-icon-chevron-down text-gray-700 font-medium" />
				</div>

				<ModalPortal
					isActive={isOpen}
					onClose={() => overlayClickClose && onClose()}
				>
					<div className="flex justify-center rounded">
						<DateRangePicker
							dateDisplayFormat="dd-MM-yyyy"
							className="border shadow-xl rounded feedback"
							staticRanges={defaultStaticRanges}
							renderStaticRangeLabel={(range) => (
								<div
									className="w-full py-2.5 px-5"
									onClick={() => {
										onClose();
										handleOnClose(range.range(), range.label);
									}}
								>
									{range.label}
								</div>
							)}
							startDatePlaceholder="From date"
							endDatePlaceholder="To date"
							inputRanges={[]}
							onChange={(rangesByKey) => {
								handleOnChange(rangesByKey);
								// INFO: Below is the case when user selects the left side predefined options
								if (focusRange.reduce((s: number, c: number) => s + c, 0)) {
									const { selection } = rangesByKey;
									handleOnClose(selection);
									onClose();
								}
							}}
							onRangeFocusChange={(newFocusedRange) => {
								setFocusRange(newFocusedRange);
							}}
							focusedRange={focusRange}
							key={"selection"}
							ranges={dateRange}
						/>
					</div>
				</ModalPortal>
			</div>
		</>
	);
}

export default DateRangePickerVerbose;
