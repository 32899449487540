import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import {
	EORB1RecordCode,
	TORB1Record,
} from "../../../../../redux/app/orb1/@types";
import { TORB1GroupI4 } from "../../../../../redux/app/orb1/groups/group-i";
import CommaSeparator from "../../../../_common/CommaSeparator";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupI4PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupI4Preview(props: ORB1GroupI4PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI4;
	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						I
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">-</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.itemI4.collectedQuantity}M³ collected in{" "}
							{orb1Record.itemI4.selectedTank.tankName}. Quantity retained{" "}
							{(
								marpolUtils.getQuantityForCalculation(
									orb1Record.itemI4.selectedTank
								) + orb1Record.itemI4.collectedQuantity
							).toFixed(2)}
							M³.
							<br />
							SOURCE: {orb1Record.itemI4.source}
						</h4>
					</div>
				</td>
			</tr>
			{orb1Record.remarks !== "" &&
			orb1Record.recordCode === EORB1RecordCode.ORB1I4 ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{orb1Record.remarks !== "" ? orb1Record.remarks : null}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default ORB1GroupI4Preview;
