import React from "react";
type IconCloseType = {
	className?: string;
};
const IconClose: React.SFC<IconCloseType> = (props: IconCloseType) => {
	return (
		<svg
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
		>
			<path
				d="M20 8L8 20"
				stroke="#76A9FF"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8 8L20 20"
				stroke="#76A9FF"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default IconClose;
