import React from "react";
type SVGToProps = { className?: string };
const SVGTo: React.FC<SVGToProps> = (props) => (
	<svg
		width="22"
		height="16"
		viewBox="0 0 22 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1611_18486)">
			<path
				d="M16.3593 8.00478H0.564453M9.59009 1.14307L16.3593 8.00021L9.59009 14.8574M20.8721 1.14307V14.8574"
				stroke="#718096"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1611_18486">
				<rect width="22" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SVGTo;
