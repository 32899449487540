import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupH2 } from "../../../../../redux/app/orb1/groups/group-h";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";
import { commonUtils } from "redux/_common";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupH2PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupH2Preview(props: ORB1GroupH2PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupH2;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "H", 26.1, renderItem26P1()],
					[null, null, 26.2, renderItem26P2()],
					[null, null, 26.3, renderItem26P3()],
					...renderTankRows(),
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem26P1() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item26P1.place === "AT_SEA" ? (
					<>
						{orb1Record.item26P1?.latitude && orb1Record.item26P1?.latitude}{" "}
						{""}
						{orb1Record.item26P1?.longitude && orb1Record.item26P1?.longitude}
					</>
				) : null}
				{orb1Record.item26P1.place === "IN_PORT" ? (
					<>{orb1Record.item26P1.portName}</>
				) : null}
			</h4>
		);
	}

	function renderItem26P2() {
		return (
			<h4 className="font-medium uppercase">
				STARTED{" "}
				{moment(orb1Record.item26P2.start.date, "YYYY-MM-DD").format(
					"DD-MMM-YYYY"
				)}
				{" AT "}
				{orb1Record.item26P2.start.time} / FINISHED{" "}
				{moment(orb1Record.item26P2.end.date, "YYYY-MM-DD").format(
					"DD-MMM-YYYY"
				)}
				{" AT "}
				{orb1Record.item26P2.end.time}
			</h4>
		);
	}

	function renderItem26P3() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item26P3.tanks
					.reduce((result, currentValue) => {
						result = commonUtils.addUp(result, currentValue.addedQuantityInMT);
						return result;
					}, 0)
					.toFixed(3)}{" "}
				MT of {orb1Record.item26P3.iso},{" "}
				{orb1Record.item26P3.sulphurPercent.toFixed(3)}% SULPHUR BUNKERED IN
				TANK(S)
			</h4>
		);
	}

	function renderTankRows(): ORB1TableColumns[] {
		return orb1Record.item26P3.tanks.map((tank, index) => {
			const content = (
				<h4 className="font-medium uppercase">
					{tank.addedQuantityInMT.toFixed(3)} MT ADDED IN{" "}
					{tank.selectedTank.tankName} NOW CONTAINING{" "}
					{tank.totalRetainedQuantityInMT.toFixed(3)} MT
				</h4>
			);
			return [null, null, null, content];
		});
	}

	return renderMain();
}

export default ORB1GroupH2Preview;
