import React from "react";
import FeedbackView from "components/app/feedback/view"

type ScreenFeedbackViewProps = React.PropsWithChildren<{
	match: {
		params: {
			feedbackId: string;
		};
	};
}>;

function ScreenFeedbackView(props:ScreenFeedbackViewProps) {
	return <FeedbackView feedbackId={props.match.params.feedbackId} />;

}

export default ScreenFeedbackView;