import React, { useEffect} from 'react';
import classNames from 'classnames';
import ModalPortal from 'components/app/_common/ModalPortal';
import MultimediaPreviewer from './MultimediaPreviewer';


type Props = {
    onClose: () => void;
    isModalOpen: boolean;
    goToPrevious: () => void;
    goToNext: () => void;
    min: number;
    max: number;
    currentMediaIndex: number;
    url: string;
    mediaType?: string;
    mediaName?: string;
};

function AttachmentPreviewer(props: Props) {
    const {
        isModalOpen,
        onClose,
        goToPrevious,
        goToNext,
        min,
        max,
        currentMediaIndex,
        url,
        mediaName,
        mediaType,
    } = props;

	
	// Handle keyboard events
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			const key = e.key;
			if (key === "ArrowRight") goToNext();
			else if (key === "ArrowLeft") goToPrevious();
			else if (key === "Escape") isModalOpen && onClose();
		};
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

    return (
        <ModalPortal
            isActive={isModalOpen}
            onClose={() => {
                onClose();
            }}
        >
            <div className="w-full h-full my-12 overflow-y-auto bg-white rounded-lg font-redhat-text">
                <div className="h-[calc(100vh-100px)] w-full">
                    <div className="relative h-[calc(100%-86px)] w-full">
					{mediaName && (
							<div className="text-center my-2">
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={url}
									className="text-lg py-2 text-center flex items-center justify-center no-underline"
								>
									<span className="underline">{mediaName}</span>{" "}
									<span className="bp3-icon bp3-icon-share ml-2" />
								</a>
							</div>
						)}
                        <span
                            className="absolute top-0 right-0 bp3-icon bp3-icon-cross text-3xl text-gray-600 m-1.5 cursor-pointer"
                            onClick={() => {
                                onClose();
                            }}
                        />
                        <div
                            className="absolute left-0 transform -translate-y-1/2 cursor-pointer top-1/2"
                            onClick={() => goToPrevious()}
                        >
                            <span
                                className={classNames(
                                    'bp3-icon bp3-icon-chevron-left text-4xl font-normal',
                                    {
                                        'text-gray-500 cursor-not-allowed':
                                            currentMediaIndex === min,
                                    }
                                )}
                            />
                        </div>
                        <div className="w-[calc(100%-72px)] flex justify-center items-center  m-auto p-2 h-full overflow-y-auto border border-gray-400 rounded-lg">
                            {mediaName && mediaType && (
                                <MultimediaPreviewer
                                    title={mediaName ?? ''}
                                    url={url}
                                    content_type={mediaType ?? ''}
                                    width={'100%'}
                                    height={'100%'}
                                    className="rounded-md"
                                />
                            )}
                        </div>
                        <div
                            className="absolute right-0 transform -translate-y-1/2 cursor-pointer top-1/2"
                            onClick={() => goToNext()}
                        >
                            <span
                                className={classNames(
                                    'bp3-icon bp3-icon-chevron-right text-4xl font-normal',
                                    {
                                        'text-gray-500 cursor-not-allowed':
                                            currentMediaIndex === max - 1,
                                    }
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalPortal>
    );
}

export default AttachmentPreviewer;
