import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupA3 } from "../../../../../redux/app/orb1/groups/group-a";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupA3PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupA3Preview(props: ORB1GroupA3PreviewProps) {
	const orb1RecordA3 = props.orb1Record as TORB1GroupA3;
	return (
		<>
			{orb1RecordA3.item1.foTanks.map((tank, index) => (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
					key={index}
				>
					<td className="p-3 text-sm text-left">
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{index === 0
									? moment(orb1RecordA3.selectedDate, "YYYY-MM-DD").format(
											"DD-MMM-YYYY"
									  )
									: null}
							</span>
						</div>
					</td>
					<td className="p-3 text-sm font-medium text-center text-gray-800">
						{index === 0 ? "A" : null}
					</td>
					<td className="p-3 text-sm font-medium text-right text-gray-800">
						<div className="h-full font-medium">{index === 0 ? "1" : null}</div>
					</td>
					<td className="p-3 text-sm font-normal text-left text-gray-800">
						<h4 className="font-medium uppercase">{tank.tankName}</h4>
					</td>
				</tr>
			))}
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="p-3 text-sm text-left">
					<div className="h-full">
						<span className="block font-medium whitespace-nowrap">
							{moment(orb1RecordA3.selectedDate, "YYYY-MM-DD").format(
								"DD-MMM-YYYY"
							)}
						</span>
					</div>
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800"> </td>
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium">2</div>
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<h4 className="font-medium">CLEANED: YES</h4>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="p-3 text-sm text-left" />
				<td className="p-3 text-sm font-normal text-left text-gray-800" />
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium">3.1</div>
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<h4 className="font-medium">
						START{" "}
						{moment(
							orb1RecordA3.item3P1.startOfCleaning.date,
							"YYYY-MM-DD"
						).format("DD-MMM-YYYY")}{" "}
						{orb1RecordA3.item3P1.startOfCleaning.time} LT AT POSITION
						<br /> LAT: {
							orb1RecordA3.item3P1.startOfCleaning.latitude
						} LON: {orb1RecordA3.item3P1.startOfCleaning.longitude}{" "}
					</h4>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="p-3 text-sm text-left" />
				<td className="p-3 text-sm font-normal text-left text-gray-800" />
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium" />
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<h4 className="font-medium">
						STOP AT{" "}
						{moment(
							orb1RecordA3.item3P1.completionOfCleaning.date,
							"YYYY-MM-DD"
						).format("DD-MMM-YYYY")}{" "}
						{orb1RecordA3.item3P1.completionOfCleaning.time} LT AT POSITION
						<br /> LAT: {
							orb1RecordA3.item3P1.completionOfCleaning.latitude
						}, LON: {orb1RecordA3.item3P1.completionOfCleaning.longitude}{" "}
					</h4>
				</td>
			</tr>
			{orb1RecordA3.item1.foTanks.map((tank, index) => (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
					key={index}
				>
					<td className="p-3 text-sm text-left" />
					<td className="p-3 text-sm font-normal text-left text-gray-800" />
					<td className="p-3 text-sm font-medium text-right text-gray-800">
						<div className="h-full font-medium">
							{index === 0 ? `3.2` : null}
						</div>
					</td>
					<td className="p-3 text-sm font-normal text-left text-gray-800">
						<h4 className="font-medium">
							{tank.tankName.toUpperCase()},{" "}
							{orb1RecordA3.item3P2.cleaningMethod === "CHEMICALS"
								? `${orb1RecordA3.item3P2.cleaningMethod} METHOD / ${orb1RecordA3.item3P2.typeOfChemical}, ${orb1RecordA3.item3P2.quantity}M³`
								: `${orb1RecordA3.item3P2.cleaningMethod} METHOD`}
						</h4>
					</td>
				</tr>
			))}
			{orb1RecordA3.item3P3.isDisposalTankDetailsAvailable === "YES" ? (
				orb1RecordA3.item3P3.disposalTanks.map((tank, index) => (
					<tr
						className={classNames("uppercase", {
							"line-through": props.isStrikedOff,
						})}
						key={index}
					>
						<td className="p-3 text-sm text-left" />
						<td className="p-3 text-sm font-normal text-left text-gray-800" />
						<td className="p-3 text-sm font-medium text-right text-gray-800">
							<div className="h-full font-medium">
								{index === 0 ? `3.3` : null}
							</div>
						</td>
						<td className="p-3 text-sm font-normal text-left text-gray-800">
							<h4 className="font-medium uppercase">
								DISPOSED {tank.transferQuantity.toFixed(2)}M³ TO{" "}
								{tank.selectedTank.tankName}
							</h4>
						</td>
					</tr>
				))
			) : (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="p-3 text-sm text-left" />
					<td className="p-3 text-sm font-normal text-left text-gray-800" />
					<td className="p-3 text-sm font-medium text-right text-gray-800">
						<div className="h-full font-medium">3.3</div>
					</td>
					<td className="p-3 text-sm font-normal text-left text-gray-800">
						<h4 className="font-medium uppercase">N/A</h4>
					</td>
				</tr>
			)}
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="p-3 text-sm text-left" />
				<td className="p-3 text-sm font-normal text-left text-gray-800" />
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium">4.1</div>
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<h4 className="font-medium">
						START BALLASTING: {orb1RecordA3.item4P1.startOfBallasting.latitude},{" "}
						{orb1RecordA3.item4P1.startOfBallasting.longitude} AT{" "}
						{orb1RecordA3.item4P1.startOfBallasting.time} HRS
					</h4>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="p-3 text-sm text-left" />
				<td className="p-3 text-sm font-normal text-left text-gray-800" />
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium" />
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<h4 className="font-medium">
						STOP BALLASTING:{" "}
						{orb1RecordA3.item4P1.completionOfBallasting.latitude},{" "}
						{orb1RecordA3.item4P1.completionOfBallasting.longitude} AT{" "}
						{orb1RecordA3.item4P1.completionOfBallasting.time} HRS
					</h4>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="p-3 text-sm text-left" />
				<td className="p-3 text-sm font-normal text-left text-gray-800" />
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium">4.2</div>
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<h4 className="font-medium">
						BALLASTED: {orb1RecordA3.item4P2.ballastQuantity}
					</h4>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td />
				<td />
				<td className="p-3 text-sm font-medium text-right text-gray-800">
					<div className="h-full font-medium">NOTE</div>
				</td>
				<td className="p-3 text-sm font-normal text-left text-gray-800">
					<div className="h-full font-medium uppercase">
						{orb1RecordA3.remarks}
					</div>
				</td>
			</tr>
		</>
	);
}

export default ORB1GroupA3Preview;
