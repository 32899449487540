import React, { FC } from 'react'
import { INotification } from 'redux/app/feedback/@types';
import Skeleton from "react-loading-skeleton";
import moment from 'moment';
import { Link } from 'react-router-dom';

interface INotificationsWrapperProps {
	notifications: INotification[];
	loading: boolean;
	onShowMore: () => void;
}

const NotificationsWrapper: FC<INotificationsWrapperProps> = (props) => {
	const { notifications, loading } = props;

	return (
		<div
			className='flex flex-col'
		>
			<h3
				className='text-base px-5 py-2.5 font-bold border-0 border-b border-solid border-gray-400'
			>
				Notifications
			</h3>

			<div
				style={{
					maxHeight: '450px'
				}}
				className='flex flex-col overflow-y-scroll notifications-wrapper'
			>
				{
					loading && !notifications.length?
						<div className="flex flex-col gap-y-2 py-3 justify-center items-center h-full">
							<Skeleton width={350} height={40} />
							<Skeleton width={350} height={40} />
							<Skeleton width={350} height={40} />
							<Skeleton width={350} height={40} />
							<Skeleton width={350} height={40} />
							<Skeleton width={350} height={40} />
						</div>
						: notifications.length === 0 ? (
							<div className="flex flex-col justify-center h-full text-center bg-gray-100">
								<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
									No Notifications found..
								</h5>
							</div>
						) : null
				}
				{
					notifications.map((notification) => {
						return (
							<Link
								to={`/app/feedback/view/${notification.feedbackId}`}
								key={notification.feedbackId}
								className='flex flex-col gap-y-2 px-5 py-2.5 border-0 border-b border-solid border-gray-400 hover:text-black hover:bg-gray-200 hover:no-underline'
							>
								<div
									className='flex items-center text-sm'
								>
									{notification.senderName} ({notification.senderDesignation})
								</div>
								<h4
									className='text-base font-medium m-0 p-0 leading-snug'
								>
									{notification.message}
								</h4>
								<p
									className='text-xs m-0 p-0 mt-0.5'
								>
									{moment(notification.createdAt).fromNow()}
								</p>
							</Link>
						)
					})
				}

				{
					notifications?.length > 5?
						loading?
							<div
								className='flex items-center justify-center py-2'
							>
								<div className='w-7 h-7'>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a2" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#000000"></stop><stop offset=".3" stop-color="#000000" stop-opacity=".9"></stop><stop offset=".6" stop-color="#000000" stop-opacity=".6"></stop><stop offset=".8" stop-color="#000000" stop-opacity=".3"></stop><stop offset="1" stop-color="#000000" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a2)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#000000" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
								</div>
							</div>
							: 	<div
									className='flex items-center justify-center py-2'
								>
									<button
										className='flex items-center justify-center text-sm font-medium border-none outline-none underline'
										onClick={() => {
											props.onShowMore();
										}}
									>
										Show More
									</button>
								</div>
							: null
				}
			</div>
		</div>
	)
}

export default NotificationsWrapper