import React from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { authSelectors, authActions } from "../../../redux/app/auth";
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
type ScreenSAMLType = React.PropsWithChildren<{
	match?: {
		path: string;
		url: string;
		params?: {
			accessToken: string;
		};
	};
}>;
const ScreenSAML: React.FC<ScreenSAMLType> = (
	props: ScreenSAMLType
) => {
	const dispatch = useDispatch();
	const locationPath = useLocation().search;
    const searchParams = new URLSearchParams(locationPath);
    const code = searchParams.get('code');
	useEffect(()=>{
		if (code) {
			dispatch(authActions.SAMLLogin(code));
		}
	}, []);
	return (<>
		Fetching details
	</>);
};

export default ScreenSAML;
