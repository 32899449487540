import React from "react";
import {
	EORB1RecordCode,
	EORB1RecordState,
	TORB1BackendRecord,
} from "redux/app/orb1/@types";
import ORB1GroupA1Preview from "./previews/ORB1A1Preview";
import ORB1GroupA2Preview from "./previews/ORB1A2Preview";
import ORB1GroupA3Preview from "./previews/ORB1A3Preview";
import ORB1GroupB1Preview from "./previews/ORB1B1Preview";
import ORB1GroupB2Preview from "./previews/ORB1B2Preview";
import ORB1GroupC1Preview from "./previews/ORB1C1Preview";
import ORB1GroupC2Preview from "./previews/ORB1C2Preview";
import ORB1GroupC3Preview from "./previews/ORB1C3Preview";
import ORB1GroupC4Preview from "./previews/ORB1C4Preview";
import ORB1GroupC5Preview from "./previews/ORB1C5Preview";
import ORB1GroupC6Preview from "./previews/ORB1C6Preview";
import ORB1GroupD1Preview from "./previews/ORB1D1Preview";
import ORB1GroupD2Preview from "./previews/ORB1D2Preview";
import ORB1GroupD3Preview from "./previews/ORB1D3Preview";
import ORB1GroupE1Preview from "./previews/ORB1E1Preview";
import ORB1GroupE2Preview from "./previews/ORB1E2Preview";
import ORB1GroupE3Preview from "./previews/ORB1E3Preview";
import ORB1GroupF1Preview from "./previews/ORB1F1Preview";
import ORB1GroupG1Preview from "./previews/ORB1G1Preview";
import ORB1GroupH1Preview from "./previews/ORB1H1Preview";
import ORB1GroupH2Preview from "./previews/ORB1H2Preview";
import ORB1GroupH3Preview from "./previews/ORB1H3Preview";
import ORB1GroupI1Preview from "./previews/ORB1I1Preview";
import ORB1GroupI2Preview from "./previews/ORB1I2Preview";
import ORB1GroupI3Preview from "./previews/ORB1I3Preview";
import ORB1GroupI4Preview from "./previews/ORB1I4Preview";
import ORB1GroupI5Preview from "./previews/ORB1I5Preview";
import ORB1GroupI6Preview from "./previews/ORB1I6Preview";
import ORB1TableRows from "./ORB1TableRows";
import ORB1D4Preview from "./previews/ORB1D4Preview";
import ORB1D5Preview from "./previews/ORB1D5Preview";
import ORB1D6Preview from "./previews/ORB1D6Preview";
import ORB1D7Preview from "./previews/ORB1D7Preview";
import ORB1D8Preview from "./previews/ORB1D8Preview";
import ORB1D9Preview from "./previews/ORB1D9Preview";
import ORB1D11Preview from "./previews/ORB1D11Preview";
import moment from "moment";
import ORB1I7Preview from "./previews/ORB1I7Preview";

type OwnProps = {
	orbRecord: TORB1BackendRecord;
};
type ORB1PreviewerProps = OwnProps;
function ORB1Previewer(props: ORB1PreviewerProps) {
	const { orbRecord } = props;
	const isStrikedOff = orbRecord.state === EORB1RecordState.STRIKED_OFF;
	const isEditOfApprovedRecord = !!orbRecord.dateOfApprovedRecordEdit;

	function renderMain() {
		if (isEditOfApprovedRecord) return renderEditedApprovedRecord();
		return renderRecord(orbRecord, isStrikedOff);
	}

	function renderEditedApprovedRecord() {
		return (
			<>
				{renderEditCorrectionLine()}
				{renderRecord(orbRecord, isStrikedOff)}
				{renderStruckOffVersionOfEdit()}
			</>
		);
	}
	function renderEditCorrectionLine() {
		if (!orbRecord.dateOfApprovedRecordEdit) return null;
		const formattedEditDate = moment(
			orbRecord.dateOfApprovedRecordEdit,
			"YYYY-MM-DD"
		).format("DD-MMM-YYYY");

		return (
			<ORB1TableRows
				record={orbRecord}
				tableRows={[
					[
						formattedEditDate,
						"I",
						"-",
						"ENTRY PERTAINING TO AN EARLIER INCORRECT OPERATIONAL ENTRY",
					],
				]}
			/>
		);
	}
	function renderStruckOffVersionOfEdit() {
		if (!orbRecord.editVersions) return null;
		const oldVersionOfRecord = {
			...orbRecord.editVersions[0],
			state: EORB1RecordState.STRIKED_OFF,
		};
		return <ORB1Previewer orbRecord={oldVersionOfRecord} />;
	}

	function renderRecord(
		recordToRender: TORB1BackendRecord,
		isStrikedOff: boolean = false
	) {
		switch (recordToRender.recordCode) {
			case EORB1RecordCode.ORB1A1:
				return (
					<ORB1GroupA1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1A2:
				return (
					<ORB1GroupA2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1A3:
				return (
					<ORB1GroupA3Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1B1:
				return (
					<ORB1GroupB1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1B2:
				return (
					<ORB1GroupB2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1C1:
				return (
					<ORB1GroupC1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1C2:
				return (
					<ORB1GroupC2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1C3:
				return (
					<ORB1GroupC3Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1C4:
				return (
					<ORB1GroupC4Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1C5:
				return (
					<ORB1GroupC5Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1C6:
				return (
					<ORB1GroupC6Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D1:
				return (
					<ORB1GroupD1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D2:
				return (
					<ORB1GroupD2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D3:
				return (
					<ORB1GroupD3Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D4:
				return (
					<ORB1D4Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D5:
				return (
					<ORB1D5Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D6:
				return (
					<ORB1D6Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D7:
				return (
					<ORB1D7Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D8:
				return (
					<ORB1D8Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D9:
				return (
					<ORB1D9Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D10:
				return (
					<ORB1D9Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1D11:
				return (
					<ORB1D11Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1E1:
				return (
					<ORB1GroupE1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1E2:
				return (
					<ORB1GroupE2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1E3:
				return (
					<ORB1GroupE3Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1F1:
				return (
					<ORB1GroupF1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1G1:
				return (
					<ORB1GroupG1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1H1:
				return (
					<ORB1GroupH1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1H2:
				return (
					<ORB1GroupH2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1H3:
				return (
					<ORB1GroupH3Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I1:
				return (
					<ORB1GroupI1Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I2:
				return (
					<ORB1GroupI2Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I3:
				return (
					<ORB1GroupI3Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I4:
				return (
					<ORB1GroupI4Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I5:
				return (
					<ORB1GroupI5Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I6:
				return (
					<ORB1GroupI6Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB1RecordCode.ORB1I7:
				return (
					<ORB1I7Preview
						orb1Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			default:
				return null;
		}
	}

	return renderMain();
}

export default ORB1Previewer;
