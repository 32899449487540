import Button from "@set-product/core/button/Button";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import ListRow from "./_elements/ListRow";
import ModalPortal from "components/app/_common/ModalPortal";
import userActions from "redux/app/user/actions";
import userSelectors from "redux/app/user/selectors";
import UserCreate from "./_elements/New";
import UserEdit from "./_elements/Edit";
import InfiniteProgress from "components/_common/InfiniteProgress";
import NumberedPagination from "components/_common/NumberedPagination";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";

type OwnProps = { pageNumber: string };
type UserListProps = PropsFromRedux & OwnProps;
function UserList(props: UserListProps) {
	const {
		users,
		jobs,
		fields,
		isAdmin,
		paginationOfUsers,
		pageNumber,
		jobToLoadUsers,
	} = props;

	useEffect(() => {
		props.loadTotalUsersCount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		props.loadUsers(pageNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	const [userOperation, setUserOperation] = useState<{
		userId: string | null;
		operation: "ADD_USER" | "UPDATE_USER";
		isModalOpen: boolean;
	}>({ operation: "ADD_USER", isModalOpen: false, userId: null });

	function triggerAddUserOperation() {
		setUserOperation({
			...userOperation,
			userId: null,
			operation: "ADD_USER",
			isModalOpen: true,
		});
	}

	function triggerUpdateUserOperation(userId: string) {
		setUserOperation({
			...userOperation,
			userId,
			operation: "UPDATE_USER",
			isModalOpen: true,
		});
	}

	function resetOperations() {
		setUserOperation({
			...userOperation,
			isModalOpen: false,
			operation: "ADD_USER",
		});
	}

	function handleClose() {
		resetOperations();
		props.resetFormErrorsOnCloseOfModal(fields);
		props.setSelectedUserId("");
	}

	return (
		<>
			<div className=" bg-white h-[calc(100vh-98px)]   m-2 rounded-md">
				<div className="flex items-center justify-between p-4">
					<h2 className="py-4 text-2xl font-normal font-redhat-display">
						Users
					</h2>
					{isAdmin && (
						<Button
							text="Add User"
							onClick={() => {
								// props.toggleIsAddMemberModalOpen();
								triggerAddUserOperation();
							}}
							icon={
								<span className="text-sm bp3-icon bp3-icon-plus" />
							}
							disabled={false}
							view="filled"
							type="submit"
							color="primary"
							className="px-4 mr-2 text-base font-normal bg-blue-700 shadow-none cursor-pointer hover:bg-blue-800 focus:bg-blue-900"
						/>
					)}
				</div>
				<div className=" md:w-full mb-3 border-t  pt-3 overflow-auto h-[calc(100vh-200px)]  mr-4 md:mr-0">
					<div className="w-full p-4 ">
						<InfiniteProgress
							isLoading={
								jobs.userCreate.isLoading ||
								jobs.userUpdate.isLoading ||
								jobs.userDelete.isLoading ||
								jobs.userLoad.isLoading
							}
							isSpacedOut={false}
						/>
						{users?.ids?.length > 0 ? (
							<table className="w-full h-full overflow-auto border border-collapse border-gray-400 rounded-md table-auto font-redhat-text">
								<thead className="sticky -top-4 ">
									<tr>
										<th className="px-3 py-3 text-sm font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border border-b border-gray-300">
											SI.no
										</th>
										<th className="px-3 py-3 text-sm font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border border-b border-gray-300">
											Name
										</th>
										<th className="px-3 py-3 text-sm font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border border-b border-gray-300">
											Email
										</th>
										<th className="px-3 py-3 text-sm font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border border-b border-gray-300">
											Role
										</th>
										{isAdmin && (
											<th className="px-3 py-3 text-sm font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border border-b border-gray-300">
												Action
											</th>
										)}
									</tr>
								</thead>
								<tbody>
									{users.ids.map((userId, index) => {
										const user = users.byIds[userId];
										return (
											<ListRow
												onEditClick={(userId) => {
													props.setSelectedUserId(
														userId
													);
													triggerUpdateUserOperation(
														userId
													);
												}}
												indexNumber={
													(paginationOfUsers.currentPage -
														1) *
														paginationOfUsers.itemsPerPage +
													(index + 1)
												}
												key={user.id}
												user={user}
											/>
										);
									})}
								</tbody>
							</table>
						) : null}
						<NumberedPagination
							{...{
								...paginationOfUsers,
								totalItems: paginationOfUsers.totalItems || 0,
							}}
							itemIds={[]}
							onPageChange={function onPageChange(
								pageNumberFromPagination
							) {
								props.goToNewPage(pageNumberFromPagination);
							}}
							isPageLoading={jobToLoadUsers.isLoading}
						/>
					</div>
				</div>
			</div>

			{userOperation.isModalOpen ? (
				<ModalPortal
					isActive={userOperation.isModalOpen}
					onClose={() => {
						handleClose();
					}}
					width={477}
					isOverlayClickCloseable={false}
				>
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							{userOperation.operation === "ADD_USER" ? (
								<UserCreate
									onClose={() => {
										handleClose();
									}}
								/>
							) : null}
							{userOperation.operation === "UPDATE_USER" ? (
								<UserEdit
									onClose={() => {
										handleClose();
									}}
									userId={userOperation.userId ?? ""}
								/>
							) : null}
						</div>
					</div>
				</ModalPortal>
			) : null}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const authStore = store.app.auth;
	const isAdmin = authStore.user?.roleCategory === "admin";
	const userStore = userSelectors._getStore(store);
	const {
		jobs,
		form: { fields },
	} = userSelectors.getUserFormData(store.app.user);
	const paginationOfUsers = userSelectors.getPagination(userStore);
	const jobToLoadUsers = userSelectors.getJobToLoadUsers(userStore);

	return {
		users: userSelectors.getUsersList(userStore),
		jobs,
		fields: Object.keys(fields),
		isAdmin,
		paginationOfUsers,
		jobToLoadUsers,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadUsers(pageNumber: string) {
			dispatch(userActions.commands.userListLoad(+pageNumber));
		},
		setSelectedUserId(userId: string) {
			dispatch(userActions.document.selectedUserIdSet(userId));
		},
		resetFormErrorsOnCloseOfModal(fields: string[]) {
			dispatch(userActions._forms.user.formErrorsReset(["GLOBAL", ...fields]));
			dispatch(
				userActions._forms.user.formErrorsSet({
					GLOBAL: {
						isValid: true,
						message: "",
					},
				})
			);
		},
		goToNewPage(pageNumber: number) {
			dispatch(
				historyActions.push(
					"#UserList",
					rootRoutes.children.app.children.users.children.list.url(pageNumber)
				)
			);
		},
		loadTotalUsersCount() {
			dispatch(userActions.commands.userCount());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(UserList);
