import React, { useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import Button from "../../../../../@set-product/core/button";
import NewCrew from "./_elements/New";
import CrewEdit from "./_elements/Edit";
import { IStore } from "../../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../../redux/app/vessel/onboard";
import InfiniteProgress from "../../../../_common/InfiniteProgress";
import ReactJson from "react-json-view";
import { Icon, Popover } from "@blueprintjs/core";
import { commonUtils } from "../../../../../redux/_common";
import CrewImport from "./_elements/Import";
import ConfirmBox from "../../../../_common/ConfirmBox";
import Modal from "../../../_common/Modal";
import { crewFormDefaults } from '../../../../../redux/app/vessel/onboard/reducers.state';

type VesselOnboardCrewProps = PropsFromRedux & {
	vesselId: string;
};

function VesselOnboardCrew(props: VesselOnboardCrewProps) {
	const { crews, jobs, onboardStatus, vesselId } = props;
	const { deleteCrewJob, listLoadJob } = jobs;
	useEffect(
		function onMount() {
			props.crewListReset();
			props.loadCrews(vesselId);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const [crewOperation, setCrewOperation] = useState<{
		crewId: string | null;
		vesselId: string;
		operation: "ADD_CREW" | "UPDATE_CREW" | "IMPORT_CREW";
		isModalOpen: boolean;
	}>({
		vesselId: props.vesselId,
		operation: "ADD_CREW",
		crewId: null,
		isModalOpen: false,
	});

	function triggerAddCrewOperation() {
		setCrewOperation({
			...crewOperation,
			crewId: null,
			operation: "ADD_CREW",
			isModalOpen: true,
		});
	}

	function triggerUpdateCrewOperation(crewId: string) {
		setCrewOperation({
			...crewOperation,
			isModalOpen: true,
			operation: "UPDATE_CREW",
			crewId,
		});
	}

	function triggerImportCrewOperation() {
		setCrewOperation({
			...crewOperation,
			isModalOpen: true,
			operation: "IMPORT_CREW",
			crewId: null,
		});
	}

	function resetOperations() {
		props.resetForm()
		setCrewOperation({
			...crewOperation,
			isModalOpen: false,
			operation: "ADD_CREW",
			crewId: null,
		});
	}

	return (
		<div className="">
			<div className="flex flex-row items-end justify-between my-6">
				<div>
					<h3 className="text-xl font-medium text-gray-700 font-redhat-display">
						Crew onboarding
					</h3>
					{listLoadJob.isLoading ? (
						<h6 className="text-sm text-gray-600">
							Loading crew...
						</h6>
					) : (
						<h6 className="text-sm text-gray-600">
							{crews.ids.length} crew present
						</h6>
					)}
				</div>
				<div className="flex flex-row items-end justify-end h-full -mx-1">
					<Button
						view="filled"
						color="primary"
						rightIcon="plus"
						onClick={function onAddNewCrewClick() {
							triggerAddCrewOperation();
						}}
						className="mx-1 font-medium text-white bg-blue-600 shadow-none hover:bg-blue-800"
					>
						Add new Crew
					</Button>
					<Button
						view="filled"
						color="primary"
						rightIcon="import"
						onClick={function onImportCrews() {
							triggerImportCrewOperation();
						}}
						className="hidden mx-1 font-medium text-white bg-blue-600 shadow-none hover:bg-blue-800"
					>
						Import Crew
					</Button>
				</div>
			</div>
			<div
				className={classNames({
					"h-auto opacity-100 transition-all duration-300 animate-pulse":
						listLoadJob.isLoading && crews.ids.length === 0,
					"h-0 opacity-0 transition-all duration-300 animate-pulse":
						!listLoadJob.isLoading,
				})}
			>
				<InfiniteProgress
					isLoading={listLoadJob.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive bordered",
						{
							"animate-pulse": listLoadJob.isLoading,
							hidden: !listLoadJob.isLoading,
						}
					)}
				>
					<thead>
						<tr>
							<th className="text-sm text-gray-500">Avatar</th>
							<th className="text-sm text-gray-500">Rank</th>
							<th className="text-sm text-gray-500">Full name</th>
							<th className="text-sm text-gray-500">Crew ID</th>
							<th className="text-sm text-gray-500">Onboard</th>
							<th className="text-sm text-gray-500">Offboard</th>
							{/* <th className="text-sm text-gray-500">
								<span>Actions</span>
							</th> */}
						</tr>
					</thead>
				</table>
				{listLoadJob.isLoading ? (
					<div className="flex-col justify-center h-64 py-24 bg-gray-100 ">
						<h4 className="-my-3 text-2xl text-center font-redhat-display">
							Loading please wait..
						</h4>
					</div>
				) : null}
			</div>
			<div className="mx-0 overflow-x-auto">
				{!listLoadJob.isLoading && crews.ids.length > 0 ? (
					<table
						className={classNames(
							"w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive bordered"
						)}
					>
						<thead>
							<tr>
								<th className="text-sm text-gray-500" />
								<th className="text-sm text-gray-500">Rank</th>
								<th className="text-sm text-gray-500">
									Full name
								</th>
								<th className="text-sm text-gray-500">
									Crew ID
								</th>
								{/* <th className="text-sm text-gray-500">
									Onboard
								</th>
								<th className="text-sm text-gray-500">
									Offboard
								</th> */}
								{/* <th className="text-sm text-gray-500">
									<span>Actions</span>
								</th> */}
							</tr>
						</thead>
						<tbody className={classNames({})}>
							{crews.ids.map(function listCrews(id) {
								const crew = crews.byIds[id];
								return (
									<tr key={id} className="align-baseline">
										<td className="content-center text-xs text-center text-gray-400 center">
											{/* {crew.image ? (
												<img
													src={crew.image}
													className="w-8 mx-auto rounded-sm"
												/>
											) : ( */}
											<Icon icon="person" />
											{/* )} */}
										</td>
										<td className="font-medium">
											{crew.designation}
										</td>
										<td>{crew.name}</td>
										<td className="font-mono">
											{crew.staffId}
										</td>
										{/* <td>
											<div className="flex flex-col pt-1 mt-2">
												<div className="text-xs font-medium">
													{moment(
														crew.fromDate
													).format("L")}
												</div>
												<div className="text-xs text-gray-500">
													{moment().to(crew.fromDate)}
												</div>
											</div>
										</td>
										<td>
											<div className="flex flex-col pt-1 mt-2">
												<div className="text-xs font-medium">
													{moment(crew.toDate).format(
														"L"
													)}
												</div>
												<div className="text-xs text-gray-500">
													{moment().to(crew.toDate)}
												</div>
											</div>
										</td> */}
										{/* <td className="-mx-1">
											<Button
												text="Edit"
												dense={true}
												view="flat"
												color="primary"
												className="p-5 py-4 mx-1 font-medium hover:shadow-none hover:bg-blue-500 hover:text-white"
												onClick={function onEditCrewClick() {
													triggerUpdateCrewOperation(
														crew.id
													);
												}}
											/>
											<Popover
												className="rounded-lg"
												content={
													<ConfirmBox
														description={`Do you really want to delete crew with name ${crew.name}?`}
														title="Confirm Deletion"
														primaryText="Delete"
														secondaryText="Cancel"
														state="ACTIVE"
														onPrimaryAction={function onDeleteCrewClick() {
															props.onCrewDeleteClick(
																crew.id
															);
														}}
													/>
												}
												target={
													<Button
														dense={true}
														view="flat"
														color="primary"
														rightIcon="cross"
														className="p-5 py-4 mx-1 font-medium text-gray-500 hover:shadow-none hover:bg-orange-500 hover:text-white"
														// tslint:disable-next-line: no-empty
														onClick={function onDeleteCrewClick() {}}
													/>
												}
											/>
										</td> */}
									</tr>
								);
							})}
						</tbody>
					</table>
				) : !listLoadJob.isLoading ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-xl font-semibold text-gray-500 font-redhat-display">
							No Crews present
						</h5>
						<p className="p-3 text-sm leading-5 text-gray-700">
							You can onboard crew by clicking on <br />
							<strong className="px-2 font-medium">
								Add new Crew
							</strong>{" "}
							or{" "}
							<strong className="px-2 font-medium">
								Import crew
							</strong>
						</p>
					</div>
				) : null}
			</div>
			<Modal
				isActive={!!crewOperation.isModalOpen}
				onClose={() => resetOperations()}
			>
				{crewOperation.isModalOpen ? (
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							{crewOperation.operation === "ADD_CREW" ? (
								<NewCrew
									onClose={function onCreateVesselModalClose() {
										resetOperations();
									}}
									vesselId={crewOperation.vesselId}
								/>
							) : null}
							{crewOperation.operation === "UPDATE_CREW" ? (
								crewOperation.crewId ? (
									<CrewEdit
										crewId={crewOperation.crewId}
										onClose={function onUpdateVesselModalClose() {
											resetOperations();
										}}
									/>
								) : (
									<span>No Crew Id Provided to edit</span>
								)
							) : null}
							{crewOperation.operation === "IMPORT_CREW" ? (
								<CrewImport
									vesselId={crewOperation.vesselId}
									onClose={function onUpdateVesselModalClose() {
										resetOperations();
									}}
									onCancel={function onUpdateVesselModalClose() {
										resetOperations();
									}}
								/>
							) : null}
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	return vesselOnboardSelectors.getCrewListData(store.app.vesselStore.onboard);
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadCrews(vesselId: string) {
			dispatch(vesselOnboardActions.command.crewListLoad(vesselId));
		},
		onCrewDeleteClick(crewId: string) {
			dispatch(vesselOnboardActions.command.crewDelete(crewId));
		},
		crewListReset() {
			dispatch(vesselOnboardActions.document.crewListReset());
		},
		resetForm() {
			dispatch(
				vesselOnboardActions._forms.crew.formFieldsSet(crewFormDefaults)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselOnboardCrew);
