import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IChangeLogActivity } from "redux/app/tool/@types";
import { IStore } from "redux/store";

type OwnProps = {
	activity: IChangeLogActivity;
};
type RenderActivityProps = PropsFromRedux & OwnProps;
function RenderActivity(props: RenderActivityProps) {
	const { activity } = props;

	let activityRenderer = <></>;

	switch (activity.item) {
		case "created":
		// {
		// 	activityRenderer = (
		// 		<div className="flex justify-between items-center feedback-activity">
		// 			<div
		// 				title={activity?.change}
		// 				className="m-0 text-gray-700 text-sm  md:w-auto"
		// 			>
		// 				{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
		// 				Created config{" "}
		// 				<span className="font-medium">
		// 					{activity?.change.slice(0, 8)}...
		// 				</span>
		// 			</div>
		// 		</div>
		// 	);
		// 	break;
		// }
		// eslint-disable-next-line no-fallthrough
		case "updated": {
			activityRenderer = (
				<div className="flex justify-between items-center">
					<div className="m-0 text-gray-700 text-sm  md:w-auto">
						{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
						<span className="font-medium">{activity?.version}</span>
						<span className="text-sm"> - {activity?.change}</span>
					</div>
				</div>
			);
			break;
		}

		default:
			activityRenderer = <></>;
	}
	return activityRenderer;
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(RenderActivity);
