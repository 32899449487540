import { Reducer } from "redux";
import reducerState from "./reducers.state";
import constants from "./constants";
import { IHistoryStore } from "./types";
const {
	HISTORY_CLEAR,
	HISTORY_POP,
	HISTORY_PUSH,
	HISTORY_REPLACE,
	HISTORY_RESET,
} = constants;
const historyReducers: Reducer = (
	state = reducerState,
	action
): IHistoryStore => {
	switch (action.type) {
		case HISTORY_CLEAR:
			return { ...state, canUpdateRoute: true, method: "CLEAR", url: "" };
		case HISTORY_POP:
			return { ...state, canUpdateRoute: true, method: "POP", url: "" };
		case HISTORY_PUSH:
			return { ...state, canUpdateRoute: true, method: "PUSH", url: action.url };
		case HISTORY_REPLACE:
			return {
				...state,
				canUpdateRoute: true,
				method: "REPLACE",
				url: action.url,
			};
		case HISTORY_RESET:
			return { ...reducerState, method: "RESET" };
	}
	return state;
};

export default historyReducers;
