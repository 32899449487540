import actionTypes from "./action-types";
const { APP_INITIALIZE, APP_RESET } = actionTypes;
const appCoreActions = {
	/**
	 * Useful to set auth headers, routes etc on application startup
	 */
	initialize: () => ({ type: APP_INITIALIZE }),
	/**
	 * Useful to clear all stored information like authToken post logout
	 */
	reset: () => ({ type: APP_RESET }),
};
export default appCoreActions;
