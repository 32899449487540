import { apiActions } from "../../_core/api";
import { Middleware } from "redux";
import { ILog, ILogActions, IReport, ISealLog, ITabLogListResponseObj, ITabReport } from "./@types";
import { IStore } from "../../store";
import logActions, { logJobActions } from "./actions";
import logAPIs from "./apis";
import logConstants from "./constants";
import { historyActions } from "../../_core/history";
import { rootRoutes } from "../../../routes";
import { commonUtils } from "redux/_common";
import moment from "moment";

const { apiRequest } = apiActions;
const logMiddleware: Middleware<any, IStore, any> = ({
	dispatch,
	getState,
}) => (next) => (action: ILogActions) => {
	switch (action.type) {
		case '@seallogs/count/LOAD': {
			const { vesselId } = action.payload;
			const job = logJobActions.seallogsCountLoad;

			const latestFilters = getState().app.logs._forms.SEAL_LOGS_FILTER.fields;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			// TODO: Add filters
			let filters: any = {
				...latestFilters,
			};

			if (!filters.startDate && !filters.endDate){
				filters = {
					...filters,
					startDate: moment.utc().subtract(30, 'days').toDate(),
					endDate: moment.utc().add(1, 'days').toDate()
				}
				dispatch(logActions._forms.sealLogsFilter.formFieldsEdit({
					...filters
				}));
			}

			filters = {
				...filters,
				startDate: commonUtils.getStartOfDay(
					moment(filters.startDate).format('YYYY-MM-DD')
				),
				endDate: commonUtils.getEndOfDay(
					moment(filters.endDate).format('YYYY-MM-DD')
				),
			};

			dispatch(
				apiRequest<{ count: number }>({
					...logAPIs.loadSeallogsCount({ ...filters, vesselId, token, userId }),
					preExecute: () => {
						// dispatch(
						// 	job.active({
						// 		notification: {},
						// 		// message: "Loading Reports Count",
						// 	})
						// );
					},
					postExecute: {
						onSuccess: (response) => {
							if (response.status === 200) {
								const countData = response.data;
								dispatch(
									job.success({
										notification: {
											hideAtState: "SUCCESS",
											timeout: 100,
										},
									})
								);
								dispatch(logActions.document.seallogsPaginationTotalItemsSet(countData?.count || 0));
							} else
								dispatch(
									job.error({
										message: "No Seallogs count found!!",
									})
								);
						},
						onError: (error) => {
							dispatch(
								job.error({ message: "Server Error" })
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case '@seallogs/listing/LOAD/PRINT': {
			const { vesselId } = action.payload;
			const job = logJobActions.seallogsLoadPrint;
			const latestFilters = getState().app.logs._forms.SEAL_LOGS_FILTER.fields;
			const tenantId = getState().app.auth.user?.tenantId ?? "";

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			// TODO: Add filters
			let filters: any = {
				...latestFilters,
			};

			if (!filters.startDate && !filters.endDate){
				filters = {
					...filters,
					startDate: moment.utc().subtract(30, 'days').toDate(),
					endDate: moment.utc().add(1, 'days').toDate()
				}
				dispatch(logActions._forms.sealLogsFilter.formFieldsEdit({
					...filters
				}));
			}

			filters = {
				...filters,
				startDate: commonUtils.getStartOfDay(
					moment(filters.startDate).format('YYYY-MM-DD')
				),
				endDate: commonUtils.getEndOfDay(
					moment(filters.endDate).format('YYYY-MM-DD')
				),
			};

			dispatch(
				apiRequest<ISealLog[]>({
					...logAPIs.loadSeallogsPrint({...filters, tenantId, vesselId, token, userId }),
					preExecute: () => {
						dispatch(job.active({ message: "Loading Seal Logs" }));
						// dispatch(logActions.document.seallogsPaginationCurrentPageSet(pageNumber));
					},
					postExecute: {
						onSuccess: (response) => {
							if (response.status === 200) {
								const seallogs = response.data;
								dispatch(logActions.document.seallogsSetPrint(seallogs));
							} else
								dispatch(
									job.error({
										message: "No Seal Logs found!!",
									})
								);
						},
						onError: (error) => {
							dispatch(
								job.error({ message: "Server Error" })
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case '@seallogs/listing/LOAD': {
			const { pageNumber, vesselId } = action.payload;
			const job = logJobActions.seallogsLoad;
			let pagination = getState().app.logs.seallogsPagination;
			const latestFilters = getState().app.logs._forms.SEAL_LOGS_FILTER.fields;
			const tenantId = getState().app.auth.user?.tenantId ?? "";

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			// TODO: Add filters
			let filters: any = {
				...latestFilters,
			};

			if (!filters.startDate && !filters.endDate){
				filters = {
					...filters,
					startDate: moment.utc().subtract(30, 'days').toDate(),
					endDate: moment.utc().add(1, 'days').toDate()
				}
				dispatch(logActions._forms.sealLogsFilter.formFieldsEdit({
					...filters
				}));
			}

			filters = {
				...filters,
				startDate: commonUtils.getStartOfDay(
					moment(filters.startDate).format('YYYY-MM-DD')
				),
				endDate: commonUtils.getEndOfDay(
					moment(filters.endDate).format('YYYY-MM-DD')
				),
			};

			dispatch(
				apiRequest<ISealLog[]>({
					...logAPIs.loadSeallogs({...filters, tenantId, vesselId, token, userId }, pagination.itemsPerPage, pageNumber),
					preExecute: () => {
						dispatch(job.active({ message: "Loading Seal Logs" }));
						dispatch(logActions.document.seallogsPaginationCurrentPageSet(pageNumber));
					},
					postExecute: {
						onSuccess: (response) => {
							if (response.status === 200) {
								const seallogs = response.data;
								dispatch(logActions.document.seallogsSet(seallogs));
							} else
								dispatch(
									job.error({
										message: "No Seal Logs found!!",
									})
								);
						},
						onError: (error) => {
							dispatch(
								job.error({ message: "Server Error" })
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case '@reports/count/LOAD': {
			const { vesselId } = action.payload;
			const job = logJobActions.reportsCountLoad;

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			dispatch(
				apiRequest<{ count: number }>({
					...logAPIs.loadReportsCount({ vesselId, token, userId }),
					preExecute: () => {
						// dispatch(
						// 	job.active({
						// 		notification: {},
						// 		// message: "Loading Reports Count",
						// 	})
						// );
					},
					postExecute: {
						onSuccess: (response) => {
							if (response.status === 200) {
								const countData = response.data;
								dispatch(
									job.success({
										notification: {
											hideAtState: "SUCCESS",
											timeout: 100,
										},
									})
								);
								dispatch(
									logActions.document.reportsPaginationTotalItemsSet(
										countData?.count || 0
									)
								);
							} else
								dispatch(
									job.error({
										message: "No Reports count found!!",
									})
								);
						},
						onError: (error) => {
							dispatch(job.error({ message: "Server Error" }));
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@reports/listing/LOAD": {
			const { pageNumber, vesselId } = action.payload;
			const job = logJobActions.reportsLoad;
			let pagination = getState().app.logs.reportsPagination;
			const tenantId = getState().app.auth.user?.tenantId ?? "";

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			// TODO: Add filters
			const filters: any = {};

			dispatch(
				apiRequest<ITabReport[]>({
					...logAPIs.loadReports(
						{ ...filters, tenantId, vesselId, token, userId },
						pagination.itemsPerPage,
						pageNumber
					),
					preExecute: () => {
						dispatch(job.active({ message: "Loading Reports" }));
						dispatch(
							logActions.document.reportsPaginationCurrentPageSet(
								pageNumber
							)
						);
					},
					postExecute: {
						onSuccess: (response) => {
							if (response.status === 200) {
								const reports = response.data;
								dispatch(
									logActions.document.reportsSet(reports)
								);
							} else
								dispatch(
									job.error({
										message: "No Reports found!!",
									})
								);
						},
						onError: (error) => {
							dispatch(job.error({ message: "Server Error" }));
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@reports/single/LOAD": {
			const { report, vesselId } = action.payload;
			const job = logJobActions.singleReportLoad;

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			dispatch(
				apiRequest<IReport>({
					...logAPIs.loadSingleReport(report, {
						vesselId: vesselId,
						token,
						userId,
					}),
					preExecute: () => {
						dispatch(job.active({ message: "Loading Report" }));
						dispatch(
							logActions.document.reportPrintTemplateReset()
						);
					},
					postExecute: {
						onSuccess: (response) => {
							const report = response.data;
							dispatch(logActions.document.selectReport(report));
							dispatch(
								logActions.commands.reportPrintTemplateLoad(
									vesselId
									// report?.versionInfo?.config
								)
							);
						},
						onError: (error) => {
							dispatch(job.error({ message: "Server Error" }));
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@reports/print-template-load": {
			const { vesselId, recordConfigVersion } = action.payload;
			const job = logJobActions.reportPrintTemplateLoad;

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			dispatch(
				apiRequest<any>({
					...logAPIs.loadReportPrintTemplate(
						vesselId,
						userId,
						token,
						recordConfigVersion
					),
					preExecute: () => {
						dispatch(
							job.active({
								message: "Loading Report print template",
							})
						);
					},
					postExecute: {
						onSuccess: (response) => {
							dispatch(
								logActions.document.reportPrintTemplateSet(
									response.data
								)
							);
						},
						onError: (error) => {
							dispatch(job.error({ message: "Server Error" }));
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@log/list/LOAD": {
			next(action);
			const { pageNumber, vesselId } = action.payload;
			const job = logJobActions.logListLoad;
			const filters = getState().app.logs._forms.FILTER.fields;
			let pagination = getState().app.logs._pagination;
			// const vesselId = filters.vesselId ? filters.vesselId : getState().app.vesselStore.onboard.vesselId || "";
			const tenantId = getState().app.auth.user?.tenantId ?? "";

			dispatch(
				apiRequest<ITabLogListResponseObj[]>({
					...logAPIs.loadLogList(
						{ ...filters, tenantId, vesselId },
						pagination.itemsPerPage,
						pageNumber
					),
					preExecute() {
						dispatch(
							logActions.document.paginationCurrentPageSet(
								pageNumber
							)
						);
						dispatch(job.active({ message: "Loading..." }));
					},
					postExecute: {
						onSuccess({ data: logListObj }) {
							if (logListObj && logListObj.length) {
								dispatch(
									logActions.document.logListSet(
										logListObj[0]?.results ?? []
									)
								);
								const totalItems =
									logListObj[0]?.count?.total_docs ?? 0;
								dispatch(
									logActions.document.paginationTotalItemsSet(
										totalItems
									)
								);
								dispatch(
									job.success({
										message: "Logs loaded successfully",
									})
								);
							}
						},
						onError() {
							dispatch(
								job.error({
									message: "Failed to load logs",
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@log/list/FILTER": {
			next(action);
			const { filters } = action.payload;
			const job = logJobActions.logListFilter;
			let pagination = getState().app.logs._pagination;
			const vesselId = filters.vesselId
				? filters.vesselId
				: getState().app.vesselStore.onboard.vesselId || "";
			const tenantId = getState().app.auth.user?.tenantId ?? "";

			dispatch(
				apiRequest<ITabLogListResponseObj[]>({
					...logAPIs.loadLogList(
						{ ...filters, tenantId, vesselId },
						pagination.itemsPerPage,
						pagination.currentPage
					),
					preExecute() {
						dispatch(logActions.document.paginationReset());
						dispatch(
							historyActions.replace(
								"@log/list/FILTER",
								// rootRoutes.children.app.children.log.children.list.url(pagination.currentPage)
								rootRoutes.children.app.children.vessel.children.onboard.children.logs.url(
									vesselId,
									pagination.currentPage
								)
							)
						);
						dispatch(
							job.active({
								message: "Filtering logs",
								notification: {
									showAtState: "ERROR",
									hideAtState: "SUCCESS",
									timeout: 100,
								},
							})
						);
					},
					postExecute: {
						onSuccess({ data: logListObj }) {
							if (logListObj && logListObj?.length) {
								const logsArray = logListObj[0]?.results ?? [];
								if (logsArray.length === 0) {
									dispatch(
										logActions.document.paginationTotalItemsSet(
											0
										)
									);
									dispatch(
										logActions.document.logListSet(
											logsArray
										)
									);
									dispatch(
										job.error({
											message:
												"No Log Found for given filter",
											notification: {
												timeout: 10000,
											},
										})
									);
								}
								dispatch(job.success({}));
								const totalItems =
									logListObj[0]?.count?.total_docs ?? 0;
								dispatch(
									logActions.document.paginationTotalItemsSet(
										totalItems
									)
								);
								dispatch(
									logActions.document.logListSet(logsArray)
								);
							}
						},
						onError() {
							dispatch(
								job.error({
									message: "Failed to filter logs",
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@log/LOAD": {
			next(action);
			const { log } = action.payload;
			const job = logJobActions.logLoad;
			const tenantId = getState().app.auth.user?.tenantId ?? "";
			const vesselId = log?.vessel?._id ?? "";
			const selectedDate = log.selectedDate;

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			dispatch(
				apiRequest<ILog | ILog[]>({
					...logAPIs.loadLogV2(log, userId, token),
					preExecute() {
						dispatch(logActions.document.logPrintTemplateReset());
						dispatch(job.active({ message: "Loading log" }));
					},
					postExecute: {
						onSuccess(response) {
							const logs = response.data;
							dispatch(logActions.document.logSelect(logs));
							dispatch(job.success({}));

							// Logic to load Print template for selected log
							if (
								log?.version &&
								log.version === "v2" &&
								log.code &&
								log.code === "deckLogConfig"
							) {
								dispatch(
									logActions.commands.logPrintTemplateLoad(
										"Deck Log v2",
										log.vessel._id
									)
								);
							} else if (
								log?.code &&
								(log.code === "engineLogConfig" ||
									log.code === "deckLogConfig")
							) {
								dispatch(
									logActions.commands.dayLogLoad({
										tenantId,
										selectedDate,
										vesselId,
									})
								);
							}
							dispatch(
								logActions.commands.logPrintTemplateLoad(
									log.code,
									log.vessel._id
								)
							);
						},
						onError() {
							dispatch(
								job.error({
									message: "Error loading selected Log",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@log/day-log/LOAD": {
			next(action);
			const {
				dayLogParams: { tenantId, vesselId, selectedDate },
			} = action.payload;
			const job = logJobActions.loadDayLogs;

			dispatch(
				apiRequest<any>({
					...logAPIs.loadDayLogs({
						tenantId,
						vesselId,
						selectedDate,
					}),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading day logs",
							})
						);
					},
					postExecute: {
						onSuccess({ data: dayLogsData }) {
							if (
								dayLogsData &&
								dayLogsData?.dayLogs?.docs &&
								Array.isArray(dayLogsData?.dayLogs?.docs) &&
								dayLogsData?.dayLogs?.docs?.length
							) {
								dispatch(
									logActions.document.setAssociatedDayLog(
										dayLogsData?.dayLogs?.docs[0]
									)
								);
								dispatch(
									job.success({
										message: "Day logs loaded successfully",
										notification: {
											hideAtState: "SUCCESS",
											timeout: 100,
										},
									})
								);
							}
						},
						onError() {
							dispatch(
								job.error({
									message:
										"Failed to load associated day log",
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case "@log/selectedLog/printTemplate/LOAD": {
			next(action);
			const { logType, vesselId, code } = action.payload;
			const job = logJobActions.logPrintTemplateLoad;
			dispatch(
				apiRequest({
					...logAPIs.loadPrintTemplate(logType, vesselId, code),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading Log View",
								notification: {
									hideAtState: "ACTIVE",
									showAtState: "ERROR",
								},
							})
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(
								logActions.document.logPrintTemplateSet(
									response.data
								)
							);
							dispatch(
								job.success({
									message: "Loaded Log View",
									notification: { hideAtState: "SUCCESS" },
								})
							);
							dispatch(job.idle({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Error loading View",
									notification: { timeout: 10000 },
								})
							);
						},
					},
				})
			);
			break;
		}
		case "form/fields/EDIT": {
			next(action);
			const { feature, formName } = action.payload;
			if (
				feature === logConstants.FEATURE &&
				formName === logConstants.forms.FILTER
			) {
				const latestFilters = getState().app.logs._forms.FILTER.fields;

				// Info: change in the dropdown should lead to pagination to page 1
				dispatch(logActions.document.paginationCurrentPageSet(1));
				dispatch(logActions.commands.logListFilter(latestFilters));
			} else if (
				feature === logConstants.FEATURE &&
				formName === logConstants.forms.SEAL_LOGS_FILTER
			) {
				const latestFilters = getState().app.logs._forms.SEAL_LOGS_FILTER.fields;
				dispatch(
					historyActions.push(
						"#Seallog",
						rootRoutes.children.app.children.vessel.children.onboard.children.seallogs.url(
							latestFilters.vesselId, 1
						)
					)
				);

				dispatch(
					logActions.commands.seallogsLoadPrint({
						vesselId: latestFilters.vesselId
					})
				);
			}
			break;
		}

		case "@reports/retry": {
			next(action);
			const { reportType, docId, vesselId } = action.payload;
			const job = logJobActions.singleReportLoad;

			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";

			if (!reportType || !docId || !vesselId) {
				dispatch(job.error({ message: "Invalid Request" }));
				return;
			}

			dispatch(
				apiRequest<IReport>({
					...logAPIs.retryReport(reportType, docId),
					preExecute: () => {
						dispatch(
							job.active({
								message: `Retrying ${reportType} connection for this report.`,
								notification: {},
							})
						);
					},
					postExecute: {
						onSuccess: (response) => {
							console.log(response);
							// notify user
							dispatch(
								job.success({
									message: "Retry request sent successfully",
									notification: {
										timeout: 1000,
									},
								})
							);

							// reload the signal reports table
							dispatch(
								logActions.commands.reportsLoad({
									pageNumber: 1,
									showPageNumberInNotification: false,
									vesselId: vesselId,
								})
							);
						},
						onError: (error) => {
							dispatch(
								job.error({
									message: "Server Error",
									notification: {
										timeout: 10000,
									},
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		default: {
			next(action);
		}
	}
};
export default logMiddleware;