import React from "react";

type SVGAlertProps = { className?: string };
const SVGAlert: React.FC<SVGAlertProps> = (props) => (
	<svg
		width="21"
		height="21"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M18.7762 18.7579H2.22554C2.03371 18.7573 1.84536 18.7066 1.67909 18.611C1.51281 18.5153 1.37436 18.3779 1.27739 18.2124C1.18006 18.0465 1.12849 17.8578 1.12793 17.6655C1.12738 17.4732 1.17787 17.2842 1.27424 17.1178L9.55454 2.78896C9.65006 2.62266 9.7878 2.48455 9.95383 2.38858C10.1199 2.29261 10.3083 2.2422 10.5001 2.24243C10.8901 2.24243 11.2534 2.45191 11.4477 2.78948L19.7054 17.0821C19.8089 17.2479 19.8662 17.4385 19.8712 17.6339C19.8763 17.8293 19.8289 18.0226 19.7341 18.1936C19.6393 18.3645 19.5005 18.507 19.3321 18.6063C19.1636 18.7055 18.9717 18.7578 18.7762 18.7579ZM10.4722 3.31396H10.4633L2.18459 17.6407L2.22554 17.7084H18.7762C18.7993 17.7084 18.8218 17.6858 18.8218 17.6633C18.8182 17.6575 18.7998 17.6129 18.7961 17.6071L10.5379 3.31396H10.4722Z"
			fill="black"
		/>
		<path
			d="M9.99023 8.16316V12.7312C9.99669 12.8849 10.0623 13.0302 10.1733 13.1366C10.2844 13.2431 10.4323 13.3026 10.5861 13.3026C10.74 13.3026 10.8878 13.2431 10.9989 13.1366C11.1099 13.0302 11.1755 12.8849 11.182 12.7312V8.16316C11.1818 8.00497 11.1191 7.85327 11.0074 7.74122C10.8958 7.62917 10.7443 7.56588 10.5861 7.56519C10.259 7.56519 9.99023 7.83451 9.99023 8.16316ZM10.5866 14.1219C10.259 14.1219 9.99076 14.3876 9.99076 14.7162V15.1157C9.99186 15.2733 10.055 15.424 10.1666 15.5353C10.2782 15.6466 10.4291 15.7093 10.5866 15.71C10.9153 15.71 11.1825 15.4407 11.1825 15.1157V14.7162C11.1825 14.3876 10.9148 14.1219 10.5866 14.1219Z"
			fill="black"
		/>
	</svg>
);
export default SVGAlert;
