import React, { useEffect } from "react";
import classNames from "classnames";
import { Dispatch } from "redux";
import { IStore } from "../../../../../../redux/store";
import { connect, ConnectedProps } from "react-redux";
import InputWrapper from "../../../../../_common/InputWrapper";
import Button from "../../../../../../@set-product/core/button";
import WSDropdown from "../../../../../_common/Dropdown";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../../../redux/app/vessel/onboard";
import InfiniteProgress from "../../../../../_common/InfiniteProgress";
import { Icon, IconName } from "@blueprintjs/core";
import { IVesselDevice } from "../../../../../../redux/app/vessel/types";
import { deviceFormDefaults } from '../../../../../../redux/app/vessel/onboard/reducers.state';

const uiStaticData = vesselOnboardSelectors.getDeviceFormStaticData();
type DeviceFormProps = PropsFromRedux & {
	title: string;
	subtitle: string;
	onCancel(): void;
	onClose(): void;
};
function DeviceForm(props: DeviceFormProps) {
	const { form, jobs, subtitle, title } = props;
	const { hasErrors, helpMessages, fields, errors, warnings } = form;
	useEffect(
		function onLoad() {
			if (jobs.deviceCreate.isSuccess || jobs.deviceUpdate.isSuccess)
				props.onClose();
		},
		[jobs.deviceCreate.isSuccess, jobs.deviceUpdate.isSuccess]
	);
	return (
		<div
			className={classNames("w-full h-full my-6 bg-white bp3-dialog py-3", {})}
		>
			<div className="justify-between p-4 px-8 pt-6 bp3-dialog-header">
				<div className="flex flex-col px-2">
					<h4 className="text-xl font-medium text-gray-700 font-redhat-display">
						{title}
					</h4>
					<h5
						className={classNames("text-sm text-gray-600", {
							"text-red-500 font-medium": hasErrors,
						})}
					>
						{hasErrors ? "Form has few errors" : subtitle}
					</h5>
				</div>
				<button
					aria-label="Close"
					onClick={() => {
						props.resetForm()
						props.onClose()
					}}
					className="p-4 text-2xl bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
				/>
			</div>
			<InfiniteProgress
				isLoading={
					jobs.deviceLoad.isLoading ||
					jobs.deviceCreate.isLoading ||
					jobs.deviceUpdate.isLoading
				}
				isSpacedOut={false}
			/>
			<div
				className="p-8 py-0 mx-0 overflow-y-auto bg-white rounded-sm bp3-dialog-body ws-scroll"
				style={{ maxHeight: 460 }}
			>
				<form
					className="flex flex-row flex-wrap items-baseline justify-start p-2 -mx-2 -my-2"
					onSubmit={function onSubmit(e) {
						e.preventDefault();
						props.formSubmit();
						props.resetForm();
					}}
					autoComplete="off"
				>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.deviceName.isValid}
								hasWarning={!warnings.deviceName.isValid}
								inputValue={fields.deviceName}
							>
								<label htmlFor="device-name-id" className="ws-input__label">
									{uiStaticData.deviceName.label}
								</label>
								<input
									type="text"
									className="ws-input"
									autoComplete="off"
									value={fields.deviceName}
									onChange={function onNameChange(e) {
										props.formFieldEdit({ deviceName: e.target.value });
									}}
								/>
								<span className="ws-input__error">
									{errors.deviceName.message}
								</span>
								<span className="ws-input__warning">
									{warnings.deviceName.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.deviceName}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.deviceType.isValid}
								hasWarning={!warnings.deviceType.isValid}
								inputValue={fields.deviceType}
							>
								<label htmlFor="device-type-id" className="ws-input__label">
									{uiStaticData.deviceType.label}
								</label>
								<WSDropdown
									id="device-type-id"
									inputWrapperProps={{
										hasError: !errors.deviceType.isValid,
										hasWarning: !warnings.deviceType.isValid,
										inputValue: fields.deviceType,
									}}
									value={fields.deviceType}
									// @ts-ignore
									options={(() => {
										return uiStaticData.deviceType.options.map((deviceType) => {
											return {
												value: deviceType.value,
												label: deviceType.label,
												icon: deviceType.icon,
											};
										});
									})()}
									onSelect={function onDeviceTypeChange(e) {
										props.formFieldEdit({
											deviceType: e?.value as "DESKTOP" | "TABLET",
										});
									}}
									renderListItem={function renderDeviceTypeItem(listItem) {
										return (
											<div>
												<Icon
													icon={listItem.icon as IconName}
													className="text-gray-600"
												/>
												<span className="ml-3 text-gray-800">
													{listItem.label}
												</span>
											</div>
										);
									}}
									renderView={function renderDeviceTypeView(selectedItem) {
										return (
											<div>
												<Icon
													icon={selectedItem.icon as IconName}
													className="text-gray-600"
												/>
												<span className="ml-3 text-gray-800">
													{selectedItem.label}
												</span>
											</div>
										);
									}}
									noFilter={true}
								/>
								<span className="ws-input__error">
									{errors.deviceType.message}
								</span>
								<span className="ws-input__warning">
									{warnings.deviceType.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.deviceType}
								</span>
							</InputWrapper>
						</div>
					</div>
					{/* <div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.operatingSystem.isValid}
								hasWarning={!warnings.operatingSystem.isValid}
								inputValue={fields.operatingSystem}
							>
								<label
									htmlFor="operating-system-id"
									className="ws-input__label"
								>
									{uiStaticData.operatingSystem.label}
								</label>
								<WSDropdown
									id="operating-system-id"
									inputWrapperProps={{
										hasError: !errors.operatingSystem.isValid,
										hasWarning: !warnings.operatingSystem.isValid,
										inputValue: fields.operatingSystem,
									}}
									value={fields.operatingSystem}
									// @ts-ignore
									options={(() => {
										return uiStaticData.operatingSystem.options.map((os) => {
											return {
												value: os,
												label: os,
												icon: os,
											};
										});
									})()}
									onSelect={function onOperatingSystemChange(e) {
										props.formFieldEdit({
											operatingSystem: e?.value,
										});
									}}
									noFilter={true}
								/>
								<span className="ws-input__error">
									{errors.operatingSystem.message}
								</span>
								<span className="ws-input__warning">
									{warnings.operatingSystem.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.operatingSystem}
								</span>
							</InputWrapper>
						</div>
					</div> */}
					{/* <div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.version.isValid}
								hasWarning={!warnings.version.isValid}
								inputValue={fields.version}
							>
								<label htmlFor="version-id" className="ws-input__label">
									{uiStaticData.version.label}
								</label>
								<WSDropdown
									id="version-id"
									inputWrapperProps={{
										hasError: !errors.version.isValid,
										hasWarning: !warnings.version.isValid,
										inputValue: fields.version,
									}}
									value={fields.version}
									// @ts-ignore
									options={(() => {
										return uiStaticData.version.options.map((os) => {
											return {
												value: os,
												label: os,
												icon: os,
											};
										});
									})()}
									onSelect={function onVersionChange(e) {
										props.formFieldEdit({
											version: e?.value,
										});
									}}
									noFilter={true}
								/>
								<span className="ws-input__error">
									{errors.version.message}
								</span>
								<span className="ws-input__warning">
									{warnings.version.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.version}
								</span>
							</InputWrapper>
						</div>
					</div> */}
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.deviceId.isValid}
								hasWarning={!warnings.deviceId.isValid}
								inputValue={fields.deviceId}
							>
								<label htmlFor="deviceid-id" className="ws-input__label">
									{uiStaticData.deviceId.label}
								</label>
								<input
									type="text"
									id="deviceid-id"
									className="ws-input"
									value={fields.deviceId}
									onChange={function onDeviceIdChange(e) {
										props.formFieldEdit({ deviceId: e.target.value });
									}}
								/>
								<span className="ws-input__error">
									{errors.deviceId.message}
								</span>
								<span className="ws-input__warning">
									{warnings.deviceId.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.deviceId}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.macAddress.isValid}
								hasWarning={!warnings.macAddress.isValid}
								inputValue={fields.macAddress}
							>
								<label htmlFor="mac-address-id" className="ws-input__label">
									{uiStaticData.macAddress.label}
								</label>
								<input
									type="text"
									id="mac-address-id"
									aria-autocomplete="none"
									className="ws-input"
									autoComplete="off"
									value={fields.macAddress}
									onChange={function onMacAddressChange(e) {
										props.formFieldEdit({
											macAddress: e.target.value,
										});
									}}
									maxLength={17}
								/>
								<span className="ws-input__error">
									{errors.macAddress.message}
								</span>
								<span className="ws-input__warning">
									{warnings.macAddress.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.macAddress}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="justify-start w-full p-0 my-3 bp3-dialog-footer-actions">
						<Button
							text="Submit"
							loading={
								jobs.deviceLoad.isLoading ||
								jobs.deviceCreate.isLoading ||
								jobs.deviceUpdate.isLoading
							}
							view="filled"
							type="submit"
							color="primary"
							className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
						/>
						<Button
							text="Cancel"
							view="smooth"
							className="mx-2 font-medium "
							onClick={() => {
								props.resetForm()
								props.onCancel()
							}}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	return vesselOnboardSelectors.getDeviceFormData(
		store.app.vesselStore.onboard
	);
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		load(deviceId: string) {
			dispatch(vesselOnboardActions.command.deviceLoad(deviceId));
		},
		formModeSet(mode: "CREATE" | "EDIT" | "NONE") {
			dispatch(vesselOnboardActions._forms.device.formModeSet(mode));
		},
		formFieldEdit(fields: Partial<IVesselDevice>) {
			dispatch(vesselOnboardActions._forms.device.formFieldsEdit(fields));
		},
		formSubmit() {
			dispatch(vesselOnboardActions._forms.device.formSubmit());
		},
		resetForm() {
			dispatch(
				vesselOnboardActions._forms.device.formFieldsSet(deviceFormDefaults)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(DeviceForm);
