import moment from "moment";
import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { TSegregatedActivity } from "redux/app/tool/@types";
import toolActions from "redux/app/tool/actions";
import toolSelectors from "redux/app/tool/selectors";
import { IStore } from "redux/store";
import ToolActivityLogCardList from "./ToolActivityLogCardList";
import Skeleton from "react-loading-skeleton";

type OwnProps = {
	isCardView: boolean;
};
type ActivityLogCardViewProps = PropsFromRedux & OwnProps;
function ActivityLogCardView(props: ActivityLogCardViewProps) {
	const { cardSpecificActivityLogs, isCardView, jobToLoadCPActivityLogs } =
		props;
	const observerTarget = useRef(null);

	//INFO: create an identifier that would be used for segregation of user actions.(structure is in return)
	const cardActivityLogs: TSegregatedActivity[] = cardSpecificActivityLogs.map(
		(activity, index) => {
			let timeFromNow = moment(activity?.timestamp).fromNow();

			if (timeFromNow.includes("minute")) {
				timeFromNow = "few minutes ago";
			}
			if (timeFromNow.includes("hour ago")) {
				timeFromNow = "a hour ago";
			}
			if (timeFromNow.includes("sec")) {
				timeFromNow = "few seconds ago";
			}

			return {
				...activity,
				timeFromNow,
				identifier:
					activity.userName +
					" + " +
					timeFromNow +
					" + " +
					activity.action +
					" + " +
					activity.module +
					" + " +
					activity.vesselName,
				index,
			};
		}
	);

	const identifierBasedSegregation = new Map<string, TSegregatedActivity[]>();

	cardActivityLogs.forEach((activity) => {
		const identifierVal = identifierBasedSegregation.get(activity.identifier);
		if (identifierVal) {
			identifierVal.push(activity);
		} else {
			identifierBasedSegregation.set(activity.identifier, [activity]);
		}
	});

	// INFO: Observer for infinite scroll
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					props.loadActivityLogForConfigAndPrints(isCardView);
				}
			},
			{ threshold: 1 }
		);

		if (isCardView && observerTarget.current) {
			observer.observe(observerTarget.current);
		}

		return () => {
			if (observerTarget.current) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				observer.unobserve(observerTarget.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [observerTarget, isCardView]);

	return (
		<>
			<div className="flex p-4 space-x-12">
				<div className="w-3/4 lg:w-2/3 px-4 max-h-[calc(100vh-210px)] overflow-y-auto ws-scroll">
					{/* INFO: loading cannot be shown always as during infinite scroll also loading takes place so need to show only when no activities */}
					{jobToLoadCPActivityLogs.isLoading &&
					!cardSpecificActivityLogs.length ? (
						<div>
							{Array.from({ length: 10 }).map((_) => {
								return (
									<Skeleton
										className="mb-6 rounded-md"
										width="100%"
										height={90}
									/>
								);
							})}
							<div ref={observerTarget}></div>
						</div>
					) : (
						Array.from(identifierBasedSegregation.keys()).map((identifier) => {
							const [userName, timeFromNow, action, module, vesselName] =
								identifier.split(" + ");

							return (
								<div className="mb-6" key={identifier}>
									<h4 className="text-gray-700 mb-1 text-sm">
										{userName}{" "}
										<span className="text-gray-500 text-sm font-medium">
											{action}
										</span>{" "}
										{module} for {vesselName}{" "}
										{/* <span className="text-gray-500 font-medium">. </span> */}
										<span className="text-gray-500 text-sm font-medium">
											{timeFromNow}
										</span>
									</h4>
									<div className="rounded-md p-2.5 border shadow">
										<ToolActivityLogCardList
											identifier={identifier}
											identifierBasedSegregation={identifierBasedSegregation}
										/>
									</div>
								</div>
							);
						})
					)}
					{/* INFO: target element for intersection api */}
					<div ref={observerTarget}></div>
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const cardSpecificActivityLogs =
		toolSelectors.getCPCardActivityLog(toolStore);
	const jobToLoadCPActivityLogs =
		toolSelectors.jobToLoadCPActivityLogs(toolStore);

	return {
		cardSpecificActivityLogs,
		jobToLoadCPActivityLogs,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadMoreCardActivityLogs(pageNumber: number) {
			dispatch(
				toolActions.document.toolCPCardActivityLogPaginationCurrentPageSet(
					pageNumber
				)
			);
		},
		loadActivityLogForConfigAndPrints(isCardView: boolean) {
			dispatch(toolActions.commands.toolCPActivityLogLoad(isCardView));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ActivityLogCardView);
