import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { transformAllVesselsGarbageDischargeDataForTable } from "redux/app/report/utils";
import { IStore } from "redux/store";

const garbageDischargeAllVesselsHeaders = [
	"Year",
	"Quarter",
	"Report date",
	"Incinerated",
	"Discharged at sea",
	"Disposed ashore",
	"Accidental release",
	"Total garbage",
];

type OwnProps = {};
type GarbageDischargeTableProps = PropsFromRedux & OwnProps;
function GarbageDischargeTable(props: GarbageDischargeTableProps) {
	const { garbageDischargeAllVesselsData, year } = props;
	const tableRowRecords = useMemo(() => {
		return Object.keys(garbageDischargeAllVesselsData).length
			? transformAllVesselsGarbageDischargeDataForTable(
					garbageDischargeAllVesselsData
			  )
			: [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [garbageDischargeAllVesselsData]);

	return (
		<div>
			<h2 className="text-xl font-medium mb-3">
				Garbage by disposal method (m³)
			</h2>{" "}
			<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text  overflow-auto  border-collapse text-center">
				<thead>
					<tr>
						{garbageDischargeAllVesselsHeaders.map((headerLabel) => (
							<th
								key={headerLabel}
								className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
							>
								{headerLabel}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableRowRecords.map((row, index) => {
						const {
							quarter,
							disposedAshore,
							dischargedToSea,
							incinerated,
							accidentalRelease,
							quarterTotal,
						} = row;
						return (
							<tr key={row.quarter}>
								{index === 0 && (
									<td
										rowSpan={5}
										className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
									>
										{year}
									</td>
								)}
								<td
									colSpan={2}
									className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
								>
									{quarter}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{incinerated.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{dischargedToSea.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{disposedAshore.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{accidentalRelease.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{quarterTotal.toFixed(2)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const garbageDischargeAllVesselsData =
		reportSelectors.getGarbageDischargeAllVesselsData(reportStore);
	const year = reportSelectors.getGarbageYear(reportStore);

	return {
		garbageDischargeAllVesselsData,
		year,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageDischargeTable);
