import React from "react";
import { Button as ButtonSource, IButtonProps, Icon } from "@blueprintjs/core";
import classNames from "classnames";

import Type from "./_type.module.css";
import View from "./_view.module.css";
import Color from "../../styles/color.module.css";

type ButtonType = {
	/**
	 * The shape of the component.
	 * Variants: `card` `action` `circle` `icon`
	 * Default value (if undefined): `default` `
	 */
	shape?: "default" | "card" | "action" | "circle" | "icon" | undefined;
	/**
	 * The view of the component.
	 * Variants: `flat` `smooth` `outlined` `raised`
	 * Default value (if undefined): `filled` `
	 */
	view?: "filled" | "flat" | "smooth" | "outlined" | "raised";
	/**
	 * The color of the component.
	 * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
	 * Default value (if undefined): `default` `
	 */
	color?:
		| "default"
		| "primary"
		| "warning"
		| "danger"
		| "success"
		| "primary_alt"
		| "warning_alt"
		| "danger_alt"
		| "success_alt";
	/**
	 * Whether this component should expand to fill its container.
	 */
	fill?: boolean;
	/**
	 * If set to `true`, the button will display a centered loading spinner instead of its contents.
	 * The width of the button is not affected by the value of this prop.
	 */
	loading?: boolean;
	disabled?: boolean;
	/**
	 * Dense size
	 */
	dense?: boolean;
} & IButtonProps;

const Button: React.FC<ButtonType> = (props: ButtonType) => {
	const isDark = false;
	const {
		shape = "default",
		view = "filled",
		color = "default",
		active,
		dense,
		className,
		icon,
		...restProps
	} = props;
	return (
		<ButtonSource
			{...restProps}
			className={classNames(
				"set-product-btn",
				className,
				Type[shape],
				dense && Type.dense,
				isDark ? View[view + "-dark"] : View[view],
				Color[color],
				active && View.focused
			)}
			icon={
				icon && (
					<Icon
						icon={icon}
						iconSize={
							dense
								? shape === "action"
									? 24
									: shape === "circle"
									? 20
									: 16
								: shape === "action"
								? 32
								: shape === "circle"
								? 24
								: 20
						}
					/>
				)
			}
		/>
	);
};

export default Button;
