import { Tab, Tabs } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { TPrintType } from "redux/app/tool/@types";
import toolActions from "redux/app/tool/actions";
import { PRINT_TABS } from "redux/app/tool/constants";
import toolSelectors from "redux/app/tool/selectors";
import { IStore } from "redux/store";
import { commonUtils } from "redux/_common";
import LogTable from "./_elements/LogTable";
import ChecklistTable from "./_elements/ChecklistTable";
import PermitsTable from "./_elements/PermitsTable";

type OwnProps = {
	children: React.ReactNode;
};
type PrintProps = PropsFromRedux & OwnProps;
function Print(props: PrintProps) {
	const {
		activeTab,
		category,
		selectedVesselId,
		selectedItems,
		jobToLoadPrint,
	} = props;

	useEffect(() => {
		if (selectedVesselId) props.loadPrintList(category, selectedVesselId);
		else props.emptyPrintListForAllCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category, selectedVesselId]);

	useEffect(() => {
		return () => {
			props.setLogPrintListEmpty();
			props.setChecklistsPrintListEmpty();
			props.setPermitsPrintListEmpty();
			props.setActionPrintId("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="px-4 border-b border-[#D8D8D8] mt-2">
				<Tabs
					id="print-tabs"
					animate={true}
					renderActiveTabPanelOnly={true}
					vertical={false}
					large={true}
					selectedTabId={activeTab}
					className="w-full rounded-sm bp3-html-table bp3-interactive overflow-x-auto"
					onChange={function onTabsChange(tabId) {
						props.handleTabChange(tabId as PRINT_TABS);
					}}
				>
					<Tab
						id={PRINT_TABS.TAB_LOGS}
						title={
							undefined
							// <span className="mr-3 text-lg bp3-icon bp3-icon-dashboard" />
						}
						disabled={!!selectedItems.length || jobToLoadPrint.isLoading}
						className="font-medium text-gray-600 "
					>
						Logs
					</Tab>
					<Tab
						id={PRINT_TABS.TAB_CHECKLISTS}
						title={
							undefined
							// <span className="mr-3 text-lg bp3-icon bp3-icon-person" />
						}
						disabled={!!selectedItems.length || jobToLoadPrint.isLoading}
						className="font-medium text-gray-600 "
					>
						Checklists
					</Tab>
					<Tab
						id={PRINT_TABS.TAB_PERMITS}
						title={
							undefined
							// <span className="mr-3 text-lg bp3-icon bp3-icon-mobile-phone" />
						}
						disabled={!!selectedItems.length || jobToLoadPrint.isLoading}
						panelClassName=""
						className="font-medium text-gray-600 "
					>
						Permits
					</Tab>

					<Tabs.Expander />
				</Tabs>
			</div>
			<div className="mx-4">{props.children}</div>
			{/* TODO: We can make single table for log, checklist & permits by passing props  
				Reason for creating separate tables, is possiblity of having different columns for each of them.
			*/}
			{activeTab === PRINT_TABS.TAB_LOGS && (
				<div className="px-4 my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<LogTable />
				</div>
			)}
			{activeTab === PRINT_TABS.TAB_CHECKLISTS && (
				<div className="px-4 my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<ChecklistTable />
				</div>
			)}
			{activeTab === PRINT_TABS.TAB_PERMITS && (
				<div className="px-4 my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<PermitsTable />
				</div>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const activeTab = toolSelectors.getActiveTabForPrint(toolStore);

	const category = toolSelectors.getActiveTabForPrint(toolStore);
	const selectedItems = toolSelectors.getPrintSelectedItems(toolStore);
	const selectedVesselId =
		toolSelectors.getSelectedVesselOption(toolStore)?.value;

	const jobToLoadPrint = toolSelectors.jobToLoadPrint(toolStore);
	return {
		activeTab,
		category,
		selectedVesselId,
		selectedItems,
		jobToLoadPrint,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		handleTabChange(activeTab: PRINT_TABS) {
			dispatch(toolActions.document.toolPrintTabSet(activeTab));
		},
		loadPrintList(category: TPrintType, vesselId?: string) {
			dispatch(toolActions.commands.toolPrintListLoad(category, vesselId));
		},
		emptyPrintListForAllCategory() {
			dispatch(toolActions.commands.toolPrintListEmptyForAllCategory());
		},
		setLogPrintListEmpty() {
			dispatch(
				toolActions.document.toolPrintLogsListSet(commonUtils.arrayToObject([]))
			);
		},
		setChecklistsPrintListEmpty() {
			dispatch(
				toolActions.document.toolPrintChecklistsListSet(
					commonUtils.arrayToObject([])
				)
			);
		},
		setPermitsPrintListEmpty() {
			dispatch(
				toolActions.document.toolPrintPermitsListSet(
					commonUtils.arrayToObject([])
				)
			);
		},
		setActionPrintId(printId: string) {
			dispatch(toolActions.document.toolPrintIdOnActionSelect(printId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Print);
