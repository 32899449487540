import React from "react";
import SVGImo from "components/_common/icons/SVGImo";
import SVGLocation from "components/_common/icons/SVGLocation";
import SVGVessel from "components/_common/icons/SVGVessel";
import SVGVersion from "components/_common/icons/SVGStack";
import SVGSync from "components/_common/icons/SVGSync";
import SVGFromTo from "components/_common/icons/SVGToFrom";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import moment from "moment";
import classNames from "classnames";
import { convertDmmToDecimalDegrees } from "components/app/dashboard/list/utils";

type OwnProps = { vesselId: string };
type VesselInfoProps = PropsFromRedux & OwnProps;

function VesselInfo(props: VesselInfoProps) {
	const { vessel, vesselMetadata } = props;

	// const getCoordinates = (isLat: boolean) => {
	// 	if (isLat === undefined) return;
	// 	if (
	// 		vessel?.liveInfo?.last_location?.lat &&
	// 		vessel?.liveInfo?.last_location?.long
	// 	) {
	// 		if (isLat) {
	// 			return convertDmmToDecimalDegrees(
	// 				vessel?.liveInfo?.last_location.lat,
	// 				vessel?.liveInfo?.last_location.long
	// 			)?.latitude;
	// 		}

	// 		return convertDmmToDecimalDegrees(
	// 			vessel?.liveInfo?.last_location.lat,
	// 			vessel?.liveInfo?.last_location.long
	// 		)?.longitude;
	// 	}
	// };

	const vesselInfo = [
		{
			icon: SVGImo,
			title: "IMO number",
			value: vessel?.imoNumber ?? "",
		},
		{
			icon: SVGFromTo,
			title: "From - To",
			value:
				!vesselMetadata?.liveInfo ||
				(!vesselMetadata.liveInfo?.from?.local_reading &&
				!vesselMetadata.liveInfo?.to?.local_reading)
					? ""
					: `${vesselMetadata.liveInfo?.from?.local_reading ?? "NA"} - ${
						vesselMetadata.liveInfo?.to?.local_reading ?? "NA"
					  } `,
		},
		{
			icon: SVGVessel,
			title: "State of ship",
			value: vesselMetadata?.liveInfo?.state_of_ship?.local_reading ?? "",
		},
		{
			icon: SVGLocation,
			title: "Current location",
			value:
				vesselMetadata?.liveInfo?.last_location?.lat &&
				vesselMetadata?.liveInfo?.last_location?.long ? (
					<a
						href={`https://maps.google.com/?q=${vesselMetadata?.liveInfo?.last_location.lat},${vesselMetadata?.liveInfo?.last_location.long}&?z=15
						`}
						// href={`https://maps.google.com/?q=
						// 	${getCoordinates(true)},
						// 	${getCoordinates(false)}
						// `}
						target="_blank"
						rel="noreferrer"
					>
						{vesselMetadata?.liveInfo?.last_location.lat} {", "}
						{vesselMetadata?.liveInfo?.last_location.long}
					</a>
				) : (
					""
				),
		},
		{
			icon: SVGSync,
			title: "Last Synced",
			value: vesselMetadata?.liveInfo?.last_synced
				? moment(vesselMetadata.liveInfo.last_synced).fromNow()
				: "",
		},
		// {
		// 	icon: SVGCaptain,
		// 	title: "Captain",
		// 	value: "Captain",
		// },
		{
			icon: SVGVersion,
			title: "Version no.",
			value: vesselMetadata?.liveInfo?.versionInfo
				? `FE:${vesselMetadata?.liveInfo?.versionInfo?.FE} | BE:${vessel?.liveInfo?.versionInfo?.BE} `
				: "",
		},
	];

	return (
		<div className="flex flex-wrap justify-center mt-4">
			{vesselInfo
				// .filter((info) => info.value)
				.map((info) => {
					const { icon: Icon, title, value } = info;

					return (
						<div
							key={title}
							className={classNames(
								" basis-28  md:basis-32 lg:flex-1 xl:flex-none  xl:basis-56 my-2 lg:mx-0 lg:my-0 "
							)}
						>
							<div className="flex flex-col items-center text-[#444444B2]">
								<div className="h-7">
									<Icon />
								</div>
								<h4 className="font-redhat-text mt-2 text-xs md:text-[12px] xl:text-sm text-center">
									{title}
								</h4>
								<p className="mt-1 text-xs md:text-[12px] xl:text-base text-center">
									{value}
								</p>
							</div>
						</div>
					);
				})}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = store.app.vesselStore;
	const currentlyOpenedVesselId = ownProps.vesselId;
	const vesselsInfo = vesselStore.vesselsInfo;
	const vesselInfoId = vesselsInfo.ids.find((id) => {
		const vessel = vesselsInfo.byIds[id];
		return vessel?.vesselRefId === currentlyOpenedVesselId;
	});;
	return {
		vessel: vesselStore.vessels.byIds[currentlyOpenedVesselId],
		vesselMetadata: vesselInfoId? vesselsInfo.byIds[vesselInfoId]: null,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselInfo);
