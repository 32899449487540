import SVGVessel from "components/_common/icons/SVGVessel";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { vesselSelectors } from "redux/app/vessel";
import { vesselOnboardSelectors } from "redux/app/vessel/onboard";
import { IStore } from "redux/store";

type OwnProps = {
	goBack?: () => void;
};
type VesselNameProps = PropsFromRedux & OwnProps;
function VesselName(props: VesselNameProps) {
	const { goBack, vessel, jobToLoadVessel } = props;
	return (
		<h3 className="text-2xl font-light text-gray-600 align-baseline select-none font-redhat-display flex items-center">
			{goBack && (
				<span
					title="go back"
					onClick={() => goBack && goBack()}
					className="bp3-icon bp3-icon-arrow-left text-lg mr-3 cursor-pointer"
				/>
			)}
			<span className="hidden md:inline-block">
				<SVGVessel className="w-6 h-6 -mb-1" />{" "}
			</span>
			<span className="inline-block mx-2">
				{jobToLoadVessel.isLoading ? (
					"Loading Vessel"
				) : (
					<>
						<span className="text-gray-400" title="Prefix">
							{vessel?.vesselPrefix} -
						</span>{" "}
						<span title="Vessel Name">{vessel?.vesselName}</span>
					</>
				)}
			</span>
		</h3>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = vesselSelectors._getStore(store);
	const onboardStore = vesselOnboardSelectors._getStore(store);
	const vesselId = vesselStore.onboard.vesselId ?? "";
	const vessel = vesselSelectors.getVesselById(
		vesselId,
		vesselSelectors.getVessels(vesselStore)
	);
	const jobToLoadVessel = vesselOnboardSelectors.jobToLoadVessel(onboardStore);
	return {
		vessel,
		jobToLoadVessel,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselName);
