import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MarpolUI from "../../../components/app/marpol/Marpol";
import { rootRoutes } from "../../../routes";
import MarpolList from "./list";

const marpolRoutes = rootRoutes.children.app.children.marpol.children;
type MarpolProps = React.PropsWithChildren<{}>;
function ScreenMarpol(props: MarpolProps) {
	return (
		<MarpolUI>
			<Switch>
				<Route path={marpolRoutes.list.path} component={MarpolList} />
				<Route render={RedirectComponent} />
			</Switch>
		</MarpolUI>
	);
}

function RedirectComponent() {
	return <Redirect to={marpolRoutes.list.url()} />;
}
export default ScreenMarpol;
