import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import { IStore } from "../../../../redux/store";
import { logActions, logSelectors } from "../../../../redux/app/logs";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { vesselActions, vesselSelectors } from "../../../../redux/app/vessel";
import LogFilterHeader from "./_elements/ListFilterHeader";
import LogListRow from "./_elements/ListRow";
import { ITabLog } from "../../../../redux/app/logs/@types";
import LogTemplateRenderer from "./_elements/TemplateRenderer";
import { rootRoutes } from "../../../../routes";
import { historyActions } from "../../../../redux/_core/history";
import SidebarModal from "../../_common/SidebarModal";
import Discrepancies from "./_elements/Discrepancy";
import { getDefaultSidebarObj } from "../utils";
import { IDiscrepancy } from "../types";
import Pagination from "components/_common/NumberedPagination";
import ModalPortal from "components/app/_common/ModalPortal";



type LogListOwnProps = {
	pageNumber: string;
	vesselId?: string;
};
type LogListProps = PropsFromRedux & LogListOwnProps;
function LogList(props: LogListProps) {
	const {
		logs,
		paginationOfLogs,
		pageNumber,
		jobToLoadLogs,
		jobToFilterLogs,
		vesselId,
	} = props;
	const setIsDragRef = useRef((value: boolean) => {});

	useEffect(() => {
		// INFO: when user selects a new vessel in the list will run again
		props.loadLogs(paginationOfLogs.currentPage !== +pageNumber ? +pageNumber : paginationOfLogs.currentPage, true, vesselId || "")

		return () => {
			props.resetPageNumber();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[vesselId,paginationOfLogs.currentPage,pageNumber])

	useEffect(() => {
		return () => {
			// INFO: On change of vessel, logType remains set, that needs to be resetted
			props.resetLogType();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const [isActive, setIsActive] = useState(false);
	const [sidebarObj, setSidebarObj] = useState(getDefaultSidebarObj());
	const [discrepancy, setDiscrepancy] = useState<IDiscrepancy>();

	const [logOperation, setLogOperation] = useState<{
		isModalOpen: boolean;
		logId: string;
		watchHour?: string;
	}>({
		isModalOpen: false,
		logId: "",
		watchHour: "",
	});

	function resetOperations() {
		setLogOperation({
			...logOperation,
			isModalOpen: false,
			logId: "",
			watchHour: "",
		});
	}

	function selectLog(log: ITabLog | null) {
		if (log) {
			const startWatchHour = "";
			const endWatchHour = "";
			setLogOperation({
				isModalOpen: true,
				logId: log.logId,
				watchHour:
					(startWatchHour ? startWatchHour : "") +
					(endWatchHour ? endWatchHour : ""),
			});
			props.loadLog(log);
		}
	}

	return (
		<div className="min-h-full  py-6 pt-0 mb-6 bg-white rounded-md">
			<SidebarModal
				isActive={isActive}
				onClose={() => {
					setIsActive(false);
					setSidebarObj(getDefaultSidebarObj());
					setDiscrepancy(undefined);
				}}
				title={
					<h2 className="text-lg font-semibold font-redhat-display">
						Discrepancies
					</h2>
				}
			>
				<p className="flex items-center justify-between px-5 gap-x-2">
					<span className="font-medium text-gray-800">
						{sidebarObj.title}
					</span>
					<span className="font-mono text-right text-gray-700">
						{sidebarObj.selectedDate}
					</span>
				</p>
				<Discrepancies
					onClick={
						sidebarObj.onClickEvent
							? (discrepancy) => {
									setIsDragRef && setIsDragRef.current(true);
									setDiscrepancy({ ...discrepancy });
									setIsActive(false);
									setSidebarObj(getDefaultSidebarObj());
							  }
							: undefined
					}
					discrepanciesArray={sidebarObj.discrepanciesArray}
				/>
			</SidebarModal>
			<div
				className={classNames(
					"flex flex-row items-end justify-between  my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Logs
					</h3>
					<h6 className="text-sm text-gray-600">
						{jobToLoadLogs.isLoading
							? "Loading logs please wait"
							: ""}
					</h6>
				</div>
			</div>
			<div className="mb-6 overflow-x-auto">
				<InfiniteProgress
					isLoading={jobToLoadLogs.isLoading || jobToFilterLogs.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full relative mb-6  border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{ "animate-pulse": jobToLoadLogs.isLoading }
					)}
				>
					<thead className="relative">
						<tr>
							<th className="text-sm text-gray-500">No.</th>
							<th className="text-sm text-gray-500">Log type</th>
							{/* <th className="text-sm text-gray-500">Vessel</th> */}
							<th className="text-sm text-center text-gray-500">
								Date
							</th>
							<th className="text-sm text-center text-gray-500">
								Alerts
							</th>
							<th className="text-sm text-center text-gray-500">
								Actions
							</th>
						</tr>
						<LogFilterHeader />
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
								jobToLoadLogs.isLoading && logs.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!jobToLoadLogs.isLoading,
						})}
					>
						{logs.map((log, index) => {
							return (
								<LogListRow
									openSidebarModal={(data) => {
										setSidebarObj(data);
										setIsActive(true);
									}}
									logId=""
									log={log}
									key={index}
									onClick={function onLogClick(log: ITabLog) {
										selectLog(log);
									}}
									indexNumber={
										(paginationOfLogs.currentPage - 1) *
											paginationOfLogs.itemsPerPage +
										(index + 1)
									}
								/>
							);
						})}
					</tbody>
				</table>
				{!jobToLoadLogs.isLoading && logs.length === 0 ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
							No Logs found..
						</h5>
					</div>
				) : null}
				<div></div>
					<Pagination
						{...paginationOfLogs}
						onPageChange={function onPageChange(
							pageNumberFromPagination
						) {
							props.goToNewPage(vesselId || "",pageNumberFromPagination);
						}}
						isPageLoading={
							jobToLoadLogs.isLoading || jobToFilterLogs.isLoading
						}
					/>
				
			</div>
			
					<ModalPortal
							isActive={logOperation.isModalOpen}
							onClose={() => {
								resetOperations();
							}}
							isFullWidth
						>
					{logOperation.isModalOpen ? (
						<LogTemplateRenderer
							setIsDragRef={setIsDragRef}
							openSidebarModal={(data) => {
								setSidebarObj(data);
								setIsActive(true);
							}}
							onClose={function onClose() {
								setSidebarObj(getDefaultSidebarObj());
								props.clearSelectedLog()
								resetOperations();
							}}
							discrepancy={discrepancy}
						/>
					) : null}
					</ModalPortal>
				</div>
			
	);
}

function mapStateToProps(store: IStore) {
	const logStore = store.app.logs;
	return {
		logs: logSelectors.getLogs(logStore),
		areVesselsPresent: vesselSelectors.areVesselsPresent(store.app.vesselStore),
		paginationOfLogs: logSelectors.getPagination(logStore),
		jobToLoadLogs: logSelectors.jobToLoadLogs(logStore),
		jobToLoadVessels: vesselSelectors.jobToLoadVessels(store.app.vesselStore),
		//jobToLoadTotalLogsCount: logSelectors.jobToLoadTotalLogCount(logStore),
		jobToFilterLogs: logSelectors.jobToFilterLogs(logStore),
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadLogs(
			pageNumber: number,
			showPageNumberInNotification: boolean = false,
			vesselId: string
		) {
			dispatch(
				logActions.commands.logListLoad({
					pageNumber,
					showPageNumberInNotification,
					vesselId
				})
			);
		},
		loadLog(log: ITabLog) {
			dispatch(logActions.commands.logLoad(log));
		},
		clearSelectedLog() {
			dispatch(logActions.document.clearSelectedLog());
		},
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		goToNewPage(vesselId: string,pageNumber: number) {
			dispatch(
				historyActions.push(
					"#LogList",
					rootRoutes.children.app.children.vessel.children.onboard.children.logs.url(
						vesselId,pageNumber
					)
				)
			);
		},
		resetPageNumber() {
			dispatch(logActions.document.paginationCurrentPageSet(1));
		},
		resetLogType() {
			dispatch(logActions.document.logTypeReset());
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(LogList);
