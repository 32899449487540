import classNames from "classnames";
import moment from "moment";
import React from "react";
import {
	EAnnex6RecordCode,
	TAnnex6Record,
} from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupI4 } from "../../../../../redux/app/annex6/groups/group-i";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupI4PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupI4Preview(props: Annex6GroupI4PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupI4;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'I', 14.1, renderItem14P1()],
                    [null, null, 14.2, renderItem14P2()],
                    [null, null, 14.3, renderItem14P3()],
                    [null, null, 14.4, renderItem14P4()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem14P1() {
        return (
            <h4 className="font-medium uppercase">
                {record.item14P1.placeOfOccurrence}
            </h4>
        );
    }

    function renderItem14P2() {
        return (
            <h4 className="font-medium uppercase">
                {record.item14P2.timeOfOccurrence} LT
            </h4>
        );
    }

    function renderItem14P3() {
        return (
            <h4 className="font-medium uppercase">
                {record.item14P3.odsName}
                <br />
                {record.item14P3.quantityDischarged} KG
            </h4>
        );
    }

    function renderItem14P4() {
        return (
            <h4 className="font-medium uppercase">
                {record.item14P4.circumstansesOfDischarge}
            </h4>
        );
    }

    return renderMain();
}

export default Annex6GroupI4Preview;
