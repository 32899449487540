import React from "react";

function DownloadButton() {
	return (
		<section className="flex justify-center mt-3">
			<button
				className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
				type="submit"
			>
				Download and email file to Ship
			</button>
		</section>
	);
}

export default DownloadButton;
