import React from "react";
import VesselList from "../../../../components/app/approval/list/List";
type ApprovalListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;
function ApprovalList(props: ApprovalListProps) {
	return <VesselList />;
}

export default ApprovalList;
