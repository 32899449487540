import React, { FC } from "react";
import DownloadButton from "../Utils/DownloadButton";
import { SectionUI } from "../Utils/SectionUI";
import config from "./vdf01.json";

const VDF01: React.FC<any> = () => {
	return (
		<div className="antialiased px-6 bg-white">
			<div className="py-12 px-40 flex flex-col gap-y-10">
				{config.checklist.map((checklist) => {
					return <SectionUI key={checklist.key} checklist={checklist} />;
				})}
				<DownloadButton />
			</div>
		</div>
	);
};

export default VDF01;
