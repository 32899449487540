import { IStore } from "redux/store";
import { ITopNavbarStore } from "./@types";

const topNavbarSelectors = {
	_getStore(store: IStore) {
		return store.app.topnavbar;
	},
	getNavbarTitle(topNavbarStore: ITopNavbarStore) {
		return topNavbarStore.navTitle;
	},
	getSearchInputText(topNavbarStore: ITopNavbarStore) {
		return topNavbarStore.searchText;
	},
	getSearchPlaceholderText(topNavbarStore: ITopNavbarStore) {
		return topNavbarStore.searchPlaceholder;
	},
};

export default topNavbarSelectors;
