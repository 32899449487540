import React from "react";
import { Text } from "recharts";

const CustomBaseXAxisTick = ({ x, y, payload }: any) => {
	if (payload && payload?.value) {
		const textToBeShown = payload.value;
		let words = textToBeShown.split(" ");
		const noOfTextElRenders = words.length / 2;

		let elementToRender = [] as any;
		for (let i = 0; i < noOfTextElRenders; i++) {
			elementToRender.push(
				<Text
					key={i}
					fontSize={"10px"}
					x={x}
					y={y + i * 10}
					textAnchor="middle"
					verticalAnchor="start"
				>
					{words.splice(0, 2).join(" ")}
				</Text>
			);
		}

		return <>{elementToRender}</>;
	}
	return null;
};

export default CustomBaseXAxisTick;
