import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import FeedbackBaseTable from "./Table";
import { DASHBOARD_FEEDBACKS_PER_PAGE } from "redux/app/feedback/constants";
import {
	ETicketTabType,
	IPagination,
	IDashboardStatsAndFeedbackPayload,
	ETypeOfFilter,
} from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";

type OwnProps = {
	filter: Partial<IDashboardStatsAndFeedbackPayload> & {
		typeOfFilter: ETypeOfFilter;
	};
	title: string;
};
type FeedbackCategoryProps = PropsFromRedux & OwnProps;
function FeedbackCategory(props: FeedbackCategoryProps) {
	const { feedbacks, filter, title, tab, jobToLoadDashboardStatsAndFeedbacks } =
		props;
	const [paginationOfFeedback, setPaginationOfFeedback] = useState<IPagination>(
		{
			currentPage: 1,
			itemsPerPage: DASHBOARD_FEEDBACKS_PER_PAGE,
			totalItems: feedbacks?.length ?? 0,
		}
	);

	useEffect(() => {
		props.loadDashboardFeedbacks({
			...filter,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	const feedbackIds = feedbacks?.map((fb) => fb._id);

	useEffect(() => {
		setPaginationOfFeedback({
			...paginationOfFeedback,
			totalItems: feedbackIds?.length,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedbackIds.length]);

	const { currentPage } = paginationOfFeedback;

	return (
		<div className="w-full px-4 mt-4">
			<h3 className="text-2xl my-4">
				{title} ({feedbacks.length})
			</h3>

			<FeedbackBaseTable
				id={filter.typeOfFilter}
				paginationOfFeedback={paginationOfFeedback}
				setPaginationOfFeedback={setPaginationOfFeedback}
				feedbacks={feedbacks.slice(
					(currentPage - 1) * DASHBOARD_FEEDBACKS_PER_PAGE,
					currentPage * DASHBOARD_FEEDBACKS_PER_PAGE
				)}
				isLoading={jobToLoadDashboardStatsAndFeedbacks.isLoading}
			/>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const { filter } = ownProps;
	const feedbackStore = feedbackSelectors._getStore(store);
	const tab = feedbackSelectors.getFeedbackTicketTabType(feedbackStore);
	const feedbacks = feedbackSelectors.getDashboardFeedbackListForCategory(
		feedbackStore,
		tab === ETicketTabType.MY_TICKETS ? "myTickets" : "allTickets",
		filter.typeOfFilter
	);
	const dateRange = feedbackSelectors.getDateRange(feedbackStore);
	const jobToLoadDashboardStatsAndFeedbacks =
		feedbackSelectors.jobToLoadDashboardFeedbacksAndStats(feedbackStore);
	return {
		jobToLoadDashboardStatsAndFeedbacks,
		feedbacks,
		tab,
		dateRange,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadDashboardFeedbacks(payload: IDashboardStatsAndFeedbackPayload) {
			dispatch(
				feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad(payload)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackCategory);
