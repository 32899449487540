export function convertDmmToDecimalDegrees(
	latitudeDmm,
	longitudeDmm,
	vesselName
) {
	// Check if input is a valid DMM format
	const dmmRegexLat = /^\d{2}°\d{2}\.\d{1,3}_?'[NS]$/;
	const dmmRegexLong = /^\d{3}°\d{2}\.\d{1,3}_?'[EW]$/;

	if (!dmmRegexLat.test(latitudeDmm) || !dmmRegexLong.test(longitudeDmm)) {
		console.error(
			`Invalid input format for ${vesselName} Expected format: \"DD°MM.mmm'D,[NS],DDD°MM.mmm'[EW]\". Received:",
			${latitudeDmm},
			${longitudeDmm}
			`
		);
		return null;
	}

	// Extract the degrees, minutes, and decimal minutes from the DMM format
	const latDirection = latitudeDmm.charAt(latitudeDmm.length - 1);
	const longDirection = longitudeDmm.charAt(longitudeDmm.length - 1);
	const latDegrees = parseFloat(latitudeDmm.substr(0, 2));
	const latMinutes = parseFloat(latitudeDmm.substr(3, 6));
	const longDegrees = parseFloat(longitudeDmm.substr(0, 3));
	const longMinutes = parseFloat(longitudeDmm.substr(4, 6));

	// Check if degrees and minutes are within valid ranges
	if (
		latDegrees < 0 ||
		latDegrees >= 90 ||
		latMinutes < 0 ||
		latMinutes >= 60
	) {
		console.error("Invalid latitude degrees or minutes.");
	}
	if (
		longDegrees < 0 ||
		longDegrees >= 180 ||
		longMinutes < 0 ||
		longMinutes >= 60
	) {
		console.error("Invalid longitude degrees or minutes.");
	}

	// Determine the sign of the latitude and longitude based on the direction
	const latSign = latDirection.toUpperCase() === "S" ? -1 : 1;
	const longSign = longDirection.toUpperCase() === "W" ? -1 : 1;

	// Convert minutes and decimal minutes to decimal degrees
	const latDecimalDegrees = latSign * (latDegrees + latMinutes / 60);
	const longDecimalDegrees = longSign * (longDegrees + longMinutes / 60);

	return {
		latitude: latDecimalDegrees,
		longitude: longDecimalDegrees,
	};
}

// Usage example
// const latitudeDmm = "35°10.150'N";
// const longitudeDmm = "017°38.370'E";

// const coordinates = convertDmmToDecimalDegrees(latitudeDmm, longitudeDmm);

// console.log("Latitude (decimal degrees):", coordinates.latitude);
// console.log("Longitude (decimal degrees):", coordinates.longitude);
