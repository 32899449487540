import { Reducer } from "redux";
import actionTypes from "./action-types";
import { INotificationStore, INotification__Action_set } from "./types";

const { NOTIFICATION_SET } = actionTypes;
const reducerState = {};

const notificationReducers: Reducer<
	INotificationStore,
	INotification__Action_set
> = (state = reducerState, action): INotificationStore => {
	switch (action.type) {
		case NOTIFICATION_SET:
			return {
				...state,
				[action.payload.JOB_NAME]: action.payload.notification,
			};
	}
	return state;
};

export default notificationReducers;
