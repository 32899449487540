import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import {
	EORB4Item57MethodOfTransferOrDisposal,
	TORB4J2,
} from "../../../../../redux/app/orb4/groups/orb4-j";
import AttachmentsPreview from "../../_elements/AttachmentsPreview";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4J2PreviewProps = OwnProps;
function ORB4J2Preview(props: ORB4J2PreviewProps) {
	const orb4Record = props.orb4Record as TORB4J2;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "J", 55, renderItem55()],
					[null, null, 56, renderItem56()],
					[null, null, getItem57Number(), renderItem57()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem55() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item57.methodOfTransferOrDisposal ===
				EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO
					? "FROM "
					: null}
				{orb4Record.item55.slopTanks.map(
					(tank) => `${tank.selectedTank.tankName}, `
				)}
			</h4>
		);
	}
	function renderItem56() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item57.methodOfTransferOrDisposal ===
				EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES ? (
					<>
						{orb4Record.item55.slopTanks.map((tank, cargoTankIndex) => {
							const tankName = tank.selectedTank.tankName;
							const transferredQuantity = tank.dischargedQuantity;
							const retainedQuantity = tank.newRetainedQuantity;
							const adjustedQty = retainedQuantity < 0 ? 0 : retainedQuantity;
							return (
								<React.Fragment key={cargoTankIndex}>
									{tankName} - {transferredQuantity.toFixed(2)} M³ SOLID RESIDUE
									REMOVED, RETAINED IN TANK {adjustedQty.toFixed(2)} M³ <br />
								</React.Fragment>
							);
						})}
					</>
				) : null}
			</h4>
		);
	}
	function renderItem57() {
		return (
			<h4 className="font-medium uppercase">
				{(function renderItem57() {
					const item57 = orb4Record.item57;
					switch (item57.methodOfTransferOrDisposal) {
						case EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES:
							return (
								<>
									TO SHORE FACILITIES AT {item57.placeOfDischarge}, <br />
									TOTAL QUANTITY TRANSFERRED :{" "}
									{item57.totalQuantityDischarged.toFixed(2)}
									M³
									<AttachmentsPreview
										{...item57}
										_rev={orb4Record._rev}
										id={orb4Record.id}
									/>
								</>
							);
						case EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO:
							return <>??</>;
						case EORB4Item57MethodOfTransferOrDisposal.TRANSFERRED_TO_OR_FROM_TANKS:
							return <>??</>;
						case EORB4Item57MethodOfTransferOrDisposal.OTHER_METHOD:
							return <>{item57.methodDescription}</>;
					}
				})()}
			</h4>
		);
	}

	function getItem57Number() {
		switch (orb4Record.item57.methodOfTransferOrDisposal) {
			case EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES:
				return 57.1;
			case EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO:
				return 57.2;
			case EORB4Item57MethodOfTransferOrDisposal.TRANSFERRED_TO_OR_FROM_TANKS:
				return 57.3;
			case EORB4Item57MethodOfTransferOrDisposal.OTHER_METHOD:
				return 57.4;
		}
	}

	return renderMain();
}

export default ORB4J2Preview;
