import React from "react";
import App from "../../components/app/App";
import { Switch, Route, Redirect } from "react-router-dom";
import { rootRoutes } from "../../routes";
import Auth from "../../components/auth/Auth";
import ScreeenAuthLogin from "./login";
import ScreenAuthReset from "./reset";
import ScreenAuthResetRequest from "./reset-request";
import ScreenSAML from "./saml";

const {
	login,
	register,
	reset,
	reset_request,
	saml
} = rootRoutes.children.auth.children;

type ScreenAuthType = {};
const ScreenAuth: React.SFC<ScreenAuthType> = (props: ScreenAuthType) => {
	return (
		<Auth>
			<Switch>
				<Route path={login.path} component={ScreeenAuthLogin} />
				<Route path={reset.path} component={ScreenAuthReset} />
				<Route path={saml.path} component={ScreenSAML}/>
				<Route path={reset_request.path} component={ScreenAuthResetRequest} />
				<Route render={RedirectComponent} />
			</Switch>
		</Auth>
	);
};

function RedirectComponent() {
	return <Redirect to={login.url()} />;
}

export default ScreenAuth;