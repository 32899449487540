const HISTORY_PUSH = "history/PUSH";
const HISTORY_POP = "history/POP";
const HISTORY_REPLACE = "history/REPLACE";
const HISTORY_CLEAR = "history/CLEAR";
const HISTORY_RESET = "history/RESET";
const historyConstants = {
	HISTORY_PUSH,
	HISTORY_POP,
	HISTORY_REPLACE,
	HISTORY_CLEAR,
	HISTORY_RESET,
};
export default historyConstants;
