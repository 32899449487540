import React from "react";

import Annex6GroupA1Preview from "./previews/Annex6GroupA1Preview";
import Annex6GroupA2Preview from "./previews/Annex6GroupA2Preview";
import Annex6GroupB1Preview from "./previews/Annex6GroupB1Preview";
import Annex6GroupB2Preview from "./previews/Annex6GroupB2Preview";
import Annex6GroupC1Preview from "./previews/Annex6GroupC1Preview";
import Annex6GroupD1Preview from "./previews/Annex6GroupD1Preview";
import Annex6GroupD2Preview from "./previews/Annex6GroupD2Preview";
import Annex6GroupD3Preview from "./previews/Annex6GroupD3Preview";
import Annex6GroupE1Preview from "./previews/Annex6GroupE1Preview";
import Annex6GroupE2Preview from "./previews/Annex6GroupE2Preview";
import Annex6GroupF1Preview from "./previews/Annex6GroupF1Preview";
import Annex6GroupG1Preview from "./previews/Annex6GroupG1Preview";
import Annex6GroupH1Preview from "./previews/Annex6GroupH1Preview";
import Annex6GroupI1Preview from "./previews/Annex6GroupI1Preview";
import Annex6GroupI2Preview from "./previews/Annex6GroupI2Preview";
import Annex6GroupI3Preview from "./previews/Annex6GroupI3Preview";
import Annex6GroupI4Preview from "./previews/Annex6GroupI4Preview";
import Annex6GroupD4Preview from "./previews/Annex6GroupD4Preview";
import Annex6GroupD5Preview from "./previews/Annex6GroupD5Preview";
import Annex6GroupD6Preview from "./previews/Annex6GroupD6Preview";
import Annex6GroupJ1Preview from "./previews/Annex6GroupJ1Preview";
import Annex6GroupJ6Preview from "./previews/ANNEX6J6Preview";
import {
	EAnnex6RecordCode,
	EAnnex6RecordState,
	TAnnex6BackendRecord,
} from "redux/app/annex6/@types";
import Annex6TableRows from "./Annex6TableRows";
import moment from "moment";
import Annex6GroupF2Preview from "./previews/Annex6GroupF2Preview";
import Annex6GroupF3Preview from "./previews/Annex6GroupF3Preview";

type OwnProps = {
	annex6Record: TAnnex6BackendRecord;
};
type Annex6PreviewerProps = OwnProps;
function Annex6Previewer(props: Annex6PreviewerProps) {
	const { annex6Record } = props;
	const isStrikedOff = annex6Record.state === EAnnex6RecordState.STRIKED_OFF;

	const isEditOfApprovedRecord = !!annex6Record.dateOfApprovedRecordEdit;

	function renderMain() {
		if (isEditOfApprovedRecord) return renderEditedApprovedRecord();
		return renderRecord(annex6Record, isStrikedOff);
	}

	function renderEditedApprovedRecord() {
		return (
			<>
				{renderEditCorrectionLine()}
				{renderRecord(annex6Record, isStrikedOff)}
				{renderStruckOffVersionOfEdit()}
			</>
		);
	}

	function renderEditCorrectionLine() {
		if (!annex6Record.dateOfApprovedRecordEdit) return null;
		const formattedEditDate = moment(
			annex6Record.dateOfApprovedRecordEdit,
			"YYYY-MM-DD"
		).format("DD-MMM-YYYY");

		return (
			<Annex6TableRows
				record={annex6Record}
				tableRows={[
					[
						formattedEditDate,
						"J",
						"-",
						"ENTRY PERTAINING TO AN EARLIER INCORRECT OPERATIONAL ENTRY",
					],
				]}
			/>
		);
	}

	function renderStruckOffVersionOfEdit() {
		if (!annex6Record.editVersions) return null;
		const oldVersionOfRecord = {
			...annex6Record.editVersions[0],
			state: EAnnex6RecordState.STRIKED_OFF,
		};
		return <Annex6Previewer annex6Record={oldVersionOfRecord} />;
	}

	function renderRecord(
		recordToRender: TAnnex6BackendRecord,
		isStrikedOff: boolean = false
	) {
		switch (recordToRender.recordCode) {
			case EAnnex6RecordCode.Annex6A1:
				return (
					<Annex6GroupA1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6A2:
				return (
					<Annex6GroupA2Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6B1:
				return (
					<Annex6GroupB1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6B2:
				return (
					<Annex6GroupB2Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6C1:
				return (
					<Annex6GroupC1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6D1:
				return (
					<Annex6GroupD1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6D2:
				return (
					<Annex6GroupD2Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6D3:
				return (
					<Annex6GroupD3Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6D4:
				return (
					<Annex6GroupD4Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6D5:
				return (
					<Annex6GroupD5Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6D6:
				return (
					<Annex6GroupD6Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6E1:
				return (
					<Annex6GroupE1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6E2:
				return (
					<Annex6GroupE2Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6F1:
				return (
					<Annex6GroupF1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6F2:
				return (
					<Annex6GroupF2Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6F3:
				return (
					<Annex6GroupF3Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6G1:
				return (
					<Annex6GroupG1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6H1:
				return (
					<Annex6GroupH1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6I1:
				return (
					<Annex6GroupI1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6I2:
				return (
					<Annex6GroupI2Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6I3:
				return (
					<Annex6GroupI3Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6I4:
				return (
					<Annex6GroupI4Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6J1:
				return (
					<Annex6GroupJ1Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EAnnex6RecordCode.Annex6J6:
				return (
					<Annex6GroupJ6Preview
						annex6Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			default:
				return null;
		}
	}

	return renderMain();
}

export default Annex6Previewer;
