import React from "react";
import Vessel from "../../../components/app/vessel/Vessel";
import { Switch, Route } from "react-router-dom";
import { rootRoutes } from "../../../routes";
import ScreenVesselOnboard from "./onboard";
import ScreenVesseList from "./list";
const {
	list,
	onboard: onboard,
} = rootRoutes.children.app.children.vessel.children;
type ScreenVesselType = React.PropsWithChildren<{}>;
function ScreenVessel(props: ScreenVesselType) {
	return (
		<Vessel>
			<Switch>
				<Route path={list.path} component={ScreenVesseList} />
				<Route path={onboard.path} component={ScreenVesselOnboard} />
				{/* <Route /> TODO: Add 404 not found here*/}
			</Switch>
		</Vessel>
	);
}

export default ScreenVessel;
