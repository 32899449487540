import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupD1 } from "../../../../../redux/app/annex6/groups/group-d";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import CommaSeparator from "components/_common/CommaSeparator";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupD1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupD1Preview(props: Annex6GroupD1PreviewProps) {
    const annex6Record = props.annex6Record as TAnnex6GroupD1;
    const operationDate = moment(
        annex6Record.selectedDate,
        'YYYY-MM-DD'
    ).format('DD-MMM-YYYY');

    function renderMain() {
        return (
            <Annex6TableRows
                record={annex6Record}
                tableRows={[
                    [operationDate, 'D', 7.1, renderItem7P1()],
                    [null, null, 'NOTE', annex6Record.remarks],
                ]}
            />
        );
    }
    function renderItem7P1() {
        if (
            !annex6Record.item7P1.equipmentsUsed ||
            !annex6Record.item7P1.changingFrom
        )
            return <Annex6OldVersionPreview />;

        return (
            <h1>
                {moment(annex6Record.selectedDate, 'YYYY-MM-DD').format(
                    'DD-MMM-YYYY'
                )}{' '}
                AT {annex6Record.item7P1.time} LT COMMENCE CHANGEOVER OF{' '}
                <CommaSeparator
                    items={annex6Record.item7P1.equipmentsUsed.map(
                        (equipment) => equipment.split('_').join(' ')
                    )}
                    lastItemSeperator={' & '}
                />{' '}
                FROM {annex6Record.item7P1.changingFrom.fuelType} (
                {annex6Record.item7P1.changingFrom.sulphurContent}% S) TO{' '}
                {annex6Record.item7P1.changingTo.fuelType}(
                {annex6Record.item7P1.changingTo.sulphurContent}% S), <br />
                POSITION: {annex6Record.item7P1.position.latitude},{' '}
                {annex6Record.item7P1.position.longitude}
            </h1>
        );
    }

    return renderMain();
}

export default Annex6GroupD1Preview;
