import React, { FC, useRef, useState } from 'react'
import moment from 'moment';
import Td from 'components/app/tool/configandprint/_elements/Td';
import Table from 'components/app/tool/configandprint/_elements/Table';
import { localRangeManagementHeaderList, configRangeManagementHeaderList } from 'redux/app/vessel/onboard/constants';
import { ILocalRange } from 'redux/app/vessel/onboard/types';
import actionIcon from "../../../../../assets/icons/action.svg";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { ERangeType } from './Ranges';

interface IRangeManagementListProps {
	ranges: ILocalRange[];
	isLoading: boolean;
	sNoStart: number;
	rangeType: ERangeType;
	onDelete: (ranges: ILocalRange) => void;
	onEdit: (ranges: ILocalRange) => void;
}

const RangeManagementList: FC<IRangeManagementListProps> = (props) => {
	const { isLoading, ranges, sNoStart, rangeType, onDelete, onEdit } = props;
	const [rowId, setRowId] = useState("");
	const dropdownRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(dropdownRef, () => setRowId(''));

	return (
		<>
			<div className="px-4 mt-4">		
				{/* <div className="w-full flex items-center mt-4">
					<input
						type="text"
						placeholder="Search"
						className=" p-2 border bg-[#F7F7F7] border-[#F7F7F7] h-full w-full rounded-md"
						// value={searchInputVal}
						onChange={(e) => {
							// props.setSearch(e.target.value);
						}}
					/>
				</div> */}
				<div className="my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<Table
						isEmpty={!(isLoading) && !ranges.length}
						headers={rangeType === ERangeType.Local? localRangeManagementHeaderList: configRangeManagementHeaderList}
						isLoading={isLoading}
					>
						{ranges.map((range, index) => {
							if (!range) return null;
								return (
									<tr key={index} id={`${index}`}>
										<Td>
											{index + 1 + sNoStart}
										</Td>
										<Td>{range?.metric}</Td>
										<Td>{JSON.stringify(range.range)}</Td>
										{
											rangeType === ERangeType.Local?
											<Td>{range?.modifiedAt
													? moment(
														  range?.modifiedAt
													  ).format('YYYY-MM-DD')
													: '--'}
											</Td>
											: null
										}
										<Td className="py-1">
											<div className="cursor-pointer relative">
												{range.remarks || '--'}
											</div>
										</Td>
										<Td className="py-1">
											<div className="cursor-pointer relative">
												<div
													className="cursor-pointer inline-flex  h-4  items-center justify-center"
													onClick={() => {
														setRowId(range.key);
													}}
												>
													<img src={actionIcon} alt={"action" + range.key} />
												</div>

												{range.key === rowId && (
													<div
														ref={dropdownRef}
														className="absolute rounded-md border border-[#D8D8D8] md:-left-full lg:-left-full xl:-left-3/4 top-2 z-10 bg-white"
													>
														<ul>
															<li
																onClick={() => {
																	onEdit(range);
																	setRowId('');
																}}
																className="py-2.5 text-[14px] pl-2.5 pr-8 md:pr-16 cursor-pointer  border-b"
															>
																<div className="flex items-center">
																	<span className="bp3-icon bp3-icon-edit text-base text-gray-500" />
																	<span className="ml-2.5">Edit</span>
																</div>
															</li>
															{
																rangeType === ERangeType.Local?
																<li
																	onClick={() => {
																		onDelete(range);
																		setRowId('');
																	}}
																	className="py-2.5 text-[14px] pl-2.5 pr-8 md:pr-16 cursor-pointer  border-b"
																>
																	<div className="flex items-center">
																		<span className="bp3-icon bp3-icon-trash text-base text-gray-500" />
																		<span className="ml-2.5">Delete</span>
																	</div>
																</li>
																: null
															}
														</ul>
													</div>
												)}
											</div>	
										</Td>
									</tr>
								);
							})}
					</Table>
				</div>
			</div>
		</>
	)
}

export default RangeManagementList