import React from "react";
type SVGSectionProps = { className?: string };
const SVGSection: React.FC<SVGSectionProps> = (props) => (
	<svg
		width="22"
		height="16"
		viewBox="0 0 22 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 0H18.75C20.2688 0 21.5 1.23122 21.5 2.75V12.75C21.5 14.2688 20.2688 15.5 18.75 15.5H6V0ZM7.5 1.5V14H18.75C19.4404 14 20 13.4404 20 12.75V2.75C20 2.05964 19.4404 1.5 18.75 1.5H7.5Z"
			fill="#B7AEAE"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 2.75C0 1.23122 1.23122 0 2.75 0H7.5V15.5H2.75C1.23122 15.5 0 14.2688 0 12.75V2.75ZM2.75 1.5C2.05964 1.5 1.5 2.05964 1.5 2.75V12.75C1.5 13.4404 2.05964 14 2.75 14H6V1.5H2.75Z"
			fill="#B7AEAE"
		/>
	</svg>
);
export default SVGSection;
