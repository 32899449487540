import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { TSegregatedActivity } from "redux/app/tool/@types";
import { IStore } from "redux/store";
import { Popover2 } from "@blueprintjs/popover2";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import moment from "moment";

const MIN_NO_OF_ACTIONS_TO_SHOW = 3;

function expansionCTAText(isExpand: boolean, noOfActions: number) {
	if (!isExpand) {
		return (
			<span>
				{noOfActions - MIN_NO_OF_ACTIONS_TO_SHOW} more action
				{noOfActions - MIN_NO_OF_ACTIONS_TO_SHOW === 1 ? "" : "s"}{" "}
				<span>&#187;</span>
			</span>
		);
	} else {
		return (
			<span>
				show less <span>&#187;</span>
			</span>
		);
	}
}

type OwnProps = {
	identifier: string;
	identifierBasedSegregation?: Map<string, TSegregatedActivity[]>;
};
type ToolActivityLogCardListProps = PropsFromRedux & OwnProps;
function ToolActivityLogCardList(props: ToolActivityLogCardListProps) {
	const { identifierBasedSegregation, identifier } = props;
	const allActions = identifierBasedSegregation?.get(identifier);

	const [isExpand, setIsExpand] = useState(false);

	const toggleExpand = () => {
		setIsExpand((isExpand) => !isExpand);
	};

	const actionContent = (activity: TSegregatedActivity) => {
		const {
			action,
			code,
			message,
			module,
			userName,
			vesselName,
			timestamp,
			version,
		} = activity;
		return (
			<div className="p-4 rounded shadow bg-white max-w-[275px] -mt-3">
				<p className="text-sm text-gray-600">
					<span className="font-medium">{userName}</span> has {action} {module}{" "}
					for {code} on {moment(timestamp).format("DD MMM, YYYY")} for vessel{" "}
					<span className="font-medium">{vesselName}</span> at{" "}
					{moment(timestamp).format("HH:mm")}
				</p>
				<p className="mt-4">
					<span className="font-medium text-sm text-gray-800">
						Version : {version}{" "}
					</span>
					<span className="block text-gray-600 text-sm">
						<span className="font-medium">notes:</span>{" "}
						{message?.length ? message : "NA"}
					</span>
				</p>
			</div>
		);
	};

	return (
		<>
			<ul>
				{identifierBasedSegregation
					?.get(identifier)
					?.slice(0, isExpand ? undefined : MIN_NO_OF_ACTIONS_TO_SHOW)
					?.map((activity) => {
						return (
							<li className="my-0.5 w-full" key={activity._id}>
								<Popover2
									interactionKind="hover-target"
									hoverCloseDelay={0}
									renderTarget={({ isOpen, ref, ...targetProps }) => {
										return (
											<div
												{...targetProps}
												className="text-ellipsis overflow-hidden whitespace-nowrap"
												ref={ref}
											>
												<span className="text-gray-500 mr-2 text-sm  hover:cursor-pointer">
													{activity?.action}
												</span>
												<span className="text-blue-700 p-0.5 px-1.5 rounded-lg bg-blue-200 text-[12px]  hover:cursor-pointer">
													{activity?.code}
												</span>
												{activity?.version ? (
													<span className="text-gray-500 ml-2 text-sm ">
														with ver :{" "}
													</span>
												) : null}
												<span className="text-[12px] text-blue-500 hover:cursor-pointer">
													{activity?.version}
												</span>
												<span className="text-gray-500 text-sm ml-2  hover:cursor-pointer">
													{activity?.message ?? ""}
													{/* {activity?.message ? ": " : ""}
													{activity?.message?.slice(0, 40)}
													{activity?.message?.length > 39 && "..."} */}
												</span>
											</div>
										);
									}}
									content={actionContent(activity)}
									placement="bottom"
								/>
							</li>
						);
					})}
			</ul>
			{allActions && allActions?.length - MIN_NO_OF_ACTIONS_TO_SHOW > 0 ? (
				<div
					onClick={() => {
						toggleExpand();
					}}
					className="text-gray-600 text-[12px] inline-block cursor-pointer hover:text-gray-800 hover:font-medium  transition duration-200 ease-in-out"
				>
					{expansionCTAText(isExpand, allActions.length)}
				</div>
			) : null}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ToolActivityLogCardList);
