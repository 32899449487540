import React from "react";
type SVGCancelProps = { className?: string };
const SVGCancel: React.FC<SVGCancelProps> = (props) => (
	<svg
		width="22"
		height="21"
		viewBox="0 0 22 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M16.0703 15.5L6.07031 5.5M16.0703 5.5L6.07031 15.5"
			stroke="#605E5E"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGCancel;
