import moment from "moment";
import { marpolUtils } from "../../marpol";
import { EMARPOLDocuments, TMARPOLTank } from "../../marpol/@types";
import {
	EORB1RecordCode,
	EORB1RecordState,
	TORB1CommonData,
	TORB1CommonFields,
} from "../@types";

export type TORB1Item11OilResidueWeekly = {
	selectedTank: TMARPOLTank;
	actualQuantity: number;
};
export type TORB1Item11OilResidueManual = {
	selectedTank: TMARPOLTank;
	collectedResidue: number;
	notesForManualOperation: string;
	isTankInvolvedInManualOperation: boolean;
	manualOperationTank: {
		selectedTank: TMARPOLTank;
		newQuantity: number;
	};
};
export type TORB1Item11OilResidueManual2 = {
	selectedTank: TMARPOLTank;
	collectedResidue: number;
	holdingTankName: string;
};

export type TORB1Item11P1 = {
	oilResidues: TORB1Item11OilResidueWeekly[];
};
export type TORB1Item11P2 = {
	oilResidues: TORB1Item11OilResidueManual[];
};
export type TORB1Item11P3 = {
	oilResidues: TORB1Item11OilResidueManual2[];
	startTime: string;
	endTime: string;
};

export type TORB1Item12POilResidue = {
	selectedTank: TMARPOLTank;
	transferQuantity: number;
};
export type TORB1Item12P1 = {
	transferOfOilResidues: TORB1Item12POilResidue[];
	port: string;
	receiptNumber: string;
	receiptAttachment: string;
};
export type TORB1Item12P2 = {
	transferOfOilResiduesSource: TORB1Item12POilResidue;
	transferOfOilResiduesDestination: TORB1Item12POilResidue[];
};
export type TORB1Item12P3 = {
	transferOfOilResidues: TORB1Item12POilResidue[];
	totalQuantityDisposed: number;
	disposalMethod: string;
};

export enum EORB1Item12P4DisposalMethod {
	BOILER = "BOILER",
	WATER_EVAPORATION = "WATER EVAPORATION",
	REGENERATION = "REGENERATION",
	OTHER_METHOD = "OTHER METHOD",
	INCINERATOR = "INCINERATOR",
	SLOP_TANK = "SLOP TANK",
	SHORE_DISPOSAL = "SHORE DISPOSAL",
}

export type TORB1Item12P4 =
	| {
			disposalOfOilResidues: TORB1Item12POilResidue[];
			totalQuantityDisposed: number;
			disposalMethod:
				| EORB1Item12P4DisposalMethod.BOILER
				| EORB1Item12P4DisposalMethod.WATER_EVAPORATION
				| EORB1Item12P4DisposalMethod.REGENERATION
				| EORB1Item12P4DisposalMethod.OTHER_METHOD;
	  }
	| {
			disposalOfOilResidues: TORB1Item12POilResidue[];
			totalQuantityDisposed: number;
			disposalMethod: EORB1Item12P4DisposalMethod.INCINERATOR;
			totalTimeOfOperation: number;
	  }
	| {
			disposalOfOilResidues: TORB1Item12POilResidue[];
			totalQuantityDisposed: number;
			disposalMethod: EORB1Item12P4DisposalMethod.SLOP_TANK;
			newRetainedQuantity: number;
			slopTank: TMARPOLTank;
			startTime: string;
			stopTime: string;
	  }
	| {
			disposalOfOilResidues: TORB1Item12POilResidue[];
			totalQuantityDisposed: number;
			disposalMethod: EORB1Item12P4DisposalMethod.SHORE_DISPOSAL;
			storedIn: string;
	  };

export type TORB1GroupC1 = {
	recordCode: EORB1RecordCode.ORB1C1;
	item11P1: TORB1Item11P1;
} & TORB1CommonData &
	TORB1CommonFields;

export type TORB1GroupC2 = {
	recordCode: EORB1RecordCode.ORB1C2;
	item11P2: TORB1Item11P2;
} & TORB1CommonData &
	TORB1CommonFields;

export type TORB1GroupC3 = {
	recordCode: EORB1RecordCode.ORB1C3;
	item11P3: TORB1Item11P3;
} & TORB1CommonData &
	TORB1CommonFields;

export type TORB1GroupC4 = {
	recordCode: EORB1RecordCode.ORB1C4;
	item12P1: TORB1Item12P1;
} & TORB1CommonData &
	TORB1CommonFields;

export type TORB1GroupC5 = {
	recordCode: EORB1RecordCode.ORB1C5;
	item12P2: TORB1Item12P2;
} & TORB1CommonData &
	TORB1CommonFields;

export type TORB1GroupC6 = {
	recordCode: EORB1RecordCode.ORB1C6;
	item12P4: TORB1Item12P4;
} & TORB1CommonData &
	TORB1CommonFields;

export type TORB1GroupC =
	| TORB1GroupC1
	| TORB1GroupC2
	| TORB1GroupC3
	| TORB1GroupC4
	| TORB1GroupC5
	| TORB1GroupC6;

const ORB1_GROUPC_DEFAULTS: {
	GROUPC1: TORB1GroupC1;
	GROUPC2: TORB1GroupC2;
	GROUPC3: TORB1GroupC3;
	GROUPC4: TORB1GroupC4;
	GROUPC5: TORB1GroupC5;
	GROUPC6: TORB1GroupC6;
} = {
	GROUPC1: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB1,
		state: EORB1RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		tanksThatCollectedSludge: {
			byIds: {},
			ids: [],
		},
		recordCode: EORB1RecordCode.ORB1C1,
		item11P1: {
			oilResidues: [],
		},
	},
	GROUPC2: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB1,
		state: EORB1RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		tanksThatCollectedSludge: {
			byIds: {},
			ids: [],
		},
		recordCode: EORB1RecordCode.ORB1C2,
		item11P2: {
			oilResidues: [],
		},
	},
	GROUPC3: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB1,
		state: EORB1RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		tanksThatCollectedSludge: {
			byIds: {},
			ids: [],
		},
		recordCode: EORB1RecordCode.ORB1C3,
		item11P3: {
			startTime: moment().format("HH:mm"),
			endTime: moment().add(5, "minutes").format("HH:mm"),
			oilResidues: [],
		},
	},
	GROUPC4: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB1,
		state: EORB1RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		tanksThatCollectedSludge: {
			byIds: {},
			ids: [],
		},
		recordCode: EORB1RecordCode.ORB1C4,
		item12P1: {
			port: "",
			transferOfOilResidues: [],
			receiptNumber: "",
			receiptAttachment: "",
		},
	},
	GROUPC5: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB1,
		state: EORB1RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		tanksThatCollectedSludge: {
			byIds: {},
			ids: [],
		},
		recordCode: EORB1RecordCode.ORB1C5,
		item12P2: {
			transferOfOilResiduesDestination: [],
			transferOfOilResiduesSource: {
				selectedTank: marpolUtils.getDefaultsForNewTank(),
				transferQuantity: 0,
			},
		},
	},
	GROUPC6: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB1,
		state: EORB1RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		tanksThatCollectedSludge: {
			byIds: {},
			ids: [],
		},
		recordCode: EORB1RecordCode.ORB1C6,
		item12P4: {
			disposalMethod: EORB1Item12P4DisposalMethod.INCINERATOR,
			totalQuantityDisposed: 0,
			disposalOfOilResidues: [],
			totalTimeOfOperation: 0,
		},
	},
};

export { ORB1_GROUPC_DEFAULTS };
