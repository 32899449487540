import classNames from "classnames";
import InfiniteProgress from "components/_common/InfiniteProgress";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	ETypeOfFilter,
	IFeedback,
	IPagination,
} from "redux/app/feedback/@types";
import {
	getPriorityClass,
	getStatusClass,
	trimStringWithEllipsis,
} from "redux/app/feedback/utils";
import { IStore } from "redux/store";
import Deadline from "../../list/_elements/Deadline";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import NumberedPagination from "components/_common/NumberedPagination";

type OwnProps = {
	feedbacks: IFeedback[];
	paginationOfFeedback: IPagination;
	setPaginationOfFeedback: React.Dispatch<React.SetStateAction<IPagination>>;
	isLoading: boolean;
	id: ETypeOfFilter;
};
type FeedbackBaseTableProps = PropsFromRedux & OwnProps;
function FeedbackBaseTable(props: FeedbackBaseTableProps) {
	const {
		feedbacks,
		paginationOfFeedback,
		setPaginationOfFeedback,
		isLoading,
		id,
	} = props;

	return (
		<div className=" overflow-x-auto" id={id}>
			<InfiniteProgress isLoading={isLoading} isSpacedOut={false} />
			<table
				className={classNames(
					"w-full mb-2 bp3-html-table font-redhat-text  overflow-x-auto  border-collapse  relative ",
					{
						// "animate-pulse": jobToLoadFeedbacks.isLoading,
						"animate-pulse": false,
					}
				)}
			>
				<thead className="">
					<tr>
						<th className="px-3 py-3 text-[12px] md:text-sm border font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 min-w-[140px] md:min-w-[180px]">
							Date
						</th>
						<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300">
							Name
						</th>
						<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300">
							Vessel
						</th>
						<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 min-w-[150px] md:min-w-[190px]">
							Section
						</th>
						<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 min-w-[300px] md:min-w-[400px] 2xl:w-auto">
							Short description
						</th>
						<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300">
							Actions
						</th>
					</tr>
				</thead>
				<tbody
					className={classNames({
						"h-0 opacity-0 transition-all duration-100 max-h-0":
							// jobToLoadFeedbacks.isLoading && feedbacks.length === 0,
							feedbacks.length === 0,
						// "h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
						// 	!jobToLoadFeedbacks.isLoading,
					})}
				>
					{feedbacks.map((feedback, index) => {
						return (
							<tr key={feedback._id} className="hover:bg-gray-200 h-[105px]">
								<td
									className={classNames(
										"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
									)}
								>
									<div className="h-full">
										<p className="m-0">
											{feedback.createdAt
												? moment(feedback.createdAt).format("DD-MM-YYYY h:mm a")
												: "NA"}
										</p>
										<p className="m-0 text-xs md:text-sm text-[#00000099]">
											Tkt no.: {feedback?.ticketNumber ?? "NA"}
										</p>
									</div>
								</td>
								<td
									className={classNames(
										"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
									)}
								>
									<div>
										<p className="m-0">{feedback?.author ?? "NA"}</p>
										<p className="m-0 text-xs md:text-sm text-[#00000099]">
											{feedback?.authorDesignation}
										</p>
									</div>
								</td>
								<td
									className={classNames(
										"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
									)}
								>
									<div>
										<p className="m-0 text-xs md:text-sm">
											{feedback?.vesselName ?? "NA"}
										</p>
									</div>
								</td>
								<td
									className={classNames(
										"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   lg:min-w-min border-t-0"
									)}
								>
									<div>
										<p className="m-0 text-xs md:text-sm">
											{feedback?.section ?? "NA"}
										</p>
										<p className="m-0 text-xs md:text-sm text-[#00000099]">
											{feedback?.subsection}
										</p>
									</div>
								</td>
								<td
									className={classNames(
										"px-3 py-2  h-full text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28  border-t-0"
									)}
								>
									<div className="flex flex-col justify-between h-full">
										<p className="m-0 text-xs md:text-sm">
											{feedback?.title?.length > 60
												? trimStringWithEllipsis(feedback?.title ?? "", 60)
												: feedback?.title ?? "NA"}
										</p>

										<div className="flex mt-4">
											{feedback?.priority && (
												<div
													className={
														getPriorityClass(feedback.priority).className +
														" mr-2"
													}
												>
													{" "}
													{getPriorityClass(feedback.priority).text}
												</div>
											)}
											{feedback?.status && (
												<div
													className={
														getStatusClass(feedback.status).className + " mr-2"
													}
												>
													{" "}
													{getStatusClass(feedback.status).text}
												</div>
											)}
											{feedback?.dueDate && (
												<Deadline
													dueDate={feedback?.dueDate}
													status={feedback?.status ?? ""}
												/>
											)}
										</div>
									</div>
								</td>
								<td
									className={classNames(
										"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
									)}
								>
									<div className="relative inline-block">
										<div
											className="text-xs md:text-sm font-medium text-[#1B4EA3] underline bg-transparent border-0 shadow-none hover:text-blue-700 cursor-pointer"
											// target="_blank"
											// rel="noreferrer noopener"
											onClick={() => {
												props.goToParticularFeedback(feedback?._id);
											}}
										>
											View
										</div>
										{/* <div className="absolute top-0 left-0"> */}
										{feedback?.user_new_message ? (
											<span className="cursor-pointer absolute -top-1 -right-2 h-[6px] w-[6px] rounded-full bg-red-600 flex justify-center items-center items"></span>
										) : null}
										{/* </div> */}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>

			{feedbacks.length === 0 ? (
				<div className="flex flex-col justify-center h-64 text-center bg-gray-100 -mt-4">
					<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
						No tickets found..
					</h5>
				</div>
			) : null}

			<NumberedPagination
				{...{
					...paginationOfFeedback,
					totalItems: paginationOfFeedback.totalItems || 0,
				}}
				itemIds={[]}
				onPageChange={function onPageChange(pageNumberFromPagination) {
					setPaginationOfFeedback({
						...paginationOfFeedback,
						currentPage: pageNumberFromPagination,
					});
				}}
				// isPageLoading={jobToLoadFeedbacks.isLoading}
			/>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToParticularFeedback(feedbackId: string) {
			dispatch(
				historyActions.push(
					"#feedback-page",
					`${rootRoutes.children.app.children.feedback.children.view.url(
						feedbackId
					)}`
				)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackBaseTable);
