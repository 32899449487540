import React from "react";
import classNames from "classnames";
type SVGLogsProps = { className?: string };
const SVGLogs: React.FC<SVGLogsProps> = (props) => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={classNames(
			"fill-current stroke-current ws-side-item__icon ",
			props.className
		)}
	>
		<path d="M19.9834 7.00113V7.00097V6.99731V7.00113Z" />
		<path
			d="M22.9984 4H9.67767C8.02099 4.00247 6.67846 5.34493 6.67606 7.00162V19.1679C5.02814 19.3479 3.83806 20.8297 4.01799 22.4777C4.18476 24.0047 5.4774 25.1594 7.01349 25.1535H17.8655C19.5222 25.151 20.8647 23.8086 20.8671 22.1518V10.0033H22.9984C24.6562 10.0033 26 8.65947 26 7.00168C26 5.34388 24.6562 4 22.9984 4ZM15.7386 24.2654H7.01349C5.84497 24.2654 4.89773 23.3181 4.89773 22.1496C4.89773 20.9811 5.84497 20.0338 7.01349 20.0338H15.7386C14.5728 21.2034 14.5728 23.0957 15.7386 24.2654ZM19.9835 7.00149V22.1518C19.9835 23.3191 19.0372 24.2654 17.87 24.2654C16.7027 24.2654 15.7564 23.3191 15.7564 22.1518C15.7564 20.9846 16.7027 20.0384 17.87 20.0384C18.1152 20.0384 18.314 19.8395 18.314 19.5943C18.314 19.3491 18.1151 19.1503 17.87 19.1503H7.58186V7.00168C7.58426 5.84229 8.51828 4.90031 9.67761 4.88814H20.8537C20.2955 5.44965 19.9825 6.20961 19.9835 7.00149ZM22.5544 9.11521H20.8716V8.38705H22.5544V9.11521ZM23.4425 9.07082V9.06637V7.94298C23.4425 7.69779 23.2436 7.49897 22.9984 7.49897H20.8716V7.00168C20.8747 5.83439 21.8235 4.89067 22.9907 4.89376C24.1581 4.89691 25.1017 5.84569 25.0987 7.01298C25.0961 8.00195 24.408 8.85681 23.4425 9.07082Z"
			strokeWidth="0.3"
		/>
		<path
			d="M16.9548 8.40472H10.4587C10.2135 8.40472 10.0146 8.60354 10.0146 8.84873C10.0146 9.09392 10.2135 9.29274 10.4587 9.29274H16.9548C17.2001 9.29274 17.3988 9.09392 17.3988 8.84873C17.3988 8.60354 17.2001 8.40472 16.9548 8.40472Z"
			strokeWidth="0.3"
		/>
		<path
			d="M16.9548 11.5751H10.4587C10.2135 11.5751 10.0146 11.7739 10.0146 12.0191C10.0146 12.2643 10.2135 12.4631 10.4587 12.4631H16.9548C17.2001 12.4631 17.3988 12.2643 17.3988 12.0191C17.3988 11.7739 17.2001 11.5751 16.9548 11.5751Z"
			strokeWidth="0.3"
		/>
		<path
			d="M16.9548 14.7456H10.4587C10.2135 14.7456 10.0146 14.9445 10.0146 15.1896C10.0146 15.4349 10.2135 15.6336 10.4587 15.6336H16.9548C17.2001 15.6336 17.3988 15.4348 17.3988 15.1896C17.3988 14.9445 17.2001 14.7456 16.9548 14.7456Z"
			strokeWidth="0.3"
		/>
	</svg>
);
export default SVGLogs;
