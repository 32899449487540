import OilSpillsInWater from "components/app/report/other/oilspillsinwater/OilSpillsInWater";
import React from "react";

type ScreenOilSpillsInWaterProps = React.PropsWithChildren<{}>;

function ScreenOilSpillsInWater(props: ScreenOilSpillsInWaterProps) {
	return <OilSpillsInWater />;
}

export default ScreenOilSpillsInWater;
