import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import Annex6TableRows from "../Annex6TableRows";
import { TAnnex6GroupF3 } from "redux/app/annex6/groups/group-f";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupF3PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupF3Preview(props: Annex6GroupF3PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupF3;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "F", 13.1, renderItem13P1()],
					[null, null, 13.2, renderItem13P2()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem13P1() {
		return (
			<h4 className="font-medium uppercase">
				Engine start on {record.item13P1.engineStart.date},
				{record.item13P1.engineStart.time} at{" "}
				{record.item13P1.startPosition.latitude},
				{record.item13P1.startPosition.longitude} and its engine record{" "}
				{record.item13P1.recordEngine}
			</h4>
		);
	}

	function renderItem13P2() {
		return (
			<h4 className="font-medium uppercase">
				Engine stop on {record.item13P2.engineStop.date},
				{record.item13P2.engineStop.time} at{" "}
				{record.item13P2.stopPosition.latitude},
				{record.item13P2.stopPosition.longitude} and its engine record{" "}
				{record.item13P2.recordEngine}
			</h4>
		);
	}

	return renderMain();
}

export default Annex6GroupF3Preview;
