import React from "react";
import classNames from "classnames";
type EpsSvgLogoProps = { className?: string };
const EpsSvgLogo: React.FC<EpsSvgLogoProps> = (props) => (
	<svg
		width={179}
		height={70}
		viewBox="0 0 179 70"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={classNames(props.className)}
	>
		<path
			d="M142.717 70C140.558 69.6333 138.373 69.3906 136.25 68.8784C120.164 64.9877 109.265 50.0316 110.036 32.9886C110.773 16.7356 123.27 2.88229 139.342 0.501502C140.468 0.334335 141.591 0.167167 142.717 0C143.907 0 145.099 0 146.289 0C146.591 0.0781912 146.89 0.202219 147.196 0.229181C162.388 1.59888 174.597 12.2625 178.015 27.2159C178.455 29.1357 178.676 31.1093 178.999 33.056C178.999 34.3529 178.999 35.6498 178.999 36.944C178.919 37.246 178.796 37.5453 178.77 37.8553C177.438 53.1727 166.836 65.5808 152.076 69.0186C150.174 69.4607 148.221 69.6791 146.289 70C145.097 70 143.907 70 142.717 70Z"
			fill="#70CBFF"
		/>
		<path
			d="M120.635 57.1309C121.366 57.1309 121.843 57.1309 122.318 57.1309C130.365 57.1309 138.414 57.1174 146.461 57.1336C149.241 57.139 151.72 56.4352 153.739 54.3861C157.821 50.2447 156.976 44.2266 151.818 41.5574C150.378 40.8132 148.742 40.4141 147.157 40.0097C144.788 39.4003 144.303 38.0711 145.879 36.1568C151.282 29.5968 156.706 23.0503 162.082 16.4688C162.485 15.9753 162.589 15.2339 162.832 14.6056C162.176 14.363 161.522 13.9073 160.866 13.9073C147.75 13.8669 134.634 13.875 121.518 13.875C121.003 13.875 120.485 13.875 119.848 13.875C125.947 5.78352 138.737 -0.202147 151.989 3.07649C166.122 6.57352 175.929 18.5799 176.836 33.4821C177.679 47.3084 169.067 60.636 156.004 65.678C142.614 70.8467 128.516 66.2038 120.635 57.1309Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 55.4427C0.485676 54.9225 0.763897 54.8926 1.43988 55.2971C3.25339 56.382 5.18912 56.7605 7.246 56.2474C7.73061 56.1263 8.23022 55.8885 8.61565 55.5703C9.43529 54.8931 9.24821 53.7225 8.26507 53.3293C7.78046 53.136 7.23689 53.0459 6.71315 53.009C5.33546 52.9134 3.94813 52.9324 2.57526 52.7939C1.26404 52.6609 0.597715 52.0511 0.369887 51.0151C0.0970293 49.7749 0.491575 48.7356 1.52886 48.0183C2.30241 47.484 3.18584 47.2658 4.09983 47.1181C6.35667 46.7521 8.42964 47.25 10.3659 48.4299C10.8253 48.7096 10.9856 49.2021 10.7647 49.5892C10.5503 49.965 10.1022 50.0698 9.62454 49.7901C8.44305 49.0989 7.21009 48.5293 5.8265 48.5374C4.98917 48.5423 4.14647 48.703 3.31932 48.8643C3.01376 48.9235 2.70713 49.1418 2.46858 49.3622C1.76741 50.01 1.99095 51.0233 2.91406 51.2111C3.78999 51.3892 4.69701 51.4072 5.59009 51.4962C6.59951 51.5977 7.62071 51.633 8.61565 51.816C10.0984 52.0891 10.8007 52.8151 10.9674 54.0537C11.1438 55.3677 10.6061 56.5058 9.32486 57.1189C7.0364 58.2141 4.66646 58.2646 2.28686 57.4023C1.77224 57.2161 1.27263 56.9717 0.795527 56.7019C0.502299 56.5357 0.263748 56.2707 0.000539862 56.0503C3.79536e-06 55.8477 0 55.6452 0 55.4427Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M80.901 18.7259C81.94 20.2024 82.9102 21.6138 83.9151 22.9927C84.303 23.5236 84.8534 23.7779 85.4779 23.853C85.6601 23.8748 85.8263 24.0546 86 24.1616C85.8668 24.3479 85.7661 24.6376 85.5945 24.705C84.4245 25.1662 83.264 25.1301 82.2426 24.2846C81.6928 23.8293 81.1909 23.2688 80.7625 22.6722C80.0203 21.6398 79.3223 20.5648 78.6648 19.4633C78.3169 18.8809 77.9402 18.5982 77.2918 18.6698C76.6567 18.7395 76.0109 18.6845 75.3316 18.6845C75.3316 19.876 75.3172 20.9906 75.3358 22.1051C75.3508 22.998 75.0177 23.4479 74.2649 23.4751C73.3975 23.5077 72.9989 23.0944 73 22.1495C73.0021 19.1907 73.0075 16.2319 73.0139 13.2731C73.0155 12.4228 73.366 12.0131 74.1269 12.0095C76.0642 12.0013 78.0015 11.9882 79.9388 12.022C80.4939 12.032 81.0657 12.1272 81.5963 12.3058C82.9321 12.7569 83.6887 13.8898 83.6786 15.2989C83.6679 16.793 82.8165 18.0767 81.5335 18.5284C81.3774 18.5864 81.2175 18.629 80.901 18.7259ZM75.3193 16.5246C76.955 16.5246 78.5242 16.5406 80.0917 16.5098C80.3709 16.5039 80.6735 16.3466 80.9197 16.1775C81.5564 15.7405 81.5239 14.8311 80.8701 14.4332C80.6996 14.3297 80.4998 14.2339 80.3112 14.2292C78.7399 14.1878 77.1687 14.1648 75.5969 14.1529C75.5047 14.1524 75.3337 14.2966 75.331 14.3782C75.3103 15.0878 75.3193 15.7991 75.3193 16.5246Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.6396 24C33.5912 24.0059 32.004 23.3777 30.5079 22.3973C30.0635 22.106 29.8894 21.4636 30.0711 20.9624C30.2605 20.4393 30.6692 20.2121 31.2073 20.3728C31.4328 20.4399 31.6502 20.565 31.8563 20.6955C33.5204 21.7507 35.2762 22.1007 37.1276 21.587C37.518 21.4791 37.9222 21.2881 38.2399 21.0111C38.9189 20.4203 38.7794 19.4018 37.9629 19.1432C37.3012 18.9333 36.5956 18.8834 35.9059 18.8241C34.7559 18.7244 33.5963 18.7434 32.4545 18.5803C31.4435 18.4362 30.6183 17.8709 30.3709 16.5802C30.1016 15.1744 30.4519 14.0308 31.4766 13.2004C32.3598 12.4839 33.3784 12.2455 34.422 12.0865C36.478 11.7751 38.3768 12.3202 40.1722 13.4709C40.7719 13.8553 41.0239 14.5143 40.7765 15.1044C40.5296 15.694 39.9406 15.818 39.2849 15.4894C38.4613 15.0772 37.6366 14.6424 36.7789 14.3529C35.7903 14.0196 34.7651 14.1269 33.776 14.43C33.4369 14.5344 33.0898 14.7539 32.8353 15.0321C32.6601 15.2237 32.542 15.624 32.5792 15.8969C32.6067 16.0932 32.9172 16.3364 33.1285 16.3791C33.7648 16.509 34.4133 16.5594 35.0583 16.6253C36.1604 16.7386 37.264 16.8311 38.3651 16.9563C38.6446 16.9883 38.9205 17.0957 39.1913 17.1935C41.4504 18.0085 41.4107 21.1475 40.075 22.4334C39.2921 23.1873 38.3824 23.5776 37.407 23.7704C36.7224 23.9057 36.0255 23.9519 35.6396 24Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.39892 18.4126C3.39892 19.2968 3.39892 20.0864 3.39892 20.9552C3.60246 20.9552 3.78097 20.9552 3.95893 20.9552C6.24302 20.9552 8.52711 20.9541 10.8112 20.9558C11.5666 20.9563 11.9878 21.3107 11.9998 21.9442C12.0112 22.5868 11.5595 22.9953 10.8036 22.9964C7.92793 23.001 5.05172 23.0016 2.17552 22.9959C1.34667 22.9936 0.999456 22.6233 1 21.7682C1.00163 18.9168 1.00544 16.0654 1.01143 13.2141C1.01306 12.3937 1.37061 12.0063 2.15647 12.0046C4.98152 11.9984 7.80711 11.9984 10.6322 12.0052C11.3315 12.0069 11.8093 12.449 11.8022 13.0472C11.7946 13.6562 11.3391 14.039 10.6055 14.0402C8.40629 14.0424 6.2071 14.0407 4.0079 14.0407C3.82341 14.0407 3.63947 14.0407 3.41362 14.0407C3.41362 14.8161 3.41362 15.5487 3.41362 16.3452C3.5894 16.3549 3.76681 16.3725 3.94477 16.3731C5.78914 16.3754 7.63241 16.3708 9.47677 16.3771C10.1026 16.3788 10.5037 16.6973 10.593 17.2328C10.7002 17.8771 10.2115 18.4069 9.4746 18.4098C7.64819 18.4183 5.8207 18.4126 3.99375 18.4126C3.81035 18.4126 3.62804 18.4126 3.39892 18.4126Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M59.5893 16.3746C60.0961 16.3746 60.5164 16.3746 60.9367 16.3746C62.7043 16.3746 64.4726 16.3706 66.2409 16.3774C66.9943 16.3797 67.459 16.7785 67.4567 17.3904C67.4543 18.0017 66.9872 18.4079 66.2409 18.4102C64.2521 18.4176 62.2621 18.413 60.2733 18.413C60.0742 18.413 59.875 18.413 59.6331 18.413C59.6331 19.2636 59.6331 20.0658 59.6331 20.9557C59.8489 20.9557 60.0617 20.9557 60.2745 20.9557C62.7974 20.9557 65.3209 20.9528 67.8443 20.9579C68.4347 20.9591 68.8562 21.2542 68.97 21.7128C69.085 22.1737 68.8633 22.6659 68.4075 22.8682C68.2148 22.9531 67.9824 22.9924 67.7685 22.9924C64.5823 23.0004 61.3961 23.0038 58.2099 22.9941C57.3995 22.9913 56.9988 22.589 57 21.7971C57.0036 18.9121 57.0142 16.0265 57.0314 13.142C57.0356 12.4122 57.4321 12.0105 58.1826 12.0077C61.3131 11.9974 64.4441 11.9974 67.5758 12.0077C68.299 12.01 68.7833 12.4652 68.7697 13.0628C68.7554 13.6456 68.2889 14.0382 67.5699 14.0399C65.1015 14.0456 62.6338 14.0524 60.166 14.0319C59.7055 14.0279 59.5513 14.1561 59.5816 14.5988C59.6177 15.1599 59.5893 15.7256 59.5893 16.3746Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M89.6346 16.1283C89.6346 17.8881 89.5929 19.7511 89.6497 21.6118C89.6872 22.8035 88.889 23.104 87.8582 22.9413C87.3241 22.8574 87.0117 22.4549 87.0087 21.855C87.0002 20.1451 87.0057 18.4364 87.0057 16.7265C87.0051 15.5807 87.0153 14.4349 87.0002 13.2896C86.9942 12.7947 87.0987 12.3497 87.6226 12.1507C88.1597 11.9471 88.7301 11.9205 89.234 12.255C89.4751 12.4154 89.6727 12.6371 89.883 12.8372C91.9687 14.8176 94.0526 16.7996 96.139 18.7799C96.1855 18.8241 96.2466 18.8542 96.3674 18.9358C96.3674 18.1319 96.3674 17.3994 96.3674 16.6664C96.3674 15.5206 96.3626 14.3754 96.3686 13.2296C96.3734 12.3899 96.7819 12.0271 97.6979 12.0305C98.58 12.0344 98.9909 12.3984 98.9921 13.2091C98.9976 16.0643 98.9879 18.9194 99 21.774C99.0024 22.2876 98.8326 22.6986 98.3142 22.8733C97.7909 23.0501 97.2326 23.0887 96.7843 22.6782C96.1662 22.1124 95.5777 21.5177 94.9584 20.953C93.1808 19.3344 91.3941 17.722 89.6346 16.1283Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M78.3051 29.1336C80.3477 29.1143 82.2059 29.7362 83.9493 30.7304C84.4504 31.0158 84.6404 31.677 84.4247 32.1641C84.2067 32.6559 83.674 32.8933 83.0683 32.704C82.6849 32.5838 82.3277 32.3793 81.964 32.2022C80.1387 31.3095 78.2537 30.9455 76.2654 31.6049C73.7222 32.4484 72.748 35.3769 74.3601 37.3488C75.2303 38.4133 76.4792 38.6935 77.7777 38.8154C79.6818 38.9948 81.3631 38.4256 82.8629 37.2937C83.6429 36.7058 84.3531 36.6835 84.7694 37.2457C85.2096 37.8406 85.0119 38.5804 84.2426 39.1097C81.3452 41.1027 78.1952 41.4931 74.8731 40.4069C72.4106 39.6009 71.0267 37.6536 71.0004 35.1747C70.9735 32.6718 72.3371 30.6682 74.8295 29.7902C75.9315 29.4021 77.1225 29.2562 78.2728 29C78.283 29.0445 78.2943 29.0891 78.3051 29.1336Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.0497 29C36.7064 28.9971 38.4025 29.615 39.997 30.5899C40.5041 30.8995 40.689 31.5494 40.4652 32.0661C40.2386 32.5882 39.7504 32.8067 39.1556 32.5912C38.8002 32.4622 38.4725 32.2473 38.1326 32.0703C36.447 31.1907 34.7064 30.8332 32.8781 31.4991C30.45 32.3834 29.5825 35.4863 31.2181 37.4266C32.05 38.4133 33.1764 38.672 34.3477 38.7821C36.1016 38.9466 37.6433 38.3648 39.0246 37.2389C39.0795 37.1945 39.1328 37.1478 39.1889 37.1046C39.8087 36.631 40.4313 36.6689 40.7946 37.1999C41.1595 37.7338 41.0328 38.5027 40.458 38.9466C37.5544 41.187 34.401 41.5984 31.1092 40.1595C28.9815 39.229 27.9691 37.3863 28.0007 34.9245C28.0335 32.3959 29.2492 30.7219 31.3652 29.7281C32.4694 29.2078 33.6456 29.0361 35.0497 29Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.01162 34.998C1.01162 33.4545 1.00997 31.9099 1.01217 30.3664C1.01383 29.4285 1.37063 29.0062 2.18515 29.0037C4.27521 28.9981 6.36584 28.9981 8.45646 29.0087C8.77962 29.0099 9.10775 29.046 9.42484 29.1155C11.184 29.5025 12.0818 30.7757 11.9941 32.7359C11.9164 34.48 10.7451 35.839 9.08018 35.9321C7.38772 36.0272 5.68919 35.998 3.99287 35.9793C3.52136 35.9744 3.3697 36.1172 3.39507 36.6557C3.44139 37.6377 3.41989 38.6228 3.43533 39.6072C3.44912 40.5264 3.07908 40.9786 2.27669 40.9991C1.39544 41.0221 1.0017 40.6016 1.00115 39.6302C0.998943 38.0867 1.00059 36.5421 1.00059 34.9986C1.005 34.998 1.00832 34.998 1.01162 34.998ZM3.4072 33.7564C5.14433 33.7564 6.8026 33.7837 8.46032 33.744C9.19708 33.7266 9.58256 33.1955 9.58146 32.3937C9.58036 31.654 9.28531 31.272 8.50223 31.2484C6.91179 31.1999 5.3197 31.2204 3.72871 31.2366C3.62118 31.2378 3.42651 31.4372 3.42154 31.5521C3.39342 32.2608 3.4072 32.9707 3.4072 33.7564Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M95.4419 51.6619C96.15 51.6614 96.8581 51.657 97.5662 51.6624C98.372 51.6684 98.6449 51.9146 98.6484 52.6379C98.652 53.5688 98.6347 54.5001 98.6556 55.4309C98.6664 55.8814 98.4716 56.1978 98.0926 56.4702C96.0828 57.9162 93.7972 58.2288 91.3378 57.8557C89.7279 57.6117 88.2919 57.06 87.254 55.8645C84.8006 53.0388 86.0944 48.8466 89.8526 47.5715C92.8145 46.5666 95.6703 46.9256 98.3876 48.3444C98.4841 48.3951 98.5777 48.4528 98.6634 48.5171C99.0316 48.7981 99.1023 49.1342 98.8529 49.5089C98.6107 49.8743 98.17 49.9849 97.7431 49.7703C96.4258 49.1091 95.0426 48.6102 93.531 48.5269C91.872 48.4359 90.3515 48.7878 89.1259 49.8912C87.4159 51.4315 87.4387 55.3699 91.0242 56.2267C92.7522 56.6401 94.4418 56.6918 96.0487 55.8177C96.4714 55.5878 96.7208 55.3182 96.6704 54.8405C96.6321 54.4713 96.6476 54.096 96.6668 53.724C96.6878 53.3057 96.4756 53.1456 96.0313 53.1477C95.0995 53.1537 94.1684 53.1445 93.2366 53.1363C92.6191 53.1303 92.2605 52.852 92.2605 52.389C92.2605 51.9489 92.6035 51.6739 93.2049 51.6641C93.9507 51.6527 94.696 51.6619 95.4419 51.6619Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.9114 29.0501C19.8542 28.8438 20.3447 29.3298 20.718 30.1519C22.0634 33.1242 23.4513 36.0777 24.8218 39.0385C25.1772 39.8054 24.9965 40.4413 24.3199 40.8217C23.6649 41.1906 22.8979 40.9806 22.5181 40.252C22.1466 39.5385 21.8762 38.7722 21.5113 38.055C21.4191 37.8724 21.1415 37.6813 20.9447 37.6765C19.57 37.6437 18.1947 37.6698 16.8194 37.6516C16.4844 37.6473 16.3163 37.776 16.1907 38.0739C15.8915 38.7825 15.5721 39.4815 15.2514 40.1804C14.9212 40.8988 14.3062 41.1609 13.6638 40.8708C13.0213 40.5796 12.831 39.9601 13.1553 39.2187C14.463 36.2288 15.7863 33.2456 17.0659 30.2435C17.4338 29.382 17.9088 28.825 18.9114 29.0501ZM20.2777 35.5044C19.7579 34.3086 19.2787 33.2074 18.7534 32C18.2426 33.2286 17.7789 34.3444 17.2962 35.5044C18.3048 35.5044 19.2464 35.5044 20.2777 35.5044Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.4098 12.0444C21.4276 11.8658 21.9624 12.302 22.3669 13.0588C23.8145 15.7698 25.3127 18.4607 26.7914 21.16C27.2043 21.9135 26.9988 22.5261 26.2228 22.8644C25.4799 23.1888 24.6339 22.9178 24.2236 22.1928C23.8819 21.5885 23.5643 20.9725 23.2706 20.3499C23.1306 20.0522 22.9471 19.927 22.5575 19.932C21.0872 19.9509 19.6176 19.9487 18.1473 19.9325C17.7875 19.9286 17.6054 20.0399 17.4673 20.3176C17.1444 20.967 16.7892 21.6052 16.4398 22.2457C16.0767 22.9106 15.4239 23.1538 14.727 22.8895C14.0256 22.6235 13.8156 22.047 14.1676 21.3676C15.586 18.6266 17.0232 15.8922 18.4079 13.1389C18.8092 12.3415 19.3298 11.8441 20.4098 12.0444ZM21.8891 17.9694C21.3427 16.9038 20.815 15.8733 20.2393 14.7526C19.6921 15.866 19.1871 16.8932 18.6582 17.9694C19.79 17.9694 20.8072 17.9694 21.8891 17.9694Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M53.4234 35.9856C53.4234 37.2154 53.4256 38.3689 53.4229 39.5218C53.4201 40.6387 52.8979 41.1345 51.9066 40.9688C51.3545 40.8763 51.0034 40.4612 51.0023 39.8345C50.9978 36.6135 50.9995 33.3919 51.0111 30.1709C51.0133 29.4673 51.4198 29.0113 52.0533 29.0088C54.9967 28.997 57.9412 28.9976 60.8846 29.0075C61.5148 29.0094 61.9927 29.5114 61.9999 30.1151C62.0082 30.7586 61.5458 31.2159 60.8502 31.2177C58.5603 31.2239 56.2704 31.2357 53.9816 31.2072C53.5153 31.201 53.3824 31.3518 53.4151 31.8568C53.4533 32.4662 53.4245 33.0811 53.4245 33.7617C53.6604 33.7617 53.8437 33.7617 54.027 33.7617C55.9204 33.7624 57.8144 33.7562 59.7078 33.7661C60.3203 33.7698 60.7661 34.2389 60.7788 34.8433C60.791 35.4507 60.3574 35.9347 59.7548 35.98C59.6175 35.9906 59.4796 35.9844 59.3417 35.9844C57.5857 35.985 55.8296 35.985 54.0736 35.985C53.8858 35.9856 53.6986 35.9856 53.4234 35.9856Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M73.0057 52.5046C73.0057 50.9854 72.9846 49.4656 73.0238 47.9475C73.0314 47.6587 73.211 47.2063 73.4262 47.129C73.7731 47.004 74.2212 47.0915 74.6125 47.1631C74.7763 47.1938 74.9114 47.4081 75.0471 47.5496C77.1805 49.7697 79.3103 51.9937 81.4489 54.2103C81.5723 54.3382 81.7636 54.4041 81.9239 54.4991C81.9718 54.3206 82.059 54.141 82.0602 53.9619C82.0701 51.9834 82.0649 50.005 82.0672 48.027C82.0678 47.2501 82.3047 47 83.0277 47C83.7396 46.9994 83.9964 47.2671 83.9969 48.0327C83.9993 50.9297 83.9975 53.8261 83.9987 56.7231C83.9987 57.2033 84.0531 57.7058 83.4541 57.9235C82.8692 58.136 82.455 57.8803 82.066 57.4705C80.0081 55.3022 77.9415 53.1412 75.8684 50.9871C75.6438 50.7535 75.3572 50.5767 75.0992 50.3744C75.0442 50.6876 74.9442 51.0007 74.9419 51.3139C74.9266 53.2037 74.9372 55.0936 74.9325 56.984C74.9307 57.7206 74.5967 58.0275 73.8667 57.9905C73.2794 57.9604 73.0092 57.6796 73.0068 57.0624C73.0016 55.542 73.0057 54.0228 73.0057 52.5046Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M51.0083 52.4942C51.0083 50.9927 51.0062 49.4912 51.0094 47.9897C51.011 47.2577 51.2463 47.0029 51.9259 47.0018C53.8846 46.9995 55.8443 46.9984 57.8029 47.0052C58.0807 47.0057 58.3638 47.0234 58.6368 47.078C60.3171 47.4107 61.1027 48.5016 60.9893 50.3227C60.888 51.9561 59.8342 53.0777 58.2461 53.13C56.6341 53.1829 55.02 53.1494 53.407 53.1562C52.8175 53.1585 52.7555 53.2233 52.7555 53.8563C52.755 54.9159 52.7619 55.9761 52.7624 57.0356C52.7624 57.7096 52.5143 57.9894 51.9174 57.9997C51.2887 58.0111 51.0036 57.7221 51.002 57.0516C50.9983 55.5324 51.0009 54.0133 51.0009 52.4942C51.0036 52.4942 51.0062 52.4942 51.0083 52.4942ZM55.6413 48.5664C55.6413 48.5647 55.6413 48.5636 55.6413 48.5624C54.8339 48.5624 54.0266 48.5675 53.2193 48.5596C52.8875 48.5562 52.7433 48.7006 52.7513 49.0675C52.7661 49.7392 52.7608 50.4108 52.7534 51.0831C52.7497 51.3965 52.8371 51.5984 53.1668 51.5973C54.765 51.5916 56.3638 51.6172 57.9609 51.5722C58.808 51.5484 59.2877 50.8715 59.2554 49.9121C59.2267 49.0527 58.7815 48.5925 57.9153 48.5704C57.1573 48.5516 56.3993 48.5664 55.6413 48.5664Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M39.0115 52.4762C39.0115 50.9746 39.0091 49.4729 39.0126 47.9713C39.0138 47.2654 39.2722 47.0026 39.9796 47.0015C42.1893 46.9986 44.399 46.9998 46.6082 47.006C46.8782 47.0072 47.1517 47.0311 47.4171 47.0794C49.2559 47.4156 50.1115 48.5054 49.9884 50.3387C49.8805 51.9359 48.7188 53.0706 47.0071 53.1264C45.2342 53.1844 43.4583 53.1486 41.6831 53.1548C40.9873 53.1571 40.9308 53.2089 40.9308 53.8744C40.9308 54.8988 40.936 55.9238 40.9418 56.9476C40.9465 57.7041 40.6858 57.9942 39.9901 57.9999C39.2914 58.0056 39.0021 57.7104 39.001 56.9806C38.9992 55.479 39.0004 53.9779 39.0004 52.4762C39.0039 52.4762 39.0074 52.4762 39.0115 52.4762ZM44.0905 48.5651C44.0905 48.5634 44.0905 48.5623 44.0905 48.5606C43.221 48.5606 42.3509 48.568 41.4801 48.5572C41.0993 48.552 40.9127 48.6817 40.9249 49.0816C40.9448 49.7528 40.9366 50.4246 40.9279 51.0969C40.9238 51.4177 41.0398 51.602 41.3962 51.598C41.8674 51.5929 42.3386 51.5997 42.8098 51.5986C44.0789 51.5957 45.3485 51.6196 46.6158 51.5781C47.567 51.5468 48.0872 50.9228 48.0749 49.9507C48.0633 49.0594 47.5769 48.5907 46.5919 48.5691C45.7585 48.5503 44.9239 48.5651 44.0905 48.5651Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.9985 52.4965C25.9985 53.9924 26.0031 55.4883 25.9961 56.9842C25.9926 57.7885 25.3763 58.1912 24.5958 57.9114C24.196 57.7681 24.0728 57.4656 24.0717 57.0782C24.0687 56.0224 24.0582 54.9661 24.0477 53.9103C24.0401 53.2062 23.9806 53.1456 23.2807 53.145C21.4304 53.1428 19.5806 53.1433 17.7303 53.145C17.0345 53.145 16.9283 53.2515 16.9259 53.9522C16.9236 54.9734 16.93 55.9935 16.9242 57.0147C16.9201 57.6893 16.6428 57.9669 15.992 57.9686C15.326 57.9703 15.0044 57.6644 15.0038 56.9893C14.9985 53.9799 14.9986 50.97 15.005 47.9607C15.0067 47.2645 15.2956 46.9983 15.9943 47C16.665 47.0012 16.9189 47.2515 16.9236 47.9454C16.9306 48.9304 16.9423 49.9165 16.9184 50.9021C16.9061 51.3875 17.0631 51.6078 17.5984 51.6027C19.5205 51.584 21.4438 51.5829 23.366 51.6033C23.9024 51.6089 24.0606 51.3886 24.0506 50.9066C24.0302 49.9392 24.0396 48.9706 24.046 48.0026C24.0512 47.2617 24.3238 46.9961 25.047 47C25.7305 47.004 25.9652 47.2555 25.967 48.0088C25.971 49.5053 25.9681 51.0006 25.9681 52.4971C25.9775 52.4965 25.988 52.4965 25.9985 52.4965Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M49.7145 14.039C49.7145 15.3082 49.7145 16.4931 49.7145 17.6773C49.7145 19.0577 49.7222 20.438 49.7117 21.8189C49.7056 22.6288 49.2861 23.0106 48.4559 22.9998C47.6606 22.9895 47.2842 22.6082 47.2831 21.7972C47.2803 19.4253 47.282 17.0539 47.282 14.6824C47.282 14.4909 47.282 14.2994 47.282 14.0396C47.0058 14.0396 46.7717 14.0401 46.537 14.0396C45.6952 14.039 44.8523 14.0504 44.011 14.0344C43.405 14.023 43.0154 13.6309 43.0004 13.0616C42.9849 12.4826 43.3939 12.0494 43.9983 12.005C44.0669 11.9998 44.1361 12.0004 44.2048 12.0004C47.075 12.0004 49.9452 11.9981 52.8155 12.0044C53.017 12.005 53.2339 12.0266 53.4176 12.1036C53.8482 12.2831 54.0663 12.7555 53.9822 13.2371C53.9025 13.6896 53.5267 14.023 53.0225 14.0327C52.1292 14.0493 51.2354 14.0396 50.3415 14.0401C50.1556 14.0396 49.9702 14.039 49.7145 14.039Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M65.001 34.9311C65.001 33.3892 64.9983 31.8466 65.0019 30.3048C65.0042 29.43 65.3199 29.0115 65.9717 29.0003C66.6594 28.9873 66.9942 29.3928 66.9956 30.2886C67.0015 33.4301 67.0015 36.5722 66.9956 39.7136C66.9938 40.5835 66.6309 41.0174 65.9609 40.9995C65.3212 40.9833 65.0041 40.5487 65.0023 39.6721C64.9987 38.0924 65.001 36.5114 65.001 34.9311Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M46.9988 35.0276C46.9988 36.5695 47.0011 38.1121 46.9979 39.6534C46.9961 40.5567 46.6684 40.9963 46.0076 41C45.3422 41.0037 45.0036 40.566 45.0027 39.6732C44.9991 36.5509 44.9991 33.4286 45.0027 30.3064C45.0041 29.4136 45.3368 28.9957 46.0162 29C46.6747 29.005 46.9938 29.4142 46.997 30.2865C47.0024 31.8669 46.9988 33.4479 46.9988 35.0276Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M32.9996 52.4895C32.9996 54.0076 33.0008 55.5253 32.999 57.0434C32.9984 57.7017 32.846 57.9942 32.5118 57.9999C32.1659 58.0056 32.0017 57.6995 32.0014 57.0219C31.9995 54.0042 31.9995 50.986 32.0014 47.9684C32.0017 47.2715 32.1481 46.9994 32.5076 47C32.8604 47.0011 32.999 47.2755 32.9996 47.9894C33.0005 49.4894 32.9996 50.9894 32.9996 52.4895Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M66.9995 52.5219C66.9995 54.0043 67.0013 55.4872 66.9983 56.9691C66.997 57.7001 66.692 58.0045 65.9793 58C65.2973 57.996 65.002 57.6955 65.0014 56.9907C64.9995 53.9906 64.9995 50.99 65.0014 47.9894C65.002 47.2675 65.2677 47.004 65.9823 47C66.7174 46.9966 66.997 47.2738 66.9983 48.0218C67.0013 49.5218 66.9995 51.0224 66.9995 52.5219Z"
			fill="black"
		/>
	</svg>
);
export default EpsSvgLogo;
