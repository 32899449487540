import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupE1 } from "../../../../../redux/app/annex6/groups/group-e";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupE1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupE1Preview(props: Annex6GroupE1PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupE1;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "E", 9.1, renderItem9P1()],
					[null, null, 9.2, renderItem9P2()],
					[null, null, 9.3, renderItem9P3()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}
	function renderItem9P1() {
		return (
			<h4 className="font-medium uppercase">
				settings change started on {record.item9P1.changeStart.date},
				{record.item9P1.changeStart.time} at{" "}
				{record.item9P1.startPosition.latitude},
				{record.item9P1.startPosition.longitude}
			</h4>
		);
	}

	function renderItem9P2() {
		return (
			<h4 className="font-medium uppercase">
				settings change completed on {record.item9P2.changeCompletion.date},
				{record.item9P2.changeCompletion.time} at{" "}
				{record.item9P2.completionPosition.latitude},
				{record.item9P2.completionPosition.longitude}
			</h4>
		);
	}

	function renderItem9P3() {
		return (
			<h4 className="font-medium uppercase">
				entry into SECA on {""}
				{record.item9P3.entrySeca.date},{record.item9P3.entrySeca.time} at{" "}
				{record.item9P3.entryPosition.latitude},
				{record.item9P3.entryPosition.longitude}
			</h4>
		);
	}

	return renderMain();
}

export default Annex6GroupE1Preview;
