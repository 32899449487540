const topNavbarConstants = {
	FEATURE: "@topnavbar",
};

export const headerTitleMapper = {
	Vessels: ["app/vessel"],
	"User Management": ["app/users"],
	Marpol: ["app/marpol"],
	Feedbacks: ["app/feedback"],
	"User Profile": ["app/users/profile"],
	Reports: ["app/reports"],
	Tools: ["app/tools/list"],
	Insights: ["/app/insights"],
};

export enum PLACEHOLDER_CONSTANTS {
	HOME = "HOME",
	VESSELS = "VESSELS",
	USERS = "USERS",
	FEEDBACK = "FEEDBACK",
	ANYTHING = "ANYTHING",
}

export const searchPlaceholderMapper = {
	"/app/vessel/list": PLACEHOLDER_CONSTANTS.VESSELS,
	"app/users/list": PLACEHOLDER_CONSTANTS.USERS,
};

export default topNavbarConstants;
