import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupI1 } from "../../../../../redux/app/orb1/groups/group-i";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupI1PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupI1Preview(props: ORB1GroupI1PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI1;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "I", "-", renderItem27()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem27() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item27.generalRemarks}
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupI1Preview;
