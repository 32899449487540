import React from "react";
import IndividualReportType from "components/app/report/individualreport/IndividualReportType";

type ScreenIndividualReportTypeProps = React.PropsWithChildren<{}>;

function ScreenIndividualReportType(props: ScreenIndividualReportTypeProps) {
	return <IndividualReportType />;
}

export default ScreenIndividualReportType;
