import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Table from "../../configandprint/_elements/Table";
import {
	configPrintActivityLogHeaderList,
	CP_ACTIVITY_LOGS_PER_PAGE,
} from "redux/app/tool/constants";
import Td from "../../configandprint/_elements/Td";
import toolActions from "redux/app/tool/actions";
import toolSelectors from "redux/app/tool/selectors";
import moment from "moment";
import NumberedPagination from "components/_common/NumberedPagination";
import classNames from "classnames";
import ActivityLogCardView from "./ActivityLogCardView";

type OwnProps = {};
type ToolCPActivityLogProps = PropsFromRedux & OwnProps;
function ToolCPActivityLog(props: ToolCPActivityLogProps) {
	const {
		activityLogs,
		jobToLoadCPActivityLogs,
		paginationOfCPActivityLogs,
		jobToLoadCount,
	} = props;

	// card & table
	const [isCardView, setIsCardView] = useState(true);

	useEffect(() => {
		if (!isCardView) props.loadCountForList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCardView]);

	useEffect(() => {
		if (!isCardView) props.loadActivityLogForConfigAndPrints(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationOfCPActivityLogs.currentPage, isCardView]);

	return (
		<div className="bg-white  m-2 rounded-md overflow-y-auto">
			<div className="px-4 mb-4 overflow-y-auto">
				<div className="flex justify-between items-center">
					<h2 className="flex items-center py-2 my-2 space-x-2 text-xl font-medium ">
						<span
							className={classNames(
								"bp3-icon bp3-icon-arrow-left text-xl font-normal cursor-pointer"
							)}
							onClick={() => props.goBack()}
						/>
						<span>Activity Log</span>
					</h2>
					<div className="p-0">
						<div className="border-gray-200 ">
							<div className="flex flex-row justify-around py-1 my-2 rounded-md bg-lightGray">
								<button
									className={classNames(
										"transition-all duration-300 px-10 text-[12px]",
										{
											"p-2 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
												isCardView,
											"p-2 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
												!isCardView,
										}
									)}
									onClick={() => {
										setIsCardView(true);
										props.resetCardActivityLogList();
										props.resetCurrentPageForCardActivityLogList();
									}}
								>
									Card
								</button>
								<button
									className={classNames(
										"transition-all duration-300 px-10 text-[12px]",
										{
											"py-1.5 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
												!isCardView,
											"py-1.5 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
												isCardView,
										}
									)}
									onClick={() => setIsCardView(false)}
								>
									Table
								</button>
							</div>
						</div>
					</div>
				</div>
				{isCardView ? (
					<ActivityLogCardView isCardView={isCardView} />
				) : (
					<>
						<Table
							isLoading={
								jobToLoadCPActivityLogs.isLoading || jobToLoadCount.isLoading
							}
							isEmpty={
								!jobToLoadCPActivityLogs.isLoading && !activityLogs?.ids?.length
							}
							headers={configPrintActivityLogHeaderList}
						>
							{activityLogs.ids.map((activityLogId, index) => {
								const activityLog = activityLogs.byIds[activityLogId];
								const {
									vesselName,
									action,
									module,
									message,
									version,
									timestamp,
									userName,
									code,
								} = activityLog;

								return (
									<tr key={activityLogId}>
										<Td className="py-2.5">
											{(paginationOfCPActivityLogs.currentPage - 1) *
												CP_ACTIVITY_LOGS_PER_PAGE +
												index +
												1}
										</Td>

										<Td className="py-2.5">{vesselName ? vesselName : "NA"}</Td>
										<Td className="py-2.5">{module ? module : "NA"}</Td>
										<Td className="py-2.5">{code ? code : "NA"}</Td>
										<Td className="py-2.5">{action ?? "NA"}</Td>
										<Td className="py-2.5">{message ?? "NA"}</Td>
										<Td className="py-2.5">{version ?? "NA"}</Td>
										<Td className="py-2.5">{userName ?? "NA"}</Td>
										<Td className="py-2.5">
											{moment(timestamp).fromNow() ?? ""}
										</Td>
									</tr>
								);
							})}
						</Table>

						{activityLogs?.ids?.length > 0 ? (
							<NumberedPagination
								{...{
									...paginationOfCPActivityLogs,
									totalItems: paginationOfCPActivityLogs.totalItems || 0,
								}}
								itemIds={[]}
								onPageChange={function onPageChange(pageNumberFromPagination) {
									props.goToNewPage(pageNumberFromPagination);
								}}
								isPageLoading={
									jobToLoadCPActivityLogs.isLoading || jobToLoadCount.isLoading
								}
							/>
						) : null}
					</>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const activityLogs = toolSelectors.getCPActivityLog(toolStore);
	const jobToLoadCPActivityLogs =
		toolSelectors.jobToLoadCPActivityLogs(toolStore);
	const paginationOfCPActivityLogs =
		toolSelectors.getPaginationOfCPActivityLog(toolStore);

	const jobToLoadCount = toolSelectors.jobToLoadCount(toolStore);

	return {
		activityLogs,
		jobToLoadCPActivityLogs,
		paginationOfCPActivityLogs,
		jobToLoadCount,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadActivityLogForConfigAndPrints(isCardView: boolean) {
			dispatch(toolActions.commands.toolCPActivityLogLoad(isCardView));
		},
		goToNewPage(pageNumber: number) {
			dispatch(
				toolActions.document.toolCPActivityLogPaginationCurrentPageSet(
					pageNumber
				)
			);
		},
		resetCardActivityLogList() {
			dispatch(toolActions.document.toolCPCardActivityLogListReset());
		},
		resetCurrentPageForCardActivityLogList() {
			dispatch(
				toolActions.document.toolCPCardActivityLogPaginationCurrentPageSet(1)
			);
		},
		loadCountForList() {
			dispatch(
				toolActions.commands.toolCountListLoad("activitylog", "cp-activity-log")
			);
		},
		goBack() {
			window?.history?.back();
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ToolCPActivityLog);
