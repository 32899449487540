import React from "react";
import Skeleton from "react-loading-skeleton";

function TableLoader() {
	const numberOfColumns = 8;
	const numberOfRows = 12;
	return (
		<>
			{Array(numberOfRows)
				.fill(0)
				.map((_, index) => {
					return (
						<tr key={index}>
							<td colSpan={numberOfColumns}>
								<Skeleton className="w-full h-[34px]" />
							</td>
						</tr>
					);
				})}
		</>
	);
}

export default TableLoader;
