import moment from "moment";
import { marpolUtils } from "../../marpol";
import { EMARPOLDocuments, TMARPOLTank } from "../../marpol/@types";
import {
	EORB4RecordCode,
	EORB4RecordState,
	TORB4CommonData,
	TORB4CommonFields,
} from "../@types";

export type TORB4J = TORB4J1 | TORB4J2;
export type TORB4J1 = TORB4CommonFields &
	TORB4CommonData & {
		id: string;
		recordCode: EORB4RecordCode.ORB4J1;
		item55: TORB4Item55;
		item57: TORB4Item57;
	};

export type TORB4J2 = TORB4CommonFields &
	TORB4CommonData & {
		id: string;
		recordCode: EORB4RecordCode.ORB4J2;
		item55: TORB4Item55;
		item57: TORB4Item57;
	};

export type TORB4Item55 = {
	slopTanks: TORB4Item55SlopTank[];
};

export type TORB4Item55SlopTank = {
	selectedTank: TMARPOLTank;
	dischargedQuantity: number;
	newRetainedQuantity: number;
};

export type TORB4Item57 =
	| TORB4Item57ReceptionFacilities
	| TORB4Item57MixedWithCargo
	| TORB4Item57TransferredToOrFromTanks
	| TORB4Item57DisposalToSlopBarge
	| TORB4Item57OtherMethod;

export type TORB4Item57ReceptionFacilities = {
	collectedByWhom: string;
	methodOfTransferOrDisposal: EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES;
	placeOfDischarge: string;
	receiptNumber: string;
	receiptAttachment: string;
	totalQuantityDischarged: number;
};

export type TORB4Item57DisposalToSlopBarge = {
	nameOfTheBarge: string;
	methodOfTransferOrDisposal: EORB4Item57MethodOfTransferOrDisposal.DISPOSAL_TO_SLOP_BARGE;
	placeOfDischarge: string;
	receiptNumber: string;
	receiptAttachment: string;
	totalQuantityDischarged: number;
};

export type TORB4Item57MixedWithCargo = {
	methodOfTransferOrDisposal: EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO;
	cargoTanks: TORB4Item57Tank[];
};

export type TORB4Item57TransferredToOrFromTanks = {
	methodOfTransferOrDisposal: EORB4Item57MethodOfTransferOrDisposal.TRANSFERRED_TO_OR_FROM_TANKS;
	tanks: TORB4Item57Tank[];
};

export type TORB4Item57OtherMethod = {
	methodOfTransferOrDisposal: EORB4Item57MethodOfTransferOrDisposal.OTHER_METHOD;
	methodDescription: string;
	totalQuantityDischarged: number;
};

export type TORB4Item57Tank = {
	selectedTank: TMARPOLTank;
	transferQuantity: number;
	newRetainedQuantity: number;
};

export enum EORB4Item57MethodOfTransferOrDisposal {
	RECEPTION_FACILITIES = "RECEPTION_FACILITIES",
	DISPOSAL_TO_SLOP_BARGE = "DISPOSAL_TO_SLOP_BARGE",
	MIXED_WITH_CARGO = "MIXED_WITH_CARGO",
	TRANSFERRED_TO_OR_FROM_TANKS = "TRANSFERRED_TO_OR_FROM_TANKS",
	OTHER_METHOD = "OTHER_METHOD",
}

const ORB4J_DEFAULTS: {
	J1: TORB4J1;
	J2: TORB4J2;
} = {
	J1: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB2,
		state: EORB4RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		recordCode: EORB4RecordCode.ORB4J1,
		item55: {
			slopTanks: [],
		},
		item57: {
			collectedByWhom: "Shore Facilities",
			methodOfTransferOrDisposal:
				EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES,
			placeOfDischarge: "",
			receiptAttachment: "",
			receiptNumber: "",
			totalQuantityDischarged: 0,
		},
	},
	J2: {
		id: "",
		_rev: "",
		marpolType: EMARPOLDocuments.ORB2,
		state: EORB4RecordState.IDLE,
		selectedDate: moment().format("YYYY-MM-DD"),
		remarks: "",
		recordCode: EORB4RecordCode.ORB4J2,
		item55: {
			slopTanks: [],
		},
		item57: {
			collectedByWhom: "Shore Facilities",
			methodOfTransferOrDisposal:
				EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES,
			placeOfDischarge: "",
			receiptAttachment: "",
			receiptNumber: "",
			totalQuantityDischarged: 0,
		},
	},
};

export { ORB4J_DEFAULTS };
