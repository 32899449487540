import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import userActions from "redux/app/user/actions";
import UserForm from "./Form";

type UserEditType = PropsFromRedux & {
	onClose(): void;
	userId: string;
};
function UserEdit(props: UserEditType) {
	const { userId, onClose } = props;
	useEffect(
		function onLoad() {
			props.setFormModeEdit();
			props.loadUserToEdit(userId);
		},
		[userId]
	);

	return (
		<UserForm
			onCancel={() => {
				onClose();
			}}
			onClose={() => {
				onClose();
			}}
			title="Edit user"
		/>
	);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		setFormModeEdit() {
			dispatch(userActions._forms.user.formModeSet("EDIT"));
		},
		loadUserToEdit(userId: string) {
			dispatch(userActions.commands.userLoad(userId));
		},
	};
}
const reduxConnector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(UserEdit);
