import { ActionType } from "typesafe-actions";
import { IListObjectStore, IListObjectStoreById } from "../../_common/type";
import { IForm, IFormStore } from "../../_core/form/types";
import { IJobHOCState } from "../../_core/job/types";
import logActions from "./actions";

export type ILog = {
	_id: string;
	status: "COMPLETE" | "INCOMPLETE";
	data: any;
	vesselId: string;
	selectedDate: string;
	createdAt: string;
	isSubmit: boolean;
	version: string;
	code: ILogType;
	title: string;
	author: string;
	[key: string]: any;
};

export type ITabLog =  {
    _id: {
      selectedDate: string;
      vesselId: string;
      code: ILogType;
    },
    logId: string;
    code: ILogType;
    isApprove: true,
    title: string;
    selectedDate: string;
    reducedDiscrepancies: [],
	version?: string;
    vessel: {
      _id: string;
      callSign: string;
      imoNumber: string;
      vesselName: string;
      vesselPrefix: string;
    },
    discrepancies: number;
  }

export type ILogSelected = {
	log: ILog | ILog[] | undefined;		
	logPrintTemplate: any;
};

// export type ILogFromServer = ILog & {
// 	typeOfLogs: ILogType;
// 	code: ILogType;
// 	selectedDate: string;
// 	createdAt: string;
// 	metaId: string;
// };
export type ILogPrintTemplate = any;
// export type ILogConfig = any;

export type ILogType =
	| ""
	| "engineLogConfig"
	| "deckLogConfig"
	| "gmdssRadioConfig"
	| "portLogConfig"
	| "radarLogConfig"
	| "compassLogConfig"
	| "bridgeOrderConfig"

export type ILogFilters = {
	vesselId: string;
	logType: ILogType;
	submitted: boolean | "";
	date: string;
};
export interface ILogFilterForm extends IForm<ILogFilters> {}

export interface IPagination {
	totalItems?: number;
	itemsPerPage: number;
	currentPage: number;
	pageWiseListIds: {
		[key: number]: string[];
	};
}
export interface IPaginationForView {
	totalItems: number;
	itemsPerPage: number;
	currentPage: number;
	itemIds: string[];
}
export interface ILogStore extends IFormStore, IJobHOCState {
	//logs: IListObjectStoreById<ILog>;
	logs: ITabLog[];
	selectedLog?: ILogSelected;
	//totalLogsCount: number;
	_forms: {
		FILTER: ILogFilterForm;
		SEAL_LOGS_FILTER: ISealLogsFilterForm;
	};
	_jobs: {};
	_pagination: IPagination;

	reportsPagination: IPagination;
	reports: ITabReport[];
	selectedReport?: IReportSelected;
	seallogs: ISealLog[];
	seallogsPrint: ISealLog[];
	seallogsPagination: IPagination;
}

export interface ISealLogsFilters {
	vesselId: string;
	startDate: string;
	endDate: string;
}

export interface ISealLogsFilterForm extends IForm<ISealLogsFilters> {}

export interface ISealLog {
    _id: string;
    id: string;
    sealNumber: string;
    location: string;
    state: ESealState;
    dateReceived: string;
    reason: string;
    createdAt: string;
    createdBy: string;
    createdProfile: string;
    updatedAt: string;
    updatedBy: string;
    updatedProfile: string;
    approvedAt: string;
    approvedBy: string;
    approvedProfile: string;
    isApproved?: boolean;
    vesselId: string;
    tenantId: string;
    vesselName: string;

    history: ISealLogHistory[];

    dateOfStateChange: string;
    quantity: string;
    batchNumber: string;
    startingSealNumber: string;

    // Additional fields
    isAddMultipleSealLogs?: boolean;
    attachment?: any;
    sealType?: ESealLogType;
    _attachments: {
        [fileName: string]: {
            content_type: string;
            revpos: number;
            digest: string;
            length: number;
            stub: boolean;
        };
    };
}

export interface ISealLogHistory {
    createdAt: string;
    recordedBy: string;
    recordedProfile: {
        id: string;
        name: string;
        rank: number;
        designation: string;
    };
    dateModified: string;
    location: string;
    reason: string;
    state: ESealState;
    previousState: ESealState;
}

export enum ESealState {
    ALL = 'ALL',
    AVAILABLE = 'AVAILABLE',
    CURRENTLY_IN_USE = 'IN_USE',
    REMOVED = 'REMOVED',
    LOST = 'LOST',
    PENDING_LOGS = 'PENDING_LOGS',
}

export enum ESealLogType {
    ALL = 'ALL',
    ENVIRONMENT = 'ENVIRONMENT',
    SECURITY = 'SECURITY',
}

export interface ISeallogsResponseObj {
	seallogs: ISealLog[];
	count: {
		total_docs: number;
	}
}

export type IReportSelected = {
	report?: IReport;		
	reportPrintTemplate: any;
};

export interface ITabLogListResponseObj {
	results: ITabLog[];
	count: {
		total_docs: number;
	}
}

export interface ILoadDayLogsParams {
	vesselId: string;
	tenantId: string;
	selectedDate: string;
}

export type ILogActions = ActionType<typeof logActions>;
const { commands, document } = logActions;
export type ILogActions_Document = ActionType<typeof document>;
export type ILogActions_Command = ActionType<typeof commands>;

export type ITabReport = {
	_id: string;
	createdAt: string;
	selectedDate: string;
	intlDateLineRecord: boolean;
	doc_status: string;
	needsToBeProcessed: boolean;
	timeline: any[];
	imosApiStatus?: string;
	signalApiStatus?: string;
	signalApiLastProcessedDate?: string;
	imosApiLastProcessedDate?: string;
	vesselId: string;
};

export interface IReport {
	_id: string;
	_rev: string;
	modelName: "signalNoonReport";
	selectedDate: string;
	intlDateLineRecord: false;
	vesselId: string;
	tenantId: string;
	vesselName: string;
	vesselImo: string;
	doc_status: string;
	createdAt: string;
	updatedAt: string;
	createdBy: string;
	createdProfile: string;
	timeline: any[];
}

export type IReportsFilters = {
	vesselId: string;
	checklistType: string;
	submitted: boolean | "";
	date: string;
};

export interface ITabReportResponseObj {
	results: ITabReport[];
	count: {
		total_docs: number;
	};
}

export enum EReportType {
	SIGNAL = "SIGNAL",
	IMOS = "IMOS",
}