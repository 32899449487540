import SVGDoc from "components/_common/icons/SVGDoc";
import SVGExcel from "components/_common/icons/SVGExcel";
import SVGPowerpoint from "components/_common/icons/SVGPowerpoint";
import React, { useEffect, useState } from "react";

export const openableContentTypes = ["application/pdf", "image/png"];

export const contentTypeCategorizerLookup = {
	"application/msword": "doc",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
		"doc",
	"application/vnd.ms-excel": "xls",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xls",
	"application/vnd.ms-powerpoint": "ppt",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation":
		"ppt",
};

export const ContentImageRenderer = {
	doc: <SVGDoc />,
	xls: <SVGExcel />,
	ppt: <SVGPowerpoint />,
};

const useCheckMobileScreen = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	return width <= 768;
};

export default useCheckMobileScreen;
