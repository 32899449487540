import { IAuthStore } from "./types";
import withForms, { formUtils } from "../../_core/form";
import withJobs from "../../_core/job/reducers";
import actions, { IAuthActions } from "./actions";
import { createReducer } from "typesafe-actions";

const reducerState: IAuthStore = {
	authToken: null,
	authorisedModules: ["dashboard", "vessel", "reports", "insights", "users"],
	user: null,
	pathPriorToLogin: "",
	_jobs: {},
	_forms: {
		LOGIN: {
			...formUtils.getDefaults({
				fields: {
					email: "",
					password: "",
				},
				mode: "NONE",
			}),
		},
		RESET_REQUEST: {
			...formUtils.getDefaults({
				fields: {
					email: "",
				},
				mode: "NONE",
			}),
		},
		RESET_PASSWORD: {
			...formUtils.getDefaults({
				fields: {
					confirmPassword: "",
					password: "",
					accessToken: undefined,
				},
				mode: "NONE",
			}),
		},
	},
	tenantInfo: null,
	isPassFieldVisible: "NO"
};

const authReducer = createReducer<IAuthStore, IAuthActions>(reducerState)
	.handleAction(actions.userSet, function userSet(state, action) {
		return { ...state, user: action.payload.user };
	})
	.handleAction(actions.tokenSet, function tokenSet(state, action) {
		return {
			...state,
			authToken: action.payload.token,
		};
	})
	.handleAction(actions.authReset, function authReset() {
		return reducerState;
	})
	.handleAction(
		actions.pathPriorToLoginSet,
		function pathPriorToLoginSet(state, action) {
			return { ...state, pathPriorToLogin: action.payload.from };
		}
	)
	.handleAction(actions.tenantInfoSet, function tenantInfoSet(state, action) {
		const { tenantInfo } = action.payload;
		return { ...state, tenantInfo };
	})
	.handleAction(
		actions.authorisedModulesSet,
		function authorisedModulesSet(state, action) {
			const { authorisedModules } = action.payload;
			return { ...state, authorisedModules };
		}
	)
	.handleAction(
		actions.setPasswordFiledVisible,
		function setPasswordFiledVisible(state, action) {
			return {
				...state,
				isPassFieldVisible: "YES"
			}
		}
	);

export default withJobs<IAuthStore>(
	"@auth",
	reducerState,
	withForms("@auth", reducerState, authReducer)
);
