import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import wayshipLog512 from "./../../../assets/images/logo-512x512.png";
import classNames from "classnames";
import { authActions } from "redux/app/auth";
import HamburgerMenu from "./HamburgerMenu";

type OwnProps = {};
type NavbarMobileProps = PropsFromRedux & OwnProps;
function NavbarMobile(props: NavbarMobileProps) {
	const [isNavOpen, setIsNavOpen] = useState(false);
	return (
		<>
			<div className="bg-wayshipBlue w-full p-4 py-2.5">
				<div className="flex justify-between items-center">
					<img src={wayshipLog512} alt="" className="h-6 my-1" />
					<nav>
						<div
							className="cursor-pointer space-y-2"
							onClick={() => setIsNavOpen((prev) => !prev)}
						>
							<span className="block h-0.5 w-7 animate-pulse bg-white"></span>
							<span className="block h-0.5 w-7 animate-pulse bg-white"></span>
							<span className="block h-0.5 w-7 animate-pulse bg-white"></span>
						</div>
						<div
							className={classNames({
								" absolute w-full h-screen  top-0 left-0 bg-wayshipBlue z-50 flex flex-col justify-evenly items-center":
									isNavOpen,
								hidden: !isNavOpen,
							})}
						>
							<div
								className="absolute top-0 right-0 px-4 py-4 cursor-pointer"
								onClick={() => setIsNavOpen(false)}
							>
								<svg
									className="h-8 w-8 text-gray-600"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<line x1="18" y1="6" x2="6" y2="18" />
									<line x1="6" y1="6" x2="18" y2="18" />
								</svg>
							</div>

							<HamburgerMenu
								// @ts-ignore
								handleMobileNavbarClick={() => {
									setIsNavOpen(false);
								}}
							/>
							<div className="logout">
								<div
									className="flex flex-row flex-no-wrap items-center justify-center w-full"
									onClick={() => props.logout()}
								>
									<div className="text-sm flex items-center font-thin text-white  cursor-pointer  hover:underline hover:text-blue-400 font-redhat-text">
										<span className="bp3-icon bp3-icon-log-out text-sm  w-6 inline-block" />
										<span>Log out</span>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		logout() {
			dispatch(authActions.logout());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NavbarMobile);
