const vesselConstants = {
	feature: "@vessel",
	forms: {},
};

export enum TAB_IDS {
	myVessels =  "my-vessels",
	all = "all"
}

export default vesselConstants;
