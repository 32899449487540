import React from "react";
type OwnProps = { className?: string; fillColor?: any; pathColor?: any };
type SVGUsersProps = OwnProps;
function SVGUsers(props: SVGUsersProps) {
	return (
		<>
			{props.className?.includes("bg-blue-100") ? (
				<svg
					width="33"
					height="21"
					viewBox="0 0 33 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={props.className}
				>
					<path
						d="M16.372 0.857666C17.5238 0.857666 18.6285 1.3287 19.443 2.16713C20.2575 3.00557 20.715 4.14274 20.715 5.32847C20.715 6.5142 20.2575 7.65137 19.443 8.4898C18.6285 9.32824 17.5238 9.79927 16.372 9.79927C15.2201 9.79927 14.1154 9.32824 13.3009 8.4898C12.4865 7.65137 12.0289 6.5142 12.0289 5.32847C12.0289 4.14274 12.4865 3.00557 13.3009 2.16713C14.1154 1.3287 15.2201 0.857666 16.372 0.857666ZM7.68583 4.0511C8.38072 4.0511 9.02597 4.2427 9.58437 4.58759C9.39823 6.41424 9.9194 8.2281 10.9866 9.64599C10.3661 10.8723 9.12524 11.7153 7.68583 11.7153C6.69852 11.7153 5.75166 11.3116 5.05353 10.5929C4.3554 9.87427 3.9632 8.89955 3.9632 7.88321C3.9632 6.86687 4.3554 5.89216 5.05353 5.1735C5.75166 4.45484 6.69852 4.0511 7.68583 4.0511ZM25.0581 4.0511C26.0454 4.0511 26.9923 4.45484 27.6904 5.1735C28.3885 5.89216 28.7807 6.86687 28.7807 7.88321C28.7807 8.89955 28.3885 9.87427 27.6904 10.5929C26.9923 11.3116 26.0454 11.7153 25.0581 11.7153C23.6187 11.7153 22.3778 10.8723 21.7574 9.64599C22.8392 8.2082 23.3414 6.3966 23.1595 4.58759C23.7179 4.2427 24.3632 4.0511 25.0581 4.0511ZM8.30626 17.1442C8.30626 14.5 11.9172 12.354 16.372 12.354C20.8267 12.354 24.4377 14.5 24.4377 17.1442V19.3796H8.30626V17.1442ZM1.48145 19.3796V17.4635C1.48145 15.688 3.8267 14.1934 7.00334 13.7591C6.27123 14.6277 5.82451 15.8285 5.82451 17.1442V19.3796H1.48145ZM31.2625 19.3796H26.9194V17.1442C26.9194 15.8285 26.4727 14.6277 25.7406 13.7591C28.9172 14.1934 31.2625 15.688 31.2625 17.4635V19.3796Z"
						stroke={"#0B2F56"}
						fill={"white"}
						strokeWidth="1.5"
					/>
				</svg>
			) : (
				<svg
					width="34"
					height="35"
					viewBox="0 0 34 35"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={props.className}
				>
					<path
						d="M17.372 10.8577C18.5238 10.8577 19.6285 11.3287 20.443 12.1671C21.2575 13.0056 21.715 14.1427 21.715 15.3285C21.715 16.5142 21.2575 17.6514 20.443 18.4898C19.6285 19.3282 18.5238 19.7993 17.372 19.7993C16.2201 19.7993 15.1154 19.3282 14.3009 18.4898C13.4865 17.6514 13.0289 16.5142 13.0289 15.3285C13.0289 14.1427 13.4865 13.0056 14.3009 12.1671C15.1154 11.3287 16.2201 10.8577 17.372 10.8577ZM8.68583 14.0511C9.38072 14.0511 10.026 14.2427 10.5844 14.5876C10.3982 16.4142 10.9194 18.2281 11.9866 19.646C11.3661 20.8723 10.1252 21.7153 8.68583 21.7153C7.69852 21.7153 6.75166 21.3116 6.05353 20.5929C5.3554 19.8743 4.9632 18.8996 4.9632 17.8832C4.9632 16.8669 5.3554 15.8922 6.05353 15.1735C6.75166 14.4548 7.69852 14.0511 8.68583 14.0511ZM26.0581 14.0511C27.0454 14.0511 27.9923 14.4548 28.6904 15.1735C29.3885 15.8922 29.7807 16.8669 29.7807 17.8832C29.7807 18.8996 29.3885 19.8743 28.6904 20.5929C27.9923 21.3116 27.0454 21.7153 26.0581 21.7153C24.6187 21.7153 23.3778 20.8723 22.7574 19.646C23.8392 18.2082 24.3414 16.3966 24.1595 14.5876C24.7179 14.2427 25.3632 14.0511 26.0581 14.0511ZM9.30626 27.1442C9.30626 24.5 12.9172 22.354 17.372 22.354C21.8267 22.354 25.4377 24.5 25.4377 27.1442V29.3796H9.30626V27.1442ZM2.48145 29.3796V27.4635C2.48145 25.688 4.8267 24.1934 8.00334 23.7591C7.27123 24.6277 6.82451 25.8285 6.82451 27.1442V29.3796H2.48145ZM32.2625 29.3796H27.9194V27.1442C27.9194 25.8285 27.4727 24.6277 26.7406 23.7591C29.9172 24.1934 32.2625 25.688 32.2625 27.4635V29.3796Z"
						stroke="white"
						strokeWidth="1.5"
					/>
				</svg>
			)}
		</>
	);
}

export default SVGUsers;
