import classNames from "classnames";
import moment from "moment";
import React from "react";
import { EBallastOperationCodes, TBallastRecord } from "../../../../../redux/app/ballast/@types";
import { TBallast_Additional, TBallastAdditionalTank } from "../../../../../redux/app/ballast/groups/additional";
import { commonUtils } from "../../../../../redux/_common";
import CommaSeparator from "../../../../_common/CommaSeparator";
import BallastTableRows from "../BallastTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type AdditionalOperationPreviewProps = OwnProps;
function AdditionalOperationPreview(props: AdditionalOperationPreviewProps) {
	const record = props.ballastRecord as TBallast_Additional;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<BallastTableRows
				record={record}
				tableRows={[
					[operationDate, "3.6", renderItem3P6()],
					[null, "--", renderItem__()],
					[null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem3P6() {
		return <h4 className="font-medium uppercase">{getPlaceContent(record)}</h4>;
	}

	function renderItem__() {
		if (
			record.operationCode !== EBallastOperationCodes.ADDITIONAL_PROCEDURES ||
			record.isIntendToUpdateTanks === false
		)
			return null;
		return (
			<>
				<h4 className="font-medium uppercase">
					Started Operation: {`${record.startDate} | ${record.startTime} (LT),`}{" "}
					Completed Operation: {`${record.endDate} | ${record.endTime} (LT)`}{" "}
				</h4>
				<h4 className="font-medium uppercase">
					{getParticipantTanksInformation(record)}
				</h4>
			</>
		);
	}

	function getPlaceContent(record: TBallast_Additional) {
		if (
			record.operationCode === EBallastOperationCodes.ADDITIONAL_PROCEDURES &&
			record.isIntendToUpdateTanks
		) {
			let { placeOfBallast, remarks } = record;
			if (placeOfBallast.place === "IN_PORT") {
				return `${remarks} in ${placeOfBallast.portName}`;
			} else if (placeOfBallast.place === "AT_SEA") {
				const { startPosition, endPosition } = placeOfBallast;
				return `${remarks} at ${startPosition.latitude},${startPosition.longitude} - ${endPosition.latitude},${endPosition.longitude}`;
			}
		} else {
			return record.remarks;
		}
	}

	function getParticipantTanksInformation(record: TBallast_Additional) {
		if (
			record.operationCode === EBallastOperationCodes.ADDITIONAL_PROCEDURES &&
			record.isIntendToUpdateTanks
		) {
			let tankList = "";
			record.tanks.forEach((tank: TBallastAdditionalTank) => {
				let { selectedTank, newRetainedQuantity } = tank;
				let { tankCode, observedQuantity, expectedQuantity } = selectedTank;
				if (newRetainedQuantity) {
					tankList += ` ${tankCode}-Initial Quantity: ${expectedQuantity} M³ | Final Quantity: ${newRetainedQuantity} M³`;
				} else {
					tankList += ` ${tankCode}-Initial Quantity: ${observedQuantity.preOperation} M³ | Final Quantity: ${observedQuantity.postOperation} M³`;
				}
			});
			return tankList;
		}
	}
	// return (
	//     <>
	//         {record.operationCode ===
	//             EBallastOperationCodes.ADDITIONAL_PROCEDURES &&
	//         record.isIntendToUpdateTanks === true ? (
	//             <>
	//                 <tr
	//                     className={classNames('uppercase', {
	//                         'line-through': props.isStrikedOff,
	//                     })}
	//                 >
	//                     <td className="relative">
	//                         <div
	//                             className={classNames(
	//                                 { 'line-through': props.isStrikedOff },
	//                                 'p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix'
	//                             )}
	//                         >
	//                             <div className="h-full">
	//                                 <span className="block font-medium whitespace-nowrap"></span>
	//                             </div>
	//                         </div>
	//                     </td>
	//                     <td className="relative">
	//                         <div
	//                             className={classNames(
	//                                 { 'line-through': props.isStrikedOff },
	//                                 'p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix'
	//                             )}
	//                         >
	//                             <div className="h-full font-medium">--</div>
	//                         </div>
	//                     </td>
	//                     <td className="relative">
	//                         <div className="p-3 m-px text-sm font-normal leading-7 text-left text-gray-800 bg-white">
	//                             <h4 className="font-medium uppercase">
	//                                 Started Operation:{' '}
	//                                 {`${record.startDate} | ${record.startTime} (LT),`}{' '}
	//                                 Completed Operation:{' '}
	//                                 {`${record.endDate} | ${record.endTime} (LT)`}{' '}
	//                             </h4>
	//                             <h4 className="font-medium uppercase">
	//                                 {getParticipantTanksInformation(record)}
	//                             </h4>
	//                         </div>
	//                     </td>
	//                 </tr>
	//             </>
	//         ) : null}
	//     </>
	// );

	return renderMain();
}

export default AdditionalOperationPreview;
