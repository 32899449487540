import Axios from "axios";
import { authActions } from "../redux/app/auth";
import store from "../redux/store";
import { historyActions } from "../redux/_core/history";
import { jobActions } from "../redux/_core/job";
import { rootRoutes } from "../routes";

export default () =>
	Axios.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error && error.response && error.response.status === 401) {
				if (
					error.response.data &&
					error.response.data.error.code === "AUTHORIZATION_REQUIRED"
				) {
					store.dispatch(authActions.authReset());
					store.dispatch(
						historyActions.replace(
							"$axios-config",
							rootRoutes.children.auth.children.login.url()
						)
					);
				}
			}
			return Promise.reject(error);
		}
	);
