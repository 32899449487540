import { action, getType } from "typesafe-actions";
import { IAPIRequestMetaData } from "../../_core/api/types";
import { ILoadDayLogsParams, ILogFilters, ILogType, IReportsFilters, ISealLogsFilters, ISeallogsResponseObj, ITabLog, ITabLogListResponseObj, ITabReport, ITabReportResponseObj } from "./@types";
import logActions from "./actions";
import { commonUtils } from "redux/_common";

const logAPIs = {
	loadSeallogsCount(filters: ISealLogsFilters & {
		userId: string;
		token: string;
	}): IAPIRequestMetaData<{ count: number }> {
		const { vesselId, userId, token, startDate, endDate } = filters;
		return {
			feature: getType(logActions.commands.reportsCountLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/count`,
			queryParams: {
				modelName: "sealLogs",
				filter: {
					selector: {
						state: {
							$ne: "AVAILABLE",
						},
						vesselId,
						$and: [
							{
								createdAt: {
									$gte: startDate,
								},
							},
							{
								createdAt: {
									$lte: endDate,
								},
							},
						],
					},
				},
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadSeallogsPrint(
		filters: ISealLogsFilters & { tenantId?: string } & {
			token: string;
			userId: string;
		},
	): IAPIRequestMetaData<ISeallogsResponseObj[]> {
		const { token, userId, startDate, endDate } = filters;
		return {
			feature: getType(logActions.commands.seallogsLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/seallogs`,
			queryParams: {
				vesselId: filters.vesselId,
				limit: 10000,
				skip: 0,
				selector: {
					$and: [
						{
							createdAt: {
								$gte: startDate,
							},
						},
						{
							createdAt: {
								$lte: endDate,
							},
						},
					],
				},
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadSeallogs(
		filters: ISealLogsFilters & { tenantId?: string } & {
			token: string;
			userId: string;
		},
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<ISeallogsResponseObj[]> {
		const { token, userId, startDate, endDate } = filters;
		return {
			feature: getType(logActions.commands.seallogsLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/seallogs`,
			queryParams: {
				vesselId: filters.vesselId,
				limit: itemsPerPage,
				skip: (pageNumber - 1) * itemsPerPage,
				selector: {
					$and: [
						{
							createdAt: {
								$gte: startDate,
							},
						},
						{
							createdAt: {
								$lte: endDate,
							},
						},
					],
				},
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadReportsCount(params: {
		vesselId: string;
		userId: string;
		token: string;
	}): IAPIRequestMetaData<{ count: number }> {
		const { vesselId, userId, token } = params;
		return {
			feature: getType(logActions.commands.reportsCountLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/count`,
			queryParams: {
				modelName: "signalNoonReport",
				filter: {
					selector: {
						vesselId,
					},
				},
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadSingleReport(
		checklist: ITabReport,
		extra: {
			vesselId: string;
			userId: string;
			token: string;
		}
	): IAPIRequestMetaData<any> {
		const { vesselId, userId, token } = extra;
		return {
			feature: getType(logActions.commands.singleReportLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/signalreports`,
			queryParams: {
				vesselId: vesselId,
				id: checklist._id,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	retryReport(
		reportType: string,
		docId: string
		// userId: string,
		// token: string
	): IAPIRequestMetaData<any> {
		return {
			feature: getType(logActions.commands.retryReport),
			method: "PATCH",
			url: `${commonUtils.getAPIBaseUrl()}/api/signalreports`,
			body: {
				id: docId,
				type: reportType,
			},
			queryParams: {
				action: "retry",
			},
			// headers: {
			// 	// @ts-ignore
			// 	"x-token": token,
			// 	"x-user-id": userId,
			// },
		};
	},
	loadReports(
		filters: IReportsFilters & { tenantId?: string } & {
			token: string;
			userId: string;
		},
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<ITabReportResponseObj[]> {
		const { token, userId } = filters;
		return {
			feature: getType(logActions.commands.reportsLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/signalreports`,
			queryParams: {
				vesselId: filters.vesselId,
				limit: itemsPerPage,
				skip: (pageNumber - 1) * itemsPerPage,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadReportPrintTemplate(
		vesselId: string,
		userId: string,
		token: string,
		recordConfigVersion?: string
	): IAPIRequestMetaData<any> {
		return {
			feature: getType(logActions.commands.reportPrintTemplateLoad),
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/printTemplates`,
			queryParams: {
				vesselId,
				code: "signalReportConfig",
				recordConfigVersion,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadLogList(
		filters: ILogFilters & { tenantId?: string },
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<ITabLogListResponseObj[]> {
		return {
			url: `https://analytics.wayship.io/api/v1/logs/${filters.tenantId}`,
			feature: getType(logActions.commands.logListLoad),
			method: "GET",
			body: {},
			queryParams: {
				limit: itemsPerPage,
				skip: (pageNumber - 1) * itemsPerPage,
				vesselId:
					filters.vesselId === "" ? undefined : filters.vesselId,
				code: filters.logType,
			},
		};
	},
	loadTotalItemsForFilter(filter: ILogFilters) {
		// TODO: Add logic here
	},
	loadLog(log: ITabLog): IAPIRequestMetaData<any> {
		const { _id } = log;
		const { vesselId, code, selectedDate } = _id;
		const actionType = getType(logActions.commands.logLoad);
		return {
			url: "/api/logs/all",
			method: "GET",
			feature: actionType,
			body: {},
			queryParams: {
				selectedDate,
				code,
				vesselId,
			},
		};
	},
	loadLogV2(
		log: ITabLog,
		userId: string,
		token: string
	): IAPIRequestMetaData<any> {
		const { _id } = log;
		const { vesselId, code, selectedDate } = _id;
		const actionType = getType(logActions.commands.logLoad);
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/logs`,
			method: "GET",
			feature: actionType,
			queryParams: {
				selectedDate,
				code,
				vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadDayLogs({
		vesselId,
		tenantId,
		selectedDate,
	}: ILoadDayLogsParams): IAPIRequestMetaData<any> {
		const actionType = getType(logActions.commands.dayLogLoad);
		return {
			url: "/api/dayLogs",
			method: "GET",
			feature: actionType,
			body: {},
			queryParams: {
				vesselId,
				tenantId,
				selectedDate,
			},
		};
	},
	loadPrintTemplate(
		logType: ILogType | string,
		vesselId: string,
		code?: string
	): IAPIRequestMetaData<any> {
		const actionType = getType(logActions.commands.logPrintTemplateLoad);
		return {
			url: "/api/printTemplates/findOne",
			feature: actionType,
			method: "GET",
			queryParams: {
				filter: {
					where: {
						code: logType,
						vesselId,
					},
				},
			},
		};
	},
};

export default logAPIs;