import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	Bar,
	LabelList,
} from "recharts";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { IStore } from "redux/store";
import {
	isORSDataEmpty,
	transformORSRawData,
	transformVesselSpecificORSRawData,
} from "redux/app/report/utils";
import CustomTooltip from "./CustomTooltip";

const dischargeMethods = [
	{
		name: "Incinerated",
		key: "incinerated",
		stackId: "a",
		color: "#f4a461",
	},
	{
		name: "Evaporated",
		key: "evaporated",
		stackId: "a",
		color: "#add8e6",
	},
	{
		name: "Boiler",
		key: "boiler",
		stackId: "a",
		color: "#f4a4bf",
	},
	{
		name: "Transfer to slop",
		key: "transferToSlop",
		stackId: "a",
		color: "#05a3e0",
	},
	{
		name: "Disposed Ashore",
		key: "disposedAshore",
		stackId: "a",
		color: "#012f85",
	},
];

const xAxisScale = "Quarter";
const yAxisScale = "m³";

type OwnProps = {
	reportContainerWidth: number;
};
type GraphProps = PropsFromRedux & OwnProps;
function Graph(props: GraphProps) {
	const {
		reportContainerWidth,
		vesselId,
		year,
		rawOrsData,
		vesselSpecificRawOrsData,
		jobToLoadAllVesselsOrsAggregate,
		jobToLoadVesselSpecificOrsAggregate,
	} = props;

	const allVesselfinalData = useMemo(() => {
		return vesselId ? [] : transformORSRawData(rawOrsData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rawOrsData, year, vesselId]);

	const vesselSpecificFinalData = useMemo(() => {
		return vesselId
			? transformVesselSpecificORSRawData(vesselSpecificRawOrsData)
			: [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselSpecificRawOrsData, vesselId, year]);

	const finalData = vesselId ? vesselSpecificFinalData : allVesselfinalData;

	if (
		(!vesselId && jobToLoadAllVesselsOrsAggregate.isLoading) ||
		(vesselId && jobToLoadVesselSpecificOrsAggregate.isLoading)
	) {
		return <div className="flex justify-center mt-20">Loading...</div>;
	}

	if (isORSDataEmpty(finalData)) {
		return (
			<div className="flex justify-center items-center mt-20">
				No data found{vesselId ? " for this vessel" : ""}.{" "}
				{vesselId
					? "Choose another vessel or change the year."
					: "Please select a vessel or change the year."}
			</div>
		);
	}

	const bars = [...dischargeMethods].reverse();

	return (
		<div className="flex justify-center m-2">
			<div className="p-4 py-3 px-6 shadow-md max-w-4xl rounded-md border border-gray-300">
				<h3 className="font-medium text-center py-1.5">
					Oily residue and sludge (m³)
				</h3>
				<div className="flex flex-wrap p-4 pb-1.5">
					{dischargeMethods.map((category) => {
						const { name, color } = category;
						return (
							<div
								key={name}
								className="flex  md:min-w-[180px] lg:min-w-[150px] xl:min-w-[170px] basis-1/3  lg:text-[12px] xl:text-sm space-x-1.5 py-0.5 items-center pl-1.5"
							>
								<span
									style={{ background: color }}
									className="w-4 h-4 rounded-full"
								/>
								<div>
									<span>{name}</span>
								</div>
							</div>
						);
					})}
				</div>
				<div className="axis flex justify-end text-[#7E7E7E] mt-4">
					<p className="m-0 text-[12px] font-medium px-3.5 border-r-2 border-[#7E7E7E]">
						X-axis - {xAxisScale}
					</p>
					<p className="m-0 text-[12px] font-medium px-3.5">
						Y-axis - {yAxisScale}
					</p>
				</div>
				<div className="flex justify-center">
					<ResponsiveContainer
						width={
							reportContainerWidth > 1000
								? reportContainerWidth / 2
								: 0.75 * reportContainerWidth
						}
						height={375}
					>
						<BarChart
							width={reportContainerWidth}
							data={finalData}
							margin={{
								top: 25,
								right: 10,
								left: -20,
								bottom: 5,
							}}
							barGap={0}
						>
							<CartesianGrid vertical={false} fill="" />
							<XAxis tickLine={false} dataKey="quarter" fontSize={14} />
							<YAxis tickLine={false} fontSize={14} />
							<Tooltip content={<CustomTooltip legends={dischargeMethods} />} />
							{bars.map((discharge, index) => {
								const { color, key, stackId, name } = discharge;

								return (
									<Bar
										barSize={35}
										key={name}
										dataKey={key}
										stackId={stackId}
										fill={color}
									>
										{index === dischargeMethods.length - 1 && (
											<LabelList
												className="text-[12px]"
												dataKey={"total"}
												position="top"
											/>
										)}
									</Bar>
								);
							})}
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getOrsVesselId(reportStore);
	const year = reportSelectors.getOrsYear(reportStore);
	const rawOrsData = reportSelectors.getRawOrsData(reportStore);
	const vesselSpecificRawOrsData =
		reportSelectors.getRawVesselSpecificOrsData(reportStore);

	const jobToLoadAllVesselsOrsAggregate =
		reportSelectors.jobToLoadAllVesselsOrsAggregate(reportStore);
	const jobToLoadVesselSpecificOrsAggregate =
		reportSelectors.jobToLoadVesselSpecificOrsAggregate(reportStore);

	return {
		vesselId,
		year,
		rawOrsData,
		vesselSpecificRawOrsData,
		jobToLoadAllVesselsOrsAggregate,
		jobToLoadVesselSpecificOrsAggregate,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Graph);
