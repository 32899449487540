import React from "react";
import VesselList from "../../../../components/app/checklist/list/List";
type ChecklistListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;
function ChecklistList(props: ChecklistListProps) {
	return <VesselList />;
}

export default ChecklistList;
