import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Select from "react-select";
import classNames from "classnames";
import insightSelectors from "redux/app/insight/selectors";
import { EParameterTabType, IDropdownOption } from "redux/app/insight/@types";
import insightActions from "redux/app/insight/actions";
import { vesselSelectors } from "redux/app/vessel";
import { controlStyles } from "redux/app/feedback/utils";
import clsx from "clsx";
import EngineParameter from "./engine/_elements/EngineParameter";
import NoonReportParameter from "./noon/_elements/NoonReportParameter";

type OwnProps = {};
type ParameterProps = PropsFromRedux & OwnProps;
function Parameter(props: ParameterProps) {
	const {
		showParameterMetricList,
		currentTab,
		miniVessels,
		searchText,
		jobToLoadConfig,
		stateOfShip,
		vesselId,
		stateOfShipDropdownOptions,
		stateOfShipError,
	} = props;

	useEffect(() => {
		props.setStateOfShip("");
		props.setStateOfShipDropdownOptions([]);
		props.updateEngineSelectedMetrics([]);
		props.updateNoonReportSelectedMetrics([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		props.toggleParameterMetricsList(true);
		// Assuming that on change of vesselId the dropdown options won't change.
		// props.setStateOfShip("");
		// props.setStateOfShipDropdownOptions([]);
		// props.updateEngineSelectedMetrics([]);
		// props.updateNoonReportSelectedMetrics([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId]);

	useEffect(() => {
		if (jobToLoadConfig.isError) {
			props.setStateOfShipDropdownOptions([]);
			props.setStateOfShip("");
			// props.updateEngineSelectedMetrics([]);
			// props.updateNoonReportSelectedMetrics([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobToLoadConfig.isError]);

	useEffect(() => {
		props.setSearchText("");
		// props.setStateOfShip("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	// To close the parameter list
	useEffect(() => {
		function closeParameterList(e: KeyboardEvent) {
			if (e.key === "Escape") props.toggleParameterMetricsList(false);
		}
		window.addEventListener("keydown", closeParameterList);
		return () => {
			window.removeEventListener("keydown", closeParameterList);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex border-b-2 py-2 pb-0 justify-between items-center px-4 pr-2 h-12">
				<h4 className="font-medium xl:text-base text-[12px]">Parameters</h4>
				<div>
					<Select
						isSearchable
						placeholder="Select Vessel"
						unstyled
						value={undefined}
						options={[
							...miniVessels.ids.map((id) => {
								return {
									...miniVessels.byIds[id],
									label: miniVessels.byIds[id].vesselName,
									value: miniVessels.byIds[id].id,
								};
							}),
						]}
						className="text-[12px] xl:text-sm lg:min-w-32 xl:min-w-[150px]"
						onChange={(newValue) => {
							props.setVesselId(newValue?.value ?? "");
						}}
						classNames={{
							option: ({ isFocused, isSelected }) =>
								classNames(
									isFocused && "hover:cursor-pointer",
									isSelected && "font-medium rounded bg-gray-200",
									"p-0.5 py-1.5 hover:bg-gray-200 rounded"
								),
							dropdownIndicator: () =>
								"p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black",
							menu: () =>
								"p-[3px] -mt-1 border border-gray-200 bg-white rounded-md",
							singleValue: () => "",
							placeholder: () => "text-gray-500 pl-1 py-0.5",
							valueContainer: () => "mt-0.5",
						}}
					/>
				</div>
			</div>
			<div
				className={classNames({
					shadow: showParameterMetricList,
				})}
			>
				<div className="p-4">
					<div
						className={classNames({
							"mb-4": !stateOfShipError,
							"mb-1": stateOfShipError,
						})}
					>
						{currentTab === EParameterTabType.ENGINE_LOG_TAB ? (
							<>
								<Select
									id="state-of-ship"
									key={"state-of-ship" + stateOfShip}
									isSearchable
									placeholder="Select State of ship"
									unstyled
									value={
										!stateOfShip
											? undefined
											: { label: stateOfShip, value: stateOfShip }
									}
									options={stateOfShipDropdownOptions}
									className="text-[12px] w-full xl:text-sm"
									onChange={(newValue) => {
										props.setStateOfShipError(false);
										props.setStateOfShip(newValue?.value ?? "");
									}}
									classNames={{
										option: ({ isFocused, isSelected }) =>
											classNames(
												isFocused && "hover:cursor-pointer",
												isSelected && "font-medium rounded bg-gray-200",
												"p-2 py-1.5 hover:bg-gray-200"
											),
										dropdownIndicator: () =>
											"p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black",
										menu: () =>
											"p-[3px] -mt-1 border border-gray-200 bg-white rounded-md",
										singleValue: () => "",
										placeholder: () => "text-gray-400 pl-0.5 py-0.5",
										valueContainer: () => "mt-0.5 px-2",
										control: ({ isDisabled }) =>
											clsx(
												isDisabled
													? `${controlStyles.disabled} rounded-md ${
															stateOfShipError && "border-red-300"
													  }`
													: stateOfShipError
													? "border rounded-md border-red-300 focus:border-red-500"
													: "border rounded-md border-gray-300 focus:border-gray-500"
											),
									}}
								/>
								{stateOfShipError ? (
									<div className="text-red-600 text-xs">
										Select state of ship option
									</div>
								) : null}
							</>
						) : (
							<Select
								id="state-of-ship-noon"
								isDisabled={true}
								placeholder="Select State of ship"
								unstyled
								value={undefined}
								className="text-[12px] w-full xl:text-sm"
								classNames={{
									dropdownIndicator: () =>
										"p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black",
									placeholder: () => "text-gray-400 pl-0.5 py-0.5",
									valueContainer: () => "mt-0.5 px-2",
									control: ({ isDisabled }) =>
										clsx(
											isDisabled &&
												`${controlStyles.disabled} rounded-md border rounded-md border-gray-300 focus:border-gray-500`
										),
								}}
							/>
						)}
					</div>

					<div className="relative">
						<input
							placeholder="Search parameter"
							className={clsx(
								"border border-gray-300 p-2 w-full rounded-md  focus:border-gray-500 disabled:bg-gray-200 text-[12px] xl:text-sm"
							)}
							onFocus={() => {
								if (!stateOfShip || !vesselId) return;
								props.toggleParameterMetricsList(true);
							}}
							value={searchText}
							onChange={(e) => {
								const inputVal = e.target.value;
								props.setSearchText(inputVal);
							}}
							onClick={() => props.toggleParameterMetricsList(true)}
							onKeyDown={(e) => {
								if (e.key === "Escape") props.toggleParameterMetricsList(false);
								else props.toggleParameterMetricsList(true);
							}}
						/>
						{searchText ? (
							<span
								className="bp3-icon bp3-icon-cross absolute right-0 my-3 mr-2 text-gray-500 cursor-pointer"
								onClick={() => props.setSearchText("")}
							/>
						) : (
							<span className="bp3-icon bp3-icon-search absolute right-0 my-3 mr-2 text-gray-500" />
						)}
						<p className="text-xs italic text-gray-500 m-0">
							press esc to close the list
						</p>
					</div>
				</div>
				{showParameterMetricList && (
					<div className="mt-1">
						<div className="w-full flex">
							<div
								onClick={() =>
									props.setCurrentTab(EParameterTabType.ENGINE_LOG_TAB)
								}
								className={classNames(
									"w-1/2 flex justify-center items-center cursor-pointer pb-1 text-sm",
									{
										"font-medium border-b-2 border-gray-700":
											currentTab === "engine-log",
										"text-gray-600": currentTab !== "engine-log",
									}
								)}
							>
								Engine log
							</div>
							<div
								onClick={() =>
									props.setCurrentTab(EParameterTabType.NOON_REPORT_TAB)
								}
								className={classNames(
									"w-1/2 flex justify-center items-center cursor-pointer pb-1 text-sm",
									{
										"font-medium border-b-2 border-gray-700":
											currentTab === "noon-report",
										"text-gray-600": currentTab !== "noon-report",
									}
								)}
							>
								Noon report
							</div>
						</div>
					</div>
				)}
			</div>
			<EngineParameter />
			<NoonReportParameter />
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const vesselStore = vesselSelectors._getStore(store);
	const engineLogConfig = insightSelectors.getEngineLogConfig(insightStore);
	const showParameterMetricList =
		insightSelectors.getShowParameterMetricList(insightStore);
	const showRecentMetricList =
		insightSelectors.getShowRecentMetricList(insightStore);
	const currentTab = insightSelectors.getParameterCurrentTab(insightStore);
	const engineLogMetricsList =
		insightSelectors.getEngineLogMetricsList(insightStore);
	const selectedMetrics =
		insightSelectors.getEngineLogSelectedMetrics(insightStore);
	const searchText = insightSelectors.getSearchInputText(insightStore);
	const jobToLoadConfig = insightSelectors.jobToLoadConfig(insightStore);
	const stateOfShip = insightSelectors.getStateOfShip(insightStore);
	const vesselId = insightSelectors.getVesselId(insightStore);
	const stateOfShipDropdownOptions =
		insightSelectors.getStateOfShipDropdownOptions(insightStore);

	const miniVessels = vesselSelectors.getMiniVessels(
		vesselSelectors.getVessels(vesselStore)
	);
	const stateOfShipError = insightSelectors.getStateOfShipError(insightStore);

	return {
		engineLogConfig,
		showParameterMetricList,
		showRecentMetricList,
		currentTab,
		selectedMetrics,
		engineLogMetricsList,
		miniVessels,
		searchText,
		jobToLoadConfig,
		stateOfShip,
		vesselId,
		stateOfShipDropdownOptions,
		stateOfShipError,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleParameterMetricsList(value?: boolean) {
			dispatch(insightActions.document.toggleParameterMetricList(value));
		},
		setCurrentTab(currentTab: EParameterTabType) {
			dispatch(
				insightActions.document.setInsightParameterCurrentTab(currentTab)
			);
		},
		setVesselId(vesselId: string) {
			dispatch(insightActions.document.parameterVesselIdSet(vesselId));
		},
		setStateOfShip(stateOfShip: string) {
			dispatch(insightActions.document.stateOfShipSet(stateOfShip));
		},
		setSearchText(searchText: string) {
			dispatch(insightActions.document.searchInputSet(searchText));
		},
		updateEngineSelectedMetrics(metrics: string[]) {
			dispatch(
				insightActions.document.recentSelectedMetricToSelectedMetricsAdd(
					metrics
				)
			);
		},
		updateNoonReportSelectedMetrics(metrics: string[]) {
			dispatch(
				insightActions.document.noonReportRecentSelectedMetricToSelectedMetricsAdd(
					metrics
				)
			);
		},
		setStateOfShipDropdownOptions(options: IDropdownOption[]) {
			dispatch(insightActions.document.stateOfShipDropdownOptionsSet(options));
		},
		setStateOfShipError(stateOfShipError: boolean) {
			dispatch(
				insightActions.document.insightStateOfShipError(stateOfShipError)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Parameter);
