import Button from "@set-product/core/button/Button";
import classNames from "classnames";
import InfiniteProgress from "components/_common/InfiniteProgress";
import SVGConfig from "components/_common/icons/tool/SVGConfig";
import SVGCross from "components/_common/icons/tool/SVGCross";
import SVGInfo from "components/_common/icons/tool/SVGInfo";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import toolSelectors from "redux/app/tool/selectors";
import { isJson } from "redux/app/tool/utils";
import { IStore } from "redux/store";
import ActivitiesRenderer from "./ActivitiesRenderer";
import toolActions from "redux/app/tool/actions";
import { Tooltip } from "react-tippy";
import Editor from "@monaco-editor/react";

type OwnProps = {
	onClose: () => void;
	action?: string;
	actionLabel?: string;
	handleActionClick?: (
		print: any,
		changeLog?: string,
		newFileRequired?: boolean
	) => void; //INFO: print is similar to config json
	type: "view" | "create" | "update";
	isDisabled?: boolean;
};
type PrintFormProps = PropsFromRedux & OwnProps;
function PrintForm(props: PrintFormProps) {
	const {
		onClose,
		actionLabel,
		action,
		handleActionClick,
		type = "view",
		printInfo,
		isDisabled = false,
		jobToLoadPrintInfo,
		jobToCreatePrint,
		jobToUpdatePrint,
		selectedVesselOption,
		errorMessage,
		isExpanded,
	} = props;
	const [print, setPrint] = useState<any>(undefined);
	const [version, setVersion] = useState("");
	const [changeLog, setChangeLog] = useState("");
	const [newFileRequired, setNewFileRequired] = useState(false);

	useEffect(() => {
		if (printInfo) {
			setPrint(JSON.stringify(printInfo, undefined, 4));
		}
	}, [type, printInfo]);

	useEffect(() => {
		if (print) {
			const isjson = isJson(print);

			if (isjson) {
				const printObj = JSON.parse(print);
				"version" in printObj && setVersion(printObj["version"]);
			}
		} else {
			setVersion("");
		}
	}, [print]);

	// INFO: This is to prevent default macos behaviour,
	// issue: when scrolling left in the editor, it's leading to router back action
	useEffect(() => {
		document.body.style.overscrollBehaviorX = "contain";
		return () => {
			props.setErrorMessage("");
			document.body.style.overscrollBehaviorX = "auto";

			props.toggleFormExpander(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div
				className={classNames(
					"overflow-y-auto bg-white rounded-lg font-redhat-text",
					{
						"h-full max-h-[calc(90vh)]": !isExpanded,
						"h-screen": isExpanded,
					}
				)}
			>
				<div className="flex justify-between items-center py-2.5 px-4 border-[#C8C7C7] border-b">
					<div>
						{actionLabel && (
							<h2 className="mb-0 text-2xl font-medium">
								{actionLabel}{" "}
							</h2>
						)}
						<p className="m-0 text-sm">
							from {selectedVesselOption?.label ?? "NA"}
						</p>
						{errorMessage && (
							<div className="text-xs text-red-500 -mt-0.5">
								* {errorMessage}
							</div>
						)}
					</div>
					<div className="cursor-pointer" onClick={() => onClose()}>
						<SVGCross />
					</div>
				</div>
				<InfiniteProgress
					isLoading={
						jobToLoadPrintInfo.isLoading ||
						jobToUpdatePrint.isLoading ||
						jobToCreatePrint.isLoading
					}
					isSpacedOut={false}
				/>
				<div className="flex ">
					<div
						className={classNames("border-r border-[#C8C7C7]", {
							"w-1/2 ": !isExpanded,
							"w-3/4": isExpanded,
						})}
					>
						<div className="border-b border-[#C8C7C7] flex items-center py-2 px-4 h-10">
							<SVGConfig />
							<div className="text-[#718096] w-full text-sm ml-2 flex justify-between items-center">
								<span>JSON</span>
								<div className="relative flex">
									<span
										className={classNames(
											"mr-2 cursor-pointer bp3-icon bp3-icon-fullscreen",
											{
												"font-medium text-blue-400":
													!isExpanded,
											}
										)}
										onClick={() =>
											props.toggleFormExpander()
										}
									/>
									{/* {!isExpanded && (
										<span className="absolute top-0 right-0 w-1 h-1 bg-blue-400 rounded-full animate-ping" />
									)} */}
								</div>
							</div>
						</div>
						<div className="p-2 ">
							<Editor
								value={print}
								language="json"
								className={classNames("overscroll-x-contain", {
									"h-[380px]":
										!isExpanded && type !== "update",
									"h-[450px]":
										!isExpanded && type === "update",
									"h-[calc(100vh-197px)]":
										isExpanded && type !== "view",
									"h-[calc(100vh-140px)]":
										isExpanded && type === "view",
								})}
								onMount={(e, m) => {
									m.editor.EditorOptions.scrollbar.defaultValue.alwaysConsumeMouseWheel =
										false;
								}}
								options={{
									automaticLayout: true,
									autoClosingBrackets: "always",
									autoClosingQuotes: "always",
									formatOnPaste: true,
									formatOnType: true,
									autoIndent: true,
									scrollBeyondLastLine: false,
									wordWrap: "on",
									readOnly: isDisabled,
									acceptSuggestionOnEnter: "off",
									contextmenu: false,
									cursorStyle: "line",
									fontSize: 12,
									hover: {
										enabled: false,
									},
									minimap: {
										enabled: false,
									},
									parameterHints: {
										enabled: false,
									},
								}}
								onChange={(value, event) => {
									setPrint(value);
								}}
							/>
							{/* <textarea
								placeholder="Write here..."
								className={classNames(
									"w-full bg-[#F7F7F7] min-h-[450px] p-2 text-sm rounded",
									{
										"cursor-not-allowed": isDisabled,
									}
								)}
								spellCheck={false}
								value={config}
								disabled={isDisabled}
								onChange={(e) => {
									const value = e.target.value;
									setConfig(value);
								}}
								onKeyDown={(event) => {
									props.setErrorMessage("");
									if (event.keyCode === 9) {
										event.preventDefault();
										var v = event.currentTarget.value,
											s = event.currentTarget.selectionStart,
											end = event.currentTarget.selectionEnd;
										event.currentTarget.value =
											v.substring(0, s) + "\t" + v.substring(end);
										event.currentTarget.selectionStart =
											event.currentTarget.selectionEnd = s + 1;
										return false;
									}
								}}
							/> */}
						</div>
					</div>
					<div
						className={classNames({
							"w-1/2 ": !isExpanded,
							"w-1/4 ": isExpanded,
						})}
					>
						<div className="border-b border-[#C8C7C7] flex items-center py-2 px-4 h-10">
							<SVGInfo />
							<h4 className="text-[#718096] text-sm ml-2">
								Additional info
							</h4>
						</div>
						<div className="m-2">
							<div>
								<h4 className="text-[#616161] text-sm mb-1 flex items-center">
									<span>Version number</span>
									{type !== "view" && (
										<Tooltip
											arrow
											title={
												"Provide a valid JSON, to edit the version"
											}
											position="top"
											trigger="mouseenter"
											className="-mt-1.5"
										>
											<span className="inline-block ml-1 text-xs cursor-pointer bp3-icon bp3-icon-info-sign" />
										</Tooltip>
									)}
								</h4>

								<input
									placeholder="Eg: 1234567980"
									className={classNames(
										"bg-[#F7F7F7] text-sm w-full px-2 py-2.5 rounded",
										{
											"cursor-not-allowed":
												isDisabled || !isJson(print),
										}
									)}
									onChange={(e) => {
										const versionVal = e.target.value;
										const isjson = isJson(print);
										if (isjson) {
											const modifiedPrint =
												JSON.parse(print);
											modifiedPrint["version"] =
												versionVal;
											setVersion(versionVal);
											setPrint(
												JSON.stringify(
													modifiedPrint,
													undefined,
													4
												)
											);
										} else {
											props.setErrorMessage(
												"Please create a valid config JSON first."
											);
										}
									}}
									disabled={isDisabled || !isJson(print)}
									value={version}
								/>
							</div>

							{type === "update" && (
								<div className="mt-2">
									<label
										htmlFor="newFileRequired"
										className="pr-2 text-base text-gray-500"
									>
										New file required?
									</label>
									<input
										type="checkbox"
										id="newFileRequired"
										name="newFileRequired"
										checked={newFileRequired}
										onChange={(e) =>
											setNewFileRequired(e.target.checked)
										}
									/>
								</div>
							)}
							{type !== "view" && (
								<div className="mt-2">
									<h4 className="text-[#616161] text-sm mb-1">
										About print(change log)
									</h4>
									<textarea
										placeholder="Write here..."
										onChange={(e) => {
											props.setErrorMessage("");
											setChangeLog(e.target.value);
										}}
										className={classNames(
											"bg-[#F7F7F7] text-sm w-full px-2 py-2.5 rounded h-[80px]",
											{ "cursor-not-allowed": isDisabled }
										)}
										value={changeLog}
										disabled={isDisabled}
									/>
								</div>
							)}
							{type !== "create" &&
								printInfo?.changeLogActivity && (
									<div className="mt-2">
										<h5 className="mb-1 ml-1 font-medium">
											Activity log
										</h5>
										<div
											className={classNames(
												" overflow-y-auto",
												{
													"max-h-[230px]":
														!isExpanded &&
														type !== "view",
													"max-h-[270px]":
														!isExpanded &&
														type === "view",
													"h-[calc(100vh-230px)]":
														isExpanded &&
														type === "view",
													"h-[calc(100vh-430px)]":
														isExpanded &&
														type !== "view",
												}
											)}
										>
											<ActivitiesRenderer
												activities={
													printInfo?.changeLogActivity ??
													[]
												}
											/>
										</div>
									</div>
								)}
						</div>
					</div>
				</div>
				{action && handleActionClick && (
					<div className="border-[#C8C7C7] border-t  p-2">
						<Button
							className="w-full bg-blue-700 disabled:bg-gray-300"
							onClick={() => {
								handleActionClick(
									print,
									changeLog,
									newFileRequired
								);
							}}
							disabled={
								jobToLoadPrintInfo.isLoading ||
								jobToCreatePrint.isLoading ||
								jobToUpdatePrint.isLoading
							}
						>
							{action}
						</Button>
					</div>
				)}
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const printInfo = toolSelectors.getPrintInfo(toolStore);
	const jobToLoadPrintInfo = toolSelectors.jobToLoadPrintInfo(toolStore);
	const selectedVesselOption = toolSelectors.getSelectedVesselOption(toolStore);
	const errorMessage = toolSelectors.getPrintErrorMessage(toolStore);
	const jobToCreatePrint = toolSelectors.jobToCreatePrint(toolStore);
	const jobToUpdatePrint = toolSelectors.jobToUpdatePrint(toolStore);
	const isExpanded = toolSelectors.getIsPrintFormExpanded(toolStore);

	return {
		printInfo,
		jobToLoadPrintInfo,
		selectedVesselOption,
		errorMessage,
		jobToCreatePrint,
		jobToUpdatePrint,
		isExpanded,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setErrorMessage(msg: string) {
			dispatch(toolActions.document.toolPrintErrorMessageSet(msg));
		},
		toggleFormExpander(isExpanded?: boolean) {
			dispatch(toolActions.document.printFormExpanderToggle(isExpanded));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PrintForm);
