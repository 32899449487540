import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupF1 } from "../../../../../redux/app/annex6/groups/group-f";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupF1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupF1Preview(props: Annex6GroupF1PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupF1;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "F", 11.1, renderItem11P1()],
					[null, null, 11.2, renderItem11P2()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem11P1() {
		return (
			<h4 className="font-medium uppercase">
				Entry to NECA (Tier III) area on {record.item11P1.entryToNECA.date},
				{record.item11P1.entryToNECA.time} at{" "}
				{record.item11P1.entryPosition.latitude},
				{record.item11P1.entryPosition.longitude}
			</h4>
		);
	}

	function renderItem11P2() {
		return (
			<h4 className="font-medium uppercase">
				Status on/off of all engines adn their SCR/EGR equipment:{" "}
				{record.item11P2.status},
			</h4>
		);
	}

	return renderMain();
}

export default Annex6GroupF1Preview;
