import Checklist from "components/app/vessel/onboard/checklist/Checklist";
import React from "react";

type ScreenVesselChecklistLogsType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
			pageNumber: string;
		};
	};
}>;
const ScreenVesselChecklistLogs: React.FC<ScreenVesselChecklistLogsType> = (
	props: ScreenVesselChecklistLogsType
) => {
	return <Checklist
			pageNumber={props.match.params.pageNumber} 
			vesselId={props.match.params.vesselId}
		/>;
};

export default ScreenVesselChecklistLogs;