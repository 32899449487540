import React from "react";
import Login from "../../../components/auth/login/Login";
type ScreeenAuthLoginType = React.PropsWithChildren<{}>;
const ScreeenAuthLogin: React.FC<ScreeenAuthLoginType> = (
	props: ScreeenAuthLoginType
) => {
	return <Login />;
};

export default ScreeenAuthLogin;
