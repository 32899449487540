import { formUtils } from "../../../_core/form";
import { IVesselOnboardStore } from "./types";
import { IVesselCrew, IVesselDevice, IVessel } from "../types";
import moment from "moment";

const crewDefaults: IVesselCrew = {
	designation: "",
	fromDate: moment(new Date()).format("YYYY-MM-DD"),
	id: "",
	// image: "",
	name: "",
	staffId: "",
	toDate: moment(new Date()).format("YYYY-MM-DD"),
	userId: "",
	vesselId: undefined,
};
const crewFormDefaults = formUtils.getDefaults({
	fields: crewDefaults,
	mode: "CREATE",
});

const deviceDefaults: IVesselDevice = {
	deviceType: "TABLET",
	deviceName: "",
	deviceId: "",
	id: "",
	macAddress: "",
	operatingSystem: "Windows",
	version: "1.0.36",
	vesselId: undefined,
};

const deviceFormDefaults = formUtils.getDefaults({
	fields: deviceDefaults,
	mode: "CREATE",
});

// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now
const vesselFormDefaults: IVessel = {
	id: "",
	vesselName: "",
	imoNumber: "",
	nationality: "",
	portOfRegistry: "",
	yearBuildDate: "",
	addressOfTheOwner: "",
	flag: "",
	nameOfTheOwner: "",
	shipSizeCategory: undefined,
	vesselFuels: undefined,
	vesselPrefix: undefined,
	vesselSubType: undefined,
	isExhauseGasCleanerScrubberProvided: undefined,
	vesselType: undefined,
	tonnage: {
		deadWeight: 0,
		grossTonnage: 0,
		netTonnage: 0,
		regGrossTonnage: 0,
	},
	dimensions: {
		draft: 0,
		height: 0,
		length: 0,
		loa: 0,
		width: 0,
	},
	callSign: "",
	mmsiNumber: "",
	distinctiveNumberOrLetters: "",
	image: "",
	isCrewOnboarded: false,
	isDeviceOnboarded: false,
	tenantId: "",
	updatedAt: "",
	createdAt: "",
};

const reducerState: IVesselOnboardStore = {
	timeLogs: [],
	crews: {
		byIds: {},
		ids: [],
	},
	devices: {
		byIds: {},
		ids: [],
	},
	configLogsList: {
		byIds: {},
		ids: [],
	},
	configInfo: null,
	tanks: [],
	crewDesignations: [],
	dashboardUsageAndAlerts: null,
	ranges: {},
	signalLocalRanges: undefined,
	_forms: {
		CREW: crewFormDefaults,
		DEVICE: {
			...deviceFormDefaults,
			helpMessages: {
				...deviceFormDefaults.helpMessages,
				deviceName: "Nick name for Device to easily remember it.",
				macAddress: "MAC address is given in Wayship app.",
				version: "Wayship app version used in device.",
				deviceType: "Is it Desktop or Tablet?",
				deviceId: "ID is given in the Wayship app",
			},
		},
		// REFACTORME: Shift the Vessel form from onboard (aka onboard) to the root vessel store. becs the vessel form is now not being used in onboard it has its separate place now
		VESSEL: formUtils.getDefaults({
			fields: vesselFormDefaults,
			mode: "CREATE",
		}),
	},
	vesselId: null,
	_jobs: {},
};

export default reducerState;
export {
	crewDefaults as crewFormDefaults,
	deviceDefaults as deviceFormDefaults,
	vesselFormDefaults,
};
