import { ActionType, createAction } from "typesafe-actions";
import { IListObjectStore } from "../../_common/type";
import { TMARPOLFilters, TMARPOLOperation } from "./@types";

enum EMARPOLConstants {
	FEATURE = "@marpol",
}

export enum EMARPOLActions {
	MARPOL_DOCS_LOAD = "@marpol/docs/LOAD",
	MARPOL_DOCS_PRINT = "@marpol/docs/PRINT",
	MARPOL_VESSEL_SELECT = "@marpol/vessel/SELECT",
	MARPOL_DATE_RANGE_SELECT = "@marpol/dateRange/SELECT",

	MARPOL_DOCS_SET = "@marpol/docs/set",
	MARPOL_FILTERS_SET = "@marpol/filters/set",

	PAGINATION_TOTAL_ITEMS_SET = "@marpol/pagination/total-items/SET",
	PAGINATION_ITEMS_PER_PAGE_SET = "@marpol/pagination/items-per-page/SET",
	PAGINATION_CURRENT_PAGE_SET = "@marpol/pagination/current-page/SET",
	PAGINATION_PAGE_IDS_SET = "@marpol/pagination/page-wise-ids/SET",
	PAGINATION_PAGE_ID_RESET = "@marpol/pagination/page-wise-ids/RESET",
	PAGINATION_PAGE_IDS_RESET = "@marpol/pagination/page-wise-ids/RESET-ALL",

	/** actions added from wayship desktop (can change as required) */
	PAGINATION_RESET = "@marpol/pagination/RESET",
	TANKS_LOAD = "@marpol/:tanks-load",
	SOUNDING_RECORD_HISTORY_LOAD = "@marpol/:sounding-record-history-load",
	SOUNDING_RECORD_HISTORY_LOAD_MONTHLY = "@marpol/:sounding-record-history-load-monthly",
	SOUNDING_RECORD_SUBMIT = "@marpol/:sounding-record-submit",
	MARPOL_REPORT_LOAD = "@marpol/:marpol-report-load",
	TANK_CREATE = "@marpol/:tank-create",
	TANK_UPDATE = "@marpol/:tank-update",
	TANK_REMOVE = "@marpol/:tank-remove",

	MARPOL_REPORT_LOADED = "@marpol/#MARPOL-REPORT-LOADED",
	SOUNDING_RECORD_SUBMITTED = "@marpol/#SOUNDING_RECORD_SUBMITTED",

	TANKS_RESET = "@marpol/${tanks}/reset",
	TANKS_SET = "@marpol/${tanks}/set",
	TANKS_EDIT = "@marpol/${tanks}/list/edit",
	RECENTLY_PROCESSED_TANKS_SET = "@marpol/${recentlyProcessedTanks}/set",
	MARPOL_REPORT_SET = "@marpol/${marpolReport}/set",
	SOUNDING_RECORDS_SET = "@marpol/${soundingRecords}/set",

	GOTO_DASHBOARD = "@marpol/-->dashboard",
	GOTO_SOUNDING_RECORD = "@marpol/-->sounding-record",
	GOTO_SOUNDING_RECORD_OPERATION = "@marpol/-->sounding-record-operation",
	GOTO_TANKS_SETTINGS = "@marpol/-->tank-manager",

	SOUNDING_RECORD_SUBMIT_SIGN = "@marpol/:sounding-record-submit-sign",

	TANK_DESCRIPANCY_FIX = "@marpol/:tank-descripancy-fix",
}

const marpolActions = {
	commands: {
		marpolDocsLoad: createAction(EMARPOLActions.MARPOL_DOCS_LOAD, () => ({}))(),
		marpolDocsPrint: createAction(
			EMARPOLActions.MARPOL_DOCS_PRINT,
			() => ({})
		)(),
		marpolVesselSelect: createAction(
			EMARPOLActions.MARPOL_VESSEL_SELECT,
			() => ({})
		)(),
		marpolDateRangeSelect: createAction(
			EMARPOLActions.MARPOL_DATE_RANGE_SELECT,
			() => ({})
		)(),
		paginationTotalItemsSet: createAction(
			EMARPOLActions.PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		paginationItemsPerPageSet: createAction(
			EMARPOLActions.PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		paginationCurrentPageSet: createAction(
			EMARPOLActions.PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		paginationPageIdsSet: createAction(
			EMARPOLActions.PAGINATION_PAGE_IDS_SET,
			({ pageNumber, itemIds }: { pageNumber: number; itemIds: string[] }) => ({
				pageNumber,
				itemIds,
			})
		)(),
		paginationPageIdsResetAll: createAction(
			EMARPOLActions.PAGINATION_PAGE_IDS_RESET
		)(),
		paginationPageIdReset: createAction(
			EMARPOLActions.PAGINATION_PAGE_ID_RESET,
			(pageNumber: number) => ({ pageNumber })
		)(),
		paginationReset: createAction(EMARPOLActions.PAGINATION_RESET)(),
	},
	events: {},
	document: {
		marpolDocsSet: createAction(
			EMARPOLActions.MARPOL_DOCS_SET,
			(docs: IListObjectStore<TMARPOLOperation>) => ({ docs })
		)(),
		marpolFiltersSet: createAction(
			EMARPOLActions.MARPOL_FILTERS_SET,
			(filters: TMARPOLFilters) => ({ filters })
		)(),
	},
};

export type TMARPOLActions = ActionType<typeof marpolActions>;
export default marpolActions;
export { EMARPOLConstants };
