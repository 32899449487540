import React from "react";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import Downshift, {
	DownshiftState,
	GetInputPropsOptions,
	GetToggleButtonPropsOptions,
	StateChangeOptions,
} from "downshift";
import classNames from "classnames";
import { IconName } from "@blueprintjs/core";

type IDropdownOption = {
	value: string;
	label: string;
	icon?: IconName;
	rightIcon?: IconName;
	[key: string]: string | undefined;
};
type DropdownWithFilterModifiedProps = React.PropsWithChildren<{
	/**
	 * Unique id required to reference and differentiate between multiple dropdown..
	 * Its also required to remember dropdown on any rerender
	 */
	id: string;
	inputWrapperProps: InputWrapperProps;
	options: IDropdownOption[];
	emptyOption?: IDropdownOption;
	inputProps?: React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>;
	disabled?: boolean;
	placeholder?: string;
	value?: string;
	/**
	 * Dont filter as per input text @default false
	 */
	noFilter?: boolean;
	/**
	 * Dont Sort as per input text @default false
	 */
	noSort?: boolean;
	renderListItem?(listItem: IDropdownOption): React.ReactElement;
	renderView?(selectedItem: IDropdownOption): React.ReactElement;
	renderEmptyView?(): React.ReactElement;
	renderInput?(
		inputProps: GetInputPropsOptions,
		toggleProps: GetToggleButtonPropsOptions
	): React.ReactElement;
	onInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
	onSelect(value: IDropdownOption | null): void;
}>;
function DropdownWithFilterModified(props: DropdownWithFilterModifiedProps) {
	const {
		disabled,
		inputWrapperProps,
		options,
		emptyOption,
		inputProps,
		value,
		placeholder,
		noFilter,
		noSort = false,
		id,
	} = props;
	const dropdownViewId = "dropdown-view-" + id;
	const dropdownInputId = "dropdown-input-" + id;

	const { className: inputWrapperClassName, ...restInputWrapperProps } =
		inputWrapperProps;

	const optionsObject = options.reduce<{ [key: string]: IDropdownOption }>(
		(result, item, index) => {
			result[item.value] = item;
			return result;
		},
		emptyOption ? { [emptyOption.value]: emptyOption } : {}
	);

	function itemToString(item: IDropdownOption | null) {
		return item ? item.label : "";
	}
	function onSelect(selection: IDropdownOption | null) {
		props.onSelect(selection);
	}
	function reduceState(
		state: DownshiftState<IDropdownOption>,
		changes: StateChangeOptions<IDropdownOption>
	): Partial<StateChangeOptions<IDropdownOption>> {
		if (
			changes.type === Downshift.stateChangeTypes.clickItem ||
			changes.type === Downshift.stateChangeTypes.keyDownEnter ||
			changes.type === Downshift.stateChangeTypes.keyDownEscape
		)
			setTimeout(function onTimeout() {
				document.getElementById(dropdownViewId)?.focus();
			}, 100);
		return changes;
	}
	return (
		<Downshift
			itemToString={itemToString}
			onChange={onSelect}
			initialSelectedItem={optionsObject[value || ""]}
			stateReducer={reduceState}
			selectedItem={optionsObject[value || ""]}
		>
			{({
				getRootProps,
				getInputProps,
				getToggleButtonProps,
				getItemProps,
				getMenuProps,
				isOpen,
				highlightedIndex,
				selectedItem,
				inputValue,
			}) => (
				<div
					className="p-0 border-0 "
					{...getRootProps(undefined, { suppressRefError: true })}
				>
					<InputWrapper
						className={classNames(
							"relative flex-row w-full p-0 mt-1 md:m-0 flex justify-center md:justify-start",
							inputWrapperClassName
						)}
						{...restInputWrapperProps}
					>
						{isOpen ? (
							props.renderInput ? (
								props.renderInput(
									getInputProps({
										type: "text",
										...inputProps,
										name: dropdownInputId,
										id: dropdownInputId,
										className: classNames(
											" my-0 rounded-b-none rounded-r-none ws-input",
											props.inputProps?.className
										),
										autoComplete: "off",
									}),
									getToggleButtonProps({ disabled })
								)
							) : (
								<>
									<input
										{...getInputProps({
											type: "text",
											name: dropdownInputId,
											id: dropdownInputId,
											className:
												"w-full my-0 rounded-b-none rounded-r-none ws-input",
											autoComplete: "off",
											onChange: props.onInputChange
												? props.onInputChange
												: undefined,
										})}
									/>
									<div
										className="flex-col items-center justify-center px-3 my-0 border-l-0 rounded-b-none rounded-l-none ws-input hover:bg-gray-200 focus:bg-gray-300"
										{...getToggleButtonProps({ disabled })}
									>
										<span className="mb-px text-gray-900 text-md bp3-icon bp3-icon-chevron-down" />
									</div>
								</>
							)
						) : (
							<button
								{...getToggleButtonProps({
									id: dropdownViewId,
									disabled,
									className: classNames(
										"my-0 py-2 px-2  text-left flex flex-row justify-between items-center cursor-pointer outline-none shadow-none bg-white ",
										{
											"pl-0": !isOpen,
										}
									),
									onClick: () =>
										setTimeout(function onTimeout() {
											document.getElementById(dropdownInputId)?.focus();
											(
												document.getElementById(
													dropdownInputId
												) as HTMLInputElement
											)?.select();
										}, 100),
									onKeyDown(e) {
										if (e.key === "ArrowDown") {
											setTimeout(function onTimeout() {
												document.getElementById(dropdownInputId)?.focus();
												(
													document.getElementById(
														dropdownInputId
													) as HTMLInputElement
												)?.select();
											}, 100);
										}
									},
								})}
							>
								{selectedItem ? (
									props.renderView ? (
										props.renderView(selectedItem)
									) : (
										<span className="whitespace-nowrap">
											{selectedItem.label}&nbsp;
										</span>
									)
								) : props.renderEmptyView ? (
									props.renderEmptyView()
								) : (
									<span className="block text-gray-500">
										{placeholder ? placeholder : <span>&nbsp;</span>}
									</span>
								)}
								<div className="flex-col items-center justify-center px-1 my-0 border-l-0 rounded-b-none rounded-l-none pointer-events-none hover:bg-gray-200 focus:bg-gray-300 justify-items-center">
									<span className="mb-px text-gray-900 text-md bp3-icon bp3-icon-chevron-down" />
								</div>
							</button>
						)}
					</InputWrapper>
					<div className="relative h-0">
						{isOpen ? (
							<ul
								className="absolute top-0 z-50 w-full p-0 m-0 overflow-y-auto bg-white border-t-0 border-gray-200 rounded-t-none  ws-input max-h-56 ws-scroll rounded-b-md"
								{...getMenuProps({})}
							>
								{options
									.filter((option) =>
										noFilter
											? !inputValue || inputValue === options[0].label
												? true
												: option.label
														.toLowerCase()
														.includes(inputValue.toLowerCase()) ||
												  option.value
														.toLowerCase()
														.includes(inputValue.toLowerCase())
											: true
									)
									.sort((option) =>
										noSort
											? 1
											: inputValue
											? option.label
													.toLowerCase()
													.includes(inputValue.toLowerCase())
												? -1
												: 0
											: 1
									)
									.map((option, index) => {
										return (
											<li
												key={index}
												{...getItemProps({
													item: option,
												})}
											>
												<div
													className={classNames(
														"m-0 ws-input bg-white border-transparent hover:bg-gray-200 flex flex-row justify-start items-center cursor-pointer w-full focus:bg-gray-300 hover:border-gray-400 rounded-none",
														{
															"border-gray-700 bg-gray-300":
																highlightedIndex === index,
															"border-blue-700 bg-blue-200 border-2":
																selectedItem?.value === option.value,
														}
													)}
												>
													{props.renderListItem ? (
														props.renderListItem(option)
													) : (
														<span className="">{option.label}&nbsp;</span>
													)}
												</div>
											</li>
										);
									})}
							</ul>
						) : null}
					</div>
				</div>
			)}
		</Downshift>
	);
}

export default DropdownWithFilterModified;
