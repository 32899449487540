import { IListObjectStore } from "../../_common/type";
import { IFormStore } from "../../_core/form/types";
import { IJobHOCState } from "../../_core/job/types";
import { EMARPOLDocuments } from "../marpol/@types";

export enum EGRB2State {
	APPROVED = "APPROVED",
	STRIKED_OFF = "STRIKED-OFF",
	SUBMITTED = "SUBMITTED",
	IDLE = "IDLE",
	EDIT = "EDIT",
}

export enum EGarbageEditLabels {
	DATE = "Date",
	UTC_TIME = "UTC Time",
	START_OPERATION_DATE = "Start Operation Date",
	START_OPERATION_TIME = "Start Operation Time",
	END_OPERATION_DATE = "End Operation Date",
	END_OPERATION_TIME = "End Operation Time",
	START_LOCATION_LATITUDE = "Start Location Lat",
	START_LOCATION_LONGITURE = "Start Location Long",
	END_LOCATION_LATITUDE = "End Location Lat",
	END_LOCATION_LONGITUDE = "End Location Long",
	GARBAGE_CATEGORY = "Garbage Category",
	GARBAGE_DISCHARGE_AMOUNT = "Garbage Discharge Amount",
	LOCATION_OF_OCCURANCE = "Location Of Occurance",
	LOCATION_OF_DISCHARGE_LATITUDE = "Location Of Discharge Lat",
	LOCATION_OF_DISCHARGE_LONGITURE = "Location Of Discharge Long",
	PLACE_OF_DISCHARGE = "Place Of Discharge",
	REMARK = "Remarks",
	DEPTH_OF_WATER = "Depth Of Water",
}

export interface IGarbageEditLog {
	id: string; // fieldName
	fieldName: string;
	pastValue: string;
	newValue: string;
	editor: IGRB2User;
	fieldLabel: string;
	timeStamp: number;
	editSessionNumber: number;
}

export interface IGRB2User {
	name: string;
	designation: string;
	staffId: string;
	date: string;
}

export interface IGarbageBasicInfo {
	id: string;
	_rev: string;
	selectedDate: string;
	utcTimestamp: string;
	state: EGRB2State;
	editLogs: {
		[editSessionNumber: number]: IListObjectStore<IGarbageEditLog>;
	};
	marpolType: EMARPOLDocuments.GRB2;
	submittedBy?: IGRB2User;
	approvedBy?: IGRB2User;
	strikeOffReason?: string;
	numberOfEditSessions: number;
}

export interface IGarbage {
	label: string;
	value: string;
	category: string; // A,B,C ...
	estimatedAmountDischarged: number;
}

export type TGarbageEntries =
	| "GARBAGE_DISCHARGED_INTO_SEA"
	| "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA"
	| "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS";

export interface IGarbageDischargedIntoSea {
	entry: "GARBAGE_DISCHARGED_INTO_SEA";
	startOfOperation: {
		date: string;
		time: string;
	};
	locationOfStartOfDischarge: {
		latitude: string;
		longitude: string;
	};
	garbage: IGarbage[];
	remark: string;
}

export type TGarbageQuestion = {
	question: string;
	YES: "ALLOWED" | "BLOCKED" | TGarbageQuestion;
	NO: "ALLOWED" | "BLOCKED" | TGarbageQuestion;
};

export type TGarbageQuestionWithAnswer = TGarbageQuestion & {
	answer: "YES" | "NO" | undefined;
};

export type TGarbageQuestionsState =
	| "PENDING"
	| "COMPLETE"
	| "BLOCKED"
	| "NOT-REQUIRED";

export interface IGarbageAccidentalLoss {
	entry: "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA";
	locationOfOccurance: "SHORE" | "SEA" | "PORT";
	startOfOperation: {
		date: string;
		time: string;
	};
	endOfOperation: {
		date: string;
		time: string;
	};
	locationOfDischarge: {
		latitude: string;
		longitude: string;
	};
	depthOfWater: number;
	garbage: IGarbage[];
	remark: string;
}

export interface IGarbageDischargedToReceptionAshoreOrOtherShips {
	entry: "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS";
	placeOfDischarge: string;
	garbage: IGarbage[];
	receiptNumber: string;
	receiptAttachment: string;
	remark: string;
}

export type IGRB2 = IGarbageBasicInfo &
	(
		| IGarbageDischargedIntoSea
		| IGarbageAccidentalLoss
		| IGarbageDischargedToReceptionAshoreOrOtherShips
	);

export type TGRB2BackendRecord = IGRB2 & {
	submittedBy: IGRB2User;
	approvedBy: IGRB2User;
};

export interface IGRB2NewOperationState {
	garbageBasicInfo: IGarbageBasicInfo & {
		entry: TGarbageEntries;
	};
	garbageDischargedIntoSea: IGarbageDischargedIntoSea;
	garbageAccidentalLoss: IGarbageAccidentalLoss;
	garbageDischargedToReceptionAshoreOrOtherShips: IGarbageDischargedToReceptionAshoreOrOtherShips;
	newOperationState: "RECORD" | "PREVIEW";
}
export interface IGRB2Store extends IFormStore, IJobHOCState {
	records: IListObjectStore<IGRB2>;
	newOperation: IGRB2NewOperationState;
	// _forms: {
	//     NEW_OPERATION: IForm<IGRB2>;
	// };
	filter: TGRB2Filters;
	garbageQuestionsState: TGarbageQuestionsState;
	garbageDischargedIntoSeaQuestions: TGarbageQuestionWithAnswer[];
}

export type TGRB2Filters = {
	startDate: any;
	endDate: any;
	shownRecords: "ALL" | "ALL-PENDING";
};
