import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupD1 } from "../../../../../redux/app/orb1/groups/group-d";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { commonUtils } from "redux/_common";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupD1PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupD1Preview(props: ORB1GroupD1PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupD1;
	function renderItem13(): React.ReactNode {
		const bilgeHoldingTank =
			orb1Record.item15P1.methodOfDischarge.method ===
			"TO_HOLDING_OR_OTHER_TANKS"
				? orb1Record.item15P1.methodOfDischarge.holdingTank
				: undefined;
		return (
			<>
				{orb1Record.item13.quantityDischarged.toFixed(2)} M³ FROM{" "}
				{orb1Record.item14.bilgeSource}
				<br />
			</>
		);
	}

	function renderItem15P2Reception() {
		if (
			orb1Record.item15P1.methodOfDischarge.method !== "TO_RECEPTION_FACILITES"
		)
			return null;

		const { receiptNumber, receiptAttachment, port } =
			orb1Record.item15P1.methodOfDischarge;
		return (
			<>
				TO {port} PORT RECEPTION FACILITIES. RECEIPT NO: <b>{receiptNumber}</b>{" "}
				<br />
				ATTACHMENT:{" "}
				<a
					href={marpolUtils.getAttachmentURL(
						receiptAttachment,
						orb1Record.id,
						orb1Record._rev
					)}
					download={receiptAttachment}
				>
					{" "}
					{receiptAttachment}
				</a>
			</>
		);
	}

	function renderItem15P3HoldingOrOtherTanks(): React.ReactNode {
		if (
			orb1Record.item15P1.methodOfDischarge.method !==
			"TO_HOLDING_OR_OTHER_TANKS"
		)
			return null;

		const bilgeHoldingTank = orb1Record.item15P1.methodOfDischarge.holdingTank;
		const retainedAtHoldingTank = commonUtils.addUp(
			marpolUtils.getQuantityForCalculation(bilgeHoldingTank),
			orb1Record.item13.quantityDischarged
		);
		return (
			<>
				TRANSFER TO {bilgeHoldingTank.tankName}
				<br />
				TANK CAPACITY {bilgeHoldingTank.totalCapacity.toFixed(2)} M³, QUANTITY
				RETAINED {retainedAtHoldingTank.toFixed(2)} M³
			</>
		);
	}

	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						D
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">13</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">{renderItem13()}</h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">14</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							STARTED {orb1Record.item14.timeOfDischarge.start.time} / FINISHED{" "}
							{orb1Record.item14.timeOfDischarge.end.time}
						</h4>
					</div>
				</td>
			</tr>
			{orb1Record.item15P1.methodOfDischarge.method ===
			"THROUGH 15 PPM EQUIPMENT" ? (
				<>
					<tr
						className={classNames("uppercase", {
							"line-through": props.isStrikedOff,
						})}
					>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full">
									<span className="block font-medium whitespace-nowrap">
										{}
									</span>
								</div>
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								{}
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full font-medium">15.1</div>
							</div>
						</td>
						<td className="relative">
							<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
								<h4 className="font-medium uppercase">
									{orb1Record.item15P1.methodOfDischarge.method}
								</h4>
							</div>
						</td>
					</tr>
					<tr
						className={classNames("uppercase", {
							"line-through": props.isStrikedOff,
						})}
					>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full">
									<span className="block font-medium whitespace-nowrap">
										{}
									</span>
								</div>
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								{}
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full font-medium">{}</div>
							</div>
						</td>
						<td className="relative">
							<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
								<h4 className="font-medium uppercase">
									POSITION AT START{" "}
									{
										orb1Record.item15P1.methodOfDischarge.startOfDischarge
											.latitude
									}
									{" , "}
									{
										orb1Record.item15P1.methodOfDischarge.startOfDischarge
											.longitude
									}
								</h4>
							</div>
						</td>
					</tr>
					<tr
						className={classNames("uppercase", {
							"line-through": props.isStrikedOff,
						})}
					>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full">
									<span className="block font-medium whitespace-nowrap">
										{}
									</span>
								</div>
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								{}
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full font-medium">{}</div>
							</div>
						</td>
						<td className="relative">
							<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
								<h4 className="font-medium uppercase">
									POSITION AT END{" "}
									{
										orb1Record.item15P1.methodOfDischarge.endOfDischarge
											.latitude
									}
									{" , "}
									{
										orb1Record.item15P1.methodOfDischarge.endOfDischarge
											.longitude
									}
								</h4>
							</div>
						</td>
					</tr>
				</>
			) : null}
			{orb1Record.item15P1.methodOfDischarge.method ===
			"TO_RECEPTION_FACILITES" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">15.2</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="w-full h-full font-medium uppercase">
								<h4>{renderItem15P2Reception()}</h4>
							</div>
						</div>
					</td>
				</tr>
			) : null}
			{orb1Record.item15P1.methodOfDischarge.method ===
			"TO_HOLDING_OR_OTHER_TANKS" ? (
				<>
					<tr
						className={classNames("uppercase", {
							"line-through": props.isStrikedOff,
						})}
					>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
								)}
							></div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
								)}
							></div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full font-medium">15.3</div>
							</div>
						</td>
						<td className="relative">
							<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
								<div className="w-full h-full font-medium uppercase">
									<h4>{renderItem15P3HoldingOrOtherTanks()}</h4>
								</div>
							</div>
						</td>
					</tr>
				</>
			) : null}
			{orb1Record.item15P1.methodOfDischarge.method ===
			"THROUGH 15 PPM EQUIPMENT" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium" />
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="w-full h-full font-medium uppercase">
								{orb1Record.item15P1.methodOfDischarge.method ===
								"THROUGH 15 PPM EQUIPMENT"
									? `SEAL NUMBER: ${orb1Record.item15P1.methodOfDischarge.sealNumber}`
									: ``}
							</div>
						</div>
					</td>
				</tr>
			) : null}

			{orb1Record.remarks !== "" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{orb1Record.remarks !== "" ? orb1Record.remarks : null}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default ORB1GroupD1Preview;
