import React from "react";
import classNames from "classnames";
type SVGInsightProps = {
	className?: string;
};
const SVGInsight: React.FC<SVGInsightProps> = (props) => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		className={classNames("fill-current ws-side-item__icon", props.className)}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M10.275 23.975H17.125M10.275 23.975V18.2667M10.275 23.975H4.11005C3.92838 23.975 3.75414 23.9029 3.62568 23.7744C3.49722 23.646 3.42505 23.4717 3.42505 23.29V18.9517C3.42505 18.77 3.49722 18.5958 3.62568 18.4673C3.75414 18.3389 3.92838 18.2667 4.11005 18.2667H10.275M17.125 23.975V10.275M17.125 23.975H23.29C23.4717 23.975 23.646 23.9029 23.7744 23.7744C23.9029 23.646 23.975 23.4717 23.975 23.29V4.11005C23.975 3.92838 23.9029 3.75414 23.7744 3.62568C23.646 3.49722 23.4717 3.42505 23.29 3.42505H17.81C17.6284 3.42505 17.4541 3.49722 17.3257 3.62568C17.1972 3.75414 17.125 3.92838 17.125 4.11005V10.275M10.275 18.2667V10.96C10.275 10.7784 10.3472 10.6041 10.4757 10.4757C10.6041 10.3472 10.7784 10.275 10.96 10.275H17.125" />
	</svg>
);
export default SVGInsight;
