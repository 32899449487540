import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { Dispatch } from "redux";
import { EParameterTabType, INotActive } from "redux/app/insight/@types";
import { IStore } from "redux/store";
import CustomTooltip from "../../noon/_elements/CustomTooltip";
import Checkbox from "../../Checkbox";
import insightSelectors from "redux/app/insight/selectors";
import {
	generateArrowedString,
	getEmptyMessage,
	traverseDatesForNoonReportInsight,
} from "redux/app/insight/utils";
import { lineChartColors } from "redux/app/insight/constants";
import insightActions from "redux/app/insight/actions";
import boxIcon from "assets/icons/box.svg";
import classNames from "classnames";
import { Range } from "react-date-range";

type OwnProps = {
	width: number;
	dateRange: Range;
};
type NoonReportMetricInsightGraphProps = PropsFromRedux & OwnProps;
function NoonReportMetricInsightGraph(
	props: NoonReportMetricInsightGraphProps
) {
	const {
		width,
		dateRange,
		selectedMetrics,
		noonReportMetricsList,
		noonReportInsights,
		activeMetrics,
		vesselId,
		stateOfShip,
		jobToLoadInsights,
		currentTab,
	} = props;
	const notActiveRef = useRef<INotActive>({ notActiveMetrics: [] });

	const {
		list: noonReportInsightsList,
		metrics: metricsFromPayload,
		dateRangeValues,
	} = noonReportInsights;

	useEffect(() => {
		props.setActiveMetrics(
			selectedMetrics.filter(
				(metric) => !notActiveRef.current.notActiveMetrics.includes(metric)
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMetrics]);

	const { lineChartsData } = useMemo(() => {
		if (noonReportInsightsList?.length && vesselId) {
			const baseData = {} as Record<string, any>;

			noonReportInsightsList.forEach((insightData: any) => {
				const key = moment(insightData.selectedDate).format("DD-MM-YYYY");
				baseData[key] = { ...insightData };
			});
			if (Object.keys(baseData)?.length) {
				const { startDate: fromDate, endDate: toDate } = dateRange;

				// Setting Line charts data
				const colorsArr = lineChartColors;
				const lineChartsData = selectedMetrics.map((metricKey, index) => {
					return {
						color: colorsArr[index % colorsArr.length],
						dataKey: metricKey,
						// @ts-ignore
						name: noonReportMetricsList.byIds[metricKey]?.metric,
						...noonReportMetricsList.byIds[metricKey],
						data: traverseDatesForNoonReportInsight(
							moment(fromDate).toISOString(),
							moment(toDate).toISOString(),
							baseData,
							metricKey
						),
					};
				});
				return {
					lineChartsData,
				};
			}
			return { lineChartsData: [] };
		} else {
			return { lineChartsData: [] };
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metricsFromPayload, dateRangeValues]);

	if (!vesselId || !selectedMetrics.length) {
		return (
			<div className="mt-20 flex justify-center items-center">
				<div className="flex flex-col items-center justify-center">
					<img src={boxIcon} alt="No selection" width={130} />
					<p className="text-sm text-gray-500 mt-2.5">
						{getEmptyMessage({
							vesselId,
							stateOfShip,
							selectedMetricsLen: selectedMetrics.length,
						})}
					</p>
				</div>
			</div>
		);
	}

	if (!noonReportInsightsList.length && !jobToLoadInsights.isLoading) {
		return (
			<div className="mt-20 flex justify-center items-center">
				<div className="flex flex-col items-center justify-center">
					<img src={boxIcon} alt="No selection" width={130} />
					<p className="text-sm text-gray-500  mt-2.5">
						No data available, please change either the vessel,{" "}
						{currentTab === EParameterTabType.ENGINE_LOG_TAB
							? `state of the
						ship,`
							: ""}{" "}
						metrics or date range.
					</p>
				</div>
			</div>
		);
	}

	if (jobToLoadInsights.isLoading) {
		return (
			<div className="mt-20 flex justify-center items-center">
				<p className="text-gray-600 font-medium">Loading insights...</p>
			</div>
		);
	}

	return selectedMetrics.length && lineChartsData?.length ? (
		<div className="mt-6 overflow-y-auto">
			<ResponsiveContainer width={width} height={450}>
				<LineChart
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 35,
					}}
					key={selectedMetrics.join(",")}
				>
					<XAxis
						interval={0}
						textAnchor="end"
						fontSize={10}
						angle={-90}
						dataKey="name"
						tickLine={false}
						allowDuplicatedCategory={false}
						tick={(props) => {
							const { x, y, payload } = props;

							return (
								<>
									<g transform={`translate(${x},${y})`}>
										<path d={`M 0,${-9} V 1`} stroke="gray" strokeWidth={1} />
										<text
											dx={-3}
											x={0}
											y={0}
											dy={1}
											strokeWidth={2}
											fontSize={12}
											textAnchor="end"
											fill="#666"
											transform="rotate(-90)"
										>
											{moment(payload.value, "DD-MM-YYYY").format("Do MMM")}
										</text>
									</g>
								</>
							);
						}}
					></XAxis>

					<YAxis
						domain={[0, "dataMax + 10"]}
						allowDataOverflow={true}
						tickCount={10}
					/>
					<CartesianGrid strokeDasharray="3 3" />
					<Tooltip content={<CustomTooltip />} />

					{lineChartsData
						.filter((line) => activeMetrics.includes(line.dataKey))
						.map((line) => {
							const { color, data, dataKey, name, key } = line;
							return (
								<Line
									key={name + dataKey + key}
									data={data}
									dataKey={dataKey}
									stroke={color}
									type="monotone"
									isAnimationActive={false}
									dot={(dotProps) => {
										const { cx, cy, stroke, strokeWidth, key } = dotProps;
										return (
											<circle
												key={key}
												cx={cx}
												cy={cy}
												r={1}
												stroke={stroke}
												strokeWidth={strokeWidth}
												fill={stroke}
											/>
										);
									}}
								/>
							);
						})}
				</LineChart>
			</ResponsiveContainer>
			<div className="flex justify-center text-sm text-gray-600">Date</div>
			{/* Checkboxes */}
			<div className="mt-4 px-4 pl-12 flex">
				{lineChartsData && lineChartsData[0] ? (
					<div
						className={classNames("pr-6 w-1/4", {
							"border-r border-gray-300": lineChartsData?.length > 1,
						})}
					>
						<h3 className="font-medium text-base">Compare</h3>

						<div className="flex items-center mt-2 -ml-2">
							<div
								className="w-4 h-4 rounded-full mx-2.5 "
								style={{ background: lineChartsData[0]?.color }}
							/>
							<div className="text-xs font-medium w-[calc(100%-26px)] text-gray-600">
								{generateArrowedString([
									lineChartsData[0]?.sectionTitle ?? "",
									lineChartsData[0]?.subSectionTitle ?? "",
									lineChartsData[0]?.metricTitle ?? "",
								])}
							</div>
						</div>
					</div>
				) : null}
				<div className="flex flex-wrap  pl-8 w-3/4">
					{lineChartsData.slice(1).map((metric) => {
						const {
							dataKey,
							color,
							sectionTitle,
							subSectionTitle,
							metricTitle,
						} = metric;

						return (
							<div
								className="w-1/3"
								key={metricTitle + sectionTitle + subSectionTitle}
							>
								<Checkbox
									id={dataKey}
									isChecked={activeMetrics.includes(dataKey)}
									label={
										<div className="flex items-start">
											<div
												className="w-4 h-4 rounded-full mx-2.5"
												style={{ background: color }}
											/>
											<div className="text-xs w-[calc(100%-60px)]">
												{generateArrowedString([
													sectionTitle ?? "",
													subSectionTitle ?? "",
													metricTitle ?? "",
												])}
											</div>
										</div>
									}
									handleChange={(e) => {
										const updatedActiveMetrics = activeMetrics.includes(dataKey)
											? [...activeMetrics].filter(
													(metric) => metric !== dataKey
											  )
											: [...activeMetrics, dataKey];
										notActiveRef.current.notActiveMetrics =
											selectedMetrics.filter(
												(metric) => !updatedActiveMetrics.includes(metric)
											);
										props.setActiveMetrics(updatedActiveMetrics);
									}}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : null;
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const selectedMetrics =
		insightSelectors.getNoonReportSelectedMetrics(insightStore);
	const noonReportMetricsList =
		insightSelectors.getNoonReportMetricsList(insightStore);
	const noonReportInsights =
		insightSelectors.getNoonReportVesselSpecificInsights(insightStore);
	const activeMetrics =
		insightSelectors.getNoonReportActiveSelectedMetrics(insightStore);
	const vesselId = insightSelectors.getVesselId(insightStore);
	const stateOfShip = insightSelectors.getStateOfShip(insightStore);
	const jobToLoadInsights =
		insightSelectors.jobToLoadVesselSpecificInsights(insightStore);
	const currentTab = insightSelectors.getParameterCurrentTab(insightStore);
	return {
		selectedMetrics,
		noonReportMetricsList,
		noonReportInsights,
		activeMetrics,
		vesselId,
		stateOfShip,
		jobToLoadInsights,
		currentTab,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setActiveMetrics(activeMetrics: string[]) {
			dispatch(
				insightActions.document.noonReportActiveSelectedMetricsSet(
					activeMetrics
				)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NoonReportMetricInsightGraph);
