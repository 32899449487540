import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import feedbackConstants from "redux/app/feedback/constants";
import feedbackSelectors from "redux/app/feedback/selectors";
import { IStore } from "redux/store";
import SendIcon from "../../../../../assets/icons/send.svg";
import feedbackActions from "redux/app/feedback/actions";
import FeedbackChatsLoader from "../../list/_elements/FeedbackChatsLoader";
import FeedbackChatAttachmentRenderer from "./FeedbackChatAttachmentRenderer";
import {
	IChatMessageAttachment,
	IFeedbackChat,
} from "redux/app/feedback/@types";
import AttachmentPreviewer from "./ChatAttachmentPreviewer";
import ChatMessageEditor from "./ChatMessageEditor";

const acceptableFileTypes = [
	".pdf",
	".doc",
	".docx",
	".xls",
	".xlsx",
	".jpg",
	".jpeg",
	".png",
	".gif",
	".bmp",
];

let timeoutId: NodeJS.Timeout;
const md = 768;

type OwnProps = { showFeedback?: boolean };
type FeedbackChatMessagesProps = PropsFromRedux & OwnProps;
function FeedbackChatMessages(props: FeedbackChatMessagesProps) {
	const {
		individualFeedback,
		message,
		jobToLoadChats,
		chatAttachments,
		isMessageBeingGenerated,
		jobToCreateMessage,
		jobToAttachChatUpload,
	} = props;

	const [width, setWidth] = useState(0);
	const [isChatExpanded, setIsChatExpanded] = useState(false);

	const toggleChatExpander = () => {
		setIsChatExpanded((s) => !s);
	};

	/**   Attachment Previewer - Start      */
	const [isMultimediaModalOpen, setIsMultimediaModal] = useState(false);
	const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
	const [chatObj, setChatObj] = useState<IFeedbackChat | undefined>(undefined);
	const [modalMediaObj, setModalMediaObj] = useState<
		IChatMessageAttachment | File | undefined
	>(undefined);
	const min = 0;
	const totalChatAttachmentsCount = chatObj?.attachments?.length ?? 0;
	const max = totalChatAttachmentsCount;

	const toggleMultimediaModal = () => {
		setIsMultimediaModal((open) => !open);
	};

	useEffect(() => {
		if (chatObj?.attachments?.length) {
			const media = chatObj?.attachments[currentMediaIndex];
			setModalMediaObj(media);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatObj?.attachments, currentMediaIndex]);

	const goToNextMultimediaAttachment = (
		currentIndex = currentMediaIndex,
		max = totalChatAttachmentsCount
	) => {
		if (currentIndex < max - 1) {
			setCurrentMediaIndex(currentIndex + 1);
		}
	};

	const goToPrevMultiMediaAttachment = (currentIndex = currentMediaIndex) => {
		if (currentIndex > 0) {
			setCurrentMediaIndex(currentIndex - 1);
		}
	};

	/**   Attachment Previewer - End      */

	/** Show - loading in last added chat - Start */
	const chatMessagesGlobal =
		individualFeedback && individualFeedback?.chatMessages
			? individualFeedback.chatMessages
			: [];

	const [chatMessages, setChatMessages] = useState<
		Array<IFeedbackChat & { showLoading: boolean }>
	>(chatMessagesGlobal.map((chat) => ({ ...chat, showLoading: false })));

	useEffect(() => {
		const chatsListLen = chatMessagesGlobal.length;
		if (isMessageBeingGenerated) {
			setChatMessages(
				chatMessagesGlobal.map((chat, index) =>
					chatsListLen - 1 === index
						? { ...chat, showLoading: true }
						: { ...chat, showLoading: false }
				)
			);
		} else {
			setChatMessages(
				chatMessagesGlobal.map((chat) => ({ ...chat, showLoading: false }))
			);
		}
	}, [isMessageBeingGenerated, chatMessagesGlobal.length]);

	/** Show - loading in last added chat - End */

	const messagesEndRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	useEffect(() => {
		if (individualFeedback) {
			props.setSelectedFeedbackId(individualFeedback._id);
			props.setSelectedFeedbackVesselId(individualFeedback.vesselId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [individualFeedback]);

	// info: ensure scroll to bottom in chat window.
	useEffect(() => {
		const windowWidth = window.innerWidth;
		setWidth(windowWidth);
		clearTimeout(timeoutId);
		if (chatMessages?.length && windowWidth > md) {
			timeoutId = setTimeout(() => {
				scrollToBottom();
			}, 200);
		}

		return () => {
			clearTimeout(timeoutId);
			props.setMessage("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatMessages.length]);

	if (jobToLoadChats.isLoading) {
		return <FeedbackChatsLoader />;
	}

	const noOfChatAttachments = chatAttachments?.length ?? 0;
	const isThereMessageContent = !!message.length;

	return (
		<>
			<div
				id="chat-messages-list"
				className="relative h-[calc(100%-205px)] overflow-y-auto pt-6 px-4"
			>
				<div className="flex flex-col w-full md:space-y-4 px-3   md:min-h-min  md:overflow-y-auto ">
					{chatMessages.length === 0 && !jobToLoadChats.isLoading && (
						<div className="flex items-center justify-center w-full h-full">
							No chats found. Start your conversation.
						</div>
					)}
					{chatMessages.map((chat) => {
						const {
							_id,
							createdAt,
							message,
							sender,
							senderName,
							attachments,
							_rev,
							attachmentType,
							showLoading,
						} = chat;
						const isAuthorWayshipTeam =
							sender === feedbackConstants.wayshipTeam;

						const formattedDate =
							moment(createdAt).format("DD-MM-YYYY / hh:mm A");
						return (
							<div
								key={_id}
								className={classNames("w-full flex mb-4 lg:mb-0", {
									"justify-start": !isAuthorWayshipTeam,
									"justify-end ": isAuthorWayshipTeam,
								})}
							>
								<div
									className={classNames(
										"p-4 w-4/5 md:w-2/3 rounded-lg relative",
										{
											" bg-[#ADEBFF] rounded-bl-none": !isAuthorWayshipTeam,
											" text-blue-100 bg-[#3278FF] rounded-br-none":
												isAuthorWayshipTeam,
										}
									)}
								>
									<h4 className="text-lg font-medium">{senderName}</h4>
									<p className="whitespace-pre-line">{message}</p>

									{attachments &&
										Array.isArray(attachments) &&
										attachments?.length && (
											<div className="flex flex-wrap pt-1 mt-3 -ml-1">
												{/* INFO: typescript is not accepting different types which have common keys in array so had to use ts-ignore */}
												{/* @ts-ignore */}
												{attachments?.map(
													// @ts-ignore
													(attachment, index) => {
														return (
															<div
																key={index}
																className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded-md m-1 cursor-pointer relative"
															>
																<div
																	className={classNames(
																		"absolute w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded-md bg-transparent",
																		{
																			"z-10": !isChatExpanded,
																			"z-0": isChatExpanded,
																		}
																	)}
																	onClick={() => {
																		toggleMultimediaModal();
																		setModalMediaObj(attachment);
																		setChatObj(chat);
																		setCurrentMediaIndex(index);
																	}}
																/>
																<FeedbackChatAttachmentRenderer
																	attachment={attachment}
																	attachmentType={attachmentType}
																	_rev={_rev}
																	id={_id}
																	index={index}
																	isUploadInProgress={showLoading}
																/>
															</div>
														);
													}
												)}
											</div>
										)}
									<p
										className={classNames(
											"text-right text-xs mt-2 font-medium flex items-center justify-end",
											{
												"text-gray-600": !isAuthorWayshipTeam,
												"text-gray-200": isAuthorWayshipTeam,
											}
										)}
									>
										{formattedDate}
									</p>
									<div
										className={classNames("absolute  bottom-0 w-6 h-6", {
											"bg-[#3278FF] left-full -translate-x-4":
												isAuthorWayshipTeam,
											"bg-[#ADEBFF] right-full translate-x-4":
												!isAuthorWayshipTeam,
										})}
										style={{
											clipPath: isAuthorWayshipTeam
												? "polygon(50% 0%, 49% 100%, 100% 100%)"
												: "polygon(50% 0, 49% 100%, 0 100%)",
										}}
									></div>
								</div>
							</div>
						);
					})}
					<div className="h-0.5" id="message-end" ref={messagesEndRef} />
				</div>
			</div>
			<div
				className="sticky bottom-0 p-4 lg:pb-0"
				style={{ background: "rgb(232 249 255" }}
			>
				<div className="relative">
					{noOfChatAttachments ? (
						<div className="mb-2">
							<div className="flex items-center mt-1">
								<p className="ml-2 underline cursor-pointer">
									{noOfChatAttachments} attachment
									{noOfChatAttachments > 1 && "s"}
								</p>
								<span
									onClick={() => {
										props.setAttachments([]);
									}}
									className="ml-2 cursor-pointer bp3-icon bp3-icon-delete"
								/>
							</div>
							<div className="flex flex-col ml-2">
								{chatAttachments &&
									chatAttachments.map((att, index) => {
										return (
											<div key={att.name + index}>
												<span>{index + 1}.</span>
												<span className="ml-1">{att.name}</span>
												<span
													onClick={() => {
														const updatedChatAttachments =
															chatAttachments.filter(
																(att, upIndex) => index !== upIndex
															);
														props.setAttachments(updatedChatAttachments);
													}}
													className="ml-2 bp3-icon bp3-icon-cross cursor-pointer"
												></span>
											</div>
										);
									})}
							</div>
						</div>
					) : null}
					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (isChatExpanded) toggleChatExpander();
							if (message.length) props.createMessage();
						}}
					>
						<div
							className={classNames("flex  justify-between ", {
								"items-center": !isChatExpanded,
								"items-end": isChatExpanded,
							})}
						>
							<div className="w-[calc(100%-60px)]">
								<ChatMessageEditor
									autoFocus={width > md}
									value={message}
									isChatExpanded={isChatExpanded}
									toggleChatExpander={toggleChatExpander}
									noOfChatAttachments={noOfChatAttachments}
									onChange={(e) => {
										props.setMessage(e.target.value);
									}}
									onPaste={async (targetFiles: File[]) => {
										const files: File[] = [];
										let error = "";
										for (let i = 0; i < targetFiles?.length; i++) {
											const file = targetFiles[i];

											if (file) {
												// Limit size 1 mb
												if (file.size > 1 * 1048576) {
													error = `${file.name} File size should be less than 1 mb`;
													break;
												}
												if (
													!acceptableFileTypes.some((type) =>
														file?.name?.includes(type)
													)
												) {
													error = `${
														file.name
													} should be one of the ${acceptableFileTypes.join(
														", "
													)} formats`;
													break;
												}
												files.push(file);
											}
										}
										if (error) {
											alert(error);
											return;
										}
										props.setAttachments([
											...(chatAttachments ? chatAttachments : []),
											...files,
										]);
									}}
									onDrop={(targetFiles: File[]) => {
										const files: File[] = [];
										let error = "";
										for (let i = 0; i < targetFiles?.length; i++) {
											const file = targetFiles[i];

											if (file) {
												// Limit size 1 mb
												if (file.size > 1 * 1048576) {
													error = `${file.name} File size should be less than 1 mb`;
													break;
												}
												if (
													!acceptableFileTypes.some((type) =>
														file?.name?.includes(type)
													)
												) {
													error = `${
														file.name
													} should be one of the ${acceptableFileTypes.join(
														","
													)} formats`;
													break;
												}
												files.push(file);
											}
										}
										if (error) {
											alert(error);
											return;
										}
										props.setAttachments([
											...(chatAttachments ? chatAttachments : []),
											...files,
										]);
									}}
								/>
							</div>
							{/* <textarea
								rows={4}
								className={classNames(
									"shadow appearance-none border w-[calc(100%-60px)] py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-md resize-none transition hover:ease-in-out duration-300"
								)}
								id="user-message"
								tabIndex={0}
								value={message}
								autoFocus={width > md}
								onChange={(e) => {
									props.setMessage(e.target.value);
								}}
							/> */}
							<div
								className={classNames("flex items-end", {
									"pb-4": isChatExpanded,
								})}
							>
								<div className="flex flex-col justify-between ">
									<button
										className={classNames(
											"w-12 h-12 rounded-full  flex justify-center items-center focus:border focus:border-blue-400 focus:shadow-sm mb-1",
											{
												"bg-[#D8E6FF]": message.length,
												"bg-gray-300": !message.length,
											}
										)}
										disabled={!message.length}
										type="button"
									>
										<label
											className={classNames(
												"flex justify-center items-center w-full h-full",
												{
													"cursor-not-allowed": !isThereMessageContent,
													"cursor-pointer": isThereMessageContent,
												}
											)}
										>
											<span
												className={classNames("bp3-icon bp3-icon-paperclip ", {
													"text-gray-800": isThereMessageContent,
													"text-gray-500": !isThereMessageContent,
												})}
											/>
											<input
												disabled={!message.length}
												accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.bmp"
												multiple={true}
												type="file"
												className="hidden"
												onChange={(e) => {
													if (e?.target?.files) {
														const files: File[] = [];
														let error = "";
														for (let i = 0; i < e.target.files.length; i++) {
															const file = e.target.files.item(i);
															if (file) {
																// Limit size 1 mb
																if (file.size > 1 * 1024 * 1024) {
																	error = `${file.name} File size should be less than 1 mb`;
																	break;
																}
																files.push(file);
															}
														}
														if (error) {
															alert(error);
															return;
														}
														props.setAttachments([
															...(chatAttachments ? chatAttachments : []),
															...files,
														]);
													}
												}}
											/>
										</label>
									</button>
									<button
										type="submit"
										disabled={
											!message.length ||
											jobToCreateMessage.isLoading ||
											jobToAttachChatUpload.isLoading
										}
										tabIndex={0}
										className={classNames(
											"w-12 h-12 rounded-full bg-[#D8E6FF] flex justify-center items-center focus:border focus:border-blue-400 focus:shadow-sm",
											{ "cursor-pointer": message.length },
											{
												"cursor-not-allowed":
													!message.length ||
													jobToCreateMessage.isLoading ||
													jobToAttachChatUpload.isLoading,
											}
										)}
									>
										<img src={SendIcon} alt="Send message" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			{isMultimediaModalOpen && (
				<AttachmentPreviewer
					url={
						chatObj?.attachmentType === "url"
							? `https://console.wayship.io/api/attachments?docId=${chatObj._id}&attachmentName=${modalMediaObj?.name}&type=${modalMediaObj?.type}`
							: chatObj?.attachmentType === "local"
							? URL.createObjectURL(modalMediaObj as File)
							: ""
					}
					mediaType={modalMediaObj?.type}
					mediaName={modalMediaObj?.name}
					currentMediaIndex={currentMediaIndex}
					min={min}
					max={max}
					goToNext={() => goToNextMultimediaAttachment()}
					goToPrevious={() => goToPrevMultiMediaAttachment()}
					isModalOpen={isMultimediaModalOpen}
					onClose={() => toggleMultimediaModal()}
				/>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const message = feedbackSelectors.getMessage(feedbackStore);
	const selectedFeedbackId =
		feedbackSelectors.getSelectedFeedbackId(feedbackStore);
	const selectedFeedback = feedbackSelectors
		.getFeedbacks(feedbackStore)
		.find((fb) => fb._id === selectedFeedbackId);

	const chatAttachments =
		feedbackSelectors.getActiveChatAttachments(feedbackStore);
	const jobToCreateMessage =
		feedbackSelectors.jobToCreateMessage(feedbackStore);
	const jobToAttachChatUpload =
		feedbackSelectors.jobToAttachChatUpload(feedbackStore);

	return {
		individualFeedback: feedbackSelectors.getIndividualFeedback(feedbackStore),
		message,
		jobToLoadChats:
			feedbackSelectors.jobToLoadIndividualFeedback(feedbackStore),
		selectedFeedback,
		chatAttachments,
		isMessageBeingGenerated:
			feedbackSelectors.getChatWithAttachmentUploadProgress(feedbackStore),
		jobToCreateMessage,
		jobToAttachChatUpload,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		createMessage() {
			dispatch(
				feedbackActions.commands.createMessage("individualFeedbackPage")
			);
		},
		setMessage(message: string) {
			dispatch(feedbackActions.document.selectedChatListMessageSet(message));
		},
		setSelectedFeedbackVesselId(vesselId: string) {
			return dispatch(
				feedbackActions.document.selectedFeedbackVesselIdSet(vesselId)
			);
		},
		setSelectedFeedbackId(feedbackId: string) {
			return dispatch(
				feedbackActions.document.selectedFeedbackIdSet(feedbackId)
			);
		},
		setAttachments(attachments: File[]) {
			dispatch(
				feedbackActions.document.selectedChatListAttachmentSet(attachments)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackChatMessages);
