import moment from "moment";
import React from "react";
import {
	TORB1BackendRecord,
	TORB1Record,
} from "../../../../../redux/app/orb1/@types";
import { TORB1GroupC1 } from "../../../../../redux/app/orb1/groups/group-c";
import getSignTableRows from "../getSignTableRows";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupC1PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupC1Preview(props: ORB1GroupC1PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupC1;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const userInfoRows = getSignTableRows(orb1Record as TORB1BackendRecord);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					...renderItem11P1Rows(),
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem11P1Rows(): ORB1TableColumns[] {
		const { oilResidues } = orb1Record.item11P1;
		let listOfRows: ORB1TableColumns[] = [];
		oilResidues.map((oilResidue, index) => {
			const isLastOilResidue = index === oilResidues.length - 1;
			const tankName = oilResidue.selectedTank.tankName;
			const totalCapacity = oilResidue.selectedTank.totalCapacity.toFixed(2);
			const actualQuantity = oilResidue.actualQuantity.toFixed(2);
			listOfRows.push([operationDate, "C", "11.1", tankName]);
			listOfRows.push([null, null, 11.2, `${totalCapacity} M³`]);
			listOfRows.push([null, null, 11.3, `${actualQuantity} M³`]);
			userInfoRows.map(
				(userRow) => !isLastOilResidue && listOfRows.push(userRow)
			);
		});
		return listOfRows;
	}

	return renderMain();
}

export default ORB1GroupC1Preview;
