import React, { FC } from 'react';
import { IData } from '.';
import WayshipDropdownDeck from './WayshipDropdownDeck';

interface IMetricProps {
    data: IData;
    metrics: any;
    setData: React.Dispatch<React.SetStateAction<IData>>;
}

const Metric: FC<IMetricProps> = ({ data, metrics, setData }) => {
    const range = metrics && metrics?.length > 0
    ? metrics.map((m: any) => ({
        label: m?.title || m?.mnemonic,
        value: m?.mnemonic || m?.title,
    }))
    : [{ label: '', value: '' }];
    return (
        <div>
            <WayshipDropdownDeck
                isSubmit={data.section.value === '' || range[0].value === ''}
                title="Select Metric"
                dataKey=""
                value={data.metric.value}
                range={range}
                onChangeHandler={(selectedItem: any, dataKey: any) => {
                    if (metrics) {
                        const metric = metrics.find(
                            (metric: any) =>
                                metric?.key === selectedItem ||
                                metric?.title === selectedItem
                        );
                        if (metric) {
                            setData((prev) => ({
                                ...prev,
                                metric: {
                                    label: metric?.title,
                                    value: metric?.title,
                                },
                                range:
                                    metric?.readingRange?.range &&
                                    metric?.readingRange?.range?.length > 0
                                        ? metric?.readingRange?.range
                                        : ['', ''],
                                key: metric?.key,
                            }));
                        } else {
                            setData((prev) => ({
                                ...prev,
                                metric: {
                                    label: selectedItem,
                                    value: selectedItem,
                                },
                                range:[undefined, undefined],
                                key: '',
                                remarks: '',
                            }));
                        }
                    } else {
                        setData((prev) => ({
                            ...prev,
                            metric: {
                                label: selectedItem,
                                value: selectedItem,
                            },
                            range:[undefined, undefined],
                            key: '',
                            remarks: '',
                        }));
                    }
                }}
            />
        </div>
    );
};

export default Metric;
