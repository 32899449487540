import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { logActions, logSelectors } from "../../../../../redux/app/logs";
import { ILogFilters, ILogType } from "../../../../../redux/app/logs/@types";
import { IStore } from "../../../../../redux/store";
import InputWrapper from "../../../../_common/InputWrapper";
import { vesselSelectors } from "../../../../../redux/app/vessel";
import { Tag } from "@blueprintjs/core";
import InfiniteProgress from "../../../../_common/InfiniteProgress";
import ReactJson from "react-json-view";
import Dropdown from "../../../../_common/Dropdown";
import { authSelectors } from "redux/app/auth";
type LogListFilterHeaderProps = PropsFromRedux;
function LogListFilterHeader(props: LogListFilterHeaderProps) {
	const { filterForm, jobToFilterLogs, jobToLoadSelectedLog, tenantCode } =
		props;
	const { errors, fields, hasErrors, helpMessages } = filterForm;

	let logs = [
		{
			label: "All Logs",
			value: "",
		},
		{
			label: "Engine Log",
			value: "engineLogConfig",
		},
		{
			label: "Deck Log",
			value: "deckLogConfig",
		},
		{
			label: "Port Log",
			value: "portLogConfig",
		},
		{
			label: "GMDSS Radio Log",
			value: "gmdssRadioConfig",
		},
		{
			label: "Radar Log",
			value: "radarLogConfig",
		},
		{
			label: "Compass Log",
			value: "compassLogConfig",
		},
		{
			label: "Bridge Order Book",
			value: "bridgeOrderConfig",
		},
		{
			label: "Engine Order Book",
			value: "engineOrderConfig",
		},
	];

	// if tenantCode is TEEKAY then only show engine logs
	if (tenantCode === "TEEKAY") {
		logs = [
			{
				label: "All Logs",
				value: "",
			},
			{
				label: "Engine Log",
				value: "engineLogConfig",
			},
			{
				label: "Compass Observation Book",
				value: "compassLogConfig",
			},
			{
				label: "GMDSS Radio Log",
				value: "gmdssRadioConfig",
			},
		];
	}

	const selectedLogName = logs.find((o) => o.value === fields.logType);
	// const selectedVessel = vessels.find(o => o.id === fields.vesselId);
	return (
		<tr className="sticky top-0 bg-white">
			<th />
			<th className="text-sm text-gray-500">
				<InputWrapper
					className="w-full py-0 my-0"
					hasError={false}
					hasWarning={false}
					isLoading={jobToFilterLogs.isLoading}
				>
					<Dropdown
						id="log-type"
						inputWrapperProps={{
							hasError: !errors.logType.isValid,
							inputValue: fields.logType,
						}}
						style={{ width: "70%" }}
						options={logs}
						onSelect={function onDeviceTypeChange(e) {
							if (e)
								props.editForm({
									logType: e?.value as ILogType,
								});
						}}
						value={selectedLogName?.value}
						noFilter={true}
						renderView={function renderView(selectedItem) {
							return (
								<span className="w-32 whitespace-no-wrap bp3-text-overflow-ellipsis">
									{selectedItem.label}&nbsp;
								</span>
							);
						}}
					/>
					<span className="ws-input__error">Hello Error</span>
					<span className="ws-input__warning">Hello Warning</span>
				</InputWrapper>
			</th>

			<th className="text-sm text-gray-500" />
			<th>
				<button className="hidden px-6 font-medium text-gray-500 bg-blue-100 border-2 border-gray-500 rounded-md ws-input focus:bg-blue-500 hover:text-gray-700 hover:border-gray-700 focus:shadow-lg focus:text-white">
					Export as PDF
				</button>
			</th>
		</tr>
	);
}

function mapStateToProps(store: IStore) {
	const logStore = logSelectors._getStore(store);
	const tenantCode = authSelectors(store.app.auth).getTenantCode();

	return {
		filterForm: logSelectors.formForFilter(logStore),
		jobToFilterLogs: logSelectors.jobToFilterLogs(logStore),
		jobToLoadSelectedLog: logSelectors.jobToLoadSelectedLog(logStore),
		tenantCode,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		editForm(fields: Partial<ILogFilters>) {
			dispatch(logActions._forms.filter.formFieldsEdit(fields));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(LogListFilterHeader);
