import { IStore } from "redux/store";
import insightActions, {
	EInsightActions,
	TInsightActions,
	insightJobActions,
} from "./actions";
import { Middleware } from "redux";
import { apiActions } from "redux/_core/api";
import insightAPIS from "./api";
import insightSelectors from "./selectors";
import {
	EParameterTabType,
	IEngineLogInsightPayloadItem,
	TStateOfShip,
} from "./@types";
import moment from "moment";
import { Range } from "react-date-range";

const insightMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action: TInsightActions) => {
		switch (action.type) {
			case EInsightActions.INSIGHT_CONFIG_LOAD: {
				next(action);
				const job = insightJobActions.insightConfigLoad;
				const insightStore = insightSelectors._getStore(getState());
				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";
				const currentTab =
					insightSelectors.getParameterCurrentTab(insightStore);

				// const logConfig: any = data;
				const { category, type, vesselId } = action.payload;
				dispatch(
					apiActions.apiRequest({
						...insightAPIS.loadConfig({
							category,
							type,
							vesselId,
							token,
							userId,
						}),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data: logConfig }) {
								if (currentTab === EParameterTabType.ENGINE_LOG_TAB) {
									dispatch(
										insightActions.document.insightEngineLogConfigSet(
											!logConfig?.[0]?.config
												? undefined
												: {
														...logConfig[0].config,
														title: logConfig[0]?.title,
														subtitle: logConfig[0]?.subtitle,
														vesselId: logConfig[0]?.vesselId,
												  }
										)
									);
								} else {
									dispatch(
										insightActions.document.insightNoonReportConfigSet(
											!logConfig?.[0]?.config
												? undefined
												: {
														...logConfig[0].config,
														title: logConfig[0]?.title,
														subtitle: logConfig[0]?.subtitle,
														vesselId: logConfig[0]?.vesselId,
												  }
										)
									);
									dispatch(
										insightActions.document.insightNoonReportEngineConfigSet(
											!logConfig?.[0]?.engineConfig
												? undefined
												: {
														...logConfig[0].engineConfig,
														title: logConfig[0]?.title,
														subtitle: logConfig[0]?.subtitle,
														vesselId: logConfig[0]?.vesselId,
												  }
										)
									);
								}
							},
							onError(error) {
								console.error(error);
								dispatch(
									job.error({
										message: `Failed to load config for engine log`,
									})
								);
								// if (currentTab === EParameterTabType.ENGINE_LOG_TAB) {
								// 	dispatch(
								// 		insightActions.document.engineLogMetricsListSet({
								// 			ids: [],
								// 			byIds: {},
								// 		})
								// 	);
								// } else {
								// 	dispatch(
								// 		insightActions.document.noonReportMetricsListSet({
								// 			ids: [],
								// 			byIds: {},
								// 		})
								// 	);
								// }
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EInsightActions.INSIGHT_VESSEL_SPECIFIC_INSIGHTS_LOAD: {
				next(action);
				let insightsDateRange: undefined | Range[];
				if ("payload" in action) {
					const { dateRange } = action?.payload;
					insightsDateRange = dateRange;
				}
				const job = insightJobActions.vesselSpecificInsightsLoad;
				const insightStore = insightSelectors._getStore(getState());
				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore?.authToken ?? "";
				const vesselId = insightSelectors.getVesselId(insightStore);
				// ||
				// "edae2c30-743b-11ec-86a9-6bbb12c98cf5";

				const currentTab =
					insightSelectors.getParameterCurrentTab(insightStore);
				const selectedMetrics =
					currentTab === EParameterTabType.ENGINE_LOG_TAB
						? insightSelectors.getEngineLogSelectedMetrics(insightStore)
						: insightSelectors.getNoonReportSelectedMetrics(insightStore);
				const metricKeys =
					selectedMetrics.map(
						(metric) =>
							`${
								currentTab === EParameterTabType.ENGINE_LOG_TAB ? "data." : ""
							}${metric}.local_reading`
					) ?? [];
				const dateRange =
					insightsDateRange ?? insightSelectors.getDateRange(insightStore);
				const { startDate, endDate } = dateRange[0];
				let stateOfShip = insightSelectors.getStateOfShip(insightStore) as
					| TStateOfShip
					| undefined;

				stateOfShip =
					currentTab === EParameterTabType.ENGINE_LOG_TAB
						? stateOfShip
						: undefined;
				const startDateISOStr =
					moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
				const endDateISOStr =
					moment(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
				const type =
					currentTab === EParameterTabType.ENGINE_LOG_TAB
						? "engine"
						: "noon_report";

				dispatch(
					apiActions.apiRequest<IEngineLogInsightPayloadItem[]>({
						...insightAPIS.loadVesselSpecificEngineLogInsights(
							token,
							userId,
							vesselId,
							metricKeys,
							startDateISOStr,
							endDateISOStr,
							type,
							stateOfShip
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data: vesselSpecificInsights }) {
								if (currentTab === EParameterTabType.ENGINE_LOG_TAB) {
									dispatch(
										insightActions.document.engineLogInsightsSet(
											vesselSpecificInsights?.length
												? vesselSpecificInsights
												: [],
											selectedMetrics,
											[
												moment(startDate).format("DD-MM-YYYY"),
												moment(endDate).format("DD-MM-YYYY"),
											]
										)
									);
								} else {
									dispatch(
										insightActions.document.noonReportInsightsSet(
											vesselSpecificInsights?.length
												? vesselSpecificInsights
												: [],
											selectedMetrics,
											[
												moment(startDate).format("DD-MM-YYYY"),
												moment(endDate).format("DD-MM-YYYY"),
											]
										)
									);
								}
							},
							onError(error) {
								console.error(error);
								dispatch(
									job.error({
										message: `Failed to load vessel specific insights`,
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			default:
				next(action);
		}
	};

export default insightMiddleware;
