import moment from "moment";
import { commonUtils } from "../../_common";
import { format, startOfToday } from "date-fns";

const marpolDateUtils = {
	getDateInISO(date?: string) {
		if (date)
			return moment.utc(date + " " + "00:00:00").format("YYYY-MM-DDTHH:mm:ssZ");
		return moment
			.utc(moment().format("YYYY-MM-DD") + " " + "00:00:00")
			.format("YYYY-MM-DDTHH:mm:ssZ");
	},
	getParsedMomentDate(iso8601Date: string) {
		const dateObj = new Date(iso8601Date);
		const formattedDate = dateObj.toISOString().split("T")[0];
		return moment(marpolDateUtils.getDateInISO(formattedDate)).startOf("day");
	},
	optimizeDateForSubmission(
		dateOfSubmission: string,
		lastRecordDate?: string,
		isAnMissedEntryOperation?: boolean
	) {
		const dateToOptimize =
			marpolDateUtils.getParsedMomentDate(dateOfSubmission);

		if (lastRecordDate && !isAnMissedEntryOperation) {
			const lastRecordMomentDate =
				marpolDateUtils.getParsedMomentDate(lastRecordDate);
			if (dateToOptimize.isSameOrBefore(lastRecordMomentDate)) {
				return marpolDateUtils.getDateInISO(
					lastRecordMomentDate.format("YYYY-MM-DD")
				);
			}
		}

		return marpolDateUtils.getDateInISO(dateOfSubmission);
	},
	getDate(dateToParse: string) {
		const isDateWithTimezone = dateToParse.includes("T");
		if (isDateWithTimezone) return dateToParse.split("T")[0];
		return dateToParse;
	},
	getDayFromDate(dateToUse: string) {
		const date = marpolDateUtils.getDate(dateToUse);
		const day = date.split("-")[2];
		return parseInt(day);
	},
	getDaysNumber(dateToUse: string) {
		const date = marpolDateUtils.getDate(dateToUse);
		const day = parseInt(date.split("-")[2]);
		const daysInmonth = parseInt(date.split("-")[1]) * 30;
		const daysInYear = parseInt(date.split("-")[0]) * 365;
		const dayPlusMonth = commonUtils.addUp(day, daysInmonth);
		return commonUtils.addUp(daysInYear, dayPlusMonth);
	},
	optimizeDateSubmission(
		submittedDate: string,
		lastRecordDate?: string,
		isAnMissedEntryOperation?: boolean
	) {
		const submissionDay = marpolDateUtils.getDaysNumber(submittedDate);
		if (lastRecordDate && !isAnMissedEntryOperation) {
			const lastRecordDay = marpolDateUtils.getDaysNumber(lastRecordDate);
			if (submissionDay < lastRecordDay) return lastRecordDate;
		}
		const dateWithoutTime = marpolDateUtils.getDate(submittedDate);
		return dateWithoutTime + "T00:00:00+00:00";
	},
	optimizeDateSelection(
		selectedDate: string,
		lastValidRecordDate?: string,
		isAnMissedEntryOperation?: boolean
	) {
		if (selectedDate === "") return;

		const currentDate = moment().format("YYYY-MM-DD");
		const selectedDateWithoutTime = marpolDateUtils.getDate(selectedDate);
		const selectedDateDay = marpolDateUtils.getDaysNumber(selectedDate);
		const currentDateDay = marpolDateUtils.getDaysNumber(currentDate);

		if (lastValidRecordDate) {
			const lastRecordDateWithoutTime =
				marpolDateUtils.getDate(lastValidRecordDate);
			const lastValidRecordDay =
				marpolDateUtils.getDaysNumber(lastValidRecordDate);
			const isBeforeLastRecordDate = selectedDateDay < lastValidRecordDay;
			if (isBeforeLastRecordDate && !isAnMissedEntryOperation)
				return lastRecordDateWithoutTime;
			return selectedDateWithoutTime;
		}

		if (selectedDateDay < currentDateDay) return selectedDateWithoutTime;
	},
	getAllowedDatesForOperationSubmission(
		lastValidRecordDate?: string,
		isAnMissedEntryOperation?: boolean
	) {
		if (isAnMissedEntryOperation)
			return {
				startDate: undefined,
				endDate: moment.utc().format("YYYY-MM-DD"),
			};

		if (lastValidRecordDate)
			return {
				startDate: moment.utc(lastValidRecordDate).format("YYYY-MM-DD"),
				endDate: moment.utc().format("YYYY-MM-DD"),
			};

		return {
			startDate: moment.utc().subtract(30, "days").format("YYYY-MM-DD"),
			endDate: moment.utc().format("YYYY-MM-DD"),
		};
	},
	getFormattedWeekAsPerDate(date: string) {
		return moment(date).format("GGGG-[W]WW");
	},
	getWeekAsPerDate(date: Date) {
		return {
			startDate: moment(date)
				.startOf("week")
				.add(12, "hours")
				.format("YYYY-MM-DD HH:mm:ss"),
			endDate: moment(date)
				.endOf("week")
				.add(12, "hours")
				.format("YYYY-MM-DD HH:mm:ss"),
		};
	},
	getCurrentWeek() {
		return {
			asPerSunday: {
				startDate: moment
					.utc()
					.startOf("week")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment
					.utc()
					.endOf("week")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
			},
			asPerMonday: {
				startDate: moment
					.utc()
					.startOf("week")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment
					.utc()
					.endOf("week")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
			},
		};
	},
	getPreviousWeek() {
		return {
			asPerSunday: {
				startDate: moment
					.utc()
					.startOf("week")
					.subtract(7, "days")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment
					.utc()
					.endOf("week")
					.subtract(7, "days")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
			},
			asPerMonday: {
				startDate: moment
					.utc()
					.startOf("week")
					.subtract(7, "days")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment
					.utc()
					.subtract(7, "days")
					.endOf("week")
					.add(12, "hours")
					.format("YYYY-MM-DD HH:mm:ss"),
			},
		};
	},
	isPrintAllowed(date: string) {
		const date3MonthsBack = moment().subtract(3, "months");
		const dateToCompare = moment(date);
		return dateToCompare.isAfter(date3MonthsBack);
	},
};

export default marpolDateUtils;
