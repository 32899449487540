import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupA1 } from "../../../../../redux/app/orb1/groups/group-a";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupA1PreviewProps = OwnProps;
function ORB1GroupA1Preview(props: ORB1GroupA1PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupA1;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "A", 1, renderItem1()],
					[...renderItem2Row()],
					[null, null, "3.1", renderItem3P1Start()],
					[null, null, null, renderItem3P1Completion()],
					[null, null, 3.2, renderItem3P2()],
					...renderItem3P3Rows(),
					[null, null, 4.1, "N/A"],
					[null, null, 4.2, "N/A"],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem1() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item1.foTanks.map((tank) => (
					<React.Fragment key={tank.id}>
						CLEANING OF {tank.tankName}
						<br />
					</React.Fragment>
				))}
			</h4>
		);
	}

	function renderItem2Row(): ORB1TableColumns {
		const { isOilCleaned } = orb1Record.item2;

		function getDateSection() {
			if (isOilCleaned === "YES") return null;
			const dateOfLastCleaning = moment(
				orb1Record.item2.dateOfLastCleaning,
				"YYYY-MM-DD"
			).format("DD-MMM-YYYY");
			return dateOfLastCleaning;
		}
		const dateSection = getDateSection();
		if (isOilCleaned === "YES") return [dateSection, null, "2", "CLEANED: YES"];

		const content = (
			<h4 className="font-medium uppercase">
				CLEANED: NO, TYPE OF OIL: {orb1Record.item2.typeOfOilPreviouslyCarried},
				DENSITY: {orb1Record.item2.densityOrViscosity}, COMMERCIAL NAME:{" "}
				{orb1Record.item2.oilCommercialName}
			</h4>
		);
		return [dateSection, null, "2", content];
	}

	function renderItem3P1Start() {
		const { date, time, latitude, longitude } =
			orb1Record.item3P1.startOfCleaning;
		const startOfCleaningDate = moment(date, "YYYY-MM-DD").format(
			"DD-MMM-YYYY"
		);
		return (
			<h4 className="font-medium uppercase">
				START {startOfCleaningDate} {time} LT AT POSITION
				<br /> LAT: {latitude} LON: {longitude}{" "}
			</h4>
		);
	}

	function renderItem3P1Completion() {
		const { date, time, latitude, longitude } =
			orb1Record.item3P1.completionOfCleaning;
		const completionOfCleaningDate = moment(date, "YYYY-MM-DD").format(
			"DD-MMM-YYYY"
		);
		return (
			<h4 className="font-medium uppercase">
				STOP AT {completionOfCleaningDate} {time} LT AT POSITION
				<br /> LAT: {latitude}, LON: {longitude}{" "}
			</h4>
		);
	}

	function renderItem3P2() {
		const { cleaningMethod } = orb1Record.item3P2;
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item1.foTanks.map((tank, index) => (
					<React.Fragment key={index}>
						{tank.tankName.toUpperCase()},{" "}
						{cleaningMethod === "CHEMICALS"
							? `${cleaningMethod} METHOD / ${orb1Record.item3P2.typeOfChemical}, ${orb1Record.item3P2.quantity}M³`
							: `${cleaningMethod} METHOD`}{" "}
						<br />
					</React.Fragment>
				))}
			</h4>
		);
	}

	function renderItem3P3Rows(): ORB1TableColumns[] {
		const { isDisposalTankDetailsAvailable } = orb1Record.item3P3;
		if (isDisposalTankDetailsAvailable === "NO")
			return [[null, null, 3.3, "N/A"]];

		let rows: ORB1TableColumns[] = [];
		const { disposalTanks } = orb1Record.item3P3;
		disposalTanks.map((tank, index) => {
			const content = (
				<h4 className="font-medium uppercase">
					DISPOSED {tank.transferQuantity.toFixed(2)}
					M³ TO {tank.selectedTank.tankName}
				</h4>
			);
			rows.push([null, null, index === 0 ? "3.3" : null, content]);
		});
		return rows;
	}

	return renderMain();
}

export default ORB1GroupA1Preview;
