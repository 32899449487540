import React from "react";
import { rootRoutes } from "../../routes";
import App from "../../components/app/App";
import { Switch, Route } from "react-router-dom";
import ScreenVessel from "./vessel";
import ScreenLog from "./log";
import Approval from "./approval";
import ScreenMarpol from "./marpol";
import Checklist from "./checklist";
import ScreenFeedback from "./feedback";
import ScreenUser from "./user";
import ScreenDashboard from "./dashboard";
import ScreenReport from "./report";
import ScreenTool from "./tools";
import ScreenInsight from "./insight";

const appRoutes = rootRoutes.children.app.children;
type ScreenAppType = {};
const ScreenApp: React.FC<ScreenAppType> = (props: ScreenAppType) => {
	return (
		<App>
			<Switch>
				<Route path={appRoutes.log.path} component={ScreenLog} />
				<Route path={appRoutes.vessel.path} component={ScreenVessel} />
				<Route path={appRoutes.approval.path} component={Approval} />
				<Route path={appRoutes.marpol.path} component={ScreenMarpol} />
				<Route path={appRoutes.checklist.path} component={Checklist} />
				<Route path={appRoutes.feedback.path} component={ScreenFeedback} />
				<Route path={appRoutes.dashboard.path} component={ScreenDashboard} />
				<Route path={appRoutes.users.path} component={ScreenUser} />
				<Route path={appRoutes.reports.path} component={ScreenReport} />
				<Route path={appRoutes.tools.path} component={ScreenTool} />
				<Route path={appRoutes.insights.path} component={ScreenInsight} />

				{/* TODO: Add 404 page here */}
			</Switch>
		</App>
	);
};

export default ScreenApp;
