import { Action } from "redux";
import { AxiosResponse } from "axios";
import { IAction } from "../../_common/type";
export interface IAPIRequestMetaData<TResponseDataFormat> {
	/**
	 * The type of API Call
	 */
	method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
	/**
	 * The URL path
	 */
	url: string;
	/**
	 * The Body params
	 */
	body?: any;
	/**
	 * The Body params
	 */
	queryParams?: object;
	/**
	 * Feature name that is being processed
	 */
	feature: string;
	/**
	 * Override baseUrl of application
	 */
	baseURL?: string;
	/**
	 * Pre API Request actions to be called
	 */
	preExecute?: () => void;
	/**
	 * Post API Request actions to be called
	 */
	postExecute?: {
		/**
		 * Success actions
		 */
		onSuccess?: (response: AxiosResponse<TResponseDataFormat>) => void;
		/**
		 * Error actions
		 */
		onError?: (error: IAPIErrorParams) => void;
		/**
		 * Final actions
		 */
		finally?: () => void;
	};
	headers?: {
		"Content-Type"?:
			| "application/java-archive"
			| "application/EDI-X12"
			| "application/EDIFACT"
			| "application/javascript"
			| "application/octet-stream"
			| "application/ogg"
			| "application/pdf"
			| "application/xhtml+xml"
			| "application/x-shockwave-flash "
			| "application/json"
			| "application/ld+json"
			| "application/xml"
			| "application/zip"
			| "application/x-www-form-urlencoded"
			| "multipart/mixed"
			| "multipart/alternative"
			| "multipart/related"
			| "multipart/form-data";
	};
}
export interface IAPIRequest<TResponseDataFormat> {
	type: string;
	/**
	 * Meta information for middlewares
	 */
	meta: IAPIRequestMetaData<TResponseDataFormat>;
}
export interface IAPIRequestParams {
	/**
	 * The Payload to send to the Backend
	 */
	body: any;
	/**
	 * The type of API Call
	 */
	method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
	/**
	 * The URL path
	 */
	url: string;
	/**
	 * Feature name that is being processed
	 */
	feature: string;
}
export interface IAPISuccess {
	type: string;
	/**
	 * The Payload response got from Backend
	 */
	payload: any;
	meta: {
		/**
		 * Feature name that is being processed
		 */
		feature: string;
		trueType?: string;
	};
}
export interface IAPISuccessParams {
	/**
	 * The response object from backend
	 */
	response: any;
	/**
	 * Feature name that was processed
	 */
	feature: string;
}
export interface IAPIError {
	type: string;
	/**
	 * The Payload Error or error message
	 */
	payload: any;
	meta: {
		/**
		 * Feature name that got an error from backend
		 */
		feature: string;
		trueType?: string;
		response?: AxiosResponse;
	};
}
export type IAPI_HeaderSet__Action = IAction<{
	header: string;
	value: any;
}>;
export interface IAPIErrorParams {
	/**
	 * The error message for api failure
	 */
	error: string;
	/**
	 * Response from server
	 */
	response?: AxiosResponse;
	/**
	 * Response from server
	 */
	request?: any;
	/**
	 * Server error message if any
	 */
	_serverErrorMessage: string;
}

export interface IAPIErrorParamsWithFeature extends IAPIErrorParams {
	/**
	 * Feature name that was processed
	 */
	feature: string;
}

export enum APIStatusForSuccess {
	/**
	 * `Continue`
	 */
	"STATUS-100" = 100,
	/**
	 * `Switching Protocols`
	 */
	"STATUS-101" = 101,
	/**
	 * `Processing`
	 */
	"STATUS-102" = 102,
	/**
	 * `OK`
	 */
	"STATUS-200" = 200,
	/**
	 * `Created`
	 */
	"STATUS-201" = 201,
	/**
	 * `Accepted`
	 */
	"STATUS-202" = 202,
	/**
	 * `Non-authoritative Information`
	 */
	"STATUS-203" = 203,
	/**
	 * `No Content`
	 */
	"STATUS-204" = 204,
	/**
	 * `Reset Content`
	 */
	"STATUS-205" = 205,
	/**
	 * `Partial Content`
	 */
	"STATUS-206" = 206,
	/**
	 * `Multi-Status`
	 */
	"STATUS-207" = 207,
	/**
	 * `Already Reported`
	 */
	"STATUS-208" = 208,
	/**
	 * `IM Used`
	 */
	"STATUS-226" = 226,
}
export enum APIStatus {
	/**
	 * `Continue`
	 */
	"STATUS-100" = 100,
	/**
	 * `Switching Protocols`
	 */
	"STATUS-101" = 101,
	/**
	 * `Processing`
	 */
	"STATUS-102" = 102,
	/**
	 * `OK`
	 */
	"STATUS-200" = 200,
	/**
	 * `Created`
	 */
	"STATUS-201" = 201,
	/**
	 * `Accepted`
	 */
	"STATUS-202" = 202,
	/**
	 * `Non-authoritative Information`
	 */
	"STATUS-203" = 203,
	/**
	 * `No Content`
	 */
	"STATUS-204" = 204,
	/**
	 * `Reset Content`
	 */
	"STATUS-205" = 205,
	/**
	 * `Partial Content`
	 */
	"STATUS-206" = 206,
	/**
	 * `Multi-Status`
	 */
	"STATUS-207" = 207,
	/**
	 * `Already Reported`
	 */
	"STATUS-208" = 208,
	/**
	 * `IM Used`
	 */
	"STATUS-226" = 226,
	/**
	 * `Multiple Choices`
	 */
	"STATUS-300" = 300,
	/**
	 * `Moved Permanently`
	 */
	"STATUS-301" = 301,
	/**
	 * `Found`
	 */
	"STATUS-302" = 302,
	/**
	 * `See Other`
	 */
	"STATUS-303" = 303,
	/**
	 * `Not Modified`
	 */
	"STATUS-304" = 304,
	/**
	 * `Use Proxy`
	 */
	"STATUS-305" = 305,
	/**
	 * `Temporary Redirect`
	 */
	"STATUS-307" = 307,
	/**
	 * `Permanent Redirect`
	 */
	"STATUS-308" = 308,
	/**
	 * `Bad Request`
	 */
	"STATUS-400" = 400,
	/**
	 * `Unauthorized`
	 */
	"STATUS-401" = 401,
	/**
	 * `Payment Required`
	 */
	"STATUS-402" = 402,
	/**
	 * `Forbidden`
	 */
	"STATUS-403" = 403,
	/**
	 * `Not Found`
	 */
	"STATUS-404" = 404,
	/**
	 * `Method Not Allowed`
	 */
	"STATUS-405" = 405,
	/**
	 * `Not Acceptable`
	 */
	"STATUS-406" = 406,
	/**
	 * `Proxy Authentication Required`
	 */
	"STATUS-407" = 407,
	/**
	 * `Request Timeout`
	 */
	"STATUS-408" = 408,
	/**
	 * `Conflict`
	 */
	"STATUS-409" = 409,
	/**
	 * `Gone`
	 */
	"STATUS-410" = 410,
	/**
	 * `Length Required`
	 */
	"STATUS-411" = 411,
	/**
	 * `Precondition Failed`
	 */
	"STATUS-412" = 412,
	/**
	 * `Payload Too Large`
	 */
	"STATUS-413" = 413,
	/**
	 * `Request-URI Too Long`
	 */
	"STATUS-414" = 414,
	/**
	 * `Unsupported Media Type`
	 */
	"STATUS-415" = 415,
	/**
	 * `Requested Range Not Satisfiable`
	 */
	"STATUS-416" = 416,
	/**
	 * `Expectation Failed`
	 */
	"STATUS-417" = 417,
	/**
	 * `I'm a teapot`
	 */
	"STATUS-418" = 418,
	/**
	 * `Misdirected Request`
	 */
	"STATUS-421" = 421,
	/**
	 * `Unprocessable Entity`
	 */
	"STATUS-422" = 422,
	/**
	 * `Locked`
	 */
	"STATUS-423" = 423,
	/**
	 * `Failed Dependency`
	 */
	"STATUS-424" = 424,
	/**
	 * `Upgrade Required`
	 */
	"STATUS-426" = 426,
	/**
	 * `Precondition Required`
	 */
	"STATUS-428" = 428,
	/**
	 * `Too Many Requests`
	 */
	"STATUS-429" = 429,
	/**
	 * `Request Header Fields Too Large`
	 */
	"STATUS-431" = 431,
	/**
	 * `Connection Closed Without Response`
	 */
	"STATUS-444" = 444,
	/**
	 * `Unavailable For Legal Reasons`
	 */
	"STATUS-451" = 451,
	/**
	 * `Client Closed Request`
	 */
	"STATUS-499" = 499,
	/**
	 * `Internal Server Error`
	 */
	"STATUS-500" = 500,
	/**
	 * `Not Implemented`
	 */
	"STATUS-501" = 501,
	/**
	 * `Bad Gateway`
	 */
	"STATUS-502" = 502,
	/**
	 * `Service Unavailable`
	 */
	"STATUS-503" = 503,
	/**
	 * `Gateway Timeout`
	 */
	"STATUS-504" = 504,
	/**
	 * `HTTP Version Not Supported`
	 */
	"STATUS-505" = 505,
	/**
	 * `Variant Also Negotiates`
	 */
	"STATUS-506" = 506,
	/**
	 * `Insufficient Storage`
	 */
	"STATUS-507" = 507,
	/**
	 * `Loop Detected`
	 */
	"STATUS-508" = 508,
	/**
	 * `Not Extended`
	 */
	"STATUS-510" = 510,
	/**
	 * `Network Authentication Required`
	 */
	"STATUS-511" = 511,
	/**
	 * `Network Connect Timeout Error`
	 */
	"STATUS-599" = 599,
	/**
	 * `No Response from server`
	 */
	"NO-RESPONSE" = "NO-RESPONSE",
	/**
	 * `Application level error`
	 */
	"APPLICATION-ERROR" = "APPLICATION-ERROR",
}
