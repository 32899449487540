import classNames from "classnames";
import React from "react";
type OwnProps = { className?: any; fillColor?: any; pathColor?: any };
type SVGDashboardNewProps = OwnProps;
function SVGDashboardNew(props: SVGDashboardNewProps) {
	const { className, fillColor, pathColor } = props;

	let isActive = className.includes("bg-blue-100");

	return (
		<svg
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill={isActive ? fillColor ?? "none" : "white"}
			xmlns="http://www.w3.org/2000/svg"
			className={classNames(className)}
		>
			<path
				d="M24.0001 5.71436H8.00007C6.7377 5.71436 5.71436 6.7377 5.71436 8.00007V24.0001C5.71436 25.2624 6.7377 26.2858 8.00007 26.2858H24.0001C25.2624 26.2858 26.2858 25.2624 26.2858 24.0001V8.00007C26.2858 6.7377 25.2624 5.71436 24.0001 5.71436Z"
				stroke={pathColor ?? "#3f3f3f"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.71436 12.5713H26.2858"
				stroke={pathColor ?? "#3f3f3f"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5713 26.2856V12.5713"
				stroke={pathColor ?? "#3f3f3f"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default SVGDashboardNew;
