import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupD2 } from "../../../../../redux/app/orb1/groups/group-d";
import CommaSeparator from "../../../../_common/CommaSeparator";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupD2PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupD2Preview(props: ORB1GroupD2PreviewProps) {
	// const { isPrimaryBilgeTankPresent } = props;
	const orb1Record = props.orb1Record as TORB1GroupD2;
	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						D
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">13</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.item13.quantityDischarged.toFixed(2)} M³ FROM{" "}
							{orb1Record.item14.bilgeSource.tankName}{" "}
						</h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{" "}
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">14</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							STARTED {orb1Record.item14.timeOfDischarge.start.time} / FINISHED{" "}
							{orb1Record.item14.timeOfDischarge.end.time}
						</h4>
					</div>
				</td>
			</tr>
			{orb1Record.item15P2.methodOfDischarge.method ===
			"THROUGH 15 PPM EQUIPMENT" ? (
				<>
					<tr
						className={classNames("uppercase", {
							"line-through": props.isStrikedOff,
						})}
					>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full">
									<span className="block font-medium whitespace-nowrap">
										{}
									</span>
								</div>
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								{" "}
								{}
							</div>
						</td>
						<td className="relative">
							<div
								className={classNames(
									{ "line-through": props.isStrikedOff },
									"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
								)}
							>
								<div className="h-full font-medium">15.1</div>
							</div>
						</td>
						<td className="relative">
							<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
								<h4 className="font-medium uppercase">
									{orb1Record.item15P2.methodOfDischarge.method} <br />
									POSITION AT START{" "}
									{
										orb1Record.item15P2.methodOfDischarge.startOfDischarge
											.latitude
									}
									{" , "}
									{
										orb1Record.item15P2.methodOfDischarge.startOfDischarge
											.longitude
									}
									<br />
									POSITION AT END{" "}
									{
										orb1Record.item15P2.methodOfDischarge.endOfDischarge
											.latitude
									}
									{" , "}
									{
										orb1Record.item15P2.methodOfDischarge.endOfDischarge
											.longitude
									}
									<br />
									QUANTITY REMAINING IN {
										orb1Record.item14.bilgeSource.tankName
									}{" "}
									{(
										marpolUtils.getQuantityForCalculation(
											orb1Record.item14.bilgeSource
										) - orb1Record.item13.quantityDischarged
									).toFixed(2)}{" "}
									M³
								</h4>
							</div>
						</td>
					</tr>
				</>
			) : null}
			{orb1Record.item15P2.methodOfDischarge.method ===
			"TO_RECEPTION_FACILITES" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">15.2</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="w-full h-full font-medium uppercase">
								TO {orb1Record.item15P2.methodOfDischarge.port} PORT RECEPTION
								FACILITIES RECEIPT NO:{" "}
								{orb1Record.item15P2.methodOfDischarge.receiptNumber}
								<br />
								TANK CAPACITY{" "}
								{orb1Record.item14.bilgeSource.totalCapacity.toFixed(2)}
								M³, QUANTITY RETAINED{" "}
								{(
									marpolUtils.getQuantityForCalculation(
										orb1Record.item14.bilgeSource
									) - orb1Record.item13.quantityDischarged
								).toFixed(2)}
								M³
								<br />
								ATTACHMENT:{" "}
								<a
									href={marpolUtils.getAttachmentURL(
										orb1Record.item15P2.methodOfDischarge.receiptAttachment,
										orb1Record.id,
										orb1Record._rev
									)}
									download={
										orb1Record.item15P2.methodOfDischarge.receiptAttachment
									}
								>
									{" "}
									{orb1Record.item15P2.methodOfDischarge.receiptAttachment}
								</a>
							</div>
						</div>
					</td>
				</tr>
			) : null}

			{orb1Record.remarks !== "" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{orb1Record.remarks !== "" ? orb1Record.remarks : null}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default ORB1GroupD2Preview;
