import React, { useEffect, useState } from "react";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { checklistActions } from "../../../../redux/app/checklist";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import classNames from "classnames";
import moment from "moment";
import LogTemplateRenderer from "../../log/list/_elements/TemplateRenderer";

type ChecklistListProps = PropsFromRedux;
function ChecklistList(props: ChecklistListProps) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => props.loadChecklists(), []);
	const { checklists, listLoadJob } = props;

	const [logOperation, setLogOperation] = useState<{
		isModalOpen: boolean;
		logId: string;
	}>({
		isModalOpen: false,
		logId: "",
	});

	function resetOperations() {
		setLogOperation({
			...logOperation,
			isModalOpen: false,
			logId: "",
		});
	}

	return (
		<div className="min-h-full px-6 py-6 mb-6 bg-white rounded-md">
			<div
				className={classNames(
					"flex flex-row items-end justify-between mx-6 my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Checklists
					</h3>
				</div>
			</div>
			<div className="m-6">
				<table
					className={classNames(
						"w-full my-6 border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{ "animate-pulse": listLoadJob.isLoading }
					)}
				>
					<thead>
						<tr>
							<th className="text-sm text-gray-500">Title</th>
							<th className="text-sm text-gray-500">Author</th>
							<th className="text-sm text-gray-500">
								Vessel Name
							</th>
							<th className="text-sm text-gray-500">
								Created At
							</th>
							<th className="text-sm text-gray-500">Actions</th>
						</tr>
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
								listLoadJob.isLoading && checklists.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!listLoadJob.isLoading,
						})}
					>
						{checklists.map((checklist: any) => {
							return (
								<tr key={checklist.id}>
									<td className="py-1 font-medium text-gray-800">
										{checklist.title}
									</td>
									<td className="font-mono text-left text-gray-700">
										{checklist.author}
									</td>
									<td className="font-mono text-left text-gray-700">
										{checklist.vesselName}
									</td>
									<td>
										<span className="py-1 font-mono text-gray-700">
											{moment(checklist.createdAt).format(
												"MM-DD-YYYY hh:mm"
											)}
										</span>
									</td>
									<td className="-mx-1 text-left">
										<button
											className="font-medium text-blue-500 underline bg-transparent border-0 shadow-none hover:text-blue-700"
											onClick={function () {
												props.selectChecklist(checklist)
											}}
										>
											Edit
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot />
				</table>
				<div
					className={classNames({
						"h-auto opacity-100 transition-all duration-300 animate-pulse":
							listLoadJob.isLoading && checklists.length === 0,
						"h-0 opacity-0 transition-all duration-300 animate-pulse":
							!listLoadJob.isLoading,
					})}
				>
					<InfiniteProgress
						isLoading={listLoadJob.isLoading}
						isSpacedOut={false}
						className="-my-6"
					/>

					{listLoadJob.isLoading ? (
						<div className="flex-col justify-center h-64 py-24 my-6 bg-gray-300 ">
							<h4 className="-my-3 text-2xl text-center font-redhat-display">
								Loading please wait..
							</h4>
						</div>
					) : null}
				</div>

				{/* <PaginationBox /> */}
			</div>
			<div
				className={classNames("ws-modal h-auto", {
					"opacity-0 pointer-events-none": !logOperation.isModalOpen,
					"opacity-1": logOperation.isModalOpen,
				})}
			>
				<div className="opacity-50 ws-modal__overlay" />
				<div
					className={classNames("ws-modal__container ", {
						" pointer-events-none ": !logOperation.isModalOpen,
						"md:w-4/5 sm:w-full lg:w-3/5": !true,
						"w-full max-h-full overflow-y-auto ws-scroll": true,
					})}
				>
					{logOperation.isModalOpen ? (
						<LogTemplateRenderer
							onClose={function onClose() {
								resetOperations();
							}}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const checklistStore: any = store.app.checklistStore;
	return {
		checklists: checklistStore.checklists,
		listLoadJob: {
			isLoading: false,
		},
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadChecklists() {
			dispatch(checklistActions.listLoad(true));
		},
		selectChecklist(checklist: any) {
			dispatch(checklistActions.loadChecklist({ checklist }));
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ChecklistList);
