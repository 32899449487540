import classNames from "classnames";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IChangeLogActivity } from "redux/app/tool/@types";
import userSelectors from "redux/app/user/selectors";
import { IStore } from "redux/store";
import RenderActivity from "./RenderActivity";

type OwnProps = {
	activities: IChangeLogActivity[];
};
type ActivitiesRendererProps = PropsFromRedux & OwnProps;
function ActivitiesRenderer(props: ActivitiesRendererProps) {
	let { activities, portalUsers } = props;
	activities = activities.reverse();
	return (
		<>
			{activities?.length ? (
				<div className="h-full">
					{/* <h5 className="font-medium ml-1">Activity log</h5> */}
					<div className=" overflow-y-auto relative p-4">
						<ol className="relative  border-l-[1.5px]  border-gray-400 ">
							{activities?.map((activity, index) => {
								const user = portalUsers?.byIds[activity?.userId];
								return (
									<li key={activity.item + activity.date} className="mb-4 ml-4">
										<div
											className={classNames(
												"absolute  bg-blue-500 rounded-full   mt-1.5 border border-white",
												{
													"w-3 h-3 -left-[6.5px]": index,
													"w-5 h-5 -left-[10.5px]": !index,
												}
											)}
										>
											<div className="relative flex justify-center items-center">
												<div
													className={classNames(
														"absolute  bg-blue-100 rounded-full ",
														{
															"w-1.5 h-1.5  mt-[10px]": index,
															"w-2.5 h-2.5 mt-[17px]": !index,
														}
													)}
												/>
											</div>
										</div>
										<div className="-mt-2">
											<RenderActivity activity={activity} />
										</div>
										<p className="m-0 text-[12px] text-gray-500">
											{moment(activity.date).format("DD/MM/YYYY hh:mm a")}
										</p>
										<div className="-mt-1 text-[#00000099]">
											<span className="text-[12px] m-0 italic">
												by {user?.name ?? "You"}
											</span>
										</div>
									</li>

									// <RenderActivity key={activity.changedAt} activity={activity} />
								);
							})}
						</ol>
					</div>
				</div>
			) : null}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const usersStore = userSelectors._getStore(store);
	const users = userSelectors.getUsersList(usersStore);
	return {
		portalUsers: users,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ActivitiesRenderer);
