import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import toolActions from "redux/app/tool/actions";
import { IPrintInfo } from "redux/app/tool/@types";
import PrintForm from "../../_elements/PrintForm";

type OwnProps = {};
type PrintViewProps = PropsFromRedux & OwnProps;
function PrintView(props: PrintViewProps) {
	useEffect(() => {
		return () => {
			props.setPrintInfo(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<PrintForm
				action=""
				actionLabel="Print Info"
				onClose={() => props.togglePrintViewModal()}
				type="view"
				isDisabled={true}
			/>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		togglePrintViewModal() {
			dispatch(toolActions.document.printViewModalToggle());
		},
		setPrintInfo(print?: IPrintInfo) {
			dispatch(toolActions.document.toolPrintInfoSet(print));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PrintView);
