import Dropdown from 'components/_common/Dropdown';
import InputWrapper from 'components/_common/InputWrapper';
import React from 'react';

type WayshipDropdownProps = React.PropsWithChildren<{}>;
function WayshipDropdownDeck({
    title,
    dataKey,
    value,
    range,
    onChangeHandler,
    isSubmit,
    error,
    onFocus,
}: any) {
    return (
        <InputWrapper
            className="w-full"
            hasError={
                error && error.hasOwnProperty('isValid')
                    ? !error.isValid
                    : false
            }
            hasWarning={false}
            inputValue={value}
        >
            <label htmlFor="number-input-random" className="ws-input__label">
                {title}
            </label>
            <Dropdown
                id="email-id"
                inputWrapperProps={{
                    className: '',
                }}
                options={
                    range
                        ? range.length > 0 &&
                          range.map((option: any) => {
                              return {
                                  label: option.label,
                                  value: option.value,
                              };
                          })
                        : []
                }
                onSelect={(selectedItem: any) => {
                    onChangeHandler(selectedItem.value, dataKey);
                }}
                placeholder="Select"
                noFilter={true}
                value={value}
                disabled={isSubmit}
                isReadOnly={isSubmit}
                onFocus={() => {
                    if (onFocus) {
                        onFocus(title);
                    } else {
                        console.log('onFocus not provided!');
                    }
                }}
            />
            <span className="ws-input__error">{error?.message || ''}</span>
            <span className="ws-input__warning">Hello Warning</span>
        </InputWrapper>
    );
}
export default WayshipDropdownDeck;
