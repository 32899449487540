import React from "react";
import MarpolList from "../../../../../components/app/marpol/list/List";

type ScreenVesselOnboardMarpolType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
			pageNumber: string;
		};
	};
}>;
const ScreenVesselOnboardMarpolList: React.FC<ScreenVesselOnboardMarpolType> = (
	props: ScreenVesselOnboardMarpolType
) => {
	const {match: {params: {vesselId}}} = props
	return <MarpolList vesselId={vesselId} />
};

export default ScreenVesselOnboardMarpolList;
