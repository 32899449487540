import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupI2 } from "../../../../../redux/app/annex6/groups/group-i";
import { marpolUtils } from "../../../../../redux/app/marpol";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupI2PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupI2Preview(props: Annex6GroupI2PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupI2;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'I', 20.1, renderItem20P1()],
                    [null, null, 20.2, renderItem20P2()],
                    [null, null, 20.3, renderItem20P3()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem20P1() {
        return (
            <h4 className="font-medium uppercase">
                {record.item20P1.placeOfDisposal}
                <br />
                {record.item20P1?.location?.latitude &&
                    record.item20P1?.location?.latitude}{' '}
                {''}
                {record.item20P1?.location?.longitude &&
                    record.item20P1?.location?.longitude}
            </h4>
        );
    }

    function renderItem20P2() {
        return (
            <h4 className="font-medium uppercase">
                {record.item20P2.timeOfDisposal} LT
            </h4>
        );
    }

    function renderItem20P3() {
        return (
            <h4 className="font-medium uppercase">
                {record.item20P3.odsName} {record.item20P3.quantityDisposed} KG
                <br />
                <br />
                {record.item20P3?.receiptAttachment ? (
                    <>
                        RECEIPT NO. {record.item20P3.receiptNumber} <br />
                        ATTACHMENT:{' '}
                        <a
                            href={marpolUtils.getAttachmentURL(
                                record.item20P3.receiptAttachment,
                                record.id,
                                record._rev
                            )}
                            download={record.item20P3.receiptAttachment}
                        >
                            {' '}
                            {record.item20P3.receiptAttachment}
                        </a>
                    </>
                ) : null}
            </h4>
        );
    }

    return renderMain();
}

export default Annex6GroupI2Preview;
