import { jobActions } from "redux/_core/job";
import { createAction, getType } from "typesafe-actions";
import {
	ETicketTabType,
	ETypeOfFilter,
	IDashboardStatsAndFeedbackPayload,
	IDashboardStatsRes,
	IFeedback,
	IFeedbackActivity,
	IFeedbackChat,
	IFeedbackDashboardRequestPayloadFilter,
	IFeedbackFilters,
	IFeedbackUpdatePayload,
	IFilterState,
	IIndividualFeedbackWithChat,
	INotification,
	IVessel,
	IVesselUser,
	TCreateMessageFrom,
	TFeedback,
	TTaskTemplate,
} from "./@types";
import feedbackConstants, { FILTER_SECTIONS } from "./constants";
import { IListObjectStore } from "redux/_common/type";
import { Range } from "react-date-range";

const NOTIFICATIONS_SET = "@feedbacks/notifications/SET"; // COMMAND ACTION
const NOTIFICATIONS_APPEND = "@feedbacks/notifications/APPEND"; // COMMAND ACTION
const NOTIFICATIONS_LOAD = "@feedbacks/notifications/LOAD"; // COMMAND ACTION
const FEEDBACK_LIST_LOAD = "@feedbacks/list/LOAD"; // COMMAND ACTION
const FEEDBACK_VESSELS_LOAD = "@feedbacks/vessels/LOAD"; // COMMAND ACTION
const FEEDBACK_CHATS_LOAD = "@feedbacks/chats/LOAD";
const INDIVIDUAL_FEEDBACK_LOAD = "@feedbacks/individual-feedback/LOAD";
const FEEDBACK_MESSAGE_CREATE = "@feedbacks/chat/message/create";
const FEEDBACK_TOTAL_COUNT_LOAD = "@feedbacks/list/count/LOAD";
const CHAT_ATTACHMENT_UPLOAD = "@feedbacks/chat-attachment-upload";
const CHAT_MESSAGE_UPDATE = "@feedbacks/chat-message/:update";
const FEEDBACK_UPDATE = "@feedbacks/feedback/:update";
const FEEDBACK_TASKS_LOAD = "@feedbacks/:task-list-load";
const FEEDBACK_SEND = "@feedbacks/feedback/:send";
const FEEDBACK_VESSEL_SPECIFIC_USERS_LOAD =
	"@feedbacks/vessel-specific-users/LOAD";
const FEEDBACK_DASHBOARD_LIST_LOAD = "@feedbacks/dashboard/:filter/list/LOAD";
const FEEDBACK_DASHBOARD_STATS_OR_FEEDBACK_LOAD =
	"@feedbacks/dashboard/stats-or-feedbacks/LOAD";

const FEEDBACK_LIST_SET = "@feedbacks/list/SET";
const FEEDBACK_DASHBOARD_LIST_SET = "@feedbacks/dashboard/list/SET"; //TODO: needs to be removed;
const FEEDBACK_VESSELS_SET = "@feedbacks/vessels/SET";
const FEEDBACK_CHAT_MODAL_TOGGLE = "@feedbacks/chats/toggle";
const FEEDBACK_SELECTED_ID_SET = "@feedbacks/selected/feedback-id/SET";
const FEEDBACK_SELECTED_VESSEL_ID_SET = "@feedbacks/selected/vessel-id/SET";
const FEEDBACK_CHATS_SET = "@feedbacks/chats/SET";
const FEEDBACK_CHATS_SET_IN_INDIVIDUAL_FEEDBACK =
	"@feedbacks/individual-feedback/chats/SET";
const CHAT_MESSAGE_SET = "@feedbacks/chat/message/set";
const ADD_CHAT_TO_CHAT_LIST = "@feedbacks/feedback/chat/add";
const ADD_CHAT_TO_CHAT_LIST_IN_INDIVIDUAL_FEEDBACK =
	"@feedbacks/individual-feedback/chat/add";
const FEEDBACK_SEARCH_BY_ISSUE = "@feedbacks/search-by-issue/set";
const FEEDBACK_FILTERS_SET = "@feedbacks/filters/set";
const NOTIFICATION_PAGINATION_TOTAL_ITEMS_SET = "@feedbacks/notificationsPagination/total-items/SET"; // DOCUMENT
const NOTIFICATION_PAGINATION_ITEMS_PER_PAGE_SET =
	"@feedbacks/notificationsPagination/items-per-page/SET"; // DOCUMENT
const NOTIFICATION_PAGINATION_CURRENT_PAGE_SET = "@feedbacks/notificationsPagination/current-page/SET"; // DOCUMENT
const NOTIFICATION_PAGINATION_RESET = "@feedbacks/notificationsPagination/RESET"; // DOCUMENT
const PAGINATION_TOTAL_ITEMS_SET = "@feedbacks/pagination/total-items/SET"; // DOCUMENT
const PAGINATION_ITEMS_PER_PAGE_SET =
	"@feedbacks/pagination/items-per-page/SET"; // DOCUMENT
const PAGINATION_CURRENT_PAGE_SET = "@feedbacks/pagination/current-page/SET"; // DOCUMENT
const PAGINATION_RESET = "@feedbacks/pagination/RESET"; // DOCUMENT
const CHAT_ATTACHMENT_SET = "@feedbacks/active-selected/chat/attachments/set";
const CHAT_UPLOAD_PROGRESS_SET =
	"@feedbacks/chat/attachment-upload-progress/set";
const FEEDBACK_SELECTED_UPDATE = "@feedbacks/selected-feedback/update";
const FEEDBACK_ACTIVITY_LIST_UPDATE =
	"@feedbacks/selected-feedback/activity-list/update";
const FEEDBACK_FORM_MODAL_TOGGLE = "@feedbacks/form/TOGGLE";
const FEEDBACK_TASK_SET = "@feedbacks/task/SET";
const FEEDBACK_VESSEL_SPECIFIC_USERS_SET =
	"@feedbacks/vessel-specific-users/SET";
const FEEDBACK_FILTER_TOGGLE = "@feedbacks/filters/TOGGLE";
const FEEDBACK_FILTER_SECTION_SET = "@feedbacks/filter-section/SET";
const FEEDBACK_FILTER_STATE_SET = "@feedbacks/filter-state/SET";
const FEEDBACK_FILTER_RESET = "@feedbacks/filter/RESET";
const FEEDBACK_TICKET_TAB_TYPE_SET = "@feedbacks/ticket-tab/type/SET";
const FEEDBACK_DASHBOARD_DATE_RANGE_SET = "@feedbacks/dashboard/date-range/SET";
const FEEDBACK_DASHBOARD_STATS_SET = "@feedbacks/dashboard/stats/SET";

const feedbackActions = {
	commands: {
		notificationsLoad: createAction(
			NOTIFICATIONS_LOAD,
			(pageNumber: number) => ({ pageNumber })
		)(),
		feedbackListLoad: createAction(
			FEEDBACK_LIST_LOAD,
			(pageNumber: number) => ({ pageNumber })
		)(),
		feedbackVesselsLoad: createAction(FEEDBACK_VESSELS_LOAD, () => ({}))(),
		feedbackChatsLoad: createAction(
			FEEDBACK_CHATS_LOAD,
			(feedbackId: string) => ({ feedbackId })
		)(),
		// INFO: individualFeedback has complete info of feedback along with chats
		individualFeedbackLoad: createAction(
			INDIVIDUAL_FEEDBACK_LOAD,
			(feedbackId: string) => ({ feedbackId })
		)(),
		createMessage: createAction(
			FEEDBACK_MESSAGE_CREATE,
			(from: TCreateMessageFrom = "chatModal") => ({ from })
		)(),
		feedbackTotalCountLoad: createAction(
			FEEDBACK_TOTAL_COUNT_LOAD,
			(vesselId?: string) => ({ vesselId })
		)(),
		chatAttachmentUpload: createAction(
			CHAT_ATTACHMENT_UPLOAD,
			(
				chat: IFeedbackChat,
				attachments: any[],
				attachmentsUrls: { name: string; type: string }[]
			) => ({
				chat,
				attachments,
				attachmentsUrls,
			})
		)(),
		chatMessageUpdate: createAction(
			CHAT_MESSAGE_UPDATE,
			(id: string, attachmentsUrls: { name: string; type: string }[]) => ({
				id,
				attachmentsUrls,
			})
		)(),
		feedbackUpdate: createAction(
			FEEDBACK_UPDATE,
			(payload: IFeedbackUpdatePayload) => ({
				payload,
			})
		)(),
		tasksLoad: createAction(FEEDBACK_TASKS_LOAD)(),
		feedbackSend: createAction(
			FEEDBACK_SEND,
			(feedback: TFeedback & { vesselId: string }) => ({
				feedback,
			})
		)(),
		vesselSpecificUsersLoad: createAction(
			FEEDBACK_VESSEL_SPECIFIC_USERS_LOAD,
			(vesselId: string) => ({
				vesselId,
			})
		)(),
		feedbackDashboardListLoad: createAction(
			FEEDBACK_DASHBOARD_LIST_LOAD,
			(requestPayload: IFeedbackDashboardRequestPayloadFilter) => ({
				...requestPayload,
			})
		)(),
		feedbackDashboardStatsOrFeedbacksLoad: createAction(
			FEEDBACK_DASHBOARD_STATS_OR_FEEDBACK_LOAD,
			(reqPayload: IDashboardStatsAndFeedbackPayload) => ({ ...reqPayload })
		)(),
	},
	document: {
		notificationsSet: createAction(
			NOTIFICATIONS_SET,
			(notifications: INotification[]) => ({
				notifications,
			})
		)(),
		notificationsAppend: createAction(
			NOTIFICATIONS_APPEND,
			(notifications: INotification[]) => ({
				notifications,
			})
		)(),
		vesselSpecificUsersSet: createAction(
			FEEDBACK_VESSEL_SPECIFIC_USERS_SET,
			(vesselUsers: IListObjectStore<IVesselUser>) => ({
				vesselUsers,
			})
		)(),
		feedbackListSet: createAction(
			FEEDBACK_LIST_SET,
			(feedbacks: IFeedback[]) => ({ feedbacks })
		)(),
		feedbackVesselsSet: createAction(
			FEEDBACK_VESSELS_SET,
			(vessels: IListObjectStore<IVessel>) => ({ vessels })
		)(),
		togglefeedbackChatModal: createAction(FEEDBACK_CHAT_MODAL_TOGGLE)(),
		feedbackChatsSet: createAction(
			FEEDBACK_CHATS_SET,
			(chats: IFeedbackChat[]) => ({ chats })
		)(),
		feedbackChatsSetInIndividualFeedback: createAction(
			FEEDBACK_CHATS_SET_IN_INDIVIDUAL_FEEDBACK,
			(feedback?: IIndividualFeedbackWithChat) => ({ feedback })
		)(),
		selectedFeedbackIdSet: createAction(
			FEEDBACK_SELECTED_ID_SET,
			(feedbackId: string) => ({ feedbackId })
		)(),
		selectedFeedbackVesselIdSet: createAction(
			FEEDBACK_SELECTED_VESSEL_ID_SET,
			(vesselId: string) => ({ vesselId })
		)(),
		selectedChatListMessageSet: createAction(
			CHAT_MESSAGE_SET,
			(message: string) => ({ message })
		)(),
		addChatToChatList: createAction(
			ADD_CHAT_TO_CHAT_LIST,
			(chat: IFeedbackChat) => ({ chat })
		)(),
		addChatToChatListInIndividualFeedback: createAction(
			ADD_CHAT_TO_CHAT_LIST_IN_INDIVIDUAL_FEEDBACK,
			(chat: IFeedbackChat) => ({ chat })
		)(),
		searchFeedbackByIssueSet: createAction(
			FEEDBACK_SEARCH_BY_ISSUE,
			(searchBy: string) => ({ searchBy })
		)(),
		feedbackFiltersSet: createAction(
			FEEDBACK_FILTERS_SET,
			(filters: IFeedbackFilters) => ({ filters })
		)(),
		notificationsPaginationTotalItemsSet: createAction(
			NOTIFICATION_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		notificationsPaginationItemsPerPageSet: createAction(
			NOTIFICATION_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		notificationsPaginationCurrentPageSet: createAction(
			NOTIFICATION_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		notificationsPaginationReset: createAction(NOTIFICATION_PAGINATION_RESET)(),
		paginationTotalItemsSet: createAction(
			PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		paginationItemsPerPageSet: createAction(
			PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		paginationCurrentPageSet: createAction(
			PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		paginationReset: createAction(PAGINATION_RESET)(),
		selectedChatListAttachmentSet: createAction(
			CHAT_ATTACHMENT_SET,
			(attachments: File[]) => ({ attachments })
		)(),
		setChatAttachmentUploadProgress: createAction(
			CHAT_UPLOAD_PROGRESS_SET,
			(isBeingUploaded: boolean) => ({
				isBeingUploaded,
			})
		)(),
		updateCurrentFeedback: createAction(
			FEEDBACK_SELECTED_UPDATE,
			(payload: Partial<IFeedback>) => ({
				payload,
			})
		)(),
		updateActivityListForSelectedFeedback: createAction(
			FEEDBACK_ACTIVITY_LIST_UPDATE,
			(activity: IFeedbackActivity) => ({
				activity,
			})
		)(),
		togglefeedbackFormModal: createAction(FEEDBACK_FORM_MODAL_TOGGLE)(),
		tasksSet: createAction(
			FEEDBACK_TASK_SET,
			(tasks: IListObjectStore<TTaskTemplate>) => ({
				tasks,
			})
		)(),
		toggleFeedbackFiltersModal: createAction(
			FEEDBACK_FILTER_TOGGLE,
			(isOpen?: boolean) => ({ isOpen })
		)(),
		feedbackFilterSectionSet: createAction(
			FEEDBACK_FILTER_SECTION_SET,
			(filterSection: FILTER_SECTIONS) => ({ filterSection })
		)(),
		feedbackFilterStateSet: createAction(
			FEEDBACK_FILTER_STATE_SET,
			(filter: IFilterState) => ({ filter })
		)(),
		feedbackFilterStateReset: createAction(FEEDBACK_FILTER_RESET)(),
		feedbackTicketTabTypeSet: createAction(
			FEEDBACK_TICKET_TAB_TYPE_SET,
			(ticketTabType: ETicketTabType) => ({ ticketTabType })
		)(),
		feedbackDashboardListSet: createAction(
			FEEDBACK_DASHBOARD_LIST_SET,
			(
				feedbacks: IFeedback[],
				filterType: ETypeOfFilter,
				tabType: ETicketTabType
			) => ({
				feedbacks,
				filterType,
				tabType,
			})
		)(),
		feedbackDashboardsStatsSet: createAction(
			FEEDBACK_DASHBOARD_STATS_SET,
			(tabType: ETicketTabType, stats: IDashboardStatsRes) => ({
				tabType,
				stats,
			})
		)(),
		feedbackDashboardDateRangeSet: createAction(
			FEEDBACK_DASHBOARD_DATE_RANGE_SET,
			(dateRange: Range[]) => ({ dateRange })
		)(),
	},
};

export const feedbackJobActions = {
	notificationsLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.notificationsLoad)
	),
	feedbackListLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.feedbackListLoad)
	),
	feedbackVesselsLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.feedbackVesselsLoad)
	),
	feedbackChatsLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.feedbackChatsLoad)
	),
	individualFeedbackLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.individualFeedbackLoad)
	),
	chatMessageSend: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.createMessage)
	),
	feedbackTotalCountLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.feedbackTotalCountLoad)
	),
	feedbackDashboardListLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.feedbackTotalCountLoad)
	),
	feedbackDashboardStatsOrFeedbacksLoad: jobActions(
		feedbackConstants.FEATURE,
		getType(feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad)
	),
};

export default feedbackActions;
