import moment from "moment";
import React from "react";
import { TORB1Record } from "redux/app/orb1/@types";
import ORB1TableRows from "../ORB1TableRows";
import { TORB1D4 } from "redux/app/orb1/groups/group-d-new";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1D4PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1D4Preview(props: ORB1D4PreviewProps) {
	const orb1Record = props.orb1Record as TORB1D4;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "D", "13", renderItem13()],
					[null, null, "14", renderItem14()],
					[null, null, "15.1", renderItem15P1()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem13(): React.ReactNode {
		return (
			<>
				{orb1Record.item13And14.quantityDischarged.toFixed(2)} M³ FROM{" "}
				{orb1Record.item13And14.bilgeSource}
			</>
		);
	}

	function renderItem14() {
		return (
			<h4 className="font-medium uppercase">
				STARTED {orb1Record.item13And14.startOfDischargeTime.time} / FINISHED{" "}
				{orb1Record.item13And14.endOfDischargeTime.time}
			</h4>
		);
	}

	function renderItem15P1() {
		return (
			<h4 className="font-medium uppercase">
				THROUGH 15 PPM EQUIPMENT <br />
				POSITION AT START{" "}
				{orb1Record.item15P1.startOfDischargePosition.latitude}
				{" , "}
				{orb1Record.item15P1.startOfDischargePosition.longitude}
				<br />
				POSITION AT END {orb1Record.item15P1.endOfDischargePosition.latitude}
				{" , "}
				{orb1Record.item15P1.endOfDischargePosition.longitude}
			</h4>
		);
	}

	return renderMain();
}

export default ORB1D4Preview;
