import historyTypesCreator from "./types-creator";
import { ICPHistory } from "../../../routes";
import constants from "./constants";

const { HISTORY_CLEAR, HISTORY_POP, HISTORY_PUSH, HISTORY_REPLACE } = constants;
export interface IHistoryMetadata {
	history: ICPHistory;
}
const push = (feature: string, url: string, meta: any = {}) => ({
	type: historyTypesCreator(feature).HISTORY_PUSH,
	url,
	payload: url,
	meta: {
		...meta,
		trueType: HISTORY_PUSH,
	},
});

const pop = (feature: string, url: string, meta: any = {}) => ({
	type: historyTypesCreator(feature).HISTORY_POP,
	url,
	payload: url,
	meta: {
		...meta,
		trueType: HISTORY_POP,
	},
});

const replace = (feature: string, url: string, meta: any = {}) => ({
	type: historyTypesCreator(feature).HISTORY_REPLACE,
	url,
	payload: url,
	meta: {
		...meta,
		trueType: HISTORY_REPLACE,
	},
});

const clear = (meta: any = {}) => ({
	type: HISTORY_CLEAR,
	meta: {
		...meta,
	},
});

const reset = () => ({
	type: constants.HISTORY_RESET,
});
const historyActions = { push, pop, replace, clear, reset };
export default historyActions;
