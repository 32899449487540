import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { transformAllVesselsGarbageCategoryDataForTable } from "redux/app/report/utils";
import { IStore } from "redux/store";

const garbageCategoryAllVesselsHeaders = [
	<div>Year</div>,
	<div>Quarter</div>,
	<div>Report date</div>,
	<div>
		<div>CAT A</div> <div> Plastics</div>
	</div>,
	<div>
		<div>CAT B</div> <div>Food waste</div>
	</div>,
	<div>
		<div>CAT C</div> <div>Domestic waste</div>
	</div>,
	<div>
		<div>CAT D</div> <div>Cooking oil</div>
	</div>,
	<div>
		<div>CAT E</div> <div>Ashes</div>
	</div>,
	<div>
		<div>CAT F</div> <div>Operational waste</div>
	</div>,
	<div>
		<div>CAT I</div> <div>Electronic waste</div>
	</div>,
];

type OwnProps = {};
type GarbageCategoryTableProps = PropsFromRedux & OwnProps;
function GarbageCategoryTable(props: GarbageCategoryTableProps) {
	const { garbageCategoryAllVesselsData, year } = props;

	const tableRowRecords = useMemo(() => {
		return (
			transformAllVesselsGarbageCategoryDataForTable(
				garbageCategoryAllVesselsData
			) ?? []
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [garbageCategoryAllVesselsData]);

	return (
		<div className="my-3">
			<h2 className="text-xl font-medium mb-3">
				Garbage volume by category (m³)
			</h2>{" "}
			<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text  overflow-auto  border-collapse text-center">
				<thead>
					<tr>
						{garbageCategoryAllVesselsHeaders.map((headerNode, index) => (
							<th
								key={index}
								className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
							>
								{headerNode}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableRowRecords.map((row, index) => {
						const { quarter, A, B, C, D, E, F, I } = row;
						return (
							<tr key={row.quarter}>
								{index === 0 && (
									<td
										rowSpan={5}
										className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
									>
										{year}
									</td>
								)}
								<td
									colSpan={2}
									className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
								>
									{quarter}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{A.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{B.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{C.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{D.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{E.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{F.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{I.toFixed(2)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const garbageCategoryAllVesselsData =
		reportSelectors.getGarbageCategoryAllVesselsData(reportStore);
	const year = reportSelectors.getGarbageYear(reportStore);

	return {
		garbageCategoryAllVesselsData,
		year,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageCategoryTable);
