import classNames from "classnames";
import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import {
	transformORSDataForTable,
	transformVesselSpecificORSDataForTable,
} from "redux/app/report/utils";
import { IStore } from "redux/store";

const garbageDischargeAllVesselsHeaders = [
	"Year",
	"Quarter",
	"Report date",
	"Incinerated",
	"Evaporated",
	"Boiler",
	"Transfer to slop",
	"Disposed ashore",
	"Total ORS",
];

type OwnProps = {};
type ORSTableProps = PropsFromRedux & OwnProps;
function ORSTable(props: ORSTableProps) {
	const { rawOrsData, vesselSpecificRawOrsData, year, vesselId } = props;
	const allVesselTableRowRecords = useMemo(() => {
		return Object.keys(rawOrsData)?.length && !vesselId
			? transformORSDataForTable(rawOrsData)
			: [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rawOrsData, vesselId]);

	const vesselSpecificTableRowRecords = useMemo(() => {
		return Object.keys(vesselSpecificRawOrsData)?.length && vesselId
			? transformVesselSpecificORSDataForTable(vesselSpecificRawOrsData)
			: [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselSpecificRawOrsData, vesselId]);

	const tableRowRecords = vesselId
		? vesselSpecificTableRowRecords
		: allVesselTableRowRecords;

	return (
		<div className="px-4">
			<h2 className="text-xl font-medium mb-3">ORS (m³)</h2>{" "}
			<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text  overflow-auto  border-collapse text-center">
				<thead>
					<tr>
						{garbageDischargeAllVesselsHeaders.map((headerLabel) => (
							<th
								key={headerLabel}
								className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
							>
								{headerLabel}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableRowRecords.map((row, index) => {
						const {
							quarter,
							incinerated,
							evaporated,
							boiler,
							transferToSlop,
							disposedAshore,
							quarterTotal,
						} = row;
						return (
							<tr
								key={row.quarter}
								className={classNames({
									"font-medium": index === tableRowRecords.length - 1,
								})}
							>
								{index === 0 && (
									<td
										rowSpan={5}
										className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
									>
										{year}
									</td>
								)}
								<td
									colSpan={2}
									className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
								>
									{quarter}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{incinerated.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{evaporated.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{boiler.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{transferToSlop.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{disposedAshore.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{quarterTotal.toFixed(2)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawOrsData = reportSelectors.getRawOrsData(reportStore);
	const vesselSpecificRawOrsData =
		reportSelectors.getRawVesselSpecificOrsData(reportStore);
	const year = reportSelectors.getOrsYear(reportStore);
	const vesselId = reportSelectors.getOrsVesselId(reportStore);

	return {
		rawOrsData,
		vesselSpecificRawOrsData,
		year,
		vesselId,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ORSTable);
