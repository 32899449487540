import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import insightSelectors from "redux/app/insight/selectors";
import { IStore } from "redux/store";
import boxIcon from "assets/icons/box.svg";
import plusIcon from "assets/icons/plus.svg";
import insightActions from "redux/app/insight/actions";
import { IMetricListItem, TRecentList } from "redux/app/insight/@types";
import classNames from "classnames";
import insightConstants, {
	initialRecentList,
} from "redux/app/insight/constants";
import ArrowedString from "../../ArrowedString";
const { LS_RECENT_LIST, RECENT_LIST_VERSION } = insightConstants;

type OwnProps = {};
type EngineMetricSelectedAndRecentListProps = PropsFromRedux & OwnProps;
function EngineMetricSelectedAndRecentList(
	props: EngineMetricSelectedAndRecentListProps
) {
	const {
		selectedMetrics,
		engineLogMetricsList,
		showRecentMetricList,
		stateOfShip,
		vesselId,
	} = props;
	const [selectedRecentMetrics, setSelectedRecentMetrics] = useState<string[]>(
		[]
	);

	let recentList: IMetricListItem[] = (
		JSON.parse(
			localStorage.getItem(LS_RECENT_LIST) ?? JSON.stringify(initialRecentList)
		) as TRecentList
	)[RECENT_LIST_VERSION].engineLogMetricsRecentList;

	const recentMetricsList = [...(recentList ?? [])].reverse();
	return (
		<>
			<div className="px-4 overflow-y-auto">
				<h4 className="text-sm font-medium">Selected</h4>
				<div className="py-4">
					{!selectedMetrics.length ? (
						<div className="pt-4 flex flex-col items-center justify-center">
							<img src={boxIcon} alt="No selection" />
							<p className="text-sm text-gray-500">No selections made</p>
						</div>
					) : (
						<div className="-my-2">
							{selectedMetrics?.map((metricKey) => {
								const selectedItem = engineLogMetricsList.byIds[metricKey];

								const { metric = "", section, subSection, key } = selectedItem;
								return (
									<div
										key={metric ?? "" + section ?? "" + subSection ?? "" + key}
										className="flex justify-between items-center py-2.5 px-2.5 pb-1.5 mb-1.5 border border-gray-300 cursor-pointer rounded-md"
									>
										<div>
											<div className="text-gray-500 text-[12px]">
												<ArrowedString
													strArr={[section ?? "", subSection ?? ""]}
													isSingle={false}
												/>
											</div>
											<h4 className="text-sm">{metric}</h4>
										</div>
										<div
											className="-mt-1"
											onClick={() => {
												props.setSelectedMetrics(key);
											}}
										>
											<span className="bp3-icon bp3-icon-cross text-blue-800 text-lg" />
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			{recentMetricsList?.length ? (
				<div className="px-4 overflow-y-auto">
					<h4 className="text-sm font-medium">Recent</h4>
					<div className="py-4 -mt-2">
						<div className="border border-gray-300 relative rounded-lg">
							<div
								onClick={() => {
									props.toggleRecentMetricsList(!showRecentMetricList);
								}}
								className="flex justify-between items-center  cursor-pointer  p-2"
							>
								<h4 className="text-[12px] xl:text-sm">
									{recentMetricsList.map((item) => item?.metric).join(", ")}
								</h4>
								<span className="bp3-icon bp3-icon-chevron-down text-xl text-gray-700" />
							</div>
							{showRecentMetricList && (
								<div className={classNames("relative top-full w-full ")}>
									<div className="max-h-[158px] overflow-y-auto">
										{recentMetricsList.map((listItem) => {
											const { section, subSection, metric, key } = listItem;
											return (
												<div
													onClick={() => {
														let selectedRecentMetricsList = [
															...selectedRecentMetrics,
														];
														if (selectedRecentMetricsList.includes(key)) {
															selectedRecentMetricsList =
																selectedRecentMetricsList.filter(
																	(m) => m !== key
																);
															setSelectedRecentMetrics(
																selectedRecentMetricsList
															);
														} else {
															setSelectedRecentMetrics([
																...selectedRecentMetricsList,
																key,
															]);
														}
													}}
													key={section + metric}
													className=" w-full flex justify-between items-center py-2.5 pb-1.5 border-b border-gray-300 cursor-pointer px-2.5"
												>
													<div>
														<div className="text-gray-500 text-[12px]">
															<ArrowedString
																isSingle={false}
																strArr={[section ?? "", subSection ?? ""]}
															/>
														</div>
														<h4 className="text-sm">{metric}</h4>
													</div>
													<div>
														{selectedRecentMetrics.includes(key) ? (
															<span className="bp3-icon bp3-icon-tick text-lg text-green-500" />
														) : (
															<img
																className="cursor-pointer"
																src={plusIcon}
																alt="plus icon"
															/>
														)}
													</div>
												</div>
											);
										})}
									</div>
									<div className="flex justify-center">
										<button
											disabled={!vesselId || !stateOfShip}
											onClick={() => {
												const unSelectedMetrics = selectedRecentMetrics.filter(
													(metric) => !selectedMetrics.includes(metric)
												);
												let newSelectedMetrics = [
													...selectedMetrics,
													...unSelectedMetrics,
												];
												newSelectedMetrics = newSelectedMetrics.filter(
													(metric) => metric in engineLogMetricsList.byIds
												);
												props.updateSelectedMetrics(newSelectedMetrics);
												setSelectedRecentMetrics([]);
												props.toggleRecentMetricsList(false);
											}}
											className="border border-gray-500 p-2  rounded-lg w-[95%] m-1.5 cursor-pointer disabled:bg-gray-200 disabled:border-gray-300"
										>
											<div className="text-sm flex justify-center items-center ">
												Apply
											</div>
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const selectedMetrics =
		insightSelectors.getEngineLogSelectedMetrics(insightStore);
	const engineLogMetricsList =
		insightSelectors.getEngineLogMetricsList(insightStore);
	const showRecentMetricList =
		insightSelectors.getShowRecentMetricList(insightStore);
	const stateOfShip = insightSelectors.getStateOfShip(insightStore);
	const vesselId = insightSelectors.getVesselId(insightStore);
	return {
		selectedMetrics,
		engineLogMetricsList,
		showRecentMetricList,
		stateOfShip,
		vesselId,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setSelectedMetrics(metric: string) {
			dispatch(
				insightActions.document.addEngineLogMetricToSelectedMetrics(metric)
			);
		},
		toggleRecentMetricsList(value?: boolean) {
			dispatch(insightActions.document.toggleRecentList(value));
		},
		updateSelectedMetrics(metrics: string[]) {
			dispatch(
				insightActions.document.recentSelectedMetricToSelectedMetricsAdd(
					metrics
				)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(EngineMetricSelectedAndRecentList);
