import React from "react";

const CustomQuarterXAxisTick = (props: any) => {
	const { x, y, payload, width, visibleTicksCount, ...rest } = props;
	const xOffset = 2 * (width / visibleTicksCount);

	if (payload && payload?.value) {
		const { index, value } = payload;
		if (index % 4 === 0) {
			return (
				<svg>
					<path
						stroke="#878787"
						strokeWidth={2}
						d={`M${x + Math.round(width / 100)} ${y + 14} L${
							x + xOffset - 20
						} ${y + 14}`}
					/>
					<text fontSize={14} x={x + xOffset} y={y} {...rest} dy={18}>
						{value}
					</text>
					<path
						stroke="#878787"
						strokeWidth={2}
						d={`M${x + xOffset + 20} ${y + 14} L${
							x + 2 * xOffset - Math.round(width / 100)
						} ${y + 14}`}
					/>
				</svg>
			);
		}
	}
	return null;
};

export default CustomQuarterXAxisTick;
