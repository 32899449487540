import { Reducer } from "redux";
import { createReducer } from "typesafe-actions";
import formActions, { IFormActions } from "./actions";
import { IFormStore, IForm } from "./types";

const formItemDefaultState: IForm<any, any, any, any> = {
	fields: {},
	errors: {},
	helpMessages: {},
	warnings: {},
	mode: "NONE",
};
const actions = formActions("DUMMY-FORM", "DUMMY-FEATURE");
const formReducers = createReducer<IForm<any, any, any, any>, IFormActions>(
	formItemDefaultState
)
	.handleAction(actions.formFieldsSet, function formFieldSet(state, action) {
		return {
			...state,
			fields: action.payload.fields,
		};
	})
	.handleAction(actions.formFieldsEdit, function formFieldEdit(state, action) {
		return {
			...state,
			fields: {
				...state.fields,
				...action.payload.fields,
			},
		};
	})
	.handleAction(actions.formFieldsClear, function formFieldsClear(
		state,
		action
	) {
		const { payload } = action;
		const { fieldKeys } = payload;
		const clearedFields = { ...state?.fields };
		fieldKeys.map((key) => {
			clearedFields[key] = "";
		});
		return {
			...state,
			fields: {
				...state.fields,
				...clearedFields,
			},
		};
	})
	.handleAction(actions.formErrorsSet, function formErrorsSet(state, action) {
		const fieldsToSet = Object.keys(action.payload.errors);
		const remainingFieldToSetAsDefault = Object.keys(state.errors).filter(
			(key) => !fieldsToSet.includes(key)
		);
		const remainingKeyDefaultValidity: typeof action.payload.errors = {};
		remainingFieldToSetAsDefault.map((key) => {
			remainingKeyDefaultValidity[key] = {
				isValid: true,
				message: "",
			};
		});
		return {
			...state,
			errors: {
				// This is a fix to the issue of the old errors not being cleared when the new errors are set
				...action.payload.errors,
				...remainingKeyDefaultValidity,
			},
		};
	})
	.handleAction(actions.formErrorsReset, function formErrorsReset(
		state,
		action
	) {
		const { payload } = action;
		const { fieldKeys } = payload;
		const resettedErrors = { ...state?.errors };
		fieldKeys.map((key) => {
			resettedErrors[key] = {
				isValid: true,
				message: "",
			};
		});
		return {
			...state,
			errors: {
				...state.errors,
				...resettedErrors,
			},
		};
	})
	.handleAction(actions.formWarningsSet, function formWarningsSet(
		state,
		action
	) {
		return {
			...state,
			warnings: {
				...state.warnings,
				...action.payload.warnings,
			},
		};
	})
	.handleAction(actions.formWarningsReset, function formWarningsReset(
		state,
		action
	) {
		const { payload } = action;
		const { fieldKeys } = payload;
		const resettedWarnings = { ...state?.warnings };
		fieldKeys.map((key) => {
			resettedWarnings[key] = {
				isValid: true,
				message: "",
			};
		});
		return {
			...state,
			warnings: {
				...state.warnings,
				...resettedWarnings,
			},
		};
	})
	.handleAction(actions.formHelpMessagesSet, function formHelpMessagesSet(
		state,
		action
	) {
		return {
			...state,
			helpMessages: {
				...state.helpMessages,
				...action.payload.helpMessages,
			},
		};
	})
	.handleAction(actions.formHelpMessagesReset, function formHelpMessagesReset(
		state,
		action
	) {
		const { payload } = action;
		const { fieldKeys } = payload;
		const resettedHelpMessages = { ...state?.helpMessages };
		fieldKeys.map((key) => {
			resettedHelpMessages[key] = "";
		});
		return state;
	})
	.handleAction(actions.formReset, function formReset(state, action) {
		const { payload } = action;
		const { fieldKeys } = payload;
		const resettedForm = { ...state };
		fieldKeys.map((key) => {
			resettedForm.fields[key] = "";
			resettedForm.errors[key] = {
				isValid: true,
				message: "",
			};
			resettedForm.warnings[key] = {
				isValid: true,
				message: "",
			};
			resettedForm.helpMessages[key] = "";
		});
		return {
			...state,
			...resettedForm,
		};
	})
	.handleAction(actions.formModeSet, function formModeSet(state, action) {
		const { mode } = action.payload;
		return {
			...state,
			mode,
		};
	});

const withForms = <T extends IFormStore>(
	feature: string,
	defaultState: T,
	reducer: Reducer<T, any>
): Reducer<T, any> => (state: T = defaultState, action) => {
	if (
		action.payload
			? action.payload.feature !== feature || !action.payload.formName
			: true
	)
		return reducer(state, action);

	if (state._forms[action.payload.formName] === undefined) {
		state = { ...state, ...defaultState };
	}

	return reducer(
		{
			...state,
			_forms: {
				...state._forms,
				[action.payload.formName]: formReducers(
					state._forms[action.payload.formName],
					action
				),
			},
		},
		action
	);
};
export default withForms;
