import { IToastProps } from "@blueprintjs/core";
import { IJobNotification } from "../job/types";

function getBluePrintToastNotification(
	notification: IJobNotification
): IToastProps {
	const {
		action,
		className,
		hideAtState,
		icon,
		intent,
		message,
		showAtState,
		timeout,
	} = notification;
	return {
		message,
		action,
		className,
		icon,
		onDismiss: undefined,
		timeout: 0,
	};
}

const notificationUtilities = {
	getBluePrintToastNotification,
};
export default notificationUtilities;
