import { getType } from "typesafe-actions";
import { IStore } from "redux/store";
import { IChecklistStore } from "./types";
import { jobSelectors } from "../../_core/job";
import checklistActions from "./actions";
import { formSelectors } from "redux/_core/form";

const checklistSelectors = {
	_getStore(store: IStore) {
		return store.app.checklistStore;
	},
	getChecklists(checklistStore: IChecklistStore) {
		return checklistStore.checklists;
	},
	getChecklistUniqueList(checklistStore: IChecklistStore) {
		return checklistStore.checklistUniqueList;
	},
	formForFilter(checklistStore: IChecklistStore) {
		return formSelectors(checklistStore._forms.FILTER);
	},
	getSelectedChecklist(checklistStore: IChecklistStore) {
		return checklistStore.selectedChecklist;
	},
	isChecklistSelected(checklistStore: IChecklistStore) {
		return !!checklistStore.selectedChecklist;
	},
	jobToLoadChecklists(checklistStore: IChecklistStore) {
		return jobSelectors.getJobStatus(
			checklistStore,
			getType(checklistActions.checklistLoad)
		);
	},
	jobToLoadPrintTemplates(checklistStore: IChecklistStore) {
		return jobSelectors.getJobStatus(
			checklistStore,
			getType(checklistActions.checklistPrintTemplateLoad)
		);
	},
	jobToLoadSelectedChecklist(checklistStore: IChecklistStore) {
		return jobSelectors.getJobStatus(
			checklistStore,
			getType(checklistActions.singleChecklistLoad)
		);
	},
	// jobToLoadSelectedLog(checklistStore: IChecklistStore) {
	// 	return jobSelectors.getJobStatus(
	// 		checklistStore,
	// 		getType(checklistActions.commands.logLoad)
	// 	);
	// },
	jobToFilterChecklists(checklistStore: IChecklistStore) {
		return jobSelectors.getJobStatus(
			checklistStore,
			getType(checklistActions.checklistFilter)
		);
	},
	getPagination(checklistStore: IChecklistStore, pageIds?: string[]) {
		const paginationStore = checklistStore._pagination;
		return {
			totalItems: paginationStore.totalItems || 0,
			currentPage: paginationStore.currentPage,
			itemsPerPage: paginationStore.itemsPerPage,
			itemIds: pageIds
				? pageIds
				: checklistStore._pagination.pageWiseListIds[paginationStore.currentPage],
		};
	},
};

export default checklistSelectors;