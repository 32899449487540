import { jobActions } from "redux/_core/job";
import insightConstants from "./constants";
import { ActionType, createAction, getType } from "typesafe-actions";
import {
	EParameterTabType,
	IEngineLogInsightPayloadItem,
	IMetricListItem,
	INoonReportMetricListItem,
} from "./@types";
import { IListObjectStore } from "redux/_common/type";
import { IOption } from "../feedback/@types";
import { Range } from "react-date-range";

export enum EInsightActions {
	// Command action
	INSIGHT_CONFIG_LOAD = "@insight/config/LOAD",
	INSIGHT_VESSEL_SPECIFIC_INSIGHTS_LOAD = "@insight/vessel-specific/insights/:type/LOAD",

	// Document action
	INSIGHT_ENGINE_LOG_CONFIG_SET = "@insight/engine-log/config/SET",
	INSIGHT_ENGINE_LOG_METRICS_LIST_SET = "@insight/engine-log/metrics-list/SET",
	INSIGHT_PARAMETER_METRIC_LIST_TOGGLE = "@insight/parameter-metric-list/TOGGLE",
	INSIGHT_RECENT_LIST_TOGGLE = "@insight/recent-list/TOGGLE",
	INSIGHT_PARAMETER_CURRENT_TAB = "@insight/parameter/current-tab/SET",
	INSIGHT_ENGINE_LOG_METRICS_ADD = "@insight/engine-log/metrices/ADD",
	INSIGHT_DATE_RANGE_SET = "@insight/date-range/SET",
	INSIGHT_ENGINE_LOG_INSIGHTS_SET = "@insight/engine-log/insights/SET",
	INSIGHT_ENGINE_LOG_ACTIVE_SELECTED_METRICS_SET = "@insight/engine-log/active-selected-metrics/SET",
	INSIGHT_PARAMETER_VESSEL_ID_SET = "@insight/paramter/vesselId/SET",
	INSIGHT_STATE_OF_SHIP_SET = "@insight/state-of-ship/SET",
	INSIGHT_SEARCH_INPUT_SET = "@insight/search-input/SET",
	INSIGHT_RECENT_SELECTED_TO_SELECTED_METRICS_ADD = "@insight/recent-selected/to-selected-metrics/ADD",
	INSIGHT_STATE_OF_SHIP_DROPDOWN_OPTIONS = "@insight/state-of-ship/dropdown-options/SET",

	INSIGHT_NOON_REPORT_CONFIG_SET = "@insight/noon-report/config/SET",
	INSIGHT_NOON_REPORT_ENGINE_CONFIG_SET = "@insight/noon-report/engine-config/SET",
	INSIGHT_NOON_REPORT_METRICS_LIST_SET = "@insight/noon-report/metrics-list/SET",
	INSIGHT_NOON_REPORT_RECENT_SELECTED_TO_SELECTED_METRICS_ADD = "@insight/noon-report/recent-selected/to-selected-metrics/ADD",
	INSIGHT_NOON_REPORT_METRICS_ADD = "@insight/noon-report/metrices/ADD",
	INSIGHT_NOON_REPORT_INSIGHTS_SET = "@insight/noon-report/insights/SET",
	INSIGHT_NOON_REPORT_ACTIVE_SELECTED_METRICS_SET = "@insight/noon-report/active-selected-metrics/SET",

	INSIGHT_STATE_OF_SHIP_ERROR = "@insight/state-of-ship/error/SET",
}

const insightActions = {
	commands: {
		insightConfigLoad: createAction(
			EInsightActions.INSIGHT_CONFIG_LOAD,
			// Taking as params rather than redux store to ensure future reusability
			(category: string, vesselId: string, type: string) => ({
				category,
				vesselId,
				type,
			})
		)(),
		vesselSpecificInsightsLoad: createAction(
			EInsightActions.INSIGHT_VESSEL_SPECIFIC_INSIGHTS_LOAD,
			(dateRange: Range[] | undefined) => ({ dateRange })
		)(),
	},
	document: {
		insightEngineLogConfigSet: createAction(
			EInsightActions.INSIGHT_ENGINE_LOG_CONFIG_SET,
			(config: any) => ({ config })
		)(),
		toggleParameterMetricList: createAction(
			EInsightActions.INSIGHT_PARAMETER_METRIC_LIST_TOGGLE,
			(showParameterMetricList?: boolean) => ({ showParameterMetricList })
		)(),
		toggleRecentList: createAction(
			EInsightActions.INSIGHT_RECENT_LIST_TOGGLE,
			(showRecentMetricList?: boolean) => ({ showRecentMetricList })
		)(),
		setInsightParameterCurrentTab: createAction(
			EInsightActions.INSIGHT_PARAMETER_CURRENT_TAB,
			(currentTab: EParameterTabType) => ({ currentTab })
		)(),
		addEngineLogMetricToSelectedMetrics: createAction(
			EInsightActions.INSIGHT_ENGINE_LOG_METRICS_ADD,
			(metric: string) => ({ metric })
		)(),
		engineLogMetricsListSet: createAction(
			EInsightActions.INSIGHT_ENGINE_LOG_METRICS_LIST_SET,
			(metricsList: IListObjectStore<IMetricListItem>) => ({ metricsList })
		)(),
		insightDateRangeSet: createAction(
			EInsightActions.INSIGHT_DATE_RANGE_SET,
			(dateRange: Range[]) => ({ dateRange })
		)(),
		engineLogInsightsSet: createAction(
			EInsightActions.INSIGHT_ENGINE_LOG_INSIGHTS_SET,
			(
				list: IEngineLogInsightPayloadItem[],
				metrics: string[],
				dateRangeValues: string[]
			) => ({
				list,
				metrics,
				dateRangeValues,
			})
		)(),
		engineLogActiveSelectedMetricsSet: createAction(
			EInsightActions.INSIGHT_ENGINE_LOG_ACTIVE_SELECTED_METRICS_SET,
			(activeMetrics: string[]) => ({ activeMetrics })
		)(),
		parameterVesselIdSet: createAction(
			EInsightActions.INSIGHT_PARAMETER_VESSEL_ID_SET,
			(vesselId: string) => ({ vesselId })
		)(),
		stateOfShipSet: createAction(
			EInsightActions.INSIGHT_STATE_OF_SHIP_SET,
			(stateOfShip: string) => ({ stateOfShip })
		)(),
		searchInputSet: createAction(
			EInsightActions.INSIGHT_SEARCH_INPUT_SET,
			(searchText: string) => ({ searchText })
		)(),
		recentSelectedMetricToSelectedMetricsAdd: createAction(
			EInsightActions.INSIGHT_RECENT_SELECTED_TO_SELECTED_METRICS_ADD,
			(selectedMetrics: string[]) => ({ selectedMetrics })
		)(),
		stateOfShipDropdownOptionsSet: createAction(
			EInsightActions.INSIGHT_STATE_OF_SHIP_DROPDOWN_OPTIONS,
			(options: IOption[]) => ({ options })
		)(),
		//INFO: Noon report specific
		insightNoonReportConfigSet: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_CONFIG_SET,
			(config: any) => ({ config })
		)(),
		insightNoonReportEngineConfigSet: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_ENGINE_CONFIG_SET,
			(config: any) => ({ config })
		)(),
		noonReportMetricsListSet: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_METRICS_LIST_SET,
			(metricsList: IListObjectStore<INoonReportMetricListItem>) => ({
				metricsList,
			})
		)(),
		noonReportRecentSelectedMetricToSelectedMetricsAdd: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_RECENT_SELECTED_TO_SELECTED_METRICS_ADD,
			(selectedMetrics: string[]) => ({ selectedMetrics })
		)(),
		addNoonReportMetricToSelectedMetrics: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_METRICS_ADD,
			(metric: string) => ({ metric })
		)(),
		noonReportInsightsSet: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_INSIGHTS_SET,
			(
				list: any, //TODO: need to change type accordingly
				metrics: string[],
				dateRangeValues: string[]
			) => ({
				list,
				metrics,
				dateRangeValues,
			})
		)(),
		noonReportActiveSelectedMetricsSet: createAction(
			EInsightActions.INSIGHT_NOON_REPORT_ACTIVE_SELECTED_METRICS_SET,
			(activeMetrics: string[]) => ({ activeMetrics })
		)(),
		insightStateOfShipError: createAction(
			EInsightActions.INSIGHT_STATE_OF_SHIP_ERROR,
			(stateOfShipError: boolean) => ({ stateOfShipError })
		)(),
	},
};

export const insightJobActions = {
	insightConfigLoad: jobActions(
		insightConstants.FEATURE,
		getType(insightActions.commands.insightConfigLoad)
	),
	vesselSpecificInsightsLoad: jobActions(
		insightConstants.FEATURE,
		getType(insightActions.commands.vesselSpecificInsightsLoad)
	),
};

export type TInsightActions = ActionType<typeof insightActions>;
export default insightActions;
