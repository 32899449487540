import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupD5 } from "../../../../../redux/app/annex6/groups/group-d";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import CommaSeparator from "components/_common/CommaSeparator";
import { commonUtils } from "redux/_common";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupD5PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupD5Preview(props: Annex6GroupD5PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupD5;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "D", 8.2, renderItem8P2()],
					[null, null, 8.3, renderItem8P3()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem8P2(): React.ReactNode {
		if (!record.item8P2.equipmentsUsed || !record.item8P2.changingFrom)
			return <Annex6OldVersionPreview />;

		return (
			<>
				{moment(record.selectedDate, "YYYY-MM-DD").format("DD-MMM-YYYY")} AT{" "}
				{record.item8P2.time} LT COMPLETED CHANGEOVER OF{" "}
				<CommaSeparator
					items={record.item8P2.equipmentsUsed.map((equipment) =>
						equipment.split("_").join(" ")
					)}
					lastItemSeperator={" & "}
				/>{" "}
				FROM {record.item8P2.changingFrom.fuelType} (
				{record.item8P2.changingFrom.sulphurContent}% S) TO{" "}
				{record.item8P2.changingTo.fuelType}(
				{record.item8P2.changingTo.sulphurContent}% S), <br />
				POSITION: {record.item8P2.position.latitude},{" "}
				{record.item8P2.position.longitude}
			</>
		);
	}

	function renderItem8P3(): React.ReactNode {
		if (!record.item8P2.changingFrom || !record.item8P2.changingTo)
			return <Annex6OldVersionPreview />;

		const changingToTanks = record.item8P3.tanks.filter(
			(tank) => tank.selectedFuelType === record.item8P2.changingTo.fuelType
		);
		const changingToQuantityTotal = changingToTanks.reduce(
			(total, tank) => commonUtils.addUp(total, tank.retainedQuantityInMT),
			0
		);

		const changingFromTanks = record.item8P3.tanks.filter(
			(tank) => tank.selectedFuelType === record.item8P2.changingFrom.fuelType
		);
		const changingFromQuantityTotal = changingFromTanks.reduce(
			(total, tank) => commonUtils.addUp(total, tank.retainedQuantityInMT),
			0
		);

		const otherTanks = record.item8P3.tanks.filter(
			(tank) => tank.selectedFuelType === "UNCATEGORIZED"
		);
		const otherTanksQuantityTotal = otherTanks.reduce(
			(total, tank) => commonUtils.addUp(total, tank.retainedQuantityInMT),
			0
		);

		const isChangingFromTanksVisible = changingFromQuantityTotal > 0;
		const isChangingToTanksVisible = changingToQuantityTotal > 0;
		const isUncategorizedTanksVisible = otherTanksQuantityTotal > 0;

		return (
			<>
				{isChangingFromTanksVisible ? (
					<>
						TOTAL {record.item8P2.changingFrom.fuelType} ROB ON COMPLETION OF
						CHANGE OVER {changingFromQuantityTotal}
						MT <br />
						<CommaSeparator
							items={changingFromTanks.map(
								(tank) =>
									`${tank.selectedTank.tankName} - ${tank.retainedQuantityInMT}MT`
							)}
							customSeperator={<br />}
						/>
						<br />
					</>
				) : null}
				{isChangingToTanksVisible ? (
					<>
						TOTAL {record.item8P2.changingTo.fuelType} ROB ON COMPLETION OF
						CHANGE OVER {changingToQuantityTotal}MT <br />
						<CommaSeparator
							items={changingToTanks.map(
								(tank) =>
									`${tank.selectedTank.tankName} - ${tank.retainedQuantityInMT}MT`
							)}
							customSeperator={<br />}
						/>
					</>
				) : null}
				{isUncategorizedTanksVisible ? (
					<>
						{otherTanks.length > 0 ? (
							<>
								<br />
								TOTAL UNCATEGORIZED ROB ON COMPLETION OF CHANGE OVER{" "}
								{otherTanksQuantityTotal}MT <br />
								<CommaSeparator
									items={otherTanks.map(
										(tank) =>
											`${tank.selectedTank.tankName} - ${tank.retainedQuantityInMT}MT`
									)}
									customSeperator={<br />}
								/>
							</>
						) : null}
					</>
				) : null}
			</>
		);
	}

	function renderItem8P4(): React.ReactNode {
		if (record.item8P4) {
			return (
				<>
					{record.item8P4.time} HOURS LT VESSEL EXIT FROM SECA, <br /> POSITION:{" "}
					{record.item8P4.position.latitude},{" "}
					{record.item8P4.position.longitude}
				</>
			);
		} else {
			return <> NA</>;
		}
	}

	return renderMain();
}

export default Annex6GroupD5Preview;
