import React from "react";

type OwnProps = {
	classNamesForCopy1?: string;
	classNamesForCopy2?: string;
	duplicateHTML(className: string): React.ReactNode;
};
type DuplicateHTMLProps = React.PropsWithChildren<OwnProps>;

/**
 *
 * @description Creates two copies of html and renders it two times in html.
 */
function DuplicateHTML(props: DuplicateHTMLProps) {
	const { classNamesForCopy1 = "", classNamesForCopy2 = "" } = props;
	return (
		<>
			{props.duplicateHTML(classNamesForCopy1)}
			{props.duplicateHTML(classNamesForCopy2)}
		</>
	);
}

export default DuplicateHTML;
