import classNames from "classnames";
import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { garbageRecordSelectors } from "../../../../../redux/app/garbage-record";
import {
	EGarbageRecordState,
	IGarbage,
	IGarbageRecord,
} from "../../../../../redux/app/garbage-record/@types";
import { marpolSelectors, marpolUtils } from "../../../../../redux/app/marpol";
import { IStore } from "../../../../../redux/store";
import DuplicateHTML from "../../_elements/DuplicateHTML";
import GarbageRecordEntryPosition from "./GarbageRecordEntryPosition";
import GRB1Participants from "./GRB1Participants";

type OwnProps =
	| {
			source: "HISTORY";
			grb1Id: string;
			grb1Record?: IGarbageRecord;
	  }
	| {
			source: "CURRENT-OPERATION";
			grb1Record: IGarbageRecord;
	  };
type GRB1TableBodyProps = PropsFromRedux & OwnProps;
function GRB1TableBody(props: GRB1TableBodyProps) {
	const { grb1Record, source, isEPS } = props;
	const garbageEntry = grb1Record.entry;
	const garbageState = grb1Record.state;
	const isGarbageOfOldVersion = !Array.isArray(grb1Record.garbage);

	if (grb1Record.marpolType !== "GARBAGE" || isGarbageOfOldVersion) return null;

	const isMultiGarbageRemarksPresent = !!grb1Record.garbage.find(
		(garbage) => garbage.remarks !== "" && garbage.remarks !== undefined
	);
	const hasMultipleGarbages = grb1Record.garbage.length > 1;

	function renderRemarks() {
		const isAccidentalEntry =
			grb1Record.entry === "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA";
		const isDischargedIntoSeaEnry =
			grb1Record.entry ===
			"GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS";
		const isStrikedOff = grb1Record.strikeOffReason;
		const isPreview = source !== "CURRENT-OPERATION";
		const hasRemarks = grb1Record.remark !== "";
		return (
			<div className="h-full font-medium whitespace-pre-line">
				{isAccidentalEntry ? (
					<div className="font-bold tracking-wide text-red-600">
						ACCIDENTAL DISCHARGE
					</div>
				) : null}
				{hasRemarks ? (
					<>
						{grb1Record.remark} <br />
					</>
				) : null}
				{isDischargedIntoSeaEnry ? (
					<>
						RECEIPT NUMBER: {grb1Record.receiptNumber} ATTACHMENT: <br />
						<a
							href={marpolUtils.getAttachmentURL(
								grb1Record.receiptAttachment,
								grb1Record.id,
								grb1Record._rev
							)}
							download={grb1Record.receiptAttachment}
							target="_blank"
							rel="noreferrer"
						>
							{grb1Record.receiptAttachment}
						</a>
					</>
				) : null}
				{isPreview ? <GRB1Participants grb1Record={grb1Record} /> : null}
				{isStrikedOff ? (
					<div className="block">
						<div className="inline-block">
							<br />
							<div className="font-bold tracking-wide text-red-600">
								STRIKE OFF REASON:
							</div>
							<div>{grb1Record.strikeOffReason}</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	return (
		<>
			{grb1Record.garbage.map((garbage, index) => {
				const isFirstGarbage = index === 0;
				const operationDate = moment(
					grb1Record.selectedDate,
					"YYYY-MM-DD"
				).format("DD-MMM-YYYY");
				const timeOfOperation =
					grb1Record.entry === "GARBAGE_INCINERATED"
						? grb1Record.endOfOperation.time
						: grb1Record.utcTimestamp;

				return (
					<tr
						className={classNames("h-full bg-white", {
							"line-through": garbageState === EGarbageRecordState.STRIKED_OFF,
						})}
						key={index}
					>
						<td className="relative border ">
							<div
								className={classNames(
									"p-3 m-px text-sm text-left h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<span
									className={classNames("block font-medium whitespace-nowrap", {
										invisible: !isFirstGarbage && hasMultipleGarbages,
									})}
								>
									{operationDate}
									<br />
									{timeOfOperation}
									{isEPS ? <>Z</> : null}
								</span>
							</div>
						</td>
						<td className="relative border ">
							<div
								className={classNames(
									"p-3 m-px text-sm text-left h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<GarbageRecordEntryPosition garbageRecord={grb1Record} />
							</div>
						</td>
						<td className="relative border ">
							<div
								className={classNames(
									"p-3 m-px text-sm text-right h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<div className="h-full font-medium">{garbage.category}</div>
							</div>
						</td>
						<td className="relative border ">
							<div
								className={classNames(
									"p-3 m-px text-sm text-right h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<h4 className="font-medium capitalize">
									{(garbageEntry === "GARBAGE_DISCHARGED_INTO_SEA" ||
										garbageEntry ===
											"ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA") &&
									garbage
										? garbage.estimatedAmountDischarged
										: "-"}
								</h4>
							</div>
						</td>
						<td className="relative border ">
							<div
								className={classNames(
									"p-3 m-px text-sm text-right h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<div className="h-full font-medium">
									{garbageEntry ===
										"GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS" &&
									garbage
										? garbage.estimatedAmountDischarged
										: "-"}
								</div>
							</div>
						</td>
						<td className="relative border ">
							<div
								className={classNames(
									"p-3 m-px text-sm text-right h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<div className="h-full font-medium">
									{garbageEntry === "GARBAGE_INCINERATED" && garbage
										? garbage.estimatedAmountDischarged
										: "-"}
								</div>
							</div>
						</td>
						<td className="relative border rounded-r-md">
							<div
								className={classNames(
									"p-3 m-px text-sm text-left h-full ",

									{
										"line-through":
											garbageState === EGarbageRecordState.STRIKED_OFF,
									}
								)}
							>
								<div className="h-full font-medium whitespace-pre-line">
									{isMultiGarbageRemarksPresent
										? garbage.remarks
										: hasMultipleGarbages
										? garbage.remarks
										: renderRemarks()}
								</div>
							</div>
						</td>
					</tr>
				);
			})}
			{isMultiGarbageRemarksPresent || hasMultipleGarbages ? (
				<tr
					className={classNames("bg-white", {
						"line-through": garbageState === EGarbageRecordState.STRIKED_OFF,
					})}
				>
					<td className="relative border " colSpan={6}>
						<div
							className={classNames(
								"p-3 m-px text-sm text-left h-full ",

								{
									"line-through":
										garbageState === EGarbageRecordState.STRIKED_OFF,
								}
							)}
						></div>
					</td>
					<td className="relative border rounded-r-md">
						<div
							className={classNames(
								"p-3 m-px text-sm text-left h-full ",

								{
									"line-through":
										garbageState === EGarbageRecordState.STRIKED_OFF,
								}
							)}
						>
							{renderRemarks()}
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const garbageRecordStore = garbageRecordSelectors._getStore(store);
	const marpolStore = marpolSelectors._getStore(store);
	const marpolReport = marpolSelectors.getMARPOLReport(marpolStore);
	const tenantName = marpolReport.MISC.tenantName;
	const isEPS = tenantName === "EPS";
	if (ownProps.source === "HISTORY")
		return {
			isEPS,
			grb1Record: ownProps.grb1Record
				? ownProps.grb1Record
				: garbageRecordSelectors.getGarbageRecordById(
						ownProps.grb1Id,
						garbageRecordStore
				  ),
		};
	if (ownProps.source === "CURRENT-OPERATION")
		return {
			isEPS,
			grb1Record: ownProps.grb1Record,
		};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GRB1TableBody);