import { Middleware } from "redux";
import authConstants from "../constants";
import * as yup from "yup";
import { promiseActions } from "../../../_core/promise-action";
import authActions, { IAuthActions } from "../actions";
import { IStore } from "../../../store";
import { IAuthFormResetRequestErrors } from "../types";
import { invokerAction } from "../../../_common/actions";
import { getType } from "typesafe-actions";

const { FEATURE, forms } = authConstants;

const resetRequestSchema = yup.object().shape({
	email: yup
		.string()
		.required("Email is Required")
		.email("Invalid Email Format"),
});

const resetRequestFormMiddleware: Middleware<any, IStore> = ({
	dispatch,
	getState,
}) => (next) => (action: IAuthActions) => {
	switch (action.type) {
		case getType(authActions._forms.resetRequest.formSubmit): {
			next(action);
			const { formName, feature } = action.payload;
			if (feature === FEATURE && formName === forms.RESET_REQUEST) {
				const authStore = getState().app.auth;
				dispatch(
					promiseActions(
						resetRequestSchema.validate(authStore._forms.RESET_REQUEST.fields, {
							abortEarly: false,
						}),
						{
							invoker: "$auth/reset-request.form",
							onSuccess: (validatedData) => {
								if (validatedData) {
									const { email } = validatedData;
									dispatch(authActions.resetRequest(email));
								}
								dispatch(
									invokerAction(
										"$auth/reset-request.form",
										authActions._forms.resetRequest.formErrorsSet({
											GLOBAL: {
												isValid: true,
												message: "No data Provided",
											},
										})
									)
								);
							},
							onError: (error: yup.ValidationError) => {
								const errors: Partial<IAuthFormResetRequestErrors> = {
									GLOBAL: {
										isValid: false,
										message: "Found few errors",
									},
								};
								error.inner.map((errorItem) => {
									errors[errorItem.path] = {
										isValid: false,
										message: errorItem.message,
									};
								});
								dispatch(
									invokerAction(
										"$auth/reset-request.form",
										authActions._forms.resetRequest.formErrorsSet(errors)
									)
								);
							},
						}
					)
				);
			}
			break;
		}
		case getType(authActions._forms.resetRequest.formFieldsEdit): {
			next(action);
			const { formName, feature, fields } = action.payload;
			if (feature === FEATURE && formName === forms.RESET_REQUEST) {
				// To reset the errors on Change //FIXME: Add below code in Form Middleware
				dispatch(
					authActions._forms.resetRequest.formErrorsReset([
						"GLOBAL",
						...Object.keys(fields),
					])
				);
			}

			break;
		}
		default: {
			next(action);
		}
	}
};
export default resetRequestFormMiddleware;
