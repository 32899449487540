import React from "react";
import FeedbackDashboard from "components/app/feedback/dashboard";

type ScreenFeedbackDashboardProps = React.PropsWithChildren<{}>;

function ScreenFeedbackDashboard(props: ScreenFeedbackDashboardProps) {
	return <FeedbackDashboard />;
}

export default ScreenFeedbackDashboard;
