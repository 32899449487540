import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import React, { useEffect, useState } from "react";
import {
	Prompt,
	Redirect,
	Route,
	RouteChildrenProps,
	Switch,
} from "react-router-dom";
import ChecklistUI from "../../../components/app/checklist/Checklist";
import { rootRoutes } from "../../../routes";
import ChecklistList from "./list";
import ScreenChecklistSaf01 from "./saf01";
import { IStore } from '../../../redux/store';

const checklistRoutes = rootRoutes.children.app.children.checklist.children;
type ChecklistProps = RouteChildrenProps & PropsFromRedux;
function Checklist(props: ChecklistProps) {
	const { isSubmit } = props;
	const [isRouteBlocked, setRouteBlocking] = useState(false);
	useEffect(
		function onMount() {
			if (props.location.pathname.includes("saf01")) setRouteBlocking(true);
			else setRouteBlocking(false);
		},
		[props.location]
	);
	return (
		<ChecklistUI>
			<Switch>
				<Route path={checklistRoutes.list.path} component={ChecklistList} />
				<Route
					path={checklistRoutes.saf01.path}
					component={ScreenChecklistSaf01}
				/>
				<Route render={RedirectComponent} />
			</Switch>
			<Prompt
				when={isRouteBlocked && !isSubmit}
				message={(location) =>
					`Changes you made may not be saved. Are you sure?`
				}
			/>
		</ChecklistUI>
	);
}

function mapStateToProps(store:IStore){
	const checklistStore: any = store.app.checklistStore;
	const isSubmit = checklistStore.isSubmit;
	return {isSubmit:isSubmit}
}
function mapDispatchToProps(dispatch:Dispatch){return {}};
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Checklist);

function RedirectComponent() {
	return <Redirect to={checklistRoutes.list.url()} />;
}
//export default Checklist;
