import React from "react";

type SVGPowerpointProps = { className?: string };
const SVGPowerpoint: React.FC<SVGPowerpointProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 80 80"
		>
			<path
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M45 62h24V18H45M11 17.444v45.112L45 69V11l-34 6.444z"
			></path>
			<circle cx="41" cy="60" r="1"></circle>
			<circle cx="41" cy="64" r="1"></circle>
			<circle cx="41" cy="56" r="1"></circle>
			<circle cx="41" cy="52" r="1"></circle>
			<circle cx="41" cy="48" r="1"></circle>
			<circle cx="41" cy="44" r="1"></circle>
			<circle cx="41" cy="40" r="1"></circle>
			<circle cx="41" cy="36" r="1"></circle>
			<circle cx="41" cy="32" r="1"></circle>
			<circle cx="41" cy="28" r="1"></circle>
			<circle cx="41" cy="24" r="1"></circle>
			<circle cx="41" cy="20" r="1"></circle>
			<circle cx="41" cy="16" r="1"></circle>
			<path d="M25.759 30H19v19h4v-7h2.372C30 42 33 40 33 36.055 33 32 30 30 25.759 30zM26 39h-3.012L23 33h3.012C28 33 29 34 29 36c0 1.995-1 3-3 3z"></path>
			<path
				fill="none"
				stroke="#000"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M45.071 55L64 55"
			></path>
			<path
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M54 25a9 9 0 019 9h-9v-9z"
			></path>
			<path
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M45 46.662A9.954 9.954 0 0050 48c5.523 0 10-4.477 10-10H50V28a9.954 9.954 0 00-5 1.338"
			></path>
		</svg>
	);
};

export default SVGPowerpoint;
