import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupI2 } from "../../../../../redux/app/orb1/groups/group-i";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB1TableRows from "../ORB1TableRows";
import { commonUtils } from "redux/_common";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupI2PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupI2Preview(props: ORB1GroupI2PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI2;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "I", "-", renderItemI2()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItemI2() {
		const retainedQty = commonUtils.addUp(
			marpolUtils.getQuantityForCalculation(orb1Record.itemI2.selectedTank),
			orb1Record.itemI2.liquidTransferredQuantity
		);
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.itemI2.liquidTransferredQuantity}M³ collected in{" "}
				{orb1Record.itemI2.selectedTank.tankName} from various uncontrolled
				sources. Quantity retained&nbsp;
				{retainedQty.toFixed(2)} M³.
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupI2Preview;
