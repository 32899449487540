import React from "react";
import {
	IAuth_Get_ResetRequestData,
	IAuthFormResetRequestFields,
} from "../../../redux/app/auth/types";
import classNames from "classnames";
import DefaultInput from "../../../@set-product/core/Inputs/DefaultInput";
import Button from "../../../@set-product/core/button";
import { connect } from "react-redux";
import { authSelectors, authActions } from "../../../redux/app/auth";
import { IStore } from "../../../redux/store";
import { Dispatch } from "redux";
import { historyActions } from "../../../redux/_core/history";
import { rootRoutes } from "../../../routes";
import ReactJson from "react-json-view";
type ResetRequestProps = IAuth_Get_ResetRequestData & {
	onChange: (field: Partial<IAuthFormResetRequestFields>) => void;
	onSubmit: () => void;
	onLoginClick: () => void;
};
function ResetRequest(props: ResetRequestProps) {
	const { form, resetRequestJob, onChange, onSubmit, onLoginClick } = props;
	const { fields, errors, hasErrors } = form;
	const { email } = fields;
	return (
		<div>
			<form
				className="my-6"
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<div className="my-6">
					<h4
						className={classNames(
							"font-medium text-2xl font-redhat-text text-gray-500" // Font [Font Size, Font style etc]
						)}
					>
						Password reset request
					</h4>
					<h6>A mail will be sent with reset instructions</h6>
				</div>
				<div className="w-full my-3">
					<DefaultInput
						value={email}
						fill={true}
						placeholder="Email ID"
						label="Email"
						error={errors?.email ? !errors.email.isValid : false}
						errorHelperText={errors?.email?.message}
						onChange={(e) => {
							e.preventDefault();
							onChange({ email: (e.target as HTMLInputElement).value });
						}}
						disabled={resetRequestJob.isLoading}
					/>
				</div>
				<div className="w-full my-8">
					<Button
						type="submit"
						color="primary"
						text="Send Reset Link"
						fill={true}
						view="filled"
						className="shadow-none"
						disabled={resetRequestJob.isLoading}
					/>
				</div>
			</form>
			<div className="my-8">
				<div className="self-center my-3 text-center">
					<span className="text-gray-600">Remember your password?</span>{" "}
					<div>
						<Button
							view="flat"
							className="h-auto p-0 m-0 text-blue-500 border-0 hover:shadow-none"
							onClick={() => onLoginClick()}
						>
							Click here to Login
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (store: IStore) =>
	authSelectors(store.app.auth).getResetRequestData();

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (field: any) =>
		dispatch(authActions._forms.resetRequest.formFieldsEdit(field)),
	onSubmit: () => dispatch(authActions._forms.resetRequest.formSubmit()),
	onLoginClick: () =>
		dispatch(
			historyActions.push(
				"#ResetRequest",
				rootRoutes.children.auth.children.login.url()
			)
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetRequest);
