import React from "react";
import cx from "classnames";

import {
	InputGroup,
	Icon,
	Button,
	IInputGroupProps,
	IconName,
	MaybeElement,
	HTMLInputProps,
} from "@blueprintjs/core";

import Type from "./_type.module.css";
import View from "./_view.module.css";
import Color from "../../../styles/color.module.css";

import { ThemeContext } from "../../ThemeContext";
type DefaultInputType = React.PropsWithChildren<{
	/**
	 * The view of the component.
	 * Variants: `outlined`
	 * Default value (if undefined): `smooth` `
	 */
	view?: "smooth" | "outlined" | "raised" | "filled";
	/**
	 * The color of the component.
	 * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
	 * Default value (if undefined): `default` `
	 */
	color?:
		| "default"
		| "primary"
		| "warning"
		| "danger"
		| "success"
		| "primary_alt"
		| "warning_alt"
		| "danger_alt"
		| "success_alt";
	/**
	 * Dense size
	 */
	dense?: boolean;
	/**
	 * Placeholder text in the absence of any value.
	 */
	placeholder?: string;
	/**
	 * Label text
	 */
	label?: string;
	/**
	 * Form value of the input
	 */
	value: string;
	/**
	 * If `true`, the component will take up the full width of its container.
	 */
	fill?: boolean;
	/**
	 * Under input helper text
	 */
	helperText?: string;
	/**
	 * Set Clear button
	 */
	clearButton?: boolean;
	/**
	 * Set Success style for input
	 */
	success?: boolean;
	/**
	 * Set Error style for input
	 */
	error?: boolean;
	/**
	 * Set Fixed label for input
	 */
	fixed?: boolean;
	/**
	 * Element to render on right side of input.
	 */
	rightElement?: React.ReactHTMLElement<any>;
	/**
	 * Custom right icon for Success style
	 */
	successIcon?: IconName | MaybeElement;
	/**
	 * Custom right icon for Error style
	 */
	errorIcon?: IconName | MaybeElement;
	/**
	 * Set custom icon for "Clear button" element
	 */
	clearIcon?: IconName | MaybeElement;
	errorHelperText?: string;
	/**
	 * onClear button click
	 */
	onClear?: () => void;
}> &
	IInputGroupProps;
const DefaultInput: React.FC<DefaultInputType> = (props: DefaultInputType) => {
	const {
		// type = "text",
		view = "smooth",
		color = "primary",
		dense,
		className,
		placeholder,
		label,
		clearButton,
		clearIcon,
		value,
		rightElement,
		success,
		error,
		fill,
		errorHelperText,
		fixed,
		onClear,
		...restProps
	} = props;

	const isActive = value ? true : false; // component active state (focused)
	const isFilled = value ? true : false; // component filled state (unfocused and has value)

	const successIcon = (
		<Icon icon={restProps.successIcon || "tick"} iconSize={dense ? 16 : 20} />
	);
	const errorIcon = (
		<Icon icon={restProps.errorIcon || "error"} iconSize={dense ? 16 : 20} />
	);

	const resultIcon = success ? successIcon : error ? errorIcon : rightElement;

	return (
		<ThemeContext.Consumer>
			{({ isDark }) => (
				<InputGroup
					{...restProps}
					// type={type}
					value={value}
					className={cx(
						fixed ? Type.fixed : dense ? Type.dense : Type.def,
						fill && Type.fill,
						dense ? View.dense : View.def,
						isActive && View.active,
						clearButton && Type.clearButton,
						clearButton && View.clearButton,
						!success && !error && isFilled && View.isFilled,
						/*SUCCESS STYLE*/
						success && View.success,
						/*ERROR STYLE */
						Color[color],
						error && View["error-helper-text"],
						resultIcon && Type["right-icon"],
						/*Dark mode*/
						isDark ? View[view + "-dark"] : fixed ? View.fixed : View[view],
						className
					)}
					placeholder={placeholder}
					leftIcon={
						label ? (
							<>
								<label>{label}</label>
								{errorHelperText && error && (
									<div
										className={cx(
											Type["error-helper-text"],
											View["error-helper-text"]
										)}
									>
										{errorHelperText}
									</div>
								)}
							</>
						) : undefined
					}
					rightElement={
						clearButton && value ? (
							<Button
								icon={<Icon icon={clearIcon || "cross"} iconSize={20} />}
								minimal={true}
								// tslint:disable-next-line: jsx-no-lambda
								onClick={(e: any) => (onClear ? onClear() : null)}
							/>
						) : (
							resultIcon
						)
					}
				/>
			)}
		</ThemeContext.Consumer>
	);
};

export default DefaultInput;
