import actionTypes from "./action-types";
import { Middleware } from "redux";
import { multiActions } from "../multi-action";
import { IPromiseReturn } from "./types";

const promiseMiddleware: Middleware = ({ dispatch, getState }) => (next) => (
	action
) => {
	if (action.type === actionTypes.PROMISE_ACTION) {
		const isPromise = (payload: any) =>
			payload && typeof payload.then === "function";
		if (isPromise(action.payload)) {
			const { payload: promise, meta } = action as IPromiseReturn;
			const { onSuccess, onError, finally: onFinish, preExecute } = meta;
			if (preExecute) preExecute();
			promise
				.then((res: any) => (onSuccess ? onSuccess(res) : null))
				.catch((error: any) => (onError ? onError(error) : null))
				.finally(() => (onFinish ? onFinish() : null));
		}
	}
	next(action);
};
export default promiseMiddleware;
