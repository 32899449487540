import React from "react";
import classNames from "classnames";
type InfiniteProgressProps = {
	isLoading: boolean;
	isSmall?: boolean;
	isSticky?: boolean;
	isSpacedOut?: boolean;
	className?: string;
};
const InfiniteProgress: React.FC<InfiniteProgressProps> = ({
	isLoading,
	isSmall = false,
	isSticky = false,
	isSpacedOut = true,
	className,
}) => (
	<div
		className={classNames(
			"ws-progress",
			{
				active: isLoading,
				small: isSmall,
				isSticky,
				"is-spaced-out": isSpacedOut,
			},
			className
		)}
	>
		<div className="indeterminate" />
	</div>
);
export default InfiniteProgress;
