import EngineAndNoonInsight from "components/app/insight/engineandnoon/EngineAndNoonInsight";
import React from "react";

type ScreenEngineAndNoonProps = React.PropsWithChildren<{}>;

function ScreenEngineAndNoon(props: ScreenEngineAndNoonProps) {
	return <EngineAndNoonInsight />;
}

export default ScreenEngineAndNoon;
