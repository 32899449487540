import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupC3 } from "../../../../../redux/app/orb1/groups/group-c";
import CommaSeparator from "../../../../_common/CommaSeparator";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupC3PreviewProps = OwnProps;
function ORB1GroupC3Preview(props: ORB1GroupC3PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupC3;
	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						C
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">11.1</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.item11P3.oilResidues.map((oilResidue, index) => {
								return `${oilResidue.selectedTank.tankName}/ `;
							})}
						</h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">11.2</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.item11P3.oilResidues.map((oilResidue, index) => {
								return `${oilResidue.selectedTank.totalCapacity.toFixed(
									2
								)}M³/ `;
							})}
						</h4>
					</div>
				</td>
			</tr>

			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">11.3</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.item11P3.oilResidues.map((oilResidue, index) => {
								return `${marpolUtils
									.getQuantityForCalculation(oilResidue.selectedTank)
									.toFixed(2)}M³/ `;
							})}
						</h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">11.4</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.item11P3.oilResidues.map((oilResidue, index) => {
								if (oilResidue.holdingTankName)
									return `${oilResidue.collectedResidue.toFixed(
										2
									)}M³ COLLECTED FROM ${oilResidue.holdingTankName}/ `;
								return `${oilResidue.collectedResidue.toFixed(2)}M³/`;
							})}
						</h4>
					</div>
				</td>
			</tr>
			{orb1Record.remarks !== "" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{orb1Record.remarks !== "" ? orb1Record.remarks : null}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default ORB1GroupC3Preview;
