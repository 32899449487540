import { IJobNotification, IJobState } from "./types";

function getDefaultNotification(
	jobState: IJobState,
	message?: string,
	notification?: IJobNotification
): IJobNotification {
	switch (jobState) {
		case "ACTIVE":
			return {
				hideAtState: "IDLE",
				showAtState: "ACTIVE",
				...notification,
				message,
				timeout: notification?.timeout ? notification.timeout : 5000,
				icon: "dot",
				className: "ws-toast ws-toast--active",
				intent: "ACTIVE",
			};
		case "ERROR":
			return {
				hideAtState: "IDLE",
				showAtState: "ERROR",
				...notification,
				message,
				timeout: notification?.timeout ? notification.timeout : 5000,
				icon: "issue",
				intent: "ERROR",
				className: "ws-toast ws-toast--error",
			};
		case "SUCCESS":
			return {
				hideAtState: "IDLE",
				showAtState: "SUCCESS",
				...notification,
				message,
				timeout: notification?.timeout ? notification.timeout : 5000,
				icon: "tick",
				intent: "SUCCESS",
				className: "ws-toast ws-toast--success",
			};
		case "IDLE":
			return {
				hideAtState: "IDLE",
				showAtState: "IDLE",
				...notification,
				message,
				timeout: notification?.timeout ? notification.timeout : 5000,
				icon: "issue",
				intent: "IDLE",
				className: "ws-toast ws-toast--idle",
			};
	}
}

export default {
	getDefaultNotification,
};
