import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import feedbackActions from "redux/app/feedback/actions";
import classNames from "classnames";
import toolActions from "redux/app/tool/actions";
import toolSelectors from "redux/app/tool/selectors";
import Select from "react-select";
import clsx from "clsx";
import {
	clearIndicatorStyles,
	controlStyles,
	dropdownIndicatorStyles,
	menuStyles,
	optionStyles,
	placeholderStyles,
} from "redux/app/feedback/utils";
import {
	getTenantsOptionList,
	getVesselsOptionList,
} from "redux/app/tool/utils";
import { IOption, IVesselUser } from "redux/app/feedback/@types";
import TrainingCertificateList from "./List";
import { IListObjectStore } from "redux/_common/type";
import Switch from "components/_common/Switch";

type OwnProps = {
	isApprovals?: boolean;
};
type TrainingCertificateProps = PropsFromRedux & OwnProps;
function TrainingCertificate(props: TrainingCertificateProps) {
	const {
		tenants,
		vessels,
		selectedTenantOption,
		selectedVesselOption,
		approveCertificate,
		jobToLoadTenants,
		jobToLoadVesselSpecificToTenant,
		jobToLoadVesselSpecificUsers,
		jobToAddTenantSpecificTrainingUsersFromCSV,
		isApprovals
	} = props;

	const [searchText, setSearchText] = React.useState("");
	const [users, setUsers] = React.useState<IListObjectStore<IVesselUser>>({
		ids: [],
		byIds: {},
	});

	useEffect(() => {
		if (isApprovals && !approveCertificate) {
			props.setApproveCertificate(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isApprovals, approveCertificate])

	useEffect(() => {
		props.loadVessels();
		props.loadTenantsList();

		return () => {
			props.setTenant(undefined);
			props.setVessel(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tenantOptions = useMemo(
		() => getTenantsOptionList(tenants),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tenants.ids]
	);
	const vesselsOptions = useMemo(
		() => getVesselsOptionList(vessels),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[vessels.ids]
	);

	useEffect(() => {
		const tenantId = selectedTenantOption?.value;
		if (tenantId) {
			props.loadTenantSpecificVesselsList(tenantId);
		}
		props.setVessel(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value]);

	useEffect(() => {
		const tenantId = selectedTenantOption?.value;
		const vesselId = selectedVesselOption?.value;
		if (approveCertificate) {
			props.loadVesselSpecificUsers(undefined, undefined, true);
		} else if (tenantId) {
			props.loadVesselSpecificUsers(tenantId, vesselId);
		} else if (vesselId) {
			props.loadVesselSpecificUsers(vesselId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value, selectedVesselOption?.value, approveCertificate]);

	useEffect(() => {
		const newUsers = {
			ids: props.vesselSpecificUsers.ids.filter((id) => {
				const user = props.vesselSpecificUsers.byIds[id];
				return user.name.toLowerCase().includes(searchText.toLowerCase());
			}),
			byIds: {},
		} as any;
		newUsers.ids.forEach((id: any) => {
			newUsers.byIds[id] = props.vesselSpecificUsers.byIds[id];
		});
		setUsers(newUsers);
	}, [props.vesselSpecificUsers, searchText]);

	return (
		<>
			<div className="bg-white h-[calc(100vh-16px)] m-2 rounded-md">
				<div className="px-4 flex justify-between items-center pt-4 w-full">
					<div className="flex items-center justify-between w-full">
						<div
							className="flex items-center gap-x-2"
						>
							{
								!approveCertificate?
									<>
										<div className="w-44">
											<Select
												id="tenants"
												isClearable
												isSearchable
												key={"tenants" + selectedTenantOption?.value}
												placeholder={"Search tenants"}
												isDisabled={
													jobToLoadVesselSpecificToTenant.isLoading || jobToAddTenantSpecificTrainingUsersFromCSV.isLoading
												}
												unstyled
												value={selectedTenantOption}
												options={tenantOptions}
												isLoading={jobToLoadTenants.isLoading}
												onChange={(newValue, actionMeta) => {
													props.setTenant(newValue ?? undefined);
													// handleChange && handleChange(newValue, actionMeta)
												}}
												className={classNames("w-full text-sm", {
													"cursor-not-allowed": false,
												})}
												menuPosition="fixed"
												classNames={{
													option: ({ isFocused, isSelected }) =>
														clsx(
															isFocused && optionStyles.focus,
															isSelected && optionStyles.selected,
															optionStyles.base
														),

													dropdownIndicator: () => dropdownIndicatorStyles,
													menu: () => menuStyles,
													clearIndicator: () => clearIndicatorStyles,
													placeholder: () => placeholderStyles,
													control: ({ isFocused, isDisabled }) =>
														clsx(
															isFocused ? controlStyles.focus : controlStyles.nonFocus,
															isDisabled ? controlStyles.disabled : controlStyles.base
														),

													valueContainer: ({ isDisabled }) =>
														clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
												}}
											/>
										</div>
										<div className="w-44">
											<Select
												id="vessels"
												isClearable
												isSearchable
												key={"vessels" + selectedVesselOption?.value}
												placeholder={"Search vessels"}
												isDisabled={
													!selectedTenantOption?.value || jobToAddTenantSpecificTrainingUsersFromCSV.isLoading
												}
												unstyled
												value={selectedVesselOption}
												options={vesselsOptions}
												isLoading={jobToLoadVesselSpecificToTenant.isLoading}
												onChange={(newValue, actionMeta) => {
													props.setVessel(newValue ?? undefined);
												}}
												className={classNames("w-full text-sm", {
													"cursor-not-allowed": false,
												})}
												menuPosition="fixed"
												classNames={{
													option: ({ isFocused, isSelected }) =>
														clsx(
															isFocused && optionStyles.focus,
															isSelected && optionStyles.selected,
															optionStyles.base
														),

													dropdownIndicator: () => dropdownIndicatorStyles,
													menu: () => menuStyles,
													clearIndicator: () => clearIndicatorStyles,
													placeholder: () => placeholderStyles,
													control: ({ isFocused, isDisabled }) =>
														clsx(
															isFocused ? controlStyles.focus : controlStyles.nonFocus,
															isDisabled ? controlStyles.disabled : controlStyles.base
														),

													valueContainer: ({ isDisabled }) =>
														clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
												}}
											/>
										</div>
									</>
									: null
							}
							<div
								className="w-44"
							>
								<div className="relative">
									<div className="absolute inset-y-0 left-0 flex items-center pl-3 transform -translate-y-1/2 pointer-events-none top-1/2">
										<span className="text-sm text-gray-600 bp3-icon bp3-icon-search" />
									</div>
									<input
										type="text"
										className={classNames(
											"block p-2 py-[7px] pl-10 text-sm text-gray-900 border border-gray-300 placeholder:text-gray-500"
										)}
										placeholder={'Search users'}
										value={searchText}
										disabled={jobToAddTenantSpecificTrainingUsersFromCSV.isLoading}
										onChange={(e) =>
											setSearchText(e.target.value)
										}
									/>
								</div>
							</div>
						</div>
						<div
							className="flex items-center gap-x-4"
						>
							<div>
								{
									!isApprovals?
										<Switch
											id={"approveCertificate"}
											label="Training Approvals"
											isChecked={approveCertificate}
											handleChange={(isChecked) => {
												props.toolVesselSpecificUsersReset();
												props.setApproveCertificate(isChecked);
											}}
										/>
										: null
								}
							</div>
							<div className="file-input">
								<input
									onChange={(e) => {
										if (e?.target?.files) {
											const file = e.target.files[0]
											if (selectedTenantOption?.value) {
												props.tenantSpecificAddTrainingUsersFromCSV(selectedTenantOption.value, file);
											}
										}
									}}
									type="file"
									id="csv-file-upload"
									className="csv-file-upload-input"
									accept=".csv"
									multiple={false}
									disabled={!selectedTenantOption || jobToAddTenantSpecificTrainingUsersFromCSV.isLoading}
								/>
								<label
									htmlFor="csv-file-upload"
									className={
										classNames("flex items-center justify-center text-white rounded-md px-4 py-1.5", {
											'bg-blue-200 cursor-not-allowed': !selectedTenantOption,
											'bg-blue-500 cursor-pointer': !!selectedTenantOption
										})
									}
								>
									Upload CSV
								</label>
							</div>
							{/* <div className="file-input">
								<input
									onChange={(e) => {
										if (e?.target?.files) {
											const file = e.target.files[0]
											Papa.parse(file, {
												download: true,
												header: true,
												skipEmptyLines: true,
												complete: function (answer) {
													const users: IVesselUser[] = [];
													answer.data.forEach((row: any, index) => {
														const user: IVesselUser = {
															id: `${index}_${row.CID}`,
															name: row.NAME,
															staffId: row.CID,
															designation: {
																name: row.RANK
															},
															trainingDate: row?.DATE ? moment(row.DATE, 'DD/MM/YYYY').toISOString() : new Date().toISOString()
														} as any;
														users.push(user);
													});
													setUsers({
														ids: users.map((user) => user.id),
														byIds: users.reduce((acc, user) => {
															acc[user.id] = user;
															return acc;
														}, {} as any)
													});
												}
											});
										}
									}}
									type="file"
									id="csv-file-upload"
									className="csv-file-upload-input"
									accept=".csv"
									multiple={false}
								/>
								<label htmlFor="csv-file-upload" className="cursor-pointer bg-blue-500 flex items-center justify-center text-white rounded-md px-4 py-1.5">Upload CSV</label>
							</div> */}
						</div>
					</div>
				</div>
				<TrainingCertificateList
					approveCertificate={approveCertificate}
					vesselSpecificUsers={users}
					jobToLoadTenants={jobToLoadTenants}
					jobToLoadVesselSpecificUsers={jobToLoadVesselSpecificUsers}
					jobToLoadVesselSpecificToTenant={jobToLoadVesselSpecificToTenant}
					editVesselUserTrainingDate={props.editVesselUserTrainingDate}
					toolUserTrainingCertificateApprove={props.toolUserTrainingCertificateApprove}
				/>
			</div>
		</>
	)
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const tenants = toolSelectors.getTenants(toolStore);
	const vessels = toolSelectors.getVessels(toolStore);
	const vesselSpecificUsers = toolSelectors.getVesselSpecificUsers(toolStore);
	const jobToLoadTenants = toolSelectors.jobToLoadTenants(toolStore);
	const jobToLoadVesselSpecificToTenant = toolSelectors.jobToLoadVesselsSpecificToTenant(toolStore);
	const jobToAddTenantSpecificTrainingUsersFromCSV = toolSelectors.jobToAddTenantSpecificTrainingUsersFromCSV(toolStore);
	const jobToLoadVesselSpecificUsers = toolSelectors.jobToLoadVesselSpecificUsers(toolStore);
	const selectedTenantOption = toolSelectors.getSelectedTenantOption(toolStore);
	const selectedVesselOption = toolSelectors.getSelectedVesselOption(toolStore);
	const approveCertificate = toolSelectors.getApproveCertificate(toolStore);

	return {
		tenants,
		vessels,
		vesselSpecificUsers,
		selectedTenantOption,
		selectedVesselOption,
		approveCertificate,
		jobToLoadTenants,
		jobToLoadVesselSpecificUsers,
		jobToLoadVesselSpecificToTenant,
		jobToAddTenantSpecificTrainingUsersFromCSV
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadVessels() {
			dispatch(feedbackActions.commands.feedbackVesselsLoad());
		},
		toolUserTrainingCertificateApprove(userId: string) {
			dispatch(toolActions.commands.toolUserTrainingCertificateApprove(userId));
		},
		loadVesselSpecificUsers(tenantId?: string, vesselId?: string, isTrainingRequired?: boolean) {
			dispatch(toolActions.commands.toolVesselSpecificUsersLoad(tenantId, vesselId, isTrainingRequired));
		},
		toolVesselSpecificUsersReset() {
			dispatch(
				toolActions.document.toolVesselSpecificUsersSet(
					{
						byIds: {},
						ids: [],
					}
				)
			);
		},
		tenantSpecificAddTrainingUsersFromCSV(tenantId: string, file: File) {
			dispatch(toolActions.commands.toolTenantSpecificAddTrainingUsersFromCSV(tenantId, file));
		},
		loadTenantsList() {
			dispatch(toolActions.commands.toolTenantListLoad());
		},
		loadTenantSpecificVesselsList(tenantId: string) {
			dispatch(toolActions.commands.toolTenantSpecificVesselLoad(tenantId));
		},
		editVesselUserTrainingDate(userId: string, date: string) {
			dispatch(
				toolActions.commands.toolVesselSpecificUserEdit(userId, date)
			);
		},
		setTenant(tenantOption?: IOption) {
			dispatch(
				toolActions.document.toolConfigPrintTenandOptionSelect(tenantOption)
			);
		},
		setVessel(vesselOption?: IOption) {
			dispatch(
				toolActions.document.toolConfigPrintVesselOptionSelect(vesselOption)
			);
		},
		setApproveCertificate(approveCertificate: boolean) {
			dispatch(
				toolActions.document.toolTrainingApproveCertificate(approveCertificate)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(TrainingCertificate);
