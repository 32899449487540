import React from "react";
type SVGInfoProps = { className?: string };
const SVGInfo: React.FC<SVGInfoProps> = (props) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M15.0693 11.1172L11.2049 11.6016L11.0665 12.2428L11.8259 12.3829C12.322 12.501 12.4199 12.6799 12.3119 13.1743L11.0665 19.0266C10.7392 20.5402 11.2437 21.2524 12.43 21.2524C13.3497 21.2524 14.4179 20.8271 14.9022 20.2433L15.0507 19.5413C14.7132 19.8383 14.2205 19.9564 13.8931 19.9564C13.429 19.9564 13.2603 19.6307 13.3801 19.0569L15.0693 11.1172ZM15.1874 7.59375C15.1874 8.0413 15.0096 8.47052 14.6932 8.78699C14.3767 9.10346 13.9475 9.28125 13.4999 9.28125C13.0524 9.28125 12.6231 9.10346 12.3067 8.78699C11.9902 8.47052 11.8124 8.0413 11.8124 7.59375C11.8124 7.1462 11.9902 6.71697 12.3067 6.40051C12.6231 6.08404 13.0524 5.90625 13.4999 5.90625C13.9475 5.90625 14.3767 6.08404 14.6932 6.40051C15.0096 6.71697 15.1874 7.1462 15.1874 7.59375Z"
			fill="#718096"
		/>
	</svg>
);
export default SVGInfo;
