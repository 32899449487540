import React, { FC } from 'react';
import { IData } from '.';
import WayshipNumber from './WayshipNumber';

interface IRangeProps {
    data: IData;
    rangeErrorMsg?: string
    setData: React.Dispatch<React.SetStateAction<IData>>;
    setRangeErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
}

const Range: FC<IRangeProps> = ({ data, rangeErrorMsg, setData, setRangeErrorMsg }) => {
    const isError = rangeErrorMsg && rangeErrorMsg?.trim().length > 0;
    return (
        <div className='grid grid-cols-2 items-center gap-x-5'>
            <WayshipNumber
                error={isError && {
                    isValid: false
                }}
                step={0.1}
                title="Min Range"
                value={`${data.range[0]}`}
                isSubmit={data.metric.value === ''}
                dataKey={data.key}
                isWholeNumber={false}
                onChangeHandler={(v) => {
                    if (setRangeErrorMsg && rangeErrorMsg) {
                        setRangeErrorMsg('');
                    }
                    setData((prev) => ({
                        ...prev,
                        range: [v, data.range[1]],
                    }));
                }}
            />
            <WayshipNumber
                error={isError && {
                    isValid: false
                }}
                step={0.1}
                title="Max Range"
                value={`${data.range[1]}`}
                isSubmit={data.metric.value === ''}
                dataKey={data.key}
                isWholeNumber={false}
                onChangeHandler={(v) => {
                    if (setRangeErrorMsg && rangeErrorMsg) {
                        setRangeErrorMsg('');
                    }
                    setData((prev) => ({
                        ...prev,
                        range: [data.range[0], v],
                    }));
                }}
            /> 
            {isError? <p className='text-xs font-medium text-red-600 col-span-2'>{rangeErrorMsg}</p>: null}
        </div>
    );
};

export default Range;
