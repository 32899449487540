import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import TeekaySvgLogo from "components/_common/icons/clientLogos/TeekaySvgLogo";
import classNames from "classnames";
import { TViewType } from "redux/app/dashboard/@types";
import dashboardActions from "redux/app/dashboard/actions";
import dashboardSelectors from "redux/app/dashboard/selectors";
import { Tab, Tabs } from "@blueprintjs/core";
import { vesselActions, vesselSelectors } from "redux/app/vessel";
import { TAB_IDS } from "redux/app/vessel/constants";
import { authSelectors } from "redux/app/auth";
import EpsSvgLogo from "components/_common/icons/clientLogos/EpsSvgLogo";

type OwnProps = {};
type DashboardNavigationProps = PropsFromRedux & OwnProps;

function RenderClienLogo(props: { tenantCode: string }) {
	const tenantCode = props?.tenantCode;

	console.log("tenantCode", tenantCode);

	switch (tenantCode) {
		case "TEEKAY":
			return <TeekaySvgLogo className="h-10" />;
		case "EPS":
			return <EpsSvgLogo className="h-10" />;
		default:
			return (
				<div className="pt-1 pl-4 text-transparent flex flex-col justify-center bg-clip-text bg-gradient-to-r from-[#4A9EF9] to-[#0B2F56] text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-medium">
					{tenantCode}
				</div>
			);
	}
}
function DashboardNavigation(props: DashboardNavigationProps) {
	const { isMapView, isTableView, selectedTabId, tenantInfo, tenantCode } =
		props;

	useEffect(() => {
		if (selectedTabId === TAB_IDS.myVessels) {
			props.loadMyVessels();
		} else {
			props.loadAllVessels();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabId]);
	
	useEffect(() => {
		props.listInfoLoad();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantInfo?._id]);

	return (
		<div
			className="sticky top-0 right-0 border-b border-[#B3B3B3] bg-white md:h-[62px] md:py-[3px]"
			style={{ zIndex: 30 }}
		>
			<div className="flex flex-wrap justify-around md:justify-between py-4 pb-0 md:items-center md:py-0">
				<div className="pt-2 pb-2 md:w-64">
					<RenderClienLogo tenantCode={tenantCode} />
				</div>
				<div className="order-3 md:order-2 text-transparent flex flex-col justify-center items-center bg-clip-text bg-gradient-to-r from-[#4A9EF9] to-[#0B2F56] text-sm sm:text-base md:text-lg lg:text-xl  xl:text-3xl font-medium md:pl-0">
					<div>
						<Tabs
							id="vessel-tabs"
							animate={true}
							renderActiveTabPanelOnly={true}
							vertical={false}
							large={true}
							selectedTabId={selectedTabId}
							className="w-full rounded-sm"
							onChange={function onTabsChange(tabId) {
								props.handleTabChange(tabId as TAB_IDS);
							}}
						>
							<Tab
								id={TAB_IDS.myVessels}
								title="My Vessels"
								disabled={false}
								className="px-2 py-1 font-medium text-gray-600"
							></Tab>
							<Tab
								id={TAB_IDS.all}
								title="All"
								disabled={false}
								className="px-2 py-1 font-medium text-gray-600"
							></Tab>
							<Tabs.Expander />
						</Tabs>
					</div>
				</div>
				<div className="order-2 md:order-3 flex justify-end px-4 py-2 md:min-w-64 ">
					<div className="p-0">
						<div className="">
							<div className="flex flex-row justify-around border-[#0B2F564D] border rounded-md ">
								<button
									className={classNames(
										"transition-all duration-300 px-4 md:px-8 text-[12px]",
										{
											"p-2 flex-1  font-medium text-gray-800 bg-[#D7D9FF] rounded-md shadow-sm outline-none":
												isMapView,
											"p-2 flex-1  font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
												isTableView,
										}
									)}
									onClick={() => {
										props.setTableView("map");
									}}
								>
									Map
								</button>
								<button
									className={classNames(
										"transition-all duration-300 px-4 md:px-8 text-[12px]",
										{
											"py-1.5 flex-1  font-medium text-gray-800 bg-[#D7D9FF] rounded-md shadow-sm outline-none":
												isTableView,
											"py-1.5 flex-1  font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
												isMapView,
										}
									)}
									onClick={() => {
										props.setTableView("table");
									}}
								>
									Table
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const dashboardStore = dashboardSelectors._getStore(store);
	const vesselStore = store.app.vesselStore;
	const viewType = dashboardSelectors.getViewType(dashboardStore);
	const user = store.app.auth.user;
	const tenantInfo = store.app.auth.tenantInfo;
	const tenantCode = authSelectors(store.app.auth).getTenantCode();

	return {
		isMapView: viewType === "map",
		isTableView: viewType === "table",
		user,
		selectedTabId: vesselSelectors.getSelectedTab(vesselStore),
		tenantInfo,
		tenantCode,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setTableView(viewType: TViewType) {
			dispatch(dashboardActions.document.dashboardViewTypeSet(viewType));
		},
		loadAllVessels() {
			dispatch(vesselActions.listLoadNew());
		},
		loadMyVessels() {
			dispatch(vesselActions.listMyVessels());
		},
		listInfoLoad() {
			dispatch(vesselActions.listInfoLoad());
		},
		handleTabChange(tabSelected: TAB_IDS) {
			dispatch(vesselActions.tabSelect(tabSelected));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(DashboardNavigation);
