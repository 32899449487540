import React from "react";

const CustomYearXAxisTick = (props: any) => {
	const { x, y, payload, width, visibleTicksCount } = props;
	const xOffset = width / visibleTicksCount;

	if (payload && payload?.value) {
		return (
			<g>
				<path
					stroke="#878787"
					fill="#878787"
					strokeWidth={2}
					d={`M${x - xOffset} ${y + 5} L${width} ${y + 5}`}
				/>
			</g>
		);
	}
	return null;
};

export default CustomYearXAxisTick;
