import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TBallastRecord } from "../../../../../redux/app/ballast/@types";
import { TBallast_Ballast } from "../../../../../redux/app/ballast/groups/ballast";
import CommaSeparator from "../../../../_common/CommaSeparator";
import BallastTableRows from "../BallastTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type ExchangeOperationPreviewProps = OwnProps;
function ExchangeOperationPreview(props: ExchangeOperationPreviewProps) {
	const record = props.ballastRecord as TBallast_Ballast;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<BallastTableRows
				record={record}
				tableRows={[
					[operationDate, "3.2.1", renderItem3P2P1()],
					[null, "3.2.2", renderItem3P2P2()],
					[null, "3.2.3", renderItem3P2P3()],
					[null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem3P2P1() {
		return (
			<h4 className="font-medium uppercase">
				{validateAndPreviewPlaceAndPosition(record)}
			</h4>
		);
	}
	function renderItem3P2P2() {
		return (
			<h4 className="font-medium uppercase">
				{record.totalOperatedWaterVolume} M³,{" "}
				{record.ballastROB
					? `Total Retained Quantity Onboard: ${record.ballastROB} M³`
					: ""}
			</h4>
		);
	}
	function renderItem3P2P3() {
		if (!record.isBallastWaterTreatmentPlanUsed) return null;
		return (
			<h4 className="font-medium uppercase">
				{record.isBallastWaterTreatmentPlanUsed ? "YES" : "NO"}
			</h4>
		);
	}

	const validateAndPreviewPlaceAndPosition = (record: TBallast_Ballast) => {
		return (
			<>
				{record.placeOfBallast.place === "IN_PORT" ? (
					<>
						{moment(record.tanks[0].startDate, "YYYY-MM-DD").format(
							"DD-MMM-YYYY"
						)}{" "}
						{record.tanks[0].startTime} to{" "}
						{moment(
							record.tanks[record.tanks.length - 1].endDate,
							"YYYY-MM-DD"
						).format("DD-MMM-YYYY")}{" "}
						{record.tanks[record.tanks.length - 1].endTime},{" "}
						{record.placeOfBallast.portName}
					</>
				) : null}
				{record.placeOfBallast.place === "AT_SEA" ? (
					<>
						{moment(record.tanks[0].startDate, "YYYY-MM-DD").format(
							"DD-MMM-YYYY"
						)}{" "}
						{record.tanks[0].startTime}{" "}
						{record.placeOfBallast.startPosition.latitude},{" "}
						{record.placeOfBallast.startPosition.longitude}, to{" "}
						{moment(
							record.tanks[record.tanks.length - 1].endDate,
							"YYYY-MM-DD"
						).format("DD-MMM-YYYY")}{" "}
						{record.tanks[record.tanks.length - 1].endTime},{" "}
						{record.placeOfBallast.endPosition.latitude},{" "}
						{record.placeOfBallast.endPosition.longitude}, DEPTH: {record.depth}
						M
					</>
				) : null}
			</>
		);
	};

	return renderMain();
}

export default ExchangeOperationPreview;
