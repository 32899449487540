// import IRouteFormat from "./IRouteFormat";
import { createBrowserHistory } from "history";
export { default as rootRoutes } from "./routes";
// export default IRouteFormat;
export const history = createBrowserHistory();
export interface ICPHistory {
	push: (url: string) => void;
	pop: () => void;
	replace: (url: string) => void;
	goBack: () => void;
	goForward: () => void;
	location: any;
}
