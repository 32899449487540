const FORM_SUBMIT = "form/SUBMIT"; // Command and Event action - Form Submitted
const FORM_FIELDS_SET = "form/fields/SET"; // Document and Event action - Set Complete form Object/Field
const FORM_FIELDS_EDIT = "form/fields/EDIT"; // Document and Event action - Update only given fields of form.
const FORM_FIELDS_CLEAR = "form/fields/CLEAR"; // Document and Event action - Clear only given fields of form.
const FORM_ERRORS_SET = "form/errors/SET"; // Document and Event action - Set Errors for given fields in form.
const FORM_ERRORS_RESET = "form/errors/RESET"; // Document and Event action - Reset Errors for given fields of form.
const FORM_WARNINGS_SET = "form/warnings/SET"; // Document and Event action - Set Warnings for given fields in form.
const FORM_WARNINGS_RESET = "form/warnings/RESET"; // Document and Event action - Reset Warnings for given fields of form.
const FORM_HELPMESSAGES_SET = "form/help-messages/SET"; // Document and Event action - Set HelperMessages for all the fields in Form.
const FORM_HELPMESSAGES_RESET = "form/help-messages/RESET"; // Document and Event action - Reset HelperMessage for all the fields in form.
const FORM_RESET = "form/RESET"; // Document and Event action - Reset complete form including [Fields, Errors, Warning, HelperMessages].
const FORM_MODE_SET = "form/mode/SET"; // Document and Event action - Set the mode which tells if form being is used to create or edit an entity.

const formActionTypes = {
	FORM_FIELDS_SET,
	FORM_FIELDS_EDIT,
	FORM_FIELDS_CLEAR,
	FORM_ERRORS_SET,
	FORM_ERRORS_RESET,
	FORM_WARNINGS_SET,
	FORM_WARNINGS_RESET,
	FORM_HELPMESSAGES_SET,
	FORM_HELPMESSAGES_RESET,
	FORM_RESET,
	FORM_SUBMIT,
	FORM_MODE_SET,
};

export default formActionTypes;
