import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Button from "../../../../../@set-product/core/button";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../../redux/app/vessel/onboard";
import { IStore } from "../../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import InfiniteProgress from "../../../../_common/InfiniteProgress";
import { Icon, Popover } from "@blueprintjs/core";
import { commonUtils } from "../../../../../redux/_common";
import ConfirmBox from "../../../../_common/ConfirmBox";
import Modal from "../../../_common/Modal";
import { useBoolean } from "react-use";

type VesselOnboardTanksProps = {
	vesselId: string;
} & PropsFromRedux;
function VesselOnboardTanks(props: VesselOnboardTanksProps) {
	const { tanks, jobs, onboardStatus, vesselId } = props;
	const { listLoadJob } = jobs;

	const [tankSearchText, setTankSearchText] = useState<string | undefined>(
		undefined
	);
	const [tankSearchBoxVisible, toggleSearchBox] = useBoolean(false);

	useEffect(function onLoad() {
		props.resetDeviceList();
		props.loadTanks(vesselId);
	}, []);

	const classes = {
		th: "p-3 m-px text-xs font-medium leading-4 tracking-wide text-left text-gray-600 bg-gray-200 border border-gray-300 whitespace-nowrap",
		thNumber:
			"p-3 m-px text-xs font-medium leading-4 tracking-wide text-right text-gray-600 bg-gray-200 border border-gray-300 whitespace-nowrap",
		td: "p-3 m-px text-sm leading-7 text-left  border whitespace-nowrap",
		tdNumber:
			"p-3 m-px text-sm leading-7 text-right  border whitespace-nowrap text-gray-700",
		tdTankName:
			"p-3 m-px text-sm leading-6 text-left  border whitespace-nowrap text-indigo-800",
	};

	console.log("Loading tanks:asdasdasd");

	return (
		<div className="">
			<div className="flex flex-row items-end justify-between my-6">
				<div>
					<h3 className="text-xl font-medium text-gray-700 font-redhat-display">
						Tanks list
					</h3>
					{listLoadJob.isLoading ? (
						<h6 className="text-sm text-gray-600">Loading tanks...</h6>
					) : (
						<h6 className="text-sm text-gray-600">
							{tanks.length} tanks available
						</h6>
					)}
				</div>
				<div className="flex flex-row items-end justify-end h-full -mx-1"></div>
			</div>
			<div
				className={classNames({
					"h-auto opacity-100 transition-all duration-300 animate-pulse":
						listLoadJob.isLoading && tanks.length === 0,
					"h-0 opacity-0 transition-all duration-300 animate-pulse":
						!listLoadJob.isLoading,
				})}
			>
				<InfiniteProgress
					isLoading={listLoadJob.isLoading}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{
							"animate-pulse": listLoadJob.isLoading,
							hidden: !listLoadJob.isLoading,
						}
					)}
				>
					<thead>
						<tr>
							<th className="text-sm">Type</th>
							<th className="text-sm">Device Name</th>
							<th className="text-sm">OS</th>
							<th className="text-sm">Version</th>
							<th className="text-sm">Device Id</th>
							<th className="text-sm">MAC address</th>
							<th className="text-sm">
								<span>Actions</span>
							</th>
						</tr>
					</thead>
				</table>
				{listLoadJob.isLoading ? (
					<div className="flex-col justify-center h-64 py-24 bg-gray-100 ">
						<h4 className="-my-3 text-2xl text-center font-redhat-display">
							Loading please wait..
						</h4>
					</div>
				) : null}
			</div>
			<div className="mx-0 overflow-x-auto">
				{tanks.length > 0 ? (
					<table className="w-full">
						<thead className="sticky top-0 bg-gray-100">
							<tr className="uppercase">
								<th className={classNames(classes.th, "text-center")}>#</th>
								<th className={classNames(classes.th, "relative min-w-64")}>
									{!tankSearchBoxVisible ? (
										<span
											id="tank-name-search"
											className="absolute top-0 bottom-0 left-0 right-0 p-3 align-middle"
											onClick={() => {
												toggleSearchBox(true);
												setTimeout(
													() =>
														document
															.getElementById("tank-name-search")
															?.focus(),
													200
												);
											}}
										>
											Tank Name
										</span>
									) : (
										<span
											id="tank-name-search"
											className="absolute top-0 bottom-0 left-0 right-0 p-3 align-middle"
											contentEditable={true}
											onInput={(e) => {
												setTankSearchText(e.currentTarget.innerText);
											}}
											onBlur={(e) => {
												setTankSearchText(e.currentTarget.innerText);
												if (e.currentTarget.innerText === "")
													toggleSearchBox(false);
											}}
										></span>
									)}
									<span className="absolute ml-auto text-xs right-3 top-3.5 bp3-icon bp3-icon-search text-gray-500" />
								</th>
								<th className={classes.thNumber}>Capacity (M³)</th>
								<th className={classes.thNumber}>Sounding (M³)</th>
								<th className={classes.thNumber}>Retained (M³)</th>
							</tr>
						</thead>
						<tbody>
							{tanks
								.filter((tank) =>
									tankSearchText ? tank.tankName.includes(tankSearchText) : true
								)
								.map((tank, index) => {
									return (
										<tr key={tank.id} className="font-medium hover:bg-gray-100">
											<td
												className={classNames(
													classes.td,
													"text-gray-500 text-xs leading-6 text-center"
												)}
											>
												{index + 1}
											</td>
											<td className={classes.tdTankName}>
												<h1>{tank.tankName}</h1>
												<h4 className="text-xs text-gray-500">
													{tank.category} - {tank.type.replaceAll("_", " ")}
												</h4>
											</td>
											<td className={classes.tdNumber}>{tank.totalCapacity}</td>
											<td className={classes.tdNumber}>
												{tank.soundingQuantity}
											</td>
											<td className={classes.tdNumber}>
												{tank.expectedQuantity}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				) : !listLoadJob.isLoading ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-xl font-semibold text-gray-500 font-redhat-display">
							No Tanks present
						</h5>
						<p className="invisible p-3 text-sm leading-5 text-gray-700">
							Tanks can be added from ship side.
						</p>
					</div>
				) : null}
			</div>
		</div>
	);
}

const { tankListLoad } = vesselOnboardActions.command;
const mapStateToProps = (store: IStore) =>
	vesselOnboardSelectors.getTanksListData(store.app.vesselStore.onboard);
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadTanks(vesselId: string) {
			dispatch(tankListLoad(vesselId));
		},
		resetDeviceList() {
			dispatch(vesselOnboardActions.document.tanksSet([]));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselOnboardTanks);
