import React from "react";
import ToolList from "components/app/tool/list/List";

type ScreenToolsListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreenToolsList(props: ScreenToolsListProps) {
	return <ToolList />;
}

export default ScreenToolsList;
