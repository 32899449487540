import React, { useEffect } from "react";
import classNames from "classnames";
import InputWrapper from "components/_common/InputWrapper";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch } from "redux";
import { IVesselUser } from "redux/app/user/@types";
import userActions from "redux/app/user/actions";
import { userFormDefaults } from "redux/app/user/reducers.state";
import userSelectors from "redux/app/user/selectors";
import { IStore } from "redux/store";
import Button from "@set-product/core/button/Button";
import WSDropdown from "../../../../_common/Dropdown";
import { authSelectors } from "redux/app/auth";

type UserFormProps = PropsFromRedux & {
	title: string;
	onCancel(): void;
	onClose(): void;
};

function UserForm(props: UserFormProps) {
	const { form, jobs, title, uiStaticData } = props;
	const { hasErrors, helpMessages, fields, errors, warnings } = form;

	useEffect(() => {
		if (
			jobs.userCreate.isSuccess ||
			jobs.userCreate.isError ||
			jobs.userUpdate.isSuccess ||
			jobs.userUpdate.isError
		)
			props.onClose();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		jobs.userCreate.isSuccess,
		jobs.userCreate.isError,
		jobs.userUpdate.isSuccess,
		jobs.userUpdate.isError,
	]);

	return (
		<div
			className={classNames(
				"w-full h-full my-6 bg-white bp3-dialog py-3",
				{}
			)}
		>
			<div className="p-8 py-0 mx-0 overflow-y-auto bg-white rounded-sm bp3-dialog-body">
				<h3 className="my-4 text-2xl font-normal">{title}</h3>
				{hasErrors && (
					<h5
						className={classNames("text-sm text-gray-600", {
							"text-red-500 font-medium": hasErrors,
						})}
					>
						Form has few errors
					</h5>
				)}
				<form
					className="flex flex-row flex-wrap items-baseline justify-start p-2 -mx-2 -my-2"
					onSubmit={function onSubmit(e) {
						e.preventDefault();
						props.formSubmit();
						props.resetForm();
					}}
					autoComplete="off"
				>
					<div className="w-full ">
						<InputWrapper
							className="w-full"
							hasError={!errors.name.isValid}
							hasWarning={!warnings.name.isValid}
							inputValue={fields.name}
						>
							<label
								htmlFor="user-name"
								className="ws-input__label"
							>
								Name
							</label>
							<input
								type="text"
								className="rounded ws-input"
								autoComplete="off"
								value={fields.name}
								onChange={function onNameChange(e) {
									props.formFieldEdit(
										{ name: e.target.value },
										fields
									);
								}}
							/>
							<span className="ws-input__error">
								{errors.name.message}
							</span>
							<span className="ws-input__warning">
								{warnings.name.message}
							</span>
							<span className="ws-input__help-message">
								{helpMessages.name}
							</span>
						</InputWrapper>
						{form.mode === "CREATE" && (
							<InputWrapper
								className="w-full"
								hasError={!errors.email.isValid}
								hasWarning={!warnings.email.isValid}
								inputValue={fields.email}
							>
								<label
									htmlFor="user-email"
									className="ws-input__label"
								>
									Email
								</label>
								<input
									type="text"
									className="rounded ws-input"
									autoComplete="off"
									value={fields.email}
									onChange={function onNameChange(e) {
										props.formFieldEdit(
											{ email: e.target.value },
											fields
										);
									}}
								/>
								<span className="ws-input__error">
									{errors.email.message}
								</span>
								<span className="ws-input__warning">
									{warnings.email.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.email}
								</span>
							</InputWrapper>
						)}
						{form.mode === "EDIT" && (
							<InputWrapper
								className="w-full"
								hasError={!errors.staffId.isValid}
								hasWarning={!warnings.staffId.isValid}
								inputValue={fields.staffId}
							>
								<label
									htmlFor="user-staffid"
									className="ws-input__label"
								>
									Staff id
								</label>
								<input
									type="text"
									className="rounded ws-input"
									autoComplete="off"
									value={fields.staffId}
									onChange={function onNameChange(e) {
										props.formFieldEdit(
											{ staffId: e.target.value },
											fields
										);
									}}
								/>
								<span className="ws-input__error">
									{errors.staffId.message}
								</span>
								<span className="ws-input__warning">
									{warnings.staffId.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.staffId}
								</span>
							</InputWrapper>
						)}
						<InputWrapper
							className="w-full"
							hasError={!errors.roleCategory.isValid}
							hasWarning={!warnings.roleCategory.isValid}
							inputValue={fields.roleCategory}
						>
							<label
								htmlFor="device-type-id"
								className="ws-input__label"
							>
								Role
							</label>
							<WSDropdown
								id="device-type-id"
								inputWrapperProps={{
									hasError: !errors.roleCategory.isValid,
									hasWarning: !warnings.roleCategory.isValid,
									inputValue: fields.roleCategory,
								}}
								value={fields.roleCategory}
								// @ts-ignore
								options={(() => {
									if (!uiStaticData) return [];
									return [
										{
											label: "Select Role",
											value: "",
										},
										...uiStaticData.roleCategory.options.map(
											(roleType) => {
												return {
													value: roleType.value,
													label: roleType.label,
												};
											}
										),
									];
								})()}
								onSelect={function onDeviceTypeChange(e) {
									props.formFieldEdit(
										{
											roleCategory: e?.value,
										},
										fields
									);
								}}
								renderListItem={function renderDeviceTypeItem(
									listItem
								) {
									return (
										<div>
											<span className="ml-3 text-gray-800">
												{listItem.label}
											</span>
										</div>
									);
								}}
								renderView={function renderUserTypeView(
									selectedItem
								) {
									return (
										<div>
											<span className="ml-3 text-gray-800">
												{selectedItem.label}
											</span>
										</div>
									);
								}}
								noFilter={true}
							/>
							<span className="ws-input__error">
								{errors.roleCategory.message}
							</span>
							<span className="ws-input__warning">
								{warnings.roleCategory.message}
							</span>
							<span className="ws-input__help-message">
								{helpMessages.roleCategory}
							</span>
						</InputWrapper>
					</div>
					<div className="mt-6">
						<Button
							text="Submit"
							disabled={
								jobs.userCreate.isLoading ||
								jobs.userUpdate.isLoading
							}
							view="filled"
							type="submit"
							color="primary"
							className="px-10 mr-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
						/>
						<Button
							text="Cancel"
							view="smooth"
							disabled={
								jobs.userCreate.isLoading ||
								jobs.userUpdate.isLoading
							}
							className="mx-2 font-medium "
							onClick={() => {
								props.resetForm();
								props.onCancel();
							}}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	// const authStore = store.app.auth;
	// const isUserAllowedToAccessFeedbackModule =
	// 	authSelectors(authStore).isUserAllowedToAccessModule("users");

	// 	console.log("isUserAllowedToAccessFeedbackModule", isUserAllowedToAccessFeedbackModule);
	const uiStaticData = userSelectors.getDeviceFormStaticData();
	const userFormData = userSelectors.getUserFormData(store.app.user);

	return {
			uiStaticData,
			form: userFormData.form,
			jobs: userFormData.jobs,
		};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		formModeSet(mode: "CREATE" | "EDIT" | "NONE") {
			dispatch(userActions._forms.user.formModeSet(mode));
		},
		formFieldEdit(fields: Partial<IVesselUser>, allFields: IVesselUser) {
			dispatch(
				userActions._forms.user.formErrorsReset([
					"GLOBAL",
					...Object.keys(allFields),
				])
			);
			dispatch(userActions._forms.user.formFieldsEdit(fields));
		},
		formSubmit() {
			dispatch(userActions._forms.user.formSubmit());
		},
		resetForm() {
			dispatch(userActions._forms.user.formFieldsSet(userFormDefaults));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(UserForm);
