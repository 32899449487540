import React from "react";
import { ORB1TableColumns } from "./ORB1TableRows";
import { TORB1BackendRecord, TORB1RecordUser } from "redux/app/orb1/@types";
import { marpolUtils } from "redux/app/marpol";

function getSignTableRows(orb1Record: TORB1BackendRecord): ORB1TableColumns[] {
	function getFormattedDate(dateWithTime: string) {
		return dateWithTime;
		return marpolUtils.getDate(dateWithTime);
	}

	function getVerificationUser() {
		if (!orb1Record.verifiedBy) return undefined;
		return {
			...orb1Record.verifiedBy,
			date: orb1Record.submittedBy.date,
		};
	}

	function renderSignatures() {
		if (!orb1Record.submittedBy) return null;
		const verifiedUser = getVerificationUser();
		return (
			<h4 className="font-medium leading-9 uppercase">
				RECORDED BY: {renderSignatureItem(orb1Record.submittedBy)}
				<br />
				VERIFIED BY: {renderSignatureItem(verifiedUser, !orb1Record.verifiedBy)}
				<br />
				APPROVED BY: {renderSignatureItem(orb1Record.approvedBy)}
			</h4>
		);
	}

	function renderStrikeOffSign() {
		if (!orb1Record.strikedBy) return null;
		return (
			<h4 className="font-medium leading-9 uppercase">
				STRUCK OFF BY: {renderSignatureItem(orb1Record.strikedBy)}
				<div className="w-full">
					STRUCK OFF REASON:
					<span className="ml-1 font-bold text-red-700">
						{orb1Record.strikeOffReason}
					</span>
				</div>
			</h4>
		);
	}

	function renderSignatureItem(
		user?: TORB1RecordUser,
		isPending: boolean = false
	) {
		if (!user || isPending) return "PENDING";
		const { date, designation, name, staffId } = user;
		return `${name} [${designation}] - ${staffId}
		 on ${getFormattedDate(date)}`;
	}

	return [
		[null, null, null, renderSignatures()],
		[null, null, null, renderStrikeOffSign()],
	];
}

export default getSignTableRows;
