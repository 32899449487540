import React from "react";
import DashboardList from "components/app/dashboard/list";

type ScreenFeedbackListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreenFeedbackList(props: ScreenFeedbackListProps) {
	return <DashboardList />;
}

export default ScreenFeedbackList;
