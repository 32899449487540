import Button from "@set-product/core/button/Button";
import ConfirmBox from "components/_common/ConfirmBox";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IUser } from "redux/app/user/@types";
import { IStore } from "redux/store";
import userActions from "redux/app/user/actions";
import userSelectors from "redux/app/user/selectors";
import { Popover } from "@blueprintjs/core";

type OwnProps = {
	user: IUser;
	indexNumber: number;
	onEditClick: (userId: string) => void;
};
type ListRowProps = PropsFromRedux & OwnProps;
function ListRow(props: ListRowProps) {
	const { user, indexNumber, onEditClick, jobs, selectedUserId, isAdmin } =
		props;
	const { name, email, roleCategory, id } = user;
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const isCurrentUserRowRoleAdmin = roleCategory.toLowerCase() === "admin";

	return (
		<tr>
			<td className="px-3 py-3 text-sm border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
				{indexNumber}
			</td>
			<td className="px-3 py-3 text-sm border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
				{name}
			</td>
			<td className="px-3 py-3 text-sm border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
				{email}
			</td>
			<td className="px-3 py-3 text-sm border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
				{roleCategory}
			</td>
			{isAdmin && (
				<td className="px-3   text-sm border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#0B2F56] underline  font-semibold">
					{!isCurrentUserRowRoleAdmin && (
						<div className="flex items-center">
							<span
								onClick={() => {
									onEditClick && onEditClick(id);
								}}
								className="cursor-pointer"
							>
								Edit
							</span>
							<Popover
								className="rounded-lg"
								canEscapeKeyClose={true}
								isOpen={isPopoverOpen}
								content={
									<ConfirmBox
										description={`Do you really want to delete user named ${user.name}?`}
										title="Confirm Deletion"
										primaryText="Delete"
										secondaryText="Cancel"
										state="ACTIVE"
										onPrimaryAction={() => {
											if (!jobs.userDelete.isLoading) {
												props.onUserDeleteClick(user.id);
												props.setSelectedUserId("");
											}
										}}
										onSecondaryAction={() => {
											setIsPopoverOpen(false);
											props.setSelectedUserId("");
										}}
									/>
								}
								target={
									<Button
										onClick={(e) => {
											if (!jobs.userDelete.isLoading && !selectedUserId) {
												props.setSelectedUserId(user.id);
												setIsPopoverOpen(true);
											}
										}}
										dense={true}
										view="flat"
										color="primary"
										rightIcon="cross"
										className="p-1 mx-5 font-medium text-gray-500 hover:shadow-none hover:bg-orange-500 hover:text-white"
									/>
								}
							></Popover>
						</div>
					)}
				</td>
			)}
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const authStore = store.app.auth;
	const isAdmin = authStore.user?.roleCategory === "admin";
	return {
		jobs: userSelectors.getUserFormData(store.app.user).jobs,
		selectedUserId: store.app.user.selectedUserId,
		isAdmin,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		onUserDeleteClick(userId: string) {
			dispatch(userActions.commands.userDelete(userId));
		},
		setSelectedUserId(userId: string) {
			dispatch(userActions.document.selectedUserIdSet(userId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ListRow);
