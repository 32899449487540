import { IAPIRequestMetaData } from "../../../_core/api/types";
import vesselOnboardActionTypes from "./action-types";
import { IAllRanges, Ranges } from "./types";
import { IVesselCrew, IVesselDevice, IVessel } from "../types";
import { commonUtils } from "redux/_common";
import { TConfigType, IConfigListItem, IConfigInfo } from "redux/app/tool/@types";
const {
	WIZARD_VESSEL_LOAD,
	WIZARD_VESSEL_IMAGE_UPLOAD,
	WIZARD_VESSEL_CREATE,
	WIZARD_VESSEL_UPDATE,
	WIZARD_CREW_LIST_LOAD,
	WIZARD_CREW_LIST_IMPORT,
	WIZARD_DEVICE_LIST_LOAD,
	WIZARD_CREW_LOAD,
	WIZARD_CREW_IMAGE_UPLOAD,
	WIZARD_CREW_CREATE,
	WIZARD_CREW_UPDATE,
	WIZARD_CREW_DELETE,
	WIZARD_CREW_DESIGNATIONS_LOAD,
	WIZARD_DEVICE_LOAD,
	WIZARD_DEVICE_CREATE,
	WIZARD_DEVICE_UPDATE,
	WIZARD_DEVICE_DELETE,
	WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD,
	WIZARD_VESSEL_RANGES_LOAD,
	WIZARD_VESSEL_CONFIG_LOAD,
	WIZARD_VESSEL_CONFIG_LIST_LOAD,
	GET_TIME_LOGS
} = vesselOnboardActionTypes.command;

const vesselOnboardAPIs = {
	loadTimeLogs(vesselId: string, page: number): IAPIRequestMetaData<any> {
		const limit = 10;
		return {
			feature: GET_TIME_LOGS,
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/timelogs`,
			queryParams: {
				vesselId,
				skip: (page - 1) * limit,
				limit
			}
		};
	},
	loadUsabilityAndAlerts(vesselId: string,fromDate?:string,toDate?:string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD,
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/vesselAlerts?id=${vesselId}`,
			queryParams:{
				fromDate,
				toDate
			}
		};
	},
	loadVesselAPI(vesselId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_VESSEL_LOAD,
			method: "GET",
			url: `api/vessels/${vesselId}`,
		};
	},
	loadVesselRanges(
		payload: {
			vesselId: string;
		} & {
			token: string;
			userId: string;
		}
	): IAPIRequestMetaData<{ localRanges: Ranges }> {
		const { vesselId, token, userId } = payload;
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/ranges`,
			feature: WIZARD_VESSEL_RANGES_LOAD,
			method: "GET",
			queryParams: {
				vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	vesselRangeAdd(
		payload: {
			vesselId: string;
			ranges: IAllRanges;
		} & {
			token: string;
			userId: string;
		}
	): IAPIRequestMetaData<any> { 
		const { vesselId, token, userId, ranges } = payload;
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/ranges`,
			feature: WIZARD_VESSEL_RANGES_LOAD,
			method: "POST",
			queryParams: {
				vesselId,
			},
			body: {
				...ranges,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	vesselRangeEdit(
		payload: {
			vesselId: string;
			ranges: IAllRanges;
		} & {
			token: string;
			userId: string;
		}
	): IAPIRequestMetaData<any> { 
		const { vesselId, token, userId, ranges } = payload;
		
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/ranges`,
			feature: WIZARD_VESSEL_RANGES_LOAD,
			method: "POST",
			queryParams: {
				vesselId,
			},
			body: {
				...ranges,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	vesselRangeDelete(
		payload: {
			vesselId: string;
			ranges: IAllRanges;
		} & {
			token: string;
			userId: string;
		}
	): IAPIRequestMetaData<any> { //{ localRanges: Ranges }
		const { vesselId, token, userId, ranges } = payload;
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/ranges`,
			feature: WIZARD_VESSEL_RANGES_LOAD,
			method: "POST",
			queryParams: {
				vesselId,
			},
			body: {
				...ranges,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadConfigsListForType(
		category: TConfigType,
		userId: string,
		token?: string,
		vesselId?: string
	): IAPIRequestMetaData<IConfigListItem[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "GET",
			feature: WIZARD_VESSEL_CONFIG_LIST_LOAD,
			queryParams: {
				category,
				vesselId: vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadConfigInfo(
		configId: string,
		token: string,
		userId: string
	): IAPIRequestMetaData<IConfigInfo> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "GET",
			feature: WIZARD_VESSEL_CONFIG_LOAD,
			queryParams: {
				id: configId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	uploadVesselImageAPI(file: File): IAPIRequestMetaData<any> {
		const formData = new FormData();
		formData.append("file", file);
		return {
			feature: WIZARD_VESSEL_IMAGE_UPLOAD,
			method: "POST",
			url: "api/storages/wayshipprivate/upload",
			headers: { "Content-Type": "multipart/form-data" },
		};
	},
	createVesselAPI(vessel: IVessel): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_VESSEL_CREATE,
			method: "POST",
			url: "api/vessels",
			body: {
				...vessel,
				id: undefined,
				isCrewOnboarded: undefined,
				isDeviceOnboarded: undefined,
				createdAt: undefined,
				updatedAt: undefined,
				tenantId: undefined,
			},
		};
	},
	updateVesselAPI(
		vesselId: string,
		vessel: Partial<IVessel>
	): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_VESSEL_UPDATE,
			method: "PATCH",
			url: "api/vessels/" + vesselId,
			body: vessel,
		};
	},
	loadCrewsAPI(vesselId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_CREW_LIST_LOAD,
			method: "GET",
			url: `api/users`,
			body: {},
			queryParams: {
				filter: {
					// include: "user",
					where: {
						"designation.vesselId": vesselId,
						isRelieved: false,
					},
				},
			},
		};
	},
	loadDesignationsForCrewAPI(): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_CREW_DESIGNATIONS_LOAD,
			method: "GET",
			url: "api/users/designations",
		};
	},
	importCrewsAPI(file: File, vesselId: string): IAPIRequestMetaData<any> {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("vesselId", vesselId);
		return {
			feature: WIZARD_CREW_LIST_IMPORT,
			method: "POST",
			url: "api/crews/importCSV",
			body: {},
			queryParams: {},
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};
	},
	loadDevicesAPI(vesselId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_DEVICE_LIST_LOAD,
			method: "GET",
			url: "api/edgeDevices",
			body: {},
			queryParams: {
				filter: {
					where: { vesselId },
				},
			},
		};
	},
	importDevicesAPI(file: File, vesselId: string): IAPIRequestMetaData<any> {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("vesselId", vesselId);
		return {
			feature: WIZARD_CREW_LIST_IMPORT,
			method: "POST",
			url: "api/edgeDevices/importCSV",
			body: {},
			queryParams: {},
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};
	},
	loadCrewAPI(crewId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_CREW_LOAD,
			method: "GET",
			url: `api/crews/${crewId}`,
			body: {},
			queryParams: {
				filter: {
					include: "user",
					where: { isOnboard: true },
				},
			},
		};
	},
	uploadCrewImageAPI(file: File): IAPIRequestMetaData<any> {
		const formData = new FormData();
		formData.append("file", file);
		return {
			feature: WIZARD_CREW_IMAGE_UPLOAD,
			method: "POST",
			url: "api/storages/wayshipprivate/upload",
			body: formData,
			queryParams: {},
			headers: { "Content-Type": "multipart/form-data" },
		};
	},
	createCrewAPI(crew: IVesselCrew): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_CREW_CREATE,
			method: "POST",
			url: "api/crews",
			body: { ...crew },
		};
	},
	updateCrewAPI(
		crewId: string,
		crew: Partial<IVesselCrew>
	): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_CREW_UPDATE,
			method: "PATCH",
			url: `api/crews/${crewId}`,
			body: { ...crew },
		};
	},
	deleteCrewAPI(crewId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_CREW_DELETE,
			method: "PUT",
			url: "api/users/relieve",
			body: { crewId },
		};
	},
	loadDeviceAPI(deviceId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_DEVICE_LOAD,
			method: "GET",
			url: `api/edgeDevices/${deviceId}`,
		};
	},
	createDeviceAPI(device: IVesselDevice): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_DEVICE_CREATE,
			method: "POST",
			url: "api/edgeDevices",
			body: { ...device, id: undefined },
			queryParams: {},
		};
	},
	updateDeviceAPI(
		deviceId: string,
		device: Partial<IVesselDevice>
	): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_DEVICE_UPDATE,
			method: "PATCH",
			url: `api/edgeDevices/${deviceId}`,
			body: device,
		};
	},
	deleteDeviceAPI(deviceId: string): IAPIRequestMetaData<any> {
		return {
			feature: WIZARD_DEVICE_DELETE,
			method: "DELETE",
			url: `api/edgeDevices/${deviceId}`,
		};
	},
};

export default vesselOnboardAPIs;
