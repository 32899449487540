import { IStore } from "../../store";
import { commonUtils } from "../../_common";
import { jobSelectors } from "../../_core/job";
import { IMARPOLStore } from "./@types";
import { EMARPOLActions } from "./actions";

export default {
	_getStore(store: IStore) {
		return store.app.marpol;
	},
	getMARPOLReport(marpolStore: IMARPOLStore) {
		return marpolStore.marpolReport;
	},
	getDocs(marpolStore: IMARPOLStore) {
		return commonUtils.objectToArray(marpolStore.docs);
	},
	getFilters(marpolStore: IMARPOLStore) {
		return marpolStore.filters;
	},
	getPagination(marpolStore: IMARPOLStore, pageIds?: string[]) {
		const paginationStore = marpolStore._pagination;
		return {
			totalItems: paginationStore.totalItems,
			currentPage: paginationStore.currentPage,
			itemsPerPage: paginationStore.itemsPerPage,
			itemIds: pageIds
				? pageIds
				: marpolStore._pagination.pageWiseListIds[paginationStore.currentPage],
		};
	},
	isVesselSelected(marpolStore: IMARPOLStore) {
		return marpolStore.filters.vesselId !== "";
	},
	jobToLoadDocs(store: IMARPOLStore) {
		return jobSelectors.getJobStatus(store, EMARPOLActions.MARPOL_DOCS_LOAD);
	},
};
