import React from "react";
import classNames from "classnames";
type SVGReportProps = {
	className?: string;
};
const SVGReport: React.FC<SVGReportProps> = (props) => {
	const isActive = props?.className?.includes("bg-blue-100");
	return (
		<div
			className={classNames(
				props.className,
				"flex justify-center items-center"
			)}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="24px"
				height="24px"
				viewBox="0 0 24 24"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
				className={classNames("w-auto stroke-current")}
			>
				<g id="surface1">
					<path
						stroke="none"
						fillRule="nonzero"
						fill={
							isActive ? "#133774" : "rgb(96.078431%,96.470588%,98.431373%)"
						}
						d="M 19.082031 0.730469 L 4.402344 0.730469 C 3.429688 0.734375 2.5 1.121094 1.808594 1.808594 C 1.121094 2.5 0.734375 3.429688 0.730469 4.402344 L 0.730469 19.082031 C 0.734375 20.054688 1.121094 20.988281 1.808594 21.675781 C 2.5 22.363281 3.429688 22.753906 4.402344 22.753906 L 19.082031 22.753906 C 20.054688 22.753906 20.988281 22.363281 21.675781 21.675781 C 22.363281 20.988281 22.753906 20.054688 22.753906 19.082031 L 22.753906 4.402344 C 22.753906 3.429688 22.363281 2.5 21.675781 1.808594 C 20.988281 1.121094 20.054688 0.734375 19.082031 0.730469 Z M 21.285156 19.082031 C 21.285156 19.667969 21.050781 20.226562 20.640625 20.640625 C 20.226562 21.050781 19.667969 21.285156 19.082031 21.285156 L 4.402344 21.285156 C 3.820312 21.285156 3.261719 21.050781 2.847656 20.640625 C 2.433594 20.226562 2.203125 19.667969 2.203125 19.082031 L 2.203125 4.402344 C 2.203125 3.820312 2.433594 3.261719 2.847656 2.847656 C 3.261719 2.433594 3.820312 2.203125 4.402344 2.203125 L 19.082031 2.203125 C 19.667969 2.203125 20.226562 2.433594 20.640625 2.847656 C 21.050781 3.261719 21.285156 3.820312 21.285156 4.402344 Z M 21.285156 19.082031 "
					/>
					<path
						stroke="none"
						fillRule="nonzero"
						fill={
							isActive ? "#133774" : "rgb(96.078431%,96.470588%,98.431373%)"
						}
						d="M 17.09375 8.289062 L 13.730469 11.652344 C 13.589844 11.792969 13.40625 11.871094 13.210938 11.871094 C 13.015625 11.871094 12.828125 11.792969 12.691406 11.652344 L 11.832031 10.796875 C 11.417969 10.382812 10.855469 10.152344 10.273438 10.152344 C 9.691406 10.152344 9.132812 10.382812 8.71875 10.796875 L 5.351562 14.160156 C 5.28125 14.230469 5.226562 14.308594 5.1875 14.398438 C 5.152344 14.488281 5.128906 14.582031 5.128906 14.683594 C 5.128906 14.78125 5.148438 14.878906 5.183594 14.96875 C 5.222656 15.054688 5.273438 15.136719 5.34375 15.207031 C 5.410156 15.277344 5.492188 15.332031 5.582031 15.371094 C 5.675781 15.402344 5.769531 15.425781 5.867188 15.421875 C 5.96875 15.421875 6.0625 15.402344 6.152344 15.363281 C 6.242188 15.324219 6.324219 15.269531 6.390625 15.199219 L 9.753906 11.832031 C 9.894531 11.691406 10.082031 11.617188 10.273438 11.617188 C 10.472656 11.617188 10.65625 11.691406 10.792969 11.832031 L 11.652344 12.691406 C 12.066406 13.105469 12.628906 13.335938 13.210938 13.335938 C 13.796875 13.335938 14.355469 13.105469 14.769531 12.691406 L 18.132812 9.324219 C 18.265625 9.1875 18.339844 9.003906 18.339844 8.808594 C 18.335938 8.617188 18.261719 8.433594 18.121094 8.296875 C 17.988281 8.160156 17.804688 8.082031 17.609375 8.082031 C 17.417969 8.082031 17.234375 8.152344 17.09375 8.289062 Z M 17.09375 8.289062 "
					/>
				</g>
			</svg>
		</div>
	);
};
export default SVGReport;
