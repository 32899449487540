import React from "react";
import CargoSlop from "components/app/report/waste/cargoslop/CargoSlop";

type ScreenCargoSlopProps = React.PropsWithChildren<{}>;

function ScreenCargoSlop(props: ScreenCargoSlopProps) {
	return <CargoSlop />;
}

export default ScreenCargoSlop;
