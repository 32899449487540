import {
	IFormStore,
	IFormErrorFormat,
	IForm,
	IForm__Get,
	IFormFieldsFormat,
} from "./types";

const hasErrors = (errors?: IFormErrorFormat) => {
	if (errors) {
		const keys = Object.keys(errors);
		const foundError = keys.find((key) => !errors[key].isValid);
		return !!foundError;
	}
	return false;
};
const hasWarnings = (warnings?: IFormErrorFormat) => hasErrors(warnings);

const formSelectors = <T extends IFormFieldsFormat>(
	store: IForm<T>
): IForm__Get<T> => ({
	hasErrors: hasErrors(store.errors),
	hasWarnings: hasWarnings(store.warnings),
	...store,
});

export default formSelectors;
