import { createAction } from "typesafe-actions";

// Document Actions
const TOPNAVBAR_SEARCHTEXT_SET = "@topnavbar/search-text/SET";
const TOPNAVBAR_TITLE_SET = "@topnavbar/title/SET";
const TOPNAVBAR_SEARCH_PLACEHOLDER_SET = "@topnavbar/search-placeholder/SET";

const topNavbarActions = {
	command: {},
	document: {
		searchTextSet: createAction(
			TOPNAVBAR_SEARCHTEXT_SET,
			(searchText: string) => ({ searchText })
		)(),
		navbarTitleSet: createAction(TOPNAVBAR_TITLE_SET, (title: string) => ({
			title,
		}))(),
		navbarSearchPlaceholderSet: createAction(
			TOPNAVBAR_SEARCH_PLACEHOLDER_SET,
			(placeholder: string) => ({
				placeholder,
			})
		)(),
	},
};

export default topNavbarActions;
