import { Middleware } from "redux";
import { IDashboardActions } from "./@types";
import { IStore } from "redux/store";


const dashboardMiddleware: Middleware<{}, IStore, any> = ({
	dispatch,
	getState,
}) => (next) => (action: IDashboardActions) => {
	switch(action.type){
		default: {
			next(action)
		}
	}
}

export default dashboardMiddleware;