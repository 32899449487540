import React from "react";
import {
	EORB4RecordCode,
	EORB4RecordState,
	TORB4BackendRecord,
} from "redux/app/orb4/@types";

import ORB4A1Preview from "./previews/ORB4A1Preview";
import ORB4B1Preview from "./previews/ORB4B1Preview";
import ORB4C1Preview from "./previews/ORB4C1Preview";
import ORB4D1Preview from "./previews/ORB4D1Preview";
import ORB4E1Preview from "./previews/ORB4E1Preview";
import ORB4G1Preview from "./previews/ORB4G1Preview";
import ORB4H1Preview from "./previews/ORB4H1Preview";
import ORB4H2Preview from "./previews/ORB4H2Preview";
import ORB4I1Preview from "./previews/ORB4I1Preview";
import ORB4J1Preview from "./previews/ORB4J1Preview";
import ORB4J2Preview from "./previews/ORB4J2Preview";
import ORB4M1Preview from "./previews/ORB4M1Preview";
import ORB4N1Preview from "./previews/ORB4N1Preview";
import ORB4O1Preview from "./previews/ORB4O1Preview";
import ORB4O3Preview from "./previews/ORB4O3Preview";
import ORB4O6Preview from "./previews/ORB4O6Preview";
import ORB4TableRows from "./ORB4TableRows";
import moment from "moment";

type OwnProps = {
	orbRecord: TORB4BackendRecord;
};
type ORB4PreviewerProps = OwnProps;
function ORB4Previewer(props: ORB4PreviewerProps) {
	const { orbRecord } = props;
	const isStrikedOff = orbRecord.state === EORB4RecordState.STRIKED_OFF;
	const isEditOfApprovedRecord = !!orbRecord.dateOfApprovedRecordEdit;

	function renderMain() {
		if (isEditOfApprovedRecord) return renderEditedApprovedRecord();
		return renderRecord(orbRecord, isStrikedOff);
	}

	function renderEditedApprovedRecord() {
		return (
			<>
				{renderEditCorrectionLine()}
				{renderRecord(orbRecord, isStrikedOff)}
				{renderStruckOffVersionOfEdit()}
			</>
		);
	}
	function renderEditCorrectionLine() {
		if (!orbRecord.dateOfApprovedRecordEdit) return null;
		const formattedEditDate = moment(
			orbRecord.dateOfApprovedRecordEdit,
			"YYYY-MM-DD"
		).format("DD-MMM-YYYY");

		return (
			<ORB4TableRows
				record={orbRecord}
				tableRows={[
					[
						formattedEditDate,
						"O",
						"-",
						"ENTRY PERTAINING TO AN EARLIER INCORRECT OPERATIONAL ENTRY",
					],
				]}
			/>
		);
	}

	function renderStruckOffVersionOfEdit() {
		if (!orbRecord.editVersions) return null;
		const oldVersionOfRecord = {
			...orbRecord.editVersions[0],
			state: EORB4RecordState.STRIKED_OFF,
		};
		return <ORB4Previewer orbRecord={oldVersionOfRecord} />;
	}

	function renderRecord(
		recordToRender: TORB4BackendRecord,
		isStrikedOff: boolean = false
	) {
		switch (recordToRender.recordCode) {
			case EORB4RecordCode.ORB4A1:
				return (
					<ORB4A1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4B1:
				return (
					<ORB4B1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4C1:
				return (
					<ORB4C1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4D1:
				return (
					<ORB4D1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4E1:
				return (
					<ORB4E1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4G1:
				return (
					<ORB4G1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4H1:
				return (
					<ORB4H1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4H2:
				return (
					<ORB4H2Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4I1:
				return (
					<ORB4I1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);

			case EORB4RecordCode.ORB4J1:
				return (
					<ORB4J1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4J2:
				return (
					<ORB4J2Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4M1:
				return (
					<ORB4M1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4N1:
				return (
					<ORB4N1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4O1:
				return (
					<ORB4O1Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4O3:
				return (
					<ORB4O3Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EORB4RecordCode.ORB4O6:
				return (
					<ORB4O6Preview
						orb4Record={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			default:
				return null;
		}
	}

	return renderMain();
}

export default ORB4Previewer;
