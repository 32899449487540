import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import toolActions from "redux/app/tool/actions";
import PrintForm from "../../_elements/PrintForm";

type OwnProps = {};
type PrintUpdateProps = PropsFromRedux & OwnProps;
function PrintUpdate(props: PrintUpdateProps) {
	useEffect(() => {
		return () => {
			props.setPrintInfo(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<PrintForm
				action="Update"
				actionLabel="Update"
				onClose={() => props.togglePrintUpdateModal()}
				type="update"
				isDisabled={false}
				handleActionClick={(
					config,
					changeLog?: string,
					newFileRequired?: boolean
				) => {
					props.printUpdate(config, changeLog ?? "", newFileRequired);
				}}
			/>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		togglePrintUpdateModal() {
			dispatch(toolActions.document.printUpdateModalToggle());
		},
		// TODO: any needs to be replaced by IPrintInfo
		setPrintInfo(print?: any) {
			dispatch(toolActions.document.toolPrintInfoSet(print));
		},
		printUpdate(print: any, changeLog: string, newFileRequired?: boolean) {
			dispatch(
				toolActions.commands.toolPrintUpdate(
					print,
					changeLog,
					newFileRequired
				)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PrintUpdate);
