import React from "react";
import { Middleware } from "redux";
import { apiActions, apiUtils } from "redux/_core/api";
import { IStore } from "../../store";
import { ITopNavbarActions } from "./@types";
import feedbackAPIs from "../feedback/api";
import { commonUtils } from "redux/_common";

import { jobActions } from "redux/_core/job";
import feedbackConstants from "./constants";
import { IFeedback } from "../feedback/@types";
import feedbackActions from "../feedback/actions";
import topNavbarSelectors from "./selectors";

function debouncedSearch(props: {
	dispatch: any;
	job: any;
	searchText: any;
	authToken: any;
	userId: any;
}) {
	const { dispatch, job, searchText, authToken, userId } = props;
	dispatch(
		apiActions.apiRequest<IFeedback[]>({
			...feedbackAPIs.searchFeedbacks(
				{ queryString: searchText },
				authToken,
				userId
			),
			preExecute() {
				dispatch(
					job.active({
						message: "Searching feedbacks",
						notification: {},
					})
				);
			},
			postExecute: {
				onSuccess({ data }) {
					// const vesselSpecificUsers = commonUtils.arrayToObject(data);
					// dispatch(
					// 	feedbackActions.document.vesselSpecificUsersSet(
					// 		vesselSpecificUsers
					// 	)
					// );

					console.log("search feedbacks", data);

					dispatch(
						feedbackActions.document.feedbackListSet(data ?? [])
					);
					dispatch(
						job.success({
							message: `Found ${data?.length} feedbacks`,
							notification: {
								// hideAtState: "SUCCESS",
								// timeout: 100,
							},
						})
					);
				},
				onError() {
					dispatch(
						job.error({
							message: "Error loading Users",
							notification: {},
						})
					);
				},
				finally() {
					dispatch(job.idle({}));
				},
			},
		})
	);
}

const debouncedSearchQuery = commonUtils.debounce(debouncedSearch, 1000);

const { apiRequest } = apiActions;
const topNavbarMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action: ITopNavbarActions) => {
		switch (action.type) {
			case "@topnavbar/search-text/SET": {
				next(action);
				const { searchText } = action.payload;
				// searchFeedbacks
				const job = jobActions(feedbackConstants.FEATURE, action.type);
				const authStore = getState().app.auth;
				const authToken = authStore.authToken;
				// @ts-ignore
				const userId = authStore?.user?.userId ?? "";

				const navbarTitle = topNavbarSelectors.getNavbarTitle(
					getState().app.topnavbar
				);

				if (navbarTitle === "Feedbacks") {
					searchText &&
						debouncedSearchQuery({
							dispatch,
							job,
							searchText,
							authToken,
							userId,
						});
				}

				break;
			}

			default: {
				next(action);
			}
		}
	};

export default topNavbarMiddleware;
