import { Middleware } from "redux";
import authConstants from "../constants";
import * as yup from "yup";
import { promiseActions } from "../../../_core/promise-action";
import authActions, { IAuthActions } from "../actions";
import { IStore } from "../../../store";
import { IAuthFormResetPasswordErrors } from "../types";
import { invokerAction } from "../../../_common/actions";
import { getType } from "typesafe-actions";

const { FEATURE, forms } = authConstants;

const resetPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.required("Password is required")
		.min(7, "Minimum 7 Characters"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password")], "Passwords must match"),
	accessToken: yup.string().required("Access token is required"),
});

const resetPasswordFormMiddleware: Middleware<any, IStore> = ({
	dispatch,
	getState,
}) => (next) => (action: IAuthActions) => {
	switch (action.type) {
		case getType(authActions._forms.resetPassword.formSubmit): {
			next(action);
			const { formName, feature } = action.payload;
			if (feature === FEATURE && formName === forms.RESET_PASSWORD) {
				const authStore = getState().app.auth;
				dispatch(
					promiseActions(
						resetPasswordSchema.validate(
							authStore._forms.RESET_PASSWORD.fields,
							{ abortEarly: false }
						),
						{
							invoker: "$auth/reset-password.form",
							onSuccess: (validatedData) => {
								if (validatedData) {
									const { password, accessToken } = validatedData;
									dispatch(authActions.resetPassword(password, accessToken));
								}
								dispatch(
									invokerAction(
										"$auth/reset-password.form",
										authActions._forms.resetPassword.formErrorsSet({
											GLOBAL: {
												isValid: true,
												message: "No data Provided",
											},
										})
									)
								);
							},
							onError: (error: yup.ValidationError) => {
								const errors: Partial<IAuthFormResetPasswordErrors> = {
									GLOBAL: {
										isValid: false,
										message: "Found few errors",
									},
								};
								error.inner.map((errorItem) => {
									errors[errorItem.path] = {
										isValid: false,
										message: errorItem.message,
									};
								});
								dispatch(
									invokerAction(
										"$auth/reset-password.form",
										authActions._forms.resetPassword.formErrorsSet(errors)
									)
								);
							},
						}
					)
				);
			}
			break;
		}
		case getType(authActions._forms.resetPassword.formFieldsEdit): {
			next(action);
			const { formName, feature, fields } = action.payload;
			if (feature === FEATURE && formName === forms.RESET_PASSWORD) {
				// To reset the errors on Change //FIXME: Add below code in Form Middleware
				dispatch(
					authActions._forms.resetPassword.formErrorsReset([
						"GLOBAL",
						...Object.keys(fields),
					])
				);
			}
			break;
		}
		default: {
			next(action);
		}
	}
};
export default resetPasswordFormMiddleware;
