import React, { useEffect } from "react";
import classNames from "classnames";
import DefaultInput from "../../../@set-product/core/Inputs/DefaultInput";
import Button from "../../../@set-product/core/button";
import { connect } from "react-redux";
import { IStore } from "../../../redux/store";
import { authSelectors, authActions } from "../../../redux/app/auth";
import { Dispatch } from "redux";
import {
	IAuth_Get_ResetPasswordData,
	IAuthFormResetPasswordFields,
} from "../../../redux/app/auth/types";
import { historyActions } from "../../../redux/_core/history";
import { rootRoutes } from "../../../routes";

type AuthResetProps = IAuth_Get_ResetPasswordData & {
	onChange: (field: Partial<IAuthFormResetPasswordFields>) => void;
	onSubmit: () => void;
	onLoginClick: () => void;
	accessTokenViaUrl?: string;
};
function AuthReset(props: AuthResetProps) {
	const { form, resetPasswordJob, accessTokenViaUrl } = props;
	const { fields, errors, hasErrors } = form;
	const { confirmPassword, password } = fields;

	useEffect(() => {
		if (accessTokenViaUrl) props.onChange({ accessToken: accessTokenViaUrl });
	}, [accessTokenViaUrl]);

	function onSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		props.onSubmit();
	}

	return (
		<div>
			<form className="my-6" onSubmit={onSubmit}>
				<div className="my-6">
					<h4
						className={classNames(
							"font-medium text-2xl font-redhat-text text-gray-500" // Font [Font Size, Font style etc]
						)}
					>
						Reset Password
					</h4>
				</div>
				<div className="w-full my-3">
					<DefaultInput
						value={password}
						fill={true}
						placeholder="Password"
						type="password"
						label="New Password"
						error={errors?.password ? !errors.password.isValid : false}
						errorHelperText={errors?.password?.message}
						onChange={(e) => {
							e.preventDefault();
							props.onChange({
								password: (e.target as HTMLInputElement).value,
							});
						}}
						disabled={resetPasswordJob.isLoading}
					/>
				</div>
				<div className="w-full my-3">
					<DefaultInput
						value={confirmPassword}
						fill={true}
						placeholder="Confirm Password"
						type="password"
						label="Confirm Password"
						error={
							errors?.confirmPassword ? !errors.confirmPassword.isValid : false
						}
						errorHelperText={errors?.confirmPassword?.message}
						onChange={(e) => {
							e.preventDefault();
							props.onChange({
								confirmPassword: (e.target as HTMLInputElement).value,
							});
						}}
						disabled={resetPasswordJob.isLoading}
					/>
				</div>
				<div className="w-full my-8">
					<Button
						type="submit"
						color="primary"
						text="Reset Password"
						fill={true}
						view="filled"
						className="shadow-none"
						disabled={resetPasswordJob.isLoading}
					/>
				</div>
			</form>
			<div className="my-8">
				<div className="self-center my-3 text-center">
					<span className="text-gray-600">Remember your password?</span>{" "}
					<div>
						<Button
							view="flat"
							className="h-auto p-0 m-0 text-blue-500 border-0 hover:shadow-none"
							onClick={() => props.onLoginClick()}
						>
							Click here to Login
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (store: IStore) =>
	authSelectors(store.app.auth).getResetPasswordData();

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (field: any) =>
		dispatch(authActions._forms.resetPassword.formFieldsEdit(field)),
	onSubmit: () => dispatch(authActions._forms.resetPassword.formSubmit()),
	onLoginClick: () =>
		dispatch(
			historyActions.push(
				"#Reset",
				rootRoutes.children.auth.children.login.url()
			)
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthReset);
