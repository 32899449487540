import { getType } from "typesafe-actions";
import { authActions } from ".";
import { IAPIRequestMetaData } from "../../_core/api/types";
import actions from "./actions";
import { ITenantInfo } from "./types";

const login = (email: string, password: string): IAPIRequestMetaData<any> => ({
	feature: getType(authActions.login),
	method: "POST",
	url: `api/auth/signin`,
	body: { email, password },
});

const samlLogin = (code: string): IAPIRequestMetaData<any> => ({
	feature: getType(authActions.SAMLLogin),
	method: "GET",
	url: '/auth/token',
	queryParams: {code: code},
});

const logout = (): IAPIRequestMetaData<any> => ({
	feature: getType(authActions.logout),
	method: "POST",
	url: `api/users/logout`,
});

const resetRequestViaMail = (email: string): IAPIRequestMetaData<any> => ({
	feature: getType(authActions.resetRequest),
	method: "POST",
	url: `api/users/reset`,
	body: { email },
});

const resetPassword = (
	newPassword: string,
	accessToken: string
): IAPIRequestMetaData<any> => ({
	feature: getType(authActions.resetPassword),
	method: "POST",
	url: `api/users/reset-password?access_token=${accessToken}`,
	body: {
		newPassword,
	},
});

const tenantInfoLoad = (
	tenantId: string
): IAPIRequestMetaData<ITenantInfo> => ({
	feature: getType(authActions.tenantInfoLoad),
	method: "GET",
	url: `https://console.wayship.io/api/tenants`,
	queryParams: {
		id: tenantId,
	},
});

const authAPIs = {
	login,
	samlLogin,
	logout,
	resetRequestViaMail,
	resetPassword,
	tenantInfoLoad
};

export default authAPIs;
