import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";

type OwnProps = {
	isSingle: boolean;
	strArr: string[];
};
type ArrowedStringProps = PropsFromRedux & OwnProps;
function ArrowedString(props: ArrowedStringProps) {
	const { isSingle, strArr } = props;
	const filteredArr = strArr.filter((val) => !!val);
	const arrLen = filteredArr.length;
	return (
		<>
			{filteredArr.map((title, index) => {
				return (
					<Fragment key={title}>
						<span>{title}</span>
						{index !== arrLen - 1 ? (
							<>
								<span className="bp3-icon bp3-icon-chevron-right text-[12px]" />
								{!isSingle ? (
									<span className="bp3-icon bp3-icon-chevron-right text-[12px] -ml-2" />
								) : null}
							</>
						) : null}
					</Fragment>
				);
			})}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ArrowedString);
