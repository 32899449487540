import { createReducer } from "typesafe-actions";
import { IApproval } from "./types";
import logActions from "./actions";
const reducerState: IApproval = {
	approvals: [],
	totalLogsCount: 0,
	_jobs: {}
};

const actions = logActions;
const approvalReducers = createReducer<any, any>(reducerState)
	.handleAction(actions.listSet, function logListSet(state: any, action: any) {
		return { ...state, approvals: action.payload.approvals };
	});
export default approvalReducers;
