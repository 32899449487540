import React from 'react'
import VesselInfo from '../../../../../components/app/vessel/onboard/info';

type ScreenVesselOnboardInfoType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
		};
	};
}>;
const ScreenVesselOnboardInfo: React.FC<ScreenVesselOnboardInfoType> = (
	props: ScreenVesselOnboardInfoType
) => {
	return <VesselInfo vesselId={props.match.params.vesselId} />;
};

export default ScreenVesselOnboardInfo;