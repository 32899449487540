import { apiActions, apiTypesCreator } from "../../_core/api";
import { Middleware } from "redux";
import actionTypes from "./action-types";
import { authActions } from "../auth";
import { commonUtils } from "../../_common";

const { apiRequest } = apiActions;
const { APP_INITIALIZE, APP_RESET } = actionTypes;
const appCoreMiddleware: Middleware = ({ dispatch, getState }) => (next) => (
	action
) => {
	switch (action.type) {
		case APP_INITIALIZE: {
			next(action);
			dispatch(
				commonUtils.getFeatureAction(APP_INITIALIZE, authActions.authorizeApp())
			);
			break;
		}
		case APP_RESET: {
			next(action);
			break;
		}
		default:
			next(action);
	}
};
export default appCoreMiddleware;
