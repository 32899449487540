import classNames from "classnames";
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { createPortal } from "react-dom";

interface ISidebarModalProps {
	title?: ReactNode;
	isActive: boolean;
	onClose: () => void;
	className?: string;
}

const SidebarModal: FC<PropsWithChildren<ISidebarModalProps>> = ({
	children,
	className,
	isActive,
	onClose,
	title,
}) => {
	if (!isActive) return null;
	return createPortal(
		<div className="absolute inset-0 flex justify-end z-[100] bg-gray-900 bg-opacity-75">
			<div
				className={classNames(
					"bg-white shadow-lg h-full overflow-auto ws-scroll_transparent",
					className
				)}
			>
				<header
					className={classNames("flex items-center p-4 gap-x-4", {
						"justify-end": !title,
						"justify-between": title,
					})}
				>
					{title}
					<button
						onClick={onClose}
						className="rounded-full p-2 flex items-center justify-center hover:bg-gray-300 hover:shadow-md"
					>
						<span className="text-xl bp3-icon bp3-icon-cross text-red-600" />
					</button>
				</header>
				{children}
			</div>
		</div>,
		document.body
	);
};

export default SidebarModal;
