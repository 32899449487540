import React from "react";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import classNames from "classnames";
import { IconName } from "@blueprintjs/core";
import InfiniteProgress from "components/_common/InfiniteProgress";

type IDropdownOption<T> = {
	value: string;
	label: string;
	icon?: IconName;
	rightIcon?: IconName;
} & T;
type YearPickerProps<T> = React.PropsWithChildren<{
	/**
	 * Unique id required to reference and differentiate between multiple dropdown..
	 * Its also required to remember dropdown on any rerender
	 */
	id: string;
	options: IDropdownOption<T>[];
	emptyOption?: IDropdownOption<T>;
	disabled?: boolean;
	placeholder?: string;
	value?: string;
	/**
	 * Dont filter as per input text @default false
	 */
	noFilter?: boolean;
	isReadOnly?: boolean;
	renderListItem?(listItem: IDropdownOption<T>): React.ReactElement;
	renderView?(selectedItem: IDropdownOption<T>): React.ReactElement;
	renderEmptyView?(): React.ReactElement;
	onSelect(value: IDropdownOption<T> | null): void;
	onFocus?: () => void;
	style?: React.CSSProperties;
	isLoading?: boolean;
}>;
function YearPicker<T>(props: YearPickerProps<T>) {
	const {
		disabled,
		options,
		emptyOption,
		value,
		noFilter = true,
		id,
		onFocus,
		style = {},
		isLoading = false,
	} = props;
	const dropdownViewId = "dropdown-view-" + id;

	const optionsObject = options.reduce<{ [key: string]: IDropdownOption<T> }>(
		(result, item, index) => {
			result[item.value] = item;
			return result;
		},
		emptyOption ? { [emptyOption.value]: emptyOption } : {}
	);

	function itemToString(item: IDropdownOption<T> | null) {
		return item ? item.label : "";
	}
	function onSelect(selection: IDropdownOption<T> | null) {
		props.onSelect(selection);
	}
	function reduceState(
		state: DownshiftState<IDropdownOption<T>>,
		changes: StateChangeOptions<IDropdownOption<T>>
	): Partial<StateChangeOptions<IDropdownOption<T>>> {
		return changes;
	}

	return (
		<>
			<Downshift
				itemToString={itemToString}
				onChange={onSelect}
				initialSelectedItem={optionsObject[value || ""]}
				stateReducer={reduceState}
				selectedItem={optionsObject[value || ""]}
			>
				{({
					getRootProps,
					getToggleButtonProps,
					getItemProps,
					getMenuProps,
					isOpen,
					highlightedIndex,
					selectedItem,
					inputValue,
				}) => {
					return (
						<div
							className={classNames(
								"p-2 border border-gray-300 rounded-sm shadow-sm bg-gray-100",
								{
									"bg-gray-100": !disabled || !isLoading,
									"cursor-not-allowed": disabled || isLoading,
									"bg-gray-300": disabled || isLoading,
								}
							)}
							{...getRootProps(undefined, { suppressRefError: true })}
							style={{ ...style }}
						>
							<button
								onFocus={onFocus}
								{...getToggleButtonProps({
									id: dropdownViewId,
									disabled,
									className: classNames(
										"w-full my-0  text-left flex flex-row justify-between items-center cursor-pointer outline-none shadow-none"
									),
								})}
							>
								<div
									className="flex items-center cursor-pointer"
									onFocus={onFocus}
								>
									<span className="text-sm">{`Jan ${selectedItem?.label} - Dec ${selectedItem?.label}`}</span>
									<span className="bp3-icon bp3-icon-caret-down text-sm text-gray-600  pl-5" />
								</div>
							</button>

							<div className="relative h-0">
								{isOpen ? (
									<ul
										className="absolute top-0 z-50 w-full p-0 m-0 overflow-y-auto bg-white border-t-0 border-gray-200 rounded-t-none shadow-lg ws-input max-h-64 ws-scroll rounded-b-md"
										{...getMenuProps({})}
									>
										{options
											.filter((option) =>
												noFilter
													? true
													: inputValue
													? option.label.includes(inputValue) ||
													  option.value.includes(inputValue)
													: true
											)
											.sort()
											.map((option, index) => {
												return (
													<li
														key={index}
														{...getItemProps({
															item: option,
															index,
														})}
													>
														<div
															className={classNames(
																"m-0 ws-input  border-transparent hover:bg-gray-200 flex flex-row justify-start items-center cursor-pointer w-full focus:bg-gray-300 hover:border-gray-400 rounded-none",
																{
																	"border-gray-700 bg-gray-300":
																		highlightedIndex === index,
																	"border-blue-700 bg-blue-200 border-2":
																		selectedItem?.value === option.value,
																}
															)}
														>
															{props.renderListItem ? (
																props.renderListItem(option)
															) : (
																<span className="">{option.label}&nbsp;</span>
															)}
														</div>
													</li>
												);
											})}
									</ul>
								) : null}
							</div>
						</div>
					);
				}}
			</Downshift>
			<div className="mt-[5px]">
				<InfiniteProgress
					className="ws-input__progress"
					isLoading={isLoading}
					isSmall={true}
					isSpacedOut={false}
				/>
			</div>
		</>
	);
}

export default YearPicker;
