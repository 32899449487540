import { createReducer } from "typesafe-actions";
import withJobs from "redux/_core/job/reducers";
import { IToolStore } from "./@types";
import toolActions, { TToolActions } from "./actions";
import {
	CONFIGS_PER_PAGE,
	CONFIG_TABS,
	CP_ACTIVITY_LOGS_PER_PAGE,
	PRINT_TABS,
	toolConstants,
} from "./constants";

const defaultState: IToolStore = {
	_jobs: {},
	selectedTenantOption: undefined,
	selectedVesselOption: undefined,
	approveCertificate: false,
	toolCPActivityLog: {
		configPrintsActivityLog: {
			ids: [],
			byIds: {},
		},
		_pagination: {
			currentPage: 1,
			itemsPerPage: CP_ACTIVITY_LOGS_PER_PAGE,
			totalItems: 0,
		},
	},
	toolCPActivityLogCard: {
		cardActivityLogList: [],
		_pagination: {
			currentPage: 1,
			itemsPerPage: 0,
			totalItems: 0,
		},
	},
	configAndPrint: {
		toolType: "config",
		searchInput: "",
		isConfirmBoxOpen: false,
		config: {
			activeTab: CONFIG_TABS.TAB_LOGS,
			selectedItems: [],
			configInfo: undefined,
			isCopyModalOpen: false,
			isUpdateModalOpen: false,
			isConfigAddModalOpen: false,
			isViewModalOpen: false,
			isConfigFormExpanded: false,
			onActionClickSelectedConfigId: "",
			errorMessage: "",
			//**** */ this is for copy modal dropdowns
			vessels: {
				byIds: {},
				ids: [],
			},
			selectedTenantOption: undefined,
			selectedVesselOptions: undefined,
			//**** */
			logs: {
				logsList: {
					byIds: {},
					ids: [],
				},
				_pagination: {
					currentPage: 1,
					itemsPerPage: CONFIGS_PER_PAGE,
					totalItems: 0,
				},
			},
			checklists: {
				checklistsList: {
					byIds: {},
					ids: [],
				},
				_pagination: {
					currentPage: 1,
					itemsPerPage: CONFIGS_PER_PAGE,
					totalItems: 0,
				},
			},
			permits: {
				permitsList: {
					byIds: {},
					ids: [],
				},
				_pagination: {
					currentPage: 1,
					itemsPerPage: CONFIGS_PER_PAGE,
					totalItems: 0,
				},
			},
		},
		print: {
			activeTab: PRINT_TABS.TAB_LOGS,
			selectedItems: [],
			isCopyModalOpen: false,
			isUpdateModalOpen: false,
			isPrintAddModalOpen: false,
			isViewModalOpen: false,
			isPrintFormExpanded: false,
			onActionClickSelectedPrintId: "",
			errorMessage: "",
			//**** */ this is for copy modal dropdowns
			vessels: {
				byIds: {},
				ids: [],
			},
			selectedTenantOption: undefined,
			selectedVesselOptions: undefined,
			//**** */
			logs: {
				logsList: {
					byIds: {},
					ids: [],
				},
				_pagination: {
					currentPage: 1,
					itemsPerPage: CONFIGS_PER_PAGE,
					totalItems: 0,
				},
			},
			checklists: {
				checklistsList: {
					byIds: {},
					ids: [],
				},
				_pagination: {
					currentPage: 1,
					itemsPerPage: CONFIGS_PER_PAGE,
					totalItems: 0,
				},
			},
			permits: {
				permitsList: {
					byIds: {},
					ids: [],
				},
				_pagination: {
					currentPage: 1,
					itemsPerPage: CONFIGS_PER_PAGE,
					totalItems: 0,
				},
			},
		},
	},
	tenants: {
		byIds: {},
		ids: [],
	},
	vessels: {
		byIds: {},
		ids: [],
	},
	vesselSpecificUsers: {
		byIds: {},
		ids: [],
	},
};

const toolReducer = createReducer<IToolStore, TToolActions>(defaultState)
	.handleAction(
		toolActions.document.toolTypeSet,
		function toolTypeSet(state, action) {
			const { toolType } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					toolType,
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigIdOnActionSelect,
		function toolConfigIdOnActionSelect(state, action) {
			const { configId } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						onActionClickSelectedConfigId: configId,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolTenantListSet,
		function toolTenantListSet(state, action) {
			const { tenants } = action.payload;
			return {
				...state,
				tenants,
			};
		}
	)
	.handleAction(
		toolActions.document.toolVesselSpecificUsersSet,
		function toolVesselSpecificUsersSet(state, action) {
			const { vesselSpecificUsers } = action.payload;
			return {
				...state,
				vesselSpecificUsers,
			};
		}
	)
	.handleAction(
		toolActions.document.toolTenantSpecificVesselsSet,
		function toolTenantSpecificVesselsSet(state, action) {
			const { vessels } = action.payload;
			return {
				...state,
				vessels,
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigCopyModalTenantSpecificVesselsSet,
		function toolConfigCopyModalTenantSpecificVesselsSet(state, action) {
			const { vessels } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						vessels,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolTrainingApproveCertificate,
		function toolTrainingApproveCertificate(state, action) {
			const { approveCertificate } = action.payload;
			return {
				...state,
				approveCertificate: approveCertificate,
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPrintTenandOptionSelect,
		function toolConfigPrintTenandOptionSelect(state, action) {
			const { tenantOption } = action.payload;
			return {
				...state,
				selectedTenantOption: tenantOption,
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPrintVesselOptionSelect,
		function toolConfigPrintVesselOptionSelect(state, action) {
			const { vesselOption } = action.payload;
			return {
				...state,
				selectedVesselOption: vesselOption,
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigCopyTenandOptionSelect,
		function toolConfigCopyTenandOptionSelect(state, action) {
			const { tenantOption } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						selectedTenantOption: tenantOption,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigCopyVesselOptionsSelect,
		function toolConfigCopyVesselOptionsSelect(state, action) {
			const { vesselOptions } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						selectedVesselOptions: vesselOptions,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigTabSet,
		function toolConfigTabSet(state, action) {
			const { configTab } = action.payload;
			return state.configAndPrint?.config
				? {
						...state,
						configAndPrint: {
							...state.configAndPrint,
							config: {
								...state.configAndPrint?.config,
								activeTab: configTab,
							},
						},
				  }
				: { ...state };
		}
	)
	.handleAction(
		toolActions.document.toolConfigLogsListSet,
		function toolConfigLogsListSet(state, action) {
			const { logs: logsList } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint?.config,
						logs: {
							...state.configAndPrint?.config?.logs,
							logsList,
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigChecklistsListSet,
		function toolConfigChecklistsListSet(state, action) {
			const { checklists: checklistsList } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint?.config,
						checklists: {
							...state.configAndPrint?.config?.checklists,
							checklistsList,
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPermitsListSet,
		function toolConfigPermitsListSet(state, action) {
			const { permits: permitsList } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint?.config,
						permits: {
							...state.configAndPrint?.config?.permits,
							permitsList,
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigItemsSelect,
		function toolConfigLogsSelect(state, action) {
			const { selectedItems } = action.payload;

			return state.configAndPrint?.config
				? {
						...state,
						configAndPrint: {
							...state.configAndPrint,
							config: {
								...state.configAndPrint?.config,
								selectedItems,
							},
						},
				  }
				: { ...state };
		}
	)
	.handleAction(
		toolActions.document.confirmBoxToggle,
		function confirmBoxToggle(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					isConfirmBoxOpen: !state.configAndPrint?.isConfirmBoxOpen,
				},
			};
		}
	)
	.handleAction(
		toolActions.document.copyModalToggle,
		function copyModalToggle(state, action) {
			return state.configAndPrint?.config
				? {
						...state,
						configAndPrint: {
							...state.configAndPrint,
							config: {
								...state.configAndPrint?.config,
								isCopyModalOpen: !state.configAndPrint?.config.isCopyModalOpen,
							},
						},
				  }
				: { ...state };
		}
	)
	.handleAction(
		toolActions.document.updateModalToggle,
		function updateModalToggle(state, action) {
			return state.configAndPrint?.config
				? {
						...state,
						configAndPrint: {
							...state.configAndPrint,
							config: {
								...state.configAndPrint?.config,
								isUpdateModalOpen:
									!state.configAndPrint?.config.isUpdateModalOpen,
							},
						},
				  }
				: { ...state };
		}
	)
	.handleAction(
		toolActions.document.configAddModalToggle,
		function configAddModalToggle(state, action) {
			return state.configAndPrint?.config
				? {
						...state,
						configAndPrint: {
							...state.configAndPrint,
							config: {
								...state.configAndPrint?.config,
								isConfigAddModalOpen:
									!state.configAndPrint?.config.isConfigAddModalOpen,
							},
						},
				  }
				: { ...state };
		}
	)
	.handleAction(
		toolActions.document.configViewModalToggle,
		function configViewModalToggle(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint?.config,
						isViewModalOpen: !state.configAndPrint?.config.isViewModalOpen,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.configFormExpanderToggle,
		function configFormExpanderToggle(state, action) {
			const { isExpanded } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint?.config,
						isConfigFormExpanded:
							isExpanded ?? !state.configAndPrint?.config.isConfigFormExpanded,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigLogPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						logs: {
							...state.configAndPrint.config.logs,
							_pagination: {
								...state?.configAndPrint?.config?.logs._pagination,
								totalItems: action.payload.totalItems,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigLogPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						logs: {
							...state.configAndPrint.config.logs,
							_pagination: {
								...state?.configAndPrint?.config?.logs._pagination,
								itemsPerPage: action.payload.itemsPerPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigLogPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						logs: {
							...state.configAndPrint.config.logs,
							_pagination: {
								...state?.configAndPrint?.config?.logs._pagination,
								currentPage: action.payload.currentPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigLogpaginationReset,
		function reset(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						logs: {
							...state.configAndPrint.config.logs,
							_pagination: {
								currentPage: 1,
								itemsPerPage: CONFIGS_PER_PAGE,
								totalItems: 0,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigChecklistPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						checklists: {
							...state.configAndPrint.config.checklists,
							_pagination: {
								...state?.configAndPrint?.config?.checklists._pagination,
								totalItems: action.payload.totalItems,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigChecklistPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						checklists: {
							...state.configAndPrint.config.checklists,
							_pagination: {
								...state?.configAndPrint?.config?.checklists._pagination,
								itemsPerPage: action.payload.itemsPerPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigChecklistPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						checklists: {
							...state.configAndPrint.config.checklists,
							_pagination: {
								...state?.configAndPrint?.config?.checklists._pagination,
								currentPage: action.payload.currentPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPermitPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						permits: {
							...state.configAndPrint.config.permits,
							_pagination: {
								...state?.configAndPrint?.config?.permits._pagination,
								totalItems: action.payload.totalItems,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPermitPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						permits: {
							...state.configAndPrint.config.permits,
							_pagination: {
								...state?.configAndPrint?.config?.permits._pagination,
								itemsPerPage: action.payload.itemsPerPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPermitPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						permits: {
							...state.configAndPrint.config.permits,
							_pagination: {
								...state?.configAndPrint?.config?.permits._pagination,
								currentPage: action.payload.currentPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigInfoSet,
		function toolConfigInfoSet(state, action) {
			const { configInfo } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						configInfo,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigErrorMessageSet,
		function toolConfigErrorMessageSet(state, action) {
			const { errorMessage } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					config: {
						...state.configAndPrint.config,
						errorMessage,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolConfigPrintSearchInputSet,
		function toolConfigPrintSearchInputSet(state, action) {
			const { searchInput } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					searchInput,
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintLogsListSet,
		function toolPrintLogsListSet(state, action) {
			const { logs: logsList } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						logs: {
							...state.configAndPrint?.print?.logs,
							logsList,
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintChecklistsListSet,
		function toolPrintChecklistsListSet(state, action) {
			const { checklists: checklistsList } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						checklists: {
							...state.configAndPrint?.print?.checklists,
							checklistsList,
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintPermitsListSet,
		function toolPrintPermitsListSet(state, action) {
			const { permits: permitsList } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						permits: {
							...state.configAndPrint?.print?.permits,
							permitsList,
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintTabSet,
		function toolPrintTabSet(state, action) {
			const { printTab } = action.payload;
			return state.configAndPrint?.config
				? {
						...state,
						configAndPrint: {
							...state.configAndPrint,
							print: {
								...state.configAndPrint?.print,
								activeTab: printTab,
							},
						},
				  }
				: { ...state };
		}
	)
	.handleAction(
		toolActions.document.toolPrintItemsSelect,
		function toolPrintLogsSelect(state, action) {
			const { selectedItems } = action.payload;

			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						selectedItems,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.printCopyModalToggle,
		function printCopyModalToggle(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						isCopyModalOpen: !state.configAndPrint?.print?.isCopyModalOpen,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.printUpdateModalToggle,
		function printUpdateModalToggle(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						isUpdateModalOpen: !state.configAndPrint?.print.isUpdateModalOpen,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.printAddModalToggle,
		function printAddModalToggle(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						isPrintAddModalOpen:
							!state.configAndPrint?.print.isPrintAddModalOpen,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.printViewModalToggle,
		function printViewModalToggle(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						isViewModalOpen: !state.configAndPrint?.print.isViewModalOpen,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.printFormExpanderToggle,
		function printFormExpanderToggle(state, action) {
			const { isExpanded } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint?.print,
						isPrintFormExpanded:
							isExpanded ?? !state.configAndPrint?.print.isPrintFormExpanded,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintLogPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						logs: {
							...state.configAndPrint.print.logs,
							_pagination: {
								...state?.configAndPrint?.print?.logs._pagination,
								totalItems: action.payload.totalItems,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintLogPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						logs: {
							...state.configAndPrint.print.logs,
							_pagination: {
								...state?.configAndPrint?.print?.logs._pagination,
								itemsPerPage: action.payload.itemsPerPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintLogPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						logs: {
							...state.configAndPrint.print.logs,
							_pagination: {
								...state?.configAndPrint?.print?.logs._pagination,
								currentPage: action.payload.currentPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintChecklistPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						checklists: {
							...state.configAndPrint.print.checklists,
							_pagination: {
								...state?.configAndPrint?.print?.checklists._pagination,
								totalItems: action.payload.totalItems,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintChecklistPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						checklists: {
							...state.configAndPrint.print.checklists,
							_pagination: {
								...state?.configAndPrint?.print?.checklists._pagination,
								itemsPerPage: action.payload.itemsPerPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintChecklistPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						checklists: {
							...state.configAndPrint.print.checklists,
							_pagination: {
								...state?.configAndPrint?.print?.checklists._pagination,
								currentPage: action.payload.currentPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintPermitPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						permits: {
							...state.configAndPrint.print.permits,
							_pagination: {
								...state?.configAndPrint?.print?.permits._pagination,
								totalItems: action.payload.totalItems,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintPermitPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						permits: {
							...state.configAndPrint.print.permits,
							_pagination: {
								...state?.configAndPrint?.print?.permits._pagination,
								itemsPerPage: action.payload.itemsPerPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintPermitPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						permits: {
							...state.configAndPrint.print.permits,
							_pagination: {
								...state?.configAndPrint?.print?.permits._pagination,
								currentPage: action.payload.currentPage,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintInfoSet,
		function toolPrintInfoSet(state, action) {
			const { printInfo } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						printInfo,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintErrorMessageSet,
		function toolPrintErrorMessageSet(state, action) {
			const { errorMessage } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						errorMessage,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintIdOnActionSelect,
		function toolPrintIdOnActionSelect(state, action) {
			const { printId } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						onActionClickSelectedPrintId: printId,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintCopyModalTenantSpecificVesselsSet,
		function toolPrintCopyModalTenantSpecificVesselsSet(state, action) {
			const { vessels } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						vessels,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintCopyTenandOptionSelect,
		function toolPrintCopyTenandOptionSelect(state, action) {
			const { tenantOption } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						selectedTenantOption: tenantOption,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolPrintCopyVesselOptionsSelect,
		function toolPrintCopyVesselOptionsSelect(state, action) {
			const { vesselOptions } = action.payload;
			return {
				...state,
				configAndPrint: {
					...state.configAndPrint,
					print: {
						...state.configAndPrint.print,
						selectedVesselOptions: vesselOptions,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolCPActivityLogListSet,
		function toolCPActivityLogListSet(state, action) {
			const { activityList } = action.payload;
			return {
				...state,
				toolCPActivityLog: {
					...state.toolCPActivityLog,
					configPrintsActivityLog: activityList,
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolCPCardActivityLogListSet,
		function toolCPCardActivityLogListSet(state, action) {
			const { activityList } = action.payload;
			return {
				...state,
				toolCPActivityLogCard: {
					...state.toolCPActivityLogCard,
					cardActivityLogList: [
						...state.toolCPActivityLogCard.cardActivityLogList,
						...activityList,
					],
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolCPActivityLogPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				toolCPActivityLog: {
					...state.toolCPActivityLog,
					_pagination: {
						...state?.toolCPActivityLog?._pagination,
						totalItems: action.payload.totalItems,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolCPActivityLogPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				toolCPActivityLog: {
					...state.toolCPActivityLog,
					_pagination: {
						...state?.toolCPActivityLog?._pagination,
						currentPage: action.payload.currentPage,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolCPCardActivityLogPaginationCurrentPageSet,
		function toolCPCardActivityLogPaginationCurrentPageSet(state, action) {
			return {
				...state,
				toolCPActivityLogCard: {
					...state.toolCPActivityLogCard,
					_pagination: {
						...state?.toolCPActivityLogCard?._pagination,
						currentPage: action.payload.currentPage,
					},
				},
			};
		}
	)
	.handleAction(
		toolActions.document.toolCPCardActivityLogListReset,
		function toolCPCardActivityLogListReset(state, action) {
			return {
				...state,
				toolCPActivityLogCard: {
					...state.toolCPActivityLogCard,
					cardActivityLogList: [],
				},
			};
		}
	);

export default withJobs<IToolStore>(
	toolConstants.FEATURE,
	defaultState,
	// @ts-ignore
	toolReducer
);
