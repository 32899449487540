import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import classNames from "classnames";
import feedbackSelectors from "redux/app/feedback/selectors";
import { ETicketTabType } from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";

type OwnProps = {};
type FeedbackTicketTabProps = PropsFromRedux & OwnProps;
function FeedbackTicketTab(props: FeedbackTicketTabProps) {
	const { ticketTabType, jobToGetStatsAndFeedback } = props;
	const isMyTicketType = ticketTabType === ETicketTabType.MY_TICKETS;

	return (
		<>
			<div className="w-64 mt-2">
				<div className="border-gray-200">
					<div className="flex flex-row justify-around py-1 my-2 rounded-md bg-lightGray">
						<button
							className={classNames(
								"transition-all duration-300 px-5 text-[12px]",
								{ "cursor-not-allowed": jobToGetStatsAndFeedback.isLoading },
								{
									"p-2 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
										isMyTicketType,
									"p-2 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
										!isMyTicketType,
								}
							)}
							disabled={jobToGetStatsAndFeedback.isLoading}
							onClick={() => {
								props.setTicketTabType(ETicketTabType.MY_TICKETS);
							}}
						>
							My Tickets
						</button>
						<button
							className={classNames(
								"transition-all duration-300 px-5 text-[12px]",
								{ "cursor-not-allowed": jobToGetStatsAndFeedback.isLoading },
								{
									"py-1.5 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
										!isMyTicketType,
									"py-1.5 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
										isMyTicketType,
								}
							)}
							disabled={jobToGetStatsAndFeedback.isLoading}
							onClick={() => {
								props.setTicketTabType(ETicketTabType.ALL_TICKETS);
							}}
						>
							All Tickets
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const ticketTabType =
		feedbackSelectors.getFeedbackTicketTabType(feedbackStore);
	const jobToGetStatsAndFeedback =
		feedbackSelectors.jobToLoadDashboardFeedbacksAndStats(feedbackStore);
	return {
		ticketTabType,
		jobToGetStatsAndFeedback,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setTicketTabType(ticketTabType: ETicketTabType) {
			dispatch(
				feedbackActions.document.feedbackTicketTabTypeSet(ticketTabType)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackTicketTab);
