import { IMARPOLStore } from "../marpol/@types";
import { IGarbageEditLog, IGarbageRecord } from "./@types";

const garbageRecordHelper = {
	getHistoryList(store: IMARPOLStore) {
		return store.docs.ids.map((id) => ({
			...(store.docs.byIds[id] as IGarbageRecord),
			editHistory: garbageRecordHelper.getGarbageRecordEditHistory(
				store.docs.byIds[id] as IGarbageRecord
			),
		}));
	},
	getGarbageRecordEditHistory(garbageRecord: IGarbageRecord) {
		const uniqueFields = Object.keys(garbageRecord.editLogs)
			.reduce<{ fieldName: string; fieldLabel: string }[]>(
				(result, editSessionNumber) => {
					const editedItems =
						garbageRecord.editLogs[parseInt(editSessionNumber, 10)];
					return [
						...result,
						...editedItems.ids.map((itemId) => {
							return {
								fieldName: editedItems.byIds[itemId].fieldName,
								fieldLabel: editedItems.byIds[itemId].fieldLabel,
							};
						}),
					];
				},
				[]
			)
			.filter(
				(item, i, ar) =>
					ar.findIndex(
						(indexItem) => indexItem.fieldName === item.fieldName
					) === i
			);

		const editHistory = uniqueFields.map((field) => {
			return {
				...field,
				edits: Object.keys(garbageRecord.editLogs).reduce<IGarbageEditLog[]>(
					(result, editSessionNumber) => {
						const editedItemsByIds =
							garbageRecord.editLogs[parseInt(editSessionNumber, 10)].byIds;
						if (editedItemsByIds[field.fieldName])
							return [...result, editedItemsByIds[field.fieldName]];
						return result;
					},
					[]
				),
			};
		});
		return editHistory;
	},
	getEditHistoryForGarbage(garbageRecord: IGarbageRecord) {
		const uniqueFields = Object.keys(garbageRecord.editLogs)
			.reduce<{ fieldName: string; fieldLabel: string }[]>(
				(result, editSessionNumber) => {
					const editedItems =
						garbageRecord.editLogs[parseInt(editSessionNumber, 10)];
					return [
						...result,
						...editedItems.ids.map((itemId) => {
							return {
								fieldName: editedItems.byIds[itemId].fieldName,
								fieldLabel: editedItems.byIds[itemId].fieldLabel,
							};
						}),
					];
				},
				[]
			)
			.filter(
				(item, i, ar) =>
					ar.findIndex(
						(indexItem) => indexItem.fieldName === item.fieldName
					) === i
			);

		const editHistory = uniqueFields.map((field) => {
			return {
				...field,
				edits: Object.keys(garbageRecord.editLogs).reduce<IGarbageEditLog[]>(
					(result, editSessionNumber) => {
						const editedItemsByIds =
							garbageRecord.editLogs[parseInt(editSessionNumber, 10)].byIds;
						if (editedItemsByIds[field.fieldName])
							return [...result, editedItemsByIds[field.fieldName]];
						return result;
					},
					[]
				),
			};
		});
		return editHistory;
	},
};

export default garbageRecordHelper;
