import { AxiosResponse } from "axios";
import {
	APIStatus,
	APIStatusForSuccess,
	IAPIErrorParams,
	IAPIRequestParams,
} from "./types";

function getDefaultMessageForResponseStatus(status: APIStatus) {
	switch (status) {
		case APIStatus["STATUS-100"]:
			return "Continue";
		case APIStatus["STATUS-101"]:
			return "Switching Protocols";
		case APIStatus["STATUS-102"]:
			return "Processing";
		case APIStatus["STATUS-200"]:
			return "OK";
		case APIStatus["STATUS-201"]:
			return "Created";
		case APIStatus["STATUS-202"]:
			return "Accepted";
		case APIStatus["STATUS-203"]:
			return "Non-authoritative Information";
		case APIStatus["STATUS-204"]:
			return "No Content";
		case APIStatus["STATUS-205"]:
			return "Reset Content";
		case APIStatus["STATUS-206"]:
			return "Partial Content";
		case APIStatus["STATUS-207"]:
			return "Multi-Status";
		case APIStatus["STATUS-208"]:
			return "Already Reported";
		case APIStatus["STATUS-226"]:
			return "IM Used";
		case APIStatus["STATUS-300"]:
			return "Multiple Choices";
		case APIStatus["STATUS-301"]:
			return "Moved Permanently";
		case APIStatus["STATUS-302"]:
			return "Found";
		case APIStatus["STATUS-303"]:
			return "See Other";
		case APIStatus["STATUS-304"]:
			return "Not Modified";
		case APIStatus["STATUS-305"]:
			return "Use Proxy";
		case APIStatus["STATUS-307"]:
			return "Temporary Redirect";
		case APIStatus["STATUS-308"]:
			return "Permanent Redirect";
		case APIStatus["STATUS-400"]:
			return "Bad Request";
		case APIStatus["STATUS-401"]:
			return "Unauthorized";
		case APIStatus["STATUS-402"]:
			return "Payment Required";
		case APIStatus["STATUS-403"]:
			return "Forbidden";
		case APIStatus["STATUS-404"]:
			return "Not Found";
		case APIStatus["STATUS-405"]:
			return "Method Not Allowed";
		case APIStatus["STATUS-406"]:
			return "Not Acceptable";
		case APIStatus["STATUS-407"]:
			return "Proxy Authentication Required";
		case APIStatus["STATUS-408"]:
			return "Request Timeout";
		case APIStatus["STATUS-409"]:
			return "Conflict";
		case APIStatus["STATUS-410"]:
			return "Gone";
		case APIStatus["STATUS-411"]:
			return "Length Required";
		case APIStatus["STATUS-412"]:
			return "Precondition Failed";
		case APIStatus["STATUS-413"]:
			return "Payload Too Large";
		case APIStatus["STATUS-414"]:
			return "Request-URI Too Long";
		case APIStatus["STATUS-415"]:
			return "Unsupported Media Type";
		case APIStatus["STATUS-416"]:
			return "Requested Range Not Satisfiable";
		case APIStatus["STATUS-417"]:
			return "Expectation Failed";
		case APIStatus["STATUS-418"]:
			return "I'm a teapot";
		case APIStatus["STATUS-421"]:
			return "Misdirected Request";
		case APIStatus["STATUS-422"]:
			return "Unprocessable Entity";
		case APIStatus["STATUS-423"]:
			return "Locked";
		case APIStatus["STATUS-424"]:
			return "Failed Dependency";
		case APIStatus["STATUS-426"]:
			return "Upgrade Required";
		case APIStatus["STATUS-428"]:
			return "Precondition Required";
		case APIStatus["STATUS-429"]:
			return "Too Many Requests";
		case APIStatus["STATUS-431"]:
			return "Request Header Fields Too Large";
		case APIStatus["STATUS-444"]:
			return "Connection Closed Without Response";
		case APIStatus["STATUS-451"]:
			return "Unavailable For Legal Reasons";
		case APIStatus["STATUS-499"]:
			return "Client Closed Request";
		case APIStatus["STATUS-500"]:
			return "Internal Server Error";
		case APIStatus["STATUS-501"]:
			return "Not Implemented";
		case APIStatus["STATUS-502"]:
			return "Bad Gateway";
		case APIStatus["STATUS-503"]:
			return "Service Unavailable";
		case APIStatus["STATUS-504"]:
			return "Gateway Timeout";
		case APIStatus["STATUS-505"]:
			return "HTTP Version Not Supported";
		case APIStatus["STATUS-506"]:
			return "Variant Also Negotiates";
		case APIStatus["STATUS-507"]:
			return "Insufficient Storage";
		case APIStatus["STATUS-508"]:
			return "Loop Detected";
		case APIStatus["STATUS-510"]:
			return "Not Extended";
		case APIStatus["STATUS-511"]:
			return "Network Authentication Required";
		case APIStatus["STATUS-599"]:
			return "Network Connect Timeout Error";
		case APIStatus["NO-RESPONSE"]:
			return "Server not responding";
		case APIStatus["APPLICATION-ERROR"]:
			return "Application level error";
	}
}

function getResponseStatusMessageForError(
	error: IAPIErrorParams,
	listOfStatusMessages: Partial<
		{ [key in keyof typeof APIStatus]: string }
	> = {}
) {
	if (error.response) {
		const message =
			listOfStatusMessages[APIStatus[error.response.status] as APIStatus];
		// Request made and server responded
		if (!message)
			return getDefaultMessageForResponseStatus(error.response.status);
		return listOfStatusMessages[APIStatus[error.response.status] as APIStatus];
	} else if (error.request) {
		// The request was made but no response was received
		return listOfStatusMessages[APIStatus["NO-RESPONSE"]]
			? listOfStatusMessages[APIStatus["NO-RESPONSE"]]
			: getDefaultMessageForResponseStatus(APIStatus["NO-RESPONSE"]);
	} else {
		return listOfStatusMessages[APIStatus["APPLICATION-ERROR"]]
			? listOfStatusMessages[APIStatus["APPLICATION-ERROR"]]
			: getDefaultMessageForResponseStatus(APIStatus["APPLICATION-ERROR"]);
	}
	return "";
}

function getResponseStatusMessageForSuccess(
	response: AxiosResponse<any>,
	listOfStatusMessages: Partial<
		{ [key in keyof typeof APIStatusForSuccess]: string }
	>
) {
	if (!listOfStatusMessages[response.status])
		return getDefaultMessageForResponseStatus(response.status);
	return listOfStatusMessages[response.status];
}
export default {
	getResponseStatusMessageForError,
	getResponseStatusMessageForSuccess,
};
