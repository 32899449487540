import React from "react";
type SVGNotesProps = { className?: string };
const SVGNotes: React.FC<SVGNotesProps> = (props) => (
	<svg
		width="15"
		height="19"
		viewBox="0 0 15 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.16699 2.37504C1.16699 2.16508 1.2504 1.96371 1.39887 1.81525C1.54733 1.66678 1.7487 1.58337 1.95866 1.58337H9.87533L13.8337 5.54171V16.625C13.8337 16.835 13.7503 17.0364 13.6018 17.1848C13.4533 17.3333 13.252 17.4167 13.042 17.4167H1.95866C1.7487 17.4167 1.54733 17.3333 1.39887 17.1848C1.2504 17.0364 1.16699 16.835 1.16699 16.625V2.37504Z"
			stroke="#718096"
			strokeLinejoin="round"
		/>
		<path
			d="M4.33398 7.91675H10.6673M4.33398 11.0834H10.6673"
			stroke="#718096"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGNotes;
