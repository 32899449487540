import React from "react";
import ResetRequest from "../../../components/auth/reset-request/ResetRequest";
type ScreenAuthResetRequestType = React.PropsWithChildren<{}>;
const ScreenAuthResetRequest: React.FC<ScreenAuthResetRequestType> = (
	props: ScreenAuthResetRequestType
) => {
	return <ResetRequest />;
};

export default ScreenAuthResetRequest;
