import { IJobNotification } from "../job/types";
import actionTypes from "./action-types";
import { INotification__Action_set } from "./types";
const { NOTIFICATION_SET } = actionTypes;
const notificationActions = {
	set(
		JOB_NAME: string,
		notification: IJobNotification
	): INotification__Action_set {
		return {
			type: NOTIFICATION_SET,
			payload: {
				JOB_NAME,
				notification,
			},
		};
	},
};
export default notificationActions;
