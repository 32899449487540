import classNames from "classnames";
import moment from "moment";
import React from "react";
import {
	EBallastOperationCodes,
	TBallastRecord,
} from "../../../../../redux/app/ballast/@types";
import { TBallast_Ballast } from "../../../../../redux/app/ballast/groups/ballast";
import { TBallast_Deballast } from "../../../../../redux/app/ballast/groups/deballast";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { commonUtils } from "redux/_common";
import ballastUtils from "redux/app/ballast/utils";
import BallastTableRows from "../BallastTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type DeballastOperationPreviewProps = OwnProps;
function DeballastOperationPreview(props: DeballastOperationPreviewProps) {
	const record = props.ballastRecord as TBallast_Deballast;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const { placeOfBallast, operationCode } =
		props.ballastRecord as TBallast_Deballast;

	function renderMain() {
		return (
			<BallastTableRows
				record={record}
				tableRows={[
					[operationDate, "3.4.1", renderItem3P4P1()],
					[null, "3.4.2", renderItem3P4P2()],
					[null, "3.4.3", renderItem3P4P3()],
					[null, "3.4.4", renderItem3P4P4()],
					[null, "3.4.5", renderItem3P4P5()],
					[operationDate, "3.3.1", renderItem3P3P1()],
					[null, "3.3.2", renderItem3P3P2()],
					[null, "3.3.3", renderItem3P3P3()],
					[null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem3P4P1() {
		if (
			operationCode !==
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place !== "AT_RECEPTION_FACILITY"
		)
			return null;
		return (
			<h4 className="font-medium uppercase">
				{moment(placeOfBallast.startDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}{" "}
				{placeOfBallast.startTime}, {placeOfBallast.originOfBallast}
			</h4>
		);
	}

	function renderItem3P4P2() {
		if (
			operationCode !==
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place !== "AT_RECEPTION_FACILITY"
		)
			return null;
		return (
			<h4 className="font-medium uppercase">
				{moment(record.tanks[0].startDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}{" "}
				{record.tanks[0].startTime}, {placeOfBallast.receptionFacilityName}
			</h4>
		);
	}

	function renderItem3P4P3() {
		if (
			operationCode !==
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place !== "AT_RECEPTION_FACILITY"
		)
			return null;
		return (
			<h4 className="font-medium uppercase">
				{placeOfBallast.receptionFacilityName}
			</h4>
		);
	}

	function renderItem3P4P4() {
		if (
			operationCode !==
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place !== "AT_RECEPTION_FACILITY"
		)
			return null;
		return (
			<h4 className="font-medium uppercase">
				{record.totalOperatedWaterVolume} M³
			</h4>
		);
	}

	function renderItem3P4P5() {
		if (
			operationCode !==
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place !== "AT_RECEPTION_FACILITY"
		)
			return null;
		return <h4 className="font-medium uppercase">NO</h4>;
	}

	function renderItem3P3P1() {
		if (
			operationCode ===
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place === "AT_RECEPTION_FACILITY"
		)
			return null;
		return (
			<h4 className="font-medium uppercase">
				{moment(record.tanks[0].startDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}{" "}
				{record.tanks[0].startTime} to{" "}
				{moment(
					record.tanks[record.tanks.length - 1].endDate,
					"YYYY-MM-DD"
				).format("DD-MMM-YYYY")}{" "}
				{record.tanks[record.tanks.length - 1].endTime},{" "}
				{placeOfBallast.place === "IN_PORT" ? (
					<>{placeOfBallast.portName}</>
				) : null}
				{placeOfBallast.place === "AT_SEA" ? (
					<>
						({placeOfBallast.startPosition.latitude},{" "}
						{placeOfBallast.startPosition.longitude}, to{" "}
						{placeOfBallast.endPosition.latitude},{" "}
						{placeOfBallast.endPosition.longitude})
					</>
				) : null}
			</h4>
		);
	}

	function renderItem3P3P2() {
		if (
			operationCode ===
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place === "AT_RECEPTION_FACILITY"
		)
			return null;
		return (
			<h4 className="font-medium uppercase">
				{operationCode === EBallastOperationCodes.DEBALLAST_IN_PORT ? (
					<>
						Discharged: {record.totalOperatedWaterVolume} M³,
						{record.ballastROB
							? `Total Ballast Retained Quantity on Board: ${record.ballastROB}M³`
							: `:Total Quantity Retained ${totalQuantityRetained(record)} M³`}
					</>
				) : (
					<>
						Discharged: {record.totalOperatedWaterVolume} M³,
						{/* Quantity retained in{' '} */}
						{/* {getParticipantTankNames(record)} :{' '} */}
						{record.ballastROB
							? `Total Ballast Retained Quantity on Board: ${record.ballastROB}M³`
							: `Total Quantity Retained: ${totalQuantityRetained(record)} M³`}
					</>
				)}
			</h4>
		);
	}

	function renderItem3P3P3() {
		if (
			operationCode ===
				EBallastOperationCodes.DEBALLAST_TO_RECEPTION_FACILITY ||
			placeOfBallast.place === "AT_RECEPTION_FACILITY"
		)
			return null;
		if (!record.isBallastWaterTreatmentPlanUsed) return null;
		return <h4 className="font-medium uppercase">YES</h4>;
	}

	const getParticipantTankNames = (ballastRecord: TBallast_Deballast) => {
		let tankNames = "";
		record.tanks.forEach((tank, index) => {
			tankNames += tank.selectedTank.tankCode;
			if (index !== record.tanks.length - 1) {
				tankNames += ",";
			}
		});
		return tankNames;
	};

	const totalQuantityRetained = (ballastRecord: TBallast_Deballast) => {
		let accumulatedValue = 0;
		if (record.ballastROB) {
			accumulatedValue = record.tanks.reduce(
				(acc, curr) => acc + curr.newRetainedQuantity,
				0
			);
		} else {
			accumulatedValue = record.tanks.reduce(
				(acc, curr) =>
					commonUtils.addUp(
						commonUtils.substract(
							curr.selectedTank.expectedQuantity,
							curr.transferQuantity
						),
						acc
					),
				0
			);
		}
		return accumulatedValue;
	};
	// const totalQuantityRetained = record.tanks.reduce(
	//     (acc, curr) =>
	//         commonUtils.addUp(
	//             commonUtils.substract(
	//                 curr.selectedTank.expectedQuantity,
	//                 curr.transferQuantity
	//             ),
	//             acc
	//         ),
	//     0
	// );

	return renderMain();
}

export default DeballastOperationPreview;
