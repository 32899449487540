import { IListObjectStore } from "../../_common/type";
import { TMARPOLProcessedTank } from "../marpol/@types";
import { TORB1GroupA } from "./groups/group-a";
import { TORB1GroupB } from "./groups/group-b";
import { TORB1GroupC } from "./groups/group-c";
import { TORB1GroupD as TORB1GroupDOld } from "./groups/group-d";
import { TORB1GroupD } from "./groups/group-d-new";
import { TORB1GroupE } from "./groups/group-e";
import { TORB1GroupF } from "./groups/group-f";
import { TORB1GroupG } from "./groups/group-g";
import { TORB1GroupH } from "./groups/group-h";
import { TORB1GroupI } from "./groups/group-i";
import { TORB1GroupZ } from "./groups/group-z";

export enum EORB1RecordState {
	APPROVED = "APPROVED",
	STRIKED_OFF = "STRIKED-OFF",
	SUBMITTED = "SUBMITTED",
	IDLE = "IDLE",
	EDIT = "EDIT",
	VERIFIED = "VERIFIED",
}

export type TORB1RecordUser = {
	name: string;
	designation: string;
	staffId: string;
	date: string;
};

export type TORB1CommonFields = {
	selectedDate: string;
	remarks: string;
	tanksThatCollectedSludge: IListObjectStore<{
		/**
		 * Tank ID
		 */
		id: string;
		tankName: string;
		sludgeCollected: number;
		oldQuantity: number;
		newQuantity: number;
	}>;
};

export type TORB1CommonData = {
	id: string;
	/**
	 * @description It's an ID for latest update for a document with given id.
	 */
	_rev: string;
	marpolType: "ORB1";
	state: EORB1RecordState;
	submittedBy?: TORB1RecordUser;
	approvedBy?: TORB1RecordUser;
	verifiedBy?: TORB1RecordUser;
	strikedBy?: TORB1RecordUser;
	strikeOffReason?: string;
	editVersions?: TORB1BackendRecord[];
	dateOfApprovedRecordEdit?: string;
};

export type TORB1ObservedQuantity = {
	preOperation: number;
	postOperation: number;
	isHavingAutomaticCollection?: boolean;
};

export enum EORB1RecordCode {
	ORB1A1 = "ORB1-A1",
	ORB1A2 = "ORB1-A2",
	ORB1A3 = "ORB1-A3",

	ORB1B1 = "ORB1-B1",
	ORB1B2 = "ORB1-B2",

	ORB1C1 = "ORB1-C1",
	ORB1C2 = "ORB1-C2",
	ORB1C3 = "ORB1-C3",
	ORB1C4 = "ORB1-C4",
	ORB1C5 = "ORB1-C5",
	ORB1C6 = "ORB1-C6",

	ORB1D1 = "ORB1-D1",
	ORB1D2 = "ORB1-D2",
	ORB1D3 = "ORB1-D3",
	ORB1D4 = "ORB1-D4",
	ORB1D5 = "ORB1-D5",
	ORB1D6 = "ORB1-D6",
	ORB1D7 = "ORB1-D7",
	ORB1D8 = "ORB1-D8",
	ORB1D9 = "ORB1-D9",
	ORB1D10 = "ORB1-D10",
	ORB1D11 = "ORB1-D11",

	ORB1E1 = "ORB1-E1",
	ORB1E2 = "ORB1-E2",
	ORB1E3 = "ORB1-E3",

	ORB1F1 = "ORB1-F1",

	ORB1G1 = "ORB1-G1",

	ORB1H1 = "ORB1-H1",
	ORB1H2 = "ORB1-H2",
	ORB1H3 = "ORB1-H3",

	ORB1I1 = "ORB1-I1",
	ORB1I2 = "ORB1-I2",
	ORB1I3 = "ORB1-I3",
	ORB1I4 = "ORB1-I4",
	ORB1I5 = "ORB1-I5",
	ORB1I6 = "ORB1-I6",
	ORB1I7 = "ORB1-I7",

	ORB1Z = "ORB1-Z",
}

export type TORB1Record =
	| TORB1GroupA
	| TORB1GroupB
	| TORB1GroupC
	| TORB1GroupDOld
	| TORB1GroupD
	| TORB1GroupE
	| TORB1GroupF
	| TORB1GroupG
	| TORB1GroupH
	| TORB1GroupI
	| TORB1GroupZ;

export type TORB1BackendRecord = TORB1Record & {
	submittedBy: TORB1RecordUser;
	approvedBy?: TORB1RecordUser;
	verifiedBy?: TORB1RecordUser;
	strikedBy?: TORB1RecordUser;
	processedTanks: TORB1ProcessedTank[];
	nonParticipantTanks: { id: string; expectedQuantity: number }[];
	isAutoCreated?: boolean;
	isEditWarningVisible?: boolean;
};

export type TORB1ProcessedTank = TMARPOLProcessedTank;
