import moment from "moment";
import React from "react";
import { TORB1Record } from "redux/app/orb1/@types";
import ORB1TableRows from "../ORB1TableRows";
import { commonUtils } from "redux/_common";
import { marpolUtils } from "redux/app/marpol";
import { TORB1D11 } from "redux/app/orb1/groups/group-d-new";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1D11PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1D11Preview(props: ORB1D11PreviewProps) {
	const orb1Record = props.orb1Record as TORB1D11;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "D", "13", renderItem13()],
					[null, null, "14", renderItem14()],
					[null, null, "15.3", renderItem15P3()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem13(): React.ReactNode {
		return (
			<>
				{orb1Record.item13And14.quantityDischarged.toFixed(2)} M³ FROM{" "}
				{orb1Record.item13And14.bilgeSource.tankName} <br />
				TOTAL CAPACITY {orb1Record.item13And14.bilgeSource.totalCapacity} M³
			</>
		);
	}

	function renderItem14() {
		return (
			<h4 className="font-medium uppercase">
				STARTED {orb1Record.item13And14.startOfDischargeTime.time} / FINISHED{" "}
				{orb1Record.item13And14.endOfDischargeTime.time}
			</h4>
		);
	}

	function renderItem15P3() {
		const bilgeHoldingTank = orb1Record.item15P3.selectedTank;
		const retainedAtHoldingTank = commonUtils.addUp(
			marpolUtils.getQuantityForCalculation(bilgeHoldingTank),
			orb1Record.item13And14.quantityDischarged
		);
		return (
			<h4 className="font-medium uppercase">
				TRANSFER TO {bilgeHoldingTank.tankName}
				<br />
				TANK CAPACITY {bilgeHoldingTank.totalCapacity.toFixed(2)} M³, QUANTITY
				RETAINED {retainedAtHoldingTank.toFixed(2)} M³
			</h4>
		);
	}

	return renderMain();
}

export default ORB1D11Preview;
