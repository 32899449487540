import actionTypes from "./action-types";
import { apiActions, apiUtils } from "../../_core/api";
import { Middleware } from "redux";
import { IStore } from "../../store";
import vesselActions from "./actions";
import vesselAPIs from "./apis";
import {
	ICreateFleetResponse,
	IUserDetail,
	IVessel,
	IVessel_ListFilter__Action,
	IVessel_ListRemove__Action,
} from "./types";
import { commonUtils } from "../../_common";
import { multiActions } from "../../_core/multi-action";
import { TAB_IDS } from "./constants";
import vesselUtils from "./utils";
import dashboardSelectors from "../dashboard/selectors";
import dashboardActions from "../dashboard/actions";
import { authActions } from "../auth";

const { apiRequest } = apiActions;
const {
	VESSEL_LIST_REMOVE,
	VESSEL_LIST_LOAD,
	VESSEL_LIST_FILTER,
	VESSEL_LIST_LOAD_NEW,
	VESSEL_LIST_INFO_LOAD,
	VESSEL_INFO_LOAD,
	MY_VESSELS_LOAD,
	FAVORITE_A_VESSEL,
	FLEET_LIST_LOAD,
	FLEET_SELECTED_VESSEL_ASSIGN,
	FLEET_CREATE,
} = actionTypes;

const vesselMiddleware: Middleware<{}, IStore> =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case FAVORITE_A_VESSEL: {
				next(action);
				const vesselStore = getState().app.vesselStore;
				const currentSelectedTab = vesselStore.selectedTab;
				const operationsOnVesselCards =
					vesselStore.operationsPriorToDebounce;

				const job = vesselActions._jobs.favoriteAVessel;

				const { favoritedVesselIds } = action.payload;
				const updatedFavoritedVesselIds = favoritedVesselIds;

				const authStore = getState().app.auth;
				const token = authStore.authToken;
				// @ts-ignore
				const userId = authStore.user?.userId;

				const operationsSet = new Set(operationsOnVesselCards);
				const allOperations = Array.from(operationsSet);
				const operationType = vesselUtils.operationType(allOperations);

				dispatch(
					apiRequest<IUserDetail>({
						...vesselAPIs.favoriteAVessel(
							updatedFavoritedVesselIds,
							token ?? "",
							userId
						),
						preExecute: () => {
							dispatch(
								job.active({
									message: vesselUtils.activeMessage(
										operationType,
										operationsOnVesselCards.length > 1
									),
									notification: {
										timeout: 1000,
									},
								})
							);
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									if (
										currentSelectedTab === TAB_IDS.myVessels
									) {
										// dispatch(vesselActions.listMyVessels())
									} else {
										// dispatch(vesselActions.listLoadNew());
									}
								} else {
									dispatch(
										job.error({
											message:
												"Could not favorite/unfavorite",
										})
									);
									dispatch(
										vesselActions.favoritedVessels(
											favoritedVesselIds
										)
									);
								}
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
								dispatch(vesselActions.vesselOperationsReset());
							},
						},
					})
				);
				break;
			}
			case MY_VESSELS_LOAD: {
				next(action);
				// @ts-ignore
				const userId = getState().app.auth.user?.userId;
				const job = vesselActions._jobs.vesselMyListLoad;

				const authStore = getState().app.auth;
				const token = authStore.authToken;

				if (!token) {
					console.log("No token found");
					dispatch(authActions.logout());
					return;
				}

				dispatch(
					apiRequest<IUserDetail>({
						...vesselAPIs.getUserDetails(userId),
						preExecute: () =>
							dispatch(
								job.active({
									message: "Loading user details",
									notification: action?.payload?.notify
										? {}
										: undefined,
								})
							),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const data = response.data;
									dispatch(
										vesselActions.favoritedVessels(
											data?.favourite_vessels ?? []
										)
									);
									const favourite_vessels =
										data?.favourite_vessels;
									dispatch(
										vesselActions.listLoadNew(
											favourite_vessels
										)
									);
								} else
									dispatch(
										job.error({
											message: "No Vessels found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case VESSEL_LIST_INFO_LOAD: {
				next(action);
				// @ts-ignore
				const tenantInfo = getState().app.auth.tenantInfo;
				const job = vesselActions._jobs.vesselListInfoLoad;

				const authStore = getState().app.auth;
				const token = authStore.authToken;

				if (!token) {
					console.log("No token found");
					dispatch(authActions.logout());
					return;
				}

				if (!tenantInfo) {
					console.log("No tenant info found");
					// dispatch(authActions.logout());
					return;
				}

				dispatch(
					apiRequest<IVessel[]>({
						...vesselAPIs.listVesselsInfos(tenantInfo._id),
						preExecute: () =>
							dispatch(
								job.active({
									message: "Loading vessels info details",
									notification: action?.payload?.notify
										? {}
										: undefined,
								})
							),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const data = response.data;
									const normalizedVesselsInfo = commonUtils.arrayToObject(data);
									dispatch(
										vesselActions.listInfoSet(
											normalizedVesselsInfo as any
										)
									);
								} else
									dispatch(
										job.error({
											message: "No Vessels found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case VESSEL_INFO_LOAD: {
				const { vesselId } = action.payload;
				next(action);
				// @ts-ignore
				const tenantInfo = getState().app.auth.tenantInfo;
				const job = vesselActions._jobs.vesselInfoLoad;

				const authStore = getState().app.auth;
				const token = authStore.authToken;

				if (!token) {
					console.log("No token found");
					dispatch(authActions.logout());
					return;
				}

				if (!tenantInfo) {
					console.log("No tenant info found");
					// dispatch(authActions.logout());
					return;
				}

				dispatch(
					apiRequest<IVessel[]>({
						...vesselAPIs.vesselInfo(tenantInfo._id, vesselId),
						preExecute: () =>
							dispatch(
								job.active({
									message: "Loading vessel info details",
									notification: action?.payload?.notify
										? {}
										: undefined,
								})
							),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const data = response.data;
									dispatch(
										vesselActions.listInfoSet(
											{
												ids: [vesselId],
												byIds: {
													[vesselId]: {
														...(data?.[0] || {}),
													},
												},
											}
										)
									);
								} else
									dispatch(
										job.error({
											message: "No Vessel found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case VESSEL_LIST_LOAD_NEW: {
				next(action);

				const authStore = getState().app.auth;
				const token = authStore.authToken;

				if (!token) {
					console.log("No token found");
					dispatch(authActions.logout());
					return;
				}

				const vesselStore = getState().app.vesselStore;
				const dashboardStore = getState().app.dashboard;
				const isDashboardPage =
					dashboardSelectors.getIsDashboardPage(dashboardStore);
				const job = vesselActions._jobs.vesselListLoadNew;
				const currentSelectedTab = vesselStore.selectedTab;
				const selectedFleetId = vesselStore.selectedFleetId;
				const { favoritedVesselIds } = action.payload;
				let where: any = undefined;
				if (
					currentSelectedTab === TAB_IDS.myVessels &&
					Array.isArray(favoritedVesselIds)
				) {
					where = {
						id: {
							inq: [...favoritedVesselIds],
						},
					};
				}
				if (
					currentSelectedTab === TAB_IDS.all &&
					selectedFleetId !== "all"
				) {
					where = {
						fleetId: selectedFleetId,
					};
				}

				dispatch(
					apiRequest<{
						vessels: IVessel[];
						count?: number;
					}>({
						...vesselAPIs.listVesselsNew(where),
						preExecute: () => {
							dispatch(
								job.active({
									message: "Loading vessels",
									notification: {
										hideAtState: "ACTIVE",
									},
								})
							);
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const { vessels, count } = response.data;
									const normalizedVessels =
										commonUtils.arrayToObject(vessels);

									if (
										isDashboardPage &&
										typeof count === "number"
									) {
										if (
											currentSelectedTab === TAB_IDS.all
										) {
											dispatch(
												dashboardActions.document.paginationAllVesselsTotalItemsSet(
													count
												)
											);
										} else if (
											currentSelectedTab ===
											TAB_IDS.myVessels
										) {
											dispatch(
												dashboardActions.document.paginationTotalItemsSet(
													count
												)
											);
										}
									}
									dispatch(
										job.success({
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
									if (
										currentSelectedTab === TAB_IDS.myVessels
									) {
										dispatch(
											vesselActions.myVesselsListSet(
												normalizedVessels
											)
										);
									} else {
										dispatch(
											vesselActions.listSet(
												normalizedVessels
											)
										);
									}
								} else
									dispatch(
										job.error({
											message: "No Vessels found!!",
										})
									);
							},
							onError: (error) => {
								job.error({
									message:
										apiUtils.getResponseStatusMessageForError(
											error
										),
									notification: {},
								});
								// logout if error status code is 401
								if (error?.response?.status === 401) {
									dispatch(authActions.logout());
								}
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case VESSEL_LIST_LOAD: {
				next(action);
				const job = vesselActions._jobs.vesselListLoad;
				dispatch(
					apiRequest<{
						vessels: IVessel[];
						count: number;
					}>({
						...vesselAPIs.listAPI(),
						preExecute: () =>
							dispatch(
								job.active({
									message: "Loading Vessels",
									notification: action.payload.notify
										? {}
										: undefined,
								})
							),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const { vessels } = response.data;
									const normalizedVessels =
										commonUtils.arrayToObject(vessels);
									dispatch(
										job.success({
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
									dispatch(
										vesselActions.listSet(normalizedVessels)
									);
								} else
									dispatch(
										job.error({
											message: "No Vessels found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case VESSEL_LIST_FILTER: {
				next(action);
				const job = vesselActions._jobs.vesselListFilter;
				const filters = (action as IVessel_ListFilter__Action).payload;
				dispatch(
					apiRequest<{
						vessels: IVessel[];
					}>({
						...vesselAPIs.filterAPI(filters),
						preExecute: () => {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const { vessels } = response.data;
									dispatch(
										multiActions.batchActions([
											job.success({}),
											vesselActions.listSet(
												commonUtils.arrayToObject(
													vessels
												)
											),
										])
									);
								} else
									dispatch(
										job.error({
											message: "No Vessels found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case VESSEL_LIST_REMOVE: {
				next(action);
				const job = vesselActions._jobs.vesselListRemove;
				const { vesselId } = (action as IVessel_ListRemove__Action)
					.payload;
				dispatch(
					apiRequest<{}>({
						...vesselAPIs.removeAPI(vesselId),
						preExecute: () => {
							dispatch(job.idle({}));
						},
						postExecute: {
							onSuccess: () => {
								dispatch(
									multiActions.batchActions([job.success({})])
								);
							},
							onError: () => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								next(action); // FIXME: Check if this works or else you have to create separate action for removing vessel from reducers
								// TODO: Add actions in command,document,jobs, and form way so that you have vesselErase document action to fix above isue
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case FLEET_LIST_LOAD: {
				next(action);
				const job = vesselActions._jobs.fleetListLoad;
				const authStore = getState().app.auth;
				const token = authStore.authToken ?? "";
				// @ts-ignore
				const userId = authStore.user?.userId;
				const tenantId = authStore.user?.tenantId ?? "";

				if (!userId || !tenantId || !token) {
					console.log("required parameters are missing");
					return;
				}
				dispatch(
					apiRequest<any>({
						...vesselAPIs.listFleetsForTenant(
							token,
							userId,
							tenantId
						),
						preExecute: () => {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									dispatch(job.success({}));

									const { data } = response;

									if (data && data.docs) {
										dispatch(
											vesselActions.fleetListSet(
												data.docs
											)
										);
									}
								} else
									dispatch(
										job.error({
											message: "No fleets found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			case FLEET_SELECTED_VESSEL_ASSIGN: {
				next(action);
				const job = vesselActions._jobs.assignSelectedVesselsToFleet;
				const vesselStore = getState().app.vesselStore;
				const selectedVessels =
					vesselStore.selectedVesselsOnEnableSelection;
				const selectedFleetId =
					vesselStore.selectedFleetIdToWhichVesselsToBeAssigned;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore.authToken ?? "";

				dispatch(
					apiRequest<any>({
						...vesselAPIs.assignSelectedVesselsToFleet(
							selectedVessels,
							selectedFleetId,
							userId,
							token
						),
						preExecute: () => {
							dispatch(
								job.active({
									message:
										"Assigning vessels to selected fleet",
									notification: {
										hideAtState: "SUCCESS",
										timeout: 100,
									},
								})
							);
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									dispatch(
										job.success({
											message:
												"Vessels Assigned to selected fleet",
											notification: {
												hideAtState: "IDLE",
												timeout: 200,
											},
										})
									);

									dispatch(
										vesselActions.selectedVesselsReset()
									);
									dispatch(
										vesselActions.enableVesselSelection(
											false
										)
									);
									dispatch(vesselActions.listLoadNew());
									dispatch(
										vesselActions.assignToFleetDropdownIdSet(
											""
										)
									);
								}
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			case FLEET_CREATE: {
				next(action);
				const job = vesselActions._jobs.createFleet;
				const vesselStore = getState().app.vesselStore;
				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId;
				const token = authStore.authToken ?? "";
				const tenantId = authStore.user?.tenantId ?? "";
				const fleetName = vesselStore.fleetName;

				dispatch(
					apiRequest<ICreateFleetResponse>({
						...vesselAPIs.createFleet(
							token,
							userId,
							tenantId,
							fleetName
						),
						preExecute: () => {
							dispatch(
								job.active({
									message: "Creating a fleet",
									notification: {
										hideAtState: "SUCCESS",
									},
								})
							);
						},
						postExecute: {
							onSuccess: (response) => {
								if (response?.data && response?.data?.ok) {
									dispatch(
										job.success({
											message: "Fleet created",
											notification: {
												hideAtState: "IDLE",
											},
										})
									);
									dispatch(vesselActions.fleetNameSet(""));
									dispatch(
										vesselActions.createFleetModalToggle()
									);
									dispatch(vesselActions.fleetListLoad());
								}
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			default: {
				next(action);
			}
		}
	};
export default vesselMiddleware;