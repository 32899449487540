import classNames from 'classnames';
import { ISidebarObj } from 'components/app/log/types';
import moment from 'moment';
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ITabChecklist } from 'redux/app/checklist/types';
import { vesselSelectors } from 'redux/app/vessel';
import { IStore } from 'redux/store';

interface IChecklistRowProps {
	checklistId: string;
	checklist: ITabChecklist;
	onClick(checklist: ITabChecklist): void;
	indexNumber: number;
	openSidebarModal: (data: ISidebarObj) => void;
};

type ChecklistRowProps = PropsFromRedux & IChecklistRowProps;
function ChecklistRow(props: ChecklistRowProps) {
	const { checklist, isVesselLoading } = props;

	// const discrepanciesCount = checklist?.discrepancies;
	const discrepanciesCount = 1;

	return (
		<tr
			key={checklist._id}
		>
			<td className="text-gray-400">{props.indexNumber}</td>
			<td className="py-1 font-medium text-gray-800">{checklist.title}</td>
			{/* <ReactJson src={{vesselId : log.vesselId, logId: log._id, logVessel: props.logVessel}} collapsed={true} /> */}
			{/* <td>
				<div>
					{isVesselLoading
						? "Loading vessels"
						: log?.vessel?.vesselName
						? log.vessel.vesselName
						: "Not Found"}
				</div>
			</td> */}
			<td className="font-mono text-center text-gray-700">
				{moment(checklist.createdAt).format("DD-MM-YYYY HH:MM")}
			</td>
			<td className="font-mono text-center">
				{
					checklist?.selectedDate?
					`${moment(checklist.selectedDate).format("DD-MM-YYYY")} ${checklist?.data?.selectedTime? checklist?.data?.selectedTime: ''}`
					: null
				}
			</td>
			<td className="text-center">
				<button
					className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700"
					onClick={function onClick() {
						props.onClick(checklist);
					}}
				>
					View
				</button>
			</td>
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: IChecklistRowProps) {

	return {
		isVesselLoading: vesselSelectors.jobToLoadVessels(store.app.vesselStore)
			.isLoading,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ChecklistRow);