import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4D1 } from "../../../../../redux/app/orb4/groups/orb4-d";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4D1PreviewProps = OwnProps;
function ORB4D1Preview(props: ORB4D1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4D1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "D", 9, renderItem9()],
					[null, null, 10, renderItem10()],
					[null, null, 11, renderItem11()],
					[null, null, 12, renderItem12()],
					[null, null, 13, renderItem13()],
					[null, null, 14, renderItem14()],
					[null, null, 15, renderItem15()],
					[null, null, 16, renderItem16()],
					[null, null, 17, renderItem17()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem9() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item9.placeOfWashing === "PORT" ? (
					<>{orb4Record.item9.portOfWashing}</>
				) : (
					<>
						<h4>At Sea</h4>
						Latitude: {orb4Record.item9.latitude} &nbsp; Longitude:
						{orb4Record.item9.longitude}
					</>
				)}
			</h4>
		);
	}
	function renderItem10() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item10.washedTank.tankName}{" "}
				{orb4Record.item10.sectionName === "-"
					? null
					: orb4Record.item10.sectionName}
			</h4>
		);
	}
	function renderItem11() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item11.numberOfMachinesInUse}
			</h4>
		);
	}
	function renderItem12() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item12.startOfWashingLT} LT (
				{orb4Record.item12.startOfWashingUTC} UTC)
			</h4>
		);
	}
	function renderItem13() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item13.stage} {orb4Record.item13.wash}{" "}
				{orb4Record.item13.nozzleAngle}
			</h4>
		);
	}
	function renderItem14() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item14.washingLinePressure} {orb4Record.item14.unit}
			</h4>
		);
	}
	function renderItem15() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item15.stopOfWashingLT} LT (
				{orb4Record.item15.stopOfWashingUTC} UTC)
			</h4>
		);
	}
	function renderItem16() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item16.methodOfEstablishing}
			</h4>
		);
	}
	function renderItem17() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item17.remarks === "" ? "N/A" : orb4Record.item17.remarks}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4D1Preview;
