import React, { FC } from 'react';
import { getEmptyValueLabelObj, IData } from '.';
import { findSelectedObj } from './Category';
import WayshipDropdownDeck from './WayshipDropdownDeck';

interface ITabProps {
    data: IData;
    tabbedSections: Record<string,string[]>;
    tabOptions: Array<{label:string,value:string}>;
    setData: React.Dispatch<React.SetStateAction<IData>>;
}

// INFO: this tab refers to the tabs(Aux Details, Machine in operation) in the Signal-report. This can be extended to other reports in future
const Tab: FC<ITabProps> = ({ data, tabOptions, setData,tabbedSections }) => {
    const range = tabOptions
    return (
        <div>
            <WayshipDropdownDeck
                isSubmit={data.category.value === '' || range[0].value === '' || !tabbedSections[data.category.value] }
                title="Select Tab"
                dataKey=""
                value={data.tab?.value}
                range={range}
                onChangeHandler={(selectedItem: any, dataKey: any) => {
                    const obj = findSelectedObj(range, selectedItem);
                    setData((prev) => ({
                        ...prev,
                        tab: {
                            label: obj?.label,
                            value: selectedItem
                        },
                        section: getEmptyValueLabelObj(),
                        modules: getEmptyValueLabelObj(),
                        subModules: getEmptyValueLabelObj(),
                        metric:  getEmptyValueLabelObj(),
                        range: [undefined, undefined],
                        key: '',
                        remarks: '',
                    }));
                }}
            />
        </div>
    );
};

export default Tab;