import { IListObjectStore } from "redux/_common/type";
import { ITenant, TConfigType, TPrintType } from "./@types";
import { IVessel } from "../feedback/@types";
import { CONFIG_TABS, PRINT_TABS } from "./constants";
import toolActions from "./actions";

export const getTenantsOptionList = (tenants: IListObjectStore<ITenant>) => {
	return tenants.ids.map((tenantId) => {
		const tenantInfo = tenants.byIds[tenantId];
		return {
			label: tenantInfo?.name ?? "",
			value: tenantId,
		};
	});
};

export const getVesselsOptionList = (vessels: IListObjectStore<IVessel>) => {
	return vessels.ids.map((vesselId) => {
		const vesselInfo = vessels.byIds[vesselId];
		return {
			label: vesselInfo?.vesselName ?? "",
			value: vesselId,
		};
	});
};

export const setCurrentDropdown = (
	rowId: string,
	value: boolean,
	setRowDropdown: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
) => {
	setRowDropdown((state) => {
		const stateWithOtherRowIdsSetToFalse = Object.keys(state).reduce(
			(acc, rowId) => {
				acc[rowId] = false;
				return acc;
			},
			{} as typeof state
		);
		return {
			...stateWithOtherRowIdsSetToFalse,
			[rowId]: value,
		};
	});
};

export const isJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const getModelName = (category: TConfigType = "logs") => {
	let modelName = "";
	if (category === "logs") {
		modelName = "logConfig";
	} else if (category === "checklists") {
		modelName = "permitConfig";
	} else if (category === "permits") {
		modelName = "permitConfig";
	}
	return modelName;
};

export const setConfigListBasedOnCategory = (
	category: CONFIG_TABS,
	configList: any,
	dispatch: any
) => {
	if (category === CONFIG_TABS.TAB_LOGS) {
		dispatch(toolActions.document.toolConfigLogsListSet(configList));
	} else if (category === CONFIG_TABS.TAB_CHECKLISTS) {
		dispatch(toolActions.document.toolConfigChecklistsListSet(configList));
	} else {
		dispatch(toolActions.document.toolConfigPermitsListSet(configList));
	}
};

export const resetPaginationOfCurrentPage = (dispatch: any) => {
	dispatch(toolActions.document.toolConfigLogPaginationCurrentPageSet(1));
	dispatch(toolActions.document.toolConfigChecklistPaginationCurrentPageSet(1));
	dispatch(toolActions.document.toolConfigPermitPaginationCurrentPageSet(1));
};

export const resetPrintPaginationOfCurrentPage = (dispatch: any) => {
	dispatch(toolActions.document.toolPrintLogPaginationCurrentPageSet(1));
	dispatch(toolActions.document.toolPrintChecklistPaginationCurrentPageSet(1));
	dispatch(toolActions.document.toolPrintPermitPaginationCurrentPageSet(1));
};

export const printListTitleExtractor = (code: string, typeOfLogs: string) => {
	let title = "";
	if (code && typeOfLogs) title = code + " | " + typeOfLogs;
	else if (!code && typeOfLogs) title = typeOfLogs;
	else if (code && !typeOfLogs) title = code;
	else title = "NA";
	return title;
};

export const setPrintListBasedOnCategory = (
	category: PRINT_TABS,
	configList: any,
	dispatch: any
) => {
	if (category === PRINT_TABS.TAB_LOGS) {
		dispatch(toolActions.document.toolPrintLogsListSet(configList));
	} else if (category === PRINT_TABS.TAB_CHECKLISTS) {
		dispatch(toolActions.document.toolPrintChecklistsListSet(configList));
	} else {
		dispatch(toolActions.document.toolPrintPermitsListSet(configList));
	}
};
