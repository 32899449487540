const feedbackConstants = {
	FEATURE: "@feedbacks",
	wayshipTeam: "WSTeam",
	MARPOL: "MARPOL",
	OTHERS: "OTHERS",
	CHECKLIST: "CHECKLIST",
	PERMIT: "PERMIT",
};

export enum FILTER_SECTIONS {
	SECTION = "Section",
	ASSIGNED_TO = "Assigned To",
	STATUS = "Status",
	PRIORITY = "Priority",
	UNREAD = "Unread",
}

export const initialStats = {
	Open: 0,
	Closed: 0,
	InProgress: 0,
	Reopened: 0,
	Total: 0,
};

// TODO: Need to add pagination
export const FEEDBACKS_PER_PAGE = 10;

export default feedbackConstants;

export const DASHBOARD_FEEDBACKS_PER_PAGE = 3;
