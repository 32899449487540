import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupD3 } from "../../../../../redux/app/orb1/groups/group-d";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { commonUtils } from "redux/_common";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupD3PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupD3Preview(props: ORB1GroupD3PreviewProps) {
	// const { isPrimaryBilgeTankPresent } = props;
	const orb1Record = props.orb1Record as TORB1GroupD3;

	function renderItem15P3HoldingOrOtherTanks(): React.ReactNode {
		const destinationTankItem = orb1Record.item15P3.toHoldingOrOtherTanks[0];
		if (!destinationTankItem) return null;
		const { selectedTank: destinationTank, transferQuantity } =
			destinationTankItem;
		const retainedAtDestinationTank = commonUtils.addUp(
			marpolUtils.getQuantityForCalculation(destinationTank),
			transferQuantity
		);
		const retainedAtSource = commonUtils.substract(
			marpolUtils.getQuantityForCalculation(
				orb1Record.item14P3.bilgeSource.selectedTank
			),
			orb1Record.item13.quantityDischarged
		);
		return (
			<>
				TRANSFER TO {destinationTank.tankName}
				<br />
				TANK CAPACITY {destinationTank.totalCapacity.toFixed(2)} M³, QUANTITY
				RETAINED {retainedAtDestinationTank.toFixed(2)} M³ <br />
				TOTAL QUANTITY IN TANK {retainedAtSource.toFixed(2)} M³
			</>
		);
	}

	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-no-wrap">
								{moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						D
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">13</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							{orb1Record.item13.quantityDischarged.toFixed(2)} M³ FROM{" "}
							{orb1Record.item14P3.bilgeSource.selectedTank.tankName}{" "}
						</h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-no-wrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">14</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">
							STARTED {orb1Record.item14P3.timeOfDischarge.start.time} /
							FINISHED {orb1Record.item14P3.timeOfDischarge.end.time}
						</h4>
					</div>
				</td>
			</tr>
			{}
			{orb1Record.item15P3.toHoldingOrOtherTanks.map(
				(holdingTankInfo, index) => {
					return (
						<tr
							key={holdingTankInfo.selectedTank.id}
							className={classNames("uppercase", {
								"line-through": props.isStrikedOff,
							})}
						>
							<td className="relative">
								<div
									className={classNames(
										{ "line-through": props.isStrikedOff },
										"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
									)}
								>
									<div className="h-full">
										<span className="block font-medium whitespace-no-wrap">
											{}
										</span>
									</div>
								</div>
							</td>
							<td className="relative">
								<div
									className={classNames(
										{ "line-through": props.isStrikedOff },
										"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
									)}
								>
									{}
								</div>
							</td>
							<td className="relative">
								<div
									className={classNames(
										{ "line-through": props.isStrikedOff },
										"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
									)}
								>
									<div className="h-full font-medium">15.3</div>
								</div>
							</td>
							<td className="relative">
								<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
									<h4 className="font-medium uppercase">
										{renderItem15P3HoldingOrOtherTanks()}
									</h4>
								</div>
							</td>
						</tr>
					);
				}
			)}
			{orb1Record.remarks !== "" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix "
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix "
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{orb1Record.remarks !== "" ? orb1Record.remarks : null}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default ORB1GroupD3Preview;
