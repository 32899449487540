import React, { useEffect, useState } from "react";
import moment from "moment";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch } from "redux";
import feedbackSelectors from "redux/app/feedback/selectors";
import { IStore } from "redux/store";
import MultimediaPreviewer from "../MultimediaPreviewer";
import classNames from "classnames";
import { contentTypeCategorizerLookup } from "../../../utils";
import PriorityStatusTable from "./_elements/PriorityStatusTable";
import userActions from "redux/app/user/actions";
import {
	IFeedbackUpdateType,
	IIndividualFeedbackWithChat,
} from "redux/app/feedback/@types";
import SVGFrom from "components/_common/icons/feedback/SVGFrom";
import SVGVessel from "components/_common/icons/feedback/SVGVessel";
import SVGVersion from "components/_common/icons/feedback/SVGVersion";
import SVGSection from "components/_common/icons/feedback/SVGSection";
import SVGCalendar from "components/_common/icons/feedback/SVGCalendar";
import AssignedToTable from "./_elements/AssignedToTable";
import Activity from "./_elements/Activity";
import Notes from "./_elements/Notes";
import ModalPortal from "components/app/_common/ModalPortal";

type OwnProps = {};
type FeedbackFormProps = PropsFromRedux & OwnProps;

const buildQuery = (
	individualFeedback: IIndividualFeedbackWithChat | undefined
) => {
	let query;
	query = encodeURI(
		`How to solve this issue in Wayship from section - ${individualFeedback?.section} of subsection - ${individualFeedback?.subsection}. Issue - ${individualFeedback?.title}  ${individualFeedback?.feedback}`
	);
	return query;
};

const FeedbackForm = (props: FeedbackFormProps) => {
	const { individualFeedback } = props;
	const currentSelectedFeedback = individualFeedback;

	// Info: could have been done with one boolean instead of two
	const [feedbackAPIUpdateType, setFeedbackAPIUpdateType] =
		useState<IFeedbackUpdateType>({
			isTable1Update: false,
			isTable2Update: false,
			notesUpdate: false,
		});

	const [isMultimediaModalOpen, setIsMultimediaModal] = useState(false);
	const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
	const [modalMediaObj, setModalMediaObj] = useState<
		| {
				name: string;
				type: string;
		  }
		| undefined
	>(undefined);

	const toggleMultimediaModal = () => {
		setIsMultimediaModal((open) => !open);
	};

	useEffect(() => {
		if (individualFeedback?.attachments?.length) {
			const media = individualFeedback?.attachments[currentMediaIndex];
			setModalMediaObj(media);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [individualFeedback?.attachments, currentMediaIndex]);

	useEffect(() => {
		props.loadUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const totalMultimediaCount = individualFeedback?.attachments?.length ?? 0;
	const max = totalMultimediaCount;
	const min = 0;

	const goToNextMultimediaAttachment = (
		currentIndex = currentMediaIndex,
		max = totalMultimediaCount
	) => {
		if (currentIndex < max - 1) {
			setCurrentMediaIndex(currentIndex + 1);
		}
	};

	const goToPrevMultiMediaAttachment = (currentIndex = currentMediaIndex) => {
		if (currentIndex > 0) {
			setCurrentMediaIndex(currentIndex - 1);
		}
	};

	// Handle keyboard events
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			const key = e.key;
			if (key === "ArrowRight") goToNextMultimediaAttachment();
			else if (key === "ArrowLeft") goToPrevMultiMediaAttachment();
			else if (key === "Escape")
				isMultimediaModalOpen && toggleMultimediaModal();
		};
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	let activitiesToRender = [...(individualFeedback?.activity ?? [])];
	activitiesToRender.reverse();

	return (
		<>
			<h3 className=" flex items-center px-5 py-3 border-b border-[#6F696966] font-medium">
				<span
					className={classNames(
						"bp3-icon bp3-icon-arrow-left text-xl font-normal cursor-pointer"
					)}
					onClick={() => props.goBack()}
				/>
				<span className="ml-2 text-xl">
					Ticket : {currentSelectedFeedback?.ticketNumber}
				</span>
			</h3>
			<div className="md:h-[calc(100%-55px)] overflow-y-auto">
				<div className="flex flex-col justify-between p-4 pb-0">
					{/* <h2 className="mb-4 text-base font-medium">
						{individualFeedback?.title ?? "NA"}
					</h2> */}
					<PriorityStatusTable
						priority={currentSelectedFeedback?.priority}
						status={currentSelectedFeedback?.status}
						dueDate={currentSelectedFeedback?.dueDate}
						assignedTo={currentSelectedFeedback?.assignedTo}
						feedbackAPIUpdateType={feedbackAPIUpdateType}
						setFeedbackAPIUpdateType={setFeedbackAPIUpdateType}
					/>

					<AssignedToTable
						feedbackAPIUpdateType={feedbackAPIUpdateType}
						setFeedbackAPIUpdateType={setFeedbackAPIUpdateType}
					/>

					<div className="flex flex-col pb-2 space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:py-4">
						<div className="basis-1/3 border border-[#DEDEDE] p-2  rounded">
							<div className="flex items-center mb-1">
								<SVGFrom />
								<h4 className="text-[#718096] text-sm font-medium ml-2">
									From
								</h4>
							</div>
							<p className="m-0 text-sm">
								{currentSelectedFeedback?.author}
							</p>
							{currentSelectedFeedback?.authorDesignation && (
								<p className="text-[12px] font-normal">
									<span className="font-light text-[#000000B2]">
										{
											currentSelectedFeedback?.authorDesignation
										}
									</span>
								</p>
							)}
						</div>
						<div className="basis-1/3 border border-[#DEDEDE] p-2  rounded">
							<div className="flex items-center mb-1">
								<SVGVessel />
								<h4 className="text-[#718096] text-sm font-medium ml-2">
									Vessel
								</h4>
							</div>
							<p className="m-0 text-sm">
								{currentSelectedFeedback?.vesselName}
							</p>
							{currentSelectedFeedback?.vesselImo && (
								<p className="text-[12px] font-normal">
									<span className="font-light text-[#000000B2]">
										IMO {currentSelectedFeedback?.vesselImo}
									</span>
								</p>
							)}
						</div>
						<div className="basis-1/3 border border-[#DEDEDE] p-2  rounded">
							<div className="flex items-center mb-1">
								<SVGVersion />
								<h4 className="text-[#718096] text-sm font-medium ml-2">
									Version
								</h4>
							</div>
							<p className="text-sm font-normal">
								{currentSelectedFeedback?.versionInfo?.FE ||
								currentSelectedFeedback?.versionInfo?.BE ? (
									<span>
										FE:{" "}
										{currentSelectedFeedback?.versionInfo
											?.FE ?? "NA"}{" "}
										| BE:{" "}
										{currentSelectedFeedback?.versionInfo
											?.BE ?? "NA"}
									</span>
								) : (
									"NA"
								)}
							</p>
						</div>
					</div>
					<div className="flex flex-col pb-2 space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:pb-4">
						{
							<>
								<div className="basis-2/3 border border-[#DEDEDE] p-2  rounded">
									<div className="flex items-center mb-1">
										<SVGSection />
										<h4 className="text-[#718096] text-sm font-medium ml-2">
											Section & Subsection
										</h4>
									</div>
									<p className="m-0 text-sm">
										{individualFeedback?.section ?? "NA"} |{" "}
										{individualFeedback?.subsection ?? "NA"}
									</p>
								</div>
								{/* {individualFeedback?.section !== feedbackConstants.OTHERS && (
								<div className="basis-1/3 border border-[#DEDEDE] p-2  rounded">
									<h4 className="text-[#718096] text-sm font-medium">
										Sub Section
									</h4>
									<p className="m-0 text-sm">{individualFeedback?.subsection}</p>
								</div>
							)} */}
							</>
						}
						<div className="basis-1/3 border border-[#DEDEDE] p-2  rounded">
							<div className="flex items-center mb-1">
								<SVGCalendar />
								<h4 className="text-[#718096] text-sm font-medium ml-2">
									Feedback date
								</h4>
							</div>
							<div className="font-normal">
								{currentSelectedFeedback && (
									<div className="text-sm">
										{moment(
											currentSelectedFeedback.createdAt
										).format("DD-MM-YYYY")}
									</div>
								)}
								{currentSelectedFeedback?.createdAt && (
									<div className="text-[12px]">
										{moment(
											currentSelectedFeedback.createdAt
										).format("hh:mm a")}
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="p-2 mb-4 border border-[#DEDEDE] rounded">
						<div className="flex justify-between">
							<h4 className="text-[#718096] text-sm font-medium">
								Short description of issue
							</h4>
							<a
								href={`https://wayship-gpt.vercel.app/?q=${buildQuery(
									individualFeedback
								)}`}
								target="_blank"
								rel="noopener noreferrer"
								className="text-[#3182CE] text-sm font-medium underline"
							>
								Wayship AI
							</a>
						</div>

						<p className="py-2 m-0 text-base leading-relaxed tracking-wide text">
							{individualFeedback?.title ?? "NA"}
						</p>
					</div>
					<div className="p-2 mb-4 border border-[#DEDEDE] rounded">
						<h4 className="text-[#718096] text-sm font-medium">
							Long description of issue{" "}
						</h4>
						<p className="py-2 m-0 text-base leading-relaxed tracking-wide text">
							{individualFeedback?.feedback ?? "NA"}
						</p>
					</div>
				</div>
				<div className="flex flex-col p-4 pt-0">
					{individualFeedback?.attachments &&
					individualFeedback.attachments.length > 0 ? (
						<div className="mb-1">
							<label className="text-[#718096] text-sm">
								Attachments
							</label>
							<div className="flex flex-wrap mt-2 space-x-2 gap-y-4">
								{individualFeedback.attachments.map(
									(attachment, index) => {
										// primitives are pdf and images
										const isNotPrimitiveContentType =
											attachment.type in
											contentTypeCategorizerLookup;
										return (
											<div
												key={attachment.name + index}
												className="relative cursor-pointer ws-input"
												onClick={() => {
													if (
														isNotPrimitiveContentType
													) {
														window.open(
															`https://console.wayship.io/api/attachments?docId=${individualFeedback?._id}&attachmentName=${modalMediaObj?.name}&type=${modalMediaObj?.type}`
														);
													} else {
														toggleMultimediaModal();
														setCurrentMediaIndex(
															index
														);
													}
												}}
											>
												{/* INFO: object is not registering click event in case of pdf, below div is workaround */}
												<div className="absolute w-full h-full bg-transparent" />
												<MultimediaPreviewer
													title=""
													key={attachment.name}
													url={`https://console.wayship.io/api/attachments?docId=${individualFeedback._id}&attachmentName=${attachment.name}&type=${attachment.type}`}
													content_type={
														attachment.type
													}
												/>
											</div>
										);
									}
								)}
							</div>
						</div>
					) : null}

					{isMultimediaModalOpen && (
						<ModalPortal
							isActive={isMultimediaModalOpen}
							onClose={() => {
								toggleMultimediaModal();
							}}
						>
							<div className="w-full h-full my-12 overflow-y-auto bg-white rounded-lg font-redhat-text">
								<div className="h-[calc(100vh-120px)] w-full">
									<div className="relative h-[calc(100%-86px)] w-full">
										{modalMediaObj?.name && (
											<div className="my-2 text-center">
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={`https://console.wayship.io/api/attachments?docId=${individualFeedback?._id}&attachmentName=${modalMediaObj?.name}&type=${modalMediaObj?.type}`}
													className="flex items-center justify-center py-2 text-lg text-center no-underline"
												>
													<span className="underline">
														{modalMediaObj.name}
													</span>{" "}
													<span className="ml-2 bp3-icon bp3-icon-share" />
												</a>
											</div>
										)}
										<span
											className="absolute top-0 right-0 bp3-icon bp3-icon-cross text-3xl text-gray-600 m-1.5 cursor-pointer"
											onClick={() => {
												toggleMultimediaModal();
											}}
										/>
										<div
											className="absolute left-0 transform -translate-y-1/2 cursor-pointer top-1/2"
											onClick={() => {
												goToPrevMultiMediaAttachment();
											}}
										>
											<span
												className={classNames(
													"bp3-icon bp3-icon-chevron-left text-4xl font-normal",
													{
														"text-gray-500 cursor-not-allowed":
															currentMediaIndex ===
															min,
													}
												)}
											/>
										</div>
										<div className="w-[calc(100%-72px)] flex justify-center items-center  m-auto p-2 h-full overflow-y-auto border border-gray-400 rounded-lg">
											{modalMediaObj?.name &&
												modalMediaObj?.type && (
													<MultimediaPreviewer
														title={
															modalMediaObj?.name ??
															""
														}
														url={`https://console.wayship.io/api/attachments?docId=${individualFeedback?._id}&attachmentName=${modalMediaObj?.name}&type=${modalMediaObj?.type}`}
														content_type={
															modalMediaObj?.type
														}
														width={"100%"}
														height={"100%"}
													/>
												)}
										</div>
										<div
											className="absolute right-0 transform -translate-y-1/2 cursor-pointer top-1/2"
											onClick={() => {
												goToNextMultimediaAttachment();
											}}
										>
											<span
												className={classNames(
													"bp3-icon bp3-icon-chevron-right text-4xl font-normal",
													{
														"text-gray-500 cursor-not-allowed":
															currentMediaIndex ===
															max - 1,
													}
												)}
											/>
										</div>
									</div>
								</div>
							</div>
						</ModalPortal>
					)}
					{/* <div className="mb-1">
					<label
						htmlFor="add-attachment-id"
						className="ws-input__label"
					>
						Add attachment
					</label>
					<div className="flex w-full bg-grey-lighter">
						<label className="flex flex-col items-center px-6 py-3 mt-2 tracking-wide uppercase bg-white border rounded-lg shadow cursor-pointer text-blue border-blue hover:bg-blue hover:text-white">
							<svg
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13 8H8V13C8 13.2652 7.89464 13.5196 7.70711 13.7071C7.51957 13.8946 7.26522 14 7 14C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V8H1C0.734784 8 0.48043 7.89464 0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7C0 6.73478 0.105357 6.48043 0.292893 6.29289C0.48043 6.10536 0.734784 6 1 6H6V1C6 0.734784 6.10536 0.480429 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.480429 8 0.734784 8 1V6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7C14 7.26522 13.8946 7.51957 13.7071 7.70711C13.5196 7.89464 13.2652 8 13 8Z"
									fill="black"
								/>
							</svg>
							<input type="file" className="hidden" />
						</label>
					</div>
				</div> */}
				</div>
				<div
					className={classNames(
						"flex flex-col lg:flex-row border border-[#DEDEDE] mx-4 mb-4 rounded-md",
						{
							"-mt-4": !individualFeedback?.attachments?.length,
						}
					)}
				>
					<Activity activities={activitiesToRender} />
					<Notes
						feedbackAPIUpdateType={feedbackAPIUpdateType}
						setFeedbackAPIUpdateType={setFeedbackAPIUpdateType}
					/>
				</div>
			</div>
		</>
	);
};

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);

	const individualFeedback =
		feedbackSelectors.getIndividualFeedback(feedbackStore);

	return {
		individualFeedback,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadUsers() {
			// Info: value 1 has no significance, we have set isPaginated to false
			dispatch(userActions.commands.userListLoad(1, false));
		},
		goBack() {
			window.history.back();
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackForm);
