import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupH1 } from "../../../../../redux/app/annex6/groups/group-h";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupH1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupH1Preview(props: Annex6GroupH1PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupH1;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'H', 17, renderItem17()],
                    [null, null, 18, renderItem18()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem17() {
        return (
            <h4 className="font-medium uppercase">
                System failure occurred on {record.item17.systemFailure.date},
                {record.item17.systemFailure.time} at{' '}
                {record.item17.systemFailurePosition.latitude},
                {record.item17.systemFailurePosition.longitude},{' '}
                {record.item17.systemIdentificationAndFuelConsumersAffected}
            </h4>
        );
    }

    function renderItem18() {
        return (
            <h4 className="font-medium uppercase">
                System operation restored on {record.item18.systemRestored.date}
                ,{record.item18.systemRestored.time} at{' '}
                {record.item18.systemRestoredPosition.latitude},
                {record.item18.systemRestoredPosition.longitude},{' '}
                {record.item18.systemIdentificationAndFuelConsumersAffected}
            </h4>
        );
    }

    return renderMain();
}

export default Annex6GroupH1Preview;
