import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Select from "react-select";
import clsx from "clsx";
import {
	IAssignedUser,
	IFeedbackUpdatePayload,
	IFeedbackUpdateType,
	IOption,
} from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";
import {
	clearIndicatorStyles,
	dropdownIndicatorStyles,
	menuStyles,
	optionStyles,
	placeholderStyles,
	priorityOptions,
	statusOptions,
} from "redux/app/feedback/utils";
import InfiniteProgress from "components/_common/InfiniteProgress";
import feedbackSelectors from "redux/app/feedback/selectors";
import moment from "moment";

export const headers = [
	{
		title: "Priority",
		key: "priority",
		width: 317,
	},
	{
		title: "Status",
		key: "status",
		width: 317,
	},
	{
		title: "Due date",
		key: "dueDate",
		width: 318,
	},
];

const validStatus = ["Open", "In Progress", "Closed", "Reopened"];
const validPriorities = ["High", "Medium", "Low"];

type OwnProps = {
	status?: string;
	priority?: string;
	assignedTo?: IAssignedUser[];
	dueDate?: string;
	feedbackAPIUpdateType: IFeedbackUpdateType;
	setFeedbackAPIUpdateType: React.Dispatch<
		React.SetStateAction<IFeedbackUpdateType>
	>;
};
type PriorityStatusTableProps = PropsFromRedux & OwnProps;
function PriorityStatusTable(props: PriorityStatusTableProps) {
	const {
		status,
		priority,
		dueDate,
		feedbackAPIUpdateType,
		setFeedbackAPIUpdateType,
		jobToUpdateFeedback,
	} = props;
	const [priorityVal, setPriorityVal] = useState<undefined | IOption>();
	const [dueDateVal, setDueDateVal] = useState<undefined | string>(undefined);

	const [statusVal, setStatusVal] = useState<undefined | IOption>();

	useEffect(() => {
		if (priority)
			setPriorityVal(priorityOptions.find((pr) => pr.value === priority));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [priority]);

	useEffect(() => {
		if (status) setStatusVal(statusOptions.find((st) => st.value === status));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	useEffect(() => {
		if (dueDate) {
			setDueDateVal(moment(dueDate).format("YYYY-MM-DD").toString());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dueDate]);

	const setToTable1UpdateType = () => {
		setFeedbackAPIUpdateType({
			isTable1Update: true,
			isTable2Update: false,
			notesUpdate: false,
		});
	};

	return (
		<>
			<InfiniteProgress
				isLoading={
					feedbackAPIUpdateType.isTable1Update && jobToUpdateFeedback.isLoading
				}
				isSpacedOut={false}
			/>
			<table
				className={classNames(
					"w-full   font-redhat-text border-separate border-spacing-0  relative mb-4"
				)}
			>
				<thead>
					<tr>
						{headers.map((header, index) => {
							return (
								<th
									key={header.key}
									style={{ width: header.width }}
									className={classNames(
										"px-3 py-3 text-[12px] md:text-sm border-[0.8px] font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 "
									)}
								>
									{header.title}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					<tr className="">
						<td
							className={classNames(
								"px-3 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D] hover:bg-gray-200  min-w-[98px] lg:min-w-min",
								{
									"cursor-pointer": status !== "Closed",
									"cursor-not-allowed bg-gray-200": status === "Closed",
								}
							)}
						>
							<div className="flex justify-between">
								<Select
									isSearchable
									placeholder="Select priority"
									isDisabled={status === "Closed"}
									unstyled
									value={priorityVal}
									options={priorityOptions}
									onChange={(newValue) => {
										if (newValue?.label && newValue?.value) {
											setPriorityVal(newValue);
											setToTable1UpdateType();
											props.updateFeedback({ priority: newValue.value });
										}
									}}
									className={classNames("w-full", {
										"cursor-not-allowed": status === "Closed",
									})}
									classNames={{
										option: ({ isFocused, isSelected }) =>
											clsx(
												isFocused && optionStyles.focus,
												isSelected && optionStyles.selected,
												optionStyles.base
											),
										dropdownIndicator: () => dropdownIndicatorStyles,
										menu: () => menuStyles,
										clearIndicator: () => clearIndicatorStyles,
										placeholder: () => placeholderStyles,
										singleValue: (props) => {
											const { value } = props.data;
											return classNames("font-medium", {
												"text-[#FF2B2B]": value === "High",
												"text-[#20B938]": value === "Medium",
												"text-[#84BA12]": value === "Low",
											});
										},
									}}
								/>
							</div>
						</td>

						<td className="px-3 py-0 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D] hover:bg-gray-200 cursor-pointer min-w-28 lg:min-w-min">
							<div className="flex justify-between">
								<Select
									isSearchable
									placeholder="Select status"
									unstyled
									options={statusOptions}
									value={statusVal}
									className="w-full"
									classNames={{
										option: ({ isFocused, isSelected }) =>
											clsx(
												isFocused && optionStyles.focus,
												isSelected && optionStyles.selected,
												optionStyles.base
											),
										dropdownIndicator: () => dropdownIndicatorStyles,
										menu: () => menuStyles,
										clearIndicator: () => clearIndicatorStyles,
										placeholder: () => placeholderStyles,
									}}
									onChange={(newValue) => {
										if (newValue?.label && newValue?.value) {
											setStatusVal(newValue);
											setToTable1UpdateType();
											props.updateFeedback({ status: newValue.value });
										}
									}}
								/>
							</div>
						</td>
						<td
							className={classNames(
								"px-3  text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-min lg:min-w-min",
								{
									"cursor-pointer": status !== "Closed",
									"cursor-not-allowed bg-gray-200": status === "Closed",
								}
							)}
						>
							<div className="flex items-center">
								<input
									type="date"
									name="text-input-date"
									className={classNames(" w-full text-xs md:text-sm")}
									style={{ margin: 0 }}
									placeholder={"Due date"}
									value={dueDateVal}
									disabled={status === "Closed"}
									onChange={(e) => {
										const date = e.target.value;
										setDueDateVal(date);
										setToTable1UpdateType();
										props.updateFeedback({ dueDate: date });
									}}
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const jobToUpdateFeedback =
		feedbackSelectors.jobToUpdateSelectedFeedback(feedbackStore);
	return { jobToUpdateFeedback };
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		updateFeedback(payload: IFeedbackUpdatePayload) {
			dispatch(feedbackActions.commands.feedbackUpdate(payload));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PriorityStatusTable);
