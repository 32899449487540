import React from "react";
type OwnProps = {
	items: string[];
	customSeperator?: any;
	lastItemSeperator?: any;
};
type CommaSeparatorProps = OwnProps;
function CommaSeparator(props: CommaSeparatorProps) {
	const numberOfItems = props.items.length;
	const isEmpty = numberOfItems === 0;
	if (isEmpty) return null;
	if (!props.customSeperator)
		return (
			<>
				{props.items.map((item, index) => {
					if (index === numberOfItems - 1)
						return <React.Fragment key={index}>{item}.</React.Fragment>;
					if (
						index === numberOfItems - 2 &&
						props.items.length > 1 &&
						props.lastItemSeperator
					)
						return (
							<React.Fragment key={index}>
								{item}
								{props.lastItemSeperator}
							</React.Fragment>
						);
					return <React.Fragment key={index}>{item}, </React.Fragment>;
				})}
			</>
		);
	return (
		<>
			{props.items.map((item, index) => {
				if (index === numberOfItems - 1)
					return <React.Fragment key={index}>{item}.</React.Fragment>;
				if (
					index === numberOfItems - 2 &&
					props.items.length > 1 &&
					props.lastItemSeperator
				)
					return (
						<React.Fragment key={index}>
							{item}
							{props.lastItemSeperator}
						</React.Fragment>
					);
				return (
					<React.Fragment key={index}>
						{item}
						{props.customSeperator}
					</React.Fragment>
				);
			})}
		</>
	);
}

export default CommaSeparator;
