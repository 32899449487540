import classNames from "classnames";
import SVGActivity from "components/_common/icons/feedback/SVGActivity";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IFeedbackActivity } from "redux/app/feedback/@types";
import { IStore } from "redux/store";
import RenderActivity from "./RenderActivity";
import moment from "moment";
import userSelectors from "redux/app/user/selectors";

type OwnProps = {
	activities: IFeedbackActivity[] | undefined;
};
type ActivityProps = PropsFromRedux & OwnProps;
function Activity(props: ActivityProps) {
	const { activities, portalUsers } = props;

	const activitiesHeader = (
		<div className="flex items-center p-2 border-b border-[#DEDEDE] ">
			<SVGActivity />
			<h4 className="ml-2 text-sm">Activity</h4>
		</div>
	);

	if (activities?.length === 0) {
		return (
			<div className="w-full h-full lg:w-1/2 border-r-0 border-[#DEDEDE] lg:border-0 ">
				{activitiesHeader}
				<div className="flex justify-center items-center">
					<h4 className="text-sm font-extralight italic mt-20">
						No activities yet.
					</h4>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full lg:w-1/2 border-r-0 border-[#DEDEDE] lg:border-0  ">
			{activitiesHeader}

			<div className={classNames("px-4 -ml-4 h-[180px] overflow-y-auto")}>
				{activities?.length ? (
					<div className="mb-8">
						<div className=" overflow-y-auto relative p-4">
							<ol className="relative  border-l-[1.5px]  border-gray-400 ">
								{activities?.map((activity, index) => {
									const user = portalUsers?.byIds[activity?.changedBy];
									return (
										<li className="mb-4 ml-4">
											<div
												className={classNames(
													"absolute  bg-blue-500 rounded-full   mt-1.5 border border-white",
													{
														"w-3 h-3 -left-[6.5px]": index,
														"w-5 h-5 -left-[10.5px]": !index,
													}
												)}
											>
												<div className="relative flex justify-center items-center">
													<div
														className={classNames(
															"absolute  bg-blue-100 rounded-full ",
															{
																"w-1.5 h-1.5  mt-[10px]": index,
																"w-2.5 h-2.5 mt-[17px]": !index,
															}
														)}
													/>
												</div>
											</div>
											<div className="-mt-2">
												<RenderActivity activity={activity} />
											</div>
											<p className="m-0 text-[12px] text-gray-500">
												{moment(activity.changedAt).format("DD/MM/YYYY hh:mm a")}
											</p>
											<div className="-mt-1 text-[#00000099]">
												<span className="text-[12px] m-0 italic">
													by {user?.name ?? "You"}
												</span>
											</div>
										</li>

										// <RenderActivity key={activity.changedAt} activity={activity} />
									);
								})}
							</ol>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const usersStore = userSelectors._getStore(store);
	const users = userSelectors.getUsersList(usersStore);
	return {
		portalUsers: users,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Activity);
