import React from "react";
import DashboardView from "components/app/dashboard/view";

type ScreenDashboardViewProps = React.PropsWithChildren<{}>;

function ScreenDashboardView(props: ScreenDashboardViewProps) {
	return <DashboardView />;
}

export default ScreenDashboardView;
