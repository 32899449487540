import actionTypes from "../action-types";
import { apiActions } from "../../../../_core/api";
import { Middleware } from "redux";
import actions from "../actions";
import vesselOnboardAPIs from "../apis";
import { commonUtils } from "../../../../_common";
import {
	IVesselOnboard_deviceListLoad__Action,
	IVesselOnboard_deviceListImport__Action,
	IVesselOnboard_deviceLoad__Action,
	IVesselOnboard_deviceCreate__Action,
	IVesselOnboard_deviceImageUpload__Action,
	IVesselOnboard_deviceUpdate__Action,
	IVesselOnboard_deviceDelete__Action,
} from "../types";
import { IVesselDevice } from "../../types";
import { string, object, ValidationError } from "yup";
import { IStore } from "../../../../store";
import { formActionTypes } from "../../../../_core/form";
import {
	IForm_Submit__Action,
	IForm_FieldsSet__Action,
	IFormErrorFormat,
} from "../../../../_core/form/types";
import vesselOnboardConstants from "../constants";
import { promiseActions } from "../../../../_core/promise-action";
import { invokerAction } from "../../../../_common/actions";

const { apiRequest } = apiActions;

const {
	WIZARD_DEVICE_LIST_IMPORT,
	WIZARD_DEVICE_LIST_LOAD,
	WIZARD_DEVICE_LOAD,
	WIZARD_DEVICE_CREATE,
	WIZARD_DEVICE_UPDATE,
	WIZARD_DEVICE_DELETE,
} = actionTypes.command;

const { FORM_SUBMIT, FORM_FIELDS_SET } = formActionTypes;

const createValidationSchema = object().shape({
	deviceName: string().required("Device name is required"),
	deviceType: string().required("Device type is required"),
	// operatingSystem: string().required("Operating system is required"),
	// version: string().required("Version is required"),
	macAddress: string()
		.min(12, "Invalid MAC Address")
		.required("Mac address is required"),
	deviceId: string().required("Device id is required"),
	id: string().notRequired(),
});

const updateValidationSchema = object().shape({
	deviceName: string().required("Device name is required"),
	deviceType: string().required("Device type is required"),
	// operatingSystem: string().required("Operating system is required"),
	// version: string().required("Version is required"),
	macAddress: string().required("Mac address is required"),
	deviceId: string().required("Device id is required"),
	id: string().required("ID is required"),
});

const onboardDeviceMiddleware: Middleware<{}, IStore> = ({
	dispatch,
	getState,
}) => (next) => (
	action:
		| IVesselOnboard_deviceListLoad__Action
		| IVesselOnboard_deviceListImport__Action
		| IVesselOnboard_deviceLoad__Action
		| IVesselOnboard_deviceCreate__Action
		| IVesselOnboard_deviceImageUpload__Action
		| IVesselOnboard_deviceUpdate__Action
		| IVesselOnboard_deviceDelete__Action
		| IForm_Submit__Action
		| IForm_FieldsSet__Action<IVesselDevice>
) => {
	switch (action.type) {
		case WIZARD_DEVICE_LIST_LOAD: {
			next(action);
			const {
				vesselId,
			} = (action as IVesselOnboard_deviceListLoad__Action).payload;
			const job = actions._jobs.WIZARD_DEVICE_LIST_LOAD;
			dispatch(
				apiRequest<IVesselDevice[]>({
					...vesselOnboardAPIs.loadDevicesAPI(vesselId),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading devices",
								notification: {
									hideAtState: "SUCCESS",
									timeout: 100,
								},
							})
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(job.success({}));
							dispatch(
								actions.document.deviceListSet(
									commonUtils.arrayToObject(response.data)
								)
							);
						},
						onError() {
							dispatch(
								job.error({
									message: "Error loading Devices",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_DEVICE_LIST_IMPORT: {
			next(action);
			const {
				file,
				vesselId,
			} = (action as IVesselOnboard_deviceListImport__Action).payload;
			const job = actions._jobs.WIZARD_DEVICE_LIST_IMPORT;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.importDevicesAPI(file, vesselId),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess() {
							dispatch(job.success({}));
							dispatch(actions.command.deviceListLoad(vesselId));
						},
						onError() {
							dispatch(job.error({}));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case WIZARD_DEVICE_LOAD: {
			next(action);
			const {
				deviceId,
			} = (action as IVesselOnboard_deviceLoad__Action).payload;
			const job = actions._jobs.WIZARD_DEVICE_LOAD;
			dispatch(
				apiRequest<IVesselDevice>({
					...vesselOnboardAPIs.loadDeviceAPI(deviceId),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading device",
								notification: { hideAtState: "SUCCESS", timeout: 100 },
							})
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(job.success({}));
							dispatch(actions.document.deviceEdit(response.data));
							dispatch(
								invokerAction(
									"$device-middleware",
									actions._forms.device.formFieldsSet(response.data)
								)
							);
						},
						onError() {
							dispatch(job.error({}));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_DEVICE_CREATE: {
			next(action);
			const {
				device,
			} = (action as IVesselOnboard_deviceCreate__Action).payload;
			const job = actions._jobs.WIZARD_DEVICE_CREATE;
			dispatch(
				apiRequest<IVesselDevice>({
					...vesselOnboardAPIs.createDeviceAPI(device),
					preExecute() {
						dispatch(
							job.active({ message: "Adding Device", notification: {} })
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(actions.document.deviceNew(response.data));
							dispatch(
								job.success({ message: "Device added", notification: {} })
							);
						},
						onError(response) {
							if(response?.response?.data?.error?.statusCode === 422) {
								dispatch(job.error({ message: "Please check mac address and device id, it must be unique" }));
							} else {
								dispatch(job.error({ message: "Failed to add Device" }));
							}
							
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_DEVICE_UPDATE: {
			next(action);
			const {
				device,
				deviceId,
			} = (action as IVesselOnboard_deviceUpdate__Action).payload;
			const job = actions._jobs.WIZARD_DEVICE_UPDATE;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.updateDeviceAPI(deviceId, device),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess() {
							dispatch(
								actions.document.deviceEdit({ ...device, id: deviceId })
							);
							dispatch(
								job.success({ message: "Device updated", notification: {} })
							);
						},
						onError() {
							dispatch(
								job.error({ message: "Device Update failed", notification: {} })
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_DEVICE_DELETE: {
			next(action);
			const {
				deviceId,
			} = (action as IVesselOnboard_deviceDelete__Action).payload;
			const job = actions._jobs.WIZARD_DEVICE_DELETE;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.deleteDeviceAPI(deviceId),
					preExecute() {
						dispatch(
							job.active({ message: "Deleting Device", notification: {} })
						);
					},
					postExecute: {
						onSuccess() {
							dispatch(job.success({ message: "Deleted device" }));
							dispatch(actions.document.deviceErase(deviceId));
						},
						onError() {
							dispatch(job.error({ message: "Error deleting device" }));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case FORM_SUBMIT: {
			next(action);
			const { feature, formName } = (action as IForm_Submit__Action).payload;
			if (
				feature === vesselOnboardConstants.feature &&
				formName === vesselOnboardConstants.forms.DEVICE
			) {
				const {
					fields,
					mode,
				} = getState().app.vesselStore.onboard._forms.DEVICE;
				if (mode === "CREATE") {
					dispatch(
						promiseActions(
							createValidationSchema.validate(fields, { abortEarly: false }),
							{
								invoker: "$vessel/onboard/device-middleware",
								onSuccess: (validatedData) => {
									if (validatedData)
										dispatch(actions.command.deviceCreate(fields));
								},
								onError: (error: ValidationError) => {
									const errors: IFormErrorFormat = {
										GLOBAL: {
											isValid: false,
											message: "Found few errors",
										},
									};
									error.inner.map((errorItem) => {
										errors[errorItem.path] = {
											isValid: false,
											message: errorItem.message,
										};
									});
									dispatch(
										invokerAction(
											"$device-middleware",
											actions._forms.device.formErrorsSet(errors)
										)
									);
								},
							}
						)
					);
				} else if (mode === "EDIT") {
					dispatch(
						promiseActions(
							updateValidationSchema.validate(fields, { abortEarly: false }),
							{
								invoker: "$vessel/onboard/device-middleware",
								onSuccess: (validatedData) => {
									if (validatedData)
										dispatch(actions.command.deviceUpdate(fields.id, fields));
								},
								onError: (error: ValidationError) => {
									const errors: IFormErrorFormat = {
										GLOBAL: {
											isValid: false,
											message: "Found few errors",
										},
									};
									error.inner.map((errorItem) => {
										errors[errorItem.path] = {
											isValid: false,
											message: errorItem.message,
										};
									});
									dispatch(
										invokerAction(
											"$device-middleware",
											actions._forms.device.formErrorsSet(errors)
										)
									);
								},
							}
						)
					);
				} else {
					dispatch(
						invokerAction(
							"$device-middleware",
							actions._forms.device.formErrorsSet({
								GLOBAL: {
									isValid: false,
									message: "No form mode provided",
								},
							})
						)
					);
				}
			}
			break;
		}
		case FORM_FIELDS_SET: {
			next(action);
			const { formName, feature, fields } = (action as IForm_FieldsSet__Action<
				IVesselDevice
			>).payload;
			if (
				feature === vesselOnboardConstants.feature &&
				formName === vesselOnboardConstants.forms.DEVICE
			) {
				// To reset the errors on Change //FIXME: Add below code in Form Middleware
				dispatch(
					invokerAction(
						"$device-middleware",
						actions._forms.device.formErrorsReset([
							"GLOBAL",
							...Object.keys(fields),
						])
					)
				);
			}
			break;
		}
		default: {
			next(action);
		}
	}
};
export default onboardDeviceMiddleware;
