import {
	IVesselOnboard_vesselLoad__Action,
	IVesselOnboard_vesselImageUpload__Action,
	IVesselOnboard_crewListLoad__Action,
	IVesselOnboard_crewListImport__Action,
	IVesselOnboard_deviceListLoad__Action,
	IVesselOnboard_deviceListImport__Action,
	IVesselOnboard_crewLoad__Action,
	IVesselOnboard_crewImageUpload__Action,
	IVesselOnboard_crewCreate__Action,
	IVesselOnboard_crewUpdate__Action,
	IVesselOnboard_crewDelete__Action,
	IVesselOnboard_deviceLoad__Action,
	IVesselOnboard_deviceCreate__Action,
	IVesselOnboard_deviceImageUpload__Action,
	IVesselOnboard_deviceUpdate__Action,
	IVesselOnboard_deviceDelete__Action,
	IVesselOnboard_crewListSet__Action,
	IVesselOnboard_deviceListSet__Action,
	IVesselOnboard_crewNew__Action,
	IVesselOnboard_crewSet__Action,
	IVesselOnboard_crewEdit__Action,
	IVesselOnboard_crewErase__Action,
	IVesselOnboard_deviceNew__Action,
	IVesselOnboard_deviceSet__Action,
	IVesselOnboard_deviceEdit__Action,
	IVesselOnboard_deviceErase__Action,
	IVesselOnboard_reset__Action,
	IVesseOnboard_vesselCreate__Action,
	IVesseOnboard_vesselUpdate__Action,
	IVesselOnboard_crewDesignationLoad__Action,
	IVesselOnboard_crewDesignationSet__Action,
	IVesselOnboard_DashboardUsabilityAlertsLoad__Action,
	IDashboardUsageAndAlerts,
	Ranges,
	IVesseOnboard_vesselConfigLoad__Action,
	IVesseOnboard_vesselConfigListLoad__Action,
	IVesseOnboard_vesselRangesEdit__Action,
	IVesseOnboard_vesselRangesAdd__Action,
	IVesseOnboard_vesselRangesDelete__Action,
	ITimeLog,
	IAllRanges,
} from "./types";

import actionTypes from "./action-types";
import { IVesselCrew, IVesselDevice, IVessel } from "../types";
import { IListObjectStore } from "../../../_common/type";
import { jobActions } from "../../../_core/job";
import { formActions } from "../../../_core/form";
import { createAction } from "typesafe-actions";
import { TMARPOLTank } from "redux/app/marpol/@types";
import { IConfigInfo, ILogListItem } from "redux/app/tool/@types";
const {
	WIZARD_VESSEL_LOAD,
	WIZARD_VESSEL_IMAGE_UPLOAD,
	WIZARD_VESSEL_CREATE,
	WIZARD_VESSEL_UPDATE,
	WIZARD_CREW_LIST_LOAD,
	WIZARD_CREW_LIST_IMPORT,
	WIZARD_DEVICE_LIST_LOAD,
	WIZARD_DEVICE_LIST_IMPORT,
	TANK_LIST_LOAD,
	WIZARD_CREW_LOAD,
	WIZARD_CREW_IMAGE_UPLOAD,
	WIZARD_CREW_CREATE,
	WIZARD_CREW_UPDATE,
	WIZARD_CREW_DELETE,
	WIZARD_DEVICE_LOAD,
	WIZARD_DEVICE_CREATE,
	WIZARD_DEVICE_IMAGE_UPLOAD,
	WIZARD_DEVICE_UPDATE,
	WIZARD_DEVICE_DELETE,
	WIZARD_CREW_DESIGNATIONS_LOAD,
	WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD,
	WIZARD_VESSEL_RANGES_LOAD,
	WIZARD_VESSEL_RANGE_DELETE,
	WIZARD_VESSEL_RANGE_EDIT,
	WIZARD_VESSEL_RANGE_ADD,
	WIZARD_VESSEL_CONFIG_LOAD,
	WIZARD_VESSEL_CONFIG_LIST_LOAD,
	GET_TIME_LOGS
} = actionTypes.command;
const {
	WIZARD_VESSEL_ID_SET,
	WIZARD_CREW_LIST_SET,
	WIZARD_DEVICE_LIST_SET,
	WIZARD_CREW_NEW,
	WIZARD_CREW_SET,
	WIZARD_CREW_EDIT,
	WIZARD_CREW_ERASE,
	WIZARD_DEVICE_NEW,
	WIZARD_DEVICE_SET,
	WIZARD_DEVICE_EDIT,
	WIZARD_DEVICE_ERASE,
	TANKS_SET,
	WIZARD_RESET,
	WIZARD_CREW_DESIGNATIONS_SET,
	WIZARD_DASHBOARD_USAGE_ALERTS_SET,
	WIZARD_VESSEL_RANGES_SET,
	WIZARD_VESSEL_SIGNAL_LOCAL_RANGES_SET,
	WIZARD_VESSEL_CONFIG_SET,
	WIZARD_VESSEL_CONFIG_LIST_SET,
	SET_TIME_LOGS,
	APPEND_TIME_LOGS,
} = actionTypes.document;

const vesselOnboardActions = {
	command: {
		getTimeLogs(vesselId: string, page: number) {
			return {
				type: GET_TIME_LOGS,
				payload: {
					vesselId,
					page,
				},
			};
		},
		vesselConfigListLoad(): IVesseOnboard_vesselConfigListLoad__Action {
			return {
				type: WIZARD_VESSEL_CONFIG_LIST_LOAD,
				payload: {},
			};
		},
		vesselConfigLoad(configId: string): IVesseOnboard_vesselConfigLoad__Action {
			return {
				type: WIZARD_VESSEL_CONFIG_LOAD,
				payload: {
					configId,
				},
			};
		},
		vesselRangesLoad: createAction(
			WIZARD_VESSEL_RANGES_LOAD,
			() => ({})
		)(),
		vesselRangeAdd(ranges: IAllRanges): IVesseOnboard_vesselRangesAdd__Action {
			return {
				type: WIZARD_VESSEL_RANGE_ADD,
				payload: {
					ranges,
				},
			};
		},
		vesselRangeDelete(ranges: IAllRanges): IVesseOnboard_vesselRangesDelete__Action {
			return {
				type: WIZARD_VESSEL_RANGE_DELETE,
				payload: {
					ranges,
				},
			};
		},
		vesselRangeEdit(ranges: IAllRanges): IVesseOnboard_vesselRangesEdit__Action {
			return {
				type: WIZARD_VESSEL_RANGE_EDIT,
				payload: {
					ranges,
				},
			};
		},
		dashboardUsabilityAlertsLoad(
			vesselId: string,
			fromDate?: string,
			toDate?: string
		): IVesselOnboard_DashboardUsabilityAlertsLoad__Action {
			return {
				type: WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD,
				payload: {
					vesselId,
					fromDate,
					toDate,
				},
			};
		},
		vesselCreate(vessel: IVessel): IVesseOnboard_vesselCreate__Action {
			return {
				type: WIZARD_VESSEL_CREATE,
				payload: { vessel },
			};
		},
		vesselUpdate(
			vesselId: string,
			vessel: Partial<IVessel>
		): IVesseOnboard_vesselUpdate__Action {
			return {
				type: WIZARD_VESSEL_UPDATE,
				payload: { vessel, vesselId },
			};
		},
		vesselLoad(
			vesselId: string,
			notify: boolean = true
		): IVesselOnboard_vesselLoad__Action {
			return {
				type: WIZARD_VESSEL_LOAD,
				payload: {
					vesselId,
					notify,
				},
			};
		},
		vesselImageUpload: (
			file: File
		): IVesselOnboard_vesselImageUpload__Action => ({
			type: WIZARD_VESSEL_IMAGE_UPLOAD,
			payload: { file },
		}),
		crewListLoad: (vesselId: string): IVesselOnboard_crewListLoad__Action => ({
			type: WIZARD_CREW_LIST_LOAD,
			payload: { vesselId },
		}),
		crewListImport: (
			file: File,
			vesselId: string
		): IVesselOnboard_crewListImport__Action => ({
			type: WIZARD_CREW_LIST_IMPORT,
			payload: { file, vesselId },
		}),
		deviceListLoad: (
			vesselId: string
		): IVesselOnboard_deviceListLoad__Action => ({
			type: WIZARD_DEVICE_LIST_LOAD,
			payload: { vesselId },
		}),
		deviceListImport: (
			file: File,
			vesselId: string
		): IVesselOnboard_deviceListImport__Action => ({
			type: WIZARD_DEVICE_LIST_IMPORT,
			payload: { file, vesselId },
		}),
		tankListLoad: createAction(TANK_LIST_LOAD, (vesselId: string) => ({
			vesselId,
		}))(),
		crewLoad: (crewId: string): IVesselOnboard_crewLoad__Action => ({
			type: WIZARD_CREW_LOAD,
			payload: { crewId },
		}),
		crewImageUpload: (file: File): IVesselOnboard_crewImageUpload__Action => ({
			type: WIZARD_CREW_IMAGE_UPLOAD,
			payload: { file },
		}),
		crewCreate: (crew: IVesselCrew): IVesselOnboard_crewCreate__Action => ({
			type: WIZARD_CREW_CREATE,
			payload: { crew },
		}),
		crewUpdate: (
			crewId: string,
			crew: Partial<IVesselCrew>
		): IVesselOnboard_crewUpdate__Action => ({
			type: WIZARD_CREW_UPDATE,
			payload: { crew, crewId },
		}),
		crewDelete: (crewId: string): IVesselOnboard_crewDelete__Action => ({
			type: WIZARD_CREW_DELETE,
			payload: { crewId },
		}),
		crewDesignationsLoad(): IVesselOnboard_crewDesignationLoad__Action {
			return { type: WIZARD_CREW_DESIGNATIONS_LOAD, payload: {} };
		},
		deviceLoad: (deviceId: string): IVesselOnboard_deviceLoad__Action => ({
			type: WIZARD_DEVICE_LOAD,
			payload: { deviceId },
		}),
		deviceCreate: (
			device: IVesselDevice
		): IVesselOnboard_deviceCreate__Action => ({
			type: WIZARD_DEVICE_CREATE,
			payload: { device },
		}),
		deviceImageUpload: (
			file: File,
			device: IVesselDevice
		): IVesselOnboard_deviceImageUpload__Action => ({
			type: WIZARD_DEVICE_IMAGE_UPLOAD,
			payload: { file, device },
		}),
		deviceUpdate: (
			deviceId: string,
			device: Partial<IVesselDevice>
		): IVesselOnboard_deviceUpdate__Action => ({
			type: WIZARD_DEVICE_UPDATE,
			payload: { device, deviceId },
		}),
		deviceDelete: (deviceId: string): IVesselOnboard_deviceDelete__Action => ({
			type: WIZARD_DEVICE_DELETE,
			payload: { deviceId },
		}),
	},
	document: {
		setTimeLogs(timeLogs: ITimeLog[]) {
			return {
				type: SET_TIME_LOGS,
				payload: {
					timeLogs
				},
			};
		},
		appendTimeLogs(timeLogs: ITimeLog[]) {
			return {
				type: APPEND_TIME_LOGS,
				payload: {
					timeLogs
				},
			};
		},
		vesselRangesSet(vesselRanges: Ranges) {
			return {
				type: WIZARD_VESSEL_RANGES_SET,
				payload: {
					vesselRanges,
				},
			};
		},
		vesselSignalLocalRangesSet(vesselRanges?: Ranges) {
			return {
				type: WIZARD_VESSEL_SIGNAL_LOCAL_RANGES_SET,
				payload: {
					vesselRanges,
				},
			};
		},
		vesselConfigLogsListSet(logs: IListObjectStore<ILogListItem>) {
			return {
				type: WIZARD_VESSEL_CONFIG_LIST_SET,
				payload: {
					logs,
				},
			};
		},
		vesselConfigInfoSet(configInfo?: IConfigInfo) {
			return {
				type: WIZARD_VESSEL_CONFIG_SET,
				payload: {
					configInfo,
				},
			};
		},
		dashboardUsageAndAlertsSet(usabilityAndAlerts: IDashboardUsageAndAlerts) {
			return {
				type: WIZARD_DASHBOARD_USAGE_ALERTS_SET,
				payload: {
					usabilityAndAlerts,
				},
			};
		},
		setVesselId(vesselId: string) {
			return {
				type: WIZARD_VESSEL_ID_SET,
				payload: { vesselId },
			};
		},
		crewListSet: (
			crews: IListObjectStore<IVesselCrew>
		): IVesselOnboard_crewListSet__Action => ({
			type: WIZARD_CREW_LIST_SET,
			payload: crews,
		}),
		crewListReset(): IVesselOnboard_crewListSet__Action {
			return {
				type: WIZARD_CREW_LIST_SET,
				payload: { byIds: {}, ids: [] },
			};
		},
		deviceListSet: (
			devices: IListObjectStore<IVesselDevice>
		): IVesselOnboard_deviceListSet__Action => ({
			type: WIZARD_DEVICE_LIST_SET,
			payload: devices,
		}),
		deviceListReset(): IVesselOnboard_deviceListSet__Action {
			return {
				type: WIZARD_DEVICE_LIST_SET,
				payload: {
					byIds: {},
					ids: [],
				},
			};
		},
		crewNew: (crew: IVesselCrew): IVesselOnboard_crewNew__Action => ({
			type: WIZARD_CREW_NEW,
			payload: { crew },
		}),
		crewSet: (crew: IVesselCrew): IVesselOnboard_crewSet__Action => ({
			type: WIZARD_CREW_SET,
			payload: { crew },
		}),
		crewEdit: (
			crew: Partial<IVesselCrew> & Required<{ id: string }>
		): IVesselOnboard_crewEdit__Action => ({
			type: WIZARD_CREW_EDIT,
			payload: { crew },
		}),
		crewErase: (crewId: string): IVesselOnboard_crewErase__Action => ({
			type: WIZARD_CREW_ERASE,
			payload: { crewId },
		}),
		crewDesignationsSet(
			designations: string[]
		): IVesselOnboard_crewDesignationSet__Action {
			return {
				type: WIZARD_CREW_DESIGNATIONS_SET,
				payload: { designations },
			};
		},
		deviceNew: (device: IVesselDevice): IVesselOnboard_deviceNew__Action => ({
			type: WIZARD_DEVICE_NEW,
			payload: { device },
		}),
		deviceSet: (device: IVesselDevice): IVesselOnboard_deviceSet__Action => ({
			type: WIZARD_DEVICE_SET,
			payload: { device },
		}),
		deviceEdit: (
			device: Partial<IVesselCrew> & Required<{ id: string }>
		): IVesselOnboard_deviceEdit__Action => ({
			type: WIZARD_DEVICE_EDIT,
			payload: { device },
		}),
		deviceErase: (deviceId: string): IVesselOnboard_deviceErase__Action => ({
			type: WIZARD_DEVICE_ERASE,
			payload: { deviceId },
		}),
		tanksSet: createAction(TANKS_SET, (tanks: TMARPOLTank[]) => ({ tanks }))(),
		reset: (): IVesselOnboard_reset__Action => ({ type: WIZARD_RESET }),
	},
	_jobs: {
		WIZARD_VESSEL_CONFIG_LOAD: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_CONFIG_LOAD
		),
		WIZARD_VESSEL_CONFIG_LIST_LOAD: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_CONFIG_LIST_LOAD
		),
		WIZARD_VESSEL_RANGES_LOAD: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_RANGES_LOAD
		),
		WIZARD_VESSEL_RANGE_DELETE: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_RANGE_DELETE
		),
		WIZARD_VESSEL_RANGE_EDIT: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_RANGE_EDIT
		),
		WIZARD_VESSEL_RANGE_ADD: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_RANGE_ADD
		),
		WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD: jobActions(
			"@vessel/onboard",
			WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD
		),
		GET_TIME_LOGS: jobActions(
			"@vessel/onboard",
			GET_TIME_LOGS
		),
		TANK_LIST_LOAD: jobActions("@vessel/onboard", TANK_LIST_LOAD),
		WIZARD_VESSEL_LOAD: jobActions("@vessel/onboard", WIZARD_VESSEL_LOAD),
		WIZARD_VESSEL_IMAGE_UPLOAD: jobActions(
			"@vessel/onboard",
			WIZARD_VESSEL_IMAGE_UPLOAD
		),
		WIZARD_CREW_LIST_LOAD: jobActions("@vessel/onboard", WIZARD_CREW_LIST_LOAD),
		WIZARD_CREW_DESIGNATIONS_LOAD: jobActions(
			"@vessel/onboard",
			WIZARD_CREW_DESIGNATIONS_LOAD
		),
		WIZARD_CREW_LIST_IMPORT: jobActions(
			"@vessel/onboard",
			WIZARD_CREW_LIST_IMPORT
		),
		WIZARD_DEVICE_LIST_LOAD: jobActions(
			"@vessel/onboard",
			WIZARD_DEVICE_LIST_LOAD
		),
		WIZARD_DEVICE_LIST_IMPORT: jobActions(
			"@vessel/onboard",
			WIZARD_DEVICE_LIST_IMPORT
		),
		WIZARD_CREW_LOAD: jobActions("@vessel/onboard", WIZARD_CREW_LOAD),
		WIZARD_CREW_IMAGE_UPLOAD: jobActions(
			"@vessel/onboard",
			WIZARD_CREW_IMAGE_UPLOAD
		),
		WIZARD_CREW_CREATE: jobActions("@vessel/onboard", WIZARD_CREW_CREATE),
		WIZARD_CREW_UPDATE: jobActions("@vessel/onboard", WIZARD_CREW_UPDATE),
		WIZARD_CREW_DELETE: jobActions("@vessel/onboard", WIZARD_CREW_DELETE),
		WIZARD_DEVICE_LOAD: jobActions("@vessel/onboard", WIZARD_DEVICE_LOAD),
		WIZARD_DEVICE_CREATE: jobActions("@vessel/onboard", WIZARD_DEVICE_CREATE),
		WIZARD_DEVICE_IMAGE_UPLOAD: jobActions(
			"@vessel/onboard",
			WIZARD_DEVICE_IMAGE_UPLOAD
		),
		WIZARD_DEVICE_UPDATE: jobActions("@vessel/onboard", WIZARD_DEVICE_UPDATE),
		WIZARD_DEVICE_DELETE: jobActions("@vessel/onboard", WIZARD_DEVICE_DELETE),
		WIZARD_VESSEL_CREATE: jobActions("@vessel/onboard", WIZARD_VESSEL_CREATE),
		WIZARD_VESSEL_UPDATE: jobActions("@vessel/onboard", WIZARD_VESSEL_UPDATE),
	},
	_forms: {
		crew: formActions<IVesselCrew>("CREW", "@vessel/onboard"),
		device: formActions<IVesselDevice>("DEVICE", "@vessel/onboard"),
		vessel: formActions<IVessel>("VESSEL", "@vessel/onboard"),
	},
};

export default vesselOnboardActions;
