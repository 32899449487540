import React from "react";
import {
	ContentImageRenderer,
	contentTypeCategorizerLookup,
} from "../../utils";
import classNames from "classnames";

const MultimediaPreviewer = ({
	url,
	title = "",
	content_type,
	width = 100,
	height = 100,
	className
}: {
	url: string;
	title: string;
	content_type: string;
	width?: number | string;
	height?: number | string;
	className?: string;
}) => {
	if (!url) return null;
	// primitives are pdf and images
	const isNotPrimitiveContentTypes =
		content_type in contentTypeCategorizerLookup;
	return (
		<div
			className={classNames('ws-scroll w-full h-full', className)}
			key={url}
		>
			{isNotPrimitiveContentTypes ? (
				<div>
					{/* @ts-ignore */}
					{ContentImageRenderer[contentTypeCategorizerLookup[content_type]]}
				</div>
			) : (
				<object data={url} width={width} height={height} type={content_type}>
					alt: {title}`
				</object>
			)}
		</div>
	);
};

export default MultimediaPreviewer;
