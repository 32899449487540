import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import { IListObjectStore } from "redux/_common/type";
import {
	EParameterTabType,
	INoonReportMetricListItem,
} from "redux/app/insight/@types";
import insightActions from "redux/app/insight/actions";
import insightSelectors from "redux/app/insight/selectors";
import { getNoonReportMetricsList } from "redux/app/insight/utils";
import { commonUtils } from "redux/_common";
import NoonReportParameterList from "./NoonReportParameterList";
import NoonReportMetricSelectedAndRecentList from "./NoonReportMetricSelectedAndRecentList";
import Skeleton from "react-loading-skeleton";

type OwnProps = {};
type NoonReportParameterProps = PropsFromRedux & OwnProps;
function NoonReportParameter(props: NoonReportParameterProps) {
	const {
		noonReportConfig,
		noonReportEngineConfig,
		showParameterMetricList,
		currentTab,
		jobToLoadConfig,
	} = props;
	useEffect(() => {
		if (
			noonReportConfig?.logCategories &&
			noonReportEngineConfig?.logCategories
		) {
			const logCategories = noonReportConfig?.logCategories;
			const engineLogCategories = noonReportEngineConfig?.logCategories;
			const metricsConfigList = getNoonReportMetricsList(
				logCategories
			) as INoonReportMetricListItem[];

			const metricsEngineConfigList = getNoonReportMetricsList(
				engineLogCategories
			) as INoonReportMetricListItem[];

			props.setNoonReportMetricsList(
				commonUtils.arrayToObject([
					...metricsConfigList,
					...metricsEngineConfigList,
				])
			);
		} else {
			props.setNoonReportMetricsList({
				ids: [],
				byIds: {},
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noonReportConfig?.vesselId, noonReportEngineConfig?.vesselId]);

	if (
		showParameterMetricList &&
		currentTab === EParameterTabType.NOON_REPORT_TAB &&
		jobToLoadConfig.isLoading
	) {
		return (
			<div>
				{Array.from({ length: 15 }).map(() => {
					return <Skeleton className="mx-1 mt-0.5 w-[97%]" height={56} />;
				})}
			</div>
		);
	}

	return (
		<>
			{showParameterMetricList &&
				currentTab === EParameterTabType.NOON_REPORT_TAB && (
					<NoonReportParameterList />
				)}
			{!showParameterMetricList &&
				currentTab === EParameterTabType.NOON_REPORT_TAB && (
					<NoonReportMetricSelectedAndRecentList />
				)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const noonReportConfig = insightSelectors.getNoonReportConfig(insightStore);
	const noonReportEngineConfig =
		insightSelectors.getNoonReportEngineConfig(insightStore);
	const currentTab = insightSelectors.getParameterCurrentTab(insightStore);
	const showParameterMetricList =
		insightSelectors.getShowParameterMetricList(insightStore);
	const jobToLoadConfig = insightSelectors.jobToLoadConfig(insightStore);

	const noonReportMetricsList =
		insightSelectors.getNoonReportMetricsList(insightStore);
	return {
		noonReportConfig,
		noonReportEngineConfig,
		noonReportMetricsList,
		currentTab,
		showParameterMetricList,
		jobToLoadConfig,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setNoonReportMetricsList(
			metricsList: IListObjectStore<INoonReportMetricListItem>
		) {
			dispatch(insightActions.document.noonReportMetricsListSet(metricsList));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NoonReportParameter);
