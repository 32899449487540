import React from "react";
import ModalPortal from "components/app/_common/ModalPortal";
import Button from "@set-product/core/button/Button";
import classNames from "classnames";
type ownProps = {
	isActive: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	confirmBtnLabel: string;
	closeBtnLabel: string;
	titleClassName?: string;
	modalSize?: number;
};
function ConfirmBox(props: ownProps) {
	const {
		isActive,
		onClose,
		onConfirm,
		title,
		confirmBtnLabel,
		closeBtnLabel,
		titleClassName,
		modalSize
	} = props;
	return (
		<ModalPortal
			isActive={isActive}
			width={modalSize || 300}
			onClose={() => onClose()}
			isOverlayClickCloseable={false}
		>
			<div className={"p-4 bg-white rounded-md w-full"}>
				<h3 className={classNames("py-3 pb-6", titleClassName)}>{title}</h3>
				<div className="flex justify-end">
					<Button className="mr-2 bg-red-500" onClick={() => onConfirm()}>
						{confirmBtnLabel}
					</Button>
					<Button className="bg-blue-500" onClick={() => onClose()}>
						{closeBtnLabel}
					</Button>
				</div>
			</div>
		</ModalPortal>
	);
}

export default ConfirmBox;
