import moment from 'moment';
import React from 'react';
import { TORB1Record } from 'redux/app/orb1/@types';
import ORB1TableRows from '../ORB1TableRows';
import { marpolUtils } from 'redux/app/marpol';
import { TORB1D5 } from 'redux/app/orb1/groups/group-d-new';

type OwnProps = {
    orb1Record: TORB1Record;
    isStrikedOff?: boolean;
};
type ORB1D5PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1D5Preview(props: ORB1D5PreviewProps) {
    const orb1Record = props.orb1Record as TORB1D5;
    const operationDate = moment(orb1Record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <ORB1TableRows
                record={orb1Record}
                tableRows={[
                    [operationDate, 'D', '13', renderItem13()],
                    [null, null, '14', renderItem14()],
                    [null, null, '15.2', renderItem15P2()],
                    [null, null, 'NOTE', orb1Record.remarks],
                ]}
            />
        );
    }

    function renderItem13(): React.ReactNode {
        return (
            <>
                {orb1Record.item13And14.quantityDischarged.toFixed(2)} M³ FROM{' '}
                {orb1Record.item13And14.bilgeSource}
            </>
        );
    }

    function renderItem14() {
        return (
            <h4 className="font-medium uppercase">
                STARTED {orb1Record.item13And14.startOfDischargeTime.time} /
                FINISHED {orb1Record.item13And14.endOfDischargeTime.time}
            </h4>
        );
    }

    function renderItem15P2() {
        const { receiptNumber, receiptAttachment, port } = orb1Record.item15P2;
        return (
            <div className="w-full h-full font-medium uppercase">
                TO {port} PORT RECEPTION FACILITIES. RECEIPT NO:{' '}
                <b>{receiptNumber}</b> <br />
                ATTACHMENT:{' '}
                <a
                    href={marpolUtils.getAttachmentURL(
                        receiptAttachment,
                        orb1Record.id,
                        orb1Record._rev
                    )}
                    download={receiptAttachment}
                >
                    {' '}
                    {receiptAttachment}
                </a>
            </div>
        );
    }

    return renderMain();
}

export default ORB1D5Preview;
