const WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD =
	"@vessel/onboard/dashboard/usability-alerts/LOAD"; // COMMAND ACTION
const WIZARD_VESSEL_LOAD = "@vessel/onboard/vessel-form/LOAD"; // COMMAND ACTION
const WIZARD_VESSEL_IMAGE_UPLOAD = "@vessel/onboard/vessel-form/IMAGE_UPLOAD"; // COMMAND ACTION
const WIZARD_VESSEL_CREATE = "@vessel/onboard/vessel-form/CREATE"; // COMMAND ACTION
const WIZARD_VESSEL_UPDATE = "@vessel/onboard/vessel-form/UPDATE"; // COMMAND ACTION

const WIZARD_CREW_LIST_LOAD = "@vessel/onboard/crew-list/LOAD"; // COMMAND ACTION
const WIZARD_CREW_LIST_IMPORT = "@vessel/onboard/crew-list/IMPORT"; // COMMAND ACTION

const WIZARD_DEVICE_LIST_LOAD = "@vessel/onboard/device-list/LOAD"; // COMMAND ACTION
const WIZARD_DEVICE_LIST_IMPORT = "@vessel/onboard/device-list/IMPORT"; // COMMAND ACTION
const TANK_LIST_LOAD = "@vessel/onboard/tank-list/LOAD";

const WIZARD_CREW_LOAD = "@vessel/onboard/crew/LOAD"; // COMMAND ACTION
const WIZARD_CREW_IMAGE_UPLOAD = "@vessel/onboard/crew/IMAGE-UPLOAD"; // COMMAND ACTION
const WIZARD_CREW_CREATE = "@vessel/onboard/crew/CREATE"; // COMMAND ACTION
const WIZARD_CREW_UPDATE = "@vessel/onboard/crew/UPDATE"; // COMMAND ACTION
const WIZARD_CREW_DELETE = "@vessel/onboard/crew/DELETE"; // COMMAND ACTION
const WIZARD_CREW_DESIGNATIONS_LOAD = "@vessel/onboard/crew/designations/LOAD"; // COMMAND ACTION

const WIZARD_DEVICE_LOAD = "@vessel/onboard/device/LOAD"; // COMMAND ACTION
const WIZARD_DEVICE_CREATE = "@vessel/onboard/device/CREATE"; // COMMAND ACTION
const WIZARD_DEVICE_IMAGE_UPLOAD = "@vessel/onboard/device/IMAGE-UPLOAD"; // COMMAND ACTION
const WIZARD_DEVICE_UPDATE = "@vessel/onboard/device/UPDATE"; // COMMAND ACTION
const WIZARD_DEVICE_DELETE = "@vessel/onboard/device/DELETE"; // COMMAND ACTION

const WIZARD_VESSEL_SET = "@vessel/onboard/vessel-form/SET"; // DOCUMENT ACTION
const WIZARD_VESSEL_EDIT = "@vessel/onboard/vessel-form/EDIT"; // DOCUMENT ACTION
const WIZARD_VESSEL_ID_SET = "@vessel/onboard/vessel-id/SET"; // DOCUMENT ACTION

const WIZARD_CREW_LIST_SET = "@vessel/onboard/crew-list/SET"; // DOCUMENT ACTIONa
const WIZARD_DEVICE_LIST_SET = "@vessel/onboard/device-list/SET"; // DOCUMENT ACTION
const WIZARD_CREW_DESIGNATIONS_SET = "@vessel/onboard/crew/designations/SET"; // DOCUMENT ACTION

const WIZARD_CREW_NEW = "@vessel/onboard/crew/NEW"; // DOCUMENT ACTION
const WIZARD_CREW_SET = "@vessel/onboard/crew/SET"; // DOCUMENT ACTION
const WIZARD_CREW_EDIT = "@vessel/onboard/crew/EDIT"; // DOCUMENT ACTION
const WIZARD_CREW_ERASE = "@vessel/onboard/crew/ERASE"; // DOCUMENT ACTION

const WIZARD_DEVICE_NEW = "@vessel/onboard/device/NEW"; // DOCUMENT ACTION
const WIZARD_DEVICE_SET = "@vessel/onboard/device/SET"; // DOCUMENT ACTION
const WIZARD_DEVICE_EDIT = "@vessel/onboard/device/EDIT"; // DOCUMENT ACTION
const WIZARD_DEVICE_ERASE = "@vessel/onboard/device/ERASE"; // DOCUMENT ACTION

const TANKS_SET = "@vessel/onboard/tanks/SET";

const WIZARD_RESET = "@vessel/onboard/RESET"; // DOCUMENT ACTION
const WIZARD_DASHBOARD_USAGE_ALERTS_SET =
	"@vessel/onboard/dashboard/usage-alerts/SET";

// Ranges
const WIZARD_VESSEL_RANGES_LOAD = "@vessel/onboard/ranges/LOAD";
const WIZARD_VESSEL_RANGES_SET = "@vessel/onboard/ranges/SET";
const WIZARD_VESSEL_SIGNAL_LOCAL_RANGES_SET = "@vessel/onboard/signal-local-ranges/SET";
const WIZARD_VESSEL_RANGE_EDIT = "@vessel/onboard/range/edit";
const WIZARD_VESSEL_RANGE_DELETE = "@vessel/onboard/range/delete";
const WIZARD_VESSEL_RANGE_ADD = "@vessel/onboard/range/add";

// CONFIGS
const WIZARD_VESSEL_CONFIG_LOAD = "@vessel/onboard/config/LOAD";
const WIZARD_VESSEL_CONFIG_LIST_LOAD = "@vessel/onboard/config-list/LOAD";
const WIZARD_VESSEL_CONFIG_SET = "@vessel/onboard/config/SET";
const WIZARD_VESSEL_CONFIG_LIST_SET = "@vessel/onboard/config-list/SET";

const GET_TIME_LOGS = "@vessel/onboard/get-time-logs";
const SET_TIME_LOGS = "@vessel/onboard/set-time-logs";
const APPEND_TIME_LOGS = "@vessel/onboard/append-time-logs";


const vesselOnboardActionTypes = {
	command: {
		GET_TIME_LOGS,
		WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD,
		WIZARD_VESSEL_LOAD,
		WIZARD_VESSEL_IMAGE_UPLOAD,
		WIZARD_VESSEL_CREATE,
		WIZARD_VESSEL_UPDATE,
		WIZARD_CREW_LIST_LOAD,
		WIZARD_CREW_LIST_IMPORT,
		WIZARD_DEVICE_LIST_LOAD,
		TANK_LIST_LOAD,
		WIZARD_DEVICE_LIST_IMPORT,
		WIZARD_CREW_LOAD,
		WIZARD_CREW_IMAGE_UPLOAD,
		WIZARD_CREW_CREATE,
		WIZARD_CREW_UPDATE,
		WIZARD_CREW_DELETE,
		WIZARD_DEVICE_LOAD,
		WIZARD_DEVICE_CREATE,
		WIZARD_DEVICE_IMAGE_UPLOAD,
		WIZARD_DEVICE_UPDATE,
		WIZARD_DEVICE_DELETE,
		WIZARD_CREW_DESIGNATIONS_LOAD,
		WIZARD_VESSEL_RANGES_LOAD,
		WIZARD_VESSEL_RANGE_DELETE,
		WIZARD_VESSEL_RANGE_EDIT,
		WIZARD_VESSEL_RANGE_ADD,
		WIZARD_VESSEL_CONFIG_LOAD,
		WIZARD_VESSEL_CONFIG_LIST_LOAD,
	},
	document: {
		APPEND_TIME_LOGS,
		SET_TIME_LOGS,
		WIZARD_VESSEL_ID_SET,
		WIZARD_CREW_LIST_SET,
		WIZARD_DEVICE_LIST_SET,
		WIZARD_CREW_NEW,
		WIZARD_CREW_SET,
		WIZARD_CREW_EDIT,
		WIZARD_CREW_ERASE,
		WIZARD_DEVICE_NEW,
		WIZARD_DEVICE_SET,
		WIZARD_DEVICE_EDIT,
		WIZARD_DEVICE_ERASE,
		TANKS_SET,
		WIZARD_RESET,
		WIZARD_CREW_DESIGNATIONS_SET,
		WIZARD_DASHBOARD_USAGE_ALERTS_SET,
		WIZARD_VESSEL_RANGES_SET,
		WIZARD_VESSEL_SIGNAL_LOCAL_RANGES_SET,
		WIZARD_VESSEL_CONFIG_SET,
		WIZARD_VESSEL_CONFIG_LIST_SET
	},
	event: {},
};

export default vesselOnboardActionTypes;
