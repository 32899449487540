import Reports from "components/app/vessel/onboard/reports/Reports";
import React from "react";

type ScreenVesselReportsLogsType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
			pageNumber: string;
		};
	};
}>;
const ScreenVesselReportsLogs: React.FC<ScreenVesselReportsLogsType> = (
	props: ScreenVesselReportsLogsType
) => {
	// return <div>hi</div>
	return <Reports
			pageNumber={props.match.params.pageNumber} 
			vesselId={props.match.params.vesselId}
		/>;
};

export default ScreenVesselReportsLogs;