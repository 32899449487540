import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import feedbackActions from "redux/app/feedback/actions";
import classNames from "classnames";
import { TToolType } from "redux/app/tool/@types";
import toolActions from "redux/app/tool/actions";
import toolSelectors from "redux/app/tool/selectors";
import Select from "react-select";
import clsx from "clsx";
import {
	clearIndicatorStyles,
	controlStyles,
	dropdownIndicatorStyles,
	menuStyles,
	optionStyles,
	placeholderStyles,
} from "redux/app/feedback/utils";
import {
	getTenantsOptionList,
	getVesselsOptionList,
} from "redux/app/tool/utils";
import { IOption } from "redux/app/feedback/@types";
import userActions from "redux/app/user/actions";
import ConfigRenderer from "./_elements/ConfigRenderer";
import PrintRenderer from "./_elements/PrintRenderer";

type OwnProps = {};
type ConfigAndPrintProps = PropsFromRedux & OwnProps;
function ConfigAndPrint(props: ConfigAndPrintProps) {
	const {
		toolType,
		tenants,
		vessels,
		selectedTenantOption,
		selectedVesselOption,
		jobToLoadTenants,
		jobToLoadVesselSepecificToTenant,
		jobToLoadConfig,
		jobToLoadPrint,
		configSelectedItems,
		printsSelectedItems,
	} = props;

	useEffect(() => {
		props.loadVessels();
		props.loadUsers();
		props.loadTenantsList();

		return () => {
			props.setTenant(undefined);
			props.setVessel(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tenantOptions = useMemo(
		() => getTenantsOptionList(tenants),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[tenants.ids]
	);
	const vesselsOptions = useMemo(
		() => getVesselsOptionList(vessels),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[vessels.ids]
	);

	useEffect(() => {
		const tenantId = selectedTenantOption?.value;
		if (tenantId) {
			props.loadTenantSpecificVesselsList(tenantId);
		}
		props.setVessel(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value]);

	const isToolTypeConfig = toolType === "config";
	const isToolTypePrint = toolType === "print";

	// TODO: need to move to new components during api-integration
	return (
		<>
			<div className="bg-white h-[calc(100vh-16px)] m-2 rounded-md">
				<div className="px-4 flex justify-between items-center pt-4">
					<div className="flex flex-col md:flex-row justify-center md:items-center  md:space-x-2">
						<div className="w-44">
							<Select
								id="tenants"
								isClearable
								isSearchable
								key={"tenants" + selectedTenantOption?.value}
								placeholder={"Search tenants"}
								isDisabled={
									jobToLoadVesselSepecificToTenant.isLoading ||
									jobToLoadConfig.isLoading ||
									jobToLoadPrint.isLoading ||
									!!printsSelectedItems.length ||
									!!configSelectedItems.length
								}
								unstyled
								value={selectedTenantOption}
								options={tenantOptions}
								isLoading={jobToLoadTenants.isLoading}
								onChange={(newValue, actionMeta) => {
									props.setTenant(newValue ?? undefined);
									// handleChange && handleChange(newValue, actionMeta)
								}}
								className={classNames("w-full text-sm", {
									"cursor-not-allowed": false,
								})}
								menuPosition="fixed"
								classNames={{
									option: ({ isFocused, isSelected }) =>
										clsx(
											isFocused && optionStyles.focus,
											isSelected && optionStyles.selected,
											optionStyles.base
										),

									dropdownIndicator: () => dropdownIndicatorStyles,
									menu: () => menuStyles,
									clearIndicator: () => clearIndicatorStyles,
									placeholder: () => placeholderStyles,
									control: ({ isFocused, isDisabled }) =>
										clsx(
											isFocused ? controlStyles.focus : controlStyles.nonFocus,
											isDisabled ? controlStyles.disabled : controlStyles.base
										),

									valueContainer: ({ isDisabled }) =>
										clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
								}}
							/>
						</div>
						<div className="w-44">
							<Select
								id="vessels"
								isClearable
								isSearchable
								key={"vessels" + selectedVesselOption?.value}
								placeholder={"Search vessels"}
								isDisabled={
									!selectedTenantOption?.value ||
									jobToLoadConfig.isLoading ||
									jobToLoadPrint.isLoading ||
									!!printsSelectedItems.length ||
									!!configSelectedItems.length
								}
								unstyled
								value={selectedVesselOption}
								options={vesselsOptions}
								isLoading={jobToLoadVesselSepecificToTenant.isLoading}
								onChange={(newValue, actionMeta) => {
									props.setVessel(newValue ?? undefined);
								}}
								className={classNames("w-full text-sm", {
									"cursor-not-allowed": false,
								})}
								menuPosition="fixed"
								classNames={{
									option: ({ isFocused, isSelected }) =>
										clsx(
											isFocused && optionStyles.focus,
											isSelected && optionStyles.selected,
											optionStyles.base
										),

									dropdownIndicator: () => dropdownIndicatorStyles,
									menu: () => menuStyles,
									clearIndicator: () => clearIndicatorStyles,
									placeholder: () => placeholderStyles,
									control: ({ isFocused, isDisabled }) =>
										clsx(
											isFocused ? controlStyles.focus : controlStyles.nonFocus,
											isDisabled ? controlStyles.disabled : controlStyles.base
										),

									valueContainer: ({ isDisabled }) =>
										clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
								}}
							/>
						</div>
					</div>
					<div className="flex flex-row justify-between border-[#0B2F564D] border rounded-md ">
						<button
							className={classNames(
								"transition-all duration-300 px-4 md:px-8 text-[12px]",
								{
									"p-2 flex-1  font-medium text-gray-800 bg-[#D7D9FF] rounded-md shadow-sm outline-none":
										isToolTypeConfig,
									"p-2 flex-1  font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
										isToolTypePrint,
									"cursor-not-allowed":
										jobToLoadConfig.isLoading ||
										jobToLoadPrint.isLoading ||
										jobToLoadTenants.isLoading ||
										jobToLoadVesselSepecificToTenant.isLoading ||
										!!printsSelectedItems.length ||
										!!configSelectedItems.length,
								}
							)}
							disabled={
								jobToLoadConfig.isLoading ||
								jobToLoadPrint.isLoading ||
								jobToLoadTenants.isLoading ||
								jobToLoadVesselSepecificToTenant.isLoading ||
								!!printsSelectedItems.length ||
								!!configSelectedItems.length
							}
							onClick={() => {
								props.setConfigPrintToolType("config");
							}}
						>
							Config
						</button>
						<button
							className={classNames(
								"transition-all duration-300 px-4 md:px-8 text-[12px]",
								{
									"py-1.5 flex-1  font-medium text-gray-800 bg-[#D7D9FF] rounded-md shadow-sm outline-none":
										isToolTypePrint,
									"py-1.5 flex-1  font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
										isToolTypeConfig,
									"cursor-not-allowed":
										jobToLoadConfig.isLoading ||
										jobToLoadPrint.isLoading ||
										jobToLoadTenants.isLoading ||
										jobToLoadVesselSepecificToTenant.isLoading ||
										!!printsSelectedItems.length ||
										!!configSelectedItems.length,
								}
							)}
							disabled={
								jobToLoadConfig.isLoading ||
								jobToLoadPrint.isLoading ||
								jobToLoadTenants.isLoading ||
								jobToLoadVesselSepecificToTenant.isLoading ||
								!!printsSelectedItems.length ||
								!!configSelectedItems.length
							}
							onClick={() => {
								props.setConfigPrintToolType("print");
							}}
						>
							Print
						</button>
					</div>
				</div>
				{isToolTypeConfig && <ConfigRenderer />}
				{isToolTypePrint && <PrintRenderer />}
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const tenants = toolSelectors.getTenants(toolStore);
	const vessels = toolSelectors.getVessels(toolStore);
	const jobToLoadTenants = toolSelectors.jobToLoadTenants(toolStore);
	const jobToLoadVesselSepecificToTenant =
		toolSelectors.jobToLoadVesselsSpecificToTenant(toolStore);
	const selectedTenantOption = toolSelectors.getSelectedTenantOption(toolStore);
	const selectedVesselOption = toolSelectors.getSelectedVesselOption(toolStore);
	const toolType = toolSelectors.getConfigPrintToolType(toolStore);

	const jobToLoadPrint = toolSelectors.jobToLoadPrint(toolStore);
	const jobToLoadConfig = toolSelectors.jobToLoadConfig(toolStore);
	const printsSelectedItems = toolSelectors.getPrintSelectedItems(toolStore);
	const configSelectedItems = toolSelectors.getSelectedItems(toolStore);

	return {
		toolType,
		tenants,
		vessels,
		selectedTenantOption,
		selectedVesselOption,
		jobToLoadTenants,
		jobToLoadVesselSepecificToTenant,
		jobToLoadConfig,
		jobToLoadPrint,
		printsSelectedItems,
		configSelectedItems,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadVessels() {
			dispatch(feedbackActions.commands.feedbackVesselsLoad());
		},
		loadUsers() {
			// Info: value 1 has no significance, we have set isPaginated to false
			dispatch(userActions.commands.userListLoad(1, false));
		},
		setConfigPrintToolType(toolType: TToolType) {
			dispatch(toolActions.document.toolTypeSet(toolType));
		},
		loadTenantsList() {
			dispatch(toolActions.commands.toolTenantListLoad());
		},
		loadTenantSpecificVesselsList(tenantId: string) {
			dispatch(toolActions.commands.toolTenantSpecificVesselLoad(tenantId));
		},
		setTenant(tenantOption?: IOption) {
			dispatch(
				toolActions.document.toolConfigPrintTenandOptionSelect(tenantOption)
			);
		},
		setVessel(vesselOption?: IOption) {
			dispatch(
				toolActions.document.toolConfigPrintVesselOptionSelect(vesselOption)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ConfigAndPrint);
