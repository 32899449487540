import useElementSize from "hooks/useElementSize";
import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import YearPicker from "../../individualreport/reportType/garbage/_elements/YearPicker";
import {
	getYearOptions,
	isOilSpillsInWaterDataEmpty,
	transformOilSpillsInWaterRawData,
	transformVesselSpecificOilSpillsInWaterRawData,
} from "redux/app/report/utils";
import VesselSelector from "../../common/VesselSelector";
import reportActions from "redux/app/report/actions";
import { vesselActions } from "redux/app/vessel";
import moment from "moment";
import reportSelectors from "redux/app/report/selectors";
import Graph from "./_elements/Graph";
import Table from "./_elements/Table";

type OwnProps = {};
type OilSpillsInWaterProps = PropsFromRedux & OwnProps;
function OilSpillsInWater(props: OilSpillsInWaterProps) {
	const {
		vesselId,
		year,
		jobToGetVesselSpecificOilSpillsInWaterReport,
		jobToLoadAllVesselAggregateOilSpillsInWaterReport,
		rawOilSpillsInWaterData,
		vesselSpecificRawOilSpillsInWaterData,
	} = props;
	const reportContainerRef = useRef<HTMLDivElement>(null);

	const { width: reportContainerWidth } = useElementSize(reportContainerRef);

	useEffect(() => {
		if (vesselId) props.loadOilSpillsInWaterVesselSpecificAggregateData();
		else {
			props.loadOilSpillsInWaterAllVesselsAggregateData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, year]);

	useEffect(() => {
		props.loadVessels();
		props.setVesselId("");

		return () => {
			props.setVesselId("");
			props.setYear(moment().year().toString());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isOilSpillsInWaterEmpty = isOilSpillsInWaterDataEmpty(
		vesselId
			? transformVesselSpecificOilSpillsInWaterRawData(
					vesselSpecificRawOilSpillsInWaterData
			  )
			: transformOilSpillsInWaterRawData(rawOilSpillsInWaterData)
	);

	const isLoading =
		(!vesselId &&
			jobToLoadAllVesselAggregateOilSpillsInWaterReport.isLoading) ||
		(vesselId && jobToGetVesselSpecificOilSpillsInWaterReport.isLoading);

	return (
		<div
			ref={reportContainerRef}
			className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto"
		>
			<div className="">
				<div className="px-5 pb-0 py-8">
					<div className="flex items-center">
						<span
							title="go back"
							onClick={() => {
								window?.history?.back();
							}}
							className="bp3-icon bp3-icon-arrow-left text-lg mr-3 cursor-pointer"
						/>
						<h2 className="text-xl font-medium">Oil spills in water</h2>
					</div>
					<div className="flex items-center justify-between">
						<div className="flex items-center">
							<div className="flex  items-baseline">
								<>
									<div className="ml-4 text-base">
										<YearPicker
											options={getYearOptions().map((op) => ({
												label: op.toString(),
												value: op.toString(),
											}))}
											disabled={
												jobToGetVesselSpecificOilSpillsInWaterReport.isLoading ||
												jobToLoadAllVesselAggregateOilSpillsInWaterReport.isLoading
											}
											isLoading={
												jobToGetVesselSpecificOilSpillsInWaterReport.isLoading ||
												jobToLoadAllVesselAggregateOilSpillsInWaterReport.isLoading
											}
											value={year}
											id="oil-spill-year-picker"
											onSelect={(option) => {
												props.setYear(option?.value ?? "");
											}}
										/>
									</div>
									<div className="pl-4 min-w-28 md:w-56">
										<VesselSelector
											onVesselSelect={(vesselId: string) => {
												props.setVesselId(vesselId);
											}}
											isLoading={
												jobToGetVesselSpecificOilSpillsInWaterReport.isLoading ||
												jobToLoadAllVesselAggregateOilSpillsInWaterReport.isLoading
											}
											selectedVesselId={vesselId}
											showDropdownLabel={false}
										/>
									</div>
								</>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isLoading ? (
				<div className="flex justify-center mt-20">Loading...</div>
			) : isOilSpillsInWaterEmpty ? (
				<div className="flex justify-center items-center mt-20">
					No data found{vesselId ? " for this vessel" : ""}.{" "}
					{vesselId
						? "Choose another vessel or change the year."
						: "Please select a vessel or change the year."}
				</div>
			) : (
				<div className="flex flex-col justify-center items-center lg:justify-start lg:items-start lg:flex-row space-x-10 md:pt-4">
					<div className="w-3/4 lg:w-1/2 p-6 md:p-0 md:pl-12 ">
						<Graph reportContainerWidth={reportContainerWidth} />
					</div>
					<div className="w-11/12 lg:w-1/2 p-6 md:p-0">
						<Table />
					</div>
				</div>
			)}
		</div>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getGenericVesselId(reportStore);
	const year = reportSelectors.getGenericYear(reportStore);

	const jobToGetVesselSpecificOilSpillsInWaterReport =
		reportSelectors.jobToLoadVesselSpecificOilSpillsInWaterAggregate(
			reportStore
		);
	const jobToLoadAllVesselAggregateOilSpillsInWaterReport =
		reportSelectors.jobToLoadAllVesselsOilSpillsInWaterAggregate(reportStore);

	const rawOilSpillsInWaterData =
		reportSelectors.getRawOilSpillsInWaterData(reportStore);
	const vesselSpecificRawOilSpillsInWaterData =
		reportSelectors.getRawVesselSpecificOilSpillsInWaterData(reportStore);
	return {
		year,
		vesselId,
		jobToGetVesselSpecificOilSpillsInWaterReport,
		jobToLoadAllVesselAggregateOilSpillsInWaterReport,
		rawOilSpillsInWaterData,
		vesselSpecificRawOilSpillsInWaterData,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		setVesselId(vesselId: string) {
			dispatch(reportActions.document.reportVesselIdSet(vesselId));
		},
		setYear(year: string) {
			dispatch(reportActions.document.reportYearSet(year));
		},
		loadOilSpillsInWaterAllVesselsAggregateData() {
			dispatch(reportActions.commands.oilSpillsInWaterAllVesselAggregateLoad());
		},
		loadOilSpillsInWaterVesselSpecificAggregateData() {
			dispatch(
				reportActions.commands.oilSpillsInWaterVesselSpecificAggregateLoad()
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(OilSpillsInWater);
