import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import ConfigForm from "../../_elements/ConfigForm";
import toolActions from "redux/app/tool/actions";
import { IConfigInfo } from "redux/app/tool/@types";

type OwnProps = {};
type ConfigViewProps = PropsFromRedux & OwnProps;
function ConfigView(props: ConfigViewProps) {
	useEffect(() => {
		return () => {
			props.setConfigInfo(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<ConfigForm
				action=""
				actionLabel="Config Info"
				onClose={() => props.toggleConfigViewModal()}
				type="view"
				isDisabled={true}
			/>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleConfigViewModal() {
			dispatch(toolActions.document.configViewModalToggle());
		},
		setConfigInfo(config?: IConfigInfo) {
			dispatch(toolActions.document.toolConfigInfoSet(config));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ConfigView);
