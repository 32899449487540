import React from "react";
import BallastOperationPreview from "./previews/BallastOperationPreview";
import DeballastOperationPreview from "./previews/DeballastOperationPreview";
import ExchangeOperationPreview from "./previews/ExchangeOperationPreview";
import InternalOperationPreview from "./previews/InternalOperationPreview";
import AdditionalOperationPreview from "./previews/AdditionalOperationPreview";
import ExceptionalOperationPreview from "./previews/ExceptionalOperationPreview";
import AdditionalMissedEntryPreview from "./previews/AdditionalMissedEntryPreview";
import {
	EBallastOperationCodes,
	EBallastRecordCode,
	EBallastRecordState,
	TBallastBackendRecord,
} from "redux/app/ballast/@types";
import BallastTableRows from "./BallastTableRows";
import moment from "moment";

type OwnProps = {
	ballastRecord: TBallastBackendRecord;
};
type BallastPreviewerProps = OwnProps;
function BallastPreviewer(props: BallastPreviewerProps) {
	const { ballastRecord } = props;
	const isStrikedOff = ballastRecord.state === EBallastRecordState.STRIKED_OFF;

	const isEditOfApprovedRecord = !!ballastRecord.dateOfApprovedRecordEdit;

	function renderMain() {
		if (isEditOfApprovedRecord) return renderEditedApprovedRecord();
		return renderRecord(ballastRecord, isStrikedOff);
	}

	function renderEditedApprovedRecord() {
		return (
			<>
				{renderEditCorrectionLine()}
				{renderRecord(ballastRecord, isStrikedOff)}
				{renderStruckOffVersionOfEdit()}
			</>
		);
	}

	function renderEditCorrectionLine() {
		if (!ballastRecord.dateOfApprovedRecordEdit) return null;
		const formattedEditDate = moment(
			ballastRecord.dateOfApprovedRecordEdit,
			"YYYY-MM-DD"
		).format("DD-MMM-YYYY");

		return (
			<BallastTableRows
				record={ballastRecord}
				tableRows={[
					[
						formattedEditDate,
						"-",
						"ENTRY PERTAINING TO AN EARLIER INCORRECT OPERATIONAL ENTRY",
					],
				]}
			/>
		);
	}

	function renderStruckOffVersionOfEdit() {
		if (!ballastRecord.editVersions) return null;
		const oldVersionOfRecord = {
			...ballastRecord.editVersions[0],
			state: EBallastRecordState.STRIKED_OFF,
		};
		return <BallastPreviewer ballastRecord={oldVersionOfRecord} />;
	}

	function renderRecord(
		recordToRender: TBallastBackendRecord,
		isStrikedOff: boolean = false
	) {
		switch (recordToRender.recordCode) {
			case EBallastRecordCode.BALLAST:
				return (
					<BallastOperationPreview
						ballastRecord={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EBallastRecordCode.DEBALLAST:
				return (
					<DeballastOperationPreview
						ballastRecord={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EBallastRecordCode.EXCHANGE:
				return (
					<ExchangeOperationPreview
						ballastRecord={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EBallastRecordCode.INTERNAL:
				return (
					<InternalOperationPreview
						ballastRecord={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			case EBallastRecordCode.ADDITIONAL: {
				if (
					ballastRecord.operationCode ===
					EBallastOperationCodes.ADDITIONAL_PROCEDURES
				)
					return (
						<AdditionalOperationPreview
							ballastRecord={recordToRender}
							isStrikedOff={isStrikedOff}
						/>
					);
				if (
					ballastRecord.operationCode ===
					EBallastOperationCodes.ADDITIONAL_PROCEDURES_MISSED_ENTRY
				)
					return (
						<AdditionalMissedEntryPreview
							ballastRecord={recordToRender}
							isStrikedOff={isStrikedOff}
						/>
					);
			}
			case EBallastRecordCode.EXCEPTIONAL:
				return (
					<ExceptionalOperationPreview
						ballastRecord={recordToRender}
						isStrikedOff={isStrikedOff}
					/>
				);
			default:
				return null;
		}
	}
	return renderMain();
}

export default BallastPreviewer;
