import BilgeWater from "components/app/report/waste/bilgewater/BilgeWater";
import React from "react";

type ScreenBilgeReportProps = React.PropsWithChildren<{}>;

function ScreenBilgeReport(props: ScreenBilgeReportProps) {
	return <BilgeWater />;
}

export default ScreenBilgeReport;
