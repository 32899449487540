import React, { useEffect, useState } from "react";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { permitActions } from "../../../../redux/app/approval";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import classNames from "classnames";
import moment from "moment";
import LogTemplateRenderer from "../../log/list/_elements/TemplateRenderer";

type ApprovalListProps = PropsFromRedux;
function ApprovalList(props: ApprovalListProps) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => props.loadApprovals(), []);
	const { approvals, listLoadJob } = props;

	const [logOperation, setLogOperation] = useState<{
		isModalOpen: boolean;
		logId: string;
	}>({
		isModalOpen: false,
		logId: "",
	});

	function resetOperations() {
		setLogOperation({
			...logOperation,
			isModalOpen: false,
			logId: "",
		});
	}

	function selectLog(log: any) {
		setLogOperation({
			isModalOpen: true,
			logId: log.id,
		});
		props.loadPrintTemplate(log);
	}

	return (
		<div className="min-h-full px-6 py-6 mb-6 bg-white rounded-md">
			<div
				className={classNames(
					"flex flex-row items-end justify-between mx-6 my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Approvals
					</h3>
				</div>
			</div>
			<div className="m-6">
				<table
					className={classNames(
						"w-full my-6 border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
						{ "animate-pulse": listLoadJob.isLoading }
					)}
				>
					<thead>
						<tr>
							<th className="text-sm text-gray-500">Title</th>
							<th className="text-sm text-gray-500">Author</th>
							<th className="text-sm text-gray-500">
								Vessel Name
							</th>
							<th className="text-sm text-gray-500">
								Created At
							</th>
							<th className="text-sm text-gray-500">Actions</th>
						</tr>
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
								listLoadJob.isLoading && approvals.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!listLoadJob.isLoading,
						})}
					>
						{approvals.map((approval: any) => {
							return (
								<tr key={approval.id}>
									<td className="py-1 font-medium text-gray-800">
										{approval.title}
									</td>
									<td className="font-mono text-left text-gray-700">
										{approval.author}
									</td>
									<td className="font-mono text-left text-gray-700">
										{approval.vesselName}
									</td>
									<td>
										<span className="py-1 font-mono text-gray-700">
											{moment(approval.createdAt).format(
												"MM-DD-YYYY hh:mm"
											)}
										</span>
									</td>
									<td className="-mx-1 text-left">
										<button
											className="font-medium text-blue-500 underline bg-transparent border-0 shadow-none hover:text-blue-700"
											onClick={function () {
												selectLog(approval);
											}}
										>
											View
										</button>
										<button
											className="font-medium ml-8 text-blue-500 underline bg-transparent border-0 shadow-none hover:text-blue-700"
											onClick={function () {
												props.approvePermit(approval);
											}}
										>
											Approve
										</button>
										<button
											className="font-medium ml-8 text-blue-500 underline bg-transparent border-0 shadow-none hover:text-blue-700"
											onClick={function () {
												props.declinePermit(approval);
											}}
										>
											Send Back
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot />
				</table>
				<div
					className={classNames({
						"h-auto opacity-100 transition-all duration-300 animate-pulse":
							listLoadJob.isLoading && approvals.length === 0,
						"h-0 opacity-0 transition-all duration-300 animate-pulse":
							!listLoadJob.isLoading,
					})}
				>
					<InfiniteProgress
						isLoading={listLoadJob.isLoading}
						isSpacedOut={false}
						className="-my-6"
					/>

					{listLoadJob.isLoading ? (
						<div className="flex-col justify-center h-64 py-24 my-6 bg-gray-300 ">
							<h4 className="-my-3 text-2xl text-center font-redhat-display">
								Loading please wait..
							</h4>
						</div>
					) : null}
				</div>

				{/* <PaginationBox /> */}
			</div>
			<div
				className={classNames("ws-modal h-auto", {
					"opacity-0 pointer-events-none": !logOperation.isModalOpen,
					"opacity-1": logOperation.isModalOpen,
				})}
			>
				<div className="opacity-50 ws-modal__overlay" />
				<div
					className={classNames("ws-modal__container ", {
						" pointer-events-none ": !logOperation.isModalOpen,
						"md:w-4/5 sm:w-full lg:w-3/5": !true,
						"w-full max-h-full overflow-y-auto ws-scroll": true,
					})}
				>
					{logOperation.isModalOpen ? (
						<LogTemplateRenderer
							onClose={function onClose() {
								resetOperations();
							}}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const approvalStore: any = store.app.approvalStore;
	return {
		approvals: approvalStore.approvals,
		listLoadJob: {
			isLoading: false,
		},
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadApprovals() {
			dispatch(permitActions.listLoad(true));
		},
		approvePermit(approval: any) {
			dispatch(permitActions.approvePermit(approval));
		},
		declinePermit(approval: any) {
			dispatch(permitActions.declinePermit(approval));
		},
		loadPrintTemplate(log: any) {
			dispatch(permitActions.loadPrintTemplate({ log }));
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ApprovalList);
