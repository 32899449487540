import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4I1 } from "../../../../../redux/app/orb4/groups/orb4-i";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4I1PreviewProps = OwnProps;
function ORB4I1Preview(props: ORB4I1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4I1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "I", 41, renderItem41()],
					[null, null, 42, renderItem42()],
					[null, null, 43, renderItem43()],
					[null, null, 44, renderItem44()],
					[null, null, 45, renderItem45()],
					[null, null, 46, renderItem46()],
					[null, null, 47, renderItem47()],
					[null, null, 48, renderItem48()],
					[null, null, 49, renderItem49()],
					[null, null, 50, renderItem50()],
					[null, null, 51, renderItem51()],
					[null, null, 52, renderItem52()],
					[null, null, 53, renderItem53()],
					[null, null, 54, renderItem54()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}
	function renderItem41() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item41.tanksBeingDischarged.map(
					(tank) => `${tank.selectedTank.tankName}, `
				)}
			</h4>
		);
	}
	function renderItem42() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item42Or43.from === "ENTRY OF RESIDUES"
					? `${orb4Record.item42Or43.timeOfSettling} HRS`
					: "N/A"}
			</h4>
		);
	}
	function renderItem43() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item42Or43.from === "DISCHARGE"
					? `${orb4Record.item42Or43.timeOfSettling} HRS`
					: "N/A"}
			</h4>
		);
	}
	function renderItem44() {
		return (
			<h4 className="font-medium uppercase">
				START AT {orb4Record.item44.startOfOperationTime.timeInLT} L/T{" "}
				{`(${orb4Record.item44.startOfOperationTime.timeInUTC}Z)`}
				, <br />
				LAT: {orb4Record.item44.startOfOperationPosition.latitude} LON:{" "}
				{orb4Record.item44.startOfOperationPosition.longitude}
			</h4>
		);
	}
	function renderItem45() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item45.ullageOfTotalContentsAtStartOfDischarge} MTR
			</h4>
		);
	}
	function renderItem46() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item46.ullageOfOilWaterInterfaceAtStartOfDischarge} MTR
			</h4>
		);
	}
	function renderItem47() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item47.bulkQuantityDischarged} M³,{" "}
				{orb4Record.item47.bulkQuantityRateOfDischarge} M³/HR
			</h4>
		);
	}
	function renderItem48() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item48.finalQuantityDischarged} M³,{" "}
				{orb4Record.item48.finalQuantityRateOfDischarge} M³/HR
			</h4>
		);
	}
	function renderItem49() {
		return (
			<h4 className="font-medium uppercase">
				STOP AT {orb4Record.item49.stopOfOperationTime.timeInLT} L/T (
				{`${orb4Record.item49.stopOfOperationTime.timeInUTC}Z`}
				), <br />
				LAT {orb4Record.item49.stopOfOperationPosition.latitude}, LON{" "}
				{orb4Record.item49.stopOfOperationPosition.longitude}
			</h4>
		);
	}
	function renderItem50() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item50.wasMonitoringAndControlSystemInOperation
					? "YES"
					: "NO"}
			</h4>
		);
	}
	function renderItem51() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item51.ullageOfOilWater} MTR
			</h4>
		);
	}
	function renderItem52() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item52.shipSpeedDuringDischarge} KTS
			</h4>
		);
	}
	function renderItem53() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item53.wasARegularCheckKept ? "YES" : "NO"}
			</h4>
		);
	}
	function renderItem54() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item54.allValvesClosed
					? "YES. ALL APPLICABLE VALVES CLOSED"
					: "NO"}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4I1Preview;
