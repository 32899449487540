const CHECKLIST_LIST_LOAD = "@checklist/list/LOAD"; // COMMAND ACTION
const CHECKLIST_LIST_SET = '@checklist/list/SET';
const CHECKLIST_BY_ID = '@checklist/id/UPDATE';
const DECLINE_BY_ID = '@checklist/id/UPDATEDECLINE';
const LOAD_CHECKLIST = "@checklist/LOAD";
const CHECKLIST_SELECT = "@checklist/SELECT"; // DOCUMENT ACTION
const CHECKLIST_SUBMIT = "@checklist/SUBMIT";

const CHECKLIST_UNIQUE_LIST_LOAD = "@checklist/unique-list/LOAD"; // COMMAND ACTION
const CHECKLIST_UNIQUE_LIST_SET = "@checklist/unique-list/SET"; // COMMAND ACTION

const CHECKLIST_COUNT_LOAD = "@checklist/count/LOAD"; // COMMAND ACTION
const CHECKLIST_LOAD = "@checklist/listing/LOAD"; // COMMAND ACTION
const CHECKLIST_SET = "@checklist/listing/SET"; // COMMAND ACTION
const CHECKLIST_FILTER = "@checklist/listing/FILTER"; // COMMAND ACTION

const SINGLE_CHECKLIST_LOAD = "@checklist/single/LOAD"; // COMMAND ACTION
const CHECKLIST_PRINT_TEMPLATE_RESET = "@checklist/print-template-reset"; // COMMAND ACTION
const SELECT_CHECKLIST = "@checklist/select-checklist"; // COMMAND ACTION
const CHECKLIST_PRINT_TEMPLATE_LOAD = "@checklist/print-template-load"; // COMMAND ACTION
const CHECKLIST_PRINT_TEMPLATE_SET = "@checklist/print-template-set"; // COMMAND ACTION
const CLEAR_SELECTED_CHECKLIST = "@checklist/clear-selected-checklist"; // COMMAND ACTION

const PAGINATION_TOTAL_ITEMS_SET = "@checklist/pagination/total-items/SET"; // DOCUMENT
const PAGINATION_ITEMS_PER_PAGE_SET = "@checklist/pagination/items-per-page/SET"; // DOCUMENT
const PAGINATION_CURRENT_PAGE_SET = "@checklist/pagination/current-page/SET"; // DOCUMENT
const PAGINATION_PAGE_IDS_SET = "@checklist/pagination/page-wise-ids/SET"; // DOCUMENT
const PAGINATION_PAGE_ID_RESET = "@checklist/pagination/page-wise-ids/RESET"; // DOCUMENT
const PAGINATION_PAGE_IDS_RESET = "@checklist/pagination/page-wise-ids/RESET-ALL"; // DOCUMENT
const PAGINATION_RESET = "@checklist/pagination/RESET"; // DOCUMENT

const checklistActionTypes = {
	CHECKLIST_LIST_LOAD,
	CHECKLIST_LIST_SET,
	CHECKLIST_BY_ID,
	DECLINE_BY_ID,
	LOAD_CHECKLIST,
	CHECKLIST_SELECT,
	CHECKLIST_SUBMIT,
	CHECKLIST_LOAD,
	CHECKLIST_UNIQUE_LIST_SET,
	CHECKLIST_UNIQUE_LIST_LOAD,
	CHECKLIST_COUNT_LOAD,
	SINGLE_CHECKLIST_LOAD,
	CHECKLIST_PRINT_TEMPLATE_RESET,
	SELECT_CHECKLIST,
	CHECKLIST_PRINT_TEMPLATE_LOAD,
	CHECKLIST_PRINT_TEMPLATE_SET,
	CLEAR_SELECTED_CHECKLIST,
	CHECKLIST_SET,
	CHECKLIST_FILTER,
	PAGINATION_TOTAL_ITEMS_SET,
	PAGINATION_ITEMS_PER_PAGE_SET,
	PAGINATION_CURRENT_PAGE_SET,
	PAGINATION_PAGE_IDS_SET,
	PAGINATION_PAGE_ID_RESET,
	PAGINATION_PAGE_IDS_RESET,
	PAGINATION_RESET,
};

export default checklistActionTypes;