import React from "react";
import ConfigAndPrint from "components/app/tool/configandprint";

type ScreenConfigAndPrintsProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreenConfigAndPrints(props: ScreenConfigAndPrintsProps) {
	return <ConfigAndPrint />;
}

export default ScreenConfigAndPrints;
