import actionTypes from "./action-types";
import { jobActions } from "../../_core/job";
import { createAction } from "typesafe-actions";
import { IChecklist, IChecklistFilters, ITabChecklist } from "./types";
import { IConfigListItem } from "../tool/@types";
import checklistConstants from "./constants";
import { formActions } from "redux/_core/form";

const {
	CHECKLIST_LIST_LOAD,
	CHECKLIST_LIST_SET,
	CHECKLIST_BY_ID,
	DECLINE_BY_ID,
	LOAD_CHECKLIST,
	CHECKLIST_SELECT,
	CHECKLIST_SUBMIT,
	CHECKLIST_LOAD,
	CHECKLIST_UNIQUE_LIST_SET,
	CHECKLIST_UNIQUE_LIST_LOAD,
	CHECKLIST_COUNT_LOAD,
	SINGLE_CHECKLIST_LOAD,
	CHECKLIST_PRINT_TEMPLATE_RESET,
	CHECKLIST_PRINT_TEMPLATE_SET,
	SELECT_CHECKLIST,
	CHECKLIST_PRINT_TEMPLATE_LOAD,
	CLEAR_SELECTED_CHECKLIST,
	CHECKLIST_SET,
	CHECKLIST_FILTER,
	PAGINATION_TOTAL_ITEMS_SET,
	PAGINATION_ITEMS_PER_PAGE_SET,
	PAGINATION_CURRENT_PAGE_SET,
	PAGINATION_PAGE_IDS_SET,
	PAGINATION_PAGE_ID_RESET,
	PAGINATION_PAGE_IDS_RESET,
	PAGINATION_RESET,
} = actionTypes;

const checklistActions = {
	listLoad: createAction(CHECKLIST_LIST_LOAD, (list: any) => list)(),
	listSet: createAction(CHECKLIST_LIST_SET, (checklists: any) => ({
		checklists,
	}))(),
	checklistSelect: createAction(CHECKLIST_SELECT, (checklist: any) => ({
		checklist,
	}))(),
	checklistSubmit: createAction(CHECKLIST_SUBMIT, (isSubmit: boolean) => ({
		isSubmit,
	}))(),
	updateChecklist: createAction(
		CHECKLIST_BY_ID,
		(checklist: any) => checklist
	)(),
	declineChecklist: createAction(DECLINE_BY_ID, (decline: any) => decline)(),
	loadChecklist: createAction(LOAD_CHECKLIST, (log: any) => log)(),

	clearSelectedChecklist: createAction(CLEAR_SELECTED_CHECKLIST, () => ({
		log: null,
	}))(),
	checklistPrintTemplateSet: createAction(
		CHECKLIST_PRINT_TEMPLATE_SET,
		(printTemplate: any) => ({ printTemplate })
	)(),
	checklistPrintTemplateLoad: createAction(
		CHECKLIST_PRINT_TEMPLATE_LOAD,
		(vesselId: string, code?: string, recordConfigVersion?: string) => ({
			vesselId,
			code,
			recordConfigVersion,
		})
	)(),
	selectChecklist: createAction(
		SELECT_CHECKLIST,
		(checklist: IChecklist) => ({
			checklist,
		})
	)(),
	checklistPrintTemplateReset: createAction(
		CHECKLIST_PRINT_TEMPLATE_RESET,
		() => ({})
	)(),
	// Checklist Load
	singleChecklistLoad: createAction(
		SINGLE_CHECKLIST_LOAD,
		(checklist: ITabChecklist, vesselId: string) => ({
			checklist,
			vesselId,
		})
	)(),
	checklistCountLoad: createAction(
		CHECKLIST_COUNT_LOAD,
		(vesselId: string) => ({
			vesselId,
		})
	)(),
	checklistLoad: createAction(
		CHECKLIST_LOAD,
		({
			pageNumber = 1,
			showPageNumberInNotification = false,
			vesselId,
		}: {
			pageNumber: number;
			showPageNumberInNotification: boolean;
			vesselId: string;
		}) => ({
			pageNumber,
			showPageNumberInNotification,
			vesselId,
		})
	)(),
	checklistSet: createAction(
		CHECKLIST_SET,
		(checklists: ITabChecklist[]) => ({
			checklists,
		})
	)(),
	loadChecklistUniqueList: createAction(
		CHECKLIST_UNIQUE_LIST_LOAD,
		(vesselId: string) => ({
			vesselId,
		})
	)(),
	checklistUniqueListSet: createAction(
		CHECKLIST_UNIQUE_LIST_SET,
		(checklistUniqueList: IConfigListItem[]) => ({
			checklistUniqueList,
		})
	)(),
	checklistFilter: createAction(
		CHECKLIST_FILTER,
		(filters: IChecklistFilters) => ({
			filters,
		})
	)(),

	// Pagination
	paginationTotalItemsSet: createAction(
		PAGINATION_TOTAL_ITEMS_SET,
		(totalItems: number) => ({ totalItems })
	)(),
	paginationItemsPerPageSet: createAction(
		PAGINATION_ITEMS_PER_PAGE_SET,
		(itemsPerPage: number) => ({ itemsPerPage })
	)(),
	paginationCurrentPageSet: createAction(
		PAGINATION_CURRENT_PAGE_SET,
		(currentPage: number) => ({ currentPage })
	)(),
	paginationPageIdsSet: createAction(
		PAGINATION_PAGE_IDS_SET,
		({
			pageNumber,
			itemIds,
		}: {
			pageNumber: number;
			itemIds: string[];
		}) => ({
			pageNumber,
			itemIds,
		})
	)(),
	paginationPageIdsResetAll: createAction(PAGINATION_PAGE_IDS_RESET)(),
	paginationPageIdReset: createAction(
		PAGINATION_PAGE_ID_RESET,
		(pageNumber: number) => ({ pageNumber })
	)(),
	paginationReset: createAction(PAGINATION_RESET)(),

	_jobs: {
		checklistListLoad: jobActions("@checklist", CHECKLIST_LIST_LOAD),
		checklistLoad: jobActions("@checklist", CHECKLIST_LOAD),
		singleChecklistLoad: jobActions("@checklist", SINGLE_CHECKLIST_LOAD),
		checklistPrintTemplateLoad: jobActions(
			"@checklist",
			CHECKLIST_PRINT_TEMPLATE_LOAD
		),
		checklistUniqueList: jobActions("@checklist", CHECKLIST_UNIQUE_LIST_LOAD),
		checklistFilter: jobActions("@checklist", CHECKLIST_FILTER),
	},
	_forms: {
		filter: formActions<IChecklistFilters>(
			checklistConstants.forms.FILTER,
			checklistConstants.feature
		),
	},
};

export default checklistActions;