import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupD2 } from "../../../../../redux/app/annex6/groups/group-d";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import CommaSeparator from "components/_common/CommaSeparator";
import { commonUtils } from "redux/_common";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupD2PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupD2Preview(props: Annex6GroupD2PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupD2;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'D', 7.2, renderItem7P2()],
                    [null, null, 7.3, renderItem7P3()],
                    [null, null, 7.4, renderItem7P4()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem7P2(): React.ReactNode {
        if (!record.item7P2.equipmentsUsed || !record.item7P2.changingFrom)
            return <Annex6OldVersionPreview />;

        return (
            <>
                {moment(record.selectedDate, 'YYYY-MM-DD').format(
                    'DD-MMM-YYYY'
                )}{' '}
                AT {record.item7P2.time} LT COMPLETED CHANGEOVER OF{' '}
                <CommaSeparator
                    items={record.item7P2.equipmentsUsed.map((equipment) =>
                        equipment.split('_').join(' ')
                    )}
                    lastItemSeperator={' & '}
                />{' '}
                FROM {record.item7P2.changingFrom.fuelType} (
                {record.item7P2.changingFrom.sulphurContent}% S) TO{' '}
                {record.item7P2.changingTo.fuelType}(
                {record.item7P2.changingTo.sulphurContent}% S), <br />
                POSITION: {record.item7P2.position.latitude},{' '}
                {record.item7P2.position.longitude}
            </>
        );
    }

    function renderItem7P3() {
        if (!record.item7P2.changingFrom || !record.item7P2.changingTo)
            return <Annex6OldVersionPreview />;

        const changingToTanks = record.item7P3.tanks.filter(
            (tank) =>
                tank.selectedFuelType === record.item7P2.changingTo.fuelType
        );
        const changingToQuantityTotal = changingToTanks.reduce(
            (total, tank) =>
                commonUtils.addUp(total, tank.retainedQuantityInMT),
            0
        );

        const changingFromTanks = record.item7P3.tanks.filter(
            (tank) =>
                tank.selectedFuelType === record.item7P2.changingFrom.fuelType
        );
        const changingFromQuantityTotal = changingFromTanks.reduce(
            (total, tank) =>
                commonUtils.addUp(total, tank.retainedQuantityInMT),
            0
        );

        const otherTanks = record.item7P3.tanks.filter(
            (tank) => tank.selectedFuelType === 'UNCATEGORIZED'
        );
        const otherTanksQuantityTotal = otherTanks.reduce(
            (total, tank) =>
                commonUtils.addUp(total, tank.retainedQuantityInMT),
            0
        );

        const isChangingFromTanksVisible = changingFromQuantityTotal > 0;
        const isChangingToTanksVisible = changingToQuantityTotal > 0;
        const isUncategorizedTanksVisible = otherTanksQuantityTotal > 0;

        return (
            <>
                {isChangingFromTanksVisible ? (
                    <>
                        TOTAL {record.item7P2.changingFrom.fuelType} ROB ON
                        COMPLETION OF CHANGE OVER {changingFromQuantityTotal}
                        MT <br />
                        <CommaSeparator
                            items={changingFromTanks.map(
                                (tank) =>
                                    `${tank.selectedTank.tankName} - ${tank.retainedQuantityInMT}MT`
                            )}
                            customSeperator={<br />}
                        />
                        <br />
                        <br />
                    </>
                ) : null}
                {isChangingToTanksVisible ? (
                    <>
                        TOTAL {record.item7P2.changingTo.fuelType} ROB ON
                        COMPLETION OF CHANGE OVER {changingToQuantityTotal}MT{' '}
                        <br />
                        <CommaSeparator
                            items={changingToTanks.map(
                                (tank) =>
                                    `${tank.selectedTank.tankName} - ${tank.retainedQuantityInMT}MT`
                            )}
                            customSeperator={<br />}
                        />
                    </>
                ) : null}
                {isUncategorizedTanksVisible ? (
                    <>
                        {otherTanks.length > 0 ? (
                            <>
                                <br />
                                <br />
                                TOTAL UNCATEGORIZED ROB ON COMPLETION OF CHANGE
                                OVER {otherTanksQuantityTotal}MT <br />
                                <CommaSeparator
                                    items={otherTanks.map(
                                        (tank) =>
                                            `${tank.selectedTank.tankName} - ${tank.retainedQuantityInMT}MT`
                                    )}
                                    customSeperator={<br />}
                                />
                            </>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    function renderItem7P4(): React.ReactNode {
        if (record.item7P4) {
            return (
                <>
                    {record.item7P4.time} HOURS LT VESSEL ENTRY INTO SECA,{' '}
                    <br /> POSITION: {record.item7P4.position.latitude} |{' '}
                    {record.item7P4.position.longitude}
                </>
            );
        } else {
            return <>NA</>;
        }
    }

    return renderMain();
}

export default Annex6GroupD2Preview;
