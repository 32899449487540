import cuid from "cuid";
import moment from "moment";
import {
	EGarbageRecordState,
	IGarbage,
	IGarbageRecord,
	IGarbageRecordNewOperationState,
	TGarbageEntries,
	TGarbageQuestion,
} from "./@types";
import { EMARPOLDocuments } from "../marpol/@types";

const garbageRecordUtils = {
	getGMTS() {
		return [
			{
				label: "UTC	GMT",
				value: "GMT",
			},
			{
				label: "ECT	GMT+1:00",
				value: "GMT+1:00",
			},
			{
				label: "EET	GMT+2:00",
				value: "GMT+2:00",
			},
			{
				label: "ART	GMT+2:00",
				value: "GMT+2:00",
			},
			{
				label: "EAT	GMT+3:00",
				value: "GMT+3:00",
			},
			{
				label: "MET	GMT+3:30",
				value: "GMT+3:30",
			},
			{
				label: "NET	GMT+4:00",
				value: "GMT+4:00",
			},
			{
				label: "PLT	GMT+5:00",
				value: "GMT+5:00",
			},
			{
				label: "IST	GMT+5:30",
				value: "GMT+5:30",
			},
			{
				label: "BST	GMT+6:00",
				value: "GMT+6:00",
			},
			{
				label: "VST	GMT+7:00",
				value: "GMT+7:00",
			},
			{
				label: "CTT	GMT+8:00",
				value: "GMT+8:00",
			},
			{
				label: "JST	GMT+9:00",
				value: "GMT+9:00",
			},
			{
				label: "ACT	GMT+9:30",
				value: "GMT+9:30",
			},
			{
				label: "AET	GMT+10:00",
				value: "GMT+10:00",
			},
			{
				label: "SST	GMT+11:00",
				value: "GMT+11:00",
			},
			{
				label: "NST	GMT+12:00",
				value: "GMT+12:00",
			},
			{
				label: "MIT	GMT-11:00",
				value: "GMT-11:00",
			},
			{
				label: "HST	GMT-10:00",
				value: "GMT-10:00",
			},
			{
				label: "AST	GMT-9:00",
				value: "GMT-9:00",
			},
			{
				label: "PST	GMT-8:00",
				value: "GMT-8:00",
			},
			{
				label: "PNT	GMT-7:00",
				value: "GMT-7:00",
			},
			{
				label: "MST	GMT-7:00",
				value: "GMT-7:00",
			},
			{
				label: "CST	GMT-6:00",
				value: "GMT-6:00",
			},
			{
				label: "EST	GMT-5:00",
				value: "GMT-5:00",
			},
			{
				label: "IET	GMT-5:00",
				value: "GMT-5:00",
			},
			{
				label: "PRT	GMT-4:00",
				value: "GMT-4:00",
			},
			{
				label: "CNT	GMT-3:30",
				value: "GMT-3:30",
			},
			{
				label: "AGT	GMT-3:00",
				value: "GMT-3:00",
			},
			{
				label: "BET	GMT-3:00",
				value: "GMT-3:00",
			},
			{
				label: "CAT	GMT-1:00",
				value: "GMT-1:00",
			},
		];
	},
	getDischargeCategories(
		garbageEntry?: TGarbageEntries,
		isEPS: boolean = false
	): IGarbage[] {
		switch (garbageEntry) {
			case "GARBAGE_DISCHARGED_INTO_SEA":
				return [
					{
						label: "Food wastes",
						estimatedAmountDischarged: 0,
						value: "B",
						category: "B",
					},
					{
						label: "Operational wastes",
						estimatedAmountDischarged: 0,
						value: "F",
						category: "F",
					},
					{
						label: "Animal Carcass(es)",
						estimatedAmountDischarged: 0,
						value: "G",
						category: "G",
					},
				];
			case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
				return [
					{
						label: "Plastics",
						estimatedAmountDischarged: 0,
						value: "A",
						category: "A",
					},
					{
						label: "Food wastes",
						estimatedAmountDischarged: 0,
						value: "B",
						category: "B",
					},
					{
						label: "Domestic waste",
						estimatedAmountDischarged: 0,
						value: "C",
						category: "C",
					},
					{
						label: "Cooking Oil",
						estimatedAmountDischarged: 0,
						value: "D",
						category: "D",
					},
					{
						label: "Incinerator ashes",
						estimatedAmountDischarged: 0,
						value: "E",
						category: "E",
					},
					{
						label: "Operational wastes",
						estimatedAmountDischarged: 0,
						value: "F",
						category: "F",
					},
					{
						label: "Animal Carcass(es)",
						estimatedAmountDischarged: 0,
						value: "G",
						category: "G",
					},
					{
						label: "Fishing Gear",
						estimatedAmountDischarged: 0,
						value: "H",
						category: "H",
					},
					{
						label: "E-waste",
						estimatedAmountDischarged: 0,
						value: "I",
						category: "I",
					},
				];
			case "GARBAGE_INCINERATED": {
				if (isEPS)
					return [
						{
							label: "Plastics",
							estimatedAmountDischarged: 0,
							value: "A",
							category: "A",
						},
						{
							label: "Food wastes",
							estimatedAmountDischarged: 0,
							value: "B",
							category: "B",
						},
						{
							label: "Domestic waste",
							estimatedAmountDischarged: 0,
							value: "C",
							category: "C",
						},
						{
							label: "Cooking Oil",
							estimatedAmountDischarged: 0,
							value: "D",
							category: "D",
						},
						{
							label: "Operational wastes",
							estimatedAmountDischarged: 0,
							value: "F",
							category: "F",
						},
					];
				return [
					{
						label: "Plastics",
						estimatedAmountDischarged: 0,
						value: "A",
						category: "A",
					},
					{
						label: "Food wastes",
						estimatedAmountDischarged: 0,
						value: "B",
						category: "B",
					},
					{
						label: "Domestic waste",
						estimatedAmountDischarged: 0,
						value: "C",
						category: "C",
					},
					{
						label: "Operational wastes",
						estimatedAmountDischarged: 0,
						value: "F",
						category: "F",
					},
				];
			}
			case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
			default:
				return [
					{
						label: "Plastics",
						estimatedAmountDischarged: 0,
						value: "A",
						category: "A",
					},
					{
						label: "Food wastes",
						estimatedAmountDischarged: 0,
						value: "B",
						category: "B",
					},
					{
						label: "Domestic waste",
						estimatedAmountDischarged: 0,
						value: "C",
						category: "C",
					},
					{
						label: "Cooking Oil",
						estimatedAmountDischarged: 0,
						value: "D",
						category: "D",
					},
					{
						label: "Incinerator ashes",
						estimatedAmountDischarged: 0,
						value: "E",
						category: "E",
					},
					{
						label: "Operational wastes",
						estimatedAmountDischarged: 0,
						value: "F",
						category: "F",
					},
					{
						label: "Animal Carcass(es)",
						estimatedAmountDischarged: 0,
						value: "G",
						category: "G",
					},
					{
						label: "Fishing Gear",
						estimatedAmountDischarged: 0,
						value: "H",
						category: "H",
					},
					{
						label: "E-waste",
						estimatedAmountDischarged: 0,
						value: "I",
						category: "I",
					},
				];
		}
	},
	getDefaultOperationData(): IGarbageRecordNewOperationState {
		return {
			newOperationState: "RECORD",
			garbageBasicInfo: {
				marpolType: EMARPOLDocuments.GRB1,
				selectedDate: moment().format("YYYY-MM-DD"),
				id: cuid(),
				_rev: "",
				utcTimestamp: moment().format("HH:mm"),
				entry: "GARBAGE_DISCHARGED_INTO_SEA",
				state: EGarbageRecordState.IDLE,
				editLogs: {},
				numberOfEditSessions: 0,
			},
			garbageIncinerated: {
				entry: "GARBAGE_INCINERATED",
				endOfOperation: {
					date: moment().format("YYYY-MM-DD"),
					time: moment().format("HH:mm"),
				},
				// garbage: {
				//     category: '',
				//     estimatedAmountDischarged: 0,
				//     label: '',
				//     value: '0',
				// },
				garbage: [],
				locationOfStartOfIncineration: {
					latitude: "",
					longitude: "",
				},
				locationOfCompletionOfIncineration: {
					latitude: "",
					longitude: "",
				},
				remark: "",
			},
			garbageAccidentalLoss: {
				entry: "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA",
				depthOfWater: 0,
				endOfOperation: {
					date: moment().format("YYYY-MM-DD"),
					time: moment().format("HH:mm"),
				},
				locationOfOccurance: "SEA",
				locationOfDischarge: {
					latitude: "",
					longitude: "",
				},
				garbage: [],
				// garbage: {
				//     category: '',
				//     estimatedAmountDischarged: 0,
				//     label: '',
				//     value: '0',
				// },
				remark: "",
			},
			garbageDischargedIntoSea: {
				entry: "GARBAGE_DISCHARGED_INTO_SEA",
				locationOfStartOfDischarge: {
					latitude: "",
					longitude: "",
				},
				garbage: [],
				remark: "",
			},
			garbageDischargedToReceptionAshoreOrOtherShips: {
				entry: "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
				placeOfDischarge: "",
				garbage: [],
				remark: "",
				receiptNumber: "",
				receiptAttachment: "",
			},
		};
	},
	convertBackendRecordToOperationState(
		garbageRecord: IGarbageRecord
	): IGarbageRecordNewOperationState {
		const defaultNewOperationState =
			garbageRecordUtils.getDefaultOperationData();
		return {
			...defaultNewOperationState,
			garbageBasicInfo: {
				state: EGarbageRecordState.IDLE,
				marpolType: EMARPOLDocuments.GRB1,
				selectedDate: garbageRecord.selectedDate, // moment().format('YYYY-MM-DD'),
				id: garbageRecord.id, // cuid(),
				_rev: garbageRecord._rev,
				utcTimestamp: garbageRecord.utcTimestamp, // moment().format('HH:mm'),
				entry: garbageRecord.entry, // 'GARBAGE_INCINERATED',
				editLogs: garbageRecord.editLogs, // [],
				numberOfEditSessions: garbageRecord.numberOfEditSessions + 1,
			},
			garbageAccidentalLoss:
				garbageRecord.entry === "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA"
					? {
							entry: "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA",
							depthOfWater: garbageRecord.depthOfWater, // 0,
							endOfOperation: garbageRecord.endOfOperation, // "",
							locationOfOccurance: garbageRecord.locationOfOccurance, // 'SEA',
							locationOfDischarge: garbageRecord.locationOfDischarge, // "",
							garbage: garbageRecord.garbage, // "",
							remark: garbageRecord.remark, // '',
					  }
					: defaultNewOperationState.garbageAccidentalLoss,
			garbageDischargedIntoSea:
				garbageRecord.entry === "GARBAGE_DISCHARGED_INTO_SEA"
					? {
							entry: "GARBAGE_DISCHARGED_INTO_SEA",
							locationOfStartOfDischarge:
								garbageRecord.locationOfStartOfDischarge, // '',
							garbage: garbageRecord.garbage, // '',
							remark: garbageRecord.remark, // '',
					  }
					: defaultNewOperationState.garbageDischargedIntoSea,
			garbageDischargedToReceptionAshoreOrOtherShips:
				garbageRecord.entry ===
				"GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS"
					? {
							entry: "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
							placeOfDischarge: garbageRecord.placeOfDischarge, // '',
							garbage: garbageRecord.garbage, // "",
							remark: garbageRecord.remark, // '',
							receiptNumber: garbageRecord.receiptNumber,
							receiptAttachment: garbageRecord.receiptAttachment,
					  }
					: defaultNewOperationState.garbageDischargedToReceptionAshoreOrOtherShips,
			garbageIncinerated:
				garbageRecord.entry === "GARBAGE_INCINERATED"
					? {
							entry: "GARBAGE_INCINERATED",
							endOfOperation: garbageRecord.endOfOperation, // '',
							garbage: garbageRecord.garbage, // '',
							locationOfStartOfIncineration:
								garbageRecord.locationOfStartOfIncineration, // '',
							locationOfCompletionOfIncineration:
								garbageRecord.locationOfCompletionOfIncineration, // '',
							remark: garbageRecord.remark, // '',
					  }
					: defaultNewOperationState.garbageIncinerated,
		};
	},
	getOperationToSubmit(
		garbageRecord: IGarbageRecord
	): IGarbageRecordNewOperationState {
		return {
			...garbageRecordUtils.convertBackendRecordToOperationState(garbageRecord),
			garbageBasicInfo: {
				...garbageRecordUtils.convertBackendRecordToOperationState(
					garbageRecord
				).garbageBasicInfo,
				state: EGarbageRecordState.IDLE,
				editLogs: {},
				numberOfEditSessions: 0,
			},
		};
	},
	getOperationForEdit(
		garbageRecord: IGarbageRecord
	): IGarbageRecordNewOperationState {
		const garbageRecordToEditState =
			garbageRecordUtils.convertBackendRecordToOperationState(garbageRecord);
		garbageRecordToEditState.garbageBasicInfo.state = EGarbageRecordState.EDIT;
		return garbageRecordToEditState;
	},
	getGarbageDischargedIntoSeaQuestions(
		garbage?: IGarbage
	): TGarbageQuestion | undefined {
		if (!garbage) return undefined;
		switch (garbage.category) {
			case "B": {
				return {
					question: "Is the food waste comminuted or grounded?",
					YES: {
						question: "Are you outside special areas and Arctic waters?",
						YES: {
							question: "Are you >3 nm, en route and as far as practicable?",
							YES: "ALLOWED",
							NO: "BLOCKED",
						},
						NO: {
							question: "Are you within special areas and Arctic waters?",
							YES: {
								question: "Are you >12 nm, en route and as far as practicable?",
								YES: "ALLOWED",
								NO: "BLOCKED",
							},
							NO: "BLOCKED",
						},
					},
					NO: {
						question: "Are you outside special areas and Arctic waters?",
						YES: {
							question: "Are you >12 nm, en route and as far as practicable?",
							YES: "ALLOWED",
							NO: "BLOCKED",
						},
						NO: "BLOCKED",
					},
				};
			}
			case "F": {
				return {
					question: "Is it cleaning agents and additives?",
					YES: {
						question: "Is it contained in cargo hold wash water?",
						YES: {
							question: "Are you outside special areas and Arctic waters?",
							YES: "ALLOWED",
							NO: {
								question: "Are you within special areas and Arctic waters?",
								YES: {
									question:
										"Are you >12 nm, en route and as far as practicable (subject to conditions in regulation 6.1.2 and paragraph 5.2.1.5 of Part II-A of the Polar Code)",
									YES: "ALLOWED",
									NO: "BLOCKED",
								},
								NO: "BLOCKED",
							},
						},
						NO: {
							question: "Is it in deck and external surfaces washwater?",
							YES: {
								question: "Are you outside special areas and Arctic waters?",
								YES: "ALLOWED",
								NO: {
									question: "Are you within special areas and Arctic waters?",
									YES: "ALLOWED",
									NO: "BLOCKED",
								},
							},
							NO: "BLOCKED",
						},
					},
					NO: "BLOCKED",
				};
			}
			case "G": {
				return {
					question: "Are you outside special areas and Arctic waters?",
					YES: {
						question: `Are you en route and as far from the nearest land as possible. Should be >100 nm and maximum water depth?`,
						YES: "ALLOWED",
						NO: "BLOCKED",
					},
					NO: {
						question: "Are you within special areas and Arctic waters?",
						YES: "BLOCKED",
						NO: "BLOCKED",
					},
				};
			}
			case "J": {
				return {
					question: "Is it cleaning agents and additives?",
					YES: "ALLOWED",
					NO: "BLOCKED",
				};
			}
		}
	},
	isUndefined(someValue: any) {
		return someValue === undefined;
	},
};
export default garbageRecordUtils;
