import React from "react";
import Button from "../../@set-product/core/button";
import { IJobState } from "../../redux/_core/job/types";
import classNames from "classnames";
type ConfirmBoxProps = React.PropsWithChildren<{
	title: string;
	description: string;
	state: IJobState;
	primaryText: string;
	secondaryText: string;
	onPrimaryAction(): void;
	onSecondaryAction?: () => void;
}>;
function ConfirmBox(props: ConfirmBoxProps) {
	const {
		description,
		primaryText,
		secondaryText,
		state,
		title,
		onSecondaryAction,
	} = props;
	return (
		<div className="p-6 pointer-events-auto">
			<div className="w-64 max-w-md">
				<div className="box-title">
					<h3 className="text-lg font-medium text-gray-800">{title}</h3>
				</div>
				<div className="my-3 text-gray-700 box-descriptions font-sm">
					{description}
				</div>
				<div className="flex flex-row items-baseline justify-end mt-2 -mx-2 -mb-2 box-action">
					<button
						onClick={() => onSecondaryAction && onSecondaryAction()}
						className="px-6 py-2 mx-2 text-gray-700 bg-white border-none rounded-sm shadow-none ws-input ws-button"
					>
						{secondaryText}
					</button>
					<button
						className="px-6 py-2 mx-2 text-white bg-red-500 rounded-md ws-input ws-button"
						onClick={props.onPrimaryAction}
					>
						{primaryText}
					</button>
				</div>
			</div>
		</div>
	);
}

export default ConfirmBox;
