import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import SVGMapShip from "components/_common/icons/SVGMapShip";
import moment from "moment";
import { EVESSEL_ALERTS_STATUS, IVesselAlerts } from "redux/app/vessel/types";
import {
	getShipIcon,
	transformAlertsAndUsageForMap,
} from "redux/app/dashboard/utils";
import RenderPopupAlertAndUsageContent from "./_elements/RenderPopupAlertAndUsageContent";
import dashboardConstants from "redux/app/dashboard/constants";

const Map = (props: {
	selectedShip: any;
	setSelectedShip: (selectedShip: any) => void;
	setShowShipDetails: (showShipDetails: boolean) => void;
	vesselLocations: any;
}) => {
	const worldCenter: L.LatLngExpression = [0, 0]; // Center coordinates for a zoomed-out world view
	const defaultZoom = 3; // Adjust the default zoom level as desired

	const locations = props.vesselLocations || [];

	// [
	// 	{ id: 1, latitude: 37.7749, longitude: -122.4194 },
	// 	{ id: 2, latitude: 34.0522, longitude: -118.2437 },
	// 	{ id: 3, latitude: 40.7128, longitude: -74.006 },
	// ];

	// const [selectedShip, setSelectedShip] = React.useState(null);

	const handleMarkerClick = (ship: {
		id: string;
		latitude: number;
		longitude: number;
	}) => {
		props.setSelectedShip(ship);
		props.setShowShipDetails(true);
	};

	const handlePopupClose = () => {
		props.setSelectedShip(null);
	};

	return (
		// <MapContainer
		// 	center={position}
		// 	zoom={13}
		// 	style={{ height: "100vh", width: "100%" }}
		// >
		// 	<TileLayer
		// 		url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		// 		attribution="© OpenStreetMap contributors"
		// 	/>
		// </MapContainer>

		<MapContainer
			center={worldCenter}
			zoom={defaultZoom}
			style={{ height: "100%", width: "100%" }}
			// touchZoom={false}
			// scrollWheelZoom={false}
			// doubleClickZoom={false}
			// dragging={false}
			// trackResize={false}
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				// url="https://api.mapbox.com/styles/v1/raghuramvolteo/cljpbpm86002c01qo37u031o2/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFnaHVyYW12b2x0ZW8iLCJhIjoiY2xqcGMzbjkwMDdrMDNkcDd2djJ6ZGdmdSJ9.Gjn4gFrnWb4rAVqJ32jOeg"
				attribution="© OpenStreetMap contributors"
			/>
			{locations.map(
				(location: {
					id: string;
					latitude: any;
					longitude: any;
					name: string;
					last_location: any;
					last_synced: any;
					from_to: string;
					status: EVESSEL_ALERTS_STATUS;
					observations: IVesselAlerts["observations"];
				}) => {
					const { listOfAlerts, listOfUsage } =
						transformAlertsAndUsageForMap(location.observations);

					return (
						<Marker
							key={location.id}
							position={[location.latitude, location.longitude]}
							icon={getShipIcon(location.status)}
							eventHandlers={{
								// click: () => handleMarkerClick(location),
								click: (e) => e.target.openPopup(),
								// mouseover: (e) => {
								// 	e.target.openPopup();
								// },
								// mouseout: (e) => {
								// 	e.target.closePopup();
								// },
							}}
						>
							<Popup
							// position={[location.latitude, location.longitude]}
							// onClose={handlePopupClose}
							>
								<div className="w-full -p-4">
									<div className="flex justify-center w-full">
										<div
											style={{
												backgroundColor:
													dashboardConstants.STATUS[
														location?.status
													] ??
													dashboardConstants.STATUS
														.GOOD,
											}}
											className="h-[3px]  -mt-[14px] w-full rounded-bl-md rounded-br-md"
										/>
									</div>
									<div className="flex space-x-4">
										<SVGMapShip />
										<div className="flex flex-col">
											<h2 className="text-base font-medium font-redhat-text">
												{location?.name}
											</h2>
											<p className="text-[12px] m-0">
												{location?.from_to}
											</p>
										</div>
									</div>

									{listOfAlerts.length > 0 ? (
										<div className="mt-2">
											<h4 className="text-[#888888] text-xs uppercase font-medium leading-3 tracking-wide mb-0.5">
												Alerts
											</h4>
											<RenderPopupAlertAndUsageContent
												content={listOfAlerts}
											/>
										</div>
									) : null}
									{listOfUsage.length > 0 ? (
										<div className="mt-2">
											<h4 className="text-[#888888] text-xs uppercase font-medium leading-3 tracking-wide mb-0.5">
												Usage
											</h4>
											<RenderPopupAlertAndUsageContent
												content={listOfUsage}
											/>
										</div>
									) : null}
									{/* <h2>Name: {location?.name}</h2>
									<h2>Raw Position: {JSON.stringify(location?.last_location)}</h2>
									<h2>
										Calculated Position: {location?.latitude}{" "}
										{location?.longitude}
									</h2> */}
									{location?.last_synced && (
										<h6 className="mt-2 text-xs">
											Last Synced:{" "}
											{moment(
												location?.last_synced
											).format("DD MMM, YY HH:mm A")}
										</h6>
									)}
									{/* <h2>From-To: {location?.from_to}</h2> */}
								</div>
							</Popup>
						</Marker>
					);
				}
			)}
		</MapContainer>
	);
};

export default Map;
