import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { IData } from '.';
import Category from './Category';
import Metric from './Metric';
import Section from './Section';
import Range from './Range';
import Remarks from './Remarks';
import Buttons from './Buttons';
import Modules from './Modules';
import SubModules from './SubModules';
import { ERANGE_MGMT_TYPES, ESIGNAL_REPORT_TYPE_VAL, ILocalRange } from 'redux/app/vessel/onboard/types';
import { ModalType } from '../Ranges';
import Tab from './Tab';

interface IAddModalProps {
    data: IData;
    logCategories: any;
    sections: React.MutableRefObject<{
        [key: string]: any;
    }>;
    categories: React.MutableRefObject<{
        [key: string]: any;
    }>;
    setData: React.Dispatch<React.SetStateAction<IData>>;
    onCancel: () => void;
    onSubmit: (localRange?: ILocalRange) => void;
	tabbedSections: Record<string,string[]>;
    dropdownType: ERANGE_MGMT_TYPES;
    signalReportType?: ESIGNAL_REPORT_TYPE_VAL
}
const AddModal: FC<IAddModalProps> = ({
    categories,
    data,
    logCategories,
    onCancel,
    onSubmit,
    sections,
    setData,
	tabbedSections,
    dropdownType,
    signalReportType
}) => {
    const modules = useRef<{ [key: string]: any }>({});
    const subModules = useRef<{ [key: string]: any }>({});




    const metrics = useMemo(() => {
        if (data.subModules.value) {
            return subModules.current[data.subModules.value]?.metrics?.reduce(
                (prev: any, metric: any) => {
                    if (metric?.readingRange?.dataType === 'integer') {
                        prev.push(metric);
                    }
                    return prev;
                },
                []
            );
        } else if (data.modules.value) {
            return modules.current[data.modules.value]?.metrics?.reduce(
                (prev: any, metric: any) => {
                    if (metric?.readingRange?.dataType === 'integer') {
                        prev.push(metric);
                    }
                    return prev;
                },
                []
            );
        } else {
            return sections.current[data.section.value]?.metrics?.reduce(
                (prev: any, metric: any) => {
                    if (metric?.readingRange?.dataType === 'integer') {
                        prev.push(metric);
                    }
                    return prev;
                },
                []
            );
        }
    }, [data.section.value, data.modules.value, data.subModules.value]);

    useEffect(() => {
        if (data.section.value) {
            sections.current[data.section.value]?.modules?.forEach((m: any) => {
                modules.current[m?.mnemonic || m?.title] = m;
            });
        }
    }, [data.section.value]);

    useEffect(() => {
        if (data.modules.value) {
            modules.current[data.modules.value]?.subModules?.forEach((m: any) => {
                subModules.current[m?.mnemonic || m?.title] = m;
            });
        }
    }, [data.modules.value]);




    const [rangeErrorMsg, setRangeErrorMsg] = useState('');
	const tabOptions = tabbedSections[data?.category?.value]
    const isTabActive = !!tabOptions?.length
    return (
        <>
            <Category
                data={data}
                logCategories={logCategories}
                setData={setData}
            />
			{/* isTabActive checks if there are tabs like in Aux details, Machinery In Operation in Steaming report */}
            {tabbedSections && isTabActive ? (
                <Tab
                    data={data}
                    setData={setData}
                    tabbedSections={tabbedSections}
                    tabOptions={tabOptions?.map((opt) => ({
                        label: opt,
                        value: opt,
                    }))}
                />
            ) : null}
            <Section data={data} categories={categories} setData={setData} />
            <Modules
                data={data}
                modules={sections.current[data.section.value]?.modules}
                setData={setData}
            />
            <SubModules
                data={data}
                subModules={modules.current[data.modules.value]?.subModules}
                setData={setData}
            />
            <Metric data={data} metrics={metrics} setData={setData} />
            <Range 
                rangeErrorMsg={rangeErrorMsg}
                setRangeErrorMsg={setRangeErrorMsg}
                data={data}
                setData={setData}
            />
            <Remarks data={data} setData={setData} />
            <Buttons
                setRangeErrorMsg={setRangeErrorMsg}
                btn1={{
                    isVisible: true,
                    text: 'Add',
                    className: 'text-secondary',
                }}
                btn2={{
                    isVisible: true,
                    text: 'Cancel',
                }}
                modalType={ModalType.Add}
                data={data}
                onCancel={onCancel}
                onSubmit={onSubmit}
				isTabActive={isTabActive}
                dropdownType={dropdownType}
				signalReportType={signalReportType}
            />
        </>
    );
};

export default AddModal;
