import React from "react";

type SVGLogsProps = { className?: string };
const SVGLogs: React.FC<SVGLogsProps> = (props) => (
	<svg
		width="25"
		height="32"
		viewBox="0 0 25 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M12.9034 3.13867C7.88876 3.13867 3.82031 6.9023 3.82031 11.5377C3.82031 16.8714 9.87568 25.3238 12.0991 28.2432C12.1914 28.3665 12.3124 28.4667 12.4522 28.5359C12.5919 28.605 12.7465 28.6411 12.9034 28.6411C13.0602 28.6411 13.2148 28.605 13.3546 28.5359C13.4943 28.4667 13.6153 28.3665 13.7076 28.2432C15.931 25.325 21.9864 16.8757 21.9864 11.5377C21.9864 6.9023 17.918 3.13867 12.9034 3.13867Z"
			stroke="#0B2F56"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.9037 14.9091C14.5758 14.9091 15.9313 13.5917 15.9313 11.9665C15.9313 10.3414 14.5758 9.02393 12.9037 9.02393C11.2315 9.02393 9.87598 10.3414 9.87598 11.9665C9.87598 13.5917 11.2315 14.9091 12.9037 14.9091Z"
			stroke="#0B2F56"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGLogs;
