import moment from "moment";
import {
	EParameterTabType,
	IEmptyMessageParams,
	IInsightMetric,
	INoonReportMetricListItem,
	TRecentList,
} from "./@types";
import insightConstants, {
	RECENT_LIST_KEYS,
	initialRecentList,
} from "./constants";
import { IListObjectStore } from "redux/_common/type";
import { createStaticRanges } from "react-date-range";
import { defineds } from "redux/_common/utils";

const { LS_RECENT_LIST, RECENT_LIST_VERSION } = insightConstants;

// adds zeroes post date
export const getFormattedISOString = (date: string) => {
	return moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z";
};

// for creating custom x-axis tick
export function getCustomIndexes(
	fromDate: string,
	toDate: string,
	baseData: Record<string, any>
) {
	//index represents point on x axis and it's value is no of times the date as appeared/count
	const customIndex = {} as Record<string, number>;
	let formattedStart = moment(getFormattedISOString(fromDate));
	const formattedEnd = moment(getFormattedISOString(toDate));
	let indexOfX = 0;
	let loopBreaker = 0;

	while (formattedStart.toISOString() !== formattedEnd.toISOString()) {
		loopBreaker++;
		const date = moment(formattedStart).format("DD-MM-YYYY");
		const baseKeysWithMatchingDates = Object.keys(baseData).filter(
			(baseKey) => {
				//baseKey is the date@watchHour
				const [recordDate] = baseKey.split("@");
				return recordDate === date;
			}
		);

		if (baseKeysWithMatchingDates.length) {
			// eslint-disable-next-line no-loop-func
			baseKeysWithMatchingDates.forEach((baseKey, index) => {
				if (index === 0)
					customIndex[indexOfX] = baseKeysWithMatchingDates.length;

				indexOfX++;
			});
		} else {
			indexOfX++;
		}
		// In case if it's going to infinite loop  - this issue was faced by my on hot-reloads - not required but kept for safety
		if (loopBreaker > 1000) break;
		formattedStart = moment(formattedStart).add(1, "d");
	}
	return customIndex;
}

// For creating lineChart data
export function traverseDates(
	fromDate: string,
	toDate: string,
	baseData: Record<string, any>,
	metric: string
) {
	const data = [] as any;
	let formattedStart = moment(getFormattedISOString(fromDate));
	const formattedEnd = moment(getFormattedISOString(toDate));
	let loopBreaker = 0;
	while (formattedStart.toISOString() !== formattedEnd.toISOString()) {
		loopBreaker++;
		const date = moment(formattedStart).format("DD-MM-YYYY");
		const baseKeysWithMatchingDates = Object.keys(baseData).filter(
			(baseKey) => {
				//baseKey is the date@watchHour
				const [recordDate] = baseKey.split("@");
				return recordDate === date;
			}
		);

		if (baseKeysWithMatchingDates.length) {
			baseKeysWithMatchingDates.forEach((baseKey) => {
				const [recordDate, recordWatchHour] = baseKey.split("@");
				data.push({
					name:
						moment(recordDate, "DD-MM-YYYY").format("Do MMM") +
						"@" +
						recordWatchHour,
					[metric]: +baseData[baseKey]?.[metric]?.local_reading,
				});
			});
		} else {
			data.push({
				name: moment(formattedStart).format("Do MMM"),
				[metric]: null,
			});
		}
		// In case if it's going to infinite loop - not required but kept for safety
		if (loopBreaker > 1000) break;
		formattedStart = moment(formattedStart).add(1, "d");
	}
	return data;
}

export function getEmptyMessage(parameters: IEmptyMessageParams) {
	const emptyMessageParameterText = {
		vesselId: "Vessel",
		stateOfShip: "State of the ship",
		selectedMetricsLen: "Metrics",
	};
	const missingParameters = Object.keys(parameters).filter((parameterKey) => {
		return !(parameters as any)[parameterKey];
	});

	return `Please select the ${missingParameters
		.map((param) => (emptyMessageParameterText as any)[param])
		.join(", ")}`;
}

export const getIndexes = (base: number, count: number) => {
	const indexes = [] as number[];
	Array.from({ length: count }).forEach((_, index) => {
		indexes.push(+base + index + 1);
	});
	return indexes;
};

export const getMetricsList = (metricsArr: any[], logCategories: any) => {
	const traverseMetrics = (metrics: any[], anotherTitle: string) => {
		if (!metrics) return;
		metrics?.forEach((metric: any) => {
			const title = metric?.title ? (metric?.title as string) : "";
			if (
				metric?.readingRange?.dataType === "integer" &&
				metric?.readingRange?.range
			) {
				metricsArr.push({
					key: metric?.key,
					metric: title + " > " + anotherTitle,
					remarks: metric?.remarks,
					range: metric?.readingRange?.range || ["", ""],
				});
			}
		});
	};

	logCategories?.forEach((category: any) => {
		const categoryTitle = category?.title ? category?.title : "";
		category?.sections?.forEach((section: any) => {
			const sectionTitle =
				`${section?.title ? section?.title + " > " : ""}` + categoryTitle;
			traverseMetrics(section?.metrics, sectionTitle);
			section?.modules?.forEach((m: any) => {
				const mTitle = `${m?.title ? m?.title + " > " : ""}` + sectionTitle;
				traverseMetrics(m?.metrics, mTitle);
				m?.subModules?.forEach((subM: any) => {
					const subMTitle =
						`${subM?.title ? subM?.title + " > " : ""}` + mTitle;
					traverseMetrics(subM?.metrics, subMTitle);
				});
			});
		});
	});

	const metricsList = metricsArr?.map((metricItem: IInsightMetric) => {
		const { key, metric, range } = metricItem;
		const [metricName, subSection, section] = metric
			.split(">")
			.map((val) => val.trim());
		return {
			_id: key,
			id: key,
			key,
			range,
			section,
			subSection,
			metric: metricName,
		};
	});

	return metricsList;
};

export function getNoonReportMetricsList(logCategories: any) {
	const metricsArr: INoonReportMetricListItem[] = [];
	logCategories.forEach((category: any) => {
		const sectionTitle = category?.title;

		category?.modules?.forEach((module: any) => {
			const subSectionTitle = module?.title;

			module.metrics?.forEach((metric: any) => {
				const metricTitle = metric?.title;
				metricsArr.push({
					...metric,
					sectionTitle,
					subSectionTitle,
					metricTitle,
					id: metric?.key,
				});
			});
		});
	});

	return metricsArr;
}

export function storeMetricsToLocalStorage<
	T extends { id: string; key: string }
>(
	tempSelectedMetrics: string[],
	metricsList: IListObjectStore<T>,
	currentTab: EParameterTabType
) {
	const maxStoredListSize = 7;
	const recentListFromLS =
		localStorage.getItem(LS_RECENT_LIST) ?? JSON.stringify(initialRecentList);

	let storedRecentList = [] as T[];
	const parsedList: TRecentList | undefined = recentListFromLS
		? JSON.parse(recentListFromLS)
		: undefined;
	if (recentListFromLS && parsedList) {
		storedRecentList =
			(parsedList?.[RECENT_LIST_VERSION] as any)[
				RECENT_LIST_KEYS[currentTab]
			] ?? [];
	}

	const newMetrics = tempSelectedMetrics.filter(
		(metric) => !storedRecentList.map((item) => item.key).includes(metric)
	);

	newMetrics.forEach((metric) => {
		const metricListItem = metricsList.byIds[metric];
		storedRecentList.push(metricListItem);
	});
	const currentCount = storedRecentList.length;
	if (currentCount > maxStoredListSize) {
		storedRecentList = storedRecentList.slice(currentCount - maxStoredListSize);
	}
	localStorage.setItem(
		LS_RECENT_LIST,
		JSON.stringify({
			[RECENT_LIST_VERSION]: {
				...parsedList?.[RECENT_LIST_VERSION],

				[RECENT_LIST_KEYS[currentTab]]: storedRecentList,
			},
		})
	);
}

// For creating lineChart data
export function traverseDatesForNoonReportInsight(
	fromDate: string,
	toDate: string,
	baseData: Record<string, any>,
	metric: string
) {
	const data = [] as any;
	let formattedStart = moment(getFormattedISOString(fromDate));
	const formattedEnd = moment(getFormattedISOString(toDate));

	while (formattedStart.toISOString() !== formattedEnd.toISOString()) {
		const date = moment(formattedStart).format("DD-MM-YYYY");
		data.push({
			name: date,
			[metric]: +baseData[date]?.[metric]?.local_reading,
		});

		formattedStart = moment(formattedStart).add(1, "d");
	}
	return data;
}

export function generateArrowedString(strArr: string[]) {
	return strArr.filter((val) => !!val).join(" > ");
}

export const insightDefaultStaticRanges = createStaticRanges([
	{
		label: "Past 30 days",
		range: () => ({
			startDate: defineds.past30days,
			endDate: defineds.endOfToday,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.past30days &&
				range.endDate === defineds.endOfToday
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Past 15 days",
		range: () => ({
			startDate: defineds.past15days,
			endDate: defineds.endOfToday,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.past15days &&
				range.endDate === defineds.endOfToday
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "This Month",
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfMonth &&
				range.endDate === defineds.endOfMonth
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Last Month",
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfLastMonth &&
				range.endDate === defineds.endOfLastMonth
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "This Week",
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfWeek &&
				range.endDate === defineds.endOfWeek
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Last Week",
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfLastWeek &&
				range.endDate === defineds.endOfLastWeek
			);
		},
		hasCustomRendering: true,
	},
]);
