import React, { useEffect, useState } from "react";
import Button from "../../../../@set-product/core/button";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { vesselSelectors, vesselActions } from "../../../../redux/app/vessel";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import classNames from "classnames";
import NewVessel from "../_elements/New";
import UpdateVessel from "../_elements/Edit";
import { rootRoutes } from "../../../../routes";
import { historyActions } from "../../../../redux/_core/history";
import Modal from "../../_common/Modal";
import Home from "../_elements/Home";
import { IVesselOperation } from "redux/app/vessel/types";

const { vesselListSelectors, vesselItemSelectors } = vesselSelectors;

type VesselListProps = PropsFromRedux;
function VesselList(props: VesselListProps) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => props.loadVessels(), []);
	const { vesselsListSelector } = props
	const { listLoadJob, onboardStatus, vessels } = vesselsListSelector;
	const [vesselOperation, setVesselOperation] = useState<IVesselOperation>({
		vesselId: null,
		operation: "ADD_VESSEL",
		isModalOpen: false,
	});

	function triggerAddVesselOperation() {
		setVesselOperation({
			vesselId: null,
			operation: "ADD_VESSEL",
			isModalOpen: true,
		});
	}

	function triggerUpdateVesselOperation(vesselId: string) {
		setVesselOperation({
			vesselId,
			isModalOpen: true,
			operation: "UPDATE_VESSEL",
		});
	}

	function resetOperations() {
		setVesselOperation({
			vesselId: null,
			isModalOpen: false,
			operation: "UPDATE_VESSEL",
		});
	}

	function GetOnboardStatusMessage() {
		const { onboardedVessels, totalVessels } = onboardStatus;
		if (listLoadJob.isLoading || !listLoadJob.isExecuted)
			return <>Loadings vessels please wait..</>;
		if (totalVessels === 0) return <>No Vessels are added &#10004;</>;
		if (totalVessels === onboardedVessels)
			return <>All vessels are onboarded &#10004;</>;
		if (onboardedVessels === 0 && totalVessels > 1)
			return (
				<>
					<span className="font-medium">{totalVessels}</span> Vessels
					are added, but none are onboarded <br />{" "}
					<strong className="font-medium">Suggestion :</strong>{" "}
					Onboard crew & devices for each Vessel.
				</>
			);
		if (onboardedVessels <= totalVessels / 2)
			return (
				<>
					<span className="font-medium">{onboardedVessels}</span> out
					of <span className="font-medium">{totalVessels}</span>{" "}
					vessels are onboarded
					<br />
					<strong className="font-medium">Tip :</strong> Onboard crew
					& devices for each Vessel.
				</>
			);
		return (
			<>
				<span className="font-medium">{onboardedVessels}</span> out of{" "}
				<span className="font-medium">{totalVessels}</span> vessels are
				onboarded
			</>
		);
	}
	



	return (
		<>
			<Home 
				triggerAddVesselOperation={triggerAddVesselOperation}
				resetOperations={resetOperations}
				vesselOperation={vesselOperation}
			/>
		</>
	);
}

function mapStateToProps(store: IStore) {
	const vesselStore = store.app.vesselStore 
	return {
		vesselsListSelector:  vesselListSelectors(vesselStore).getVesselListData(),
		selectedTabId: vesselSelectors.getSelectedTab(vesselStore)
	}
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVessels() {
			// dispatch(vesselActions.listLoad());
		},
		onOnboardClick(vesselId: string) {
			dispatch(
				historyActions.push(
					"#VesselList",
					rootRoutes.children.app.children.vessel.children.onboard.children.crew.url(
						vesselId
					)
				)
			);
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselList);


/*

<div className="min-h-full px-6 py-6 mb-6 bg-white rounded-md">
			<div
				className={classNames(
					"flex flex-row items-end justify-between mx-6 my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Vessels
					</h3>
					</div>
					<div className="flex flex-row items-end justify-end h-full -mx-1">
						<Button
							view="filled"
							color="primary"
							rightIcon="plus"
							onClick={function onAddNewVesselClick() {
								triggerAddVesselOperation();
							}}
							className="mx-1 font-medium text-white bg-blue-600 shadow-none hover:bg-blue-800 font-redhat-display"
						>
							Add new Vessel
						</Button>
						<Button
							view="filled"
							color="primary"
							rightIcon="import"
							// tslint:disable-next-line: no-empty
							onClick={function onVesselsImportClick() {}}
							className="hidden mx-1 font-medium text-white bg-blue-600 shadow-none hover:bg-blue-800 font-redhat-display"
						>
							Import Vessels
						</Button>
					</div>
				</div>
				<div className="m-6">
					<table
						className={classNames(
							"w-full my-6 border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
							{ "animate-pulse": listLoadJob.isLoading }
						)}
					>
						<thead>
							<tr>
								<th className="text-sm text-gray-500">Name</th>
								<th className="text-sm text-right text-gray-500">
									IMO Number
								</th>
								<th className="text-sm text-center text-gray-500">
									<span className="pl-2">Actions</span>
								</th>
							</tr>
						</thead>
						<tbody
							className={classNames({
								"h-0 opacity-0 transition-all duration-100 max-h-0":
									listLoadJob.isLoading &&
									vessels.ids.length === 0,
								"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
									!listLoadJob.isLoading,
							})}
						>
							{vessels.ids.map((id, index) => {
								const vessel = vessels.byIds[id];
								const selector = vesselItemSelectors(vessel);
								return (
									<tr key={id}>
										<td className="py-1 font-medium text-gray-800">
											<span className="text-gray-500">
												{vessel.vesselPrefix} -
											</span>{" "}
											{vessel.vesselName}
										</td>
								
										<td className="font-mono text-right text-gray-700">
											{vessel.imoNumber}
										</td>
									
										<td className="text-center">
											<button
												className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700"
												onClick={function onEditVesselClick() {
													props.onOnboardClick(vessel.id);
												}}
											>
												V iew
											</button>
	
											<button
												className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700"
												onClick={function onEditVesselClick() {
													triggerUpdateVesselOperation(
														vessel.id
													);
												}}
											>
												Edit
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot />
					</table>
					<div
						className={classNames({
							"h-auto opacity-100 transition-all duration-300 animate-pulse":
								listLoadJob.isLoading && vessels.ids.length === 0,
							"h-0 opacity-0 transition-all duration-300 animate-pulse":
								!listLoadJob.isLoading,
						})}
					>
						<InfiniteProgress
							isLoading={listLoadJob.isLoading}
							isSpacedOut={false}
							className="-my-6"
						/>
	
						{listLoadJob.isLoading ? (
							<div className="flex-col justify-center h-64 py-24 my-6 bg-gray-300 ">
								<h4 className="-my-3 text-2xl text-center font-redhat-display">
									Loading please wait..
								</h4>
							</div>
						) : null}
					</div>
	
				</div>
				<Modal
					isActive={vesselOperation.isModalOpen}
					onClose={() => resetOperations()}
				>
					{vesselOperation.isModalOpen ? (
						<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
							<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
								{vesselOperation.operation === "ADD_VESSEL" ? (
									<NewVessel
										onClose={function onCreateVesselModalClose() {
											resetOperations();
										}}
									/>
								) : null}
								{vesselOperation.operation === "UPDATE_VESSEL" ? (
									vesselOperation.vesselId !== null ? (
										<UpdateVessel
											vesselId={vesselOperation.vesselId}
											onClose={function onUpdateVesselModalClose() {
												resetOperations();
											}}
										/>
									) : (
										<span>No Vessel Id Provided to edit</span>
									)
								) : null}
							</div>
						</div>
					) : null}
				</Modal>
			</div>

*/