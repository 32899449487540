import { createAction } from "typesafe-actions";
import { TViewType } from "./@types";

export enum EDashboardActions {
	DASHBOARD_VIEW_TYPE_SET = "@dashboard/view/SET",
	PAGINATION_TOTAL_ITEMS_SET = "@dashboard/pagination/total-items/SET",
	PAGINATION_ITEMS_PER_PAGE_SET = "@dashboard/pagination/items-per-page/SET",
	PAGINATION_CURRENT_PAGE_SET = "@dashboard/pagination/current-page/SET",
	PAGINATION_RESET = "@dashboard/pagination/RESET",
	DASHOBOARD_PAGE_SET = "@dashboard/page/SET",
	PAGINATION_ALL_VESSELS_TOTAL_ITEMS_SET = "@dashboard/all-vessels/pagination/total-items/SET",
	PAGINATION_ALL_VESSELS_ITEMS_PER_PAGE_SET = "@dashboard/all-vessels/pagination/items-per-page/SET",
	PAGINATION_ALL_VESSELS_CURRENT_PAGE_SET = "@dashboard/all-vessels/pagination/current-page/SET",
}

const dashboardActions = {
	commands: {},
	document: {
		dashboardViewTypeSet: createAction(
			EDashboardActions.DASHBOARD_VIEW_TYPE_SET,
			(viewType: TViewType) => ({ viewType })
		)(),
		isDashboardPageSet: createAction(
			EDashboardActions.DASHOBOARD_PAGE_SET,
			(isDashboard: boolean) => ({ isDashboard })
		)(),
		paginationTotalItemsSet: createAction(
			EDashboardActions.PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		paginationItemsPerPageSet: createAction(
			EDashboardActions.PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		paginationCurrentPageSet: createAction(
			EDashboardActions.PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		paginationAllVesselsTotalItemsSet: createAction(
			EDashboardActions.PAGINATION_ALL_VESSELS_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		paginationAllVesselsItemsPerPageSet: createAction(
			EDashboardActions.PAGINATION_ALL_VESSELS_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		paginationAllVesselsCurrentPageSet: createAction(
			EDashboardActions.PAGINATION_ALL_VESSELS_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
	},
};

export const dashboardJobActions = {};

export default dashboardActions;
