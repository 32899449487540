import React, { useEffect, useMemo, useState } from "react";
import RangeManagementList from "./List";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "redux/store";
import { Dispatch } from "redux";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "redux/app/vessel/onboard";
import {
	ERANGE_MGMT_TYPES,
	ESIGNAL_REPORT_TYPE,
	ESIGNAL_REPORT_TYPE_VAL,
	IAllRanges,
	ILocalRange,
	TRangeManagement,
} from "redux/app/vessel/onboard/types";
import { RANGE_MANAGEMENT_PER_PAGE } from "redux/app/vessel/onboard/constants";
import { IPagination } from "redux/app/tool/@types";
import { Tab, Tabs } from "@blueprintjs/core";
import NumberedPagination from "components/_common/NumberedPagination";
import RangeManagementForm from "./RangeManagementForm";
import ConfirmBox from "components/app/tool/configandprint/_elements/ConfirmBox";
import ModalPortal from "components/app/_common/ModalPortal";
import Button from "@set-product/core/button/Button";
import { formatSRTypeValues } from "redux/app/vessel/onboard/utils";
import InputWrapper from "components/_common/InputWrapper";
import Dropdown from "components/_common/Dropdown";
import moment from "moment";

export enum ModalType {
	Add = "Add",
	Edit = "Edit",
	Delete = "Delete",
	Remarks = "Remarks",
}

export enum ERangeType {
	Local = "Local Ranges",
	Config = "Config Ranges",
}

type OwnProps = {};
type RangeManagementProps = PropsFromRedux & OwnProps;
function RangeManagement(props: RangeManagementProps) {
	const { ranges:localEngineLogRanges, signalLocalRanges } = props
	const { configListLoadJob, configLoadJob, localRangesLoadJob } = props.jobs;
	const [totalRanges, setTotalRanges] = useState<ILocalRange[]>([]);
	const [ranges, setRanges] = useState<ILocalRange[]>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(ModalType.Add);
	const [localRange, setLocalRange] = useState<ILocalRange>({
		key: "",
		metric: "",
		range: [undefined, undefined],
		remarks: "",
		isLocalRange: true,
	});
	const [dropdownType, setDropdownType] = useState(
		ERANGE_MGMT_TYPES.ENGINE_LOG
	);
	const [signalReportType, setSignalReportType] = useState(
		ESIGNAL_REPORT_TYPE_VAL.STEAMING
	);

	const [rangeType, setRangeType] = useState(ERangeType.Local);
	const [pagination, setPagination] = useState<IPagination>({
		itemsPerPage: RANGE_MANAGEMENT_PER_PAGE,
		currentPage: 1,
	});

	// INFO: To ensure on change of the dropdown value(Eng to Signal), set signal rep-type to Steaming
	useEffect(() => {
		setRangeType(ERangeType.Local);
		if (dropdownType === ERANGE_MGMT_TYPES.SIGNAL_REPORT) {
			setSignalReportType(ESIGNAL_REPORT_TYPE_VAL.STEAMING);
		}
	}, [dropdownType]);

	useEffect(() => {
		props.loadVesselRanges();
		props.loadVesselConfigList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.vesselId]);

	useEffect(() => {
	
		const id = props?.configLogsList?.ids?.find((id) => {
			if (props?.configLogsList?.byIds?.[id]?.type === dropdownType) {
				return true;
			}
			
			return false;
		});
		if (id) {
			props.loadVesselConfig(props?.configLogsList?.byIds?.[id]?._id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.configLogsList,dropdownType]);

	const localRanges = useMemo(() => {
		let localRanges = [] as ILocalRange[];
		if (dropdownType === ERANGE_MGMT_TYPES.ENGINE_LOG && props.ranges) {
			localRanges = Object.entries(props.ranges)
				?.filter(([key, range]) => {
					return !!range;
				})
				?.map(([key, range]) => {
					return {
						...range,
						key,
					};
				});
		}
		if (
			dropdownType === ERANGE_MGMT_TYPES.SIGNAL_REPORT &&
			props?.signalLocalRanges &&
			signalReportType
		) {
			Object.entries(props.signalLocalRanges).forEach(([key, value]) => {
				const srType = formatSRTypeValues(signalReportType);
				if (srType === value?.type) {
					localRanges.push({
						key,
						metric: value?.metric,
						remarks: value?.remarks,
						range: value?.range || ["", ""],
						isLocalRange: value?.isLocalRange,
						modifiedAt: value?.modifiedAt,
						type: value?.type,
					} as ILocalRange);
				}
			});
		}

		return localRanges;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.ranges, signalReportType, dropdownType, signalLocalRanges, localEngineLogRanges]);

	const logCategories =
        dropdownType === ERANGE_MGMT_TYPES.ENGINE_LOG
            ? props.configInfo?.config?.logCategories
            : props.configInfo?.config[signalReportType + 'Config']
                  ?.logCategories;
	

	const configRanges = useMemo(() => {
		
		const newConfigRanges: ILocalRange[] = [];
		const traverseMetrics = (metrics: any[], anotherTitle: string) => {
			if (!metrics) return;
			metrics?.forEach((metric: any) => {
				const title = metric?.title ? (metric?.title as string) : "";
				if (
					metric?.readingRange?.dataType === "integer" &&
					metric?.readingRange?.range
				) {
					newConfigRanges.push({
						key: metric?.key,
						metric: title + " > " + anotherTitle,
						remarks: metric?.remarks,
						range: metric?.readingRange?.range || ["", ""],
					});
				}
			});
		};
		if (logCategories) {
			logCategories?.forEach((category: any) => {
				const categoryTitle = category?.title ? category?.title : "";
				category?.sections?.forEach((section: any) => {
					const sectionTitle =
						`${section?.title ? section?.title + " > " : ""}` + categoryTitle;
					traverseMetrics(section?.metrics, sectionTitle);
					section?.modules?.forEach((m: any) => {
						const mTitle = `${m?.title ? m?.title + " > " : ""}` + sectionTitle;
						traverseMetrics(m?.metrics, mTitle);
						m?.subModules?.forEach((subM: any) => {
							const subMTitle =
								`${subM?.title ? subM?.title + " > " : ""}` + mTitle;
							traverseMetrics(subM?.metrics, subMTitle);
						});
					});
				});
			});
			return newConfigRanges;
		}
		return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logCategories,signalReportType,dropdownType]);

	useEffect(() => {
		if (rangeType === ERangeType.Local) {
			setTotalRanges(localRanges);
		} else {
			setTotalRanges(configRanges);
		}
	}, [configRanges, localRanges, rangeType]);

	useEffect(() => {
		const { currentPage } = pagination;
		const start = (currentPage - 1) * RANGE_MANAGEMENT_PER_PAGE;
		setRanges(totalRanges.slice(start, start + RANGE_MANAGEMENT_PER_PAGE));
	}, [totalRanges, pagination]);

	const isLoading = localRangesLoadJob.isLoading || configListLoadJob.isLoading || configLoadJob.isLoading

	const signalReportTypeOptions = [
        {
            label: ESIGNAL_REPORT_TYPE.STREAMING,
            value: ESIGNAL_REPORT_TYPE_VAL.STEAMING,
        },
        {
            label: ESIGNAL_REPORT_TYPE.ARRIVAL,
            value: ESIGNAL_REPORT_TYPE_VAL.ARRIVAL,
        },
        {
            label: ESIGNAL_REPORT_TYPE.PORT,
            value: ESIGNAL_REPORT_TYPE_VAL.PORT,
        },
        {
            label: ESIGNAL_REPORT_TYPE.DEPARTURE,
            value: ESIGNAL_REPORT_TYPE_VAL.DEPARTURE,
        },
		{
            label: ESIGNAL_REPORT_TYPE.CARGO,
            value: ESIGNAL_REPORT_TYPE_VAL.CARGO,
        },
		{
            label: ESIGNAL_REPORT_TYPE.BUNKER,
            value: ESIGNAL_REPORT_TYPE_VAL.BUNKER,
        },
    ];
    if (props?.configInfo?.config?.kind === 'bulker') {
        signalReportTypeOptions.splice(3, 0, {
            label: ESIGNAL_REPORT_TYPE.BERTH,
            value: ESIGNAL_REPORT_TYPE_VAL.BERTH,
        });
    }

	return (
		<>
			{modalOpen ? <ModalPortal
				isActive={modalOpen}
				onClose={() => {
					setModalOpen(false);
					setLocalRange({
						key: "",
						metric: "",
						range: [undefined, undefined],
						remarks: "",
					});
					setModalType(ModalType.Add);
				}}
				isOverlayClickCloseable={false}
				width={500}
			>
				{modalOpen && modalType !== ModalType.Delete ? (
					  (
						<RangeManagementForm
							localRange={localRange}
							modalType={modalType}
							logCategories={logCategories}
							dropdownType={dropdownType}
							signalReportType={signalReportType}
							onCancel={() => {
								setModalOpen(false);
								setLocalRange({
									key: "",
									metric: "",
									range: [undefined, undefined],
									remarks: "",
								});
								setModalType(ModalType.Add);
							}}
							onSubmit={(info) => {
								if (info) {						
									if (dropdownType === ERANGE_MGMT_TYPES.SIGNAL_REPORT) {
										if (modalType === ModalType.Edit) {
											props.editVesselRanges({
												localRanges: { ...(localEngineLogRanges ?? {}) },
												signalLocalRanges: {
													...(signalLocalRanges ?? {}),
													[info.key]: {
														...localRange,
														...info,
														modifiedAt: moment().toISOString()
													} as any,
												},
											});
										} else {
											props.addVesselRanges({
												localRanges: { ...(localEngineLogRanges ?? {}) },
												signalLocalRanges: {
													...(signalLocalRanges ?? {}),
													[info.key]: {
														...info,
													} as any,
												},
											});
										}
									} else {
										if (modalType === ModalType.Edit) {
											props.editVesselRanges({
												...(signalLocalRanges ?? {}),
												localRanges: {
													...(localEngineLogRanges ?? {}),
													[info.key]: {
														...localRange,
														...info,
														modifiedAt: moment().toISOString()
													} as any,
												},
											});
										} else {
											props.addVesselRanges({
												...(signalLocalRanges ?? {}),
												localRanges: {
													...(localEngineLogRanges ?? {}),
													[info.key]: {
														...info,
													} as any,
												},
											});
										}
									}
								}
								setModalOpen(false);
								setLocalRange({
									key: "",
									metric: "",
									range: [undefined, undefined],
									remarks: "",
								});
								setModalType(ModalType.Add);
							}}
							rangeManagement={props.configInfo as TRangeManagement}
						/>
					)
				) : null}
			</ModalPortal> : null}
			{modalType === ModalType.Delete ? (
				<ConfirmBox
					title="Are you sure you want to delete this range?"
					closeBtnLabel="Close"
					confirmBtnLabel="Confirm"
					isActive={true}
					onClose={() => {
						setModalOpen(false);
						setLocalRange({
							key: "",
							metric: "",
							range: [undefined, undefined],
							remarks: "",
						});
						setModalType(ModalType.Add);
					}}
					onConfirm={() => {
						if (dropdownType === ERANGE_MGMT_TYPES.SIGNAL_REPORT) {
							props.deleteVesselRanges({
								...(localRanges ?? {}),
								signalLocalRanges: {
									...(signalLocalRanges ?? {}),
									[localRange.key]: null as any,
								},
							});
						} else {
							props.deleteVesselRanges({
								...(signalLocalRanges ?? {}),
								localRanges: {
									...(localEngineLogRanges ?? {}),
									[localRange.key]: null as any,
								},
							});
						}

						setModalOpen(false);
						setLocalRange({
							key: "",
							metric: "",
							range: [undefined, undefined],
							remarks: "",
						});
						setModalType(ModalType.Add);
					}}
					modalSize={500}
					titleClassName="ws-input__label text-lg pt-0"
				/>
			) : null}
			<div className="flex items-center space-x-4">
				<InputWrapper
					className="w-[150px] rounded-md ml-4"
					hasError={false}
					hasWarning={false}
				>
					<Dropdown
						id="range-mgmt"
						inputWrapperProps={{
							className: "min-w-32 xl:pt-1 max-h-10 xl:max-h-11 range-mgmt-sr-dropdown",
						}}
						emptyOption={{
							label: "",
							value: "",
						}}
						value={dropdownType}
						onSelect={(selectedItem: any) => {
							
								setDropdownType(selectedItem.value as ERANGE_MGMT_TYPES);
						}}
						options={[
							{
								label: "Engine Log",
								value: ERANGE_MGMT_TYPES.ENGINE_LOG,
							},
							{
								label: "Signal Report",
								value: ERANGE_MGMT_TYPES.SIGNAL_REPORT,
							},
						]}
						renderListItem={(listItem) => {
							return (
								<div className="p-0 tracking-wide rounded-md cursor-pointer">
									<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
										{listItem.label}
									</h1>
								</div>
							);
						}}
						renderView={(listItem) => {
							return (
								<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
									<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
										{listItem.label}
									</h1>
								</div>
							);
						}}
					/>
					<span className="ws-input__error">Hello Error</span>
					<span className="ws-input__warning">Hello Warning</span>
				</InputWrapper>
				{dropdownType === 'signalReportConfig' ? (
                                <div>
                                    <Dropdown
                                        id="signalreport-dropdown-type"
                                        inputWrapperProps={{
                                            className:
                                                'min-w-32 xl:pt-1 max-h-10 xl:max-h-11 range-mgmt-sr-dropdown',
                                        }}
                                        options={signalReportTypeOptions}
                                        onSelect={(selectedItem: any) => {
                                            setSignalReportType(
                                                selectedItem?.value
                                            );
                                        }}
                                        placeholder="Select"
                                        noFilter={true}
                                        value={signalReportType}
                                        disabled={false}
                                        isReadOnly={false}
										renderListItem={(listItem) => <div className="p-0 tracking-wide rounded-md cursor-pointer">
											<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
												{listItem.label}
											</h1>
										</div>}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
                                    />
                                </div>
                            ) : null}
			</div>
			<div className="px-4">
				<div className="flex items-center">
					<Tabs
						id="ranges-tabs"
						animate={true}
						renderActiveTabPanelOnly={true}
						vertical={false}
						large={true}
						selectedTabId={rangeType}
						className="w-full rounded-sm bp3-html-table bp3-interactive overflow-x-auto"
						onChange={function onTabsChange(tabId) {
							setPagination((prev) => {
								return {
									...prev,
									currentPage: 1,
								};
							});
							setRangeType(tabId as ERangeType);
						}}
					>
						<Tab
							id={ERangeType.Local}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Local Ranges ({localRanges.length})
						</Tab>
						<Tab
							id={ERangeType.Config}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Config Ranges ({configRanges.length})
						</Tab>
						<Tabs.Expander />
					</Tabs>
					<div>
						<Button
							className="bg-transparent outline-none flex items-center justify-center shadow-none border border-solid border-gray-300 border-b-0 rounded-none rounded-t-md"
							onClick={() => {
								setModalType(ModalType.Add);
								setModalOpen(true);
								setLocalRange({
									key: "",
									metric: "",
									range: [undefined, undefined],
									remarks: "",
								});
							}}
						>
							<span className="text-lg text-blue-500 bp3-icon bp3-icon-add" />
						</Button>
					</div>
				</div>
				<div className="border-t border-gray-300" />
			</div>
			<RangeManagementList
				rangeType={rangeType}
				ranges={ranges}
				isLoading={isLoading}
				sNoStart={
					pagination.currentPage * pagination.itemsPerPage -
					pagination.itemsPerPage
				}
				onDelete={(range) => {
					setModalType(ModalType.Delete);
					setModalOpen(true);
					setLocalRange(range);
				}}
				onEdit={(range) => {
					setModalType(ModalType.Edit);
					setModalOpen(true);
					setLocalRange(range);
				}}
			/>
			<NumberedPagination
				{...{
					...pagination,
					totalItems: totalRanges.length || 0,
				}}
				itemIds={[]}
				onPageChange={function onPageChange(pageNumberFromPagination) {
					setPagination((prev) => {
						return {
							...prev,
							currentPage: pageNumberFromPagination,
						};
					});
				}}
				isPageLoading={false}
			/>
		</>
	);
}

const mapStateToProps = (store: IStore) => {
	return {
		...vesselOnboardSelectors.getRangesData(store.app.vesselStore.onboard),
	};
};
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVesselRanges() {
			dispatch(vesselOnboardActions.command.vesselRangesLoad());
		},
		deleteVesselRanges(ranges: IAllRanges) {
			dispatch(vesselOnboardActions.command.vesselRangeDelete(ranges));
		},
		editVesselRanges(ranges: IAllRanges) {
			dispatch(vesselOnboardActions.command.vesselRangeEdit(ranges));
		},
		addVesselRanges(ranges: IAllRanges) {
			dispatch(vesselOnboardActions.command.vesselRangeAdd(ranges));
		},
		loadVesselConfigList() {
			dispatch(vesselOnboardActions.command.vesselConfigListLoad());
		},
		loadVesselConfig(configId: string) {
			dispatch(vesselOnboardActions.command.vesselConfigLoad(configId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(RangeManagement);
