import React, { FC, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import WayshipDate from 'components/_common/WayshipDate';
import VolteoIcon from 'components/_common/icons/VolteIcon';
import { IVesselUser } from 'redux/app/feedback/@types';
import classNames from 'classnames';

interface ICertificateViewProps {
    user: IVesselUser;
    onClose: () => void;
    editVesselUserTrainingDate: (userId: string, date: string) => void;
    onUserChange: (user: IVesselUser) => void;
    isWayshipSupportAccount: boolean;
}

const CertificateView: FC<ICertificateViewProps> = (props) => {
    const { user, isWayshipSupportAccount } = props;
    const componentRef = useRef<any>();
    const [isTrainingDateEditable, setIsTrainingDateEditable] = React.useState(false);
    const [trainingDate, setTrainingDate] = useState('');
    const [error, setError] = useState({
        isValid: true,
        message: '',
    });
    useEffect(() => {
        setTrainingDate(user?.trainingDate || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.trainingDate]);
    return (
        <section
			style={{
				width: `${(user?.trainingDate && !isTrainingDateEditable)? `${window.innerWidth - 400}px`: '400px'}`,
				height: `${(user?.trainingDate && !isTrainingDateEditable)? `${window.innerHeight - 50}px`: 'auto'} `
			}}
            className={classNames('bg-white py-4 px-6 rounded-lg m-auto overflow-y-auto')}
        >
			<div>
				<div className='flex items-center justify-between mb-5'>
					<h2 className='m-0 text-xl font-bold'>
						{
							isTrainingDateEditable?
								'Edit Training Date'
								: user?.trainingDate?
									'Print Certificate'
									: 'Generate Certificate'
						}
					</h2>
					<div className='flex items-center gap-x-5'>
						{
							user?.trainingDate && !isTrainingDateEditable?
								<>
									<ReactToPrint
										trigger={() => (
											<button title='Print' className="p-4 py-3 flex items-center text-gray-700 bg-blue-100 rounded-md outline-none hover:bg-blue-200 focus:bg-blue-300">
												<span className="bp3-icon bp3-icon-print" />
											</button>
										)}
										content={() =>
											componentRef.current
										}
									/>
									{
										isWayshipSupportAccount?
											<button
												onClick={() => {
													setIsTrainingDateEditable(true);
												}}
												title='Change Training Date'
												className="p-4 py-3 flex items-center text-gray-700 bg-blue-100 rounded-md outline-none hover:bg-blue-200 focus:bg-blue-300 "
											>
												<span className="bp3-icon bp3-icon-edit" />
											</button>
											: null
									}
								</>
								: null
						}
						<button
							aria-label="Close"
							onClick={() => {
								if (isTrainingDateEditable) {
									setIsTrainingDateEditable(false);
								} else {
									props.onClose();
								}
							}}
							className="p-4 py-3 flex items-center text-gray-600 bg-gray-100 rounded-md outline-none hover:bg-gray-200 focus:bg-gray-300 "
						>
							<span className="bp3-icon bp3-icon-cross" />
						</button>
					</div>
				</div>
				{
					user?.trainingDate && !isTrainingDateEditable?
						<div ref={componentRef} className='flex items-center h-full justify-center'>
							<div className='bg-white w-[886px] p-10'>
								<div className='border-[2px] font-serif border-solid border-[#5AC194] text-[#26364D] flex flex-col items-center px-[69px] pt-9 pb-[41px]'>
									<VolteoIcon />
									<p className='mt-[13px] text-base font-normal leading-normal'>
										Volteo Maritime Pte Ltd (Singapore)
									</p>
									<div className='mt-9 border-0 border-b border-solid border-[#26364D] pb-2'>
										<h2 className='m-0 font-bold text-[32px] leading-[24px] tracking-[3.2px]'>
											CERTIFICATE OF KNOWLEDGE
										</h2>
									</div>
									<div className='mt-9 w-[672px]'>
										This is to certify that
										<span className='mx-1 font-semibold italic text-sm leading-6'>
											{user?.name}
										</span>
										holding the designation of
										<span className='mx-1 font-semibold italic text-sm leading-6'>
											{user?.designation?.name}
										</span>
										with Staff ID
										<span className='mx-1 font-semibold italic text-sm leading-6'>
											{user?.staffId}
										</span>
										has completed the
										<span className='mx-1 font-semibold italic text-sm leading-6'>
											Wayship v1.0.62
										</span>
										virtual training on 
										<span className='mx-1 font-semibold italic text-sm leading-6'>
											{moment(user?.trainingDate).format('Do MMMM YYYY')}
										</span>
										which included the following topics:
									</div>
									<div className='mt-6 flex gap-x-9 text-[#277050] text-xs font-norma leading-normal'>
										<div className='flex flex-col gap-y-3 w-[220px]'>
											<p>Install and configure Wayship with users and tanks</p>
											<p>Learn how MARPOL records can be submitted, get it reviewed and approved</p>
											<p>Preparing for PSC inspections and ways to show the software</p>
										</div>
										<div className='flex flex-col gap-y-3 w-[200px]'>
											<p>User Sign On, Sign Off and Reset Password</p>
											<p>Learn how ranges work for Engine and its value to increase data clarity</p>
											<p>Backup and Retrieve Data for any contingencies</p>
										</div>
										<div className='flex flex-col gap-y-3 w-[220px]'>
											<p>Learn how each logbook can be created and submitted for review and approval</p>
											<p>Retrieve and print historical logs and records</p>
											<p>Understand how shore managers benefit from this data</p>
										</div>
									</div>
									<div className='mt-[35px] border-0 border-b border-solid border-[#26364D] pb-2'>
										<h3 className='m-0 text-xl font-bold italic leading-normal'>Surendra Lingareddy</h3>
									</div>
									<p className='mt-2 text-lg leading-normal font-normal'>Trainer</p>
								</div>
							</div>
						</div>
						: isWayshipSupportAccount? (
							<div className='flex flex-col h-full mb-5'>
								<WayshipDate
									value={trainingDate || ''}
									title='Training Date'
									type='date'
									dataKey='trainingDate'
									error={error}
									onChangeHandler={(value) => {
										setError({
											isValid: true,
											message: '', 
										})
										setTrainingDate(value);
									}}
								/>
								<button
									className="box-border flex items-center max-w-min justify-center p-3 px-8 font-medium text-white rounded-lg outline-none focus:ring ws-button whitespace-nowrap"
									disabled={!trainingDate}
									onClick={() => {
										if (trainingDate) {
											if (isTrainingDateEditable) {
												setIsTrainingDateEditable(false);
											}
											props.editVesselUserTrainingDate(
												user._id,
												trainingDate
											);
											props.onUserChange({
												...user,
												trainingDate: trainingDate,
											})
										} else {
											setError({
												isValid: false,
												message: 'Please select training date', 
											})
										}
									}}
								>
									{
										isTrainingDateEditable?
											'Save'
											: 'Generate'
									}
								</button>
							</div>
						): <p>No Certificate Found</p>
				}
			</div>
        </section>
    )
}

export default CertificateView