import React from "react";
import { QuestionRenderer } from "./QuestionRenderer";

const SectionUI = (props: { checklist: any }) => {
	const { checklist } = props;
	return (
		<>
			{checklist.questions && (
				<section className="flex flex-col">
					<h1 className="text-xl font-extrabold mt-2">{checklist.title}</h1>
					{checklist.questions &&
						checklist.questions.map((e: any, index: number) => {
							return (
								<QuestionRenderer
									classes={e.classNames}
									key={e.key}
									fields={e.fields}
									num={e.number}
									title={e.question}
								/>
							);
						})}
				</section>
			)}
		</>
	);
};

export { SectionUI };
