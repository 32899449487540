import React from "react";
import classnames from "classnames";
import { createPortal } from "react-dom";

type ModalType = React.PropsWithChildren<{
	isActive: boolean;
	onClose: () => void;
	className?: string;
	isFullWidth?: boolean;
	width?: number | string;
	height?: number | string;
	isOverlayClickCloseable?: boolean;
}>;
const ModalPortal: React.SFC<ModalType> = ({
	isActive,
	onClose,
	children,
	className,
	isFullWidth = false,
	width,
	isOverlayClickCloseable = true
}: ModalType) => {
	return createPortal(
		<div
			className={classnames({
				"ws-modal--active": isActive,
				"h-0 z-0 pointer-events-none ": !isActive,
			})}
		>
			<div
				className={classnames(
					"ws-modal",
					{
						"opacity-0": !isActive,
						"opacity-1": isActive,
					},
					className
				)}
			>
				<div className="ws-modal__overlay" onClick={() => isOverlayClickCloseable && onClose()} />
				<div
					style={width ? { width } : undefined}
					className={classnames("ws-modal__container mx-4 md:mx-auto", {
						" pointer-events-none ": !isActive,
						"md:w-4/5 sm:w-full lg:w-3/5": !isFullWidth && !width,
						"w-full max-h-full overflow-y-auto ws-scroll ": isFullWidth,
					})}
				>
					{children}
				</div>
			</div>
		</div>,
		document.body
	);
};

export default ModalPortal;
