import React, { useEffect } from "react";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import FeedbackForm from "./_elements/feedbackForm/FeedbackForm";
import FeedbackChatMessages from "../view/_elements/FeedbackChatMessages";
import feedbackActions from "redux/app/feedback/actions";
import { authSelectors } from "redux/app/auth";
import { Helmet } from "react-helmet-async";
import feedbackSelectors from "redux/app/feedback/selectors";

type FeedbackViewProps = { feedbackId: string } & PropsFromRedux;
function FeedbackView(props: FeedbackViewProps) {
	const { feedbackId, individualFeedback, isLoggedIn } = props;

	//INFO: The css in App.tsx makes the screen height more than 100vh
	// useEffect(() => {
	// 	// @ts-ignore
	// 	document.body.getElementsByClassName("displayable-screen")[0].style.overflowY = "hidden"
	// 	// @ts-ignore
	// 	document.body.getElementsByClassName("displayable-screen")[0].style.maxHeight = "100vh"

	// 	return () => {
	// 		// @ts-ignore
	// 		document.body.getElementsByClassName("displayable-screen")[0].style.overflowY = "auto"
	// 		// @ts-ignore
	// 		document.body.getElementsByClassName("displayable-screen")[0].style.minHeight = "100vh"
	// 	}
	// }, [])

	useEffect(() => {
		if (isLoggedIn && feedbackId) {
			props.loadFeedback(feedbackId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedbackId, isLoggedIn]);

	useEffect(() => {
		return () => {
			props.resetFeedback();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// useEffect(() => {
	// 	props.loadFeedback(feedbackId);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [feedbackId]);

	return (
		<div
			className="h-[calc(100vh-16px)] min-h-full overflow-y-auto bg-white rounded-md md:overflow-hidden m-2"
			// style={{ height: "calc(100vh - 100px)" }}
		>
			<Helmet>
				<title>{individualFeedback?.title || "Feedback"}</title>
				<meta name="description" content={individualFeedback?.feedback} />

				<meta
					property="og:description"
					content={individualFeedback?.feedback}
				/>
				{/* <link
					rel="canonical"
					id="canonical-url"
					href={`https://wayship-assist-og.vercel.app/api/feedbackog?title=${individualFeedback?.title}`}
				/> */}

				<meta
					property="og:url"
					content={`https://wayship-assist-og.vercel.app/api/feedbackog?title=${individualFeedback?.title}`}
				/>
				<meta
					property="og:image"
					content={`https://wayship-assist-og.vercel.app/api/feedbackog?title=${individualFeedback?.title}`}
				/>

				<meta property="og:title" content={individualFeedback?.title} />
				<meta name="title" content={individualFeedback?.title} />
			</Helmet>
			<div className="flex flex-col justify-between w-full md:flex-row md:h-full">
				<div className="w-full h-full overflow-y-auto md:w-1/2">
					<FeedbackForm />
				</div>
				<div
					className="w-full h-full  md:w-1/2 md:overflow-y-hidden"
					style={{ background: "rgb(232 249 255" }}
				>
					<h3 className="flex items-center h-[55px] px-5 py-3 border-b border-[#6F696966] font-medium font-redhat-text">
						Chat with {individualFeedback?.author ?? "User"}
					</h3>
					<FeedbackChatMessages />
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const isLoggedIn = authSelectors(store.app.auth).isLoggedIn();
	const feedbackStore = feedbackSelectors._getStore(store);

	const individualFeedback =
		feedbackSelectors.getIndividualFeedback(feedbackStore);

	return {
		isLoggedIn,
		individualFeedback,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadFeedback(feedbackId: string) {
			return dispatch(
				feedbackActions.commands.individualFeedbackLoad(feedbackId)
			);
		},
		resetFeedback(){
			dispatch(feedbackActions.document.feedbackChatsSetInIndividualFeedback(undefined))
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackView);
