import React from "react";
type SVGFilterProps = { className?: string };
const SVGFilter: React.FC<SVGFilterProps> = (props) => (
	<svg
		width="21"
		height="21"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.1875 20.625H8.8125C8.36495 20.625 7.93573 20.4472 7.61926 20.1307C7.30279 19.8143 7.125 19.3851 7.125 18.9375V12.5334L0.872813 6.28125C0.55591 5.96624 0.376884 5.53839 0.375 5.09156V2.0625C0.375 1.61495 0.55279 1.18572 0.869257 0.869257C1.18572 0.55279 1.61495 0.375 2.0625 0.375H18.9375C19.3851 0.375 19.8143 0.55279 20.1307 0.869257C20.4472 1.18572 20.625 1.61495 20.625 2.0625V5.09156C20.6231 5.53839 20.4441 5.96624 20.1272 6.28125L13.875 12.5334V18.9375C13.875 19.3851 13.6972 19.8143 13.3807 20.1307C13.0643 20.4472 12.6351 20.625 12.1875 20.625ZM2.0625 2.0625V5.09156L8.8125 11.8416V18.9375H12.1875V11.8416L18.9375 5.09156V2.0625H2.0625Z"
			fill="black"
		/>
	</svg>
);
export default SVGFilter;
