import React, { useEffect, useState } from "react";
import classNames from "classnames";
import InfiniteProgress from "../../../../../_common/InfiniteProgress";
import InputWrapper from "../../../../../_common/InputWrapper";
import { IStore } from "../../../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../../../redux/app/vessel/onboard";
type CrewImportProps = PropsFromRedux & {
	onCancel(): void;
	onClose(): void;
	vesselId: string;
};
function CrewImport(props: CrewImportProps) {
	const { importErrors, jobs, vesselId } = props;
	const [file, setFile] = useState<File | null>(null);
	const hasErrors = false;
	return (
		<div
			className={classNames("w-full h-full my-6 bg-white bp3-dialog pb-0", {})}
		>
			<div className="justify-between p-4 px-8 pt-6 bp3-dialog-header">
				<div className="flex flex-col px-2">
					<h4 className="text-xl font-medium text-gray-700 font-redhat-display">
						Import crew
					</h4>
					<h5
						className={classNames("text-sm text-gray-600", {
							"text-red-500 font-medium": hasErrors,
						})}
					>
						{hasErrors ? "Form has few errors" : "CSV file based crew import"}
					</h5>
				</div>
				<button
					aria-label="Close"
					onClick={props.onClose}
					disabled={jobs.import.isLoading}
					className="p-4 text-2xl bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
				/>
			</div>
			<InfiniteProgress isLoading={jobs.import.isLoading} isSpacedOut={false} />
			<div
				className="p-8 py-0 mx-0 overflow-y-auto bg-white rounded-sm bp3-dialog-body ws-scroll"
				style={{ maxHeight: 460 }}
			>
				<form
					className="flex flex-row flex-wrap items-baseline justify-start p-2 -mx-2 -my-2"
					onSubmit={function onSubmit(e) {
						e.preventDefault();
						// formSubmit();
					}}
				>
					<div className="w-full">
						<div className="px-2">
							<div className="p-6 my-8 mt-3 text-sm text-gray-600 bg-blue-100 border-l-4 border-blue-400 rounded-sm shadow-sm">
								<p>
									<strong className="font-medium">Note:</strong> <br />
									We have attached an example csv file below.
								</p>
								<p>
									This file has the format of csv that is required successfully
									import crew.
								</p>
								<p className="font-medium">
									Please make sure that your csv file has the exact same format
								</p>
								<div className="block pt-3">
									<a
										href=""
										className="text-blue-500 underline hover:text-blue-700 hover:bg-blue-200"
									>
										example-file.csv
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper className="w-full" inputValue={file}>
								<label htmlFor="field-id" className="ws-input__label">
									Crew CSV file
								</label>
								<InputWrapper className="p-0 m-0">
									<input
										type="file"
										className="ws-input "
										onChange={function onFileChoose(e) {
											if (e.target.files) setFile(e.target.files[0]);
										}}
									/>
								</InputWrapper>
							</InputWrapper>
						</div>
					</div>
					{importErrors ? (
						<div className="w-full">
							<div className="p-6 text-red-400">
								{(importErrors as string).toString()}
							</div>
						</div>
					) : null}
					<div className="justify-start w-full p-0 my-3 bp3-dialog-footer-actions">
						<button
							className="px-10 py-2 mx-2 font-sans text-sm font-medium text-white bg-blue-700 rounded-sm shadow-md hover:bg-blue-800 focus:bg-blue-900 ws-button focus:outline-none"
							onClick={function onImportClick() {
								if (file) props.importCrew(file, vesselId);
							}}
							disabled={jobs.import.isLoading}
						>
							<span className="py-1">Import Crew</span>
						</button>
						<button
							className="px-6 py-3 mx-2 font-sans text-sm font-medium text-gray-500 bg-white rounded-sm "
							onClick={props.onCancel}
							disabled={jobs.import.isLoading}
						>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	return vesselOnboardSelectors.getCrewImportData(
		store.app.vesselStore.onboard
	);
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		importCrew(csv: File, vesselId: string) {
			dispatch(vesselOnboardActions.command.crewListImport(csv, vesselId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(CrewImport);
