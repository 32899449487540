import actionTypes from "./action-types";
import {
	ILocalstorageSetItem__Action,
	ILocalstorageRemoveItem__Action,
} from "./types";
const {
	LOCALSTORAGE_SET_ITEM,
	LOCALSTORAGE_REMOVE_ITEM,
	LOCALSTORAGE_CLEAR,
} = actionTypes;

export const typeCreator = (feature: string) => ({
	LOCALSTORAGE_SET_ITEM: `${feature} [${LOCALSTORAGE_SET_ITEM}]`,
	LOCALSTORAGE_REMOVE_ITEM: `${feature} [${LOCALSTORAGE_REMOVE_ITEM}]`,
	LOCALSTORAGE_CLEAR: `${feature} [${LOCALSTORAGE_CLEAR}]`,
});

export default {
	setItem: (
		feature: string,
		key: string,
		value: any
	): ILocalstorageSetItem__Action => ({
		type: typeCreator(feature).LOCALSTORAGE_SET_ITEM,
		payload: {
			key,
			value,
		},
		meta: {
			feature,
			trueType: LOCALSTORAGE_SET_ITEM,
		},
	}),
	removeItem: (
		feature: string,
		key: string
	): ILocalstorageRemoveItem__Action => ({
		type: typeCreator(feature).LOCALSTORAGE_REMOVE_ITEM,
		payload: { key },
		meta: {
			feature,
			trueType: LOCALSTORAGE_REMOVE_ITEM,
		},
	}),
	clear: (feature: string) => ({
		type: typeCreator(feature).LOCALSTORAGE_CLEAR,
		meta: {
			feature,
			trueType: LOCALSTORAGE_CLEAR,
		},
	}),
};
