import Button from "@set-product/core/button/Button";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import feedbackActions from "redux/app/feedback/actions";
import { FILTER_SECTIONS } from "redux/app/feedback/constants";
import feedbackSelectors from "redux/app/feedback/selectors";
import {
	filterSections,
	getPossibleSections,
	getPossibleSubSections,
	getUserOptions,
	priorityOptions,
	statusOptions,
} from "redux/app/feedback/utils";
import { IStore } from "redux/store";
import FeedbackFilterDropdown from "./FeedbackFilterDropdown";
import { tasksData } from "redux/app/feedback/tasksData";
import { IFilterState, IOption } from "redux/app/feedback/@types";
import userSelectors from "redux/app/user/selectors";
import userActions from "redux/app/user/actions";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";

type OwnProps = {};
type FilterContentProps = PropsFromRedux & OwnProps;
function FilterContent(props: FilterContentProps) {
	const {
		currentFilterTab,
		filterState,
		tasks,
		users,
		jobToLoadUsers,
		jobToLoadFeedbackCount,
		jobToLoadFeedbacks,
	} = props;
	const [subSectionOptions, setSubSectionOptions] = useState<IOption[]>([]);
	const sections = getPossibleSections(tasks);
	const validUsers = getUserOptions(users);
	const userOptions = validUsers?.map((user) => ({
		label: user.name,
		value: user.id,
	}));

	useEffect(() => {
		props.loadUsers();
	}, []);

	useEffect(() => {
		if (!filterState?.section?.value) {
			props.setFilterState({ subsection: undefined });
		}
	}, [filterState.section]);

	useEffect(() => {
		if (filterState?.section) {
			const selectedSectionOption = filterState.section;
			const possibleSubSections = getPossibleSubSections(
				tasks,
				selectedSectionOption?.value
			);
			setSubSectionOptions(possibleSubSections);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterState?.section?.value]);

	return (
		<div>
			<div className="flex justify-between items-center py-2.5 px-2 border-b border-[#DCDCDC]">
				<h3 className="text-lg font-medium">Filter</h3>
				<div className="flex items-center">
					<button
						className="underline text-[#133774] text-base"
						onClick={() => {
							props.toggleIsFeedbackFiltersModal();
							props.filtersReset();
							props.loadFeedbacks("1");
							props.loadTotalFeedbackCount();
							props.selectTab(FILTER_SECTIONS.SECTION);
							props.goToFirstPage();
						}}
					>
						Reset
					</button>
					<span
						onClick={() => props.toggleIsFeedbackFiltersModal()}
						className="text-xl bp3-icon bp3-icon-cross ml-2 cursor-pointer"
					></span>
				</div>
			</div>
			<div className="flex min-h-[280px]">
				<div className="w-2/5 border-r border-[#DCDCDC]">
					<ul>
						{filterSections.map((filterSection) => {
							return (
								<li
									key={filterSection}
									className={classNames(
										"m-0 py-2 px-2 border-b border-[#DCDCDC] text-sm hover:bg-purple-100 cursor-pointer #000000CC",
										{
											"bg-purple-100 text-[#000000CC] font-medium":
												currentFilterTab === filterSection,
										}
									)}
									onClick={() => {
										props.selectTab(filterSection);
									}}
								>
									{filterSection}
								</li>
							);
						})}
					</ul>
				</div>
				<div className="w-3/5 p-1">
					{currentFilterTab === FILTER_SECTIONS.SECTION && (
						<div className="mt-2">
							<div className="px-2 pb-4">
								<label
									className="text-sm font-medium pb-1.5 inline-block"
									htmlFor="section"
								>
									Select Section
								</label>
								<FeedbackFilterDropdown
									key={filterState?.section?.value ?? "empty-section"}
									id={"section"}
									placeholder="section"
									options={sections}
									value={filterState?.section ?? undefined}
									handleChange={(newValue) => {
										props.setFilterState({ section: newValue as IOption });
									}}
								/>
							</div>
							<div className="px-2 pb-4">
								<label
									className="text-sm font-medium pb-1.5 inline-block"
									htmlFor="section"
								>
									Select Subsection
								</label>
								<FeedbackFilterDropdown
									key={filterState?.subsection?.value ?? "empty-subsection"}
									id={"subsection"}
									placeholder="sub-section"
									options={subSectionOptions}
									isDisabled={!filterState?.section}
									value={filterState?.subsection}
									handleChange={(newValue) => {
										props.setFilterState({ subsection: newValue as IOption });
									}}
								/>
							</div>
						</div>
					)}
					{currentFilterTab === FILTER_SECTIONS.ASSIGNED_TO && (
						<div className="px-2 pb-4 mt-2">
							<label
								className="text-sm font-medium pb-1.5 inline-block"
								htmlFor="assignedTo"
							>
								Select user
							</label>
							<FeedbackFilterDropdown
								key={filterState?.assignedTo?.value ?? "empty-assignedTo"}
								id={"assignedTo"}
								placeholder="user"
								options={userOptions}
								value={filterState?.assignedTo}
								isLoading={jobToLoadUsers.isLoading}
								handleChange={(newValue) => {
									props.setFilterState({ assignedTo: newValue as IOption });
								}}
							/>
						</div>
					)}
					{currentFilterTab === FILTER_SECTIONS.STATUS && (
						<div className="px-2 pb-4 mt-2">
							<label
								className="text-sm font-medium pb-1.5 inline-block"
								htmlFor="status"
							>
								Select status
							</label>
							<FeedbackFilterDropdown
								key={filterState?.status?.value ?? "empty-status"}
								id={"status"}
								placeholder="status"
								value={filterState?.status}
								options={statusOptions}
								handleChange={(newValue) => {
									props.setFilterState({ status: newValue as IOption });
								}}
							/>
						</div>
					)}
					{currentFilterTab === FILTER_SECTIONS.PRIORITY && (
						<div className="px-2 pb-4 mt-2">
							<label
								className="text-sm font-medium pb-1.5 inline-block"
								htmlFor="priority"
							>
								Select priority
							</label>
							<FeedbackFilterDropdown
								key={filterState?.priority?.value ?? "empty-priority"}
								id={"priority"}
								placeholder="priority"
								value={filterState?.priority}
								options={priorityOptions}
								handleChange={(newValue) => {
									props.setFilterState({ priority: newValue as IOption });
								}}
							/>
						</div>
					)}

					{currentFilterTab === FILTER_SECTIONS.UNREAD && (
						<div className="mt-2 ml-2">
							<button
								className="flex items-center text-sm text-gray-500 md:mr-4 mb-2 md:mb-0"
								onClick={() => {
									props.setFilterState({
										showPendingMessages: !filterState.showPendingMessages,
									});
								}}
							>
								<span
									className={classNames(
										"mb-px text-gray-600 text-md bp3-icon",
										{
											"bp3-icon-tick-circle": filterState.showPendingMessages,
											"bp3-icon-circle": !filterState.showPendingMessages,
										}
									)}
								></span>
								<span className="mx-1 ">Sort by unread Messages</span>
							</button>
						</div>
					)}
				</div>
			</div>
			<div className="p-1 border-t border-[#DCDCDC]">
				<Button
					className=" bg-blue-700 whitespace-nowrap disabled:bg-gray-300 w-full "
					onClick={() => {
						props.loadFeedbacks("1");
						props.loadTotalFeedbackCount();
						props.goToFirstPage();
						props.toggleIsFeedbackFiltersModal();
					}}
					disabled={
						jobToLoadFeedbackCount.isLoading || jobToLoadFeedbacks.isLoading
					}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const currentFilterTab =
		feedbackSelectors.getCurrentFilterSection(feedbackStore);
	const filterState = feedbackSelectors.getFeedbackFilterState(feedbackStore);
	const usersStore = userSelectors._getStore(store);
	const users = userSelectors.getUsersList(usersStore);
	const jobToLoadUsers = userSelectors.getJobToLoadUsers(usersStore);
	const jobToLoadFeedbacks =
		feedbackSelectors.jobToLoadFeedbacks(feedbackStore);
	const jobToLoadFeedbackCount =
		feedbackSelectors.jobToLoadFeedbacksCount(feedbackStore);

	return {
		currentFilterTab,
		filterState,
		tasks: tasksData,
		users,
		jobToLoadUsers,
		jobToLoadFeedbacks,
		jobToLoadFeedbackCount,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		selectTab(currentTab: FILTER_SECTIONS) {
			dispatch(feedbackActions.document.feedbackFilterSectionSet(currentTab));
		},
		toggleIsFeedbackFiltersModal() {
			dispatch(feedbackActions.document.toggleFeedbackFiltersModal());
		},
		setFilterState(state: Partial<IFilterState>) {
			dispatch(feedbackActions.document.feedbackFilterStateSet(state));
		},
		loadUsers() {
			// Info: value 1 has no significance, we have set isPaginated to false
			dispatch(userActions.commands.userListLoad(1, false));
		},
		loadFeedbacks(pageNumber: string) {
			dispatch(feedbackActions.commands.feedbackListLoad(+pageNumber));
		},
		loadTotalFeedbackCount(vesselId?: string) {
			dispatch(feedbackActions.commands.feedbackTotalCountLoad(vesselId));
		},
		filtersReset() {
			dispatch(feedbackActions.document.feedbackFilterStateReset());
		},
		goToFirstPage() {
			dispatch(
				historyActions.push(
					"#FeedbackList",
					rootRoutes.children.app.children.feedback.children.list.url(1)
				)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FilterContent);
