import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Feedback from "components/app/feedback/Feedback";
import { rootRoutes } from "../../../routes";
import ScreenDashboardList from "./list";
import ScreenDashboardView from "./view";

const dashboardRoutes = rootRoutes.children.app.children.dashboard.children;
type ScreenDashboardProps = React.PropsWithChildren<{}>;
function ScreenDashboard(props: ScreenDashboardProps) {
	return (
		<Feedback>
			<Switch>
				<Route
					path={dashboardRoutes.list.path}
					component={ScreenDashboardList}
				/>
				<Route
					path={dashboardRoutes.view.path}
					component={ScreenDashboardView}
				/>
				<Route render={RedirectComponent} />
			</Switch>
		</Feedback>
	);
}

function RedirectComponent() {
	return <Redirect to={dashboardRoutes.list.url()} />;
}
export default ScreenDashboard;
