// @ts-nocheck
import { IListObjectStore } from "redux/_common/type";
import { TTaskTemplate } from "./@types";

export const tasksData: IListObjectStore<TTaskTemplate> = {
	byIds: {
		VDF01: {
			id: "VDF01",
			title: "VDF01 - LNG Bunkering Compatibility Assessment",
			type: "PERMIT",
		},
		PC18A: {
			id: "PC18A",
			title: "PC18A - Safety Training Planner",
			type: "PERMIT",
		},
		PT25: {
			id: "PT25",
			title: "PT25 - Energy Generator Load (Blackout) Testing Checklist",
			type: "PERMIT",
		},
		VOT56B: {
			id: "VOT56B",
			title: "VOT56B-Ship-to-ship(STS)-Transfer-Documentation",
			type: "PERMIT",
		},
		VDF02: {
			id: "VDF02",
			title: "VDF02 - LNG Pre-arrival / Operation Safety Checklist",
			type: "PERMIT",
		},
		VOT80: {
			id: "VOT80",
			title: "VOT80 - Heavy Weather Ballast Checklist",
			type: "PERMIT",
		},
		VO68: {
			id: "VO68",
			title: "VO68 - Vessels Bunker Report",
			type: "PERMIT",
		},
		PC18: {
			id: "PC18",
			title: "PC18 - Shipboard Training Record",
			type: "PERMIT",
		},
		VO02: {
			id: "VO02",
			title: "VO02 - Daily Sounding / Ullage Monitoring Log",
			type: "PERMIT",
		},
		VDF06E: {
			id: "VDF06E",
			title: "VDF06E - O2 Sampling Log",
			type: "PERMIT",
		},
		VDF06B: {
			id: "VDF06B",
			title: "VDF06B - LNG Bunker Tank Cooldown Log (TYPE-B)",
			type: "PERMIT",
		},
		PC44: {
			id: "PC44",
			title: "PC44 - DF Trg Evaluation and Assessment Matrix",
			type: "PERMIT",
		},
		VO81: {
			id: "VO81",
			title: "VO81 - Superintendent Visit Log",
			type: "PERMIT",
		},
		VOT60: {
			id: "VOT60",
			title: "VOT60 - STS Questionnaire(Dumb Barges)",
			type: "PERMIT",
		},
		VO67A: {
			id: "VO67A",
			title: "VO67A NaOH Bunkeing Safety Checklist - Truck",
			type: "PERMIT",
		},
		VQS22C: {
			id: "VQS22C",
			title: "VQS22C - Personnel Transfer by Crane Compatibility Analysis",
			type: "PERMIT",
		},
		VOT01: {
			id: "VOT01",
			title: "VOT01 - Demurrage Claim Documents Checklist",
			type: "PERMIT",
		},
		VOT58: {
			id: "VOT58",
			title: "VOT58 - STS Questionnaire(Bunkering)",
			type: "PERMIT",
		},
		VDF06C: {
			id: "VDF06C",
			title: "VDF06C - LNG Bunker Tank Cooldown Log (TYPE-C)",
			type: "PERMIT",
		},
		VDF03: {
			id: "VDF03",
			title: "VDF03 - Pre-Transfer Meeting / Information Exchange",
			type: "PERMIT",
		},
		VTC103: {
			id: "VTC103",
			title:
				"VTC103 - Checklist for Emergency Use of Portable Framo Cargo Pump",
			type: "PERMIT",
		},
		VOT56_CH_1: {
			id: "VOT56_CH_1",
			title:
				"VOT56 - Ship-to-Ship(STS) Transfer Checklist (Pre - Fixture Information)",
			type: "PERMIT",
		},
		VOT56A: {
			id: "VOT56A",
			title: "VOT56A Ship to Ship(STS) Transfer Checklist In Port",
			type: "PERMIT",
		},
		VO66: {
			id: "VO66",
			title: "VO66 - Chief Officer Pre-Departure Aide Memoire",
			type: "PERMIT",
		},
		VDF06D: {
			id: "VDF06D",
			title: "VDF06D - DD Preparations",
			type: "PERMIT",
		},
		PQS03: {
			id: "PQS03",
			title: "PQS03 - HOT WORK PLAN FOR APPROVAL – DRY Fleet",
			type: "PERMIT",
		},
		VB13: {
			id: "VB13",
			title: "VB13 - Helicopter Operations Checklist",
			type: "PERMIT",
		},
		VB04: {
			id: "VB04",
			title: "VB04 - Anchoring Plan & Checklist",
			type: "PERMIT",
		},
		VTG53: {
			id: "VTG53",
			title: "VTG53 - During Cargo Operations Checklist",
			type: "PERMIT",
		},
		VB21: {
			id: "VB21",
			title: "VB21 - Gyro Failure Checklist",
			type: "PERMIT",
		},
		VTC03: {
			id: "VTC03",
			title:
				"VTC03 - Pre-Arrival & Prior to Cargo Operations Checklist (Chemical Tankers)",
			type: "PERMIT",
		},
		PTT08: {
			id: "PTT08",
			title: "PTT08 - COP WBP Turbine Port Log",
			type: "PERMIT",
		},
		VB05: {
			id: "VB05",
			title: "VB05 - Navigation in Coastal Waters and Restricted Areas",
			type: "PERMIT",
		},
		VB16: {
			id: "VB16",
			title: "VB16 - Bridge Familiarization Checklist",
			type: "PERMIT",
		},
		VOOT03: {
			id: "VOOT03",
			title: "VOOT03 Crude Oil Washing(cow) Record",
			type: "PERMIT",
		},
		PT33: {
			id: "PT33",
			title: "PT33 - Vessel Reactivation from Lay-up Checklist",
			type: "PERMIT",
		},
		PM02: {
			id: "PM02",
			title: "PM02 - Mooring Winch Brake Holding Capacity Test",
			type: "PERMIT",
		},
		PT32: {
			id: "PT32",
			title: "PT32 - Existing Vessel Takeover Checklist",
			type: "PERMIT",
		},
		VO01: {
			id: "VO01",
			title: "VO01 - Void Space / Ballast Tank Atmosphere Record",
			type: "PERMIT",
		},
		VB07: {
			id: "VB07",
			title: "VB07 - Restricted Visibilty Checklist",
			type: "PERMIT",
		},
		VOT54: {
			id: "VOT54",
			title: "VOT54 - Pre-Cargo Operations Checklist",
			type: "PERMIT",
		},
		VB19: {
			id: "VB19",
			title: "VB19 - UMS Check for Bridge",
			type: "PERMIT",
		},
		VO63: {
			id: "VO63",
			title: "VO63 - Master's Voyage Checklist",
			type: "PERMIT",
		},
		VTC112: {
			id: "VTC112",
			title: "VTC112 - Nitrogen System Condition Checklist",
			type: "PERMIT",
		},
		VQS11: {
			id: "VQS11",
			title: "VQS11 - Small Craft Alongside Checklist",
			type: "PERMIT",
		},
		VT06: {
			id: "VT06",
			title: "VT06 - Engine Room Change of Watch Checklist",
			type: "PERMIT",
		},
		VT101: {
			id: "VT101",
			title: "VT101 - Engine Room Departure (Stand By) Checklist",
			type: "PERMIT",
		},
		VTG06: {
			id: "VTG06",
			title: "VTG06 - Controlled Venting Checklist",
			type: "PERMIT",
		},
		VB23: {
			id: "VB23",
			title: "VB23 - Echo Sounder Failure Checklist",
			type: "PERMIT",
		},
		VB11: {
			id: "VB11",
			title: "VB11 - Changeover of Anchor Watch Checklist",
			type: "PERMIT",
		},
		VO67B: {
			id: "VO67B",
			title: "VO67B NaOH Bunkeing Safety Checklist - Barge",
			type: "PERMIT",
		},
		VTG54: {
			id: "VTG54",
			title: "VTG54 - LPG Pre-Cargo Operations Checklist",
			type: "PERMIT",
		},
		PT31: {
			id: "PT31",
			title: "PT31 - Post Drydock & Prior Depature Shipyard Checklist",
			type: "PERMIT",
		},
		VB22: {
			id: "VB22",
			title: "VB22 - ECDIS and Sensor Failure Checklist",
			type: "PERMIT",
		},
		VB16E: {
			id: "VB16E",
			title: "VB16E - ECDIS Familiarization Checklist",
			type: "PERMIT",
		},
		VB03: {
			id: "VB03",
			title: "VB03 - Bridge Pre-Arrival Checklist",
			type: "PERMIT",
		},
		VT102: {
			id: "VT102",
			title: "VT102 - Engine Room Arrival Checklist",
			type: "PERMIT",
		},
		VQS21: {
			id: "VQS21",
			title: "VQS21 - Mandatory Safety and Shipboard Familiarization",
			type: "PERMIT",
		},
		VT07: {
			id: "VT07",
			title: "VT07 - Machinery Spaces Familiarization Checklist",
			type: "PERMIT",
		},
		VB02: {
			id: "VB02",
			title: "VB02 - Bridge Pre-Departure Checklist",
			type: "PERMIT",
		},
		PT28: {
			id: "PT28",
			title: "PT28 - Steering Gear Tests & Checks",
			type: "PERMIT",
		},
		VDF09: {
			id: "VDF09",
			title: "VDF09 - Recovery from ESD1 / ESD2",
			type: "PERMIT",
		},
		VT112: {
			id: "VT112",
			title: "VT112 - Checklist For Safety Officer's Inspection",
			type: "PERMIT",
		},
		PQS01: {
			id: "PQS01",
			title: "PQS01 - Periodic Safety Meeting",
			type: "PERMIT",
		},
		VQS26: {
			id: "VQS26",
			title: "VQS26 - ToolBox Meeting Form",
			type: "PERMIT",
		},
		PC13: {
			id: "PC13",
			title: "PC13 - Sign On & Sign Off Checklist",
			type: "PERMIT",
		},
		VB06: {
			id: "VB06",
			title: "VB06 - Heavy Weather Checklist",
			type: "PERMIT",
		},
		VO72: {
			id: "VO72",
			title: "VO72 - Diving Operations Checklist",
			type: "PERMIT",
		},
		VB10R: {
			id: "VB10R",
			title: "VB10R - Watch Change Over and Bridge Daily Checklists",
			type: "PERMIT",
		},
		PT22: {
			id: "PT22",
			title: "PT22 - Refrigerant & Ozone Depleting Substance Log",
			type: "PERMIT",
		},
		VQS20: {
			id: "VQS20",
			title:
				"VQS20 - Stern Side Ramp Operation Checklist (Hoisting) [PCC CHECKLIST 4… (FOR 4,300 PCTC)]",
			type: "PERMIT",
		},
		VB24: {
			id: "VB24",
			title: "VB24 - Radar Failure Checklist",
			type: "PERMIT",
		},
		PT23: {
			id: "PT23",
			title: "PT23 - List of Equipm containg Oz Depleting Subs",
			type: "PERMIT",
		},
		VQS25: {
			id: "VQS25",
			title: "VQS25 - Record of Charterers Equipment",
			type: "PERMIT",
		},
		VTG07: {
			id: "VTG07",
			title: "VTG07 - Manifold Connection / Disconnection Checklist",
			type: "PERMIT",
		},
		VQS05A: {
			id: "VQS05A",
			title: "VQS05A - Pumproom Entry Preparation",
			type: "PERMIT",
		},
		VB18: {
			id: "VB18",
			title: "VB18 - Depth of Water at Berth",
			type: "PERMIT",
		},
		VB01E: {
			id: "VB01E",
			title: "VB01E - Checklist for Passage Planning",
			type: "PERMIT",
		},
		PT34: {
			id: "PT34",
			title: "PT34 - New Building Takeover Checklist",
			type: "PERMIT",
		},
		VO55: {
			id: "VO55",
			title: "VO55 - Port Watch Changeover Checklist",
			type: "PERMIT",
		},
		VQS18: {
			id: "VQS18",
			title:
				"VQS18 - Stern Side Ramp Operation Checklist (Hoisting) [PCC CHECKLIST 2… (FOR PCC EXCL. 3,500 UTS PCC)]",
			type: "PERMIT",
		},
		VB01D: {
			id: "VB01D",
			title: "VB01D - Checklist for Bridge Team Management",
			type: "PERMIT",
		},
		VO05: {
			id: "VO05",
			title: "VO05 - OFF-HIRE STATEMENT",
			type: "PERMIT",
		},
		VB09: {
			id: "VB09",
			title: "VB09 - Master Pilot Exchange (Pilot Card)",
			type: "PERMIT",
		},
		VOOT68: {
			id: "VOOT68",
			title: "VOOT68 - IGS Condition Checklist",
			type: "PERMIT",
		},
		VDF06A: {
			id: "VDF06A",
			title: "VDF06A - LNG Bunker Tank Monitoring Log",
			type: "PERMIT",
		},
		VQS14B: {
			id: "VQS14B",
			title: "VQS14B - Ship Shore Safety Checklist Bulk-carrier",
			type: "PERMIT",
		},
		VOT59: {
			id: "VOT59",
			title: "VOT59 - STS Questionnaire(Cargo-Transfer)",
			type: "PERMIT",
		},
		VQS04A: {
			id: "VQS04A",
			title: "VQS04A - Enclosed Space Entry Preparation",
			type: "PERMIT",
		},
		VO67: {
			id: "VO67",
			title: "VO67 - Bunkering Safety Checklist",
			type: "PERMIT",
		},
		VO49: {
			id: "VO49",
			title: "VO49 - BALLAST WATER REPORTING FORM",
			type: "PERMIT",
		},
		W01: {
			id: "W01",
			title: "Wayship Familiarization Checklist",
			type: "PERMIT",
		},
		VOT53: {
			id: "VOT53",
			title: "VOT53 - During Cargo Operations Checklist",
			type: "PERMIT",
		},
		VDF04AB: {
			id: "VDF04AB",
			title: "VDF04 - LNG Bunkering Checklist ( A & B )",
			type: "PERMIT",
		},
		MC15: {
			id: "MC15",
			title: "MC15 - Crew Liberian Documents Status",
			type: "PERMIT",
		},
		VQS14_GTF: {
			id: "VQS14_GTF",
			title: "VQS14 - Ship-Shore Safety Checklist (Gas Tanker Full)",
			type: "PERMIT",
		},
		VQS19: {
			id: "VQS19",
			title:
				"VQS19 - Stern Side Ramp Operation Checklist (Lowering) [PCC CHECKLIST 3…(FOR 4,300 PCTC)]",
			type: "PERMIT",
		},
		VO16: {
			id: "VO16",
			title: "VO16 - Authority To Sign Bill Of Landing",
			type: "PERMIT",
		},
		VQS01: {
			id: "VQS01",
			title: "VQS01 - Common Permit to Work",
			type: "PERMIT",
		},
		PC13A: {
			id: "PC13A",
			title: "PC13A - Sign On Checklist",
			type: "PERMIT",
		},
		PC13B: {
			id: "PC13B",
			title: "PC13B - Sign Off Checklist",
			type: "PERMIT",
		},
		VO18: {
			id: "VO18",
			title: "VO18 - Notice of Readiness",
			type: "PERMIT",
		},
		VQS14_OTICT: {
			id: "VQS14_OTICT",
			title: "VQS14 - Ship-Shore Safety Checklist (Oil Tanker IG COW and TC)",
			type: "PERMIT",
		},
		VQS14_CTNIT: {
			id: "VQS14_CTNIT",
			title: "VQS14 - Ship-Shore Safety Checklist (Chem Tanker No IG No TC)",
			type: "PERMIT",
		},
		VQS14_PACO: {
			id: "VQS14_PACO",
			title: "VQS14 - Ship-Shore Safety Checklist (Pre- Arrival Checks only)",
			type: "PERMIT",
		},
		VQS15: {
			id: "VQS15",
			title: "VQS15 - Pre-arrival Anti Pollution & Safety Checklist",
			type: "PERMIT",
		},
		VQS14_FC: {
			id: "VQS14_FC",
			title: "VQS14 - Ship-Shore Safety Checklist (Full Checklist)",
			type: "PERMIT",
		},
		VDF04E: {
			id: "VDF04E",
			title:
				"VDF04E - LNG Bunkering Checklist (Part-E:  After LNG Transfer Checklist)",
			type: "PERMIT",
		},
		VO17: {
			id: "VO17",
			title: "VO17 - Authority to Sign BL - Time Charter",
			type: "PERMIT",
		},
		VQS14_CTIT: {
			id: "VQS14_CTIT",
			title: "VQS14 - Ship-Shore Safety Checklist (Chem Tanker IG and TC)",
			type: "PERMIT",
		},
		VQS14_OTTNIC: {
			id: "VQS14_OTTNIC",
			title: "VQS14 - Ship-Shore Safety Checklist (Oil Tanker TC No IG No COW)",
			type: "PERMIT",
		},
		VQS17: {
			id: "VQS17",
			title:
				"VQS17 - Stern Side Ramp Operation Checklist (Lowering) [PCC CHECKLIST 1… (FOR PCC EXCL. 3,500 UTS PCC)]",
			type: "PERMIT",
		},
		VDF10: {
			id: "VDF10",
			title: "VDF10 - Safety & Security Familiarization for Shore Personnel",
			type: "PERMIT",
		},
		VQS14_RCO: {
			id: "VQS14_RCO",
			title: "VQS14 - Ship-Shore Safety Checklist (Repetitive Checks Only)",
			type: "PERMIT",
		},
		PQS02: {
			id: "PQS02",
			title: "PQS02 - Work Plan For Proposed Hotwork",
			type: "PERMIT",
		},
		VDF04CD: {
			id: "VDF04CD",
			title: "VDF04 - LNG Bunkering Checklist ( C & D )",
			type: "PERMIT",
		},
		VO26: {
			id: "VO26",
			title: "VO26 - Tanker Loading Summary",
			type: "PERMIT",
		},
		VB10: {
			id: "VB10",
			title: "VB10 - Watch Change Over and Bridge Daily Checklists",
			type: "PERMIT",
		},
		portLogConfig: {
			id: "portLogConfig",
			title: "Port Log",
			type: "LOG",
		},
		bridgeOrderConfig: {
			id: "bridgeOrderConfig",
			title: "Bridge Order Book",
			type: "LOG",
		},
		compassLogConfig: {
			id: "compassLogConfig",
			title: "Compass Error Log",
			type: "LOG",
		},
		deckLogConfig: {
			id: "deckLogConfig",
			title: "Deck Log",
			type: "LOG",
		},
		engineLogConfig: {
			id: "engineLogConfig",
			title: "Engine Log",
			type: "LOG",
		},
		radarLogConfig: {
			id: "radarLogConfig",
			title: "Radar Log",
			type: "LOG",
		},
		gmdssRadioConfig: {
			id: "gmdssRadioConfig",
			title: "GMDSS Radio Log",
			type: "LOG",
		},
	},
	ids: [
		"VDF01",
		"PC18A",
		"PT25",
		"VOT56B",
		"VDF02",
		"VOT80",
		"VO68",
		"PC18",
		"VO02",
		"VDF06E",
		"VDF06B",
		"PC44",
		"VO81",
		"VOT60",
		"VO67A",
		"VQS22C",
		"VOT01",
		"VOT58",
		"VDF06C",
		"VDF03",
		"VTC103",
		"VOT56_CH_1",
		"VOT56A",
		"VO66",
		"VDF06D",
		"PQS03",
		"VB13",
		"VB04",
		"VTG53",
		"VB21",
		"VTC03",
		"PTT08",
		"VB05",
		"VB16",
		"VOOT03",
		"PT33",
		"PM02",
		"PT32",
		"VO01",
		"VB07",
		"VOT54",
		"VB19",
		"VO63",
		"VTC112",
		"VQS11",
		"VT06",
		"VT101",
		"VTG06",
		"VB23",
		"VB11",
		"VO67B",
		"VTG54",
		"PT31",
		"VB22",
		"VB16E",
		"VB03",
		"VT102",
		"VQS21",
		"VT07",
		"VB02",
		"PT28",
		"VDF09",
		"VT112",
		"PQS01",
		"VQS26",
		"PC13",
		"VB06",
		"VO72",
		"VB10R",
		"PT22",
		"VQS20",
		"VB24",
		"PT23",
		"VQS25",
		"VTG07",
		"VQS05A",
		"VB18",
		"VB01E",
		"PT34",
		"VO55",
		"VQS18",
		"VB01D",
		"VO05",
		"VB09",
		"VOOT68",
		"VDF06A",
		"VQS14B",
		"VOT59",
		"VQS04A",
		"VO67",
		"VO49",
		"W01",
		"VOT53",
		"VDF04AB",
		"MC15",
		"VQS14_GTF",
		"VQS19",
		"VO16",
		"VQS01",
		"PC13A",
		"PC13B",
		"VO18",
		"VQS14_OTICT",
		"VQS14_CTNIT",
		"VQS14_PACO",
		"VQS15",
		"VQS14_FC",
		"VDF04E",
		"VO17",
		"VQS14_CTIT",
		"VQS14_OTTNIC",
		"VQS17",
		"VDF10",
		"VQS04A",
		"VQS14_RCO",
		"PQS02",
		"VDF04CD",
		"VO26",
		"VB10",
		"portLogConfig",
		"bridgeOrderConfig",
		"compassLogConfig",
		"deckLogConfig",
		"engineLogConfig",
		"radarLogConfig",
		"gmdssRadioConfig",
	],
};
