import React, { useEffect, useState } from "react";
import { rootRoutes } from "../../../routes";
import { historyActions } from "../../../redux/_core/history";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteProps } from "react-router-dom";
import SVGVessel from "../../_common/icons/SVGVessel";
import SVGLogs from "../../_common/icons/SVGLog";
import classNames from "classnames";
import { IStore } from "redux/store";
import SVGBook from "components/_common/icons/SVGBook";
import SVGUsers from "components/_common/icons/SVGUsers";
import SVGReport from "components/_common/icons/SVGReport";
import { authSelectors } from "redux/app/auth";
import SVGDashboard from "components/_common/icons/SVGDashboard";
import SVGDashboardNew from "components/_common/icons/SVGDashboardNew";
import SVGTools from "components/_common/icons/SVGTools";
import SVGInsight from "components/_common/icons/insight/SVGInsight";

const { vessel, feedback, users, dashboard, reports, tools, insights } =
	rootRoutes.children.app.children;

type SidebarType = PropsFromRedux &
	RouteProps & {
		push: (route: string) => void;
	};
function Sidebar(
	props: SidebarType & { handleMobileNavbarClick?: () => void }
) {
	const {
		push,
		location,
		handleMobileNavbarClick,
		isUserAllowedToAccessFeedbackModule,
	} = props;

	const [currentActiveLink, setCurrentActiveLink] = useState(
		"dashboardNew-sidebar"
	);

	useEffect(() => {
		const activeLinkElement = document.getElementById(currentActiveLink);
		activeLinkElement?.scrollIntoView({ behavior: "smooth" });
	}, [currentActiveLink]);

	const links = [
		{
			Icon: SVGDashboardNew,
			text: "Home",
			go: () => push(dashboard.children.list.url()),
			path: dashboard.path,
			isBluePrintIcon: true,
			id: "dashboardNew-sidebar",
		},
		{
			Icon: SVGVessel,
			text: "Vessels",
			go: () => push(vessel.children.list.url()),
			path: vessel.path,
			isBluePrintIcon: false,
			id: "vessels-sidebar",
		},
		{
			Icon: SVGInsight,
			text: "Insights",
			go: () => push(insights.url()),
			path: insights.path,
			isBluePrintIcon: false,
			id: "insights-sidebar",
		},
		{
			Icon: SVGReport,
			text: "Reports",
			go: () => push(reports.children.categories.url()),
			path: reports.children.categories.path,
			isBluePrintIcon: false,
			id: "reports-sidebar",
		},
		// {
		// 	Icon: SVGLogs,
		// 	text: "Approvals",
		// 	go: () => push(approval.children.list.url()),
		// 	path: approval.path,
		// 	isBluePrintIcon: false,
		// },
		// {
		// 	Icon: SVGLogs,
		// 	text: "Checklists",
		// 	go: () => push(checklist.childrens.list.url()),
		// 	path: checklist.path,
		// 	isBluePrintIcon: false,
		// },
		// {
		// 	Icon: SVGLogs,
		// 	text: "MARPOL",
		// 	go: () => push(marpol.children.list.url()),
		// 	path: marpol.path,
		// 	isBluePrintIcon: false,
		// },
	];

	if (isUserAllowedToAccessFeedbackModule) {
		links.push({
			Icon: SVGBook,
			text: "Feedback",
			go: () => push(feedback.children.dashboard.url()),
			path: feedback.path,
			isBluePrintIcon: false,
			id: "feedback-sidebar",
		});
		links.push({
			Icon: SVGTools,
			text: "Tools",
			go: () => push(tools.children.list.url()),
			path: tools.path,
			isBluePrintIcon: false,
			id: "tools-sidebar",
		});
	}

	links.push({
		Icon: SVGUsers,
		text: "Users",
		go: () => push(users.children.list.url()),
		path: users.path,
		isBluePrintIcon: false,
		id: "users-sidebar",
	});

	return (
		<div className="flex flex-col">
			{links.map((link, index) => {
				const isActive = location?.pathname.includes(link.path);
				return (
					<div
						key={index}
						id={link?.id ?? ""}
						onClick={() => {
							link.go();
							handleMobileNavbarClick && handleMobileNavbarClick();
							setCurrentActiveLink(link.id);
						}}
						className={classNames(
							"flex flex-col items-center justify-start py-2 rounded-sm cursor-pointer ",
							{
								// "bg-blue-100 ": isActive,
								"bg-wayshipBlue": !isActive,
							}
						)}
					>
						<link.Icon
							className={classNames("w-12 h-12 rounded-full p-2", {
								"flex flex-row items-center justify-center select-none text-wayshipBlue":
									link.isBluePrintIcon,
								// "text-gray-600":
								// 	!isActive && !link.isBluePrintIcon,
								// "text-wayshipBlue":
								// 	isActive && !link.isBluePrintIcon,
								// "text-gray-500":
								// 	!isActive && link.isBluePrintIcon,
								// "text-gray-700":
								// 	isActive && link.isBluePrintIcon,
								"bg-blue-100": isActive,
								"text-blue-100": !isActive,
							})}
						/>
						<div
							className={classNames(
								"text-sm md:text-md font-redhat-text select-none text-blue-100 font-medium py-2"
								// {
								// 	"text-blue-100 font-medium": isActive,
								// 	"text-gray-600 font-medium": !isActive,
								// }
							)}
						>
							{link.text}
						</div>
					</div>
				);
			})}
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const authStore = store.app.auth;
	const userEmail = authStore.user?.email;
	const isUserAllowedToAccessFeedbackModule =
		authSelectors(authStore).isUserAllowedToAccessModule("feedback");
	return {
		userEmail,
		isUserAllowedToAccessFeedbackModule,
	};
}

const mapDispatchToProps = (dispatch: any) => ({
	push: (url: string) => dispatch(historyActions.push("SIDEBAR", url)),
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default withRouter(reduxConnector(Sidebar));
