import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../../redux/store";
import { getYearOptions } from "redux/app/report/utils";
import reportSelectors from "redux/app/report/selectors";
import YearPicker from "../../individualreport/reportType/garbage/_elements/YearPicker";
import reportActions from "redux/app/report/actions";
import classNames from "classnames";
import VesselSelector from "components/app/report/common/VesselSelector";
import Graph from "./_elements/Graph";
import useElementSize from "hooks/useElementSize";
import Table from "./_elements/Table";
import { vesselActions } from "redux/app/vessel";

type OwnProps = {};
type BilgeWaterProps = PropsFromRedux & OwnProps;
function BilgeWater(props: BilgeWaterProps) {
	const {
		year,
		vesselId,
		jobToLoadVesselTenantBilgeReport,
		jobToGetVesselSpecificBilgeReport,
	} = props;
	const reportContainerRef = useRef<HTMLDivElement>(null);
	const [isGraphView, setIsGraphView] = useState(true);
	const { width: reportContainerWidth } = useElementSize(reportContainerRef);

	useEffect(() => {
		props.loadVessels();
		props.setVesselId("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (vesselId) props.loadGarbageReportData();
		else {
			props.loadBilgeWaterAggregateForAllVessels();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, year]);

	return (
		<div
			ref={reportContainerRef}
			className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto"
		>
			<div className="">
				<div className="px-5 py-8">
					<div className="flex items-center justify-between">
						<div className="flex items-center">
							<span
								title="go back"
								onClick={() => {
									window?.history?.back();
								}}
								className="bp3-icon bp3-icon-arrow-left text-lg mr-3 cursor-pointer"
							/>
							<div className="flex  items-baseline">
								<h2 className="text-xl font-medium">Bilge Water</h2>
								{isGraphView && (
									<>
										<div className="ml-4 text-base">
											<YearPicker
												options={getYearOptions().map((op) => ({
													label: op.toString(),
													value: op.toString(),
												}))}
												value={year}
												id="garbage-year-picker"
												onSelect={(option) => {
													props.setYear(option?.value ?? "");
												}}
												isLoading={
													jobToLoadVesselTenantBilgeReport.isLoading ||
													jobToGetVesselSpecificBilgeReport.isLoading
												}
											/>
										</div>
										<div className="pl-4 min-w-28 md:w-56">
											<VesselSelector
												onVesselSelect={(vesselId: string) => {
													props.setVesselId(vesselId);
												}}
												isLoading={
													jobToLoadVesselTenantBilgeReport.isLoading ||
													jobToGetVesselSpecificBilgeReport.isLoading
												}
												selectedVesselId={vesselId}
												showDropdownLabel={false}
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<div>
							<div className="p-0">
								<div className="border-gray-200 ">
									<div className="flex flex-row justify-around py-1 my-2 rounded-md bg-lightGray">
										<button
											className={classNames(
												"transition-all duration-300 px-10 text-[12px]",
												{
													"p-2 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
														isGraphView,
													"p-2 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
														!isGraphView,
												}
											)}
											onClick={() => setIsGraphView(true)}
										>
											Graph
										</button>
										<button
											className={classNames(
												"transition-all duration-300 px-10 text-[12px]",
												{
													"py-1.5 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
														!isGraphView,
													"py-1.5 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
														isGraphView,
												}
											)}
											onClick={() => {
												setIsGraphView(false);
											}}
										>
											Table
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isGraphView ? (
				<Graph reportContainerWidth={reportContainerWidth} />
			) : (
				<Table year={year} />
			)}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getBilgeWaterVesselId(reportStore);
	const year = reportSelectors.getBilgeWaterYear(reportStore);
	const jobToGetVesselSpecificBilgeReport =
		reportSelectors.jobToLoadGarbageReport(reportStore);
	const jobToLoadVesselTenantBilgeReport =
		reportSelectors.jobToLoadVesselTenantBilgeReport(reportStore);

	return {
		vesselId,
		year,
		jobToLoadVesselTenantBilgeReport,
		jobToGetVesselSpecificBilgeReport,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		loadGarbageReportData() {
			dispatch(reportActions.commands.bilgeWaterReportLoad());
		},
		loadBilgeWaterAggregateForAllVessels() {
			dispatch(reportActions.commands.bilgeWaterAllVesselsAggregateLoad());
		},
		setVesselId(vesselId: string) {
			dispatch(reportActions.document.bilgeWaterReportVesselIdSet(vesselId));
		},
		setYear(year: string) {
			dispatch(reportActions.document.bilgeWaterReportYearSet(year));
		},
		// setAggregateBilgeApiCallEnded() {
		// 	dispatch(
		// 		reportActions.document.bilgeWaterIsApiCallForAllVesselsEnded(false)
		// 	);
		// },
		// resetQuarterLabels() {
		// 	dispatch(
		// 		reportActions.document.bilgeWaterQuarterLabelsSet([
		// 			...initialQuarterLabels,
		// 		])
		// 	);
		// },
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(BilgeWater);
