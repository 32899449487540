import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TBallastRecord } from "../../../../../redux/app/ballast/@types";
import { TBallast_Internal } from "../../../../../redux/app/ballast/groups/internal";
import { commonUtils } from "../../../../../redux/_common";
import CommaSeparator from "../../../../_common/CommaSeparator";
import BallastTableRows from "../BallastTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type InternalOperationPreviewProps = OwnProps;
function InternalOperationPreview(props: InternalOperationPreviewProps) {
	const record = props.ballastRecord as TBallast_Internal;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<BallastTableRows
				record={record}
				tableRows={[
					[operationDate, "3.2.1", renderItem3P2P1()],
					[null, "3.2.2", renderItem3P2P2()],
					[null, "3.2.3", renderItem3P2P3()],
					[null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem3P2P1() {
		return (
			<h4 className="font-medium uppercase">
				{validatePlaceOfBallastPreview(record)}
			</h4>
		);
	}
	function renderItem3P2P2() {
		return (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={[
						...record.destinationTanks.map(
							({ selectedTank, transferQuantity }) =>
								`Volume Circulated ${transferQuantity}M³ from ${record.sourceTank.selectedTank.tankName} to ${selectedTank.tankName}`
						),
					]}
					customSeperator={<br />}
				/>
			</h4>
		);
	}
	function renderItem3P2P3() {
		return (
			<h4 className="font-medium uppercase">
				Yes (Internal transfer between Ballast tanks)
			</h4>
		);
	}

	const validatePlaceOfBallastPreview = (record: TBallast_Internal) => {
		if (record.placeOfBallast) {
			if (record.placeOfBallast.place === "IN_PORT") {
				return (
					<>
						{record.sourceTank.startDate}, {record.sourceTank.startTime} LT to{" "}
						{
							record.destinationTanks[record.destinationTanks.length - 1]
								.endDate
						}
						,{" "}
						{
							record.destinationTanks[record.destinationTanks.length - 1]
								.endTime
						}{" "}
						LT at {record.placeOfBallast.portName}
					</>
				);
			} else if (record.placeOfBallast.place === "AT_SEA") {
				return (
					<>
						{record.sourceTank.startDate}, {record.sourceTank.startTime} LT{" "}
						{record.placeOfBallast.startPosition.latitude},{" "}
						{record.placeOfBallast.startPosition.longitude}, to{" "}
						{
							record.destinationTanks[record.destinationTanks.length - 1]
								.endDate
						}
						,{" "}
						{
							record.destinationTanks[record.destinationTanks.length - 1]
								.endTime
						}{" "}
						LT {record.placeOfBallast.endPosition.latitude},
						{record.placeOfBallast.endPosition.longitude}
					</>
				);
			}
		} else {
			return (
				<>
					{record.sourceTank.startDate}, {record.sourceTank.startTime} LT to{" "}
					{record.destinationTanks[record.destinationTanks.length - 1].endDate},{" "}
					{record.destinationTanks[record.destinationTanks.length - 1].endTime}{" "}
					LT
				</>
			);
		}
	};

	return renderMain();
}

export default InternalOperationPreview;
