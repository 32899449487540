import React, { FC, useEffect, useRef } from 'react';
import NotificationIcon from "../../../../../assets/icons/notification-bell.svg";
import NotificationsWrapper from './NotificationsWrapper';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from 'redux/store';
import { Dispatch } from 'redux';
import feedbackActions from 'redux/app/feedback/actions';
import feedbackSelectors from 'redux/app/feedback/selectors';

type NotificationProps = {
	popoverVisible: boolean;
} & PropsFromRedux;

const Notification: FC<NotificationProps> = (props) => {
	const { popoverVisible, notificationsPagination } = props;
	const [showPopover, setShowPopover] = React.useState(false);
	const popoverRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		props.loadNotifications(notificationsPagination?.currentPage || 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationsPagination?.currentPage]);

	const handleClickOutside = (event: MouseEvent) => {
		if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
			setShowPopover(false);
		}
	};

	useEffect(() => {
		if (showPopover) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showPopover]);

	return (
		<div className='relative w-12 h-12'>
			<div
				className="rounded-full w-12 h-12 bg-[#F0F0F1] flex justify-center items-center cursor-pointer relative"
				onClick={() => {
					setShowPopover(true);
				}}
			>
				<img
					src={NotificationIcon}
					alt="notification-icon"
				/>
			</div>
			{
				showPopover && popoverVisible ?
					<div
						ref={popoverRef}
						style={{
							'boxShadow': 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
						}}
						className='bg-white rounded-xl w-[375px] absolute top-[52px] right-0 z-50'
					>
						<NotificationsWrapper
							loading={props.jobToLoadNotifications.isLoading}
							notifications={props.notifications}
							onShowMore={() => {
								props.notificationsPaginationCurrentPageSet((notificationsPagination.currentPage || 1) + 1);
							}}
						/>
					</div>
					: null
			}
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const feedbackStore = store.app.feedback;

	return {
		notifications: feedbackSelectors.getNotifications(feedbackStore),
		notificationsPagination: feedbackSelectors.getNotificationsPagination(feedbackStore),
		jobToLoadNotifications: feedbackSelectors.jobToLoadNotifications(feedbackStore),
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadNotifications(pageNumber: number) {
			dispatch(feedbackActions.commands.notificationsLoad(pageNumber));
		},
		notificationsPaginationCurrentPageSet(pageNumber: number) {
			dispatch(feedbackActions.document.notificationsPaginationCurrentPageSet(pageNumber));
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Notification);
