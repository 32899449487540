import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ApprovalUI from "../../../components/app/approval/Approval";
import { rootRoutes } from "../../../routes";
import ApprovalList from "./list";

const approvalRoutes = rootRoutes.children.app.children.approval.children;
type ApprovalProps = React.PropsWithChildren<{}>;
function Approval(props: ApprovalProps) {
	return (
		<ApprovalUI>
			<Switch>
				<Route path={approvalRoutes.list.path} component={ApprovalList} />
				<Route render={RedirectComponent} />
			</Switch>
		</ApprovalUI>
	);
}

function RedirectComponent() {
	return <Redirect to={approvalRoutes.list.url()} />;
}
export default Approval;
