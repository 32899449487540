import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "../../redux/store";
import { Dispatch } from "redux";
import { extActions } from "../../redux/app/ext";
import Button from "../../@set-product/core/button/Button";
import PermitSignModal from "./permitSignModal";
// @ts-ignore
import { toDate } from 'date-fns/esm';
import Modal from "../../components/app/_common/Modal";
import { rootRoutes } from '../../routes';
import { historyActions } from '../../redux/_core/history';

const tableJson = [
	{
		index: 1,
		qTxt: `The barge has obtained the necessary permissions to go alongside
	receiving vessel.`,
		subIndex: -1,
		id: "",
		ship: {
			value: "ship",
			id: "cl_tec_01_tbho_tnpt_garv_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tbho_tnpt_garv_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_tbho_tnpt_garv_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tbho_tnpt_garv_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tbho_tnpt_garv_brg_rmks::remote",
		}
	},
	{
		index: 2,
		subIndex: 0,
		qTxt: `The fenders have been checked, are in good order and there is no
		possibility of metal to metal contact.`,
		id: "cl_tec_01_tfhb_cago_tnp_mmc",
		ship: {
			value: "",
			id: "cl_tec_01_tfhb_cago_tnp_mmc_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tfhb_cago_tnp_mmc_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tfhb_cago_tnp_mmc_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tfhb_cago_tnp_mmc_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 3,
		qTxt: `Adequate electrical insulating means are in place in the barge/ship
		connection.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_aeim_aipi_tbsc_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_aeim_aipi_tbsc_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_aeim_aipi_tbsc_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_aeim_aipi_tbsc_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_aeim_aipi_tbsc_brg_rmks::remote",
		},
	},
	{
		index: 4,
		qTxt: `All bunker hoses are in good condition and are appropriate for the service
	intended.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_abha_igca_afsi_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_abha_igca_afsi_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_abha_igca_afsi_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_abha_igca_afsi_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_abha_igca_afsi_brg_rmks::remote",
		},
	},
	{
		index: 5,
		qTxt: `The barge is securely moored.`,
		id: "cl_tec_01_tbrg_sec_mrd",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_tbrg_sec_mrd_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tbrg_sec_mrd_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tbrg_sec_mrd_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tbrg_sec_mrd_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 6,
		qTxt: `There is a safe means of access between the ship and barge`,
		id: "cl_tec_01_tias_moab_tsab",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_tias_moab_tsab_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tias_moab_tsab_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tias_moab_tsab_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tias_moab_tsab_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 7,
		qTxt: `Effective communications have been established between responsible officers`,
		id: "cl_tec_01_echb_ebro_lhsvhf",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_echb_ebro_lhsvhf_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_echb_ebro_lhsvhf_b::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "(VHF/UHF/Ch _____):",
					"key": "cl_tec_01_echb_ebro_lhsvhf_r_vuc",
					"type": "string"
				},
				{
					"name": "Primary System:",
					"key": "cl_tec_01_echb_ebro_lhsvhf_r_ps",
					"type": "string"
				},
				{
					"name": "Backup System:",
					"key": "cl_tec_01_echb_ebro_lhsvhf_r_bs",
					"type": "string"
				},
				{
					"name": "Emergency Stop Signal:",
					"key": "cl_tec_01_echb_ebro_lhsvhf_r_ess",
					"type": "string"
				},
			],
			id: "cl_tec_01_echb_ebro_lhsvhf_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_echb_ebro_lhsvhf_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 8,
		qTxt: `There is an effective watch on board the barge and ship receiving bunkers.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_tiae_wobt_bsrb_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tiae_wobt_bsrb_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_tiae_wobt_bsrb_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tiae_wobt_bsrb_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tiae_wobt_bsrb_brg_rmks::remote",
		},
	},
	{
		index: 9,
		qTxt: `Fire hoses and firefighting equipment on board the barge and ship are ready for immediate use.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_fhff_eobb_sriu_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_fhff_eobb_sriu_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_fhff_eobb_sriu_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_fhff_eobb_sriu_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_fhff_eobb_sriu_brg_rmks::remote",
		},
	},
	{
		index: 10,
		qTxt: `All scuppers are effectively plugged. Temporarily removed scupper plugs will be monitored at all times. Drip trays are in position on decks around connections and bunker tank vents.`,
		id: "cl_tec_01_asep_trsp_wbmt_dpdac",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_asep_trsp_wbmt_dpdac_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_asep_trsp_wbmt_dpdac_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_asep_trsp_wbmt_dpdac_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_asep_trsp_wbmt_dpdac_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 11,
		qTxt: `Unused bunker connections are blanked and fully bolted.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_uubr_conb_fubd_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_uubr_conb_fubd_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_uubr_conb_fubd_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_uubr_conb_fubd_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_uubr_conb_fubd_brg_rmks::remote",
		},
	},
	{
		index: 12,
		qTxt: `The transfer hose is properly rigged and fully bolted and secured to manifolds on ship and barge`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_tthi_prfb_smsb_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tthi_prfb_smsb_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_tthi_prfb_smsb_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tthi_prfb_smsb_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tthi_prfb_smsb_brg_rmks::remote",
		},
	},
	{
		index: 13,
		qTxt: `Overboard valves connected to the cargo system, engine room bilge's and bunker lines are closed and sealed.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_obvc_tcse_rbbcs_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_obvc_tcse_rbbcs_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_obvc_tcse_rbbcs_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_obvc_tcse_rbbcs_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_obvc_tcse_rbbcs_brg_rmks::remote",
		},
	},
	{
		index: 14,
		qTxt: `All cargo and bunker tank hatch lids are closed.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_acrg_bktk_hlac_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_acrg_bktk_hlac_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_acrg_bktk_hlac_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_acrg_bktk_hlac_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_acrg_bktk_hlac_brg_rmks::remote",
		},
	},
	{
		index: 15,
		qTxt: `Bunker tank contents will be monitored at regular intervals.`,
		id: "cl_tec_01_btcnt_wbmd_aris",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_btcnt_wbmd_aris_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_btcnt_wbmd_aris_b::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_btcnt_wbmd_aris_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_btcnt_wbmd_aris_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 16,
		qTxt: `There is a supply of oil spill cleanup material readily available for immediate use.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_tias_oscm_raiu_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tias_oscm_raiu_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_tias_oscm_raiu_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tias_oscm_raiu_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tias_oscm_raiu_brg_rmks::remote",
		},
	},
	{
		index: 17,
		qTxt: `The main radio transmitter aerials are earthed, and radars are switched off.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_tmrt_aaed_arso_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tmrt_aaed_arso_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_tmrt_aaed_arso_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tmrt_aaed_arso_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tmrt_aaed_arso_brg_rmks::remote",
		},
	},
	{
		index: 18,
		qTxt: `The VHF / AIS on the correct power mode or switched off?`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_tvhf_aist_cpms_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_tvhf_aist_cpms_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_tvhf_aist_cpms_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_tvhf_aist_cpms_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_tvhf_aist_cpms_brg_rmks::remote",
		},
	},
	{
		index: 19,
		qTxt: `Smoking rooms have been identified and smoking restrictions are being observed.`,
		id: "cl_tec_01_srhb_idas_rabod",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_srhb_idas_rabod_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_srhb_idas_rabod_b::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "Nominated Smoking Rooms:",
					"key": "cl_tec_01_srhb_idas_rabod_r_nsr",
					"type": "string"
				},
			],
			id: "cl_tec_01_srhb_idas_rabod_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_srhb_idas_rabod_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 20,
		qTxt: `Naked Light regulations are being observed.`,
		id: "cl_tec_01_nkdl_regs_abobd",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_nkdl_regs_abobd_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_nkdl_regs_abobd_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_nkdl_regs_abobd_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_nkdl_regs_abobd_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 21,
		qTxt: `All external doors and ports in the accommodation are closed.`,
		id: "cl_tec_01_aexnl_dapi_aacd",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_aexnl_dapi_aacd_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_aexnl_dapi_aacd_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_aexnl_dapi_aacd_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_aexnl_dapi_aacd_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 22,
		qTxt: `MSDS for the Bunker transferred have been exchanged where requested./ available onboard? If not LOP to be issued.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_msds_btbe_wrao_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_msds_btbe_wrao_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_msds_btbe_wrao_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_msds_btbe_wrao_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_msds_btbe_wrao_barge_rmks::remote",
		},
	},
	{
		index: 23,
		qTxt: `The hazards associated with toxic substances in the bunkers being handled have been identified and understood. (Initial reading may be noted either from barge/ supplier or MSDS; however repeated gas checks have to be physically verified on board)`,
		id: "cl_tec_01_thaw_tsbb_hhbi_hrpvb",
		subIndex: 0,
		ship: {
			value: "",
			id: "cl_tec_01_thaw_tsbb_hhbi_hrpvb_s",
		},
		barge: {
			value: "",
			id: "cl_tec_01_thaw_tsbb_hhbi_hrpvb_b::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "H2S (Initial):",
					"key": "cl_tec_01_thaw_tsbb_hhbi_hrpvb_r_h2si",
					"type": "string"
				},
				{
					"name": "Benzene Content:",
					"key": "cl_tec_01_thaw_tsbb_hhbi_hrpvb_r_bc",
					"type": "string"
				},
			],
			id: "cl_tec_01_thaw_tsbb_hhbi_hrpvb_r",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_thaw_tsbb_hhbi_hrpvb_brg_r::remote",
		},
		rep: true,
	},
	{
		index: 24,
		qTxt: `Has method of line clearing between ship / barge (shore) on completion of bunkering agreed?`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_hmol_cbsb_coba_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_hmol_cbsb_coba_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_hmol_cbsb_coba_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_hmol_cbsb_coba_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_hmol_cbsb_coba_brg_rmks::remote",
		},
	},
	{
		index: 25,
		qTxt: `High level alarms if fitted to be tested prior to bunker operation and to be kept on throughout the operation`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_hlai_ftpb_otto_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_hlai_ftpb_otto_brg::remote",
		},
		code: {
			value: "",
			id: "cl_tec_01_hlai_ftpb_otto_code",
		},
		remarks: {
			value: [],
			id: "cl_tec_01_hlai_ftpb_otto_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_hlai_ftpb_otto_brg_rmks::remote",
		},
	},
	{
		index: 26,
		qTxt: `The maximum wind and swell criteria for operations have been agreed.`,
		id: "",
		subIndex: -1,
		ship: {
			value: "",
			id: "cl_tec_01_maxw_swca_ohbad_wdsl_shp",
		},
		barge: {
			value: "",
			id: "cl_tec_01_maxw_swca_ohbad_wdsl_brg::remote",
		},
		code: {
			value: "A",
			id: "cl_tec_01_maxw_swca_ohbad_wdsl_code",
		},
		remarks: {
			value: null,
			id: "cl_tec_01_maxw_swca_ohbad_wdsl_rmks",
		},
		bremarks: {
			value: null,
			id: "cl_tec_01_maxw_swca_ohbad_wdsl_brg_rmks::remote",
		},
	},
];

const BunkerOps = (props: {
	extForm: any;
	updateData: any;
	updateTable: any;
}) => {
	const { extForm, updateData, updateTable } = props;
	const [shipOps, setShipOps] = useState(tableJson);

	useEffect(() => {
		const modTable = [...shipOps];
		modTable.forEach(function (d) {
			if (Array.isArray(extForm?.data?.[d.id]) && d.code.value.includes("R")) {
				if(extForm?.data?.[d.id].length > 1) {
					extForm?.data?.[d.id].forEach((element: any, index: number) => {
						if(extForm?.data?.[d.id].length-1 == index) {
							modTable[modTable.indexOf(d)].subIndex = extForm?.data?.[d.id].length-1
						} else {
							modTable.splice(modTable.indexOf(d)+1, 0, {
								...d,
								subIndex: index,
								code: {
									value: "",
									id: "",
								},
							});
						}
						
					});
				}
			}
		});
		setShipOps(modTable)

	}, [])

	const _ui = shipOps.map((d:any, index) => {
		return (
			<tr>
				<td className="text-center border border-blue-200">{d.index}</td>
				<td className="border border-blue-200">
					{d.qTxt}
					{d.index === 26 ? (
						<div>
							Stop Bunkering at:
							{/* <textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_sba"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_sba"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_sba",
										e.target.value
									);
								}}
							/> */}
							Wind
							<textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_sba_w"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_sba_w"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_sba_w",
										e.target.value
									);
								}}
							/>
							Swell
							<textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_sba_s"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_sba_s"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_sba_s",
										e.target.value
									);
								}}
							/>
							Disconnect at:
							{/* <textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_da"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_da"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_da",
										e.target.value
									);
								}}
							/> */}
							Wind
							<textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_da_w"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_da_w"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_da_w",
										e.target.value
									);
								}}
							/>
							Swell
							<textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_da_s"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_da_s"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_da_s",
										e.target.value
									);
								}}
							/>
							Unberth at:
							{/* <textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_u"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_u"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_u",
										e.target.value
									);
								}}
							/> */}
							Wind
							<textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_u_w"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_u_w"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_u_w",
										e.target.value
									);
								}}
							/>
							Swell
							<textarea
								disabled
								className="border border-blue-500"
								rows={1}
								cols={3}
								value={
									extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_u_s"]
										? extForm?.data?.["cl_tec_01_maxw_swca_ohbad_wdsl_u_s"]
										: ""
								}
								onChange={(e) => {
									e.preventDefault();
									updateData(
										"cl_tec_01_maxw_swca_ohbad_wdsl_u_s",
										e.target.value
									);
								}}
							/>
						</div>
					) : (
						""
					)}
				</td>
				<td className="border border-blue-200">
					<textarea
						className="w-full"
						disabled
						value={
							d.rep
								? extForm?.data?.[d.id]?.[d.subIndex]?.[d.ship.id]
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.ship.id]
									: ""
								: extForm?.data?.[d.ship.id]
								? extForm?.data?.[d.ship.id]
								: ""
						}
					/>
				</td>
				<td className="border border-blue-200">
					<textarea
						className="w-full"
						value={
							d.rep
								? extForm?.data?.[d.id]?.[d.subIndex]?.[d.barge.id]
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.barge.id]
									: ""
								: extForm?.data?.[d.barge.id]
								? extForm?.data?.[d.barge.id]
								: ""
						}
						onChange={(e) => {
							e.preventDefault();
							if (d.rep) {
								updateTable(d.barge.id, e.target.value, d.id, d.subIndex);
							} else {
								updateData(d.barge.id, e.target.value);
							}
						}}
					/>
				</td>
					<td className="text-center border border-blue-200">{d.code.value}</td>
				<td className="border border-blue-200">
					<textarea
						className="w-full"
						disabled
						value={
							d.rep
								? extForm?.data?.[d.id]?.[d.subIndex]?.[d.remarks.id]
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.remarks.id]
									: ""
								: extForm?.data?.[d.remarks.id]
								? extForm?.data?.[d.remarks.id]
								: ""
						}
						onChange={(e) => {
							e.preventDefault();
							if (d.rep) {
								updateTable(d.remarks.id, e.target.value, d.id, d.subIndex);
							} else {
								updateData(d.remarks.id, e.target.value);
							}
						}}
					/>
					{
						d?.remarks?.value && d?.remarks?.value?.length > 0 ? (
							<>
							{
							d?.remarks?.value?.map((re: any, index: number) => (
								<>
								<br/>
								{re.name}
								<input type="string"
									disabled 
								  	value={
									d.rep
										? extForm?.data?.[d.id]?.[d.subIndex]?.[re.key]
											? extForm?.data?.[d.id]?.[d.subIndex]?.[re.key]
											: ""
										: extForm?.data?.[re.key]
										? extForm?.data?.[re.key]
										: ""
								} onChange={(e) => {
									e.preventDefault();
									if (d.rep) {
										updateTable([re.key], e.target.value, d.id, d.subIndex);
									} else {
										updateData(re.key, e.target.value);
									}
								}}/></>
							))
							}
							</>
						) : ""
					}
				</td>
				<td className="border border-blue-200">
					<textarea
						className="w-full"
						value={
							d.rep
								? extForm?.data?.[d.id]?.[d.subIndex]?.[d.bremarks.id]
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.bremarks.id]
									: ""
								: extForm?.data?.[d.bremarks.id]
								? extForm?.data?.[d.bremarks.id]
								: ""
						}
						onChange={(e) => {
							e.preventDefault();
							if (d.rep) {
								updateTable(d.bremarks.id, e.target.value, d.id, d.subIndex);
							} else {
								updateData(d.bremarks.id, e.target.value);
							}
						}}
					/>
				</td>
			</tr>
		);
	});
	return <tbody>{_ui}</tbody>;
};

const CLTec01: React.FC<any> = (props: any) => {
	const { extForm } = props;

	const [isModalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if(extForm.id === undefined) {
			props.formRedirect()
		}
	}, []);

	function triggerSubmitOperation() {
		setModalOpen(true);
	}

	function resetOperations() {
		setModalOpen(false);
	}

	/**
	*This is to update store with key and value
	* @param key key of the attributes
	* @param val value to be set
	*/
	function updateData(key: string, val: string) {
		if (key) {
			const data = { ...extForm };
			data.data[key] = val;
			props.formFieldEdit({ ...data });
		}
	}

	/**
	 * This is the update table value in store
	 * @param key which key should be updated
	 * @param val value of the paramter
	 * @param parentKey parent element key
	 * @param index index in table
	 */
	function updateTable(
		key: string,
		val: string,
		parentKey: string,
		index: number
	) {
		const data = { ...extForm };
		if (key) {
			if (data?.data?.[parentKey]?.[index]?.[key]) {
				data.data[parentKey][index][key] = val;
			} else {
				if (data.data[parentKey] === undefined) {
					data.data[parentKey] = [];
				}
				if (data.data[parentKey].length === 0) {
					data.data[parentKey].push({});
					data.data[parentKey][index][key] = val;
				} else if(data.data[parentKey][index]) {
					data.data[parentKey][index][key] = val;
				} else {
					if(index > data.data[parentKey].length) {
						for(let i=data.data[parentKey].length-1; i < (index+1); i++ ) {
							data.data[parentKey].push({});
						}
					} else {
						data.data[parentKey].push({});
					}
					data.data[parentKey][index][key] = val;
				}
			}
			props.formFieldEdit({ ...data });
		}
	}
	return (
		<div className="w-full">
			<div className="m-20 text-lg">
				<div className="sm:mx-auto">
					<header className="bg-blue-500">
						<table className="container mb-1">
							<thead>
								<tr>
									<th className="p-2 text-white bg-blue-500">
										CL TEC 01 BUNKERING SAFETY CHECKLIST
									</th>
									<th className="bg-white"></th>
									<th className="p-2 text-white bg-blue-500">MSML</th>
								</tr>
							</thead>
						</table>
					</header>

					<div className="p-3 mt-5 font-bold text-center text-white bg-blue-500">
						BUNKERING OPERATIONS – SECTION 2
					</div>
					<div className="p-3 font-bold text-center text-white bg-blue-500">
						To be completed by Chief Engineer and Bunker Barge/ Terminal
						Representative
					</div>
					<div className="pl-5 font-bold bg-blue-300">
						Pumping rate and procedure to be followed during topping up of
						bunker tanks has been discussed, agreed and documented.
					</div>
					<div className="pl-5 font-bold bg-blue-300">
						Prior blowing through the lines, the bunker supplier shall inform
						the vessel and not proceed with the blow through till a clear advice
						is received from the vessel’s Chief Engineer.
					</div>
					<form
						action=""
						onSubmit={function onSubmit(e) {
							e.preventDefault();
							props.setFormSubmit(true)
							props.formSubmit();
						}}
					>
						{
							//console.log(" Location--->" + extForm?.data?.cl_tec_01_bos2_ccebb_l)
						}
						<div className="grid grid-cols-4">
							<div className="pl-5 border border-blue-300">Location</div>
							<div className="border border-blue-300">
								<textarea
									className="w-full"
									disabled
									value={
										extForm?.data?.cl_tec_01_bos2_ccebb_l
											? extForm?.data.cl_tec_01_bos2_ccebb_l
											: ""
									}
									onChange={function onChange(e) {
										e.preventDefault();
										updateData("cl_tec_01_bos2_ccebb_l", e.target.value);
									}}
								/>
							</div>

							<div className="border border-blue-300">Date</div>
							<div className="pl-5 border border-blue-300">
								<input
									type="date"
									disabled
									className="w-full"
									value={
										extForm?.data?.cl_tec_01_bos2_ccebb_d
											? extForm?.data.cl_tec_01_bos2_ccebb_d
											: ""
									}
									onChange={function onChange(e) {
										e.preventDefault();
										updateData("cl_tec_01_bos2_ccebb_d", e.target.value);
									}}
								></input>
							</div>

							<div className="pl-5 bg-blue-200 border border-blue-300">
								Ship
							</div>
							<div className="border border-blue-300">
								<textarea
									className="w-full"
									disabled
									value={
										extForm?.data?.cl_tec_01_bos2_ccebb_s
											? extForm?.data.cl_tec_01_bos2_ccebb_s
											: ""
									}
								></textarea>
							</div>
							<div className="bg-blue-200 border border-blue-300">
								Barge/ Bunker Terminal:
							</div>
							<div className="border border-blue-300">
								<textarea
									className="w-full"
									value={
										extForm?.data?.["cl_tec_01_bos2_ccebb_bbt::remote"]
											? extForm?.data?.["cl_tec_01_bos2_ccebb_bbt::remote"]
											: ""
									}
									onChange={function onChange(e) {
										e.preventDefault();
										updateData("cl_tec_01_bos2_ccebb_bbt::remote", e.target.value);
									}}
								/>
							</div>
							<div className="pl-5 border border-blue-300">Ship’s Master:</div>
							<div className="border border-blue-300">
								<textarea
									className="w-full"
									disabled
									value={
										extForm?.data?.cl_tec_01_bos2_ccebb_sm
											? extForm?.data.cl_tec_01_bos2_ccebb_sm
											: ""
									}
								/>
							</div>
							<div className="border border-blue-300">
								Barge Master/Terminal In charge:
							</div>
							<div className="border border-blue-300">
								<textarea
									className="w-full"
									value={
										extForm?.data?.["cl_tec_01_bos2_ccebb_bmtc::remote"]
											? extForm?.data?.["cl_tec_01_bos2_ccebb_bmtc::remote"]
											: ""
									}
									onChange={function onChange(e) {
										e.preventDefault();
										updateData("cl_tec_01_bos2_ccebb_bmtc::remote", e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="pl-5 mt-5 font-bold text-center text-white bg-blue-500">
							SECTION 2/ Sub-Section 1: BUNKERS TO BE TRANSFERRED
						</div>
						<table className="w-full border border-collapse border-blue-200">
							<thead className="border">
								<th className="font-normal border border-blue-200">Grade</th>
								<th className="border border-blue-200">Weight(mt)</th>
								<th className="border border-blue-200">
									Volume at Loading Temp. (M3)
								</th>
								<th className="border border-blue-200">
									Loading Temperature (◦C/F)
								</th>
								<th className="border border-blue-200">
									Agreed Initial Rate (m3/hr)
								</th>
								<th className="border border-blue-200">
									Maximum Transfer Rate (m3/hr)
								</th>
								<th className="border border-blue-200">
									Agreed Topping Off Rate (m3/hr)
								</th>
								<th className="border border-blue-200">
									Maximum Line Pressure (Kg/Bar/PSI)
								</th>
							</thead>
							<tbody>
								{[...Array(3)].map((x, i) => (
									<tr>
										<td className="border border-blue-200">
											<select onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_g",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}
											} >
												<option>Select</option>
												<option value="Fuel Oil" selected={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_g"
													] === "Fuel Oil" ? true : false
												}>Fuel Oil</option>
												<option value="Gas Oil/Diesel" selected={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_g"
													] === "Gas Oil/Diesel" ? true : false
												}>Gas Oil/Diesel</option>
												<option value="Lub Oil in Bulk" selected={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_g"
													] === "Lub Oil in Bulk" ? true : false
												}>Lub Oil in Bulk</option>
											</select>	
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_w"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i][
																"cl_tec_01_bos2_ss1_bt_w"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_w",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_vlt"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
																"cl_tec_01_bos2_ss1_bt_vlt"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_vlt",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_lt"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
																"cl_tec_01_bos2_ss1_bt_lt"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_lt",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_air"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
																"cl_tec_01_bos2_ss1_bt_air"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_air",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_mtr"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
																"cl_tec_01_bos2_ss1_bt_mtr"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_mtr",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_ator"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
																"cl_tec_01_bos2_ss1_bt_ator"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_ator",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
														"cl_tec_01_bos2_ss1_bt_mlp"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss1_bt::append"]?.[i]?.[
																"cl_tec_01_bos2_ss1_bt_mlp"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss1_bt_mlp",
														e.target.value,
														"cl_tec_01_bos2_ss1_bt::append",
														i
													);
												}}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="pl-5 mt-5 font-bold text-center text-white bg-blue-500">
							SECTION 2 / Sub-Section 2: BUNKER TANKS TO BE LOADED
						</div>
						<table className="w-full border border-collapse border-blue-200">
							<thead className="border">
								<th className="font-normal border border-blue-200">
									Tank I.D.
								</th>
								<th className="border border-blue-200">Grade</th>
								<th className="border border-blue-200">Volume of Tank @ __%</th>
								<th className="border border-blue-200">
									Volume of Oil in tank before loading
								</th>
								<th className="border border-blue-200">Available Volume</th>
								<th className="border border-blue-200">Volume to be loaded</th>
								<th className="border border-blue-200">
									total volume by grade
								</th>
								<th className="border border-blue-200">
									Final Sounding (m or cm)
								</th>
							</thead>
							<tbody>
								{[...Array(5)].map((x, i) => (
									<tr>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.["cl_tec_01_bos2_ss2bt_tid"]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_tid"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_tid",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.["cl_tec_01_bos2_ss2bt_g"]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_g"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_g",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
														"cl_tec_01_bos2_ss2bt_vt"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_vt"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_vt",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
														"cl_tec_01_bos2_ss2bt_votbl"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_votbl"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_votbl",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
														"cl_tec_01_bos2_ss2bt_av"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_av"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_av",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
														"cl_tec_01_bos2_ss2bt_vl"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_vl"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_vl",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
														"cl_tec_01_bos2_ss2bt_tvg"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_tvg"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_tvg",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
														"cl_tec_01_bos2_ss2bt_fs"
													]
														? extForm?.data?.["cl_tec_01_bos2_ss2bt_::append"]?.[i]?.[
																"cl_tec_01_bos2_ss2bt_fs"
														  ]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateTable(
														"cl_tec_01_bos2_ss2bt_fs",
														e.target.value,
														"cl_tec_01_bos2_ss2bt_::append",
														i
													);
												}}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="p-3 mt-5 font-bold text-center text-white bg-blue-500">
							SECTION 2 / Sub-Section 3: CHECKS PRIOR TO TRANSFER
						</div>
						<div className="font-bold text-center text-white bg-blue-500">
							Items 1 to 4 are checks by Barge prior coming alongside.
						</div>
						<div className="font-bold text-center text-white bg-blue-500">
							Items 5 to 25 are joint checks prior to transfer.
						</div>
						<table className="w-full border border-collapse border-blue-200">
							<thead className="border">
								<th className=""></th>
								<th className="border border-blue-200">
									<div className="pl-1 text-2xl font-bold">Bunkering</div>
								</th>
								<th className="border border-blue-200">Ship</th>
								<th className="border border-blue-200">Barge</th>
								<th className="border border-blue-200">Code</th>
								<th className="border border-blue-200">Ship Remarks</th>
								<th className="border border-blue-200">Barge Remarks</th>
							</thead>
							<BunkerOps
								extForm={extForm}
								updateData={updateData}
								updateTable={updateTable}
							/>
						</table>
						<div className="mt-5 text-lg text-center">DECLARATION</div>
						<div className="mt-5 text-left">
							We have checked, where appropriate jointly, the items of the
							checklist in accordance with the instructions and have satisfied
							ourselves that the entries we have made are correct to the best of
							our knowledge.
						</div>

						<div className="mt-5 text-left">
							We have also made arrangements to carry out repetitive checks as
							necessary and agreed that those items coded ‘R’ in the Check List
							should be re-checked at intervals not exceeding{" "}
							<textarea
								className="border border-blue-500"
								disabled
								rows={1}
								cols={3}
								value={
									extForm?.data?.cl_tec_01_dec_interval
										? extForm?.data.cl_tec_01_dec_interval
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("cl_tec_01_dec_interval", e.target.value);
								}}
							/>
							hours. If to our knowledge the status of any item changes, we will
							immediately inform the other party.
						</div>
						<div className="grid place-content-center">
							<table className="w-full mt-8 mb-8 border border-collapse border-blue-200">
								<thead className="border">
									<th className="font-normal bg-blue-500 border border-blue-200">
										For Ship
									</th>
									<th className="font-normal bg-blue-500 border"></th>
									<th className="bg-blue-500 border border-blue-200">
										For Barge / Bunker Terminal
									</th>
									<th className="bg-blue-500 border border-blue-200"></th>
								</thead>
								<tbody>
									<tr>
										<td className="border border-blue-200">Name</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												disabled
												value={
													extForm?.author
														? extForm?.author
														: ""
												}
											/>
										</td>
										<td className="border border-blue-200">Name</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_dec_fbbt_n::remote"]
														? extForm?.data?.["cl_tec_01_dec_fbbt_n::remote"]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateData("cl_tec_01_dec_fbbt_n::remote", e.target.value);
												}}
											/>
										</td>
									</tr>
									<tr>
										<td className="border border-blue-200">Rank</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.cl_tec_01_dec_fs_r
														? extForm?.data.cl_tec_01_dec_fs_r
														: ""
												}
												disabled
											/>
										</td>
										<td className="border border-blue-200">Rank</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_dec_fbbt_r::remote"]
														? extForm?.data?.["cl_tec_01_dec_fbbt_r::remote"]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateData("cl_tec_01_dec_fbbt_r::remote", e.target.value);
												}}
											/>
										</td>
									</tr>
									<tr>
										<td className="border border-blue-200">Signature</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.cl_tec_01_dec_fs_s
														? extForm?.data.cl_tec_01_dec_fs_s
														: ""
												}
												disabled
											/>
										</td>
										<td className="border border-blue-200">Signature</td>
										<td className="border border-blue-200">
											<textarea
												className="w-full"
												value={
													extForm?.data?.["cl_tec_01_dec_fbbt_s::remote"]
														? extForm?.data?.["cl_tec_01_dec_fbbt_s::remote"]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateData("cl_tec_01_dec_fbbt_s::remote", e.target.value);
												}}
											/>
										</td>
									</tr>
									<tr>
										<td className="border border-blue-200">Date</td>
										<td className="border border-blue-200">
											<input
												type="date"
												value={
													extForm?.data?.cl_tec_01_dec_fs_d
														? extForm?.data.cl_tec_01_dec_fs_d
														: ""
												}
												disabled
											></input>
										</td>
										<td className="border border-blue-200">Date</td>
										<td className="border border-blue-200">
											<input
												type="date"
												value={
													extForm?.data?.["cl_tec_01_dec_fbbt_d::remote"]
														? extForm?.data?.["cl_tec_01_dec_fbbt_d::remote"]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateData("cl_tec_01_dec_fbbt_d::remote", e.target.value);
												}}
											></input>
										</td>
									</tr>
									<tr>
										<td className="border border-blue-200">Time</td>
										<td className="border border-blue-200">
											<input
												type="time"
												value={
													extForm?.data?.cl_tec_01_dec_fs_t
														? extForm?.data.cl_tec_01_dec_fs_t
														: ""
												}
												disabled
											></input>
										</td>
										<td className="border border-blue-200">Time</td>
										<td className="border border-blue-200">
											<input
												type="time"
												value={
													extForm?.data?.["cl_tec_01_dec_fbbt_t::remote"]
														? extForm?.data?.["cl_tec_01_dec_fbbt_t::remote"]
														: ""
												}
												onChange={function onChange(e) {
													e.preventDefault();
													updateData("cl_tec_01_dec_fbbt_t::remote", e.target.value);
												}}
											></input>
										</td>
									</tr>
								</tbody>
							</table>
							<table>
								<thead>
									<th className="border border-blue-200" colSpan={extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.length ? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.length+1 : 1}>
										Record of repetitive checks: (not to exceed 4 hours)
									</th>
								</thead>
								<tbody>

								<tr className="inline-block float-left w-1/4">
									<td className="block h-12 border border-blue-200">
										<b>Date</b>
									</td>

									<td className="block h-8 border border-blue-200">
										<b>Time</b>
									</td>

									<td className="block h-20 border border-blue-200">
										2. The fenders have been checked, are in good order and
										there is no possibility of metal to metal contact.
									</td>

									<td className="block h-12 border border-blue-200">
										5. The barge is securely moored.
									</td>

									<td className="block h-20 border border-blue-200">
										6. There is a safe means of access between the ship and
										barge{" "}
									</td>

									<td className="block h-20 border border-blue-200">
										7. Effective communications have been established between
										responsible officers.
									</td>

									<td className="block h-40 border border-blue-200">
										10. All scuppers are effectively plugged. Temporarily
										removed scupper plugs will be monitored at all times. Drip
										trays are in position on decks around connections and
										bunker tank vents.
									</td>

									<td className="block h-12 border border-blue-200">
										15. Bunker tank contents will be monitored at regular
										intervals.
									</td>

									<td className="block h-20 border border-blue-200">
										19. Smoking rooms have been identified and smoking
										restrictions are being observed.{" "}
									</td>

									<td className="block h-12 border border-blue-200">
										20. Naked Light regulations are being observed.{" "}
									</td>

									<td className="block h-12 border border-blue-200">
										21. All external doors and ports in the accommodation are
										closed.
									</td>

									<td className="block h-12 border border-blue-200">
										23.Check and Write toxic gas readings in ppm (H2S, Benzene
										etc.)
									</td>

									<td className="block h-12 border border-blue-200">
										<b>Initial for ship</b>
									</td>

									<td className="block h-12 border border-blue-200">
										<b>Initial for Barge/Terminal</b>
									</td>
								</tr>
								{[...Array(6)].map((x: any, i: number) => (
									<tr className="inline-block float-left w-32">
										<td className="block h-12 border border-blue-200">
											<input type="date"
												className="w-full"
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.cl_tec_01_rorc_nte1h_tblq2_d
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]
																?.cl_tec_01_rorc_nte1h_tblq2_d
														: ""
												}
											/>
										</td>
										<td className="block h-8 border border-blue-200">
											<input type="time"
												className="w-full"
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.cl_tec_01_rorc_nte1h_tblq2_t
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]
																?.cl_tec_01_rorc_nte1h_tblq2_t
														: ""
												}
												/>
										</td>
										<td className="block h-20 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_tfc
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
															cl_tec_01_rorc_nte1h_tblq2_tfc
														: ""
												}
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_tbsm
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_tbsm
														: ""
												}
											/>
										</td>
										<td className="block h-20 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_tsasb
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_tsasb
														: ""
												}
											/>
										</td>
										<td className="block h-20 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_ecero
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_ecero
														: ""
												}
											/>
										</td>
										<td className="block h-40 border border-blue-200">
											<textarea
												className="w-full"
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_acep
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_acep
														: ""
												}
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_btmri
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_btmri
														: ""
												}
											/>
										</td>
										<td className="block h-20 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_srisro
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_srisro
														: ""
												}
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_nlr
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_nlr
														: ""
												}
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_aedpac
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_aedpac
														: ""
												}
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
													cl_tec_01_rorc_nte1h_tblq2_cwtrp
														? extForm?.data?.cl_tec_01_rorc_nte1h_tblq2?.[i]?.
														cl_tec_01_rorc_nte1h_tblq2_cwtrp
														: ""
												}
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
											/>
										</td>
										<td className="block h-12 border border-blue-200">
											<textarea
												className="w-full "
												disabled
											/>
										</td>

									</tr>
								)
							)}
								</tbody>
							</table>
							<div className="m-8 text-center border border-blue-200">
								<b>
									Note: Chief Engineer is responsible for above repetitive
									checks and calculations at agreed intervals.
								</b>
							</div>
						</div>
						<div className="justify-end p-0 my-3 bp3-dialog-footer-actions">
							<Button
								text="Sign"
								view="filled"
								color="primary"
								onClick={() => {
									triggerSubmitOperation();
								}}
								className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
							/>
							<Button
								text="Submit"
								view="filled"
								type="submit"
								color="primary"
								className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
							/>
						</div>
					</form>
				</div>
			</div>
			<Modal isActive={!!isModalOpen} onClose={() => resetOperations()}>
				{isModalOpen ? (
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							<PermitSignModal
								onClose={function onModelClose() {
									resetOperations();
								}}
								onSign={function onModelSign() {
									extForm.externalCollaborators[0].isSigned = true;
									props.formFieldEdit({ ...extForm });
									props.setFormSubmit(true)
									props.formSubmit();
									resetOperations();
								}}
							></PermitSignModal>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
};
function mapStateToProps(store: IStore) {
	const extStore: any = store.app.extStore;
	return {
		extForm: extStore.extForm,
		listLoadJob: {
			isLoading: false,
		},
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		formFieldEdit(value: any) {
			this.setFormSubmit(false)
			dispatch(extActions.formFieldsPatch(value));
		},
		formSubmit() {
			dispatch(extActions.formUpdate(""));
		},
		formRedirect() {
			const url = rootRoutes.children.ext.children.login.url;
			dispatch(historyActions.replace("@ext", url()));
		},
		setFormSubmit(value: boolean) {
			dispatch(extActions.formSubmit(value));
		}
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
export default reduxConnector(CLTec01);
