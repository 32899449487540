import moment from "moment";
import React from "react";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupA2 } from "../../../../../redux/app/orb1/groups/group-a";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupA2PreviewProps = OwnProps;
function ORB1GroupA2Preview(props: ORB1GroupA2PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupA2;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "A", "1", renderItem1()],
					renderItem2Row(),
					[null, null, "3.1", "N/A"],
					[null, null, "3.2", "N/A"],
					[null, null, "3.3", "N/A"],
					[null, null, "4.1", renderItem4P1()],
					[null, null, "4.2", renderItem4P2()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem1() {
		return (
			<h4 className="font-medium uppercase">
				{orb1Record.item1.foTanks.map((tank) => (
					<React.Fragment key={tank.id}>
						BALLASTING OF {tank.tankName}
						<br />
					</React.Fragment>
				))}
			</h4>
		);
	}

	function renderItem2Row(): ORB1TableColumns {
		const { isOilCleaned } = orb1Record.item2;

		function getDateSection() {
			if (isOilCleaned === "YES") return null;
			const dateOfLastCleaning = moment(
				orb1Record.item2.dateOfLastCleaning,
				"YYYY-MM-DD"
			).format("DD-MMM-YYYY");
			return dateOfLastCleaning;
		}
		const dateSection = getDateSection();
		if (isOilCleaned === "YES") return [dateSection, null, "2", "CLEANED: YES"];

		const content = (
			<h4 className="font-medium uppercase">
				CLEANED: NO, TYPE OF OIL: {orb1Record.item2.typeOfOilPreviouslyCarried},
				DENSITY: {orb1Record.item2.densityOrViscosity}, COMMERCIAL NAME:{" "}
				{orb1Record.item2.oilCommercialName}
			</h4>
		);
		return [dateSection, null, "2", content];
	}

	function renderItem4P1() {
		const { startOfBallasting, completionOfBallasting } = orb1Record.item4P1;
		return (
			<h4 className="font-medium uppercase">
				START BALLASTING: {startOfBallasting.latitude},{" "}
				{startOfBallasting.longitude} AT {startOfBallasting.time} HRS
				<br />
				STOP BALLASTING: {completionOfBallasting.latitude},{" "}
				{completionOfBallasting.longitude} AT {completionOfBallasting.time} HRS
			</h4>
		);
	}

	function renderItem4P2() {
		return (
			<h4 className="font-medium uppercase">
				BALLASTED: {orb1Record.item4P2.ballastQuantity.toFixed(2)}M³
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupA2Preview;
