import React from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

function FeedbackChatsLoader() {
    return (
        <>
            <div className="p-5 mb-2 h-full w-full">
                <h3 className="text-xl font-medium">Support team</h3>
                <p className="text-gray-600 mt-1">loading your chats....</p>
                <div className="space-y-4 mt-2">
                    {[1,2,3].map((_, index) => {
                        const isEven = index % 2 === 0;
                        return (
                            <div
                                key={index}
                                className={classNames('w-full flex', {
                                    'justify-start': isEven,
                                    'justify-end ': !isEven,
                                })}
                            >
                                <div
                                    className={classNames(
                                        'w-2/3 relative rounded-br-none'
                                    )}
                                >
                                    <Skeleton
                                        height={150}
                                        className="rounded-lg"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default FeedbackChatsLoader;
