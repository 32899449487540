import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import { Tooltip } from "react-tippy";
import SVGCopy from "components/_common/icons/tool/SVGCopy";
import Button from "@set-product/core/button/Button";
import ModalPortal from "components/app/_common/ModalPortal";
import toolSelectors from "redux/app/tool/selectors";
import toolActions from "redux/app/tool/actions";
import { resetPrintPaginationOfCurrentPage } from "redux/app/tool/utils";
import Print from "../print";
import PrintView from "../print/_elements/PrintView";
import PrintUpdate from "../print/_elements/PrintUpdate";
import PrintCreate from "../print/_elements/PrintCreate";
import PrintCopy from "../print/_elements/PrintCopy";

type OwnProps = {};
type PrintRendererProps = PropsFromRedux & OwnProps;
function PrintRenderer(props: PrintRendererProps) {
	const {
		toolType,
		selectedItems,
		isCopyModalOpen,
		isUpdateModalOpen,
		isPrintAddModalOpen,
		isViewModalOpen,
		selectedTenantOption,
		selectedVesselOption,
		searchInputVal,
		printActiveTab,
		isPrintFormExpanded,
	} = props;

	useEffect(() => {
		props.setSearch("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toolType, printActiveTab]);

	// Reset currentPage to 1 in pagination after every search
	useEffect(() => {
		props.setCurrentPagePaginationTo1();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInputVal]);

	// Reset the current page on change of vessel or tenant
	// Unselect all items on change of vessel/Dropdown
	useEffect(() => {
		props.resetCurrentPagePagination();
		props.setSelectedItems([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value, selectedVesselOption?.value]);
	return (
		<>
			<Print>
				<div className="w-full flex items-center  mt-4">
					<div className="w-full">
						<input
							type="text"
							placeholder="Search"
							className=" p-2 border bg-[#F7F7F7] border-[#F7F7F7] h-full w-full rounded-md"
							value={searchInputVal}
							onChange={(e) => {
								props.setSearch(e.target.value);
							}}
						/>
					</div>
					{selectedItems.length > 0 && (
						<Tooltip title={"Copy"}>
							<div className="flex items-center">
								<div
									className="ml-2 rounded-full flex items-center p-2.5 border border-[#D8D8D8] cursor-pointer hover:shadow-md"
									onClick={() => {
										props.toggleCopyModal();
									}}
								>
									<SVGCopy />
								</div>
							</div>
						</Tooltip>
					)}
					{selectedItems.length > 0 && (
						<Tooltip title={"Unselect"}>
							<div className="flex items-center">
								<div
									className="ml-2 rounded-full flex items-center p-2.5 border border-[#D8D8D8] cursor-pointer hover:shadow-md"
									onClick={() => {
										props.setSelectedItems([]);
									}}
								>
									<span className="bp3-icon bp3-icon-remove text-lg text-gray-500" />
								</div>
							</div>
						</Tooltip>
					)}
					<Button
						className="px-6  bg-blue-700 whitespace-nowrap disabled:bg-gray-300 md:ml-4 md:mb-0"
						onClick={() => {
							props.togglePrintAddModal();
						}}
						disabled={
							!selectedTenantOption?.value ||
							!selectedVesselOption?.value ||
							!!selectedItems.length
						}
						icon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
							>
								<path
									d="M7.99935 13.8332V8.49984M7.99935 8.49984V3.1665M7.99935 8.49984H13.3327M7.99935 8.49984H2.66602"
									stroke="white"
									strokeWidth="2"
									strokeLinecap="round"
								/>
							</svg>
						}
					>
						Add
					</Button>
				</div>
			</Print>

			{isCopyModalOpen && (
				<ModalPortal
					isActive={isCopyModalOpen}
					onClose={() => {
						props.toggleCopyModal();
					}}
					width={650}
					isOverlayClickCloseable={false}
				>
					<PrintCopy />
				</ModalPortal>
			)}
			{isPrintAddModalOpen && (
				<ModalPortal
					isActive={isPrintAddModalOpen}
					onClose={() => {
						props.togglePrintAddModal();
					}}
					width={isPrintFormExpanded ? "100%" : 650}
					isOverlayClickCloseable={false}
				>
					<PrintCreate />
				</ModalPortal>
			)}
			{isUpdateModalOpen && (
				<ModalPortal
					isActive={isUpdateModalOpen}
					onClose={() => {
						props.toggleUpdateModal();
					}}
					width={isPrintFormExpanded ? "100%" : 650}
					isOverlayClickCloseable={false}
				>
					<PrintUpdate />
				</ModalPortal>
			)}
			{isViewModalOpen && (
				<ModalPortal
					isActive={isViewModalOpen}
					onClose={() => {
						props.togglePrintViewModal();
					}}
					width={isPrintFormExpanded ? "100%" : 650}
					isOverlayClickCloseable={false}
					className="overscroll-x-contain"
				>
					<PrintView />
				</ModalPortal>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const printActiveTab = toolSelectors.getActiveTabForPrint(toolStore);

	const selectedTenantOption = toolSelectors.getSelectedTenantOption(toolStore);
	const selectedVesselOption = toolSelectors.getSelectedVesselOption(toolStore);
	const isViewModalOpen = toolSelectors.getIsPrintViewModalOpen(toolStore);
	const searchInputVal = toolSelectors.getSearchInputValue(toolStore);
	const isPrintFormExpanded = toolSelectors.getIsPrintFormExpanded(toolStore);
	return {
		toolType: toolSelectors.getConfigPrintToolType(toolStore),
		printActiveTab,
		selectedItems: toolSelectors.getPrintSelectedItems(toolStore),
		isCopyModalOpen: toolSelectors.getPrintIsCopyModalOpen(toolStore),
		isUpdateModalOpen: toolSelectors.getPrintIsUpdateModalOpen(toolStore),
		isPrintAddModalOpen: toolSelectors.getIsPrintAddModalOpen(toolStore),
		selectedTenantOption,
		selectedVesselOption,
		isViewModalOpen,
		searchInputVal,
		isPrintFormExpanded,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleCopyModal() {
			dispatch(toolActions.document.printCopyModalToggle());
		},
		toggleUpdateModal() {
			dispatch(toolActions.document.printUpdateModalToggle());
		},
		togglePrintAddModal() {
			dispatch(toolActions.document.printAddModalToggle());
		},
		togglePrintViewModal() {
			dispatch(toolActions.document.printViewModalToggle());
		},
		setSearch(searchInput: string) {
			dispatch(toolActions.document.toolConfigPrintSearchInputSet(searchInput));
		},
		resetCurrentPagePagination() {
			resetPrintPaginationOfCurrentPage(dispatch);
		},
		setSelectedItems(selectedItems: string[]) {
			dispatch(toolActions.document.toolPrintItemsSelect(selectedItems));
		},
		setCurrentPagePaginationTo1() {
			dispatch(toolActions.commands.toolPrintOnSearchCurrentPageTo1Set());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(PrintRenderer);
