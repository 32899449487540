import React from "react";
import LogList from "../../../../components/app/log/list/List";
type ScreenLogListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;
function ScreenLogList(props: ScreenLogListProps) {
	return <LogList pageNumber={props.match.params.pageNumber} />;
}

export default ScreenLogList;
