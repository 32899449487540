import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	EAnnex6RecordCode,
	TAnnex6BackendRecord,
} from "../../../../redux/app/annex6/@types";
import { logSelectors } from "../../../../redux/app/logs";
import { marpolSelectors } from "../../../../redux/app/marpol";
import { vesselSelectors } from "../../../../redux/app/vessel";
import { IStore } from "../../../../redux/store";
import Annex6GroupA1Preview from "../annex6/previews/Annex6GroupA1Preview";
import Annex6GroupA2Preview from "../annex6/previews/Annex6GroupA2Preview";
import Annex6GroupB1Preview from "../annex6/previews/Annex6GroupB1Preview";
import Annex6GroupB2Preview from "../annex6/previews/Annex6GroupB2Preview";
import Annex6GroupC1Preview from "../annex6/previews/Annex6GroupC1Preview";
import Annex6GroupD1Preview from "../annex6/previews/Annex6GroupD1Preview";
import Annex6GroupD2Preview from "../annex6/previews/Annex6GroupD2Preview";
import Annex6GroupD3Preview from "../annex6/previews/Annex6GroupD3Preview";
import Annex6GroupD4Preview from "../annex6/previews/Annex6GroupD4Preview";
import Annex6GroupD5Preview from "../annex6/previews/Annex6GroupD5Preview";
import Annex6GroupD6Preview from "../annex6/previews/Annex6GroupD6Preview";
import Annex6GroupE1Preview from "../annex6/previews/Annex6GroupE1Preview";
import Annex6GroupE2Preview from "../annex6/previews/Annex6GroupE2Preview";
import Annex6GroupF1Preview from "../annex6/previews/Annex6GroupF1Preview";
import Annex6GroupG1Preview from "../annex6/previews/Annex6GroupG1Preview";
import Annex6GroupH1Preview from "../annex6/previews/Annex6GroupH1Preview";
import Annex6GroupI1Preview from "../annex6/previews/Annex6GroupI1Preview";
import Annex6GroupI2Preview from "../annex6/previews/Annex6GroupI2Preview";
import Annex6GroupI3Preview from "../annex6/previews/Annex6GroupI3Preview";
import Annex6GroupI4Preview from "../annex6/previews/Annex6GroupI4Preview";
import Annex6GroupJ1Preview from "../annex6/previews/Annex6GroupJ1Preview";
import MARPOLRecordParticipantsView from "../_elements/ParticipantsView";
import Annex6Previewer from "../annex6/Annex6Previewer";

type OwnProps = {
	disabled: boolean;
};
type ORB2DocsProps = OwnProps;
function ORB2Docs(props: ORB2DocsProps & PropsFromRedux) {
	const { disabled, marpolDocs } = props;

	if (disabled)
		return (
			<div className="w-full h-64 text-gray-500 bg-gray-500 rounded-lg opacity-50">
				Disabled MARPOL DOCS
			</div>
		);

	if (marpolDocs.length === 0)
		return (
			<div className="flex flex-col flex-1 max-h-full mb-2">
				<div className="flex flex-row w-full max-h-full overflow-y-auto ws-scroll">
					<div className="flex-1 ">
						<h1 className="p-6 text-sm font-medium border border-gray-200 rounded-sm">
							No records found..
						</h1>
					</div>
				</div>
			</div>
		);

	return (
		<div className="w-full p-0 pt-6">
			<div className="mb-8">
				<h1 className="text-xl font-bold font-redhat-display">
					Annex6 records
				</h1>
				<h1 className="w-3/4 mb-6 text-base font-medium leading-5 tracking-wide text-gray-600">
					Annex6 Operations.
				</h1>
				{/* <div className="flex flex-col">
					<div className="flex flex-row justify-start">
						<div className="w-24 font-medium text-gray-700">Vessel Name:</div>
						<div className="flex-1 font-bold uppercase">{props.vesselName}</div>
					</div>
					<div className="flex flex-row justify-start">
						<div className="w-24 font-medium text-gray-700">IMO Number:</div>
						<div className="flex-1 font-bold uppercase">{props.imoNumber}</div>
					</div>
				</div> */}
			</div>
			<div className="w-full bg-gray-100">
				<table className="w-full bg-gray-100">
					<thead>
						<tr className="bg-gray-100">
							<th>
								<div className="p-3 m-px text-xs font-medium leading-4 tracking-wide text-left text-gray-600 bg-gray-200 border-gray-300 whitespace-nowrap">
									Date <span className="invisible"> and Time (UTC)</span>
								</div>
							</th>
							<th>
								<div className="p-3 m-px text-xs font-medium leading-4 tracking-wide text-center text-gray-600 bg-gray-200 border-gray-300 whitespace-nowrap min-w-20">
									Code
								</div>
							</th>
							<th>
								<div className="p-3 m-px text-xs font-medium leading-4 tracking-wide text-right text-gray-600 bg-gray-200 border-gray-300 whitespace-nowrap min-w-20">
									Item
								</div>
							</th>
							<th>
								<div className="p-3 m-px text-xs font-medium leading-4 tracking-wide text-left text-gray-600 bg-gray-200 border-gray-300 whitespace-nowrap min-w-20">
									Record of Operations
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{marpolDocs.map((annex6Record, index) => {
							return (
								<React.Fragment key={annex6Record.id}>
									<Annex6Previewer annex6Record={annex6Record} />
									<MARPOLRecordParticipantsView
										isSubmitterChiefOrMaster={false}
										record={annex6Record}
									/>
								</React.Fragment>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="px-3 py-5 mt-6 text-right">
				<span className="ml-auto text-sm font-medium text-gray-600">
					Powered by - <strong className="font-bold">Volteo Maritime</strong>
				</span>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const logStore = logSelectors._getStore(store);
	const vesselStore = vesselSelectors._getStore(store);
	const marpolStore = marpolSelectors._getStore(store);

	return {
		areVesselsPresent: vesselSelectors.areVesselsPresent(store.app.vesselStore),
		filterForm: logSelectors.formForFilter(logStore),
		marpolDocs: marpolSelectors.getDocs(marpolStore) as TAnnex6BackendRecord[],
		miniVessels: vesselSelectors.getMiniVessels(
			vesselSelectors.getVessels(vesselStore)
		),
		jobToFilterLogs: logSelectors.jobToFilterLogs(logStore),
		jobToLoadSelectedLog: logSelectors.jobToLoadSelectedLog(logStore),
		jobToLoadVessels: vesselSelectors.jobToLoadVessels(vesselStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ORB2Docs);
