import classNames from "classnames";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import React, { useEffect, useRef, useState } from "react";

type IDropdownOption = {
	value: string;
	label: string;
};

type DropdownRadioProps = {
	options: IDropdownOption[];
	topLabel?: string;
	isOpen: boolean;
	name: string;
	handleOpen: () => void;
	handleClose: () => void;
	bottomBar?: () => React.ReactNode;
	handleChange: (value: string) => void;
	value: string;
	displayRenderer: (
		displayableSelectedValue: IDropdownOption[]
	) => React.ReactNode;
	placement?: "bottom" | "overlap";
	horizontalOrientation?: "center";
};

function DropdownRadio(props: DropdownRadioProps) {
	const {
		options,
		handleOpen,
		isOpen,
		handleClose,
		topLabel,
		name,
		bottomBar,
		value,
		handleChange,
		displayRenderer,
		placement,
		horizontalOrientation,
	} = props;
	const menuRef = useRef<HTMLDivElement>(null);
	const [selectedValue, setSelectedValue] = useState(value);

	useEffect(() => {
		handleChange(selectedValue);
	}, [selectedValue]);

	useOnClickOutside(menuRef, () => handleClose());
	const totalLen = options.length;

	const displayableSelectedValue = options.filter(
		(option) => option.value === selectedValue
	);

	return (
		<div className="relative">
			<div>{displayRenderer && displayRenderer(displayableSelectedValue)}</div>
			{isOpen && (
				<div
					ref={menuRef}
					className={classNames(
						"w-52 absolute  bg-white border rounded-md h-72 shadow-sm",
						{
							"top-0": placement === "overlap" || !placement,
							"top-full": placement === "bottom",
							"left-1/2 transform -translate-x-1/2":
								horizontalOrientation === "center",
						}
					)}
				>
					<ul className="h-[272px]  overflow-auto">
						{topLabel && (
							<li
								key={topLabel}
								className="px-2 py-3 text-base border-b font-semibold"
							>
								{topLabel}
							</li>
						)}
						{options.map((option, index) => {
							const { label, value } = option;
							return (
								<li
									key={value}
									className={classNames(
										"px-2 py-3 text-base border-b cursor-pointer",
										{
											"pb-8": totalLen > 4 && totalLen - 1 === index,
										}
									)}
									onClick={(e) => setSelectedValue(value)}
								>
									<div className="mb-[0.125rem] flex items-center min-h-[1.5rem] w-full">
										<input
											id={value}
											className="peer/radio transform scale-125"
											type="radio"
											name={name}
											value={value}
											checked={value === selectedValue}
											onChange={(e) => {
												e.stopPropagation();
												setSelectedValue(e.target.value);
											}}
										/>
										<label
											htmlFor={value}
											className="ml-2 peer-checked/radio:text-sky-500 cursor-pointer"
										>
											{label}
										</label>
									</div>
								</li>
							);
						})}
					</ul>
					<div className="h-[48px]">{bottomBar && bottomBar()}</div>
				</div>
			)}
		</div>
	);
}

export default DropdownRadio;
