import { jobActions } from "redux/_core/job";
import { ActionType, createAction, getType } from "typesafe-actions";
import reportConstants from "./constants";
import {
	IBilgeWaterAllVesselAggregateRecord,
	IBilgeWaterRecord,
	ICargoSlopAggregateRecord,
	ICargoSlopVesselSpecificAggregateRecord,
	IGarbageCategoryAllVesselsRecord,
	IGarbageDischargeAllVesselsRecord,
	IGarbageRawRecord,
	IORSAggregateRecord,
	IORSVesselSpecificAggregateRecord,
	IOilSpillsInWaterAggregateRecord,
	IOilSpillsInWaterVesselSpecificAggregateRecord,
} from "./@types";

export enum EReportActions {
	REPORT_GARBAGE_LOAD = "@report/garbage/LOAD",

	REPORT_BILGE_WATER_LOAD = "@report/bilge-water/LOAD",
	REPORT_BILGE_WATER_ALL_VESSELS_AGGREGATE_LOAD = "@report/bilge-water/all-vessels/aggregate/LOAD",
	REPORT_GARBAGE_DISCHARGE_ALL_VESSELS_LOAD = "@report/all-vessels/garbage-discharge/LOAD",
	REPORT_GARBAGE_CATEGORY_ALL_VESSELS_LOAD = "@report/all-vessels/garbage-category/LOAD",

	REPORT_ORS_VESSEL_SPECIFIC_AGGREGATE_LOAD = "@report/ors/vessel-specific/aggregate/LOAD",
	REPORT_ORS_ALL_VESSEL_AGGREGATE_LOAD = "@report/ors/all-vessel/aggregate/LOAD",

	REPORT_CARGO_SLOP_VESSEL_SPECIFIC_AGGREGATE_LOAD = "@report/cargo-slop/vessel-specific/aggregate/LOAD",
	REPORT_CARGO_SLOP_ALL_VESSEL_AGGREGATE_LOAD = "@report/cargo-slop/all-vessel/aggregate/LOAD",

	REPORT_OIL_SPILLS_IN_WATER_VESSEL_SPECIFIC_AGGREGATE_LOAD = "@report/oil-spills-in-water/vessel-specific/aggregate/LOAD",
	REPORT_OIL_SPILLS_IN_WATER_ALL_VESSEL_AGGREGATE_LOAD = "@report/oil-spills-in-water/all-vessel/aggregate/LOAD",

	// INFO: Making it generic, rather than for each category-type
	REPORT_YEAR_SET = "@report/year/SET",
	REPORT_VESSEL_ID_SET = "@report/vesselId/SET",

	REPORT_GARBAGE_RAW_DATA_SET = "@report/garbage/raw/SET",
	REPORT_GARBAGE_VESSEL_ID_SET = "@report/garbage/vesselId/SET",
	REPORT_GARBAGE_YEAR_SET = "@report/garbage/year/SET",
	REPORT_ALL_VESSELS_GARBAGE_DISCHARGE_SET = "@report/all-vessels/garbage-discharge/data/SET",
	REPORT_ALL_VESSELS_GARBAGE_CATEGORY_SET = "@report/all-vessels/garbage-category/data/SET",

	REPORT_BINGE_WATER_RAW_DATA_SET = "@report/bilge-water/raw/SET",
	REPORT_BILGE_WATER_VESSEL_ID_SET = "@report/bilge-water/vesselId/SET",
	REPORT_BILGE_WATER_YEAR_SET = "@report/bilge-water/year/SET",
	REPORT_BILGE_WATER_ALL_VESSELS_DATA_SET = "@report/bilge-water/all-vessels/data/SET",

	REPORT_ORS_AGGREGATE_DATA_SET = "@report/ors/data/SET",
	REPORT_ORS_VESSEL_SPECIFIC_AGGREGATE_DATA_SET = "@report/ors/vessel-specific/data/SET",
	REPORT_ORS_VESSEL_ID_SET = "@report/ors/vesselId/SET",
	REPORT_ORS_YEAR_SET = "@report/ors/year/SET",

	REPORT_CARGO_SLOP_AGGREGATE_DATA_SET = "@report/cargo-slop/data/SET",
	REPORT_CARGO_SLOP_VESSEL_SPECIFIC_AGGREGATE_DATA_SET = "@report/cargo-slop/vessel-specific/data/SET",

	REPORT_OIL_SPILLS_IN_WATER_AGGREGATE_DATA_SET = "@report/oil-spills-in-water/data/SET",
	REPORT_OIL_SPILLS_IN_WATER_VESSEL_SPECIFIC_AGGREGATE_DATA_SET = "@report/oil-spills-in-water/vessel-specific/data/SET",
}

const reportActions = {
	commands: {
		garbageReportLoad: createAction(EReportActions.REPORT_GARBAGE_LOAD)(),
		bilgeWaterReportLoad: createAction(
			EReportActions.REPORT_BILGE_WATER_LOAD
		)(),
		bilgeWaterAllVesselsAggregateLoad: createAction(
			EReportActions.REPORT_BILGE_WATER_ALL_VESSELS_AGGREGATE_LOAD
		)(),
		garbageDischargeAllVesselsLoad: createAction(
			EReportActions.REPORT_GARBAGE_DISCHARGE_ALL_VESSELS_LOAD
		)(),
		garbageCategoryAllVesselsLoad: createAction(
			EReportActions.REPORT_GARBAGE_CATEGORY_ALL_VESSELS_LOAD
		)(),
		orsVesselSpecificAggregateLoad: createAction(
			EReportActions.REPORT_ORS_VESSEL_SPECIFIC_AGGREGATE_LOAD
		)(),
		orsAllVesselAggregateLoad: createAction(
			EReportActions.REPORT_ORS_ALL_VESSEL_AGGREGATE_LOAD
		)(),
		cargoSlopVesselSpecificAggregateLoad: createAction(
			EReportActions.REPORT_CARGO_SLOP_VESSEL_SPECIFIC_AGGREGATE_LOAD
		)(),
		cargoSlopAllVesselAggregateLoad: createAction(
			EReportActions.REPORT_CARGO_SLOP_ALL_VESSEL_AGGREGATE_LOAD
		)(),
		oilSpillsInWaterVesselSpecificAggregateLoad: createAction(
			EReportActions.REPORT_OIL_SPILLS_IN_WATER_VESSEL_SPECIFIC_AGGREGATE_LOAD
		)(),
		oilSpillsInWaterAllVesselAggregateLoad: createAction(
			EReportActions.REPORT_OIL_SPILLS_IN_WATER_ALL_VESSEL_AGGREGATE_LOAD
		)(),
	},
	document: {
		reportVesselIdSet: createAction(
			EReportActions.REPORT_VESSEL_ID_SET,
			(vesselId: string) => ({ vesselId })
		)(),
		reportYearSet: createAction(
			EReportActions.REPORT_YEAR_SET,
			(year: string) => ({ year })
		)(),
		garbageRawDataSet: createAction(
			EReportActions.REPORT_GARBAGE_RAW_DATA_SET,
			(garbageRawData: IGarbageRawRecord[]) => ({
				garbageRawData,
			})
		)(),
		garbageReportVesselIdSet: createAction(
			EReportActions.REPORT_GARBAGE_VESSEL_ID_SET,
			(vesselId: string) => ({ vesselId })
		)(),
		garbageReportYearSet: createAction(
			EReportActions.REPORT_GARBAGE_YEAR_SET,
			(year: string) => ({ year })
		)(),
		bilgeWaterRawDataSet: createAction(
			EReportActions.REPORT_BINGE_WATER_RAW_DATA_SET,
			(bilgeWaterRawData?: IBilgeWaterRecord[]) => ({
				bilgeWaterRawData,
			})
		)(),
		bilgeWaterReportVesselIdSet: createAction(
			EReportActions.REPORT_BILGE_WATER_VESSEL_ID_SET,
			(vesselId: string) => ({ vesselId })
		)(),
		bilgeWaterReportYearSet: createAction(
			EReportActions.REPORT_BILGE_WATER_YEAR_SET,
			(year: string) => ({ year })
		)(),
		bilgeWaterAllVesselDataSet: createAction(
			EReportActions.REPORT_BILGE_WATER_ALL_VESSELS_DATA_SET,
			(vesselBilgeWaterRecords: IBilgeWaterAllVesselAggregateRecord[]) => ({
				vesselBilgeWaterRecords,
			})
		)(),
		garbageDischargeAllVesselDataSet: createAction(
			EReportActions.REPORT_ALL_VESSELS_GARBAGE_DISCHARGE_SET,
			(garbageDischargeRecords: IGarbageDischargeAllVesselsRecord[]) => ({
				garbageDischargeRecords,
			})
		)(),

		garbageCategoryAllVesselDataSet: createAction(
			EReportActions.REPORT_ALL_VESSELS_GARBAGE_CATEGORY_SET,
			(garbageCategoryRecords: IGarbageCategoryAllVesselsRecord[]) => ({
				garbageCategoryRecords,
			})
		)(),
		orsReportVesselIdSet: createAction(
			EReportActions.REPORT_ORS_VESSEL_ID_SET,
			(vesselId: string) => ({ vesselId })
		)(),
		orsReportYearSet: createAction(
			EReportActions.REPORT_ORS_YEAR_SET,
			(year: string) => ({ year })
		)(),
		orsRawDataSet: createAction(
			EReportActions.REPORT_ORS_AGGREGATE_DATA_SET,
			(rawOrsData: IORSAggregateRecord[]) => ({
				rawOrsData,
			})
		)(),
		orsVesselSpecificRawDataSet: createAction(
			EReportActions.REPORT_ORS_VESSEL_SPECIFIC_AGGREGATE_DATA_SET,
			(rawVesselSpecificOrsData: IORSVesselSpecificAggregateRecord[]) => ({
				rawVesselSpecificOrsData,
			})
		)(),
		cargoSlopRawDataSet: createAction(
			EReportActions.REPORT_CARGO_SLOP_AGGREGATE_DATA_SET,
			(rawCargoSlopData: ICargoSlopAggregateRecord[]) => ({
				rawCargoSlopData,
			})
		)(),
		cargoSlopVesselSpecificRawDataSet: createAction(
			EReportActions.REPORT_CARGO_SLOP_VESSEL_SPECIFIC_AGGREGATE_DATA_SET,
			(
				rawVesselSpecificCargoSlopData: ICargoSlopVesselSpecificAggregateRecord[]
			) => ({
				rawVesselSpecificCargoSlopData,
			})
		)(),
		oilSpillsInWaterRawDataSet: createAction(
			EReportActions.REPORT_OIL_SPILLS_IN_WATER_AGGREGATE_DATA_SET,
			(rawOilSpillsInWaterData: IOilSpillsInWaterAggregateRecord[]) => ({
				rawOilSpillsInWaterData,
			})
		)(),
		oilSpillsInWaterVesselSpecificRawDataSet: createAction(
			EReportActions.REPORT_OIL_SPILLS_IN_WATER_VESSEL_SPECIFIC_AGGREGATE_DATA_SET,
			(
				rawVesselSpecificOilSpillsInWaterData: IOilSpillsInWaterVesselSpecificAggregateRecord[]
			) => ({
				rawVesselSpecificOilSpillsInWaterData,
			})
		)(),
	},
};

export const reportJobActions = {
	garbageReportLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.garbageReportLoad)
	),
	bilgeWaterReportLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.bilgeWaterReportLoad)
	),
	bilgeWaterAllVesselsAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.bilgeWaterAllVesselsAggregateLoad)
	),
	allVesselsGarbageDischargeLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.garbageDischargeAllVesselsLoad)
	),
	allVesselsGarbageCategoryLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.garbageCategoryAllVesselsLoad)
	),
	vesselSpecificOrsAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.orsVesselSpecificAggregateLoad)
	),
	allVesselOrsAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.orsAllVesselAggregateLoad)
	),
	vesselSpecificCargoSlopAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.cargoSlopVesselSpecificAggregateLoad)
	),
	allVesselCargoSlopAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.cargoSlopAllVesselAggregateLoad)
	),
	vesselSpecificOilSpillsInWaterAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.oilSpillsInWaterVesselSpecificAggregateLoad)
	),
	allVesselOilSpillsInWaterAggregateLoad: jobActions(
		reportConstants.FEATURE,
		getType(reportActions.commands.oilSpillsInWaterAllVesselAggregateLoad)
	),
};

export type TReportActions = ActionType<typeof reportActions>;
export default reportActions;
