import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import Select from "react-select";
import {
	IFeedbackUpdatePayload,
	IFeedbackUpdateType,
	IOption,
} from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";
import {
	clearIndicatorStyles,
	dropdownIndicatorStyles,
	getUserOptions,
	menuStyles,
	multiValueLabelStyles,
	multiValueRemoveStyles,
	multiValueStyles,
	optionStyles,
	placeholderStyles,
} from "redux/app/feedback/utils";
import userSelectors from "redux/app/user/selectors";
import { IStore } from "redux/store";
import clsx from "clsx";
import InfiniteProgress from "components/_common/InfiniteProgress";

type OwnProps = {
	feedbackAPIUpdateType: IFeedbackUpdateType;
	setFeedbackAPIUpdateType: React.Dispatch<
		React.SetStateAction<IFeedbackUpdateType>
	>;
};
type AssignedToTableProps = PropsFromRedux & OwnProps;
function AssignedToTable(props: AssignedToTableProps) {
	const {
		individualFeedback,
		users,
		feedbackAPIUpdateType,
		setFeedbackAPIUpdateType,
		jobToUpdateFeedback,
	} = props;
	const [assignedVal, setAssignedVal] = useState<undefined | IOption[]>();
	const validUsers = getUserOptions(users);
	const userOptions = validUsers?.map((user) => ({
		label: user.name,
		value: user.id,
	}));

	useEffect(() => {
		if (individualFeedback?.assignedTo) {
			setAssignedVal(
				individualFeedback?.assignedTo.map((user) => ({
					label: user.userName,
					value: user.userId,
				}))
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [individualFeedback?.assignedTo]);
	return (
		<>
			<InfiniteProgress
				isLoading={
					feedbackAPIUpdateType.isTable2Update && jobToUpdateFeedback.isLoading
				}
				isSpacedOut={false}
			/>
			<table
				className={classNames(
					"w-full   font-redhat-text  overflow-auto  border-separate border-spacing-0 overflow-x-auto relative mb-4 md:mb-0"
				)}
			>
				<thead>
					<tr>
						<th
							className={classNames(
								"px-3 py-3 text-[12px] md:text-sm border-[0.8px] font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 "
							)}
						>
							Assigned to
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td
							className={classNames(
								"px-3 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D] hover:bg-gray-200  min-w-28 lg:min-w-min",
								{
									"cursor-pointer": individualFeedback?.status !== "Closed",
									"cursor-not-allowed bg-gray-200":
										individualFeedback?.status === "Closed",
								}
							)}
						>
							<div>
								{userOptions.length > 0 ? (
									<Select
										isMulti
										isSearchable
										isClearable
										placeholder="Select users"
										isDisabled={individualFeedback?.status === "Closed"}
										unstyled
										options={userOptions}
										value={assignedVal}
										className="w-full"
										classNames={{
											option: ({ isFocused, isSelected }) =>
												clsx(
													isFocused && optionStyles.focus,
													isSelected && optionStyles.selected,
													optionStyles.base
												),
											dropdownIndicator: () => dropdownIndicatorStyles,
											menu: () => menuStyles,
											clearIndicator: () => clearIndicatorStyles,
											placeholder: () => placeholderStyles,
											multiValue: () => multiValueStyles,
											multiValueRemove: () => multiValueRemoveStyles,
											multiValueLabel: () => multiValueLabelStyles,
										}}
										onChange={(newValue, actionMeta) => {
											const assignedTo = newValue.map((miniUser) => ({
												userId: miniUser.value,
												userName: miniUser.label,
												userEmail:
													validUsers.find((user) => user.id === miniUser.value)
														?.email ?? "",
											}));
											setFeedbackAPIUpdateType({
												isTable1Update: false,
												isTable2Update: true,
												notesUpdate: false,
											});
											setAssignedVal(
												assignedTo.map((user) => ({
													label: user.userName,
													value: user.userId,
												}))
											);
											props.updateFeedback({ assignedTo });
										}}
									/>
								) : (
									<div className="py-3">No users</div>
								)}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const usersStore = userSelectors._getStore(store);
	const users = userSelectors.getUsersList(usersStore);
	const individualFeedback =
		feedbackSelectors.getIndividualFeedback(feedbackStore);
	const jobToUpdateFeedback =
		feedbackSelectors.jobToUpdateSelectedFeedback(feedbackStore);

	return { users, individualFeedback, jobToUpdateFeedback };
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		updateFeedback(payload: IFeedbackUpdatePayload) {
			dispatch(feedbackActions.commands.feedbackUpdate(payload));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(AssignedToTable);
