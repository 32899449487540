import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { IStore } from "redux/store";
import CustomTooltip from "./CustomTooltip";
import { EGarbageCategoryTypes } from "redux/app/report/@types";
import { transformDataForGarbageCategoryForAllVesselsForGraphgarbageCategoryAllVesselRecords } from "redux/app/report/utils";

const xAxisScale = "Year";
const yAxisScale = "m³";

const garbageCategories = [
	{
		key: EGarbageCategoryTypes.PLASTICS,
		name: "Plastic",
		color: "#089a8e",
	},
	{
		key: EGarbageCategoryTypes.FOOD_WASTES,
		name: "Food waste",
		color: "#354245",
	},
	{
		key: EGarbageCategoryTypes.DOMESTIC_WASTE,
		name: "Domestic waste",
		color: "#d55551",
	},
	{
		key: EGarbageCategoryTypes.COOKING_OIL,
		name: "Cooking oil",
		color: "#e5be0e",
	},
	{
		key: EGarbageCategoryTypes.INCINERATOR_ASHES,
		name: "Incinerator ashes",
		color: "#535d5e",
	},
	{
		key: EGarbageCategoryTypes.OPERATIONAL_WASTES,
		name: "Operational waste",
		color: "#86cae0",
	},
	{
		key: EGarbageCategoryTypes.EWASTE,
		name: "Electronic waste",
		color: "#d68158",
	},
];

type OwnProps = {
	width: number;
};
type GarbageCategoryGraphProps = PropsFromRedux & OwnProps;
function GarbageCategoryGraph(props: GarbageCategoryGraphProps) {
	const { width, year, garbageCategoryAllVesselsData } = props;

	const finalData = useMemo(() => {
		const yearRecord =
			transformDataForGarbageCategoryForAllVesselsForGraphgarbageCategoryAllVesselRecords(
				garbageCategoryAllVesselsData
			) ?? {};
		yearRecord.name = year;
		return Object.keys(yearRecord).length ? [yearRecord] : [];
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [garbageCategoryAllVesselsData, year]);

	return (
		<div className="w-full flex flex-col justify-center items-center">
			<h3 className="font-medium">Garbage - volume by category (m³)</h3>
			<div className="flex flex-wrap p-4 pb-1.5">
				{garbageCategories.map((category) => {
					const { name, key, color } = category;
					return (
						<div className="flex basis-1/3 lg:min-w-[130px] lg:text-[10.5px] xl:text-sm space-x-1.5 py-0.5 items-center pl-1.5">
							<span
								style={{ background: color }}
								className="w-4 h-4 rounded-full"
							/>
							<div>
								<span>{key} - </span>
								<span>{name}</span>
							</div>
						</div>
					);
				})}
			</div>
			<div className="flex flex-col">
				<div className="axis flex justify-end text-[#7E7E7E]">
					<p className="m-0 text-[12px] font-medium px-3.5 border-r-2 border-[#7E7E7E]">
						X-axis - {xAxisScale}
					</p>
					<p className="m-0 text-[12px] font-medium px-3.5">
						Y-axis - {yAxisScale}
					</p>
				</div>
				<ResponsiveContainer
					width={width > 900 ? width * 0.4 : 0.75 * width}
					height={375}
				>
					<BarChart
						width={width}
						data={finalData}
						margin={{
							top: 25,
							right: 10,
							left: -20,
							bottom: 5,
						}}
						barGap={0}
					>
						<CartesianGrid vertical={false} fill="" />
						<XAxis tickLine={false} dataKey="name" fontSize={14} />
						<YAxis tickLine={false} fontSize={14} />
						<Tooltip content={<CustomTooltip legends={garbageCategories} />} />
						{garbageCategories.map((category) => {
							const { name, key, color } = category;
							return <Bar barSize={40} key={name} dataKey={key} fill={color} />;
						})}
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const year = reportSelectors.getGarbageYear(reportStore);
	const garbageCategoryAllVesselsData =
		reportSelectors.getGarbageCategoryAllVesselsData(reportStore);

	return {
		year,
		garbageCategoryAllVesselsData,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageCategoryGraph);
