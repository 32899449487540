import actionTypes from "./action-types";
import { apiActions } from "../../_core/api";
import { Middleware } from "redux";
import { IStore } from "../../store";
import checklistActions from "./actions";
import checklistAPIs from "./apis";
import { logJobActions } from "../logs/actions";
import rootRoutes from '../../../routes/routes';
import historyActions from '../../_core/history/actions';
import { IChecklist, ITabChecklist } from "./types";
import { IConfigListItem } from "../tool/@types";
import checklistConstants from "./constants";

const { apiRequest } = apiActions;
const {
	CHECKLIST_LIST_LOAD,
	CHECKLIST_BY_ID,
	CHECKLIST_LOAD,
	CHECKLIST_FILTER,
	CHECKLIST_UNIQUE_LIST_LOAD,
	CHECKLIST_COUNT_LOAD,
	LOAD_CHECKLIST,
	SINGLE_CHECKLIST_LOAD,
	CHECKLIST_PRINT_TEMPLATE_LOAD
} = actionTypes;

const checklistMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case CHECKLIST_UNIQUE_LIST_LOAD: {
				next(action);
				const { vesselId } = action.payload;
				const job = checklistActions._jobs.checklistUniqueList;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				dispatch(
					apiRequest<IConfigListItem[]>({
						...checklistAPIs.loadChecklistUniqueList(userId, token, vesselId),
						preExecute: () => {
							dispatch(
								job.active({ message: 'Loading unique checklists.' })
							);
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const checklistUniqueList = response.data;
									dispatch(job.success({}));
									dispatch(checklistActions.checklistUniqueListSet(checklistUniqueList));
								} else
									dispatch(
										job.error({
											message: "No Checklists count found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case CHECKLIST_COUNT_LOAD: {
				const { vesselId } = action.payload;
				const job = checklistActions._jobs.checklistLoad;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";
				const filters = getState().app.checklistStore._forms.FILTER.fields;

				dispatch(
					apiRequest<{ count: number }>({
						...checklistAPIs.loadChecklistCount({ filters, vesselId, token, userId }),
						preExecute: () => {
							// dispatch(job.active({}));
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const countData = response.data;
									dispatch(job.success({}));
									dispatch(checklistActions.paginationTotalItemsSet(countData?.count || 0));
								} else
									dispatch(
										job.error({
											message: "No Checklists count found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case CHECKLIST_LOAD: {
				const { pageNumber, vesselId } = action.payload;
				const job = checklistActions._jobs.checklistLoad;
				let pagination = getState().app.checklistStore._pagination;
				const tenantId = getState().app.auth.user?.tenantId ?? "";

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				// TODO: Add filters
				const filters: any = {};

				dispatch(
					apiRequest<ITabChecklist[]>({
						...checklistAPIs.loadChecklist({...filters, tenantId, vesselId, token, userId }, pagination.itemsPerPage, pageNumber),
						preExecute: () => {
							dispatch(checklistActions.paginationCurrentPageSet(pageNumber));
							dispatch(job.active({ message: "Loading..." }));
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const checklists = response.data;
									dispatch(job.success({}));
									dispatch(checklistActions.checklistSet(checklists));
								} else
									dispatch(
										job.error({
											message: "No Checklists found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case CHECKLIST_FILTER: {
				const { filters } = action.payload;
				const job = checklistActions._jobs.checklistFilter;
				let pagination = getState().app.checklistStore._pagination;
				const vesselId = filters.vesselId ? filters.vesselId : getState().app.vesselStore.onboard.vesselId || "";
				const tenantId = getState().app.auth.user?.tenantId ?? "";

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				dispatch(
					apiRequest<ITabChecklist[]>({
						...checklistAPIs.loadChecklist({ ...filters, tenantId, vesselId, token, userId }, pagination.itemsPerPage, pagination.currentPage),
						preExecute: () => {
							dispatch(checklistActions.paginationReset());
							dispatch(job.active({ message: "Loading..." }));
							dispatch(
								historyActions.replace(
									CHECKLIST_FILTER,
									// rootRoutes.children.app.children.log.children.list.url(pagination.currentPage)
									rootRoutes.children.app.children.vessel.children.onboard.children.checklist.url(vesselId, pagination.currentPage)
								)
							);
							dispatch(checklistActions.checklistCountLoad(vesselId));
						},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const checklists = response.data;
									dispatch(job.success({}));
									dispatch(checklistActions.checklistSet(checklists));
								} else
									dispatch(
										job.error({
											message: "No Checklists found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case SINGLE_CHECKLIST_LOAD: {
				const { checklist, vesselId } = action.payload;
				const job = checklistActions._jobs.singleChecklistLoad;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				dispatch(
					apiRequest<IChecklist>({
						...checklistAPIs.loadSingleChecklist(checklist, { vesselId: vesselId, token, userId }),
						preExecute: () => {
							dispatch(checklistActions.checklistPrintTemplateReset());
							dispatch(
								job.active({
									message: "Loading Checklist...",
									notification: action.payload.notify
										? {}
										: undefined,
								})
							)},
						postExecute: {
							onSuccess: (response) => {
								const checklist = response.data;
								dispatch(
									checklistActions.selectChecklist(checklist)
								);
								dispatch(job.success({}));
								dispatch(
									checklistActions.checklistPrintTemplateLoad(
										vesselId,
										checklist.code,
										checklist?.versionInfo?.config
									)
								);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case CHECKLIST_PRINT_TEMPLATE_LOAD: {
				const { code, vesselId, recordConfigVersion } = action.payload;
				const job = checklistActions._jobs.checklistPrintTemplateLoad;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				dispatch(
					apiRequest<any>({
						...checklistAPIs.loadPrintTemplate(
							vesselId,
							code,
							userId,
							token,
							recordConfigVersion
						),
						preExecute: () => {
							dispatch(
								job.active({
									message:
										"Loading Checklist Print Template...",
									notification: action.payload.notify
										? {}
										: undefined,
								})
							);
						},
						postExecute: {
							onSuccess: (response) => {
								dispatch(
									checklistActions.checklistPrintTemplateSet(
										response.data
									)
								);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case CHECKLIST_LIST_LOAD: {
				// next(action);
				const job = checklistActions._jobs.checklistListLoad;
				dispatch(
					apiRequest<{
						logs: any;
						count: number;
					}>({
						...checklistAPIs.listAPI(),
						preExecute: () => {
							dispatch(
								job.active({
									message: "Loading...",
									notification: action.payload.notify
										? {}
										: undefined,
								})
							)},
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const { logs } = response.data;
									const checklists = logs;
									dispatch(
										job.success({
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
									dispatch(checklistActions.listSet(checklists));
								} else
									dispatch(
										job.error({
											message: "No Checklists found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case CHECKLIST_BY_ID: {
				const job = checklistActions._jobs.checklistListLoad;
				const store = getState();
				const checklistStore: any = store.app.checklistStore;
				const checklist = checklistStore.checklist;
				dispatch(
					apiRequest<{
						task: any
					}>({
						...checklistAPIs.updateChecklistAPI(checklist),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const url = rootRoutes.children.app.children.checklist.children.list.url;
									dispatch(historyActions.push("@checklist", url()));
									dispatch(checklistActions.listLoad(true));
								}
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case LOAD_CHECKLIST: {
				next(action);
				const log = action.payload.checklist;
				const job = logJobActions.logLoad;
				if (log) {
					dispatch(job.active({ message: "Loading Checklist" }));
					dispatch(checklistActions.checklistSelect(log));
					dispatch(job.success({}));
					const url = rootRoutes.children.app.children.checklist.children.saf01.url;
					dispatch(historyActions.push("@checklist", url()));
				}

				break;
			}
			case "form/fields/EDIT": {
				next(action);
				const { feature, formName } = action.payload;
				if (
					feature === checklistConstants.feature &&
					formName === checklistConstants.forms.FILTER
				) {
					const latestFilters = getState().app.checklistStore._forms.FILTER.fields;
					
					// Info: change in the dropdown should lead to pagination to page 1
					dispatch(checklistActions.paginationCurrentPageSet(1))
					dispatch(checklistActions.checklistFilter(latestFilters));
				}
				break;
			}
			default: {
				next(action);
			}
		}
	};
export default checklistMiddleware;