import React from "react";
import LogList from "components/app/log/list/List"

type ScreenVesselOnboardLogsType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
			pageNumber: string;
		};
	};
}>;
const ScreenVesselOnboardLogs: React.FC<ScreenVesselOnboardLogsType> = (
	props: ScreenVesselOnboardLogsType
) => {
	return <LogList 
			pageNumber={props.match.params.pageNumber} 
			vesselId={props.match.params.vesselId}
		/>;
};

export default ScreenVesselOnboardLogs;
