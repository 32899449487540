import React, { useEffect } from "react";
import { IStore } from "../redux/store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import appCoreActions from "../redux/app/_core/actions";
import RedirectViaRedux from "./_common/RedirectViaRedux";

const Root: React.FC<{
	initializeApp: () => void;
}> = (props) => {
	useEffect(function onLoad() {
		props.initializeApp();
	}, []);
	return (
		<>
			<RedirectViaRedux />
		</>
	);
};

const mapStateToProps = (store: IStore) => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
	initializeApp: () => dispatch(appCoreActions.initialize()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Root);
