import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react'
import AddModal from './AddModal';
import EditModal from './EditModal';
import IconInfo from 'components/_common/icons/Info';
import { ERANGE_MGMT_TYPES, ESIGNAL_REPORT_TYPE_VAL, ILocalRange, TRangeManagement } from 'redux/app/vessel/onboard/types';
import { ModalType } from '../Ranges';

interface IRangeManagementFormProps {
    localRange: ILocalRange;
    modalType: ModalType;
    onCancel: () => void;
    onSubmit: (localRange?: ILocalRange) => void;
    rangeManagement?: TRangeManagement;
	logCategories: any;
	dropdownType: ERANGE_MGMT_TYPES;
	signalReportType: ESIGNAL_REPORT_TYPE_VAL
}

export type TValueLabelObj = {
    value: string;
    label?: string;
};

export interface IData {
    category: TValueLabelObj;
	tab?: TValueLabelObj;
    section: TValueLabelObj;
    metric: TValueLabelObj;
    range: (number | undefined)[];
    key: string;
    remarks: string;
    modules: TValueLabelObj;
    subModules: TValueLabelObj;
    isLocalRange?: boolean;
	type?: string // ESignalReportTypeVal - this key is for determining the type of signalReport
}

export const getEmptyValueLabelObj = () => {
    return {
        label: '',
        value: ''
    };
}

const RangeManagementForm: FC<IRangeManagementFormProps> = (props) => {
    // const logCategories = props?.rangeManagement?.config?.logCategories;
	const {logCategories,signalReportType,dropdownType} = props
    const categories = useRef<{[key: string]: any}>({});
	const [tabbedSections,setTabbedSections] = useState<Record<string,string[]>>({})
    const sections = useRef<{[key: string]: any}>({});
    const [data, setData] = useState<IData>({
        category: getEmptyValueLabelObj(),
		tab: getEmptyValueLabelObj(),
        section: getEmptyValueLabelObj(),
        metric: {
            label: props.localRange.metric,
            value: props.localRange.metric
        },
        range: props.localRange.range as number[],
        key: props.localRange.key,
        remarks: props.localRange.remarks,
        modules: getEmptyValueLabelObj(),
        subModules: getEmptyValueLabelObj(),
        isLocalRange: props.localRange.isLocalRange,
		type: props?.localRange?.type, // it would be only available for signal report.
    });


	useEffect(() => {
        if (logCategories) {
            let tabbedSections = {} as Record<string,string[]>
            logCategories?.forEach((category: any) => {
                categories.current[category?.mnemonic || category?.title] =
                    category;
                category?.sections?.forEach((section: any) => {
                    for (let i = 0; i < section?.metrics?.length; i++) {
                        const metric = section?.metrics[i];
                        if (
                            [
                                'generatorEnginesDropdown',
                                'tabbed_view',
                            ].includes(metric?.readingRange?.dataType)
                        ) {
                            tabbedSections[
                                category?.mnemonic || category?.title
                            ] = metric?.readingRange?.range?.map(
                                (tab: any) => tab?.label
                            );
                        }
                    }
                    sections.current[section?.mnemonic || section?.title] =
                        section;
                });
            });
            setTabbedSections(tabbedSections);
        }
    }, [logCategories]);


    // useEffect(() => {
    //     if (logCategories) {
    //         logCategories?.forEach((category: any) => {
    //             categories.current[category?.mnemonic || category?.title] = category;
    //             category?.sections?.forEach((section: any) => {
    //                 sections.current[section?.mnemonic || section?.title] = section;
    //             })
    //         })
    //     }
    // }, [logCategories]);
  return (
      <div className="flex flex-col flex-wrap items-center justify-center mx-auto font-redhat-text min-w-[500px]">
          <div
			className={classNames("flex flex-col items-center justify-center w-full bg-white rounded-lg ws-scroll", {
				"h-[calc(100vh-100px)]": props.modalType === ModalType.Add,
				"": props.modalType === ModalType.Edit,
			})}
		  >
              <div
                  className="flex flex-col flex-1 w-full h-full px-6 py-6"
                  onSubmit={(e) => {
                      e.preventDefault();
                      props.onSubmit();
                  }}
              >
                  <div className="flex flex-row flex-wrap items-center w-full mb-4 gap-x-2">
                      <span className={classNames('text-blue-900', {})}>
                          <IconInfo />
                      </span>
                      <h4
                          className={classNames(
                              'mb-0 text-xl font-medium leading-none text-gray-800 font-redhat-display'
                          )}
                      >
                          {props.modalType === ModalType.Remarks
                              ? 'Remarks for'
                              : props.modalType}{' '}
                          Range.
                      </h4>
                  </div>
				  <div
					className={classNames('', {
						'pb-6': props.modalType === ModalType.Add,
					})}
				  >
					{props.modalType === ModalType.Add ? (
						<AddModal
							onCancel={props.onCancel}
							onSubmit={props.onSubmit}
							categories={categories}
							data={data}
							logCategories={logCategories}
							sections={sections}
							setData={setData}
							tabbedSections={tabbedSections}
							dropdownType={dropdownType}
							signalReportType={signalReportType}
						/>
					) : null}
					{props.modalType === ModalType.Edit ? (
						<EditModal
							onCancel={props.onCancel}
							onSubmit={props.onSubmit}
							data={data}
							setData={setData}
							dropdownType={dropdownType}
                          signalReportType={signalReportType}
						/>
					) : null}
				  </div>
              </div>
          </div>
      </div>
  );
}

export default RangeManagementForm