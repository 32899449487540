import React from 'react';
import classNames from 'classnames';
import { EBallastRecordState, TBallastRecord } from 'redux/app/ballast/@types';

export type BallastTableColumns = React.ReactNode[];
type OwnProps = {
    record: TBallastRecord;
    tableRows: BallastTableColumns[];
};
function BallastTableRows(props: OwnProps) {
    const { record, tableRows } = props;
    const isStrikedOff = record.state === EBallastRecordState.STRIKED_OFF;
    return (
        <>
            {tableRows.map((tableRow, index) => {
                const isValidRow = tableRow.length === 3;
                if (!isValidRow) {
                    console.error(
                        'Invalid Ballast Row format for:',
                        record.recordCode,
                        isValidRow,
                        tableRow.length
                    );
                    return null;
                }
                const dateSection = tableRow[0];
                const itemSection = tableRow[1];
                const remarksSection = tableRow[2];
                const isRemarksEmpty =
                    remarksSection === '' || remarksSection === null;

                if (isRemarksEmpty) return null;

                return (
                    <tr
                        className={classNames('uppercase', {
                            'line-through': isStrikedOff,
                        })}
                        key={index}
                    >
                        <td className="relative">
                            <div
                                className={classNames(
                                    { 'line-through': isStrikedOff },
                                    'p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix'
                                )}
                            >
                                <div className="h-full">
                                    <span className="block font-medium whitespace-nowrap">
                                        {dateSection}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td className="relative">
                            <div
                                className={classNames(
                                    { 'line-through': isStrikedOff },
                                    'p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix'
                                )}
                            >
                                <div className="h-full font-medium">
                                    {itemSection}
                                </div>
                            </div>
                        </td>
                        <td className="relative">
                            <div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
                                <div className="font-medium uppercase">
                                    {remarksSection}
                                </div>
                            </div>
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

export default BallastTableRows;
