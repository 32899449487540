import Report from "components/app/report/Report";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { rootRoutes } from "routes";
import ScreenEngineAndNoon from "./engineAndNoon";

const reportsRoutes = rootRoutes.children.app.children.insights;
type ScreenUserProps = React.PropsWithChildren<{}>;
function ScreenReport(props: ScreenUserProps) {
	return (
		<Report>
			<Switch>
				<Route
					exact
					path={reportsRoutes.path}
					component={ScreenEngineAndNoon}
				/>

				<Route render={RedirectComponent} />
			</Switch>
		</Report>
	);
}

function RedirectComponent() {
	return <Redirect to={reportsRoutes.url()} />;
}
export default ScreenReport;
