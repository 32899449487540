import { IListObjectStore } from "redux/_common/type";
import { EVESSEL_ALERTS_STATUS, IVessel, IVesselAlerts } from "../vessel/types";
import L from "leaflet";
import blueShip from "../../../components/app/dashboard/list/blueShip.svg";
import orangeShip from "../../../components/app/dashboard/list/blueShip.svg";
import yellowShip from "../../../components/app/dashboard/list/yellowShip.svg";
import { ITransformedTableRow, TAlertAndUsageContent } from "./@types";
import { convertDmmToDecimalDegrees } from "components/app/dashboard/list/utils";

export function transformVesselsDataForTable(
	vessels: IListObjectStore<IVessel>
): ITransformedTableRow[] {
	if (!vessels.ids.length) return [];
	const modifiedVessels = vessels?.ids?.map((vesselId) => {
		const vessel = vessels.byIds[vesselId];
		const { id, vesselName, vesselAlerts } = vessel;

		const from = vessel?.liveInfo?.from?.local_reading;
		const to = vessel?.liveInfo?.to?.local_reading;
		return {
			id,
			name: vesselName,
			fromTo: from || to ? `${from || "NA"} - ${to || "NA"}` : "NA",
			alerts: vesselAlerts?.observations?.alerts ?? {},
			usage: vesselAlerts?.observations?.usage ?? {},
		};
	});

	return modifiedVessels ?? [];
}

export function getShipIcon(status: EVESSEL_ALERTS_STATUS) {
	let iconUrl = blueShip;
	if (status === EVESSEL_ALERTS_STATUS.GOOD) {
		iconUrl = blueShip;
	} else if (status === EVESSEL_ALERTS_STATUS.HIGH) {
		iconUrl = orangeShip;
	} else if (status === EVESSEL_ALERTS_STATUS.MEDIUM) {
		iconUrl = yellowShip;
	}

	const shipIcon = L.icon({
		iconUrl,
		iconSize: [32, 32], // Adjust the size of the ship icon
		iconAnchor: [16, 16], // Adjust the anchor point of the ship icon
	});
	return shipIcon;
}

const getStatusType = (status: string) => {
	let statusType = EVESSEL_ALERTS_STATUS.GOOD;
	if (status === "high") statusType = EVESSEL_ALERTS_STATUS.HIGH;
	else if (status === "medium") statusType = EVESSEL_ALERTS_STATUS.MEDIUM;

	return statusType;
};

export function transformAlertsAndUsageForMap(
	observations: IVesselAlerts["observations"]
) {
	const alerts = observations?.alerts;
	const usage = observations?.usage;

	let listOfAlerts = [] as TAlertAndUsageContent[],
		listOfUsage = [] as TAlertAndUsageContent[];
	if (alerts) {
		Object.keys(alerts)?.forEach((alert) => {
			// alert can be high,medium...
			const alertContent = alerts[alert];
			const modifiedAlertContent = alertContent.map((content) => ({
				...content,
				status: getStatusType(alert),
			}));
			listOfAlerts = listOfAlerts.concat(modifiedAlertContent);
		});
	}
	if (usage) {
		Object.keys(usage)?.forEach((usageKey) => {
			const usageContent = usage[usageKey];
			const modifiedUsageContent = usageContent.map((content) => ({
				...content,
				status: getStatusType(usageKey),
			}));
			listOfUsage = listOfUsage.concat(modifiedUsageContent);
		});
	}

	return { listOfAlerts, listOfUsage };
}

export function getVesselLocations(vessels: IListObjectStore<IVessel>) {
	const vesselLocations = vessels.ids.map((id) => {
		const vessel = vessels.byIds[id];
		const rawCoordinates =
			vessel?.liveInfo?.last_location?.lat &&
			vessel?.liveInfo?.last_location?.long
				? convertDmmToDecimalDegrees(
						vessel?.liveInfo?.last_location.lat,
						vessel?.liveInfo?.last_location.long,
						vessel?.vesselName
				  )
				: null;

		return {
			id: vessel.id,
			name: vessel.vesselName,
			last_location: vessel?.liveInfo?.last_location,
			last_synced: vessel?.liveInfo?.last_synced,
			latitude: rawCoordinates?.latitude,
			longitude: rawCoordinates?.longitude,
			from_to: `${vessel?.liveInfo?.from?.local_reading || ""} - ${
				vessel?.liveInfo?.to?.local_reading || ""
			}`,
			status: vessel?.vesselAlerts?.status ?? EVESSEL_ALERTS_STATUS.GOOD,
			observations: vessel?.vesselAlerts?.observations,
		};
	});

	const filteredVesselLocations = vesselLocations.filter(
		(vessel) => vessel.latitude && vessel.longitude
	);
	return filteredVesselLocations;
}

export function tableColorMapper(key: string) {
	const colorMapper = {
		high: "#E67E22",
		medium: "#DCD413",
	};
	return key in colorMapper
		? colorMapper[key as keyof typeof colorMapper]
		: colorMapper["high"];
}
