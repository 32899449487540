import store, { IStore } from "redux/store";
import { IUserStore } from "./@types";
import { jobSelectors } from "redux/_core/job";
import { getType } from "typesafe-actions";
import userActions from "./actions";
import { formSelectors } from "redux/_core/form";

const userSelectors = {
	_getStore(store: IStore) {
		return store.app.user;
	},
	getUsersList(userStore: IUserStore) {
		return userStore?.users ?? [];
	},
	getUserProfileDetails(userStore: IUserStore) {
		return userStore?.userProfile;
	},
	getUserFormData(userStore: IUserStore) {
		return {
			form: formSelectors(userStore._forms.USER),
			jobs: {
				userCreate: jobSelectors.getJobStatus(
					userStore,
					getType(userActions.commands.userCreate)
				),
				userUpdate: jobSelectors.getJobStatus(
					userStore,
					getType(userActions.commands.userUpdate)
				),
				userDelete: jobSelectors.getJobStatus(
					userStore,
					getType(userActions.commands.userDelete)
				),
				userLoad: jobSelectors.getJobStatus(
					userStore,
					getType(userActions.commands.userListLoad)
				),
			},
		};
	},
	getDeviceFormStaticData(isUserAllowedToAccessFeedbackModule?: boolean) {
		let options = [{ label: "Superintendent", value: "Superintendent" }];

		if (isUserAllowedToAccessFeedbackModule) {
			options.push({ label: "Developer", value: "developer" });
			options.push({ label: "Support", value: "support" });
		}

		return {
			roleCategory: {
				label: "Role",
				placeholder: "",
				options: options,
			},
		};
	},
	getPagination(userStore: IUserStore) {
		return userStore._pagination;
	},
	getJobToLoadUsers(userStore: IUserStore) {
		return jobSelectors.getJobStatus(
			userStore,
			getType(userActions.commands.userListLoad)
		);
	},
};

export default userSelectors;
