import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4M1 } from "../../../../../redux/app/orb4/groups/orb4-m";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4M1PreviewProps = OwnProps;
function ORB4M1Preview(props: ORB4M1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4M1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "M", 70, renderItem70()],
					[null, null, 71, renderItem71()],
					[null, null, 72, renderItem72()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem70() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item70And71.timeOfSystemFailure.LT} LT (
				{orb4Record.item70And71.timeOfSystemFailure.UTC}Z),{" "}
				{orb4Record.item70And71.timeOfSystemFailure.dateOfFailure
					? moment(
							orb4Record.item70And71.timeOfSystemFailure.dateOfFailure,
							"YYYY-MM-DD"
					  ).format("DD-MMM-YYYY")
					: null}
			</h4>
		);
	}
	function renderItem71() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item70And71.isOperationalTimeKnown &&
				orb4Record.item70And71.timeWhenSystemIsOperational.dateOfRestoration
					? `${moment(
							orb4Record.item70And71.timeWhenSystemIsOperational
								.dateOfRestoration,
							"YYYY-MM-DD"
					  ).format("DD-MMM-YYYY")}, `
					: null}
				{orb4Record.item70And71.isOperationalTimeKnown
					? `${orb4Record.item70And71.timeWhenSystemIsOperational.LT} LT
		(${orb4Record.item70And71.timeWhenSystemIsOperational.UTC}Z`
					: "N/A"}
				)
			</h4>
		);
	}
	function renderItem72() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item72.reasonForFailure}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4M1Preview;
