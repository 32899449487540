import cuid from "cuid";
import moment from "moment";
import {
	EGRB2State,
	IGarbage,
	IGRB2,
	IGRB2NewOperationState,
	TGarbageEntries,
	TGarbageQuestion,
} from "./@types";
import { EMARPOLDocuments } from "../marpol/@types";

const grb2Utils = {
	getDischargeCategories(
		garbageEntry?: TGarbageEntries,
		isEPS: boolean = false
	): IGarbage[] {
		switch (garbageEntry) {
			case "GARBAGE_DISCHARGED_INTO_SEA":
				return [
					{
						label: "Cargo Residue (Non-HME)",
						estimatedAmountDischarged: 0,
						value: "J",
						category: "J",
					},
				];
			case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
				return [
					{
						label: "Cargo Residue (Non-HME)",
						estimatedAmountDischarged: 0,
						value: "J",
						category: "J",
					},
					{
						label: "Cargo Residue (HME)",
						estimatedAmountDischarged: 0,
						value: "K",
						category: "K",
					},
				];
			case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
				return [
					{
						label: "Cargo Residue (Non-HME)",
						estimatedAmountDischarged: 0,
						value: "J",
						category: "J",
					},
					{
						label: "Cargo Residue (HME)",
						estimatedAmountDischarged: 0,
						value: "K",
						category: "K",
					},
				];
			default:
				return [
					{
						label: "Cargo Residue (Non-HME)",
						estimatedAmountDischarged: 0,
						value: "J",
						category: "J",
					},
				];
		}
	},
	getDefaultOperationData(): IGRB2NewOperationState {
		return {
			newOperationState: "RECORD",
			garbageBasicInfo: {
				marpolType: EMARPOLDocuments.GRB2,
				selectedDate: moment().format("YYYY-MM-DD"),
				id: cuid(),
				_rev: "",
				utcTimestamp: moment().format("HH:mm"),
				entry: "GARBAGE_DISCHARGED_INTO_SEA",
				state: EGRB2State.IDLE,
				editLogs: {},
				numberOfEditSessions: 0,
			},
			garbageAccidentalLoss: {
				entry: "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA",
				depthOfWater: 0,
				startOfOperation: {
					date: moment().format("YYYY-MM-DD"),
					time: moment().format("HH:mm"),
				},
				endOfOperation: {
					date: moment().format("YYYY-MM-DD"),
					time: moment().format("HH:mm"),
				},
				locationOfOccurance: "SEA",
				locationOfDischarge: {
					latitude: "",
					longitude: "",
				},
				garbage: [],
				remark: "",
			},
			garbageDischargedIntoSea: {
				entry: "GARBAGE_DISCHARGED_INTO_SEA",
				startOfOperation: {
					date: moment().format("YYYY-MM-DD"),
					time: moment().format("HH:mm"),
				},
				locationOfStartOfDischarge: {
					latitude: "",
					longitude: "",
				},
				garbage: [],
				remark: "",
			},
			garbageDischargedToReceptionAshoreOrOtherShips: {
				entry: "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
				placeOfDischarge: "",
				garbage: [],
				remark: "",
				receiptNumber: "",
				receiptAttachment: "",
			},
		};
	},
	convertBackendRecordToOperationState(grb2: IGRB2): IGRB2NewOperationState {
		const defaultNewOperationState = grb2Utils.getDefaultOperationData();
		return {
			...defaultNewOperationState,
			garbageBasicInfo: {
				state: EGRB2State.IDLE,
				marpolType: EMARPOLDocuments.GRB2,
				selectedDate: grb2.selectedDate, // moment().format('YYYY-MM-DD'),
				id: grb2.id, // cuid(),
				_rev: grb2._rev,
				utcTimestamp: grb2.utcTimestamp, // moment().format('HH:mm'),
				entry: grb2.entry, // 'GARBAGE_INCINERATED',
				editLogs: grb2.editLogs, // [],
				numberOfEditSessions: grb2.numberOfEditSessions + 1,
			},
			garbageAccidentalLoss:
				grb2.entry === "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA"
					? {
							entry: "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA",
							depthOfWater: grb2.depthOfWater, // 0,
							startOfOperation: grb2.startOfOperation, // "",
							endOfOperation: grb2.endOfOperation, // "",
							locationOfOccurance: grb2.locationOfOccurance, // 'SEA',
							locationOfDischarge: grb2.locationOfDischarge, // "",
							garbage: grb2.garbage, // "",
							remark: grb2.remark, // '',
					  }
					: defaultNewOperationState.garbageAccidentalLoss,
			garbageDischargedIntoSea:
				grb2.entry === "GARBAGE_DISCHARGED_INTO_SEA"
					? {
							entry: "GARBAGE_DISCHARGED_INTO_SEA",
							startOfOperation: grb2.startOfOperation, // '',
							locationOfStartOfDischarge: grb2.locationOfStartOfDischarge, // '',
							garbage: grb2.garbage, // '',
							remark: grb2.remark, // '',
					  }
					: defaultNewOperationState.garbageDischargedIntoSea,
			garbageDischargedToReceptionAshoreOrOtherShips:
				grb2.entry === "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS"
					? {
							entry: "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
							placeOfDischarge: grb2.placeOfDischarge, // '',
							garbage: grb2.garbage, // "",
							remark: grb2.remark, // '',
							receiptNumber: grb2.receiptNumber,
							receiptAttachment: grb2.receiptAttachment,
					  }
					: defaultNewOperationState.garbageDischargedToReceptionAshoreOrOtherShips,
		};
	},
	getOperationToSubmit(grb2: IGRB2): IGRB2NewOperationState {
		return {
			...grb2Utils.convertBackendRecordToOperationState(grb2),
			garbageBasicInfo: {
				...grb2Utils.convertBackendRecordToOperationState(grb2)
					.garbageBasicInfo,
				state: EGRB2State.IDLE,
				editLogs: {},
				numberOfEditSessions: 0,
			},
		};
	},
	getOperationForEdit(grb2: IGRB2): IGRB2NewOperationState {
		const grb2ToEditState =
			grb2Utils.convertBackendRecordToOperationState(grb2);
		grb2ToEditState.garbageBasicInfo.state = EGRB2State.EDIT;
		return grb2ToEditState;
	},
	getGarbageDischargedIntoSeaQuestions(
		garbage?: IGarbage
	): TGarbageQuestion | undefined {
		if (!garbage) return undefined;
		switch (garbage.category) {
			case "J": {
				return {
					question: "Is the cargo contained in washwater?",
					YES: {
						question: "Are you outside special areas and Arctic waters?",
						YES: {
							question: "Are you >12 nm, en route and as far as practicable?",
							YES: "ALLOWED",
							NO: "BLOCKED",
						},
						NO: {
							question: "Are you within special areas and Arctic waters?",
							YES: {
								question: `Is the port of departure and the next port of destination located within a
								special area and will the ship not transit outside the special area between these two ports?`,
								YES: {
									question: `Are there adequate reception facilities at the ports?`,
									YES: "BLOCKED",
									NO: {
										question: `Are you >12 nm, en route and as far as practicable (subject to conditions
											in regulation 6.1.2 and paragraph 5.2.1.5 of Part II-A of the Polar Code)`,
										YES: "ALLOWED",
										NO: "BLOCKED",
									},
								},
								NO: "BLOCKED",
							},
							NO: "BLOCKED",
						}, // CASE 3
					},
					NO: {
						question: "Are you outside special areas and Arctic waters?",
						YES: {
							question: "Are you >12 nm, en route and as far as practicable?",
							YES: "ALLOWED",
							NO: "BLOCKED",
						},
						NO: "BLOCKED",
					}, // CASE 2
				};
			}
		}
	},
	isUndefined(someValue: any) {
		return someValue === undefined;
	},
};
export default grb2Utils;
