import { IJobGetStatus, IJobStore } from "./types";
const getStatus = (job: IJobStore): IJobGetStatus => {
	if (job)
		return {
			isLoading: job.state === "ACTIVE",
			isError: job.state === "ERROR",
			isSuccess: job.state === "SUCCESS",
			isIdle: job.state === "IDLE",
			message: job.message,
			isExecuted: true,
		};
	return {
		isLoading: false,
		isError: false,
		isSuccess: false,
		isIdle: false,
		isExecuted: false,
		message: "",
	};
};
const getJobStatus = (state: any, feature: string): IJobGetStatus => {
	const job = state && state._jobs ? state._jobs[feature] : undefined;
	return getStatus(job);
};
const jobSelectors = { getJobStatus, getStatus };
export default jobSelectors;
