import { IJobReducerState, IJobHOCState, IJobStore } from "./types";
import { Reducer } from "redux";
import cuid from "cuid";
import constants from "./action-types";
const { JOB_ACTIVE, JOB_ERROR, JOB_IDLE, JOB_SUCCESS } = constants;
const reducerState: IJobReducerState = {};
const jobsReducers: Reducer = (
	state = reducerState,
	action
): IJobReducerState => {
	switch (action.type) {
		case JOB_IDLE:
			return {
				...state,
				[action.meta.JOB_NAME]: {
					state: "IDLE",
					...action.payload,
				},
			};
		case JOB_ACTIVE:
			return {
				...state,
				[action.meta.JOB_NAME]: {
					state: "ACTIVE",
					...action.payload,
				},
			};
		case JOB_ERROR:
			return {
				...state,
				[action.meta.JOB_NAME]: {
					state: "ERROR",
					...action.payload,
				},
			};
		case JOB_SUCCESS:
			return {
				...state,
				[action.meta.JOB_NAME]: {
					state: "SUCCESS",
					...action.payload,
				},
			};
	}
	return state;
};

const jobGlobalReducer: Reducer<IJobStore> = (
	state: IJobStore = { state: "IDLE", id: cuid() },
	action
): IJobStore => {
	if (action.meta && action.meta.hasGlobalEffect)
		switch (action.type) {
			case JOB_IDLE:
				return {
					...state,
					state: "IDLE",
					...action.payload,
				};
			case JOB_ACTIVE:
				return {
					...state,
					state: "ACTIVE",
					...action.payload,
				};
			case JOB_ERROR:
				return {
					...state,
					state: "ERROR",
					...action.payload,
				};
			case JOB_SUCCESS:
				return {
					...state,
					state: "SUCCESS",
					...action.payload,
				};
		}
	return state;
};

const withJobs = <T extends IJobHOCState>(
	feature: string,
	defaultState: T,
	reducer: Reducer<T>
): Reducer<T> => (state: T = defaultState, action) => {
	if (
		action.meta
			? action.meta.feature !== feature || !action.meta.JOB_NAME
			: true
	)
		return reducer(state, action);
	if (state._jobs === undefined) state = { ...defaultState, ...state };
	return reducer(
		{
			...state,
			_jobs: jobsReducers(state._jobs, action),
		},
		action
	);
};

export default withJobs;
export { jobGlobalReducer };
