import cuid from "cuid";
import moment from "moment";
import {
	EMARPOLDocuments,
	EMARPOLTankCategory,
	EMARPOLTankPosition,
	EMARPOLTankType,
	ETankGroupCodes,
	TMARPOLTank,
	TMARPOLTankFromBackend,
	TSoundingRecord,
	TSoundingRecordTank,
} from "./@types";
import marpolSelectors from "./selectors";

const tankUtils = {
	getTanks(
		tanks: TMARPOLTank[],
		tankCategory?: EMARPOLTankCategory,
		tankType?: EMARPOLTankType
	) {
		let tanksList = tanks;
		if (tankCategory)
			tanksList = tanksList.filter((tank) => tank.category === tankCategory);
		if (tankType)
			tanksList = tanksList.filter((tank) => tank.type === tankType);
		return tanksList;
	},
	generateTankId(tankCode: string) {
		return `${tankCode}-${cuid()}`;
	},
	getTankRemainingPercentage(tank: TMARPOLTank) {
		return (
			((tank.totalCapacity - tank.expectedQuantity) / tank.totalCapacity) * 100
		);
	},
	getExpectedQuantityPercent(tank: TMARPOLTank) {
		return (tank.expectedQuantity / tank.totalCapacity) * 100;
	},
	getTankRemainingQuantity(tank: TMARPOLTank) {
		return tank.totalCapacity - tank.expectedQuantity;
	},
	getDefaultIdForEmptyTank() {
		return "DEFAULT_ID_NOT_FOR_USE";
	},
	getDefaultsForNewTank(): TMARPOLTank {
		return {
			id: tankUtils.getDefaultIdForEmptyTank(),
			tankName: "",
			tankCode: "",
			expectedQuantity: 0,
			frames: "",
			lateralPosition: EMARPOLTankPosition.UNKNOWN,
			percent: 0,
			totalCapacity: 0,
			type: EMARPOLTankType.OTHER_TANK,
			valveStatus: "CLOSED",
			category: EMARPOLTankCategory.OTHER,
			observedQuantity: {
				preOperation: -1,
				postOperation: -1,
			},
			soundingQuantity: -1,
			quantitiesLimits: {
				maximum: -1,
				minimum: -1,
			},
		};
	},
	formatTankFromBackend(tankFromBackend: TMARPOLTankFromBackend): TMARPOLTank {
		const expectedQuantity =
			tankFromBackend.expectedQuantity !== null &&
			tankFromBackend.expectedQuantity !== undefined
				? tankFromBackend.expectedQuantity
				: tankFromBackend.soundingQuantity !== undefined &&
				  tankFromBackend.soundingQuantity !== null
				? tankFromBackend.soundingQuantity
				: -1;

		const observedQuantity = {
			preOperation: -1,
			postOperation: -1,
		};
		const soundingQuantity = tankFromBackend.soundingQuantity;
		const tankName = `${tankFromBackend.fullname}`;

		// @ts-ignore
		delete tankFromBackend.soundingQuantity;
		// @ts-ignore
		delete tankFromBackend.expectedQuantity;
		// @ts-ignore
		delete tankFromBackend.fullname;
		delete tankFromBackend.soundingQuantityInCM;

		return {
			...tankFromBackend,
			expectedQuantity,
			observedQuantity,
			soundingQuantity,
			tankName,
		};
	},
	sortTanksByCategory(tanks: TMARPOLTank[]) {
		const ioPP3P1Tanks = tanks.filter(
			(tank) => tank.category === EMARPOLTankCategory.IOPP3P1
		);
		const ioPP3P3Tanks = tanks.filter(
			(tank) => tank.category === EMARPOLTankCategory.IOPP3P3
		);
		const cargoTanks = tanks
			.filter((tank) => tank.category === EMARPOLTankCategory.CARGO)
			.sort(({ tankName: name1 }, { tankName: name2 }) => {
				if (name1.toLowerCase() < name2.toLowerCase()) return -1;
				if (name1.toLowerCase() > name2.toLowerCase()) return 1;
				return 0;
			});
		const otherTanks = tanks.filter(
			(tank) => tank.category === EMARPOLTankCategory.OTHER
		);
		const slopTanks = tanks.filter(
			(tank) => tank.category === EMARPOLTankCategory.SLOP_TANKS
		);
		const ballastTanks = tanks.filter(
			(tank) => tank.category === EMARPOLTankCategory.BALLAST
		);
		return [
			//@ts-ignore
			...ioPP3P1Tanks,
			...ioPP3P3Tanks,
			...cargoTanks,
			...otherTanks,
			...slopTanks,
			...ballastTanks,
		];
	},
	/**
	 *
	 *
	 * @description: `Sometimes pre-operation quantity will be not equal to retained quantity of tank, In such scenario user needs to confirm if descrepancy is due to automatic drainage from some source. In such condition Here we consider pre-operation quantity as expected quantity of tank for operation`.
	 */
	getQuantityForCalculation(tank: TMARPOLTank) {
		if (
			tank.observedQuantity.isHavingAutomaticCollection &&
			tank.observedQuantity.preOperation > tank.expectedQuantity
		)
			return tank.observedQuantity.preOperation;
		return tank.expectedQuantity;
	},
	getTanksBasedOnSoundingRecordCode(
		recordCode: ETankGroupCodes,
		tanks: TMARPOLTank[]
	): TMARPOLTank[] {
		switch (recordCode) {
			case ETankGroupCodes.ORB1:
				return [
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.IOPP3P1),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.IOPP3P3),
				];
			case ETankGroupCodes.ORB1_WITH_SLOP:
				return [
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.IOPP3P1),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.IOPP3P3),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.SLOP_TANKS),
				];
			case ETankGroupCodes.ORB1_WITH_OTHERS:
				return [
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.IOPP3P1),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.IOPP3P3),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.OTHER),
				];
			case ETankGroupCodes.ORB2:
				return [...tankUtils.getTanks(tanks, EMARPOLTankCategory.CARGO)];
			case ETankGroupCodes.ORB2_WITH_SLOP:
				return [
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.CARGO),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.SLOP_TANKS),
				];
			case ETankGroupCodes.ORB2_WITH_OTHERS:
				return [
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.CARGO),
					...tankUtils.getTanks(tanks, EMARPOLTankCategory.OTHER),
				];
			case ETankGroupCodes.BALLAST:
				return [...tankUtils.getTanks(tanks, EMARPOLTankCategory.BALLAST)];
			case ETankGroupCodes.OTHERS:
				return [...tankUtils.getTanks(tanks, EMARPOLTankCategory.OTHER)];
			case ETankGroupCodes.SLOP:
				return [...tankUtils.getTanks(tanks, EMARPOLTankCategory.SLOP_TANKS)];
		}
		return tankUtils.getTanks(tanks);
	},
	getSoundingRecordCodeOfTank(tank: TMARPOLTank) {
		switch (tank.category) {
			case EMARPOLTankCategory.IOPP3P1:
				return ETankGroupCodes.ORB1;
			case EMARPOLTankCategory.IOPP3P3:
				return ETankGroupCodes.ORB1;
			case EMARPOLTankCategory.CARGO:
				return ETankGroupCodes.ORB2;
			case EMARPOLTankCategory.SLOP_TANKS:
				return ETankGroupCodes.SLOP;
			case EMARPOLTankCategory.BALLAST:
				return ETankGroupCodes.BALLAST;
			case EMARPOLTankCategory.OTHER:
				return ETankGroupCodes.OTHERS;
		}
	},
};

export default tankUtils;
