import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupC1 } from "../../../../../redux/app/annex6/groups/group-c";
import { marpolUtils } from "../../../../../redux/app/marpol";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupC1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupC1Preview(props: Annex6GroupC1PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupC1;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'C', 5.0, renderItem5()],
                    [null, null, 6.1, renderItem6P1()],
                    [null, null, 6.2, renderItem6P2()],
                    [null, null, 6.3, renderItem6P3()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }
    function renderItem5() {
        return (
            <>
                BDN REFERENCE NO: {record.item5.BDN}, DATE FUEL OIL RECEIVED ON
                BOARD{' '}
                {moment(record.item5.fuelRecievedDate, 'YYYY-MM-DD').format(
                    'DD-MMM-YYYY'
                )}
                .
            </>
        );
    }

    function renderItem6P1() {
        return (
            <h4 className="font-medium uppercase">
                MARPOL sample SEAL No. {record.item6P1.sealNo}
            </h4>
        );
    }

    function renderItem6P2(): React.ReactNode {
        if (!record.item6P2.place) return <Annex6OldVersionPreview />;

        if (record.item6P2.place === 'AT_SEA')
            return (
                <>
                    {record.item6P2.latitude} {record.item6P2.longitude}
                </>
            );
        return <>{record.item6P2.portName} </>;
    }

    function renderItem6P3(): React.ReactNode {
        return (
            <h4 className="font-medium uppercase">
                {record.item5?.receiptAttachment ? (
                    <>
                        RECEIPT NO. {record.item5.receiptNumber} <br />
                        ATTACHMENT:{' '}
                        <a
                            href={marpolUtils.getAttachmentURL(
                                record.item5.receiptAttachment,
                                record.id,
                                record._rev
                            )}
                            download={record.item5.receiptAttachment}
                        >
                            {' '}
                            {record.item5.receiptAttachment}
                        </a>
                        <br />
                    </>
                ) : null}
                RECEIVER CONTACT: {record.item6P3.contact}
                <br />
                RECEIVER ADDRESS: {record.item6P3.address}
            </h4>
        );
    }

    return renderMain();
}

export default Annex6GroupC1Preview;
