import { Dispatch, Middleware } from "redux";
import { IStore } from "../../store";
import actions from "./actions";
import { jobActionTypes, jobUtilities } from "../job";
import { IJobState } from "../job/types";
import { appToaster } from "../../../shared/toast";
import utilities from "./utils";
import { IJobActions } from "../job/actions";

function canShowNotification(
	currentJobState: IJobState,
	showAtJobState: IJobState = "ACTIVE"
) {
	switch (currentJobState) {
		case "ACTIVE":
			if (showAtJobState === "ACTIVE") return true;
		case "SUCCESS":
			if (showAtJobState === "ACTIVE" || showAtJobState === "SUCCESS")
				return true;
		case "ERROR":
			if (showAtJobState === "ACTIVE" || showAtJobState === "ERROR")
				return true;
		case "IDLE":
			return false;
	}
	return false;
}
const { JOB_ACTIVE, JOB_ERROR, JOB_IDLE, JOB_SUCCESS } = jobActionTypes;
const notificationMiddleware: Middleware<{}, IStore> = ({
	dispatch,
	getState,
}) => (next) => (action: IJobActions) => {
	switch (action.type) {
		case JOB_ACTIVE: {
			next(action);
			const { message, notification, JOB_NAME } = action.payload;
			const notificationInStore = getState().core.notification[JOB_NAME];
			if (notificationInStore || notification) {
				dispatch(
					actions.set(
						JOB_NAME,
						jobUtilities.getDefaultNotification("ACTIVE", message, {
							...notificationInStore,
							...notification,
						})
					)
				);
				const updatedNotification = getState().core.notification[JOB_NAME];
				if (
					updatedNotification.hideAtState === "ACTIVE" &&
					updatedNotification.timeout !== 0
				)
					setTimeout(function dismissOnJobActive() {
						appToaster.dismiss(JOB_NAME);
					}, updatedNotification.timeout);
				else if (canShowNotification("ACTIVE", updatedNotification.showAtState))
					appToaster.show(
						utilities.getBluePrintToastNotification(
							getState().core.notification[JOB_NAME]
						),
						JOB_NAME
					);
			}

			break;
		}
		case JOB_ERROR: {
			next(action);
			const { message, notification, JOB_NAME } = action.payload;
			const notificationInStore = getState().core.notification[JOB_NAME];
			if (notificationInStore || notification) {
				dispatch(
					actions.set(
						JOB_NAME,
						jobUtilities.getDefaultNotification("ERROR", message, {
							...notificationInStore,
							...notification,
						})
					)
				);
				const updatedNotification = getState().core.notification[JOB_NAME];
				if (
					updatedNotification.hideAtState === "ERROR" &&
					updatedNotification.timeout !== 0
				)
					setTimeout(function dismissOnJobError() {
						appToaster.dismiss(JOB_NAME);
					}, updatedNotification.timeout);
				else if (canShowNotification("ERROR", updatedNotification.showAtState))
					appToaster.show(
						utilities.getBluePrintToastNotification(
							getState().core.notification[JOB_NAME]
						),
						JOB_NAME
					);
			}
			break;
		}
		case JOB_SUCCESS: {
			next(action);
			const { message, notification, JOB_NAME } = action.payload;
			const notificationInStore = getState().core.notification[JOB_NAME];
			if (notificationInStore || notification) {
				dispatch(
					actions.set(
						JOB_NAME,
						jobUtilities.getDefaultNotification("SUCCESS", message, {
							...notificationInStore,
							...notification,
						})
					)
				);
				const updatedNotification = getState().core.notification[JOB_NAME];
				if (
					updatedNotification.hideAtState === "SUCCESS" &&
					updatedNotification.timeout !== 0
				)
					setTimeout(function dismissOnJobSuccess() {
						appToaster.dismiss(JOB_NAME);
					}, updatedNotification.timeout);
				else if (
					canShowNotification("SUCCESS", updatedNotification.showAtState)
				)
					appToaster.show(
						utilities.getBluePrintToastNotification(
							getState().core.notification[JOB_NAME]
						),
						JOB_NAME
					);
			}
			break;
		}
		case JOB_IDLE: {
			next(action);
			const { message, notification, JOB_NAME } = action.payload;
			const notificationInStore = getState().core.notification[JOB_NAME];
			if (notificationInStore || notification) {
				dispatch(
					actions.set(
						JOB_NAME,
						jobUtilities.getDefaultNotification("IDLE", message, {
							...notificationInStore,
							...notification,
						})
					)
				);
				const updatedNotification = getState().core.notification[JOB_NAME];
				if (updatedNotification.timeout !== 0)
					setTimeout(function dismissOnJobIdle() {
						appToaster.dismiss(JOB_NAME);
					}, updatedNotification.timeout);
			}
			break;
		}
		default: {
			next(action);
		}
	}
};
export default notificationMiddleware;
