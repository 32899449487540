import actionTypes from "./action-types";
import { apiActions } from "../../_core/api";
import { Middleware } from "redux";
import { IStore } from "../../store";
import permitActions from "./actions";
import approvalAPIs from "./apis";
import logActions, { logJobActions } from "../logs/actions";

const { apiRequest } = apiActions;
const {
	APPROVAL_LIST_LOAD,
	APPROVAL_BY_ID,
	DECLINE_BY_ID,
	LOAD_PRINT_TEMPLATE,
} = actionTypes;

const approvalMiddleware: Middleware<IStore> =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case APPROVAL_LIST_LOAD: {
				// next(action);
				const job = permitActions._jobs.vesselListLoad;
				dispatch(
					apiRequest<{
						logs: any;
						count: number;
					}>({
						...approvalAPIs.listAPI(),
						preExecute: () =>
							dispatch(
								job.active({
									message: "Loading...",
									notification: action.payload.notify
										? {}
										: undefined,
								})
							),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									const { logs } = response.data;
									const approvals = logs;
									dispatch(
										job.success({
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
									dispatch(permitActions.listSet(approvals));
								} else
									dispatch(
										job.error({
											message: "No Approvals found!!",
										})
									);
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case APPROVAL_BY_ID: {
				const job = permitActions._jobs.vesselListLoad;
				const approval: any = action.payload;
				if (approval != undefined) {
					approval.isSharedToOffice = false;
					approval?.collaborators.forEach((element:any) => {
						if (element.rank < 1) {
							element.isSigned = true;
							element.signedOn = new Date();
						}
					});
				}
				dispatch(
					apiRequest<{
						logs: any;
						count: number;
					}>({
						...approvalAPIs.updateVesselAPI(approval),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									dispatch(permitActions.listLoad(true));
								}
							},
							onError: (error) => {
								dispatch(
									job.error({ message: "Server Error" })
								);
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case DECLINE_BY_ID: {
				const job = permitActions._jobs.vesselListLoad;
				const approval: any = action.payload;
				if (approval != undefined) {
					approval.isSharedToOffice = false;
					approval?.collaborators.forEach((element:any) => {
						if (element.rank < 1) {
							element.isSigned = false;
						}
					});
				}
				dispatch(
					apiRequest<{
						logs: any;
						count: number;
					}>({
						...approvalAPIs.updateVesselAPI(approval),
						postExecute: {
							onSuccess: (response) => {
								if (response.status === 200) {
									dispatch(permitActions.listLoad(true));
								}
							},
							onError: (error) => {
								dispatch(job.error({ message: "Server Error" }));
							},
							finally: () => {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case LOAD_PRINT_TEMPLATE: {
				next(action);
				const { log } = action.payload;
				const job = logJobActions.logLoad;
				if (log) {
					dispatch(logActions.document.logPrintTemplateReset());
					dispatch(job.active({ message: "Loading Permit" }));

					dispatch(logActions.document.logSelect([log]));
					dispatch(job.success({}));

					dispatch(
						logActions.commands.logPrintTemplateLoad(
							log.code,
							log.vesselId,
							log.code
						)
					);
				}

				break;
			}
			default: {
				next(action);
			}
		}
	};
export default approvalMiddleware;
