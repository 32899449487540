import { getType } from "typesafe-actions";
import { IAPIRequestMetaData } from "../../_core/api/types";
import {
	IChatMessageAttachment,
	IFeedback,
	IFeedbackChat,
	IFeedbackUpdatePayload,
	IFilterState,
	IIndividualFeedbackWithChat,
	IVessel,
	IVesselUser,
	TCount,
	IDashboardStatsAndFeedbackPayload,
	TDashboardStatsAndFeedbackResponse,
	INotification,
} from "./@types";
import feedbackActions from "./actions";
import { commonUtils } from "redux/_common";

const feedbackAPIs = {
	loadNotifications(
		{
			token,
			userId
		}: {
			token: string
			userId: string
		},
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<INotification[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/notifications`,
			feature: getType(feedbackActions.commands.notificationsLoad),
			method: "GET",
			body: {},
			queryParams: {
				limit: itemsPerPage,
				skip: (pageNumber - 1) * itemsPerPage,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadFeedbackList(
		filters: IFilterState & { assignedToEmail?: string },
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<IFeedback[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/feedback/`,
			feature: getType(feedbackActions.commands.feedbackListLoad),
			method: "GET",
			body: {},
			queryParams: {
				filter: {
					selector: {
						vesselId: filters?.vesselId || undefined,
						user_new_message:
							filters?.showPendingMessages || undefined,
						status: filters?.status?.value,
						priority: filters?.priority?.value,
						section: filters?.section?.value,
						subsection: filters?.subsection?.value,
						assignedTo: filters?.assignedToEmail
							? [{ userEmail: filters.assignedToEmail }]
							: undefined,
					},
					limit: itemsPerPage,
					skip: (pageNumber - 1) * itemsPerPage,
				},
			},
		};
	},
	// TODO: can be generalized and made single api call for getting count across app
	loadFeedbackTotalCount(
		filters: IFilterState & { assignedToEmail?: string }
	): IAPIRequestMetaData<TCount> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/count/`,
			feature: getType(feedbackActions.commands.feedbackVesselsLoad),
			method: "GET",
			body: {},
			queryParams: {
				modelName: "Feedback",
				// vesselId: filters?.vesselId ? filters?.vesselId : undefined,
				// user_new_message: filters?.showPendingMessages || undefined,
				filter: {
					selector: {
						status: filters?.status?.value,
						priority: filters?.priority?.value,
						section: filters?.section?.value,
						subsection: filters?.subsection?.value,
						user_new_message:
							filters?.showPendingMessages || undefined,
						vesselId: filters?.vesselId,
						assignedTo: filters?.assignedToEmail
							? {
									$elemMatch: {
										userEmail: filters.assignedToEmail,
									},
							  }
							: undefined,
					},
				},
			},
		};
	},
	loadVessles(): IAPIRequestMetaData<IVessel[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/vessels/`,
			feature: getType(feedbackActions.commands.feedbackVesselsLoad),
			method: "GET",
			body: {},
			queryParams: {},
		};
	},
	loadChatsForFeedback(
		feedbackId: string
	): IAPIRequestMetaData<IFeedbackChat[]> {
		// feedbackId = "787b1abf23419dd4d0d8dc608d073389";
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/chatMessage?feedbackId=${feedbackId}`,
			feature: getType(feedbackActions.commands.feedbackChatsLoad),
			method: "GET",
			body: {},
			queryParams: {},
		};
	},
	sendChatMessage(
		vesselId: string,
		message: string,
		feedbackId: string
	): IAPIRequestMetaData<IFeedbackChat> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/chatMessage/`,
			feature: getType(feedbackActions.commands.createMessage),
			method: "POST",
			body: {
				vesselId,
				message,
				feedbackId,
			},
		};
	},
	loadFeedbackWithChats(
		feedbackId: string
	): IAPIRequestMetaData<IIndividualFeedbackWithChat> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/feedback`,
			feature: getType(feedbackActions.commands.feedbackChatsLoad),
			method: "GET",
			body: {},
			queryParams: {
				id: feedbackId,
			},
		};
	},
	uploadAttachment(formData: FormData): IAPIRequestMetaData<any> {
		return {
			url: `https://console.wayship.io/api/attachments`,
			feature: getType(feedbackActions.commands.chatAttachmentUpload),
			method: "POST",
			headers: {
				"Content-Type": "multipart/form-data",
			},
			body: formData,
		};
	},
	updateChatWithAttachment(
		chatMessageId: string,
		attachments: IChatMessageAttachment[]
	): IAPIRequestMetaData<IFeedbackChat> {
		return {
			url: `https://console.wayship.io/api/chatMessage`,
			feature: getType(feedbackActions.commands.chatMessageUpdate),
			method: "PATCH",
			body: {
				chatMessageId,
				attachments,
			},
		};
	},
	updateFeedback(
		payload: IFeedbackUpdatePayload & { _id: string; userId: string },
		token: string
	): IAPIRequestMetaData<any> {
		return {
			url: "https://console.wayship.io/api/feedback",
			feature: getType(feedbackActions.commands.feedbackUpdate),
			method: "PATCH",
			body: { ...payload },
			headers: {
				// @ts-ignore
				"x-token": token,
			},
		};
	},
	loadVesselSpecificUsers(
		vesselId: string
	): IAPIRequestMetaData<IVesselUser[]> {
		return {
			url: "https://console.wayship.io/api/users",
			feature: getType(feedbackActions.commands.vesselSpecificUsersLoad),
			method: "GET",
			queryParams: {
				vesselId,
			},
		};
	},
	loadDashboardStatsAndFeedback(
		payload: IDashboardStatsAndFeedbackPayload & {
			token: string;
			userId: string;
		}
	): IAPIRequestMetaData<TDashboardStatsAndFeedbackResponse> {
		const { token, userId, assignedTo, selector, stats } = payload;
		return {
			url: "https://console.wayship.io/api/feedbackStats",
			feature: getType(
				feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad
			),
			method: "GET",
			queryParams: {
				stats,
				selector,
				assignedTo,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	searchFeedbacks(
		queryParams: { itemToSearch?: string; queryString: string },
		token: string | null,
		userId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/search`,
			feature: getType(feedbackActions.commands.feedbackUpdate),
			method: "GET",
			queryParams: {
				modelName: "Feedback",
				itemToSearch: queryParams?.itemToSearch || "feedback",
				queryString: queryParams?.queryString,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
};

export default feedbackAPIs;
