import moment from "moment";
import React from "react";
import {
	TORB1BackendRecord,
	TORB1Record,
} from "../../../../../redux/app/orb1/@types";
import { TORB1GroupI3 } from "../../../../../redux/app/orb1/groups/group-i";
import getSignTableRows from "../getSignTableRows";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupI3PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupI3Preview(props: ORB1GroupI3PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI3;
	if (!!!orb1Record.itemI3.iopp3P3Tanks) return null;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const userInfoRows = getSignTableRows(orb1Record as TORB1BackendRecord);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					...renderItemI3Rows(),
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItemI3Rows() {
		const { iopp3P3Tanks } = orb1Record.itemI3;
		let listOfRows: ORB1TableColumns[] = [];
		iopp3P3Tanks.map((tank, index) => {
			const isLastOilResidue = index === iopp3P3Tanks.length - 1;
			const tankName = tank.selectedTank.tankName;
			const totalCapacity = tank.selectedTank.totalCapacity.toFixed(2);
			const actualQuantity = tank.actualQuantity.toFixed(2);
			const content = (
				<h4 className="font-medium uppercase">
					Inventory of {tankName} .<br /> Capacity {totalCapacity}
					M³, Quantity retained {actualQuantity} M³.
				</h4>
			);
			listOfRows.push([operationDate, "I", "-", content]);
			userInfoRows.map(
				(userRow) => !isLastOilResidue && listOfRows.push(userRow)
			);
		});
		return listOfRows;
	}

	return renderMain();
}

export default ORB1GroupI3Preview;
