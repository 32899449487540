import { Middleware } from "redux";
import authConstants from "../constants";
import { IStore } from "../../../store";
import * as yup from "yup";
import { promiseActions } from "../../../_core/promise-action";
import authActions, { IAuthActions } from "../actions";
import { IAuthFormLoginErrors } from "../types";
import { invokerAction } from "../../../_common/actions";
import { getType } from "typesafe-actions";

const { FEATURE, forms } = authConstants;

const loginSchema = yup.object().shape({
	password: yup
		.string()
		.required("Password is required")
		.min(7, "Minimum 7 Characters"),
	email: yup
		.string()
		.required("Email is Required")
		.email("Invalid Email Format"),
});

const loginFormMiddleware: Middleware<any, IStore> = ({
	dispatch,
	getState,
}) => (next) => (action: IAuthActions) => {
	switch (action.type) {
		case getType(authActions._forms.login.formSubmit): {
			next(action);
			const { formName, feature } = action.payload;
			if (feature === FEATURE && formName === forms.LOGIN) {
				const authStore = getState().app.auth;
				dispatch(
					promiseActions(
						loginSchema.validate(authStore._forms.LOGIN.fields, {
							abortEarly: false,
						}),
						{
							invoker: "$auth/login.form",
							onSuccess: (validatedData) => {
								if (validatedData) {
									const { email, password } = validatedData;
									dispatch(authActions.login(email, password));
								} else
									dispatch(
										invokerAction(
											"$auth/login.form",
											authActions._forms.login.formErrorsSet({
												GLOBAL: {
													isValid: true,
													message: "No data Provided",
												},
											})
										)
									);
							},
							onError: (error: yup.ValidationError) => {
								const errors: Partial<IAuthFormLoginErrors> = {
									GLOBAL: {
										isValid: false,
										message: "Found few errors",
									},
								};
								error.inner.map((errorItem) => {
									errors[errorItem.path] = {
										isValid: false,
										message: errorItem.message,
									};
								});
								dispatch(
									invokerAction(
										"$auth/login.form",
										authActions._forms.login.formErrorsSet(errors)
									)
								);
							},
						}
					)
				);
			}
			break;
		}
		case getType(authActions._forms.login.formFieldsEdit): {
			next(action);
			const { formName, feature, fields } = action.payload;
			if (feature === FEATURE && formName === forms.LOGIN) {
				// To reset the errors on Change //FIXME: Add below code in Form Middleware
				dispatch(
					authActions._forms.login.formErrorsReset([
						"GLOBAL",
						...Object.keys(fields),
					])
				);
			}
			break;
		}
		default:
			next(action);
	}
};
export default loginFormMiddleware;
