import InfiniteProgress from "components/_common/InfiniteProgress";
import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import {
	isBilgeDataEmpty,
	tranformBilgeWaterDataForTable,
	transformAggregateAllVesselsBilgeWaterDataForTable,
} from "redux/app/report/utils";
import { IStore } from "redux/store";

const bilgeWaterHeaders = [
	"Year",
	"Quarter",
	"Report date",
	"OWS overboard",
	"Transfer to slop",
	"Total bilge water",
];

type OwnProps = {
	year: string;
};
type TableProps = PropsFromRedux & OwnProps;
function Table(props: TableProps) {
	const {
		rawBilgeWaterData,
		year,
		rawBilgeWaterAllVesselsData,
		vesselId,

		jobToLoadVesselTenantBilgeReport,
		jobToGetVesselSpecificBilgeReport,
	} = props;

	const tableRowRecords = useMemo(() => {
		if (vesselId) return tranformBilgeWaterDataForTable(rawBilgeWaterData);
		else
			return transformAggregateAllVesselsBilgeWaterDataForTable(
				rawBilgeWaterAllVesselsData
			);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rawBilgeWaterData, rawBilgeWaterAllVesselsData, vesselId]);

	if (
		(!vesselId && jobToLoadVesselTenantBilgeReport.isLoading) ||
		(vesselId && jobToGetVesselSpecificBilgeReport.isLoading)
	) {
		return <div className="flex justify-center mt-20">Loading...</div>;
	}

	if (isBilgeDataEmpty(tableRowRecords)) {
		return (
			<div className="flex justify-center items-center mt-20">
				No data found{vesselId ? " for this vessel" : ""}.{" "}
				{vesselId
					? "Choose another vessel."
					: "Please select a vessel or change the year."}
			</div>
		);
	}

	return (
		<div className="px-4">
			<InfiniteProgress
				isLoading={jobToLoadVesselTenantBilgeReport.isLoading}
				isSpacedOut={false}
			/>
			<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text  overflow-auto  border-collapse text-center">
				<thead>
					<tr>
						{bilgeWaterHeaders.map((headerLabel) => (
							<th
								key={headerLabel}
								className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
							>
								{headerLabel}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableRowRecords.map((row, index) => {
						const { quarter, owsOverboard, transferToSlop, quarterTotal } = row;
						return (
							<tr key={row.quarter}>
								{index === 0 && (
									<td
										rowSpan={5}
										className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
									>
										{year}
									</td>
								)}
								<td
									colSpan={2}
									className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
								>
									{quarter}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{owsOverboard.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{transferToSlop.toFixed(2)}
								</td>
								<td className="px-1 text-center py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]">
									{quarterTotal.toFixed(2)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawBilgeWaterData = reportSelectors.getRawBilgeWaterData(reportStore);
	const rawBilgeWaterAllVesselsData =
		reportSelectors.getBilgeWaterAllVesselsData(reportStore);
	const vesselId = reportSelectors.getBilgeWaterVesselId(reportStore);
	const jobToLoadVesselTenantBilgeReport =
		reportSelectors.jobToLoadVesselTenantBilgeReport(reportStore);

	const jobToGetVesselSpecificBilgeReport =
		reportSelectors.jobToLoadGarbageReport(reportStore);
	return {
		rawBilgeWaterData,
		rawBilgeWaterAllVesselsData,
		vesselId,

		jobToLoadVesselTenantBilgeReport,
		jobToGetVesselSpecificBilgeReport,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Table);
