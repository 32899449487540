import React from "react";
import clsx from "clsx";
import { IPaginationForView } from "../../redux/app/logs/@types";
import classNames from "classnames";
type PaginationProps = IPaginationForView & {
	onPageChange(pageNumber: number, itemsPerPage: number): void;
	isPageLoading?: boolean;
	onItemsPerPageChange?: (itemsPerPage: number) => void;
	isChangeItemsPerPageVisible?: boolean;
};
function NumberedPagination(props: PaginationProps) {
	const {
		currentPage,
		itemsPerPage,
		totalItems,
		isPageLoading = false,
		onItemsPerPageChange,
		isChangeItemsPerPageVisible
	} = props;

	const totalPages = Math.ceil(totalItems / itemsPerPage);
	if (totalPages === 0) return null;
	let allPages = [];
	for (let index = 0; index < totalPages; index++) {
		allPages[index] = index + 1;
	}
	if (totalPages > 6) {
		const previousPage = currentPage - 1;
		const nextPage = currentPage + 1;
		allPages = allPages.slice(
			previousPage - 3 <= 0 ? 0 : previousPage - 3,
			nextPage + 3 >= totalPages ? totalPages : nextPage + 2
		);
	}
	const isPreviousLinkDisable = currentPage <= 1 || totalPages < 6;
	const isNextLinkDisable = currentPage >= totalPages || totalPages < 6;;
	const isFirstLinkDisable = currentPage <= 1 || totalPages < 6;
	const isLastLinkDisable = currentPage >= totalPages || totalPages < 6;;

	const rangeStartNumber = (currentPage - 1) * itemsPerPage;
	const rangeEndNumber =
		rangeStartNumber + itemsPerPage > totalItems
			? totalItems
			: rangeStartNumber + itemsPerPage;

	function onNextClick() {
		props.onPageChange(currentPage + 1, itemsPerPage);
	}
	function onPreviousClick() {
		props.onPageChange(currentPage - 1, itemsPerPage);
	}
	function onPageNumberClick(pageNumber: number) {
		props.onPageChange(pageNumber, itemsPerPage);
	}
	function onFirstPageClick() {
		props.onPageChange(1, itemsPerPage);
	}
	function onLastPageClick() {
		props.onPageChange(totalPages, itemsPerPage);
	}

	return (
		<div className="flex items-center justify-between mt-6">
			
			<div className="items-baseline  align-baseline sm:flex-1 sm:flex sm:items-center sm:justify-between">
				<div
					className="flex items-center gap-x-5"
				>
					<p className="leading-5 text-gray-500 text-md mb-2">
						Showing{" "}
						<span className="text-gray-600">{rangeStartNumber + 1}</span> -{" "}
						<span className="text-gray-600">{rangeEndNumber}</span> of{" "}
						<span className="text-gray-600">{totalItems}</span> results
					</p>
					{
						isChangeItemsPerPageVisible?
							<div className="flex items-center gap-2 mb-2 text-gray-600">
								<div className="py-0 pl-2 pr-2 bg-white border border-gray-400 rounded-full outline-none select-wrapper cursor-pointer">
									<select
										className="outline-none appearance-none"
										value={itemsPerPage}
										onChange={(e) => {
											e.persist();
											onItemsPerPageChange && onItemsPerPageChange(Number(e.target?.value));
										}}
									>
										{[5, 10, 25, 50].map((item) => (
											<option key={item} value={item}>{item + ' items'}</option>
										))}
									</select>
									<span className="text-blue-600 text-md mb-0.5 bp3-icon bp3-icon-chevron-down" />
								</div>
								per page
							</div>
							: null
					}
				</div>
				<div>
					<nav className="relative z-0 inline-flex shadow-sm cursor-pointer">
						<button
							disabled={isPageLoading}
							tabIndex={0}
							className={clsx(
								"focus:bg-gray-300 relative cursor-pointer inline-flex items-center px-2 py-2 text-xs font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-gray-100 border border-gray-300 rounded-l-md hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 ",
								{
									hidden: isFirstLinkDisable,								
								}
							)}
							aria-label="Last page"
							onClick={onFirstPageClick}
						>
							<span className="w-5 h-5 ">
								<span className="items-baseline w-full h-full align-baseline bp3-icon bp3-icon-double-chevron-left" />
							</span>
						</button>
						<button
							disabled={isPageLoading}
							tabIndex={0}
							className={clsx(
								"focus:bg-gray-300 relative cursor-pointer inline-flex items-center px-2 py-2 text-xs   font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-gray-100 border border-gray-300  hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500",
								{
									hidden: isPreviousLinkDisable,
									"rounded-l-md": isFirstLinkDisable,
								}
							)}
							aria-label="Previous"
							onClick={onPreviousClick}
						>
							<span className="w-5 h-5 ">
								<span className="items-baseline w-full h-full align-baseline bp3-icon bp3-icon-chevron-left" />
							</span>
						</button>
						{allPages.map((item, index) => (
							<button
								disabled={isPageLoading}
								key={item}
								tabIndex={0}
								className={classNames(
									"focus:bg-gray-300 relative cursor-pointer inline-flex items-center px-4 py-2 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border  focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700",
									{
										"bg-gray-100  border-gray-300": item !== currentPage,
										"bg-blue-200  border-blue-400": item === currentPage,
										"animate-pulse": isPageLoading,
									}
								)}
								onClick={() => onPageNumberClick(item)}
							>
								{item}
							</button>
						))}

						<button
							disabled={isPageLoading}
							tabIndex={0}
							className={clsx(
								"focus:bg-gray-300 relative cursor-pointer inline-flex items-center px-2 py-2  text-xs font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-gray-100 border border-gray-300  hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500",
								{
									hidden: isNextLinkDisable,
								},
								{
									"rounded-r-md": isLastLinkDisable,
								}
							)}
							aria-label="Next"
							onClick={onNextClick}
						>
							<span className="w-5 h-5 ">
								<span className="items-baseline w-full h-full align-baseline bp3-icon bp3-icon-chevron-right" />
							</span>
						</button>
						<button
							disabled={isPageLoading}
							tabIndex={0}
							className={clsx(
								"focus:bg-gray-300 relative cursor-pointer inline-flex items-center px-2 py-2  text-xs font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-gray-100 border border-gray-300 rounded-r-md hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500",
								{
									hidden: isLastLinkDisable,
								}
							)}
							aria-label="Last page"
							onClick={onLastPageClick}
						>
							<span className="w-5 h-5 ">
								<span className="items-baseline w-full h-full align-baseline bp3-icon bp3-icon-double-chevron-right" />
							</span>
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
}
export default NumberedPagination;
