import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import React, { useEffect, useState } from "react";
import {
	Switch,
	Route,
	Redirect,
	BrowserRouter,
	withRouter,
	RouteChildrenProps,
	Prompt,
} from "react-router-dom";
import { IStore } from '../../redux/store';
import { rootRoutes } from "../../routes";
import CLTec01 from "./cl-tec-01";
import CLTnk07 from "./cl-tnk-07";
import VDF03 from './VDF03';
import VDF04 from './VDF04';
import VDF04A from './VDF04/VDF04A';
import VDF04C from './VDF04/VDF04C';
import VDF04E from './VDF04/VDF04E';
import VDF01 from './VDF01';
import VOT56 from "./VOT56";
import VOT56A from "./VOT56A";
import VOT56B from "./VOT56B";
import LoginExt from "./login";
import VOT58 from "./VOT58";
import VOT59 from "./VOT59";
import VOT60 from "./VOT60";
import VO67 from "./VO67";
import VO67A from "./VO67A";
import VO67B from "./VO67B";
import Chem_Tanker_IG_and_TC from "./VQS14/Chem_Tanker_IG_and_TC";
import Chem_Tanker_No_IG_and_No_TC from "./VQS14/Chem_Tanker_No_IG_No_TC";
import Full_Checklist from "./VQS14/Full_Checklist";
import Gas_Tanker from "./VQS14/Gas_Tanker";
import Oil_Tanker_IG_COW_and_TC from "./VQS14/Oil_Tanker_IG_COW_and_TC";
import Oil_Tanker_IC_No_IG_No_COW from "./VQS14/Oil_Tanker_TC_No_IG_No_COW";
import Pre_arrival_Checks_Only from "./VQS14/Pre_arrival_Checks_Only";
import Repetitive_Checks_Only from "./VQS14/Repetitive_Checks_Only";
import VQS14B from "./VQS14B";


const { login, form } = rootRoutes.children.ext.children;

type ExtScreenType = RouteChildrenProps & PropsFromRedux;

const ExtScreen: React.SFC<ExtScreenType> = (props: ExtScreenType) => {
	const [isRouteBlocked, setRouteBlocking] = useState(false);
	const { isSubmit} = props
	useEffect(
		function onMount() {
			if (props.location.pathname.includes("tec01")) setRouteBlocking(true);
			else if (props.location.pathname.includes("tnk07")) setRouteBlocking(true);
			else setRouteBlocking(false);
		},
		[props.location]
	);
	return (
		<>
		<Switch>
			<Route path={"/ext/login"} component={withRouter(LoginExt)} />
			<Route path={"/ext/form/tec01"} component={withRouter(CLTec01)} />
			<Route path={"/ext/form/tnk07"} component={withRouter(CLTnk07)} />
			<Route path={"/ext/form/vdf03"} component={withRouter(VDF03)} />
			<Route path={"/ext/form/vdf04"} component={withRouter(VDF04)} />
			<Route path={"/ext/form/vdf04A"} component={withRouter(VDF04A)} />
			<Route path={"/ext/form/vdf04C"} component={withRouter(VDF04C)} />
			<Route path={"/ext/form/vdf04E"} component={withRouter(VDF04E)} />
			<Route path={"/ext/form/vdf01"} component={withRouter(VDF01)} />
			<Route path={"/ext/form/vot56"} component={withRouter(VOT56)} />
			<Route path={"/ext/form/vot56a"} component={withRouter(VOT56A)} />
			<Route path={"/ext/form/vot56b"} component={withRouter(VOT56B)} />
			<Route path={"/ext/form/vot58"} component={withRouter(VOT58)} />
			<Route path={"/ext/form/vot59"} component={withRouter(VOT59)} />
			<Route path={"/ext/form/vot60"} component={withRouter(VOT60)} />
			<Route path={"/ext/form/vo67"} component={withRouter(VO67)} />
			<Route path={"/ext/form/vo67a"} component={withRouter(VO67A)} />
			<Route path={"/ext/form/vo67b"} component={withRouter(VO67B)} />
			<Route path={"/ext/form/Chem_Tanker_IG_and_TC"} component={withRouter(Chem_Tanker_IG_and_TC)} />
			<Route path={"/ext/form/Chem_Tanker_No_IG_and_No_TC"} component={withRouter(Chem_Tanker_No_IG_and_No_TC)} />
			<Route path={"/ext/form/Full_Checklist"} component={withRouter(Full_Checklist)} />
			<Route path={"/ext/form/Gas_Tanker"} component={withRouter(Gas_Tanker)} />
			<Route path={"/ext/form/Oil_Tanker_IG_COW_and_TC"} component={withRouter(Oil_Tanker_IG_COW_and_TC)} />
			<Route path={"/ext/form/Oil_Tanker_IC_No_IG_No_COW"} component={withRouter(Oil_Tanker_IC_No_IG_No_COW)} />
			<Route path={"/ext/form/Pre_arrival_Checks_Only"} component={withRouter(Pre_arrival_Checks_Only)} />
			<Route path={"/ext/form/Repetitive_Checks_Only"} component={withRouter(Repetitive_Checks_Only)} />
			<Route path={"/ext/form/vqs14b"} component={withRouter(VQS14B)} />
			{/* <Route exact={true} path="/ext/" render={RedirectComponent} /> */}
			<Route render={RedirectComponent} />
		</Switch>
		<Prompt
		when={isRouteBlocked && !isSubmit}
		message={(location) =>
			`Changes you made may not be saved. Are you sure?`
		}
		/>
		</>
	);
};

function mapStateToProps(store:IStore){
	const extStore: any = store.app.extStore;
	const isSubmit = extStore.isSubmit;
	return {isSubmit:isSubmit}
}
function mapDispatchToProps(dispatch:Dispatch){return {}};
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ExtScreen);

function RedirectComponent() {
	return <Redirect to={form.url()} />;
}
