import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupC4 } from "../../../../../redux/app/orb1/groups/group-c";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { commonUtils } from "redux/_common";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupC4PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupC4Preview(props: ORB1GroupC4PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupC4;
	if (orb1Record.item12P1.transferOfOilResidues.length === 0) return null;
	const firstOilResidue = orb1Record.item12P1.transferOfOilResidues[0];
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "C", "12.1", renderItem12P1()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem12P1() {
		const { transferQuantity, selectedTank } = firstOilResidue;
		const { port, receiptNumber, receiptAttachment } = orb1Record.item12P1;
		const url = marpolUtils.getAttachmentURL(
			receiptAttachment,
			orb1Record.id,
			orb1Record._rev
		);
		const retainedQty = commonUtils.substract(
			marpolUtils.getQuantityForCalculation(selectedTank),
			transferQuantity
		);
		return (
			<h4 className="font-medium uppercase">
				{transferQuantity.toFixed(2)} M³ DELIVERED TO {port} PORT RECEPTION
				FACILITIES <br /> FROM {selectedTank.tankName}. RECEIPT NO:{" "}
				<b>{receiptNumber}</b>
				<br />
				QUANTITY RETAINED IN TANK ={retainedQty.toFixed(2)} M³,
				<br />
				ATTACHMENT:{" "}
				<a href={url} download={receiptAttachment}>
					{receiptAttachment}
				</a>
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupC4Preview;
