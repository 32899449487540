import React, { useEffect } from "react";
import VesselForm from "./Form";
import { Dispatch } from "redux";
import { vesselOnboardActions } from "../../../../redux/app/vessel/onboard";
import { connect, ConnectedProps } from "react-redux";
import { vesselFormDefaults } from "../../../../redux/app/vessel/onboard/reducers.state";
import { invokerAction } from "../../../../redux/_common/actions";

type NewVesselProps = PropsFromRedux & {
	onClose(): void;
};
function NewVessel(props: NewVesselProps) {
	useEffect(function onLoad() {
		props.resetForm();
		props.setFormModeCreate();
	}, []);
	return (
		<VesselForm
			title="Add a new Vessel"
			subtitle="Following information is required to create a new vessel"
			onCancel={() => { 
				props.resetForm();
				props.onClose()
			}}
			onClose={() => {
				props.resetForm();
				props.onClose()
			}}
		/>
	);
}

const { vessel: vesselForm } = vesselOnboardActions._forms;
const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		resetForm() {
			dispatch(
				invokerAction(
					"#VesselNew",
					vesselForm.formFieldsSet(vesselFormDefaults)
				)
			);
		},
		setFormModeCreate() {
			dispatch(invokerAction("#VesselNew", vesselForm.formModeSet("CREATE")));
		},
	};
};
const reduxConnector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NewVessel);
