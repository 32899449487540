import React from "react";
type SVGImoProps = { className?: string };
const SVGImo: React.FC<SVGImoProps> = (props) => (
	<svg
		width="48"
		height="25"
		viewBox="0 0 48 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M4.3125 0.602589C2.44688 0.954931 0.825 2.22017 0.225 3.78169L0 4.37427V12.7424V21.1106L0.225 21.7032C0.834375 23.2807 2.46563 24.5379 4.35938 24.8823C4.86563 24.9704 7.575 24.9944 20.2125 24.9944H35.4563L35.7094 24.8262C36.0281 24.61 36.0563 24.1375 35.7563 23.9053C35.5594 23.7612 35.1375 23.7532 20.0719 23.7131C9.1125 23.6811 4.50938 23.6491 4.3125 23.585C3.15 23.2167 2.35313 22.6161 1.8375 21.7112L1.5 21.1106V12.7424V4.37427L1.8375 3.77368C2.35313 2.8688 3.1875 2.24419 4.3125 1.90786C4.47188 1.85982 5.86875 1.80376 7.48125 1.77974L10.3781 1.73169L10.5563 1.5395C10.7812 1.29927 10.7812 0.96294 10.5563 0.722706L10.3781 0.530518L7.62188 0.514502C5.625 0.506495 4.725 0.530518 4.3125 0.602589Z"
			fill="#0B2F56"
		/>
		<path
			d="M12.6563 0.586647C12.4031 0.706764 12.2438 0.898952 12.2344 1.06712C12.2344 1.30735 12.4313 1.58762 12.675 1.68372C12.8344 1.74778 17.1094 1.7718 27.9188 1.7718C40.1625 1.7718 43.0219 1.79583 43.4063 1.88391C44.6156 2.15618 45.5156 2.7968 46.0688 3.77376L46.4062 4.37434V12.7425V21.1107L46.0688 21.7113C45.5156 22.6882 44.6156 23.3288 43.4063 23.6011C43.0781 23.6812 42.2438 23.7132 40.5469 23.7132C37.9594 23.7132 37.7906 23.7372 37.5656 24.1456C37.4063 24.4259 37.5469 24.7702 37.8844 24.9064C38.2406 25.0425 42.7688 25.0265 43.5469 24.8824C45.4406 24.538 47.0719 23.2808 47.6813 21.7032L47.9062 21.1107V12.7425V4.37434L47.6813 3.78176C47.0719 2.20423 45.4406 0.946999 43.5469 0.602663C43.0406 0.514578 40.3688 0.490553 27.8906 0.490553C16.7438 0.498562 12.7969 0.522585 12.6563 0.586647Z"
			fill="#0B2F56"
		/>
		<path
			d="M17.55 9.61954C17.1468 9.6836 16.7906 9.8838 16.5093 10.2121C16.3218 10.4203 16.3125 10.5565 16.2843 12.7586C16.2562 14.9848 16.2656 15.0969 16.4531 15.4332C16.9218 16.25 18.3468 16.4262 19.2187 15.7775C19.6125 15.4813 19.7343 15.1369 19.7343 14.3201V13.7035H19.1718H18.6093V14.3281C18.6093 14.8406 18.5718 14.9848 18.4406 15.0889C18.225 15.257 17.8593 15.265 17.6156 15.1129C17.4468 15.0088 17.4375 14.8727 17.4093 13.0549C17.3906 11.9818 17.4 11.0289 17.4281 10.9248C17.5125 10.6606 17.7468 10.5004 18.0468 10.5004C18.4312 10.5004 18.6093 10.7566 18.6093 11.3172V11.7816H19.1718H19.7343V11.2691C19.7343 10.4764 19.4718 9.9959 18.8906 9.73965C18.5718 9.60352 17.9812 9.54747 17.55 9.61954Z"
			fill="#0B2F56"
		/>
		<path
			d="M34.3311 9.62739C33.5154 9.79556 33.1404 10.26 33.0654 11.1969L33.0186 11.7815H33.5998H34.1717V11.3731C34.1717 10.7645 34.3686 10.5002 34.8467 10.5002C35.2123 10.5002 35.3904 10.7645 35.3904 11.301C35.3904 11.9016 35.1092 12.3901 34.2748 13.2229C33.3279 14.1758 33.1217 14.5362 33.0654 15.401L33.0186 16.1057H34.7717H36.5154V15.6252V15.1448H35.3904C34.3217 15.1448 34.2654 15.1368 34.2654 14.9846C34.2654 14.7124 34.5279 14.352 35.2123 13.6793C35.9904 12.9106 36.2623 12.5342 36.4686 11.9096C36.7873 10.9727 36.4498 9.99575 35.7373 9.7395C35.2967 9.58735 34.7342 9.53931 34.3311 9.62739Z"
			fill="#0B2F56"
		/>
		<path
			d="M39.0471 9.61134C38.2314 9.74747 37.8096 10.2039 37.6877 11.0768L37.6314 11.5412H38.1846H38.7377L38.8033 11.1649C38.8689 10.7244 39.0752 10.5002 39.4221 10.5002C39.8533 10.5002 40.0221 10.7484 40.0221 11.3971C40.0221 11.8936 39.9939 11.9816 39.8064 12.1098C39.6752 12.1979 39.4408 12.2619 39.2252 12.2619H38.8596V12.7424V13.2229H39.2346C39.4783 13.2229 39.6752 13.2789 39.7877 13.375C40.1158 13.6553 40.1439 14.8324 39.8252 15.1047C39.6752 15.2328 39.2627 15.2649 39.0658 15.1608C38.9252 15.0807 38.7658 14.6322 38.7658 14.2879V14.0236H38.1939H37.6221L37.6877 14.5602C37.8002 15.5611 38.2596 16.0496 39.1502 16.1537C40.0127 16.2578 40.8846 15.8494 41.0908 15.2408C41.1564 15.0647 41.2033 14.6242 41.2033 14.2559C41.2033 13.5031 40.9971 12.9826 40.6408 12.8225L40.4252 12.7264L40.6877 12.5262C41.0533 12.2299 41.2033 11.8615 41.2033 11.2609C41.2033 10.5002 40.9127 9.97969 40.3596 9.73946C40.0408 9.60333 39.4502 9.54727 39.0471 9.61134Z"
			fill="#0B2F56"
		/>
		<path
			d="M7.59375 9.79556C7.55625 9.97173 6.42188 16.0016 6.42188 16.0737C6.42188 16.0897 6.66563 16.1057 6.975 16.1057H7.51875L7.58438 15.6172C7.6875 14.8885 7.65938 14.9045 8.4375 14.9045C9.21563 14.9045 9.17813 14.8725 9.29063 15.6413L9.34688 16.1057H9.94688C10.3688 16.1057 10.5469 16.0737 10.5469 16.0016C10.5375 15.9536 10.275 14.4961 9.94688 12.7825L9.36563 9.65942L8.50313 9.6354C7.67813 9.61138 7.64062 9.61938 7.59375 9.79556ZM8.71875 12.3741C8.85 13.1188 8.95312 13.7915 8.95312 13.8715C8.95312 13.9997 8.87813 14.0237 8.4 14.0237H7.85625L7.97813 13.359C8.04375 12.9987 8.16563 12.286 8.25938 11.7815C8.34375 11.277 8.4375 10.8926 8.44688 10.9407C8.46563 10.9807 8.57812 11.6293 8.71875 12.3741Z"
			fill="#0B2F56"
		/>
		<path
			d="M11.5316 9.70752C11.5035 9.77159 11.4941 11.229 11.5035 12.9427L11.5316 16.0657L12.591 16.0898C13.7723 16.1138 14.2504 16.0337 14.6629 15.7374C15.0379 15.4731 15.1785 15.0728 15.1785 14.304C15.1785 13.5353 15.0379 13.1349 14.6535 12.8626L14.3723 12.6544L14.6066 12.4702C14.9348 12.206 15.0754 11.7495 15.0285 11.0368C14.991 10.3562 14.8316 10.0919 14.2879 9.81963C14.0348 9.69151 13.7535 9.65948 12.7785 9.63545C11.8223 9.60342 11.5691 9.61944 11.5316 9.70752ZM13.6691 10.7005C13.8473 10.8526 13.9598 11.3812 13.8754 11.7175C13.7723 12.1259 13.5754 12.262 13.1066 12.262H12.7035V11.4212V10.5804H13.116C13.3598 10.5804 13.5941 10.6284 13.6691 10.7005ZM13.6973 13.287C13.9129 13.4071 14.016 13.7114 14.016 14.2399C14.016 14.9927 13.8848 15.1448 13.2098 15.1448H12.7035V14.1839V13.223H13.1535C13.3973 13.223 13.641 13.255 13.6973 13.287Z"
			fill="#0B2F56"
		/>
		<path
			d="M30.2906 9.89971C30.0844 10.22 29.7188 10.4202 29.3438 10.4202C29.1281 10.4202 29.1094 10.4442 29.1094 10.7806V11.1409H29.5781H30.0469V13.6233V16.1138L30.6375 16.0897L31.2188 16.0657V12.8626V9.65947L30.8438 9.63545C30.4969 9.61143 30.45 9.63545 30.2906 9.89971Z"
			fill="#0B2F56"
		/>
		<path
			d="M22.9219 12.8625V13.303H24H25.0781V12.8625V12.4221H24H22.9219V12.8625Z"
			fill="#0B2F56"
		/>
		<path
			d="M11.5315 18.8445C10.8471 19.357 11.5971 20.2779 12.4034 19.9175C12.7221 19.7814 12.7971 19.6452 12.7971 19.2689C12.7971 18.7404 12.0284 18.4841 11.5315 18.8445Z"
			fill="#0B2F56"
		/>
		<path
			d="M35.3531 18.8366C35.175 18.9567 35.1094 19.0768 35.1094 19.269C35.1094 19.6454 35.1844 19.7815 35.5031 19.9176C35.8594 20.0778 36.2906 19.9977 36.4875 19.7414C36.9937 19.0768 36.0844 18.3481 35.3531 18.8366Z"
			fill="#0B2F56"
		/>
	</svg>
);
export default SVGImo;
