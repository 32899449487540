import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import GarbageDischargeGraph from "./_elements/GarbageDischargeGraph";
import GarbageCategoryGraph from "./_elements/GarbageCategoryGraph";
import GarbageDischargeTable from "./_elements/GarbageDischargeTable";
import GarbageCategoryTable from "./_elements/GarbageCategoryTable";
import reportSelectors from "redux/app/report/selectors";

type OwnProps = {
	isGraphView: boolean;
	width: number;
};
type AllVesselsProps = PropsFromRedux & OwnProps;
function AllVessels(props: AllVesselsProps) {
	const {
		isGraphView,
		width,
		jobToLoadAllVesselsGarbageCategoryReport,
		jobToLoadAllVesselsGarbageDischargeReport,
	} = props;

	if (
		jobToLoadAllVesselsGarbageCategoryReport.isLoading ||
		jobToLoadAllVesselsGarbageDischargeReport.isLoading
	) {
		return <div className="flex justify-center mt-20">Loading...</div>;
	}

	return (
		<>
			{isGraphView ? (
				<div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-4 mx-4 mb-4 ">
					<div className="w-full lg:w-1/2 lg:shadow lg:border border-[0.5px] lg:rounded-md lg:py-4 flex justify-center ">
						<GarbageDischargeGraph width={width} />
					</div>
					<div className="w-full lg:w-1/2 lg:shadow lg:border border-[0.5px] lg:rounded-md lg:py-4 flex justify-center">
						<GarbageCategoryGraph width={width} />
					</div>
				</div>
			) : (
				<div className="mx-4 -mt-6">
					<GarbageDischargeTable />
					<GarbageCategoryTable />
				</div>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const jobToLoadAllVesselsGarbageCategoryReport =
		reportSelectors.jobToLoadAllVesselsGarbageCategoryReport(reportStore);
	const jobToLoadAllVesselsGarbageDischargeReport =
		reportSelectors.jobToLoadAllVesselsGarbageDischargeReport(reportStore);
	return {
		jobToLoadAllVesselsGarbageCategoryReport,
		jobToLoadAllVesselsGarbageDischargeReport,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(AllVessels);
