import { IStore } from "redux/store";
import { IReportStore } from "./@types";
import { jobSelectors } from "redux/_core/job";
import { getType } from "typesafe-actions";
import reportActions from "./actions";

const reportSelectors = {
	_getStore(store: IStore) {
		return store.app.report;
	},
	getRawGarbageData(reportStore: IReportStore) {
		return reportStore?.garbage?.rawGarbageData ?? [];
	},
	getVesselId(reportStore: IReportStore) {
		return reportStore?.garbage?.vesselId ?? "";
	},
	getGarbageYear(reportStore: IReportStore) {
		return reportStore?.garbage?.year ?? "";
	},
	getRawBilgeWaterData(reportStore: IReportStore) {
		return reportStore?.bilgeWater?.rawBilgeWaterData ?? [];
	},
	getBilgeWaterVesselId(reportStore: IReportStore) {
		return reportStore?.bilgeWater?.vesselId ?? "";
	},
	getBilgeWaterYear(reportStore: IReportStore) {
		return reportStore?.bilgeWater?.year ?? "";
	},
	getQuarterLabels(reportStore: IReportStore) {
		return reportStore?.bilgeWater?.quarterLabels;
	},
	getBilgeWaterAllVesselsData(reportStore: IReportStore) {
		return reportStore?.bilgeWater?.allVesselBilgeWaterData;
	},
	getGarbageDischargeAllVesselsData(reportStore: IReportStore) {
		return reportStore?.garbage?.allVesselsGarbageDischargeData;
	},
	getGarbageCategoryAllVesselsData(reportStore: IReportStore) {
		return reportStore?.garbage?.allVesselsGarbageCategoryData;
	},
	getRawOrsData(reportStore: IReportStore) {
		return reportStore?.ors?.rawOrsData ?? [];
	},
	getRawVesselSpecificOrsData(reportStore: IReportStore) {
		return reportStore?.ors?.rawOrsVesselSpecificData ?? [];
	},
	getOrsVesselId(reportStore: IReportStore) {
		return reportStore?.ors?.vesselId ?? "";
	},
	getOrsYear(reportStore: IReportStore) {
		return reportStore?.ors?.year ?? "";
	},
	getGenericVesselId(reportStore: IReportStore) {
		return reportStore?.vesselId ?? "";
	},
	getGenericYear(reportStore: IReportStore) {
		return reportStore?.year;
	},
	getRawCargoSlopData(reportStore: IReportStore) {
		return reportStore?.cargoSlop?.rawCargoSlopData ?? [];
	},
	getRawVesselSpecificCargoSlopData(reportStore: IReportStore) {
		return reportStore?.cargoSlop?.rawCargoSlopVesselSpecificData ?? [];
	},
	getRawOilSpillsInWaterData(reportStore: IReportStore) {
		return reportStore?.oilSpillsInWater?.rawOilSpillsInWaterData ?? [];
	},
	getRawVesselSpecificOilSpillsInWaterData(reportStore: IReportStore) {
		return (
			reportStore?.oilSpillsInWater?.rawOilSpillsInWaterVesselSpecificData ?? []
		);
	},
	jobToLoadGarbageReport(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.garbageReportLoad)
		);
	},
	jobToLoadVesselSpecificBilgeReport(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.bilgeWaterReportLoad)
		);
	},
	jobToLoadVesselTenantBilgeReport(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.bilgeWaterAllVesselsAggregateLoad)
		);
	},

	jobToLoadAllVesselsGarbageCategoryReport(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.garbageCategoryAllVesselsLoad)
		);
	},
	jobToLoadAllVesselsGarbageDischargeReport(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.garbageDischargeAllVesselsLoad)
		);
	},
	jobToLoadAllVesselsOrsAggregate(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.orsAllVesselAggregateLoad)
		);
	},
	jobToLoadVesselSpecificOrsAggregate(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.orsVesselSpecificAggregateLoad)
		);
	},
	jobToLoadAllVesselsCargoSlopAggregate(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.cargoSlopAllVesselAggregateLoad)
		);
	},
	jobToLoadVesselSpecificCargoSlopAggregate(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.cargoSlopVesselSpecificAggregateLoad)
		);
	},
	jobToLoadAllVesselsOilSpillsInWaterAggregate(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(reportActions.commands.oilSpillsInWaterAllVesselAggregateLoad)
		);
	},
	jobToLoadVesselSpecificOilSpillsInWaterAggregate(reportStore: IReportStore) {
		return jobSelectors.getJobStatus(
			reportStore,
			getType(
				reportActions.commands.oilSpillsInWaterVesselSpecificAggregateLoad
			)
		);
	},
};

export default reportSelectors;
