import React from 'react';
import classnames from 'classnames';

const IconInfo = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classnames('fill-current', props.className)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM10.8 4.8H13.2V7.2H10.8V4.8ZM15.6 19.2H8.4V18H10.8V9.6H9.6V8.4H13.2V18H15.6V19.2Z"
            />
        </svg>
    );
};

export default IconInfo;
