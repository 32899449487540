import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	EGarbageRecordState,
	IGarbageAccidentalLoss,
	IGarbageDischargedIntoSea,
	IGarbageDischargedToReceptionAshoreOrOtherShips,
	IGarbageIncinerated,
	IGarbageRecord,
} from "../../../../../redux/app/garbage-record/@types";
import garbageRecordHelper from "../../../../../redux/app/garbage-record/helper";
import { logSelectors } from "../../../../../redux/app/logs";
import {
	marpolActions,
	marpolSelectors,
} from "../../../../../redux/app/marpol";
import {
	vesselActions,
	vesselSelectors,
} from "../../../../../redux/app/vessel";
import { IStore } from "../../../../../redux/store";
import GarbageRecordEditsForPrint from "./GarbageRecordEditsForPrint";
import GRB1Table from "../../garbage-record/Table/GRB1Table";
import GRB1TableBody from "../../garbage-record/Table/GRB1TableBody";

type OwnProps = {
	disabled: boolean;
};
type MARPOLDocsProps = OwnProps & PropsFromRedux;
function GarbageRecordDocs(props: MARPOLDocsProps) {
	const { marpolDocs, disabled } = props;

	if (disabled)
		return (
			<div className="w-full h-64 text-gray-500 bg-gray-500 rounded-lg opacity-50">
				Disabled MARPOL DOCS
			</div>
		);

	if (marpolDocs.length === 0)
		return (
			<div className="flex flex-col flex-1 max-h-full mb-2 ">
				<div className="flex flex-row w-full max-h-full overflow-y-auto ws-scroll">
					<div className="flex-1 ">
						<h1 className="p-6 text-sm font-medium border border-gray-200 rounded-sm">
							No records found..
						</h1>
					</div>
				</div>
			</div>
		);

	return (
		<div className="py-6 ws-scroll">
			<div className="px-6 mb-12">
				<h1 className="mb-8 text-xl font-bold font-redhat-display">
					RECORD OF GARBAGE DISCHARGES
				</h1>
				<div className="mb-3">
					<h3 className="mb-1 text-base font-medium text-gray-700 ">
						Garbage Categories
					</h3>
					<div className="flex flex-row flex-wrap -mx-6">
						<ul className="px-6">
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									A.
								</span>{" "}
								Plastics
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									B.
								</span>{" "}
								Food wastes
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									C.
								</span>{" "}
								Domestic wastes.
							</li>
						</ul>
						<ul className="px-6">
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									D.
								</span>{" "}
								Cooking Oil
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									E.
								</span>{" "}
								Incinetor Ashes
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									F.
								</span>{" "}
								Operational wastes
							</li>
						</ul>
						<ul className="px-6">
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									G.
								</span>{" "}
								Animal Carcass
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									H.
								</span>{" "}
								Fishing Gear
							</li>
							<li className="my-3 text-gray-800 font-redhat-text">
								<span className="inline-block w-4 font-bold text-gray-700 font-redhat-text">
									I.
								</span>{" "}
								E-Waste
							</li>
						</ul>
					</div>
				</div>
				<h1 className="w-3/4 font-medium leading-5 tracking-wide text-gray-600">
					Discharges under MARPOL Annex V regulations 4 (Discharge of garbage
					outside special areas), 5 (Special requirements for discharge of
					garbage from fixed or floating platform) or 6(Discharge of garbage
					withing special areas) or chapter 5 of part II-A of the Polar Code
				</h1>
			</div>
			<div className="px-6 mb-12 rounded-md">
				<GRB1Table>
					{marpolDocs.map((grb1Record) => (
						<GRB1TableBody
							source="HISTORY"
							grb1Record={grb1Record}
							grb1Id={grb1Record.id}
						/>
					))}
				</GRB1Table>
			</div>
			<div className="px-6 mb-12 rounded-md"></div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const logStore = logSelectors._getStore(store);
	const vesselStore = vesselSelectors._getStore(store);
	const marpolStore = marpolSelectors._getStore(store);

	return {
		areVesselsPresent: vesselSelectors.areVesselsPresent(store.app.vesselStore),
		filterForm: logSelectors.formForFilter(logStore),
		marpolDocs: garbageRecordHelper.getHistoryList(
			marpolStore // marpolSelectors.getDocs(marpolStore) as IGarbageRecord[]
		),
		miniVessels: vesselSelectors.getMiniVessels(
			vesselSelectors.getVessels(vesselStore)
		),
		jobToFilterLogs: logSelectors.jobToFilterLogs(logStore),
		jobToLoadSelectedLog: logSelectors.jobToLoadSelectedLog(logStore),
		jobToLoadVessels: vesselSelectors.jobToLoadVessels(vesselStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageRecordDocs);
