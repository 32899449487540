import classNames from "classnames";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { logSelectors } from "../../../../redux/app/logs";
import { marpolActions, marpolSelectors } from "../../../../redux/app/marpol";
import {
	EMARPOLDocuments,
	TMARPOLFilters,
} from "../../../../redux/app/marpol/@types";
import { vesselActions, vesselSelectors } from "../../../../redux/app/vessel";
import { IStore } from "../../../../redux/store";
import Dropdown from "../../../_common/Dropdown";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import InputWrapper from "../../../_common/InputWrapper";
import { authSelectors } from "redux/app/auth";

type OwnProps = {
	onPrint(): void;
	disabled: boolean;
};
type MARPOLFilterProps = OwnProps & PropsFromRedux;
function MARPOLFilter(props: MARPOLFilterProps) {
	const {
		marpolFilters,
		miniVessels,
		jobToLoadVessels,
		disabled,
		jobToLoadDocs,
		tenantCode,
	} = props;

	let marpolRecordTypes = [
		{
			label: "Oil Record Book Part 1",
			value: EMARPOLDocuments.ORB1,
		},
		{
			label: "Oil Record Book Part 2",
			value: EMARPOLDocuments.ORB2,
		},
		{
			label: "Garbage Record 1",
			value: EMARPOLDocuments.GRB1,
		},
		{
			label: "Garbage Record 2",
			value: EMARPOLDocuments.GRB2,
		},
		{
			label: "Ballast Records",
			value: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION,
		},
		{
			label: "ANNEX VI Records",
			value: EMARPOLDocuments.ANNEX6,
		},
	];

	// if tenantCode is TEEKAY then do not show Garbage Record 2
	if (tenantCode === "TEEKAY") {
		marpolRecordTypes = marpolRecordTypes.filter(
			(record) => record.value !== EMARPOLDocuments.GRB2
		);
	}

	return (
		<div
			className={classNames(
				"relative flex flex-row justify-start flex-1 px-0 pl-6",
				{
					"-mx-2": !disabled,
					"mr-3": disabled,
				}
			)}
		>
			<div className="w-1/3 px-2">
				<InputWrapper
					className="w-full py-0 my-0"
					hasError={false}
					hasWarning={false}
					isLoading={jobToLoadDocs.isLoading}
				>
					<label className="ws-input__label">Record type</label>
					<Dropdown
						id="device-type-id"
						inputWrapperProps={{}}
						options={marpolRecordTypes}
						onSelect={function onDeviceTypeChange(e) {
							if (e)
								props.filtersEdit({
									...marpolFilters,
									documentType: e.value as EMARPOLDocuments,
								});
							props.loadDocs();
						}}
						value={marpolFilters.documentType}
						noFilter={true}
						renderView={function renderView(selectedItem) {
							return (
								<span className="w-full font-bold uppercase whitespace-no-wrap bp3-text-overflow-ellipsis">
									{selectedItem.label}&nbsp;
								</span>
							);
						}}
					/>
					<span className="ws-input__error">Hello Error</span>
					<span className="ws-input__warning">Hello Warning</span>
					<InfiniteProgress
						className="ws-input__progress"
						isLoading={true}
						isSmall={false}
						isSpacedOut={false}
					/>
				</InputWrapper>
			</div>

			<div className="w-1/3 px-2">
				<InputWrapper className="w-full py-0 my-0">
					<label className="ws-input__label">Start date</label>
					<input
						type="date"
						name="year-build-date"
						id="year-build-date"
						className="font-bold text-gray-700 ws-input"
						value={marpolFilters.weekStart}
						onChange={function onYearBuildDataChange(e) {
							props.filtersEdit({
								...marpolFilters,
								weekStart: e.target.value,
							});
							props.loadDocs();
						}}
						placeholder="From"
					/>
				</InputWrapper>
			</div>
			<div className="w-1/3 px-2">
				<InputWrapper className="w-full py-0 my-0">
					<label className="ws-input__label">End date</label>
					<input
						type="date"
						name="year-build-date"
						id="year-build-date"
						className="font-bold text-gray-700 ws-input"
						value={marpolFilters.weekEnd}
						onChange={function onYearBuildDataChange(e) {
							props.filtersEdit({
								...marpolFilters,
								weekEnd: e.target.value,
							});
							props.loadDocs();
						}}
						placeholder="To"
					/>
				</InputWrapper>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const logStore = logSelectors._getStore(store);
	const vesselStore = vesselSelectors._getStore(store);
	const marpolStore = marpolSelectors._getStore(store);
	const tenantCode = authSelectors(store.app.auth).getTenantCode();

	return {
		areVesselsPresent: vesselSelectors.areVesselsPresent(
			store.app.vesselStore
		),
		marpolDocs: marpolSelectors.getDocs(marpolStore),
		marpolFilters: marpolSelectors.getFilters(marpolStore),
		miniVessels: vesselSelectors.getMiniVessels(
			vesselSelectors.getVessels(vesselStore)
		),
		jobToFilterLogs: logSelectors.jobToFilterLogs(logStore),
		jobToLoadSelectedLog: logSelectors.jobToLoadSelectedLog(logStore),
		jobToLoadVessels: vesselSelectors.jobToLoadVessels(vesselStore),
		jobToLoadDocs: marpolSelectors.jobToLoadDocs(marpolStore),
		tenantCode,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		loadDocs() {
			dispatch(marpolActions.commands.marpolDocsLoad());
		},
		filtersEdit(filters: TMARPOLFilters) {
			dispatch(marpolActions.document.marpolFiltersSet(filters));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(MARPOLFilter);
