import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4H2 } from "../../../../../redux/app/orb4/groups/orb4-h";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4H2PreviewProps = OwnProps;
function ORB4H2Preview(props: ORB4H2PreviewProps) {
	const orb4Record = props.orb4Record as TORB4H2;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "H", 32, renderItem32()],
					[null, null, 33, renderItem33()],
					[null, null, 34, renderItem34()],
					[null, null, 35, renderItem35()],
					[null, null, 36, renderItem36()],
					[null, null, 37, renderItem37()],
					[null, null, 38, renderItem38()],
					[null, null, 39, renderItem39()],
					[null, null, 40, renderItem40()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem32() {
		return (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={orb4Record.item32.tanksBeingDischarged.map(
						(tank) => `${tank.selectedTank.tankName}`
					)}
				/>
			</h4>
		);
	}
	function renderItem33() {
		return (
			<h4 className="font-medium uppercase">
				START LAT: {orb4Record.item33.startOfDischargePosition.latitude} LON{" "}
				{orb4Record.item33.startOfDischargePosition.longitude},{" "}
				{orb4Record.item33.startOfDischargeTime.lt} LT
			</h4>
		);
	}
	function renderItem34() {
		return (
			<h4 className="font-medium uppercase">
				STOP LAT: {orb4Record.item34.stopOfDischargePosition.latitude} LON{" "}
				{orb4Record.item34.stopOfDischargePosition.longitude},{" "}
				{orb4Record.item34.stopOfDischargeTime.lt} LT
			</h4>
		);
	}
	function renderItem35() {
		return <h4 className="font-medium uppercase">N/A</h4>;
	}
	function renderItem36() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item36.shipSpeed} KTS
			</h4>
		);
	}
	function renderItem37() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item37.wasDischargeMonitoringAndControllingSystemActive
					? "YES"
					: "NO"}
			</h4>
		);
	}
	function renderItem38() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item38.wasRegularCheckKept ? "YES" : "NO"}
			</h4>
		);
	}
	function renderItem39() {
		return (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={orb4Record.item39.slopTanks.map(
						(tank) =>
							`${tank.transferQuantity} M³ TRANSFERRED TO ${
								tank.selectedTank.tankName
							} / TOTAL QUANTITY: ${
								tank.selectedTank.expectedQuantity + tank.transferQuantity
							} M³`
					)}
				/>
			</h4>
		);
	}
	function renderItem40() {
		return <h4 className="font-medium uppercase">N/A</h4>;
	}

	return renderMain();
}

export default ORB4H2Preview;
