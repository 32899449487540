import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4N1 } from "../../../../../redux/app/orb4/groups/orb4-n";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4N1PreviewProps = OwnProps;
function ORB4N1Preview(props: ORB4N1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4N1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "N", 73, renderItem73()],
					[null, null, 74, renderItem74()],
					[null, null, 75, renderItem75()],
					[null, null, 76, renderItem76()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem73() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item73.timeOfOccurence.LT}
			</h4>
		);
	}
	function renderItem74() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item74.locationOfOccurence === "PORT"
					? orb4Record.item74.location
					: null}

				{orb4Record.item74.locationOfOccurence === "AT_SEA"
					? `${orb4Record.item74.latitude} ${orb4Record.item74.longitude}`
					: null}
			</h4>
		);
	}
	function renderItem75() {
		return (
			<h4 className="font-medium uppercase">
				APPROXIMATELY {orb4Record.item75.approxQuantityDischarged} M³
			</h4>
		);
	}
	function renderItem76() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item76.circumstancesOfDischargeOrEscape}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4N1Preview;
