import React from "react";

import ToolCPActivityLog from "components/app/tool/list/_elements/ToolCPActivityLog";

type ScreentConfigsAndPrintsActivityLogProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreentConfigsAndPrintsActivityLog(
	props: ScreentConfigsAndPrintsActivityLogProps
) {
	return <ToolCPActivityLog />;
}

export default ScreentConfigsAndPrintsActivityLog;
