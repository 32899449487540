import {
	typeCreator,
	API_ERROR,
	API_REQUEST,
	API_SUCCESS,
	API_DEFAULT_HEADER_SET,
} from "./action-types";
import {
	IAPIRequestMetaData,
	IAPISuccessParams,
	IAPIErrorParams,
	IAPISuccess,
	IAPIError,
	IAPIErrorParamsWithFeature,
	IAPI_HeaderSet__Action,
} from "./types";
import Axios from "axios";

const apiRequest = <TResponseDataFormat = any>(
	metaData: IAPIRequestMetaData<TResponseDataFormat>
) => ({
	type: typeCreator(metaData.feature).API_REQUEST,
	meta: {
		...metaData,
		trueType: API_REQUEST,
		axiosDefaults: Axios.defaults,
	},
});

const apiSuccess = ({
	feature,
	response,
}: IAPISuccessParams): IAPISuccess => ({
	type: typeCreator(feature).API_SUCCESS,
	payload: response,
	meta: {
		feature,
		trueType: API_SUCCESS,
	},
});

const apiError = ({
	error,
	feature,
	response,
}: IAPIErrorParamsWithFeature): IAPIError => ({
	type: typeCreator(feature).API_ERROR,
	payload: error,
	meta: {
		feature,
		response,
		trueType: API_ERROR,
	},
});

const apiDefaultHeaderSet = (
	feature: string,
	header: string,
	value: any
): IAPI_HeaderSet__Action => ({
	type: typeCreator(feature).API_DEFAULT_HEADER_SET,
	payload: {
		header,
		value,
	},
	meta: {
		feature,
		trueType: API_DEFAULT_HEADER_SET,
	},
});
const apiActions = {
	apiRequest,
	apiSuccess,
	apiError,
	apiDefaultHeaderSet,
};
export default apiActions;
