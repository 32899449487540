import classNames from "classnames";
import React from "react";
import { TAnnex6BackendRecord } from "../../../../redux/app/annex6/@types";
import { TBallastBackendRecord } from "../../../../redux/app/ballast/@types";
import {
	EORB1RecordState,
	TORB1BackendRecord,
} from "../../../../redux/app/orb1/@types";
import { TORB4BackendRecord } from "../../../../redux/app/orb4/@types";
import { EMARPOLDocuments } from "redux/app/marpol/@types";
import { marpolUtils } from "redux/app/marpol";
type OwnProps = {
	isSubmitterChiefOrMaster: boolean;
	record:
		| TORB1BackendRecord
		| TORB4BackendRecord
		| TBallastBackendRecord
		| TAnnex6BackendRecord;
};
type ORB1RecordParticipantsViewProps = OwnProps;
function MARPOLRecordParticipantsView(props: ORB1RecordParticipantsViewProps) {
	const { isSubmitterChiefOrMaster, record } = props;
	const isBallastRecord =
		record.marpolType === EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION;

	function getFormattedDate(dateWithTime: string) {
		return dateWithTime
		// return marpolUtils.getDate(dateWithTime);
	}

	return (
		<>
			<tr
				className={classNames("bg-gray-100", {
					"line-through": record.state === EORB1RecordState.STRIKED_OFF,
				})}
			>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-gray-800 bg-white ws-marpol-td--divfix">
						{" "}
						&nbsp;
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-gray-800 bg-white ws-marpol-td--divfix">
						{" "}
						&nbsp;
					</div>
				</td>
				{!isBallastRecord && (
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-gray-800 bg-white ws-marpol-td--divfix">
							{" "}
							&nbsp;
						</div>
					</td>
				)}
				<td>
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ">
						<h4 className="font-medium leading-9 uppercase">
							RECORDED BY:{" "}
							{record.submittedBy
								? `${record.submittedBy.name} [${
										record.submittedBy.designation
								  }] - ${record.submittedBy.staffId} ${
										record.submittedBy.date
											? `on ${getFormattedDate(record.submittedBy.date)}`
											: ""
								  }`
								: "PENDING"}
							<br />
							VERIFIED BY:{" "}
							{record.verifiedBy
								? `${record.verifiedBy.name} [${
										record.verifiedBy.designation
								  }] - ${record.verifiedBy.staffId} ${
										record.verifiedBy.date && record.submittedBy?.date
											? `on ${getFormattedDate(record.submittedBy.date)}`
											: ""
								  }`
								: "PENDING"}
							<br />
							APRROVED BY:{" "}
							{record.approvedBy
								? `${record.approvedBy.name} [${
										record.approvedBy.designation
								  }] - ${record.approvedBy.staffId} ${
										record.approvedBy.date
											? `on ${getFormattedDate(record.approvedBy.date)}`
											: ""
								  }`
								: "PENDING"}
						</h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("bg-gray-100", {
					hidden: record.state !== EORB1RecordState.STRIKED_OFF,
				})}
			>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-gray-800 bg-white ws-marpol-td--divfix">
						{" "}
						&nbsp;
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-gray-800 bg-white ws-marpol-td--divfix">
						{" "}
						&nbsp;
					</div>
				</td>
				{!isBallastRecord && (
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-gray-800 bg-white ws-marpol-td--divfix">
							{" "}
							&nbsp;
						</div>
					</td>
				)}
				<td>
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ">
						<h4 className="font-medium leading-9 uppercase">
							STRUCK OFF BY:{" "}
							{record.strikedBy
								? `${record.strikedBy.name} [${
										record.strikedBy.designation
								  }] - ${record.strikedBy.staffId} ${
										record.strikedBy.date
											? `on ${getFormattedDate(record.strikedBy.date)}`
											: ""
								  }`
								: "PENDING"}
							{/* @ts-ignore */}
							{record.strikeOffReason ? (
								<div className="">
									STRUCK OFF REASON:
									<span className="ml-1 font-bold text-red-700">
										{/* @ts-ignore */}
										{record.strikeOffReason}
									</span>
								</div>
							) : (
								""
							)}
						</h4>
					</div>
				</td>
			</tr>
		</>
	);
}

export default MARPOLRecordParticipantsView;
