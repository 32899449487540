import { IListObjectStore } from "../../_common/type";
import { IJobHOCState } from "../../_core/job/types";
import { EMARPOLDocuments, TMARPOLProcessedTank } from "../marpol/@types";
import { TORB4A } from "./groups/orb4-a";
import { TORB4B } from "./groups/orb4-b";
import { TORB4C } from "./groups/orb4-c";
import { TORB4D } from "./groups/orb4-d";
import { TORB4E } from "./groups/orb4-e";
import { TORB4G } from "./groups/orb4-g";
import { TORB4H } from "./groups/orb4-h";
import { TORB4I } from "./groups/orb4-i";
import { TORB4J } from "./groups/orb4-j";
import { TORB4M } from "./groups/orb4-m";
import { TORB4N } from "./groups/orb4-n";
import { TORB4O } from "./groups/orb4-o";

export enum EORB4OperationState {
	RECORD = "RECORD",
	PREVIEW = "PREVIEW",
}

export enum EORB4RecordState {
	APPROVED = "APPROVED",
	STRIKED_OFF = "STRIKED-OFF",
	SUBMITTED = "SUBMITTED",
	IDLE = "IDLE",
	EDIT = "EDIT",
	VERIFIED = "VERIFIED",
}

export type TORB4RecordUser = {
	name: string;
	designation: string;
	staffId: string;
	date: string;
};

export type TORB4CommonFields = {
	selectedDate: string;
	remarks: string;
};

export type TORB4CommonData = {
	id: string;
	/**
	 * @description It's an ID for latest update for a document with given id.
	 */
	_rev: string;
	marpolType: EMARPOLDocuments.ORB2;
	state: EORB4RecordState;
	submittedBy?: TORB4RecordUser;
	approvedBy?: TORB4RecordUser;
	verifiedBy?: TORB4RecordUser;
	strikedBy?: TORB4RecordUser;
	strikeOffReason?: string;
	editVersions?: TORB4BackendRecord[];
	dateOfApprovedRecordEdit?: string;
};

export type TORB4ObservedQuantity = {
	preOperation: number;
	postOperation: number;
};

export enum EORB4RecordCode {
	ORB4A1 = "ORB4-A1",
	ORB4B1 = "ORB4-B1",
	ORB4C1 = "ORB4-C1",
	ORB4D1 = "ORB4-D1",
	ORB4E1 = "ORB4-E1",
	ORB4G1 = "ORB4-G1",
	ORB4H1 = "ORB4-H1",
	ORB4H2 = "ORB4-H2",
	ORB4H3 = "ORB4-H3",
	ORB4I1 = "ORB4-I1",
	ORB4J1 = "ORB4-J1",
	ORB4J2 = "ORB4-J2",
	ORB4M1 = "ORB4-M1",
	ORB4N1 = "ORB4-N1",
	ORB4O1 = "ORB4-O1",
	ORB4O2 = "ORB4-O2",
	ORB4O3 = "ORB4-O3",
	ORB4O6 = "ORB4-O6",
}

export type TORB4Record =
	| TORB4A
	| TORB4B
	| TORB4C
	| TORB4D
	| TORB4E
	| TORB4G
	| TORB4H
	| TORB4I
	| TORB4J
	| TORB4M
	| TORB4N
	| TORB4O;

export type TORB4BackendRecord = TORB4Record & {
	submittedBy: TORB4RecordUser;
	approvedBy?: TORB4RecordUser;
	verifiedBy?: TORB4RecordUser;
	strikedBy?: TORB4RecordUser;
	processedTanks: TORB4ProcessedTank[];
	nonParticipantTanks: { id: string; expectedQuantity: number }[];
	isAutoCreated?: boolean;
	isEditWarningVisible?: boolean;
};

export type TORB4ProcessedTank = TMARPOLProcessedTank;
