import { IJobHOCState } from "redux/_core/job/types";
import { ActionType } from "typesafe-actions";
import feedbackActions from "./actions";
import { IListObjectStore } from "redux/_common/type";
import { FILTER_SECTIONS } from "./constants";
import { PropsValue } from "react-select";
import { Range } from "react-date-range";

export interface IFeedback {
	_id: string;
	_rev: string;
	title: string;
	author: string;
	authorDesignation?: string;
	vesselId: string;
	feedback: string;
	authorId?: string;
	modelName: string;
	draftIssueItemId?: number;
	isDraftIssueCreated?: boolean;
	isRead?: boolean;
	vesselName?: string;
	vesselImo?: string;
	createdAt: string;
	section?: string;
	status?: string;
	subsection?: string;
	emailSent?: boolean;
	user_new_message?: boolean;
	support_new_message?: boolean;
	versionInfo?: {
		FE: string;
		BE: string;
	};
	assignedTo: IAssignedUser[];
	activity?: IFeedbackActivity[];
	ticketNumber?: number;
	priority?: string;
	dueDate?: string;
	notes?: string;
	attachments?: { name: string; type: string }[];
}

export type TCreateMessageFrom = "individualFeedbackPage" | "chatModal";

export interface IVersionInfo {
	FE: string;
	BE: string;
	config?: string;
}

export interface IVessel {
	vesselName: string;
	id: string;
}

// export interface IPagination {
// 	totalItems: number;
// 	itemsPerPage: number;
// 	currentPage: number;
// }

export interface IChatMessageAttachment {
	name: string;
	type: string;
}

export interface IFeedbackChat {
	_id: string;
	_rev: string;
	vesselId: string;
	message: string;
	sender: string;
	senderName: string;
	createdAt: string;
	feedbackId: string;
	modelName: string;
	emailSent?: boolean;
	attachments?: File[] | IChatMessageAttachment[] | undefined;
	attachmentType?: "url" | "local";
}

export interface IFeedbackFilters {
	vesselId: string;
	showPendingMessages?: boolean;
}

export interface IPagination {
	totalItems?: number;
	itemsPerPage: number;
	currentPage: number;
}

export interface IFilterState {
	section?: IOption;
	subsection?: IOption;
	assignedTo?: IOption;
	status?: IOption;
	priority?: IOption;
	user_new_message?: boolean;
	vesselId?: string;
	showPendingMessages?: boolean;
}

export interface IFeedbackStore extends IJobHOCState {
	notifications: INotification[];
	notificationsPagination: IPagination;
	feedbacks: IFeedback[];
	vessels: IListObjectStore<IVessel>;
	isFeedbackChatModalOpen: boolean;
	selectedFeedbackId: string;
	chats: IFeedbackChat[];
	individualFeedbackWithChats?: IIndividualFeedbackWithChat;
	message: string;
	activeChatAttachments: File[] | null;
	isMessageBeingGenerated: boolean;
	selectedFeedbackVesselId: string;
	searchBy: string;
	filters: IFeedbackFilters;
	_pagination: IPagination;
	isFeedbackFormModalOpen: boolean;
	tasks: IListObjectStore<TTaskTemplate>;
	vesselSpecificUsers: IListObjectStore<IVesselUser>;
	isFeedbackFiltersOpen: boolean;
	currentSelectedFeedbackFilterSection: FILTER_SECTIONS;
	filterState: IFilterState;
	ticketTabType: ETicketTabType;
	dashboard: {
		myTickets: {
			feedbacks: Record<string, IFeedback[]>;
			stats: IDashboardStatsRes;
		};
		allTickets: {
			feedbacks: Record<string, IFeedback[]>;
			stats: IDashboardStatsRes;
		};
		dateRange: Range[];
	};
}

export interface IAssignedUser {
	userId: string;
	userName: string;
	userEmail: string;
}

export interface IFeedbackUpdatePayload {
	priority?: string;
	status?: string;
	dueDate?: string;
	assignedTo?: IAssignedUser[];
	notes?: string;
}
export type TCount = {
	count: number;
};

export interface IFeedbackActivity {
	changedAt: string;
	changedBy: string;
	item: string;
	priority?: string;
	status?: string;
	assignedTo?: IAssignedUser[];
	dueDate?: string;
	notes?: string;
}

export interface IOption {
	label: string;
	value: string;
}

export interface IFeedbackUpdateType {
	// table 1 include priority, status & dueDate
	isTable1Update: boolean;
	// table 2 includes assignedTo
	isTable2Update: boolean;
	notesUpdate: boolean;
}

export type TStatusType = "Open" | "In Progress" | "Closed" | "Reopened";
export type TPriorityType = "High" | "Medium" | "Low";

export interface IIndividualFeedbackWithChat extends IFeedback {
	chatMessages: IFeedbackChat[];
}

export type TFeedback = {
	author?: string;
	feedback?: string;
	title?: string;
	section?: string;
	vesselName?: string;
	userId?: string;
	subsection?: string;
	support_new_message?: boolean;
	user_new_message?: boolean;
	attachments?: File[] | null;
	FE?: string;
	BE?: string;
	versionInfo?: IVersionInfo;
};

export type TTaskTemplate = {
	id: string;
	title: string;
	type: "LOG" | "CHECKLIST" | "PERMIT";
	isCollaborative?: boolean;
	refNo?: string;
	bindings: {
		defaults(params?: any): void;
		formValidation(params?: any): void;
		renderData(params?: any): void;
		taskTriggers(params?: any): void;
		Form(params?: any): any;
		View(params?: any): JSX.Element;
		PrintView(params?: any): JSX.Element;
	};
};

export interface IVesselUser {
	_id: string;
	_rev: string;
	name: string;
	role: string;
	roleCategory: string;
	designation: {
		type: string;
		label: string;
		rank: number;
		name: string;
		vesselId: string;
	};
	staffId: string;
	isRelieved: boolean;
	password: string;
	tenantId: string;
	isWayshipTrained: boolean;
	modelName: string;
	pin: string;
	id: string;
	trainingDate?: string;
	vesselName?: string;
	isEndorsed?: boolean;
	endorsedBy?: [
		{ name: string; rank: number; designation: string; timestamp: string }
	];
}

export interface ITaskSelectFilterForm {
	filterText: string;
}

export type IFeedbackActions = ActionType<typeof feedbackActions>;

const { commands, document } = feedbackActions;
export type IFeebackActions_Document = ActionType<typeof document>;
export type IFeedbackActions_Command = ActionType<typeof commands>;

export enum ETicketTabType {
	ALL_TICKETS = "all-tickets",
	MY_TICKETS = "my-tickets",
}

export enum ETypeOfFilter {
	HIGH_PRIORITY = "highPriority",
	WAITING_FOR_REPLY = "waitingForReply",
	DUE_THIS_WEEK = "dueThisWeek",
	TICKETS_TO_BE_ASSIGNED = "ticketsToBeAssigned",
	TICKETS_OVERDUE = "ticketsOverdue",
}

export interface IFeedbackDashboardRequestPayloadFilter {
	pageNumber: number;
	typeOfFilter: ETypeOfFilter;
	status?: IOption;
	priority?: IOption;
	user_new_message?: boolean;
	showPendingMessages?: boolean;
}

export interface IDashboardStatsAndFeedbackPayload {
	stats?: boolean;
	assignedTo?: string;
	selector?: Record<string, any>;
	typeOfFilter?: ETypeOfFilter;
}

export interface IDashboardStatsRes {
	Open: number;
	Closed: number;
	InProgress: number;
	Reopened: number;
	Total: number;
}

export type TDashboardStatsAndFeedbackResponse =
	| IFeedback[]
	| IDashboardStatsRes;


export interface INotification {
	_id: string;
	_rev: string;
	vesselId: string;
	message: string;
	sender: string;
	senderName: string;
	createdAt: string;
	feedbackId: string;
	senderDesignation: string;
	modelName: string;
	vesselName: string;
	emailSent: boolean
}