import React from "react";

type SVGDocProps = { className?: string };
const SVGDoc: React.FC<SVGDocProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 50 50"
		>
			<path d="M28.875 0a.466.466 0 00-.063.031l-28 5.313a1 1 0 00-.812 1v37.312a1 1 0 00.813 1l28 5.313c.289.054.59-.02.82-.207.226-.188.363-.465.367-.762v-5h17c1.094 0 2-.906 2-2V8c0-1.094-.906-2-2-2H30V1a1.004 1.004 0 00-1.125-1zM28 2.188v4.5a1.025 1.025 0 000 .593v35.532a1.016 1.016 0 000 .406v4.593L2 42.844V7.156zM30 8h17v34H30v-5h14v-2H30v-6h14v-2H30v-5h14v-2H30v-5h14v-2H30zM4.625 15.656l3.813 18.688h3.75l2.468-11.969c.114-.55.219-1.273.281-2.125h.032c.027.773.09 1.5.219 2.125l2.406 11.969h3.625l3.843-18.688H21.75l-2 12.469a16.853 16.853 0 00-.219 2.094H19.5c-.066-.88-.133-1.54-.219-2l-2.375-12.563h-3.5l-2.625 12.406c-.168.793-.285 1.52-.312 2.188h-.063c-.039-.895-.097-1.625-.187-2.156L8.188 15.656z"></path>
		</svg>
	);
};

export default SVGDoc;
