import React from "react";
type SVGConfigProps = { className?: string };
const SVGConfig: React.FC<SVGConfigProps> = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M12.6669 2.11133L9.50022 5.27799H5.27799V9.50022L2.11133 12.6669L5.27799 15.8335V20.0558H9.50022L12.6669 23.2224L15.8335 20.0558H20.0558V15.8335L23.2224 12.6669L20.0558 9.50022V5.27799H15.8335L12.6669 2.11133Z"
			stroke="#718096"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M12.6667 15.8333C13.5065 15.8333 14.312 15.4997 14.9058 14.9058C15.4997 14.312 15.8333 13.5065 15.8333 12.6667C15.8333 11.8268 15.4997 11.0214 14.9058 10.4275C14.312 9.83363 13.5065 9.5 12.6667 9.5C11.8268 9.5 11.0214 9.83363 10.4275 10.4275C9.83363 11.0214 9.5 11.8268 9.5 12.6667C9.5 13.5065 9.83363 14.312 10.4275 14.9058C11.0214 15.4997 11.8268 15.8333 12.6667 15.8333Z"
			stroke="#718096"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGConfig;
