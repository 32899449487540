import { IListObjectStore } from "redux/_common/type";
import { IJobHOCState } from "redux/_core/job/types";
import { IOption } from "../feedback/@types";
import { Range } from "react-date-range";

export interface IDateRangeVal {
	startDate: Date;
	endDate: Date;
	key: string;
}

export interface IEngineLog {
	config: any;
	metricsList: IListObjectStore<IMetricListItem>;
	engineLogInsights: {
		list: IEngineLogInsightPayloadItem[];
		metrics: string[];
		dateRangeValues: string[];
	};
	selectedEngineLogMetrics: string[];
	activeSelectedEngineLogMetrics: string[]; //refers to the checkbox metrics(below graph) that appear once user selects few metrics.
}

export interface INoonReport {
	config: any;
	engineConfig: any;
	metricsList: IListObjectStore<INoonReportMetricListItem>;
	noonReportInsights: {
		list: any;
		metrics: string[];
		dateRangeValues: string[];
	};
	activeSelectedNoonReportMetrics: string[];
	selectedNoonReportMetrics: string[];
}

export enum EParameterTabType {
	ENGINE_LOG_TAB = "engine-log",
	NOON_REPORT_TAB = "noon-report",
}

export interface IInsightStore extends IJobHOCState {
	engineLog: IEngineLog;
	noonReport: INoonReport;
	showParameterMetricList: boolean;
	showRecentMetricList: boolean;
	currentTab: EParameterTabType;
	vesselId: string;
	dateRange: Range[];
	stateOfShip: string;
	stateOfShipOptions: IOption[];
	stateOfShipError: boolean;
	search: string;
}

export interface IInsightMetric {
	key: string;
	metric: string;
	remarks: string;
	range?: string;
}

export interface IMetricListItem {
	id: string;
	key: string;
	range: string;
	section: string;
	subSection: string;
	metric: string;
}

export interface IEngineLogInsightPayloadItem {
	data: Record<string, any>;
	selectedDate: string;
}

export interface IDropdownOption {
	label: string;
	value: string;
}

export type TStateOfShip =
	| "At Sea"
	| "Anchor"
	| "At Port"
	| "Maneuvering"
	| "Drifting"
	| "Unmanned";

export interface lineChartItem {
	data: any;
	id: string;
	key: string;
	range: string;
	section: string;
	subSection: string;
	metric: string;
	color: string;
	dataKey: string;
	name: string;
}

export interface IEmptyMessageParams {
	vesselId: string;
	stateOfShip: string;
	selectedMetricsLen: number;
}

export interface ILoadEngineLogConfigParams {
	category: string;
	vesselId: string;
	type: string;
	userId?: string;
	token?: string;
}

export interface ILoadNoonReportConfigParams {
	userId?: string;
	token?: string;
}

export interface INotActive {
	notActiveMetrics: string[];
}

export interface INoonReportMetricListItem {
	sectionTitle: string;
	subSectionTitle?: string;
	metricTitle: string;
	key: string;
	readingRange: { dataType: string };
	id: string;
}

export type TRecentList = Record<
	string,
	{
		engineLogMetricsRecentList: IMetricListItem[];
		noonReportMetricsRecentList: INoonReportMetricListItem[];
	}
>;
