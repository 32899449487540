import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../../redux/store";
import InputWrapper from "../../../../_common/InputWrapper";
import checklistSelectors from "redux/app/checklist/selectors";
import { IChecklistFilters } from "redux/app/checklist/types";
import { checklistActions } from "redux/app/checklist";
import Select from "react-select";
import classNames from "classnames";

type ChecklistFilterHeaderProps = PropsFromRedux;
function ChecklistFilterHeader(props: ChecklistFilterHeaderProps) {
	const { checklistUniqueList, filterForm } = props;
	const { fields } = filterForm;

	let options = [
		{
			label: "All Checklist",
			value: "",
		},
		...checklistUniqueList?.map((checklistObj) => {
			return {
				label: checklistObj.title,
				value: checklistObj.type
			};
		})
	];

	const selectedChecklist = options.find((o) => o.value === fields.checklistType);

	return (
		<tr className="sticky top-0 bg-white">
			<th />
			<th className="text-sm text-gray-500">
				<InputWrapper
					className="w-full py-0 my-0"
					hasError={false}
					hasWarning={false}
					// isLoading={jobToFilterLogs.isLoading}
				>
					<Select
						isSearchable
						placeholder="All Checklist"
						unstyled
						value={(options as any)?.find((option: any) => option?.value === selectedChecklist?.value)}
						options={options as any}
						className="ws-input p-0 px-1.5"
						onChange={(e: any) => {
							if (e) {
								props.editForm({
									checklistType: e?.value as string,
								});
							}
						}}
						classNames={{
							option: ({ isFocused, isSelected }) =>
								classNames(
									isFocused && "hover:cursor-pointer",
									isSelected && "font-medium rounded bg-gray-200",
									"p-2 hover:bg-gray-200 rounded"
								),
							dropdownIndicator: () =>
								"hover:bg-gray-100 text-gray-500 rounded-md hover:text-black",
							menu: () =>
								"border border-gray-200 bg-white rounded-md",
							singleValue: () => "",
							placeholder: () => "text-gray-500",
							valueContainer: () => "",
						}}
					/>
					<span className="ws-input__error">Hello Error</span>
					<span className="ws-input__warning">Hello Warning</span>
				</InputWrapper>
			</th>

			<th className="text-sm text-gray-500" />
			<th>
				<button className="hidden px-6 font-medium text-gray-500 bg-blue-100 border-2 border-gray-500 rounded-md ws-input focus:bg-blue-500 hover:text-gray-700 hover:border-gray-700 focus:shadow-lg focus:text-white">
					Export as PDF
				</button>
			</th>
		</tr>
	);
}

function mapStateToProps(store: IStore) {
	const checklistStore = checklistSelectors._getStore(store);

	return {
		filterForm: checklistSelectors.formForFilter(checklistStore),
		checklistUniqueList: checklistSelectors.getChecklistUniqueList(checklistStore)
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		editForm(fields: Partial<IChecklistFilters>) {
			dispatch(checklistActions._forms.filter.formFieldsEdit(fields));
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ChecklistFilterHeader);
