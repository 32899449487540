import actionTypes from "../action-types";
import { apiActions } from "../../../../_core/api";
import { Middleware } from "redux";
import {
	IVesselOnboard_vesselLoad__Action,
	IVesselOnboard_vesselImageUpload__Action,
	IVesseOnboard_vesselCreate__Action,
	IVesseOnboard_vesselUpdate__Action,
	Ranges,
	IVesseOnboard_vesselConfigListLoad__Action,
	IVesseOnboard_vesselConfigLoad__Action,
	IVesseOnboard_vesselRangesDelete__Action,
	IVesseOnboard_vesselRangesEdit__Action,
	IVesseOnboard_vesselRangesAdd__Action,
} from "../types";
import actions from "../actions";
import vesselOnboardAPIs from "../apis";
import { IVessel } from "../../types";
import { object, ValidationError, string } from "yup";
import { formActionTypes } from "../../../../_core/form";
import {
	IForm_Submit__Action,
	IForm_FieldsSet__Action,
	IFormErrorFormat,
} from "../../../../_core/form/types";
import { IStore } from "../../../../store";
import { promiseActions } from "../../../../_core/promise-action";
import vesselOnboardConstants from "../constants";
import vesselActions from "../../actions";
import { invokerAction } from "../../../../_common/actions";
import vesselOnboardActions from "../actions";
import { IConfigInfo, IConfigListItem } from "redux/app/tool/@types";
import { commonUtils } from "redux/_common";

const { apiRequest } = apiActions;
const {
	WIZARD_VESSEL_LOAD,
	WIZARD_VESSEL_IMAGE_UPLOAD,
	WIZARD_VESSEL_CREATE,
	WIZARD_VESSEL_UPDATE,
	WIZARD_VESSEL_RANGES_LOAD,
	WIZARD_VESSEL_CONFIG_LOAD,
	WIZARD_VESSEL_CONFIG_LIST_LOAD,
	WIZARD_VESSEL_RANGE_EDIT,
	WIZARD_VESSEL_RANGE_DELETE,
	WIZARD_VESSEL_RANGE_ADD
} = actionTypes.command;
const { FORM_SUBMIT, FORM_FIELDS_SET } = formActionTypes;

const createValidationSchema = object().shape({
	vesselName: string().required("Vessel name is required"),
	imoNumber: string()
		.length(7, "Must have exact 7 characters")
		.required("IMO number is required"),
	yearBuildDate: string().required("Build date is required"),
	nameOfTheOwner: string().required("Name of the owner is required"),
	portOfRegistry: string().required("Port of registry is required"),
	nationality: string().required("Nationality is required"),
	addressOfTheOwner: string().required("Address of the owner is required"),
	vesselPrefix: string()
		.required("Vessel prefix is required")
		.oneOf([
			"MV",
			"MT",
			"AE",
			"AFS",
			"AHT",
			"AHTS",
			"AO",
			"AOG",
			"AOR",
			"AOT",
			"ATB",
			"BRP",
			"CRV",
			"C/F",
			"CS",
			"DB",
			"DEPV",
			"DLB",
			"DCV",
			"DSV",
			"DV",
			"ERRV",
			"EV",
			"FPSO",
			"FPV",
			"FT",
			"FV",
			"GTS",
			"HLV",
			"HMHS",
			"HSC",
			"HSF",
			"HTV",
			"IRV",
			"ITB",
			"LB",
			"LNG/C",
			"LPG/C",
			"MF",
			"MFV",
			"MS (M/S)",
			"MSV",
			"MSY",
			"MT",
			"MTS",
			"MV",
			"MY",
			"NB",
			"NRV",
			"NS",
			"OSV",
			"PS",
			"PSV",
			"QSMV",
			"QTEV",
			"RMS",
			"RNLB",
			"RRS",
			"RV/RSV",
			"SB",
			"SL",
			"SS(S/S)",
			"SSCV",
			"SSS",
			"SSV",
			"ST",
			"STS",
			"STV",
			"SV",
			"SY",
			"TB",
			"TEV",
			"TIV",
			"TrSS",
			"TS",
			"Tr.SMV",
			"TSMV",
			"TSS",
			"TST",
			"TV",
			"ULCC",
			"VLCC",
			"YD",
			"YT",
			"YMT",
			"YTB",
			"YTL",
			"YTM",
			"YW",
			"YWN",
			"YOS",
		]),
	vesselType: string()
		.required("Vessel type is required")
		.oneOf(["Dry", "Liquid"]),
	vesselSubType: string()
		.required("Vessel sub type is required")
		.oneOf([
			"General Cargo Carrier",
			"Container Carrier",
			"RO-RO Carrier",
			"Bulk Carrier",
			"Crude Carrier",
			"Product Carrier",
			"Liquefied Carrier",
			"Chemical Carrier",
		]),
	shipSizeCategory: string()
		.required("Ship size category is required")
		.oneOf([
			"Small Handy Size",
			"Seawaymax",
			"Handy Size",
			"Handy Max",
			"Panamax",
			"Neopanamax",
			"Capsize",
			"Chinamax",
			"Aframax",
			"Q-Max",
			"Suezmax",
			"VLCC",
			"ULCC",
			"Malaccamax",
		]),
	id: string().notRequired(),
	callSign: string(),
	flag: string(),
	image: string(),
	tonnage: string(),
	dimensions: string(),
	tenantId: string(),
	createdAt: string(),
	updatedAt: string(),
	isCrewOnboarded: string(),
	isDeviceOnboarded: string(),
});

const updateValidationSchema = object().shape({
	vesselPrefix: string(),
	nameOfTheOwner: string(),
	addressOfTheOwner: string(),
	callSign: string(),
	flag: string(),
	vesselType: string(),
	vesselSubType: string(),
	shipSizeCategory: string(),
	image: string(),
	tonnage: string(),
	dimensions: string(),
	tenantId: string(),
	createdAt: string(),
	updatedAt: string(),
	isCrewOnboarded: string(),
	isDeviceOnboarded: string(),
	imoNumber: string().required("Imo number is required"),
	vesselName: string().required("Vessel name is required"),
	yearBuildDate: string().required("Year build date is required"),
	nationality: string().required("Nationality is required"),
	portOfRegistry: string().required("Port of registry is required"),
	id: string().required("Id is required"),
});

const onboardVesselMiddleware: Middleware<any, IStore> = ({
	dispatch,
	getState,
}) => (next) => (
	action:
		| IVesselOnboard_vesselLoad__Action
		| IVesselOnboard_vesselImageUpload__Action
		| IForm_Submit__Action
		| IForm_FieldsSet__Action<IVessel>
		| IVesseOnboard_vesselCreate__Action
		| IVesseOnboard_vesselUpdate__Action
		| IVesseOnboard_vesselConfigListLoad__Action
		| IVesseOnboard_vesselConfigLoad__Action
		| IVesseOnboard_vesselRangesEdit__Action
		| IVesseOnboard_vesselRangesDelete__Action
		| IVesseOnboard_vesselRangesAdd__Action
) => {
	switch (action.type) {
		case WIZARD_VESSEL_CONFIG_LIST_LOAD: {
			next(action);
			const { vesselId } = getState().app.vesselStore.onboard;
			if (!vesselId) return;
			const job = actions._jobs.WIZARD_VESSEL_CONFIG_LIST_LOAD;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";
			dispatch(
				apiActions.apiRequest<IConfigListItem[]>({
					...vesselOnboardAPIs.loadConfigsListForType(
						'logs',
						userId,
						token,
						vesselId
					),
					preExecute() {
						dispatch(
							job.active({
								message: "Loading config...",
							})
						);
					},
					postExecute: {
						onSuccess({ data }) {
							if (data && data.length) {
								dispatch(
									vesselOnboardActions.document.vesselConfigLogsListSet(
										commonUtils.arrayToObject(
											data.map((val) => ({ ...val, id: val._id }))
										)
									)
								);
							}
							dispatch(job.success({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Error loading configs",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_CONFIG_LOAD: {
			next(action);
			const { configId } = (action as IVesseOnboard_vesselConfigLoad__Action).payload;
			if (!configId) return;
			const job = actions._jobs.WIZARD_VESSEL_CONFIG_LOAD;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";
			dispatch(
				apiActions.apiRequest<IConfigInfo>({
					...vesselOnboardAPIs.loadConfigInfo(configId, token, userId),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess({ data, status }) {
							if (status === 200 && data?._id) {
								dispatch(vesselOnboardActions.document.vesselConfigInfoSet(data));
							}
							dispatch(job.success({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Failed to load config info",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_RANGES_LOAD: {
			next(action);
			const { vesselId } = getState().app.vesselStore.onboard;
			if (!vesselId) return;
			const job = actions._jobs.WIZARD_VESSEL_RANGES_LOAD;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";
			dispatch(
				apiActions.apiRequest<{ localRanges: Ranges; signalLocalRanges: Ranges }>({
					...vesselOnboardAPIs.loadVesselRanges({
						vesselId: vesselId || "",
						token,
						userId
					}),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess({ data }) {
							if (data?.localRanges && !Array.isArray(data?.localRanges) && typeof data?.localRanges === "object") {
								dispatch(
									vesselOnboardActions.document.vesselRangesSet(
										data?.localRanges
									)
								);
							}
							dispatch(
								vesselOnboardActions.document.vesselSignalLocalRangesSet(
									data?.signalLocalRanges &&
										!Array.isArray(data?.signalLocalRanges) &&
										typeof data?.signalLocalRanges === "object"
										? data.signalLocalRanges
										: undefined
								)
							);
							dispatch(job.success({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Error loading ranges",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_RANGE_DELETE: {
			next(action);
			const { vesselId } = getState().app.vesselStore.onboard;
			if (!vesselId) return;
			const { ranges } = (action as IVesseOnboard_vesselRangesDelete__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_RANGE_DELETE;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";
			dispatch(
				apiActions.apiRequest<any>({
					...vesselOnboardAPIs.vesselRangeDelete({
						vesselId: vesselId || "",
						ranges,
						token,
						userId
					}),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess({ data }) {
							dispatch(vesselOnboardActions.command.vesselRangesLoad());
							dispatch(job.success({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Error deleting ranges",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_RANGE_ADD: {
			next(action);
			const { vesselId } = getState().app.vesselStore.onboard;
			if (!vesselId) return;
			const { ranges } = (action as IVesseOnboard_vesselRangesAdd__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_RANGE_ADD;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";
			dispatch(
				apiActions.apiRequest<any>({
					...vesselOnboardAPIs.vesselRangeAdd({
						vesselId: vesselId || "",
						ranges,
						token,
						userId
					}),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess({ data }) {
							dispatch(vesselOnboardActions.command.vesselRangesLoad());
							dispatch(job.success({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Error adding ranges",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_RANGE_EDIT: {
			next(action);
			const { vesselId } = getState().app.vesselStore.onboard;
			if (!vesselId) return;
			const { ranges } = (action as IVesseOnboard_vesselRangesEdit__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_RANGE_EDIT;
			const authStore = getState().app.auth;
			// @ts-ignore
			const userId = authStore.user?.userId ?? "";
			const token = authStore?.authToken ?? "";
			dispatch(
				apiActions.apiRequest<any>({
					...vesselOnboardAPIs.vesselRangeEdit({
						vesselId: vesselId || "",
						ranges,
						token,
						userId
					}),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess({ data }) {
							dispatch(vesselOnboardActions.command.vesselRangesLoad());
							dispatch(job.success({}));
						},
						onError() {
							dispatch(
								job.error({
									message: "Error editing ranges",
									notification: {},
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_LOAD: {
			next(action);
			const {
				vesselId,
				notify,
			} = (action as IVesselOnboard_vesselLoad__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_LOAD;
			dispatch(
				apiRequest<IVessel>({
					...vesselOnboardAPIs.loadVesselAPI(vesselId),
					preExecute: () => {
						dispatch(
							job.active({
								message: "Loading Vessel",
								notification: notify
									? {
											hideAtState: "SUCCESS",
											timeout: 100,
									  }
									: undefined,
							})
						);
					},
					postExecute: {
						onSuccess(response) {
							if (response.data) {
								dispatch(job.success({}));
								dispatch(
									invokerAction(
										WIZARD_VESSEL_LOAD,
										actions._forms.vessel.formFieldsSet(response.data)
									)
								);
								dispatch(
									invokerAction(
										WIZARD_VESSEL_LOAD,
										actions._forms.vessel.formModeSet("EDIT")
									)
								);
								dispatch(vesselActions.listEdit(vesselId, response.data));
							}
						},
						onError() {
							dispatch(
								job.error({
									message: "Error loading vessel",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_IMAGE_UPLOAD: {
			next(action);
			const {
				file,
			} = (action as IVesselOnboard_vesselImageUpload__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_IMAGE_UPLOAD;
			dispatch(
				apiRequest({
					...vesselOnboardAPIs.uploadVesselImageAPI(file),
					preExecute() {
						dispatch(job.active({}));
					},
					postExecute: {
						onSuccess(response) {
							const fileName = response.data.result.files.file[0].name;
							const BASE_URL = `${process.env.REACT_APP_DOMAIN}/api`;
							const image = `${BASE_URL}/storages/wayshipprivate/download/${fileName}`;
							dispatch(job.success({}));
							dispatch(
								invokerAction(
									WIZARD_VESSEL_IMAGE_UPLOAD,
									actions._forms.vessel.formFieldsEdit({ image })
								)
							);
						},
						onError() {
							dispatch(job.error({}));
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case FORM_SUBMIT: {
			next(action);
			const { feature, formName } = (action as IForm_Submit__Action).payload;
			if (
				feature === vesselOnboardConstants.feature &&
				formName === vesselOnboardConstants.forms.VESSEL
			) {
				const {
					fields,
					mode,
				} = getState().app.vesselStore.onboard._forms.VESSEL;
				if (mode === "CREATE") {
					dispatch(
						promiseActions(
							createValidationSchema.validate(fields, { abortEarly: false }),
							{
								invoker: "$vessel/onboard/vessel-middleware",
								onSuccess(validatedData) {
									if (validatedData)
										dispatch(actions.command.vesselCreate(fields));
								},
								onError(error: ValidationError) {
									const errors: IFormErrorFormat = {
										GLOBAL: {
											isValid: false,
											message: "Found few errors",
										},
									};
									error.inner.map((errorItem) => {
										errors[errorItem.path] = {
											isValid: false,
											message: errorItem.message,
										};
									});
									dispatch(
										invokerAction(
											"$vessel/onboard/vessel-middleware",
											actions._forms.vessel.formErrorsSet(errors)
										)
									);
								},
							}
						)
					);
				} else if (mode === "EDIT") {
					dispatch(
						promiseActions(
							updateValidationSchema.validate(fields, { abortEarly: false }),
							{
								invoker: "$vessel/onboard/vessel-middleware",
								onSuccess(validatedData) {
									if (validatedData)
										dispatch(actions.command.vesselUpdate(fields.id, fields));
								},
								onError(error: ValidationError) {
									const errors: IFormErrorFormat = {
										GLOBAL: {
											isValid: false,
											message: "Found few errors",
										},
									};
									error.inner.map((errorItem) => {
										errors[errorItem.path] = {
											isValid: false,
											message: errorItem.message,
										};
									});
									dispatch(
										invokerAction(
											"$vessel/onboard/vessel-middleware",
											actions._forms.vessel.formErrorsSet(errors)
										)
									);
								},
							}
						)
					);
				} else
					dispatch(
						invokerAction(
							"$vessel/onboard/vessel-middleware",
							actions._forms.vessel.formErrorsSet({
								GLOBAL: {
									isValid: false,
									message: "No form mode provided",
								},
							})
						)
					);
			}
			break;
		}
		case FORM_FIELDS_SET: {
			next(action);
			const { formName, feature, fields } = (action as IForm_FieldsSet__Action<
				any
			>).payload;
			if (
				feature === vesselOnboardConstants.feature &&
				formName === vesselOnboardConstants.forms.VESSEL
			) {
				// To reset the errors on Change //FIXME: Add below code in Form Middleware
				dispatch(
					invokerAction(
						"$vessel/onboard/vessel-middleware",
						actions._forms.vessel.formErrorsReset([
							"GLOBAL",
							...Object.keys(fields),
						])
					)
				);
			}
			break;
		}
		case WIZARD_VESSEL_CREATE: {
			next(action);
			const { vessel } = (action as IVesseOnboard_vesselCreate__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_CREATE;
			// NOTE: Backend sends 500 Error instead of validation errors.. when callSign is sent empty in vessel object [Buggy Backend]
			dispatch(
				apiRequest<IVessel>({
					...vesselOnboardAPIs.createVesselAPI(vessel),
					preExecute() {
						dispatch(
							job.active({ message: "Adding Vessel", notification: {} })
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(job.success({ message: "Vessel is added" }));
							dispatch(vesselActions.listPush(response.data));
						},
						onError() {
							dispatch(
								job.error({
									message: "Failed to add Vessel",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case WIZARD_VESSEL_UPDATE: {
			next(action);
			const {
				vessel,
				vesselId,
			} = (action as IVesseOnboard_vesselUpdate__Action).payload;
			const job = actions._jobs.WIZARD_VESSEL_UPDATE;
			dispatch(
				apiRequest<IVessel>({
					...vesselOnboardAPIs.updateVesselAPI(vesselId, vessel),
					preExecute() {
						dispatch(
							job.active({ message: "Updating vessel...", notification: {} })
						);
					},
					postExecute: {
						onSuccess(response) {
							dispatch(job.success({ message: "Vessel updated!" }));
							dispatch(vesselActions.listEdit(vesselId, response.data));
							dispatch(actions.command.vesselLoad(vesselId))
						},
						onError() {
							dispatch(
								job.error({
									message: "Failed to edit Vessel",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		default: {
			next(action);
		}
	}
};
export default onboardVesselMiddleware;
