import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import userActions from "redux/app/user/actions";
import userSelectors from "redux/app/user/selectors";

type OwnProps = {};
type UserProfileProps = PropsFromRedux & OwnProps;
function UserProfile(props: UserProfileProps) {
	const { userProfileDetails } = props;

	useEffect(() => {
		props.loadUserDetails();
	}, []);

	return (
		<div className="bg-white m-2 p-2 rounded-lg  h-[calc(100vh-98px)]">
			<div className="w-full flex justify-center">
				{userProfileDetails && (
					<div className="min-w-72 md:min-w-80 mt-11 shadow p-2 rounded-lg f">
						<div className="flex justify-center mb-8">
							<div className="bg-lightBlue w-[100px] h-[100px] rounded-full flex items-center justify-center font-semibold text-lg">
								{userProfileDetails?.name
									?.split(" ")
									.slice(0, 2)
									.map((v) => v.charAt(0).toUpperCase())
									.join("")}
							</div>
						</div>
						<div className="ml-8 flex flex-col space-y-4">
							<div>
								<h4 className="ws-input__label text-base">Name </h4>
								<p className="text-lg">{userProfileDetails?.name}</p>
							</div>

							<div>
								<h4 className="ws-input__label text-base">Email </h4>
								<p className="text-lg">{userProfileDetails?.email}</p>
							</div>

							<div>
								<h4 className="ws-input__label text-base">Designation </h4>
								<p className="text-lg">{userProfileDetails?.roleCategory}</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const userStore = userSelectors._getStore(store);

	const userProfileDetails = userSelectors.getUserProfileDetails(userStore);
	return {
		userProfileDetails,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadUserDetails() {
			dispatch(userActions.commands.userDetailsLoad());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(UserProfile);
