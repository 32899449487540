import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupD4 } from "../../../../../redux/app/annex6/groups/group-d";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import CommaSeparator from "components/_common/CommaSeparator";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupD4PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupD4Preview(props: Annex6GroupD4PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupD4;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "D", 8.1, renderItem8P1()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem8P1(): React.ReactNode {
		if (!record.item8P1.equipmentsUsed || !record.item8P1.changingFrom)
			return <Annex6OldVersionPreview />;

		return (
			<>
				{moment(record.selectedDate, "YYYY-MM-DD").format("DD-MMM-YYYY")} AT{" "}
				{record.item8P1.time} LT COMMENCE CHANGEOVER OF{" "}
				<CommaSeparator
					items={record.item8P1.equipmentsUsed.map((equipment) =>
						equipment.split("_").join(" ")
					)}
					lastItemSeperator={" & "}
				/>{" "}
				FROM {record.item8P1.changingFrom.fuelType} (
				{record.item8P1.changingFrom.sulphurContent}% S) TO{" "}
				{record.item8P1.changingTo.fuelType}(
				{record.item8P1.changingTo.sulphurContent}% S), <br />
				POSITION: {record.item8P1.position.latitude},{" "}
				{record.item8P1.position.longitude}
			</>
		);
	}

	return renderMain();
}

export default Annex6GroupD4Preview;
