import React from "react";

type SVGExcelProps = { className?: string };
const SVGExcel: React.FC<SVGExcelProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 50 50"
		>
			<path d="M28.875 0a.466.466 0 00-.063.031l-28 5.313a1 1 0 00-.812 1v37.312a1 1 0 00.813 1l28 5.313c.289.054.59-.02.82-.207.226-.188.363-.465.367-.762v-5h17c1.094 0 2-.906 2-2V8c0-1.094-.906-2-2-2H30V1a1.004 1.004 0 00-1.125-1zM28 2.188V6.53a1.01 1.01 0 000 .875v35.407a1.016 1.016 0 000 .406v4.593L2 42.844V7.156zM30 8h17v34H30v-5h4v-2h-4v-6h4v-2h-4v-5h4v-2h-4v-5h4v-2h-4zm6 5v2h8v-2zM6.687 15.688l5.47 9.343-5.97 9.344h5l3.25-6.031c.227-.582.376-1.028.438-1.313h.031c.13.61.254 1.024.375 1.25l3.25 6.094H23.5l-5.75-9.438 5.594-9.25h-4.688l-2.968 5.532a20.942 20.942 0 00-.594 1.656h-.031a9.492 9.492 0 00-.563-1.594l-2.688-5.593zM36 20v2h8v-2zm0 7v2h8v-2zm0 8v2h8v-2z"></path>
		</svg>
	);
};

export default SVGExcel;
