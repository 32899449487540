import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { rootRoutes } from "../routes";
import Root from "../components/Root";
import ScreenApp from "./app";
import ScreenAuth from "./auth";
import ExtScreen from './ext-forms';
import LoginExt from './ext-forms/login';

const routes = rootRoutes.children;
type ScreenRootType = {};
const ScreenRoot: React.FC<ScreenRootType> = (props: ScreenRootType) => (
	<BrowserRouter>
		<Root />
		<Switch>
			<Route path={routes.auth.path} component={ScreenAuth} />
			<Route path={routes.app.path} component={ScreenApp} />
			<Route path={routes.ext.path} component={ExtScreen} />
			<Route exact={true} path="/" render={RedirectComponent} />
		</Switch>
	</BrowserRouter>
);

function RedirectComponent() {
	return <Redirect to={routes.app.children.dashboard.children.list.url()} />;
}
export default ScreenRoot;
