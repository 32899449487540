import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import {
	EORB4Item57MethodOfTransferOrDisposal,
	TORB4Item57Tank,
	TORB4J1,
} from "../../../../../redux/app/orb4/groups/orb4-j";
import AttachmentsPreview from "../../_elements/AttachmentsPreview";
import ORB4TableRows from "../ORB4TableRows";
import CommaSeparator from "components/_common/CommaSeparator";
import { commonUtils } from "redux/_common";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4J1PreviewProps = OwnProps;
function ORB4J1Preview(props: ORB4J1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4J1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "J", 55, renderItem55()],
					[null, null, 56, renderItem56()],
					[null, null, getItem57Number(), renderItem57()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem55() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item57.methodOfTransferOrDisposal ===
				EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO
					? "FROM "
					: null}
				{orb4Record.item55.slopTanks.map(
					(tank) => `${tank.selectedTank.tankName}, `
				)}
			</h4>
		);
	}

	function getItem57Number() {
		switch (orb4Record.item57.methodOfTransferOrDisposal) {
			case EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES:
				return 57.1;
			case EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO:
				return 57.2;
			case EORB4Item57MethodOfTransferOrDisposal.TRANSFERRED_TO_OR_FROM_TANKS:
				return 57.3;
			case EORB4Item57MethodOfTransferOrDisposal.OTHER_METHOD:
				return 57.4;
			case EORB4Item57MethodOfTransferOrDisposal.DISPOSAL_TO_SLOP_BARGE:
				return 57.1;
		}
	}
	function getTankNames(tanks: TORB4Item57Tank[]) {
		let tankListAndRetainedQty: string[] = [];
		tanks.forEach((tank: TORB4Item57Tank) => {
			tankListAndRetainedQty.push(
				`TRANSFERRED TO ${tank.selectedTank.tankCode} / QUANTITY TRANSFERRED ${tank.transferQuantity} M³, TOTAL QUANTITY IN ${tank.selectedTank.tankCode} - ${tank.selectedTank.observedQuantity.postOperation} M³`
			);
		});
		return {
			tankListAndRetainedQty: tankListAndRetainedQty.join(","),
		};
	}

	function renderItem56() {
		switch (orb4Record.item57.methodOfTransferOrDisposal) {
			case EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO:
				return (
					<>
						{orb4Record.item55.slopTanks.map((tank, cargoTankIndex) => {
							const tankName = tank.selectedTank.tankName;
							const transferredQuantity = tank.dischargedQuantity;
							const retainedQuantity = tank.newRetainedQuantity;
							const adjustedRetainedQty =
								retainedQuantity < 0 ? 0 : retainedQuantity;
							return (
								<React.Fragment key={cargoTankIndex}>
									{tankName} - {transferredQuantity.toFixed(2)} M³ SOLID RESIDUE
									REMOVED, RETAINED IN TANK {adjustedRetainedQty.toFixed(2)} M³{" "}
									<br />
								</React.Fragment>
							);
						})}
					</>
				);
			case EORB4Item57MethodOfTransferOrDisposal.DISPOSAL_TO_SLOP_BARGE:
				return (
					<CommaSeparator
						items={orb4Record.item55.slopTanks.map((tank) => {
							const tankName = tank.selectedTank.tankName;
							const transferredQuantity = tank.dischargedQuantity;
							const retainedQuantity = tank.newRetainedQuantity;
							const adjustedRetainedQty =
								retainedQuantity < 0 ? 0 : retainedQuantity;
							return `${tankName}:
						${transferredQuantity.toFixed(2)} M³ DISPOSED OFF;
						${adjustedRetainedQty.toFixed(2)} M³ RETAINED`;
						})}
						customSeperator={<br />}
					/>
				);

			case EORB4Item57MethodOfTransferOrDisposal.TRANSFERRED_TO_OR_FROM_TANKS:
				return (
					<>
						<CommaSeparator
							items={orb4Record.item55.slopTanks.map((tank) => {
								const transferredQuantity = tank.dischargedQuantity;
								const tankName = tank.selectedTank.tankName;
								return `${tankName}:
								${transferredQuantity.toFixed(2)} M³ TRANSFERRED;
								${tank.newRetainedQuantity.toFixed(2)} M³ RETAINED`;
							})}
							customSeperator={<br />}
						/>
					</>
				);
			case EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES:
			case EORB4Item57MethodOfTransferOrDisposal.OTHER_METHOD:
				return (
					<CommaSeparator
						items={orb4Record.item55.slopTanks.map((tank) => {
							const transferredQuantity = tank.dischargedQuantity;
							const retainedQuantity = tank.newRetainedQuantity;
							const tankName = tank.selectedTank.tankName;
							const adjustedRetainedQty =
								retainedQuantity < 0 ? 0 : retainedQuantity;
							return `${tankName}:
								${transferredQuantity.toFixed(2)} M³ TRANSFERRED;
								${adjustedRetainedQty.toFixed(2)} M³ RETAINED`;
						})}
						customSeperator={<br />}
					/>
				);
		}
	}

	function renderItem57() {
		const item57 = orb4Record.item57;
		switch (item57.methodOfTransferOrDisposal) {
			case EORB4Item57MethodOfTransferOrDisposal.RECEPTION_FACILITIES:
				return (
					<>
						TO {item57.collectedByWhom} AT {item57.placeOfDischarge}
						,
						<br />
						QUANTITY TRANSFERRED: {item57.totalQuantityDischarged.toFixed(2)}
						M³
						<AttachmentsPreview
							{...item57}
							_rev={orb4Record._rev}
							id={orb4Record.id}
						/>
					</>
				);
			case EORB4Item57MethodOfTransferOrDisposal.MIXED_WITH_CARGO: {
				return (
					<>
						{item57.cargoTanks.map((cargoTank) => {
							const totalQuantityInTank = commonUtils.addUp(
								cargoTank.selectedTank.expectedQuantity,
								cargoTank.transferQuantity
							);
							return (
								<React.Fragment key={cargoTank.selectedTank.id}>
									Transferred to {cargoTank.selectedTank.tankName}, quantity
									transferred {cargoTank.transferQuantity.toFixed(2)}
									M³, Total quantity in {cargoTank.selectedTank.tankName}{" "}
									{totalQuantityInTank.toFixed(2)}
									M³
									<br />
								</React.Fragment>
							);
						})}
					</>
				);
			}

			case EORB4Item57MethodOfTransferOrDisposal.DISPOSAL_TO_SLOP_BARGE:
				return (
					<>
						TO SLOP BARGE "{item57.nameOfTheBarge}" AT {item57.placeOfDischarge}
						,
						<br />
						QUANTITY TRANSFERRED: {item57.totalQuantityDischarged.toFixed(2)}
						M³
						<AttachmentsPreview
							{...item57}
							_rev={orb4Record._rev}
							id={orb4Record.id}
						/>
					</>
				);
			case EORB4Item57MethodOfTransferOrDisposal.TRANSFERRED_TO_OR_FROM_TANKS:
				return <>{getTankNames(item57.tanks).tankListAndRetainedQty}</>;
			case EORB4Item57MethodOfTransferOrDisposal.OTHER_METHOD:
				return (
					<>
						{item57.methodDescription}, QUANTITY TRANSFERRED:{" "}
						{item57.totalQuantityDischarged.toFixed(2)} M³
					</>
				);
		}
	}

	return renderMain();
}

export default ORB4J1Preview;
