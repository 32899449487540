import {
	IForm,
	IFormErrorFormat,
	IFormErrorFormatTemp,
	IFormFieldsFormat,
	IFormHelpMessagesFormat,
	IFormValidityFormat,
} from "./types";

const getDefaults = <
	IFormFields extends IFormFieldsFormat,
	IFieldErrors extends IFormErrorFormat
>(
	state: Partial<IForm<IFormFields>> &
		Required<{ fields: IFormFields; mode: "CREATE" | "EDIT" | "NONE" }>
): IForm<IFormFields> => {
	// Setting up default values for errors, warnings and helpMessages
	const errors: IFormErrorFormat = {
		...state.errors,
		GLOBAL: {
			isValid: true,
			message: "",
		},
	};
	const warnings: IFormErrorFormat = {
		...state.warnings,
		GLOBAL: {
			isValid: true,
			message: "",
		},
	};
	const helpMessages: IFormHelpMessagesFormat = {
		...state.helpMessages,
	};

	Object.keys(state.fields).map((key) => {
		errors[key] = {
			isValid: true,
			message: "",
		};
		warnings[key] = {
			isValid: true,
			message: "",
		};
		helpMessages[key] = "";
	});

	const stateWithDefaults: IForm<any, any, any, any> = {
		...state,
		errors: {
			...errors,
			...state.errors,
		},
		warnings: {
			...warnings,
			...state.warnings,
		},
		helpMessages: {
			...helpMessages,
			...state.helpMessages,
		},
	};
	return stateWithDefaults;
};

function getErrorsObject(
	errors: { field: string; error: IFormValidityFormat }[],
	globalError: IFormValidityFormat = {
		isValid: false,
		message: "Form has Errors",
	}
) {
	return errors.reduce(
		(result: any, item, index, array) => {
			result[item.field] = {
				isValid: item.error.isValid,
				message: item.error.message,
			};
			return result;
		},
		{
			GLOBAL: globalError,
		}
	);
}
const formUtils = {
	getDefaults,
	getErrorsObject,
};

export default formUtils;
