import Button from "@set-product/core/button/Button";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { vesselActions, vesselSelectors } from "redux/app/vessel";
import { IStore } from "redux/store";

type OwnProps = {};
type AddFleetProps = PropsFromRedux & OwnProps;
function AddFleet(props: AddFleetProps) {
	const { value, jobToCreateFleet } = props;

	return (
		<div className="bg-white rounded-lg p-10">
			<h3 className="text-2xl font-bold">New fleet</h3>
			<h4 className="font-md my-2">Name</h4>
			<input
				className=" appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none  focus:border-gray-600"
				id="fleetName"
				type="text"
				placeholder="fleet name"
				value={value}
				onChange={(e) => {
					const fleetName = e.target.value;
					props.handleChange(fleetName);
				}}
			/>
			<div className="mt-6 space-x-2">
				<Button
					text="Add"
					onClick={() => {
						if (value && !jobToCreateFleet.isLoading) {
							props.createFleet();
						}
					}}
					disabled={jobToCreateFleet.isLoading}
					view="filled"
					type="submit"
					color="primary"
					className="px-10 mr-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
				/>
				<Button
					text="Cancel"
					view="smooth"
					className="mx-2 font-medium "
					onClick={() => props.closeModal()}
				/>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = store.app.vesselStore;
	return {
		value: vesselStore.fleetName,
		jobToCreateFleet: vesselSelectors.jobToCreateFleet(vesselStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		handleChange(fleetName: string) {
			dispatch(vesselActions.fleetNameSet(fleetName));
		},
		closeModal() {
			dispatch(vesselActions.createFleetModalToggle());
		},
		createFleet() {
			dispatch(vesselActions.createFleet());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(AddFleet);
