import moment from "moment";
import { commonUtils } from "../../_common";
import { marpolUtils } from "../marpol";
import { TMARPOLTank, TMARPOLTankWithObservedQuantity } from "../marpol/@types";
import {
	EBallastOperationCodes,
	EBallastRecordCode,
	EBallastRecordState,
	TBallastOperationAlert,
	TBallastRecord,
	TBallastRecordUser,
} from "./@types";
import { TBallast_Ballast } from "./groups/ballast";
import { TBallast_Deballast } from "./groups/deballast";
import ballastDefaults from "./groups/defaults";
import { TBallast_Exchange } from "./groups/exchange";
import { TBallast_Internal } from "./groups/internal";

const ballastUtils = {
	getDefaultDataForGroupOperation(
		recordCode: EBallastRecordCode,
		operationCode: EBallastOperationCodes
	): TBallastRecord {
		return ballastDefaults[operationCode];
	},

	getRequiredAlert(alerts: TBallastOperationAlert[]) {
		return alerts.reduce<TBallastOperationAlert | undefined>(
			(result, currentAlert) => {
				if (currentAlert.type === "REQUIRED_FIELD") result = currentAlert;
				return result;
			},
			undefined
		);
	},
	getErrorAlerts(alerts: TBallastOperationAlert[]) {
		return alerts.reduce<TBallastOperationAlert[]>((result, currentAlert) => {
			if (currentAlert.type === "ERROR") return [...result, currentAlert];
			return result;
		}, []);
	},
	getProcessedTankAlerts(alerts: TBallastOperationAlert[]) {
		return alerts.reduce<TBallastOperationAlert[]>((result, currentAlert) => {
			if (currentAlert.type === "OBSERVED_QUANTITY_ALERT")
				return [...result, currentAlert];
			return result;
		}, []);
	},
	getIdsWithErrorAlerts(alerts: TBallastOperationAlert[]): string[] {
		const ids: string[][] = alerts.map((alert) => {
			let listItemIds: string[] = [];
			const actionId: string = alert.action ? alert.action.link : "";
			if (
				(alert.type === "ERROR" || alert.type === "REQUIRED_FIELD") &&
				alert.listOfItems
			)
				listItemIds = alert.listOfItems.map((item) => `${item.link}`);
			return [...listItemIds, actionId];
		});
		return ids.reduce((result, currentIdList) => {
			return [...result, ...currentIdList];
		}, []);
	},

	isBallastRecordPresentAsPerState(ballastRecordState?: EBallastRecordState) {
		if (!ballastRecordState) return false;
		return (
			ballastRecordState === EBallastRecordState.SUBMITTED ||
			ballastRecordState === EBallastRecordState.VERIFIED ||
			ballastRecordState === EBallastRecordState.APPROVED
		);
	},
	getOperationalRetainedQuantity<
		T extends {
			isDifferenceInQtyAcknowledged: boolean;
			selectedTank: TMARPOLTank;
		}
	>(tankItem: T) {
		return tankItem.isDifferenceInQtyAcknowledged
			? tankItem.selectedTank.observedQuantity.preOperation
			: tankItem.selectedTank.expectedQuantity;
	},
};

export default ballastUtils;
