import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import {
	EORB1Item12P4DisposalMethod,
	TORB1GroupC6,
} from "../../../../../redux/app/orb1/groups/group-c";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupC6PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupC6Preview(props: ORB1GroupC6PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupC6;
	if (orb1Record.item12P4.disposalOfOilResidues.length === 0) return null;
	const sourceSludgeTank = orb1Record.item12P4.disposalOfOilResidues[0];

	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[
						operationDate,
						"C",
						renderItemNumberOnDisposalMethod(),
						renderMainTDOnDisposalMethod(),
					],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderMainTDOnDisposalMethod() {
		switch (orb1Record.item12P4.disposalMethod) {
			case EORB1Item12P4DisposalMethod.BOILER:
			case EORB1Item12P4DisposalMethod.REGENERATION:
			case EORB1Item12P4DisposalMethod.OTHER_METHOD:
				return (
					<h4 className="font-medium uppercase">
						{sourceSludgeTank.transferQuantity.toFixed(2)} M³ DISPOSED FROM{" "}
						{sourceSludgeTank.selectedTank.tankName}
						<br />
						QUANTITY RETAINED IN TANK ={" "}
						{(
							marpolUtils.getQuantityForCalculation(
								sourceSludgeTank.selectedTank
							) - sourceSludgeTank.transferQuantity
						).toFixed(2)}{" "}
						M³
					</h4>
				);
			case EORB1Item12P4DisposalMethod.INCINERATOR:
				return (
					<h4 className="font-medium uppercase">
						{sourceSludgeTank.transferQuantity.toFixed(2)} M³ FROM{" "}
						{sourceSludgeTank.selectedTank.tankName} INCINERATED
						<br />
						{`Total time of incineration ${
							orb1Record.item12P4.totalTimeOfOperation
								? orb1Record.item12P4.totalTimeOfOperation
								: 0
						} HOURS`}
						<br />
						QUANTITY RETAINED IN TANK ={" "}
						{(
							marpolUtils.getQuantityForCalculation(
								sourceSludgeTank.selectedTank
							) - sourceSludgeTank.transferQuantity
						).toFixed(2)}{" "}
						M³
					</h4>
				);
			case EORB1Item12P4DisposalMethod.WATER_EVAPORATION:
				return (
					<h4 className="font-medium uppercase">
						{sourceSludgeTank.transferQuantity.toFixed(2)} M³ WATER EVAPORATED
						FROM {sourceSludgeTank.selectedTank.tankName}
						<br />
						QUANTITY RETAINED IN TANK ={" "}
						{(
							marpolUtils.getQuantityForCalculation(
								sourceSludgeTank.selectedTank
							) - sourceSludgeTank.transferQuantity
						).toFixed(2)}{" "}
						M³
					</h4>
				);
			case EORB1Item12P4DisposalMethod.SLOP_TANK:
				return (
					<h4 className="font-medium uppercase">
						{sourceSludgeTank.transferQuantity.toFixed(2)} M³ OF SLUDGE, FROM{" "}
						{sourceSludgeTank.selectedTank.tankName}
						<br />
						TRANSFERRED TO {orb1Record.item12P4.slopTank.tankName}
						<br />
						<strong>
							STARTED AT {orb1Record.item12P4.startTime} HRS, FINISHED AT{" "}
							{orb1Record.item12P4.stopTime}HRS
							<br />
						</strong>
						TOTAL QUANTITY RETAINED IN {
							orb1Record.item12P4.slopTank.tankName
						}={" "}
						{/* {(
                            marpolUtils.getQuantityForCalculation(
                                orb1Record.item12P4.slopTank
                            ) + sourceSludgeTank.transferQuantity
                        ).toFixed(2)} */}
						{orb1Record.item12P4.slopTank.observedQuantity.postOperation} M³,
						<br />
						TOTAL QUANTITY RETAINED IN {
							sourceSludgeTank.selectedTank.tankName
						}{" "}
						={" "}
						{(
							marpolUtils.getQuantityForCalculation(
								sourceSludgeTank.selectedTank
							) - sourceSludgeTank.transferQuantity
						).toFixed(2)}
						{/* {
                            sourceSludgeTank.selectedTank.observedQuantity
                                .postOperation
                        }{' '} */}
						M³
					</h4>
				);
			case EORB1Item12P4DisposalMethod.SHORE_DISPOSAL:
				return (
					<h4 className="font-medium uppercase">
						{sourceSludgeTank.transferQuantity.toFixed(2)} M³ OF SOLID SLUDGE
						RESIDUE REMOVED FROM {sourceSludgeTank.selectedTank.tankName}
						<br />
						QUANTITY RETAINED IN TANK ={" "}
						{(
							marpolUtils.getQuantityForCalculation(
								sourceSludgeTank.selectedTank
							) - sourceSludgeTank.transferQuantity
						).toFixed(2)}{" "}
						M³.
						<br />
						SOLID SLUDGE RESIDUE STORED IN {orb1Record.item12P4.storedIn} FOR
						SHORE DISPOSAL
					</h4>
				);
		}
	}

	function renderItemNumberOnDisposalMethod() {
		switch (orb1Record.item12P4.disposalMethod) {
			case EORB1Item12P4DisposalMethod.BOILER:
			case EORB1Item12P4DisposalMethod.REGENERATION:
			case EORB1Item12P4DisposalMethod.OTHER_METHOD:
			case EORB1Item12P4DisposalMethod.WATER_EVAPORATION:
			case EORB1Item12P4DisposalMethod.SLOP_TANK:
			case EORB1Item12P4DisposalMethod.SHORE_DISPOSAL:
				return <div className="h-full font-medium">12.4</div>;
			case EORB1Item12P4DisposalMethod.INCINERATOR:
				return <div className="h-full font-medium">12.3</div>;
		}
	}

	return renderMain();
}

export default ORB1GroupC6Preview;
