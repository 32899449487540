import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Dispatch } from "redux";
import { marpolActions, marpolSelectors } from "../../../../redux/app/marpol";
import { vesselActions, vesselSelectors } from "../../../../redux/app/vessel";
import { IStore } from "../../../../redux/store";
import MARPOLFilter from "../_elements/MARPOLFilter";
// import ORB1Docs from "./ORB1Docs";
// import MARPOLVesselSelector from "./MARPOLVesselSelector";
import MARPOLDocManager from "./MARPOLDocManager";
import { EMARPOLDocuments, TMARPOLFilters } from "redux/app/marpol/@types";

type OwnProps = {
	vesselId?: string;
};
type MarpolListProps = OwnProps & PropsFromRedux;
function MarpolList(props: MarpolListProps) {
	const {
		isVesselSelected,
		paginationOfMARPOL,
		jobToLoadDocs,
		vesselId,
		filtersForMarpol,
		vessel,
	} = props;
	const printElementRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => printElementRef.current,
	});

	useEffect(() => {
		if (vesselId) {
			props.filtersEdit({
				...filtersForMarpol,
				vesselId,
				documentType: EMARPOLDocuments.ORB1,
			});
			props.loadDocs();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId]);

	// useEffect(() => {
	// 	if (isVesselSelected) {
	// 		props.loadDocs();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isVesselSelected]);

	return (
		<div className="min-h-full px-12 mt-6 bg-white rounded-md">
			<div
				className={classNames(
					"flex flex-row items-end justify-between  my-3  w-full"
				)}
			>
				<div className="flex flex-col justify-between w-full">
					<div className="relative flex flex-row justify-start justify-between w-full">
						<div className="w-1/2 -mx-6">
							<MARPOLFilter onPrint={() => {}} disabled={!isVesselSelected} />
						</div>
						<div className="flex flex-row flex-col items-center justify-center pt-1">
							<button className="p-3 px-6 py-1 mt-6 text-base text-indigo-700 border border-indigo-600 rounded-sm outline-none ws-button-og">
								<span
									className="mr-3 font-medium"
									onClick={() => handlePrint && handlePrint()}
								>
									Print
								</span>
								<span className="mb-0.5 text-sm bp3-icon bp3-icon-print"></span>
							</button>
						</div>
					</div>
				</div>
			</div>
			{isVesselSelected && jobToLoadDocs.isLoading ? (
				<div className="flex flex-col flex-1 max-h-full my-3 mb-2">
					<div className="flex flex-row w-full max-h-full overflow-y-auto ws-scroll">
						<div className="flex-1 ">
							<h1 className="p-6 text-sm font-medium border border-gray-200 rounded-sm">
								Loading records...
							</h1>
						</div>
					</div>
				</div>
			) : (
				<div ref={printElementRef}>
					<div className="relative h-full max-h-full overflow-y-auto">
						<MARPOLDocManager />
					</div>
				</div>
			)}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const marpolStore = marpolSelectors._getStore(store);
	const vesselStore = vesselSelectors._getStore(store);
	const vesselId = ownProps?.vesselId;
	const vessel = vesselId
		? vesselSelectors.getVesselById(
				vesselId,
				vesselSelectors.getVessels(vesselStore)
		  )
		: null;
	return {
		isVesselSelected: marpolSelectors.isVesselSelected(marpolStore),
		paginationOfMARPOL: marpolSelectors.getPagination(marpolStore),
		jobToLoadDocs: marpolSelectors.jobToLoadDocs(marpolStore),
		filtersForMarpol: marpolStore.filters,
		vessel,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		loadDocs() {
			dispatch(marpolActions.commands.marpolDocsLoad());
		},
		filtersEdit(filters: TMARPOLFilters) {
			dispatch(marpolActions.document.marpolFiltersSet(filters));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(MarpolList);