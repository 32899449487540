import React from "react";
import MarpolList from "../../../../components/app/marpol/list/List";
type ScreenMarpolListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;
function ScreenMarpolList(props: ScreenMarpolListProps) {
	return <MarpolList />;
}

export default ScreenMarpolList;
