import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { vesselOnboardActions } from "../../../../../../redux/app/vessel/onboard";
import { deviceFormDefaults } from "../../../../../../redux/app/vessel/onboard/reducers.state";
import Form from "./Form";

type DeviceEditType = PropsFromRedux & {
	deviceId: string;
	onCancel(): void;
	onClose(): void;
};
function DeviceEdit(props: DeviceEditType) {
	const { deviceId } = props;
	useEffect(
		function onLoad() {
			props.setFormModeEdit();
			props.loadDeviceToEdit(deviceId);
		},
		[deviceId]
	);
	return (
		<Form
			onCancel={props.onCancel}
			onClose={props.onClose}
			title="Edit Device"
			subtitle="Keep the device open with Wayship app while you are editing"
		/>
	);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadDeviceToEdit(deviceId: string) {
			dispatch(
				vesselOnboardActions._forms.device.formFieldsSet(deviceFormDefaults)
			);
			dispatch(vesselOnboardActions.command.deviceLoad(deviceId));
		},
		setFormModeEdit() {
			dispatch(vesselOnboardActions._forms.device.formModeSet("EDIT"));
		},
	};
}
const reduxConnector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(DeviceEdit);
