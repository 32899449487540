import { createReducer } from "typesafe-actions";
import { IDasboardActions_Document, IDashboardStore } from "./@types";
import dashboardActions from "./actions";
import withJobs from "redux/_core/job/reducers";
import dashboardConstants from "./constants";

const { ITEMS_PER_PAGE } = dashboardConstants;

const initialState: IDashboardStore = {
	_jobs: {},
	viewType: "map",
	isDashboard: false,
	// for my-vessels
	_pagination: {
		currentPage: 1,
		itemsPerPage: ITEMS_PER_PAGE,
		totalItems: 0,
	},
	_paginationAll: {
		currentPage: 1,
		itemsPerPage: ITEMS_PER_PAGE,
		totalItems: 0,
	},
};

const dashboardReducers = createReducer<
	IDashboardStore,
	IDasboardActions_Document
>(initialState)
	.handleAction(
		dashboardActions.document.dashboardViewTypeSet,
		function dashboardViewTypeSet(state, action) {
			const viewType = action.payload.viewType;
			return {
				...state,
				viewType,
			};
		}
	)
	.handleAction(
		dashboardActions.document.isDashboardPageSet,
		function dashboardViewTypeSet(state, action) {
			const isDashboard = action.payload.isDashboard;
			return {
				...state,
				isDashboard,
			};
		}
	)
	.handleAction(
		dashboardActions.document.paginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		dashboardActions.document.paginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		dashboardActions.document.paginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		dashboardActions.document.paginationAllVesselsTotalItemsSet,
		function paginationAllVesselsTotalItemsSet(state, action) {
			return {
				...state,
				_paginationAll: {
					...state._paginationAll,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		dashboardActions.document.paginationAllVesselsItemsPerPageSet,
		function paginationAllVesselsItemsPerPageSet(state, action) {
			return {
				...state,
				_paginationAll: {
					...state._paginationAll,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		dashboardActions.document.paginationAllVesselsCurrentPageSet,
		function paginationAllVesselsCurrentPageSet(state, action) {
			return {
				...state,
				_paginationAll: {
					...state._paginationAll,
					currentPage: action.payload.currentPage,
				},
			};
		}
	);

export default withJobs(
	dashboardConstants.FEATURE,
	initialState,
	// @ts-ignore
	dashboardReducers
);
