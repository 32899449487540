import { IListObjectStore } from "../../_common/type";
import { IJobHOCState } from "../../_core/job/types";
import {
	EMARPOLDocuments,
	TMARPOLProcessedTank,
	TMARPOLTank,
} from "../marpol/@types";
import { TAnnex6GroupA } from "./groups/group-a";
import { TAnnex6GroupB } from "./groups/group-b";
import { TAnnex6GroupC } from "./groups/group-c";
import { TAnnex6GroupD } from "./groups/group-d";
import { TAnnex6GroupE } from "./groups/group-e";
import { TAnnex6GroupF } from "./groups/group-f";
import { TAnnex6GroupG } from "./groups/group-g";
import { TAnnex6GroupH } from "./groups/group-h";
import { TAnnex6GroupI } from "./groups/group-i";
import { TAnnex6GroupJ } from "./groups/group-j";

export enum EAnnex6OperationState {
	RECORD = "RECORD",
	PREVIEW = "PREVIEW",
}

export enum EAnnex6RecordState {
	APPROVED = "APPROVED",
	STRIKED_OFF = "STRIKED-OFF",
	SUBMITTED = "SUBMITTED",
	IDLE = "IDLE",
	EDIT = "EDIT",
	VERIFIED = "VERIFIED",
}

export type TAnnex6RecordUser = {
	name: string;
	designation: string;
	staffId: string;
	date: string;
};

export type TAnnex6CommonFields = {
	selectedDate: string;
	remarks: string;
};

export type TAnnex6CommonData = {
	id: string;
	/**
	 * @description It's an ID for latest update for a document with given id.
	 */
	_rev: string;
	marpolType: EMARPOLDocuments.ANNEX6;
	state: EAnnex6RecordState;
	submittedBy?: TAnnex6RecordUser;
	approvedBy?: TAnnex6RecordUser;
	verifiedBy?: TAnnex6RecordUser;
	strikedBy?: TAnnex6RecordUser;
	strikeOffReason?: string;
	editVersions?: TAnnex6BackendRecord[];
	dateOfApprovedRecordEdit?: string;
};

export type TAnnex6ObservedQuantity = {
	preOperation: number;
	postOperation: number;
};

export enum EAnnex6RecordCode {
	Annex6A1 = "Annex6-A1",
	Annex6A2 = "Annex6-A2",

	Annex6B1 = "Annex6-B1",
	Annex6B2 = "Annex6-B2",

	Annex6C1 = "Annex6-C1",

	Annex6D1 = "Annex6-D1",
	Annex6D2 = "Annex6-D2",
	Annex6D3 = "Annex6-D3",
	Annex6D4 = "Annex6-D4",
	Annex6D5 = "Annex6-D5",
	Annex6D6 = "Annex6-D6",

	Annex6E1 = "Annex6-E1",
	Annex6E2 = "Annex6-E2",

	Annex6F1 = "Annex6-F1",
	Annex6F2 = "Annex6-F2",
	Annex6F3 = "Annex6-F3",

	Annex6G1 = "Annex6-G1",
	Annex6G2 = "Annex6-G2",
	Annex6G3 = "Annex6-G3",

	Annex6H1 = "Annex6-H1",

	Annex6I1 = "Annex6-I1",
	Annex6I2 = "Annex6-I2",
	Annex6I3 = "Annex6-I3",
	Annex6I4 = "Annex6-I4",

	Annex6J1 = "Annex6-J1",
	Annex6J6 = "Annex6-J6",
}

export type TAnnex6Record =
	| TAnnex6GroupA
	| TAnnex6GroupB
	| TAnnex6GroupC
	| TAnnex6GroupD
	| TAnnex6GroupE
	| TAnnex6GroupF
	| TAnnex6GroupG
	| TAnnex6GroupH
	| TAnnex6GroupI
	| TAnnex6GroupJ;

export type TAnnex6CurrentOperationAlert = {
	id: string;
	type:
		| "WARNING"
		| "ERROR"
		| "INFO"
		| "REQUIRED_FIELD"
		| "OBSERVED_QUANTITY_ALERT";
	title: string;
	subtitle: string;
	message: string;
	listOfItems?: {
		title: string;
		subtitle?: string;
		message?: string;
		link?: string;
	}[];
	action?: {
		title: string;
		link: string;
	};
};

export type TAnnex6BackendRecord = TAnnex6Record & {
	submittedBy: TAnnex6RecordUser;
	approvedBy?: TAnnex6RecordUser;
	verifiedBy?: TAnnex6RecordUser;
	strikedBy?: TAnnex6RecordUser;
	processedTanks: TAnnex6ProcessedTank[];
	nonParticipantTanks: { id: string; expectedQuantity: number }[];
};

export type TAnnex6ProcessedTank = TMARPOLProcessedTank;
export type TAnnex6CurrentOperation = {
	recordCode: EAnnex6RecordCode;
	operationState: EAnnex6OperationState;
	formData: TAnnex6Record;
	validationAlerts: IListObjectStore<TAnnex6CurrentOperationAlert>;
	processedTanks: IListObjectStore<TAnnex6ProcessedTank>;
};

export type TAnnex6HistoryFilters = {
	startDate: any;
	endDate: any;
	shownRecords: "ALL" | "ALL-PENDING";
};

export type TAnnex6WeekReport = {};
export interface IAnnex6Store extends IJobHOCState {
	records: IListObjectStore<TAnnex6BackendRecord>;
	currentOperation?: TAnnex6CurrentOperation;
	filter: TAnnex6HistoryFilters;
	currentWeekReport: TAnnex6WeekReport;
	previousWeekReport: TAnnex6WeekReport;
	bdnReports?: TAnnex6BunkerDeliveryNote[];
}

export type TAnnex6BunkerDeliveryNote = {
	id: string;
	_id: string;
	_rev: string;
	attachmentSourceId: string;
	attachmentSourceRev: string;
	state: EAnnex6OperationState;
	marpolType: EMARPOLDocuments.BDN;
	bunkerDeliveryNoteNo: string;
	sealNumber: string;
	fuelType: string;
	tanks: {
		selectedTank: TMARPOLTank;
		addedQuantityInMT: number;
		totalRetainedInMT: number;
	}[];

	placeOrPosition:
		| {
				place: "IN_PORT";
				portName: string;
		  }
		| {
				place: "AT_SEA";
				latitude: string;
				longitude: string;
		  };
	totalBunkeredQty: number;
	remarks: string;
	selectedDate: string;
	dependentIds: any[];
	disposalStatus: {
		isSampleDisposed: boolean;
		sampleDisposedDate: string;
	};
	bdnAttachmentLink: string;
};
