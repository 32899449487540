import React from "react";

import { ITableHeader } from "redux/app/tool/@types";
import Th from "./Th";
import InfiniteProgress from "components/_common/InfiniteProgress";
import { IStore } from "redux/store";
import { Dispatch } from "redux";
import { ConnectedProps, connect } from "react-redux";

type OwnProps = {
	headers: ITableHeader[];
	children: React.ReactNode;
	isEmpty: boolean;
	isLoading?: boolean;
};
function Table(props: OwnProps & PropsFromRedux) {
	const { headers, children, isEmpty, isLoading = false } = props;
	return (
		<>
			<InfiniteProgress isLoading={isLoading} isSpacedOut={false} />
			<table className="w-full overflow-auto border border-collapse border-gray-400 rounded-md table-auto font-redhat-text">
				<thead className="">
					<tr>
						{headers.map((headerItem) => {
							const { className, name } = headerItem;
							return <Th key={name} value={name} className={className} />;
						})}
					</tr>
				</thead>
				<tbody>
					{isEmpty ? (
						<tr>
							<td
								className=" border-gray-100 font-medium"
								colSpan={headers.length}
							>
								<div className="py-20 flex justify-center items-center text-center">
									<span className="text-base text-gray-700">No data </span>
									<span className="bp3-icon bp3-icon-warning-sign ml-1.5 text-gray-500"></span>{" "}
								</div>
							</td>
						</tr>
					) : (
						children
					)}
				</tbody>
			</table>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Table);
