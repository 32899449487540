import { formActions } from "../../_core/form";
import authConstants from "./constants";
import { jobActions } from "../../_core/job";
import {
	IAuthFormLoginFields,
	IAuthFormLoginErrors,
	IAuthUser,
	IAuthFormResetPasswordFields,
	IAuthFormResetPasswordErrors,
	IAuthFormResetRequestFields,
	IAuthFormResetRequestErrors,
	ITenantInfo,
} from "./types";
import { apiActions } from "../../_core/api";
import { ActionType, createAction } from "typesafe-actions";

const AUTH_LOGIN = "@auth/login"; // COMMAND ACTION
const SAML_LOGIN = "@auth/saml/login";
const AUTH_LOGOUT = "@auth/logout"; // COMMAND ACTION
const AUTH_RESET_REQUEST = "@auth/RESET-REQUEST"; // COMMAND ACTION
const AUTH_RESET_PASSWORD = "@auth/RESET-PASSWORD"; // COMMAND ACTION - Triggers reset password operation
const AUTH_AUTHORIZE_APP = "@auth/AUTHORIZE-APP"; // COMMAND ACTION
const AUTH_TENANT_INFO_LOAD = "@auth/tenant-info/LOAD"; // COMMAND ACTION
const AUTH_RESET = "@auth/RESET"; // EVENT & DOCUMENT ACTION - Resets complete authentication system
const AUTH_USER_SET = "@auth/user/SET"; // DOCUMENT ACTION
const AUTH_TOKEN_SET = "@auth/token/SET"; // DOCUMENT ACTION
const AUTH_PATH_PRIOR_TO_LOGIN_SET = "@auth/from/SET"
const AUTH_TENANT_INFO_SET = "@auth/tenant-info/SET"; // DOCUMENT ACTION
const AUTH_AUTHORISED_MODULES_SET = "@auth/authorised-modules/SET"; // DOCUMENT ACTION
const SET_PASSFILED_VISIBLE = "@auth/setpassfiledvisible";
const { FEATURE, forms } = authConstants;

const authActions = {
	login: createAction(AUTH_LOGIN, (username: string, password: string) => ({
		username,
		password,
	}))(),
	SAMLLogin: createAction(SAML_LOGIN, (code: string) => {
		return {
			code: code
		};
	})(),
	logout: createAction(AUTH_LOGOUT, () => ({}))(),
	resetRequest: createAction(AUTH_RESET_REQUEST, (emailId: string) => ({
		emailId,
	}))(),
	resetPassword: createAction(
		AUTH_RESET_PASSWORD,
		(newPassword: string, accessToken: string) => ({
			newPassword,
			accessToken,
		})
	)(),
	setPasswordFiledVisible: createAction(
		SET_PASSFILED_VISIBLE,
		() => ("YES")
	)(),
	pathPriorToLoginSet: createAction(
		AUTH_PATH_PRIOR_TO_LOGIN_SET,
		(from: string) => ({ from })
	)(),
	userSet: createAction(AUTH_USER_SET, (user: IAuthUser) => ({ user }))(),
	tokenSet: createAction(AUTH_TOKEN_SET, (token: string) => ({ token }))(),
	tenantInfoSet: createAction(
		AUTH_TENANT_INFO_SET,
		(tenantInfo: ITenantInfo) => ({
			tenantInfo,
		})
	)(),
	authReset: createAction(AUTH_RESET, () => ({}))(),
	authorizeApp: createAction(AUTH_AUTHORIZE_APP, () => ({}))(),
	tenantInfoLoad: createAction(AUTH_TENANT_INFO_LOAD, (tenantId: string) => ({
		tenantId,
	}))(),
	authorisedModulesSet: createAction(
		AUTH_AUTHORISED_MODULES_SET,
		(authorisedModules: string[]) => ({ authorisedModules })
	)(),
	_forms: {
		login: formActions<IAuthFormLoginFields, IAuthFormLoginErrors>(
			forms.LOGIN,
			FEATURE
		),
		resetPassword: formActions<
			IAuthFormResetPasswordFields,
			IAuthFormResetPasswordErrors
		>(forms.RESET_PASSWORD, FEATURE),
		resetRequest: formActions<
			IAuthFormResetRequestFields,
			IAuthFormResetRequestErrors
		>(forms.RESET_REQUEST, FEATURE),
	},
};
export const authJobActions = {
	login: jobActions(FEATURE, AUTH_LOGIN),
	logout: jobActions(FEATURE, AUTH_LOGOUT),
	resetRequest: jobActions(FEATURE, AUTH_RESET_REQUEST),
	resetPassword: jobActions(FEATURE, AUTH_RESET_PASSWORD),
	tenantInfoLoad: jobActions(FEATURE, AUTH_TENANT_INFO_LOAD),
};
export type IAuthActions = ActionType<typeof authActions>;
export default authActions;
