import classNames from "classnames";
import React, { FC } from "react";
import { IDiscrepancy } from "../../../types";

interface ICardProps {
	onClick?: (discrepancy: IDiscrepancy) => void;
	discrepancy: IDiscrepancy;
}

const Card: FC<ICardProps> = ({ discrepancy, onClick }) => {
	const { isLocalRange, range, remarks, title, value, status } = discrepancy;
	return (
		<div className="flex flex-col items-start p-6 bg-red-100 rounded-lg shadow-sm gap-y-2 font-redhat-text">
			<div className="flex justify-between w-full gap-x-2">
				<button
					onClick={() => onClick && onClick(discrepancy)}
					className={classNames(
						"cursor-pointer font-redhat-display text-base font-medium text-red-800 outline-none",
						{
							// 'cursor-pointer': onClick !== undefined
						}
					)}
				>
					{title}
				</button>
				<div>
					<span className="text-xl text-red-400 bp3-icon bp3-icon-info-sign" />
				</div>
			</div>
			<h4 className="text-xs text-gray-600">Discrepancy Detected</h4>
			<p className="text-gray-700 font-redhat-text">
				Current Value : {value}{' '}
                {Number(status) === 1 ? `exceeds` : `is below`} range [
				{range?.[0] !== undefined ? range[0] : ""}
				{range?.[1] !== undefined ? `- ${range[1]}` : ""}]
			</p>
			{remarks && (
				<p className="text-gray-700 font-redhat-text">
					Remarks : {remarks}
				</p>
			)}
			{isLocalRange && (
				<p className="text-gray-500 font-redhat-text">
					This discrepancy was triggered by a locally set range
				</p>
			)}
		</div>
	);
};

export default Card;
