import React from "react";

type SVGLogsProps = { className?: string };
const SVGLogs: React.FC<SVGLogsProps> = (props) => (
	<svg
		width="33"
		height="33"
		viewBox="0 0 33 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<g clipPath="url(#clip0_716_15553)">
			<path
				d="M29.9535 18.4072V16.4865C29.9535 10.123 24.7818 4.9629 18.4035 4.9629C16.6684 4.96076 14.9551 5.35003 13.3913 6.10172C11.8274 6.85341 10.4533 7.94815 9.37107 9.30446M6.8877 14.5896V16.5103C6.8877 22.8809 12.0568 28.0371 18.4377 28.0371C20.1679 28.0346 21.8757 27.6461 23.4367 26.9C24.9977 26.1538 26.3726 25.0688 27.4611 23.7239"
				stroke="#0B2F56"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 16.5L6.83594 13.6641L9.80078 16.5M32.875 16.5L30.0391 19.3359L27.0742 16.5"
				stroke="#0B2F56"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_716_15553">
				<rect width="33" height="33" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SVGLogs;
