import classNames from "classnames";
import React from "react";
import { FC } from "react";
import { FieldType } from "../types";
import { SwitchUI } from "./SwitchUI";
import Table from "./Table";

const QuestionRenderer: FC<{
	fields: FieldType[];
	title: string;
	num: string;
	classes: string[];
}> = (props) => {
	const { fields, num, title } = props;
	if (!fields) {
		return null;
	}
	const classes=props?.classes?.join(" ")
	// if fields is more than 4 then no wrap
	let flexWrap = 'flex-wrap';
	// if (fields.length >= 4) {
	// 	flexWrap = '';
	// }
	// if (fields.length > 7) {
	// 	flexWrap = 'flex-wrap';
	// }
	// fields.map(field => {
	// 	if (field?.type === 'label' && field?.title?.length > 10) {
	// 		flexWrap = 'flex-wrap'
	// 	}
	// })
	return (
		<article className="flex flex-row m-1">
			<div className="w-1/3 border border-1 border-black p-2">
				<span className="align-middle">
					{num} {title}
				</span>
			</div>
			<div className={classNames("flex justify-between w-full border border-1 border-l-0 border-black", classes, flexWrap)}>
				{fields?.map((e: any) => {
					if (e.columns && e.columns.length > 0) {
						return <Table key={e.key} table={e} isEnableAllForExternal={e?.isEnableAllForExternal} />;
					}
					return <SwitchUI noOfFields={fields.length} key={e.key} field={e} isEnableAllForExternal={e?.isEnableAllForExternal} />;
				})}
			</div>
		</article>
	);
};

export { QuestionRenderer };
