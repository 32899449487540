// tslint:disable: no-invalid-template-strings
import { ActionType, createAction } from "typesafe-actions";
import { IListObjectStore, RequireOne } from "../../_common/type";
import {
	EGarbageRecordState,
	IGarbageAccidentalLoss,
	IGarbageBasicInfo,
	IGarbageDischargedIntoSea,
	IGarbageDischargedToReceptionAshoreOrOtherShips,
	IGarbageEditLog,
	IGarbageIncinerated,
	IGarbageRecord,
	TGarbageEntries,
	TGarbageQuestionsState,
	TGarbageQuestionWithAnswer,
	TGRB1Filters,
} from "./@types";

const garbageRecordConstants = {
	FEATURE: "@garbage-record",
	FORMS: {
		NEW_OPERATION: "NEW_OPERATION",
	},
};

enum EGarbageRecordActions {
	FORM_SET = "@garbage-record/${newOperation}/[form]/set",
	FORM_EDIT = "@garbage-record/${newOperation}/[form]/edit",
	FORM_RESET = "@garbage-record/${newOperation}/reset",
	DISCHARGE_ENTRY_FORM_EDIT = "@garbage-record/${newOperation}/[entryBasedForm]/edit",
	GARBAGE_EDIT_LOGS_SET = "@garbage-record/${newOperation}/[basicForm]/${editLogs}/set",
	EDIT_LOGS_ANALYZE = "@garbage-record/newOperation/analyze-edit-logs",
	EDIT_LOGS_EDIT = "@garbage-record/${newOperation}/[basicForm]/${editLogs}/edit",

	OPERATION_DOCUMENT_CREATE = "@garbage-record/:operation-document-create",
	NEW_OPERATION_PREVIEW = "@garbage-record/:new-operation-preview",
	NEW_OPERATION_GO_BACK = "@garbage-record/:new-operation-go-back",
	NEW_OPERATION_SUBMIT = "@garbage-record/:new-operation-submit",
	NEW_OPERATION_STATE_SET = "@garbage-record/newOperation/${newOperationState}/set",
	NEW_OPERATION_SUBMITTED = "@garbage-record/#NEW_OPERATION_SUBMITTED",
	NEW_OPERATION_SET = "@garbage-record/${newOperation}/set",
	NEW_OPERATION_SET_FOR_EDIT = "@garbage-record/${newOperation}/edit",
	OPERATION_ATTACHMENT_UPLOAD = "@garbage/:operation-attachment-upload",

	HISTORY_LOAD = "@garbage-record/:history-load",
	HISTORY_SET = "@garbage-record/${history}/set",

	NEW_OPERATION_SUBMIT_SIGN = "@garbage-record/:new-operation-submit-sign",
	APPROVE_ENTRY_SIGN = "@garbage-record/:approve-entry-sign",
	STRIKE_OFF_ENTRY_SIGN = "@garbage-record/:strike-off-entry-sign",
	EDIT_ENTRY_SIGN = "@garbage-record/:edit-entry-sign",
	APPROVE_LAST_7_ENTRIES_SIGN = "@garbage-record/:approve-last-7-entries-sign",

	GARBAGE_RECORD_STATE_SET = "@garbage-record/${records}/[item]/${state}/set",
	GARBAGE_DISCHARGED_INTO_SEA_QUESTION_STATE_SET = "@garbage-record/${isDischargedIntoSeaQuestionState}/set",
	GARBAGE_DISCHARGED_INTO_SEA_QUESTION_SET = "@garbage-record/${garbageDischargedSeaQuestions}/set",

	FILTER_SET = "@orb1/:set-filter",
}

const garbageRecordActions = {
	commands: {
		operationDocumentCreate: createAction(
			EGarbageRecordActions.OPERATION_DOCUMENT_CREATE
		)(),
		newOperationPreview: createAction(
			EGarbageRecordActions.NEW_OPERATION_PREVIEW
		)(),
		newOperationSubmit: createAction(
			EGarbageRecordActions.NEW_OPERATION_SUBMIT
		)(),
		newOperationGoBack: createAction(
			EGarbageRecordActions.NEW_OPERATION_GO_BACK
		)(),
		historyLoad: createAction(
			EGarbageRecordActions.HISTORY_LOAD,
			(providedFilters: TGRB1Filters) => ({ providedFilters })
		)(),
		approveEntrySign: createAction(
			EGarbageRecordActions.APPROVE_ENTRY_SIGN,
			(garbageRecordId: string) => ({ garbageRecordId })
		)(),
		strikeOffEntrySign: createAction(
			EGarbageRecordActions.STRIKE_OFF_ENTRY_SIGN,
			(garbageRecordId: string) => ({ garbageRecordId })
		)(),
		editEntrySign: createAction(
			EGarbageRecordActions.EDIT_ENTRY_SIGN,
			(garbageRecordId: string) => ({ garbageRecordId })
		)(),
		approveLast7EntriesSign: createAction(
			EGarbageRecordActions.APPROVE_LAST_7_ENTRIES_SIGN,
			() => ({})
		)(),
		newOperationSubmitSign: createAction(
			EGarbageRecordActions.NEW_OPERATION_SUBMIT_SIGN
		)(),
		analyzeEditLog: createAction(
			EGarbageRecordActions.EDIT_LOGS_ANALYZE,
			(
				edits: RequireOne<
					Partial<IGarbageEditLog>,
					"fieldLabel" | "fieldName" | "newValue" | "pastValue"
				>
			) => ({ edits })
		)(),
		operationAttachmentUpload: createAction(
			EGarbageRecordActions.OPERATION_ATTACHMENT_UPLOAD,
			(attachmentFile: File) => ({ attachmentFile })
		)(),
	},
	events: {
		newOperationSubmitted: createAction(
			EGarbageRecordActions.NEW_OPERATION_SUBMITTED
		)(),
	},
	document: {
		formSet: createAction(EGarbageRecordActions.FORM_SET, () => ({}))(),
		basicFormEdit: createAction(
			EGarbageRecordActions.FORM_EDIT,
			(
				basicGarbageForm: Partial<
					IGarbageBasicInfo & { entry: TGarbageEntries }
				>
			) => ({
				basicGarbageForm,
			})
		)(),
		dischargeEntryFormEdit: createAction(
			EGarbageRecordActions.DISCHARGE_ENTRY_FORM_EDIT,
			(
				dischargeEntryBasedForm:
					| RequireOne<Partial<IGarbageAccidentalLoss>, "entry">
					| RequireOne<Partial<IGarbageDischargedIntoSea>, "entry">
					| RequireOne<
							Partial<IGarbageDischargedToReceptionAshoreOrOtherShips>,
							"entry"
					  >
					| RequireOne<Partial<IGarbageIncinerated>, "entry">
			) => ({ dischargeEntryBasedForm })
		)(),
		formReset: createAction(EGarbageRecordActions.FORM_RESET, () => ({}))(),
		newOperationStateSet: createAction(
			EGarbageRecordActions.NEW_OPERATION_STATE_SET,
			(state: "RECORD" | "PREVIEW") => ({ state })
		)(),
		historySet: createAction(
			EGarbageRecordActions.HISTORY_SET,
			(history: IListObjectStore<IGarbageRecord>) => ({ history })
		)(),
		garbageRecordStateSet: createAction(
			EGarbageRecordActions.GARBAGE_RECORD_STATE_SET,
			(garbageRecordId: string, state: EGarbageRecordState) => ({
				state,
				garbageRecordId,
			})
		)(),
		garbageEditLogsSet: createAction(
			EGarbageRecordActions.GARBAGE_EDIT_LOGS_SET,
			(editLogs: IGarbageEditLog[]) => ({ editLogs })
		)(),
		editLogsEdit: createAction(
			EGarbageRecordActions.EDIT_LOGS_EDIT,
			(editSession: number, edits: IGarbageEditLog) => ({
				editSession,
				edits,
			})
		)(),
		newOperationSetForEdit: createAction(
			EGarbageRecordActions.NEW_OPERATION_SET_FOR_EDIT,
			(garbageRecord: IGarbageRecord) => ({ garbageRecord })
		)(),
		newOperationSet: createAction(
			EGarbageRecordActions.NEW_OPERATION_SET,
			(garbageRecord: IGarbageRecord) => ({ garbageRecord })
		)(),
		garbageDischargedIntoSeaQuestionStateSet: createAction(
			EGarbageRecordActions.GARBAGE_DISCHARGED_INTO_SEA_QUESTION_STATE_SET,
			(dischargedIntoSeaQuestionState: TGarbageQuestionsState) => ({
				dischargedIntoSeaQuestionState,
			})
		)(),
		garbageDischargedIntoSeaQuestionSet: createAction(
			EGarbageRecordActions.GARBAGE_DISCHARGED_INTO_SEA_QUESTION_SET,
			(questions: TGarbageQuestionWithAnswer[]) => ({ questions })
		)(),
		filterSet: createAction(
			EGarbageRecordActions.FILTER_SET,
			(filter: TGRB1Filters) => ({
				filter,
			})
		)(),
	},
};

export type TGarbageRecordActions = ActionType<typeof garbageRecordActions>;
export default garbageRecordActions;
export { garbageRecordConstants, EGarbageRecordActions };
