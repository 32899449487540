import React from "react";
import DownloadButton from "../../Utils/DownloadButton";
import { SectionUI } from "../../Utils/SectionUI";
import config from "./index.json";


const Pre_arrival_Checks_Only: React.FC<any> = (props: any) => {
	return (
		<div className="antialiased bg-white">
			<div className="py-12 px-40 flex flex-col gap-y-10">
				{config.checklist.map((checklist) => {
					return <SectionUI key={checklist.key} checklist={checklist} />;
				})}
				<DownloadButton />
			</div>
		</div>
	);
};

export default Pre_arrival_Checks_Only;
