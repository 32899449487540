import React from 'react';
import Select from 'react-select';

type MultiselectDropdownProps = React.PropsWithChildren<{
    value: any;
    range: Array<{ value: string; label: string }>;
    onChangeHandler: any;
    isSubmit?: boolean;
    onFocus?: any;
	placeholder?: string;
	disabled?: boolean;
}>;

const MultiselectDropdown = (props: MultiselectDropdownProps) => {
    const {
        value,
        range,
        onChangeHandler,
        isSubmit,
		placeholder,
		disabled
    } = props;

    return (
        <>
			<Select
                options={range}
                value={value}
                onChange={(selectedItem: any) => {
                    onChangeHandler(selectedItem);
                }}
				placeholder={placeholder}
                isMulti={true}
				classNames={{
					container: (props) => ('border-gray-300 bg-gray-100 rounded-sm'),
					control: (props) => ('border-gray-300 bg-gray-100 rounded-sm'),
				}}
                styles={{
					container: (base: any) => ({
						...base,
						minHeight: '40px',
						marginTop: '8px',
					}),
                    control: (base: any) => ({
                        ...base,
                        minHeight: '40px',
                        // backgroundColor: '#fff',
                        // border: '1px solid #d9d9d9',
                        // borderRadius: '4px',
                        // boxShadow: 'none',
                        // '&:hover': {
                        //     border: '1px solid #d9d9d9',
                        // },
                        // '&:focus': {
                        //     border: '1px solid #d9d9d9',
                        // },
                    }),
                }}
                isDisabled={isSubmit || disabled}
            />
		</>
    );
};

export default MultiselectDropdown;
