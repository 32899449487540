import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Table from "../../_elements/Table";
import toolSelectors from "redux/app/tool/selectors";
import Td from "../../_elements/Td";
import moment from "moment";
import SVGView from "components/_common/icons/tool/SVGView";
import SVGUpdate from "components/_common/icons/tool/SVGUpdate";
import SVGCopy from "components/_common/icons/tool/SVGCopy";
import toolActions from "redux/app/tool/actions";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { setCurrentDropdown } from "redux/app/tool/utils";
import actionIcon from "../../../../../../assets/icons/action.svg";
import NumberedPagination from "components/_common/NumberedPagination";
import { PRINTS_PER_PAGE, checklistHeaderList } from "redux/app/tool/constants";
import userSelectors from "redux/app/user/selectors";
import ConfirmBox from "../../_elements/ConfirmBox";

type OwnProps = {};
type ChecklistTableProps = PropsFromRedux & OwnProps;
function ChecklistTable(props: ChecklistTableProps) {
	const {
		printChecklistsList,
		selectedItems,
		paginationForChecklists,
		jobToLoadPrint,
		users,
		searchText,
		isConfirmBoxModalOpen,
		actionPrintId,
	} = props;
	const [rowId, setRowId] = useState("");
	const [rowDropdown, setRowDropdown] = useState<Record<string, boolean>>({});
	const dropdownRef = useRef<HTMLDivElement>(null);

	const areListItemsSelected = selectedItems.length;

	useEffect(() => {
		props.setTotalItems(printChecklistsList?.ids?.length ?? 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [printChecklistsList?.ids]);

	useEffect(() => {
		return () => {
			props.setSelectedItems([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useOnClickOutside(dropdownRef, () =>
		setCurrentDropdown(rowId, false, setRowDropdown)
	);

	const idsAfterSearch = printChecklistsList?.ids.filter((printId) => {
		return printChecklistsList?.byIds[printId]?.title
			?.toLowerCase()
			?.includes(searchText?.toLowerCase());
	});

	return (
		<>
			<Table
				isEmpty={!jobToLoadPrint.isLoading && !printChecklistsList?.ids?.length}
				headers={checklistHeaderList}
				isLoading={jobToLoadPrint.isLoading}
			>
				{idsAfterSearch
					?.slice(
						(paginationForChecklists.currentPage - 1) * PRINTS_PER_PAGE,
						paginationForChecklists.currentPage * PRINTS_PER_PAGE
					)
					.map((logId, index) => {
						const checklistPrintItem = printChecklistsList.byIds[logId];
						const { title, version, createdAt, updatedAt, updatedBy } =
							checklistPrintItem;
						let usersName = "";
						if (updatedBy) {
							const userObj = Object.values(users.byIds).find(
								(user) => user.id === updatedBy
							);
							usersName = userObj?.name ?? "";
						}

						const lastUpdatedInfo = updatedAt
							? moment(updatedAt).fromNow()
							: createdAt
							? moment(createdAt).fromNow()
							: undefined;

						return (
							<tr key={logId} id={logId}>
								<Td>
									<div className="text-center">
										<input
											type="checkbox"
											className="bg-blue-100 border-blue-300 text-blue-500 focus:ring-blue-200 w-5 h-5 cursor-pointer"
											checked={selectedItems.includes(logId)}
											onChange={() => {
												if (selectedItems.includes(logId)) {
													const updatedSelectedItems = selectedItems.filter(
														(itemIdVal) => itemIdVal !== logId
													);

													props.setSelectedItems(updatedSelectedItems);
												} else {
													props.setSelectedItems([...selectedItems, logId]);
												}
											}}
										/>
									</div>
								</Td>
								<Td>
									{(paginationForChecklists.currentPage - 1) * PRINTS_PER_PAGE +
										index +
										1}
								</Td>
								<Td>{title}</Td>
								<Td>{version}</Td>
								<Td>
									{lastUpdatedInfo}{" "}
									{updatedBy ? `by ${usersName}` : lastUpdatedInfo ? "" : "NA"}
								</Td>
								<Td>
									<div className="cursor-pointer relative">
										<div
											className="cursor-pointer inline-flex  h-4  items-center justify-center"
											onClick={() => {
												if (areListItemsSelected) props.setSelectedItems([]);
												setRowId(logId);
												setCurrentDropdown(logId, true, setRowDropdown);
											}}
										>
											<img src={actionIcon} alt={"action" + logId} />
										</div>

										{rowDropdown[logId] && (
											<div
												ref={dropdownRef}
												className="absolute rounded-md border border-[#D8D8D8] md:-left-full lg:-left-full xl:-left-3/4 top-2 z-10 bg-white"
											>
												<ul>
													<li
														onClick={() => {
															setCurrentDropdown(logId, false, setRowDropdown);
															props.loadPrint(logId);
															props.toggleViewModal();
														}}
														className="py-2.5 text-[14px] pl-2.5 pr-8 md:pr-16 cursor-pointer  border-b"
													>
														<div className="flex items-center">
															<SVGView />
															<span className="ml-2.5">View</span>
														</div>
													</li>
													<li
														onClick={() => {
															props.loadPrint(logId);
															setCurrentDropdown(logId, false, setRowDropdown);
															props.toggleUpdateModal();
														}}
														className="py-2.5 text-[14px] pl-2.5 pr-8 md:pr-16 cursor-pointer  border-b"
													>
														<div className="flex items-center">
															<SVGUpdate />
															<span className="ml-2.5">Update</span>
														</div>
													</li>
													<li
														onClick={() => {
															setCurrentDropdown(logId, false, setRowDropdown);
															props.setActionPrintId(logId);
															props.toggleCopyModal();
														}}
														className="py-2.5 text-[14px] pl-2.5 pr-8 md:pr-16 cursor-pointer  border-b"
													>
														<div className="flex items-center">
															<SVGCopy />
															<span className="ml-2.5">Copy</span>
														</div>
													</li>
													<li
														onClick={() => {
															setCurrentDropdown(logId, false, setRowDropdown);
															props.setActionPrintId(logId);
															props.toggleConfirmBox();
														}}
														className="py-2.5 text-[14px] pl-2.5 pr-8 md:pr-16 cursor-pointer  border-b"
													>
														<div className="flex items-center">
															<span className="bp3-icon bp3-icon-trash text-[18px] text-gray-500" />
															<span className="ml-2.5">Delete</span>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								</Td>
							</tr>
						);
					})}
			</Table>
			<NumberedPagination
				{...{
					...paginationForChecklists,
					totalItems: searchText.length
						? idsAfterSearch.length
						: paginationForChecklists.totalItems || 0,
				}}
				itemIds={[]}
				onPageChange={function onPageChange(pageNumberFromPagination) {
					props.goToNewPage(pageNumberFromPagination);
				}}
				isPageLoading={jobToLoadPrint.isLoading}
			/>
			{isConfirmBoxModalOpen && (
				<ConfirmBox
					title="Are you sure you want to delete this config?"
					closeBtnLabel="Close"
					confirmBtnLabel="Confirm"
					isActive={isConfirmBoxModalOpen}
					onClose={() => props.toggleConfirmBox()}
					onConfirm={() => {
						props.toggleConfirmBox();
						props.deletePrint(actionPrintId);
					}}
				/>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const printChecklistsList = toolSelectors.getPrintChecklistsList(toolStore);
	const selectedItems = toolSelectors.getPrintSelectedItems(toolStore);
	const paginationForChecklists =
		toolSelectors.getPrintPaginationForChecklists(toolStore);
	const jobToLoadPrint = toolSelectors.jobToLoadPrint(toolStore);
	const userStore = userSelectors._getStore(store);
	const users = userSelectors.getUsersList(userStore);
	const searchText = toolSelectors.getSearchInputValue(toolStore);
	const isConfirmBoxModalOpen =
		toolSelectors.getIsConfirmBoxModalOpen(toolStore);
	const actionPrintId = toolSelectors.getActionPrintId(toolStore);

	return {
		printChecklistsList,
		selectedItems,
		paginationForChecklists,
		jobToLoadPrint,
		users,
		searchText,
		isConfirmBoxModalOpen,
		actionPrintId,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadPrint(printId: string) {
			dispatch(toolActions.commands.toolPrintLoad(printId));
		},
		setSelectedItems(selectedItems: string[]) {
			dispatch(toolActions.document.toolPrintItemsSelect(selectedItems));
		},
		toggleConfirmBox() {
			dispatch(toolActions.document.confirmBoxToggle());
		},
		toggleCopyModal() {
			dispatch(toolActions.document.printCopyModalToggle());
		},
		toggleUpdateModal() {
			dispatch(toolActions.document.printUpdateModalToggle());
		},
		toggleViewModal() {
			dispatch(toolActions.document.printViewModalToggle());
		},
		goToNewPage(pageNumber: number) {
			dispatch(
				toolActions.document.toolPrintChecklistPaginationCurrentPageSet(
					pageNumber
				)
			);
		},
		setTotalItems(items: number) {
			dispatch(
				toolActions.document.toolPrintChecklistPaginationTotalItemsSet(items)
			);
		},
		setActionPrintId(configId: string) {
			dispatch(toolActions.document.toolPrintIdOnActionSelect(configId));
		},
		deletePrint(configId: string) {
			dispatch(toolActions.commands.toolPrintDelete(configId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ChecklistTable);
