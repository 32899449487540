import React, { useEffect } from "react";
import CrewForm from "./Form";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { vesselOnboardActions } from "../../../../../../redux/app/vessel/onboard";
import { crewFormDefaults } from "../../../../../../redux/app/vessel/onboard/reducers.state";

type CrewEditProps = PropsFromRedux & {
	crewId: string;
	onClose(): void;
};
function CrewEdit(props: CrewEditProps) {
	const { crewId } = props;
	useEffect(
		function onLoad() {
			props.setFormModeEdit();
			props.loadCrewToEdit(crewId);
		},
		[crewId]
	);

	return (
		<CrewForm
			onCancel={props.onClose}
			onClose={props.onClose}
			title="Edit Crew"
			subtitle="following fields can be edited for Crew"
		/>
	);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadCrewToEdit(crewId: string) {
			dispatch(
				vesselOnboardActions._forms.crew.formFieldsSet(crewFormDefaults)
			);
			dispatch(vesselOnboardActions.command.crewLoad(crewId));
		},
		setFormModeEdit() {
			dispatch(vesselOnboardActions._forms.crew.formModeSet("EDIT"));
		},
	};
}
const reduxConnector = connect(() => ({}), mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(CrewEdit);
