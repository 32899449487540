import React, { useEffect } from "react";
import { vesselSelectors } from "../../../../redux/app/vessel";
import Button from "../../../../@set-product/core/button";
import classNames from "classnames";
import { useToggle } from "react-use";
import InputWrapper from "../../../_common/InputWrapper";
import CountrySelectInput from "./CountrySelectInput";
import { IStore } from "../../../../redux/store";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	vesselOnboardSelectors,
	vesselOnboardActions,
} from "../../../../redux/app/vessel/onboard";
import WSDropdown from "../../../_common/Dropdown";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import { IVessel } from "../../../../redux/app/vessel/types";
import { invokerAction } from "../../../../redux/_common/actions";

const uiStaticData = vesselSelectors.getVesselFormStaticData();
type VesselFormType = PropsFromRedux & {
	title: string;
	subtitle: string;
	onCancel(): void;
	onClose(): void;
};

function VesselForm(props: VesselFormType) {
	const { form, jobs, subtitle, title } = props;
	const { errors, fields, hasErrors, helpMessages, warnings } = form;
	const [advanceToggle, setAdvanceToggle] = useToggle(false);

	useEffect(
		function onLoad() {
			if (jobs.vesselCreate.isSuccess || jobs.vesselUpdate.isSuccess)
				props.onClose();
		},
		[jobs.vesselCreate, jobs.vesselUpdate]
	);
	return (
		<div
			className={classNames("w-full h-full my-6 bg-white bp3-dialog py-3", {})}
		>
			<div className="justify-between p-4 px-8 pt-6 bp3-dialog-header">
				<div className="flex flex-col px-2">
					<h4 className="text-xl font-medium text-gray-700 font-redhat-display">
						{title}
					</h4>
					<h5
						className={classNames("text-sm text-gray-600", {
							"text-red-500 font-medium": hasErrors,
						})}
					>
						{hasErrors ? "Form has few errors" : subtitle}
					</h5>
				</div>
				<button
					aria-label="Close"
					onClick={props.onClose}
					className="p-4 text-2xl bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
				/>
			</div>
			<InfiniteProgress
				isLoading={
					jobs.vesselLoad.isLoading ||
					jobs.vesselCreate.isLoading ||
					jobs.vesselUpdate.isLoading
				}
				isSpacedOut={false}
			/>
			<div
				className="p-8 py-0 mx-0 overflow-y-auto bg-white rounded-sm bp3-dialog-body ws-scroll"
				style={{ maxHeight: 460 }}
			>
				<form
					className="flex flex-row flex-wrap items-baseline justify-start p-2 -mx-2 -my-2"
					onSubmit={function onSubmit(e) {
						e.preventDefault();
						props.formSubmit();
					}}
					autoComplete="off"
				>
					<div className="w-2/5">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.vesselName.isValid}
								inputValue={fields.vesselName}
							>
								<label htmlFor="vessel-name" className="ws-input__label">
									{uiStaticData.vesselName.label}
								</label>
								<input
									type="text"
									name="vessel-name"
									id="vessel-name"
									className="ws-input"
									value={fields.vesselName}
									onChange={function onVesselNameChange(e) {
										props.formFieldEdit({ vesselName: e.target.value });
									}}
									placeholder={uiStaticData.vesselName.placeholder}
								/>
								<span className="ws-input__error">
									{errors.vesselName.message}
								</span>
								<span className="ws-input__warning">
									{warnings.vesselName.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.vesselName}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/4">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.vesselPrefix.isValid}
								hasWarning={!warnings.vesselPrefix.isValid}
								inputValue={fields.vesselPrefix}
							>
								<label htmlFor="prefix-id" className="ws-input__label">
									{uiStaticData.vesselPrefix.label}
								</label>
								<WSDropdown
									id="vessel-prefix-id"
									options={uiStaticData.vesselPrefix.options}
									value={fields.vesselPrefix}
									inputWrapperProps={{
										hasError: !errors.vesselPrefix.isValid,
										hasWarning: !warnings.vesselPrefix.isValid,
										inputValue: fields.vesselPrefix,
									}}
									onSelect={function onVesselPrefixChange(selectedItem) {
										props.formFieldEdit({
											vesselPrefix: selectedItem?.value as any,
											callSign: selectedItem?.value as any,
										});
									}}
									renderListItem={(item) => (
										<div className="flex flex-col w-full">
											<strong className="w-full font-semibold text-gray-800">
												{item.value}
											</strong>{" "}
											<span>{item.label}&nbsp;</span>
										</div>
									)}
									renderView={(selectedItem) => (
										<span>{selectedItem.value}&nbsp;</span>
									)}
								/>
								<span className="ws-input__error">
									{errors.vesselPrefix.message}
								</span>
								<span className="ws-input__warning">
									{warnings.vesselPrefix.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.vesselPrefix}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/3">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.portOfRegistry.isValid}
								hasWarning={!warnings.portOfRegistry.isValid}
								inputValue={fields.portOfRegistry}
							>
								<label htmlFor="port-registry" className="ws-input__label">
									{uiStaticData.portOfRegistry.label}
								</label>
								<CountrySelectInput
									inputWrapperProps={{
										className: "w-full",
										hasError: !errors.portOfRegistry.isValid,
										hasWarning: !warnings.portOfRegistry.isValid,
										inputValue: fields.portOfRegistry,
									}}
									defaultFlag={fields.flag || ""}
									onChange={function onPortOfRegistryChange(newValue) {
										if (newValue === null) {
											props.formFieldEdit({
												flag: "",
												nationality: "",
												portOfRegistry: "",
											});
										} else
											props.formFieldEdit({
												flag: newValue.flag,
												nationality: newValue.country,
												portOfRegistry: newValue.country,
											});
									}}
									disabled={false}
								/>
								<span className="ws-input__error">
									{errors.portOfRegistry.message}
								</span>
								<span className="ws-input__warning">
									{warnings.portOfRegistry.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.portOfRegistry}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-2/5">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.nameOfTheOwner.isValid}
								hasWarning={!warnings.nameOfTheOwner.isValid}
								inputValue={fields.nameOfTheOwner}
							>
								<label htmlFor="name-of-owner" className="ws-input__label">
									{uiStaticData.nameOfTheOwner.label}
								</label>
								<input
									type="text"
									name="name-of-owner"
									id="name-of-owner"
									className="ws-input"
									value={fields.nameOfTheOwner}
									onChange={function onNameOfTheOwnerChange(e) {
										props.formFieldEdit({ nameOfTheOwner: e.target.value });
									}}
									placeholder={uiStaticData.nameOfTheOwner.placeholder}
								/>
								<span className="ws-input__error">
									{errors.nameOfTheOwner.message}
								</span>
								<span className="ws-input__warning">
									{warnings.nameOfTheOwner.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.nameOfTheOwner}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/4">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.imoNumber.isValid}
								hasWarning={!warnings.imoNumber.isValid}
								inputValue={fields.imoNumber}
							>
								<label htmlFor="imo-number" className="ws-input__label">
									{uiStaticData.imoNumber.label}
								</label>
								<input
									type="number"
									name="imo-number"
									id="imo-number"
									className="ws-input"
									value={fields.imoNumber}
									onChange={function onIMONumberChange(e) {
										props.formFieldEdit({ imoNumber: e.target.value });
									}}
									placeholder={uiStaticData.imoNumber.placeholder}
								/>
								<span className="ws-input__error">
									{errors.imoNumber.message}
								</span>
								<span className="ws-input__warning">
									{warnings.imoNumber.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.imoNumber}
								</span>
							</InputWrapper>
						</div>
					</div>

					<div className="w-1/3">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.yearBuildDate.isValid}
								hasWarning={!warnings.yearBuildDate.isValid}
								inputValue={fields.yearBuildDate}
							>
								<label htmlFor="year-build-date" className="ws-input__label">
									{uiStaticData.yearBuildDate.label}
								</label>
								<input
									type="date"
									name="year-build-date"
									id="year-build-date"
									className="ws-input"
									value={fields.yearBuildDate}
									onChange={function onYearBuildDataChange(e) {
										props.formFieldEdit({ yearBuildDate: e.target.value });
									}}
									placeholder={uiStaticData.yearBuildDate.placeholder}
								/>
								<span className="ws-input__error">
									{errors.yearBuildDate.message}
								</span>
								<span className="ws-input__warning">
									{warnings.yearBuildDate.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.yearBuildDate}
								</span>
							</InputWrapper>
						</div>
					</div>

					<div className="w-1/2">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.addressOfTheOwner.isValid}
								hasWarning={!warnings.addressOfTheOwner.isValid}
								inputValue={fields.addressOfTheOwner}
							>
								<label htmlFor="owner-address" className="ws-input__label">
									{uiStaticData.addressOfTheOwner.label}
								</label>
								<textarea
									rows={3}
									name="owner-address"
									id="owner-address"
									className="ws-input"
									placeholder={uiStaticData.addressOfTheOwner.placeholder}
									spellCheck="false"
									value={fields.addressOfTheOwner}
									onChange={function onOwnerAddressChange(e) {
										props.formFieldEdit({ addressOfTheOwner: e.target.value });
									}}
								/>
								<span className="ws-input__error">
									{errors.addressOfTheOwner.message}
								</span>
								<span className="ws-input__warning">
									{warnings.addressOfTheOwner.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.addressOfTheOwner}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-full">&nbsp;</div>
					<div className="w-1/3">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.vesselType.isValid}
								hasWarning={!warnings.vesselType.isValid}
								inputValue={fields.vesselType}
							>
								<label htmlFor="type-id" className="ws-input__label">
									{uiStaticData.vesselType.label}
								</label>
								<WSDropdown
									id="vessel-type-id"
									inputWrapperProps={{
										hasError: !errors.vesselType.isValid,
										hasWarning: !warnings.vesselType.isValid,
										inputValue: fields.vesselType,
									}}
									options={uiStaticData.vesselType.options}
									placeholder={uiStaticData.vesselType.placeholder}
									value={fields.vesselType}
									onSelect={function onVesselTypeChange(selectedItem) {
										if (selectedItem)
											props.formFieldEdit({
												vesselType: selectedItem.value as any,
											});
									}}
								/>
								<span className="ws-input__error">
									{errors.vesselType.message}
								</span>
								<span className="ws-input__warning">
									{warnings.vesselType.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.vesselType}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/3">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.vesselSubType.isValid}
								hasWarning={!warnings.vesselSubType.isValid}
								inputValue={fields.vesselSubType}
							>
								<label htmlFor="subtype-id" className="ws-input__label">
									{uiStaticData.vesselSubType.label}
								</label>
								<WSDropdown
									id="vesse-subtype-id"
									inputWrapperProps={{
										hasError: !errors.vesselSubType.isValid,
										hasWarning: !warnings.vesselSubType.isValid,
										inputValue: fields.vesselSubType,
									}}
									options={uiStaticData.vesselSubType.options}
									value={fields.vesselSubType}
									onSelect={function onVesselSubtypeChange(selectedItem) {
										props.formFieldEdit({
											vesselSubType: selectedItem?.value as any,
										});
									}}
								/>
								<span className="ws-input__error">
									{errors.vesselSubType.message}
								</span>
								<span className="ws-input__warning">
									{warnings.vesselSubType.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.vesselSubType}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-1/3">
						<div className="px-2">
							<InputWrapper
								className="w-full"
								hasError={!errors.shipSizeCategory.isValid}
								hasWarning={!warnings.shipSizeCategory.isValid}
								inputValue={fields.shipSizeCategory}
							>
								<label htmlFor="size-category-id" className="ws-input__label">
									{uiStaticData.shipSizeCategory.label}
								</label>
								<WSDropdown
									id="ship-size-category-id"
									inputWrapperProps={{
										hasError: !errors.shipSizeCategory.isValid,
										hasWarning: !warnings.shipSizeCategory.isValid,
										inputValue: fields.shipSizeCategory,
									}}
									options={uiStaticData.shipSizeCategory.options}
									onSelect={function onShipSizeCategoryChange(selectedItem) {
										props.formFieldEdit({
											shipSizeCategory: selectedItem?.value as any,
										});
									}}
									placeholder={uiStaticData.shipSizeCategory.placeholder}
									value={fields.shipSizeCategory}
								/>
								<span className="ws-input__error">
									{errors.shipSizeCategory.message}
								</span>
								<span className="ws-input__warning">
									{warnings.shipSizeCategory.message}
								</span>
								<span className="ws-input__help-message">
									{helpMessages.shipSizeCategory}
								</span>
							</InputWrapper>
						</div>
					</div>
					<div className="w-full p-2">
						<div className="flex flex-row items-baseline justify-end w-full">
							<div className="ml-auto">
								<Button
									text="Advance (optional)"
									view="flat"
									dense={true}
									onClick={function onAdvanceClick() {
										setAdvanceToggle(!advanceToggle);
									}}
									rightIcon={
										advanceToggle ? (
											<span className="text-blue-600 text-smf bp3-icon bp3-icon-caret-down" />
										) : (
											<span className="text-gray-600 text-smf bp3-icon bp3-icon-caret-up" />
										)
									}
									className={classNames(
										"self-end ml-auto text-sm font-normal text-blue-600 shadow-none hover:text-blue-500 ",
										{ "bg-blue-100 border border-gray-400": advanceToggle }
									)}
								/>
							</div>
						</div>
					</div>
					{advanceToggle ? (
						<>
							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
									Tonnage{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
								{!errors.tonnage.isValid ? (
									<h6>{errors.tonnage.message}</h6>
								) : null}
							</div>
							<div className="w-1/3">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.tonnage?.grossTonnage}
									>
										<label
											htmlFor="tonnage-gross-id"
											className="ws-input__label"
										>
											{uiStaticData.tonnage.subFields.grossTonnage.label}
										</label>
										<input
											type="number"
											name="tonnage-gross-id"
											id="tonnage-gross-id"
											className="ws-input"
											placeholder={
												uiStaticData.tonnage.subFields.grossTonnage.placeholder
											}
											value={fields.tonnage?.grossTonnage}
											onChange={function onGrossTonnageChange(e) {
												props.formFieldEdit({
													tonnage: {
														...(fields.tonnage
															? fields.tonnage
															: {
																	deadWeight: 0,
																	grossTonnage: 0,
																	netTonnage: 0,
																	regGrossTonnage: 0,
															  }),
														grossTonnage: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/3">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.tonnage?.regGrossTonnage}
									>
										<label
											htmlFor="tonnage-reg-gross-id"
											className="ws-input__label"
										>
											{uiStaticData.tonnage.subFields.regGrossTonnage.label}
										</label>
										<input
											type="number"
											name="tonnage-reg-gross-id"
											id="tonnage-reg-gross-id"
											className="ws-input"
											placeholder={
												uiStaticData.tonnage.subFields.regGrossTonnage
													.placeholder
											}
											value={fields.tonnage?.regGrossTonnage}
											onChange={function onRegGrossTonnageChange(e) {
												props.formFieldEdit({
													tonnage: {
														...(fields.tonnage
															? fields.tonnage
															: {
																	deadWeight: 0,
																	grossTonnage: 0,
																	netTonnage: 0,
																	regGrossTonnage: 0,
															  }),
														regGrossTonnage: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/3">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.tonnage?.deadWeight}
									>
										<label
											htmlFor="tonnage-dead-weight"
											className="ws-input__label"
										>
											{uiStaticData.tonnage.subFields.deadWeight.label}
										</label>
										<input
											type="number"
											name="tonnage-dead-weight"
											id="tonnage-dead-weight"
											className="ws-input"
											placeholder={
												uiStaticData.tonnage.subFields.deadWeight.placeholder
											}
											value={fields.tonnage?.deadWeight}
											onChange={function onDeadWeightChange(e) {
												props.formFieldEdit({
													tonnage: {
														...(fields.tonnage
															? fields.tonnage
															: {
																	deadWeight: 0,
																	grossTonnage: 0,
																	netTonnage: 0,
																	regGrossTonnage: 0,
															  }),
														deadWeight: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/3">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.tonnage?.netTonnage}
									>
										<label htmlFor="tonnage-net" className="ws-input__label">
											{uiStaticData.tonnage.subFields.netTonnage.label}
										</label>
										<input
											type="number"
											name="tonnage-net"
											id="tonnage-net"
											className="ws-input"
											placeholder={
												uiStaticData.tonnage.subFields.netTonnage.placeholder
											}
											value={fields.tonnage?.netTonnage}
											onChange={function onNetTonnageChange(e) {
												props.formFieldEdit({
													tonnage: {
														...(fields.tonnage
															? fields.tonnage
															: {
																	deadWeight: 0,
																	grossTonnage: 0,
																	netTonnage: 0,
																	regGrossTonnage: 0,
															  }),
														netTonnage: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
									Dimensions{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
								{!errors.dimensions.isValid ? (
									<h6>{errors.dimensions.message}</h6>
								) : null}
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.dimensions?.length}
									>
										<label
											htmlFor="dimension-length"
											className="ws-input__label"
										>
											{uiStaticData.dimensions.subFields.length.label}
										</label>
										<input
											type="number"
											name="dimension-length"
											id="dimension-length"
											className="ws-input"
											placeholder={
												uiStaticData.dimensions.subFields.length.placeholder
											}
											value={fields.dimensions?.length}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													dimensions: {
														...(fields.dimensions
															? fields.dimensions
															: {
																	draft: 0,
																	height: 0,
																	length: 0,
																	loa: 0,
																	width: 0,
															  }),
														length: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.dimensions?.width}
									>
										<label
											htmlFor="dimension-width"
											className="ws-input__label"
										>
											{uiStaticData.dimensions.subFields.width.label}
										</label>
										<input
											type="number"
											name="dimension-width"
											id="dimension-width"
											className="ws-input"
											placeholder={
												uiStaticData.dimensions.subFields.width.placeholder
											}
											value={fields.dimensions?.width}
											onChange={function onWidthChange(e) {
												props.formFieldEdit({
													dimensions: {
														...(fields.dimensions
															? fields.dimensions
															: {
																	draft: 0,
																	height: 0,
																	length: 0,
																	loa: 0,
																	width: 0,
															  }),
														width: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.dimensions?.height}
									>
										<label
											htmlFor="dimension-height"
											className="ws-input__label"
										>
											{uiStaticData.dimensions.subFields.height.label}
										</label>
										<input
											type="number"
											name="dimension-height"
											id="dimension-height"
											className="ws-input"
											placeholder={
												uiStaticData.dimensions.subFields.height.placeholder
											}
											value={fields.dimensions?.height}
											onChange={function onHeightChange(e) {
												props.formFieldEdit({
													dimensions: {
														...(fields.dimensions
															? fields.dimensions
															: {
																	draft: 0,
																	height: 0,
																	length: 0,
																	loa: 0,
																	width: 0,
															  }),
														height: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.dimensions?.draft}
									>
										<label
											htmlFor="dimension-draft"
											className="ws-input__label"
										>
											{uiStaticData.dimensions.subFields.draft.label}
										</label>
										<input
											type="number"
											name="dimension-draft"
											id="dimension-draft"
											className="ws-input"
											placeholder={
												uiStaticData.dimensions.subFields.draft.placeholder
											}
											value={fields.dimensions?.draft}
											onChange={function onDraftChange(e) {
												props.formFieldEdit({
													dimensions: {
														...(fields.dimensions
															? fields.dimensions
															: {
																	draft: 0,
																	height: 0,
																	length: 0,
																	loa: 0,
																	width: 0,
															  }),
														draft: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.dimensions?.loa}
									>
										<label htmlFor="dimension-load" className="ws-input__label">
											{uiStaticData.dimensions.subFields.loa.label}
										</label>
										<input
											type="number"
											name="dimension-load"
											id="dimension-load"
											className="ws-input"
											placeholder={
												uiStaticData.dimensions.subFields.loa.placeholder
											}
											value={fields.dimensions?.loa}
											onChange={function onLoaChange(e) {
												props.formFieldEdit({
													dimensions: {
														...(fields.dimensions
															? fields.dimensions
															: {
																	draft: 0,
																	height: 0,
																	length: 0,
																	loa: 0,
																	width: 0,
															  }),
														loa: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
								Hull{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
								{!errors.hull?.isValid ? (
									<h6>{errors.hull?.message}</h6>
								) : null}
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.hull?.dateofdelivery}
									>
										<label
											htmlFor="hull-dateofdelivery"
											className="ws-input__label"
										>
											{uiStaticData.hull.subFields.dateofdelivery.label}
										</label>
										<input
											type="date"
											name="hull-dateofdelivery"
											id="hull-dateofdelivery"
											className="ws-input"
											placeholder={
												uiStaticData.hull.subFields.dateofdelivery.placeholder
											}
											value={fields.hull?.dateofdelivery}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													hull: {
														...(fields.hull
															? fields.hull
															: {
																dateofdelivery: "",
																length: 0,
																builder: "",
																breadth: 0,
																summerdeadweight: 0,
																loadeddraught: 0,
															  }),
															  dateofdelivery: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.hull?.length}
									>
										<label
											htmlFor="hull-length"
											className="ws-input__label"
										>
											{uiStaticData.hull.subFields.length.label}
										</label>
										<input
											type="number"
											name="hull-length"
											id="hull-length"
											className="ws-input"
											placeholder={
												uiStaticData.hull.subFields.length.placeholder
											}
											value={fields.hull?.length}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													hull: {
														...(fields.hull
															? fields.hull
															: {
																dateofdelivery: "",
																length: 0,
																builder: "",
																breadth: 0,
																summerdeadweight: 0,
																loadeddraught: 0,
															  }),
															  length: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.hull?.builder}
									>
										<label
											htmlFor="hull-builder"
											className="ws-input__label"
										>
											{uiStaticData.hull.subFields.builder.label}
										</label>
										<input
											type="text"
											name="hull-builder"
											id="hull-builder"
											className="ws-input"
											placeholder={
												uiStaticData.hull.subFields.builder.placeholder
											}
											value={fields.hull?.builder}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													hull: {
														...(fields.hull
															? fields.hull
															: {
																dateofdelivery: "",
																length: 0,
																builder: "",
																breadth: 0,
																summerdeadweight: 0,
																loadeddraught: 0,
															  }),
														builder: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.hull?.breadth}
									>
										<label
											htmlFor="hull-breadth"
											className="ws-input__label"
										>
											{uiStaticData.hull.subFields.breadth.label}
										</label>
										<input
											type="number"
											name="hull-breadth"
											id="hull-breadth"
											className="ws-input"
											placeholder={
												uiStaticData.hull.subFields.breadth.placeholder
											}
											value={fields.hull?.breadth}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													hull: {
														...(fields.hull
															? fields.hull
															: {
																dateofdelivery: "",
																length: 0,
																builder: "",
																breadth: 0,
																summerdeadweight: 0,
																loadeddraught: 0,
															  }),
															  breadth: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.hull?.summerdeadweight}
									>
										<label
											htmlFor="hull-summerdeadweight"
											className="ws-input__label"
										>
											{uiStaticData.hull.subFields.summerdeadweight.label}
										</label>
										<input
											type="number"
											name="hull-summerdeadweight"
											id="hull-summerdeadweight"
											className="ws-input"
											placeholder={
												uiStaticData.hull.subFields.summerdeadweight.placeholder
											}
											value={fields.hull?.summerdeadweight}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													hull: {
														...(fields.hull
															? fields.hull
															: {
																dateofdelivery: "",
																length: 0,
																builder: "",
																breadth: 0,
																summerdeadweight: 0,
																loadeddraught: 0,
															  }),
															  summerdeadweight: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.hull?.loadeddraught}
									>
										<label
											htmlFor="hull-loadeddraught"
											className="ws-input__label"
										>
											{uiStaticData.hull.subFields.loadeddraught.label}
										</label>
										<input
											type="number"
											name="hull-loadeddraught"
											id="hull-loadeddraught"
											className="ws-input"
											placeholder={
												uiStaticData.hull.subFields.loadeddraught.placeholder
											}
											value={fields.hull?.loadeddraught}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													hull: {
														...(fields.hull
															? fields.hull
															: {
																dateofdelivery: "",
																length: 0,
																builder: "",
																breadth: 0,
																summerdeadweight: 0,
																loadeddraught: 0,
															  }),
															  loadeddraught: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
								MainEngine{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
								{!errors.mainengine?.isValid ? (
									<h6>{errors.mainengine?.message}</h6>
								) : null}
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.maker}
									>
										<label
											htmlFor="mainengine-maker"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.maker.label}
										</label>
										<input
											type="text"
											name="mainengine-maker"
											id="mainengine-maker"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.maker.placeholder
											}
											value={fields.mainengine?.maker}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  maker: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.bore}
									>
										<label
											htmlFor="mainengine-bore"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.bore.label}
										</label>
										<input
											type="text"
											name="mainengine-bore"
											id="mainengine-bore"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.bore.placeholder
											}
											value={fields.mainengine?.bore}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  bore: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.type}
									>
										<label
											htmlFor="mainengine-type"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.type.label}
										</label>
										<WSDropdown
											id="mainengine-type"
											inputWrapperProps={{
												hasError: false,
												hasWarning: false,
												inputValue: "",
											}}
											options={uiStaticData.mainengine.subFields.type.options}
											placeholder={uiStaticData.mainengine.subFields.type.placeholder}
											value={fields.mainengine?.type}
											onSelect={function onVesselTypeChange(selectedItem) {
												if (selectedItem)
													props.formFieldEdit({
														mainengine: {
															...(fields.mainengine
																? fields.mainengine
																: {
																	maker: "",
																	bore: "",
																	type: "",
																	stroke: "",
																	engineno: "",
																	mcrbhpxrpm: 0,
																	numberofcylinders: 0,
																	ncrbhpxrpm: 0,
																  }),
																  type: selectedItem.value,
														}
													});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.stroke}
									>
										<label
											htmlFor="mainengine-stroke"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.stroke.label}
										</label>
										<input
											type="text"
											name="mainengine-stroke"
											id="mainengine-stroke"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.stroke.placeholder
											}
											value={fields.mainengine?.stroke}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  stroke: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.engineno}
									>
										<label
											htmlFor="mainengine-engineno"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.engineno.label}
										</label>
										<input
											type="text"
											name="mainengine-engineno"
											id="mainengine-engineno"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.engineno.placeholder
											}
											value={fields.mainengine?.engineno}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  engineno: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.mcrbhpxrpm}
									>
										<label
											htmlFor="mainengine-mcrbhpxrpm"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.mcrbhpxrpm.label}
										</label>
										<input
											type="number"
											name="mainengine-mcrbhpxrpm"
											id="mainengine-mcrbhpxrpm"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.mcrbhpxrpm.placeholder
											}
											value={fields.mainengine?.mcrbhpxrpm}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  mcrbhpxrpm: parseInt(e.target.value,10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.numberofcylinders}
									>
										<label
											htmlFor="mainengine-numberofcylinders"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.numberofcylinders.label}
										</label>
										<input
											type="number"
											name="mainengine-numberofcylinders"
											id="mainengine-numberofcylinders"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.numberofcylinders.placeholder
											}
											value={fields.mainengine?.numberofcylinders}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  numberofcylinders: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.mainengine?.ncrbhpxrpm}
									>
										<label
											htmlFor="mainengine-ncrbhpxrpm"
											className="ws-input__label"
										>
											{uiStaticData.mainengine.subFields.ncrbhpxrpm.label}
										</label>
										<input
											type="number"
											name="mainengine-ncrbhpxrpm"
											id="mainengine-ncrbhpxrpm"
											className="ws-input"
											placeholder={
												uiStaticData.mainengine.subFields.ncrbhpxrpm.placeholder
											}
											value={fields.mainengine?.ncrbhpxrpm}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													mainengine: {
														...(fields.mainengine
															? fields.mainengine
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																mcrbhpxrpm: 0,
																numberofcylinders: 0,
																ncrbhpxrpm: 0,
															  }),
															  ncrbhpxrpm: parseInt(e.target.value,10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
								Propeller{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
								{!errors.propeller?.isValid ? (
									<h6>{errors.propeller?.message}</h6>
								) : null}
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.propeller?.manufacturer}
									>
										<label
											htmlFor="propeller-manufacturer"
											className="ws-input__label"
										>
											{uiStaticData.propeller.subFields.manufacturer.label}
										</label>
										<input
											type="text"
											name="propeller-manufacturer"
											id="propeller-manufacturer"
											className="ws-input"
											placeholder={
												uiStaticData.propeller.subFields.manufacturer.placeholder
											}
											value={fields.propeller?.manufacturer}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													propeller: {
														...(fields.propeller
															? fields.propeller
															: {
																manufacturer: "",
																diameter: "",
																type: "",
																stroke: "",
																material: "",
																constant: "",
															  }),
															  manufacturer: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.propeller?.diameter}
									>
										<label
											htmlFor="propeller-diameter"
											className="ws-input__label"
										>
											{uiStaticData.propeller.subFields.diameter.label}
										</label>
										<input
											type="text"
											name="propeller-diameter"
											id="propeller-diameter"
											className="ws-input"
											placeholder={
												uiStaticData.propeller.subFields.diameter.placeholder
											}
											value={fields.propeller?.diameter}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													propeller: {
														...(fields.propeller
															? fields.propeller
															: {
																manufacturer: "",
																diameter: "",
																type: "",
																stroke: "",
																material: "",
																constant: "",
															  }),
															  diameter: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.propeller?.type}
									>
										<label
											htmlFor="propeller-type"
											className="ws-input__label"
										>
											{uiStaticData.propeller.subFields.type.label}
										</label>
										<input
											type="text"
											name="propeller-type"
											id="propeller-type"
											className="ws-input"
											placeholder={
												uiStaticData.propeller.subFields.type.placeholder
											}
											value={fields.propeller?.type}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													propeller: {
														...(fields.propeller
															? fields.propeller
															: {
																manufacturer: "",
																diameter: "",
																type: "",
																stroke: "",
																material: "",
																constant: "",
															  }),
															  type: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.propeller?.stroke}
									>
										<label
											htmlFor="propeller-stroke"
											className="ws-input__label"
										>
											{uiStaticData.propeller.subFields.stroke.label}
										</label>
										<input
											type="text"
											name="propeller-stroke"
											id="propeller-stroke"
											className="ws-input"
											placeholder={
												uiStaticData.propeller.subFields.stroke.placeholder
											}
											value={fields.propeller?.stroke}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													propeller: {
														...(fields.propeller
															? fields.propeller
															: {
																manufacturer: "",
																diameter: "",
																type: "",
																stroke: "",
																material: "",
																constant: "",
															  }),
															  stroke: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.propeller?.material}
									>
										<label
											htmlFor="propeller-material"
											className="ws-input__label"
										>
											{uiStaticData.propeller.subFields.material.label}
										</label>
										<input
											type="text"
											name="propeller-material"
											id="propeller-material"
											className="ws-input"
											placeholder={
												uiStaticData.propeller.subFields.material.placeholder
											}
											value={fields.propeller?.material}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													propeller: {
														...(fields.propeller
															? fields.propeller
															: {
																manufacturer: "",
																diameter: "",
																type: "",
																stroke: "",
																material: "",
																constant: "",
															  }),
															  material: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.propeller?.constant}
									>
										<label
											htmlFor="propeller-constant"
											className="ws-input__label"
										>
											{uiStaticData.propeller.subFields.constant.label}
										</label>
										<input
											type="text"
											name="propeller-constant"
											id="propeller-constant"
											className="ws-input"
											placeholder={
												uiStaticData.propeller.subFields.constant.placeholder
											}
											value={fields.propeller?.constant}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													propeller: {
														...(fields.propeller
															? fields.propeller
															: {
																manufacturer: "",
																diameter: "",
																type: "",
																stroke: "",
																material: "",
																constant: "",
															  }),
															  constant: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
								Auxiliary Engines{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
								{!errors.auxiliaryengines?.isValid ? (
									<h6>{errors.auxiliaryengines?.message}</h6>
								) : null}
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.maker}
									>
										<label
											htmlFor="auxiliaryengines-maker"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.maker.label}
										</label>
										<input
											type="text"
											name="auxiliaryengines-maker"
											id="auxiliaryengines-maker"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.maker.placeholder
											}
											value={fields.auxiliaryengines?.maker}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  maker: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.bore}
									>
										<label
											htmlFor="auxiliaryengines-bore"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.bore.label}
										</label>
										<input
											type="text"
											name="auxiliaryengines-bore"
											id="auxiliaryengines-bore"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.bore.placeholder
											}
											value={fields.auxiliaryengines?.bore}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  bore: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.type}
									>
										<label
											htmlFor="auxiliaryengines-type"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.type.label}
										</label>
										<input
											type="text"
											name="auxiliaryengines-type"
											id="auxiliaryengines-type"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.type.placeholder
											}
											value={fields.auxiliaryengines?.type}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  type: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.stroke}
									>
										<label
											htmlFor="auxiliaryengines-stroke"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.stroke.label}
										</label>
										<input
											type="text"
											name="auxiliaryengines-stroke"
											id="auxiliaryengines-stroke"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.stroke.placeholder
											}
											value={fields.auxiliaryengines?.stroke}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  stroke: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.engineno}
									>
										<label
											htmlFor="auxiliaryengines-engineno"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.engineno.label}
										</label>
										<input
											type="text"
											name="auxiliaryengines-engineno"
											id="auxiliaryengines-engineno"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.engineno.placeholder
											}
											value={fields.auxiliaryengines?.engineno}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  engineno: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.rpm}
									>
										<label
											htmlFor="auxiliaryengines-rpm"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.rpm.label}
										</label>
										<input
											type="number"
											name="auxiliaryengines-rpm"
											id="auxiliaryengines-rpm"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.rpm.placeholder
											}
											value={fields.auxiliaryengines?.rpm}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  rpm: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.stroke}
									>
										<label
											htmlFor="auxiliaryengines-typeofgen"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.typeofgen.label}
										</label>
										<input
											type="text"
											name="auxiliaryengines-typeofgen"
											id="auxiliaryengines-typeofgen"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.typeofgen.placeholder
											}
											value={fields.auxiliaryengines?.typeofgen}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  typeofgen: e.target.value,
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
							<div className="w-1/4">
								<div className="px-2">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.auxiliaryengines?.outputpowerkw}
									>
										<label
											htmlFor="auxiliaryengines-stroke"
											className="ws-input__label"
										>
											{uiStaticData.auxiliaryengines.subFields.outputpowerkw.label}
										</label>
										<input
											type="number"
											name="auxiliaryengines-outputpowerkw"
											id="auxiliaryengines-outputpowerkw"
											className="ws-input"
											placeholder={
												uiStaticData.auxiliaryengines.subFields.outputpowerkw.placeholder
											}
											value={fields.auxiliaryengines?.outputpowerkw}
											onChange={function onLengthChange(e) {
												props.formFieldEdit({
													auxiliaryengines: {
														...(fields.auxiliaryengines
															? fields.auxiliaryengines
															: {
																maker: "",
																bore: "",
																type: "",
																stroke: "",
																engineno: "",
																rpm: 0,
																typeofgen: "",
																outputpowerkw: 0,
															  }),
															  outputpowerkw: parseInt(e.target.value, 10),
													},
												});
											}}
										/>
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>

							<div className="w-full px-2 py-3 mt-6 text-md">
								<h5 className="text-lg font-normal text-gray-500">
									Vessel image{" "}
									<span className="text-xs text-gray-400">(optional)</span>
								</h5>
							</div>
							<div className="w-full">
								<div className="px-2 ">
									<InputWrapper
										className="w-full"
										hasError={false}
										hasWarning={false}
										inputValue={fields.image}
									>
										<label htmlFor="image" className="ws-input__label">
											{uiStaticData.image.label}
										</label>
										{fields.image ? (
											<div
												tabIndex={0}
												id="vessel-image"
												className="p-12 text-center border-2 border-dashed cursor-pointer ws-input hover:bg-gray-200"
											>
												<img src={fields.image} alt="Vessel Image" />
											</div>
										) : (
											<div
												tabIndex={0}
												id="vessel-image"
												className="p-12 text-center border-2 border-dashed cursor-pointer ws-input hover:bg-gray-200"
											>
												<span className="mx-2 font-medium text-gray-500">
													{uiStaticData.image.placeholder}
												</span>
												<span className="mx-2 text-xl text-gray-500 bp3-icon-upload bp3-icon" />
											</div>
										)}
										<span className="ws-input__error">Hello Error</span>
										<span className="ws-input__warning">Hello Warning</span>
										<span className="ws-input__help-message" />
									</InputWrapper>
								</div>
							</div>
						</>
					) : null}
					<div className="justify-start p-0 my-3 bp3-dialog-footer-actions">
						<Button
							text="Submit"
							loading={
								jobs.vesselLoad.isLoading ||
								jobs.vesselCreate.isLoading ||
								jobs.vesselUpdate.isLoading
							}
							view="filled"
							type="submit"
							color="primary"
							className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
						/>
						<Button
							text="Cancel"
							view="smooth"
							className="mx-2 font-medium "
							onClick={props.onCancel}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	return vesselOnboardSelectors.getVesselFormData(
		store.app.vesselStore.onboard
	);
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		imageUpload(file: File) {
			dispatch(
				invokerAction(
					"#VesselForm",
					vesselOnboardActions.command.vesselImageUpload(file)
				)
			);
		},
		load(vesselId: string) {
			dispatch(
				invokerAction(
					"#VesselForm",
					vesselOnboardActions.command.vesselLoad(vesselId)
				)
			);
		},
		formModeSet(mode: "CREATE" | "EDIT" | "NONE") {
			dispatch(
				invokerAction(
					"#VesselForm",
					vesselOnboardActions._forms.vessel.formModeSet(mode)
				)
			);
		},
		formFieldEdit(fields: Partial<IVessel>) {
			dispatch(
				invokerAction(
					"#VesselForm",
					vesselOnboardActions._forms.vessel.formFieldsEdit(fields)
				)
			);
		},
		formSubmit() {
			dispatch(
				invokerAction(
					"#VesselForm",
					vesselOnboardActions._forms.vessel.formSubmit()
				)
			);
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselForm);
