import classNames from 'classnames';
import React from 'react';
type OwnProps = { className?: string; fillColor?: any; pathColor?: any };
type SVGBookProps = OwnProps;
function SVGBook(props: SVGBookProps) {
    const { className, fillColor, pathColor } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth="1.3"
            strokeLinecap="round"
			fill={fillColor ?? 'none'}
            strokeLinejoin="round"
            className={classNames('w-auto stroke-current', className)}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path stroke={pathColor} d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
            <line stroke={pathColor} x1="13" y1="8" x2="15" y2="8" />
            <line stroke={pathColor} x1="13" y1="12" x2="15" y2="12" />
        </svg>
    );
}

export default SVGBook;
