import { Location, State } from "history";
import {
	PLACEHOLDER_CONSTANTS,
	headerTitleMapper,
	searchPlaceholderMapper,
} from "./constants";

export const getTopNavHeaderTitle = (location: Location<State> | undefined) => {
	let headerTitle = "Home";
	Object.keys(headerTitleMapper).forEach((title) => {
		const possiblePaths = (headerTitleMapper as any)[title];
		if (possiblePaths.some((path: string) => location?.pathname.includes(path)))
			headerTitle = title;
	});
	return headerTitle;
};

export const generatePlaceholder = (path: string, extraInfo?: any) => {
	const getType = Object.keys(searchPlaceholderMapper).filter((mapperPath) =>
		path.includes(mapperPath)
	);
	const type = getType.length
		? (searchPlaceholderMapper as any)[getType[0]]
		: PLACEHOLDER_CONSTANTS.ANYTHING;

	switch (type) {
		case PLACEHOLDER_CONSTANTS.VESSELS: {
			return "search for vessels";
		}
		case PLACEHOLDER_CONSTANTS.USERS: {
			return "search for users";
		}
		case PLACEHOLDER_CONSTANTS.FEEDBACK: {
			return "search for feedback";
		}
		default:
			return "search for anything";
	}
};
