import moment from "moment";
import { createReducer } from "typesafe-actions";
import withForms, { formUtils } from "../../_core/form";
import withJobs from "../../_core/job/reducers";
import { ILogActions_Document, ILogFilters, ILogStore, ISealLogsFilters } from "./@types";
import logActions from "./actions";
import { LOGS_PER_PAGE, REPORTS_PER_PAGE } from "./constants";

const reducerState: ILogStore = {
	_forms: {
		FILTER: formUtils.getDefaults<ILogFilters, any>({
			fields: {
				logType: "",
				vesselId: "",
				submitted: "",
				date: "",
			},
			mode: "NONE",
		}),
		SEAL_LOGS_FILTER: formUtils.getDefaults<ISealLogsFilters, any>({
			fields: {
				vesselId: "",
				startDate: moment().subtract(30, "day").format("YYYY-MM-DD"),
				endDate: moment().format("YYYY-MM-DD"),
			},
			mode: "NONE",
		}),
	},
	_jobs: {},
	_pagination: {
		currentPage: 1,
		itemsPerPage: LOGS_PER_PAGE,
		pageWiseListIds: {},
		totalItems: 0,
	},
	reports: [],
	selectedReport: undefined,
	reportsPagination: {
		currentPage: 1,
		itemsPerPage: LOGS_PER_PAGE,
		pageWiseListIds: {},
		totalItems: 0,
	},
	seallogs: [],
	seallogsPrint: [],
	seallogsPagination: {
		currentPage: 1,
		itemsPerPage: LOGS_PER_PAGE,
		pageWiseListIds: {},
		totalItems: 0,
	},
	logs: [],
	//totalLogsCount: 0,
	selectedLog: undefined,
};

const actions = logActions.document;
const logReducers = createReducer<ILogStore, ILogActions_Document>(reducerState)
	.handleAction(actions.logListSet, function logListSet(state, action) {
		if (action.payload.logs.length > 0) {
			const mapLogData = action.payload.logs.map((log) => {
				let logSelectedDate = moment(log.selectedDate).format(
					"DDMMYYYY"
				);
				return {
					...log,
					uniqueKey: `${log.vessel._id}${log.code}${logSelectedDate}`,
				};
			});
			const filteredData = mapLogData.filter(
				(log, index, self) =>
					index ===
					self.findIndex((pred) => pred.uniqueKey === log.uniqueKey)
			);
			return { ...state, logs: filteredData };
		} else {
			return { ...state, logs: action.payload.logs };
		}
	})
		.handleAction(
        actions.setAssociatedDayLog,
		// @ts-ignore
        function setAssociatedDayLog(state, action) {
			if(Array.isArray(state?.selectedLog?.log)){
				const tempLog = [...(state?.selectedLog?.log ?? [])];
				// check whether the log is an array and the first index exists
				if (tempLog && Array.isArray(tempLog) && tempLog.length > 0) {
					// we need to set the associated day log
					tempLog[0].associatedDayLog = action.payload.associatedDayLog;
				}
	
				return {
					...state,
					selectedLog: {
						...state.selectedLog,
						log: tempLog.length ? tempLog : state.selectedLog?.log,
					},
				};
			} else {
				return {...state}
			}
           
        }
    )
	.handleAction(actions.logTypeReset,function logTypeReset(state,action){
		return {
			...state,
			_forms: {
				...state._forms,
				FILTER: {
					...state._forms.FILTER,
					fields: {
						...state._forms.FILTER.fields,
						logType: ""
					}
				}
			}
		}
	})
	.handleAction(actions.logListReset, function logListReset(state, action) {
		return state;
	})
	.handleAction(actions.logListEdit, function logListEdit(state, action) {
		return state;
	})
	.handleAction(
		actions.logPrintTemplateSet,
		function logPrintTemplateSet(state, action) {
			const { printTemplate } = action.payload;
			return {
				...state,
				selectedLog: {
					...state.selectedLog,
					log: state.selectedLog?.log,
					//logConfig: state.selectedLog?.logConfig,
					logPrintTemplate: printTemplate,
				},
			};
		}
	)
	.handleAction(
		actions.logPrintTemplateReset,
		function logPrintTemplateReSet(state, action) {
			return {
				...state,
				selectedLog: state.selectedLog
					? {
							...state.selectedLog,
							logPrintTemplate: null,
					  }
					: undefined,
			};
		}
	)
	.handleAction(actions.logSelect, function logSelect(state, action) {
		const { log } = action.payload;
		return {
			...state,
			selectedLog: {
				log,
				logPrintTemplate: undefined,
			},
		};
	})
	.handleAction(actions.clearSelectedLog, function logSelect(state, action) {
		// const { log } = action.payload;
		return {
			...state,
			selectedLog: undefined,
		};
	})

	// Handling Pagination only
	.handleAction(
		actions.paginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		actions.paginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		actions.paginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		actions.paginationPageIdsSet,
		function pageIdsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					pageWiseListIds: {
						...state._pagination.pageWiseListIds,
						[action.payload.pageNumber]: action.payload.itemIds,
					},
				},
			};
		}
	)
	.handleAction(
		actions.paginationPageIdsResetAll,
		function pageIdsResetAll(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					pageWiseListIds: {},
				},
			};
		}
	)
	.handleAction(
		actions.paginationPageIdReset,
		function pageIdReset(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					pageWiseListIds: {
						...state._pagination.pageWiseListIds,
						[action.payload.pageNumber]: [],
					},
				},
			};
		}
	)
	.handleAction(actions.paginationReset, function reset(state, action) {
		return {
			...state,
			_pagination: {
				currentPage: 1,
				itemsPerPage: LOGS_PER_PAGE,
				pageWiseListIds: {},
				totalItems: 0,
			},
		};
	})
	// Handling SealLogs Load
	.handleAction(actions.seallogsSetPrint, function seallogsSetPrint(state, action) {
		return { ...state, seallogsPrint: action.payload.seallogs };
	})// Handling Pagination only
	// Handling SealLogs Load
	.handleAction(actions.seallogsSet, function seallogsSet(state, action) {
		return { ...state, seallogs: action.payload.seallogs };
	})// Handling Pagination only
	.handleAction(
		actions.seallogsPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				seallogsPagination: {
					...state.seallogsPagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		actions.seallogsPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				seallogsPagination: {
					...state.seallogsPagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		actions.seallogsPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				seallogsPagination: {
					...state.seallogsPagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		actions.seallogsPaginationPageIdsSet,
		function pageIdsSet(state, action) {
			return {
				...state,
				seallogsPagination: {
					...state.seallogsPagination,
					pageWiseListIds: {
						...state.seallogsPagination.pageWiseListIds,
						[action.payload.pageNumber]: action.payload.itemIds,
					},
				},
			};
		}
	)
	.handleAction(
		actions.seallogsPaginationPageIdsResetAll,
		function pageIdsResetAll(state, action) {
			return {
				...state,
				seallogsPagination: {
					...state.seallogsPagination,
					pageWiseListIds: {},
				},
			};
		}
	)
	.handleAction(
		actions.seallogsPaginationPageIdReset,
		function pageIdReset(state, action) {
			return {
				...state,
				seallogsPagination: {
					...state.seallogsPagination,
					pageWiseListIds: {
						...state.seallogsPagination.pageWiseListIds,
						[action.payload.pageNumber]: [],
					},
				},
			};
		}
	)
	.handleAction(actions.seallogsPaginationReset, function reset(state, action) {
		return {
			...state,
			seallogsPagination: {
				currentPage: 1,
				itemsPerPage: REPORTS_PER_PAGE,
				pageWiseListIds: {},
				totalItems: 0,
			},
		};
	})
	.handleAction(actions.clearSelectedReport, function clearSelectedReport(state, action) {
		return {
			...state,
			selectedReport: undefined,
		};
	})
	.handleAction(actions.selectReport, function selectReport(state, action) {
		const { report } = action.payload;
		return {
			...state,
			selectedReport: {
				report,
				reportPrintTemplate: undefined,
			},
		};
	})
	.handleAction(
		actions.reportPrintTemplateSet,
		function reportPrintTemplateSet(state, action) {
			const { printTemplate } = action.payload;
			return {
				...state,
				selectedReport: {
					...(state?.selectedReport || {}),
					report: state.selectedReport?.report,
					reportPrintTemplate: printTemplate,
				},
			};
		}
	)
	.handleAction(
		actions.reportPrintTemplateReset,
		function reportPrintTemplateReset(state, action) {
			return {
				...state,
				selectedReport: state.selectedReport
					? {
							...state.selectedReport,
							reportPrintTemplate: null,
					  }
					: undefined,
			};
		}
	)
	// Handling Reports Load
	.handleAction(actions.reportsSet, function reportsSet(state, action) {
		return { ...state, reports: action.payload.reports };
	})
	// Handling Pagination only
	.handleAction(
		actions.reportsPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				reportsPagination: {
					...state.reportsPagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		actions.reportsPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				reportsPagination: {
					...state.reportsPagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		actions.reportsPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				reportsPagination: {
					...state.reportsPagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		actions.reportsPaginationPageIdsSet,
		function pageIdsSet(state, action) {
			return {
				...state,
				reportsPagination: {
					...state.reportsPagination,
					pageWiseListIds: {
						...state.reportsPagination.pageWiseListIds,
						[action.payload.pageNumber]: action.payload.itemIds,
					},
				},
			};
		}
	)
	.handleAction(
		actions.reportsPaginationPageIdsResetAll,
		function pageIdsResetAll(state, action) {
			return {
				...state,
				reportsPagination: {
					...state.reportsPagination,
					pageWiseListIds: {},
				},
			};
		}
	)
	.handleAction(
		actions.reportsPaginationPageIdReset as any,
		function pageIdReset(state, action) {
			return {
				...state,
				reportsPagination: {
					...state.reportsPagination,
					pageWiseListIds: {
						...state.reportsPagination.pageWiseListIds,
						[action.payload.pageNumber]: [],
					},
				},
			};
		}
	)
	.handleAction(actions.reportsPaginationReset as any, function reset(state, action) {
		return {
			...state,
			reportsPagination: {
				currentPage: 1,
				itemsPerPage: REPORTS_PER_PAGE,
				pageWiseListIds: {},
				totalItems: 0,
			},
		};
	});
export default withJobs(
	"@logs",
	reducerState,
	withForms("@logs", reducerState, logReducers)
);
