import React from "react";
type SVGViewProps = { className?: string };
const SVGView: React.FC<SVGViewProps> = (props) => (
	<svg
		width="17"
		height="16"
		viewBox="0 0 17 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 5C0 4.17157 0.671573 3.5 1.5 3.5H15.5C16.3284 3.5 17 4.17157 17 5V11C17 11.8284 16.3284 12.5 15.5 12.5H1.5C0.671573 12.5 0 11.8284 0 11V5ZM1.5 4.5C1.22386 4.5 1 4.72386 1 5V11C1 11.2761 1.22386 11.5 1.5 11.5H15.5C15.7761 11.5 16 11.2761 16 11V5C16 4.72386 15.7761 4.5 15.5 4.5H1.5Z"
			fill="#718096"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 1C17 1.27614 16.7761 1.5 16.5 1.5L0.5 1.5C0.223858 1.5 1.95691e-08 1.27614 4.37103e-08 1C6.78515e-08 0.723858 0.223858 0.5 0.5 0.5L16.5 0.500001C16.7761 0.500001 17 0.723859 17 1Z"
			fill="#718096"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 15C17 15.2761 16.7761 15.5 16.5 15.5L0.5 15.5C0.223858 15.5 1.95691e-08 15.2761 4.37103e-08 15C6.78515e-08 14.7239 0.223858 14.5 0.5 14.5L16.5 14.5C16.7761 14.5 17 14.7239 17 15Z"
			fill="#718096"
		/>
	</svg>
);
export default SVGView;
