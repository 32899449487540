import actionTypes from "./action-types";
import { Middleware } from "redux";
import {
	ILocalstorageSetItem__Action,
	ILocalstorageRemoveItem__Action,
} from "./types";

const {
	LOCALSTORAGE_CLEAR,
	LOCALSTORAGE_SET_ITEM,
	LOCALSTORAGE_REMOVE_ITEM,
} = actionTypes;
const localStorageMiddleware: Middleware = ({ dispatch, getState }) => (
	next
) => (action) => {
	switch (action.type) {
		case LOCALSTORAGE_CLEAR: {
			next(action);
			localStorage.clear();
			break;
		}
		case LOCALSTORAGE_SET_ITEM: {
			next(action);
			const { key, value } = (action as ILocalstorageSetItem__Action).payload;
			localStorage.setItem(key, JSON.stringify(value));
			break;
		}
		case LOCALSTORAGE_REMOVE_ITEM: {
			next(action);
			const { key } = (action as ILocalstorageRemoveItem__Action).payload;
			localStorage.removeItem(key);
			break;
		}
		default:
			next(action);
	}
};
export default localStorageMiddleware;
