import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Saf01 from '../../../../components/app/checklist/saf01/Saf01';
import { rootRoutes } from '../../../../routes';

const saf01Route = rootRoutes.children.app.children.checklist.children.saf01;
type ScreenChecklistSaf01Props = React.PropsWithChildren<{}>;
function ScreenChecklistSaf01(props: ScreenChecklistSaf01Props) {
	return (
		<Saf01>			
		</Saf01>
	);
}

export default ScreenChecklistSaf01;
