import cuid from "cuid";
import { getType } from "typesafe-actions";
import { IStore } from "../../store";
import { formSelectors } from "../../_core/form";
import { jobSelectors } from "../../_core/job";
import {
	IGarbageEditLog,
	IGarbageRecord,
	IGarbageRecordStore,
	TGarbageEntries,
} from "./@types";
import { EGarbageRecordActions } from "./actions";

const garbageRecordSelectors = {
	_getStore(store: IStore) {
		return store.app.garbageRecord;
	},
	getNewOperationState(store: IGarbageRecordStore) {
		return store.newOperation.newOperationState;
	},
	getBasicFormData(store: IGarbageRecordStore) {
		return store.newOperation.garbageBasicInfo;
	},
	getFormDataByEntry(entry: TGarbageEntries, store: IGarbageRecordStore) {
		const {
			garbageAccidentalLoss,
			garbageDischargedIntoSea,
			garbageDischargedToReceptionAshoreOrOtherShips,
			garbageIncinerated,
		} = store.newOperation;
		switch (entry) {
			case "GARBAGE_INCINERATED":
				return garbageIncinerated;
			case "GARBAGE_DISCHARGED_INTO_SEA":
				return garbageDischargedIntoSea;

			case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
				return garbageAccidentalLoss;

			case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
				return garbageDischargedToReceptionAshoreOrOtherShips;
		}
	},
	getGarbageRecordFromNewOperation(store: IGarbageRecordStore) {
		const {
			garbageAccidentalLoss,
			garbageBasicInfo,
			garbageDischargedIntoSea,
			garbageDischargedToReceptionAshoreOrOtherShips,
			garbageIncinerated,
		} = store.newOperation;
		switch (garbageBasicInfo.entry) {
			case "GARBAGE_INCINERATED":
				return {
					...garbageBasicInfo,
					...garbageIncinerated,
				};
			case "GARBAGE_DISCHARGED_INTO_SEA":
				return {
					...garbageBasicInfo,
					...garbageDischargedIntoSea,
				};
			case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
				return {
					...garbageBasicInfo,
					...garbageAccidentalLoss,
				};
			case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
				return {
					...garbageBasicInfo,
					...garbageDischargedToReceptionAshoreOrOtherShips,
				};
		}
	},
	getHistoryList(store: IGarbageRecordStore) {
		return store.records.ids.map(
			(id) => store.records.byIds[id]
			// editHistory: garbageRecordSelectors.getGarbageRecordEditHistory(
			//     id,
			//     store
			// ),
		);
	},
	getGarbageRecordIds(store: IGarbageRecordStore) {
		return store.records.ids;
	},
	getGarbageRecordById(garbageRecordId: string, store: IGarbageRecordStore) {
		return store.records.byIds[garbageRecordId];
	},
	getGarbageRecordFromHistory(
		garbageRecordId: string,
		store: IGarbageRecordStore
	) {
		return store.records.byIds[garbageRecordId];
	},
	getGarbageRecordEditHistory(
		garbageRecordId: string,
		store: IGarbageRecordStore
	) {
		const garbageRecord = garbageRecordSelectors.getGarbageRecordFromHistory(
			garbageRecordId,
			store
		);
		const uniqueFields = Object.keys(garbageRecord.editLogs)
			.reduce<{ fieldName: string; fieldLabel: string }[]>(
				(result, editSessionNumber) => {
					const editedItems =
						garbageRecord.editLogs[parseInt(editSessionNumber, 10)];
					return [
						...result,
						...editedItems.ids.map((itemId) => {
							return {
								fieldName: editedItems.byIds[itemId].fieldName,
								fieldLabel: editedItems.byIds[itemId].fieldLabel,
							};
						}),
					];
				},
				[]
			)
			.filter(
				(item, i, ar) =>
					ar.findIndex(
						(indexItem) => indexItem.fieldName === item.fieldName
					) === i
			);

		const editHistory = uniqueFields.map((field) => {
			return {
				...field,
				edits: Object.keys(garbageRecord.editLogs).reduce<IGarbageEditLog[]>(
					(result, editSessionNumber) => {
						const editedItemsByIds =
							garbageRecord.editLogs[parseInt(editSessionNumber, 10)].byIds;
						if (editedItemsByIds[field.fieldName])
							return [...result, editedItemsByIds[field.fieldName]];
						return result;
					},
					[]
				),
			};
		});
		return editHistory;
	},
	getEditHistoryForGarbage(garbageRecord: IGarbageRecord) {
		const uniqueFields = Object.keys(garbageRecord.editLogs)
			.reduce<{ fieldName: string; fieldLabel: string }[]>(
				(result, editSessionNumber) => {
					const editedItems =
						garbageRecord.editLogs[parseInt(editSessionNumber, 10)];
					return [
						...result,
						...editedItems.ids.map((itemId) => {
							return {
								fieldName: editedItems.byIds[itemId].fieldName,
								fieldLabel: editedItems.byIds[itemId].fieldLabel,
							};
						}),
					];
				},
				[]
			)
			.filter(
				(item, i, ar) =>
					ar.findIndex(
						(indexItem) => indexItem.fieldName === item.fieldName
					) === i
			);

		const editHistory = uniqueFields.map((field) => {
			return {
				...field,
				edits: Object.keys(garbageRecord.editLogs).reduce<IGarbageEditLog[]>(
					(result, editSessionNumber) => {
						const editedItemsByIds =
							garbageRecord.editLogs[parseInt(editSessionNumber, 10)].byIds;
						if (editedItemsByIds[field.fieldName])
							return [...result, editedItemsByIds[field.fieldName]];
						return result;
					},
					[]
				),
			};
		});
		return editHistory;
	},
	getFilter(store: IGarbageRecordStore) {
		return {
			...store.filter,
			shownRecords: store.filter?.shownRecords
				? store.filter?.shownRecords
				: "ALL",
		};
	},
	jobToLoadHistory(store: IGarbageRecordStore) {
		return jobSelectors.getJobStatus(store, EGarbageRecordActions.HISTORY_LOAD);
	},
	jobToProcessAttachment(activeTaskStore: IGarbageRecordStore) {
		return jobSelectors.getJobStatus(
			activeTaskStore,
			EGarbageRecordActions.OPERATION_ATTACHMENT_UPLOAD
		);
	},
};

export default garbageRecordSelectors;
