import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import ConfigForm from "../../_elements/ConfigForm";
import toolActions from "redux/app/tool/actions";
import { IConfigInfo } from "redux/app/tool/@types";

type OwnProps = {};
type ConfigUpdateProps = PropsFromRedux & OwnProps;
function ConfigUpdate(props: ConfigUpdateProps) {
	useEffect(() => {
		return () => {
			props.setConfigInfo(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<ConfigForm
				action="Update"
				actionLabel="Update"
				onClose={() => props.toggleConfigUpdateModal()}
				type="update"
				isDisabled={false}
				handleActionClick={(config, changeLog?: string) => {
					props.configUpdate(config, changeLog ?? "");
				}}
			/>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleConfigUpdateModal() {
			dispatch(toolActions.document.updateModalToggle());
		},
		setConfigInfo(config?: IConfigInfo) {
			dispatch(toolActions.document.toolConfigInfoSet(config));
		},
		configUpdate(config: any, changeLog: string) {
			dispatch(toolActions.commands.toolConfigUpdate(config, changeLog));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ConfigUpdate);
