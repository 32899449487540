import moment from "moment";
import React from "react";
import {
	EORB1RecordState,
	TORB1Record,
} from "../../../../../redux/app/orb1/@types";
import { TORB1GroupI6 } from "../../../../../redux/app/orb1/groups/group-i";
import { marpolUtils } from "redux/app/marpol";
import ORB1Previewer from "../ORB1Previewer";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupI6PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupI6Preview(props: ORB1GroupI6PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI6;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const dateOfOriginalOperation = moment(
		orb1Record.itemI6.dateOfOriginalOperation,
		"YYYY-MM-DD"
	).format("DD-MMM-YYYY");

	function renderMain() {
		return (
			<>
				<ORB1TableRows
					record={orb1Record}
					tableRows={[
						[dateOfOriginalOperation, "I", "-", renderItemMain()],
						[...renderMissedPlaceholder()],
						[null, null, "NOTE", orb1Record.remarks],
					]}
				/>
				{renderMissedRecord()}
			</>
		);
	}

	function renderItemMain() {
		return (
			<h4 className="font-medium uppercase">
				ENTRY PERTAINING TO AN EARLIER MISSED OPERATIONAL ENTRY
				{orb1Record.itemI6.receiptAttachment &&
				orb1Record.itemI6.receiptAttachment !== " " ? (
					<>
						<br />
						CORRECTION NOTE:{" "}
						<a
							href={marpolUtils.getAttachmentURL(
								orb1Record.itemI6.receiptAttachment,
								orb1Record.id,
								orb1Record._rev
							)}
							download={orb1Record.itemI6.receiptAttachment}
						>
							{" "}
							{orb1Record.itemI6.receiptAttachment}
						</a>
					</>
				) : null}
			</h4>
		);
	}

	function renderMissedPlaceholder(): ORB1TableColumns {
		const canShowPlaceholder =
			orb1Record.itemI6.isAnMissedEntryRecord &&
			!orb1Record.itemI6.missedRecord;
		if (!canShowPlaceholder) return [null, null, null, null];

		const content = (
			<h4 className="font-medium text-gray-500 uppercase">
				<div className="flex flex-row items-center justify-center p-6 px-12 font-medium text-center bg-gray-100 border-2 border-gray-400 border-dashed">
					<div>MISSED ENTRY WILL APPEAR HERE</div>
					<span className="ml-6 text-gray-400 bp3-icon bp3-icon-time"></span>
				</div>
			</h4>
		);

		return [
			operationDate,
			<div className="text-gray-500">?</div>,
			<div className="text-gray-500">?</div>,
			content,
		];
	}

	function renderMissedRecord() {
		if (!orb1Record.itemI6.missedRecord) return null;
		return (
			<ORB1Previewer
				orbRecord={{
					...orb1Record.itemI6.missedRecord,
					selectedDate: orb1Record.selectedDate,
					state: props.isStrikedOff
						? EORB1RecordState.STRIKED_OFF
						: orb1Record.itemI6.missedRecord.state,
				}}
			/>
		);
	}

	return renderMain();
}

export default ORB1GroupI6Preview;
