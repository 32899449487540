import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4E1 } from "../../../../../redux/app/orb4/groups/orb4-e";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4E1PreviewProps = OwnProps;
function ORB4E1Preview(props: ORB4E1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4E1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "E", 18, renderItem18()],
					[null, null, 19.1, renderItem19P1()],
					[null, null, 19.2, renderItem19P2()],
					[null, null, 19.3, renderItem19P3()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem18() {
		return (
			<h4 className="font-medium uppercase">
				START LAT {orb4Record.item18.positionAtStart.latitude}, LON{" "}
				{orb4Record.item18.positionAtStart.longitude} <br />
				STOP LAT {orb4Record.item18.positionAtStop.latitude}, LON{" "}
				{orb4Record.item18.positionAtStop.longitude}
			</h4>
		);
	}
	function renderItem19P1() {
		return (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={orb4Record.item19.ballastedTanks.map(
						(tank) => tank.selectedTank.tankName
					)}
				/>
			</h4>
		);
	}
	function renderItem19P2() {
		return (
			<h4 className="font-medium uppercase">
				START AT {orb4Record.item19.timeAtBallastingStart.dateTimeInLT} LT (
				{orb4Record.item19.timeAtBallastingStart.dateTimeInUTC}
				Z ) <br />
				STOP AT {orb4Record.item19.timeAtBallastingStop.dateTimeInLT} LT(
				{orb4Record.item19.timeAtBallastingStop.dateTimeInUTC}Z )
			</h4>
		);
	}
	function renderItem19P3() {
		return (
			<h4 className="font-medium uppercase">
				TOTAL QUANTITY RECEIVED IN TANKS <br />
				<CommaSeparator
					items={orb4Record.item19.ballastedTanks.map(
						(tank) =>
							`${tank.selectedTank.tankName} = ${tank.totalQuantityReceived} M³`
					)}
				/>
			</h4>
		);
	}

	return renderMain();
}

export default ORB4E1Preview;
