import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import userActions from "redux/app/user/actions";
import UserForm from "./Form";

type UserCreateType = PropsFromRedux & {
	onClose(): void;
};
function UserCreate(props: UserCreateType) {
	useEffect(function onLoad() {
		props.setFormModeCreate();
	}, []);

	return (
		<UserForm
			onCancel={() => {
				props.onClose();
			}}
			onClose={() => {
				props.onClose();
			}}
			title="Add new member"
		/>
	);
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		setFormModeCreate() {
			dispatch(userActions._forms.user.formModeSet("CREATE"));
		},
	};
}
const reduxConnector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(UserCreate);
