import React from 'react';
import classNames from 'classnames';
import { EAnnex6RecordState, TAnnex6Record } from 'redux/app/annex6/@types';

export type Annex6TableColumns = React.ReactNode[];
type OwnProps = {
    record: TAnnex6Record;
    tableRows: Annex6TableColumns[];
};
function Annex6TableRows(props: OwnProps) {
    const { record, tableRows } = props;
    const isStrikedOff = record.state === EAnnex6RecordState.STRIKED_OFF;
    return (
        <>
            {tableRows.map((tableRow, index) => {
                const isValidRow = tableRow.length === 4;
                if (!isValidRow) {
                    console.error(
                        'Invalid Annex6 Row format for:',
                        record.recordCode,
                        isValidRow,
                        tableRow.length
                    );
                    return null;
                }
                const dateSection = tableRow[0];
                const codeSection = tableRow[1];
                const itemSection = tableRow[2];
                const remarksSection = tableRow[3];
                const isRemarksEmpty =
                    remarksSection === '' || remarksSection === null;

                if (isRemarksEmpty) return null;

                return (
                    <tr
                        className={classNames('uppercase', {
                            'line-through': isStrikedOff,
                        })}
                        key={index}
                    >
                        <td className="relative">
                            <div
                                className={classNames(
                                    { 'line-through': isStrikedOff },
                                    'p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix'
                                )}
                            >
                                <div className="h-full">
                                    <span className="block font-medium whitespace-nowrap">
                                        {dateSection}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td className="relative">
                            <div
                                className={classNames(
                                    { 'line-through': isStrikedOff },
                                    'p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix'
                                )}
                            >
                                {codeSection}
                            </div>
                        </td>
                        <td className="relative">
                            <div
                                className={classNames(
                                    { 'line-through': isStrikedOff },
                                    'p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix'
                                )}
                            >
                                <div className="h-full font-medium">
                                    {itemSection}
                                </div>
                            </div>
                        </td>
                        <td className="relative">
                            <div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
                                <div className="font-medium uppercase">
                                    {remarksSection}
                                </div>
                            </div>
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

export default Annex6TableRows;
