import classNames from "classnames";
import clsx from "clsx";
import React from "react";
type OwnProps = {
	children: React.ReactNode;
	className?: string;
};
function Td(
	props: OwnProps &
		React.DetailedHTMLProps<
			React.TdHTMLAttributes<HTMLTableDataCellElement>,
			HTMLTableDataCellElement
		>
) {
	const { children, className } = props;
	return (
		<td
			className={clsx(
				className,
				"px-3 py-2 text-sm border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D] "
			)}
		>
			{children}
		</td>
	);
}

export default Td;
