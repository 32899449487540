import React, { useState } from "react";
import AppSidebar from "./_elements/Sidebar";
import { jobSelectors } from "../../redux/_core/job";
import { IStore } from "../../redux/store";
import { connect, ConnectedProps } from "react-redux";
import { IJobGetStatus } from "../../redux/_core/job/types";
import wayshipLog512 from "./../../assets/images/logo-512x512.png";
import { Icon } from "@blueprintjs/core";
import { Dispatch } from "redux";
import { authActions, authSelectors } from "../../redux/app/auth";
import { HelmetProvider } from "react-helmet-async";
import useCheckMobileScreen from "./feedback/utils";
import classNames from "classnames";
import TopNavbar from "./_elements/topnavbar/TopNavbar";
import NavbarMobile from "./_elements/NavbarMobile";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";

type AppProps = React.PropsWithChildren<PropsFromRedux & IJobGetStatus>;
const { vessel, dashboard } = rootRoutes.children.app.children;
let timeoutId:NodeJS.Timeout

function App(props: AppProps) {
	const isMobile = useCheckMobileScreen();
	const { loggedInUserName, isUserAllowedToAccessModule } = props;
	const [userActionPopupVisible, setUserActionPopupVisible] = useState(false);

	const closeUserActionsMenu = () => {
		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			setUserActionPopupVisible(false);
		}, 100);
	};

	// user is not present, so forcefully logging out
	if (isUserAllowedToAccessModule === null) {
		console.log("user is not present, so forcefully logging out");
		props.logout();
	}

	return (
		<HelmetProvider>
			{/* <title>My Title</title>
				<meta name="description" content="Helmet application" /> */}
			<div className="flex-col bg-gray-300">
				<div
					className={classNames({
						flex: !isMobile,
						"flex-col": isMobile,
					})}
				>
					{isMobile ? (
						<>
							<NavbarMobile />
						</>
					) : (
						<div
							className="sticky top-0 w-24 h-screen"
							style={{ background: "#0B2F56", zIndex: 1 }}
						>
							<div className="flex flex-col items-center justify-center border-gray-300 border-right">
								<div className="flex-col items-center justify-center mt-6 mb-6">
									<div className="flex flex-row flex-no-wrap items-center justify-center w-full ">
										<div className="">
											<img
												onClick={() => {
													props.push(
														dashboard.children.list.url()
													);
												}}
												src={wayshipLog512}
												alt=""
												className="h-6 my-1 cursor-pointer"
											/>
										</div>
									</div>
								</div>

								<div className="h-[calc(100vh-170px)] overflow-y-auto no-scrollbar">
									<AppSidebar />
								</div>

								<div className="absolute bottom-0 my-8 logout">
									<div
										title="user actions"
										className={classNames(
											"flex justify-center px-3 py-3 m-2 text-center text-gray-800 rounded-md outline-none cursor-pointer relative"
										)}
									>
										<button
											onBlur={() => {
												setUserActionPopupVisible(
													false
												);
											}}
											onClick={() => {
												setUserActionPopupVisible(true);
											}}
											id="user-action-btn"
											className="shadow-none border-none outline-none flex items-center justify-center w-[45px] h-[45px] rounded-full bg-lightBlue relative"
										>
											{loggedInUserName ? (
												<div
													className="flex items-center justify-center text-lg font-medium bg-blue-200 rounded-full cursor-pointer"
													style={{
														width: "40px",
														height: "40px",
													}}
												>
													{loggedInUserName
														?.split(" ")
														.slice(0, 2)
														.map((v) =>
															v
																.charAt(0)
																.toUpperCase()
														)
														.join("")}
												</div>
											) : null}
											<div
												className={classNames(
													"absolute bottom-10 left-10 transition-all",
													{
														"scale-0 opacity-0":
															!userActionPopupVisible,
														"scale-100 opacity-100":
															userActionPopupVisible,
													}
												)}
											>
												<div className="flex flex-col justify-center p-2 bg-white rounded-md shadow-md gap-y-2">
													<div
														className="flex items-center gap-x-3 w-[150px] h-[45px] border border-solid border-lightBlue hover:bg-lightBlue p-2 rounded-md text-[#133774] font-medium cursor-pointer"
														onClick={() => {
															props.push(
																rootRoutes.children.app.children.users.children.profile.url()
															);
															closeUserActionsMenu();
														}}
														title="Profile"
														id="profileBtn"
													>
														<span className="text-lg bp3-icon bp3-icon-user" />
														<span>Profile</span>
													</div>
													<div
														className="flex items-center gap-x-3 w-[150px] h-[45px] border border-solid border-lightBlue hover:bg-lightBlue p-2 rounded-md text-[#133774] font-medium"
														id="logoutBtn"
														onClick={() => {
															setUserActionPopupVisible(
																false
															);
															props.logout();
														}}
														title="Logout"
													>
														<span className="text-lg bp3-icon bp3-icon-power" />
														<span>Logout</span>
													</div>
												</div>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="w-full h-full overflow-x-auto">
						<div className="flex-1 w-full h-full min-h-full mx-auto overflow-y-auto ws-scroll">
							<div className="flex flex-col flex-1 h-full min-h-screen">
								<div className="flex-row items-center justify-between hidden w-full h-16 px-6 bg-white shadow-md">
									<div>
										<Icon
											icon="notifications-updated"
											iconSize={18}
											intent={"none"}
											className="flex flex-row items-center justify-center w-6 h-6 text-gray-700"
										/>
									</div>
								</div>
								<div className="flex flex-col flex-1 h-full my-0">
									{isUserAllowedToAccessModule ? (
										<>
											<TopNavbar />
											{props.children}
										</>
									) : (
										<div className="flex flex-col items-center justify-center flex-1 w-full h-full">
											<div className="text-3xl font-medium text-gray-600">
												<span
													role="img"
													aria-label="warning emoji"
												>
													⚠️
												</span>{" "}
												You are not authorised to access
												this module!
											</div>
											<div className="text-lg font-medium text-gray-600">
												Please contact your
												administrator.
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</HelmetProvider>
	);
}

const mapStateToProps = (store: IStore) => {
	const authStore = store.app.auth;
	const loggedInUser = authSelectors(authStore).getLoggedInUser();
	// get current module name from
	const currentModule = window.location.pathname?.split("/")?.[2];
	const authorisedModules = authSelectors(authStore)?.getAuthorisedModules();
	const isUserAllowedToAccessModule =
		authorisedModules?.includes(currentModule) ?? null;
	// console.log("isUserAllowedToAccessModule", isUserAllowedToAccessModule);

	return {
		...jobSelectors.getStatus(store.core.jobGlobal),
		loggedInUserName: loggedInUser?.name,
		isUserAllowedToAccessModule,
	};
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		logout() {
			dispatch(authActions.logout());
		},
		push(url: string) {
			return dispatch(historyActions.push("#AppSidebar", url));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(App);
