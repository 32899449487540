import tankUtils from "./tanks.utils";
import marpolDateUtils from './date.utils';

// Previous implementation
// https://assist.wayship.io/app/vessel/onboard/48192a85999e7bd4372013f51507fc2b/marpol/list/undefined/wayship/marpolORB2::aa9dd6ca-e8cb-456b-82f5-b90dfcfd6d7f/RECEIPT_ORB2H2_2024-05-31.pdf?rev=10-eff2aabaa8257b638ed43a69b39be2fe

// New implementation
// https://console.wayship.io/api/attachments?docId=b9d7d10fb7d6ffd059574f79c63c50fc&attachmentName=Capture2.PNG&type=image/png

const marpolUtils = {
	...tankUtils,
	...marpolDateUtils,
	getAttachmentURL(fileName: string, id: string, _rev: string) {
		const fileExtension = (fileName.split('.').pop() || '').toLowerCase();
		let fileType = '';

		switch (fileExtension) {
		case 'png':
			fileType = 'image/png';
			break;
		case 'jpg':
		case 'jpeg':
			fileType = 'image/jpeg';
			break;
		case 'gif':
			fileType = 'image/gif';
			break;
		case 'pdf':
			fileType = 'application/pdf';
			break;
		case 'doc':
			fileType = 'application/msword';
			break;
		case 'docx':
			fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
			break;
		case 'xls':
			fileType = 'application/vnd.ms-excel';
			break;
		case 'xlsx':
			fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			break;
		case 'ppt':
			fileType = 'application/vnd.ms-powerpoint';
			break;
		case 'pptx':
			fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
			break;
		// Add more cases as needed
		default:
			fileType = 'application/octet-stream'; // Default type if not matched
		}
		return `https://console.wayship.io/api/attachments?docId=${id}&attachmentName=${fileName}&type=${fileType}`;
	},
};
export default marpolUtils;
