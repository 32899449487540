import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import RectangularStatusBar from "./RectangularStatusBar";
import classNames from "classnames";
import { Tooltip } from "react-tippy";

type OwnProps = {
	colorMapper: any;
	row: any;
};
type RowProps = PropsFromRedux & OwnProps;
function Row(props: RowProps) {
	const { colorMapper, row } = props;
	return (
		<tr key={row.col0}>
			{Object.keys(row).map((_, index) => {
				const data = row[`col${index}`];
				const value = data?.value;
				const hint = data?.hint;
				return (
					<td
						key={index}
						className={classNames(
							"leading-4 tracking-wide text-left font-redhat-text text-[#2D2D2D] p-0.5",
							{ "pl-2 font-medium text-[12px] md:text-base": !index },
							{ "text-sm": index }
						)}
					>
						{!index ? (
							value //Info: F 1st column values for each row is a value
						) : value in colorMapper ? ( //Info: rest of the columns are either state or level
							hint ? (
								<Tooltip arrow title={hint} position="top" trigger="mouseenter">
									<RectangularStatusBar color={colorMapper[value]} />
								</Tooltip>
							) : (
								<RectangularStatusBar color={colorMapper[value]} />
							)
						) : (
							""
						)}
					</td>
				);
			})}
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Row);
