import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TBallastBackendRecord, TBallastRecord } from "../../../../../redux/app/ballast/@types";
import { TBallast_Ballast } from "../../../../../redux/app/ballast/groups/ballast";
import CommaSeparator from "../../../../_common/CommaSeparator";
import BallastRecordParticipantsView from "../_elements/ParticipantsView";
import BallastTableRows from "../BallastTableRows";
import getSignTableRows from "../getSignTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type BallastOperationPreviewProps = OwnProps;
function BallastOperationPreview(props: BallastOperationPreviewProps) {
	const record = props.ballastRecord as TBallast_Ballast;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<BallastTableRows
				record={record}
				tableRows={[
					[operationDate, "3.1.1", renderItem3P1P1()],
					[null, "3.1.2", renderItem3P1P2()],
					...renderUserInfoRows(),
					[operationDate, "3.2.1", renderItem3P2P1()],
					[null, "3.2.2", renderItem3P2P2()],
					[null, "3.2.3", renderItem3P2P3()],
					[null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem3P1P1() {
		return validateAndPreviewPlaceAndPosition(record);
	}

	function renderItem3P1P2() {
		return (
			<h4 className="font-medium uppercase">
				Uptake: {record.totalOperatedWaterVolume} M³,
				{/* Quantity retained in{' '} */}
				{/* {getParticipantTankNames(ballastRecord)} : */}
				{/* {totalQuantityRetained(ballastRecord)} M³, */}
				{record.ballastROB
					? `Total Retained Quantity Onboard: ${record.ballastROB} M³`
					: `Total Quantity Retained: ${totalQuantityRetained(record)} M³`}
			</h4>
		);
	}

	function renderUserInfoRows() {
		if (!record.isBallastWaterTreatmentPlanUsed) return [];
		return getSignTableRows(record as TBallastBackendRecord);
	}

	function renderItem3P2P1() {
		if (!record.isBallastWaterTreatmentPlanUsed) return null;
		return (
			<h4 className="font-medium uppercase">
				{validateAndPreviewPlaceAndPosition(record)}
			</h4>
		);
	}

	function renderItem3P2P2() {
		if (!record.isBallastWaterTreatmentPlanUsed) return null;
		return (
			<h4 className="font-medium uppercase">
				Treated: {record.totalOperatedWaterVolume} M³
			</h4>
		);
	}

	function renderItem3P2P3() {
		if (!record.isBallastWaterTreatmentPlanUsed) return null;
		return <h4 className="font-medium uppercase">YES (USING BWTP)</h4>;
	}

	const validateAndPreviewPlaceAndPosition = (
		ballastRecord: TBallast_Ballast
	) => {
		return (
			<>
				{ballastRecord.placeOfBallast.place === "IN_PORT" ? (
					<>
						{moment(ballastRecord.tanks[0].startDate, "YYYY-MM-DD").format(
							"DD-MMM-YYYY"
						)}{" "}
						{ballastRecord.tanks[0].startTime} to{" "}
						{moment(
							ballastRecord.tanks[ballastRecord.tanks.length - 1].endDate,
							"YYYY-MM-DD"
						).format("DD-MMM-YYYY")}{" "}
						{ballastRecord.tanks[ballastRecord.tanks.length - 1].endTime},{" "}
						{ballastRecord.placeOfBallast.portName}
					</>
				) : null}
				{ballastRecord.placeOfBallast.place === "AT_SEA" ? (
					<>
						{moment(ballastRecord.tanks[0].startDate, "YYYY-MM-DD").format(
							"DD-MMM-YYYY"
						)}{" "}
						{ballastRecord.tanks[0].startTime}{" "}
						{ballastRecord.placeOfBallast.startPosition.latitude},{" "}
						{ballastRecord.placeOfBallast.startPosition.longitude}, to{" "}
						{moment(
							ballastRecord.tanks[ballastRecord.tanks.length - 1].endDate,
							"YYYY-MM-DD"
						).format("DD-MMM-YYYY")}{" "}
						{ballastRecord.tanks[ballastRecord.tanks.length - 1].endTime},{" "}
						{ballastRecord.placeOfBallast.endPosition.latitude},{" "}
						{ballastRecord.placeOfBallast.endPosition.longitude}, DEPTH:{" "}
						{ballastRecord.depth}M
					</>
				) : null}
			</>
		);
	};

	const totalQuantityRetained = (ballastRecord: TBallast_Ballast) => {
		let accumulatedValue = 0;
		if (ballastRecord.ballastROB) {
			accumulatedValue = ballastRecord.tanks.reduce(
				(acc, curr) => acc + curr.newRetainedQuantity,
				0
			);
		} else {
			accumulatedValue = ballastRecord.tanks.reduce(
				(acc, curr) =>
					acc + curr.selectedTank.expectedQuantity + curr.transferQuantity,
				0
			);
		}
		return accumulatedValue;
	};

	const getParticipantTankNames = (ballastRecord: TBallast_Ballast) => {
		let tankNames = "";
		ballastRecord.tanks.forEach((tank, index) => {
			tankNames += tank.selectedTank.tankCode;
			if (index !== ballastRecord.tanks.length - 1) {
				tankNames += ",";
			}
		});
		return tankNames;
	};

	return renderMain();
}

export default BallastOperationPreview;
