// tslint:disable: no-invalid-template-strings
import { ActionType, createAction } from "typesafe-actions";
import { IListObjectStore, RequireOne } from "../../_common/type";
import {
	EGRB2State,
	IGarbageAccidentalLoss,
	IGarbageBasicInfo,
	IGarbageDischargedIntoSea,
	IGarbageDischargedToReceptionAshoreOrOtherShips,
	IGarbageEditLog,
	IGRB2,
	TGarbageEntries,
	TGarbageQuestionsState,
	TGarbageQuestionWithAnswer,
	TGRB2Filters,
} from "./@types";

const grb2Constants = {
	FEATURE: "@grb-2",
	FORMS: {
		NEW_OPERATION: "NEW_OPERATION",
	},
};

enum EGRB2Actions {
	FORM_SET = "@grb-2/${newOperation}/[form]/set",
	FORM_EDIT = "@grb-2/${newOperation}/[form]/edit",
	FORM_RESET = "@grb-2/${newOperation}/reset",
	DISCHARGE_ENTRY_FORM_EDIT = "@grb-2/${newOperation}/[entryBasedForm]/edit",
	GARBAGE_EDIT_LOGS_SET = "@grb-2/${newOperation}/[basicForm]/${editLogs}/set",
	EDIT_LOGS_ANALYZE = "@grb-2/newOperation/analyze-edit-logs",
	EDIT_LOGS_EDIT = "@grb-2/${newOperation}/[basicForm]/${editLogs}/edit",

	OPERATION_DOCUMENT_CREATE = "@grb-2/:operation-document-create",
	NEW_OPERATION_PREVIEW = "@grb-2/:new-operation-preview",
	NEW_OPERATION_GO_BACK = "@grb-2/:new-operation-go-back",
	NEW_OPERATION_SUBMIT = "@grb-2/:new-operation-submit",
	NEW_OPERATION_STATE_SET = "@grb-2/newOperation/${newOperationState}/set",
	NEW_OPERATION_SUBMITTED = "@grb-2/#NEW_OPERATION_SUBMITTED",
	NEW_OPERATION_SET = "@grb-2/${newOperation}/set",
	NEW_OPERATION_SET_FOR_EDIT = "@grb-2/${newOperation}/edit",
	OPERATION_ATTACHMENT_UPLOAD = "@garbage/:operation-attachment-upload",

	HISTORY_LOAD = "@grb-2/:history-load",
	HISTORY_SET = "@grb-2/${history}/set",

	NEW_OPERATION_SUBMIT_SIGN = "@grb-2/:new-operation-submit-sign",
	APPROVE_ENTRY_SIGN = "@grb-2/:approve-entry-sign",
	STRIKE_OFF_ENTRY_SIGN = "@grb-2/:strike-off-entry-sign",
	EDIT_ENTRY_SIGN = "@grb-2/:edit-entry-sign",
	APPROVE_LAST_7_ENTRIES_SIGN = "@grb-2/:approve-last-7-entries-sign",

	GARBAGE_RECORD_STATE_SET = "@grb-2/${records}/[item]/${state}/set",
	GARBAGE_DISCHARGED_INTO_SEA_QUESTION_STATE_SET = "@grb-2/${isDischargedIntoSeaQuestionState}/set",
	GARBAGE_DISCHARGED_INTO_SEA_QUESTION_SET = "@grb-2/${garbageDischargedSeaQuestions}/set",

	FILTER_SET = "@orb1/:set-filter",
}

const grb2Actions = {
	commands: {
		operationDocumentCreate: createAction(
			EGRB2Actions.OPERATION_DOCUMENT_CREATE
		)(),
		newOperationPreview: createAction(EGRB2Actions.NEW_OPERATION_PREVIEW)(),
		newOperationSubmit: createAction(EGRB2Actions.NEW_OPERATION_SUBMIT)(),
		newOperationGoBack: createAction(EGRB2Actions.NEW_OPERATION_GO_BACK)(),
		historyLoad: createAction(
			EGRB2Actions.HISTORY_LOAD,
			(providedFilters: TGRB2Filters) => ({ providedFilters })
		)(),
		approveEntrySign: createAction(
			EGRB2Actions.APPROVE_ENTRY_SIGN,
			(grb2Id: string) => ({ grb2Id })
		)(),
		strikeOffEntrySign: createAction(
			EGRB2Actions.STRIKE_OFF_ENTRY_SIGN,
			(grb2Id: string) => ({ grb2Id })
		)(),
		editEntrySign: createAction(
			EGRB2Actions.EDIT_ENTRY_SIGN,
			(grb2Id: string) => ({ grb2Id })
		)(),
		approveLast7EntriesSign: createAction(
			EGRB2Actions.APPROVE_LAST_7_ENTRIES_SIGN,
			() => ({})
		)(),
		newOperationSubmitSign: createAction(
			EGRB2Actions.NEW_OPERATION_SUBMIT_SIGN
		)(),
		analyzeEditLog: createAction(
			EGRB2Actions.EDIT_LOGS_ANALYZE,
			(
				edits: RequireOne<
					Partial<IGarbageEditLog>,
					"fieldLabel" | "fieldName" | "newValue" | "pastValue"
				>
			) => ({ edits })
		)(),
		operationAttachmentUpload: createAction(
			EGRB2Actions.OPERATION_ATTACHMENT_UPLOAD,
			(attachmentFile: File) => ({ attachmentFile })
		)(),
	},
	events: {
		newOperationSubmitted: createAction(EGRB2Actions.NEW_OPERATION_SUBMITTED)(),
	},
	document: {
		formSet: createAction(EGRB2Actions.FORM_SET, () => ({}))(),
		basicFormEdit: createAction(
			EGRB2Actions.FORM_EDIT,
			(
				basicGarbageForm: Partial<
					IGarbageBasicInfo & { entry: TGarbageEntries }
				>
			) => ({
				basicGarbageForm,
			})
		)(),
		dischargeEntryFormEdit: createAction(
			EGRB2Actions.DISCHARGE_ENTRY_FORM_EDIT,
			(
				dischargeEntryBasedForm:
					| RequireOne<Partial<IGarbageAccidentalLoss>, "entry">
					| RequireOne<Partial<IGarbageDischargedIntoSea>, "entry">
					| RequireOne<
							Partial<IGarbageDischargedToReceptionAshoreOrOtherShips>,
							"entry"
					  >
			) => ({ dischargeEntryBasedForm })
		)(),
		formReset: createAction(EGRB2Actions.FORM_RESET, () => ({}))(),
		newOperationStateSet: createAction(
			EGRB2Actions.NEW_OPERATION_STATE_SET,
			(state: "RECORD" | "PREVIEW") => ({ state })
		)(),
		historySet: createAction(
			EGRB2Actions.HISTORY_SET,
			(history: IListObjectStore<IGRB2>) => ({ history })
		)(),
		grb2StateSet: createAction(
			EGRB2Actions.GARBAGE_RECORD_STATE_SET,
			(grb2Id: string, state: EGRB2State) => ({
				state,
				grb2Id,
			})
		)(),
		garbageEditLogsSet: createAction(
			EGRB2Actions.GARBAGE_EDIT_LOGS_SET,
			(editLogs: IGarbageEditLog[]) => ({ editLogs })
		)(),
		editLogsEdit: createAction(
			EGRB2Actions.EDIT_LOGS_EDIT,
			(editSession: number, edits: IGarbageEditLog) => ({
				editSession,
				edits,
			})
		)(),
		newOperationSetForEdit: createAction(
			EGRB2Actions.NEW_OPERATION_SET_FOR_EDIT,
			(grb2: IGRB2) => ({ grb2 })
		)(),
		newOperationSet: createAction(
			EGRB2Actions.NEW_OPERATION_SET,
			(grb2: IGRB2) => ({ grb2 })
		)(),
		garbageDischargedIntoSeaQuestionStateSet: createAction(
			EGRB2Actions.GARBAGE_DISCHARGED_INTO_SEA_QUESTION_STATE_SET,
			(dischargedIntoSeaQuestionState: TGarbageQuestionsState) => ({
				dischargedIntoSeaQuestionState,
			})
		)(),
		garbageDischargedIntoSeaQuestionSet: createAction(
			EGRB2Actions.GARBAGE_DISCHARGED_INTO_SEA_QUESTION_SET,
			(questions: TGarbageQuestionWithAnswer[]) => ({ questions })
		)(),
		filterSet: createAction(
			EGRB2Actions.FILTER_SET,
			(filter: TGRB2Filters) => ({
				filter,
			})
		)(),
	},
};

export type TGRB2Actions = ActionType<typeof grb2Actions>;
export default grb2Actions;
export { grb2Constants, EGRB2Actions };
