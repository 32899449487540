import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import insightSelectors from "redux/app/insight/selectors";
import { IStore } from "redux/store";
import plusIcon from "assets/icons/plus.svg";
import {
	EParameterTabType,
	INoonReportMetricListItem,
} from "redux/app/insight/@types";
import insightActions from "redux/app/insight/actions";
import { IListObjectStore } from "redux/_common/type";
import { storeMetricsToLocalStorage } from "redux/app/insight/utils";
import ArrowedString from "../../ArrowedString";

type OwnProps = {};
type NoonReportParameterListProps = PropsFromRedux & OwnProps;
function NoonReportParameterList(props: NoonReportParameterListProps) {
	const { noonReportMetricsList, searchText, selectedMetrics } = props;
	const [tempSelectedMetrics, setTempSelectedMetrics] = useState<string[]>([]);

	useEffect(() => {
		setTempSelectedMetrics(selectedMetrics);
	}, [selectedMetrics]);

	const noonReportMetricsListWithSearch = noonReportMetricsList.ids.filter(
		(idKey) => {
			const item = noonReportMetricsList.byIds[idKey];
			const { sectionTitle, subSectionTitle, metricTitle } = item;
			const textBeingSearched = searchText.toLowerCase();
			return (
				metricTitle?.toLowerCase().includes(textBeingSearched) ||
				subSectionTitle?.toLowerCase().includes(textBeingSearched) ||
				sectionTitle?.toLowerCase().includes(textBeingSearched)
			);
		}
	);

	return (
		<div>
			<div className={classNames("px-4 overflow-y-auto h-[calc(100vh-365px)]")}>
				{noonReportMetricsListWithSearch.map((metricKey) => {
					const listItem = noonReportMetricsList.byIds[metricKey];
					const { sectionTitle, subSectionTitle, metricTitle, key } = listItem;
					return (
						<div
							key={key}
							className="flex justify-between items-center py-2.5 pb-1.5 border-b border-gray-300 cursor-pointer min-h-[56px]"
							onClick={() => {
								let updatedTempSelectedMetrics = [...tempSelectedMetrics];

								updatedTempSelectedMetrics = tempSelectedMetrics.includes(key)
									? updatedTempSelectedMetrics.filter((m) => m !== key)
									: [...updatedTempSelectedMetrics, key];

								setTempSelectedMetrics(updatedTempSelectedMetrics);
							}}
						>
							<div className="w-[calc(100%-20px)]">
								<div className="text-gray-500 text-[12px]">
									<ArrowedString
										strArr={[sectionTitle ?? "", subSectionTitle ?? ""]}
										isSingle={false}
									/>
								</div>
								<h4 className="text-sm">{metricTitle} </h4>
							</div>
							<div>
								{tempSelectedMetrics.includes(key) ? (
									<span className="bp3-icon bp3-icon-tick text-lg text-green-500" />
								) : (
									<img
										className="cursor-pointer"
										src={plusIcon}
										alt="plus icon"
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
			<div
				onClick={() => {
					storeMetricsToLocalStorage<INoonReportMetricListItem>(
						tempSelectedMetrics,
						noonReportMetricsList,
						EParameterTabType.NOON_REPORT_TAB
					);
					props.updateSelectedMetrics(tempSelectedMetrics);
					props.toggleParameterMetricsList(false);
					props.setSearchText("");
				}}
				className="border-t flex justify-center items-center h-[46px] text-sm cursor-pointer "
			>
				Apply
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const selectedMetrics =
		insightSelectors.getNoonReportSelectedMetrics(insightStore);
	const noonReportMetricsList =
		insightSelectors.getNoonReportMetricsList(insightStore);
	const searchText = insightSelectors.getSearchInputText(insightStore);

	return {
		noonReportMetricsList,
		searchText,
		selectedMetrics,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleParameterMetricsList(value?: boolean) {
			dispatch(insightActions.document.toggleParameterMetricList(value));
		},
		updateSelectedMetrics(metrics: string[]) {
			dispatch(
				insightActions.document.noonReportRecentSelectedMetricToSelectedMetricsAdd(
					metrics
				)
			);
		},
		setNoonReportMetricsList(
			metricsList: IListObjectStore<INoonReportMetricListItem>
		) {
			dispatch(insightActions.document.noonReportMetricsListSet(metricsList));
		},
		setSearchText(searchText: string) {
			dispatch(insightActions.document.searchInputSet(searchText));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NoonReportParameterList);
