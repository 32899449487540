import React from "react";
import ReportCategories from "components/app/report/categories/Categories";

type ScreenReportCategoriesProps = React.PropsWithChildren<{}>;

function ScreenReportCategories(props: ScreenReportCategoriesProps) {
	return <ReportCategories />;
}

export default ScreenReportCategories;
