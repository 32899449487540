import React, { FC } from 'react';
import { getEmptyValueLabelObj, IData } from '.';
import { findSelectedObj } from './Category';
import WayshipDropdownDeck from './WayshipDropdownDeck';

interface IModulesProps {
    data: IData;
    modules: any;
    setData: React.Dispatch<React.SetStateAction<IData>>;
}

const Modules: FC<IModulesProps> = ({ data, modules, setData }) => {
    const range = modules && modules?.length > 0
    ? modules.map((m: any) => ({
        label: m?.title || m?.mnemonic,
        value: m?.mnemonic || m?.title,
    }))
    : [{ label: '', value: '' }];
    return (
        <div>
            <WayshipDropdownDeck
                isSubmit={data.section.value === '' || range[0].value === ''}
                title="Select Modules"
                dataKey=""
                value={data.modules.value}
                range={range}
                onChangeHandler={(selectedItem: any, dataKey: any) => {
                    const obj = findSelectedObj(range, selectedItem);
                    setData((prev) => ({
                        ...prev,
                        modules: {
                            label: obj?.label,
                            value: selectedItem
                        },
                        subModules: getEmptyValueLabelObj(),
                        metric:  getEmptyValueLabelObj(),
                        range: [undefined, undefined],
                        key: '',
                        remarks: '',
                    }));
                }}
            />
        </div>
    );
};

export default Modules;
