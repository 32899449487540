import React from "react";

import TrainingCertificateCmp from "components/app/tool/TrainingCertificate";

type TrainingCertificateProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
	location?: {
		pathname?: string;
	}
}>;

function TrainingCertificate(
	props: TrainingCertificateProps
) {
	return <TrainingCertificateCmp isApprovals={props?.location?.pathname?.includes('approvals')} />;
}

export default TrainingCertificate;
