import React from "react";
import moment from "moment";
import { Tag } from "@blueprintjs/core";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { vesselSelectors } from "../../../../../redux/app/vessel";
import { IStore } from "../../../../../redux/store";
import { ILog, ITabLog } from "../../../../../redux/app/logs/@types";
import ReactJson from "react-json-view";
import classNames from "classnames";
import { ISidebarObj } from "../../types";

type OwnProps = {
	logId: string;
	log: any;
	onClick(log: ITabLog): void;
	indexNumber: number;
	openSidebarModal: (data: ISidebarObj) => void;
};

type LogListRowProps = PropsFromRedux & OwnProps;
function LogListRow(props: LogListRowProps) {
	const { log, isVesselLoading} = props;

	const discrepanciesCount = log?.discrepancies;

	return (
		<tr key={log._id}>
			<td className="text-gray-400">{props.indexNumber}</td>
			<td className="py-1 font-medium text-gray-800">{log.title}</td>
			{/* <ReactJson src={{vesselId : log.vesselId, logId: log._id, logVessel: props.logVessel}} collapsed={true} /> */}
			{/* <td>
				<div>
					{isVesselLoading
						? "Loading vessels"
						: log?.vessel?.vesselName
						? log.vessel.vesselName
						: "Not Found"}
				</div>
			</td> */}
			<td className="font-mono text-center text-gray-700">
				{moment(log.selectedDate).format("DD-MM-YYYY")}
			</td>
			<td className="font-mono text-center">
				<div className="relative inline-block transform scale-90">
					{log?.code === "engineLogConfig" &&
						discrepanciesCount > 0 && (
							<div className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 px-1 py-1 text-sm text-white bg-red-600 rounded-full ">
								{discrepanciesCount}
							</div>
						)}
					{log?.code === "engineLogConfig" &&
						discrepanciesCount > 0 && (
							<button
								onClick={function onClick() {
									props.onClick(log);
								}}
								className={classNames(
									"mx-2 my-2 rounded-full outline-none ",
									{
										"text-red-600 bg-red-100 hover:bg-red-200 focus:bg-red-200":
											discrepanciesCount > 0,

										"text-gray-700 bg-lightGray hover:bg-blue-200 focus:bg-blue-300":
											!(discrepanciesCount > 0),
									}
								)}
								title={
									discrepanciesCount > 0
										? `${discrepanciesCount} Discrepancies Found`
										: "Smart Alerts"
								}
							>
								<span className="m-4 text-lg bp3-icon-warning-sign bp3-icon" />
							</button>
						)}
				</div>
			</td>
			<td className="text-center">
				<button
					className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700"
					onClick={function onClick() {
						props.onClick(log);
					}}
				>
					View
				</button>
			</td>
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {

	return {
	
		isVesselLoading: vesselSelectors.jobToLoadVessels(store.app.vesselStore)
			.isLoading,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(LogListRow);
