const APPROVAL_LIST_LOAD = "@approval/list/LOAD"; // COMMAND ACTION
const APPROVAL_LIST_SET = '@approval/list/SET';
const APPROVAL_BY_ID = '@approval/id/UPDATE';
const DECLINE_BY_ID = '@approval/id/UPDATEDECLINE';
const LOAD_PRINT_TEMPLATE = "@approval/print/LOAD";
const approvalActionTypes = {
	APPROVAL_LIST_LOAD,
	APPROVAL_LIST_SET,
	APPROVAL_BY_ID,
	DECLINE_BY_ID,
	LOAD_PRINT_TEMPLATE,
};

export default approvalActionTypes;
