import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { EParameterTabType, IMetricListItem } from "redux/app/insight/@types";
import insightSelectors from "redux/app/insight/selectors";
import { getMetricsList } from "redux/app/insight/utils";
import { IStore } from "redux/store";
import { IListObjectStore } from "redux/_common/type";
import insightActions from "redux/app/insight/actions";
import { commonUtils } from "redux/_common";
import EngineMetricParameterList from "./EngineMetricParameterList";
import EngineMetricSelectedAndRecentList from "./EngineMetricSelectedAndRecentList";
import Skeleton from "react-loading-skeleton";

type OwnProps = {};
type EngineParameterProps = PropsFromRedux & OwnProps;
function EngineParameter(props: EngineParameterProps) {
	const {
		engineLogConfig,
		currentTab,
		showParameterMetricList,
		jobToLoadConfig,
	} = props;
	useEffect(() => {
		if (engineLogConfig?.logCategories) {
			let metricsArr: any = [];

			const logCategories = engineLogConfig?.logCategories;
			const metricsList = getMetricsList(
				metricsArr,
				logCategories
			) as IMetricListItem[];

			props.setEngineLogMetricsList(commonUtils.arrayToObject(metricsList));
		} else {
			props.setEngineLogMetricsList({
				ids: [],
				byIds: {},
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [engineLogConfig?.vesselId]);

	useEffect(() => {
		if (engineLogConfig?.logCategories?.length) {
			const stateOfShipMnemonic = "wd";
			const stateOfShipMetricObj = engineLogConfig?.logCategories.find(
				(category: any) => category?.mnemonic === stateOfShipMnemonic
			);

			if (stateOfShipMetricObj?.sections?.length) {
				const dropdownValues =
					stateOfShipMetricObj?.sections?.[0]?.metrics?.[0]?.readingRange
						?.range;
				props.setStateOfShipDropdownOptions(dropdownValues);
			} else {
				props.setStateOfShipDropdownOptions([]);
			}
		} else {
			props.setStateOfShipDropdownOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [engineLogConfig?.logCategories]);

	if (
		showParameterMetricList &&
		currentTab === EParameterTabType.ENGINE_LOG_TAB &&
		jobToLoadConfig.isLoading
	) {
		return (
			<div>
				{Array.from({ length: 15 }).map((_, index) => {
					return (
						<Skeleton key={index} className="mx-1 mt-0.5 w-[97%]" height={56} />
					);
				})}
			</div>
		);
	}

	return (
		<>
			{showParameterMetricList && currentTab === "engine-log" && (
				<EngineMetricParameterList />
			)}
			{!showParameterMetricList && currentTab === "engine-log" && (
				<EngineMetricSelectedAndRecentList />
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const engineLogConfig = insightSelectors.getEngineLogConfig(insightStore);
	const currentTab = insightSelectors.getParameterCurrentTab(insightStore);
	const showParameterMetricList =
		insightSelectors.getShowParameterMetricList(insightStore);
	const jobToLoadConfig = insightSelectors.jobToLoadConfig(insightStore);
	return {
		engineLogConfig,
		currentTab,
		showParameterMetricList,
		jobToLoadConfig,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setEngineLogMetricsList(metricsList: IListObjectStore<IMetricListItem>) {
			dispatch(insightActions.document.engineLogMetricsListSet(metricsList));
		},
		setStateOfShipDropdownOptions(options: { label: string; value: string }[]) {
			dispatch(insightActions.document.stateOfShipDropdownOptionsSet(options));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(EngineParameter);
