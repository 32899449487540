import { IVesselCrew } from "../types";
import moment from "moment";
import { ESIGNAL_REPORT_TYPE_VAL } from "./types";
function chunkString(str: string, len: number) {
	const size = Math.ceil(str.length / len);
	const r = Array(size);
	let offset = 0;
	for (let i = 0; i < size; i++) {
		r[i] = str.substr(offset, len);
		offset += len;
	}
	return r;
}

export default {
	/**
	 * Crew Object of server is not with exact format of IVesseCrew.. So this function is required to convert it
	 * @param crewFromServer - Crew Object got from server and is incompatible with clients IVesseCrew Format
	 */
	getCrewFromServerCrewData(crewFromServer: any): IVesselCrew {
		return {
			designation: crewFromServer.designation?.name,
			// fromDate: moment(crewFromServer.fromDate).format("YYYY-MM-DD"),
			// toDate: moment(crewFromServer.toDate).format("YYYY-MM-DD"),
			id: crewFromServer.id,
			// image: crewFromServer.image,
			// userId: crewFromServer.userId,
			name: crewFromServer.name,
			staffId: crewFromServer.staffId,
			vesselId: crewFromServer.designation.vesselId,
		};
	},
	stringToMacAddress(macString: string) {
		const chunks = chunkString(macString, 2);
		let formattedMac = "";
		chunks.map((chunk, index) => {
			index === 0
				? (formattedMac = `${chunk}`)
				: (formattedMac = `${formattedMac}${chunk}`);
		});
		return formattedMac;
	},
	macAddressToString(macAddressFormat: string) {
		const aadhar = macAddressFormat.replace(/:/g, "");
		return aadhar;
	},
};

export function getPaddedDate(date: string) {
	const day = moment(date).format("Do MMM");
	const monthPart = day.slice(-4);
	const datePart = day.slice(0, -4);

	const modifiedDatePart = datePart.length === 3 ? `0${datePart}` : datePart;
	return modifiedDatePart + monthPart;
}

export function formatSRTypeValues(signalReportType?: ESIGNAL_REPORT_TYPE_VAL){
	// One place in config as Streaming, can't change there so
	if(signalReportType === ESIGNAL_REPORT_TYPE_VAL.STEAMING) return 'steaming' //ESIGNAL_REPORT_TYPE_VAL.STEAMING contains value as 'streaming'
	return signalReportType
}
