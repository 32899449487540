import withJobs from "redux/_core/job/reducers";
import { ITopNavbarActions_Document, ITopNavbarStore } from "./@types";
import { createReducer } from "typesafe-actions";
import topNavbarActions from "./actions";
import topNavbarConstants from "./constants";

const initialState: ITopNavbarStore = {
	_jobs: {},
	searchText: "",
	navTitle: "",
	searchPlaceholder: "",
};

const topNavbarReducers = createReducer<
	ITopNavbarStore,
	ITopNavbarActions_Document
>(initialState)
	.handleAction(
		topNavbarActions.document.searchTextSet,
		function searchTextSet(state, action) {
			const searchText = action.payload.searchText;
			return {
				...state,
				searchText,
			};
		}
	)
	.handleAction(
		topNavbarActions.document.navbarTitleSet,
		function navbarTitleSet(state, action) {
			const navTitle = action.payload.title;
			return {
				...state,
				navTitle,
			};
		}
	)
	.handleAction(
		topNavbarActions.document.navbarSearchPlaceholderSet,
		function navbarSearchPlaceholderSet(state, action) {
			const placeholder = action.payload.placeholder;
			return {
				...state,
				searchPlaceholder: placeholder,
			};
		}
	);

export default withJobs(
	topNavbarConstants.FEATURE,
	initialState,
	// @ts-ignore
	topNavbarReducers
);
