import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { rootRoutes } from "../../../routes";
import User from "components/app/user/User";
import ScreenUserList from "./list";
import ScreenUserProfile from "./profile";

const userRoutes = rootRoutes.children.app.children.users.children;
type ScreenUserProps = React.PropsWithChildren<{}>;
function ScreenUser(props: ScreenUserProps) {
	return (
		<User>
			<Switch>
				<Route path={userRoutes.list.path} component={ScreenUserList} />
				<Route path={userRoutes.profile.path} component={ScreenUserProfile} />
				<Route render={RedirectComponent} />
			</Switch>
		</User>
	);
}

function RedirectComponent() {
	return <Redirect to={userRoutes.list.url()} />;
}
export default ScreenUser;
