import React from "react";
import classNames from "classnames";
import DefaultInput from "../../../@set-product/core/Inputs/DefaultInput";
import Button from "../../../@set-product/core/button";
import { connect } from "react-redux";
import { IStore } from "../../../redux/store";
import { authSelectors, authActions } from "../../../redux/app/auth";
import { Dispatch } from "redux";
import {
	IAuth_Get_LoginData,
	IAuthFormLoginFields,
} from "../../../redux/app/auth/types";
import { historyActions } from "../../../redux/_core/history";
import { rootRoutes } from "../../../routes";
type LoginProps = IAuth_Get_LoginData & {
	onChange: (field: Partial<IAuthFormLoginFields>) => void;
	onSubmit: () => void;
	onResetRequest: () => void;
	onNext: (email: string) => void;
	isPassFieldVisible?: 'YES'| 'NO';
};
function Login(props: LoginProps) {
	const { form, loginJob, 
	onChange, 
	onSubmit, 
	onResetRequest, 
	onNext, 
	} = props;
	const isPassFieldVisible = props.isPassFieldVisible;
	console.log(props.isPassFieldVisible);
	console.log('isPassFieldVisible');
	const { fields, errors, hasErrors } = form;
	const { email, password } = fields;
	return (
		<div>
			<form
				className="my-6"
				onSubmit={(e) => {
					e.preventDefault();
					if (password) {
						onSubmit();
					} else {
						onNext(email);
					}
				}}
			>
				<div className="my-6">
					<h4
						className={classNames(
							"font-medium text-2xl font-redhat-text text-gray-500" // Font [Font Size, Font style etc]
						)}
					>
						Login
					</h4>
				</div>
				<div className="w-full my-3">
					<DefaultInput
						value={email}
						fill={true}
						placeholder="Email ID"
						label="Email"
						error={!errors.email.isValid}
						errorHelperText={errors.email.message}
						onChange={(e) => {
							e.preventDefault();
							e.persist();
							onChange({ email: (e.target as HTMLInputElement).value });
						}}
						disabled={loginJob.isLoading}
					/>
				</div>
				{isPassFieldVisible === 'YES'?(
					<>
						<div className="w-full my-3">
							<DefaultInput
								value={password}
								fill={true}
								placeholder="Password"
								type="password"
								label="Password"
								error={!errors.password.isValid}
								errorHelperText={errors.password.message}
								onChange={(e) => {
									e.preventDefault();
									onChange({ password: (e.target as HTMLInputElement).value });
								}}
								disabled={loginJob.isLoading}
							/>
						</div>
						<div className="w-full my-8">
						<Button
							type="submit"
							color="primary"
							text="SIGN IN"
							fill={true}
							view="filled"
							className="shadow-none"
							disabled={loginJob.isLoading}
							/>
						</div>
					</>
				):<div className="w-full my-8">
					<Button
						type="submit"
						color="primary"
						text="Next"
						fill={true}
						view="filled"
						className="shadow-none"
						disabled={loginJob.isLoading}
						/>
					</div>}
				
				
			</form>
			<div className="my-8">
				<div className="self-center my-3 text-center">
					<span className="text-gray-600">To Reset Password</span>{" "}
					<div>
						<Button
							view="flat"
							className="h-auto p-0 m-0 text-blue-500 border-0 hover:shadow-none"
							onClick={() => onResetRequest()}
						>
							Click here
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (store: IStore) => {
	const {form, loginJob} = authSelectors(store.app.auth).getLoginData();
	const isPassFieldVisible = authSelectors(store.app.auth).isPassFieldVisible();
	return {
		form, loginJob, isPassFieldVisible
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (field: any) =>
		dispatch(authActions._forms.login.formFieldsEdit(field)),
	onSubmit: () => dispatch(authActions._forms.login.formSubmit()),
	onNext: (email: string) => dispatch(authActions.login(email, "")),
	onResetRequest: () =>
		dispatch(
			historyActions.push(
				"#LOGIN",
				rootRoutes.children.auth.children.reset_request.url()
			)
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
