import React from "react";
import InputWrapper, { InputWrapperProps } from "../../../_common/InputWrapper";
import classNames from "classnames";
import Downshift from "downshift";
import ReactJson from "react-json-view";

const countries: { [key: string]: { country: string; flag: string } } = {
	[""]: { flag: "", country: "" },
	AD: { flag: "AD", country: "Andorra" },
	AE: { flag: "AE", country: "United Arab Emirates" },
	AF: { flag: "AF", country: "Afghanistan" },
	AG: { flag: "AG", country: "Antigua and Barbuda" },
	AI: { flag: "AI", country: "Anguilla" },
	AH: { flag: "AH", country: "Aleyska" },
	AL: { flag: "AL", country: "Albania" },
	AM: { flag: "AM", country: "Armenia" },
	AN: { flag: "AN", country: "Netherlands Antilles" },
	AO: { flag: "AO", country: "Angola" },
	AQ: { flag: "AQ", country: "Antarctica" },
	AR: { flag: "AR", country: "Argentina" },
	AS: { flag: "AS", country: "American Samoa" },
	AT: { flag: "AT", country: "Austria" },
	AU: { flag: "AU", country: "Australia" },
	AW: { flag: "AW", country: "Aruba" },
	AX: { flag: "AX", country: "Åland Islands" },
	AZ: { flag: "AZ", country: "Azerbaijan" },
	BA: { flag: "BA", country: "Bosnia and Herzegovina" },
	BB: { flag: "BB", country: "Barbados" },
	BD: { flag: "BD", country: "Bangladesh" },
	BE: { flag: "BE", country: "Belgium" },
	BF: { flag: "BF", country: "Burkina Faso" },
	BG: { flag: "BG", country: "Bulgaria" },
	BH: { flag: "BH", country: "Bahrain" },
	BI: { flag: "BI", country: "Burundi" },
	BJ: { flag: "BJ", country: "Benin" },
	BL: { flag: "BL", country: "Saint Barthélemy" },
	BM: { flag: "BM", country: "Bermuda" },
	BN: { flag: "BN", country: "Brunei Darussalam" },
	BO: { flag: "BO", country: "Bolivia" },
	BQ: { flag: "BQ", country: "Bonaire, Sint Eustatius and Saba" },
	BR: { flag: "BR", country: "Brazil" },
	BS: { flag: "BS", country: "Bahamas" },
	BT: { flag: "BT", country: "Bhutan" },
	BV: { flag: "BV", country: "Bouvet Island" },
	BW: { flag: "BW", country: "Botswana" },
	BY: { flag: "BY", country: "Belarus" },
	BZ: { flag: "BZ", country: "Belize" },
	CA: { flag: "CA", country: "Canada" },
	CC: { flag: "CC", country: "Cocos (Keeling) Islands" },
	CD: { flag: "CD", country: "Congo, The Democratic Republic Of The" },
	CF: { flag: "CF", country: "Central African Republic" },
	CG: { flag: "CG", country: "Congo" },
	CH: { flag: "CH", country: "Switzerland" },
	CI: { flag: "CI", country: "Côte D'Ivoire" },
	CK: { flag: "CK", country: "Cook Islands" },
	CL: { flag: "CL", country: "Chile" },
	CM: { flag: "CM", country: "Cameroon" },
	CN: { flag: "CN", country: "China" },
	CO: { flag: "CO", country: "Colombia" },
	CR: { flag: "CR", country: "Costa Rica" },
	CU: { flag: "CU", country: "Cuba" },
	CV: { flag: "CV", country: "Cape Verde" },
	CW: { flag: "CW", country: "Curaçao" },
	CX: { flag: "CX", country: "Christmas Island" },
	CY: { flag: "CY", country: "Cyprus" },
	CZ: { flag: "CZ", country: "Czech Republic" },
	DE: { flag: "DE", country: "Germany" },
	DJ: { flag: "DJ", country: "Djibouti" },
	DK: { flag: "DK", country: "Denmark" },
	DM: { flag: "DM", country: "Dominica" },
	DO: { flag: "DO", country: "Dominican Republic" },
	DZ: { flag: "DZ", country: "Algeria" },
	EC: { flag: "EC", country: "Ecuador" },
	EE: { flag: "EE", country: "Estonia" },
	EG: { flag: "EG", country: "Egypt" },
	EH: { flag: "EH", country: "Western Sahara" },
	ER: { flag: "ER", country: "Eritrea" },
	ES: { flag: "ES", country: "Spain" },
	ET: { flag: "ET", country: "Ethiopia" },
	FI: { flag: "FI", country: "Finland" },
	FJ: { flag: "FJ", country: "Fiji" },
	FK: { flag: "FK", country: "Falkland Islands (Malvinas)" },
	FM: { flag: "FM", country: "Micronesia, Federated States Of" },
	FO: { flag: "FO", country: "Faroe Islands" },
	FR: { flag: "FR", country: "France" },
	GA: { flag: "GA", country: "Gabon" },
	GB: { flag: "GB", country: "United Kingdom" },
	GD: { flag: "GD", country: "Grenada" },
	GE: { flag: "GE", country: "Georgia" },
	GF: { flag: "GF", country: "French Guiana" },
	GG: { flag: "GG", country: "Guernsey" },
	GH: { flag: "GH", country: "Ghana" },
	GI: { flag: "GI", country: "Gibraltar" },
	GL: { flag: "GL", country: "Greenland" },
	GM: { flag: "GM", country: "Gambia" },
	GN: { flag: "GN", country: "Guinea" },
	GP: { flag: "GP", country: "Guadeloupe" },
	GQ: { flag: "GQ", country: "Equatorial Guinea" },
	GR: { flag: "GR", country: "Greece" },
	GS: { flag: "GS", country: "South Georgia and the South Sandwich Islands" },
	GT: { flag: "GT", country: "Guatemala" },
	GU: { flag: "GU", country: "Guam" },
	GW: { flag: "GW", country: "Guinea-Bissau" },
	GY: { flag: "GY", country: "Guyana" },
	HK: { flag: "HK", country: "Hong Kong" },
	HM: { flag: "HM", country: "Heard and McDonald Islands" },
	HN: { flag: "HN", country: "Honduras" },
	HR: { flag: "HR", country: "Croatia" },
	HT: { flag: "HT", country: "Haiti" },
	HU: { flag: "HU", country: "Hungary" },
	ID: { flag: "ID", country: "Indonesia" },
	IE: { flag: "IE", country: "Ireland" },
	IL: { flag: "IL", country: "Israel" },
	IM: { flag: "IM", country: "Isle of Man" },
	IN: { flag: "IN", country: "India" },
	IO: { flag: "IO", country: "British Indian Ocean Territory" },
	IQ: { flag: "IQ", country: "Iraq" },
	IR: { flag: "IR", country: "Iran, Islamic Republic Of" },
	IS: { flag: "IS", country: "Iceland" },
	IT: { flag: "IT", country: "Italy" },
	JE: { flag: "JE", country: "Jersey" },
	JM: { flag: "JM", country: "Jamaica" },
	JO: { flag: "JO", country: "Jordan" },
	JP: { flag: "JP", country: "Japan" },
	KE: { flag: "KE", country: "Kenya" },
	KG: { flag: "KG", country: "Kyrgyzstan" },
	KH: { flag: "KH", country: "Cambodia" },
	KI: { flag: "KI", country: "Kiribati" },
	KM: { flag: "KM", country: "Comoros" },
	KN: { flag: "KN", country: "Saint Kitts And Nevis" },
	KP: { flag: "KP", country: "Korea" },
	KR: { flag: "KR", country: "Korea" },
	KW: { flag: "KW", country: "Kuwait" },
	KY: { flag: "KY", country: "Cayman Islands" },
	KZ: { flag: "KZ", country: "Kazakhstan" },
	LA: { flag: "LA", country: "Lao People's Democratic Republic" },
	LB: { flag: "LB", country: "Lebanon" },
	LC: { flag: "LC", country: "Saint Lucia" },
	LI: { flag: "LI", country: "Liechtenstein" },
	LK: { flag: "LK", country: "Sri Lanka" },
	LR: { flag: "LR", country: "Liberia" },
	LS: { flag: "LS", country: "Lesotho" },
	LT: { flag: "LT", country: "Lithuania" },
	LU: { flag: "LU", country: "Luxembourg" },
	LV: { flag: "LV", country: "Latvia" },
	LY: { flag: "LY", country: "Libya" },
	MA: { flag: "MA", country: "Morocco" },
	MC: { flag: "MC", country: "Monaco" },
	MD: { flag: "MD", country: "Moldova, Republic of" },
	ME: { flag: "ME", country: "Montenegro" },
	MF: { flag: "MF", country: "Saint Martin" },
	MG: { flag: "MG", country: "Madagascar" },
	MH: { flag: "MH", country: "Marshall Islands" },
	MK: { flag: "MK", country: "Macedonia" },
	ML: { flag: "ML", country: "Mali" },
	MM: { flag: "MM", country: "Myanmar" },
	MN: { flag: "MN", country: "Mongolia" },
	MO: { flag: "MO", country: "Macao" },
	MP: { flag: "MP", country: "Northern Mariana Islands" },
	MQ: { flag: "MQ", country: "Martinique" },
	MR: { flag: "MR", country: "Mauritania" },
	MS: { flag: "MS", country: "Montserrat" },
	MT: { flag: "MT", country: "Malta" },
	MU: { flag: "MU", country: "Mauritius" },
	MV: { flag: "MV", country: "Maldives" },
	MW: { flag: "MW", country: "Malawi" },
	MX: { flag: "MX", country: "Mexico" },
	MY: { flag: "MY", country: "Malaysia" },
	MZ: { flag: "MZ", country: "Mozambique" },
	NA: { flag: "NA", country: "Namibia" },
	NC: { flag: "NC", country: "New Caledonia" },
	NE: { flag: "NE", country: "Niger" },
	NF: { flag: "NF", country: "Norfolk Island" },
	NG: { flag: "NG", country: "Nigeria" },
	NI: { flag: "NI", country: "Nicaragua" },
	NL: { flag: "NL", country: "Netherlands" },
	NO: { flag: "NO", country: "Norway" },
	NP: { flag: "NP", country: "Nepal" },
	NR: { flag: "NR", country: "Nauru" },
	NU: { flag: "NU", country: "Niue" },
	NZ: { flag: "NZ", country: "New Zealand" },
	OM: { flag: "OM", country: "Oman" },
	PA: { flag: "PA", country: "Panama" },
	PE: { flag: "PE", country: "Peru" },
	PF: { flag: "PF", country: "French Polynesia" },
	PG: { flag: "PG", country: "Papua New Guinea" },
	PH: { flag: "PH", country: "Philippines" },
	PK: { flag: "PK", country: "Pakistan" },
	PL: { flag: "PL", country: "Poland" },
	PM: { flag: "PM", country: "Saint Pierre And Miquelon" },
	PN: { flag: "PN", country: "Pitcairn" },
	PR: { flag: "PR", country: "Puerto Rico" },
	PS: { flag: "PS", country: "Palestine, State of" },
	PT: { flag: "PT", country: "Portugal" },
	PW: { flag: "PW", country: "Palau" },
	PY: { flag: "PY", country: "Paraguay" },
	QA: { flag: "QA", country: "Qatar" },
	RE: { flag: "RE", country: "Réunion" },
	RO: { flag: "RO", country: "Romania" },
	RS: { flag: "RS", country: "Serbia" },
	RU: { flag: "RU", country: "Russian Federation" },
	RW: { flag: "RW", country: "Rwanda" },
	SA: { flag: "SA", country: "Saudi Arabia" },
	SB: { flag: "SB", country: "Solomon Islands" },
	SC: { flag: "SC", country: "Seychelles" },
	SD: { flag: "SD", country: "Sudan" },
	SE: { flag: "SE", country: "Sweden" },
	SG: { flag: "SG", country: "Singapore" },
	SH: { flag: "SH", country: "Saint Helena" },
	SI: { flag: "SI", country: "Slovenia" },
	SJ: { flag: "SJ", country: "Svalbard And Jan Mayen" },
	SK: { flag: "SK", country: "Slovakia" },
	SL: { flag: "SL", country: "Sierra Leone" },
	SM: { flag: "SM", country: "San Marino" },
	SN: { flag: "SN", country: "Senegal" },
	SO: { flag: "SO", country: "Somalia" },
	SR: { flag: "SR", country: "Suriname" },
	SS: { flag: "SS", country: "South Sudan" },
	ST: { flag: "ST", country: "Sao Tome and Principe" },
	SV: { flag: "SV", country: "El Salvador" },
	SX: { flag: "SX", country: "Sint Maarten" },
	SY: { flag: "SY", country: "Syrian Arab Republic" },
	SZ: { flag: "SZ", country: "Swaziland" },
	TC: { flag: "TC", country: "Turks and Caicos Islands" },
	TD: { flag: "TD", country: "Chad" },
	TF: { flag: "TF", country: "French Southern Territories" },
	TG: { flag: "TG", country: "Togo" },
	TH: { flag: "TH", country: "Thailand" },
	TJ: { flag: "TJ", country: "Tajikistan" },
	TK: { flag: "TK", country: "Tokelau" },
	TL: { flag: "TL", country: "Timor-Leste" },
	TM: { flag: "TM", country: "Turkmenistan" },
	TN: { flag: "TN", country: "Tunisia" },
	TO: { flag: "TO", country: "Tonga" },
	TR: { flag: "TR", country: "Turkey" },
	TT: { flag: "TT", country: "Trinidad and Tobago" },
	TV: { flag: "TV", country: "Tuvalu" },
	TW: { flag: "TW", country: "Taiwan, Republic Of China" },
	TZ: { flag: "TZ", country: "Tanzania, United Republic of" },
	UA: { flag: "UA", country: "Ukraine" },
	UG: { flag: "UG", country: "Uganda" },
	UM: { flag: "UM", country: "United States Minor Outlying Islands" },
	US: { flag: "US", country: "United States" },
	UY: { flag: "UY", country: "Uruguay" },
	UZ: { flag: "UZ", country: "Uzbekistan" },
	VA: { flag: "VA", country: "Holy See (Vatican City State)" },
	VC: { flag: "VC", country: "Saint Vincent And The Grenadines" },
	VE: { flag: "VE", country: "Venezuela, Bolivarian Republic of" },
	VG: { flag: "VG", country: "Virgin Islands, British" },
	VI: { flag: "VI", country: "Virgin Islands, U.S." },
	VN: { flag: "VN", country: "Vietnam" },
	VU: { flag: "VU", country: "Vanuatu" },
	WF: { flag: "WF", country: "Wallis and Futuna" },
	WS: { flag: "WS", country: "Samoa " },
	YE: { flag: "YE", country: "Yemen" },
	YT: { flag: "YT", country: "Mayotte" },
	ZA: { flag: "ZA", country: "South Africa" },
	ZM: { flag: "ZM", country: "Zambia" },
	ZW: { flag: "ZW", country: "Zimbabwe" },
};

type CountrySelectInputProps = React.PropsWithChildren<{
	inputWrapperProps: InputWrapperProps;
	value?: string;
	onChange(value: { flag: string; country: string } | null): void;
	defaultFlag: string;
	disabled?: boolean;
}>;
function CountrySelectInput(props: CountrySelectInputProps) {
	const { disabled, onChange, inputWrapperProps, ...restProps } = props;

	const {
		className: inputWrapperClassName,
		...restInputWrapperProps
	} = inputWrapperProps;
	return (
		<Downshift
			itemToString={(item) => (item ? item.country : "")}
			onChange={(selection) => onChange(selection)}
			initialSelectedItem={
				countries[props.defaultFlag]
					? countries[props.defaultFlag]
					: { flag: "", country: "" }
			}
			selectedItem={
				countries[props.defaultFlag]
					? countries[props.defaultFlag]
					: { flag: "", country: "" }
			}
			{...restProps}
			stateReducer={function reducer(state, changes) {
				if (
					changes.type === Downshift.stateChangeTypes.clickItem ||
					changes.type === Downshift.stateChangeTypes.keyDownEnter
				)
					setTimeout(
						() => document.getElementById("country-view-2eaaa357")?.focus(),
						100
					);

				return changes;
			}}
		>
			{({
				getRootProps,
				getInputProps,
				getToggleButtonProps,
				getItemProps,
				getMenuProps,
				isOpen,
				highlightedIndex,
				selectedItem,
				inputValue,
			}) => (
				<div
					className="p-0 border-0 ws-input"
					{...getRootProps(undefined, { suppressRefError: true })}
				>
					<InputWrapper
						className={classNames(
							"relative flex-row justify-start w-full p-0 m-0 ",
							inputWrapperClassName
						)}
						{...restInputWrapperProps}
					>
						{isOpen ? (
							<>
								<input
									{...getInputProps({
										type: "text",
										name: "country-input-59d6576a",
										id: "country-input-59d6576a",
										className:
											"w-full my-0 rounded-b-none rounded-r-none ws-input",
									})}
								/>
								<div
									className="flex-col items-center justify-center px-3 my-0 border-l-0 rounded-b-none rounded-l-none ws-input hover:bg-gray-200 focus:bg-gray-300"
									{...getToggleButtonProps({ disabled })}
								>
									<span className="text-gray-700 text-md bp3-icon bp3-icon-caret-down" />
								</div>
							</>
						) : (
							<button
								{...getToggleButtonProps({
									id: "country-view-2eaaa357",
									disabled,
									className:
										"w-full my-0 rounded-b-none rounded-r-none ws-input text-left flex flex-row justify-start items-center cursor-pointer outline-none",
									onClick: () =>
										setTimeout(function onTimeout() {
											document
												.getElementById("country-input-59d6576a")
												?.focus();
											(document.getElementById(
												"country-input-59d6576a"
											) as HTMLInputElement)?.select();
										}, 100),
									onKeyDown(e) {
										if (e.key === "ArrowDown") {
											setTimeout(function onTimeout() {
												document
													.getElementById("country-input-59d6576a")
													?.focus();
												(document.getElementById(
													"country-input-59d6576a"
												) as HTMLInputElement)?.select();
											}, 100);
										}
									},
								})}
							>
								{selectedItem?.flag ? (
									<img
										src={`https://www.countryflags.io/${selectedItem?.flag}/flat/16.png`}
										alt="Flag"
									/>
								) : null}
								<span className="ml-2">
									{countries[`${selectedItem?.flag}`].country ? (
										countries[`${selectedItem?.flag}`].country
									) : (
										<span>&nbsp;</span>
									)}
								</span>
							</button>
						)}
					</InputWrapper>
					<div className="relative h-0">
						{isOpen ? (
							<ul
								className="absolute top-0 z-50 w-full p-0 m-0 overflow-y-auto bg-white border-t-0 rounded-t-none shadow-lg ws-input max-h-56 ws-scroll rounded-b-md"
								{...getMenuProps({})}
							>
								{Object.keys(countries)
									.filter((flag) =>
										inputValue
											? flag.includes(inputValue) ||
											  countries[flag].country.includes(inputValue)
											: true
									)
									.sort()
									.map((flag, index) => {
										return (
											<li
												key={index}
												{...getItemProps({
													item: countries[flag],
													key: flag,
												})}
											>
												<div
													className={classNames(
														"m-0 ws-input bg-white border-transparent hover:bg-gray-200 flex flex-row justify-start items-center cursor-pointer w-full focus:bg-gray-300 hover:border-gray-400",
														{
															"border-gray-700 bg-gray-300":
																highlightedIndex === index,
															"border-blue-700 bg-blue-300":
																selectedItem?.country ===
																countries[flag].country,
														}
													)}
												>
													{flag ? (
														<img
															src={`https://www.countryflags.io/${flag}/flat/24.png`}
															alt="Flag"
														/>
													) : null}
													<span className="ml-2">
														{countries[flag].country ? (
															countries[flag].country
														) : (
															<span>&nbsp;</span>
														)}
													</span>
												</div>
											</li>
										);
									})}
							</ul>
						) : null}
					</div>
				</div>
			)}
		</Downshift>
	);
}

export default CountrySelectInput;
