import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// tslint:disable-next-line: no-submodule-imports
import storage from "redux-persist/lib/storage";
import historyReducers from "./_core/history";
import notificationReducers from "./_core/notification";
import { jobGlobalReducer } from "./_core/job";
import authReducers from "./app/auth";
import vesselReducers from "./app/vessel";
import logReducers from "./app/logs";
import approvalReducers from "./app/approval";
import marpolReducers from "./app/marpol";
import extReducers from "./app/ext";
import checklistReducers from "./app/checklist/reducers";
import feedbackReducers from "./app/feedback/reducers";
import userReducers from "./app/user";
import topNavbarReducers from "./app/topnavbar";
import dashboardReducers from "./app/dashboard";
import reportReducers from "./app/report";
import toolReducers from "./app/tool";
import insightReducers from "./app/insight";
import grb2Reducers from "./app/grb2";
import garbageRecordReducers from "./app/garbage-record";

const appPersistConfig = {
	key: "app",
	storage,
	whitelist: ["auth"],
	debug: true,
};

const rootReducer = combineReducers({
	core: combineReducers({
		history: historyReducers,
		notification: notificationReducers,
		jobGlobal: jobGlobalReducer,
	}),
	app: persistReducer(
		appPersistConfig,
		combineReducers({
			auth: authReducers,
			vesselStore: vesselReducers,
			approvalStore: approvalReducers,
			checklistStore: checklistReducers,
			logs: logReducers,
			marpol: marpolReducers,
			extStore: extReducers,
			feedback: feedbackReducers,
			user: userReducers,
			topnavbar: topNavbarReducers,
			grb2: grb2Reducers,
			dashboard: dashboardReducers,
			garbageRecord: garbageRecordReducers,
			report: reportReducers,
			tool: toolReducers,
			insight: insightReducers,
		})
	),
});
export default rootReducer;
