import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { TooltipProps } from "recharts";
import {
	ValueType,
	NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { Dispatch } from "redux";
import insightSelectors from "redux/app/insight/selectors";
import { IStore } from "redux/store";

type OwnProps = {};

const CustomTooltip = (
	props: TooltipProps<ValueType, NameType> & OwnProps & PropsFromRedux
) => {
	const { active, payload, label, engineLogMetricsList } = props;

	if (active && payload && payload.length) {
		const [date, watchHour] = label.split("@");

		return (
			<div className="bg-white p-2.5 border-[0.5px] font-redhat-text rounded-md shadow-md">
				<p className="label font-redhat-text ">{`${date ?? label}`}</p>
				{watchHour && (
					<p className="text-sm font-medium">
						Watch hour : {watchHour.slice(0, 4)} - {watchHour.slice(4)}
					</p>
				)}
				{payload.map((category: any) => {
					const metricItem = engineLogMetricsList.byIds[category?.dataKey];
					return (
						<div key={category?.dataKey} className="flex items-center text-sm">
							<div
								className="w-2 h-2 rounded-full mr-1"
								style={{ background: category.stroke }}
							/>
							<p className="text-[12px] text-gray-600">
								{category?.dataKey
									? category?.dataKey in engineLogMetricsList.byIds
										? `${metricItem?.section} > ${metricItem?.subSection} > ${metricItem?.metric}`
										: ""
									: ""}{" "}
								:{" "}
								{"payload" in category &&
								isNaN(category["payload"][category?.dataKey])
									? "NA"
									: (category["payload"][category?.dataKey] as number)?.toFixed(
											2
									  )}
							</p>
						</div>
					);
				})}
			</div>
		);
	}

	return null;
};

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);
	const engineLogMetricsList =
		insightSelectors.getEngineLogMetricsList(insightStore);
	return {
		engineLogMetricsList,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

export default reduxConnector(CustomTooltip);
