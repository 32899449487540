import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupC5 } from "../../../../../redux/app/orb1/groups/group-c";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { commonUtils } from "redux/_common";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupC5PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1GroupC5Preview(props: ORB1GroupC5PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupC5;
	const sourceSludgeTank = orb1Record.item12P2.transferOfOilResiduesSource;
	const destinationTankItem =
		orb1Record.item12P2.transferOfOilResiduesDestination[0];

	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "C", "12.2", renderItem12P2()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem12P2(): React.ReactNode {
		if (!destinationTankItem) return null;
		const sourceTank = sourceSludgeTank.selectedTank;
		const destinationTank = destinationTankItem.selectedTank;
		const retainedAtSource = commonUtils.substract(
			marpolUtils.getQuantityForCalculation(sourceTank),
			sourceSludgeTank.transferQuantity
		);
		const retainedAtDestination = commonUtils.addUp(
			marpolUtils.getQuantityForCalculation(destinationTank),
			destinationTankItem.transferQuantity
		);

		return (
			<>
				{sourceSludgeTank.transferQuantity.toFixed(2)} M³ TRANSFERRED FROM{" "}
				{sourceTank.tankName} TO {destinationTank.tankName}. <br />
				TOTAL RETAINED IN {sourceTank.tankName} = {retainedAtSource} M³
				<br />
				TOTAL QUANTITY IN {destinationTank.tankName} {retainedAtDestination} M³
			</>
		);
	}

	return renderMain();
}

export default ORB1GroupC5Preview;
