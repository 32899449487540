import "./@set-product/styles/setproduct.css";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import ScreenRoot from "./screens";
// tslint:disable-next-line: no-submodule-imports
import { PersistGate } from "redux-persist/integration/react";
import InfiniteProgress from "./components/_common/InfiniteProgress";
import "./styles/main.css";
import axiosConfig from "./shared/axios-config";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tippy/dist/tippy.css";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
// tslint:disable-next-line: no-var-requires
// require("dotenv").config();
axiosConfig();

Sentry.init({
	dsn: "https://9931d1b3f97f46e9b8052bfe372bafe7@o324516.ingest.sentry.io/5193144",
	integrations: [
		new Sentry.BrowserTracing({
			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [
				"localhost",
				/^https:\/\/yourserver\.io\/api/,
			],
		}),
		new Sentry.Replay({
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false,
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,
	environment: process.env.NODE_ENV,

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
  

ReactDOM.render(
	// tslint:disable-next-line: jsx-wrap-multiline
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate
				loading={<InfiniteProgress isLoading={true} />}
				persistor={persistor}
			>
				<ScreenRoot />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls..
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// typedoc --out ./docs ./src --mode file --tsconfig ./tsconfig.json

// Production url https://assist.wayship.io
// Development url http://13.233.83.101:3000
