import { IListObjectStore } from "../../_common/type";
import { IJobHOCState } from "../../_core/job/types";
import {
	EMARPOLDocuments,
	TMARPOLProcessedTank,
	TMARPOLTank,
} from "../marpol/@types";
import { TBallast_Additional } from "./groups/additional";
import { TBallast_Ballast } from "./groups/ballast";
import { TBallast_Deballast } from "./groups/deballast";
import { TBallast_Exceptional } from "./groups/exceptional";
import { TBallast_Exchange } from "./groups/exchange";
import { TBallast_Internal } from "./groups/internal";

export enum EBallastOperationState {
	RECORD = "RECORD",
	PREVIEW = "PREVIEW",
}

export enum EBallastRecordState {
	APPROVED = "APPROVED",
	STRIKED_OFF = "STRIKED-OFF",
	SUBMITTED = "SUBMITTED",
	IDLE = "IDLE",
	EDIT = "EDIT",
	VERIFIED = "VERIFIED",
}

export enum EBallastRecordCode {
	BALLAST = "BALLAST",
	DEBALLAST = "DEBALLAST",
	EXCHANGE = "EXCHANGE",
	ADDITIONAL = "ADDITIONAL",
	INTERNAL = "INTERNAL",
	EXCEPTIONAL = "EXCEPTIONAL",
}

export enum EBallastOperationCodes {
	BALLAST_WITHOUT_TREATMENT = "BALLAST_WITHOUT_TREATMENT",
	BALLAST_WITH_TREATMENT = "BALLAST_WITH_TREATMENT",
	DEBALLAST_IN_PORT = "DEBALLAST_IN_PORT",
	DEBALLAST_IN_SEA = "DEBALLAST_IN_SEA",
	DEBALLAST_TO_RECEPTION_FACILITY = "DEBALLAST_TO_RECEPTION_FACILITY",
	EXCHANGE_BALLAST_USING_SEQUENTIAL = "EXCHANGE_BALLAST_USING_SEQUENTIAL",
	EXCHANGE_BALLAST_USING_FLOW_THROUGH = "EXCHANGE_BALLAST_USING_FLOW_THROUGH",
	INTERNAL_TRANSFER_BETWEEN_BALLAST = "INTERNAL_TRANSFER_BETWEEN_BALLAST",
	EXCEPTIONAL_DEBALLAST_FROM_CARGO = "EXCEPTIONAL_DEBALLAST_FROM_CARGO",
	ACCIDENTAL_DISCHARGE_OF_BALLAST_WATER = "ACCIDENTAL_DISCHARGE_OF_BALLAST_WATER",
	ADDITIONAL_PROCEDURES = "ADDITIONAL_PROCEDURES",
	ADDITIONAL_PROCEDURES_MISSED_ENTRY = "ADDITIONAL_PROCEDURES_MISSED_ENTRY",
}

export enum EBallastOperationMethods {
	EMPTY_GRAVITY = "EMPTY GRAVITY",
	EMPTY_PUMP = "EMPTY PUMP",
	INTERNAL_TRANSFER = "INTERNAL TRANSFER",
	FILL_GRAVITY = "FILL GRAVITY",
	FILL_PUMP = "FILL PUMP",
	EXCHANGE_EMPTY_REFILL = "EXCHANGE EMPTY/REFILL",
	EXCHANGE_FLOW_THROUGH = "EXCHANGE FLOW/THROUGH",
	ALTERNATE_METHOD = "ALTERNATE METHOD",
	NOT_APPLICABLE = "NOT APPLICABLE",
	PUMP_OR_EDUCTOR = "PUMP/EDUCTOR",
}

export type TBallastCommonFields = {
	selectedDate: string;
	remarks: string;
};

export type TBallastCommonData = {
	id: string;
	/**
	 * @description It's an ID for latest update for a document with given id.
	 */
	_rev: string;
	state: EBallastRecordState;
	/**
	 * @description It is required to change the UI behaviour for similar operations.
	 */
	operationCode: EBallastOperationCodes;
	marpolType: EMARPOLDocuments.BALLAST_WATER_RECORD_OPERATION;
	submittedBy?: TBallastRecordUser;
	approvedBy?: TBallastRecordUser;
	verifiedBy?: TBallastRecordUser;
	strikedBy?: TBallastRecordUser;
	strikeOffReason?: string;
    editVersions?: TBallastBackendRecord[];
    dateOfApprovedRecordEdit?: string;
};
export type TBallastRecordUser = {
	name: string;
	designation: string;
	staffId: string;
	date: string;
};

export type TBallastPlace =
	| {
			place: "IN_PORT";
			portName: string;
	  }
	| {
			place: "AT_SEA";
			startPosition: { latitude: string; longitude: string };
			endPosition: { latitude: string; longitude: string };
	  }
	| {
			place: "AT_RECEPTION_FACILITY";
			receiptNumber: string;
			receiptAttachment: string;
			startDate: string;
			startTime: string;
			originOfBallast: string;
			receptionFacilityName: string;
	  };

export type TBallastRecord =
	| TBallast_Ballast
	| TBallast_Deballast
	| TBallast_Exchange
	| TBallast_Internal
	| TBallast_Exceptional
	| TBallast_Additional;

export type TBallastBackendRecord = TBallastRecord & {
	processedTanks: TMARPOLProcessedTank[];
	nonParticipantTanks: { id: string; expectedQuantity: number }[];
	submittedBy: TBallastRecordUser;
	approvedBy?: TBallastRecordUser;
	verifiedBy?: TBallastRecordUser;
	strikedBy?: TBallastRecordUser;
};

export type TBallastCurrentOperation = {
	recordCode: EBallastRecordCode;
	operationState: EBallastOperationState;
	formData: TBallastRecord;
	validationAlerts: IListObjectStore<TBallastOperationAlert>;
	processedTanks: IListObjectStore<TMARPOLProcessedTank>;
};

export type TBallastOperationAlert = {
	id: string;
	type:
		| "WARNING"
		| "ERROR"
		| "INFO"
		| "REQUIRED_FIELD"
		| "OBSERVED_QUANTITY_ALERT";
	title: string;
	subtitle: string;
	message: string;
	listOfItems?: {
		title: string;
		subtitle?: string;
		message?: string;
		link?: string;
	}[];
	action?: {
		title: string;
		link: string;
	};
};

export type TBallastRecordFilters = {
	startDate: any;
	endDate: any;
	shownRecords: "ALL" | "ALL-PENDING";
};

export type TBallastTableLog = {
	id: string;
	selectedDate: string;
	ballastWaterRecordOperationsId: string;
	operationType: EBallastRecordCode;
	recordCode: EBallastRecordCode;
	state: EBallastRecordState;
	placeOfBallast: TBallastPlace;
	originOfBallast: string;
	tank: TMARPOLTank;
	startDate: string;
	startTime: string;
	endDate: string;
	endTime: string;
	initialVolume: number;
	finalVolume: number;
	differenceVolume: number;
	isBallastWaterTreatmentPlanUsed: boolean;
	operationMethod: EBallastOperationMethods;
	pumpsUsed: string;
	temperature: number;
	salinity: number;
	depth: number;
	remarks: string;
	transferToFrom: string;
	estimatedUptake: string;
	estimatedCirculatedOrTreated: string;
	estimatedDischarged: string;
	density: number;
	submittedBy?: TBallastRecordUser;
	approvedBy?: TBallastRecordUser;
	verifiedBy?: TBallastRecordUser;
	strikedBy?: TBallastRecordUser;
};

export interface IBallastStore extends IJobHOCState {
	records: IListObjectStore<TBallastBackendRecord>;
	currentOperation?: TBallastCurrentOperation;
	filters: TBallastRecordFilters;
	tableLogs: TBallastTableLog[];
}
