import classNames from "classnames";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { ETicketTabType } from "redux/app/feedback/@types";
import feedbackSelectors from "redux/app/feedback/selectors";
import { IStore } from "redux/store";
import useCheckMobileScreen from "../../utils";

const tabLabels: Record<string, string> = {
	highPriority: "High priority",
	waitingForReply: "Awaiting reply",
	dueThisWeek: "Due this week",
	ticketsToBeAssigned: "To be assigned",
	ticketsOverdue: "Overdue",
};

type OwnProps = {};
type FeedbackTicketTableTabsProps = PropsFromRedux & OwnProps;
function FeedbackTicketTableTabs(props: FeedbackTicketTableTabsProps) {
	const { feedbackTableTabsData, jobToGetStats } = props;

	// order of the tables
	const tableTabsHavingData = [
		"highPriority",
		"waitingForReply",
		"dueThisWeek",
		"ticketsOverdue",
		"ticketsToBeAssigned",
	];
	const isMobile = useCheckMobileScreen();

	if (jobToGetStats.isLoading) {
		return (
			<div className="w-full px-4 mt-3">
				<div className="flex justify-between  space-x-2 lg:space-x-2.5 xl:space-x-5">
					{[0, 1, 2, 3, 4].map((_) => {
						return (
							<div className="basis-1/5">
								<Skeleton width="100%" height={isMobile ? 80 : 120} />
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	return tableTabsHavingData.length > 0 ? (
		<div className="w-full px-4">
			<div className="w-full flex justify-center items-center mt-4 mb-1 space-x-2 lg:space-x-2.5 xl:space-x-5">
				{tableTabsHavingData.map((tabKey) => (
					<div
						className={classNames(
							"shadow rounded flex flex-col justify-center items-center py-6 border border-gray-100 max-h-[93px] md:max-h-full text-center",
							{ "cursor-not-allowed": jobToGetStats.isLoading },
							{
								"basis-1/5": tableTabsHavingData.length === 5,
								"basis-1/4": tableTabsHavingData.length === 4,
								"w-[234px]":
									tableTabsHavingData.length && tableTabsHavingData.length <= 3,
								"cursor-not-allowed": jobToGetStats.isLoading,
								"cursor-pointer": !jobToGetStats.isLoading,
							}
						)}
						key={tabKey}
						onClick={() => {
							const getTableElement = document.getElementById(tabKey);
							getTableElement &&
								getTableElement.scrollIntoView({
									behavior: "smooth",
									block: "center",
								});
						}}
					>
						<h2 className={classNames("text-xl md:text-3xl text-gray-700")}>
							{feedbackTableTabsData[tabKey]?.length ?? 0}
						</h2>
						<p
							className={classNames(
								"m-0 text-[9px] md:text-sm font-medium text-gray-500"
							)}
						>
							{tabLabels[tabKey]}
						</p>
					</div>
				))}
			</div>
		</div>
	) : null;
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const ticketTab = feedbackSelectors.getFeedbackTicketTabType(feedbackStore);
	const feedbackTableTabsData =
		feedbackSelectors.getDashboardFeedbackTablesData(
			feedbackStore,
			ticketTab === ETicketTabType.MY_TICKETS ? "myTickets" : "allTickets"
		);
	const jobToGetStats =
		feedbackSelectors.jobToLoadDashboardFeedbacksAndStats(feedbackStore);
	return {
		feedbackTableTabsData,
		jobToGetStats,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackTicketTableTabs);
