import authReducers from "./reducers";
import authMainMiddleware from "./middlewares";
import loginFormMiddleware from "./middlewares/login.form";
import resetRequestFormMiddleware from "./middlewares/reset-request.form";
import resetPasswordFormMiddleware from "./middlewares/reset-password.form";

export { default as authActions } from "./actions";
export { default as authSelectors } from "./selectors";
export { default as authUtils } from "./utils";
export { default as authMainMiddleware } from "./middlewares";
export { default as loginFormMiddleware } from "./middlewares/login.form";

const authMiddlewaresList = [
	authMainMiddleware,
	loginFormMiddleware,
	resetRequestFormMiddleware,
	resetPasswordFormMiddleware,
];

export { authMiddlewaresList };
export default authReducers;
