import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	categoryLegends,
	garbageDischargeCategoriesMapper,
	orderOfDischargeCategories,
} from "redux/app/report/constants";
import reportSelectors from "redux/app/report/selectors";
import {
	generateTableRows,
	getMonthWiseGarbageRow,
} from "redux/app/report/utils";
import { IStore } from "redux/store";

type OwnProps = {};
type TableProps = PropsFromRedux & OwnProps;
function Table(props: TableProps) {
	const { rawGarbageData, vesselId } = props;
	const spreadOfDischargeCategory = 7;

	if (rawGarbageData.length === 0) {
		return (
			<div className="flex justify-center items-center mt-20">
				No data found{vesselId ? " for this vessel" : ""}.{" "}
				{vesselId
					? "Choose another vessel."
					: "Please select a vessel or change the year."}
			</div>
		);
	}

	const monthWiseGarbageData = getMonthWiseGarbageRow(rawGarbageData);
	const tableRows = generateTableRows("2023", monthWiseGarbageData);

	return (
		<>
			<div className="border-t border-[#D3D3D3] w-full overflow-x-auto">
				<div className="p-4">
					<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text  overflow-auto  border-collapse text-center">
						<thead>
							<tr>
								<th
									rowSpan={2}
									className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
								>
									Year
								</th>
								<th
									rowSpan={2}
									className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
								>
									Qtr
								</th>

								{orderOfDischargeCategories.map((category) => {
									const categoryName =
										garbageDischargeCategoriesMapper[
											category as keyof typeof garbageDischargeCategoriesMapper
										];
									return (
										<th
											key={categoryName}
											colSpan={spreadOfDischargeCategory}
											className="px-1 py-3 text-sm  border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
										>
											{categoryName}
										</th>
									);
								})}
							</tr>
							<tr>
								{orderOfDischargeCategories.map((category, index) => {
									return (
										<Fragment key={category + index}>
											{categoryLegends.map((cl) => {
												return (
													<th
														key={category + cl.key}
														className="px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
													>
														{cl.key}
													</th>
												);
											})}
										</Fragment>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{tableRows.map((tableRow: any) => {
								return (
									<tr key={tableRow[1]}>
										{tableRow.map((col: any) => {
											return (
												<td
													className="px-1 py-3 text-[8px] lg:text-xs xl:text-[12px] border leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]"
													key={col}
												>
													<div className="flex justify-center">{col}</div>
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>

					<div className="flex items-center mt-2">
						<div className="w-1 h-1 rounded-full bg-black mr-0.5" />
						<div className="text-sm flex ">
							{categoryLegends.map((leg) => {
								return (
									<div className="mx-0.5" key={leg.key}>
										<span className="font-medium ml-1">{leg.key}</span>
										<span className="mx-0.5">-</span>
										<span>{leg.title}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawGarbageData = reportSelectors.getRawGarbageData(reportStore);
	const vesselId = reportSelectors.getVesselId(reportStore);
	return {
		rawGarbageData,
		vesselId,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Table);
