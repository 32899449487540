import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupI1 } from "../../../../../redux/app/annex6/groups/group-i";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupI1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupI1Preview(props: Annex6GroupI1PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupI1;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "I", 19.1, renderItem19P1()],
					[null, null, 19.2, renderItem19P2()],
					[null, null, 19.3, renderItem19P3()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem19P1() {
		return (
			<h4 className="font-medium uppercase">
				{record.item19P1.placeOfDelivery}
				<br />
				{record.item19P1?.location?.latitude &&
					record.item19P1?.location?.latitude}{" "}
				{""}
				{record.item19P1?.location?.longitude &&
					record.item19P1?.location?.longitude}
			</h4>
		);
	}

	function renderItem19P2() {
		return (
			<h4 className="font-medium uppercase">
				{record.item19P2.timeOfDelivery} LT
			</h4>
		);
	}

	function renderItem19P3() {
		return (
			<h4 className="font-medium uppercase">
				{record.item19P3.odsName} {record.item19P3.quantityReceived} KG
			</h4>
		);
	}

	return renderMain();
}

export default Annex6GroupI1Preview;
