import typesCreator from "./types-creator";
import actionTypes from "./action-types";
import utilities from "./utils";
import { IJobNotification } from "./types";
import { ActionType, createAction } from "typesafe-actions";
const JOB_IDLE = "job/IDLE";
const JOB_ACTIVE = "job/ACTIVE";
const JOB_SUCCESS = "job/SUCCESS";
const JOB_ERROR = "job/ERROR";
const JOB_PROMISE = "job/PROMISE";

type IAction = {
	message?: string;
	/**
	 * Contains settings for notifications. If set then shows notification in the UI.
	 * @default undefined
	 */
	notification?: IJobNotification | undefined;
};

function jobActions(
	feature: string,
	JOB_NAME: string,
	hasGlobalEffect = false
) {
	return {
		idle: createAction(
			`${JOB_NAME} [${JOB_IDLE}]`,
			function getPayload({ message, notification }: IAction) {
				return { message, notification, JOB_NAME };
			},
			function getMeta() {
				return {
					trueType: JOB_IDLE,
					feature,
					JOB_NAME,
					hasGlobalEffect,
				};
			}
		)(),
		active: createAction(
			`${JOB_NAME} [${JOB_ACTIVE}]`,
			function getPayload({ message, notification }: IAction) {
				return { message, notification, JOB_NAME };
			},
			function getMeta() {
				return {
					trueType: JOB_ACTIVE,
					feature,
					JOB_NAME,
					hasGlobalEffect,
				};
			}
		)(),
		error: createAction(
			`${JOB_NAME} [${JOB_ERROR}]`,
			function getPayload({ message, notification }: IAction) {
				return { message, notification, JOB_NAME };
			},
			function getMeta() {
				return {
					trueType: JOB_ERROR,
					feature,
					JOB_NAME,
					hasGlobalEffect,
				};
			}
		)(),
		success: createAction(
			`${JOB_NAME} [${JOB_SUCCESS}]`,
			function getPayload({ message, notification }: IAction) {
				return { message, notification, JOB_NAME };
			},
			function getMeta() {
				return {
					trueType: JOB_SUCCESS,
					feature,
					JOB_NAME,
					hasGlobalEffect,
				};
			}
		)(),
	};
}

const noFeatureAction = jobActions("", "");
export type IJobActions = ActionType<typeof noFeatureAction>;
export default jobActions;
