import React, { FC } from 'react';
import { getEmptyValueLabelObj, IData } from '.';
import { findSelectedObj } from './Category';
import WayshipDropdownDeck from './WayshipDropdownDeck';

interface ISectionProps {
    data: IData;
    categories: React.MutableRefObject<{
        [key: string]: any;
    }>;
    setData: React.Dispatch<React.SetStateAction<IData>>;
}

const Section: FC<ISectionProps> = ({ data, categories, setData }) => {
    const range = categories.current[data.category.value]?.sections &&
    categories.current[data.category.value]?.sections?.length > 0
        ? categories.current[data.category.value]?.sections?.map(
              (section: any) => ({
                  label: section?.title || section?.mnemonic,
                  value: section?.mnemonic || section?.title,
              })
          )
        : [{ label: '', value: '' }];

    return (
        <div>
            <WayshipDropdownDeck
			
                isSubmit={data.category.value === '' || range[0].value === ''}
                title="Select Section"
                dataKey=""
                value={data.section.value ?? ""}
                range={range}
                onChangeHandler={(selectedItem: any, dataKey: any) => {
                    const obj = findSelectedObj(range, selectedItem);
                    setData((prev) => ({
                        ...prev,
                        section: {
                            label: obj?.label,
                            value: selectedItem
                        },
                        modules: getEmptyValueLabelObj(),
                        subModules: getEmptyValueLabelObj(),
                        metric:  getEmptyValueLabelObj(),
                        range: [undefined, undefined],
                        key: '',
                        remarks: '',
                    }));
                }}
            />
        </div>
    );
};

export default Section;
