import { Middleware } from "redux";

const trueTypeMiddleware: Middleware = ({ dispatch, getState }) => (next) => (
	action
) => {
	next(action);
	if (action.meta && action.meta.trueType)
		dispatch({
			...action,
			type: action.meta.trueType,
			meta: { ...action.meta, trueType: undefined },
		});
};
export default trueTypeMiddleware;
