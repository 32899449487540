import { createReducer } from "typesafe-actions";
import withForms, { formUtils } from "redux/_core/form";
import withJobs from "redux/_core/job/reducers";
import { IChecklistStore, IChecklistActions, IChecklistFilters } from "./types";
import checklistActions from "./actions";
import { CHECKLIST_PER_PAGE } from "./constants";

const reducerState: IChecklistStore = {
	_forms: {
		FILTER: formUtils.getDefaults<IChecklistFilters, any>({
			fields: {
				checklistType: "",
				vesselId: "",
			},
			mode: "NONE",
		}),
	},
	_pagination: {
		currentPage: 1,
		itemsPerPage: CHECKLIST_PER_PAGE,
		pageWiseListIds: {},
		totalItems: 0,
	},
	checklists: [],
	checklistUniqueList: [],
	selectedChecklist: undefined,
	checklist: {},
	isSubmit: false, 
	totalLogsCount: 0,
	_jobs: {}
};

const actions = checklistActions;
const checklistReducers = createReducer<IChecklistStore, IChecklistActions>(reducerState)
	.handleAction(actions.listSet, function logListSet(state, action) {
		return { ...state, checklists: action.payload.checklists };
	})
	.handleAction(actions.checklistSelect, function checklistSelect(state, action) {
		const checklist = action.payload.checklist;
		return {
			...state,
			checklist
		};
	})
	.handleAction(actions.checklistSubmit, function checklistSubmit(state, action) {
		const isSubmit = action.payload.isSubmit;
		return {
			...state,
			isSubmit
		};
	})

	.handleAction(actions.clearSelectedChecklist, function clearSelectedChecklist(state, action) {
		return {
			...state,
			selectedChecklist: undefined,
		};
	})
	.handleAction(actions.selectChecklist, function selectChecklist(state, action) {
		const { checklist } = action.payload;
		return {
			...state,
			selectedChecklist: {
				checklist,
				checklistPrintTemplate: undefined,
			},
		};
	})
	.handleAction(
		actions.checklistPrintTemplateSet,
		function checklistPrintTemplateSet(state, action) {
			const { printTemplate } = action.payload;
			return {
				...state,
				selectedChecklist: {
					...(state?.selectedChecklist || {}),
					checklist: state.selectedChecklist?.checklist,
					checklistPrintTemplate: printTemplate,
				},
			};
		}
	)
	.handleAction(
		actions.checklistPrintTemplateReset,
		function checklistPrintTemplateReset(state, action) {
			return {
				...state,
				selectedChecklist: state.selectedChecklist
					? {
							...state.selectedChecklist,
							checklistPrintTemplate: null,
					  }
					: undefined,
			};
		}
	)
	// Handling Checklist Load
	.handleAction(actions.checklistSet, function checklistSet(state, action) {
		return { ...state, checklists: action.payload.checklists };
	})
	.handleAction(actions.checklistUniqueListSet, function checklistUniqueListSet(state, action) {
		return { ...state, checklistUniqueList: action.payload.checklistUniqueList };
	})
	// Handling Pagination only
	.handleAction(
		actions.paginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		actions.paginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		actions.paginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		actions.paginationPageIdsSet,
		function pageIdsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					pageWiseListIds: {
						...state._pagination.pageWiseListIds,
						[action.payload.pageNumber]: action.payload.itemIds,
					},
				},
			};
		}
	)
	.handleAction(
		actions.paginationPageIdsResetAll,
		function pageIdsResetAll(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					pageWiseListIds: {},
				},
			};
		}
	)
	.handleAction(
		actions.paginationPageIdReset as any,
		function pageIdReset(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					pageWiseListIds: {
						...state._pagination.pageWiseListIds,
						[action.payload.pageNumber]: [],
					},
				},
			};
		}
	)
	.handleAction(actions.paginationReset as any, function reset(state, action) {
		return {
			...state,
			_pagination: {
				currentPage: 1,
				itemsPerPage: CHECKLIST_PER_PAGE,
				pageWiseListIds: {},
				totalItems: 0,
			},
		};
	});

export default withJobs(
	"@checklist",
	reducerState,
	withForms("@checklist", reducerState, checklistReducers)
);
