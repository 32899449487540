import { API_REQUEST, API_DEFAULT_HEADER_SET } from "./action-types";
import { Middleware } from "redux";
import axios from "axios";
import {
	IAPIRequest,
	IAPISuccess,
	IAPIError,
	IAPI_HeaderSet__Action,
} from "./types";
import actions from "./actions";

const baseAppUrl = `${process.env.REACT_APP_DOMAIN}`;

const apiMiddleware: Middleware = ({ dispatch }) => (next) => (
	action: IAPIRequest<any> | IAPISuccess | IAPIError | IAPI_HeaderSet__Action
) => {
	next(action);
	if (action.type === API_REQUEST) {
		const { meta } = action as IAPIRequest<any>;
		const {
			feature,
			method,
			url,
			baseURL,
			preExecute,
			postExecute,
			body,
			queryParams,
			headers,
		} = meta;

		if (preExecute) preExecute();

		axios({
			baseURL: baseURL ? baseURL : baseAppUrl,
			url,
			method,
			data: body ? body : undefined,
			params: queryParams ? queryParams : undefined,
			headers,
		})
			.then((response) => {
				dispatch(actions.apiSuccess({ feature, response }));
				if (postExecute && postExecute.onSuccess)
					postExecute.onSuccess(response);
			})
			.catch((error) => {
				dispatch(
					actions.apiError({
						feature,
						error: error.toString(),
						response: error.response,
						_serverErrorMessage: error.response
							? error.response.data.message
							: "Network Error",
					})
				);
				if (postExecute && postExecute.onError)
					postExecute.onError({
						error: error.toString(),
						response: error.response,
						_serverErrorMessage: error.response
							? error.response.data.message
							: "Network Error",
					});
			})
			.finally(() =>
				postExecute && postExecute.finally ? postExecute.finally() : null
			);
	}
	if (action.type === API_DEFAULT_HEADER_SET) {
		const { header, value } = (action as IAPI_HeaderSet__Action).payload;
		axios.defaults.headers.common[header] = value;
	}
};

export default apiMiddleware;
