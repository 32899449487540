import { createReducer } from "typesafe-actions";
import { EParameterTabType, IInsightStore } from "./@types";
import insightActions, { TInsightActions } from "./actions";
import withJobs from "redux/_core/job/reducers";
import insightConstants from "./constants";
import { defineds } from "redux/_common/utils";

const defaultState: IInsightStore = {
	_jobs: {},
	engineLog: {
		config: undefined,
		metricsList: {
			byIds: {},
			ids: [],
		},
		engineLogInsights: {
			list: [],
			metrics: [],
			dateRangeValues: [],
		},
		activeSelectedEngineLogMetrics: [],
		selectedEngineLogMetrics: [],
	},
	noonReport: {
		config: undefined,
		engineConfig: undefined,
		metricsList: {
			byIds: {},
			ids: [],
		},
		noonReportInsights: {
			list: [],
			metrics: [],
			dateRangeValues: [],
		},
		activeSelectedNoonReportMetrics: [],
		selectedNoonReportMetrics: [],
	},
	vesselId: "",
	showParameterMetricList: true,
	showRecentMetricList: false,
	currentTab: EParameterTabType.ENGINE_LOG_TAB,
	stateOfShipOptions: [],
	stateOfShip: "",
	stateOfShipError: false,
	search: "",
	dateRange: [
		{
			startDate: defineds.past30days,
			endDate: defineds.endOfToday,
			key: "selection",
		},
	],
};

const insightReducer = createReducer<IInsightStore, TInsightActions>(
	defaultState
)
	.handleAction(
		insightActions.document.insightEngineLogConfigSet,
		function insightEngineLogConfigSet(state, action) {
			const { config } = action.payload;
			return {
				...state,
				engineLog: {
					...state.engineLog,
					config,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.toggleParameterMetricList,
		function toggleParameterMetricList(state, action) {
			const { showParameterMetricList } = action.payload;
			return {
				...state,
				showParameterMetricList:
					showParameterMetricList !== undefined
						? showParameterMetricList
						: !state.showParameterMetricList,
			};
		}
	)
	.handleAction(
		insightActions.document.toggleRecentList,
		function toggleRecentList(state, action) {
			const { showRecentMetricList } = action.payload;
			return {
				...state,
				showRecentMetricList:
					showRecentMetricList !== undefined
						? showRecentMetricList
						: !state.showRecentMetricList,
			};
		}
	)
	.handleAction(
		insightActions.document.setInsightParameterCurrentTab,
		function setInsightParameterCurrentTab(state, action) {
			const { currentTab } = action.payload;
			return {
				...state,
				currentTab,
			};
		}
	)
	.handleAction(
		insightActions.document.addEngineLogMetricToSelectedMetrics,
		function addEngineLogMetricToSelectedMetrics(state, action) {
			const { metric } = action.payload;
			const selectedMetrics = [...state.engineLog.selectedEngineLogMetrics];
			const updatedMetrics = selectedMetrics.includes(metric)
				? selectedMetrics.filter((m) => m !== metric)
				: [...selectedMetrics, metric];

			return {
				...state,
				engineLog: {
					...state.engineLog,
					selectedEngineLogMetrics: updatedMetrics,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.engineLogMetricsListSet,
		function engineLogMetricsListSet(state, action) {
			const { metricsList } = action.payload;

			return {
				...state,
				engineLog: {
					...state.engineLog,
					metricsList,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.insightDateRangeSet,
		function insightDateRangeSet(state, action) {
			const { dateRange } = action.payload;
			return {
				...state,
				dateRange,
			};
		}
	)
	.handleAction(
		insightActions.document.engineLogInsightsSet,
		function engineLogInsightsSet(state, action) {
			const { list, metrics, dateRangeValues } = action.payload;
			return {
				...state,
				engineLog: {
					...state.engineLog,
					engineLogInsights: {
						metrics,
						list,
						dateRangeValues,
					},
				},
			};
		}
	)
	.handleAction(
		insightActions.document.engineLogActiveSelectedMetricsSet,
		function engineLogActiveSelectedMetricsSet(state, action) {
			const { activeMetrics } = action.payload;
			return {
				...state,
				engineLog: {
					...state.engineLog,
					activeSelectedEngineLogMetrics: activeMetrics,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.parameterVesselIdSet,
		function parameterVesselIdSet(state, action) {
			const { vesselId } = action.payload;
			return {
				...state,
				vesselId,
			};
		}
	)
	.handleAction(
		insightActions.document.stateOfShipSet,
		function stateOfShipSet(state, action) {
			const { stateOfShip } = action.payload;
			return {
				...state,
				stateOfShip,
			};
		}
	)
	.handleAction(
		insightActions.document.searchInputSet,
		function searchInputSet(state, action) {
			const { searchText } = action.payload;
			return {
				...state,
				search: searchText,
			};
		}
	)
	.handleAction(
		insightActions.document.recentSelectedMetricToSelectedMetricsAdd,
		function recentSelectedMetricToSelectedMetricsAdd(state, action) {
			const { selectedMetrics } = action.payload;
			return {
				...state,
				engineLog: {
					...state.engineLog,
					selectedEngineLogMetrics: selectedMetrics,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.stateOfShipDropdownOptionsSet,
		function stateOfShipDropdownOptionsSet(state, action) {
			const { options } = action.payload;
			return {
				...state,
				stateOfShipOptions: options,
			};
		}
	)
	.handleAction(
		insightActions.document.insightNoonReportConfigSet,
		function insightNoonReportConfigSet(state, action) {
			const { config } = action.payload;
			return {
				...state,
				noonReport: {
					...state.noonReport,
					config,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.insightNoonReportEngineConfigSet,
		function insightNoonReportEngineConfigSet(state, action) {
			const { config } = action.payload;
			return {
				...state,
				noonReport: {
					...state.noonReport,
					engineConfig: config,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.noonReportMetricsListSet,
		function noonReportMetricsListSet(state, action) {
			const { metricsList } = action.payload;

			return {
				...state,
				noonReport: {
					...state.noonReport,
					metricsList,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.noonReportRecentSelectedMetricToSelectedMetricsAdd,
		function noonReportRecentSelectedMetricToSelectedMetricsAdd(state, action) {
			const { selectedMetrics } = action.payload;
			return {
				...state,
				noonReport: {
					...state.noonReport,
					selectedNoonReportMetrics: selectedMetrics,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.addNoonReportMetricToSelectedMetrics,
		function addNoonReportMetricToSelectedMetrics(state, action) {
			const { metric } = action.payload;
			const selectedMetrics = [...state.noonReport.selectedNoonReportMetrics];
			const updatedMetrics = selectedMetrics.includes(metric)
				? selectedMetrics.filter((m) => m !== metric)
				: [...selectedMetrics, metric];

			return {
				...state,
				noonReport: {
					...state.noonReport,
					selectedNoonReportMetrics: updatedMetrics,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.noonReportInsightsSet,
		function noonReportInsightsSet(state, action) {
			const { list, metrics, dateRangeValues } = action.payload;
			return {
				...state,
				noonReport: {
					...state.noonReport,
					noonReportInsights: {
						metrics,
						list,
						dateRangeValues,
					},
				},
			};
		}
	)
	.handleAction(
		insightActions.document.noonReportActiveSelectedMetricsSet,
		function noonReportActiveSelectedMetricsSet(state, action) {
			const { activeMetrics } = action.payload;
			return {
				...state,
				noonReport: {
					...state.noonReport,
					activeSelectedNoonReportMetrics: activeMetrics,
				},
			};
		}
	)
	.handleAction(
		insightActions.document.insightStateOfShipError,
		function insightStateOfShipError(state, action) {
			const { stateOfShipError } = action.payload;
			return {
				...state,
				stateOfShipError,
			};
		}
	);

export default withJobs<IInsightStore>(
	insightConstants.FEATURE,
	defaultState,
	// @ts-ignore
	insightReducer
);
