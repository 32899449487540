import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4G1 } from "../../../../../redux/app/orb4/groups/orb4-g";
import CommaSeparator from "../../../../_common/CommaSeparator";
import AttachmentsPreview from "../../_elements/AttachmentsPreview";
import { commonUtils } from "redux/_common";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4G1PreviewProps = OwnProps;
function ORB4G1Preview(props: ORB4G1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4G1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "G", 27, renderItem27()],
					[null, null, 28, renderItem28()],
					[null, null, 29, renderItem29()],
					[null, null, 30, renderItem30()],
					[null, null, 31.1, renderItem31P1()],
					[null, null, 31.2, renderItem31P2()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem27() {
		return (
			<h4 className="font-medium uppercase">
				<CommaSeparator
					items={orb4Record.item27.washedTanks.map((tank) => tank.tankName)}
				/>
			</h4>
		);
	}
	function renderItem28() {
		return (
			<h4 className="font-medium uppercase">
				{(function renderItem28() {
					const item28 = orb4Record.item28;

					if (item28.placeOfWashing === "PORT") return <>{item28.portName}</>;

					if (item28.placeOfWashing === "AT_SEA")
						return (
							<>
								START LAT {item28.startPosition.latitude} LON:{" "}
								{item28.startPosition.longitude} <br />
								STOP LAT {item28.stopPosition.latitude} LON:{" "}
								{item28.stopPosition.longitude}{" "}
							</>
						);
				})()}
			</h4>
		);
	}
	function renderItem29() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item29.durationOfWashing.hours} HRS{" "}
				{orb4Record.item29.durationOfWashing.minutes} MINS
			</h4>
		);
	}
	function renderItem30() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item30.methodOfCleaning}
			</h4>
		);
	}
	function renderItem31P1() {
		const isItem31P1 =
			orb4Record.item31.tankWashingTransferredTo !==
			"DESIGNATED_SLOP_OR_CARGO_TANKS";
		if (!isItem31P1) return null;
		const item31 = orb4Record.item31;
		if (item31.tankWashingTransferredTo === "RECEPTION_FACILITY") return null;
		return (
			<h4 className="font-medium uppercase">
				{item31.tankWashingTransferredTo.replace(/_/g, " ")}
				,
				<br />
				<CommaSeparator
					items={item31.slopTanks.map(
						(tank) =>
							`${tank.selectedTank.tankName}, QUANTITY TRANSFERRED ${
								tank.transferQuantity
							}M³,  TOTAL QUANTITY RETAINED ${commonUtils.addUp(
								tank.transferQuantity,
								tank.selectedTank.expectedQuantity
							)}M³`
					)}
					customSeperator={
						<>
							,<br />
						</>
					}
				/>
			</h4>
		);
	}
	function renderItem31P2() {
		const isItem31P2 =
			orb4Record.item31.tankWashingTransferredTo ===
			"DESIGNATED_SLOP_OR_CARGO_TANKS";
		const item31 = orb4Record.item31;

		if (item31.tankWashingTransferredTo === "DESIGNATED_SLOP_OR_CARGO_TANKS")
			return (
				<>
					{item31.tankWashingTransferredTo.replace(/_/g, " ")}
					,
					<br />
					<CommaSeparator
						items={item31.slopTanks.map(
							(tank) =>
								`${tank.selectedTank.tankName}, QUANTITY TRANSFERRED ${
									tank.transferQuantity
								}M³,  TOTAL QUANTITY RETAINED ${commonUtils.addUp(
									tank.transferQuantity,
									tank.selectedTank.expectedQuantity
								)}M³`
						)}
						customSeperator={
							<>
								,<br />
							</>
						}
					/>
				</>
			);

		if (item31.tankWashingTransferredTo === "RECEPTION_FACILITY")
			return (
				<h4 className="font-medium uppercase">
					{item31.portOfTransfer} / &nbsp;
					{item31.transferQuantity}M³
					<AttachmentsPreview
						{...item31}
						_rev={orb4Record._rev}
						id={orb4Record.id}
					/>
				</h4>
			);
	}

	return renderMain();
}

export default ORB4G1Preview;
