import SealLogs from "components/app/vessel/onboard/seallogs/SealLogs";
import React from "react";

type ScreenVesselSealLogsType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
			pageNumber: string;
		};
	};
}>;
const ScreenVesselSealLogs: React.FC<ScreenVesselSealLogsType> = (
	props: ScreenVesselSealLogsType
) => {
	// return <div>hi</div>
	return <SealLogs
			pageNumber={props.match.params.pageNumber} 
			vesselId={props.match.params.vesselId}
		/>;
};

export default ScreenVesselSealLogs;