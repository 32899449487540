import moment from "moment";
import React from "react";
import { marpolUtils } from "redux/app/marpol";
import { TORB1BackendRecord, TORB1Record } from "redux/app/orb1/@types";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";
import getSignTableRows from "../getSignTableRows";
import { TORB1GroupI7, TORB1ItemI7Tank } from "redux/app/orb1/groups/group-i";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1I7PreviewProps = React.PropsWithChildren<OwnProps>;
function ORB1I7Preview(props: ORB1I7PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupI7;
	const { itemI7, id, _rev } = orb1Record;
	const { receiptAttachment } = itemI7;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	const missedOperationDate = moment(
		orb1Record.itemI7.dateOfOriginalOperation,
		"YYYY-MM-DD"
	).format("DD-MMM-YYYY");

	function renderItemMissedInfo() {
		if (!receiptAttachment)
			return (
				<h4 className="font-medium uppercase">
					ENTRY PERTAINING TO AN EARLIER MISSED OPERATIONAL ENTRY.
				</h4>
			);
		return (
			<h4 className="font-medium uppercase">
				ENTRY PERTAINING TO AN EARLIER MISSED OPERATIONAL ENTRY.
				<br />
				CORRECTION NOTE:{" "}
				<a
					href={marpolUtils.getAttachmentURL(receiptAttachment, id, _rev)}
					download={receiptAttachment}
				>
					{" "}
					{receiptAttachment}
				</a>
			</h4>
		);
	}
	function renderItemsOfC11P1Item(tankItem: TORB1ItemI7Tank) {
		const tableRows: ORB1TableColumns[] = [
			[missedOperationDate, "C", "11.1", tankItem.tank.tankName],
			[null, null, "11.2", `${tankItem.tank.totalCapacity.toFixed(2)} M³`],
			[null, null, "11.3", `${tankItem.newQuantity.toFixed(2)} M³`],
			...getSignTableRows(orb1Record as TORB1BackendRecord),
		];
		return tableRows;
	}

	function renderItemsOfI3Item(tankItem: TORB1ItemI7Tank) {
		const tableRows: ORB1TableColumns[] = [
			[
				missedOperationDate,
				"I",
				"-",
				<h4 className="font-medium uppercase">
					Inventory of {tankItem.tank.tankName} .<br /> Capacity{" "}
					{tankItem.tank.totalCapacity}
					M³, Quantity retained {tankItem.newQuantity}
					M³.
				</h4>,
			],
			...getSignTableRows(orb1Record as TORB1BackendRecord),
		];
		return tableRows;
	}

	function renderItemsOfC11P1() {
		let tableRows: ORB1TableColumns[] = [];
		orb1Record.itemI7.oilResidues.map(
			(tankItem) =>
				(tableRows = [...tableRows, ...renderItemsOfC11P1Item(tankItem)])
		);
		return tableRows;
	}
	function renderItemsOfI3() {
		let tableRows: ORB1TableColumns[] = [];
		orb1Record.itemI7.iopp3P3Tanks.map(
			(tankItem) =>
				(tableRows = [...tableRows, ...renderItemsOfI3Item(tankItem)])
		);
		return tableRows;
	}

	return (
		<ORB1TableRows
			record={orb1Record}
			tableRows={[
				[operationDate, "I", "-", renderItemMissedInfo()],
				...renderItemsOfC11P1(),
				...renderItemsOfI3(),
			]}
		/>
	);
}

export default ORB1I7Preview;
