import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";


type OwnProps = {
	dueDate: string;
	status: string;
};
type DeadlineProps = PropsFromRedux & OwnProps;
function Deadline(props: DeadlineProps) {
	const { dueDate,status } = props;
	let className = "";
	const dueDateTimestamp = moment(dueDate).unix();
	const currentDateTimestamp = moment().unix();
	if (dueDateTimestamp < currentDateTimestamp && status !== "Closed") {
		className = "inline-flex items-center rounded-md bg-orange-200 px-2 py-1 text-xs font-medium text-orange-600";
	} else  {
		className = "inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-gray-600";
	}
	return (
		<div
			className={className}
		>
			{moment(dueDate).format("Do MMM, YYYY")}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Deadline);
