import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupE2 } from "../../../../../redux/app/annex6/groups/group-e";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupE2PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupE2Preview(props: Annex6GroupE2PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupE2;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "E", 10.1, renderItem10P1()],
					[null, null, 10.2, renderItem10P2()],
					[null, null, 10.3, renderItem10P3()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem10P1() {
		return (
			<h4 className="font-medium uppercase">
				settings change started on {record.item10P1.changeStart.date},
				{record.item10P1.changeStart.time} at{" "}
				{record.item10P1.startPosition.latitude},
				{record.item10P1.startPosition.longitude}
			</h4>
		);
	}

	function renderItem10P2() {
		return (
			<h4 className="font-medium uppercase">
				settings change completed on {record.item10P2.changeCompletion.date},
				{record.item10P2.changeCompletion.time} at{" "}
				{record.item10P2.completionPosition.latitude},
				{record.item10P2.completionPosition.longitude}
			</h4>
		);
	}

	function renderItem10P3() {
		return (
			<h4 className="font-medium uppercase">
				Exit from SECA on {""}
				{record.item10P3.exitSeca.date},{record.item10P3.exitSeca.time} at{" "}
				{record.item10P3.exitPosition.latitude},
				{record.item10P3.exitPosition.longitude}
			</h4>
		);
	}

	return renderMain();
}

export default Annex6GroupE2Preview;
