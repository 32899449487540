import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "../../redux/store";
import { Dispatch } from "redux";
import { extActions } from "../../redux/app/ext";
import Button from "../../@set-product/core/button/Button";
import PermitSignModal from "./permitSignModal";
import Modal from "../../components/app/_common/Modal";
import { rootRoutes } from '../../routes';
import { historyActions } from '../../redux/_core/history';

const tableJson = [
	{
		index: 1,
		qTxt: `There is safe access between the ship and shore.`,
		subIndex: 0,
		id: "CL_TNK_07_tisa_bwnt_spsr",
		ship: {
			value: "",
			id: "CL_TNK_07_tisa_bwnt_spsr_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tisa_bwnt_spsr_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tisa_bwnt_spsr_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tisa_bwnt_spsr_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 2,
		subIndex: 0,
		qTxt: `The ship is securely moored.`,
		id: "CL_TNK_07_thsp_isec_mord",
		ship: {
			value: "",
			id: "CL_TNK_07_thsp_isec_mord_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_thsp_isec_mord_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_thsp_isec_mord_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_thsp_isec_mord_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 3,
		subIndex: 0,
		qTxt: `The agreed ship/shore communication system is operative.`,
		id: "CL_TNK_07_thad_spsr_comtn_syso",
		ship: {
			value: "",
			id: "CL_TNK_07_thad_spsr_comtn_syso_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_thad_spsr_comtn_syso_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "System:",
					"key": "CL_TNK_07_thad_spsr_comtn_syso_r_s",
					"type": "string"
				},
				{
					"name": "Back up system:",
					"key": "CL_TNK_07_thad_spsr_comtn_syso_r_bus",
					"type": "string"
				}
			],
			id: "CL_TNK_07_thad_spsr_comtn_syso_r",

		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_thad_spsr_comtn_syso_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 4,
		subIndex: 0,
		qTxt: `Emergency towing-off pennants are correctly rigged and positioned.`,
		id: "CL_TNK_07_emey_towf_pents_crap",
		ship: {
			value: "",
			id: "CL_TNK_07_emey_towf_pents_crap_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_emey_towf_pents_crap_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_emey_towf_pents_crap_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_emey_towf_pents_crap_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 5,
		subIndex: 0,
		qTxt: `The ship's fire hoses and firefighting equipment is positioned and ready for immediate use.`,
		id: "CL_TNK_07_tsfh_ffet_priu",
		ship: {
			value: "",
			id: "CL_TNK_07_tsfh_ffet_priu_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tsfh_ffet_priu_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tsfh_ffet_priu_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tsfh_ffet_priu_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 6,
		subIndex: 0,
		qTxt: `The terminal's fire-fighting equipment is positioned and ready for immediate use.`,
		id: "CL_TNK_07_ttff_eqpd_rfiu",
		ship: {
			value: "",
			id: "CL_TNK_07_ttff_eqpd_rfiu_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_ttff_eqpd_rfiu_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_ttff_eqpd_rfiu_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_ttff_eqpd_rfiu_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 7,
		subIndex: -1,
		qTxt: `The ship's cargo and bunker hoses, pipelines and manifolds are in Good condition, properly rigged and appropriate for the service intended.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tspc_bhpm_aigc_prasi_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tspc_bhpm_aigc_prasi_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_tspc_bhpm_aigc_prasi_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tspc_bhpm_aigc_prasi_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tspc_bhpm_aigc_prasi_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 8,
		subIndex: -1,
		qTxt: `The terminal's cargo and bunker hoses/arms are in good condition, properly rigged and appropriate for the service intended.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tcbha_igcpr_afsi_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tcbha_igcpr_afsi_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_tcbha_igcpr_afsi_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tcbha_igcpr_afsi_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tcbha_igcpr_afsi_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 9,
		subIndex: -1,
		qTxt: `The cargo transfer system is sufficiently isolated and drained to allow safe removal of blank flanges prior to connection`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_ctss_idasr_bfpc_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_ctss_idasr_bfpc_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_ctss_idasr_bfpc_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_ctss_idasr_bfpc_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_ctss_idasr_bfpc_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 10,
		subIndex: 0,
		qTxt: `Scuppers and 'save ails' on board are effectively plugged and drip trays are in position and empty.`,
		id: "CL_TNK_07_sssa_obae_pdtpe",
		ship: {
			value: "",
			id: "CL_TNK_07_sssa_obae_pdtpe_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_sssa_obae_pdtpe_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_sssa_obae_pdtpe_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_sssa_obae_pdtpe_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 11,
		subIndex: 0,
		qTxt: `Temporarily removed scupper plugs will be constantly monitored`,
		id: "CL_TNK_07_trmd_srps_wbcm",
		ship: {
			value: "",
			id: "CL_TNK_07_trmd_srps_wbcm_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_trmd_srps_wbcm_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_trmd_srps_wbcm_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_trmd_srps_wbcm_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 12,
		subIndex: 0,
		qTxt: `Shore spill containment and sumps are correctly managed.`,
		id: "CL_TNK_07_ssct_asae_cymg",
		ship: {
			value: "",
			id: "CL_TNK_07_ssct_asae_cymg_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_ssct_asae_cymg_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_ssct_asae_cymg_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_ssct_asae_cymg_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 13,
		subIndex: -1,
		qTxt: `The ship's unused cargo and bunker connections are properly secured with blank flanges fully bolted.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_sucb_caps_wbffb_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_sucb_caps_wbffb_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_sucb_caps_wbffb_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_sucb_caps_wbffb_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_sucb_caps_wbffb_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 14,
		subIndex: -1,
		qTxt: `The terminal's unused cargo and bunker connections are properly secured with blank flanges fully bolted.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tucb_caps_wbffb_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tucb_caps_wbffb_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_tucb_caps_wbffb_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tucb_caps_wbffb_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tucb_caps_wbffb_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 15,
		subIndex: -1,
		qTxt: `All cargo, ballast and bunker tank lids are closed. `,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_acrg_balb_tnklc_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_acrg_balb_tnklc_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_acrg_balb_tnklc_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_acrg_balb_tnklc_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_acrg_balb_tnklc_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 16,
		subIndex: -1,
		qTxt: `Sea and overboard discharge valves, when not in use, are closed and visibly secured.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_saodv_wnuac_visse_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_saodv_wnuac_visse_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_saodv_wnuac_visse_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_saodv_wnuac_visse_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_saodv_wnuac_visse_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 17,
		subIndex: 0,
		qTxt: `All external doors, ports and windows in the accommodation, stores and machinery spaces are closed. Engine room vents may be OPEN.`,
		id: "CL_TNK_07_tisa_aedp_wita_smsc_ervo",
		ship: {
			value: "",
			id: "CL_TNK_07_tisa_aedp_wita_smsc_ervo_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tisa_aedp_wita_smsc_ervo_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tisa_aedp_wita_smsc_ervo_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tisa_aedp_wita_smsc_ervo_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 18,
		subIndex: -1,
		qTxt: `The ship's emergency fire control plans are located externally.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tshpe_fctrl_pale_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tshpe_fctrl_pale_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_tshpe_fctrl_pale_code",
		},
		remarks: {
			value: [
				{
					"name": "Location:",
					"key": "CL_TNK_07_tshpe_fctrl_pale_rmks_l",
					"type": "string"
				}
			],
			id: "CL_TNK_07_tshpe_fctrl_pale_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tshpe_fctrl_pale_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `If the ship is fitted, or required to be fitted, with an Inert Gas System (IGS) the following points should be physically checked:`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: true,
	},
	{
		index: 0,
		subIndex: -1,
		qTxt: `Inert Gas System`,
		id: "",
		ship: {
			value: "Ship",
			id: "",
		},
		terminal: {
			value: "Terminal",
			id: "",
		},
		code: {
			value: "Code",
			id: "",
		},
		remarks: {
			value: "Ship Remarks",
			id: "",
		},
		bremarks: {
			value: "Terminal Remarks",
			id: "",
		},
		rep: false,
	},
	{
		index: 19,
		subIndex: 0,
		qTxt: `Fixed IGS pressure and Oxygen content recorders are working.`,
		id: "CL_TNK_07_figs_poxy_craw",
		ship: {
			value: "",
			id: "CL_TNK_07_figs_poxy_craw_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_figs_poxy_craw_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_figs_poxy_craw_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_figs_poxy_craw_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 20,
		subIndex: 0,
		qTxt: `All cargo tank atmospheres are at positive pressure with oxygen content of 8% or less by volume.`,
		id: "CL_TNK_07_acrg_tnkap_poxy_c8lv",
		ship: {
			value: "",
			id: "CL_TNK_07_acrg_tnkap_poxy_c8lv_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_acrg_tnkap_poxy_c8lv_t::remote",
		},
		code: {
			value: "PR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_acrg_tnkap_poxy_c8lv_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_acrg_tnkap_poxy_c8lv_trml_r::remote",
		},
		rep: true,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `Note: Shaded boxes indicate item not meant for that party (ship or terminal) `,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `PART 'B' - BULK LIQUID GENERAL - VERBAL VERIFICATION`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: 0,
		subIndex: -1,
		qTxt: `Bulk Liquid - General`,
		id: "",
		ship: {
			value: "Ship",
			id: "",
		},
		terminal: {
			value: "Terminal",
			id: "",
		},
		code: {
			value: "Code",
			id: "",
		},
		remarks: {
			value: "Ship Remarks",
			id: "",
		},
		bremarks: {
			value: "Terminal Remarks",
			id: "",
		},
		rep: false,
	},
	{
		index: 21,
		subIndex: 0,
		qTxt: `The ship is ready to move under its own power.`,
		id: "CL_TNK_07_tshp_isry_muop",
		ship: {
			value: "",
			id: "CL_TNK_07_tshp_isry_muop_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tshp_isry_muop_t::remote",
		},
		code: {
			value: "PR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tshp_isry_muop_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tshp_isry_muop_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 22,
		subIndex: 0,
		qTxt: `There is an effective deck watch in attendance on board and adequate supervision of operations on the ship and in the terminal.`,
		id: "CL_TNK_07_tiae_dwao_baso_sitt",
		ship: {
			value: "",
			id: "CL_TNK_07_tiae_dwao_baso_sitt_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tiae_dwao_baso_sitt_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tiae_dwao_baso_sitt_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tiae_dwao_baso_sitt_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 23,
		subIndex: 0,
		qTxt: `There are sufficient personnel on board and  ashore to deal with an emergency.`,
		id: "CL_TNK_07_tasp_obaa_tdwe",
		ship: {
			value: "",
			id: "CL_TNK_07_tasp_obaa_tdwe_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tasp_obaa_tdwe_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tasp_obaa_tdwe_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tasp_obaa_tdwe_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 24,
		subIndex: 0,
		qTxt: `The procedures for cargo, bunker and ballast  handling have been agreed.`,
		id: "CL_TNK_07_tpfc_bbhg_hbad",
		ship: {
			value: "",
			id: "CL_TNK_07_tpfc_bbhg_hbad_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tpfc_bbhg_hbad_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tpfc_bbhg_hbad_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tpfc_bbhg_hbad_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 25,
		subIndex: -1,
		qTxt: `The emergency signal and shutdown procedure to be used by the ship and shore have been explained and understood`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tess_ptbus_shbeud_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tess_ptbus_shbeud_trml::remote",
		},
		code: {
			value: "A",
			id: "CL_TNK_07_tess_ptbus_shbeud_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tess_ptbus_shbeud_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tess_ptbus_shbeud_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 26,
		subIndex: 0,
		qTxt: `Material safety data sheets (MSDS) for the cargo transfer have been exchanged where requested.`,
		id: "CL_TNK_07_msds_ftct_hbnex_wrrd",
		ship: {
			value: "",
			id: "CL_TNK_07_msds_ftct_hbnex_wrrd_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_msds_ftct_hbnex_wrrd_t::remote",
		},
		code: {
			value: "PR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_msds_ftct_hbnex_wrrd_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_msds_ftct_hbnex_wrrd_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 27,
		subIndex: -1,
		qTxt: `The hazards associated with toxic substances in the cargo being handled have been identified and understood.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_thaw_tscbh_hbiau_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_thaw_tscbh_hbiau_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_thaw_tscbh_hbiau_code",
		},
		remarks: {
			value: [
				{
					"name": "H2S Content:",
					"key": "CL_TNK_07_thaw_tscbh_hbiau_rmks_h2sc",
					"type": "string"
				},
				{
					"name": "Benzene Content:",
					"key": "CL_TNK_07_thaw_tscbh_hbiau_rmks_bc",
					"type": "string"
				}
			],
			id: "CL_TNK_07_thaw_tscbh_hbiau_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_thaw_tscbh_hbiau_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 28,
		subIndex: -1,
		qTxt: `An International Shore Fire Connection has  been provided.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_anint_shrf_chvpd_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_anint_shrf_chvpd_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_anint_shrf_chvpd_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_anint_shrf_chvpd_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_anint_shrf_chvpd_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 29,
		subIndex: 0,
		qTxt: `The agreed tank venting system will be used.`,
		id: "CL_TNK_07_tgad_tnkv_syswud",
		ship: {
			value: "",
			id: "CL_TNK_07_tgad_tnkv_syswud_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tgad_tnkv_syswud_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "Method:",
					"key": "CL_TNK_07_tgad_tnkv_syswud_r_m",
					"type": "string"
				}
			],
			id: "CL_TNK_07_tgad_tnkv_syswud_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tgad_tnkv_syswud_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 30,
		subIndex: 0,
		qTxt: `The requirements for closed operations have  been agreed.`,
		id: "CL_TNK_07_treq_frcd_oprt_hbad",
		ship: {
			value: "",
			id: "CL_TNK_07_treq_frcd_oprt_hbad_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_treq_frcd_oprt_hbad_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_treq_frcd_oprt_hbad_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_treq_frcd_oprt_hbad_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 31,
		subIndex: -1,
		qTxt: `The operation of the P/V system has been verified.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_toot_pvsys_hbvd_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_toot_pvsys_hbvd_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_toot_pvsys_hbvd_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_toot_pvsys_hbvd_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_toot_pvsys_hbvd_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 32,
		subIndex: 0,
		qTxt: `Where a vapor return line is connected, operating parameters have been agreed.`,
		id: "CL_TNK_07_whrv_rtli_cops_hbad",
		ship: {
			value: "",
			id: "CL_TNK_07_whrv_rtli_cops_hbad_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_whrv_rtli_cops_hbad_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_whrv_rtli_cops_hbad_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_whrv_rtli_cops_hbad_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 33,
		subIndex: 0,
		qTxt: `Independent high level alarms, if fitted, are operational and have been tested.`,
		id: "CL_TNK_07_indh_lval_ifao_ahbt",
		ship: {
			value: "",
			id: "CL_TNK_07_indh_lval_ifao_ahbt_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_indh_lval_ifao_ahbt_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_indh_lval_ifao_ahbt_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_indh_lval_ifao_ahbt_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 34,
		subIndex: 0,
		qTxt: `Adequate electrical insulating means are in place in the ship /shore connection.`,
		id: "CL_TNK_07_adte_elei_maip_sscn",
		ship: {
			value: "",
			id: "CL_TNK_07_adte_elei_maip_sscn_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_adte_elei_maip_sscn_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "Check condition of cable and  connection.",
					"key": "CL_TNK_07_adte_elei_maip_sscn_r_cccc",
					"type": "string"
				}
			],
			id: "CL_TNK_07_adte_elei_maip_sscn_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_adte_elei_maip_sscn_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 35,
		subIndex: 0,
		qTxt: `Shore lines are fitted with a non-return valve or procedures to avoid 'back filling' have been discussed`,
		id: "CL_TNK_07_shrl_fnrv_oabfhd",
		ship: {
			value: "",
			id: "CL_TNK_07_shrl_fnrv_oabfhd_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_shrl_fnrv_oabfhd_t::remote",
		},
		code: {
			value: "PR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_shrl_fnrv_oabfhd_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_shrl_fnrv_oabfhd_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 36,
		subIndex: 0,
		qTxt: `Smoking rooms have been identified and smoking requirements are being observed.`,
		id: "CL_TNK_07_skgr_hbid_srbod",
		ship: {
			value: "",
			id: "CL_TNK_07_skgr_hbid_srbod_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_skgr_hbid_srbod_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: [
				{
					"name": "Nominated smoking rooms:",
					"key": "CL_TNK_07_skgr_hbid_srbod_r_nsr",
					"type": "string"
				}
			],
			id: "CL_TNK_07_skgr_hbid_srbod_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_skgr_hbid_srbod_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 37,
		subIndex: 0,
		qTxt: `Naked light regulations are being observed.`,
		id: "CL_TNK_07_nkdl_regs_abgod",
		ship: {
			value: "",
			id: "CL_TNK_07_nkdl_regs_abgod_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_nkdl_regs_abgod_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_nkdl_regs_abgod_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_nkdl_regs_abgod_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 38,
		subIndex: 0,
		qTxt: `Ship/shore telephones, mobile phones and pager requirements are being observed`,
		id: "CL_TNK_07_spsr_tpmp_prbod",
		ship: {
			value: "",
			id: "CL_TNK_07_spsr_tpmp_prbod_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_spsr_tpmp_prbod_t::remote",
		},
		code: {
			value: "AR",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_spsr_tpmp_prbod_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_spsr_tpmp_prbod_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 39,
		subIndex: -1,
		qTxt: `Hand torches (flash Lights) are of an approved type.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_hndt_flao_apdt_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_hndt_flao_apdt_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_hndt_flao_apdt_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_hndt_flao_apdt_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_hndt_flao_apdt_rmks::remote",
		},
		rep: false,
	},
	{
		index: 40,
		subIndex: -1,
		qTxt: `Fixed VHF/UHF transceivers and AIS equipment are on the correct power mode or switched off.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_fvhfuhf_taise_aotc_pmoso_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_fvhfuhf_taise_aotc_pmoso_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_fvhfuhf_taise_aotc_pmoso_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_fvhfuhf_taise_aotc_pmoso_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_fvhfuhf_taise_aotc_pmoso_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 41,
		subIndex: -1,
		qTxt: `Portable VHF/UHF transceivers are of an approved type.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_pvhf_uhft_aoapte_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_pvhf_uhft_aoapte_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_pvhf_uhft_aoapte_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_pvhf_uhft_aoapte_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_pvhf_uhft_aoapte_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 42,
		subIndex: -1,
		qTxt: `The ship's main radio transmitter aerials are earthed and radars are switched off.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tsmr_taae_aras_off_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tsmr_taae_aras_off_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_tsmr_taae_aras_off_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tsmr_taae_aras_off_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tsmr_taae_aras_off_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 43,
		subIndex: -1,
		qTxt: `Electric cables to portable electrical equipment within the hazardous area are disconnected from power.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_ectp_eewh_aadc_frpr_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_ectp_eewh_aadc_frpr_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_ectp_eewh_aadc_frpr_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_ectp_eewh_aadc_frpr_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_ectp_eewh_aadc_frpr_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 44,
		subIndex: -1,
		qTxt: `Window type air conditioning units are disconnected. `,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_wint_acua_disd_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_wint_acua_disd_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_wint_acua_disd_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_wint_acua_disd_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_wint_acua_disd_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 45,
		subIndex: -1,
		qTxt: `Positive pressure is being maintained inside the accommodation, and air conditioning intakes, which may permit the entry of cargo vapors, are closed.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_ppbm_itaa_ciwmp_tecvc_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_ppbm_itaa_ciwmp_tecvc_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_ppbm_itaa_ciwmp_tecvc_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_ppbm_itaa_ciwmp_tecvc_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_ppbm_itaa_ciwmp_tecvc_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 46,
		subIndex: 0,
		qTxt: `Measures have been taken to ensure sufficient mechanical ventilation in the pump room.`,
		id: "CL_TNK_07_mshb_tkne_smlvn_itppr",
		ship: {
			value: "",
			id: "CL_TNK_07_mshb_tkne_smlvn_itppr_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_mshb_tkne_smlvn_itppr_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_mshb_tkne_smlvn_itppr_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_mshb_tkne_smlvn_itppr_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 47,
		subIndex: -1,
		qTxt: `There is provision for an emergency escape. `,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_thri_prvn_faeme_esc_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_thri_prvn_faeme_esc_trml::remote",
		},
		code: {
			value: "",
			id: "CL_TNK_07_thri_prvn_faeme_esc_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_thri_prvn_faeme_esc_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_thri_prvn_faeme_esc_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `Note: Shaded boxes indicate item not meant for that party (ship or terminal)`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: 48,
		subIndex: -1,
		qTxt: `The maximum wind and swell criteria for operations has been agreed`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tmmw_scaf_ophbg_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tmmw_scaf_ophbg_trml::remote",
		},
		code: {
			value: "A",
			id: "CL_TNK_07_tmmw_scaf_ophbg_code",
		},
		remarks: {
			value: [
				{
					"name": "Stop Cargo at :",
					"key": "CL_TNK_07_tmmw_scaf_ophbg_rmks_sca",
					"type": "string"
				},
				{
					"name": "Disconnect at:",
					"key": "CL_TNK_07_tmmw_scaf_ophbg_rmks_da",
					"type": "string"
				},
				{
					"name": "Unberth at:",
					"key": "CL_TNK_07_tmmw_scaf_ophbg_rmks_ua",
					"type": "string"
				}
			],
			id: "CL_TNK_07_tmmw_scaf_ophbg_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tmmw_scaf_ophbg_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 49,
		subIndex: -1,
		qTxt: `Security protocols have been agreed between the Ship Security Officer and the Port Facility Security Officer, if appropriate`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_sphba_ssopf_soria_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_sphba_ssopf_soria_trml::remote",
		},
		code: {
			value: "A",
			id: "CL_TNK_07_sphba_ssopf_soria_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_sphba_ssopf_soria_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_sphba_ssopf_soria_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 50,
		subIndex: -1,
		qTxt: `Where appropriate procedures have been agreed for receiving Nitrogen supplied from shore, either for inerting or purging ship's tanks, or for line clearing into the ship`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_wapar_nsfse_ipstcs_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_wapar_nsfse_ipstcs_trml::remote",
		},
		code: {
			value: "AP",
			id: "CL_TNK_07_wapar_nsfse_ipstcs_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_wapar_nsfse_ipstcs_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_wapar_nsfse_ipstcs_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `If the ship is fitted, or required to be fitted, with an Inert Gas System (IGS) the following statements should be addressed.`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: 0,
		subIndex: -1,
		qTxt: `Inert Gas System`,
		id: "",
		ship: {
			value: "Ship",
			id: "",
		},
		terminal: {
			value: "Terminal",
			id: "",
		},
		code: {
			value: "Code",
			id: "",
		},
		remarks: {
			value: "Ship Remarks",
			id: "",
		},
		bremarks: {
			value: "Terminal Remarks",
			id: "",
		},
		rep: false,
	},
	{
		index: 51,
		subIndex: -1,
		qTxt: `The IGS is fully operational and in good working order. `,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tigs_ifopl_gwor_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tigs_ifopl_gwor_trml::remote",
		},
		code: {
			value: "P",
			id: "CL_TNK_07_tigs_ifopl_gwor_code",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tigs_ifopl_gwor_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tigs_ifopl_gwor_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 52,
		subIndex: 0,
		qTxt: `Deck seals, or equivalent, are in good working order.`,
		id: "CL_TNK_07_dcks_oeta_igdwor",
		ship: {
			value: "",
			id: "CL_TNK_07_dcks_oeta_igdwor_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_dcks_oeta_igdwor_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_dcks_oeta_igdwor_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_dcks_oeta_igdwor_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 53,
		subIndex: 0,
		qTxt: `Liquid levels in pressure/vacuum breakers are correct.`,
		id: "CL_TNK_07_ldls_ipvbs_acrct",
		ship: {
			value: "",
			id: "CL_TNK_07_ldls_ipvbs_acrct_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_ldls_ipvbs_acrct_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_ldls_ipvbs_acrct_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_ldls_ipvbs_acrct_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 54,
		subIndex: 0,
		qTxt: `The fixed and portable oxygen analyzers have been calibrated and are working properly.`,
		id: "CL_TNK_07_tfxd_prox_azhb_calwp",
		ship: {
			value: "",
			id: "CL_TNK_07_tfxd_prox_azhb_calwp_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tfxd_prox_azhb_calwp_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tfxd_prox_azhb_calwp_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tfxd_prox_azhb_calwp_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 55,
		subIndex: 0,
		qTxt: `All the individual tank IGS valves (if fitted)  are correctly set and locked `,
		id: "CL_TNK_07_allti_tigsv_acsal",
		ship: {
			value: "",
			id: "CL_TNK_07_allti_tigsv_acsal_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_allti_tigsv_acsal_t::remote",
		},
		code: {
			value: "R",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_allti_tigsv_acsal_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_allti_tigsv_acsal_trml_r::remote",
		},
		rep: true,
	},
	{
		index: 56,
		subIndex: -1,
		qTxt: `All personnel in charge of cargo operations are aware that in the case of failure of the Inert Gas Plant, discharge operations should cease, and the terminal be advised.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_apcc_opaat_cfigp_doscta_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_apcc_opaat_cfigp_doscta_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_apcc_opaat_cfigp_doscta_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_apcc_opaat_cfigp_doscta_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `Note: Shaded boxes indicate item not meant for that party (ship or terminal)`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `If the ship is fitted with a crude oil washing (COW) system, and intends to crude oil wash, the following statements should be addressed.`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: 0,
		subIndex: -1,
		qTxt: `Crude Oil Washing`,
		id: "",
		ship: {
			value: "Ship",
			id: "",
		},
		terminal: {
			value: "Terminal",
			id: "",
		},
		code: {
			value: "Code",
			id: "",
		},
		remarks: {
			value: "Ship Remarks",
			id: "",
		},
		bremarks: {
			value: "Terminal Remarks",
			id: "",
		},
		rep: false,
	},
	{
		index: 57,
		subIndex: -1,
		qTxt: `The Pre-Arrival COW checklist, as contained in the approved COW manual, has been satisfactorily completed.`,
		id: "CL_TNK_07_tfxd_prox_azhb_calwp",
		ship: {
			value: "",
			id: "CL_TNK_07_tpacc_acacm_hbsc_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tpacc_acacm_hbsc_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tpacc_acacm_hbsc_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tpacc_acacm_hbsc_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 58,
		subIndex: 0,
		qTxt: `The COW check lists for use before, during and after COW, as contained in the approved COW manual, are available and being used. `,
		id: "CL_TNK_07_tcwc_lfub_dacc_acmabu",
		ship: {
			value: "",
			id: "CL_TNK_07_tcwc_lfub_dacc_acmabu_s",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tcwc_lfub_dacc_acmabu_t::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tcwc_lfub_dacc_acmabu_r",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tcwc_lfub_dacc_acmabu_trml_r::remote",
		},
		rep: true,
	},
	{
		index: -1,
		subIndex: -1,
		qTxt: `If the ship is planning to tank clean alongside, the following statements should be addressed.`,
		id: "",
		ship: {
			value: "",
			id: "",
		},
		terminal: {
			value: "",
			id: "",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "",
		},
		bremarks: {
			value: "",
			id: "",
		},
		rep: false,
	},
	{
		index: 0,
		subIndex: -1,
		qTxt: `Tank Cleaning`,
		id: "",
		ship: {
			value: "Ship",
			id: "",
		},
		terminal: {
			value: "Terminal",
			id: "",
		},
		code: {
			value: "Code",
			id: "",
		},
		remarks: {
			value: "Ship Remarks",
			id: "",
		},
		bremarks: {
			value: "Terminal Remarks",
			id: "",
		},
		rep: false,
	},
	{
		index: 59,
		subIndex: -1,
		qTxt: `Tank cleaning operations are planned during  the ship's stay alongside the shore
		installation.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tkcop_apdts_sast_shri_ship",
		},
		terminal: {
			value: ["Yes", "No"],
			id: "CL_TNK_07_tkcop_apdts_sast_shri_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tkcop_apdts_sast_shri_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tkcop_apdts_sast_shri_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 60,
		subIndex: -1,
		qTxt: `If 'yes' the procedures and approvals for tank  cleaning have been agreed. `,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_iyes_paaf_tnkc_hbad_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_iyes_paaf_tnkc_hbad_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_iyes_paaf_tnkc_hbad_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_iyes_paaf_tnkc_hbad_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 61,
		subIndex: -1,
		qTxt: `Permission has been granted for gas freeing  operations.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_prmn_hbgd_fgsfo_ship",
		},
		terminal: {
			value: ["Yes", "No"],
			id: "CL_TNK_07_prmn_hbgd_fgsfo_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_prmn_hbgd_fgsfo_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_prmn_hbgd_fgsfo_trml_rmks::remote",
		},
		rep: false,
	},
];

const tableJson1 = [
	{
		index: 1,
		subIndex: -1,
		qTxt: `Material Safety Data Sheets are available giving the necessary data for the safe handling of the cargo.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_msds_aagt_ndft_shtcg_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_msds_aagt_ndft_shtcg_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_msds_aagt_ndft_shtcg_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_msds_aagt_ndft_shtcg_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 2,
		subIndex: -1,
		qTxt: `A manufacturer's inhibition certificate, where applicable, has been provided.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_amic_waple_hbpd_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_amic_waple_hbpd_trml::remote",
		},
		code: {
			value: "P",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_amic_waple_hbpd_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_amic_waple_hbpd_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 3,
		subIndex: -1,
		qTxt: `Counter measures against accidental personal contact with the cargo have been agreed.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_cmaa_pcwt_crgh_bnagd_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_cmaa_pcwt_crgh_bnagd_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_cmaa_pcwt_crgh_bnagd_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_cmaa_pcwt_crgh_bnagd_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 4,
		subIndex: -1,
		qTxt: `Sufficient protective clothing and equipment (including self- contained breathing apparatus) is ready for immediate use and is suitable for the product being handled`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_sufpc_eirf_iuau_sftpbh_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_sufpc_eirf_iuau_sftpbh_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_sufpc_eirf_iuau_sftpbh_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_sufpc_eirf_iuau_sftpbh_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 5,
		subIndex: -1,
		qTxt: `The cargo handling rate is compatible with the automatic shutdown system, if in use.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_tchr_cwta_snsiu_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_tchr_cwta_snsiu_trml::remote",
		},
		code: {
			value: "A",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_tchr_cwta_snsiu_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_tchr_cwta_snsiu_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 6,
		subIndex: -1,
		qTxt: `Cargo system gauges and alarms are correctly set and in good order.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_crgs_gaaa_crcsgr_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_crgs_gaaa_crcsgr_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_crgs_gaaa_crcsgr_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_crgs_gaaa_crcsgr_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 7,
		subIndex: -1,
		qTxt: `Portable vapour detection instruments are readily available for the products being  handled.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_pvdi_araf_tpbh_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_pvdi_araf_tpbh_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_pvdi_araf_tpbh_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_pvdi_araf_tpbh_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 8,
		subIndex: -1,
		qTxt: `Information on fire-fighting media and procedures has been exchanged.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_info_ffma_prhb_excd_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_info_ffma_prhb_excd_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_info_ffma_prhb_excd_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_info_ffma_prhb_excd_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 9,
		subIndex: -1,
		qTxt: `Transfer hoses are of suitable material,resistant to the action of the products being handled.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_thsm_rtta_otpbh_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_thsm_rtta_otpbh_trml::remote",
		},
		code: {
			value: "",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_thsm_rtta_otpbh_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_thsm_rtta_otpbh_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 10,
		subIndex: -1,
		qTxt: `Cargo handling is being performed with the permanent installed pipeline system.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_chibp_wtpi_pplns_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_chibp_wtpi_pplns_trml::remote",
		},
		code: {
			value: "P",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_chibp_wtpi_pplns_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_chibp_wtpi_pplns_trml_rmks::remote",
		},
		rep: false,
	},
	{
		index: 11,
		subIndex: -1,
		qTxt: `Where appropriate procedures have been agreed for receiving Nitrogen Supplied from shore, either for inerting or purging ship's tanks, or for line clearing into the ship.`,
		id: "",
		ship: {
			value: "",
			id: "CL_TNK_07_waphba_rnsfse_pstcs_ship",
		},
		terminal: {
			value: "",
			id: "CL_TNK_07_waphba_rnsfse_pstcs_trml::remote",
		},
		code: {
			value: "AP",
			id: "",
		},
		remarks: {
			value: "",
			id: "CL_TNK_07_waphba_rnsfse_pstcs_rmks",
		},
		bremarks: {
			value: "",
			id: "CL_TNK_07_waphba_rnsfse_pstcs_trml_rmks::remote",
		},
		rep: false,
	},
];

const RadioGroup = (props: { extForm: any; updateData: any; d: any }) => {
	const { extForm, updateData, d } = props;
	return (
		<fieldset id={d.id}>
			{d.terminal.value.map((item: string, index: number) => (
				<>
					<input
					    className="m-2"
						key={item}
						type="radio"
						name={d.id}
						checked={extForm?.data?.[d.terminal.id] === item ? true : false}
						onChange={function onChange(e) {
							updateData(d.terminal.id, item);
						}}
					/>
					<label
						onClick={function onClick(e) {	
							updateData(d.terminal.id, item);
						}}
					>
						{item}
					</label>
				</>
			))}
		</fieldset>
	);
};

const ShipOps = (props: {
	extForm: any;
	updateData: any;
	updateTable: any;
}) => {
	const { extForm, updateData, updateTable } = props;
	const [shipOps, setShipOps] = useState(tableJson);

	useEffect(() => {
		const modTable = [...shipOps];
		modTable.forEach(function (d) {
			if (Array.isArray(extForm?.data?.[d.id]) && d.code.value.includes("R")) {
				if(extForm?.data?.[d.id].length > 1) {
					extForm?.data?.[d.id].forEach((element: any, index: number) => {
						if(extForm?.data?.[d.id].length-1 == index) {
							modTable[modTable.indexOf(d)].subIndex = extForm?.data?.[d.id].length-1
						} else {
							modTable.splice(modTable.indexOf(d)+1, 0, {
								...d,
								subIndex: index,
								code: {
									value: "",
									id: "",
								},
							});
						}
						
					});
				}
			}
		});
		setShipOps(modTable)

	}, [])

	const _ui = shipOps.map((d: any, index) => {
		let row =
			d.index === -1 ? (
				<tr>
					<td colSpan={6} className="py-6">
						<b>{d.qTxt}</b>
					</td>
				</tr>
			) : d.index === 0 ? (
				<tr>
					<td className="w-8 text-center border border-black"></td>
					<td className="w-8 text-center border border-black">
						<b>{d.qTxt}</b>
					</td>
					<td className="w-8 text-center border border-black">
						<b>{d.ship.value}</b>
					</td>
					<td className="w-8 text-center border border-black">
						<b>{d.terminal.value}</b>
					</td>
					<td className="w-8 text-center border border-black">
						<b>{d.code.value}</b>
					</td>
					<td className="w-8 text-center border border-black">
						<b>{d.remarks.value}</b>
					</td>
					<td className="w-8 text-center border border-black">
						<b>{d.bremarks.value}</b>
					</td>
				</tr>
			) : (
				<tr>
					<td className="text-center border border-black">{d.index}</td>
					<td className="border border-black">{d.qTxt}</td>
					<td className="border border-black">
						<textarea
							className="w-full"
							disabled
							value={
								d.rep
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.ship.id]
										? extForm?.data?.[d.id]?.[d.subIndex]?.[d.ship.id]
										: ""
									: extForm?.data?.[d.ship.id]
									? extForm?.data?.[d.ship.id]
									: ""
							}
						/>
					</td>
					<td className="border border-black">
						{
							Array.isArray(d.terminal.value) ? 
								(<RadioGroup extForm={extForm} updateData={updateData} d={d} />)
								: 
								(
									<textarea
										className="w-full"
										disabled={
											[5, 7, 10, 11, 13, 15, 16 ,17 ,18, 19, 20, 21, 31, 33, 40, 42, 44, 45, 46, 51, 52, 53, 54, 55, 56, 57, 58].includes(d.index)
											? true : false
											}
										value={
											d.rep
												? extForm?.data?.[d.id]?.[d.subIndex]?.[d.terminal.id]
													? extForm?.data?.[d.id]?.[d.subIndex]?.[d.terminal.id]
													: ""
												: extForm?.data?.[d.terminal.id]
												? extForm?.data?.[d.terminal.id]
												: ""
										}
										onChange={(e) => {
											e.preventDefault();
											if (d.rep) {
												updateTable(d.terminal.id, e.target.value, d.id, d.subIndex);
											} else {
												updateData(d.terminal.id, e.target.value);
											}
										}}
									/>
								)
						}
					</td>
					<td className="text-center border border-black">
						{d.code.value.includes("R") ? (
						<button
							className="p-2 ml-3 text-white bg-blue-500"
							onClick={function onRClick(e) {
								e.preventDefault()
								const clone = [...shipOps]
								const obj = clone[clone.indexOf(d)]
								clone.splice(clone.indexOf(d) + 1, 0, {
									...d,
									subIndex: (obj.subIndex),
									code: {
										value: "",
										id: obj.id,
									},
								});
								clone[clone.indexOf(d)].subIndex = obj.subIndex+1
								setShipOps(clone);
							}}
						>
							{d.code.value}
						</button>
					) : (
						d.code.value
					)}
					</td>
					<td className="border border-black">
						<textarea
							className="w-full"
							disabled
							value={
								d.rep
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.remarks.id]
										? extForm?.data?.[d.id]?.[d.subIndex]?.[d.remarks.id]
										: ""
									: extForm?.data?.[d.remarks.id]
									? extForm?.data?.[d.remarks.id]
									: ""
							}
							onChange={(e) => {
								e.preventDefault();
								if (d.rep) {
									updateTable([d.remarks.id], e.target.value, d.id, d.subIndex);
								} else {
									updateData(d.remarks.id, e.target.value);
								}
							}}
						/>
						{
							d.remarks.value.length > 0 ? (
								<>
								{
								d.remarks.value.map((re: any, index: number) => (
									<>
									<br/>
									{re.name}
									<input type="string" disabled value={
										d.rep
											? extForm?.data?.[d.id]?.[d.subIndex]?.[re.key]
												? extForm?.data?.[d.id]?.[d.subIndex]?.[re.key]
												: ""
											: extForm?.data?.[re.key]
											? extForm?.data?.[re.key]
											: ""
									} onChange={(e) => {
										e.preventDefault();
										if (d.rep) {
											updateTable([re.key], e.target.value, d.id, d.subIndex);
										} else {
											updateData(re.key, e.target.value);
										}
									}}/></>
								))
								}
								</>
							) : ""
						}
					</td>
					<td className="border border-black">
						<textarea
							className="w-full"
							value={
								d.rep
									? extForm?.data?.[d.id]?.[d.subIndex]?.[d.bremarks.id]
										? extForm?.data?.[d.id]?.[d.subIndex]?.[d.bremarks.id]
										: ""
									: extForm?.data?.[d.bremarks.id]
									? extForm?.data?.[d.bremarks.id]
									: ""
							}
							onChange={(e) => {
								e.preventDefault();
								if (d.rep) {
									updateTable([d.bremarks.id], e.target.value, d.id, d.subIndex);
								} else {
									updateData(d.bremarks.id, e.target.value);
								}
							}}
						/>
					</td>
				</tr>
			);
		return row;
	});
	return <tbody>{_ui}</tbody>;
};

const ShipOps1 = (props: {
	extForm: any;
	updateData: any;
	updateTable: any;
}) => {
	const { extForm, updateData, updateTable } = props;
	const _ui = tableJson1.map((d: any, index: number) => {
		return (
			<tr>
				<td className="text-center border border-black">{d.index}</td>
				<td className="border border-black">{d.qTxt}</td>
				<td className="border border-black">
					<textarea
						className="w-full"
						disabled
						value={
							extForm?.data?.[d.ship.id]
								? extForm?.data?.[d.ship.id]
								: ""
						}
					/>
				</td>
				<td className="border border-black">
					<textarea
						className="w-full"
						value={
							extForm?.data?.[d.terminal.id]
								? extForm?.data?.[d.terminal.id]
								: ""
						}
						onChange={(e) => {
							e.preventDefault()
							updateData(d.terminal.id, e.target.value);

						}}
					/>
				</td>
				<td className="text-center border border-black">{d.code.value}</td>
				<td className="border border-black">
					<textarea
						className="w-full"
						disabled
						value={
							extForm?.data?.[d.remarks.id]
								? extForm?.data?.[d.remarks.id]
								: ""
						}
						onChange={(e) => {
							e.preventDefault();
							updateData(d.remarks.id, e.target.value);
						}}
					/>
				</td>
				<td className="border border-black">
						<textarea
							className="w-full"
							value={
								extForm?.data?.[d.bremarks.id]
									? extForm?.data?.[d.bremarks.id]
									: ""
							}
							onChange={(e) => {
								e.preventDefault();
								updateData(d.bremarks.id, e.target.value);
							}}
						/>
					</td>
			</tr>
		);
	});
	return <tbody>{_ui}</tbody>;
};

const CLTnk07: React.FC<any> = (props: any) => {
	const { extForm } = props;

	const [isModalOpen, setModalOpen] = useState(false);

	function triggerSubmitOperation() {
		setModalOpen(true);
	}

	function resetOperations() {
		setModalOpen(false);
	}

	useEffect(() => {
		if(extForm.id === undefined) {
			props.formRedirect()
		}
	}, []);

	/**
	*This is to update store with key and value
	* @param key key of the attributes
	* @param val value to be set
	*/
	function updateData(key: string, val: string) {
		if (key) {
			const data = { ...extForm };
			data.data[key] = val;
			props.formFieldEdit({ ...data });
		}
	}

	/**
	 * This is the update table value in store
	 * @param key which key should be updated
	 * @param val value of the paramter
	 * @param parentKey parent element key
	 * @param index index in table
	 */
	function updateTable(
		key: string,
		val: string,
		parentKey: string,
		index: number
	) {
		const data = { ...extForm };
		if (key) {
			if (data?.data?.[parentKey]?.[index]?.[key]) {
				data.data[parentKey][index][key] = val;
			} else {
				if (data.data[parentKey] === undefined) {
					data.data[parentKey] = [];
				}
				if (data.data[parentKey].length === 0) {
					data.data[parentKey].push({});
					data.data[parentKey][index][key] = val;
				} else if(data.data[parentKey][index]) {
					data.data[parentKey][index][key] = val;
				} else {
					if(index > data.data[parentKey].length) {
						for(let i=data.data[parentKey].length-1; i < (index+1); i++ ) {
							data.data[parentKey].push({});
						}
					} else {
						data.data[parentKey].push({});
					}
				}
			}
			props.formFieldEdit({ ...data });
		}
	}
	return (
		<div className="m-20 text-lg">
			<form
				id="checklistForm"
				className="bg-white"
				// onSubmit={function onSubmit(e) {
				// 	e.preventDefault();
				// 	console.log("From Here 1")
				// 	props.formSubmit();
				// }}
			>
				<div className="flex flex-col justify-center min-h-screen 0y-6 sm:py-12">
					<div className="font-bold place-self-center">
						SHIP/SHORE SAFETY CHECKLIST
					</div>
					<div className="flex grid grid-cols-4 mx-4 mt-2">
						<div className="pl-5 border border-black">Ship's Name:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								disabled
								value={
									extForm?.data?.vesselName
										? extForm?.data.vesselName
										: ""
								}
							/>
						</div>
						<div className="pl-5 border border-black">Voyege No:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								disabled
								value={
									extForm?.data?.CL_TNK_07_voyage_num
										? extForm?.data.CL_TNK_07_voyage_num
										: ""
								}
							/>
						</div>
						<div className="pl-5 border border-black">Berth:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								disabled
								value={
									extForm?.data?.CL_TNK_07_berth
										? extForm?.data.CL_TNK_07_berth
										: ""
								}
							/>
						</div>
						<div className="pl-5 border border-black">Port: </div>
						<div className="border border-black">
							<textarea
								className="w-full"
								value={
									extForm?.data?.["CL_TNK_07_port::remote"]
										? extForm?.data?.["CL_TNK_07_port::remote"]
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_port::remote", e.target.value)
								}}
							/>
						</div>
						<div className="pl-5 border border-black">Date of Arrival:</div>
						<div className="border border-black">
							<input
								type="date"
								className="w-full"
								disabled
								value={
									extForm?.data?.CL_TNK_07_date_of_arvl
										? extForm?.data.CL_TNK_07_date_of_arvl
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_date_of_arvl", e.target.value)
								}}
							/>
						</div>
						<div className="pl-5 border border-black">Time of arrival:</div>
						<div className="border border-black">
							<input
								className="w-full h-full"
								type="time"
								disabled
								value={
									extForm?.data?.CL_TNK_07_time_of_arvl
										? extForm?.data.CL_TNK_07_time_of_arvl
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_time_of_arvl", e.target.value)
								}}
							/>
						</div>
					</div>

					<div className="mx-4 mt-2 font-bold">
						INSTRUCTIONS FOR COMPLETION:
					</div>
					<div className="mx-4 mt-2">
						The safety of operations requires that all questions should be
						answered affirmatively by clearly ticking (√) the appropriate box.
						If an affirmative answer is not possible, the reason should be given
						and agreement reached upon appropriate precautions to be taken
						between the ship and the terminal. Where any question is considered
						to be not applicable, then a note to that effect should be inserted
						in the remarks column.
					</div>
					<div className="mx-4 mt-2">
						A box in the column's 'ship' and 'terminal' indicates that checks
						should be carried out by the party concerned.
					</div>
					<div className="mx-4 mt-2">
						The presence of the letters A, P or R in the column 'Code' indicates
						the following:
					</div>
					<div className="mx-4 mt-2">
						<b>A: (‘Agreement’)</b> Any procedures and agreements should be in
						writing in the remarks column of this checklist or other mutually
						acceptable form. The signature of both parties should be required.
					</div>
					<div className="mx-4 mt-2">
						<b>P: (‘Permission’)</b> In the case of a negative answer, the
						operation should not be carried out without the written permission
						from the Port Authority.
					</div>
					<div className="mx-4 mt-2">
						<b>R: (‘Re-check’)</b> Indicates items to be rechecked at intervals
						not exceeding that agreed in this declaration. *
					</div>
					<div className="mx-4 mt-2">
						Record of repetitive checks carried out by Ship staff and Shore
						staff for items marked "R".
					</div>
					<table className="mx-4 mt-2 border-collapse table-fixed">
						<thead>
							<tr>
								<th className="w-1/5 border border-black">DATE/TIME</th>
								<th className="w-1/5 border border-black">
									Signature FOR SHIP
								</th>
								<th className="w-1/5 border border-black">
									Signature FOR SHORE
								</th>
								<th className="w-1/6 border border-black">SHIP REMARKS</th>
								<th className="w-1/4 border border-black">SHORE REMARKS</th>
							</tr>
						</thead>
						<tbody>
							{[...Array(15)]?.map(
								(x: any, i: number) => (
									<tr>
										<td className="border border-black">
											<input type="date"
												className="w-full "
												value={
													extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.["CL_TNK_07_instrn_fr_cmpln_d::remote"]
														? extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]
																?.["CL_TNK_07_instrn_fr_cmpln_d::remote"]
														: ""
												}
												onChange={(e) => {
													e.preventDefault();
													updateTable("CL_TNK_07_instrn_fr_cmpln_d::remote", e.target.value, "CL_TNK_07_instrn_fr_cmpln", i)
												}}
											/>
											<input type="time"
											className="w-full"
											value={
												extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.["CL_TNK_07_instrn_fr_cmpln_t::remote"]
													? extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]
															?.["CL_TNK_07_instrn_fr_cmpln_t::remote"]
													: ""
											}
											onChange={(e) => {
												e.preventDefault();
												updateTable("CL_TNK_07_instrn_fr_cmpln_t::remote", e.target.value, "CL_TNK_07_instrn_fr_cmpln", i)
											}}/>
										</td>
										<td className="border border-black">
											<textarea
												className="w-full "
												disabled
												value={
													extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.[
														"CL_TNK_07_instrn_fr_cmpln_sfsp"
													]
														? extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.[
																"CL_TNK_07_instrn_fr_cmpln_sfsp"
														  ]
														: ""
												}
												onChange={(e) => {
													e.preventDefault();
													updateTable("CL_TNK_07_instrn_fr_cmpln_sfsp", e.target.value, "CL_TNK_07_instrn_fr_cmpln", i)
												}}
											/>
										</td>
										<td className="border border-black">
											<textarea
												className="w-full "
												value={
													extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.[
														"CL_TNK_07_instrn_fr_cmpln_sfsr::remote"
													]
														? extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.[
															"CL_TNK_07_instrn_fr_cmpln_sfsr::remote"
														  ]
														: ""
												}
												onChange={(e) => {
													e.preventDefault();
													updateTable("CL_TNK_07_instrn_fr_cmpln_sfsr::remote", e.target.value, "CL_TNK_07_instrn_fr_cmpln", i)
												}}
											/>
										</td>
										<td className="w-full border border-black">
											<textarea
												rows={2}
												disabled
												className="w-full "
												value={
													extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.CL_TNK_07_instrn_fr_cmpln_r
														? extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]
																?.CL_TNK_07_instrn_fr_cmpln_r
														: ""
												}
												onChange={(e) => {
													e.preventDefault();
													updateTable("CL_TNK_07_instrn_fr_cmpln_r", e.target.value, "CL_TNK_07_instrn_fr_cmpln", i)
												}}
											/>
										</td>
										<td className="w-full border border-black">
											<textarea
												rows={2}
												className="w-full "
												value={
													extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]?.["CL_TNK_07_instrn_fr_cmpln_trml_r::remote"]
														? extForm?.data?.["CL_TNK_07_instrn_fr_cmpln"]?.[i]
																?.["CL_TNK_07_instrn_fr_cmpln_trml_r::remote"]
														: ""
												}
												onChange={(e) => {
													e.preventDefault();
													updateTable("CL_TNK_07_instrn_fr_cmpln_trml_r::remote", e.target.value, "CL_TNK_07_instrn_fr_cmpln", i)
												}}
											/>
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					<div className="mx-4 mt-2 italic">
						(* Maximum interval for carrying out repetitive checks{" "}
						<u>will not exceed 6 hours)</u>
					</div>
					<div className="mx-4 mt-2 font-semibold">
						PART 'A' - BULK LIQUID GENERAL - PHYSICAL CHECKS
					</div>
					<table className="mx-4 mt-2 border-collapse table-fixed">
						<thead>
							<tr>
								<th className="border border-black"></th>
								<th className="border border-black w-1/3">
									Bulk Liquid - General{" "}
								</th>
								<th className="border border-black w-1/6">Ship</th>
								<th className="w-1/6 border border-black">Terminal</th>
								<th className=" border border-black">Code</th>
								<th className="border border-black w-1/6">Ship Remarks</th>
								<th className="border border-black w-1/4">Terminal Remarks</th>
							</tr>
						</thead>
						<ShipOps
							extForm={extForm}
							updateData={updateData}
							updateTable={updateTable}
						/>
					</table>
					<div className="mx-4 mt-2 font-semibold">
						PART 'C' - BULK LIQUID CHEMICALS - VERBAL VERIFICATION
					</div>
					<table className="mx-4 mt-2 border-collapse table-fixed">
						<thead>
							<tr>
								<th className="border border-black"></th>
								<th className="border border-black w-1/3">
									Bulk Liquid Chemicals
								</th>
								<th className="border border-black w-1/6">Ship</th>
								<th className="w-1/6 border border-black">Terminal</th>
								<th className="border border-black">Code</th>
								<th className="border border-black w-1/6">Ship Remarks</th>
								<th className="border border-black w-1/4">Terminal Remarks</th>
							</tr>
						</thead>
						<ShipOps1
							extForm={extForm}
							updateData={updateData}
							updateTable={updateTable}
						/>
					</table>
					<div className="mx-4 mt-4 font-bold">DECLARATION</div>

					<div className="mx-4 mt-4">
						<p>
							We, the undersigned, have checked the above items in Parts A and
							B, and where appropriate, Part C or D, in accordance with the
							instructions and have satisfied ourselves that the entries we have
							made are correct to the best of our knowledge.
						</p>
						<p>
							We have also made arrangements to carry out repetitive checks as
							necessary and agreed that those items coded 'R' in the Check List
							should be re-checked at interval not exceeding{" "}
							<textarea
								rows={1}
								className="border"
								disabled
								cols={10}
								value={
									extForm?.data?.CL_TNK_07_dec_interval
										? extForm?.data.CL_TNK_07_dec_interval
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_dec_interval", e.target.value)
								}}
							/>
							.hours.
						</p>
						<p>
							If to our knowledge the status of any item changes, we will
							immediately inform the other party.
						</p>
					</div>
					<div className="flex grid grid-cols-4 mx-4 mt-2">
						<div className="pl-5 border border-black">For Ship</div>
						<div className="border border-black"></div>
						<div className="pl-5 border border-black">For Shore</div>
						<div className="border border-black"></div>
						<div className="pl-5 border border-black">Name:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								disabled
								value={
									extForm?.author
											? extForm?.author
											: ""
								}
							/>
						</div>
						<div className="pl-5 border border-black">Name:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								value={
									extForm?.data?.["CL_TNK_07_fr_shre_name::remote"]
										? extForm?.data?.["CL_TNK_07_fr_shre_name::remote"]
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_fr_shre_name::remote", e.target.value)
								}}
							/>
						</div>
						<div className="pl-5 border border-black">Rank:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								disabled
								value={
									extForm?.data?.CL_TNK_07_fr_shp_rank
										? extForm?.data.CL_TNK_07_fr_shp_rank
										: ""
								}

							/>
						</div>
						<div className="pl-5 border border-black">Rank:</div>
						<div className="border border-black">
							<textarea
									className="w-full"
									value={
										extForm?.data?.["CL_TNK_07_fr_shre_rank::remote"]
											? extForm?.data?.["CL_TNK_07_fr_shre_rank::remote"]
											: ""
									}
									onChange={function onChange(e) {
										e.preventDefault();
										updateData("CL_TNK_07_fr_shre_rank::remote", e.target.value)
									}}
								/>
						</div>
						<div className="pl-5 border border-black">Signature:</div>
						<div className="border border-black">
							<textarea
								className="w-full"
								disabled
								value={
									extForm?.data?.CL_TNK_07_fr_shp_sgntr
										? extForm?.data.CL_TNK_07_fr_shp_sgntr
										: ""
								}

							/>
						</div>
						<div className="pl-5 border border-black">Signature:</div>
						<div className="border border-black">
						<textarea
								className="w-full"
								value={
									extForm?.data?.["CL_TNK_07_fr_shre_sgntr::remote"]
										? extForm?.data?.["CL_TNK_07_fr_shre_sgntr::remote"]
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_fr_shre_sgntr::remote", e.target.value)
								}}
							/>
						</div>
						<div className="pl-5 border border-black">Date:</div>
						<div className="border border-black">
							<input
								className="w-full"
								disabled
								type="date"
								value={
									extForm?.data?.CL_TNK_07_fr_shp_date
										? extForm?.data.CL_TNK_07_fr_shp_date
										: ""
								}
							/>
						</div>
						<div className="pl-5 border border-black">Date:</div>
						<div className="border border-black">
							<input
								className="w-full"
								type="date"
								value={
									extForm?.data?.["CL_TNK_07_fr_shre_date::remote"]
										? extForm?.data?.["CL_TNK_07_fr_shre_date::remote"]
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_fr_shre_date::remote",e.target.value)
								}}
							/>
						</div>
						<div className="pl-5 border border-black">Time:</div>
						<div className="border border-black">
							<input
								type="time"
								className="w-full"
								disabled
								value={
									extForm?.data?.CL_TNK_07_fr_shp_time
										? extForm?.data.CL_TNK_07_fr_shp_time
										: ""
								}
							/>
						</div>
						<div className="pl-5 border border-black">Time:</div>
						<div className="border border-black">
						<input
								className="w-full"
								type="time"
								value={
									extForm?.data?.["CL_TNK_07_fr_shre_time::remote"]
										? extForm?.data?.["CL_TNK_07_fr_shre_time::remote"]
										: ""
								}
								onChange={function onChange(e) {
									e.preventDefault();
									updateData("CL_TNK_07_fr_shre_time::remote", e.target.value)
								}}
							/>
						</div>
					</div>
					<div className="flex w-full mx-4 mt-4">


					</div>
				</div>
				<div className="justify-end p-0 my-3 bp3-dialog-footer-actions">
					<Button
						text="Sign"
						view="filled"
						color="primary"
						onClick={function onClick(e) {
							e.preventDefault()
							triggerSubmitOperation();
						}}
						className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
					/>
					<Button
						text="Submit"
						view="filled"
						onClick={function onSubmit(e) {
							e.preventDefault()
							props.setFormSubmit(true)
							props.formSubmit();
						}}
						color="primary"
						className="px-10 mx-2 bg-blue-700 shadow-none hover:bg-blue-800 focus:bg-blue-900"
					/>
				</div>
			</form>
			<Modal isActive={!!isModalOpen} onClose={() => resetOperations()}>
				{isModalOpen ? (
					<div className="flex flex-col items-center justify-start h-full my-12 overflow-y-auto font-redhat-text">
						<div className="flex items-center w-full min-h-full my-6 overflow-y-auto bp3-dialog-container sm:w-full lg:w-5/6">
							<PermitSignModal
								onClose={function onModelClose() {
									resetOperations();
								}}
								onSign={function onModelSign() {
									extForm.externalCollaborators[0].isSigned = true;
									props.formFieldEdit({ ...extForm });
									props.setFormSubmit(true)
									props.formSubmit();
									resetOperations();
								}}
							></PermitSignModal>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
};

function mapStateToProps(store: IStore) {
	const extStore: any = store.app.extStore;
	return {
		extForm: extStore.extForm,
		listLoadJob: {
			isLoading: false,
		},
	};
	//return checklistSelectors.getTNK07Data(store.app.checklistStore);
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		formFieldEdit(value: any) {
			this.setFormSubmit(false)
			dispatch(extActions.formFieldsPatch(value));
		},
		formSubmit() {
			dispatch(extActions.formUpdate(""));
		},
		formRedirect() {
			const url = rootRoutes.children.ext.children.login.url;
			dispatch(historyActions.replace("@ext", url()));
		},
		setFormSubmit(value: boolean) {
			dispatch(extActions.formSubmit(value));
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
export default reduxConnector(CLTnk07);
