import classNames from "classnames";
import moment from "moment";
import React from "react";
import {
	TBallastRecord,
	TBallastPlace,
	EBallastOperationCodes,
} from "../../../../../redux/app/ballast/@types";
import { TBallast_Exceptional } from "../../../../../redux/app/ballast/groups/exceptional";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ballastUtils from "redux/app/ballast/utils";
import BallastTableRows from "../BallastTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type ExceptionalOperationPreviewProps = OwnProps;
function ExceptionalOperationPreview(props: ExceptionalOperationPreviewProps) {
	const record = props.ballastRecord as TBallast_Exceptional;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const { placeOfBallast, operationCode } =
		props.ballastRecord as TBallast_Exceptional;

	function renderMain() {
		return (
			<BallastTableRows
				record={record}
				tableRows={[
					[null, "3.5.1", renderItem3P5P1()],
					[null, "3.5.2", renderItem3P5P2()],
					[null, "3.5.3", renderItem3P5P3()],
					[null, "3.5.4", renderItem3P5P4()],
					[null, "3.5.5", renderItem3P5P5()],
					[null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem3P5P1() {
		return (
			<h4 className="font-medium uppercase">
				{moment(record.tanks[0].startDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}{" "}
				{record.tanks[0].startTime} to{" "}
				{moment(
					record.tanks[record.tanks.length - 1].endDate,
					"YYYY-MM-DD"
				).format("DD-MMM-YYYY")}{" "}
				{record.tanks[record.tanks.length - 1].endTime}
			</h4>
		);
	}
	function renderItem3P5P2() {
		return (
			<h4 className="font-medium uppercase">
				{validatePlaceOfBallastPreview(placeOfBallast)}
			</h4>
		);
	}
	function renderItem3P5P3() {
		return (
			<h4 className="font-medium uppercase">
				Discharged: {record.totalOperatedWaterVolume} M³, Remaining:{" "}
				{record.ballastROB
					? record.tanks.reduce<number>((result, currentItem) => {
							return result + currentItem.newRetainedQuantity;
					  }, 0)
					: record.tanks.reduce<number>((result, currentItem) => {
							return (
								result +
								ballastUtils.getOperationalRetainedQuantity(currentItem)
							);
					  }, 0) - record.totalOperatedWaterVolume}
				M³ <br />
				{operationCode ==
				EBallastOperationCodes.ACCIDENTAL_DISCHARGE_OF_BALLAST_WATER
					? "FROM"
					: "FROM CARGO TANKS"}{" "}
				<CommaSeparator
					items={record.tanks.map((tank, index) => tank.selectedTank.tankCode)}
				/>
			</h4>
		);
	}
	function renderItem3P5P4() {
		return (
			<h4 className="font-medium uppercase">{record.circumstantialRemarks}</h4>
		);
	}
	function renderItem3P5P5() {
		return (
			<h4 className="font-medium uppercase">
				{record.isBallastWaterTreatmentPlanUsed ? "YES" : "NO"}
			</h4>
		);
	}

	const validatePlaceOfBallastPreview = (placeOfBallast: TBallastPlace) => {
		if (placeOfBallast.place) {
			return placeOfBallast.place === "AT_RECEPTION_FACILITY" ? (
				<>
					{moment(placeOfBallast.startDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}{" "}
					{placeOfBallast.startTime}, {placeOfBallast.originOfBallast}
				</>
			) : placeOfBallast.place === "IN_PORT" ? (
				<>{placeOfBallast.portName}</>
			) : placeOfBallast.place === "AT_SEA" ? (
				<>
					From {placeOfBallast.startPosition.latitude},{" "}
					{placeOfBallast.startPosition.longitude}, to{" "}
					{placeOfBallast.endPosition.latitude},{" "}
					{placeOfBallast.endPosition.longitude}
				</>
			) : null;
		} else {
			return <>NA</>;
		}
	};

	return renderMain();
}

export default ExceptionalOperationPreview;
