import classNames from "classnames";
import React, { FC, useState } from "react";
import { IDiscrepanciesArray, IDiscrepancy } from "../../../types";
import Card from "./Card";

interface IDiscrepanciesProps {
	onClick?: (discrepancy: IDiscrepancy) => void;
	discrepanciesArray: IDiscrepanciesArray;
}

const Discrepancies: FC<IDiscrepanciesProps> = ({
	discrepanciesArray,
	onClick,
}) => {
	const [discrepanciesObj, setDiscrepanciesObj] = useState(
		discrepanciesArray && discrepanciesArray.length > 0
			? discrepanciesArray[0]
			: {
					watchHour: "",
					discrepancies: [],
			  }
	);

	
	return (
		<div
			style={{
				width: "475px",
			}}
			className="flex flex-col p-5 gap-y-3"
		>
			{discrepanciesArray.length > 0 ? (
				<div className="flex items-center w-full overflow-x-auto ws-scroll_transparent">
					{discrepanciesArray.filter(({ discrepancies }) => !!discrepancies?.length).map(
						({ watchHour, discrepancies }, index) => {
							return (
								<button
									key={index}
									className={classNames(
										"outline-none p-2 rounded-md border",
										{
											"border-gray-300 bg-gray-300": watchHour === 
											discrepanciesObj.watchHour,
											"border-transparent bg-transparent": watchHour !==
											discrepanciesObj.watchHour
										}
									)}
									onClick={() =>
										setDiscrepanciesObj({
											watchHour,
											discrepancies,
										})
									}
								>
									{watchHour?.slice(0, 4) + " - " + watchHour?.slice(4)}
								</button>
							);
						}
					)}
				</div>
			) : null}
			{discrepanciesObj?.discrepancies.length > 0 && (
				<div className="flex flex-col gap-y-3">
					{discrepanciesObj.discrepancies.map(
						(discrepancy, index) => {
							return (
								<Card
									discrepancy={{
										...discrepancy,
										watchHour: discrepanciesObj.watchHour,
									}}
									onClick={onClick}
									key={index}
								/>
							);
						}
					)}
				</div>
			)}
		</div>
	);
};

export default Discrepancies;
