import { apiActions } from "../../_core/api";
import { Middleware } from "redux";
import authActions, { IAuthActions, authJobActions } from "./actions";
import authAPIs from "./apis";
import { localStorageAx } from "../../_core/localstorage";
import authConstants from "./constants";
import { multiActions } from "../../_core/multi-action";
import { historyActions } from "../../_core/history";
import { history, rootRoutes } from "../../../routes";
import { IStore } from "../../store";
import { getType } from "typesafe-actions";
import { ITenantInfo } from "./types";
import authSelectors from "./selectors";
const { apiRequest, apiDefaultHeaderSet } = apiActions;

const authMiddleware: Middleware<{}, IStore> = ({ dispatch, getState }) => (
	next
) => (action: IAuthActions) => {
	switch (action.type) {
		case getType(authActions.login): {
			next(action);
			const { username, password } = action.payload;
			const job = authJobActions.login;
			dispatch(
				apiRequest<{
					accessToken: string;
					email: string;
					name: string;
					role: "tenantAdmin" | "Admin" | "tenantUser";
					username: string;
					action?: string;
					url?: any;
					tenantId?: string;
				}>({
					...authAPIs.login(username, password),
					preExecute: () => {
						dispatch(job.active({ message: "Logging in!!!" }));
					},
					postExecute: {
						onSuccess: (response) => {
							if (response?.data?.action === 'redirect') {
								if (response.data.url) {
									window.location = response.data.url
								}
								return;
							}
							if (response?.data?.action === 'password') {
								// set state to make password field visible
								dispatch(authActions.setPasswordFiledVisible());
								return;
							}
							const { accessToken } = response.data;
							const pathPriorToLogin = getState().app.auth?.pathPriorToLogin ?? ""
							// const vesselRouteUrl = rootRoutes.children.app.children.vessel.children.list.url();
							const dashboardRouteUrl = rootRoutes.children.app.children.dashboard.children.list.url();
							if (accessToken){
								dispatch(
									multiActions.batchActions([
										job.success({
											message:
												"Welcome " + response.data.name,
											notification: {},
										}),
										authActions.tokenSet(accessToken),
										authActions.userSet(response.data),
										localStorageAx.setItem(
											getType(authActions.login),
											authConstants.storage.AUTH_KEY,
											accessToken
										),
										localStorageAx.setItem(
											getType(authActions.login),
											authConstants.storage.USER_KEY,
											response.data
										),
										apiDefaultHeaderSet(
											getType(authActions.login),
											authConstants.headers.TOKEN,
											accessToken
										),
										historyActions.replace(
											getType(authActions.login),
											pathPriorToLogin
												? pathPriorToLogin
												: dashboardRouteUrl
										),
									])
								);
								dispatch(authActions.pathPriorToLoginSet(""));

								// add feedback and tools modules to authorisedModules, if user is allowed to access them
								const authorisedModules = authSelectors(
									getState().app.auth
								).isUserAllowedToAccessModule("feedback")
									? [
											...getState().app.auth
												.authorisedModules,
											"feedback",
											"tools",
									  ]
									: [
											...getState().app.auth
												.authorisedModules,
									  ];

								// update authorisedModules in store
								dispatch(
									authActions.authorisedModulesSet(
										authorisedModules
									)
								);
							}
							else
								dispatch(
									job.error({
										message: "No Login Token received from Server",
										notification: {},
									})
								);
						},
						onError: () => {
							dispatch(
								job.error({
									message: "Email or Password is Invalid!",
									notification: {},
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case getType(authActions.SAMLLogin): {
			next(action);
			const job = authJobActions.login;
			const {code} = action.payload;
			dispatch(
				apiRequest({
					...authAPIs.samlLogin(code),
					preExecute: () => {
						dispatch(job.active({ message: "Logging in!!!" }));
					},
					postExecute: {
						onSuccess: (response) => {
							const { accessToken } = response.data;
							const pathPriorToLogin = getState().app.auth?.pathPriorToLogin ?? ""
							// const vesselRouteUrl = rootRoutes.children.app.children.vessel.children.list.url();
							const dashboardRouteUrl = rootRoutes.children.app.children.dashboard.children.list.url();
							if (accessToken){
								dispatch(
									multiActions.batchActions([
										job.success({
											message:
												"Welcome " + response.data.name,
											notification: {},
										}),
										authActions.tokenSet(accessToken),
										authActions.userSet(response.data),
										localStorageAx.setItem(
											getType(authActions.login),
											authConstants.storage.AUTH_KEY,
											accessToken
										),
										localStorageAx.setItem(
											getType(authActions.login),
											authConstants.storage.USER_KEY,
											response.data
										),
										apiDefaultHeaderSet(
											getType(authActions.login),
											authConstants.headers.TOKEN,
											accessToken
										),
										historyActions.replace(
											getType(authActions.login),
											pathPriorToLogin
												? dashboardRouteUrl
												: dashboardRouteUrl
										),
									])
								);
								dispatch(authActions.pathPriorToLoginSet(""));

								// add feedback and tools modules to authorisedModules, if user is allowed to access them
								const authorisedModules = authSelectors(
									getState().app.auth
								).isUserAllowedToAccessModule("feedback")
									? [
											...getState().app.auth
												.authorisedModules,
											"feedback",
											"tools",
									  ]
									: [
											...getState().app.auth
												.authorisedModules,
									  ];

								// update authorisedModules in store
								dispatch(
									authActions.authorisedModulesSet(
										authorisedModules
									)
								);
							}
							else
								dispatch(
									job.error({
										message: "No Login Token received from Server",
										notification: {},
									})
								);
						},
						onError: () => {
							dispatch(
								job.error({
									message: "Email or Password is Invalid!",
									notification: {},
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case getType(authActions.tenantInfoLoad): {
			next(action);
			const { tenantId } = action.payload;
			const job = authJobActions.tenantInfoLoad;
			dispatch(
				apiRequest<ITenantInfo>({
					...authAPIs.tenantInfoLoad(tenantId),
					postExecute: {
						onSuccess: (response) => {
							if (response.data) {
								dispatch(authActions.tenantInfoSet(response.data));
							}
						},
						onError: () => {
							dispatch(
								job.error({
									message: "Error in getting tenant info",
									notification: {},
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}

		case getType(authActions.logout): {
			const job = authJobActions.logout;
			next(action);
			dispatch(
				apiRequest({
					...authAPIs.logout(),
					preExecute: () => {
						dispatch(job.active({ message: "Logging out!", notification: {} }));
					},
					postExecute: {
						onSuccess: () => {
							dispatch(authActions.authReset());
							dispatch(job.success({ message: "Logged out Successfully!!" }));
							dispatch(
								historyActions.replace(
									"@auth",
									rootRoutes.children.auth.children.login.url()
								)
							);
						},
						onError: () => {
							dispatch(authActions.authReset());
							dispatch(job.success({ message: "Logged out Successfully!!" }));
							dispatch(
								historyActions.replace(
									"@auth",
									rootRoutes.children.auth.children.login.url()
								)
							);
							// dispatch(
							// 	job.error({
							// 		message: "Logout failed",
							// 		notification: { timeout: 10000 },
							// 	})
							// );
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case getType(authActions.authReset): {
			next(action);
			break;
		}
		case getType(authActions.resetPassword): {
			next(action);
			const { accessToken, newPassword } = action.payload;
			const job = authJobActions.resetPassword;
			dispatch(
				apiRequest({
					...authAPIs.resetPassword(newPassword, accessToken),
					preExecute: () => {
						dispatch(job.active({ message: "Resetting password!!" }));
					},
					postExecute: {
						onSuccess: () => {
							dispatch(
								job.success({
									message: "Successfully resetted password!!",
									notification: {},
								})
							);
						},
						onError: () => {
							dispatch(
								job.error({
									message: "Error resetting password!!",
									notification: {
										timeout: 10000,
									},
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case getType(authActions.resetRequest): {
			next(action);
			const { emailId } = action.payload;
			const job = authJobActions.resetRequest;
			dispatch(
				apiRequest({
					...authAPIs.resetRequestViaMail(emailId),
					preExecute: () => {
						dispatch(job.active({ message: "Sending reset Request!!" }));
					},
					postExecute: {
						onSuccess: () => {
							dispatch(
								job.success({
									message: "Mail has been sent to " + emailId,
									notification: {},
								})
							);
						},
						onError: () => {
							dispatch(
								job.error({
									message: "Error in sending mail to " + emailId,
									notification: { timeout: 10000 },
								})
							);
						},
						finally: () => {
							dispatch(job.idle({}));
						},
					},
				})
			);
			break;
		}
		case getType(authActions.authorizeApp): {
			next(action);
			const { authToken, user } = getState().app.auth;
			if (authToken)
				dispatch(
					apiActions.apiDefaultHeaderSet(
						getType(authActions.authorizeApp),
						authConstants.headers.TOKEN,
						authToken
					)
				);
			else {
				if (!window.location.href.includes("ext")) {
					const prevLocation = history.location?.pathname;
					const loginUrl = rootRoutes.children.auth.children.login.url();
					if(prevLocation !== loginUrl){
						dispatch(authActions.pathPriorToLoginSet(prevLocation))
					}

					dispatch(
						historyActions.replace(
							getType(authActions.authorizeApp),
							loginUrl
						)
					);
				}
			}
			break;
		}
		default:
			next(action);
	}
};
export default authMiddleware;
