import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "../../redux/store";
import { Dispatch } from "redux";
import { extActions } from "../../redux/app/ext";
import classNames from "classnames";
import DefaultInput from "../../@set-product/core/Inputs/DefaultInput";
import Button from "../../@set-product/core/button";
import WSLogo from "./../../assets/logos/logo-with-text.svg";

const LoginExt: React.FC<any> = (props: any) => {
	const { loginJob, login, onSubmit, onChange } = props;
	const { email, password } = login.fields;
	return (
		<div
			className={classNames(
				"flex flex-row justify-center items-center", // Layout [Flex, Inline, Grid]
				"h-full", // Sizing [Width, Height]
				"", // Spacing [Padding, Margin]
				"", // Font [Font Size, Font style]
				"bg-transparent", // Colors [Border, Background, Font Colors]
				"", // Borders [Border size, style, width, radius]
				"overflow-hidden z-10" // Position [Absolute, Relative]
			)}
		>
			<div className="flex-row items-stretch w-full min-h-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-3/4">
				<div
					className={classNames(
						"", // Layout [Flex, Inline, Grid]
						"sm:min-h-full", // Sizing [Width, Height]
						"my-0 mx-0 sm:mx-6 sm:my-64", // Spacing [Padding, Margin]
						"", // Font [Font Size, Font style]
						"bg-white", // Colors [Border, Background, Font Colors]
						"sm:rounded-lg border", // Borders [Border size, style, width, radius]
						"" // Position [Absolute, Relative]
					)}
				>
					<div className="p-6 bg-gray-100 rounded-t-lg">
						<img src={WSLogo} className="mx-auto my-3" alt='none'/>
					</div>
					<div className="p-6 px-6 sm:px-12">
						<form
							className="my-6"
							onSubmit={(e) => {
								onSubmit(email, password);
								e.preventDefault();
							}}
						>
							<div className="my-6">
								<h4
									className={classNames(
										"font-medium text-2xl font-redhat-text text-gray-500" // Font [Font Size, Font style etc]
									)}
								>
									Login
								</h4>
							</div>
							<div className="w-full my-3">
								<DefaultInput
									value={email}
									fill={true}
									placeholder="Email ID"
									label="Email"
									// error={!errors.email.isValid}
									// errorHelperText={errors.email.message}
									onChange={(e) => {
										e.preventDefault();
										onChange({ email: (e.target as HTMLInputElement).value });
									}}
									disabled={loginJob.isLoading}
								/>
							</div>
							<div className="w-full my-3">
								<DefaultInput
									value={password}
									fill={true}
									placeholder="Password"
									type="password"
									label="Password"
									// error={!errors.password.isValid}
									// errorHelperText={errors.password.message}
									onChange={(e) => {
										e.preventDefault();
										onChange({
											password: (e.target as HTMLInputElement).value,
										});
									}}
									disabled={loginJob.isLoading}
								/>
							</div>
							<div className="w-full my-8">
								<Button
									type="submit"
									color="primary"
									text="SIGN IN"
									fill={true}
									view="filled"
									className="shadow-none"
									disabled={loginJob.isLoading}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(store: IStore) {
	const extStore: any = store.app.extStore;
	return {
		extForm: extStore.extForm,
		loginJob: {
			isLoading: false,
		},
		login: extStore.login,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		onSubmit(email: string, password: string) {
			const base64 = btoa(`${email}:${password}`);
			dispatch(extActions.loadTask(`Basic ${base64}`));
		},
		onChange: (field: any) => {
			dispatch(extActions.formFieldsEdit(field));
		},
		// approvePermit(approval: any) {
		// 	dispatch(permitActions.approvePermit(approval));
		// },
		// declinePermit(approval: any) {
		// 	dispatch(permitActions.declinePermit(approval));
		// },
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
export default reduxConnector(LoginExt);
