import { marpolUtils } from "redux/app/marpol";
import { TMARPOLTank, TMARPOLTankFromBackend } from "redux/app/marpol/@types";
import { IStore } from "redux/store";
import { apiActions } from "redux/_core/api";
import createMiddleware from "redux/_core/create-middleware";
import { IVesselActions, vesselActions } from "../..";
import vesselOnboardActionTypes from "../action-types";
import vesselOnboardActions from "../actions";

const tanksMiddleware = createMiddleware<IStore, any>()
	.handleAction(
		vesselOnboardActions.command.tankListLoad,
		function tankListLoad(action, { dispatch }) {
			const { vesselId } = action.payload;
			const job = vesselOnboardActions._jobs.TANK_LIST_LOAD;
			dispatch(
				apiActions.apiRequest<{ tanks: { docs: TMARPOLTankFromBackend[] } }>({
					feature: vesselOnboardActionTypes.command.TANK_LIST_LOAD,
					method: "GET",
					url: `api/tanks`,
					queryParams: {
						vesselId,
					},
					preExecute() {
						dispatch(
							job.active({
								message: "Loading Tanks",
								notification: {
									hideAtState: "SUCCESS",
									timeout: 100,
								},
							})
						);
					},
					postExecute: {
						onSuccess({ data }) {
							dispatch(
								vesselOnboardActions.document.tanksSet(
									marpolUtils.sortTanksByCategory(
										data.tanks.docs.map((tank) =>
											marpolUtils.formatTankFromBackend(tank)
										)
									)
								)
							);
							dispatch(
								job.success({
									message: "Successfully loaded tanks",
									notification: { className: "text-xs" },
								})
							);
						},
						onError() {
							dispatch(
								job.error({
									message: "Server error while loading tanks",
									notification: { timeout: 10000 },
								})
							);
						},
						finally() {
							dispatch(job.idle({}));
						},
					},
				})
			);
		}
	)
	.getMiddleware();

export default tanksMiddleware;
