import withJobs from "redux/_core/job/reducers";
import userConstants from "./constants";
import { IUserActions_Document, IUserStore } from "./@types";
import userActions from "./actions";
import { createReducer } from "typesafe-actions";
import withForms from "redux/_core/form";
import userReducerState from "./reducers.state";
const { USERS_PER_PAGE } = userConstants;
const userReducers = createReducer<IUserStore, IUserActions_Document>(
	userReducerState
)
	.handleAction(
		userActions.document.userListSet,
		function userListSet(state, action) {
			const users = action.payload.users ?? [];
			return {
				...state,
				users,
			};
		}
	)
	.handleAction(
		userActions.document.selectedUserIdSet,
		function userListSet(state, action) {
			const selectedUserId = action.payload.userId;
			return {
				...state,
				selectedUserId,
			};
		}
	)
	.handleAction(
		userActions.document.userErase,
		function userListSet(state, action) {
			const userId = action.payload.userId;
			const byIds = { ...state.users.byIds };
			delete byIds[userId];
			return {
				...state,
				users: {
					byIds,
					ids: state.users.ids.filter((id) => id !== userId),
				},
			};
		}
	)
	.handleAction(
		userActions.document.userNew,
		function userListSet(state, action) {
			const user = action.payload.user;
			return {
				...state,
				users: {
					...state.users,
					byIds: {
						...state.users.byIds,
						[user.id]: user,
					},
					ids: [...state.users.ids, user.id],
				},
			};
		}
	)
	.handleAction(
		userActions.document.userEdit,
		function userListSet(state, action) {
			const user = action.payload.user;
			return {
				...state,
				users: {
					...state.users,
					byIds: {
						...state.users.byIds,
						[user.id]: {
							...state.users.byIds[user.id],
							...user,
						},
					},
				},
			};
		}
	)
	.handleAction(
		userActions.document.userDetailsSet,
		function userDetailsSet(state, action) {
			const userDetails = action.payload.userDetails;
			return {
				...state,
				userProfile: { ...userDetails },
			};
		}
	)
	.handleAction(
		userActions.document.paginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		userActions.document.paginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		userActions.document.paginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		userActions.document.paginationReset,
		function reset(state, action) {
			return {
				...state,
				_pagination: {
					currentPage: 1,
					itemsPerPage: USERS_PER_PAGE,
					totalItems: 0,
				},
			};
		}
	);

export default withForms(
	userConstants.FEATURE,
	userReducerState,
	withJobs(
		userConstants.FEATURE,
		userReducerState,
		// @ts-ignore
		userReducers
	)
);
