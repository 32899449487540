import { createAction, getType } from "typesafe-actions";
import { IUser, IVesselUser } from "./@types";
import { jobActions } from "redux/_core/job";
import userConstants from "./constants";
import { formActions } from "redux/_core/form";
import { IListObjectStore } from "redux/_common/type";
import { IFormErrorFormat } from "redux/_core/form/types";
import { IUserDetail } from "../vessel/types";

// COMMAND ACTION
const USER_LIST_LOAD = "@user/list/LOAD";
const USER_CREATE = "@user/CREATE";
const USER_LOAD = "@user/LOAD";
const USER_UPDATE = "@user/UPDATE";
const USER_DELETE = "@user/DELETE";
const USER_FORM_RESET = "@user/form/RESET";
const USER_DETAILS_LOAD = "@user/details/LOAD";
const USER_COUNT = "@user/count";

// DOCUMENT ACTION
const USER_LIST_SET = "@user/list/SET";
const USER_NEW = "@user/add/NEW";
const SELECTED_USER_ID_SET = "@user/user-id/SET";
const USER_DATA_EDIT = "@user/EDIT";
const USER_ERASE = "@user/ERASE";
const USER_DETAILS_SET = "@user/details/SET";
const PAGINATION_TOTAL_ITEMS_SET = "@user/pagination/total-items/SET"; // DOCUMENT
const PAGINATION_ITEMS_PER_PAGE_SET = "@user/pagination/items-per-page/SET"; // DOCUMENT
const PAGINATION_CURRENT_PAGE_SET = "@user/pagination/current-page/SET"; // DOCUMENT
const PAGINATION_RESET = "@user/pagination/RESET"; // DOCUMENT

const userActions = {
	commands: {
		userListLoad: createAction(
			USER_LIST_LOAD,
			(pageNumber: number, isPaginated?: boolean) => ({
				pageNumber,
				isPaginated: isPaginated ?? true,
			})
		)(),
		userCreate: createAction(USER_CREATE, (user: IVesselUser) => ({
			user,
		}))(),
		userLoad: createAction(USER_LOAD, (userId: string) => ({ userId }))(),
		userUpdate: createAction(
			USER_UPDATE,
			(userId: string, user: IVesselUser) => ({
				userId,
				user,
			})
		)(),
		userDelete: createAction(USER_DELETE, (userId: string) => ({ userId }))(),
		formReset: createAction(
			USER_FORM_RESET,
			(fields: IVesselUser, errors: IFormErrorFormat) => ({ fields, errors })
		)(),
		userDetailsLoad: createAction(USER_DETAILS_LOAD)(),
		userCount: createAction(USER_COUNT)(),
	},
	document: {
		userListSet: createAction(
			USER_LIST_SET,
			(users: IListObjectStore<IUser>) => ({ users })
		)(),
		userNew: createAction(USER_NEW, (user: IUser) => ({ user }))(),
		selectedUserIdSet: createAction(SELECTED_USER_ID_SET, (userId: string) => ({
			userId,
		}))(),
		userEdit: createAction(USER_DATA_EDIT, (user: IUser) => ({ user }))(),
		userErase: createAction(USER_ERASE, (userId: string) => ({ userId }))(),
		userDetailsSet: createAction(
			USER_DETAILS_SET,
			(userDetails: IUserDetail) => ({ userDetails })
		)(),
		paginationTotalItemsSet: createAction(
			PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		paginationItemsPerPageSet: createAction(
			PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		paginationCurrentPageSet: createAction(
			PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		paginationReset: createAction(PAGINATION_RESET)(),
	},
	_forms: {
		user: formActions<IVesselUser>("USER", "@user"),
	},
};

export const userJobActions = {
	userListLoad: jobActions(
		userConstants.FEATURE,
		getType(userActions.commands.userListLoad)
	),
	userCreate: jobActions(
		userConstants.FEATURE,
		getType(userActions.commands.userCreate)
	),
	userUpdate: jobActions(
		userConstants.FEATURE,
		getType(userActions.commands.userUpdate)
	),
	userDelete: jobActions(
		userConstants.FEATURE,
		getType(userActions.commands.userDelete)
	),
	userGetDetails: jobActions(
		userConstants.FEATURE,
		getType(userActions.commands.userDetailsLoad)
	),
	userCount: jobActions(
		userConstants.FEATURE,
		getType(userActions.commands.userCount)
	),
};

export default userActions;
