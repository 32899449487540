import React from "react";
import classNames from "classnames";
export type InputWrapperProps = {
	hasError?: boolean;
	hasWarning?: boolean;
	isPrimary?: boolean;
	isLoading?: boolean;
	className?: string;
	inputValue?: any;
};
const InputWrapper: React.FC<InputWrapperProps> = ({
	hasError = false,
	hasWarning = false,
	isPrimary = false,
	isLoading = false,
	children,
	className,
	inputValue,
}) => {
	const hasValueClass = inputValue ? "ws-input__container--has-value" : "";
	return (
		<div
			className={classNames(
				"ws-input__container bp3-input-group",
				{
					"bp3-intent-primary": isPrimary,
					"bp3-intent-warning ws-input__container--warning": hasWarning,
					"bp3-intent-danger ws-input__container--error": hasError,
					"ws-input__container--loading": isLoading,
				},
				hasValueClass,
				className
			)}
		>
			{children}
		</div>
	);
};
export default InputWrapper;
