import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupB1 } from "../../../../../redux/app/orb1/groups/group-b";
import CommaSeparator from "../../../../_common/CommaSeparator";
import ORB1TableRows, { ORB1TableColumns } from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupA1PreBiewProps = OwnProps;
function ORB1GroupB1Preview(props: ORB1GroupA1PreBiewProps) {
	const orb1Record = props.orb1Record as TORB1GroupB1;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					...renderItem5Rows(),
					[null, null, "6", renderItem6()],
					[null, null, "7", renderItem7()],
					[null, null, "8", renderItem8()],
					[...renderItem9Row()],
					[null, null, "10", renderItem10()],
					[null, null, null, renderSealNumber()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem5Rows(): ORB1TableColumns[] {
		return orb1Record.item5.foTanks.map((tankItem, index) => {
			const isItFirstItem = index === 0;
			if (isItFirstItem) return [operationDate, "B", "5", tankItem.tankName];
			return [null, null, null, tankItem.tankName];
		});
	}

	function renderItem6() {
		const { positionAtStart } = orb1Record.item6;
		return (
			<h4 className="font-medium uppercase">
				START DISCHARGING: {positionAtStart.latitude},{" "}
				{positionAtStart.longitude}{" "}
			</h4>
		);
	}

	function renderItem7() {
		const { positionAtCompletion } = orb1Record.item7;
		return (
			<h4 className="font-medium uppercase">
				STOP DISCHARGING: {positionAtCompletion.latitude},{" "}
				{positionAtCompletion.longitude}{" "}
			</h4>
		);
	}

	function renderItem8() {
		return (
			<h4 className="font-medium uppercase">
				SPEED: {orb1Record.item8.speedOfShip} KNOTS
			</h4>
		);
	}

	function renderItem9Row(): ORB1TableColumns {
		const { dischargeMethod } = orb1Record.item9;

		function renderReceptionCase() {
			if (dischargeMethod !== "TO RECEPTION FACILITIES") return null;
			const { receiptNumber, receiptAttachment } = orb1Record.item9;
			const url = marpolUtils.getAttachmentURL(
				receiptAttachment,
				orb1Record.id,
				orb1Record._rev
			);
			return (
				<>
					RECEIPT NO. {receiptNumber} <br /> ATTACHMENT:{" "}
					<a href={url} download={receiptAttachment}>
						{receiptAttachment}
					</a>
				</>
			);
		}

		const itemNumber =
			dischargeMethod === "THROUGH 15 PPM EQUIPMENT" ? 9.1 : 9.2;
		const content = (
			<h4 className="font-medium uppercase">
				{orb1Record.item9.dischargeMethod} METHOD, {renderReceptionCase()}
			</h4>
		);
		return [null, null, itemNumber, content];
	}

	function renderItem10() {
		const { dischargedQuantity } = orb1Record.item10;
		return (
			<h4 className="font-medium uppercase">
				DIRTY BALLAST DISCHARGED: {dischargedQuantity.toFixed(2)} M³
			</h4>
		);
	}

	function renderSealNumber() {
		if (orb1Record.item9.dischargeMethod === "THROUGH 15 PPM EQUIPMENT")
			return `SEAL NUMBER: ${orb1Record.item9.sealNumber}`;
		return null;
	}

	return renderMain();
}

export default ORB1GroupB1Preview;
