import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IFeedbackFilters, IFilterState } from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";
import { vesselActions } from "redux/app/vessel";
import { IStore } from "redux/store";
import DropdownWithFilterModified from "components/_common/DropdownWithFilterModified";

type OwnProps = {
	onVesselSelect?(vesselId: string): void;
};
type FeedbackVesselSelectorsProps = OwnProps & PropsFromRedux;
function FeedbackVesselSelectors(props: FeedbackVesselSelectorsProps) {
	const { miniVessels, feedbackFilters } = props;

	return (
		<div className="w-full">
			<div className="w-full">
				<DropdownWithFilterModified
					onInputChange={(e) => {}}
					id="dropdown-filter-id"
					inputWrapperProps={{}}
					options={[
						{
							label: "All Vessels",
							value: "",
						},
						...miniVessels.ids.map((id) => {
							return {
								...miniVessels.byIds[id],
								label: miniVessels.byIds[id].vesselName,
								value: miniVessels.byIds[id].id,
							};
						}),
					]}
					value={feedbackFilters?.vesselId}
					placeholder="Select Vessel"
					onSelect={function onVesselChange(e) {
						if (e) {
							props.filtersEdit({ vesselId: e.value });
							if (props.onVesselSelect) props.onVesselSelect(e.value);
						}
					}}
					renderView={function renderView(selectedItem) {
						return (
							<span className="whitespace-no-wrap w-42 bp3-text-overflow-ellipsis text-lg font-medium">
								{selectedItem.label}&nbsp;
							</span>
						);
					}}
					noFilter={true}
				/>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const feedbackStore = feedbackSelectors._getStore(store);
	return {
		feedbackFilters: feedbackSelectors.getFiltersState(feedbackStore),
		miniVessels: feedbackSelectors.getVessels(feedbackStore),
		jobToLoadVessels: feedbackSelectors.jobToLoadVessels(feedbackStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		filtersEdit(filters: Partial<IFilterState>) {
			dispatch(feedbackActions.document.feedbackFilterStateSet(filters));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackVesselSelectors);
