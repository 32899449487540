import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { commonUtils } from 'redux/_common';
import { ESealState, ISealLog } from 'redux/app/logs/@types';
import { IStore } from 'redux/store';

interface ISealLogRowProps {
	seallog: ISealLog;
	onClick(seallog: ISealLog): void;
	indexNumber: number;
};

type SealLogRowProps = PropsFromRedux & ISealLogRowProps;
function SealLogRow(props: SealLogRowProps) {
	const { seallog } = props;
	const {
		sealNumber,
		location,
		history,
		createdProfile,
		approvedProfile,
	} = seallog;

	const removed = history?.find(
		(seal) =>
			seal.state === ESealState.REMOVED
	);
	const fitted = history?.find(
		(seal) =>
			seal.state ===
			ESealState.CURRENTLY_IN_USE
	);

	return (
		<tr key={seallog._id}>
			<td className="font-mono text-center text-gray-700">{props.indexNumber}</td>
			<td className="font-mono text-center text-gray-700">{location}</td>
			<td className="font-mono text-center text-gray-700">{fitted?.dateModified
                                                        ? commonUtils.formatSystemDate(
                                                              fitted?.dateModified,
															  true
                                                          )
                                                        : ''}</td>
			<td className="font-mono text-center text-gray-700">{sealNumber}</td>
			<td className="font-mono text-center text-gray-700">{removed?.createdAt
                                                        ? commonUtils.formatSystemDate(
                                                              removed?.dateModified,
															  true
                                                          )
                                                        : ''}</td>
			<td className="font-mono text-center text-gray-700">{removed?.reason}</td>
			<td className="font-mono text-center text-gray-700">{createdProfile}</td>
			<td className="font-mono text-center text-gray-700">{approvedProfile}</td>
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: ISealLogRowProps) {

	return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(SealLogRow);