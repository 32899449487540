import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import feedbackConstants from "redux/app/feedback/constants";
import feedbackSelectors from "redux/app/feedback/selectors";
import { IStore } from "redux/store";
import SendIcon from "../../../../../assets/icons/send.svg";
import feedbackActions from "redux/app/feedback/actions";
import FeedbackChatsLoader from "./FeedbackChatsLoader";
import { trimStringWithEllipsis } from "redux/app/feedback/utils";

let timeoutId: NodeJS.Timeout;

type OwnProps = {};
type FeedbackChatsProps = PropsFromRedux & OwnProps;
function FeedbackChats(props: FeedbackChatsProps) {
	const { chatMessages, message, jobToLoadChats, selectedFeedback } = props;

	const messagesEndRef = useRef<HTMLDivElement>(null);
	const [showFeedback, setShowFeedback] = useState(false);

	const toggleShowFeedback = () => {
		setShowFeedback((state) => !state);
	};

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	// info: ensure scroll to bottom in chat window.
	useEffect(() => {
		clearTimeout(timeoutId);
		if (chatMessages?.length) {
			timeoutId = setTimeout(() => {
				scrollToBottom();
			}, 200);
		}

		return () => {
			clearTimeout(timeoutId);
			props.setMessage("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatMessages]);

	useEffect(() => {
		if (showFeedback) {
			toggleShowFeedback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFeedback]);

	if (jobToLoadChats.isLoading) {
		return <FeedbackChatsLoader />;
	}

	return (
		<div className="h-full p-3">
			<div className="mb-2">
				<h3 className="text-xl font-medium">{`Issue: ${trimStringWithEllipsis(
					selectedFeedback?.title ?? "",
					50
				)}`}</h3>
				<div className="w-full">
					<p
						onClick={() => toggleShowFeedback()}
						className="flex items-center mt-1 space-x-2 text-gray-600 cursor-pointer"
					>
						<span>
							Feedback by - {selectedFeedback?.author} (
							{moment(selectedFeedback?.createdAt).format(
								"DD/MM/YYYY"
							)}
							)
						</span>
						<span
							className={classNames("bp3-icon", {
								"bp3-icon-chevron-down": !showFeedback,
								"bp3-icon-chevron-up": showFeedback,
							})}
						></span>
					</p>
					{showFeedback && (
						<div
							className="w-full overflow-auto bg-gray-300"
							style={{ height: 116 }}
						>
							{selectedFeedback?.section && (
								<h4 className="p-2 font-medium">
									{selectedFeedback?.section} -{" "}
									{selectedFeedback?.subsection}
								</h4>
							)}
							<div className="text-ellipsis whitespace-nowrap">
								<p className="p-2 whitespace-pre-line ">
									{selectedFeedback?.feedback}
								</p>
							</div>

							<div className="p-2">
								Version:{" "}
								{selectedFeedback?.versionInfo &&
									`FE: ${selectedFeedback?.versionInfo?.FE} BE:${selectedFeedback?.versionInfo?.BE}`}
							</div>
						</div>
					)}
				</div>
			</div>
			<div
				style={{
					height: `calc(100% - ${showFeedback ? "255px" : "140px"})`,
				}}
				className="flex flex-col w-full space-y-4 overflow-y-auto px-3"
			>
				{chatMessages.length === 0 && !jobToLoadChats.isLoading && (
					<div className="flex items-center justify-center w-full h-full">
						No chats found. Start your conversation.
					</div>
				)}
				{chatMessages.map((chat) => {
					const { _id, createdAt, message, sender, senderName } =
						chat;
					const isAuthorWayshipTeam =
						sender === feedbackConstants.wayshipTeam;

					const formattedDate =
						moment(createdAt).format("DD-MM-YYYY / hh:mm");
					return (
						<div
							key={_id}
							className={classNames("w-full flex", {
								"justify-start": !isAuthorWayshipTeam,
								"justify-end ": isAuthorWayshipTeam,
							})}
						>
							<div
								className={classNames(
									"p-4 w-2/3 rounded-lg relative",
									{
										" bg-[#ADEBFF] rounded-bl-none":
											!isAuthorWayshipTeam,
										" text-blue-100 bg-[#3278FF] rounded-br-none":
											isAuthorWayshipTeam,
									}
								)}
							>
								<h4 className="text-lg font-medium">
									{senderName}
								</h4>
								<p className="whitespace-pre-line">{message}</p>
								<p
									className={classNames(
										"text-right text-xs mt-2 font-medium",
										{
											"text-gray-600":
												!isAuthorWayshipTeam,
											"text-gray-200":
												isAuthorWayshipTeam,
										}
									)}
								>
									{formattedDate}
								</p>
								<div
									className={classNames(
										"absolute  bottom-0 w-6 h-6",
										{
											"bg-[#3278FF] left-full -translate-x-4":
												isAuthorWayshipTeam,
											"bg-[#ADEBFF] right-full translate-x-4":
												!isAuthorWayshipTeam,
										}
									)}
									style={{
										clipPath: isAuthorWayshipTeam
											? "polygon(50% 0%, 49% 100%, 100% 100%)"
											: "polygon(50% 0, 49% 100%, 0 100%)",
									}}
								></div>
							</div>
						</div>
					);
				})}
				<div ref={messagesEndRef} />
			</div>
			<div className="sticky bottom-0 py-4">
				<div className="relative w-full">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (message.length) props.createMessage();
						}}
					>
						<div className="flex items-end">
							<textarea
								rows={2}
								className="shadow appearance-none border w-[calc(100%-60px)] py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-md resize-none transition hover:ease-in-out duration-300"
								id="user-message"
								tabIndex={0}
								value={message}
								autoFocus
								onChange={(e) => {
									props.setMessage(e.target.value);
								}}
							/>
							<div className="flex items-end">
								<button
									type="submit"
									disabled={!message.length}
									tabIndex={0}
									className={classNames(
										"absolute right-1 top-3 w-12 h-12 rounded-full bg-[#D8E6FF] flex justify-center items-center focus:border focus:border-blue-400 focus:shadow-sm",
										{ "cursor-pointer": message.length },
										{
											"cursor-not-allowed":
												!message.length,
										}
									)}
								>
									<img src={SendIcon} alt="Send message" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const message = feedbackSelectors.getMessage(feedbackStore);
	const selectedFeedbackId =
		feedbackSelectors.getSelectedFeedbackId(feedbackStore);
	const selectedFeedback = feedbackSelectors
		.getFeedbacks(feedbackStore)
		.find((fb) => fb._id === selectedFeedbackId);

	return {
		chatMessages: feedbackSelectors.getChatsForFeedback(feedbackStore),
		message,
		jobToLoadChats:
			feedbackSelectors.jobToLoadSelectedFeedbackChat(feedbackStore),
		selectedFeedback,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		createMessage() {
			dispatch(feedbackActions.commands.createMessage());
		},
		setMessage(message: string) {
			dispatch(feedbackActions.document.selectedChatListMessageSet(message));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackChats);
