import React from "react";
import { TooltipProps } from "recharts";
import {
	ValueType,
	NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { ICustomTooltipProps } from "redux/app/report/@types";

type OwnProps = {
	legends: ICustomTooltipProps[];
	metric?: string;
};

const CustomTooltip = (props: TooltipProps<ValueType, NameType> & OwnProps) => {
	const { active, payload, label, legends, metric = "m³" } = props;
	if (active && payload && payload.length) {
		return (
			<div className="bg-white p-2.5 border-[0.5px] font-redhat-text rounded-md shadow-md">
				<p className="label font-redhat-text ">{`${label}`}</p>

				<>
					{payload.map((category) => {
						const cat = legends.find((leg: any) => leg.key === category.name);
						return (
							<div key={cat?.key} className="flex items-center text-sm">
								<div
									className="w-2 h-2 rounded-full mr-1"
									style={{ background: category.color }}
								/>
								<p>
									{cat?.name ?? ""} :{" "}
									{category?.value ? (+category?.value)?.toFixed(2) : "0"}
								</p>
							</div>
						);
					})}
					<p className="text-xs">
						{" "}
						<span className="text-red-400 text-sm mr-0.5">*</span>All values in
						{metric}
					</p>
				</>
			</div>
		);
	}

	return null;
};

export default CustomTooltip;
