import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { commonUtils } from "redux/_common";
import { TAlertAndUsageContent } from "redux/app/dashboard/@types";
import dashboardConstants from "redux/app/dashboard/constants";
import { IStore } from "redux/store";
const { STATUS } = dashboardConstants;

type OwnProps = {
	content: TAlertAndUsageContent[];
};
type RenderPopupAlertAndUsageContentProps = PropsFromRedux & OwnProps;
function RenderPopupAlertAndUsageContent(
	props: RenderPopupAlertAndUsageContentProps
) {
	const { content } = props;

	return (
		<div className="flex flex-col">
			{content.slice(0, 6).map((row) => (
				<div className="flex items-start">
					<div
						style={{ backgroundColor: STATUS[row.status] }}
						className="min-w-1 min-h-1 rounded-full mt-1.5"
					/>
					<p className="m-0 p-0 text-[12px] ml-[3px]">
						{commonUtils.capitalize(row.description)}
					</p>
				</div>
			))}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(RenderPopupAlertAndUsageContent);
