import classNames from 'classnames';
import React, { FC } from 'react'
import { ISealLog } from 'redux/app/logs/@types';
import SealLogRow from './SealLogRow';

interface ISealLogsPrintTemplateProps {
	seallogs: ISealLog[];
}

const SealLogsPrintTemplate: FC<ISealLogsPrintTemplateProps> = (props) => {
	const { seallogs } = props;

	return (
		<div
			className='flex flex-col p-6'
		>
			<div>
				<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
					Seal Logs
				</h3>
			</div>
			<div>

			<table
				className={classNames(
					"w-full relative mb-6  border border-gray-300 rounded-sm bp3-html-table bp3-interactive",
				)}
			>
				<thead className="relative">
					<tr>
						<th className="text-sm text-gray-500">
							No.
						</th>
						<th className="text-sm text-gray-500">
							Location
						</th>
						<th className="text-sm text-center text-gray-500">
							Seal Fitted
						</th>
						<th className="text-sm text-center text-gray-500">
							Seal No.
						</th>
						<th className="text-sm text-center text-gray-500">
							Seal Removed
						</th>
						<th className="text-sm text-center text-gray-500">
							Reason for removal
						</th>
						<th className="text-sm text-center text-gray-500">
							Recorded By
						</th>
						<th className="text-sm text-center text-gray-500">
							C/E Initials
						</th>
					</tr>
				</thead>
				<tbody
					className={classNames("h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto")}
				>
					{seallogs?.map((seallog, index) => {
						return (
							<SealLogRow
								seallog={seallog}
								key={index}
								onClick={(seallog: ISealLog) => {
									// selectSeallog(seallog);
								}}
								indexNumber={
									index + 1
								}
							/>
						);
					})}
				</tbody>
			</table>
			</div>
		</div>
	)
}

export default SealLogsPrintTemplate