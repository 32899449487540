import React, { useEffect } from "react";
import CrewForm from "./Form";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../../../redux/store";
import { vesselOnboardActions } from "../../../../../../redux/app/vessel/onboard";

type CrewCreateProps = PropsFromRedux & {
	onClose(): void;
	vesselId: string;
};
const NewCrew: React.FC<CrewCreateProps> = (props: CrewCreateProps) => {
	useEffect(function onLoad() {
		props.setFormModeCreate();
		props.setVesselId(props.vesselId);
	}, []);
	return (
		<CrewForm
			onCancel={() => {
				props.onClose();
			}}
			onClose={() => {
				props.onClose();
			}}
			title="Add new Crew"
			subtitle="following fields are required to add a new crew"
		/>
	);
};

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		setVesselId(vesselId: string) {
			dispatch(vesselOnboardActions._forms.crew.formFieldsEdit({ vesselId }));
		},
		setFormModeCreate() {
			dispatch(vesselOnboardActions._forms.crew.formModeSet("CREATE"));
		},
	};
}
const reduxConnector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NewCrew);
