import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TORB1Record } from "../../../../../redux/app/orb1/@types";
import { TORB1GroupC2 } from "../../../../../redux/app/orb1/groups/group-c";
import { commonUtils } from "../../../../../redux/_common";
import ORB1TableRows from "../ORB1TableRows";

type OwnProps = {
	orb1Record: TORB1Record;
	isStrikedOff?: boolean;
};
type ORB1GroupC2PreviewProps = OwnProps;
function ORB1GroupC2Preview(props: ORB1GroupC2PreviewProps) {
	const orb1Record = props.orb1Record as TORB1GroupC2;
	const operationDate = moment(orb1Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB1TableRows
				record={orb1Record}
				tableRows={[
					[operationDate, "C", 11.1, renderItem11P1()],
					[null, null, 11.2, renderItem11P2()],
					[null, null, 11.3, renderItem11P3()],
					[null, null, 11.4, renderItem11P4()],
					[null, null, "NOTE", orb1Record.remarks],
				]}
			/>
		);
	}

	function renderItem11P1() {
		const oilResidue = orb1Record.item11P2.oilResidues[0];
		return (
			<h4 className="font-medium uppercase">
				{oilResidue.selectedTank.tankName}
			</h4>
		);
	}

	function renderItem11P2() {
		const oilResidue = orb1Record.item11P2.oilResidues[0];
		return (
			<h4 className="font-medium uppercase">
				{oilResidue.selectedTank.totalCapacity.toFixed(2)} M³
			</h4>
		);
	}

	function renderItem11P3() {
		const oilResidue = orb1Record.item11P2.oilResidues[0];
		const quantityForCalculation = marpolUtils.getQuantityForCalculation(
			oilResidue.selectedTank
		);
		const calculatedQty = commonUtils.addUp(
			quantityForCalculation,
			oilResidue.collectedResidue
		);
		return (
			<h4 className="font-medium uppercase">{calculatedQty.toFixed(2)} M³</h4>
		);
	}

	function renderItem11P4() {
		const oilResidue = orb1Record.item11P2.oilResidues[0];
		if (oilResidue.notesForManualOperation)
			return (
				<h4 className="font-medium uppercase">
					{oilResidue.collectedResidue.toFixed(2)} M³ COLLECTED FROM{" "}
					{oilResidue.notesForManualOperation}
				</h4>
			);
		return (
			<h4 className="font-medium uppercase">
				{oilResidue.collectedResidue.toFixed(2)} M³
			</h4>
		);
	}

	return renderMain();
}

export default ORB1GroupC2Preview;
