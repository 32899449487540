const authConstants = {
	FEATURE: "@auth",
	forms: {
		LOGIN: "LOGIN",
		RESET_REQUEST: "RESET_REQUEST",
		RESET_PASSWORD: "RESET_PASSWORD",
	},
	storage: {
		AUTH_KEY: "AUTH_TOKEN",
		USER_KEY: "USER_INFO",
	},
	headers: {
		TOKEN: "x-access-token",
	},
};
export default authConstants;
