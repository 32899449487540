import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import YearPicker from "../../individualreport/reportType/garbage/_elements/YearPicker";
import { getYearOptions } from "redux/app/report/utils";
import VesselSelector from "../../common/VesselSelector";
import classNames from "classnames";
import { vesselActions } from "redux/app/vessel";
import reportActions from "redux/app/report/actions";
import reportSelectors from "redux/app/report/selectors";
import Graph from "./_elements/Graph";
import useElementSize from "hooks/useElementSize";
import Table from "./_elements/Table";
import moment from "moment";

type OwnProps = {};
type ORSProps = PropsFromRedux & OwnProps;
function ORS(props: ORSProps) {
	const {
		vesselId,
		year,
		jobToGetVesselSpecificOrsReport,
		jobToLoadAllVesselAggregateOrsReport,
	} = props;
	const reportContainerRef = useRef<HTMLDivElement>(null);
	const [isGraphView, setIsGraphView] = useState(true);
	const { width: reportContainerWidth } = useElementSize(reportContainerRef);

	useEffect(() => {
		props.loadVessels();
		props.setVesselId("");

		return () => {
			props.setVesselId("");
			props.setYear(moment().year().toString());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (vesselId) props.loadOrsVesselSpecificAggregateData();
		else {
			props.loadOrsAllVesselsAggregateData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, year]);

	return (
		<div
			ref={reportContainerRef}
			className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto"
		>
			<div className="">
				<div className="px-5 pb-0 py-8">
					<div className="flex items-center">
						<span
							title="go back"
							onClick={() => {
								window?.history?.back();
							}}
							className="bp3-icon bp3-icon-arrow-left text-lg mr-3 cursor-pointer"
						/>
						<h2 className="text-xl font-medium">Oily Residue & Sludge</h2>
					</div>
					<div className="flex items-center justify-between">
						<div className="flex items-center">
							<div className="flex  items-baseline">
								{isGraphView && (
									<>
										<div className="ml-4 text-base">
											<YearPicker
												options={getYearOptions().map((op) => ({
													label: op.toString(),
													value: op.toString(),
												}))}
												value={year}
												id="ors-year-picker"
												onSelect={(option) => {
													props.setYear(option?.value ?? "");
												}}
												isLoading={
													jobToGetVesselSpecificOrsReport.isLoading ||
													jobToLoadAllVesselAggregateOrsReport.isLoading
												}
											/>
										</div>
										<div className="pl-4 min-w-28 md:w-56">
											<VesselSelector
												onVesselSelect={(vesselId: string) => {
													props.setVesselId(vesselId);
												}}
												isLoading={
													jobToGetVesselSpecificOrsReport.isLoading ||
													jobToLoadAllVesselAggregateOrsReport.isLoading
												}
												selectedVesselId={vesselId}
												showDropdownLabel={false}
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<div>
							<div className="p-0">
								<div className="border-gray-200 ">
									<div className="flex flex-row justify-around py-1 my-2 rounded-md bg-lightGray">
										<button
											className={classNames(
												"transition-all duration-300 px-10 text-[12px]",
												{
													"p-2 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
														isGraphView,
													"p-2 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
														!isGraphView,
												}
											)}
											onClick={() => setIsGraphView(true)}
										>
											Graph
										</button>
										<button
											className={classNames(
												"transition-all duration-300 px-10 text-[12px]",
												{
													"py-1.5 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
														!isGraphView,
													"py-1.5 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
														isGraphView,
												}
											)}
											onClick={() => {
												setIsGraphView(false);
											}}
										>
											Table
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isGraphView ? (
				<Graph reportContainerWidth={reportContainerWidth} />
			) : (
				<Table />
			)}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getOrsVesselId(reportStore);
	const year = reportSelectors.getOrsYear(reportStore);
	const jobToGetVesselSpecificOrsReport =
		reportSelectors.jobToLoadVesselSpecificOrsAggregate(reportStore);
	const jobToLoadAllVesselAggregateOrsReport =
		reportSelectors.jobToLoadAllVesselsOrsAggregate(reportStore);
	return {
		vesselId,
		year,
		jobToGetVesselSpecificOrsReport,
		jobToLoadAllVesselAggregateOrsReport,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		setVesselId(vesselId: string) {
			dispatch(reportActions.document.orsReportVesselIdSet(vesselId));
		},
		setYear(year: string) {
			dispatch(reportActions.document.orsReportYearSet(year));
		},
		loadOrsAllVesselsAggregateData() {
			dispatch(reportActions.commands.orsAllVesselAggregateLoad());
		},
		loadOrsVesselSpecificAggregateData() {
			dispatch(reportActions.commands.orsVesselSpecificAggregateLoad());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ORS);
