import actionTypes from "./action-types";
import { jobActions } from "../../_core/job";
import { ActionType, createAction, getType } from "typesafe-actions";

const {
	APPROVAL_LIST_LOAD,
	APPROVAL_LIST_SET,
	APPROVAL_BY_ID,
	DECLINE_BY_ID,
	LOAD_PRINT_TEMPLATE,
} = actionTypes;

const permitActions = {
	listLoad: createAction(APPROVAL_LIST_LOAD, (list: any) => list)(),
	listSet: createAction(APPROVAL_LIST_SET, (approvals: any) => ({
		approvals,
	}))(),
	approvePermit: createAction(APPROVAL_BY_ID, (approval: any) => approval)(),
	declinePermit: createAction(DECLINE_BY_ID, (decline: any) => decline)(),
	loadPrintTemplate: createAction(LOAD_PRINT_TEMPLATE, (log: any) => log)(),
	_jobs: {
		vesselListLoad: jobActions("@approval", APPROVAL_LIST_LOAD),
	},
};

export default permitActions;
