import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IFeedbackActivity } from "redux/app/feedback/@types";
import { priorityColorMapper } from "redux/app/feedback/utils";
import { IStore } from "redux/store";

type OwnProps = {
	activity: IFeedbackActivity;
};
type RenderActivityProps = PropsFromRedux & OwnProps;
function RenderActivity(props: RenderActivityProps) {
	const { activity } = props;

	let activityRenderer = <></>;

	switch (activity.item) {
		case "priority": {
			activityRenderer = (
				<div className="flex justify-between items-center feedback-activity">
					<div className="m-0 text-gray-700 text-sm  md:w-auto">
						{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
						Changed priority to{" "}
						<span
							className="font-medium"
							style={{ color: priorityColorMapper(activity?.priority) }}
						>
							{activity?.priority}
						</span>
					</div>
				</div>
			);
			break;
		}
		case "assignedTo": {
			activityRenderer = (
				<div className="flex justify-between items-center">
					<div className="m-0 text-gray-700 text-sm  md:w-auto">
						{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
						Assigned to{" "}
						<span className="font-medium">
							{" "}
							:{" "}
							{activity?.assignedTo && activity?.assignedTo?.length > 0
								? activity?.assignedTo?.map((user) => user.userName).join(", ")
								: "None"}
						</span>
					</div>
				</div>
			);
			break;
		}
		case "status": {
			activityRenderer = (
				<div className="flex justify-between items-center">
					<div className="m-0 text-gray-700 text-sm  md:w-auto">
						{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
						Changed status to{" "}
						<span className="font-medium">{activity?.status}</span>
					</div>
				</div>
			);
			break;
		}
		case "dueDate": {
			activityRenderer = (
				<div className="flex justify-between items-center">
					<div className="m-0 text-gray-700 text-sm  md:w-auto">
						{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
						Changed due date to{" "}
						<span className="font-medium">
							{activity?.dueDate
								? moment(activity?.dueDate).format("DD-MM-YYYY").toString()
								: "NA"}
						</span>
					</div>
				</div>
			);
			break;
		}
		case "notes": {
			activityRenderer = (
				<div className="flex justify-between items-center">
					<div className="m-0 text-gray-700 text-sm  md:w-auto">
						{/* <span className="text-blue-600">{user?.name ?? "You"}</span>  */}
						Updated notes to{" "}
						<span className="font-medium">
							{activity?.notes?.slice(0, 5)}...
						</span>
					</div>
				</div>
			);
			break;
		}

		default:
			activityRenderer = <></>;
	}
	return activityRenderer;
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(RenderActivity);
