import classNames from "classnames";
import React, { FC, useState } from "react";
import { FieldType } from "../types";

const SwitchUI = (props: { field: FieldType; classes?: string; isEnableAllForExternal: boolean, noOfFields?: number }) => {
	const { field, classes = "block w-1/2", isEnableAllForExternal, noOfFields } = props;
	const [sign, setSign] = useState<any>();
	if (!field) {
		console.log("NO Options");
		return <></>;
	}
	const title = (field.title || field.name||"") as string;
	let style = `flex flex-row w-1/2 mt-1`;
	// if (noOfFields && noOfFields > 4) {
	// 	style+=` w-1/${noOfFields/2}`
	// }
	// if (noOfFields && noOfFields > 7) {
	// 	style = `flex flex-row mt-1`;
	// }
	if (title.length > 20) { // label has instructions so let flex wrap
		style = 'flex flex-row w-full';
	}
	const titleStyle = "text-base w-1/2";
	const fieldClasses = field?.classNames?.join(" ");
	let isDisabled=true
	if (isEnableAllForExternal) {
		isDisabled=false
	}else if(field.readOnly){
		isDisabled=false
	}
	console.log(field.which)

	switch (field?.type) {
		case "label":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={`text-black ${title.length > 10 ? "w-full" : fieldClasses}`}>
						{title}
					</span>
				</div>
			);
		case "dropdown":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<select
						data-which={field?.which}
						data-isInterval={field.isInterval}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						id={field.key}
						className={classNames(`form-select`,classes, {
							"bg-gray-100":isDisabled
						})}
					>
						<option value="--select--">--select--</option>
						{field?.range.map((e: any) => {
							return (
								<option key={e.label} value={e.value}>
									{e.label}
								</option>
							);
						})}
					</select>
				</div>
			);
		case "upload-sign":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<canvas
						data-which={field?.which}
						data-disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						id={field.key}
						className={classNames(`form-input`,classes, {
							"bg-gray-100":isDisabled
						})}
						placeholder={field.placeholder || field.value}
					></canvas>
				</div>
			);
		case 'signature':
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<div className="flex flex-col gap-y-2 items-center">
						<label htmlFor={field.key} className={classNames("flex flex-col items-center justify-center border-2 border-gray-300 border-dashed rounded-lg hover:bg-gray-100 p-2 max-w-[208px]", {
							"cursor-no-drop": isDisabled,
							"cursor-pointer": !isDisabled
						})}>
							<div className="flex flex-col items-center justify-center gap-y-2">
								<span className="bp3-icon bp3-icon-upload text-gray-500 text-xl" />
								<p className="text-sm text-center text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
								<p className="text-xs text-center text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
							</div>
							<input
								data-which={field?.which}
								disabled={isDisabled}
								data-key={field?.data?.key}
								data-value={field?.data?.value}
								accept="image/*"
								type="file"
								id={field.key}
								placeholder={field.placeholder || field.value}
								className="hidden"
							/>
						</label>
						<div className="max-w-[180px]">
							<img className="block w-full" id={`${field.key}:img`} src={sign} alt=""/>
						</div>
					</div>
				</div>
			)
		case "string":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<input
						data-which={field?.which}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						type="text"
						id={field.key}
						className={classNames(`form-input`,classes, {
							"bg-gray-100":isDisabled
						})}
						placeholder={field.placeholder || field.value}
					/>
				</div>
			);
		case "text":
			return (
				<div className={classNames(style,"max-w-xs",fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<textarea
						data-which={field?.which}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						id={field.key}
						className={classNames(`form-textarea`,classes, {
							"bg-gray-100":isDisabled
						})}
						placeholder={field.placeholder || field.value}
					></textarea>
				</div>
			);
		case "integer":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<input
						data-which={field?.which}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						type="number"
						id={field.key}
						className={classNames(`form-input`,classes, {
							"bg-gray-100":isDisabled
						})}
					/>
				</div>
			);
		case "datetime-local":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<input
						data-which={field?.which}
						data-interval={field.intervalKey}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						type="datetime-local"
						id={field.key}
						className={classNames(`form-input`,classes, {
							"bg-gray-100":isDisabled
						})}
						placeholder={field.placeholder}
					/>
				</div>
			);
		case "date":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<input
						data-which={field?.which}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						type="date"
						id={field.key}
						className={classNames(`form-input m-0`,classes, {
							"bg-gray-100":isDisabled
						})}
						placeholder={field.placeholder}
					/>
				</div>
			);
		case "time":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<input
						data-which={field?.which}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						type="time"
						id={field.key}
						className={classNames(`form-input`,classes, {
							"bg-gray-100":isDisabled
						})}
						placeholder={field.placeholder}
					/>
				</div>
			);
		case "checkbox":
			return (
				<div className={classNames(style,fieldClasses)}>
					<span className={titleStyle}>{title}</span>
					<input
						data-which={field?.which}
						disabled={isDisabled}
						data-key={field?.data?.key}
						data-value={field?.data?.value}
						type="checkbox"
						id={field.key}
						className={classNames(`form-checkbox ml-2 mt-1`, {
							"bg-gray-100":isDisabled,
						})}
						placeholder={field.placeholder}
					/>
				</div>
			);
		case "radio":
			const values = field.values || field.options;
			return (
				<div className={classNames("flex flex-row",fieldClasses, style)}>
					<span className={titleStyle}>{title}</span>
					<div className="flex flex-col gap-1">
						{values.map((ele: any) => {
							return (
								<label key={ele} className="inline-flex items-center">
									<input
										data-which={field?.which}
										disabled={isDisabled}
										data-key={field?.data?.key}
										data-value={field?.data?.value}
										className={classNames(`form-radio`,{
											"bg-gray-100":isDisabled
										})}
										name={field.key}
										value={ele}
										id={field.key + "_" + ele}
										type="radio"
									/>
									<span className="ml-2">{ele}</span>
								</label>
							);
						})}
					</div>
				</div>
			);
		default:
			return <></>;
	}
};

export { SwitchUI };
