import classNames from "classnames";
import React from "react";
type OwnProps = {
	value: string;
};
function Th(
	props: OwnProps &
		React.DetailedHTMLProps<
			React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
			HTMLTableHeaderCellElement
		>
) {
	const { value, className } = props;
	return (
		<th
			className={classNames(
				"px-3 py-3 text-sm font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border border-b border-gray-300",
				className
			)}
		>
			{value}
		</th>
	);
}

export default Th;
