import classNames from "classnames";
import moment from "moment";
import React from "react";
import { IVessel } from "redux/app/vessel/types";

type VesselCardProps = {
	vessel: IVessel;
	handleFavorite: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
	isFavorited: boolean;
	isSelected?: boolean;
};
function VesselCard(props: VesselCardProps) {
	const { vessel, handleFavorite, isFavorited, isSelected = false } = props;
	const { vesselName, imoNumber, addressOfTheOwner, flag, liveInfo } = vessel;

	return (
		<div
			className={classNames(
				"w-[300px] bg-[#EEEFFF] rounded-md px-4 py-3 h-full",
				{
					"border-2 border-gray-400": !isSelected,
					"border-2 border-[#1B4EA3]": isSelected,
				}
			)}
		>
			<div className="flex items-center justify-between">
				<h3 className="text-lg font-semibold">{vesselName}</h3>
				<span
					onClick={(e) => {
						handleFavorite && handleFavorite(e);
					}}
					className={classNames(
						"bp3-icon  text-gray-700 text-lg cursor-pointer",
						{
							"bp3-icon-star-empty": !isFavorited,
							"bp3-icon-star": isFavorited,
						}
					)}
				/>
			</div>
			<div>
				{liveInfo?.from && liveInfo.to ? (
					<p className="text-xs text-gray-600">
						{liveInfo.from?.local_reading} - {liveInfo.to?.local_reading}
					</p>
				) : (
					<div className="h-4"></div>
				)}
			</div>
			<div className="my-4">
				<p className="text-sm text-gray-700">IMO {imoNumber}</p>
				<p className="text-sm text-gray-700">
					{liveInfo?.state_of_ship?.local_reading
						? `At: ${liveInfo?.state_of_ship?.local_reading}`
						: ""}
				</p>
			</div>
			<div className="flex items-center justify-between">
				<p className="text-xs text-gray-600">
					Last synced:{" "}
					{liveInfo?.last_synced ? moment(liveInfo.last_synced).fromNow() : ""}
				</p>
				<p className="text-xs text-gray-600">
					Version:{" "}
					{liveInfo?.versionInfo
						? `${liveInfo?.versionInfo?.FE} | ${liveInfo?.versionInfo?.BE} `
						: ""}
				</p>
			</div>
		</div>
	);
}

export default VesselCard;
