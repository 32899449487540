import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import filterIcon from "../../../../../../../assets/icons/filter.svg";
import classNames from "classnames";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Label,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import CustomBaseXAxisTick from "./CustomBaseXAxisTick";
import CustomQuarterXAxisTick from "./CustomQuarterXAxisTick";
import CustomYearXAxisTick from "./CustomYearXAxisTick";
import reportSelectors from "redux/app/report/selectors";
import { fillColors } from "redux/app/report/constants";
import { transformDataForGraph } from "redux/app/report/utils";
import CustomTooltip from "./CustomTooltip";

const xAxisScale = "Qtr";
const yAxisScale = "m³";
const yAxisDomain = undefined;

type OwnProps = {
	dischargeCategories: string[];
	categoryLegends: {
		title: string;
		key: "A" | "B" | "C" | "D" | "E" | "F" | "I";
	}[];
	reportContainerWidth: number;
	year: string;
	vesselId: string;
};
type GraphProps = PropsFromRedux & OwnProps;
function Graph(props: GraphProps) {
	const {
		dischargeCategories,
		categoryLegends,
		reportContainerWidth,
		rawGarbageData,
		year,
		vesselId,
		jobToGetGarbageReport,
	} = props;

	const finalData = useMemo(() => {
		return transformDataForGraph(rawGarbageData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, rawGarbageData, year]);

	if (jobToGetGarbageReport.isLoading) {
		return <div className="flex justify-center mt-20">Loading...</div>;
	}

	if (rawGarbageData.length === 0) {
		return (
			<div className="flex justify-center items-center mt-20">
				No data found{vesselId ? " for this vessel" : ""}.{" "}
				{vesselId
					? "Choose another vessel."
					: "Please select a vessel or change the year."}
			</div>
		);
	}

	return (
		<>
			<>
				<div className="border-t border-b border-[#D3D3D3]">
					<div className="flex justify-stretch">
						<div className="flex flex-col">
							<h3 className="text-sm font-medium px-5 py-3 border-r border-b border-[#D3D3D3] w-28">
								Discharge
							</h3>
							<h3 className="text-sm font-medium px-5 py-3 border-r border-[#D3D3D3] w-28 h-full">
								Category
							</h3>
						</div>
						<div className="flex flex-col w-full">
							<div className="flex border-b px-5 py-3 border-[#D3D3D3]">
								{dischargeCategories.map((dischargeType) => {
									return (
										<span key={dischargeType} className="mx-4 text-sm">
											{dischargeType}
										</span>
									);
								})}
							</div>
							<div className="flex px-5 py-3 ">
								{categoryLegends.map((cat) => {
									return (
										<div key={cat.title} className="flex items-center">
											<span
												style={{ background: fillColors[cat.key] }}
												className={classNames(
													"ml-2 xl:ml-4 text-sm  xl:text-sm w-4 h-4 lg:w-[16px] lg:h-[16px] xl:w-[22px] xl:h-[22px] rounded-full  "
												)}
											/>
											<span className="ml-1 mr-1 xl:mr-1 xl:ml-2 text-sm lg:text-[12px] xl:text-sm ">
												{cat.title}
											</span>
										</div>
									);
								})}
							</div>
						</div>
						<div className="flex justify-center items-center px-7 border-l border-[#D3D3D3]">
							<img
								className="text-gray-400 cursor-pointer"
								src={filterIcon}
								alt="Filter"
							/>
						</div>
					</div>
				</div>
				<div className="axis flex justify-end text-[#7E7E7E] py-2 pb-1">
					<p className="text-sm font-medium px-3.5 py-1 border-r-2 border-[#7E7E7E]">
						X-axis - {xAxisScale}
					</p>
					<p className="text-sm font-medium px-3.5 py-1">
						Y-axis - {yAxisScale}
					</p>
				</div>
				<div className="p-2">
					<ResponsiveContainer width="100%" height={415}>
						<BarChart
							width={reportContainerWidth}
							data={finalData}
							margin={{
								top: 25,
								right: 10,
								left: -20,
								bottom: 5,
							}}
							barGap={0}
						>
							<CartesianGrid vertical={false} fill="" />

							<XAxis
								interval={0}
								minTickGap={1}
								dataKey="name"
								tickLine={false}
								fontSize={8.5}
								tick={<CustomBaseXAxisTick />}
							/>
							<XAxis
								interval={0}
								xAxisId={"quarterAxis"}
								dataKey="quarter"
								id="quarter"
								axisLine={false}
								tickLine={false}
								scale={"band"}
								type="category"
								allowDuplicatedCategory={false}
								tick={<CustomQuarterXAxisTick />}
							/>
							<XAxis
								interval={0}
								xAxisId={"yearAxis"}
								axisLine={false}
								tickLine={false}
								tick={<CustomYearXAxisTick />}
							>
								<Label fontSize={14} dy={14}>
									{year}
								</Label>
							</XAxis>
							<Tooltip content={<CustomTooltip />} />
							<YAxis
								domain={yAxisDomain ? yAxisDomain : undefined}
								// tickCount={}
								tickLine={false}
							/>
							{categoryLegends.map((category) => {
								return (
									<Bar
										key={category.title}
										dataKey={category.key}
										fill={fillColors[category.key]}
									/>
								);
							})}
						</BarChart>
					</ResponsiveContainer>
				</div>
			</>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawGarbageData = reportSelectors.getRawGarbageData(reportStore);
	const jobToGetGarbageReport =
		reportSelectors.jobToLoadGarbageReport(reportStore);

	return { rawGarbageData, jobToGetGarbageReport };
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Graph);
