const dashboardConstants = {
	FEATURE: "@dashboard",
	STATUS: {
		HIGH: "#E67E22",
		MEDIUM: "#DCD413",
		GOOD: "#459FFF",
	},
	ITEMS_PER_PAGE: 8,
};

export const dashboardTableHeaders = [
	{
		title: "Vessel name",
		key: "name",
		width: 317,
	},
	{
		title: "From - to",
		key: "fromTo",
		width: 317,
	},
	{
		title: "Alerts",
		key: "alerts",
		width: 318,
	},
	{
		title: "Usage",
		key: "usage",
		width: 318,
	},
];

export default dashboardConstants;
