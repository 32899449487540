import ModalPortal from 'components/app/_common/ModalPortal';
import React, { FC, useEffect, useState } from 'react'
import { commonUtils } from 'redux/_common';
import { IJobGetStatus } from 'redux/_core/job/types';
import { ITimeLog } from 'redux/app/vessel/onboard/types';

interface ILoaderProps {
	className?: string;
}

const Loader: FC<ILoaderProps> = (props) => {
	const {className} = props;

	return (
		<div className={className}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a2" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#000000"></stop><stop offset=".3" stop-color="#000000" stop-opacity=".9"></stop><stop offset=".6" stop-color="#000000" stop-opacity=".6"></stop><stop offset=".8" stop-color="#000000" stop-opacity=".3"></stop><stop offset="1" stop-color="#000000" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a2)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#000000" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle>
			</svg>
		</div>
	);
}

interface ITimeLogsWrapperProps {
	timeLogs: ITimeLog[];
	jobToLoadTimeLogs: IJobGetStatus;
	getTimeLogs(vesselId: string, page: number): void;
	vesselId: string;
}

const TimeLogsWrapper: FC<ITimeLogsWrapperProps> = (props) => {
	const { timeLogs, jobToLoadTimeLogs, vesselId } = props;
	const [isTimeLogsModalVisible, setIsTimeLogsModalVisible] = useState(false);
	const [page, setPage] = useState(1);

	const isLoading = jobToLoadTimeLogs.isLoading;

	useEffect(() => {
		if (vesselId) {
			props.getTimeLogs(vesselId, 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId]);

	return (
		<>
			<div
				className="absolute top-0 right-5 cursor-pointer flex items-center gap-x-1.5 border-0 border-b border-black p-0"
				onClick={() => {
					setIsTimeLogsModalVisible(true);
				}}
			>
				<span className="bp3-icon bp3-icon-time" />
				<span>Time Logs</span>
			</div>
			<ModalPortal
				isActive={isTimeLogsModalVisible}
				onClose={() => {
					setIsTimeLogsModalVisible(false);
				}}
				width={900}
			>
				<div className="h-full my-12 p-6 bg-white rounded-lg max-h-[calc(90vh)] overflow-hidden font-redhat-text">
					<div className="flex items-center justify-between mb-5">
						<h1 className="text-xl font-medium">Time Logs</h1>
						<button
							title="close"
							className="flex items-center justify-center text-2xl border-none outline-none"
							onClick={() => {
								setIsTimeLogsModalVisible(false);
							}}
						>
							<span className="bp3-icon bp3-icon-cross" />
						</button>
					</div>

					<table className="w-full border border-gray-300 rounded-sm bp3-html-table bp3-interactive bordered">
						{/* Table Header */}
						<thead className="block w-full bg-white">
							<tr className="table w-full table-fixed">
								<th className="text-sm text-gray-500 w-[6%]">
									S.no
								</th>
								{/* <th className="text-sm text-gray-500 w-[23.5%]">
									Recorded on
								</th> */}
								<th className="text-sm text-gray-500 w-[31.3%]">
									Ship's Server time
								</th>
								<th className="text-sm text-gray-500 w-[31.3%]">
									Actual Time
								</th>
								<th className="text-sm text-gray-500 w-[31.3%]">
									Difference
								</th>
							</tr>
						</thead>

						{/* Table Body */}
						<tbody className="block max-h-[500px] overflow-y-auto w-full table-fixed">
							{timeLogs.map((log, index) => (
								<tr
									key={log._id}
									className="table w-full table-fixed"
								>
									<td className="text-sm w-[6%]">
										{index + 1}
									</td>
									{/* <td className="text-sm w-[23.5%]">
										{commonUtils.formatSystemDate(
											log.createdAt
										)}
									</td> */}
									<td className="text-sm w-[31.3%]">
										{commonUtils.formatSystemDate(
											log.localServerTime
										)}
									</td>
									<td className="text-sm w-[31.3%]">
										{commonUtils.formatSystemDate(
											log.cloudServerTime
										)}
									</td>
									<td className="text-sm w-[31.3%">
										{
											// log?.difference
											// 	? log?.difference
											// 	:
											commonUtils.getTimeDifference(
												log.cloudServerTime,
												log.localServerTime
											)
										}
									</td>
								</tr>
							))}
						</tbody>
					</table>

					{timeLogs?.length > 5 ? (
						isLoading ? (
							<div className="flex items-center justify-center py-2">
								<Loader className="w-10 h-10" />
							</div>
						) : (
							<div className="flex items-center justify-center py-2">
								<button
									className="flex items-center justify-center text-sm font-medium underline border-none outline-none"
									onClick={() => {
										setPage((prev) => prev + 1);
										props.getTimeLogs(vesselId, page + 1);
									}}
								>
									Show More
								</button>
							</div>
						)
					) : null}
					{isLoading && !timeLogs.length ? (
						<div className="flex flex-col items-center justify-center h-64 py-3 gap-y-2 ">
							<div
								className="flex items-center justify-center h-64"
								style={{
									height: "256px !important",
								}}
							>
								<Loader className="w-10 h-10" />
							</div>
						</div>
					) : timeLogs.length === 0 ? (
						<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
							<h5 className="text-xl font-semibold text-gray-500 font-redhat-display">
								No Time logs present
							</h5>
						</div>
					) : null}
				</div>
			</ModalPortal>
		</>
	);
}

export default TimeLogsWrapper