import React from "react";
type SVGLogsProps = { className?: string };
const SVGLogs: React.FC<SVGLogsProps> = (props) => (
	<svg
		width="39"
		height="39"
		viewBox="0 0 39 39"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.64258 23.2143L19.4997 30.6429L34.3884 23.2143M4.64258 16.0773L19.5146 23.2143L34.3884 16.0773L19.5146 8.35718L4.64258 16.0773Z"
			stroke="#0B2F56"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGLogs;
