import React, { useEffect, useState } from "react";

type PermitSignModalProps = React.PropsWithChildren<{
	onClose: () => void;
	onSign: () => void;
}>;

const PermitSignModal: React.SFC<PermitSignModalProps> = ({
	onClose,
	onSign,
}: PermitSignModalProps) => {
	return (
		<div className="flex justify-center my-12 font-redhat-text bp3-dialog bg-white">
			<div className="flex items-center justify-center h-auto px-8 py-10 my-1  rounded-lg bp3-dialog-body">
				<div className="flex-1 px-3">
					<div className="flex flex-wrap -mx-2">
						<div className="px-2" style={{ width: "100%" }}>
							Do you want to sign this permit?
						</div>
					</div>

					<div className="flex flex-row items-center justify-start mt-5">
						<button
							style={{
								background: "#EBF2FF",
							}}
							className="px-10 py-3 mt-2 text-base font-medium rounded-lg text-secondary"
							onClick={onSign}
						>
							Sign
						</button>
						<button
							className="px-10 py-3 mt-2 text-base font-medium rounded-lg text-secondary"
							onClick={onClose}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PermitSignModal;
