import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupG1 } from "../../../../../redux/app/annex6/groups/group-g";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupG1PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupG1Preview(props: Annex6GroupG1PreviewProps) {
	const annex6Record = props.annex6Record as TAnnex6GroupG1;
	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{moment(annex6Record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						G
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">22</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase"></h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">23</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase"></h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">24</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase"></h4>
					</div>
				</td>
			</tr>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">{}</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						{}
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">25</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase"></h4>
					</div>
				</td>
			</tr>
			{annex6Record.remarks !== "" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix"
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{annex6Record.remarks}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default Annex6GroupG1Preview;
