import React, { useEffect, useMemo, useState } from "react";
import { IStore } from "../../../../redux/store";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import InfiniteProgress from "../../../_common/InfiniteProgress";
import classNames from "classnames";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";
import Pagination from "components/_common/NumberedPagination";
import FeedbackChats from "./_elements/FeedbackChats";
import IconClose from "components/_common/icons/Close";
import FeedbackVesselSelectors from "./_elements/FeedbackVesselSelectors";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import { IFilterState, IVersionInfo, IVessel } from "redux/app/feedback/@types";
import ModalPortal from "components/app/_common/ModalPortal";
import moment from "moment";
import Deadline from "./_elements/Deadline";
import FeedbackForm from "./_elements/FeedbackForm";
import Button from "@set-product/core/button/Button";
import {
	checkIfFiltersPresent,
	getPriorityClass,
	getStatusClass,
	trimStringWithEllipsis,
} from "redux/app/feedback/utils";
import SVGFilter from "components/_common/icons/feedback/SVGFilter";
import Filters from "./_elements/filters/Filters";
import FeedbackTicketTab from "../_common/FeedbackTicketTab";
import FeedbackTicketStatusCount from "../_common/FeedbackTicketStatusCount";

type FeedbackListProps = { pageNumber: string } & PropsFromRedux;
function FeedbackList(props: FeedbackListProps) {
	const {
		feedbacks,
		jobToLoadFeedbacks,
		vesselsByIds,
		isFeedbackChatModalOpen,
		// searchBy,
		pageNumber,
		paginationOfFeedback,
		currentDropdownVessel,
		isFeedbackFormModalOpen,
		feedbackFilters,
	} = props;

	let filterStateWithoutVesselId = { ...feedbackFilters };
	const areFiltersPresent = checkIfFiltersPresent(filterStateWithoutVesselId);

	const vesselVersions = useMemo(() => {
		const FEVersions = new Set();
		const BEVersions = new Set();
		Object.values(vesselsByIds)
			.filter(
				(vessel: IVessel & { versionInfo?: IVersionInfo }) =>
					!!vessel?.versionInfo
			)
			.forEach((vessel: IVessel & { versionInfo?: IVersionInfo }) => {
				if (vessel.versionInfo) {
					// this if statement to fix typescript error
					const {
						versionInfo: { FE, BE },
					} = vessel;
					FE && FEVersions.add(FE);
					BE && BEVersions.add(BE);
				}
			});

		return {
			FEVersions: Array.from(FEVersions) as string[],
			BEVersions: Array.from(BEVersions) as string[],
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Object.keys(vesselsByIds).length]);

	const { BEVersions, FEVersions } = vesselVersions;

	useEffect(() => {
		props.loadTotalFeedbackCount(currentDropdownVessel);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentDropdownVessel]);

	useEffect(() => {
		props.loadVessels();
		props.loadFeedbacks(pageNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	return (
		<div className="min-h-full mb-6 bg-white rounded-md md:m-2 md:px-2">
			<div className="flex justify-center items-center"></div>
			<div
				className={classNames(
					"flex flex-row items-end justify-between md:mx-6 md:my-6 px-2 md:px-0"
				)}
			>
				<div className="w-full">
					<div className="flex flex-col items-center justify-between space-y-2 md:mt-0 md:flex-row md:space-y-0">
						<div className="flex flex-col items-center md:flex-row ">
							{/* <h3 className="mr-4 text-2xl font-medium text-gray-700 font-redhat-display">
								Feedback
							</h3> */}
							<div className="w-64">
								<FeedbackVesselSelectors
									onVesselSelect={() => {
										props.goToNewPage(1);
										props.loadFeedbacks("1");
									}}
								/>
							</div>
						</div>
						{/* INFO: search can't be done on frontend - as the entire feedbacks are not receivied at once */}
						{/* <div className="w-[240px]">
							<input
								className="inline-block w-full p-2 border border-gray-300 rounded-lg"
								placeholder="Search title or feedback"
								value={searchBy}
								onChange={(e) => {
									props.setSearchBy(e.target.value);
								}}
							/>
						</div> */}

						<div className="flex flex-col items-center justify-center md:flex-row">
							<button
								onClick={() => props.toggleIsFeedbackFiltersModal()}
								className={classNames(
									"w-10 h-10 rounded-full border flex justify-center items-center border-[#B49494] transition-shadow ease-in-out duration-75 hover:shadow-lg focus:shadow-lg active:shadow-lg mb-2 md:mb-0",
									{ "shadow-lg": areFiltersPresent }
								)}
							>
								<div className="relative">
									<SVGFilter />
									{areFiltersPresent && (
										<span className="cursor-pointer absolute -top-1 -right-2.5 h-[8px] w-[8px] rounded-full bg-red-600 flex justify-center items-center items"></span>
									)}
								</div>
							</button>
							<Button
								className="px-6 mb-2 bg-blue-700 whitespace-nowrap disabled:bg-gray-300 md:ml-4 md:mb-0"
								onClick={() => {
									props.toggleFeedbackFormModal();
								}}
								disabled={jobToLoadFeedbacks.isLoading}
							>
								Add Feedback
							</Button>
						</div>
					</div>
					<h6 className="text-sm text-gray-600">
						{jobToLoadFeedbacks.isLoading ? "Loading feedback please wait" : ""}
					</h6>
					<div className="mt-6 mb-6 overflow-x-auto">
						<InfiniteProgress
							isLoading={jobToLoadFeedbacks.isLoading}
							isSpacedOut={false}
						/>
						<table
							className={classNames(
								"w-full mb-6 bp3-html-table font-redhat-text  overflow-x-auto  border-collapse  relative ",
								{
									"animate-pulse": jobToLoadFeedbacks.isLoading,
								}
							)}
						>
							<thead className="">
								<tr>
									<th className="px-3 py-3 text-[12px] md:text-sm border font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 min-w-[140px] md:min-w-[180px]">
										Date
									</th>
									<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300">
										Name
									</th>
									<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300">
										Vessel
									</th>
									<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 min-w-[150px] md:min-w-[190px]">
										Section
									</th>
									<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 min-w-[300px] md:min-w-[400px] 2xl:w-auto">
										Short description
									</th>
									<th className="px-3 py-3 text-[12px] md:text-sm border border-l-0 font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300">
										Actions
									</th>
								</tr>
							</thead>
							<tbody
								className={classNames({
									"h-0 opacity-0 transition-all duration-100 max-h-0":
										jobToLoadFeedbacks.isLoading && feedbacks.length === 0,
									"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
										!jobToLoadFeedbacks.isLoading,
								})}
							>
								{feedbacks.map((feedback, index) => {
									return (
										<tr
											key={feedback._id}
											className="hover:bg-gray-200 h-[105px]"
										>
											<td
												className={classNames(
													"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
												)}
											>
												<div className="h-full">
													<p className="m-0">
														{feedback.createdAt
															? moment(feedback.createdAt).format(
																	"DD-MM-YYYY h:mm a"
															  )
															: "NA"}
													</p>
													<p className="m-0 text-xs md:text-sm text-[#00000099]">
														Tkt no.: {feedback?.ticketNumber ?? "NA"}
													</p>
												</div>
											</td>
											<td
												className={classNames(
													"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
												)}
											>
												<div>
													<p className="m-0">{feedback?.author ?? "NA"}</p>
													<p className="m-0 text-xs md:text-sm text-[#00000099]">
														{feedback?.authorDesignation}
													</p>
												</div>
											</td>
											<td
												className={classNames(
													"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
												)}
											>
												<div>
													<p className="m-0 text-xs md:text-sm">
														{feedback?.vesselName ?? "NA"}
													</p>
												</div>
											</td>
											<td
												className={classNames(
													"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   lg:min-w-min border-t-0"
												)}
											>
												<div>
													<p className="m-0 text-xs md:text-sm">
														{feedback?.section ?? "NA"}
													</p>
													<p className="m-0 text-xs md:text-sm text-[#00000099]">
														{feedback?.subsection}
													</p>
												</div>
											</td>
											<td
												className={classNames(
													"px-3 py-2  h-full text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left   border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28  border-t-0"
												)}
											>
												<div className="flex flex-col justify-between h-full">
													<p className="m-0 text-xs md:text-sm">
														{feedback?.title?.length > 60
															? trimStringWithEllipsis(
																	feedback?.title ?? "",
																	60
															  )
															: feedback?.title ?? "NA"}
													</p>

													<div className="flex mt-4">
														{feedback?.priority && (
															<div
																className={
																	getPriorityClass(feedback.priority)
																		.className + " mr-2"
																}
															>
																{" "}
																{getPriorityClass(feedback.priority).text}
															</div>
														)}
														{feedback?.status && (
															<div
																className={
																	getStatusClass(feedback.status).className +
																	" mr-2"
																}
															>
																{" "}
																{getStatusClass(feedback.status).text}
															</div>
														)}
														{feedback?.dueDate && (
															<Deadline
																dueDate={feedback?.dueDate}
																status={feedback?.status ?? ""}
															/>
														)}
													</div>
												</div>
											</td>
											<td
												className={classNames(
													"px-3 py-5 text-xs md:text-sm border-[0.5px] leading-4 tracking-wide text-left border-b border-gray-300 font-redhat-text text-[#2D2D2D]   min-w-28 lg:min-w-min border-t-0"
												)}
											>
												<div className="relative inline-block">
													<div
														className="text-xs md:text-sm font-medium text-[#1B4EA3] underline bg-transparent border-0 shadow-none hover:text-blue-700 cursor-pointer"
														// target="_blank"
														// rel="noreferrer noopener"
														onClick={() => {
															props.goToParticularFeedback(feedback?._id);
														}}
													>
														View
													</div>
													{/* <div className="absolute top-0 left-0"> */}
													{feedback?.user_new_message ? (
														<span className="cursor-pointer absolute -top-1 -right-2 h-[6px] w-[6px] rounded-full bg-red-600 flex justify-center items-center items"></span>
													) : null}
													{/* </div> */}
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						{!jobToLoadFeedbacks.isLoading && feedbacks.length === 0 ? (
							<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
								<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
									No Feedbacks found..
								</h5>
							</div>
						) : null}

						<div></div>

						<Pagination
							{...{
								...paginationOfFeedback,
								totalItems: paginationOfFeedback.totalItems || 0,
							}}
							itemIds={[]}
							onPageChange={function onPageChange(pageNumberFromPagination) {
								props.goToNewPage(pageNumberFromPagination);
							}}
							isPageLoading={jobToLoadFeedbacks.isLoading}
						/>
						<Filters />
						<ModalPortal
							isActive={isFeedbackFormModalOpen}
							onClose={() => {
								props.toggleFeedbackFormModal();
							}}
							width={650}
						>
							<div className="h-full max-h-[calc(90vh)] my-12 p-4 overflow-y-auto bg-white rounded-lg font-redhat-text">
								<FeedbackForm FEVersions={FEVersions} BEVersions={BEVersions} />
							</div>
						</ModalPortal>
						<ModalPortal
							isActive={isFeedbackChatModalOpen}
							onClose={() => {
								props.toggleFeedbackChatModal();
							}}
						>
							<div className="h-full my-12 overflow-y-auto bg-white rounded-lg font-redhat-text">
								<div
									style={{ height: "calc(100vh - 100px)" }}
									className="relative p-3 overflow-hidden"
								>
									<FeedbackChats />
									<div className="absolute top-0 right-0 p-5">
										<div onClick={() => props.toggleFeedbackChatModal()}>
											<IconClose className="w-8 h-8 cursor-pointer" />
										</div>
									</div>
								</div>
							</div>
						</ModalPortal>
					</div>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore) {
	const feedbackStore = store.app.feedback;

	return {
		feedbacks: feedbackStore.feedbacks,
		jobToLoadFeedbacks: feedbackSelectors.jobToLoadFeedbacks(feedbackStore),
		vesselsByIds: feedbackStore.vessels.byIds,
		currentDropdownVessel:
			feedbackSelectors.getFiltersState(feedbackStore).vesselId,
		isFeedbackChatModalOpen:
			feedbackSelectors.getIsFeedbackChatModalOpen(feedbackStore),
		searchBy: feedbackSelectors.getSearchTerm(feedbackStore),
		paginationOfFeedback: feedbackSelectors.getPagination(feedbackStore),
		feedbackFilters: feedbackSelectors.getFiltersState(feedbackStore),
		isFeedbackFormModalOpen:
			feedbackSelectors.getIsFeedbackFormModalOpen(feedbackStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadFeedbacks(pageNumber: string) {
			dispatch(feedbackActions.commands.feedbackListLoad(+pageNumber));
		},
		loadVessels() {
			dispatch(feedbackActions.commands.feedbackVesselsLoad());
		},
		toggleFeedbackChatModal() {
			return dispatch(feedbackActions.document.togglefeedbackChatModal());
		},
		loadChatsForFeedback(feedbackId: string) {
			return dispatch(feedbackActions.commands.feedbackChatsLoad(feedbackId));
		},
		setSelectedFeedbackId(feedbackId: string) {
			return dispatch(
				feedbackActions.document.selectedFeedbackIdSet(feedbackId)
			);
		},
		setSelectedFeedbackVesselId(vesselId: string) {
			return dispatch(
				feedbackActions.document.selectedFeedbackVesselIdSet(vesselId)
			);
		},
		setSearchBy(searchTerm: string) {
			return dispatch(
				feedbackActions.document.searchFeedbackByIssueSet(searchTerm)
			);
		},
		goToNewPage(pageNumber: number) {
			dispatch(
				historyActions.push(
					"#FeedbackList",
					rootRoutes.children.app.children.feedback.children.list.url(
						pageNumber
					)
				)
			);
		},
		resetPageNumber() {
			dispatch(feedbackActions.document.paginationCurrentPageSet(1));
		},
		loadTotalFeedbackCount(vesselId?: string) {
			dispatch(feedbackActions.commands.feedbackTotalCountLoad(vesselId));
		},
		filtersEdit(filters: Partial<IFilterState>) {
			dispatch(feedbackActions.document.feedbackFilterStateSet(filters));
		},
		toggleFeedbackFormModal() {
			dispatch(feedbackActions.document.togglefeedbackFormModal());
		},
		toggleIsFeedbackFiltersModal() {
			dispatch(feedbackActions.document.toggleFeedbackFiltersModal());
		},
		goToParticularFeedback(feedbackId: string) {
			dispatch(
				historyActions.push(
					"#feedback-page",
					`${rootRoutes.children.app.children.feedback.children.view.url(
						feedbackId
					)}`
				)
			);
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackList);
