import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import Garbage from "./reportType/garbage/Garbage";

type OwnProps = {};
type IndividualReportTypeProps = PropsFromRedux & OwnProps;
function IndividualReportType(props: IndividualReportTypeProps) {
	// INFO: possibly a switch case handler where different graphs can be handled here
	return <Garbage />;
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(IndividualReportType);
