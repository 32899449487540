import { jobSelectors } from "redux/_core/job";
import { getType } from "typesafe-actions";
import { IStore } from "../../store";
import { IFeedbackStore, ETypeOfFilter } from "./@types";
import feedbackActions from "./actions";

const feedbackSelectors = {
	_getStore(feedbackStore: IStore) {
		return feedbackStore.app.feedback;
	},
	getNotifications(feedbackStore: IFeedbackStore) {
		return feedbackStore.notifications;
	},
	getNotificationsPagination(feedbackStore: IFeedbackStore) {
		return feedbackStore.notificationsPagination;
	},
	getFeedbacks(feedbackStore: IFeedbackStore) {
		return feedbackStore.feedbacks;
	},
	getVessels(feedbackStore: IFeedbackStore) {
		return feedbackStore.vessels;
	},
	jobToLoadNotifications(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.notificationsLoad)
		);
	},
	jobToLoadFeedbacks(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.feedbackListLoad)
		);
	},
	jobToLoadFeedbacksCount(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.feedbackTotalCountLoad)
		);
	},
	getIsFeedbackChatModalOpen(feedbackStore: IFeedbackStore) {
		return feedbackStore.isFeedbackChatModalOpen;
	},
	getIsFeedbackFormModalOpen(feedbackStore: IFeedbackStore) {
		return feedbackStore.isFeedbackFormModalOpen;
	},
	getChatsForFeedback(feedbackStore: IFeedbackStore) {
		return feedbackStore.chats;
	},
	getIndividualFeedback(feedbackStore: IFeedbackStore) {
		return feedbackStore.individualFeedbackWithChats;
	},
	getSelectedFeedbackId(feedbackStore: IFeedbackStore) {
		return feedbackStore.selectedFeedbackId;
	},
	getMessage(feedbackStore: IFeedbackStore) {
		return feedbackStore.message;
	},
	getActiveChatAttachments(feedbackStore: IFeedbackStore) {
		return feedbackStore?.activeChatAttachments;
	},
	getSelectedFeedbackVesselId(feedbackStore: IFeedbackStore) {
		return feedbackStore.selectedFeedbackVesselId;
	},
	jobToLoadSelectedFeedbackChat(feebackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feebackStore,
			getType(feedbackActions.commands.feedbackChatsLoad)
		);
	},
	jobToLoadIndividualFeedback(feebackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feebackStore,
			getType(feedbackActions.commands.individualFeedbackLoad)
		);
	},
	jobToLoadVessels(feebackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feebackStore,
			getType(feedbackActions.commands.feedbackVesselsLoad)
		);
	},
	getSearchTerm(feedbackStore: IFeedbackStore) {
		return feedbackStore.searchBy;
	},
	getFilters(feedbackStore: IFeedbackStore) {
		return feedbackStore.filters;
	},
	getFiltersState(feedbackStore: IFeedbackStore) {
		return feedbackStore.filterState;
	},
	getPagination(feedbackStore: IFeedbackStore) {
		return feedbackStore._pagination;
	},
	getChatWithAttachmentUploadProgress(feedbackStore: IFeedbackStore) {
		return feedbackStore.isMessageBeingGenerated;
	},
	jobToCreateMessage(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.createMessage)
		);
	},
	jobToAttachChatUpload(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.chatAttachmentUpload)
		);
	},
	jobToUpdateChatWithAttachments(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.chatMessageUpdate)
		);
	},
	jobToUpdateSelectedFeedback(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.feedbackUpdate)
		);
	},
	getTaskTemplates(feedbackStore: IFeedbackStore) {
		return feedbackStore.tasks;
	},
	jobToLoadTasks(feedbackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feedbackStore,
			getType(feedbackActions.commands.tasksLoad)
		);
	},
	getVesselSpecificUsers(feedbackStore: IFeedbackStore) {
		return feedbackStore.vesselSpecificUsers;
	},
	jobToLoadVesselSpecificUsers(feebackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feebackStore,
			getType(feedbackActions.commands.vesselSpecificUsersLoad)
		);
	},
	jobToSendFeedback(feebackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feebackStore,
			getType(feedbackActions.commands.feedbackSend)
		);
	},
	getIsFeedbackFiltersModalOpen(feebackStore: IFeedbackStore) {
		return feebackStore.isFeedbackFiltersOpen;
	},
	getCurrentFilterSection(feebackStore: IFeedbackStore) {
		return feebackStore.currentSelectedFeedbackFilterSection;
	},
	getFeedbackFilterState(feebackStore: IFeedbackStore) {
		return feebackStore.filterState;
	},
	getFeedbackTicketTabType(feebackStore: IFeedbackStore) {
		return feebackStore.ticketTabType;
	},
	getDashboardFeedbackListForCategory(
		feebackStore: IFeedbackStore,
		ticketTabType: "myTickets" | "allTickets",
		category: ETypeOfFilter
	) {
		return feebackStore?.dashboard[ticketTabType]?.feedbacks[category] ?? [];
	},
	getDateRange(feedbackStore: IFeedbackStore) {
		return feedbackStore.dashboard.dateRange;
	},
	getDashboardStats(
		feedbackStore: IFeedbackStore,
		ticketTabType: "myTickets" | "allTickets"
	) {
		return feedbackStore.dashboard[ticketTabType].stats;
	},
	getDashboardFeedbackTablesData(
		feedbackStore: IFeedbackStore,
		ticketTabType: "myTickets" | "allTickets"
	) {
		return feedbackStore.dashboard[ticketTabType].feedbacks;
	},
	jobToLoadDashboardFeedbacksAndStats(feebackStore: IFeedbackStore) {
		return jobSelectors.getJobStatus(
			feebackStore,
			getType(feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad)
		);
	},
};

export default feedbackSelectors;
