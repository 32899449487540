import { IJobHOCState } from "redux/_core/job/types";

export interface IVessel {
	_id: string;
	callSign: string;
	imoNumber: string;
	vesselName: string;
	vesselPrefix: string;
	vesselSubType: string;
	vesselType: string;
}

export enum EORSAggregateRecord {
	INCINERATOR = "INCINERATOR",
	SHORE_DISPOSAL = "SHORE DISPOSAL",
	WATER_EVAPORATION = "WATER EVAPORATION",
	SLOP_TANK = "SLOP TANK",
	BOILER = "BOILER",
}

export interface IORSMonthWiseData {
	month: number;
	totalQuantityDisposed: number;
}
export interface IORSAggregateRecord {
	disposalMethod: EORSAggregateRecord;
	tenantId: string;
	monthWiseData: IORSMonthWiseData[];
}

export interface IORSVesselSpecificDateRange {
	year: number;
	month: number;
}

export interface IORSVesselSpecificCategory {
	disposalMethod: EORSAggregateRecord;
	totalQuantityDisposed: number;
}

export interface IORBVesselSpecificInfo extends IRawGarbageVesselInfo {
	vesselType: string;
}

export interface IORSVesselSpecificAggregateRecord {
	dateRange: IORSVesselSpecificDateRange;
	lastAggregatedAt: string;
	orsCategories: IORSVesselSpecificCategory[];
	tenantId: string;
	vessel: IORBVesselSpecificInfo;
}

export interface ICargoSlopMonthWiseRecord {
	month: number;
	totalQuantityDischarged: number;
}

export interface ICargoSlopAggregateRecord {
	tenantId: string;
	monthWiseData: ICargoSlopMonthWiseRecord[];
}

export interface ICargoSlopVesselSpecificAggregateRecord {
	tenantId: string;
	monthWiseData: ICargoSlopMonthWiseRecord[];
	vessel: IVessel;
	lastAggregatedAt: string;
	cumulativeQuantityDischarged: number;
}

export interface IOilSpillsInWaterMonthWiseRecord {
	month: number;
	totalQuantityDischarged: number;
}

export interface IOilSpillsInWaterAggregateRecord {
	tenantId: string;
	monthWiseData: IOilSpillsInWaterMonthWiseRecord[];
}

export interface IOilSpillsInWaterVesselSpecificAggregateRecord {
	tenantId: string;
	monthWiseData: IOilSpillsInWaterMonthWiseRecord[];
	vessel: IVessel;
	lastAggregatedAt: string;
	cumulativeQuantityDischarged: number;
}

export interface IReportStore extends IJobHOCState {
	garbage: {
		rawGarbageData: IGarbageRawRecord[] | null;
		vesselId: string;
		year: string;
		allVesselsGarbageDischargeData: IGarbageDischargeAllVesselsRecord[];
		allVesselsGarbageCategoryData: IGarbageCategoryAllVesselsRecord[];
	};
	bilgeWater: {
		quarterLabels: string[];
		rawBilgeWaterData: IBilgeWaterRecord[] | null;
		vesselId: string;
		year: string;
		allVesselBilgeWaterData: IBilgeWaterAllVesselAggregateRecord[];
	};
	ors: {
		rawOrsData: IORSAggregateRecord[];
		rawOrsVesselSpecificData: IORSVesselSpecificAggregateRecord[];
		vesselId: string;
		year: string;
	};
	cargoSlop: {
		rawCargoSlopData: ICargoSlopAggregateRecord[];
		rawCargoSlopVesselSpecificData: ICargoSlopVesselSpecificAggregateRecord[];
	};
	oilSpillsInWater: {
		rawOilSpillsInWaterData: IOilSpillsInWaterAggregateRecord[];
		rawOilSpillsInWaterVesselSpecificData: IOilSpillsInWaterVesselSpecificAggregateRecord[];
	};
	vesselId: string;
	year: string;
}

export enum GARBAGE_DISCHARGE_CATEGORIES {
	GARBAGE_DISCHARGED_INTO_SEA = "GARBAGE_DISCHARGED_INTO_SEA",
	GARBAGE_INCINERATED = "GARBAGE_INCINERATED",
	GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS = "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
}

export enum IRawGarbageDischargeLabel {
	"FOOD_WASTES" = "Food wastes",
}

export interface IRawGarbageDischargeRecord {
	label: string; //Fix me: IRawGarbageDischargeLabel;
	estimatedAmountDischarged: number;
	value: string;
	category: string;
}

export interface IRawGarbageVesselInfo {
	_id: string;
	callSign: string;
	imoNumber: string;
	vesselName: string;
	vesselPrefix: string;
	vesselSubType: string;
	vesselType: string;
}

export interface IRawGarbageDateRange {
	month: number;
	year: number;
}

export interface IRawGarbageCategory {
	category: string; //Fix me - label enum
	garbageDischarged: number;
	label: string; //Fix me - enum
}

export interface IRawGarbageDischargeCategory {
	category: string; //GARBAGE_DISCHARGE_CATEGORIES;
	discharged: number;
	garbageRecords: IRawGarbageDischargeRecord[];
}

export interface IGarbageRawRecord {
	_id: string;
	tenantId: string;
	garbageDischargeCategories: IRawGarbageDischargeCategory[];
	vessel: IRawGarbageVesselInfo;
	totalGarbageDischarged: number;
	lastAggregatedAt: string;
	dateRange: IRawGarbageDateRange;
	garbageCategories: IRawGarbageCategory[];
}

export type IMonthwiseRawGarbageObj = {
	[month: string]: IGarbageRawRecord;
};

export interface IMonthWiseRawGarbageRow {
	[key: string]: Record<string, IRawGarbageDischargeCategory> | Object;
}

export interface IBilgeWaterRecord {
	_id: string;
	disposalMethod: string;
	tenantId: string;
	totalQuantityDisposed: number;
	vessel: IVessel;
	lastAggregatedAt: string;
	dateRange: {
		month: number;
		year: number;
	};
}

export interface IBilgeWaterAllVesselMonthData {
	month: number;
	totalQuantityDisposed: number;
}

export interface IBilgeWaterAllVesselAggregateRecord {
	disposalMethod: EBilgeWaterType;
	tenantId: string;
	monthWiseData: IBilgeWaterAllVesselMonthData[];
}

export interface ICustomTooltipProps {
	key: string;
	name: string;
	color: string;
}

export enum EDischargeTypes {
	GARBAGE_INCINERATED = "GARBAGE_INCINERATED",
	GARBAGE_DISCHARGED_INTO_SEA = "GARBAGE_DISCHARGED_INTO_SEA",
	GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS = "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
	ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA = "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA",
}

export enum EGarbageCategoryTypes {
	PLASTICS = "A",
	FOOD_WASTES = "B",
	DOMESTIC_WASTE = "C",
	COOKING_OIL = "D",
	INCINERATOR_ASHES = "E",
	OPERATIONAL_WASTES = "F",
	EWASTE = "I",
}

export interface IGarbageDishcargeMonthWiseData {
	month: number;
	amountDischarged: number;
}
export interface IGarbageDischargeAllVesselsRecord {
	dischargeCategory: EDischargeTypes;
	tenantId: string;
	monthWiseData: IGarbageDishcargeMonthWiseData[];
}

export interface IGarbageCategoryMonthWiseData {
	month: number;
	amountDischarged: number;
}
export interface IGarbageCategoryAllVesselsRecord {
	category: EGarbageCategoryTypes;
	label: string;
	tenantId: string;
	monthWiseData: IGarbageCategoryMonthWiseData[];
}

export interface IGarbageCategoryGraphTransformedData {
	name?: string;
	A: number;
	B: number;
	C: number;
	D: number;
	E: number;
	F: number;
	I: number;
}

export enum EBilgeWaterType {
	OWS_OVERBOARD = "OWS Overboard",
	TRANSFER_TO_SLOP = "Transfer to Slop",
}

export interface IBilgeWaterGraphFinalData {
	quarter: string;
	transferToSlop: number;
	owsOverboard: number;
}

export interface IGarbageDischargeGraphFinalData {
	quarter: string;
	disposedAshore: number;
	dischargedToSea: number;
	incinerated: number;
	accidentalRelease: number;
}

export interface IGarbageCategoryGraphFinalData {
	quarter: string;
	A: number;
	B: number;
	C: number;
	D: number;
	E: number;
	F: number;
	I: number;
}

export interface IORSGraphFinalData {
	quarter: string;
	incinerated: number;
	evaporated: number;
	boiler: number;
	transferToSlop: number;
	disposedAshore: number;
	total?: string;
}

export interface IReportCategoryType {
	title: string;
	url?: string;
	isDisabled?: boolean;
}

export interface IReportCategory {
	title: string;
	types: IReportCategoryType[];
}

export interface ICargoSlopGraphFinalData {
	quarter: string;
	totalDischarged: number;
}

export interface IOilSpillsInWaterGraphFinalData {
	quarter: string;
	totalDischarged: number;
}
