import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupD6 } from "../../../../../redux/app/annex6/groups/group-d";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupD6PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupD6Preview(props: Annex6GroupD6PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupD6;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "D", 8.4, renderItem8P4()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem8P4(): React.ReactNode {
		if (!record.item8P4.position) return <Annex6OldVersionPreview />;
		return (
			<>
				{record.item8P4.time} HOURS LT VESSEL EXIT FROM SECA, <br /> POSITION:{" "}
				{record.item8P4.position.latitude}, {record.item8P4.position.longitude}
			</>
		);
	}

	return renderMain();
}

export default Annex6GroupD6Preview;
