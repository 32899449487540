import { IStore } from "redux/store";
import { IDashboardStore } from "./@types";

const dashboardSelectors = {
	_getStore(dashboardStore: IStore) {
		return dashboardStore.app.dashboard;
	},
	getViewType(dashboardStore: IDashboardStore) {
		return dashboardStore.viewType;
	},
	getPagination(dashboardStore: IDashboardStore) {
		return dashboardStore._pagination;
	},
	getPaginationForAllVessels(dashboardStore: IDashboardStore) {
		return dashboardStore._paginationAll;
	},
	getIsDashboardPage(dashboardStore: IDashboardStore) {
		return dashboardStore.isDashboard;
	},
};

export default dashboardSelectors;
