import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import EngineMetricInsightGraph from "./_elements/engine/_elements/EngineMetricInsightGraph";
import useElementSize from "hooks/useElementSize";
import { EParameterTabType } from "redux/app/insight/@types";
import insightActions from "redux/app/insight/actions";
import Parameter from "./_elements/Parameter";
import insightSelectors from "redux/app/insight/selectors";
import { vesselActions } from "redux/app/vessel";
import NoonReportMetricInsightGraph from "./_elements/noon/_elements/NoonReportMetricInsightGraph";
import DateRangePickerVerbose from "components/_common/DateRangePickerVerbose";
import { insightDefaultStaticRanges } from "redux/app/insight/utils";
import { Range, RangeKeyDict } from "react-date-range";

type OwnProps = {};
type InsightProps = PropsFromRedux & OwnProps;
function Insight(props: InsightProps) {
	const { dateRange, metricKeys, vesselId, stateOfShip, currentTab } = props;
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const handleOnChange = (ranges: RangeKeyDict) => {
		const { selection } = ranges;
		props.setDateRange([selection]);
		if (metricKeys.length && vesselId)
			props.loadVesselSpecificInsights([selection]);
	};

	const reportContainerRef = useRef<HTMLDivElement>(null);

	const { width: reportContainerWidth } = useElementSize(reportContainerRef);

	useEffect(() => {
		props.loadVessels();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (vesselId && currentTab) {
			const type =
				currentTab === EParameterTabType.ENGINE_LOG_TAB
					? "engineLogConfig"
					: "dayLogConfig";
			props.loadConfig("logs", vesselId, type);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, currentTab]);

	useEffect(() => {
		if (metricKeys.length && vesselId) {
			props.loadVesselSpecificInsights();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metricKeys, vesselId, stateOfShip, currentTab]);

	return (
		<div className=" m-2 overflow-y-auto">
			<div className="flex justify-between space-x-2">
				<div className="rounded-lg w-1/4 h-[calc(100vh-98px)] bg-white shadow-lg overflow-y-auto">
					<Parameter />
				</div>
				<div
					ref={reportContainerRef}
					className="rounded-lg w-3/4 h-[calc(100vh-98px)] bg-white overflow-y-auto"
				>
					<div className="h-12 border-b-2 flex justify-between items-center px-2.5">
						<h3 className="font-medium">
							Graph -{" "}
							{currentTab === EParameterTabType.ENGINE_LOG_TAB
								? "Engine log"
								: "Noon report"}
						</h3>
						<div className="min-w-[204px] flex items-center feedback">
							<DateRangePickerVerbose
								handleOnChange={handleOnChange}
								dateRange={dateRange}
								onClose={() => setIsDatePickerOpen(false)}
								handleOpen={() => setIsDatePickerOpen(true)}
								isOpen={isDatePickerOpen}
								staticRanges={insightDefaultStaticRanges}
							/>
						</div>
					</div>
					{currentTab === EParameterTabType.ENGINE_LOG_TAB ? (
						<EngineMetricInsightGraph
							width={reportContainerWidth}
							dateRange={dateRange[0]}
						/>
					) : (
						<NoonReportMetricInsightGraph
							width={reportContainerWidth}
							dateRange={dateRange[0]}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const insightStore = insightSelectors._getStore(store);

	const dateRange = insightSelectors.getDateRange(insightStore);
	const vesselId = insightSelectors.getVesselId(insightStore);
	const stateOfShip = insightSelectors.getStateOfShip(insightStore);
	const currentTab = insightSelectors.getParameterCurrentTab(insightStore);
	const metricKeys =
		currentTab === EParameterTabType.ENGINE_LOG_TAB
			? insightSelectors.getEngineLogSelectedMetrics(insightStore)
			: insightSelectors.getNoonReportSelectedMetrics(insightStore);
	return {
		dateRange,
		metricKeys,
		vesselId,
		stateOfShip,
		currentTab,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadConfig(category: string, vesselId: string, type: string) {
			dispatch(
				insightActions.commands.insightConfigLoad(category, vesselId, type)
			);
		},
		loadVesselSpecificInsights(dateRange?: Range[]) {
			dispatch(insightActions.commands.vesselSpecificInsightsLoad(dateRange));
		},
		setDateRange(dateRange: Range[]) {
			dispatch(insightActions.document.insightDateRangeSet(dateRange));
		},
		setActiveMetrics(activeMetrics: string[]) {
			dispatch(
				insightActions.document.engineLogActiveSelectedMetricsSet(activeMetrics)
			);
		},
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		setCurrentTab(tab: EParameterTabType) {
			dispatch(insightActions.document.setInsightParameterCurrentTab(tab));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Insight);
