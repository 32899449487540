import ModalPortal from "components/app/_common/ModalPortal";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";
import { IStore } from "redux/store";
import FilterContent from "./_elements/FilterContent";

type OwnProps = {};
type FeedbackFiltersProps = PropsFromRedux & OwnProps;
function FeedbackFilters(props: FeedbackFiltersProps) {
	const { isFeedbackFiltersModalOpen } = props;
	return (
		<ModalPortal
			isActive={isFeedbackFiltersModalOpen}
			onClose={() => {
				props.toggleIsFeedbackFiltersModal();
			}}
			width={400}
		>
			<div className="h-full max-h-[calc(90vh)] my-12  overflow-y-auto bg-white rounded-lg font-redhat-text">
				<FilterContent />
			</div>
		</ModalPortal>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const isFeedbackFiltersModalOpen =
		feedbackSelectors.getIsFeedbackFiltersModalOpen(feedbackStore);
	return {
		isFeedbackFiltersModalOpen,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleIsFeedbackFiltersModal() {
			dispatch(feedbackActions.document.toggleFeedbackFiltersModal());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackFilters);
