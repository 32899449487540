import ModalPortal from "components/app/_common/ModalPortal";
import React, { useState } from "react";
import {
	DateRangePicker,
	RangeKeyDict,
	Range,
	StaticRange,
} from "react-date-range";
import { commonUtils } from "redux/_common";
const { getVerboseFormattedDate } = commonUtils;

type OwnProps = {
	handleOpen: () => void;
	onClose: () => void;
	isOpen: boolean;
	handleOnChange: (rangesByKey: RangeKeyDict) => void;
	dateRange: Range[];
	staticRanges?: StaticRange[];
};
type DateRangePickerVerboseProps = OwnProps;
function DateRangePickerVerbose(props: DateRangePickerVerboseProps) {
	const {
		dateRange,
		handleOnChange,
		handleOpen,
		isOpen,
		onClose,
		staticRanges = [],
	} = props;

	const [focusRange, setFocusRange] = useState<any>([0, 0]);
	const formattedDate = getVerboseFormattedDate(dateRange[0], staticRanges);
	return (
		<>
			<div className="relative w-full">
				<div
					onClick={() => handleOpen()}
					className="  p-1.5 py-2 shadow rounded-md text-sm text-gray-700 flex items-center justify-between cursor-pointer min-w-48"
				>
					<div className="flex items-center">
						<span className="bp3-icon bp3-icon-calendar text-gray-700 mr-2 " />
						<span className="mr-6 font-medium">{formattedDate}</span>
					</div>
					<span className="bp3-icon bp3-icon-chevron-down text-gray-700 font-medium" />
				</div>

				<ModalPortal isActive={isOpen} onClose={() => onClose()}>
					<div className="flex justify-center rounded">
						<DateRangePicker
							dateDisplayFormat="dd-MM-yyyy"
							className="border shadow-xl rounded feedback"
							staticRanges={staticRanges}
							renderStaticRangeLabel={(range) => (
								<div className="w-full py-2.5 px-5" onClick={() => onClose()}>
									{range.label}
								</div>
							)}
							inputRanges={[]}
							onChange={(rangesByKey) => {
								handleOnChange(rangesByKey);
								// INFO: Below is the case when user selects the left side predefined options
								if (focusRange.reduce((s: number, c: number) => s + c, 0))
									onClose();
							}}
							onRangeFocusChange={(newFocusedRange) => {
								setFocusRange(newFocusedRange);
							}}
							focusedRange={focusRange}
							key={"selection"}
							ranges={dateRange}
						/>
					</div>
				</ModalPortal>
			</div>
		</>
	);
}

export default DateRangePickerVerbose;
