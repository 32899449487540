import cuid from "cuid";
import moment from "moment";
import { createReducer } from "typesafe-actions";
import withJobs from "../../_core/job/reducers";
import { EGRB2State, IGRB2NewOperationState, IGRB2Store } from "./@types";
import grb2Actions, { grb2Constants, TGRB2Actions } from "./actions";
import grb2Utils from "./utils";

const defaultNewOperationState: IGRB2NewOperationState =
	grb2Utils.getDefaultOperationData();

const defaultState: IGRB2Store = {
	records: {
		byIds: {},
		ids: [],
	},
	newOperation: defaultNewOperationState,
	filter: {
		startDate: moment().subtract(7, "days").toDate(),
		endDate: moment().add(1, "day").toDate(),
		shownRecords: "ALL",
	},
	garbageQuestionsState: "NOT-REQUIRED",
	garbageDischargedIntoSeaQuestions: [],
	_forms: {},
	_jobs: {},
};
const grb2Reducers = createReducer<IGRB2Store, TGRB2Actions>(defaultState)
	.handleAction(
		grb2Actions.document.basicFormEdit,
		function basicFormEdit(state, action) {
			return {
				...state,
				newOperation: {
					...state.newOperation,
					garbageBasicInfo: {
						...state.newOperation.garbageBasicInfo,
						...action.payload.basicGarbageForm,
					},
				},
			};
		}
	)
	.handleAction(
		grb2Actions.document.dischargeEntryFormEdit,
		function dischargeEntryFormEdit(state, action) {
			const newDataForEntry = action.payload.dischargeEntryBasedForm;
			switch (newDataForEntry.entry) {
				case "GARBAGE_DISCHARGED_INTO_SEA":
					return {
						...state,
						newOperation: {
							...state.newOperation,
							garbageDischargedIntoSea: {
								...state.newOperation.garbageDischargedIntoSea,
								...newDataForEntry,
							},
						},
					};
				case "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA":
					return {
						...state,
						newOperation: {
							...state.newOperation,
							garbageAccidentalLoss: {
								...state.newOperation.garbageAccidentalLoss,
								...newDataForEntry,
							},
						},
					};
				case "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS":
					return {
						...state,
						newOperation: {
							...state.newOperation,
							garbageDischargedToReceptionAshoreOrOtherShips: {
								...state.newOperation
									.garbageDischargedToReceptionAshoreOrOtherShips,
								...newDataForEntry,
							},
						},
					};
			}
		}
	)
	.handleAction(
		grb2Actions.document.newOperationStateSet,
		function newOperationStateSet(state, action) {
			return {
				...state,
				newOperation: {
					...state.newOperation,
					newOperationState: action.payload.state,
				},
			};
		}
	)
	.handleAction(grb2Actions.document.formReset, function formReset(state) {
		return {
			...state,
			newOperation: defaultNewOperationState,
		};
	})
	.handleAction(
		grb2Actions.document.historySet,
		function historySet(state, action) {
			return {
				...state,
				records: action.payload.history,
			};
		}
	)
	.handleAction(
		grb2Actions.document.grb2StateSet,
		function grb2StateSet(state, action) {
			const { state: grb2State, grb2Id } = action.payload;
			return {
				...state,
				records: {
					...state.records,
					byIds: {
						...state.records.byIds,
						[grb2Id]: {
							...state.records.byIds[grb2Id],
							state: grb2State,
						},
					},
				},
			};
		}
	)
	.handleAction(
		grb2Actions.document.editLogsEdit,
		function editLogsEdit(state, action) {
			const { editSession, edits } = action.payload;
			if (editSession === 0) return state;
			const editsForGivenSession = state.newOperation.garbageBasicInfo.editLogs[
				editSession
			]
				? state.newOperation.garbageBasicInfo.editLogs[editSession]
				: {
						byIds: {},
						ids: [],
				  };
			const newEdits = {
				...editsForGivenSession,
				byIds: {
					...editsForGivenSession.byIds,
					[edits.fieldName]: edits,
				},
			};
			newEdits.ids = Object.keys(newEdits.byIds);
			return {
				...state,
				newOperation: {
					...state.newOperation,
					garbageBasicInfo: {
						...state.newOperation.garbageBasicInfo,
						editLogs: {
							...state.newOperation.garbageBasicInfo.editLogs,
							[editSession]: {
								...newEdits,
							},
						},
					},
				},
			};
		}
	)
	.handleAction(
		grb2Actions.document.newOperationSet,
		function newOperationSet(state, action) {
			const { grb2 } = action.payload;
			return {
				...state,
				newOperation: grb2Utils.getOperationToSubmit(grb2),
			};
		}
	)
	.handleAction(
		grb2Actions.document.newOperationSetForEdit,
		function newOperationSetForEdit(state, action) {
			const { grb2 } = action.payload;
			return {
				...state,
				newOperation: grb2Utils.getOperationForEdit(grb2),
			};
		}
	)
	.handleAction(
		grb2Actions.document.filterSet,
		function filterSet(state, action) {
			return {
				...state,
				filter: action.payload.filter,
			};
		}
	)
	.handleAction(
		grb2Actions.document.garbageDischargedIntoSeaQuestionStateSet,
		function garbageDischargedIntoSeaQuestionStateSet(state, { payload }) {
			const { dischargedIntoSeaQuestionState } = payload;
			if (dischargedIntoSeaQuestionState === "NOT-REQUIRED")
				return {
					...state,
					garbageQuestionsState: payload.dischargedIntoSeaQuestionState,
					garbageDischargedIntoSeaQuestions: [],
				};
			return {
				...state,
				garbageQuestionsState: payload.dischargedIntoSeaQuestionState,
			};
		}
	)
	.handleAction(
		grb2Actions.document.garbageDischargedIntoSeaQuestionSet,
		function garbageDischargedIntoSeaQuestionSet(state, action) {
			return {
				...state,
				garbageDischargedIntoSeaQuestions: action.payload.questions,
			};
		}
	);
export default withJobs<IGRB2Store>(
	grb2Constants.FEATURE,
	defaultState,
	grb2Reducers
);
