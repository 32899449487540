import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import InputWrapper from "components/_common/InputWrapper";
import Dropdown from "components/_common/Dropdown";
import { vesselSelectors } from "redux/app/vessel";
import InfiniteProgress from "components/_common/InfiniteProgress";

type OwnProps = {
	isLoading?: boolean;
	showDropdownLabel?: boolean;
	selectedVesselId?: string;
	onVesselSelect?(vesselId: string): void;
};
type VesselSelectorProps = PropsFromRedux & OwnProps;
function VesselSelector(props: VesselSelectorProps) {
	const {
		isLoading = false,
		showDropdownLabel = false,
		miniVessels,
		selectedVesselId = "",
	} = props;
	return (
		<div className="">
			<div>
				<InputWrapper
					className="w-full py-0 my-0"
					hasError={false}
					hasWarning={false}
					isLoading={isLoading}
				>
					{showDropdownLabel && (
						<label className="ws-input__label">Select a vessel</label>
					)}
					<Dropdown
						inputWrapperProps={{}}
						id="vessel-list-id"
						disabled={isLoading}
						options={[
							{
								label: "All Vessels",
								value: "",
							},
							...miniVessels.ids.map((id) => {
								return {
									...miniVessels.byIds[id],
									label: miniVessels.byIds[id].vesselName,
									value: miniVessels.byIds[id].id,
								};
							}),
						]}
						value={selectedVesselId}
						placeholder="Select Vessel"
						onSelect={function onVesselChange(e) {
							if (e) {
								if (props.onVesselSelect) props.onVesselSelect(e.value);
							}
						}}
						renderView={function renderView(selectedItem) {
							return (
								<span className="whitespace-no-wrap w-42 bp3-text-overflow-ellipsis">
									{selectedItem.label}&nbsp;
								</span>
							);
						}}
						noFilter={true}
					/>
					<span className="ws-input__error">Hello Error</span>
					<span className="ws-input__warning">Hello Warning</span>
					<InfiniteProgress
						className="ws-input__progress"
						isLoading={true}
						isSmall={true}
						isSpacedOut={false}
					/>
				</InputWrapper>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = vesselSelectors._getStore(store);

	return {
		miniVessels: vesselSelectors.getMiniVessels(
			vesselSelectors.getVessels(vesselStore)
		),
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(VesselSelector);
