import React from "react";
import VesselOnboardDevice from "../../../../../components/app/vessel/onboard/device/Device";

type ScreenVesselOnboardDeviceType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
		};
	};
}>;
const ScreenVesselOnboardDevice: React.FC<ScreenVesselOnboardDeviceType> = (
	props: ScreenVesselOnboardDeviceType
) => {
	return <VesselOnboardDevice vesselId={props.match.params.vesselId} />;
};

export default ScreenVesselOnboardDevice;
