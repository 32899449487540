import React from "react";
import VesselOnboardDashboard from "../../../../../components/app/vessel/onboard/dashboard/Dashboard";

type ScreenVesselOnboardDashboardType = React.PropsWithChildren<{
	match: {
		params: {
			vesselId: string;
		};
	};
}>;

const ScreenVesselOnboardDashboard: React.FC<
	ScreenVesselOnboardDashboardType
> = (props: ScreenVesselOnboardDashboardType) => {
	return <VesselOnboardDashboard vesselId={props.match.params.vesselId} />;
};

export default ScreenVesselOnboardDashboard;
