import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4O3 } from "../../../../../redux/app/orb4/groups/orb4-o";
import AttachmentsPreview from "../../_elements/AttachmentsPreview";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4O3PreviewProps = OwnProps;
function getAdditionalProceduresAndRemarks(orb4Record: TORB4O3) {
	let content = orb4Record.itemO1.generalRemarks + "\n";
	if (orb4Record.itemO2.tanks.length > 0) {
		orb4Record.itemO2.tanks.forEach((tank) => {
			content += `, Total quantity retained in ${tank.selectedTank.tankName} is ${tank.newQuantity} M³`;
		});
		return content;
	} else {
		return content;
	}
}
function ORB4O3Preview(props: ORB4O3PreviewProps) {
	const orb4Record = props.orb4Record as TORB4O3;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "O", "-", renderItemO1()],
					[null, null, "-", renderItemO2()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItemO1() {
		return (
			<h4 className="font-medium uppercase">
				{/* {orb4Record.item1.generalRemarks} */}
				{getAdditionalProceduresAndRemarks(orb4Record)}
			</h4>
		);
	}
	function renderItemO2() {
		if (orb4Record.itemO3.receiptAttachment === "") return null;
		return (
			<h4 className="font-medium uppercase">
				<AttachmentsPreview
					{...orb4Record.itemO3}
					_rev={orb4Record._rev}
					id={orb4Record.id}
				/>
			</h4>
		);
	}

	return renderMain();
}

export default ORB4O3Preview;
