import { Middleware } from "redux";
import { IStore } from "redux/store";
import {
	IDashboardUsageAndAlerts,
	ITimeLog,
	IVesselOnboard_DashboardUsabilityAlertsLoad__Action,
} from "../types";
import vesselOnboardActionTypes from "../action-types";
import actions from "./../actions";
import { apiActions } from "redux/_core/api";
import vesselOnboardAPIs from "../apis";

const { apiRequest } = apiActions;

const {
	command: { WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD, GET_TIME_LOGS },
} = vesselOnboardActionTypes;

const onboardCrewMiddleware: Middleware<any, IStore> =
	({ dispatch, getState }) =>
	(next) =>
	(action: IVesselOnboard_DashboardUsabilityAlertsLoad__Action) => {
		switch (action.type) {
			case GET_TIME_LOGS: {
				next(action);
				const { vesselId, page } = (
					action as any
				).payload;
				const job = actions._jobs.GET_TIME_LOGS;
				dispatch(
					apiRequest<ITimeLog[]>({
						...vesselOnboardAPIs.loadTimeLogs(vesselId, page),
						preExecute() {
							dispatch(
								job.active({
									message: "Loading time logs.",
									// notification: {},
								})
							);
						},
						postExecute: {
							onSuccess(response) {
								const data = response.data;
								console.log(response);
								if (data) {
									if (page === 1) {
										dispatch(
											actions.document.setTimeLogs(data)
										);
									} else {
										dispatch(
											actions.document.appendTimeLogs(data)
										);
									}
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Server error while loading time logs.",
										notification: { timeout: 10000 },
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD: {
				next(action);
				const { vesselId,fromDate,toDate } = (
					action as IVesselOnboard_DashboardUsabilityAlertsLoad__Action
				).payload;
				const job = actions._jobs.WIZARD_DASHBOARD_USABILITY_ALERTS_LOAD;
				dispatch(
					apiRequest<IDashboardUsageAndAlerts>({
						...vesselOnboardAPIs.loadUsabilityAndAlerts(vesselId,fromDate,toDate),
						preExecute() {
							dispatch(
								job.active({
									// message: "Loading Usage & Alerts data",
									// notification: {},
								})
							);
						},
						postExecute: {
							onSuccess(response) {
								const data = response.data;
								if (data) {
									dispatch(
										actions.document.dashboardUsageAndAlertsSet({ ...data })
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Server error while loading usage & alerts data",
										notification: { timeout: 10000 },
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			default: {
				next(action);
			}
		}
	};

export default onboardCrewMiddleware;
