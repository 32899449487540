import { Middleware, Action } from "redux";
import actionTypes from "./action-types";
import { batch } from "react-redux";

const { MULTI_BATCH_ACTION, MULTI_DISPATCH_ACTION } = actionTypes;
const multiActionMiddleware: Middleware = ({ dispatch }) => (next) => (
	action
) => {
	switch (action.type) {
		case MULTI_DISPATCH_ACTION: {
			next(action);
			const results = action.payload.actions.map((actionItem: Action) =>
				dispatch(actionItem)
			);
			return results;
		}
		case MULTI_BATCH_ACTION: {
			next(action);
			const results = batch(() =>
				action.payload.actions.map((actionItem: Action) => dispatch(actionItem))
			);
			return results;
		}
		default:
			next(action);
	}
};
export default multiActionMiddleware;
