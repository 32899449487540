import classNames from "classnames";
import InfiniteProgress from "components/_common/InfiniteProgress";
import NumberedPagination from "components/_common/NumberedPagination";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IListObjectStore } from "redux/_common/type";
import { TTableHeader } from "redux/app/dashboard/@types";
import dashboardActions from "redux/app/dashboard/actions";
import dashboardConstants, {
	dashboardTableHeaders,
} from "redux/app/dashboard/constants";
import dashboardSelectors from "redux/app/dashboard/selectors";
import { transformVesselsDataForTable } from "redux/app/dashboard/utils";
import { vesselSelectors } from "redux/app/vessel";
import { IVessel } from "redux/app/vessel/types";
import { IStore } from "redux/store";
import CellRenderer from "./CellRenderer";
import ExpandedCellRenderer from "./ExpandedCellRenderer";
import { TAB_IDS } from "redux/app/vessel/constants";
const { ITEMS_PER_PAGE } = dashboardConstants;
const headers = dashboardTableHeaders;

export interface FCellRenderer {
	value: any;
	columnDescriptor: TTableHeader;
	isRowForColumnExpanded?: boolean;
	id: string;
	index: number;
	setRowExpander?: (rowId: string) => void;
}

type OwnProps = {
	vessels: IListObjectStore<IVessel>;
};
type TableProps = PropsFromRedux & OwnProps;
function Table(props: TableProps) {
	const { vessels, paginationOfVessels, areVesselsLoading, selectedTabId } =
		props;
	const [expander, setExpander] = useState<Record<string, boolean>>({});

	const [currentSelectedRowIndex, setCurrentSelectedRowIndex] = useState(0);

	const tableData = transformVesselsDataForTable(vessels);

	useEffect(() => {
		// props.goToNewPage(1, selectedTabId as TAB_IDS);
		setCurrentSelectedRowIndex(0);
		setExpander({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabId]);

	useEffect(() => {
		if (currentSelectedRowIndex) {
			const elementToScrollTo = document.getElementById(
				`row-${currentSelectedRowIndex - 1}`
			);

			elementToScrollTo?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [currentSelectedRowIndex]);

	const setRowExpander = (rowId: string) => {
		if (expander[rowId]) {
			setExpander((state) => ({ ...state, [rowId]: false }));
		} else {
			setExpander((state) => {
				const stateWithOtherRowIdsSetToFalse = Object.keys(state).reduce(
					(acc, rowId) => {
						acc[rowId] = false;
						return acc;
					},
					{} as typeof state
				);
				return {
					...stateWithOtherRowIdsSetToFalse,
					[rowId]: true,
				};
			});
		}
	};

	return (
		<>
			<div className="p-4">
				<div
					id="table-container"
					// max-h-[394px] to fix the table height
					className="pt-0 mt-0 overflow-y-auto border-gray-400 "
				>
					<InfiniteProgress
						isLoading={areVesselsLoading}
						isSpacedOut={false}
					/>
					<table
						className={classNames(
							"w-full  table-auto  font-redhat-text  overflow-auto  border-separate border-spacing-0 overflow-x-auto relative",
							{
								"border-[0.5px]": tableData.length === 0,
							}
						)}
					>
						<thead className="sticky -mt-0 -top-4">
							<tr>
								{headers.map((header, index) => {
									return (
										<th
											key={header.key}
											style={{ width: header.width }}
											className={classNames(
												"px-3 py-3 text-[12px] md:text-sm border-[0.8px] font-medium leading-4 tracking-wide text-left text-gray-700 bg-gray-100 border-b border-gray-300 sticky -mt-4 top-0 "
											)}
										>
											{header.title}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{tableData.length ? (
								tableData
									.slice(
										(paginationOfVessels.currentPage - 1) *
											ITEMS_PER_PAGE,
										paginationOfVessels.currentPage *
											ITEMS_PER_PAGE
									)
									?.map((row, currentRowIndex) => {
										const {
											id,
											name,
											fromTo,
											alerts,
											usage,
										} = row;
										const orderOfColumns = [
											name,
											fromTo,
											alerts,
											usage,
										];
										return (
											<Fragment key={id}>
												<tr
													id={`row-${currentRowIndex}`}
													onClick={() => {
														setRowExpander(id);
														setCurrentSelectedRowIndex(
															currentRowIndex
														);
													}}
													className="cursor-pointer hover:bg-gray-200"
												>
													{orderOfColumns.map(
														(column, index) => {
															return (
																<CellRenderer
																	key={
																		"cell" +
																		id +
																		index
																	}
																	value={
																		column
																	}
																	columnDescriptor={
																		headers[
																			index
																		]
																	}
																	id={id}
																	index={
																		index
																	}
																	isRowForColumnExpanded={
																		expander[
																			id
																		]
																	}
																	setRowExpander={
																		setRowExpander
																	}
																/>
															);
														}
													)}
												</tr>
												{expander[id] && (
													<tr id={`expanded-${id}`}>
														{orderOfColumns.map(
															(column, index) => {
																return (
																	<ExpandedCellRenderer
																		key={
																			"exp-cell" +
																			id +
																			index
																		}
																		value={
																			column
																		}
																		columnDescriptor={
																			headers[
																				index
																			]
																		}
																		id={id}
																		index={
																			index
																		}
																		isRowForColumnExpanded={
																			expander[
																				id
																			]
																		}
																	/>
																);
															}
														)}
													</tr>
												)}
											</Fragment>
										);
									})
							) : (
								<tr>
									<td colSpan={4}>
										<div className="flex justify-center py-12">
											No data found
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<NumberedPagination
					{...{
						...paginationOfVessels,
						totalItems: paginationOfVessels.totalItems || 0,
					}}
					itemIds={[]}
					onPageChange={function onPageChange(
						pageNumberFromPagination
					) {
						props.goToNewPage(
							pageNumberFromPagination,
							selectedTabId as TAB_IDS
						);
					}}
					isPageLoading={areVesselsLoading}
				/>
			</div>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = store.app.vesselStore;

	const dashboardStore = dashboardSelectors._getStore(store);

	const paginationOfVessels = dashboardSelectors.getPagination(dashboardStore);
	const paginationOfAllVessels =
		dashboardSelectors.getPaginationForAllVessels(dashboardStore);
	const jobToLoadMyVessels = vesselSelectors.jobToLoadMyVessels(vesselStore);
	const jobToLoadAllVessels = vesselSelectors.jobToLoadAllVessels(vesselStore);
	const selectedTab = vesselSelectors.getSelectedTab(vesselStore);
	return {
		paginationOfVessels:
			selectedTab === TAB_IDS.all
				? { ...paginationOfAllVessels }
				: { ...paginationOfVessels },

		areVesselsLoading:
			jobToLoadAllVessels.isLoading || jobToLoadMyVessels.isLoading,
		selectedTabId: selectedTab,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToNewPage(pageNumber: number, selectedTab: TAB_IDS) {
			if (selectedTab === TAB_IDS.all) {
				dispatch(
					dashboardActions.document.paginationAllVesselsCurrentPageSet(
						pageNumber
					)
				);
			} else if (selectedTab === TAB_IDS.myVessels) {
				dispatch(
					dashboardActions.document.paginationCurrentPageSet(pageNumber)
				);
			}
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Table);
