import { Tab, Tabs } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { TConfigType } from "redux/app/tool/@types";
import toolActions from "redux/app/tool/actions";
import { CONFIG_TABS } from "redux/app/tool/constants";
import toolSelectors from "redux/app/tool/selectors";
import { IStore } from "redux/store";

import LogTable from "./_elements/LogTable";
import ChecklistTable from "./_elements/ChecklistTable";
import PermitsTable from "./_elements/PermitsTable";
import { commonUtils } from "redux/_common";

type OwnProps = {
	children: React.ReactNode;
};
type ConfigProps = PropsFromRedux & OwnProps;
function Config(props: ConfigProps) {
	const {
		activeTab,
		category,
		selectedVesselId,
		selectedItems,
		jobToLoadConfig,
	} = props;

	useEffect(() => {
		if (selectedVesselId) props.loadConfigList(category, selectedVesselId);
		else props.emptyConfigListForAllCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category, selectedVesselId]);

	useEffect(() => {
		return () => {
			props.setLogConfigListEmpty();
			props.setChecklistsConfigListEmpty();
			props.setPermitsConfigListEmpty();
			props.setActionConfigId("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="px-4 border-b border-[#D8D8D8] mt-2">
				<Tabs
					id="config-tabs"
					animate={true}
					renderActiveTabPanelOnly={true}
					vertical={false}
					large={true}
					selectedTabId={activeTab}
					className="w-full rounded-sm bp3-html-table bp3-interactive overflow-x-auto"
					onChange={function onTabsChange(tabId) {
						props.handleTabChange(tabId as CONFIG_TABS);
					}}
				>
					<Tab
						id={CONFIG_TABS.TAB_LOGS}
						title={
							undefined
							// <span className="mr-3 text-lg bp3-icon bp3-icon-dashboard" />
						}
						disabled={!!selectedItems.length || jobToLoadConfig.isLoading}
						className="font-medium text-gray-600 "
					>
						Logs
					</Tab>
					<Tab
						id={CONFIG_TABS.TAB_CHECKLISTS}
						title={
							undefined
							// <span className="mr-3 text-lg bp3-icon bp3-icon-person" />
						}
						disabled={!!selectedItems.length || jobToLoadConfig.isLoading}
						className="font-medium text-gray-600 "
					>
						Checklists
					</Tab>
					<Tab
						id={CONFIG_TABS.TAB_PERMITS}
						title={
							undefined
							// <span className="mr-3 text-lg bp3-icon bp3-icon-mobile-phone" />
						}
						disabled={!!selectedItems.length || jobToLoadConfig.isLoading}
						panelClassName=""
						className="font-medium text-gray-600 "
					>
						Permits
					</Tab>

					<Tabs.Expander />
				</Tabs>
			</div>
			<div className="mx-4">{props.children}</div>
			{/* TODO: We can make single table for log, checklist & permits by passing props  
				Reason for creating separate tables, is possiblity of having different columns for each of them.
			*/}
			{activeTab === CONFIG_TABS.TAB_LOGS && (
				<div className="px-4 my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<LogTable />
				</div>
			)}
			{activeTab === CONFIG_TABS.TAB_CHECKLISTS && (
				<div className="px-4 my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<ChecklistTable />
				</div>
			)}
			{activeTab === CONFIG_TABS.TAB_PERMITS && (
				<div className="px-4 my-4 overflow-y-auto bg-white h-[calc(100vh-190px)]">
					<PermitsTable />
				</div>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const activeTab = toolSelectors.getActiveTabForConfig(toolStore);

	const category = toolSelectors.getActiveTabForConfig(toolStore);
	const selectedItems = toolSelectors.getSelectedItems(toolStore);
	const selectedVesselId =
		toolSelectors.getSelectedVesselOption(toolStore)?.value;

	const jobToLoadConfig = toolSelectors.jobToLoadConfig(toolStore);

	return {
		activeTab,
		category,
		selectedVesselId,
		selectedItems,
		jobToLoadConfig,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		handleTabChange(activeTab: CONFIG_TABS) {
			dispatch(toolActions.document.toolConfigTabSet(activeTab));
		},
		loadConfigList(category: TConfigType, vesselId?: string) {
			dispatch(toolActions.commands.toolConfigListLoad(category, vesselId));
		},
		emptyConfigListForAllCategory() {
			dispatch(toolActions.commands.toolConfigListEmptyForAllCategory());
		},
		setLogConfigListEmpty() {
			dispatch(
				toolActions.document.toolConfigLogsListSet(
					commonUtils.arrayToObject([])
				)
			);
		},
		setChecklistsConfigListEmpty() {
			dispatch(
				toolActions.document.toolConfigChecklistsListSet(
					commonUtils.arrayToObject([])
				)
			);
		},
		setPermitsConfigListEmpty() {
			dispatch(
				toolActions.document.toolConfigPermitsListSet(
					commonUtils.arrayToObject([])
				)
			);
		},
		setActionConfigId(configId: string) {
			dispatch(toolActions.document.toolConfigIdOnActionSelect(configId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Config);
