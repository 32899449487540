import moment from "moment";
import React from "react";
import { TORB4Record } from "../../../../../redux/app/orb4/@types";
import { TORB4A1 } from "../../../../../redux/app/orb4/groups/orb4-a";
import { commonUtils } from "redux/_common";
import ORB4TableRows from "../ORB4TableRows";

type OwnProps = {
	orb4Record: TORB4Record;
	isStrikedOff?: boolean;
};
type ORB4A1PreviewProps = OwnProps;

function ORB4A1Preview(props: ORB4A1PreviewProps) {
	const orb4Record = props.orb4Record as TORB4A1;
	const operationDate = moment(orb4Record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<ORB4TableRows
				record={orb4Record}
				tableRows={[
					[operationDate, "A", 1, renderItem1()],
					[null, null, 2, renderItem2()],
					[null, null, 3, renderItem3()],
					[null, null, "NOTE", orb4Record.remarks],
				]}
			/>
		);
	}

	function renderItem1() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item1.placeOfLoading}
			</h4>
		);
	}
	function renderItem2() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item2.typeOfOilLoaded} -{" "}
				{orb4Record.item2.tanks.map(
					(tank) => `${tank.selectedTank.tankName}, `
				)}
			</h4>
		);
	}
	function renderItem3() {
		return (
			<h4 className="font-medium uppercase">
				{orb4Record.item3P1 ? (
					<>
						TOTAL QUANTITY OF OIL LOADED{" "}
						{orb4Record.item3P2.totalQuantityLoaded.toFixed(2)}
						M³ at 15°C temperature <br />
						TOTAL CONTENTS OF TANK{" "}
						{orb4Record.item3P2.totalContentsOfTank.toFixed(2)}
						M³
					</>
				) : (
					<>
						QUANTITY LOADED{" "}
						{commonUtils
							.addUp(
								// @ts-ignore
								orb4Record.item3.totalQuantityLoaded,
								orb4Record.item3.totalQuantityInPipes
									? orb4Record.item3.totalQuantityInPipes
									: 0
							)
							.toFixed(2)}
						M³ at 15°C temperature <br />
						TOTAL CONTENTS OF TANK{" "}
						{
							// @ts-ignore
							orb4Record.item3.totalContentsOfTank.toFixed(2)
						}
						M³
					</>
				)}
			</h4>
		);
	}

	return renderMain();
}

export default ORB4A1Preview;
