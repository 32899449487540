import moment from "moment";
import {
	EDashboardAlertsStatus,
	EDashboardUsabilityStatus,
} from "redux/app/vessel/onboard/types";

export function getStatusValueForUsageObj(obj: any, generatedDates: string[]) {
	const otherColumns = {} as any;

	generatedDates.forEach((date, index) => {
		if (date in obj) {
			const { state, ...restInfo } = obj[date];
			otherColumns[`col${index + 1}`] = Object.keys(restInfo).length
				? { value: state, ...restInfo }
				: { value: state };
		} else {
			otherColumns[`col${index + 1}`] = {
				value: EDashboardUsabilityStatus.NOT_DONE,
			};
		}
	});
	return otherColumns;
}

export function getStatusValueForAlertsObject(
	obj: any,
	generatedDates: string[]
) {
	const otherColumns = {} as any;
	generatedDates.forEach((date, index) => {
		if (date in obj) {
			const { level, ...restInfo } = obj[date];
			otherColumns[`col${index + 1}`] = Object.keys(restInfo).length
				? { value: level, ...restInfo }
				: { value: level };
		} else {
			otherColumns[`col${index + 1}`] = {
				value: EDashboardAlertsStatus.ALL_GOOD,
			};
		}
	});
	return otherColumns;
}

export function generateHeaderRow(
	fromDate: string = moment().subtract(6, "d").toISOString(),
	toDate: string = moment().toISOString()
) {
	const datesList: string[] = [];
	if (fromDate && toDate) {
		const nextDate = moment(fromDate).clone();
		while (nextDate.isSameOrBefore(toDate)) {
			let formattedDateStr = new Date(
				nextDate.toISOString().split("T")[0]
			).toISOString();
			nextDate.add(1, "days");
			datesList.push(formattedDateStr);
		}
	}
	return datesList;
}
