import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import Annex6TableRows from "../Annex6TableRows";
import { TAnnex6GroupF2 } from "redux/app/annex6/groups/group-f";


type OwnProps = {
    annex6Record: TAnnex6Record;
    isStrikedOff?: boolean;
};
type Annex6GroupF2PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupF2Preview(props: Annex6GroupF2PreviewProps) {
    const record = props.annex6Record as TAnnex6GroupF2;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'F', 12.1, renderItem12P1()],
                    [null, null, 12.2, renderItem12P2()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem12P1() {
        return (
            <h4 className="font-medium uppercase">
                Exit from NECA (Tier III) area on{' '}
                {record.item12P1.exitFromNECA.date},
                {record.item12P1.exitFromNECA.time} at{' '}
                {record.item12P1.exitPosition.latitude},
                {record.item12P1.exitPosition.longitude}
            </h4>
        );
    }

    function renderItem12P2() {
        return (
            <h4 className="font-medium uppercase">
                Status on/off of all engines adn their SCR/EGR equipment:{' '}
                {record.item12P2.status}
            </h4>
        );
    }

    return renderMain();
}


export default Annex6GroupF2Preview;