import classNames from "classnames";
import moment from "moment";
import React from "react";
import {
	EBallastRecordState,
	TBallastRecord,
} from "../../../../../redux/app/ballast/@types";
import {
	TBallast_Additional,
	TBallast_Additional_MissedEntry,
} from "../../../../../redux/app/ballast/groups/additional";
import { commonUtils } from "../../../../../redux/_common";
import CommaSeparator from "../../../../_common/CommaSeparator";
import { marpolUtils } from "redux/app/marpol";
import BallastPreviewer from "../BallastPreviewer";
import BallastTableRows, { BallastTableColumns } from "../BallastTableRows";

type OwnProps = {
	ballastRecord: TBallastRecord;
	isStrikedOff?: boolean;
};
type AdditionalMissedEntryPreviewProps = OwnProps;
function AdditionalMissedEntryPreview(
	props: AdditionalMissedEntryPreviewProps
) {
	const record = props.ballastRecord as TBallast_Additional_MissedEntry;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);
	const dateOfOriginalOperation = moment(
		record.itemMissedEntry.dateOfOriginalOperation,
		"YYYY-MM-DD"
	).format("DD-MMM-YYYY");

	function renderMain() {
		return (
			<>
				<BallastTableRows
					record={record}
					tableRows={[
						[dateOfOriginalOperation, "-", "-", renderItemMain()],
						[...renderMissedPlaceholder()],
						[null, null, "NOTE", record.remarks],
					]}
				/>
				{renderMissedRecord()}
			</>
		);
	}

	function renderItemMain() {
		return (
			<h4 className="font-medium uppercase">
				{/*  This entry key/value validation is a quickfix and stopgap solution for vessel arctic, this validation will be
			  refactored and types will be also be corrected */}
				ENTRY PERTAINING TO AN EARLIER MISSED OPERATIONAL ENTRY
				{record.itemMissedEntry.receiptAttachment &&
				record.itemMissedEntry.receiptAttachment !== " " ? (
					<>
						<br />
						CORRECTION NOTE:{" "}
						<a
							href={marpolUtils.getAttachmentURL(
								record.itemMissedEntry.receiptAttachment,
								record.id,
								record._rev
							)}
							download={record.itemMissedEntry.receiptAttachment}
						>
							{" "}
							{record.itemMissedEntry.receiptAttachment}
						</a>
					</>
				) : null}
			</h4>
		);
	}

	function renderMissedPlaceholder(): BallastTableColumns {
		const canShowPlaceholder =
			record.itemMissedEntry.isAnMissedEntryRecord &&
			!record.itemMissedEntry.missedRecord;
		if (!canShowPlaceholder) return [null, null, null, null];

		const content = (
			<h4 className="font-medium text-gray-500 uppercase">
				<div className="flex flex-row items-center justify-center p-6 px-12 font-medium text-center bg-gray-100 border-2 border-gray-400 border-dashed">
					<div>MISSED ENTRY WILL APPEAR HERE</div>
					<span className="ml-6 text-gray-400 bp3-icon bp3-icon-time"></span>
				</div>
			</h4>
		);

		return [
			operationDate,
			<div className="text-gray-500">?</div>,
			<div className="text-gray-500">?</div>,
			content,
		];
	}

	function renderMissedRecord() {
		if (!record.itemMissedEntry.missedRecord) return null;
		return (
			<BallastPreviewer
				ballastRecord={{
					...record.itemMissedEntry.missedRecord,
					selectedDate: record.selectedDate,
					state: props.isStrikedOff
						? EBallastRecordState.STRIKED_OFF
						: record.itemMissedEntry.missedRecord.state,
				}}
			/>
		);
	}

	return renderMain();
}

export default AdditionalMissedEntryPreview;
