import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupB2 } from "../../../../../redux/app/annex6/groups/group-b";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupB2PreBiewProps = OwnProps;
function Annex6GroupB2Preview(props: Annex6GroupB2PreBiewProps) {
    const record = props.annex6Record as TAnnex6GroupB2;
    const operationDate = moment(record.selectedDate, 'YYYY-MM-DD').format(
        'DD-MMM-YYYY'
    );

    function renderMain() {
        return (
            <Annex6TableRows
                record={record}
                tableRows={[
                    [operationDate, 'B', 3.0, renderItem3()],
                    [null, null, 4.2, renderItem4P2()],
                    [null, null, 'NOTE', record.remarks],
                ]}
            />
        );
    }

    function renderItem3() {
        return (
            <h4 className="font-medium uppercase">
                BDN REFERENCE NO: {record.item3.BDN}
            </h4>
        );
    }

    function renderItem4P2(): React.ReactNode {
        return <>{record.item4P2.marpolSampleDisposedDate}</>;
    }

    return renderMain();
}

export default Annex6GroupB2Preview;
