// import { getType } from "typesafe-actions";
import { commonUtils } from "redux/_common";
import { IAPIRequestMetaData } from "../../_core/api/types";
import checklistActionTypes from "./action-types";
// import checklistActions from "./actions";
import { IChecklistFilters, ITabChecklist, ITabChecklistResponseObj } from "./types";
import { IConfigListItem } from "../tool/@types";

const {
	CHECKLIST_LIST_LOAD,
	CHECKLIST_LOAD,
	CHECKLIST_UNIQUE_LIST_LOAD,
	CHECKLIST_COUNT_LOAD,
	SINGLE_CHECKLIST_LOAD,
	CHECKLIST_BY_ID,
	CHECKLIST_PRINT_TEMPLATE_LOAD
} = checklistActionTypes;
const checklistAPIs = {
	loadChecklistUniqueList(
		userId: string,
		token?: string,
		vesselId?: string
	): IAPIRequestMetaData<IConfigListItem[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "GET",
			feature: CHECKLIST_UNIQUE_LIST_LOAD,
			queryParams: {
				category: 'checklists',
				vesselId: vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadChecklistCount(params: {
		filters?: IChecklistFilters;
		vesselId: string;
		userId: string;
		token: string;
	}): IAPIRequestMetaData<{ count: number }> {
		const { vesselId, userId, token, filters } = params;
		const selector: any = {
			vesselId
		};
		if (filters?.checklistType) {
			selector["code"] = filters.checklistType;
		}
		return {
			feature: CHECKLIST_COUNT_LOAD,
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/count`,
			queryParams: {
				modelName: "permitLogs",
				filter: {
					selector: selector,
				},
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadSingleChecklist(
		checklist: ITabChecklist,
		extra: {
			vesselId: string;
			userId: string;
			token: string;
		}
	): IAPIRequestMetaData<any> {
		const { vesselId, userId, token } = extra;
		return {
			feature: SINGLE_CHECKLIST_LOAD,
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/checklists`,
			queryParams: {
				vesselId: vesselId,
				id: checklist._id,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadChecklist(
		filters: IChecklistFilters & { tenantId?: string } & {
			token: string;
			userId: string;
		},
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<ITabChecklistResponseObj[]> {
		const { token, userId } = filters;
		return {
			feature: CHECKLIST_LOAD,
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/checklists`,
			queryParams: {
				vesselId: filters.vesselId,
				limit: itemsPerPage,
				skip: (pageNumber - 1) * itemsPerPage,
				code: filters.checklistType
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	// loadPrintTemplate(
	// 	vesselId: string,
	// 	code: string,
	// ): IAPIRequestMetaData<any> {
	// 	return {
	// 		url: "/api/printTemplates/findOne",
	// 		feature: CHECKLIST_PRINT_TEMPLATE_LOAD,
	// 		method: "GET",
	// 		queryParams: {
	// 			filter: {
	// 				where: {
	// 					code: code,
	// 					vesselId,
	// 				},
	// 			},
	// 		},
	// 	};
	// },
	loadPrintTemplate(
		vesselId: string,
		code: string,
		userId: string,
		token: string,
		recordConfigVersion?: string
	): IAPIRequestMetaData<any> {
		return {
			feature: CHECKLIST_PRINT_TEMPLATE_LOAD,
			method: "GET",
			url: `${commonUtils.getAPIBaseUrl()}/api/printTemplates`,
			queryParams: {
				vesselId,
				code,
				recordConfigVersion,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	listAPI(pageNumber = 1, itemsPerPage = 30): IAPIRequestMetaData<any> {
		return {
			feature: CHECKLIST_LIST_LOAD,
			method: "GET",
			url: "api/checklistLogs/approve",
			queryParams: {
				filter: {
					limit: itemsPerPage,
					skip: (pageNumber - 1) * itemsPerPage,
				},
			},
		};
	},
	updateChecklistAPI(checklist: any): IAPIRequestMetaData<any> {
		return {
			feature: CHECKLIST_BY_ID,
			method: "PATCH",
			url: "api/checklistLogs/" + checklist.id,
			body: checklist,
			headers: {
				// @ts-ignore
			},
		};
	},
};

export default checklistAPIs;