import moment from "moment";
import {
	EBilgeWaterType,
	EDischargeTypes,
	EGarbageCategoryTypes,
	EORSAggregateRecord,
	IBilgeWaterAllVesselAggregateRecord,
	IBilgeWaterGraphFinalData,
	IBilgeWaterRecord,
	ICargoSlopAggregateRecord,
	ICargoSlopGraphFinalData,
	ICargoSlopVesselSpecificAggregateRecord,
	IGarbageCategoryAllVesselsRecord,
	IGarbageCategoryGraphFinalData,
	IGarbageCategoryGraphTransformedData,
	IGarbageDischargeAllVesselsRecord,
	IGarbageDischargeGraphFinalData,
	IGarbageRawRecord,
	IMonthwiseRawGarbageObj,
	IORSAggregateRecord,
	IORSGraphFinalData,
	IORSVesselSpecificAggregateRecord,
	IOilSpillsInWaterAggregateRecord,
	IOilSpillsInWaterGraphFinalData,
	IOilSpillsInWaterVesselSpecificAggregateRecord,
	IRawGarbageDischargeRecord,
} from "./@types";
import {
	categoryLegends,
	garbageDischargeCategoriesMapper,
	orderOfDischargeCategories,
	quarterLabels as initialQuarterLabels,
	quarterMonthMapper,
	totalMonthsCount,
	wasteCategories,
} from "./constants";

export function getMonthWiseGarbageRow(rawGarbageData: IGarbageRawRecord[]) {
	const monthWiseRawGarbageObj: IMonthwiseRawGarbageObj = {};
	rawGarbageData.forEach((garbageRow) => {
		monthWiseRawGarbageObj[garbageRow.dateRange.month] = garbageRow;
	});

	const monthWiseRawGarbageRow = Array.from({ length: totalMonthsCount })
		.map((_, index) => {
			return index.toString() in monthWiseRawGarbageObj
				? monthWiseRawGarbageObj[index]
				: undefined;
		})
		.map((row) => {
			return typeof row !== "undefined" && "garbageDischargeCategories" in row
				? row["garbageDischargeCategories"]
				: undefined;
		})
		.reduce(
			(monthWiseGarbDischargeAcc, currMonthGarbageDischargeRecord, index) => {
				const garbageRecordsForCategory =
					currMonthGarbageDischargeRecord?.reduce((acc, gr) => {
						acc[gr.category] = gr.garbageRecords.reduce((acc2, waste) => {
							acc2[waste.category] = waste;
							return acc2;
						}, {} as Record<string, IRawGarbageDischargeRecord>);
						return acc;
					}, {} as Record<string, Record<string, IRawGarbageDischargeRecord>>);

				monthWiseGarbDischargeAcc[index] = garbageRecordsForCategory ?? {};
				return monthWiseGarbDischargeAcc;
			},
			{} as Record<
				string,
				Record<string, IRawGarbageDischargeRecord[]> | Object
			>
		);

	return monthWiseRawGarbageRow;
}

// Calculates summation of values for waste categories for the month numbers added - this is being used for quarter based data calculation - quarter 1 = [0,1,2]
function specificMonthsWasteCategorySumCalculation(
	months: number[],
	monthWiseRawGarbageRow: Record<
		string,
		Object | Record<string, IRawGarbageDischargeRecord[]>
	>
) {
	// initial values set to 0 for each waste category within DischargeCategory
	const wasteDataObj = wasteCategories.reduce((acc, cat) => {
		acc[cat] = 0;
		return acc;
	}, {} as Record<string, number>);

	const wasteDataForDisCat = {} as Record<string, Record<string, number>>;
	orderOfDischargeCategories.forEach((dischargeCategory) => {
		wasteDataForDisCat[dischargeCategory] = { ...wasteDataObj };
	});

	months.forEach((month) => {
		orderOfDischargeCategories.forEach((dischargeCategory) => {
			if (!!monthWiseRawGarbageRow[month]) {
				if (
					typeof monthWiseRawGarbageRow[month] !== "undefined" &&
					dischargeCategory in monthWiseRawGarbageRow[month]
				) {
					Object.keys(
						(monthWiseRawGarbageRow[month] as any)[dischargeCategory]
					).forEach((wC) => {
						wasteDataForDisCat[dischargeCategory][wC] =
							wasteDataForDisCat[dischargeCategory][wC] +
								// @ts-ignore
								monthWiseRawGarbageRow[month][dischargeCategory][wC]
									?.estimatedAmountDischarged ?? 0;
					});
				}
			}
		});
	});

	return wasteDataForDisCat;
}

export function getSumOfProperties(obj: Record<string, number>) {
	let total = 0;
	Object.keys(obj).forEach((prop) => {
		total += obj[prop];
	});
	return total;
}

export function transformDataForGraph(rawGarbageData: IGarbageRawRecord[]) {
	const finalData: Array<Record<string, string>> = [];
	// data transformation:
	const monthWiseRawGarbageRow = getMonthWiseGarbageRow(rawGarbageData);

	["Qtr 1", "Qtr 2", "Qtr 3", "Qtr 4"].forEach((quarterLabel) => {
		const months =
			quarterMonthMapper[quarterLabel as keyof typeof quarterMonthMapper];

		const wasteDataForDisCat = specificMonthsWasteCategorySumCalculation(
			months,
			monthWiseRawGarbageRow
		);

		orderOfDischargeCategories.map((dischargeCategory) => {
			finalData.push({
				quarter: quarterLabel,
				name: (garbageDischargeCategoriesMapper as any)[dischargeCategory],
				...wasteDataForDisCat[dischargeCategory],
			});
		});
	});

	return finalData;
}

export function generateTableRows(
	year: string,
	monthWiseRawGarbageRow: Record<
		string,
		Object | Record<string, IRawGarbageDischargeRecord[]>
	>
) {
	const rows: any = [];
	["Qtr 1", "Qtr 2", "Qtr 3", "Qtr 4"].forEach((quarterLabel, index) => {
		const months =
			quarterMonthMapper[quarterLabel as keyof typeof quarterMonthMapper];

		const wasteDataForDisCat = specificMonthsWasteCategorySumCalculation(
			months,
			monthWiseRawGarbageRow
		);

		const wasteValues = orderOfDischargeCategories.map((dischargeCategory) => [
			...categoryLegends.map((wC) => {
				return dischargeCategory in wasteDataForDisCat
					? wC.key in wasteDataForDisCat[dischargeCategory]
						? wasteDataForDisCat[dischargeCategory][wC.key]?.toFixed(2) ?? "NA"
						: "NA"
					: "NA";
			}),
		]);

		let wasteValuesForAllDischargeCategory = [] as string[];
		wasteValues.forEach((valueArr) => {
			wasteValuesForAllDischargeCategory =
				wasteValuesForAllDischargeCategory.concat(valueArr);
		});

		rows.push([year, index + 1, ...wasteValuesForAllDischargeCategory]);
	});
	return rows;
}

export function getYearOptions(startYear = moment().year(), noOfOptions = 3) {
	return Array.from({ length: 3 }, (_, index) => {
		return startYear - index;
	});
}

export const monthWiseCategorisationOfBilgeWaterData = (
	rawBilgeWaterData: IBilgeWaterRecord[]
) => {
	const owsOverboardMonthWiseData = {} as Record<string, number>;
	const transferToSlopMonthWiseData = {} as Record<string, number>;
	rawBilgeWaterData.forEach((bilgeWaterRecord) => {
		const {
			disposalMethod,
			totalQuantityDisposed,
			dateRange: { month },
		} = bilgeWaterRecord;
		// INFO: assuming there are only two disposal methods.
		if (disposalMethod === "OWS Overboard") {
			owsOverboardMonthWiseData[month] = totalQuantityDisposed;
		} else {
			transferToSlopMonthWiseData[month] = totalQuantityDisposed;
		}
	});
	return { transferToSlopMonthWiseData, owsOverboardMonthWiseData };
};

export function quarterWiseDataTransformationForGraph(
	rawBilgeWaterData: IBilgeWaterRecord[]
) {
	const { owsOverboardMonthWiseData, transferToSlopMonthWiseData } =
		monthWiseCategorisationOfBilgeWaterData(rawBilgeWaterData);

	return ["Qtr 1", "Qtr 2", "Qtr 3", "Qtr 4"].map((quarterLabel, index) => {
		const months =
			quarterMonthMapper[quarterLabel as keyof typeof quarterMonthMapper];

		return {
			quarter: quarterLabel,
			transferToSlop: months.reduce((transferToSlopForQuarter, month) => {
				if (month.toString() in transferToSlopMonthWiseData) {
					transferToSlopForQuarter += transferToSlopMonthWiseData[month];
				}
				return transferToSlopForQuarter;
			}, 0),
			owsOverboard: months.reduce((owsOverboardForQuarter, month) => {
				if (month.toString() in owsOverboardMonthWiseData) {
					owsOverboardForQuarter += owsOverboardMonthWiseData[month];
				}
				return owsOverboardForQuarter;
			}, 0),
		};
	});
}

export function dataSummation(arrayData: any[], accessor: string) {
	let total = 0;
	if (arrayData?.length) {
		arrayData.forEach((dataObj) => {
			if (accessor in dataObj && typeof dataObj[accessor] === "number") {
				total += dataObj[accessor];
			}
		});
	}
	return total;
}

export function assignValueToMonthwiseMapper(
	monthWiseSpecificMapper: Record<string, number>,
	monthWiseData: { month: number; amountDischarged: number }[]
) {
	monthWiseData.forEach(
		({ month, amountDischarged }) =>
			(monthWiseSpecificMapper[month] = amountDischarged)
	);
}

export function calculateSpecificGarbageQuarterValue(
	quarter: string,
	monthWiseSpecificMapper: Record<string, number>
) {
	let quarterGarbageValue = 0;
	quarterMonthMapper[quarter].forEach((month) => {
		const monthlyDisposedValue =
			month in monthWiseSpecificMapper ? monthWiseSpecificMapper[month] : 0;
		quarterGarbageValue += monthlyDisposedValue;
	});
	return quarterGarbageValue;
}

export function tranformBilgeWaterDataForTable(
	rawBilgeWaterData: IBilgeWaterRecord[]
) {
	const quarterWiseData =
		quarterWiseDataTransformationForGraph(rawBilgeWaterData);

	const quarterWithTotal = quarterWiseData.map((quarterData) => {
		const { owsOverboard, transferToSlop } = quarterData;
		return {
			...quarterData,
			quarterTotal: owsOverboard + transferToSlop,
		};
	});

	const allQuartersOwsOverboard = dataSummation(
		quarterWiseData,
		"owsOverboard"
	);
	const allQuartersTransferToSlop = dataSummation(
		quarterWiseData,
		"transferToSlop"
	);

	quarterWithTotal.push({
		quarter: "YTD Total",
		owsOverboard: allQuartersOwsOverboard,
		transferToSlop: allQuartersTransferToSlop,
		quarterTotal: allQuartersOwsOverboard + allQuartersTransferToSlop,
	});

	return quarterWithTotal;
}

export function transformDataForAllVesselsToGraph(
	allVesselBilgeWaterRecords: IBilgeWaterAllVesselAggregateRecord[]
) {
	const allQuarters = [...initialQuarterLabels];
	const owsOverboardMonthWiseMapper = {} as Record<string, number>;
	const transferToSlopMonthWiseMapper = {} as Record<string, number>;
	allVesselBilgeWaterRecords.forEach((record) => {
		if (record.disposalMethod === EBilgeWaterType.OWS_OVERBOARD) {
			const { monthWiseData } = record;
			monthWiseData.forEach(
				({ month, totalQuantityDisposed }) =>
					(owsOverboardMonthWiseMapper[month] = totalQuantityDisposed)
			);
		}
		if (record.disposalMethod === EBilgeWaterType.TRANSFER_TO_SLOP) {
			const { monthWiseData } = record;
			monthWiseData.forEach(
				({ month, totalQuantityDisposed }) =>
					(transferToSlopMonthWiseMapper[month] = totalQuantityDisposed)
			);
		}
	});

	const records: IBilgeWaterGraphFinalData[] = allQuarters.map((quarter) => {
		const owsOverboard = calculateSpecificGarbageQuarterValue(
			quarter,
			owsOverboardMonthWiseMapper
		);
		const transferToSlop = calculateSpecificGarbageQuarterValue(
			quarter,
			transferToSlopMonthWiseMapper
		);
		return {
			quarter,
			owsOverboard,
			transferToSlop,
		};
	});

	return records;
}

export function transformAggregateAllVesselsBilgeWaterDataForTable(
	allVesselsData: IBilgeWaterAllVesselAggregateRecord[]
) {
	const quarterWiseData = transformDataForAllVesselsToGraph(allVesselsData);
	const quarterWithTotal = quarterWiseData.map((quarterData) => {
		const { owsOverboard, transferToSlop } = quarterData;
		return {
			...quarterData,
			quarterTotal: owsOverboard + transferToSlop,
		};
	});
	const allQuartersOwsOverboard = dataSummation(
		quarterWiseData,
		"owsOverboard"
	);
	const allQuartersTransferToSlop = dataSummation(
		quarterWiseData,
		"transferToSlop"
	);
	quarterWithTotal.push({
		quarter: "YTD Total",
		owsOverboard: allQuartersOwsOverboard,
		transferToSlop: allQuartersTransferToSlop,
		quarterTotal: allQuartersOwsOverboard + allQuartersTransferToSlop,
	});

	return quarterWithTotal;
}

export function isBilgeDataEmpty(
	finalData: {
		quarter: string;
		transferToSlop: number;
		owsOverboard: number;
	}[]
) {
	return finalData.every(
		(item) => item.owsOverboard === 0 && item.transferToSlop === 0
	);
}

export function transformDataForGarbageDischargeForAllVessels(
	allVesselGarbageDischargeRecords: IGarbageDischargeAllVesselsRecord[]
) {
	const allQuarters = [...initialQuarterLabels];
	const disposedAshoreMonthWiseMapper = {} as Record<string, number>;
	const dischargedToSeaMonthWiseMapper = {} as Record<string, number>;
	const incineratedMonthWiseMapper = {} as Record<string, number>;
	const accidentalReleaseMonthWiseMapper = {} as Record<string, number>;
	allVesselGarbageDischargeRecords.forEach((record) => {
		if (
			record.dischargeCategory ===
			EDischargeTypes.GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS
		) {
			assignValueToMonthwiseMapper(
				disposedAshoreMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (
			record.dischargeCategory === EDischargeTypes.GARBAGE_DISCHARGED_INTO_SEA
		) {
			assignValueToMonthwiseMapper(
				dischargedToSeaMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.dischargeCategory === EDischargeTypes.GARBAGE_INCINERATED) {
			assignValueToMonthwiseMapper(
				incineratedMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (
			record.dischargeCategory ===
			EDischargeTypes.ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA
		) {
			assignValueToMonthwiseMapper(
				accidentalReleaseMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
	});

	const records: IGarbageDischargeGraphFinalData[] = allQuarters.map(
		(quarter) => {
			const disposedAshore = calculateSpecificGarbageQuarterValue(
				quarter,
				disposedAshoreMonthWiseMapper
			);
			const dischargedToSea = calculateSpecificGarbageQuarterValue(
				quarter,
				dischargedToSeaMonthWiseMapper
			);
			const incinerated = calculateSpecificGarbageQuarterValue(
				quarter,
				incineratedMonthWiseMapper
			);
			const accidentalRelease = calculateSpecificGarbageQuarterValue(
				quarter,
				accidentalReleaseMonthWiseMapper
			);

			return {
				quarter,
				disposedAshore,
				dischargedToSea,
				incinerated,
				accidentalRelease,
			};
		}
	);

	return records;
}

export function transformAllVesselsGarbageDischargeDataForTable(
	allVesselsData: IGarbageDischargeAllVesselsRecord[]
) {
	const quarterWiseData =
		transformDataForGarbageDischargeForAllVessels(allVesselsData);
	const quarterWithTotal = quarterWiseData.map((quarterData) => {
		const { accidentalRelease, dischargedToSea, disposedAshore, incinerated } =
			quarterData;
		return {
			...quarterData,
			quarterTotal:
				disposedAshore + dischargedToSea + incinerated + accidentalRelease,
		};
	});
	const allQuartersDisposedAshore = dataSummation(
		quarterWiseData,
		"disposedAshore"
	);
	const allQuartersDischargedToSea = dataSummation(
		quarterWiseData,
		"dischargedToSea"
	);
	const allQuartersIncinerated = dataSummation(quarterWiseData, "incinerated");
	const allQuartersAccidentalRelease = dataSummation(
		quarterWiseData,
		"accidentalRelease"
	);
	quarterWithTotal.push({
		quarter: "YTD Total",
		disposedAshore: allQuartersDisposedAshore,
		dischargedToSea: allQuartersDischargedToSea,
		incinerated: allQuartersIncinerated,
		accidentalRelease: allQuartersAccidentalRelease,
		quarterTotal:
			allQuartersDisposedAshore +
			allQuartersDischargedToSea +
			allQuartersIncinerated +
			allQuartersAccidentalRelease,
	});
	return quarterWithTotal;
}

export function transformDataForGarbageCategoryForAllVessels(
	allVesselGarbageCategoryRecords: //  Record<string,
	IGarbageCategoryAllVesselsRecord[]
	// >
) {
	const allQuarters = [...initialQuarterLabels];
	const plasticMonthWiseMapper = {} as Record<string, number>;
	const foodWasteMonthWiseMapper = {} as Record<string, number>;
	const domesticWasteMonthWiseMapper = {} as Record<string, number>;
	const cookingOilMonthWiseMapper = {} as Record<string, number>;
	const incineratorMonthWiseMapper = {} as Record<string, number>;
	const operationalWasteMonthWiseMapper = {} as Record<string, number>;
	const eWasteMonthWiseMapper = {} as Record<string, number>;
	allVesselGarbageCategoryRecords.forEach((record) => {
		if (record.category === EGarbageCategoryTypes.PLASTICS) {
			assignValueToMonthwiseMapper(
				plasticMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.category === EGarbageCategoryTypes.FOOD_WASTES) {
			assignValueToMonthwiseMapper(
				foodWasteMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.category === EGarbageCategoryTypes.DOMESTIC_WASTE) {
			assignValueToMonthwiseMapper(
				domesticWasteMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.category === EGarbageCategoryTypes.COOKING_OIL) {
			assignValueToMonthwiseMapper(
				cookingOilMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.category === EGarbageCategoryTypes.INCINERATOR_ASHES) {
			assignValueToMonthwiseMapper(
				incineratorMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.category === EGarbageCategoryTypes.OPERATIONAL_WASTES) {
			assignValueToMonthwiseMapper(
				operationalWasteMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
		if (record.category === EGarbageCategoryTypes.EWASTE) {
			assignValueToMonthwiseMapper(
				eWasteMonthWiseMapper,
				record?.monthWiseData ?? []
			);
		}
	});

	const records: IGarbageCategoryGraphFinalData[] = allQuarters.map(
		(quarter) => {
			const A = calculateSpecificGarbageQuarterValue(
				quarter,
				plasticMonthWiseMapper
			);
			const B = calculateSpecificGarbageQuarterValue(
				quarter,
				foodWasteMonthWiseMapper
			);
			const C = calculateSpecificGarbageQuarterValue(
				quarter,
				domesticWasteMonthWiseMapper
			);
			const D = calculateSpecificGarbageQuarterValue(
				quarter,
				cookingOilMonthWiseMapper
			);
			const E = calculateSpecificGarbageQuarterValue(
				quarter,
				incineratorMonthWiseMapper
			);
			const F = calculateSpecificGarbageQuarterValue(
				quarter,
				operationalWasteMonthWiseMapper
			);
			const I = calculateSpecificGarbageQuarterValue(
				quarter,
				eWasteMonthWiseMapper
			);

			return {
				quarter,
				A,
				B,
				C,
				D,
				E,
				F,
				I,
			};
		}
	);

	return records;
}

export function transformDataForGarbageCategoryForAllVesselsForGraphgarbageCategoryAllVesselRecords(
	garbageCategoryAllVesselRecords: IGarbageCategoryAllVesselsRecord[]
) {
	const getQuarterWiseTransformedData =
		transformDataForGarbageCategoryForAllVessels(
			garbageCategoryAllVesselRecords
		);
	const yearRecord = getQuarterWiseTransformedData.reduce(
		(yearRecord, quarterRecord) => {
			const { A, B, C, D, E, F, I } = quarterRecord;
			const mapper = {
				A,
				B,
				C,
				D,
				E,
				F,
				I,
			};
			Object.keys(mapper).forEach((category) => {
				(yearRecord as any)[category] =
					category in yearRecord
						? (yearRecord as any)[category] + (mapper as any)[category]
						: 0;
			});

			return yearRecord;
		},
		{} as IGarbageCategoryGraphTransformedData
	);

	return yearRecord;
}

export function transformAllVesselsGarbageCategoryDataForTable(
	allVesselsData: IGarbageCategoryAllVesselsRecord[]
) {
	const quarterWiseData =
		transformDataForGarbageCategoryForAllVessels(allVesselsData);
	const quarterWithTotal = quarterWiseData.map((quarterData) => {
		const { A, B, C, D, E, F, I } = quarterData;
		return {
			...quarterData,
			quarterTotal: A + B + C + D + E + F + I,
		};
	});
	const allQuartersA = dataSummation(quarterWiseData, "A");
	const allQuartersB = dataSummation(quarterWiseData, "B");
	const allQuartersC = dataSummation(quarterWiseData, "C");
	const allQuartersD = dataSummation(quarterWiseData, "D");
	const allQuartersE = dataSummation(quarterWiseData, "E");
	const allQuartersF = dataSummation(quarterWiseData, "F");
	const allQuartersI = dataSummation(quarterWiseData, "I");

	quarterWithTotal.push({
		quarter: "YTD Total",
		A: allQuartersA,
		B: allQuartersB,
		C: allQuartersC,
		D: allQuartersD,
		E: allQuartersE,
		F: allQuartersF,
		I: allQuartersI,
		quarterTotal:
			allQuartersA +
			allQuartersB +
			allQuartersC +
			allQuartersD +
			allQuartersE +
			allQuartersF +
			allQuartersI,
	});

	return quarterWithTotal;
}

// Transformation for ORS

export function assignDisposedValueToMonthwiseMapper(
	monthWiseSpecificMapper: Record<string, number>,
	monthWiseData: { month: number; totalQuantityDisposed: number }[]
) {
	monthWiseData.forEach(
		({ month, totalQuantityDisposed }) =>
			(monthWiseSpecificMapper[month] = totalQuantityDisposed)
	);
}

export function transformVesselSpecificORSRawData(
	rawVesselSpecificORSRecords: IORSVesselSpecificAggregateRecord[]
) {
	const orsVesselSpecificMonthWiseMapper: Record<
		string,
		IORSVesselSpecificAggregateRecord
	> = {};
	rawVesselSpecificORSRecords.forEach((record) => {
		orsVesselSpecificMonthWiseMapper[record.dateRange?.month] = record;
	});

	const finalQuarterlyVesselSpecificData: IORSGraphFinalData[] = [];

	["Qtr 1", "Qtr 2", "Qtr 3", "Qtr 4"].forEach((quarterLabel) => {
		const months =
			quarterMonthMapper[quarterLabel as keyof typeof quarterMonthMapper];

		// the below variables are quarterly values
		let incinerated = 0,
			evaporated = 0,
			transferToSlop = 0,
			boiler = 0,
			disposedAshore = 0;
		months.forEach((month) => {
			const monthSpecificData = orsVesselSpecificMonthWiseMapper[month];
			monthSpecificData?.orsCategories?.forEach((category) => {
				const { disposalMethod, totalQuantityDisposed } = category;
				if (disposalMethod === EORSAggregateRecord.INCINERATOR) {
					incinerated += totalQuantityDisposed;
				}
				if (disposalMethod === EORSAggregateRecord.BOILER) {
					boiler += totalQuantityDisposed;
				}
				if (disposalMethod === EORSAggregateRecord.WATER_EVAPORATION) {
					evaporated += totalQuantityDisposed;
				}
				if (disposalMethod === EORSAggregateRecord.SHORE_DISPOSAL) {
					disposedAshore += totalQuantityDisposed;
				}
				if (disposalMethod === EORSAggregateRecord.SLOP_TANK) {
					transferToSlop += totalQuantityDisposed;
				}
			});
		});

		finalQuarterlyVesselSpecificData.push({
			quarter: quarterLabel,
			incinerated,
			evaporated,
			transferToSlop,
			boiler,
			disposedAshore,
			total: (
				incinerated +
				evaporated +
				boiler +
				transferToSlop +
				disposedAshore
			).toFixed(2),
		});
	});
	return finalQuarterlyVesselSpecificData;
}

export function transformORSRawData(rawORSRecords: IORSAggregateRecord[]) {
	// WATER EVAPORATION, SHORE DISPOSAL, BOILER, SLOP TANK & INCINERATOR
	const allQuarters = [...initialQuarterLabels];
	const incineratedMonthWiseMapper = {} as Record<string, number>;
	const evaporatedMonthWiseMapper = {} as Record<string, number>;
	const transferToSlopMonthWiseMapper = {} as Record<string, number>;
	const disposedAshoreMonthWiseMapper = {} as Record<string, number>;
	const boilerMonthWiseMapper = {} as Record<string, number>;

	rawORSRecords.forEach((record) => {
		if (record.disposalMethod === EORSAggregateRecord.INCINERATOR) {
			assignDisposedValueToMonthwiseMapper(
				incineratedMonthWiseMapper,
				record.monthWiseData
			);
		}
		if (record.disposalMethod === EORSAggregateRecord.WATER_EVAPORATION) {
			assignDisposedValueToMonthwiseMapper(
				evaporatedMonthWiseMapper,
				record.monthWiseData
			);
		}
		if (record.disposalMethod === EORSAggregateRecord.SLOP_TANK) {
			assignDisposedValueToMonthwiseMapper(
				transferToSlopMonthWiseMapper,
				record.monthWiseData
			);
		}
		if (record.disposalMethod === EORSAggregateRecord.SHORE_DISPOSAL) {
			assignDisposedValueToMonthwiseMapper(
				disposedAshoreMonthWiseMapper,
				record.monthWiseData
			);
		}
		if (record.disposalMethod === EORSAggregateRecord.BOILER) {
			assignDisposedValueToMonthwiseMapper(
				boilerMonthWiseMapper,
				record.monthWiseData
			);
		}
	});

	const records: IORSGraphFinalData[] = allQuarters.map((quarter) => {
		const incinerated = calculateSpecificGarbageQuarterValue(
			quarter,
			incineratedMonthWiseMapper
		);
		const evaporated = calculateSpecificGarbageQuarterValue(
			quarter,
			evaporatedMonthWiseMapper
		);
		const transferToSlop = calculateSpecificGarbageQuarterValue(
			quarter,
			transferToSlopMonthWiseMapper
		);
		const disposedAshore = calculateSpecificGarbageQuarterValue(
			quarter,
			disposedAshoreMonthWiseMapper
		);
		const boiler = calculateSpecificGarbageQuarterValue(
			quarter,
			boilerMonthWiseMapper
		);
		return {
			quarter,
			incinerated,
			evaporated,
			transferToSlop,
			boiler,
			disposedAshore,
			total: (
				incinerated +
				evaporated +
				boiler +
				transferToSlop +
				disposedAshore
			).toFixed(2),
		};
	});

	return records;
}

export function transformORSDataForTable(orsRecords: IORSAggregateRecord[]) {
	const quarterWiseData = transformORSRawData(orsRecords);
	const quarterWithTotal = quarterWiseData.map((quarterData) => {
		const { evaporated, transferToSlop, boiler, disposedAshore, incinerated } =
			quarterData;
		return {
			...quarterData,
			quarterTotal:
				incinerated + evaporated + boiler + transferToSlop + disposedAshore,
		};
	});
	const allQuartersIncinerated = dataSummation(quarterWiseData, "incinerated");
	const allQuartersEvaporated = dataSummation(quarterWiseData, "evaporated");
	const allQuartersBoiler = dataSummation(quarterWiseData, "boiler");
	const allQuartersTransferToSlop = dataSummation(
		quarterWiseData,
		"transferToSlop"
	);
	const allQuartersDisposedAshore = dataSummation(
		quarterWiseData,
		"disposedAshore"
	);
	quarterWithTotal.push({
		quarter: "YTD Total",
		incinerated: allQuartersIncinerated,
		evaporated: allQuartersEvaporated,
		boiler: allQuartersBoiler,
		transferToSlop: allQuartersTransferToSlop,
		disposedAshore: allQuartersDisposedAshore,
		quarterTotal:
			allQuartersIncinerated +
			allQuartersEvaporated +
			allQuartersBoiler +
			allQuartersTransferToSlop +
			allQuartersDisposedAshore,
	});
	return quarterWithTotal;
}

export function transformVesselSpecificORSDataForTable(
	orsRecords: IORSVesselSpecificAggregateRecord[]
) {
	const quarterWiseData = transformVesselSpecificORSRawData(orsRecords);
	const quarterWithTotal = quarterWiseData.map((quarterData) => {
		const { evaporated, transferToSlop, boiler, disposedAshore, incinerated } =
			quarterData;
		return {
			...quarterData,
			quarterTotal:
				incinerated + evaporated + boiler + transferToSlop + disposedAshore,
		};
	});
	const allQuartersIncinerated = dataSummation(quarterWiseData, "incinerated");
	const allQuartersEvaporated = dataSummation(quarterWiseData, "evaporated");
	const allQuartersBoiler = dataSummation(quarterWiseData, "boiler");
	const allQuartersTransferToSlop = dataSummation(
		quarterWiseData,
		"transferToSlop"
	);
	const allQuartersDisposedAshore = dataSummation(
		quarterWiseData,
		"disposedAshore"
	);
	quarterWithTotal.push({
		quarter: "YTD Total",
		incinerated: allQuartersIncinerated,
		evaporated: allQuartersEvaporated,
		boiler: allQuartersBoiler,
		transferToSlop: allQuartersTransferToSlop,
		disposedAshore: allQuartersDisposedAshore,
		quarterTotal:
			allQuartersIncinerated +
			allQuartersEvaporated +
			allQuartersBoiler +
			allQuartersTransferToSlop +
			allQuartersDisposedAshore,
	});
	return quarterWithTotal;
}

export function isORSDataEmpty(orsData: IORSGraphFinalData[]) {
	const manipulatedData = orsData.map((orsObj) => {
		const { disposedAshore, evaporated, incinerated, transferToSlop, boiler } =
			orsObj;
		return disposedAshore + evaporated + boiler + incinerated + transferToSlop;
	});

	return manipulatedData.every((keyValue) => keyValue === 0);
}

// For Cargo-slop
export function assignDischargedValueToMonthwiseMapper(
	monthWiseSpecificMapper: Record<string, number>,
	monthWiseData: { month: number; totalQuantityDischarged: number }[]
) {
	monthWiseData?.forEach(
		({ month, totalQuantityDischarged }) =>
			(monthWiseSpecificMapper[month] = totalQuantityDischarged)
	);
}

export function transformCargoSlopRawData(
	rawcargoSlopRecords: ICargoSlopAggregateRecord[]
) {
	const allQuarters = [...initialQuarterLabels];

	const cargoSlopRecord = rawcargoSlopRecords[0];
	const totalDischargedMonthwiseMapper = {} as Record<string, number>;
	assignDischargedValueToMonthwiseMapper(
		totalDischargedMonthwiseMapper,
		cargoSlopRecord?.monthWiseData
	);

	const records: ICargoSlopGraphFinalData[] = allQuarters?.map((quarter) => {
		const totalDischarged = calculateSpecificGarbageQuarterValue(
			quarter,
			totalDischargedMonthwiseMapper
		);
		return {
			quarter,
			totalDischarged: totalDischarged,
		};
	});

	return records;
}

// This transformation function is almost similar to transformCargoSlopRawData
export function transformVesselSpecificCargoSlopRawData(
	rawVesselSpecificCargoSlopRecords: ICargoSlopVesselSpecificAggregateRecord[]
) {
	const allQuarters = [...initialQuarterLabels];
	const cargoSlopVesselSpecificMonthWiseMapper: Record<string, number> = {};
	assignDischargedValueToMonthwiseMapper(
		cargoSlopVesselSpecificMonthWiseMapper,
		rawVesselSpecificCargoSlopRecords[0]?.monthWiseData ?? []
	);

	const records: ICargoSlopGraphFinalData[] = allQuarters?.map((quarter) => {
		const totalDischarged = calculateSpecificGarbageQuarterValue(
			quarter,
			cargoSlopVesselSpecificMonthWiseMapper
		);
		return {
			quarter,
			totalDischarged: totalDischarged,
		};
	});

	return records;
}

export function transformCargoSlopDataForTable(
	cargoSlopRecords: ICargoSlopAggregateRecord[]
) {
	let quarterWiseData = transformCargoSlopRawData(cargoSlopRecords);

	quarterWiseData.push({
		quarter: "YTD Total",
		totalDischarged: quarterWiseData?.reduce(
			(s, q) => s + q.totalDischarged,
			0
		),
	});
	return quarterWiseData;
}

export function transformVesselSpecificCargoSlopDataForTable(
	cargoSlopRecords: ICargoSlopVesselSpecificAggregateRecord[]
) {
	let quarterWiseData =
		transformVesselSpecificCargoSlopRawData(cargoSlopRecords);
	quarterWiseData.push({
		quarter: "YTD Total",
		totalDischarged: quarterWiseData?.reduce(
			(s, q) => s + q.totalDischarged,
			0
		),
	});
	return quarterWiseData;
}

export function isCargoSlopDataEmpty(
	cargoSlopData: ICargoSlopGraphFinalData[]
) {
	return cargoSlopData.every((csd) => csd.totalDischarged === 0);
}

// For Oil spills in water - creating a separate fns rather than using the fns of cargo-slop
// Possibility of change of the structure of data
export function transformOilSpillsInWaterRawData(
	rawoilSpillsInWaterRecords: IOilSpillsInWaterAggregateRecord[]
) {
	const allQuarters = [...initialQuarterLabels];

	const oilSpillsInWaterRecord = rawoilSpillsInWaterRecords[0];
	const totalDischargedMonthwiseMapper = {} as Record<string, number>;
	assignDischargedValueToMonthwiseMapper(
		totalDischargedMonthwiseMapper,
		oilSpillsInWaterRecord?.monthWiseData
	);

	const records: IOilSpillsInWaterGraphFinalData[] = allQuarters?.map(
		(quarter) => {
			const totalDischarged = calculateSpecificGarbageQuarterValue(
				quarter,
				totalDischargedMonthwiseMapper
			);
			return {
				quarter,
				totalDischarged: totalDischarged,
			};
		}
	);

	return records;
}

// This transformation function is almost similar to transformOilSpillsInWaterRawData
export function transformVesselSpecificOilSpillsInWaterRawData(
	rawVesselSpecificOilSpillsInWaterRecords: IOilSpillsInWaterVesselSpecificAggregateRecord[]
) {
	const allQuarters = [...initialQuarterLabels];
	const oilSpillsInWaterVesselSpecificMonthWiseMapper: Record<string, number> =
		{};
	assignDischargedValueToMonthwiseMapper(
		oilSpillsInWaterVesselSpecificMonthWiseMapper,
		rawVesselSpecificOilSpillsInWaterRecords[0]?.monthWiseData ?? []
	);

	const records: IOilSpillsInWaterGraphFinalData[] = allQuarters?.map(
		(quarter) => {
			const totalDischarged = calculateSpecificGarbageQuarterValue(
				quarter,
				oilSpillsInWaterVesselSpecificMonthWiseMapper
			);
			return {
				quarter,
				totalDischarged: totalDischarged,
			};
		}
	);

	return records;
}

export function transformOilSpillsInWaterDataForTable(
	oilSpillsInWaterRecords: IOilSpillsInWaterAggregateRecord[]
) {
	let quarterWiseData = transformOilSpillsInWaterRawData(
		oilSpillsInWaterRecords
	);

	quarterWiseData.push({
		quarter: "YTD Total",
		totalDischarged: quarterWiseData?.reduce(
			(s, q) => s + q.totalDischarged,
			0
		),
	});
	return quarterWiseData;
}

export function transformVesselSpecificOilSpillsInWaterDataForTable(
	oilSpillsInWaterRecords: IOilSpillsInWaterVesselSpecificAggregateRecord[]
) {
	let quarterWiseData = transformVesselSpecificOilSpillsInWaterRawData(
		oilSpillsInWaterRecords
	);
	quarterWiseData.push({
		quarter: "YTD Total",
		totalDischarged: quarterWiseData?.reduce(
			(s, q) => s + q.totalDischarged,
			0
		),
	});
	return quarterWiseData;
}

export function isOilSpillsInWaterDataEmpty(
	oilSpillsInWaterData: IOilSpillsInWaterGraphFinalData[]
) {
	return oilSpillsInWaterData.every((csd) => csd.totalDischarged === 0);
}
