import React, { useEffect } from "react";
import classNames from "classnames";
import Button from "../../@set-product/core/button";
import loginBackgroundImage from "./../../assets/images/login-background.png";
import { Button as ButtonSource } from "@blueprintjs/core";
import WSLogo from "./../../assets/logos/logo-with-text.svg";
import InfiniteProgress from "../_common/InfiniteProgress";
import { authSelectors } from "../../redux/app/auth";
import { IStore } from "../../redux/store";
import { connect } from "react-redux";
import { IAuth_Get_Jobs } from "../../redux/app/auth/types";
import { Redirect } from "react-router-dom";
import { rootRoutes } from "../../routes";
type AuthType = React.PropsWithChildren<
	IAuth_Get_Jobs & { authToken: string | null }
>;
const Auth: React.FC<AuthType> = (props: AuthType) => {
	if (props.authToken !== null)
		return (
			<Redirect
				to={{
					pathname: rootRoutes.children.app.children.vessel.path,
				}}
			/>
		);
	const { children, loginJob, resetPasswordJob, resetRequestJob } = props;
	return (
		<>
			<div
				className={classNames(
					"flex flex-row justify-center items-center", // Layout [Flex, Inline, Grid]
					"h-full", // Sizing [Width, Height]
					"", // Spacing [Padding, Margin]
					"", // Font [Font Size, Font style]
					"bg-transparent", // Colors [Border, Background, Font Colors]
					"", // Borders [Border size, style, width, radius]
					"overflow-hidden z-10" // Position [Absolute, Relative]
				)}
			>
				<div className="flex-row items-stretch w-full min-h-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-3/4">
					<div
						className={classNames(
							"", // Layout [Flex, Inline, Grid]
							"sm:min-h-full", // Sizing [Width, Height]
							"my-0 mx-0 sm:mx-6 sm:my-64", // Spacing [Padding, Margin]
							"", // Font [Font Size, Font style]
							"bg-white", // Colors [Border, Background, Font Colors]
							"sm:rounded-lg border", // Borders [Border size, style, width, radius]
							"" // Position [Absolute, Relative]
						)}
					>
						<div className="p-6 bg-gray-100 rounded-t-lg">
							<img src={WSLogo} className="mx-auto my-3" />
						</div>
						<div>
							<InfiniteProgress
								isLoading={
									loginJob.isLoading ||
									resetPasswordJob.isLoading ||
									resetRequestJob.isLoading
								}
								isSpacedOut={false}
								isSticky={true}
							/>
						</div>
						<div className="p-6 px-6 sm:px-12">{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (store: IStore) => ({
	...authSelectors(store.app.auth).getAllJobs(),
	authToken: store.app.auth.authToken,
});

export default connect(mapStateToProps, () => ({}))(Auth);
