import classNames from "classnames";
import useElementSize from "hooks/useElementSize";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import reportActions from "redux/app/report/actions";
import Graph from "./_elements/Graph";
import Table from "./_elements/Table";
import {
	categoryLegends,
	garbageDischargeCategoriesMapper,
	orderOfDischargeCategories,
} from "redux/app/report/constants";
import reportSelectors from "redux/app/report/selectors";
import YearPicker from "./_elements/YearPicker";
import { getYearOptions } from "redux/app/report/utils";
import VesselSelector from "components/app/report/common/VesselSelector";
import AllVessels from "./_elements/allVessels";
import { vesselActions, vesselSelectors } from "redux/app/vessel";

type OwnProps = {};
type GarbageReportProps = PropsFromRedux & OwnProps;
function GarbageReport(props: GarbageReportProps) {
	const {
		vesselId,
		year,
		jobToLoadVessels,
		jobToLoadGarbageReport,
		jobToLoadAllVesselsGarbageCategoryReport,
		jobToLoadAllVesselsGarbageDischargeReport,
	} = props;
	const reportContainerRef = useRef<HTMLDivElement>(null);
	const [isGraphView, setIsGraphView] = useState(true);

	const { width: reportContainerWidth } = useElementSize(reportContainerRef);

	const dischargeCategories = orderOfDischargeCategories.map((key) => {
		return garbageDischargeCategoriesMapper[
			key as keyof typeof garbageDischargeCategoriesMapper
		];
	});

	useEffect(() => {
		props.setVesselId("");
		props.loadVessels();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (vesselId && year) props.loadGarbageReportData();
		if (!vesselId && year) {
			props.loadAllVesselsGarbageDischargeData();
			props.loadAllVesselsGarbageCategoryData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, year]);

	let contentRenderer: React.ReactNode;
	if (vesselId) {
		contentRenderer = isGraphView ? (
			<Graph
				categoryLegends={categoryLegends}
				dischargeCategories={dischargeCategories}
				reportContainerWidth={reportContainerWidth}
				year={year}
				vesselId={vesselId}
			/>
		) : (
			<Table />
		);
	} else {
		contentRenderer = (
			<AllVessels width={reportContainerWidth} isGraphView={isGraphView} />
		);
	}

	return (
		<div
			ref={reportContainerRef}
			className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto"
		>
			<div className="">
				<div className="px-5 py-8">
					<div className="flex items-center justify-between">
						<div className="flex items-center">
							<span
								title="go back"
								onClick={() => props.goBack()}
								className="bp3-icon bp3-icon-arrow-left text-lg mr-3 cursor-pointer"
							/>
							<div className="flex  items-baseline">
								<h2 className="text-xl font-medium">Garbage</h2>
								{isGraphView && (
									<>
										<div className="ml-4 text-base">
											<YearPicker
												options={getYearOptions().map((op) => ({
													label: op.toString(),
													value: op.toString(),
												}))}
												value={year}
												id="garbage-year-picker"
												onSelect={(option) => {
													props.setYear(option?.value ?? "");
												}}
												isLoading={
													jobToLoadVessels.isLoading ||
													jobToLoadGarbageReport.isLoading ||
													jobToLoadAllVesselsGarbageDischargeReport.isLoading ||
													jobToLoadAllVesselsGarbageCategoryReport.isLoading
												}
											/>
										</div>
										<div className="pl-4 min-w-28 md:w-56">
											<VesselSelector
												onVesselSelect={(vesselId: string) => {
													props.setVesselId(vesselId);
												}}
												showDropdownLabel={false}
												selectedVesselId={vesselId}
												isLoading={
													jobToLoadVessels.isLoading ||
													jobToLoadGarbageReport.isLoading ||
													jobToLoadAllVesselsGarbageDischargeReport.isLoading ||
													jobToLoadAllVesselsGarbageCategoryReport.isLoading
												}
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<div>
							<div className="p-0">
								<div className="border-gray-200 ">
									<div className="flex flex-row justify-around py-1 my-2 rounded-md bg-lightGray">
										<button
											className={classNames(
												"transition-all duration-300 px-10 text-[12px]",
												{
													"p-2 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
														isGraphView,
													"p-2 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
														!isGraphView,
												}
											)}
											onClick={() => setIsGraphView(true)}
										>
											Graph
										</button>
										<button
											className={classNames(
												"transition-all duration-300 px-10 text-[12px]",
												{
													"py-1.5 flex-1 mx-1 font-medium text-gray-800 bg-white rounded-md shadow-sm outline-none":
														!isGraphView,
													"py-1.5 flex-1 mx-1 font-medium text-gray-500 rounded-md outline-none hover:text-gray-700":
														isGraphView,
												}
											)}
											onClick={() => setIsGraphView(false)}
										>
											Table
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{contentRenderer}
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselId = reportSelectors.getVesselId(reportStore);
	const year = reportSelectors.getGarbageYear(reportStore);
	const vesselStore = vesselSelectors._getStore(store);
	const jobToLoadVessels = vesselSelectors.jobToLoadVessels(vesselStore);
	const jobToLoadGarbageReport =
		reportSelectors.jobToLoadGarbageReport(reportStore);
	const jobToLoadAllVesselsGarbageCategoryReport =
		reportSelectors.jobToLoadAllVesselsGarbageCategoryReport(reportStore);
	const jobToLoadAllVesselsGarbageDischargeReport =
		reportSelectors.jobToLoadAllVesselsGarbageDischargeReport(reportStore);
	return {
		vesselId,
		year,
		jobToLoadVessels,
		jobToLoadGarbageReport,
		jobToLoadAllVesselsGarbageCategoryReport,
		jobToLoadAllVesselsGarbageDischargeReport,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goBack() {
			dispatch(
				historyActions.push(
					"#Report",
					rootRoutes.children.app.children.reports.children.categories.url()
				)
			);
		},
		loadVessels() {
			dispatch(vesselActions.listLoad(true));
		},
		loadGarbageReportData() {
			dispatch(reportActions.commands.garbageReportLoad());
		},
		loadAllVesselsGarbageDischargeData() {
			dispatch(reportActions.commands.garbageDischargeAllVesselsLoad());
		},
		loadAllVesselsGarbageCategoryData() {
			dispatch(reportActions.commands.garbageCategoryAllVesselsLoad());
		},
		setVesselId(vesselId: string) {
			dispatch(reportActions.document.garbageReportVesselIdSet(vesselId));
		},
		setYear(year: string) {
			dispatch(reportActions.document.garbageReportYearSet(year));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageReport);
