import React from "react";
type SwitchType = React.PropsWithChildren<{
	label?: string;
	id: string;
	isChecked: boolean;
	handleChange: (isChecked: boolean) => void;
}>;
const Switch: React.FC<SwitchType> = ({
	label,
	id,
	isChecked,
	handleChange,
}: SwitchType) => {
	return (
		<label htmlFor={id} className="flex items-center cursor-pointer">
			{label && (
				<div className="mr-4 text-sm md:text-base text-gray-700 font-medium">
					{label}
				</div>
			)}
			<div className="relative">
				<input
					onChange={(e) => {
						const isChecked = e.target.checked;
						handleChange(isChecked);
					}}
					type="checkbox"
					id={id}
					className="sr-only"
					checked={isChecked}
				/>
				<div className="block bg-white border border-gray-600 w-[52px] h-7 rounded-full"></div>
				<div className="dot absolute left-1 top-[3px] bg-black w-[22px] h-[22px] rounded-full transition"></div>
			</div>
		</label>
	);
};

export default Switch;
