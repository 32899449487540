import React from "react";
import AuthReset from "../../../components/auth/reset/Reset";
type ScreenAuthResetType = React.PropsWithChildren<{
	match?: {
		path: string;
		url: string;
		params?: {
			accessToken: string;
		};
	};
}>;
const ScreenAuthReset: React.FC<ScreenAuthResetType> = (
	props: ScreenAuthResetType
) => {
	return <AuthReset accessTokenViaUrl={props.match?.params?.accessToken} />;
};

export default ScreenAuthReset;
