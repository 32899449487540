import classNames from "classnames";
import moment from "moment";
import React from "react";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import { TAnnex6GroupD3 } from "../../../../../redux/app/annex6/groups/group-d";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupD3PreviewProps = React.PropsWithChildren<OwnProps>;
function Annex6GroupD3Preview(props: Annex6GroupD3PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupD3;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	// function renderMain() {
	//     return (
	//         <Annex6TableRows
	//             record={record}
	//             tableRows={[
	//                 [operationDate, 'A', 1.1, renderItem1P1()],
	//                 [null, null, 1.2, renderItem1P2()],
	//                 [null, null, 1.3, renderItem1P3()],
	//                 [null, null, 1.4, renderItem1P4()],
	//                 [null, null, 1.5, renderItem1P5()],
	//                 [null, null, 'NOTE', record.remarks],
	//             ]}
	//         />
	//     );
	// }

	function renderItem7P4(): React.ReactNode {
		if (!record.item7P4.position) return <Annex6OldVersionPreview />;
		return (
			<>
				{record.item7P4.time} HOURS LT VESSEL ENTRY INTO SECA, <br /> POSITION:{" "}
				{record.item7P4.position.latitude} | {record.item7P4.position.longitude}
			</>
		);
	}

	return (
		<>
			<tr
				className={classNames("uppercase", {
					"line-through": props.isStrikedOff,
				})}
			>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm leading-7 text-left bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full">
							<span className="block font-medium whitespace-nowrap">
								{moment(record.selectedDate, "YYYY-MM-DD").format(
									"DD-MMM-YYYY"
								)}
							</span>
						</div>
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-center text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						D
					</div>
				</td>
				<td className="relative">
					<div
						className={classNames(
							{ "line-through": props.isStrikedOff },
							"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
						)}
					>
						<div className="h-full font-medium">7.4</div>
					</div>
				</td>
				<td className="relative">
					<div className="p-3 m-px text-sm font-normal leading-7 text-left text-gray-800 bg-white">
						<h4 className="font-medium uppercase">{renderItem7P4()}</h4>
					</div>
				</td>
			</tr>
			{record.remarks !== "" ? (
				<tr
					className={classNames("uppercase", {
						"line-through": props.isStrikedOff,
					})}
				>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix "
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px leading-7 bg-white ws-marpol-td--divfix "
							)}
						></div>
					</td>
					<td className="relative">
						<div
							className={classNames(
								{ "line-through": props.isStrikedOff },
								"p-3 m-px text-sm font-medium leading-7 text-right text-gray-800 bg-white ws-marpol-td--divfix"
							)}
						>
							<div className="h-full font-medium">NOTE</div>
						</div>
					</td>
					<td className="relative">
						<div className="p-3 m-px text-sm font-medium leading-7 text-left text-gray-800 bg-white">
							<div className="h-full font-medium uppercase">
								{record.remarks}
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</>
	);
}

export default Annex6GroupD3Preview;
