import React from "react";
type SVGCrossProps = { className?: string };
const SVGCross: React.FC<SVGCrossProps> = (props) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<line
			x1="1.25"
			y1="-1.25"
			x2="26.3976"
			y2="-1.25"
			transform="matrix(0.690453 -0.723377 0.690453 0.723377 1.91211 21.9995)"
			stroke="black"
			strokeWidth="2.5"
			strokeLinecap="round"
		/>
		<line
			x1="1.25"
			y1="-1.25"
			x2="26.3976"
			y2="-1.25"
			transform="matrix(0.690453 0.723377 -0.690453 0.723377 1 2.00049)"
			stroke="black"
			strokeWidth="2.5"
			strokeLinecap="round"
		/>
	</svg>
);
export default SVGCross;
