import classNames from "classnames";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { commonUtils } from "redux/_common";
import { TTableAlert, TTableHeader } from "redux/app/dashboard/@types";
import { tableColorMapper } from "redux/app/dashboard/utils";
import { IStore } from "redux/store";

type OwnProps = {
	value: any;
	columnDescriptor: TTableHeader;
	isRowForColumnExpanded?: boolean;
	id: string;
	index: number;
	setRowExpander?: (rowId: string) => void;
};
type ExpandedCellRendererProps = PropsFromRedux & OwnProps;
function ExpandedCellRenderer(props: ExpandedCellRendererProps) {
	const { value, columnDescriptor, id, index } = props;
	let cell: any = "";
	switch (columnDescriptor.key) {
		case "name":
			cell = <></>;
			break;

		case "fromTo":
			cell = <></>;
			break;

		case "alerts": {
			const alerts = value as TTableAlert;
			const isAlertsThere = alerts ? !!Object.keys(alerts)?.length : false;
			if (isAlertsThere) {
				cell = (
					<>
						{Object.keys(alerts).map((alertkey) => {
							const alert = alerts[alertkey];
							return (
								<div key={alertkey} className="flex flex-col">
									{alert.map((indvAlert, index) => {
										return (
											<div
												key={index}
												className="flex items-center space-x-2 mb-1"
											>
												<div
													style={{
														backgroundColor: tableColorMapper(alertkey),
													}}
													className="min-w-1 min-h-1 rounded-full"
												/>
												<p className="text-xs my-0 md:text-sm">
													{commonUtils.capitalize(indvAlert.description)}
												</p>
											</div>
										);
									})}
								</div>
							);
						})}
					</>
				);
			} else {
				cell = <></>;
			}
			break;
		}
		case "usage": {
			const usage = value as TTableAlert;
			const isUsageThere = usage ? !!Object.keys(usage)?.length : false;
			if (isUsageThere) {
				cell = (
					<>
						{Object.keys(usage).map((usageKey) => {
							const alert = usage[usageKey];
							return (
								<div key={usageKey} className="flex flex-col justify-start">
									{alert.map((indvUsage, index) => {
										return (
											<div
												key={"indv-usage" + index}
												className="flex items-center  space-x-2 mb-1"
											>
												<div
													style={{
														backgroundColor: tableColorMapper(usageKey),
													}}
													className="min-w-1 min-h-1 rounded-full"
												/>
												<p className="text-xs my-0 md:text-sm">
													{commonUtils.capitalize(indvUsage.description)}
												</p>
											</div>
										);
									})}
								</div>
							);
						})}
					</>
				);
			} else {
				cell = <></>;
			}
			break;
		}
		default:
			cell = <></>;
	}
	return (
		<td
			key={"exp-cell" + id + index}
			className={classNames(
				"px-3 py-3 text-xs md:text-sm leading-4 tracking-wide text-left  font-redhat-text text-[#2D2D2D] bg-gray-100 align-baseline border-[0.5px] border-gray-300 border-t-0 border-b-0"
			)}
		>
			{cell}
		</td>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ExpandedCellRenderer);
