import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import classNames from "classnames";
import feedbackSelectors from "redux/app/feedback/selectors";
import {
	ETicketTabType,
	IDashboardStatsAndFeedbackPayload,
	IFilterState,
	IOption,
} from "redux/app/feedback/@types";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import feedbackActions from "redux/app/feedback/actions";
import { getCreatedAt } from "redux/app/feedback/utils";
import Skeleton from "react-loading-skeleton";
import useCheckMobileScreen from "../utils";

const statInfo = {
	Open: {
		className: "text-red-700",
		label: "Open",
	},
	InProgress: {
		className: "text-yellow-800",
		label: "In progress",
	},
	Closed: {
		className: "text-green-600",
		label: "Closed",
	},
	Reopened: {
		className: "text-red-700",
		label: "Reopened",
	},
	Total: {
		className: "text-gray-500",
		label: "Total",
	},
};

type OwnProps = {
	selectedDateLabel?: string;
};
type FeedbackTicketStatusCountProps = PropsFromRedux & OwnProps;
function FeedbackTicketStatusCount(props: FeedbackTicketStatusCountProps) {
	const {
		ticketTabType,
		userEmail,
		statsValue,
		dateRange,
		selectedDateLabel,
		jobToLoadApi,
		assignedToOption,
	} = props;

	const isMobile = useCheckMobileScreen();

	const dashboardStats = useMemo(() => {
		return Object.keys(statInfo).map((stat) => {
			return {
				...statInfo[stat as keyof typeof statInfo],
				value: statsValue[stat as keyof typeof statsValue],
			};
		});
	}, [statsValue]);

	useEffect(() => {
		const startDate = dateRange[0].startDate;
		const endDate = dateRange[0].endDate;
		props.loadDashboardStats({
			stats: true,
			assignedTo:
				ticketTabType === ETicketTabType.MY_TICKETS ? userEmail : undefined,
			selector:
				startDate && endDate
					? {
							createdAt: getCreatedAt(dateRange[0], selectedDateLabel),
					  }
					: undefined,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketTabType]);

	if (jobToLoadApi.isLoading) {
		return (
			<div className="w-full px-4">
				<div>
					<h3 className="text-2xl mb-4">Tickets</h3>
					<div className="flex justify-between  space-x-2 lg:space-x-2.5 xl:space-x-5">
						{[0, 1, 2, 3, 4].map((_) => {
							return (
								<div className="basis-1/5">
									<Skeleton width="100%" height={isMobile ? 80 : 120} />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full px-4">
			<h3 className="text-2xl mb-4">Tickets</h3>
			<div className="flex w-full space-x-2 lg:space-x-2.5 xl:space-x-5">
				{dashboardStats.map((status) => {
					return (
						<div
							className={classNames(
								"shadow rounded flex flex-col justify-center items-center py-6 border border-gray-100",
								{ "basis-1/5": ticketTabType === ETicketTabType.MY_TICKETS },
								{ "basis-1/4": ticketTabType === ETicketTabType.ALL_TICKETS },
								{
									"cursor-pointer": status.label === "Total",
								}
							)}
							onClick={() => {
								if (status.label === "Total") {
									if (ticketTabType === ETicketTabType.MY_TICKETS) {
										props.setFilterState({
											assignedTo: assignedToOption as IOption,
										});
									}
									props.goToTab(
										rootRoutes.children.app.children.feedback.children.list.url()
									);
								}
							}}
						>
							<h2 className={classNames("text-xl md:text-3xl text-gray-700")}>
								{status.value}
							</h2>
							<p
								className={classNames(
									"m-0 text-[9px] md:text-sm font-medium",
									status.className
								)}
							>
								{status.label}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const ticketTabType =
		feedbackSelectors.getFeedbackTicketTabType(feedbackStore);
	const userEmail = store.app.auth.user?.email ?? "";
	const statsValue = feedbackSelectors.getDashboardStats(
		feedbackStore,
		ticketTabType === ETicketTabType.MY_TICKETS ? "myTickets" : "allTickets"
	);
	const dateRange = feedbackSelectors.getDateRange(feedbackStore);
	const jobToLoadApi =
		feedbackSelectors.jobToLoadDashboardFeedbacksAndStats(feedbackStore);

	const user = store.app.auth.user;
	const assignedToOption = {
		label: user?.name,
		// @ts-ignore
		value: user?.userId,
	} as IOption;

	return {
		ticketTabType,
		userEmail,
		statsValue,
		dateRange,
		jobToLoadApi,
		assignedToOption,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToTab(urlStr: string) {
			return dispatch(historyActions.push("FEEDBACK", urlStr));
		},
		loadDashboardStats(payload: IDashboardStatsAndFeedbackPayload) {
			dispatch(
				feedbackActions.commands.feedbackDashboardStatsOrFeedbacksLoad(payload)
			);
		},
		setFilterState(state: Partial<IFilterState>) {
			dispatch(feedbackActions.document.feedbackFilterStateSet(state));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackTicketStatusCount);
