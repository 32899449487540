import withJobs from "redux/_core/job/reducers";
import { createReducer } from "typesafe-actions";
import {
	ETicketTabType,
	IFeebackActions_Document,
	IFeedbackStore,
} from "./@types";
import feedbackActions from "./actions";
import feedbackConstants, { FEEDBACKS_PER_PAGE } from "./constants";
import { filterSections } from "./utils";
import { initialStats } from "./constants";

const initialState: IFeedbackStore = {
	notifications: [],
	notificationsPagination: {
		currentPage: 1,
		itemsPerPage: FEEDBACKS_PER_PAGE,
		totalItems: 0,
	},
	feedbacks: [],
	_jobs: {},
	vessels: {
		byIds: {},
		ids: [],
	},
	vesselSpecificUsers: {
		byIds: {},
		ids: [],
	},
	isFeedbackChatModalOpen: false,
	selectedFeedbackId: "",
	selectedFeedbackVesselId: "",
	chats: [],
	individualFeedbackWithChats: undefined,
	message: "",
	activeChatAttachments: null,
	isMessageBeingGenerated: false,
	searchBy: "",
	filters: {
		vesselId: "",
		showPendingMessages: undefined,
	},
	_pagination: {
		currentPage: 1,
		itemsPerPage: FEEDBACKS_PER_PAGE,
		totalItems: 0,
	},
	isFeedbackFormModalOpen: false,
	tasks: {
		byIds: {},
		ids: [],
	},
	isFeedbackFiltersOpen: false,
	currentSelectedFeedbackFilterSection: filterSections[0],
	filterState: {},
	ticketTabType: ETicketTabType.MY_TICKETS,
	dashboard: {
		myTickets: {
			feedbacks: {
				highPriority: [],
				waitingForReply: [],
				dueThisWeek: [],
				ticketsToBeAssigned: [],
				ticketsOverdue: [],
			},
			stats: { ...initialStats },
		},
		allTickets: {
			feedbacks: {
				highPriority: [],
				waitingForReply: [],
				dueThisWeek: [],
				ticketsToBeAssigned: [],
				ticketsOverdue: [],
			},
			stats: { ...initialStats },
		},
		dateRange: [
			{
				startDate: undefined,
				endDate: undefined,
				key: "selection",
			},
		],
	},
};

const feedbackReducers = createReducer<
	IFeedbackStore,
	IFeebackActions_Document
>(initialState)
	.handleAction(
		feedbackActions.document.feedbackListSet,
		function feedbackListSet(state, action) {
			const feedbacks = action.payload.feedbacks ?? [];
			return {
				...state,
				feedbacks,
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackVesselsSet,
		function feedbackVesselsSet(state, action) {
			const { vessels } = action.payload;
			return {
				...state,
				vessels,
			};
		}
	)
	.handleAction(
		feedbackActions.document.togglefeedbackChatModal,
		function feedbackVesselsSet(state, action) {
			return {
				...state,
				isFeedbackChatModalOpen: !state.isFeedbackChatModalOpen,
			};
		}
	)
	.handleAction(
		feedbackActions.document.selectedFeedbackIdSet,
		function selectedFeedbackIdSet(state, action) {
			const { feedbackId } = action.payload;

			return {
				...state,
				selectedFeedbackId: feedbackId,
			};
		}
	)
	.handleAction(
		feedbackActions.document.selectedFeedbackVesselIdSet,
		function selectedFeedbackVesselIdSet(state, action) {
			const { vesselId } = action.payload;
			return {
				...state,
				selectedFeedbackVesselId: vesselId,
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackChatsSet,
		function feedbackChatsSet(state, action) {
			const { chats } = action.payload;
			return {
				...state,
				chats,
			};
		}
	)
	.handleAction(
		feedbackActions.document.selectedChatListMessageSet,
		function selectedChatListMessageSet(state, action) {
			const { message } = action.payload;
			return {
				...state,
				message,
			};
		}
	)
	.handleAction(
		feedbackActions.document.selectedChatListAttachmentSet,
		function selectedChatListMessageSet(state, action) {
			const { attachments } = action.payload;
			return {
				...state,
				activeChatAttachments: attachments,
			};
		}
	)
	.handleAction(
		feedbackActions.document.addChatToChatList,
		function addChatToChatList(state, action) {
			const { chat } = action.payload;

			return {
				...state,
				chats: [...state.chats, chat],
			};
		}
	)
	.handleAction(
		feedbackActions.document.searchFeedbackByIssueSet,
		function searchFeedbackByIssueSet(state, action) {
			const { searchBy } = action.payload;
			return {
				...state,
				searchBy,
			};
		}
	)
	// .handleAction(
	// 	feedbackActions.document.feedbackFiltersSet,
	// 	function feedbackFiltersSet(state, action) {
	// 		return {
	// 			...state,
	// 			filters: action.payload.filters,
	// 		};
	// 	}
	// )
	.handleAction(
		feedbackActions.document.notificationsPaginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				notificationsPagination: {
					...state.notificationsPagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.notificationsPaginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				notificationsPagination: {
					...state.notificationsPagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.notificationsPaginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				notificationsPagination: {
					...state.notificationsPagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.notificationsPaginationReset,
		function reset(state, action) {
			return {
				...state,
				notificationsPagination: {
					currentPage: 1,
					itemsPerPage: FEEDBACKS_PER_PAGE,
					totalItems: 0,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.paginationTotalItemsSet,
		function totalItemsSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					totalItems: action.payload.totalItems,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.paginationItemsPerPageSet,
		function itemsPerPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					itemsPerPage: action.payload.itemsPerPage,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.paginationCurrentPageSet,
		function currentPageSet(state, action) {
			return {
				...state,
				_pagination: {
					...state._pagination,
					currentPage: action.payload.currentPage,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.paginationReset,
		function reset(state, action) {
			return {
				...state,
				_pagination: {
					currentPage: 1,
					itemsPerPage: FEEDBACKS_PER_PAGE,
					totalItems: 0,
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.addChatToChatListInIndividualFeedback,
		function addChatToChatListInIndividualFeedback(state, action) {
			const { chat } = action.payload;

			if (state.individualFeedbackWithChats)
				return {
					...state,
					individualFeedbackWithChats: {
						...state.individualFeedbackWithChats,
						chatMessages: state?.individualFeedbackWithChats?.chatMessages
							?.length
							? [...state?.individualFeedbackWithChats?.chatMessages, chat]
							: [chat],
					},
				};
			else {
				return {
					...state,
				};
			}
		}
	)
	.handleAction(
		feedbackActions.document.feedbackChatsSetInIndividualFeedback,
		function feedbackChatsSetInIndividualFeedback(state, action) {
			const { feedback } = action.payload;
			return {
				...state,
				individualFeedbackWithChats: feedback,
			};
		}
	)
	.handleAction(
		feedbackActions.document.setChatAttachmentUploadProgress,
		function setChatAttachmentUploadProgress(state, action) {
			const { isBeingUploaded } = action.payload;
			return {
				...state,
				isMessageBeingGenerated: isBeingUploaded,
			};
		}
	)
	.handleAction(
		feedbackActions.document.updateCurrentFeedback,
		function updateCurrentFeedback(state, action) {
			const { payload } = action.payload;
			return state?.individualFeedbackWithChats
				? {
						...state,
						individualFeedbackWithChats: {
							...state.individualFeedbackWithChats,
							...payload,
						},
				  }
				: state;
		}
	)
	.handleAction(
		feedbackActions.document.updateActivityListForSelectedFeedback,
		function updateActivityListForSelectedFeedback(state, action) {
			const { activity } = action.payload;
			return state?.individualFeedbackWithChats
				? {
						...state,
						individualFeedbackWithChats: {
							...state.individualFeedbackWithChats,
							activity: [
								...(state?.individualFeedbackWithChats?.activity ?? []),
								activity,
							],
						},
				  }
				: state;
		}
	)
	.handleAction(
		feedbackActions.document.togglefeedbackFormModal,
		function togglefeedbackFormModal(state, action) {
			return {
				...state,
				isFeedbackFormModalOpen: !state.isFeedbackFormModalOpen,
			};
		}
	)
	.handleAction(
		feedbackActions.document.tasksSet,
		function taskSet(state, action) {
			return {
				...state,
				tasks: action.payload.tasks,
			};
		}
	)
	.handleAction(
		feedbackActions.document.notificationsSet,
		function notificationsSet(state, action) {
			const { notifications } = action.payload;
			return {
				...state,
				notifications: notifications,
			};
		}
	)
	.handleAction(
		feedbackActions.document.notificationsAppend,
		function notificationsAppend(state, action) {
			const { notifications } = action.payload;
			const newNotifications = [...(state.notifications || [])];

			notifications.forEach((notification) => {
				const index = newNotifications.findIndex(
					(n) => n._id === notification._id
				);
				if (index === -1) {
					newNotifications.push(notification);
				}
			});

			return {
				...state,
				notifications: newNotifications,
			};
		}
	)
	.handleAction(
		feedbackActions.document.vesselSpecificUsersSet,
		function vesselSpecificUsersSet(state, action) {
			const { vesselUsers } = action.payload;
			return {
				...state,
				vesselSpecificUsers: vesselUsers,
			};
		}
	)
	.handleAction(
		feedbackActions.document.toggleFeedbackFiltersModal,
		function toggleFeedbackFiltersModal(state, action) {
			const { isOpen } = action.payload;
			return {
				...state,
				isFeedbackFiltersOpen:
					typeof isOpen === "boolean" ? isOpen : !state.isFeedbackFiltersOpen,
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackFilterSectionSet,
		function feedbackFilterSectionSet(state, action) {
			const { filterSection } = action.payload;
			return {
				...state,
				currentSelectedFeedbackFilterSection: filterSection,
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackFilterStateSet,
		function feedbackFilterStateSet(state, action) {
			const { filter } = action.payload;
			return {
				...state,
				filterState: { ...state.filterState, ...filter },
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackFilterStateReset,
		function feedbackFilterStateReset(state, action) {
			return {
				...state,
				filterState: {},
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackTicketTabTypeSet,
		function feedbackTicketTabTypeSet(state, action) {
			const { ticketTabType } = action.payload;
			return {
				...state,
				ticketTabType,
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackDashboardListSet,
		function feedbackDashboardListSet(state, action) {
			const { feedbacks, filterType, tabType } = action.payload;
			const tab =
				tabType === ETicketTabType.MY_TICKETS ? "myTickets" : "allTickets";
			return {
				...state,
				dashboard: {
					...state.dashboard,
					[tab]: {
						...state.dashboard[tab],
						feedbacks: {
							...state.dashboard[tab]?.feedbacks,
							[filterType]: feedbacks,
						},
					},
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackDashboardsStatsSet,
		function feedbackDashboardsStatsSet(state, action) {
			const { stats, tabType } = action.payload;
			const tab =
				tabType === ETicketTabType.MY_TICKETS ? "myTickets" : "allTickets";
			return {
				...state,
				dashboard: {
					...state.dashboard,
					[tab]: {
						...state.dashboard[tab],
						stats: {
							...stats,
						},
					},
				},
			};
		}
	)
	.handleAction(
		feedbackActions.document.feedbackDashboardDateRangeSet,
		function feedbackDashboardDateRangeSet(state, action) {
			const { dateRange } = action.payload;

			return {
				...state,
				dashboard: {
					...state.dashboard,
					dateRange,
				},
			};
		}
	);
export default withJobs(
	feedbackConstants.FEATURE,
	initialState,
	feedbackReducers
);
