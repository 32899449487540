import React from "react";
type SVGVersionProps = { className?: string };
const SVGVersion: React.FC<SVGVersionProps> = (props) => (
	<svg
		width="26"
		height="25"
		viewBox="0 0 26 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M3.0957 14.8809L13.0005 19.6428L22.9263 14.8809M3.0957 10.3059L13.0104 14.8809L22.9263 10.3059L13.0104 5.35713L3.0957 10.3059Z"
			stroke="#B7AEAE"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGVersion;
