import React from "react";
import VesselList from "../../../../components/app/vessel/list/List";
type ScreenVesseListType = React.PropsWithChildren<{}>;
const ScreenVesseList: React.FC<ScreenVesseListType> = (
	props: ScreenVesseListType
) => {
	return <VesselList />;
};

export default ScreenVesseList;
