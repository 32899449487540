import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Feedback from "components/app/feedback/Feedback";
import { rootRoutes } from "../../../routes";
import ScreenFeedbackList from "./list";
import ScreenFeedbackView from "./view";
import ScreenFeedbackDashboard from "./dashboard";

const feedbackRoutes = rootRoutes.children.app.children.feedback.children;
type ScreenFeedbackProps = React.PropsWithChildren<{}>;
function ScreenFeedback(props: ScreenFeedbackProps) {
	return (
		<Feedback>
			<Switch>
				<Route path={feedbackRoutes.list.path} component={ScreenFeedbackList} />
				<Route path={feedbackRoutes.view.path} component={ScreenFeedbackView} />
				<Route
					path={feedbackRoutes.dashboard.path}
					component={ScreenFeedbackDashboard}
				/>
				<Route render={RedirectComponent} />
			</Switch>
		</Feedback>
	);
}

function RedirectComponent() {
	return <Redirect to={feedbackRoutes.list.url()} />;
}
export default ScreenFeedback;
