import { Middleware } from "redux";
import { historyActions } from ".";
import constants from "./constants";

const {
	HISTORY_CLEAR,
	HISTORY_POP,
	HISTORY_PUSH,
	HISTORY_REPLACE,
	HISTORY_RESET,
} = constants;
const historyMiddleware: Middleware = ({ dispatch }) => (next) => (
	action: any
) => {
	next(action);
	switch (action.type) {
		case HISTORY_CLEAR:
		case HISTORY_POP:
		case HISTORY_PUSH:
		case HISTORY_REPLACE:
			setTimeout(() => dispatch(historyActions.reset()), 100);
			break;
	}
};
export default historyMiddleware;
