import React from "react";
type SVGItemProps = { className?: string };
const SVGItem: React.FC<SVGItemProps> = (props) => (
	<svg
		width="18"
		height="24"
		viewBox="0 0 18 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={props.className}
	>
		<path
			d="M11.5 2H3C2.46957 2 1.96086 2.21071 1.58579 2.58579C1.21071 2.96086 1 3.46957 1 4V20C1 20.5304 1.21071 21.0391 1.58579 21.4142C1.96086 21.7893 2.46957 22 3 22H15C15.5304 22 16.0391 21.7893 16.4142 21.4142C16.7893 21.0391 17 20.5304 17 20V7.5L11.5 2Z"
			stroke="#718096"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 2V8H17M5 13H7M5 17H7M11 13H13M11 17H13"
			stroke="#718096"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SVGItem;
