import React from "react";
import FeedbackList from "components/app/feedback/list/List"

type ScreenFeedbackListProps = React.PropsWithChildren<{
	match: {
		params: {
			pageNumber: string;
		};
	};
}>;

function ScreenFeedbackList(props:ScreenFeedbackListProps) {
	return <FeedbackList pageNumber={props.match.params.pageNumber} />;

}

export default ScreenFeedbackList;
