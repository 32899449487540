import React from "react";
import ORS from "components/app/report/waste/ors/ORS";

type ScreenORSProps = React.PropsWithChildren<{}>;

function ScreenORS(props: ScreenORSProps) {
	return <ORS />;
}

export default ScreenORS;
