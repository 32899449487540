import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Config from "../config";
import { Tooltip } from "react-tippy";
import SVGCopy from "components/_common/icons/tool/SVGCopy";
import Button from "@set-product/core/button/Button";
import ModalPortal from "components/app/_common/ModalPortal";
import toolSelectors from "redux/app/tool/selectors";
import toolActions from "redux/app/tool/actions";
import { resetPaginationOfCurrentPage } from "redux/app/tool/utils";
import ConfigCopy from "../config/_elements/ConfigCopy";
import ConfigCreate from "../config/_elements/ConfigCreate";
import ConfigUpdate from "../config/_elements/ConfigUpdate";
import ConfigView from "../config/_elements/ConfigView";

type OwnProps = {};
type ConfigRendererProps = PropsFromRedux & OwnProps;
function ConfigRenderer(props: ConfigRendererProps) {
	const {
		toolType,
		selectedItems,
		isCopyModalOpen,
		isUpdateModalOpen,
		isConfigAddModalOpen,
		isViewModalOpen,
		selectedTenantOption,
		selectedVesselOption,
		searchInputVal,
		configActiveTab,
		isConfigFormExpanded,
	} = props;

	useEffect(() => {
		props.setSearch("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toolType, configActiveTab]);

	// Reset currentPage to 1 in pagination after every search
	useEffect(() => {
		props.setCurrentPagePaginationTo1();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInputVal]);

	// Reset the current page on change of vessel or tenant
	// Unselect all items on change of vessel/Dropdown
	useEffect(() => {
		props.resetCurrentPagePagination();
		props.setSelectedItems([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTenantOption?.value, selectedVesselOption?.value]);
	return (
		<>
			<Config>
				<div className="w-full flex items-center  mt-4">
					<div className="w-full">
						<input
							type="text"
							placeholder="Search"
							className=" p-2 border bg-[#F7F7F7] border-[#F7F7F7] h-full w-full rounded-md"
							value={searchInputVal}
							onChange={(e) => {
								props.setSearch(e.target.value);
							}}
						/>
					</div>
					{selectedItems.length > 0 && (
						<Tooltip title={"Copy"}>
							<div className="flex items-center">
								<div
									className="ml-2 rounded-full flex items-center p-2.5 border border-[#D8D8D8] cursor-pointer hover:shadow-md"
									onClick={() => {
										props.toggleCopyModal();
									}}
								>
									<SVGCopy />
								</div>
							</div>
						</Tooltip>
					)}
					{selectedItems.length > 0 && (
						<Tooltip title={"Unselect"}>
							<div className="flex items-center">
								<div
									className="ml-2 rounded-full flex items-center p-2.5 border border-[#D8D8D8] cursor-pointer hover:shadow-md"
									onClick={() => {
										props.setSelectedItems([]);
									}}
								>
									<span className="bp3-icon bp3-icon-remove text-lg text-gray-500" />
								</div>
							</div>
						</Tooltip>
					)}
					<Button
						className="px-6  bg-blue-700 whitespace-nowrap disabled:bg-gray-300 md:ml-4 md:mb-0"
						onClick={() => {
							props.toggleConfigAddModal();
						}}
						disabled={
							!selectedTenantOption?.value ||
							!selectedVesselOption?.value ||
							!!selectedItems.length
						}
						icon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
							>
								<path
									d="M7.99935 13.8332V8.49984M7.99935 8.49984V3.1665M7.99935 8.49984H13.3327M7.99935 8.49984H2.66602"
									stroke="white"
									strokeWidth="2"
									strokeLinecap="round"
								/>
							</svg>
						}
					>
						Add
					</Button>
				</div>
			</Config>

			{isCopyModalOpen && (
				<ModalPortal
					isActive={isCopyModalOpen}
					onClose={() => {
						props.toggleCopyModal();
					}}
					width={650}
					isOverlayClickCloseable={false}
				>
					<ConfigCopy />
				</ModalPortal>
			)}
			{isConfigAddModalOpen && (
				<ModalPortal
					isActive={isConfigAddModalOpen}
					onClose={() => {
						props.toggleConfigAddModal();
					}}
					width={isConfigFormExpanded ? "100%" : 650}
					isOverlayClickCloseable={false}
				>
					<ConfigCreate />
				</ModalPortal>
			)}
			{isUpdateModalOpen && (
				<ModalPortal
					isActive={isUpdateModalOpen}
					onClose={() => {
						props.toggleUpdateModal();
					}}
					width={isConfigFormExpanded ? "100%" : 650}
					isOverlayClickCloseable={false}
				>
					<ConfigUpdate />
				</ModalPortal>
			)}
			{isViewModalOpen && (
				<ModalPortal
					isActive={isViewModalOpen}
					onClose={() => {
						props.toggleConfigViewModal();
					}}
					width={isConfigFormExpanded ? "100%" : 650}
					isOverlayClickCloseable={false}
					className="overscroll-x-contain"
				>
					<ConfigView />
				</ModalPortal>
			)}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const toolStore = toolSelectors._getStore(store);
	const configActiveTab = toolSelectors.getActiveTabForConfig(toolStore);

	const selectedTenantOption = toolSelectors.getSelectedTenantOption(toolStore);
	const selectedVesselOption = toolSelectors.getSelectedVesselOption(toolStore);
	const isViewModalOpen = toolSelectors.getIsConfigViewModalOpen(toolStore);
	const searchInputVal = toolSelectors.getSearchInputValue(toolStore);
	const isConfigFormExpanded = toolSelectors.getIsConfigFormExpanded(toolStore);
	return {
		toolType: toolSelectors.getConfigPrintToolType(toolStore),
		configActiveTab,
		selectedItems: toolSelectors.getSelectedItems(toolStore),
		isCopyModalOpen: toolSelectors.getIsCopyModalOpen(toolStore),
		isUpdateModalOpen: toolSelectors.getIsUpdateModalOpen(toolStore),
		isConfigAddModalOpen: toolSelectors.getIsConfigAddModalOpen(toolStore),
		selectedTenantOption,
		selectedVesselOption,
		isViewModalOpen,
		searchInputVal,
		isConfigFormExpanded,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		toggleCopyModal() {
			dispatch(toolActions.document.copyModalToggle());
		},
		toggleUpdateModal() {
			dispatch(toolActions.document.updateModalToggle());
		},
		toggleConfigAddModal() {
			dispatch(toolActions.document.configAddModalToggle());
		},
		toggleConfigViewModal() {
			dispatch(toolActions.document.configViewModalToggle());
		},
		setSearch(searchInput: string) {
			dispatch(toolActions.document.toolConfigPrintSearchInputSet(searchInput));
		},
		resetCurrentPagePagination() {
			resetPaginationOfCurrentPage(dispatch);
		},
		setSelectedItems(selectedItems: string[]) {
			dispatch(toolActions.document.toolConfigItemsSelect(selectedItems));
		},
		setCurrentPagePaginationTo1() {
			dispatch(toolActions.commands.toolConfigOnSearchCurrentPageTo1Set());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ConfigRenderer);
