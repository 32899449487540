import {
	EParameterTabType,
	IMetricListItem,
	INoonReportMetricListItem,
	TRecentList,
} from "./@types";

const insightConstants = {
	FEATURE: "@insight",
	RECENT_LIST_VERSION: "1.0.0",
	LS_RECENT_LIST: "recentList",
};

// Local storage structure;
export const initialRecentList = {
	[insightConstants.RECENT_LIST_VERSION]: {
		engineLogMetricsRecentList: [] as IMetricListItem[],
		noonReportMetricsRecentList: [] as INoonReportMetricListItem[],
	},
} as TRecentList;

export const lineChartColors = [
	"#0b84a5",
	"#ca472f",
	"#6f4e7c",
	"#f6c85f",
	"#9ed867",
	"#ffa056",
	"#8dddd0",
	"#fd7f6f",
	"#7eb0d5",
	"#b2e061",
	"#bd7ebe",
	"#ffb55a",
	"#ffee65",
	"#beb9db",
	"#fdcce5",
	"#8bd3c7",
];

export const RECENT_LIST_KEYS = {
	[EParameterTabType.NOON_REPORT_TAB]: "noonReportMetricsRecentList",
	[EParameterTabType.ENGINE_LOG_TAB]: "engineLogMetricsRecentList",
};

export default insightConstants;
