import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import CustomTooltip from "./CustomTooltip";
import reportSelectors from "redux/app/report/selectors";
import { transformDataForGarbageDischargeForAllVessels } from "redux/app/report/utils";

const xAxisScale = "Qtr";
const yAxisScale = "m³";

const dischargeMethods = [
	{
		name: "Disposed ashore",
		key: "disposedAshore",
		stackId: "a",
		color: "#add8e6",
	},
	{
		name: "Discharged to sea",
		key: "dischargedToSea",
		stackId: "a",
		color: "#05a3e0",
	},
	{
		name: "Incinerated",
		key: "incinerated",
		stackId: "a",
		color: "#004bd4",
	},
	{
		name: "Accidental release",
		key: "accidentalRelease",
		stackId: "a",
		color: "#003087",
	},
];

type OwnProps = {
	width: number;
};
type GarbageDischargeGraphProps = PropsFromRedux & OwnProps;
function GarbageDischargeGraph(props: GarbageDischargeGraphProps) {
	const { width, garbageDischargeAllVesselsData, year } = props;

	const finalData = useMemo(() => {
		return (
			transformDataForGarbageDischargeForAllVessels(
				garbageDischargeAllVesselsData
			) ?? []
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [garbageDischargeAllVesselsData, year]);

	return (
		<div className="w-full flex flex-col justify-between items-center">
			<div className="flex flex-col items-center">
				<h3 className="font-medium">Garbage - total (m³)</h3>
				<div className="flex flex-wrap p-4 pb-1.5">
					{dischargeMethods.map((category) => {
						const { name, color } = category;
						return (
							<div className="flex  md:min-w-[180px] lg:min-w-[150px] xl:min-w-[170px] basis-1/3  lg:text-[12px] xl:text-sm space-x-1.5 py-0.5 items-center pl-1.5">
								<span
									style={{ background: color }}
									className="w-4 h-4 rounded-full"
								/>
								<div>
									<span>{name}</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="flex flex-col">
				<div className="axis flex justify-end text-[#7E7E7E]">
					<p className="m-0 text-[12px] font-medium px-3.5 border-r-2 border-[#7E7E7E]">
						X-axis - {xAxisScale}
					</p>
					<p className="m-0 text-[12px] font-medium px-3.5">
						Y-axis - {yAxisScale}
					</p>
				</div>
				<ResponsiveContainer
					width={width > 900 ? width * 0.4 : 0.75 * width}
					height={375}
				>
					<BarChart
						width={width}
						data={finalData}
						margin={{
							top: 25,
							right: 10,
							left: -20,
							bottom: 5,
						}}
						barGap={0}
					>
						<CartesianGrid vertical={false} fill="" />
						<XAxis tickLine={false} dataKey="quarter" fontSize={14} />
						<YAxis tickLine={false} fontSize={14} />
						<Tooltip content={<CustomTooltip legends={dischargeMethods} />} />

						{dischargeMethods.map((discharge) => {
							const { color, key, stackId, name } = discharge;

							return (
								<Bar
									barSize={35}
									key={name}
									dataKey={key}
									stackId={stackId}
									fill={color}
								>
									<LabelList
										className="text-[12px]"
										dataKey={"total"}
										position="top"
									/>
								</Bar>
							);
						})}
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const garbageDischargeAllVesselsData =
		reportSelectors.getGarbageDischargeAllVesselsData(reportStore);
	const year = reportSelectors.getGarbageYear(reportStore);
	return {
		garbageDischargeAllVesselsData,
		year,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(GarbageDischargeGraph);
