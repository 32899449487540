import React from "react";
import { Middleware } from "redux";
import { apiActions, apiUtils } from "redux/_core/api";
import { IStore } from "../../store";
import {
	IDashboardStatsAndFeedbackPayload,
	IDashboardStatsRes,
	IFeedback,
	IFeedbackActions,
	IFeedbackChat,
	IFilterState,
	IIndividualFeedbackWithChat,
	INotification,
	IVessel,
	IVesselUser,
	TCount,
	TDashboardStatsAndFeedbackResponse,
	TTaskTemplate,
} from "./@types";
import feedbackActions, { feedbackJobActions } from "./actions";
import feedbackAPIs from "./api";
import { commonUtils } from "redux/_common";
import feedbackSelectors from "./selectors";
import { jobActions } from "redux/_core/job";
import feedbackConstants from "./constants";
import userSelectors from "../user/selectors";
import { getUserOptions } from "./utils";
import { initialStats } from "./constants";

function debouncedSearch(props: {
	dispatch: any;
	job: any;
	searchBy: any;
	authToken: any;
	userId: any;
}) {
	const { dispatch, job, searchBy, authToken, userId } = props;
	dispatch(
		apiActions.apiRequest<IFeedback[]>({
			...feedbackAPIs.searchFeedbacks(
				{ queryString: searchBy },
				authToken,
				userId
			),
			preExecute() {
				dispatch(
					job.active({
						message: "Searching feedbacks",
						notification: {},
					})
				);
			},
			postExecute: {
				onSuccess({ data }) {
					// const vesselSpecificUsers = commonUtils.arrayToObject(data);
					// dispatch(
					// 	feedbackActions.document.vesselSpecificUsersSet(
					// 		vesselSpecificUsers
					// 	)
					// );

					console.log("search feedbacks", data);

					dispatch(
						feedbackActions.document.feedbackListSet(data ?? [])
					);
					dispatch(
						job.success({
							message: `Found ${data?.length} feedbacks`,
							notification: {
								// hideAtState: "SUCCESS",
								// timeout: 100,
							},
						})
					);
				},
				onError() {
					dispatch(
						job.error({
							message: "Error loading Users",
							notification: {},
						})
					);
				},
				finally() {
					dispatch(job.idle({}));
				},
			},
		})
	);
}

const debouncedSearchQuery = commonUtils.debounce(debouncedSearch, 1000);

const { apiRequest } = apiActions;
const feedbackMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action: IFeedbackActions) => {
		switch (action.type) {
			case "@feedbacks/notifications/LOAD": {
				next(action);
				const { pageNumber } = action.payload;
				const job = feedbackJobActions.notificationsLoad;
				const feedbackStore = feedbackSelectors._getStore(getState());
				const pagination = feedbackStore.notificationsPagination;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				dispatch(
					apiRequest<INotification[]>({
						...feedbackAPIs.loadNotifications(
							{
								token,
								userId
							},
							pagination.itemsPerPage,
							pageNumber
						),
						preExecute() {
							dispatch(
								feedbackActions.document.notificationsPaginationCurrentPageSet(
									pageNumber
								)
							);
							dispatch(
								job.active({
									message: `Loading notifications`,
								})
							);
						},
						postExecute: {
							onSuccess({ data: notifications }) {
								dispatch(
									feedbackActions.document.notificationsAppend(
										notifications
									)
								);
							},
							onError() {
								dispatch(
									job.error({
										notification: {},
										message: "Failed to load notifications",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/list/LOAD": {
				next(action);
				const { pageNumber } = action.payload;
				const job = feedbackJobActions.feedbackListLoad;
				const feedbackStore = feedbackSelectors._getStore(getState());
				const filterState = feedbackSelectors.getFiltersState(
					feedbackStore
				) as IFilterState & { assignedToEmail?: string };
				const pagination = feedbackStore._pagination;

				if (filterState?.assignedTo?.value) {
					const usersStore = userSelectors._getStore(getState());
					const users = userSelectors.getUsersList(usersStore);
					const validUsers = getUserOptions(users);
					const assignedUserEmail =
						validUsers.find(
							(user) => user.id === filterState?.assignedTo?.value
						)?.email ?? "";
					if (assignedUserEmail) {
						filterState.assignedToEmail = assignedUserEmail;
					}
				} else {
					"assignedToEmail" in filterState &&
						delete filterState.assignedToEmail;
				}
				const isFilterModalOpen =
					feedbackSelectors.getIsFeedbackFiltersModalOpen(
						feedbackStore
					);

				dispatch(
					apiRequest<IFeedback[]>({
						...feedbackAPIs.loadFeedbackList(
							filterState,
							pagination.itemsPerPage,
							pageNumber
						),
						preExecute() {
							dispatch(
								feedbackActions.document.paginationCurrentPageSet(
									pageNumber
								)
							);
							dispatch(
								job.active({
									message: `Loading feedbacks`,
									// notification: {},
								})
							);
						},
						postExecute: {
							onSuccess({ data: feedbacks }) {
								if (feedbacks && feedbacks?.length) {
									dispatch(
										feedbackActions.document.feedbackListSet(
											feedbacks ?? []
										)
									);

									dispatch(
										job.success({
											message:
												"Feedbacks loaded successfully",
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
								} else {
									dispatch(
										feedbackActions.document.feedbackListSet(
											[]
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										notification: {},
										message: "Failed to load feedbacks",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/list/count/LOAD": {
				next(action);
				// const { vesselId } = action.payload;
				const job = feedbackJobActions.feedbackTotalCountLoad;
				const feedbackStore = feedbackSelectors._getStore(getState());
				const filterState = feedbackSelectors.getFiltersState(
					feedbackStore
				) as IFilterState & { assignedToEmail?: string };
				// filterState.vesselId = vesselId;
				if (filterState?.assignedTo?.value) {
					const usersStore = userSelectors._getStore(getState());
					const users = userSelectors.getUsersList(usersStore);
					const validUsers = getUserOptions(users);
					const assignedUserEmail =
						validUsers.find(
							(user) => user.id === filterState?.assignedTo?.value
						)?.email ?? "";
					if (assignedUserEmail) {
						filterState.assignedToEmail = assignedUserEmail;
					}
				} else {
					"assignedToEmail" in filterState &&
						delete filterState.assignedToEmail;
				}
				dispatch(
					apiRequest<TCount>({
						...feedbackAPIs.loadFeedbackTotalCount(filterState),
						preExecute() {},
						postExecute: {
							onSuccess({ data }) {
								if (typeof data?.count === "number") {
									dispatch(
										feedbackActions.document.paginationTotalItemsSet(
											data.count
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load count",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/vessels/LOAD": {
				next(action);
				const job = feedbackJobActions.feedbackVesselsLoad;

				dispatch(
					apiRequest<IVessel[]>({
						...feedbackAPIs.loadVessles(),
						preExecute() {},
						postExecute: {
							onSuccess({ data: vessels }) {
								if (vessels && vessels?.length) {
									dispatch(
										feedbackActions.document.feedbackVesselsSet(
											commonUtils.arrayToObject(vessels)
										)
									);
								} else {
									dispatch(
										feedbackActions.document.feedbackVesselsSet(
											commonUtils.arrayToObject([])
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load vessels",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/chats/LOAD": {
				next(action);
				const job = feedbackJobActions.feedbackChatsLoad;
				const { feedbackId } = action.payload;

				dispatch(
					apiRequest<IFeedbackChat[]>({
						...feedbackAPIs.loadChatsForFeedback(feedbackId),
						preExecute() {
							dispatch(
								job.active({
									message: "Loading Chats",
									// notification: {},
								})
							);
						},
						postExecute: {
							onSuccess({ data: chats }) {
								if (chats && chats?.length) {
									const modifiedChats = chats.map((chat) => ({
										...chat,
										attachmentType: "url" as
											| "url"
											| "local"
											| undefined,
									}));
									console.log({ modifiedChats });
									dispatch(
										feedbackActions.document.feedbackChatsSet(
											modifiedChats
										)
									);

									dispatch(
										job.success({
											message:
												"Chats loaded successfully",
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
								} else {
									dispatch(
										feedbackActions.document.feedbackChatsSet(
											[]
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load chats",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/individual-feedback/LOAD": {
				next(action);
				const job = feedbackJobActions.individualFeedbackLoad;
				const { feedbackId } = action.payload;

				dispatch(
					apiRequest<IIndividualFeedbackWithChat>({
						...feedbackAPIs.loadFeedbackWithChats(feedbackId),
						preExecute() {
							dispatch(
								job.active({
									message: "Loading Chats",
									// notification: {},
								})
							);
						},
						postExecute: {
							onSuccess({ data: feedback }) {
								if (feedback) {
									feedback.chatMessages =
										feedback.chatMessages.map(
											(message) => ({
												...message,
												attachmentType: "url",
											})
										);
									dispatch(
										feedbackActions.document.feedbackChatsSetInIndividualFeedback(
											feedback
										)
									);

									dispatch(
										job.success({
											message:
												"Chats loaded successfully",
											notification: {
												hideAtState: "SUCCESS",
												timeout: 100,
											},
										})
									);
								} else {
									dispatch(
										feedbackActions.document.feedbackChatsSetInIndividualFeedback(
											undefined
										)
									);
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load chats",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/chat-message/:update": {
				next(action);

				const { id, attachmentsUrls } = action.payload;
				const job = jobActions(feedbackConstants.FEATURE, action.type);

				if (
					id &&
					attachmentsUrls &&
					Array.isArray(attachmentsUrls) &&
					attachmentsUrls.length > 0
				) {
					dispatch(
						apiRequest<IFeedbackChat>({
							...feedbackAPIs.updateChatWithAttachment(
								id,
								attachmentsUrls
							),
							preExecute() {
								dispatch(
									feedbackActions.document.setChatAttachmentUploadProgress(
										true
									)
								);
								dispatch(job.active({}));
							},
							postExecute: {
								onSuccess({ data }) {
									dispatch(
										job.success({
											message: "Message sent!",
										})
									);
									dispatch(
										feedbackActions.document.selectedChatListMessageSet(
											""
										)
									);

									return;
								},
								onError() {
									dispatch(job.error({}));
								},
								finally() {
									dispatch(
										feedbackActions.document.setChatAttachmentUploadProgress(
											false
										)
									);
									dispatch(job.idle({}));
								},
							},
						})
					);
				} else {
					dispatch(job.success({ message: "Message sent!" }));
					dispatch(
						feedbackActions.document.selectedChatListMessageSet("")
					);
					dispatch(
						feedbackActions.document.selectedChatListAttachmentSet(
							[]
						)
					);
				}
				break;
			}
			case "@feedbacks/chat-attachment-upload": {
				next(action);
				// get attachment from payload
				const { attachments, attachmentsUrls, chat } = action.payload;

				const job = jobActions(feedbackConstants.FEATURE, action.type);

				if (
					attachments &&
					Array.isArray(attachments) &&
					attachments?.[0]
				) {
					// make an API request to upload the attachment
					const safeName = attachments?.[0]?.name?.replace(
						/[^a-zA-Z0-9-_\.]/g,
						""
					);
					const safeUrl = encodeURIComponent(safeName);
					let formData = new FormData();

					formData.append("docId", chat._id);
					formData.append("attachmentName", safeName);
					formData.append("type", attachments?.[0]?.type);
					formData.append("attachment", attachments?.[0]);
					dispatch(
						apiActions.apiRequest({
							...feedbackAPIs.uploadAttachment(formData),
							preExecute() {
								dispatch(
									feedbackActions.document.setChatAttachmentUploadProgress(
										true
									)
								);
								dispatch(job.active({}));
							},
							postExecute: {
								onSuccess({ data }) {
									const newAttachments = attachments.slice(1);

									if (
										newAttachments &&
										Array.isArray(newAttachments) &&
										newAttachments.length > 0
									) {
										dispatch(
											feedbackActions.commands.chatAttachmentUpload(
												chat,
												newAttachments,
												[
													...attachmentsUrls,
													{
														name: safeUrl,
														type: attachments?.[0]
															?.type,
													},
												]
											)
										);
									} else {
										dispatch(
											feedbackActions.commands.chatMessageUpdate(
												chat._id,
												[
													...attachmentsUrls,
													{
														name: safeUrl,
														type: attachments?.[0]
															?.type,
													},
												]
											)
										);
									}
									dispatch(job.idle({}));
								},
								onError(error) {
									dispatch(
										job.error({
											message:
												apiUtils.getResponseStatusMessageForError(
													error
												),
										})
									);
									dispatch(
										feedbackActions.document.setChatAttachmentUploadProgress(
											false
										)
									);
									dispatch(job.idle({}));
								},
								finally() {},
							},
						})
					);
				}
				break;
			}
			case "@feedbacks/chat/message/create": {
				next(action);
				const { from } = action.payload;
				const feedbackStore = feedbackSelectors._getStore(getState());
				const feedbackId =
					feedbackSelectors.getSelectedFeedbackId(feedbackStore);
				const message = feedbackSelectors.getMessage(feedbackStore);
				const vesselId =
					feedbackSelectors.getSelectedFeedbackVesselId(
						feedbackStore
					);
				const job = feedbackJobActions.chatMessageSend;
				const attachments =
					feedbackSelectors.getActiveChatAttachments(feedbackStore);

				dispatch(
					apiRequest<IFeedbackChat>({
						...feedbackAPIs.sendChatMessage(
							vesselId,
							message,
							feedbackId
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data: chat }) {
								dispatch(
									feedbackActions.document.selectedChatListAttachmentSet(
										[]
									)
								);
								if (
									attachments &&
									Array.isArray(attachments) &&
									attachments.length > 0
								) {
									dispatch(
										feedbackActions.document.setChatAttachmentUploadProgress(
											true
										)
									);

									from === "chatModal"
										? dispatch(
												feedbackActions.document.addChatToChatList(
													{
														...chat,
														attachments,
														attachmentType: "local",
													}
												)
										  )
										: dispatch(
												feedbackActions.document.addChatToChatListInIndividualFeedback(
													{
														...chat,
														attachments,
														attachmentType: "local",
													}
												)
										  );

									dispatch(
										feedbackActions.commands.chatAttachmentUpload(
											chat,
											attachments,
											[]
										)
									);
								} else {
									if (chat?._id) {
										from === "chatModal"
											? dispatch(
													feedbackActions.document.addChatToChatList(
														chat
													)
											  )
											: dispatch(
													feedbackActions.document.addChatToChatListInIndividualFeedback(
														chat
													)
											  );
										dispatch(
											feedbackActions.document.selectedChatListMessageSet(
												""
											)
										);
										dispatch(
											feedbackActions.document.setChatAttachmentUploadProgress(
												false
											)
										);
									}
								}
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to send message",
									})
								);
								dispatch(
									feedbackActions.document.setChatAttachmentUploadProgress(
										false
									)
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case "@feedbacks/:task-list-load": {
				next(action);

				const job = jobActions(feedbackConstants.FEATURE, action.type);
				dispatch(
					apiActions.apiRequest<TTaskTemplate[]>({
						feature: action.type,
						method: "GET",
						url: "task-search",

						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								const taskTemplates =
									commonUtils.arrayToObject(data);
								dispatch(
									feedbackActions.document.tasksSet(
										taskTemplates
									)
								);
								dispatch(job.success({}));
								// dispatch(
								// 	feedbackActions.events.tasksLoaded(
								// 		taskTemplates
								// 	)
								// );
							},
							onError() {
								dispatch(
									job.error({
										message: "Error loading task templates",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/feedback/:send": {
				next(action);

				const { feedback } = action.payload;
				feedback.versionInfo = {
					FE: feedback?.FE || "",
					BE: feedback?.BE || "",
				};
				const job = jobActions(feedbackConstants.FEATURE, action.type);
				const authStore = getState().app.auth;
				const authToken = authStore.authToken;
				// @ts-ignore
				const userId = authStore?.user?.userId ?? "";

				dispatch(
					apiActions.apiRequest({
						method: "POST",
						feature: action.type,
						url: "https://console.wayship.io/api/feedback",
						headers: {
							// @ts-ignore
							"x-token": authToken,
						},
						body: {
							...feedback,
							userId,
						},
						preExecute() {
							dispatch(
								job.active({
									message: "Sending feedback..",
									notification: {},
								})
							);
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									feedbackActions.commands.feedbackListLoad(1)
								);
								return;
							},
							onError() {
								dispatch(job.error({}));
							},
							finally() {
								dispatch(
									feedbackActions.document.togglefeedbackFormModal()
								);
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			
			case "@feedbacks/vessel-specific-users/LOAD": {
				next(action);
				const { vesselId } = action.payload;
				const job = jobActions(feedbackConstants.FEATURE, action.type);
				dispatch(
					apiActions.apiRequest<IVesselUser[]>({
						...feedbackAPIs.loadVesselSpecificUsers(vesselId),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								const vesselSpecificUsers =
									commonUtils.arrayToObject(data);
								dispatch(
									feedbackActions.document.vesselSpecificUsersSet(
										vesselSpecificUsers
									)
								);
								dispatch(job.success({}));
							},
							onError() {
								dispatch(
									job.error({
										message: "Error loading Users",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/feedback/:update": {
				next(action);
				const authStore = getState().app.auth;
				const authToken = authStore?.authToken ?? "";
				const feedbackStore = feedbackSelectors._getStore(getState());
				const { payload } = action.payload;
				const currentSelectedFeedback =
					feedbackSelectors.getIndividualFeedback(feedbackStore);
				const job = jobActions(feedbackConstants.FEATURE, action.type);

				dispatch(
					apiRequest<IIndividualFeedbackWithChat>({
						...feedbackAPIs.updateFeedback(
							{
								...payload,
								_id: currentSelectedFeedback?._id ?? "",
								// @ts-ignore
								userId: authStore.user?.userId ?? "",
							},
							authToken
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data, status }) {
								dispatch(
									job.success({
										message: "Feedback updated",
									})
								);
								dispatch(
									feedbackActions.document.updateCurrentFeedback(
										{ ...payload }
									)
								);
								const activityList = data?.activity;
								if (status === 200 && activityList?.length) {
									dispatch(
										feedbackActions.document.updateActivityListForSelectedFeedback(
											activityList[
												activityList.length - 1
											]
										)
									);
								}

								return;
							},
							onError(err) {
								console.log({ err }, "error");
								dispatch(
									job.error({
										notification: {},
										message: "Error while updating",
									})
								);
							},
							finally() {
								// currentSelectedFeedback?._id &&
								// 	dispatch(
								// 		feedbackActions.commands.individualFeedbackLoad(
								// 			currentSelectedFeedback?._id
								// 		)
								// 	);
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case "@feedbacks/dashboard/stats-or-feedbacks/LOAD": {
				next(action);
				const job =
					feedbackJobActions.feedbackDashboardStatsOrFeedbacksLoad;
				const feedbackStore = feedbackSelectors._getStore(getState());
				const ticketTabType =
					feedbackSelectors.getFeedbackTicketTabType(feedbackStore);
				const { stats, assignedTo, selector, typeOfFilter } =
					action.payload as Partial<IDashboardStatsAndFeedbackPayload>;

				const authStore = getState().app.auth;
				// @ts-ignore
				const userId = authStore.user?.userId ?? "";
				const token = authStore?.authToken ?? "";

				dispatch(
					apiRequest<TDashboardStatsAndFeedbackResponse>({
						...feedbackAPIs.loadDashboardStatsAndFeedback({
							token,
							userId,
							assignedTo,
							stats,
							selector,
						}),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								if (stats && typeof stats === "boolean") {
									const statsData =
										data as IDashboardStatsRes;

									dispatch(
										feedbackActions.document.feedbackDashboardsStatsSet(
											ticketTabType,
											statsData ?? { ...initialStats }
										)
									);
								} else if (typeOfFilter && !stats) {
									if (data && (data as IFeedback[])?.length) {
										const feedbacks = data as IFeedback[];
										dispatch(
											feedbackActions.document.feedbackDashboardListSet(
												feedbacks ?? [],
												typeOfFilter,
												ticketTabType
											)
										);
										dispatch(job.success({}));
									} else {
										dispatch(
											feedbackActions.document.feedbackDashboardListSet(
												[],
												typeOfFilter,
												ticketTabType
											)
										);
									}
								}
							},
							onError(err) {
								console.error(err);
								dispatch(
									job.error({
										notification: {},
										message: "Failed to load feedbacks",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			default: {
				next(action);
			}
		}
	};

export default feedbackMiddleware;
