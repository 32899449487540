import React, { useEffect } from "react";
import { IChatMessageAttachment } from "redux/app/feedback/@types";
import MultimediaPreviewer from "./MultimediaPreviewer";

type Props = {
	attachment: File | IChatMessageAttachment;
	index: number;
	attachmentType?: "url" | "local";
	id: string;
	_rev?: string;
	isUploadInProgress: boolean;
};

function FeedbackChatAttachmentRenderer(props: Props) {
	const { attachment, index, attachmentType, id, isUploadInProgress } = props;

	let fileUrl: string = "";
	if (attachmentType === "local")
		fileUrl = URL.createObjectURL(attachment as File);

	useEffect(() => {
		return () => {
			if (fileUrl) {
				URL.revokeObjectURL(fileUrl);
			}
		};
	}, []);

	return (
		<div
			className="w-full h-full relative"
			key={index}
		>
			{isUploadInProgress && (
				<div className="absolute w-full h-full bg-[#00000066] ">
					<div className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded-md flex justify-center items-center" >
					<svg
						className="animate-spin  mx-3 ml-2 h-5 w-5 text-white"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						></path>
					</svg>
					</div>
				</div>
			)}
			{
				<MultimediaPreviewer
					title={attachment?.name ?? ""}
					url={
						attachmentType === "url"
							? `https://console.wayship.io/api/attachments?docId=${id}&attachmentName=${attachment?.name}&type=${attachment?.type}`
							: attachmentType === "local"
							? fileUrl
							: ""
					}
					content_type={attachment?.type ?? ""}
					width={"100%"}
					height={"100%"}
					className="rounded-md"
				/>
			}
		</div>
	);
}

export default React.memo(
	FeedbackChatAttachmentRenderer,
	(prevProps, nextProps) => {
		if (
			prevProps.attachment.name === nextProps.attachment.name &&
			prevProps.attachment.type === nextProps.attachment.type && 
			prevProps.isUploadInProgress  === nextProps.isUploadInProgress
		)
			return true;
		return false;
	}
);
