import { IStore } from "redux/store";
import { IInsightStore } from "./@types";
import { jobSelectors } from "redux/_core/job";
import { getType } from "typesafe-actions";
import insightActions from "./actions";

const insightSelectors = {
	_getStore(store: IStore) {
		return store.app.insight;
	},
	getEngineLogConfig(insightStore: IInsightStore) {
		return insightStore.engineLog.config;
	},
	getShowParameterMetricList(insightStore: IInsightStore) {
		return insightStore.showParameterMetricList;
	},
	getShowRecentMetricList(insightStore: IInsightStore) {
		return insightStore.showRecentMetricList;
	},
	getParameterCurrentTab(insightStore: IInsightStore) {
		return insightStore.currentTab;
	},
	getEngineLogSelectedMetrics(insightStore: IInsightStore) {
		return insightStore.engineLog.selectedEngineLogMetrics;
	},
	getEngineLogMetricsList(insightStore: IInsightStore) {
		return insightStore.engineLog.metricsList;
	},
	getDateRange(insightStore: IInsightStore) {
		return insightStore.dateRange;
	},
	getVesselId(insightStore: IInsightStore) {
		return insightStore.vesselId;
	},
	getStateOfShipDropdownOptions(insightStore: IInsightStore) {
		return insightStore.stateOfShipOptions;
	},
	getStateOfShip(insightStore: IInsightStore) {
		return insightStore.stateOfShip;
	},
	getSearchInputText(insightStore: IInsightStore) {
		return insightStore.search;
	},
	getEngineLogVesselSpecificInsights(insightStore: IInsightStore) {
		// TODO: name change of the variable
		return insightStore.engineLog.engineLogInsights;
	},
	getEngineLogActiveSelectedMetrics(insightStore: IInsightStore) {
		// TODO: name change of the variable
		return insightStore.engineLog.activeSelectedEngineLogMetrics;
	},
	// INFO: NOON Report specific selectors
	getNoonReportConfig(insightStore: IInsightStore) {
		return insightStore.noonReport.config;
	},
	getNoonReportEngineConfig(insightStore: IInsightStore) {
		return insightStore.noonReport.engineConfig;
	},
	getNoonReportMetricsList(insightStore: IInsightStore) {
		return insightStore.noonReport.metricsList;
	},
	getNoonReportSelectedMetrics(insightStore: IInsightStore) {
		return insightStore.noonReport.selectedNoonReportMetrics;
	},
	getNoonReportActiveSelectedMetrics(insightStore: IInsightStore) {
		return insightStore.noonReport.activeSelectedNoonReportMetrics;
	},
	getNoonReportVesselSpecificInsights(insightStore: IInsightStore) {
		return insightStore.noonReport.noonReportInsights;
	},
	getStateOfShipError(insightStore: IInsightStore) {
		return insightStore.stateOfShipError;
	},

	// JOBS
	jobToLoadConfig(insightStore: IInsightStore) {
		return jobSelectors.getJobStatus(
			insightStore,
			getType(insightActions.commands.insightConfigLoad)
		);
	},
	jobToLoadVesselSpecificInsights(insightStore: IInsightStore) {
		return jobSelectors.getJobStatus(
			insightStore,
			getType(insightActions.commands.vesselSpecificInsightsLoad)
		);
	},
};

export default insightSelectors;
