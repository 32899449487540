import React from "react";
import VesselOnboardRanges from "../../../../../components/app/vessel/onboard/ranges/Ranges";
type ScreenVesselOnboardRangesType = React.PropsWithChildren<{}>;
const ScreenVesselOnboardRanges: React.FC<ScreenVesselOnboardRangesType> = (
	props: ScreenVesselOnboardRangesType
) => {
	return <VesselOnboardRanges>ScreenVesselOnboardRanges</VesselOnboardRanges>;
};

export default ScreenVesselOnboardRanges;
