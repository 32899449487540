import { IAPIRequestMetaData } from "redux/_core/api/types";
import { getType } from "typesafe-actions";
import toolActions from "./actions";
import {
	ICPActivityLog,
	IConfigInfo,
	IConfigListItem,
	ICopyPayload,
	IPrintInfo,
	IPrintListItem,
	ITenant,
	TConfigType,
	TPrintType,
	IPrintCopyPayload,
} from "./@types";
import { IVessel, IVesselUser, TCount } from "../feedback/@types";
import { commonUtils } from "redux/_common";

const toolAPIs = {
	loadTenantsList(): IAPIRequestMetaData<ITenant[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/tenants`,
			method: "GET",
			feature: getType(toolActions.commands.toolTenantListLoad),
		};
	},
	loadVesselSpecificUsers(
		tenantId?: string,
		vesselId?: string,
		isTrainingRequired?: boolean
	): IAPIRequestMetaData<IVesselUser[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/users`,
			feature: getType(toolActions.commands.toolVesselSpecificUsersLoad),
			method: "GET",
			queryParams: {
				tenantId,
				vesselId,
				isTrainingRequired,
			},
		};
	},
	userTrainingCertificateApprove(userId: string): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/users`,
			feature: getType(toolActions.commands.toolVesselSpecificUsersLoad),
			method: "PATCH",
			queryParams: {
				action: "issueTrainingCertificate",
			},
			body: {
				_id: userId,
			},
		};
	},
	tenantSpecificAddTrainingUsersFromCSV(
		users: IVesselUser[],
		tenantId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/users`,
			feature: getType(
				toolActions.commands.toolTenantSpecificAddTrainingUsersFromCSV
			),
			method: "POST",
			body: { users, tenantId },
		};
	},
	editVesselSpecificUser(
		userId: string,
		trainingDate: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/users?action=trainingDate`,
			feature: getType(toolActions.commands.toolVesselSpecificUserEdit),
			method: "PATCH",
			body: {
				_id: userId,
				trainingDate,
			},
		};
	},
	loadTenantSpecificVesselsList(
		tenantId: string
	): IAPIRequestMetaData<IVessel[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/vessels`,
			method: "GET",
			feature: getType(toolActions.commands.toolTenantSpecificVesselLoad),
			queryParams: {
				tenantId,
			},
		};
	},
	loadConfigsListForType(
		category: TConfigType,
		userId: string,
		token?: string,
		vesselId?: string
	): IAPIRequestMetaData<IConfigListItem[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "GET",
			feature: getType(toolActions.commands.toolConfigListLoad),
			queryParams: {
				category,
				vesselId: vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadConfigInfo(
		configId: string,
		token: string,
		userId: string
	): IAPIRequestMetaData<IConfigInfo> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "GET",
			feature: getType(toolActions.commands.toolConfigLoad),
			queryParams: {
				id: configId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	configCreate(
		config: any,
		token: string,
		userId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "POST",
			feature: getType(toolActions.commands.toolConfigCreate),
			body: config,
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	configUpdate(
		config: any,
		token: string,
		userId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "PATCH",
			feature: getType(toolActions.commands.toolConfigUpdate),
			body: config,
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	configCopy(
		payload: ICopyPayload,
		action: "copy",
		token: string,
		userId: string
	): IAPIRequestMetaData<{ message: string }> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "POST",
			feature: getType(toolActions.commands.toolConfigCopy),
			body: payload,
			queryParams: {
				action,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	configDelete(
		configId: string,
		token: string,
		userId: string
	): IAPIRequestMetaData<{ message: string }> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			method: "DELETE",
			feature: getType(toolActions.commands.toolConfigDelete),
			queryParams: {
				id: configId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadPrintsListForType(
		category: TPrintType,
		userId: string,
		token?: string,
		vesselId?: string
	): IAPIRequestMetaData<IPrintListItem[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/prints`,
			method: "GET",
			feature: getType(toolActions.commands.toolPrintListLoad),
			queryParams: {
				category,
				vesselId: vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadPrintInfo(
		printId: string,
		token: string,
		userId: string
	): IAPIRequestMetaData<IPrintInfo> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/prints`,
			method: "GET",
			feature: getType(toolActions.commands.toolPrintLoad),
			queryParams: {
				id: printId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	printCreate(
		print: any,
		token: string,
		userId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/prints`,
			method: "POST",
			feature: getType(toolActions.commands.toolPrintCreate),
			body: print,
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	printUpdate(
		print: any,
		token: string,
		userId: string
	): IAPIRequestMetaData<any> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/prints`,
			method: "PATCH",
			feature: getType(toolActions.commands.toolPrintUpdate),
			body: print,
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	printCopy(
		payload: IPrintCopyPayload,
		action: "copy",
		token: string,
		userId: string
	): IAPIRequestMetaData<{ message: string }> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/prints`,
			method: "POST",
			feature: getType(toolActions.commands.toolPrintCopy),
			body: payload,
			queryParams: {
				action,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	printDelete(
		printId: string,
		token: string,
		userId: string
	): IAPIRequestMetaData<{ message: string }> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/prints`,
			method: "DELETE",
			feature: getType(toolActions.commands.toolPrintDelete),
			queryParams: {
				id: printId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	toolCPActivityLogList(
		token: string,
		userId: string,
		itemsPerPage: number,
		pageNumber: number
	): IAPIRequestMetaData<ICPActivityLog[]> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/activitylog`,
			method: "GET",
			feature: getType(toolActions.commands.toolCPActivityLogLoad),
			queryParams: {
				limit: itemsPerPage,
				skip: (pageNumber - 1) * itemsPerPage,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	getListCountByModelName(modelName: string): IAPIRequestMetaData<TCount> {
		return {
			url: `${commonUtils.getAPIBaseUrl()}/api/count/`,
			feature: getType(toolActions.commands.toolCountListLoad),
			method: "GET",
			body: {},
			queryParams: {
				modelName,
			},
		};
	},
};

export default toolAPIs;
