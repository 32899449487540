import { multiActionMiddleware } from "./_core/multi-action";
import { historyMiddleware } from "./_core/history";
import { apiMiddleware } from "./_core/api";
import { applyMiddleware, compose, Middleware } from "redux";
import { promiseMiddleware } from "./_core/promise-action";
import { authMiddlewaresList } from "./app/auth";
import { vesselMiddleware } from "./app/vessel";
import { approvalMiddleware } from "./app/approval";
import { extMiddleware } from "./app/ext";
import { vesselOnboardMiddlewares } from "./app/vessel/onboard";
import { notificationMiddleware } from "./_core/notification";
import localStorageMiddleware from "./_core/localstorage";
import trueTypeMiddleware from "./_core/true-type";
import appCoreMiddleware from "./app/_core/middleware";
import { logMiddleware } from "./app/logs";
import { marpolMiddleware } from "./app/marpol";
import { checklistMiddleware } from "./app/checklist";
import feedbackMiddleware from "./app/feedback/middlewares";
import { topNavbarMiddleware } from "./app/topnavbar";
import { userMiddleware } from "./app/user";
import { dashboardMiddleware } from "./app/dashboard";
import { reportMiddleware } from "./app/report";
import { toolMiddleware } from "./app/tool";
import { insightMiddleware } from "./app/insight";

const composeEnhancers =
	typeof window === "object" &&
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				trace: true,
				traceLimit: 25,
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;

const coreMiddlewares = [
	multiActionMiddleware,
	promiseMiddleware,
	trueTypeMiddleware,
	historyMiddleware,
	apiMiddleware,
	localStorageMiddleware,
	notificationMiddleware,
];
const featureMiddlewares: Middleware[] = [
	...authMiddlewaresList,
	appCoreMiddleware,
	vesselMiddleware,
	...vesselOnboardMiddlewares,
	logMiddleware,
	approvalMiddleware,
	marpolMiddleware,
	checklistMiddleware,
	extMiddleware,
	feedbackMiddleware,
	topNavbarMiddleware,
	userMiddleware,
	dashboardMiddleware,
	reportMiddleware,
	toolMiddleware,
	insightMiddleware,
];
const enhancer = composeEnhancers(
	applyMiddleware(
		...coreMiddlewares,
		...featureMiddlewares
		// Add middlewares here
	)
	// other store enhancers if any
);

export default enhancer;
