import classNames from "classnames";
import InfiniteProgress from "components/_common/InfiniteProgress";
import SVGNotes from "components/_common/icons/feedback/SVGNotes";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
	IFeedbackUpdatePayload,
	IFeedbackUpdateType,
} from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";
import { IStore } from "redux/store";

type OwnProps = {
	setFeedbackAPIUpdateType: (
		value: React.SetStateAction<IFeedbackUpdateType>
	) => void;
	feedbackAPIUpdateType: IFeedbackUpdateType;
};
type NotesProps = PropsFromRedux & OwnProps;
function Notes(props: NotesProps) {
	const {
		setFeedbackAPIUpdateType,
		individualFeedback,
		feedbackAPIUpdateType,
		jobToUpdateFeedback,
	} = props;

	const [notesVal, setNotesVal] = useState(individualFeedback?.notes ?? "");
	useEffect(() => {
		if (individualFeedback?.notes) {
			setNotesVal(individualFeedback?.notes);
		} else {
			setNotesVal("")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [individualFeedback?.notes]);

	return (
		<div className="w-full lg:w-1/2 lg:border-l lg:border-[#DEDEDE] ">
			<form
				onSubmit={(e) => {
					e.preventDefault();

					setFeedbackAPIUpdateType({
						isTable1Update: false,
						isTable2Update: false,
						notesUpdate: true,
					});
					props.updateFeedback({ notes: notesVal });
				}}
			>
				<div className="flex items-center justify-between p-2 border-t lg:border-t-0 border-b border-[#DEDEDE] ">
					<div className="flex items-center">
						<SVGNotes />
						<h4 className="ml-2 text-sm">Notes</h4>
					</div>
					<button
						type="submit"
						disabled={
							(feedbackAPIUpdateType.notesUpdate &&
								jobToUpdateFeedback.isLoading) ||
							!notesVal?.length
						}
						className={classNames("underline text-[12px] font-semibold", {
							"text-[#1B4EA3]": !(
								feedbackAPIUpdateType.notesUpdate &&
								jobToUpdateFeedback.isLoading
							),
							"text-gray-600 cursor-not-allowed":
								(feedbackAPIUpdateType.notesUpdate &&
									jobToUpdateFeedback.isLoading) ||
								!notesVal?.length,
						})}
					>
						Save
					</button>
				</div>
				<InfiniteProgress
					isLoading={
						feedbackAPIUpdateType.notesUpdate && jobToUpdateFeedback.isLoading
					}
					isSpacedOut={false}
				/>
				<div className="w-full">
					<textarea
						value={notesVal}
						className="w-full h-[180px] p-4 text-sm"
						onChange={(e) => {
							const modifiedNote = e.target.value;
							setNotesVal(modifiedNote);
						}}
						placeholder="Write your notes/remarks here"
					/>
				</div>
			</form>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const feedbackStore = feedbackSelectors._getStore(store);
	const individualFeedback =
		feedbackSelectors.getIndividualFeedback(feedbackStore);
	const jobToUpdateFeedback =
		feedbackSelectors.jobToUpdateSelectedFeedback(feedbackStore);
	return {
		individualFeedback,
		jobToUpdateFeedback,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		updateFeedback(payload: IFeedbackUpdatePayload) {
			dispatch(feedbackActions.commands.feedbackUpdate(payload));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Notes);
