import React, { useEffect } from "react";
import { IHistoryStore } from "../../redux/_core/history/types";
import { connect } from "react-redux";
import { IStore } from "../../redux/store";
import { useHistory } from "react-router-dom";
type RedirectViaReduxProps = {
	historyStore: IHistoryStore;
};
const RedirectViaRedux: React.FC<RedirectViaReduxProps> = ({
	historyStore,
}) => {
	const history = useHistory();
	useEffect(() => {
		if (historyStore.canUpdateRoute) {
			switch (historyStore.method) {
				case "PUSH":
					history.push(historyStore.url);
					break;
				case "POP":
					history.back();
					break;
				case "REPLACE":
					history.replace(historyStore.url);
					break;
				case "CLEAR":
					//FIXME: Do something here
					break;
			}
		}
	}, [historyStore.canUpdateRoute]);
	return null;
};
const mapStateToProps = (store: IStore) => ({
	historyStore: store.core.history,
});

export default connect(mapStateToProps, () => ({}))(RedirectViaRedux);
