import React from "react";

const bilgeWaterLegends = [
	{ title: "OWS overboard", key: "owsOverboard" },
	{ title: "Transfer to slop", key: "transferToSlop" },
];

const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		return (
			<div className="bg-white p-2.5 border-[0.5px] font-redhat-text rounded-md shadow-md">
				<p className="label font-redhat-text ">{`${label}`}</p>

				<>
					{payload.map((category: any) => {
						const cat = bilgeWaterLegends.find(
							(leg) => leg.key === category.name
						);
						return (
							<div key={cat?.key} className="flex items-center text-sm">
								<div
									className="w-2 h-2 rounded-full mr-1"
									style={{ background: category.fill }}
								/>
								<p>
									{cat?.title ?? ""} : {category?.value?.toFixed(2) ?? ""}
								</p>
							</div>
						);
					})}
					<p className="text-xs">
						{" "}
						<span className="text-red-400 text-sm mr-0.5">*</span>All values in
						m³
					</p>
				</>
			</div>
		);
	}

	return null;
};

export default CustomTooltip;
