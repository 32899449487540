import Axios from "axios";
import { IAuthUser } from "./types";
import authConstants from "./constants";

const { AUTH_KEY, USER_KEY } = authConstants.storage;

const cacheAuthToken = (token: string) => {
	localStorage.setItem(AUTH_KEY, token);
};
const getCachedAuthToken = () => {
	return localStorage.getItem(AUTH_KEY);
};
const cacheUserInformation = (user: IAuthUser) => {
	localStorage.setItem(USER_KEY, JSON.stringify(user));
};
const getCachedUserInformation = () => {
	const cachedUser = localStorage.getItem(USER_KEY);
	if (cachedUser) return JSON.parse(cachedUser);
	return null;
};
const clearAuth = () => {
	localStorage.removeItem(AUTH_KEY);
	localStorage.removeItem(USER_KEY);
	Axios.defaults.headers.common["x-access-token"] = null;
};
const setAxiosAuthToken = (token: string) => {
	Axios.defaults.headers.common["x-access-token"] = token;
};

const authUtils = {
	cacheAuthToken,
	getCachedAuthToken,
	cacheUserInformation,
	getCachedUserInformation,
	clearAuth,
	setAxiosAuthToken,
};

export default authUtils;
