import classNames from "classnames";
import moment from "moment";
import React from "react";
import { marpolUtils } from "../../../../../redux/app/marpol";
import { TAnnex6Record } from "../../../../../redux/app/annex6/@types";
import {
	TAnnex6GroupA,
	TAnnex6GroupA1,
} from "../../../../../redux/app/annex6/groups/group-a";
import CommaSeparator from "../../../../_common/CommaSeparator";
import Annex6OldVersionPreview from "./Annex6OldVersionPreview";
import Annex6TableRows from "../Annex6TableRows";

type OwnProps = {
	annex6Record: TAnnex6Record;
	isStrikedOff?: boolean;
};
type Annex6GroupA1PreviewProps = OwnProps;
function Annex6GroupA1Preview(props: Annex6GroupA1PreviewProps) {
	const record = props.annex6Record as TAnnex6GroupA1;
	const operationDate = moment(record.selectedDate, "YYYY-MM-DD").format(
		"DD-MMM-YYYY"
	);

	function renderMain() {
		return (
			<Annex6TableRows
				record={record}
				tableRows={[
					[operationDate, "A", 1.1, renderItem1P1()],
					[null, null, 1.2, renderItem1P2()],
					[null, null, 1.3, renderItem1P3()],
					[null, null, 1.4, renderItem1P4()],
					[null, null, 1.5, renderItem1P5()],
					[null, null, "NOTE", record.remarks],
				]}
			/>
		);
	}

	function renderItem1P1() {
		if (!record.item1P1.place) return <Annex6OldVersionPreview />;
		if (record.item1P1.place === "IN_PORT") return record.item1P1.portName;
		if (record.item1P1.place === "AT_SEA")
			return (
				<>
					{record.item1P1?.latitude} {record.item1P1?.longitude}
				</>
			);
		return record.item1P1.receptionFacilityName;
	}

	function renderItem1P2() {
		if (!record.item1P2.start || !record.item1P2.end)
			return <Annex6OldVersionPreview />;

		return `STARTED  ${record.item1P2.start.date} at ${record.item1P2.start.time} | FINISHED  ${record.item1P2.end.date} at ${record.item1P2.end.time}`;
	}

	function renderItem1P3() {
		return (
			<h4 className="font-medium uppercase">
				{record.item1P3.fuelType} - {record.item1P3.sulphurContent} % SULPHUR
				CONTENT
			</h4>
		);
	}

	function renderItem1P4() {
		if (!record.item1P4.tanks) {
			console.error(
				record.item1P4,
				"Item1P4 is invalid or is an older version."
			);
			return <Annex6OldVersionPreview />;
		}
		const totalQuantityInMT = record.item1P4.tanks
			.reduce((result, currentTank) => result + currentTank.quantityOfFuel, 0)
			.toFixed(3);
		return (
			<>
				{totalQuantityInMT} MT OF {record.item1P3.fuelType} bunkered to tanks.{" "}
				<br />
				<CommaSeparator
					items={record.item1P4.tanks.map((tank) => {
						const quantityOfFuel = tank.quantityOfFuel.toFixed(3);
						const totalContent = tank.totalContent.toFixed(3);
						return `${quantityOfFuel} MT ADDED TO ${tank.selectedTank.tankName} NOW CONTAINING ${totalContent} MT`;
					})}
					customSeperator={<br />}
				/>
			</>
		);
	}

	function renderItem1P5() {
		const attachmentId = record.isAutoCreated
			? record.attachmentSourceId
			: record.id;
		const attachmentRev = record.isAutoCreated
			? record.attachmentSourceRev
			: record._rev;
		const link = record.item1P5.bdnAttachmentLink;
		const url = marpolUtils.getAttachmentURL(link, attachmentId, attachmentRev);
		return (
			<>
				BDN REFERENCE NO: {record.item1P5.BDN}
				{link ? (
					<>
						<br />
						<a href={url} target="_blank">
							{link}
						</a>
					</>
				) : null}
			</>
		);
	}

	return renderMain();
}

export default Annex6GroupA1Preview;
