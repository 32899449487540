import { IAPIRequestMetaData } from "redux/_core/api/types";
import { getType } from "typesafe-actions";
import insightActions from "./actions";

import {
	IEngineLogInsightPayloadItem,
	ILoadEngineLogConfigParams,
	TStateOfShip,
} from "./@types";

const insightAPIS = {
	loadConfig(params: ILoadEngineLogConfigParams): IAPIRequestMetaData<any> {
		const { category, type, vesselId, token, userId } = params;
		return {
			feature: getType(insightActions.commands.insightConfigLoad),
			method: "GET",
			url: `https://console.wayship.io/api/configs`,
			// url: `${commonUtils.getAPIBaseUrl()}/api/configs`,
			queryParams: {
				category,
				type,
				vesselId,
			},
			headers: {
				// @ts-ignore
				"x-token": token,
				"x-user-id": userId,
			},
		};
	},
	loadVesselSpecificEngineLogInsights(
		token: string,
		userId: string,
		vesselId: string,
		metricKeys: string[],
		fromDate: string,
		toDate: string,
		type: "engine" | "noon_report",
		state_of_ship?: TStateOfShip
	): IAPIRequestMetaData<IEngineLogInsightPayloadItem[]> {
		return {
			feature: getType(insightActions.commands.vesselSpecificInsightsLoad),
			method: "GET",
			url: `https://console.wayship.io/api/insights`,
			// url: `${commonUtils.getAPIBaseUrl()}/api/insights`,
			queryParams: {
				vesselId,
				metricKeys: `[${metricKeys.map((key) => `"${key}"`)}]`,
				fromDate,
				toDate,
				state_of_ship,
				type,
			},
			headers: {
				// @ts-ignore
				"x-token": token || undefined,
				"x-user-id": userId,
			},
		};
	},
};

export default insightAPIS;
