import Button from "@set-product/core/button/Button";
import Dropdown from "components/_common/DropdownWithFilter";
import InfiniteProgress from "components/_common/InfiniteProgress";
import InputWrapper from "components/_common/InputWrapper";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { authSelectors } from "redux/app/auth";
import { TFeedback } from "redux/app/feedback/@types";
import feedbackActions from "redux/app/feedback/actions";
import feedbackSelectors from "redux/app/feedback/selectors";
import { tasksData } from "redux/app/feedback/tasksData";
import {
	getSegregatedTasks,
	getVesselUsers,
	isFeedbackEmpty,
} from "redux/app/feedback/utils";

import { IStore } from "redux/store";

type OwnProps = {
	FEVersions: string[];
	BEVersions: string[];
};
type FeedbackFormProps = PropsFromRedux & OwnProps;
function FeedbackForm(props: FeedbackFormProps) {
	const {
		authenticatedUserName,
		miniVessels,
		crews,
		tasks,
		isFeedbackFormModalOpen,
		jobToLoadVesselSpecificUsers,
		FEVersions,
		BEVersions,
		jobToSendFeedback,
	} = props;
	// const { crews } = vesselCrewData;

	const vesselUsers = getVesselUsers(crews);

	const initialFeedback = {
		author: authenticatedUserName ? authenticatedUserName : "",
		feedback: "",
		title: "",
		section: "",
		subsection: "",
		vesselName: "",
		FE: "",
		BE: "",
	};

	const [feedback, setFeedback] = useState<TFeedback>(initialFeedback);
	const segregatedTasks = getSegregatedTasks(tasks);

	const possibleSections = Object.keys(segregatedTasks).map((section) => ({
		label: section,
		value: section,
	}));

	const selectedSection = feedback.section ?? "";
	const possibleSubSections =
		selectedSection && selectedSection in segregatedTasks
			? segregatedTasks[selectedSection]?.map((subSection) => ({
					label: subSection,
					value: subSection,
			  }))
			: [];

	useEffect(() => {
		setFeedback(initialFeedback);
		// props.loadTaskTemplates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isFeedbackFormModalOpen) {
			setFeedback(initialFeedback);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFeedbackFormModalOpen]);

	useEffect(() => {
		feedback?.vesselName && props.loadVesselSpecificUsers(feedback.vesselName);
		setFeedback({ ...feedback, author: undefined });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedback.vesselName]);

	useEffect(() => {
		if (jobToSendFeedback.isError || jobToSendFeedback.isSuccess) {
			setFeedback(initialFeedback);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobToSendFeedback.isError, jobToSendFeedback.isSuccess]);

	return (
		<>
			<form
				className="h-full"
				onSubmit={(e) => {
					e.preventDefault();

					let { author, vesselName } = feedback;
					const authorDetails = vesselUsers.find(
						(user) => user.value === author
					);
					props.onSubmit({
						...feedback,
						author: authorDetails?.label ?? "",
						authorId: authorDetails?.value ?? "",
						authorDesignation: authorDetails?.value
							? crews.byIds[authorDetails.value]?.designation.name
							: "",
						vesselId: vesselName ?? "",
						vesselName:
							Object.values(miniVessels.byIds).find(
								(vessel) => vessel.id === vesselName
							)?.vesselName ?? "",
					});
					// setFeedback(initialFeedback);
				}}
			>
				<div className="h-full px-4 overflow-hidden">
					<div className="flex items-center justify-between mb-2">
						<h3 className="mb-0 text-xl font-medium leading-none text-gray-800 font-redhat-display">
							Add Feedback
						</h3>
						<Button
							text="Submit"
							disabled={
								isFeedbackEmpty(feedback) || jobToSendFeedback.isLoading
							}
							view="filled"
							type="submit"
							color="primary"
							className="px-6 mb-2 bg-blue-700 whitespace-nowrap disabled:bg-gray-300 disabled:text-gray-500 md:mr-4 md:mb-0"
						/>
					</div>
					<div className="h-[calc(80vh-72px)] overflow-y-auto">
						<div className="flex flex-col md:flex-row md:space-x-2">
							<div className="w-full mb-1">
								<InputWrapper
									className="w-full"
									hasError={false}
									hasWarning={false}
								>
									<label
										htmlFor="select-vessel-name"
										className="ws-input__label"
									>
										Vessel Name
									</label>
									<Dropdown
										onInputChange={(e) => {}}
										id="select-vessel-name"
										inputWrapperProps={{
											className: "p-0",
										}}
										emptyOption={{
											label: "",
											value: "",
										}}
										value={feedback?.vesselName}
										onSelect={(selectedItem) => {
											if (selectedItem) {
												setFeedback({
													...feedback,
													vesselName: selectedItem.value,
												});
												props.loadVesselSpecificUsers(selectedItem.value);
											}
										}}
										options={[
											{
												label: "Select a vessel",
												value: "",
											},
											...miniVessels.ids.map((id) => {
												return {
													...miniVessels.byIds[id],
													label: miniVessels.byIds[id].vesselName,
													value: miniVessels.byIds[id].id,
												};
											}),
										]}
										renderListItem={(listItem) => {
											return (
												<div className="p-0 tracking-wide rounded-md cursor-pointer">
													<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										noFilter={true}
									/>
									<span className="ws-input__error">Hello Error</span>
									<span className="ws-input__warning">Hello Warning</span>
								</InputWrapper>
							</div>
							<div className="w-full mb-1">
								<InputWrapper
									className="w-full"
									hasError={false}
									hasWarning={false}
									isLoading={jobToLoadVesselSpecificUsers.isLoading}
								>
									<label
										htmlFor="select-author-name"
										className="ws-input__label"
									>
										Author Name
									</label>
									<Dropdown
										onInputChange={(e) => {}}
										id="select-author-name"
										inputWrapperProps={{
											className: "p-0",
										}}
										disabled={!feedback?.vesselName || !vesselUsers.length}
										emptyOption={{
											label: "",
											value: "",
										}}
										value={feedback?.author}
										onSelect={(selectedItem) => {
											if (selectedItem) {
												setFeedback({
													...feedback,
													author: selectedItem.value,
												});
											}
										}}
										options={vesselUsers}
										renderListItem={(listItem) => {
											return (
												<div className="p-0 tracking-wide rounded-md cursor-pointer">
													<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
									/>
									<span className="ws-input__error">Hello Error</span>
									<span className="ws-input__warning">Hello Warning</span>
									<InfiniteProgress
										className="ws-input__progress"
										isLoading={true}
										isSmall={true}
										isSpacedOut={false}
									/>
								</InputWrapper>
							</div>
						</div>
						<div className="flex flex-col md:flex-row space-x-2">
							<div className="w-full mb-1">
								<InputWrapper
									className="w-full"
									hasError={false}
									hasWarning={false}
								>
									<label
										htmlFor="select-section-id"
										className="ws-input__label"
									>
										Section
									</label>
									<Dropdown
										onInputChange={(e) => {}}
										id="feedback-section-id"
										inputWrapperProps={{
											className: "p-0",
										}}
										emptyOption={{
											label: "",
											value: "",
										}}
										value={feedback?.section}
										onSelect={(selectedItem) => {
											if (selectedItem) {
												setFeedback({
													...feedback,
													section: selectedItem.value,
												});
											}
										}}
										options={possibleSections}
										renderListItem={(listItem) => {
											return (
												<div className="p-0 tracking-wide rounded-md cursor-pointer">
													<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
									/>
									<span className="ws-input__error">Hello Error</span>
									<span className="ws-input__warning">Hello Warning</span>
								</InputWrapper>
							</div>
							<div className="w-full mb-1">
								<InputWrapper
									className="w-full"
									hasError={false}
									hasWarning={false}
								>
									<label
										htmlFor="select-sub-section-id"
										className="ws-input__label"
									>
										Sub Section
									</label>
									<Dropdown
										onInputChange={(e) => {}}
										id="feedback-sub-section-id"
										inputWrapperProps={{
											className: "p-0",
										}}
										emptyOption={{
											label: "",
											value: "",
										}}
										value={feedback.subsection}
										onSelect={(selectedItem) => {
											if (selectedItem) {
												setFeedback({
													...feedback,
													subsection: selectedItem.value,
												});
											}
										}}
										options={possibleSubSections}
										renderListItem={(listItem) => {
											return (
												<div className="p-0 tracking-wide rounded-md cursor-pointer">
													<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
									/>
									<span className="ws-input__error">Hello Error</span>
									<span className="ws-input__warning">Hello Warning</span>
								</InputWrapper>
							</div>
						</div>
						<div className="flex flex-col md:flex-row md:space-x-2">
							<div className="w-full mb-1">
								<InputWrapper
									className="w-full"
									hasError={false}
									hasWarning={false}
								>
									<label
										htmlFor="select-fe-version"
										className="ws-input__label"
									>
										FE Version
									</label>
									<Dropdown
										onInputChange={(e) => {}}
										id="select-fe-version"
										inputWrapperProps={{
											className: "p-0",
										}}
										emptyOption={{
											label: "",
											value: "",
										}}
										value={feedback?.FE}
										onSelect={(selectedItem) => {
											if (selectedItem) {
												setFeedback({
													...feedback,
													FE: selectedItem.value,
												});
											}
										}}
										options={[
											{
												label: "Select FE Version",
												value: "",
											},
											...FEVersions.map((version) => ({
												label: version,
												value: version,
											})),
										]}
										renderListItem={(listItem) => {
											return (
												<div className="p-0 tracking-wide rounded-md cursor-pointer">
													<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										noFilter={true}
									/>
									<span className="ws-input__error">Hello Error</span>
									<span className="ws-input__warning">Hello Warning</span>
								</InputWrapper>
							</div>
							<div className="w-full mb-1">
								<InputWrapper
									className="w-full"
									hasError={false}
									hasWarning={false}
								>
									<label
										htmlFor="select-be-version"
										className="ws-input__label"
									>
										BE Version
									</label>
									<Dropdown
										onInputChange={(e) => {}}
										id="select-be-version"
										inputWrapperProps={{
											className: "p-0",
										}}
										emptyOption={{
											label: "",
											value: "",
										}}
										value={feedback?.BE}
										onSelect={(selectedItem) => {
											if (selectedItem) {
												setFeedback({
													...feedback,
													BE: selectedItem.value,
												});
											}
										}}
										options={[
											{
												label: "Select BE Version",
												value: "",
											},
											...BEVersions.map((version) => ({
												label: version,
												value: version,
											})),
										]}
										renderListItem={(listItem) => {
											return (
												<div className="p-0 tracking-wide rounded-md cursor-pointer">
													<h1 className="mb-1 py-0.5 text-xs font-semibold text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										renderView={(listItem) => {
											return (
												<div className="p-0 tracking-wide bg-gray-200 rounded-md cursor-pointer">
													<h1 className="text-xs font-medium tracking-wider text-gray-600 font-redhat-display">
														{listItem.label}
													</h1>
												</div>
											);
										}}
										noFilter={true}
									/>
									<span className="ws-input__error">Hello Error</span>
									<span className="ws-input__warning">Hello Warning</span>
								</InputWrapper>
							</div>
						</div>
						<div className="mb-1">
							<InputWrapper
								className="w-full"
								hasError={false}
								hasWarning={false}
							>
								<label
									htmlFor="issue-short-description"
									className="ws-input__label"
								>
									Short Description of the issue.
								</label>
								<input
									autoComplete="off"
									// disabled={prefilled}
									type="text"
									name="issue-short-description"
									id="issue-short-description"
									className="ws-input"
									placeholder=""
									value={feedback?.title}
									onChange={(e) => {
										setFeedback({
											...feedback,
											title: e.target.value,
										});
									}}
								/>

								<span className="ws-input__error">Hello Error</span>
								<span className="ws-input__warning">Hello Warning</span>
							</InputWrapper>
						</div>
						<div className="mb-1">
							<InputWrapper
								className="w-full"
								hasError={false}
								hasWarning={false}
							>
								<label htmlFor="issue-description" className="ws-input__label">
									Long Description of the issue.
								</label>
								<textarea
									rows={6}
									className="ws-input"
									// disabled={prefilled}
									placeholder=""
									id="issue-description"
									name="issue-description"
									value={feedback?.feedback}
									onChange={(e) => {
										setFeedback({
											...feedback,
											feedback: e.target.value,
										});
									}}
								/>
								<span className="ws-input__error">Hello Error</span>
								<span className="ws-input__warning">Hello Warning</span>
							</InputWrapper>
						</div>
					</div>
				</div>
			</form>
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const authStore = store.app.auth;
	const feedbackStore = feedbackSelectors._getStore(store);
	return {
		authenticatedUserName: authSelectors(authStore)?.getLoggedInUser()?.name,
		miniVessels: feedbackSelectors.getVessels(feedbackStore),
		crews: feedbackSelectors.getVesselSpecificUsers(feedbackStore),
		// tasks: feedbackSelectors.getTaskTemplates(feedbackStore),
		tasks: tasksData,
		jobToLoadTasks: feedbackSelectors.jobToLoadTasks(feedbackStore),
		jobToLoadVesselSpecificUsers:
			feedbackSelectors.jobToLoadVesselSpecificUsers(feedbackStore),
		isFeedbackFormModalOpen:
			feedbackSelectors.getIsFeedbackFormModalOpen(feedbackStore),
		jobToSendFeedback: feedbackSelectors.jobToSendFeedback(feedbackStore),
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadVesselSpecificUsers(vesselId: string) {
			dispatch(feedbackActions.commands.vesselSpecificUsersLoad(vesselId));
		},
		loadTaskTemplates() {
			return dispatch(feedbackActions.commands.tasksLoad());
		},
		onSubmit(
			feedback: TFeedback & {
				vesselId: string;
				authorId: string;
				authorDesignation: string;
			}
		) {
			return dispatch(feedbackActions.commands.feedbackSend(feedback));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(FeedbackForm);
