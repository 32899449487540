import { IListObjectStore } from "../../_common/type";
import { IFormStore } from "../../_core/form/types";
import { IJobHOCState } from "../../_core/job/types";
import { EMARPOLDocuments } from "../marpol/@types";

export enum EGarbageRecordState {
	APPROVED = "APPROVED",
	STRIKED_OFF = "STRIKED-OFF",
	SUBMITTED = "SUBMITTED",
	IDLE = "IDLE",
	EDIT = "EDIT",
}

export enum EGarbageEditLabels {
	DATE = "Date",
	UTC_TIME = "UTC Time",
	START_OPERATION_DATE = "Start Operation Date",
	START_OPERATION_TIME = "Start Operation Time",
	END_OPERATION_DATE = "End Operation Date",
	END_OPERATION_TIME = "End Operation Time",
	START_LOCATION_LATITUDE = "Start Location Lat",
	START_LOCATION_LONGITURE = "Start Location Long",
	END_LOCATION_LATITUDE = "End Location Lat",
	END_LOCATION_LONGITUDE = "End Location Long",
	GARBAGE_CATEGORY = "Garbage Category",
	GARBAGE_DISCHARGE_AMOUNT = "Garbage Discharge Amount",
	LOCATION_OF_OCCURANCE = "Location Of Occurance",
	LOCATION_OF_DISCHARGE_LATITUDE = "Location Of Discharge Lat",
	LOCATION_OF_DISCHARGE_LONGITURE = "Location Of Discharge Long",
	PLACE_OF_DISCHARGE = "Place Of Discharge",
	REMARK = "Remarks",
	DEPTH_OF_WATER = "Depth Of Water",
}

export interface IGarbageEditLog {
	id: string; // fieldName
	fieldName: string;
	pastValue: string;
	newValue: string;
	editor: IGarbageRecordUser;
	fieldLabel: string;
	timeStamp: number;
	editSessionNumber: number;
}

export interface IGarbageRecordUser {
	name: string;
	designation: string;
	staffId: string;
	date: string;
}

export interface IGarbageBasicInfo {
	id: string;
	_rev: string;
	selectedDate: string;
	utcTimestamp: string;
	state: EGarbageRecordState;
	editLogs: {
		[editSessionNumber: number]: IListObjectStore<IGarbageEditLog>;
	};
	marpolType: EMARPOLDocuments.GRB1;
	submittedBy?: IGarbageRecordUser;
	approvedBy?: IGarbageRecordUser;
	strikeOffReason?: string;
	numberOfEditSessions: number;
}

export interface IGarbage {
	label: string;
	value: string;
	category: string; // A,B,C ...
	estimatedAmountDischarged: number;
	remarks?: string;
}

export type TGarbageEntries =
	| "GARBAGE_INCINERATED"
	| "GARBAGE_DISCHARGED_INTO_SEA"
	| "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA"
	| "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS";

export interface IGarbageIncinerated {
	entry: "GARBAGE_INCINERATED";
	endOfOperation: {
		date: string;
		time: string;
	};
	locationOfStartOfIncineration: {
		latitude: string;
		longitude: string;
	};
	locationOfCompletionOfIncineration: {
		latitude: string;
		longitude: string;
	};
	garbage: IGarbage[];
	remark: string;
}

export interface IGarbageDischargedIntoSea {
	entry: "GARBAGE_DISCHARGED_INTO_SEA";
	locationOfStartOfDischarge: {
		latitude: string;
		longitude: string;
	};
	garbage: IGarbage[];
	remark: string;
}

export type TGarbageQuestion = {
	question: string;
	YES: "ALLOWED" | "BLOCKED" | TGarbageQuestion;
	NO: "ALLOWED" | "BLOCKED" | TGarbageQuestion;
};

export type TGarbageQuestionWithAnswer = TGarbageQuestion & {
	answer: "YES" | "NO" | undefined;
};

export type TGarbageQuestionsState =
	| "PENDING"
	| "COMPLETE"
	| "BLOCKED"
	| "NOT-REQUIRED";

export interface IGarbageAccidentalLoss {
	entry: "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA";
	locationOfOccurance: "SHORE" | "SEA" | "PORT";
	endOfOperation: {
		date: string;
		time: string;
	};
	locationOfDischarge: {
		latitude: string;
		longitude: string;
	};
	depthOfWater: number;
	garbage: IGarbage[];
	remark: string;
}

export interface IGarbageDischargedToReceptionAshoreOrOtherShips {
	entry: "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS";
	placeOfDischarge: string;
	garbage: IGarbage[];
	receiptNumber: string;
	receiptAttachment: string;
	remark: string;
}

export type IGarbageRecord = IGarbageBasicInfo &
	(
		| IGarbageIncinerated
		| IGarbageDischargedIntoSea
		| IGarbageAccidentalLoss
		| IGarbageDischargedToReceptionAshoreOrOtherShips
	);

export type IGRB1BackendRecord = IGarbageRecord & {
	submittedBy: IGarbageRecordUser;
	approvedBy: IGarbageRecordUser;
};
export interface IGarbageRecordNewOperationState {
	garbageBasicInfo: IGarbageBasicInfo & {
		entry: TGarbageEntries;
	};
	garbageIncinerated: IGarbageIncinerated;
	garbageDischargedIntoSea: IGarbageDischargedIntoSea;
	garbageAccidentalLoss: IGarbageAccidentalLoss;
	garbageDischargedToReceptionAshoreOrOtherShips: IGarbageDischargedToReceptionAshoreOrOtherShips;
	newOperationState: "RECORD" | "PREVIEW";
}

export interface IGarbageRecordStore extends IFormStore, IJobHOCState {
	records: IListObjectStore<IGarbageRecord>;
	newOperation: IGarbageRecordNewOperationState;
	// _forms: {
	//     NEW_OPERATION: IForm<IGarbageRecord>;
	// };
	filter: TGRB1Filters;
	garbageQuestionsState: TGarbageQuestionsState;
	garbageDischargedIntoSeaQuestions: TGarbageQuestionWithAnswer[];
}

export type TGRB1Filters = {
	startDate: any;
	endDate: any;
	shownRecords: "ALL" | "ALL-PENDING";
};
